import CurrencyInput from 'react-currency-input-field'
import { useSelector } from 'react-redux'
import { CustomTextField } from '../../shared/components/TextField'

export default function RowThreeItems(props: any) {
    const { updateFairCredit, id, change, readOnly, showRequiredFields, isCurrency } = props
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)

    return (
        <>
            <div style={{ marginRight: props.marginRight, display: 'flex', width: "50%", marginTop: props.marginTop, marginLeft: props.marginLeft, }}>
                <p className="boldPP" style={{ width: `${20 - props.firstPMarginRight}%`, marginRight: `${props.firstPMarginRight}%`, marginLeft: `${props.firstPMarginLeft}%` }} >{props.firstP}</p>
                <div style={{ width: "20%", marginBottom: '1%' }}>
                    {isCurrency ?
                        <CurrencyInput
                            id={id}
                            name={id}
                            className={`padding-currency-${dir}`}
                            placeholder="0.00"
                            tabIndex={-1}
                            value={updateFairCredit ? updateFairCredit[id] : ""}
                            readOnly={readOnly}
                            style={{ width: '100%', height: '32px', backgroundColor: readOnly ? "#FAF9F8" : "white", borderWidth: "1px", borderStyle: "solid", outlineColor: "rgb(0, 120, 212)" }}
                            decimalsLimit={2}
                            onValueChange={(value, name) => change(name || "", Number(value))}
                        />
                        : <CustomTextField requiredField={showRequiredFields && updateFairCredit && !updateFairCredit[id] ? true : false} label={" "} value={updateFairCredit ? updateFairCredit[id] : ""} readOnly={readOnly} onChange={change} id={id} />
                    }
                </div>
                <p className={`boldPP div-scond-${dir}`}>{props.scoundP}</p>
            </div>
        </>
    )
}
