import axios from "axios";
import { cloneDeep } from "lodash";
import moment from "moment";

import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { basicUrl } from "../../shared/config";
import { deteleFile } from "./FilesAction";
import { SET_DATA_CONVERSION, SET_DATA_INVOICE, } from './types'

let currUserCredentials: IUserCredentials | null = null

export const getOpenTransactions = async (typeAction: any, userCredentials: IUserCredentials | null, setSelectConversionList: any) => {
    currUserCredentials = userCredentials
    const openTransactions = {
        "id_branch": currUserCredentials && currUserCredentials["idBranch"],
        "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
        "id_client": currUserCredentials && currUserCredentials["idClient"],
        "transaction_type_id": typeAction,
        "transaction_request_method": "read_drafts",
    }
    const body = JSON.stringify(openTransactions);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    await axios.post(basicUrl + '/transaction', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0 || res.data["err_code"] === 1) {
                        setSelectConversionList && setSelectConversionList(res.data.data)
                    }
                }
            }
            catch (err) {
                console.log('error in create openTransactions',)
            }
        })
}

export const readDraftTransactions = (typeAction: any, userCredentials: IUserCredentials | null, idEntity: string, idTransaction: string) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const transaction = {
        "transaction_type_id": typeAction,
        "transaction_request_method": "read",
        "id_branch": currUserCredentials && currUserCredentials["idBranch"],
        "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
        "id_client": currUserCredentials && currUserCredentials["idClient"],
        "id_entity": idEntity,
        "id_transaction": idTransaction,
    }
    const body = JSON.stringify(transaction);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    axios.post(basicUrl + '/transaction', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        if (typeAction === "cheque_cashing" || typeAction === "exchange") {
                            dispatch({
                                type: SET_DATA_CONVERSION,
                                payload: cloneDeep(res.data.data)
                            })
                        }
                        if (typeAction === "invoice" || typeAction === "receipt") {
                            dispatch({
                                type: SET_DATA_INVOICE,
                                payload: cloneDeep(res.data.data)
                            })
                        }
                    }
                }
            }
            catch (err) {
                console.log('error in create openTransactions',)
            }
        })
}


export const createTransaction = (type: string, typeAction: any, entityName: any, convertsion: any, userCredentials: IUserCredentials | null, idEntity: any
    , setshowSpiner?: any, setServerMessageError?: any, setMessageError?: any, setTitleMessageError?: any, setSelectConversionList?: any, setExsistChanges?: any, setSaveDataFailed?: any, setSaveDatasuccess?: any) => async (dispatch: Function) => {
        currUserCredentials = userCredentials
        let financial_assets_in = cloneDeep(convertsion.financial_assets_in)
        let financial_assets_out = cloneDeep(convertsion.financial_assets_out)
        financial_assets_in?.forEach((element: any) => {
            if (typeAction === "cheque_cashing" && element?.financial_asset_code === "3" && element["financial_asset_details"]) {
                if (!element["financial_asset_details"]?.cheque_cashing_date) {
                    element["financial_asset_details"].cheque_cashing_date = moment(new Date()).format('YYYY-MM-DD');
                }
                // element.financial_asset_details.cheque_date = moment(element?.financial_asset_details?.cheque_date).format("YYYY-MM-DD")
                element["financial_asset_details"].cashing_intrest = element["financial_asset_details"].cashing_intrest / 100;
            }
        });
        const constObject: any = {
            "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
            "id_client": currUserCredentials && currUserCredentials["idClient"],
            "id_branch": currUserCredentials && currUserCredentials["idBranch"],
            "id_entity": idEntity,
            "entity_name": entityName,
            "id_account": convertsion?.id_account,
            "documentation": convertsion?.documentation,
            "related_entities": convertsion?.related_entities,
            "is_unusual_reporting": convertsion?.is_unusual_reporting,
            "transaction_type_id": typeAction,
            /////לבדוק שלא שולח בקשה להדפסת html בכל קריאה של עדכון!!!!!!!!!!!
            "transaction_document": {
                "document_request_method": convertsion?.transaction_document?.document_request_method
            },
            "transaction_request_method": type,
            "total_amount_ils": convertsion?.total_amount_ils,
            "financial_assets_in": financial_assets_in,
            "financial_assets_out": financial_assets_out,
            "id_transaction": convertsion?.id_transaction,
            "note": convertsion?.note
        }
        if (typeAction === "cheque_cashing") {
            constObject.total_net_amount = convertsion?.total_net_amount && Number(convertsion?.total_net_amount)?.toFixed(2)
            constObject.total_expenses = convertsion?.total_expenses
            constObject.amount_round = convertsion?.amount_round && Number(convertsion?.amount_round)?.toFixed(2)
        }
        debugger
        const body = JSON.stringify(constObject);
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };
        console.log(body,"budy");
        
        await axios.post(basicUrl + '/transaction', body, config)
            .then(async res => {
                debugger
                try {
                    if (res.status === 200) {
                        if (res.data["err_code"] === 0) {
                            if (type === "complete" || type === "create")
                                await getOpenTransactions(typeAction, userCredentials, setSelectConversionList)
                            await dispatch({
                                type: SET_DATA_CONVERSION,
                                payload: cloneDeep(res.data.data)
                            })
                            // setShowDialogSave && setShowDialogSave(true)
                            setshowSpiner && setshowSpiner(false)
                            setSaveDatasuccess && setSaveDatasuccess(true)
                            setServerMessageError && setServerMessageError(true)
                            setMessageError && setMessageError('הפעולה בוצעה בהצלחה')
                            setTitleMessageError && setTitleMessageError("הערה")
                            setExsistChanges && setExsistChanges(true)
                            setSaveDataFailed && setSaveDataFailed(false)
                        }
                        else {
                            setSaveDataFailed && setSaveDataFailed(true)
                            setshowSpiner && setshowSpiner(false)
                            setTitleMessageError && setTitleMessageError(res.data?.ui_message?.ui_message_header)
                            setMessageError && setMessageError(res.data?.ui_message?.ui_message)
                            setServerMessageError && setServerMessageError(true)
                            console.log(res.data?.err_description)
                        }
                    }
                }
                catch (err) {
                    setSaveDataFailed && setSaveDataFailed(true)
                    // setShowDialogFailed && setShowDialogFailed(true)
                    setshowSpiner && setshowSpiner(false)
                    console.log('error in create exchangeObject', err)
                }
            })
    }

export const deleteTransaction = (type: string, typeAction: any, convertsion: any, userCredentials: IUserCredentials | null, idEntity: any, setSelectConversionList?: any, setShowDialogSave?: any, setShowDialogFailed?: any, setshowSpiner?: any, setSaveDataFailed?: any) => async (dispatch: Function) => {
    // (type: string, typeAction: any, convertsion: any, userCredentials: IUserCredentials | null, idEntity: any, setshowSpiner?: any,setServerMessageError?: any, setMessageError?: any,setSelectConversionList?: any, setSaveDataFailed?: any)
    debugger
    currUserCredentials = userCredentials
    if (typeAction === "cheque_cashing" || typeAction === "exchange") {
        let financial_assets_in = cloneDeep(convertsion.financial_assets_in)
        financial_assets_in?.forEach(async (element: any) => {
            if (element?.financial_asset_details?.id_media_face && element?.financial_asset_details?.id_media_back) {
                await dispatch(deteleFile(convertsion.id_entity, element?.financial_asset_details?.id_media_face, userCredentials))
                await dispatch(deteleFile(convertsion.id_entity, element?.financial_asset_details?.id_media_back, userCredentials))
            }
            if (element?.financial_asset_details?.media?.media_face?.id_media && element?.financial_asset_details?.media?.media_back?.id_media) {
                await dispatch(deteleFile(convertsion.id_entity, element?.financial_asset_details?.media?.media_face?.id_media, userCredentials))
                await dispatch(deteleFile(convertsion.id_entity, element?.financial_asset_details?.media?.media_back?.id_media, userCredentials))
            }
        });
    }

    const constObject = {
        "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
        "id_client": currUserCredentials && currUserCredentials["idClient"],
        "id_branch": currUserCredentials && currUserCredentials["idBranch"],
        "id_entity": idEntity,
        "transaction_request_method": type,
        "transaction_type_id": typeAction,
        "id_transaction": convertsion?.id_transaction
    }
    const body = JSON.stringify(constObject);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    await axios.post(basicUrl + '/transaction', body, config)
        .then(res => {
            debugger
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        if (type === "delete" && (typeAction === "exchange" || typeAction === "cheque_cashing")) {
                            getOpenTransactions(typeAction, userCredentials, setSelectConversionList)
                            dispatch({
                                type: SET_DATA_CONVERSION,
                                payload: res.data.data
                            })
                        }
                        if (type === "delete" && (typeAction === "invoice" || typeAction === "receipt")) {
                            getOpenTransactions(typeAction, userCredentials, setSelectConversionList)
                            dispatch({
                                type: SET_DATA_INVOICE,
                                payload: res.data.data
                            })
                        }
                        setShowDialogSave && setShowDialogSave(true)
                        setshowSpiner && setshowSpiner(false)
                        setSaveDataFailed && setSaveDataFailed(false)
                        // setServerMessageError&&setServerMessageError(true)
                        //  setMessageError&&setMessageError("נמחק בהצלחה")
                    }
                    else {
                        setSaveDataFailed && setSaveDataFailed(true)
                        setshowSpiner && setshowSpiner(false)
                        //     setServerMessageError&&setServerMessageError(true)
                        //  setMessageError&&setMessageError(res?.data?.err_description)
                    }
                }
            }
            catch (err) {
                setSaveDataFailed && setSaveDataFailed(true)
                setShowDialogFailed && setShowDialogFailed(true)
                setshowSpiner(false)
                console.log('error in delete exchangeObject', err)
            }
        })
}

export const readCompletedTransactions = (type: any, userCredentials: IUserCredentials | null,
    setArrayResult?: any) => async (dispatch: Function) => {
        currUserCredentials = userCredentials
        const transaction = {
            "transaction_type_id": type,
            "transaction_request_method": "read_completed",
            "id_branch": currUserCredentials && currUserCredentials["idBranch"],
            "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
            "id_client": currUserCredentials && currUserCredentials["idClient"],
        }
        const body = JSON.stringify(transaction);
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };
        await axios.post(basicUrl + '/transaction', body, config)
            .then(res => {
                try {
                    if (res.status === 200) {
                        if (res.data["err_code"] === 0) {
                            setArrayResult(res.data.data)
                        }
                    }
                }
                catch (err) {
                    console.log('error in create openTransactions',)
                }
            })
    }

export const saveCurrencyEntry = (type: any, typeAction: any, currencyEntry: any, userCredentials: IUserCredentials | null,
    setIdCurrencyEntry?: any, setshowSpiner?: any, setHtml?: any, setServerMessageError?: any, setMessageError?: any, t?: any) => async (dispatch: Function) => {
        currUserCredentials = userCredentials
        let tableName = typeAction === "money_in" ? "financial_assets_in" : "financial_assets_out"
        const action = {
            "transaction_type_id": typeAction,
            "transaction_request_method": type,
            "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
            "id_branch": currUserCredentials && currUserCredentials["idBranch"],
            "id_client": currUserCredentials && currUserCredentials["idClient"],
            "id_entity": currUserCredentials && currUserCredentials["idBranch"],
            "transaction_document": {
                "document_request_method": currencyEntry?.transaction_document?.document_request_method
            },
            "expenses": currencyEntry?.expenses,
            "total_amount_ils": currencyEntry?.total_amount_ils,
            "date_value": moment(currencyEntry?.date).format('YYYY-MM-DD') + " " + currencyEntry?.hour,
            "id_account": currencyEntry?.id_account,
            "id_transaction": currencyEntry?.id_transaction,
            [tableName]: typeAction === "money_in" ? currencyEntry?.financial_assets_in : currencyEntry?.financial_assets_out
        }
        const body = JSON.stringify(action);
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };
        await axios.post(basicUrl + '/transaction', body, config)
            .then(res => {
                try {
                    if (res.status === 200) {
                        if (res.data["err_code"] === 0) {
                            setIdCurrencyEntry && setIdCurrencyEntry(res.data.data.id_transaction)
                            setHtml && setHtml(res.data.data?.transaction_document?.document_html)
                            setshowSpiner && setshowSpiner(false)
                            type === "complete" && setMessageError && setMessageError(t("actionCompletedSuccessfullyMassege"))
                            type === "complete" && setServerMessageError && setServerMessageError(true)
                            // setShowDialogSave && setShowDialogSave(true)
                        }
                        else {
                            setMessageError && setMessageError(type === "create" ?
                                t("errorInAction") : res.data?.ui_message?.ui_message)
                            setServerMessageError && setServerMessageError(true)
                            setshowSpiner && setshowSpiner(false)
                        }
                    }
                }
                catch (err) {
                    console.log('error in create openTransactions',)
                }
            })
    }


export const getGatesData = (currency: string) => async (dispatch: Function) => {
    await axios({
        method: "get",
        // url: 'https://api.currencyapi.com/v2/latest?apikey=aZUBDn80F1y6YTOkaq5i2qLQP0Jj4Ki8V6zquG7v&base_currency=ILS',
        //this
        url: `https://api.currencyapi.com/v2/latest?apikey=pxXIUcFB4nHcJSp0QYmhLmmwkxcYcpypfdwRKJQG&base_currency=${currency}&datetime_end`,

    }).then(async function (res) {
        if (currency === "ILS")
            sessionStorage.setItem('gatesArr', JSON.stringify(res.data.data));
        else
            sessionStorage.setItem('gatesArrCurrency', JSON.stringify(res.data.data));
    }).catch((err) => {
        console.error(err);
    });
}
