import { useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router';
import '../../scssPages/sidebar.scss'
import ContentSideCustomers from './ContentSideCustomers';
import ContentSideDiscount from './ContentSideDiscount';
import ContentSideSettings from './ContentSideSettings'
import ContentSideConvert from './ContentSideConvert'
import ContentSideAccounting from './ContentSideAccounting';
import ContentSideSearch from './ContentSideSearch';
import SideDiscount from './SideSubDiscount';
import SideCustomers from './SideSubCustomers';
import SideSettings from './SideSubSettings';
import SideAccounting from './SideSubAccounting'
import SideSearch from './SideSubSearch';
import HomePageClient from '../../components/homePage/HomePageCostumer'
import SideConvert from './SideConvert';
import { SideMain } from './SideMain';
import ContentSideReports from './ContentSideReports';
import SideReports from './SideSubReports';
import { useSelector } from 'react-redux';
import NotPermission from './NotPermission';
import PrivateRoute from './PrivateRoute';
import SideSubGlobal from './SideSubGlobal';

const Sidebar = () => {
    const [category, setcategory] = useState('')
    const [permissionHomePage, setPermissionHomePage] = useState(false)
    const location = useLocation()
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)


    useEffect(() => {
        if (location.pathname === "/home_page") {
            setcategory("")
        }
    }, [location])
    return (
        <>
            <div className={`sidebar-${dir}`}>
              <div style={{ backgroundColor: "#1A1F71", width:`${dir==="rtl"?"60px":"77px"}`}}>
                    <SideMain setcategory={setcategory} category={category} /></div>
                {category === "customers" &&
                    <div style={{ minWidth: "178px", maxWidth: "178px" }}>
                        <ContentSideCustomers /></div>}
                {category === "setting" &&
                    <div style={{ minWidth: "178px", maxWidth: "178px" }}>
                        <ContentSideSettings /></div>
                }
                {category === "currency_exchange" &&
                    <div style={{ minWidth: "178px", maxWidth: "178px" }}>
                        <ContentSideConvert /></div>
                }
                {category === "cheques_cashing" &&
                    <div style={{ minWidth: "178px", maxWidth: "178px" }}>
                        <ContentSideDiscount /></div>
                }
                {category === "bookkeeping" &&
                    <div style={{ minWidth: "178px", maxWidth: "178px" }}>
                        <ContentSideAccounting /></div>
                }
                {category === "search" &&
                    <div style={{ minWidth: "178px", maxWidth: "178px" }}>
                        <ContentSideSearch /></div>
                }
                {category === "reports" &&
                    <div style={{ minWidth: "178px", maxWidth: "178px" }}>
                        <ContentSideReports /></div>
                }
            </div>
            {(location?.pathname !== "/home_page" && location?.pathname !== "/not_premission") &&
                <SideSubGlobal category={category} />}
            {/* {(category === "customers" && location?.pathname !== "/home_page") &&
                <SideCustomers />}
            {(category === "setting" && location?.pathname !== "/home_page") &&
                <SideSettings />}
            {(category === "exchanges" && location?.pathname !== "/home_page") &&
                <SideConvert />}
            {(category === "cheques_cashing" && location?.pathname !== "/home_page") &&
                <SideDiscount />}
            {(category === "bookkeeping" && location?.pathname !== "/home_page") &&
                <SideAccounting />}
            {(category === "search" && location?.pathname !== "/home_page") &&
                <SideSearch />}
            {(category === "reports" && location?.pathname !== "/home_page") &&
                <SideReports />} */}
            {location?.pathname === "/home_page" &&
                <PrivateRoute exact path="/home_page" name="home_page"
                    setPermissionHomePage={setPermissionHomePage}
                    permissionHomePage={permissionHomePage} component={HomePageClient} />
            }
            {location?.pathname === "/not_premission" &&
                <Route exact path="/not_premission" component={NotPermission} />
            }
        </>
    );
}
export default Sidebar;