
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react'
import { ISpinnerStyles, PrimaryButton, Spinner } from '@fluentui/react';

import { IUserCredentials } from './customerDetails/CustomerDetails';
import CustemTable from '../shared/components/tabels/tableList';
import { CustomFilesDocumentsDialog } from '../shared/components/dialog/Dialog';
import { updateDocument } from '../store/actions/documentAction';
import { DialogMessages } from '../shared/components/dialog/DialogMessages';

const spinnerStyles: Partial<ISpinnerStyles> = {
    root: {
        position: 'fixed',
        zIndex: '999999',
        fontWeight: 'bold',
    },
    circle: {
        color: '#FFB400 ',
        height: '100px',
        width: '100px',
    },
}

const DocumentsList = (props: any) => {
    const { idOtherEntity, setRelatedFactors/*setDleteDocument*/,
        items, activeDocumentsList, actionType } = props
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [t] = useTranslation();
    const dispatch = useDispatch()
    const customerReducer = useSelector(({ customerReducer }: any) => customerReducer)
    const datacConversion = useSelector(({ conversionReducer }: any) => conversionReducer.dataConversion)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [addFile, setaddFile] = useState(false)
    const [showDialogSave, setShowDialogSave] = useState(false)
    const [showSpiner, setShowSpiner] = useState(false)
    const [showDeleteDocuments, setShowDeleteDocuments] = useState(false)
    const [deleteDocument, setDleteDocument] = useState<any>("")
    const [filesElement, setFilesElement] = useState<any>([]);

    const columns = [
        { key: '2', name: t('name'), fieldName: "media_name", entity_name: "name", minWidth: 150, maxWidth: 150, },
        { key: '3', name: t('type'), fieldName: "type", entity_name: "type", minWidth: 70, maxWidth: 70 },
        { key: '4', name: t('status'), fieldName: "status", entity_name: "status", minWidth: 70, maxWidth: 70 },
        { key: '5', name: t('dateAdded'), fieldName: "date_created", minWidth: 100, maxWidth: 100 },
        { key: '6', name: t('expirationDate'), fieldName: "media_exp_date", minWidth: 100, maxWidth: 100 },
        { key: '7', name: t('note'), fieldName: "media_details", minWidth: 100, maxWidth: 100 },
        { key: '8', name: t('operations'), fieldName: "operationsDocuments", minWidth: 90, maxWidth: 90 },
    ];

    useEffect(() => {
        setUserCredentials({
            "idInitiator": authReducer.data.user.id_entity,
            "idClient": authReducer.data.user.id_client,
            "idBranch": authReducer.data.branch.id_entity,
            "idClientData": authReducer.data.client.id_client
        })
    }, [authReducer]);

    useEffect(() => {
        if (deleteDocument) {
            let documents = [...items]
            let index = documents.findIndex((doc: any) => doc?.id_media === deleteDocument?.id_media)
            if (documents[index].media_status_id !== "99" && documents[index].id_media === deleteDocument?.id_media) {
                documents[index].media_status_id = "99"
                documents[index].status = "מחוק"
                dispatch(updateDocument(documents, userCredentials, setRelatedFactors ? datacConversion?.id_entity : customerReducer?.dataCustomer?.id_entity, setShowDialogSave, "", setShowSpiner, idOtherEntity, actionType))
            }
        }
        // eslint-disable-next-line
    }, [deleteDocument])

    useEffect(() => {
        const updatedStatusItems = activeDocumentsList.map((f: any) => f.type === 'חובה' && f?.id_media === '' ? { ...f, status: 'לא פעיל' } : f)
        setFilesElement(updatedStatusItems);
    }, [activeDocumentsList, customerReducer]);

    return (
        <>
            {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}

            {showDialogSave && <DialogMessages setansDelete={setShowDialogSave}
                setshowModalDelete={setShowDialogSave} dialogType={"create"} />}

            <div className={actionType === "READ_CUSTOMER" ? `content-div-${dir}` : ""} >
                <div className="atach-form" style={{ width: "880px" }} >
                    <p className="title-text" style={{ width: "880px" }}>{t('customerDocuments')}</p>
                    <div className="title-text addFile" style={{ cursor: "pointer" }} onClick={() => setaddFile(true)} ><i className="fal fa-plus"></i></div>
                    {addFile && <CustomFilesDocumentsDialog actionType={actionType} setShowSpiner={setShowSpiner} setShowDialog={setaddFile} setRelatedFactors={setRelatedFactors} setShowDialogUpdate={setShowDialogSave} documents={items} userCredentials={userCredentials} idOtherEntity={idOtherEntity} idEntity={customerReducer?.dataCustomer?.id_entity} isNew={true} />}
                </div>
                <hr style={{width:"880px"}} className='hr'></hr>
            </div>

            <div className={!idOtherEntity ? `doc-list-div-${dir}` : `doc-list-no-div-${dir}`} >
                {!showDeleteDocuments ?
                    activeDocumentsList?.length > 0 && <CustemTable actionType={actionType} setDleteDocument={setDleteDocument}
                        setRelatedFactors={setRelatedFactors} setshowSpiner={setShowSpiner} tableName="documentsList" columns={columns} allItems={filesElement}
                        renderItemColumn rederRow={"operationsDocuments"} idOtherEntity={idOtherEntity} addCustem={true} isFooter={false} /> :
                    customerReducer?.dataCustomer?.documentation?.length > 0 &&
                    <CustemTable actionType={actionType} setDleteDocument={setDleteDocument} setRelatedFactors={setRelatedFactors}
                        setshowSpiner={setShowSpiner} tableName="documentsList" columns={columns}
                        allItems={filesElement.concat(customerReducer?.dataCustomer?.documentation?.filter((doc: any) => doc?.media_status_id === "99"))}
                        renderItemColumn rederRow={"operationsDocuments"} idOtherEntity={idOtherEntity} addCustem={true} isFooter={false} />}
                {customerReducer?.dataCustomer?.documentation?.length > 0 && activeDocumentsList?.length > 0 && !idOtherEntity &&
                    <PrimaryButton className='button' style={{ width: "233px" }} allowDisabledFocus checked={false}
                        onClick={() => setShowDeleteDocuments(!showDeleteDocuments)} text={showDeleteDocuments ? t("activeDocuments") : t("deletedDocuments")} />}
            </div>
        </>
    )
}
export default DocumentsList;