
import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import dataReducer from './dataReducer'
import customerReducer from './customerReducer';
import conversionReducer from './conversionReducer'
import accountReducer from './accountReducer';
import completeDocReducer from './completeDocReducer';
import discountReducer from './discountReducer';
import aboardTransferReducer from './aboardTransferReducer'
import reportsReducer from './reportsReducer';
export default combineReducers({
  error: errorReducer,
  authReducer: authReducer,
  dataReducer:dataReducer,
  customerReducer:customerReducer,
  conversionReducer:conversionReducer,
  discountReducer:discountReducer,
  accountReducer:accountReducer,
  completeDocReducer:completeDocReducer,
  aboardTransferReducer:aboardTransferReducer,
  reportsReducer:reportsReducer
});
