import { IconButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerLogout } from '../../store/actions/authActions'

export const Logout = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)

  const  handleLogout = () => {
    // logout1(dispatch)
    CustomerLogout(dispatch)
    window.sessionStorage.clear();
    window.location.replace("/");
    // logout && logout()
  }
  return (
    <>
      <p className="logout">{t('logout')}</p>
      <IconButton
        onClick={handleLogout}
        // iconProps={{ iconName: 'NavigateBack' }}
        className={`${dir==="rtl"? "iconExit fal fa-arrow-circle-left":
        "iconExit fal fa-arrow-circle-right"}`}
        styles={{
          icon: { color: 'white', fontSize: 24 },
          root: {
            selectors: {
              ':hover .ms-Button-icon': {
                color: '#FFB400'
              },
              ':active .ms-Button-icon': {
                color: 'yellow'
              }
            },
          },
          rootHovered: { backgroundColor: '1A1F71' },
          rootPressed: { backgroundColor: '1A1F71' }
        }}
      />
      {/* <PrimaryButton onClick={logout}
         text={'Logout'}
        /> */}
    </>
  );
};

export default Logout