import { PrimaryButton } from "@fluentui/react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Pagination } from "@pnp/spfx-controls-react/lib/Pagination";
import { useBoolean } from '@fluentui/react-hooks';

import { RESET_CUSTOMER_OBJECT } from '../../store/actions/types'
import Subtitle from "../../shared/components/Subtitle";
import CustemTable from "../../shared/components/tabels/tableList";
import { basicUrl } from "../../shared/config";
import Title from "../../shared/components/Title";
import { Icons } from "../../modelsType/Icon";
import DialogSearch from "../global/DialogSearch";
import { IUserCredentials } from "../customerDetails/CustomerDetails";

import "./customerSearch.scss"
import "../../scssPages/pagination.scss"
import { checkPermissionLevelOfPage } from "../../shared/components/CustomHooks/GlobalFunctions";

export interface Iitem {
  key: Number;
  name: string;
  phone: string;
  address: string
}
export interface IitemList {
  item: Iitem[]
}

const CustomerSearch = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [curentPage, setCurentPage] = useState(1);
  const [customerSearch, setCutomerSearch] = useState("");
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const [item, setItem] = useState<any>([])
  const authReducer = useSelector(({ authReducer }: any) => authReducer)
  const clientData = useSelector((state: any) => state.authReducer.data.user)
  const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
  const [/*showSpiner*/, setshowSpiner] = useState(false)
  const client = useSelector((state: any) => state.authReducer.data.client.id_entity)
  const [numResults, setnumResults] = useState(-1)
  const [dataResult, setDataResult] = useState<any>({})
  const [firstTime, setFirstTime] = useState(true)
  const [isClient, setisClient] = useState(false)
  const [loading, { setTrue: displayLoading, setFalse: hideLoading }] = useBoolean(true);
  const location = useLocation()
  const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)

  const columns = [
    { key: '1', name: t('name'), fieldName: "name", entity_name: "name", minWidth: 180, maxWidth: 180 },
    { key: '2', name: t('idNumber'), fieldName: "idNumber", minWidth: 150, maxWidth: 150 },
    { key: '3', name: t('address'), fieldName: "address", minWidth: 240, maxWidth: 240 },
    { key: '4', name: t('phone'), fieldName: "phone", minWidth: 130, maxWidth: 130 },
    { key: '5', name: '', fieldName: "link", minWidth: 100, maxWidth: 100 },
  ];

  let objectItem: any[] = [];

  useEffect(() => {
    if (authReducer) {
      setUserCredentials({
        "idInitiator": authReducer.data.user.id_entity,
        "idClient": authReducer.data.user.id_client,
        "idBranch": authReducer.data.branch.id_entity,
        "idClientData": authReducer.data.client.id_client
      })
    }
  }, [authReducer])


  //load and get data from search
  const getData = async () => {
    displayLoading()
    if (client === '00000000-0000-0000-0000-000000000001')
      setisClient(true)
    const search = {
      "id_initiator": clientData.id_entity,
      "id_client": clientData.id_client,
      "search_fields": [
        "name",
        "address",
        "thelephone"
      ],

      "search_type": "like",
      "search_entity_type_id": isClient === true ? ["client"] : ["customer"],

      "order_by": "DESC",
      "page_size": 10,
      "page_index": curentPage,
      "search_value": customerSearch

    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`

      }
    };

    const res = await axios.post(basicUrl + "/entity/search", search, config)
    const objectData = res.data.search_results;
    if (firstTime === true) {
      setFirstTime(false)
      setnumResults(-1)
    }
    else
      objectData?.map((obj: any, index: number) => {
        const phoneObject = obj.telephones
        const resultPhone = phoneObject?.find(({ is_default }: any) => is_default === true);
        let addressObject = obj?.addresses
        if (addressObject !== undefined) {
          const resultAddress = addressObject.find(({ is_default }: any) => is_default === true);
          if (resultAddress) {
            let address = resultAddress?.address_street ? resultAddress?.address_street : ""
            let numStreet = resultAddress?.address_number ? resultAddress?.address_number : ""
            let cityAddress = resultAddress?.address_city ? resultAddress?.address_city : ""
            addressObject = address + " " + numStreet + " " + cityAddress;
          }
          else
            addressObject = t('DoesNotExist');

        }
        else
          addressObject = t('DoesNotExist');
        objectItem?.push({ key: ++index, name: obj.entity_name, phone: resultPhone ? resultPhone?.telephone_number : t('DoesNotExist'), address: addressObject, idEntity: obj.id_entity, idNumber: obj?.ID_number })
      });
    setItem(objectItem);
    setDataResult(res.data)
  }

  useEffect(() => {
    getData();
  }, [curentPage, clientData]);

  const _onPageUpdate = async (pageno?: number) => {
    var currentPge = (pageno) ? pageno : curentPage;
    setCurentPage(currentPge);
    setnumResults(dataResult?.records_found)
  };

  useEffect(() => {
    if (dataResult?.search_results?.length > 0) {
      _onPageUpdate();
      hideLoading();
    }
  }, [dataResult?.search_results]);

  const createButton = () => {
    dispatch({
      type: RESET_CUSTOMER_OBJECT,
    })
    const location = {
      pathname: '/customers/customer_details',
      state: { create: true }
    }
    history.push(location)
  }

  return (
    <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
    ? 'page-read' : 'page-read-only'}`}>

      <div className={`sub-header-${dir} fixed-${dir}`} style={{ height: "60px" }}>
        <Title
          title={t("customers")} />
        <div className="divider"></div><div className="divider"></div>
      </div>
      <br></br><br></br>
      <Subtitle title={t('customerSearch')} />

      <div className={`content-div-${dir}`} >
        <div className="content-search" style={{ marginTop: "2%" }} >
          {/* <DialogSearch setshowSpiner={setshowSpiner} isSearchInsiders={false} type={"customerSearch"} 
          setTextSearch={setCutomerSearch} /> */}
                  <DialogSearch userCredentials={userCredentials} setshowSpiner={setshowSpiner} isSearchInsiders={false} type={"customerSearch"} setTextSearch={setCutomerSearch} />
          <PrimaryButton className={`bottun-${dir}`} onClick={getData} text={t("searchCustomer")} />
          <PrimaryButton className={`bottun-${dir}`} allowDisabledFocus checked={false} onClick={createButton} name="create" text={t('createCustomer')} iconProps={Icons.addFriend} />
        </div>

        {numResults !== (-1) ? numResults > 0 ? <p style={{ marginBottom: '0' }}>{t('found')} {numResults} {t('results')}</p> : <p style={{ marginBottom: '0' }}>{t('searchCustomerNotFound')}</p> : ""}
        {item?.length > 0 ? <div style={{ width: "930px" }}> <CustemTable columns={columns} allItems={item} renderItemColumn rederRow={"link"} isFooter={false} /></div> : ""}

        {item?.length > 0 && !loading &&
          <div className="paginationContainer" style={{ maxWidth: '930px', display: 'flex', justifyContent: "center", margin: "20px 0px", direction: "ltr" }}>
            <div className="searchWp__paginationContainer__pagination">
              <Pagination
                currentPage={curentPage}
                totalPages={Math.ceil(dataResult?.records_found / dataResult?.page_size)}
                onChange={(page) => _onPageUpdate(page)}
                limiter={3}
              /></div>
          </div>}
      </div>
    </div >
  );
}
export default CustomerSearch;



