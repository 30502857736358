import { PrimaryButton, Spinner, ISpinnerStyles } from '@fluentui/react';
import moment from 'moment';
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { CustomerDate } from '../components/../shared/components/CustomHooks/concatTextFields';
import HeaderUtils from '../components/../shared/components/headerUtils/HeaderUtils'
import Subtitle from '../components/../shared/components/Subtitle'
import { CustomTextField } from '../components/../shared/components/TextField';
import FinancialAssets from '../components/actions/convertionsAction/CurrencyEntry/FinancialAssets';
import { IUserCredentials } from '../components/customerDetails/CustomerDetails';
import { DialogMessages } from '../shared/components/dialog/DialogMessages';
import { receiveLogOrder } from '../store/actions/accountAction';
import { useTranslation } from 'react-i18next';
import { checkPermissionLevelOfPage } from '../shared/components/CustomHooks/GlobalFunctions';
import { useLocation } from 'react-router';
const filename = './usScanCheck.html';


const initialState = {
    date_value: moment().format("YYYY-MM-DD"), total_amount_ils: 0, details: "", reference_1: "", reference_2: 0
    , id_transaction: null, transaction_document: { document_request_method: null }, orders: [],
    credit: [{
        cr_dr: "credit", currency_code: "ILS", financial_asset_code: 1, accountName: "",
        amount: 0, exchange_rate_ils: 1, amount_ils: 0, isDouble: false,
        row_number: 1, contra_id_account: null, account: null,
    }],
    debit: [{
        cr_dr: "debit", currency_code: "ILS", financial_asset_code: 1, accountName: "",
        amount: 0, exchange_rate_ils: 1, amount_ils: 0, isDouble: false,
        row_number: 1, contra_id_account: null, account: null,
    }]
};
export default function LogOrder() {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)

    const [isValidDateCreate, setIsValidDateCreate] = useState(true)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [logOrder, setLogOrder] = useState<any>({ ...initialState })
    const [isNegativeBalance, setIsNegativeBalance] = useState(false)
    const [emptyAmount, setEmptyAmount] = useState(false)
    const [emptyContraAccount, setEmptyContraAccount] = useState(false)
    const [isDoubleId, setIsDoubleId] = useState(false)
    const [messageError, setMessageError] = useState('')
    const [serverMessageError, setServerMessageError] = useState(false)
    const [titleMessageError, setTitleMessageError] = useState('')
    const [showSpiner, setShowSpiner] = useState(false)
    const [sumCr, setSumCr] = useState(0)
    const [sumDr, setSumDr] = useState(0)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    const [isValueDate, setIsValueDate] = useState(false)
    let negativeBalance = false, doubleId = false, emptyContra = false
    const dispatch = useDispatch()
    const spinnerStyles: Partial<ISpinnerStyles> = {
        root: {
            position: 'fixed',
            zIndex: '999999',
            fontWeight: 'bold',
        },
        circle: {
            color: '#FFB400 ',
            height: '100px',
            width: '100px',
        },
    }

    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer?.data?.user?.id_entity,
                "idClient": authReducer?.data?.user?.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer?.data?.client?.id_client
            })
        }
    }, [authReducer]);

    const changeLogOrder = async (key: any, value: any) => {
        setLogOrder({ ...logOrder, [key]: value })
    }


    const pushToOrder = async (element: any) => {
        logOrder.orders.push({
            "cr_dr": element?.cr_dr,
            "id_account": element?.contra_id_account,
            "currency_code": element?.currency_code,
            "amount": element?.amount,
            "exchange_rate_ils": element?.exchange_rate_ils,
            "amount_ils": element?.amount_ils,
            "row_number": element?.row_number
        })
    }

    const resetPage = () => {
        setLogOrder(initialState)
        setEmptyContraAccount(false)
        setEmptyAmount(false)
        setIsNegativeBalance(false)
        setIsValueDate(false)
        setSumCr(0)
        setSumDr(0)
    }

    const checkRequiredFelds = async (tableName: any) => {
        logOrder[tableName]?.forEach(async (element: any) => {
            if (element?.amount <= 0) {
                negativeBalance = true
                setEmptyAmount(true)
                setIsNegativeBalance(true)
            }
            if (element?.isDouble) {
                doubleId = true
                setIsDoubleId(true)
            }
            if (!element.contra_id_account && element.financial_asset_code !== "18") {
                setEmptyContraAccount(true)
                emptyContra = true
            }
            pushToOrder(element)
        });
    }

    const absorption = async () => {
        logOrder.orders = [];
        checkRequiredFelds("credit")
        checkRequiredFelds("debit")
        if (logOrder.date_value === "")
            setIsValueDate(true)

        if (sumCr === sumDr && sumCr > 0 && !negativeBalance && !doubleId && logOrder.date_value) {
            await dispatch(receiveLogOrder(logOrder, "complete", userCredentials,
                setServerMessageError, setMessageError, setTitleMessageError, setShowSpiner, t))
            resetPage()
        }
        else {
            if (doubleId)
                setMessageError(t("notPossibleToChooseTheSameAccountMassege"))
            else
                setMessageError(t("performBalancedActionsMassege"))
            setServerMessageError(true)
            setTitleMessageError(t('note'))
        }
    }

    return (
        <div className={`${checkPermissionLevelOfPage(credentials, location) === "RW"
            ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={t("bookkeeping")} subTitle={"false"} disabledSave={true} createBtn={false}
                saveFunc={""} editFunc={false} cancelFunc={""} />
            <br></br><br></br>
            {serverMessageError && <DialogMessages setansDelete={setServerMessageError} subText={messageError} title={titleMessageError} setshowModalDelete={setServerMessageError} dialogType={"dialogMessage"} />}
            {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
            <Subtitle title={t("logOrder")} />
            <div className={`content-div-${dir}`} style={{ height: "100vh" }} >
                <p className="title-text" >{t("actionDetails")}</p>
                <hr className="hr" style={{ width: "720px" }} ></hr>
                <div style={{ display: 'flex', width: "720px" }}>
                    <div style={{ width: "240px" }}>
                        <CustomerDate requiredField={true} isDefaultDate={true} isValidDate={isValidDateCreate} setIsValidDate={setIsValidDateCreate}
                            showRequiredFildes={isValueDate && !logOrder?.date_value ? true : false} entity={logOrder} required setEntity={setLogOrder} readOnly={false} idDate="date_value" label={t("date")} source={""} />
                    </div>
                    <div className={`margin-between-input-${dir}`} style={{ width: "240px" }}>
                        <CustomTextField requiredField={false} readOnly={false} value={logOrder?.reference_1}
                            onChange={changeLogOrder} id={"reference_1"} label={t("reference1")} />
                    </div>
                    <div className={`margin-between-input-${dir}`} style={{ width: "240px" }}>
                        <CustomTextField requiredField={false} readOnly={false} value={logOrder?.reference_2}
                            onChange={changeLogOrder} id={"reference_2"} label={t("reference2")} />
                    </div>
                </div>

                <div style={{ width: '720px' }}>
                    <CustomTextField requiredField={false} readOnly={false} value={logOrder?.details} onChange={changeLogOrder}
                        id={"details"} label={t("details")} />
                </div>
                <div style={{ width: "80%" }}  >
                    <div>
                        <FinancialAssets tableName="debit" sourcePage="logOrder" action={logOrder} isDoubleId={isDoubleId}
                            emptyOutAmount={emptyAmount} userCredentials={userCredentials} setAction={setLogOrder} readOnly={false}
                            sum={sumDr} setSum={setSumDr} isNegativeBalance={isNegativeBalance} emptyOutContraAccount={emptyContraAccount} />
                        <div className={`calc-discount-${dir}`} style={{ width: "720px" }}>
                            {sumDr !== 0 && <div className={`title-calc-receipt-${dir}`} >
                                {t('total')}:
                            </div>}
                            {sumDr !== 0 && <div className="total-calc-receipt-out">
                                {sumDr?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {t("ILS")}
                            </div>}
                            {sumDr === 0 && <div className="title-calc-receipt">
                                {t('total')}:&nbsp;</div>}
                            {sumDr === 0 && <div className="total-calc-receipt-out">
                                0.00   {t('ILS')}</div>}
                        </div>
                    </div>
                    <div>
                        <FinancialAssets tableName="credit" sourcePage="logOrder" action={logOrder} isDoubleId={isDoubleId}
                            emptyOutAmount={emptyAmount} userCredentials={userCredentials} setAction={setLogOrder} readOnly={false}
                            sum={sumCr} setSum={setSumCr} isNegativeBalance={isNegativeBalance} emptyOutContraAccount={emptyContraAccount} />
                        <div className={`calc-discount-${dir}`} style={{ width: "720px" }}>
                            {sumCr !== 0 && <div className={`title-calc-receipt-${dir}`} >
                                {t('total')}:
                            </div>}
                            {sumCr !== 0 && <div style={{
                                width: "140px",
                                textAlign: "start", justifyContent: "start", display: "flex"
                            }} >
                                {sumCr?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {t("ILS")}
                            </div>}
                            {sumCr === 0 && <div className={`title-calc-receipt-${dir}`}>
                                {t('total')}:</div>}
                            {sumCr === 0 && <div style={{
                                width: "140px",
                                textAlign: "start", justifyContent: "start", display: "flex"
                            }}>
                                0.00   {t('ILS')}</div>}
                        </div>
                    </div>
                </div>
                <div style={{ width: "720px", display: "flex", justifyContent: "end" }} >
                    <PrimaryButton className={`button-log-order-${dir}`}
                        text={t("reception")} onClick={() => absorption()} />
                </div>


                {/* <iframe width="100%" height="100%" src={`${process.env.PUBLIC_URL}/RangerImageFromBase64.html`}/> */}
                {/* <iframe width="100%" height="100%" src={`${process.env.PUBLIC_URL}/scan.html`} /> */}

            </div>
        </div>
    )
}
