import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { cloneDeep, isEqual } from 'lodash';

import { DialogMessages } from '../../../../shared/components/dialog/DialogMessages';
import { PrintHtml } from '../../../../shared/components/CustomHooks/PrintHtml'
import HeaderUtils from '../../../../shared/components/headerUtils/HeaderUtils';
import Subtitle from '../../../../shared/components/Subtitle';
import EntityDetails from '../../../global/EntityDetails';
import { IUserCredentials } from '../../../customerDetails/CustomerDetails'
import DiscountFinancialAssetsIn from './DiscountFinancialAssetsIn'
import FinancialAssets from '../../convertionsAction/CurrencyEntry/FinancialAssets'
import { CustomPrompt } from '../../../../shared/components/CustomHooks/CustomPrompt'
import ListRelatedFactors from '../../../relatedFactors/ListRelatedFactors';
import EditableRelatedFactors from '../../../relatedFactors/EditableRelatedFactors';
import { createTransaction, deleteTransaction, getGatesData } from '../../../../store/actions/convertionActions'
import { CustomToggle } from "../../../../shared/components/Toggle";
import { TextFeildNote } from '../../../../shared/components/Note';
import { calcChequeDetails, checkAge, checkChequeDays, checkPermissionLevelOfPage, checkRequiredFields, customerInfoInObject, isCorporationFunc } from '../../../../shared/components/CustomHooks/GlobalFunctions'
import { Icons } from '../../../../modelsType/Icon';
import { CHANGED_LOCATION, RESET_CUSTOMER_OBJECT, RESET_DATA_CONVERSION } from '../../../../store/actions/types';
import moment from 'moment';
import { readCustomerId } from '../../../../store/actions/customerActioin';


const initialiseState = {
    id_entity: "", documentation: [], note: "", related_entities: [], transaction_type_id: 1,
    total_amount_ils: 0, total_expenses: 0, amount_round: 0, total_net_amount: 0,
    id_transaction: null, id_account: "", is_unusual_reporting: false,
    transaction_document: { document_request_method: null },
    financial_assets_in: [{
        row_number: 1,
        currency_code: "",
        financial_asset_code: "",
        amount: 0,
        exchange_rate_ils: 1,
        amount_ils: 0,
        fee: 0,
        contra_id_account: null,
        financial_asset_details: {},
    }],
    financial_assets_out: [{
        currency_code: "", financial_asset_code: "",
        financial_asset_details: {}, amount: 0, exchange_rate_ils: 1, amount_ils: 0,
        contra_id_account: null, row_number: 1, fee: 0
    }],
}

export default function CheckDiscounts() {
    const [t] = useTranslation()
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    let componentRef = useRef(null);
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const saveDraft = useSelector(({ conversionReducer }: any) => conversionReducer.saveDraftChanges)
    const dataConversion = useSelector(({ conversionReducer }: any) => conversionReducer.dataConversion)
    const dataNewTransaction = useSelector(({ conversionReducer }: any) => conversionReducer.dataNewTransaction)
    const [emptyInAmount, setEmptyInAmount] = useState(false)
    const [docAction, setDocAction] = useState(false)
    const [addDoc, setAddDoc] = useState("")
    const [insiderAction, setInsiderAction] = useState(false)
    const [addInsider, setAddInsider] = useState("")
    const [emptyOutAmount, setEmptyOutAmount] = useState(false)
    const [sumIn, setSumIn] = useState(0)
    const [sumOut, setSumOut] = useState(0)
    const [sumFee, setSumFee] = useState(0)
    const { state } = useLocation<any>()
    const history = useHistory()
    const [beforeDelete, setbeforeDelete] = useState(false)
    const [ifDelete, setifDelete] = useState(false)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const contraAccounts = useSelector(({ authReducer }: any) => authReducer?.data?.user.contra_accounts)
    const [contraCashAccounts, setcontraCashAccounts] = useState<any>([])
    const location = useLocation()
    //dont need it-check
    const [/*discountsCustomerId*/, setDiscountsCustomerId] = useState<any>()
    const [selectedDraft, setSelectedDraft] = useState("")
    const [searchCustomer, setSearchCustomer] = useState<any>()
    const [ifChanges, setIfChanges] = useState(false)
    const [/*showSpiner*/, setshowSpiner] = useState(false)
    const [relatedFactors, setRelatedFactors] = useState<any>()
    const [itemsInsiders, setItemsInsiders] = useState<Object[]>([])
    const [selectConversionList, setSelectConversionList] = useState<any>([])
    const [ifChangeRelatedType, setIfChangeRelatedType] = useState(false)
    const [emptyOutContraAccount, setEmptyOutContraAccount] = useState(false)
    const [emptyInContraAccount, setEmptyInContraAccount] = useState(false)
    const [emptyRequiredFields, setEmptyRequiredFields] = useState(false)
    const [isNegativeBalance, setIsNegativeBalance] = useState(false)
    const [showDialogFinishFail, setshowDialogFinishFail] = useState(false)
    const [showDialogFailed, setShowDialogFailed] = useState(false)
    const [messageError, setMessageError] = useState('')
    const [serverMessageError, setServerMessageError] = useState<any>('')
    const [titleMessageError, setTitleMessageError] = useState('')
    const [showDialogSave, setShowDialogSave] = useState(false)
    const [listInsiders, setListInsiders] = useState([{}])
    const [addFactorDialog, setaddFactorDialog] = useState(false)
    const [readOnly, setReadOnly] = useState<any>(true)
    const [discounts, setDiscounts] = useState(initialiseState)
    const [showDialogChangeCashingDate, setShowDialogChangeCashingDate] = useState(false)
    const [changeChashingDate, setChangeChashingDate] = useState("")
    const isGet = sessionStorage.getItem("isGetRates")

    async function funcGetGates() {
        await dispatch(getGatesData("ILS"))
    }

    useEffect(() => {
        async function dataNewTransactionFunc() {
            if (dataNewTransaction?.isNew === true) {
                await resetPageData()
                await dispatch(readCustomerId(dataNewTransaction?.idCustomer, userCredentials, null, "", "", false, "", setReadOnly, setSearchCustomer, ""))
            }
        }
        dataNewTransactionFunc()
    }, [dataNewTransaction])

    useEffect(() => {
        let isGet = sessionStorage.getItem("isGetRates")
        if (isGet !== "false") {
            sessionStorage.setItem('isGetRates', "false");
            funcGetGates()
        }
    }, [isGet])

    useEffect(() => {
        if (changeChashingDate === "save") {
            setChangeChashingDate("")
            setDraftFinancialAssets("financial_assets_in", true)
        }
        if (changeChashingDate === "don't save") {
            setChangeChashingDate("")
            setShowDialogChangeCashingDate(false)
            setDraftFinancialAssets("financial_assets_in")
        }
    }, [changeChashingDate])

    useEffect(() => {
        async function addInsiderFunc() {
            if (addInsider === "save") {
                setAddInsider("")
                await dispatch(createTransaction("update", "cheque_cashing", dataConversion.entity_name,
                    discounts, userCredentials, discounts.id_entity,))
                dispatch(readCustomerId(discounts?.id_entity, userCredentials,
                    "", "", "", false));

                const location = {
                    pathname: '/customers/customer_related_entities',
                    state: ({ category: "customers" })
                }
                dispatch({
                    type: CHANGED_LOCATION,
                    payload: location
                })
            }
            if (addInsider === "don't save") {
                setAddInsider("")
            }
        }
        addInsiderFunc()
    }, [addInsider])




    useEffect(() => {
        async function addDocFunc() {
            if (addDoc === "save") {
                setAddDoc("")
                await dispatch(createTransaction("update", "cheque_cashing", dataConversion.entity_name,
                    discounts, userCredentials, discounts.id_entity,))
                await dispatch(readCustomerId(discounts?.id_entity, userCredentials,
                    "", "", "", false));
                const location = {
                    pathname: '/customers/customer_documentation',
                    state: ({ category: "customers" })
                }
                dispatch({
                    type: CHANGED_LOCATION,
                    payload: location
                })
            }
            if (addDoc === "don't save") {
                setAddDoc("")
            }
        }
        addDocFunc()
    }, [addDoc])

    const draftDiscountsDatafunc = async () => {
        await setDraftDiscountsData()
    }

    useEffect(() => {
        if (dataConversion) {
            const { id_transaction } = dataConversion
            if (discounts.id_transaction === null && dataConversion.transaction_request_method === "create") {
                setSelectedDraft(id_transaction + ',' + discounts?.id_entity)
                setDiscounts({ ...discounts, "id_transaction": id_transaction })
            }
            if (selectedDraft !== "" || state === "showCompleted") {
                draftDiscountsDatafunc()
            }

        }
    }, [dataConversion])

    useEffect(() => {
        if (dataConversion && state?.source === "refresh") {
            history.location.state = {}
            setSelectedDraft(dataConversion?.id_transaction + ',' + dataConversion?.id_entity)
            draftDiscountsDatafunc()
        }
    }, [dataConversion, state])

    useEffect(() => {
        state === "showCompleted" && setReadOnly(true)
    }, [readOnly])

    useEffect(() => {
        if (ifChangeRelatedType) {
            dispatch(createTransaction("update", "cheque_cashing", searchCustomer.entityName,
                discounts, userCredentials, discounts.id_entity))
            setIfChangeRelatedType(false)
        }
    }, [ifChangeRelatedType])

    useEffect(() => {
        if (relatedFactors) {
            setshowSpiner(false)
            if (relatedFactors?.key === "deleteRelatedType")
                deleteRelatedEntity(relatedFactors)
            else {
                if (relatedFactors?.key === "relatedTypeId") {
                    setIfChangeRelatedType(true)
                    let arrRelated: any = [...discounts.related_entities]
                    let index = arrRelated.findIndex((item: any) => item.id_entity === relatedFactors.id)
                    if (index !== -1) arrRelated[index].service_requisition_type_id = relatedFactors.typeId
                    setDiscounts({ ...discounts, "related_entities": arrRelated })
                }
                else {
                    let arrInsiders: any[] = [...itemsInsiders]
                    let index = arrInsiders.findIndex((item: any) => (item?.key === relatedFactors?.key && item?.key !== undefined) || (item?.idEntity && item?.idEntity === relatedFactors?.idEntity) || (item?.id_entity && item?.id_entity === relatedFactors?.key) || (item?.id_entity && item?.id_entity === relatedFactors?.idEntity))

                    if (index === -1) {
                        arrInsiders.push(relatedFactors)
                        setItemsInsiders(arrInsiders)
                        addRelatedEntity("", relatedFactors)
                    }
                    else {
                        arrInsiders[index] = relatedFactors
                        setItemsInsiders(arrInsiders)
                    }
                }
            }
        }
    }, [relatedFactors])

    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])

    ////check the print in trello
    // useEffect(() => {
    //     if (dataConversion?.status_id === "completed" && dataConversion?.transaction_document
    //  && dataConversion?.transaction_type_id === "cheque_cashing" && state !== "showCompleted" && state) {
    //         document?.getElementById("print")?.click();
    //     }
    // }, [dataConversion?.status_id])

    useEffect(() => {
        if (dataConversion?.transaction_document?.document_html && selectedDraft) {
            document?.getElementById("print")?.click();
            let convert = cloneDeep(discounts)
            let printDocument: any = convert["transaction_document"]
            printDocument = { document_request_method: null }
            setDiscounts({ ...discounts, "transaction_document": printDocument })
        }
    }, [dataConversion?.transaction_document])

    useEffect(() => {
        if (dataConversion?.status_id === "deleted" || (dataConversion?.status_id === "completed" && state !== "showCompleted")) {
            resetPageData()
        }
    }, [dataConversion?.status_id])

    useEffect(() => {
        if (contraAccounts) {
            let arrCash: any[] = [];
            for (let i = 0; i < contraAccounts.length; i++) {
                if (contraAccounts[i].contra_account_group_id === "cash") {
                    arrCash.push({ key: contraAccounts[i].id_account, text: contraAccounts[i].account_name + ' ' + contraAccounts[i].account_number, groupId: contraAccounts[i].contra_account_group_id, accountNumber: contraAccounts[i].account_number, is_default: contraAccounts[i].is_default ? true : false })
                }
            }
            setcontraCashAccounts(arrCash)
        }
    }, [contraAccounts])

    useEffect(() => {
        if (selectedDraft && dataConversion?.status_id === "draft") {
            if (searchCustomer?.isPopupMessage) {
                setServerMessageError(true)
                setMessageError(searchCustomer?.note)
                setTitleMessageError(t('note'))
            }
        }
    }, [selectedDraft])


    const addRelatedEntity = (key: string, value: any) => {
        setIfChanges(true)
        let arrRelated: any = [...discounts.related_entities]
        let relatedEntity = { id_entity: "", service_requisition_type_id: "" }
        if (value?.key) {
            relatedEntity = {
                id_entity: value?.key,
                service_requisition_type_id: value?.entitySubTypeId
            }
            arrRelated.push(relatedEntity)
        }
        setReadOnly(false)
        setDiscounts({ ...discounts, "related_entities": arrRelated })
    }

    const deleteRelatedEntity = (reletedType: any) => {
        setIfChanges(true)
        let arrInsiders: any[] = [...itemsInsiders]
        let arrRelated: any = [...discounts.related_entities]
        arrRelated = arrRelated.filter((item: any) => item?.id_entity !== reletedType?.item?.key)
        arrInsiders = arrInsiders.filter((item: any) => (item?.key !== reletedType?.item?.key && item?.key !== undefined) || (item?.idEntity && item?.idEntity !== reletedType?.item?.key) || (item?.id_entity && item?.id_entity !== reletedType?.item?.key) || (item?.id_entity && item?.id_entity !== reletedType?.item?.key))
        setItemsInsiders(arrInsiders)
        setDiscounts({ ...discounts, "related_entities": arrRelated })
        reletedType.setshowSpiner(false)
    }

    const setDraftFinancialAssets = (financialAssetsArr: any, changeCahsingDate?: any) => {
        let financialAssets: any = [], sum = 0, sumOfFee = 0
        let total_expenses = 0, total_net_amount = 0, total_amount_ils = 0, amount_round = 0, fee = 0
        if (dataConversion[financialAssetsArr]) {
            let prop: any = searchCustomer?.priceProgram?.price_program_properties
            let properties: any = {};
            prop?.forEach((element: any) => {
                properties[element.name] = element.value ? element.value : element.percent;
            });
            dataConversion[financialAssetsArr].forEach((item: any, index: any) => {
                let temp: any = item
                if (changeCahsingDate) {
                    let chequeDays = checkChequeDays(item?.financial_asset_details?.cheque_date)
                    if (temp?.financial_asset_details) {
                        temp.financial_asset_details.cheque_days = chequeDays
                        temp.financial_asset_details.cheque_cashing_date = moment(new Date()).format("YYYY-MM-DD")
                    }
                    temp = calcChequeDetails(temp)
                    setIfChanges(true)
                }
                financialAssets.push({
                    financial_asset_code_name: item?.financial_asset_code_name,
                    financial_asset_details: temp.financial_asset_details
                    , currency_code: item?.currency_code, financial_asset_code: item?.financial_asset_code,
                    amount: item?.amount, exchange_rate_ils: item?.exchange_rate_ils, amount_ils: item?.amount_ils && item?.amount_ils,
                    contra_id_account: item?.contra_id_account, row_number: item?.row_number, fee: item?.fee
                })
                sum += item.amount_ils
                if (financialAssetsArr === "financial_assets_in") {
                    total_amount_ils += item?.amount_ils
                    total_expenses += (
                        Number(item?.financial_asset_details?.cheque_expenses ? item?.financial_asset_details?.cheque_expenses : 0) +
                        //  Number(item?.financial_asset_details?.allowed_expenses ? item?.financial_asset_details?.allowed_expenses : 0) + 
                        Number(item?.financial_asset_details?.allowed_actual_expenses ? item?.financial_asset_details?.allowed_actual_expenses : 0))
                }
                if (financialAssetsArr === "financial_assets_out")
                    sumOfFee += item?.fee ? Number(item?.fee) : 0
            })
        }
        if (financialAssetsArr === "financial_assets_out") {
            setSumOut(sum);
            setSumFee(sumOfFee)
        }
        else
            setSumIn(sum)

        if (financialAssetsArr === "financial_assets_in") {
            total_net_amount += Number(total_amount_ils) - Number(total_expenses?.toFixed(2))
            amount_round = Number(Math.round(total_net_amount)) - Number(total_net_amount?.toFixed(2))
            total_net_amount = Math.round(total_net_amount);
            total_expenses = Number(total_expenses?.toFixed(2))
            const sortArray = financialAssets.sort((a: any, b: any) => a.row_number - b.row_number);

            setDiscounts((discounts) => ({
                ...discounts, "total_net_amount": total_net_amount, "total_amount_ils": total_net_amount,
                "total_expenses": total_expenses, "amount_round": amount_round, [financialAssetsArr]: sortArray, ["note"]: dataConversion?.note
            }))
            // total
            // setDiscounts((discounts) => ({ ...discounts, "total_net_amount": total_net_amount, "total_amount_ils": total_amount_ils, "total_expenses": total_expenses, "amount_round": amount_round, [financialAssetsArr]: financialAssets }))
        } else {
            setDiscounts((discounts) => ({ ...discounts, [financialAssetsArr]: financialAssets, ["note"]: dataConversion?.note }))
        }
    }

    const setDraftDiscountsData = async () => {
        emptyInContraAccount && setEmptyInContraAccount(false)
        emptyOutContraAccount && setEmptyOutContraAccount(false)
        emptyRequiredFields && setEmptyRequiredFields(false)
        isNegativeBalance && setIsNegativeBalance(false)
        let arrInsiders: any[] = []
        let arrRelated: any = []
        const { id_transaction, id_entity, total_amount_ils, id_account, is_unusual_reporting } = dataConversion
        dataConversion?.related_entities?.forEach((entity: any) => {
            arrRelated.push({
                id_entity: entity?.id_entity,
                service_requisition_type_id: entity?.service_requisition_type_id
            })
            if (Number(entity.service_requisition_type_id) === 11 || Number(entity.service_requisition_type_id) === 13) {
                arrInsiders.push(entity?.related_entitiy ? entity?.related_entitiy : entity?.related_entity)
            }
        })
        setDiscounts((discounts) => ({ ...discounts, "id_transaction": id_transaction, "id_entity": id_entity, "total_amount_ils": total_amount_ils, "id_account": id_account, "related_entities": arrRelated, "is_unusual_reporting": is_unusual_reporting }))
        setItemsInsiders(arrInsiders)
        let customerInfo = dataConversion?.entity && customerInfoInObject(dataConversion?.entity)
        setSearchCustomer(customerInfo)
        let item
        let id = sessionStorage.getItem("firstDraftId")
        if (dataConversion?.financial_assets_in) {
            item = dataConversion?.financial_assets_in.find((item: any) => item?.financial_asset_code === "3")
        }
        if (state !== "showCompleted") {
            if (id !== dataConversion?.id_transaction && (item && item?.financial_asset_details?.cheque_cashing_date !== moment(new Date()).format('YYYY-MM-DD'))) {
                sessionStorage.setItem('firstDraftId', dataConversion?.id_transaction);
                setShowDialogChangeCashingDate(true)
            }
        }
        if ((dataConversion?.status_id !== "deleted" && dataConversion?.status_id !== "completed") || state === "showCompleted") {
            await setDraftFinancialAssets("financial_assets_in")
            await setDraftFinancialAssets("financial_assets_out")
        }
    }

    const resetPageData = async () => {
        let reset: any
        await dispatch({
            type: RESET_DATA_CONVERSION,
            payload: null
        })
        setSumOut(0)
        setSumIn(0)
        setSearchCustomer(reset)
        setIfChanges(false)
        setSelectedDraft("")
        emptyOutContraAccount && setEmptyOutContraAccount(false)
        emptyInContraAccount && setEmptyInContraAccount(false)
        emptyRequiredFields && setEmptyRequiredFields(false)
        emptyInAmount && setEmptyInAmount(false)
        emptyOutAmount && setEmptyOutAmount(false)
        isNegativeBalance && setIsNegativeBalance(false)
        setItemsInsiders([])
        setReadOnly(true)
        setDiscounts(initialiseState)

    }

    const 
    finishDeal = async () => {
        debugger
        if (dataConversion?.ID_number || dataConversion?.entity?.ID_number) {
            let copyDiscounts = { ...discounts }
            if (searchCustomer?.isLocked === true) {
                setServerMessageError(true)
                setTitleMessageError(t("note"))
                setMessageError(t("customerlockedMassege"))
            }
            else {
                setEmptyInContraAccount(false)

                let emptyIn = false, emptyOut = false, requiredFields, requiredFieldsOut = false, negativeBalance = false
                discounts?.financial_assets_in?.map(async (element: any) => {
                    if (!element.contra_id_account) {
                        setEmptyInContraAccount(true)
                        emptyIn = true
                    }
                    requiredFields = checkRequiredFields(element.financial_asset_code, element);
                    if (requiredFields) {
                        setEmptyRequiredFields(true)
                    }
                    if (element.financial_asset_code === "3" && element?.financial_asset_details?.cheque_net < 0) {
                        negativeBalance = true
                        setIsNegativeBalance(true)
                    }
                    if (!element.amount) {
                        setEmptyInAmount(true)
                        emptyOut = true
                    }
                });
                discounts?.financial_assets_out?.forEach(async (element: any) => {
                    if (!element.contra_id_account && element.financial_asset_code !== "18") {
                        if (element.financial_asset_code === "1") {
                            let defaultcontraCash = contraCashAccounts.find((item: any) => item.is_default === true)
                            let index = copyDiscounts?.financial_assets_out?.findIndex((item: any) => Number(item.row_number) === Number(element?.row_number));
                            if (index !== -1 && defaultcontraCash) {
                                copyDiscounts.financial_assets_out[index].contra_id_account = defaultcontraCash.key
                            }
                            else {
                                setEmptyOutContraAccount(true)
                                emptyOut = true
                            }
                        }
                    }
                    if (element.financial_asset_code === "3" && (!element?.financial_asset_details?.cheque_date ||
                        !element?.financial_asset_details?.reference_number || !element.amount)) {
                        requiredFields = true
                        setEmptyRequiredFields(true)
                    }
                    if (!element.amount) {
                        setEmptyOutAmount(true)
                        emptyOut = true
                    }
                    requiredFieldsOut = await checkRequiredFields(element.financial_asset_code, element)
                    await requiredFieldsOut && setEmptyRequiredFields(true)
                });
                let amountIn = discounts?.total_net_amount !== 0 ? discounts?.total_net_amount : sumIn
                if (discounts?.id_transaction !== null) {
                    if ((amountIn - (sumOut + sumFee) !== 0) || (discounts.financial_assets_out && discounts.financial_assets_out[0].amount_ils === 0)
                        || (discounts.financial_assets_in[0] && discounts.financial_assets_in[0].amount_ils === 0) || emptyIn || emptyOut || requiredFields || negativeBalance) {
                        setshowDialogFinishFail(true)
                    }
                    else {
                        if (discounts?.total_amount_ils > 50000
                            && searchCustomer?.isFullyIdentified === false) {
                            setDocAction(true)
                        }
                        else {
                            let insider = searchCustomer?.insiders?.find((item: any) => item?.insider_type_id === 8 && item?.is_deleted === false)
                            let type = dataConversion?.entity?.properties?.customer_type_id
                            if ((insider === -1 || insider === undefined) && (type === "3" || type === "5" ||
                                type === "9" || type === "12" || type === "10")) {
                                setInsiderAction(true)
                            }
                            else {
                                const age = checkAge(searchCustomer.dateBirth)
                                let isCorporation = isCorporationFunc(searchCustomer)
                                if ((age >= 21 && !isCorporation) || isCorporation) {
                                    await dispatch(createTransaction("complete", "cheque_cashing", searchCustomer.entityName, copyDiscounts,
                                        userCredentials, discounts.id_entity, setshowSpiner, setServerMessageError,
                                        setMessageError, setTitleMessageError, setSelectConversionList))
                                    await dispatch({
                                        type: RESET_CUSTOMER_OBJECT,
                                    })
                                }
                                else {

                                    setMessageError("לא ניתן לבצע את הפעולה, הלקוח מתחת גיל 21")
                                    setTitleMessageError(t("note"))
                                    setServerMessageError(true)
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            setServerMessageError(true)
            setTitleMessageError(t("note"))
            setMessageError(("notHaveIdentityNumber"))
        }
    }

    const deleteDeal = async () => {
debugger
        if (dataConversion?.status_id === "draft") {
            setbeforeDelete(true)
        }
        else if (dataConversion?.status_id === "completed") {
            await dispatch(deleteTransaction("cancel", "cheque_cashing", discounts, userCredentials, discounts.id_entity,
                setSelectConversionList, setShowDialogSave, "", setshowSpiner))
            // await dispatch(deleteTransaction("cancel", "cheque_cashing", discounts, userCredentials, 
            // discounts.id_entity,setshowSpiner,setServerMessageError, setMessageError ,setSelectConversionList, ))
        }
    }

    useEffect(() => {
        if (ifDelete && dataConversion?.status_id === "draft") {
            dispatch(deleteTransaction("delete", "cheque_cashing", discounts, userCredentials, discounts.id_entity,
                setSelectConversionList, setShowDialogSave, "", setshowSpiner))
            // dispatch(deleteTransaction("delete", "cheque_cashing", discounts, userCredentials,
            //  discounts.id_entity,setshowSpiner,setServerMessageError, setMessageError, setSelectConversionList ))
            // setifDelete(false)
            dispatch({
                type: RESET_CUSTOMER_OBJECT,
            })
        }
    }, [ifDelete])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const change = async (key: any, value: any) => {
        let c: any = discounts
        !ifChanges && !isEqual(c[key], value) && setIfChanges(true)
        await setDiscounts({ ...discounts, [key]: value })
    }

    return (
        <div className={`${checkPermissionLevelOfPage(credentials, location) === "RW"
            ? 'page-read' : 'page-read-only'}`}>
            {docAction &&
                <DialogMessages setansDelete={setDocAction} setshowModalDelete={setDocAction}
                    subText={t('actionCannotCompletedCustomerDocuments')} title={t('note')} dialogType={"dialogMessage"}
                    setSaveDataConversion={setAddDoc} />}

            {insiderAction &&
                <DialogMessages setansDelete={setInsiderAction} setshowModalDelete={setInsiderAction}
                    subText={t('actionCannotCompletedControllingOwners')} title={t('note')} dialogType={"dialogMessage"}
                    setSaveDataConversion={setAddInsider} />}

            {showDialogChangeCashingDate && <DialogMessages setansDelete={setShowDialogChangeCashingDate}
                setshowModalDelete={setShowDialogChangeCashingDate}
                subText={t('discountDateDassed')} title={t('note')} dialogType={"dialogMessage"}
                setSaveDataConversion={setChangeChashingDate} />}

            {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} subText={messageError} title={t('error')} setshowModalDelete={setShowDialogFailed} dialogType={"dialogMessage"} />}
            {serverMessageError && <DialogMessages setansDelete={setServerMessageError} subText={messageError} title={titleMessageError} setshowModalDelete={setServerMessageError}
                dialogType={"dialogMessage"} confirm={searchCustomer?.note ? true : false} />}

            {showDialogFinishFail && <DialogMessages setansDelete={setshowDialogFinishFail} setshowModalDelete={setshowDialogFinishFail} dialogType={"FinishDealFail"} />}
            {showDialogSave && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"create"} />}
            {beforeDelete && <DialogMessages setansDelete={setbeforeDelete} setifDelete={setifDelete} setshowModalDelete={setbeforeDelete} dialogType={"delete"} />}
            {state === "showCompleted" ? "" : <> <HeaderUtils title={t("cashings")} subTitle={"false"} editBtn={false} uploadBtn={false} disabledSave={true} showSelectConvertions={true} /> <br></br><br></br></>}
            {state !== "showCompleted" ? <Subtitle title={t("checkDiscounts")} /> : <div className="dialogSubTitle">{t("checkDiscounts")}</div>}
            {saveDraft?.changeLocation !== undefined && <CustomPrompt sumIn={sumIn} sumOut={sumOut} typeAction="cheque_cashing"
                entityName={searchCustomer?.entityName} action={discounts} ifChanges={ifChanges} saveDraft={saveDraft} subText={t('saveChanges')} userCredentials={userCredentials} resetFunc={resetPageData} />}
            <p id='print' className="button" onClick={handlePrint} />
            <div style={{ display: "none" }}>
                <div ref={componentRef} dangerouslySetInnerHTML={{ __html: dataConversion?.transaction_document?.document_html }} />
            </div>
            <div className={state !== "showCompleted" ? `content-div-${dir}` : ""} >
                <div style={{ display: "flex", justifyContent: "space-between", width: "76vw" }}>
                    <EntityDetails setSelectedDraft={setSelectedDraft} selectedDraft={selectedDraft} listInsiders={listInsiders}
                        setListInsiders={setListInsiders} setActionCustomerId={setDiscountsCustomerId} typeAction="cheque_cashing"
                        ifChanges={ifChanges} setIfChanges={setIfChanges}
                        salvo={false}
                        actions={discounts} setActions={setDiscounts} readOnly={readOnly} setReadOnly={setReadOnly}
                        setSelectConversionList={setSelectConversionList}
                        selectConversionList={selectConversionList} setSearchCustomer={setSearchCustomer}
                        searchCustomer={searchCustomer} /></div>

                <p className="title-text" >{t("actionDetails")}</p>

                <hr className="hr" style={{ width: "76vw" }}></hr>
                <DiscountFinancialAssetsIn emptyRequiredFields={emptyRequiredFields} emptyInAmount={emptyInAmount}
                    isNegativeBalance={isNegativeBalance} sourcePage="discounts" sumIn={sumIn} setSumIn={setSumIn}
                    setSumOut={setSumOut} setSumFee={setSumFee} readOnly={readOnly} ifChanges={ifChanges} setIfChanges={setIfChanges}
                    typeAction="cheque_cashing" userCredentials={userCredentials} searchCustomer={searchCustomer} action={discounts}
                    setAction={setDiscounts} emptyInContraAccount={emptyInContraAccount} />

                <FinancialAssets emptyOutAmount={emptyOutAmount} setIfChanges={setIfChanges} ifChanges={ifChanges}
                    sourcePage="checkDiscount" searchCustomer={searchCustomer} userCredentials={userCredentials}
                    typeAction="cheque_cashing" tableName="financial_assets_out" action={discounts} setAction={setDiscounts}
                    emptyRequiredFields={emptyRequiredFields}
                    readOnly={readOnly} sum={sumOut} setSum={setSumOut} sumFee={sumFee} setSumFee={setSumFee}
                    emptyOutContraAccount={emptyOutContraAccount} />

                {selectedDraft && dataConversion?.status_id === "draft" &&
                    <div style={{ width: "780px", display: "inline-grid", marginTop: "20px", justifyContent: "end", }}>
                        <PrintHtml saveFunction={finishDeal} typeAction="cheque_cashing" conversionCustomer={searchCustomer}
                            action={discounts} setAction={setDiscounts} userCredentials={userCredentials} />
                        <PrimaryButton className={`bottun-${dir}`} onClick={() => { deleteDeal(); }}
                            style={{ width: "233px", marginTop: "4px", backgroundColor: "red", borderColor: "red" }}
                            allowDisabledFocus disabled={discounts.id_transaction ? false : true} checked={false} name="delete"
                            text={t("deleteTransaction")} />
                    </div>}
                <div >
                    <div style={{ width: "780px", marginTop: '4%' }}>
                        <div style={{ justifyContent: "space-between", display: "flex" }} >
                            <p className="title-text" style={{ marginTop: '0%' }}>{t('note')}</p>
                            <div><CustomToggle onText={t("selectNon-standardReporting")} readOnly={readOnly}
                                onChange={change} id={'is_unusual_reporting'} defaultChecked={discounts?.is_unusual_reporting}
                                checked={discounts?.is_unusual_reporting} offText={t("selectNon-standardReporting")} /></div>
                        </div>
                        <hr className="hr" ></hr>
                        <TextFeildNote label={t('')} onChange={change} id={'note'} value={discounts?.note} />
                    </div>
                </div>
                <div style={{ marginTop: "18px" }}>
                    <PrimaryButton disabled={readOnly} allowDisabledFocus checked={false} onClick={() => setaddFactorDialog(true)} name="add" text={t("addBeneficiary/guarantor")} iconProps={Icons.addFriend} />
                    {addFactorDialog && <EditableRelatedFactors setShowDialog={setaddFactorDialog} setshowSpiner={setshowSpiner} setRelatedFactors={setRelatedFactors} isConversions={true} />}
                </div>
                <div >
                    {itemsInsiders.length >= 1 && <ListRelatedFactors setshowSpiner={setshowSpiner} setRelatedFactors={setRelatedFactors} dataCustomer={itemsInsiders} />}
                </div>
            </div>
        </div >
    )
}

