import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import '../scssPages/base.scss';

const Home = () => {
    return (
        <>
            <div className="grid-container">
                <Header />
                <Sidebar />
                {/* <Route path="/" exact component={CustomerSearch}/>  */}
            </div>
        </>
    )
}

export default Home;