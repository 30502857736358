import React, { useState, useEffect, useRef } from 'react'
import { DefaultButton, Icon, IconButton, PrimaryButton } from "@fluentui/react"
import { useReactToPrint } from 'react-to-print';

import { Icons } from "../../../modelsType/Icon"
import GenericPdfDownloader from '../../../components/pdfUtils/GenericPdfDownloader'
import Title from '../Title'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { readCustomerId } from '../../../store/actions/customerActioin'
import { IUserCredentials } from '../../../components/customerDetails/CustomerDetails'
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

import './headerUtils.css'
import IconSvg from '../../../modelsType/Icon/IconSvg';
import PrintTable from '../../../components/global/PrintTable';
import { checkPermissionLevelOfPage } from '../CustomHooks/GlobalFunctions';

const HeaderUtils = (props: any) => {
  const { createBtn = false, editBtn = false, uploadBtn = false, title, subTitle, disabledSave,
    action = true, funcAction, actionData, PrintTitle,pdfAction,pdfName,createPDF,createPrint,createXl } = props
  const { createFunc = () => alert("create"), editFunc = () => alert("edit"),
    saveFunc = () => alert("save"), cancelFunc = () => alert("cancel") } = props
  const dispatch = useDispatch()
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const location = useLocation()
  const [actionName, setActionName] = useState('')
  const [permission, setPermission] = useState('')
  const authReducer = useSelector(({ authReducer }: any) => authReducer)
  const customerReducer = useSelector(({ customerReducer }: any) => customerReducer)
  const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
  const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
  const mainAccounts = useSelector(({ authReducer }: any) => authReducer?.data?.branch?.main_accounts)
  const [customerName, setCustomerName] = useState('')
  let printRef = useRef(null);
  const _export = React.useRef<any>(null);

    
  useEffect(() => {
    setUserCredentials({
      "idInitiator": authReducer.data.user.id_entity,
      "idClient": authReducer.data.client.id_entity,
      "idBranch": authReducer.data.branch.id_entity,
      "idClientData": authReducer.data.client.id_client
    })
  }, [authReducer]);

  useEffect(() => {
    if (customerReducer && customerReducer.dataCustomer?.entity_name !== undefined)
      setCustomerName(customerReducer.dataCustomer?.entity_name)
  }, [customerReducer])

  useEffect(()=>{
    if (location&&credentials?.length) {
      checkPermissionLevelOfPage(credentials,location,setPermission)
    }
  },[location,credentials])

  useEffect(() => {
    if (actionData) {
      if (actionName === "print") {
        if (handlePrint !== undefined)
          handlePrint()
      }
      if (actionName === "excel") {
        excelExport()
      }
    }
  }, [actionData])

  const print = () => {
    if (title === "דוחות"||title === "Reports") {
      if (handlePrint !== undefined)
        funcAction && funcAction()
    }
    else {
      setActionName("print")
      funcAction && funcAction()
    }
  }
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const excelExportGlobal = () => {
    setActionName("excel")
    funcAction && funcAction()
  };
  
  return (
    <>
      <div className={`sub-header-${dir} fixed-${dir}`}>
        <Title
          title={t(title)} nameInTitle={subTitle === "false" ? "" : customerName} />
        <div className="divider"></div><div className="divider"></div>

        {!editBtn ? "" : permission === "RW" && <DefaultButton className='button edit-button' onClick={() => editFunc(true)} checked={false} text={t('edit')} iconProps={Icons.editContact} name="update" />}
        {!createBtn ? "" : permission === "RW" && <PrimaryButton ariaDescription="create" className='button' allowDisabledFocus onClick={createFunc} checked={false} name="create" text={t('createCustomer')} iconProps={Icons.addFriend} />}
        {disabledSave ? "" : permission === "RW" && <DefaultButton className='button edit-button' onClick={() => cancelFunc(true)} checked={false} text={t("cancel")} iconProps={Icons.editContact} name="cancel" />}
        {!uploadBtn ? "" : permission === "RW" && <DefaultButton className='button save-upload' onClick={saveFunc} checked={false} text={t('save')} iconProps={Icons.cloudUpload} />}
        {!createBtn ? "" : permission === "RW" && <PrimaryButton ariaDescription="create" className='button' allowDisabledFocus onClick={createFunc} checked={false} name="create" text={t('createCustomer')} iconProps={Icons.addFriend} />}
        {(permission === "RW" && (title === "הנהלת חשבונות"||title === "Bookkeeping") && !mainAccounts) && <PrimaryButton ariaDescription="create" className='button' allowDisabledFocus onClick={createFunc} checked={false} name="create" text={t('create')} iconProps={Icons.addFriend} />}
        {action === true ? <div>
          {createPDF&&<IconButton
            iconProps={Icons.pdf}
            styles={{
              icon: { color: 'red', fontSize: 36 }
            }}
            className="button"
            onClick={() => GenericPdfDownloader({ rootElementId: pdfAction, downloadFileName: pdfName })}
          />}
          {createPrint&&<IconButton
            iconProps={Icons.print}
            styles={{
              icon: { color: 'black', fontSize: 36 }
            }}
            className="button"
            onClick={print}

          />}
          {(title !== "דוחות"&&title !== "Reports" )? <ExcelExport data={actionData}
            fileName={t(title)} ref={_export}>
            {PrintTitle?.map((item: any, key: any) => (
              <ExcelExportColumn key={key}
                field={item?.eng}
                title={item?.key}
                locked={true}
                width={200}
              />
            ))}
          </ExcelExport>
            : <ExcelExport data={actionData} fileName={t(title)} ref={_export}>
              {PrintTitle?.map((item: any, key: any) => (
                <ExcelExportColumn key={key}
                  field={item}
                  title={item}
                  locked={true}
                  width={200}
                />
              ))}
            </ExcelExport>}

          {createXl&&<IconButton style={{ margin: "0px" }}
            className="button" onClick={() => {
              (title === "דוחות"||title === "Reports") ? excelExport()
                : excelExportGlobal()
            }}
          > <IconSvg name="xl" /></IconButton>}
        </div>
          :
          <div style={{ height: "45px" }}></div>
        }
        {subTitle !== "false" && <div className="button"
          style={{
            cursor: "pointer"
          }}
          title='רענן'
          onClick={() => {
            dispatch(readCustomerId(customerReducer.dataCustomer?.id_entity,
              userCredentials, "", "", "", false));
          }}
        > <Icon iconName="refresh"></Icon></div>}
       {subTitle === "false" &&<div style={{width:"20px",height:"45px"}}></div>}
      </div>
      <PrintTable actionData={actionData} arrTitle={PrintTitle} printRef={printRef} title={title} />
    </>
  )
}

export default HeaderUtils
