import  { useEffect, useState } from 'react'
import CustemTable from '../../shared/components/tabels/tableList';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils'
import './users.scss'
import Subtitle from '../../shared/components/Subtitle';
import { ISpinnerStyles } from 'office-ui-fabric-react';
import UsersDialog from './usersDialog';
import { findDefault, IUserCredentials } from '../customerDetails/CustomerDetails';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions';

const Users = () => {
    const [t] = useTranslation();
    const [dir,setDir]=useState("")
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const customerReducer = useSelector(({ customerReducer }: any) => customerReducer)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const userData = useSelector(({ authReducer }: any) => authReducer.data.branch.users)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [/*customerName*/, setCustomerName] = useState('')
    const [showSpiner, setshowSpiner] = useState(false)
    const [itemsUsers, setitemsUsers] = useState([{}])
    const [showDialogCreate, setShowDialogCreate] = useState(false)
    const [idEntity, setidEntity] = useState("")
    const [ShowDialogUpdate, setShowDialogUpdate] = useState(false)
    const columns = [
        { key: '2', name: t('fullName'), fieldName: "entity_name", entity_name: "entity_name", minWidth: 170, maxWidth: 170 },
        { key: '3', name: t('identityNumber'), fieldName: "idNumber", entity_name: "idNumber", minWidth: 90, maxWidth: 90 },
        { key: '4', name: t('address0'), fieldName: "addressName", entity_name: "address", minWidth: 160, maxWidth: 160 },
        { key: '5', name: t('phone'), fieldName: "telephone", minWidth: 100, maxWidth: 100 },
        // { key: '6', name: "רמת הרשאה", fieldName: "levelPermission", minWidth: 100, maxWidth: 100 },
        { key: '7', name: t('status'), fieldName: "status_name", minWidth: 100, maxWidth: 100 },
        { key: '8', name: t('edit'), fieldName: "edit", minWidth: 100, maxWidth: 100 },
    ];

    const [addUser, setaddUser] = useState(false)
    const spinnerStyles: Partial<ISpinnerStyles> = {
        root: {
            position: 'fixed',
            zIndex: '999999',
            fontWeight: 'bold',
        },
        circle: {
            color: '#FFB400 ',
            height: '100px',
            width: '100px',
        },
    }
    useEffect(()=>{
        if(lang){
            setDir(lang)
        }
        },[lang])
        
    useEffect(() => {
        if (customerReducer)
            setCustomerName(customerReducer.dataCustomer?.properties?.entity_name)
    }, [customerReducer])


    useEffect(() => {
        setidEntity(authReducer.data.branch.id_entity)
        usersData()
    }, [authReducer])

    const usersData = () => {
        let arrUser: object[] = []
        let indexAddress: any
        let indexEmail: any
        let indexPhone: any
        indexAddress = findDefault(userData.addresses)
        indexEmail = findDefault(userData.emails)
        indexPhone = findDefault(userData.telephones)
        userData?.forEach((dataCustomer: any, index: number) => {
            // dataCustomer.is_deleted === false &&
            arrUser.push({
                key: dataCustomer?.id_entity,
                idNumber: dataCustomer?.identification?.ID_number,
                idTypeOther: dataCustomer?.identification?.ID_type_other,
                customerTypeId: dataCustomer?.properties?.customer_type_id,
                idTypeCountryCode: dataCustomer?.identification?.ID_country_code,
                workerName: dataCustomer?.identification?.identifier_name,
                idTypeId: dataCustomer?.identification?.ID_type_id,
                classId: dataCustomer?.class?.class_id,
                status_id: dataCustomer?.status?.status_id,
                status_name: dataCustomer?.status?.status_name,
                dateBirth: dataCustomer?.properties?.date_birth,
                firstName: dataCustomer?.first_name?dataCustomer?.first_name:dataCustomer?.properties?.first_name,
                lastName: dataCustomer?.last_name?dataCustomer?.last_name:dataCustomer?.properties?.last_name,
                entity_name: dataCustomer?.entity_name,
                middleName: dataCustomer?.properties?.middle_name,
                entitySubTypeId: dataCustomer?.types && dataCustomer?.types[0]?.entity_sub_type_id,
                entityNumber: dataCustomer?.properties?.entity_number,
                isLocked: dataCustomer?.properties?.is_locked,
                note: dataCustomer?.properties?.note,
                gender: dataCustomer?.gender?.gender_id,
                genderName: dataCustomer?.gender?.gender_other,
                entityStatusId: dataCustomer?.status?.status_id,
                addressName: dataCustomer.addresses && dataCustomer.addresses[indexAddress]?.address_street,
                address: dataCustomer.addresses && dataCustomer.addresses[indexAddress]?.address_street_code,
                addressState: dataCustomer.addresses && dataCustomer.addresses[indexAddress]?.address_state,
                addressNumber: dataCustomer.addresses && dataCustomer.addresses[indexAddress]?.address_number,
                addressCityName: dataCustomer.addresses && dataCustomer.addresses[indexAddress]?.address_city,
                addressCity: dataCustomer.addresses && dataCustomer.addresses[indexAddress]?.address_city_code,
                addressZipCode: dataCustomer.addresses && dataCustomer.addresses[indexAddress]?.address_zip_code,
                addressCountryCode: dataCustomer.addresses && dataCustomer.addresses[indexAddress]?.address_country_code,
                // iDCountryCode: dataCustomer.addresses && dataCustomer.addresses[indexAddress]?.address_country_code,
                telephone: dataCustomer?.telephones && dataCustomer?.telephones[indexPhone]?.telephone_number,
                telephoneCountryCode: dataCustomer?.telephones && dataCustomer?.telephones[indexPhone]?.telephone_country_code,
                email: dataCustomer?.emails && dataCustomer?.emails[indexEmail]?.email_address,
                telephoneTypeId: dataCustomer?.telephones && dataCustomer?.telephones[indexPhone]?.telephone_type_id,
                telephoneId: dataCustomer?.telephones && dataCustomer?.telephones[indexPhone]?.telephone_id,
                telephoneDefault: 1,
                emailTypeId: dataCustomer?.emails && dataCustomer?.emails[indexEmail]?.email_type_id,
                emailId: dataCustomer?.emails && dataCustomer?.emails[indexEmail]?.email_id,
                emailDefault: 1,
                addressTypeId: dataCustomer.addresses && dataCustomer.addresses[indexAddress]?.address_type_id,
                addressId: dataCustomer.addresses && dataCustomer.addresses[indexAddress]?.address_id,
                addressDefault: 1,
                industryId: dataCustomer.industry_classification?.industry_classification_id,
                CreditGroup: dataCustomer.industry_classification?.industry_sub_classification_id,
                login_entity_number: dataCustomer.login_entity_number,
                login_ID: dataCustomer.login_ID,
                login_password: dataCustomer.login_password,
                documentation:dataCustomer?.documentation,
                permission_group_id:dataCustomer?.permission_group_id,
                user_language:dataCustomer?.preferences?.user_language,
            })
        });
        setitemsUsers(arrUser)
    }
    useEffect(() => {
        setUserCredentials({
            "idInitiator": authReducer.data.user.id_entity,
            "idClient": authReducer.data.user.id_client,
            "idBranch": authReducer.data.branch.id_entity,
            "idClientData": authReducer.data.client.id_client
        })

    }, [authReducer]);
    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
        ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={"settings"} subTitle={"false"} disabledSave={true} createBtn={false} editBtn={false} deleteBtn={false} uploadBtn={false} />
            <br></br><br></br>
            {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
            {showDialogCreate && <DialogMessages setansDelete={setShowDialogCreate} setshowModalDelete={setShowDialogCreate} dialogType={"create"} />}
            {ShowDialogUpdate && <DialogMessages setansDelete={setShowDialogUpdate} setshowModalDelete={setShowDialogUpdate} dialogType={"update"} />}
            <Subtitle title={t('users')} />
            <div className={`content-div-${dir}`}>
                <div className="atach-form" style={{ width: "800px" }}>
                    <p className="title-text" style={{ width: "800px" }}>{t('usersList')}</p>
                    <p className="title-text addFile" onClick={() => setaddUser(true)} >{"(+)"}</p>
                    {addUser ? <UsersDialog setShowDialog={setaddUser} userCredentials={userCredentials} idEntity={idEntity} setShowDialogUpdate={setShowDialogUpdate} setShowDialogCreate={setShowDialogCreate} setshowSpiner={setshowSpiner} update={false} branchNumber={authReducer?.data?.branch?.entity_number} /> : ""}
                </div>
                <hr className="hr" style={{  width: "800px" }} ></hr>
            </div>
            <div className={`div-user-${dir}`}>
                {itemsUsers.length === 0 ? <div style={{ textAlign: "center", marginTop: "18px", width: "80%" }}>{t('noDisplay')}</div> : <CustemTable tableName="usersList" columns={columns} allItems={itemsUsers} renderItemColumn idEntityUser={idEntity} rederRow={"edit"} setshowSpiner={setshowSpiner} addCustem={true} setShowDialogCreate={setShowDialogCreate} isFooter={false} />}
            </div>
        </div>
    )
}

export default Users
