import { useState, useEffect } from "react"
import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils'
import Subtitle from "../../shared/components/Subtitle";
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CustomTextField } from "../../shared/components/TextField";
import { CustomDropdown } from "../../shared/components/Option";
import { convertArrToDropdropdown } from "../customerDetails/CustomerDetails";
import { PrimaryButton } from '@fluentui/react/lib/Button';

const Stocktaking = () => {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
    const [readOnly, setReadOnly] = useState(false)
    const [sumIn, setsumIn] = useState(0)
    const [isNewRow, setisNewRow] = useState(-1)
    const [defaultCurrencyCode, setdefaultCurrencyCode] = useState()
    const [defaultfinanssialAsset, setdefaultfinanssialAsset] = useState()
    const [convertions, setConvertions] = useState({
        id_insider: "", documentation: [], note: "", related_entities: [{ id_entity: "", service_requisition_type_id: 0 }],
        transaction_type_id: 1, total_amount_ils: 0,
        financial_assets_in: [{
            currency_code: defaultCurrencyCode, financial_asset_code: defaultfinanssialAsset,
            financial_asset_details: "", amount: 0, exchange_rate_ils: 0, amount_ils: 0,
            contra_id_account: ""
        }],
        financial_assets_out: [{
            currency_code: defaultCurrencyCode, financial_asset_code: defaultfinanssialAsset,
            financial_asset_details: "", amount: 0, exchange_rate_ils: 0, amount_ils: 0,
            contra_id_account: ""
        }]
    })
    const [enumsDropdown, setenumsDropdown] = useState<any>({ "currencyCode": [], "finanssialAssetCode": [] })
    useEffect(() => {
        if (enums) {
            let arr = {
                "currencyCode": convertArrToDropdropdown([]),
                "finanssialAssetCode": convertArrToDropdropdown([])
            };

            for (let i = 0; i < enums.length; i++) {
                if (enums[i].general !== undefined) {
                    let s = enums[i].general;
                    for (let i = 0; i < s.length; i++) {
                        if (s[i].currency_code !== undefined)
                            arr.currencyCode = convertArrToDropdropdown(s[i].currency_code)
                    }
                }
                if (enums[i].exchange !== undefined) {
                    let s = enums[i].exchange;
                    for (let i = 0; i < s.length; i++) {
                        if (s[i].financial_asset_code !== undefined)
                            arr.finanssialAssetCode = convertArrToDropdropdown(s[i].financial_asset_code)
                    }
                }
            }
            let defaultfinanssialValue: any
            (defaultfinanssialValue = arr?.finanssialAssetCode.find(({ is_default }: any) => is_default === true))
            let defaultcurrencyCodeValue: any
            (defaultcurrencyCodeValue = arr?.currencyCode.find(({ is_default }: any) => is_default === true))
            setdefaultCurrencyCode(defaultcurrencyCodeValue && defaultcurrencyCodeValue.key)
            setdefaultfinanssialAsset(defaultfinanssialValue && defaultfinanssialValue.key)
            setenumsDropdown(arr);
        }
    }, [enums])
    const removeFObject = (index: number) => {
        const items = [...convertions.financial_assets_in];
        items.splice(index, 1)
        if (items.length === 0)
            items.push({
                currency_code: defaultCurrencyCode, financial_asset_code: defaultfinanssialAsset,
                financial_asset_details: "", amount: 0, exchange_rate_ils: 0, amount_ils: 0,
                contra_id_account: ""
            })
        let sum = 0;
        for (let i = 0; i < items.length; i++) {
            sum += items[i].amount_ils
        }
        setsumIn(sum)
        setConvertions({ ...convertions, "financial_assets_in": items })
    }
    const addFinancialIn = (key: string, value: any) => {
        const [id, index] = key.split(',');
        let i = Number(index)
        let arr: any = []
        // if (id === "financial_asset_code") {
        //     settableType("in")
        //     setindex(i)
        //     settableType(id)
        //     value === "12" && setCredit(true)
        //     if (value === "9" || value === "10")
        //         setTransferFromCustomer(true)
        //     value === "1" && setcashPayment(true)
        //     value === "3" && setcheckDetails(true)
        // }
        arr = [...convertions.financial_assets_in]
        if (isNewRow !== i) {
            arr.push({
                currency_code: defaultCurrencyCode, financial_asset_code: defaultfinanssialAsset,
                financial_asset_details: "", amount: 0, exchange_rate_ils: 0, amount_ils: 0,
                contra_id_account: ""
            })
            setisNewRow(i)
        }

        if ((arr.length - 1) >= i) {
            let temp = {
                currency_code: (id === "currency_code") ? value : arr[i]?.currency_code, financial_asset_code: (id === "financial_asset_code") ? value : arr[i]?.financial_asset_code, financial_asset_details: [],
                amount: (id === "amount") ? Number(value) : arr[i]?.amount, exchange_rate_ils: (id === "exchange_rate_ils") ? Number(value) : arr[i]?.exchange_rate_ils,
                amount_ils: (id === "amount") ? value * arr[i]?.exchange_rate_ils : (id === "exchange_rate_ils") ? value * arr[i]?.amount : arr[i]?.amount_ils,
                contra_id_account: ""
            }
            arr[i] = temp
            let sum = 0;
            if (id === "amount" || id === "exchange_rate_ils") {
                for (let i = 0; i < arr.length; i++) {
                    sum += arr[i].amount_ils
                }
                setsumIn(sum)
            }
        }
        else {
            arr.push({
                currency_code: (id === "currency_code") ? value : arr[i]?.currency_code, financial_asset_code: (id === "financial_asset_code") ? value : arr[i]?.financial_asset_code, financial_asset_details: [],
                amount: (id === "amount") ? value : arr[i]?.amount, exchange_rate_ils: (id === "exchange_rate_ils") ? value : arr[i]?.exchange_rate_ils, amount_ils: (id === "amount") ? value * arr[i]?.exchange_rate_ils : (id === "exchange_rate_ils") ? value * arr[i]?.amount : arr[i]?.amount_ils, contra_id_account: ""
            })
        }
        change("financial_assets_in", arr)
    }
    const change = async (key: string, value: any) => {
        await setConvertions({ ...convertions, [key]: value })
    }
    useEffect(() => {
        if (defaultCurrencyCode) {
            let arr = [...convertions.financial_assets_in]
            arr[0].currency_code = defaultCurrencyCode
            setConvertions({ ...convertions, "financial_assets_in": arr })
        }

    }, [defaultCurrencyCode])
    useEffect(() => {
        if (defaultfinanssialAsset) {
            let arr = [...convertions.financial_assets_in]
            arr[0].financial_asset_code = defaultfinanssialAsset
            setConvertions({ ...convertions, "financial_assets_in": arr })
        }

    }, [defaultfinanssialAsset])
    return (
        <>
            <HeaderUtils title={t("foreignCurrency")} subTitle={"false"} createBtn={false} editBtn={false} deleteBtn={false} disabledSave={true} uploadBtn={false} />
            <br></br><br></br>
            <Subtitle title={"ספירת מט''ח ומט''י"} />
            <div className={`content-div-${dir}`} >
                <p className="title-text" >{t("actionDetails")}</p>
                <hr className="hr" style={{ width: "800px" }}></hr>
                <div style={{ width: "20%" }}>
                    <CustomTextField value={''} readOnly={readOnly} type="date" label={"תאריך"} onChange={''} id={'dateAction'} />
                    <CustomTextField value={''} readOnly={readOnly} label={"שם הסופר"} onChange={''} id={'name'} />
                    <CustomTextField value={''} readOnly={readOnly} label={"מקום הספירה"} onChange={''} id={'place'} />
                </div>
                <div className={`warpper-fields-rightCon-${dir}`} >
                    <div style={{ width: "520px" }} >
                        <div className="titleC">
                            <p className="title-text boldC" >{"מצאי מט''ח ומט''י"}</p>
                        </div>
                    </div>
                    <hr className="hr" style={{ width: "520px", height: "2px" }} ></hr>

                    <div style={{ width: "520px" }}>
                        <div style={{ width: "100%" }}>
                            <div className="titleC" style={{ backgroundColor: "#E1E1E1" }}>
                                <p style={{ width: "20%", marginBottom: '0' }}>{t("currency")}</p>
                                <p style={{ width: "20%", marginBottom: '0' }}>{t("type")}</p>
                                <p style={{ width: "20%", marginBottom: '0' }}>{t("amount")}</p>
                                <p style={{ width: "20%", marginBottom: '0' }}>{t("gate")}</p>
                                <p style={{ width: "20%", marginBottom: '0' }}>{t("totalILS")}</p></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", height: "2px", marginBottom: '0' }} ></hr>
                        {convertions.financial_assets_in?.map((c, index) =>
                            <div key={index} style={{ width: "100%", marginTop: "5px" }}>
                                <div className="inlineB">
                                    <div style={{ width: "17%" }}>
                                        <CustomDropdown otherInputId={''} hasOtherValue={false} options={enumsDropdown.currencyCode} label={t('')} onChange={addFinancialIn} selectedKey={c?.currency_code} id={`currency_code,${index}`} othertextInput={t('')} />
                                    </div>
                                    <div style={{ width: "17%" }}>
                                        <CustomDropdown otherInputId={''} hasOtherValue={false} options={enumsDropdown.finanssialAssetCode} label={t('')} onChange={addFinancialIn} selectedKey={c?.financial_asset_code} id={`financial_asset_code,${index}`} othertextInput={t('')} />
                                    </div>
                                    <div style={{ width: "17%" }}>
                                        {/* convertions.financial_assets_in[index]?.amount */}
                                        <CustomTextField label={''} min="0" type="NUMBER" value={c?.amount} onChange={addFinancialIn} id={`amount,${index}`} /></div>
                                    <div style={{ width: "17%" }}>
                                        <CustomTextField label={''} min="0" type="NUMBER" value={c?.exchange_rate_ils} onChange={addFinancialIn} id={`exchange_rate_ils,${index}`} /></div>
                                    <div style={{ width: "17%" }}>
                                        <CustomTextField label={''} type="NUMBER" value={c?.amount_ils} readOnly={true} onChange={addFinancialIn} id={`amount_ils,${index}`} /></div>
                                    <p className="deleteX" onClick={() => removeFObject(index)}>x</p></div>
                            </div>

                        )}
                        <hr className="hr" style={{ width: "100%", marginTop: "3px", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className="inlineB">
                                <p className={`total-stocking-${dir}`} >{"סה''כ: " + " " + sumIn + " " + t("ILS")}</p></div>
                        </div>
                    </div>
                </div>
                <PrimaryButton className={`bottun-${dir}`} allowDisabledFocus checked={false} name="finish" text={t("reception")} />

            </div>

        </>
    )
}

export default Stocktaking
