export const globalPages = {
    customer_search: {
        key: "customer-search",
        iconProps: { iconName: "SearchIcon" },
        styles: {
            icon: { color: '#1A1F71' }
        },
        name: "customerSearch",
        url: "/customer_search"
    },
    customer_details: {
        key: "customer-details",
        iconProps: { iconName: "UserIcon" },
        styles: {
            icon: { color: '#1A1F71', fontWeight: "light" }
        },
        url: "/customer_details",
        name: "customerDetails",

        component: "CustomerDetails"
    },
    customer_related_entities: {
        key: "related-factors",
        iconProps: { iconName: "UserFriendsIcon" },
        styles: {
            icon: { color: '#1A1F71' },
        },
        url: "/customer_related_entities",
        name: "RelatedFactors",

        component: "CustomerDetails"
    },
    customer_bank_accounts: {
        key: "bank-accounts",
        iconProps: { iconName: 'CardIcon' },
        url: "/customer_bank_accounts",
        component: "BankAccounts",
        name: "bankAccounts"
    },
    customer_kyc_questionnaire: {
        key: "money-laundering",
        iconProps: { iconName: "UserShieldIcon" },
        url: "/customer_kyc_questionnaire",
        component: "MoneyLaundering",
        name: "money_laundering"
    },
    service_receiver_statement: {
        key: "service-receiver-statement",
        iconProps: { iconName: "UserSecretIcon" },
        url: "/service_receiver_statement",
        component: "GetService",
        name: "service_requester_statement"
    },
    customer_contacts: {
        key: "contact",
        iconProps: { iconName: "IdCardIcon" },
        url: "/customer_contacts",
        component: "Contact",
        name: "contactInformation"
    },
    customer_documentation: {
        key: "documents",
        iconProps: { iconName: "FileUserIcon" },
        url: "/customer_documentation",
        component: "Documents",
        name: "documents_b"
    },
    customer_customer_accounts: {
        key: "customer-accounts",
        url: "/customer_customer_accounts",
        component: "CustomerAccounts",
        iconProps: { iconName: "ChalkboardTeacherIcon" },
        name: "customer_account"
    },
    customer_obligation_collateral: {
        key: "obligo",
        iconProps: { iconName: "infoIcon" },
        url: "/customer_obligation_collateral",
        component: "Obligo",
        name: "obligo"
    },
    customer_price_program: {
        key: "customer-plan",
        iconProps: { iconName: "UserTagIcon" },
        styles: {
            icon: { color: '#1A1F71' }
        },
        url: "/customer_price_program",
        name: "customerPlan",
        component: "CustomerPlan"
    },
    accounts_chart: {
        key: "accounts",
        url: "/accounts_chart",
        iconProps: { iconName: "LandmarkIcon" },
        name: "accountsChart"
    },
    money_in: {
        key: "currency-entry",
        url: "/money_in",
        styles: {
            root: { marginRight: "0px", margin: "0px,0px" },
            text: { marginRight: "0px", margin: "0px,0px" }
        },
        iconProps: { iconName: "MoneyCheckIcon" },
        name: "foreignCurrencyEntry"
    },
    journal_entries: {
        key: "log-order",
        className: "side-sub",
        iconProps: { iconName: "MoneyCheckIcon" }
        , styles: {
            icon: { color: '#1A1F71' }
        },
        name: "logOrder",
        url: "/journal_entries",
    },
    invoice: {
        key: "invoice",
        className: "side-sub",
        iconProps: { iconName: "InvoiceIcon" }
        , styles: {
            icon: { color: '#1A1F71' }
        },
        name: "invoice",
        url: "/invoice",
    },
    receipt: {
        key: "receipt",
        className: "side-sub",
        iconProps: { iconName: "ReceiptIcon" }
        , styles: {
            icon: { color: '#1A1F71' }
        },
        name: "receipt",
        url: "/receipt",
    },
    documents: {
        key: "documents",
        className: "side-sub",
        iconProps: { iconName: "ThListIcon" }
        , styles: {
            icon: { color: '#1A1F71' }
        },
        name: "documents",
        url: "/documents",
    },
    main_accounts: {
        key: "bookkeeping",
        url: "/main_accounts",
        iconProps: { iconName: "UserIcon" },
        name: "mainAccount"
    },
    exchange: {
        key: "currency_exchange",
        url: "/exchange",
        iconProps: { iconName: "CoinsIcov" },
        name: "currencyExchange"

    },
    exchange_transactions_list: {
        key: "transaction-convert",
        url: "/exchange_transactions_list",
        iconProps: { iconName: "ThListIcon" },
        name: "transactionConvert"
    },
    cheque_cashing: {
        key: "check-discounts",
        className: "side-sub",
        iconProps: { iconName: "MoneyCheckEditIcon" }
        , styles: {
            icon: { color: 'red' }
        },
        name: "checkDiscounts",
        url: "/cheque_cashing"
    },
    cheques_management: {
        key: "check-management",
        className: "side-sub",
        iconProps: { iconName: "MoneyCheckIcon" }
        , styles: {
            icon: { color: '#1A1F71' }
        },
        name: "checkManagement",
        url: "/cheques_management"

    },
    cheque_cashing_transactions_list: {
        key: "discount-transaction",
        className: "side-sub",
        iconProps: { iconName: "ThListIcon" }
        , styles: {
            icon: { color: '#1A1F71' }
        },
        name: "discountTransaction",
        url: "/cheque_cashing_transactions_list"
    },
    exchange_pnl: {
        key: "sumProfit",
        url: "/exchange_pnl",
        iconProps: { iconName: "AbacuseIcon" },
        name: "sumProfit"
    },
    impa_ctr: {
        key: "regularReports",
        url: "/impa_ctr",
        iconProps: { iconName: "FileIcon" },
        name: "regularReports"
    },
    impa_utr: {
        key: "impa_utr",
        url: "/impa_utr",
        iconProps: { iconName: "FileIcon" },
        name: "unRegularReports"
    },  
     reports: {
        key: "reports",
        url: "/reports",
        iconProps: { iconName: "FileIcon" },
        name: "reports"
    },
    // documents: {
    //     key: "documents",
    //     className: "side-sub",
    //     iconProps: { iconName: "ThListIcon" }
    //     , styles: {
    //         icon: { color: '#1A1F71' }
    //     },
    //     name: t("search"),
    //     url: "/documents"
    // },
    branch_setting: {
        key: "branch_settings",
        url: "/branch_setting",
        iconProps: { iconName: "CogsIcon" },
        name: "branchSettings"
    },
impa_utr_filter_defaults: {
        key: "impa_utr_filter_defaults",
        url: "/impa_utr_filter_defaults",
        iconProps: { iconName: "CogsIcon" },
        name: "usersSettings"
    },
    price_program_templates: {
        key: "price-plan",
        url: "/price_program_templates",
        iconProps: { iconName: "planIcon" },
        name: "planPrice"
    },
    users: {
        key: "users",
        url: "/users",
        component: "Users",
        iconProps: { iconName: "UsersMedicalIcon" },
        name: "users"
    },
    ui_alerts: {
        key: "ui-alerts",
        url: "/ui_alerts",
        iconProps: { iconName: "UsersMedicalIcon" },
        name: "uiAlerts"
    },
    fair_credit: {
        key: "role-fair-credit",
        url: "/fair_credit",
        iconProps: { iconName: "CreditIcon" },
        name: "roleFairCredit"
    },
    email_setting: {
        key: "userEmail",
        url: "/email_setting",
        iconProps: { iconName: "EmailIcon" },
        name: "userEmail"
    },
}
