import axios from "axios";

import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { readCustomerId } from "./customerActioin";
import { basicUrl } from "../../shared/config";
import { CreateAddress, updateAddress } from './addressAction'
import { CreatePhone, updatePhone } from './phonecAction'
import { CreateEmail, updateEmail } from './emailAction'
import { READ_CUSTOMER } from './types'
import { setTypeId } from '../../shared/components/CustomHooks/CheckCutomerType'
import moment from "moment";
import { readDraftTransactions } from "./convertionActions";

let currUserCredentials: IUserCredentials | null = null

export const createInsider = (insider: any, userCredentials: IUserCredentials | null, idEntity: string, setShowDialogSave: any
    , setShowDialogFailed: any, setshowSpiner?: any, setRelatedFactors?: any, isConversions?: boolean, setMessageError?: any, isEntityTransaction?: any) => async (dispatch: Function) => {
        currUserCredentials = userCredentials
        if ((insider.gender === "1" || insider.gender === "2") && insider.idCountryCode === "IL") insider.classId = "1";
        if ((insider.gender === "1" || insider.gender === "2") && insider.idCountryCode === "XP") insider.classId = "6";
        if ((insider.gender === "1" || insider.gender === "2") && insider.idCountryCode !== "IL" && insider.idCountryCode !== "XP") return "2";
        if (insider.gender === "3" && insider.idCountryCode === "IL") insider.classId = "3";
        if (insider.gender === "3" && insider.idCountryCode !== "IL") insider.classId = "4";
        if (insider.gender === "4") insider.classId = "5";
        const createInsider = {
            "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
            "id_client": currUserCredentials && currUserCredentials["idClient"],
            "id_branch": currUserCredentials && currUserCredentials["idBranch"],
            "id_customer": isConversions ? "" : idEntity,
            "entity_request_method": "create",
            "ID_country_code": insider?.idCountryCode,
            "ID_number": insider?.idNumber,
            "ID_type_id": insider?.idTypeId,
            "status_id": insider?.statusId,
            "class_id": insider?.classId,
            "customer_type_id": insider?.customerTypeId ? insider?.customerTypeId : await setTypeId(insider?.idTypeId),
            "entity_type_id": "insider",
            // "entity_sub_type_id": insider?.entitySubTypeId,
            // "entity_sub_type_name": insider?.factorTypeName,
            "first_name": insider?.firstName,
            "last_name": insider?.lastName,
            "entity_name": insider?.firstName + " " + insider?.lastName,
            // "first_name_en": "Orev",
            // "last_name_en": "Arvoni",
            // "entity_name_en": "Orev Arvony",
            "date_birth": insider?.dateBirth,
            "gender_id": insider?.gender,
            "class_other": insider?.classOther ? insider?.classOther : null,
            "gender_other": insider?.genderOther ? insider?.genderOther : null,
            // "2EDE8ED3-2E0A-EC11-ADD7-005056AA2213"
            "id_identifier": insider?.workerName ? insider?.workerName : currUserCredentials && currUserCredentials["idInitiator"],
            "is_identified": true,
            "is_loaded_documentation": false,
            "is_default": insider?.default,
            "is_locked": false,
            "note": insider?.note ? insider?.note : null,
            "is_general": 1,
            "return_entity": true
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const body = JSON.stringify(createInsider);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };
        await axios.post(basicUrl + '/entity', body, config)
            .then(async res => {
                try {
                    if (res.status === 200) {
                        if (res.data["err_code"] === 0) {
                            await CreateAddress(insider, userCredentials, res.data.data.id_entity, "", "insider");
                            await CreatePhone(insider, userCredentials, res.data.data.id_entity, "", "insider");
                            await CreateEmail(insider, userCredentials, res.data.data.id_entity, "", "insider")
                            if (setRelatedFactors) {
                                insider.key = res.data.data.id_entity
                                await setRelatedFactors(insider)
                            }
                            isConversions ?
                                setRelatedFactors(insider)
                                // await dispatch(readCustomerId(res.data.data.id_entity, userCredentials, "", "", "", false, setshowSpiner, "", "", setRelatedFactors))
                                : await dispatch(attachInsiderToCustomer(insider, userCredentials, idEntity, res.data.data.id_entity, setshowSpiner,isEntityTransaction))
                            setMessageError && setMessageError("false")
                            // setRelatedFactors ? dispatch(readCustomerId(res.data.data.id_entity, userCredentials, "", "", "", false, "", "", setRelatedFactors))
                            //     : dispatch(readCustomerId(idEntity, userCredentials, "", "", "", false))
                            setshowSpiner && setshowSpiner(false)
                            setShowDialogSave && setShowDialogSave(true)
                        }
                        else {
                            // res.data["err_code"] === 50000 && setMessageError && setMessageError("true")
                            // setshowSpiner && setshowSpiner(false)
                            if (res.data["err_code"] === 50000 || res.data["err_code"] === 50002) {
                                setMessageError && setMessageError("true")
                                setshowSpiner && setshowSpiner(false)
                            }
                            else {
                                setshowSpiner && setshowSpiner(false)
                                setMessageError && setMessageError(res.data?.ui_message?.ui_message)
                                setShowDialogFailed && setShowDialogFailed(true)
                            }

                        }
                    }
                    else {
                        setshowSpiner(false)
                    }
                }
                catch (err) {
                    // setShowDialogFailed(true)
                    setshowSpiner(false)
                    console.log('error in create insider',)
                }
            })
    }
///// in create insider attach insider to customer
export const attachInsiderToCustomer = (insider: any, userCredentials: IUserCredentials | null, idEntity: string, idInsider: any, setshowSpiner?: any, isEntityTransaction?: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const attachInsider = {
        "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
        "id_client": currUserCredentials && currUserCredentials["idClient"],
        "id_branch": currUserCredentials && currUserCredentials["idBranch"],
        "id_insider": idInsider,
        "id_entity": idEntity,
        "insider_type_id": insider?.entitySubTypeId,
        "return_entity": 1,
        "is_default": insider?.default ? insider?.default : false,
        "attribute_request_method": "create",
        "attribute": "insider",
        "entity_type_id": "customer"
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(attachInsider);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    await axios.post(basicUrl + '/entity', body, config)
        .then(async res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        if (isEntityTransaction) {
                            dispatch(readDraftTransactions("exchange", userCredentials, idEntity, isEntityTransaction))
                        }
                        else {
                            dispatch({
                                type: READ_CUSTOMER,
                                payload: res.data.data
                            })
                        }
                        setshowSpiner && setshowSpiner(false)
                    }
                }

            }
            catch (err) {
                console.log('error in attach Insider ToCustomer', err)
            }
        })
}

export const updateInsiderDetails = (insider: any, userCredentials: IUserCredentials | null, idEntity: string, insiderId: string, setShowDialogUpdate: any, setShowDialogFailed: any, setMessageError?: any, setshowSpiner?: any, setRelatedFactors?: any, attach?: any, isEntityTransaction?: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    if ((insider.gender === "1" || insider.gender === "2") && insider.idCountryCode === "IL") insider.classId = "1";
    if ((insider.gender === "1" || insider.gender === "2") && insider.idCountryCode === "XP") insider.classId = "6";
    if ((insider.gender === "1" || insider.gender === "2") && insider.idCountryCode !== "IL" && insider.idCountryCode !== "XP") insider.classId = "2";
    if (insider.gender === "3" && insider.idCountryCode === "IL") insider.classId = "3";
    if (insider.gender === "3" && insider.idCountryCode !== "IL") insider.classId = "4";
    if (insider.gender === "4") insider.classId = "5";


    const updateInsider = {
        "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
        "id_client": currUserCredentials && currUserCredentials["idClient"],
        "id_branch": currUserCredentials && currUserCredentials["idBranch"],
        "id_customer": idEntity,
        "id_entity": insiderId,
        "entity_request_method": "update",
        "ID_country_code": insider?.idCountryCode,
        "ID_number": insider?.idNumber,
        "ID_type_id": insider?.idTypeId,
        "status_id": insider?.statusId,
        "class_id": insider?.classId,
        "entity_type_id": "insider",
        "entity_sub_type_id": insider?.entitySubTypeId,
        // "entity_sub_type_name": insider?.factorTypeName,
        "first_name": insider?.firstName,
        "last_name": insider?.lastName,
        "entity_name": insider?.firstName + " " + insider?.lastName,
        // "first_name_en": "Orev",
        // "last_name_en": "Arvoni",
        // "entity_name_en": "Orev Arvony",
        //d
        // "date_birth": moment(insider?.dateBirth).format("YYYY-MM-DD"),
        "date_birth": insider?.dateBirth,
        "gender_id": insider?.gender,
        "class_other": insider?.genderOther ? insider?.genderOther : null,
        "gender_other": insider?.genderOther ? insider?.genderOther : null,
        "return_entity": true,
        "is_default": insider?.default ? insider?.default : false,
        "note": insider?.note ? insider?.note : null,
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(updateInsider);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    await axios.post(basicUrl + '/entity', body, config)
        .then(async res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        insider?.addressId ?
                            await updateAddress(insider, userCredentials, res.data.data.id_entity, insider?.addressId, "", "", "", "insider")
                            : (insider?.addressCity||insider?.addressCityName) && await CreateAddress(insider, userCredentials, res.data.data.id_entity, "", "insider");
                        insider?.telephoneId ? await updatePhone(insider, userCredentials, res.data.data.id_entity, insider?.telephoneId, "", "", "", "insider") :
                            insider?.telephone && await CreatePhone(insider, userCredentials, res.data.data.id_entity, "", "insider");
                        insider?.emailId ? await updateEmail(insider, userCredentials, res.data.data.id_entity, insider?.emailId, "", "", "", "insider")
                            : insider?.email && await CreateEmail(insider, userCredentials, res.data.data.id_entity, "", "insider")
                        !setRelatedFactors ? await dispatch(updateInsiderType(insider, userCredentials, idEntity, isEntityTransaction)) :
                            setRelatedFactors(insider)
                        // await dispatch(readCustomerId(res.data.data.id_entity, userCredentials, "", "", "", false, "", "", "", setRelatedFactors))
                        attach && await dispatch(attachInsiderToCustomer(insider, userCredentials, idEntity, res.data.data.id_entity, setshowSpiner, isEntityTransaction))
                        setshowSpiner && setshowSpiner(false)
                        setShowDialogUpdate && setShowDialogUpdate(true)
                    }
                    else
                        setshowSpiner && setshowSpiner(false)
                }
            }
            catch (err) {
                setShowDialogFailed && setShowDialogFailed(true)
                setMessageError && setMessageError(res.data?.ui_message?.ui_message)
                setshowSpiner && setshowSpiner(false)
                console.log('error in update colletrall',)
            }
        })
}


export const updateInsiderType = (insider: any, userCredentials: IUserCredentials | null, idEntity: string, isEntityTransaction?: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const updateInsider = {
        "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
        "id_client": currUserCredentials && currUserCredentials["idClient"],
        "id_branch": currUserCredentials && currUserCredentials["idBranch"],
        "id_insider": insider.key,
        "id_entity": idEntity,
        "insider_type_id": insider?.entitySubTypeId,
        "return_entity": 1,
        "is_default": insider?.default,
        "attribute_request_method": "update",
        "attribute": "insider",
        "entity_type_id": "customer"
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(updateInsider);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/entity', body, config)
        .then(async res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        if (!isEntityTransaction) {
                            await dispatch(readCustomerId(idEntity, userCredentials))
                        }
                        // setRelatedFactors ? dispatch(readCustomerId(res.data.data.id_entity, userCredentials, "", "", "", false, "", "", setRelatedFactors))
                        //     : dispatch(readCustomerId(idEntity, userCredentials, "", "", "", false))
                    }
                }
            }
            catch (err) {
                console.log('error in create insider',)
            }
        })
}


export const deleteInsider = (insider: any, userCredentials: IUserCredentials | null, idEntity: string, setShowDialogSave: any, setShowDialogFailed: any, setshowSpiner?: any, setRelatedFactors?: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const deleteInsider = {
        "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
        "id_client": currUserCredentials && currUserCredentials["idClient"],
        "id_branch": currUserCredentials && currUserCredentials["idBranch"],
        "id_insider": insider.key,
        "id_entity": idEntity,
        "insider_type_id": insider?.entitySubTypeId,
        "return_entity": 1,
        "is_default": insider?.default,
        "attribute_request_method": "delete",
        "attribute": "insider",
        "entity_type_id": "customer"
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(deleteInsider);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/entity', body, config)
        .then(async res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        await dispatch(readCustomerId(idEntity, userCredentials))
                        // await CreateAddress(insider, userCredentials, res.data.data.id_entity, "", "insider");
                        // await CreatePhone(insider, userCredentials, res.data.data.id_entity, "", "insider");
                        // await CreateEmail(insider, userCredentials, res.data.data.id_entity, "", "insider")
                        // setRelatedFactors ? dispatch(readCustomerId(res.data.data.id_entity, userCredentials, "", "", "", false, "", "", setRelatedFactors))
                        //     : dispatch(readCustomerId(idEntity, userCredentials, "", "", "", false))
                        setshowSpiner(false)
                        // setShowDialogSave(true)
                    }
                }
                else {
                    setshowSpiner(false)
                }
            }
            catch (err) {
                // setShowDialogFailed(true)
                setshowSpiner(false)
                console.log('error in create insider',)
            }
        })
}