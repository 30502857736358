import { SET_DATA_INVOICE, RESET_DATA_INVOICE,SET_DATA_HOME_PAGE_ACCOUNT
 } from '../actions/types';

const initialState = {
    dataAccount: null,
    saveDraftChanges: null,
    dataHomePageAccount:null
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SET_DATA_INVOICE: {
            sessionStorage.setItem('dataAccount', JSON.stringify(action.payload));
            return {
                ...state,
                dataAccount: action.payload,
            }
        }
        case RESET_DATA_INVOICE: {
            sessionStorage.setItem('dataAccount', JSON.stringify(action.payload));
            return {
                ...state,
                dataAccount: null
            }
        }
        case SET_DATA_HOME_PAGE_ACCOUNT: {
            // sessionStorage.setItem('dataAccount', JSON.stringify(action.payload));
            return {
                ...state,
                dataHomePageAccount: action.payload,
            }
        }
       
        default: return state;
    }
}