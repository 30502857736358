export default function ExportFactorsInvolved(props: any) {
    const { dataOfEntities } = props
    const arrTitle = ["שם פרטי ושם משפחה / תאגיד", "מספר זהות / דרכון / רישום", "קשר לנושא הידיעה", "סוג כתובת", "שם בעל הכתובת"
        , "מדינה/ אזור-מחוז", "מקום ישוב", "רחוב ומספר", "מיקוד", "ת.ד", " הערות נוספות"]


    return (
        <div className='div-width'>
            <h6 className='title-page' style={{
                textAlign: "center"
                , fontWeight: "bold"
                , textDecoration: "underline 2px",
                fontSize: "14px"
            }}><u>גורמים המעורבים בתוכן הידיעה</u></h6>

            <div>
                <div style={{
                    height: "900px",
                    display: "inline-block",
                }}><div style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left bottom"
                }}>
                        <table style={{ width: "100%", fontSize: "12px", fontFamily: "david" }}>
                            <tbody>
                                <tr>
                                    {arrTitle?.map((item: any, i: any) => (

                                        <>
                                            {i === 0 ? <th style={{ borderBottom: " 1px solid black", borderTop: " 1px solid black", borderRight: "1px solid black", borderLeft: "1px solid black", textAlign: "center" }}>
                                                {item}</th>
                                                :
                                                <th style={{ borderBottom: " 1px solid black", borderTop: " 1px solid black", borderLeft: "1px solid black", textAlign: "center" }}>
                                                    {item}</th>}
                                        </>
                                    ))}
                                </tr>
                                {dataOfEntities?.related_entities?.map((entity: any, index: number) => (
                                    <tr key={index}>
                                        <td style={{ borderBottom: " 1px solid black", borderRight: "1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{entity?.entity_name}</td>
                                        <td style={{ borderBottom: " 1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{entity?.ID_number}</td>
                                        <td style={{ borderBottom: " 1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{""}</td>
                                        <td style={{ borderBottom: " 1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{entity?.address?.address_type_name}</td>
                                        <td style={{ borderBottom: " 1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{""}</td>
                                        <td style={{ borderBottom: " 1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{entity?.address?.address_state ?? ""}</td>
                                        <td style={{ borderBottom: " 1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{entity?.address?.address_city ?? ""}</td>
                                        <td style={{ borderBottom: " 1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{entity?.address?.address_street ?? "" + " " + entity?.address?.address_number ?? ""}</td>
                                        <td style={{ borderBottom: " 1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{entity?.address?.address_zip_code ?? ""}</td>
                                        <td style={{ borderBottom: " 1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{""}</td>
                                        <td style={{ borderBottom: " 1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{""}</td>
                                    </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
