import { DefaultButton, Dialog,ContextualMenu, } from '@fluentui/react';
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useBoolean } from '@fluentui/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CustemTable from '../../shared/components/tabels/tableList';
import { useSelector } from 'react-redux';

export default function AccountsBookKeepingDialog(props: any) {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const { setShowDialog } = props
    useEffect(() => {
        if (setShowDialog) {
            toggleHideDialog()
        }
    }, [setShowDialog,toggleHideDialog])

    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
      };
    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        dragOptions: dragOptions 
    };

    const columns = [
        { key: '1', name: t('accountName'), fieldName: "accountName", entity_name: "accountName", minWidth: 80, maxWidth: 80 },
        { key: '2', name: t('accountNumber'), fieldName: "accountNumber", entity_name: "accountNumber", minWidth: 80, maxWidth: 80 },
        { key: '3', name: t('bank'), fieldName: "bank", entity_name: "bank", minWidth: 80, maxWidth: 80 },
        { key: '4', name: t('branch'), fieldName: "branch", entity_name: "branch", minWidth: 80, maxWidth: 80 },
        { key: '5', name: t('branchAddress'), fieldName: "branchAddress", entity_name: "branchAddress", minWidth: 80, maxWidth: 80 },
    ];
    const items = [
        { key: 1, accountName: 'עסקי', accountNumber: '7822256', bank: '15- דיסקונט', branch: '112', branchAddress: 'יפו 66 ירושלים' },
        { key: 2, accountName: 'עסקי', accountNumber: '5852412', bank: '12- דיסקונט', branch: '112', branchAddress: 'יפו 66 ירושלים' },
    ]


    return (
        <Dialog minWidth="xl" maxWidth="xl" modalProps={modelProps} hidden={hideDialog} onDismiss={() => { toggleHideDialog(); setShowDialog(false) }} >
            <div style={{ width: "100%" }}>
                <DefaultButton className={`button-account-hide-${dir}`} onClick={() => { toggleHideDialog(); setShowDialog(false) }} text={t("cancel")} />
                <div style={{ display: "flex" }}>
                    <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{t('BookkeepingAccount')}</p>
                    <FontAwesomeIcon icon={faPlus} title={t("createCustomer")} style={{}} onClick={() => alert("cref")} className={`iconRefresh-${dir}`} />
                </div>
                <hr className="hr"></hr>
                <div style={{width:"36vw"}}>
                    <CustemTable tableName="attachedFiles" columns={columns} allItems={items} renderItemColumn rederRow={"operationsFiles"} addCustem={true} isFooter={false} />
                </div>
            </div>
        </Dialog>
    )
}
