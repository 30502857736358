import React, { useEffect } from "react";
import { Dropdown, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import ComponenetProps from "../../modelsType/type/interface";
import { useState } from "react";
import "../../scssPages/form.scss";
import { CustomTextField } from "./TextField";
import { useSelector } from "react-redux";

interface MyProps extends ComponenetProps {
  options: any;
  label: string;
  selectedKey: any;
  othertextInput: string;
  hasOtherValue: boolean;
  otherInputId: any;
  readOnly?: boolean;
  otherValue?: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  requiredField?: boolean;
  tabIndex?: any;
  onKeyDown?: any;
  dropdownRef?: any;
  IsTabIndex?: any;
  placeholder?: string;
}

// const dropdownStyles = { dropdown: { width: 300 } };
const dropdownStyles: Partial<IDropdownStyles> = {
  // '#FAF9F8'
  title: { backgroundColor: "white" },
  callout: { minWidth: "fit-content", maxwidth: "fit-content" },
};
const dropdownStylesRead: Partial<IDropdownStyles> = {
  title: { backgroundColor: "white" },
};
const dropdownStylesRequired: Partial<IDropdownStyles> = {
  title: { backgroundColor: "#FAF9F8  !important", border: "solid rgb(219, 56, 56) 3px !important" },
  callout: { minWidth: "fit-content", maxwidth: "fit-content" },
};
export const CustomDropdown: React.FunctionComponent<MyProps> = (props) => {
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);

  const {
    readOnly,
    onChange,
    options,
    label,
    dropdownRef,
    selectedKey,
    id,
    othertextInput,
    onKeyDown,
    otherValue,
    hasOtherValue,
    otherInputId,
    disabled,
    requiredField,
    IsTabIndex,
    placeholder,
  } = props;
  const [, /*selected*/ setSelected] = useState<String | undefined>("");
  const [, /*selectedIndex*/ setSelectedIndex] = useState<number | undefined>(
    0
  );
  const [selectedText, setselectedText] = useState("");
  const [, /*isOther*/ setisOther] = useState(false);

  useEffect(() => {
    if (options !== undefined) {
      if (
        selectedKey !== undefined &&
        selectedKey !== "" &&
        options.length > 0
      ) {
        options?.forEach((option: any) => {
          if (option.key === selectedKey) {
            setselectedText(option.text);
            if (option.is_other === true) setisOther(true);
            return;
          }
        });
      } else if (selectedKey === "" || selectedKey === undefined) setselectedText("");
    }
  }, [options, selectedKey]);
  return (
    <div>
      {!readOnly ? (
        <Dropdown
          disabled={disabled}
          label={label}
          onChange={(e, selectedOption) => {
            setSelected(selectedOption?.text);
            onChange(id, selectedOption?.key, selectedOption?.text);
            let getSelectedIndex = options.findIndex(
              (x: any) => x.text === selectedOption?.text
            );
            setSelectedIndex(getSelectedIndex);
          }}
          placeholder={placeholder}
          ref={dropdownRef}
          options={options}
          tabIndex={IsTabIndex === true ? -1 : 0}
          onKeyDown={onKeyDown}
          selectedKey={selectedKey}
          className="text-field"
          aria-readonly={false}
          styles={
            dir === "ltr" ? ({
              caretDownWrapper: {
                left: "8px !important",
                right: "none !important",
              },
              title: { padding: "0px 8px 0px 8px !important" },
            })
              : ({
                caretDownWrapper: {
                  left: "none !important",
                  right: "8px !important",
                },
              }) &&
                requiredField ? dropdownStylesRequired : (readOnly ? dropdownStylesRead : dropdownStyles)
          }
        ></Dropdown>
      ) : (
        <CustomTextField
          value={selectedText}
          requiredField={requiredField}
          readOnly={true}
          label={label}
          onChange={""}
          id={""}
        />
      )}
      {hasOtherValue && selectedText === "אחר" ? (
        <CustomTextField
          readOnly={readOnly}
          label={othertextInput}
          value={otherValue}
          onChange={onChange}
          id={otherInputId}
        />
      ) : (
        false
      )}
    </div>
  );
};
