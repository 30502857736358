import axios from 'axios'
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT,
  SET_MODULES_USER_BRANCH
} from '../actions/types'
import { IConfigHeaders } from '../../modelsType/type/interface';
import { User } from '../../modelsType/User';
import { basicUrl } from '../../shared/config';
import { IUserCredentials } from '../../components/customerDetails/CustomerDetails';
// import swal from 'sweetalert2';
// import Login from '../../components/auth/Login';
//cheack token&load user
// const api=
// export const loadUser = () => (dispatch: Function, getState: Function) => {
//   const entity =
//   {
//     "id_client ": 45654784,

//     "enum_type": "class",

//     "id_initiator": 478541,

//     "enum_request_method": "read",

//     "category": "customer",

//     "user_language": "HE"
//   }
//   const body = JSON.stringify(entity);

//   //User loading
//   dispatch({ type: USERֹֹ_LOADING });

//   axios.post(basicUrl + "/uspEnum", body, tokenConfig(getState))
//     .then((res) => {
//       try {
//         dispatch({
//           type: USERֹֹ_LOADED,
//           payload: res.data
//         })
//       }
//       catch (e) {
//       }
//     })
//     .catch(err => {
//       err.response ? returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL') : returnErrors('the server is down pls try later', 'LOGIN_FAIL')
//       dispatch({
//         type: AUTH_ERROR
//       })
//     })
// }




// Login User
// export const login = (loginUser: User, setShowDialogSave: any, setshowSpiner: any, loginSuccess?: any) => (
//   dispatch: Function
// ) => {
//   // Headers
//   // const config = {
//   //   headers: {
//   //     'Content-Type': 'application/json'
//   //   }
//   // };
//   const user = {
//     "login_request_method": "login",
//     "login_entity_number": loginUser.LoginEntityNumber,
//     "login_finger_prints": "oilkl– lkoijhj– i987– 9089",
//     "ip": "123– 456– 678",
//     "login_ID": loginUser.LoginID,
//     "login_password": loginUser.LoginPassword
//   }
//   axios
//     .post(basicUrl + '/login',
//       user,
//       {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       }
//     )
//     .then(res => {
//       if (res.data === "dont login") {
//         dispatch({
//           type: LOGIN_FAIL,
//           isAuthentitcated: false
//         })
//       }
//       else {
//         setshowSpiner(false)
//         loginSuccess && loginSuccess(true)
//         dispatch({
//           type: LOGIN_SUCCESS,
//           payload: res.data
//         })
//         dispatch({ type: 'SET_GENERAL_FORM_OPTIONS_MAP', payload: res.data.data.enums })
//       }
//     })
//     .catch(err => {
//       setshowSpiner(false)
//       setShowDialogSave(true)
//     });
// };



export const login = (loginUser: User, setShowDialogSave: any, setshowSpiner: any, loginSuccess?: any) => (
  dispatch: Function
) => {
  // Headers
  // const config = {
  //   headers: {
  //     'Content-Type': 'application/json'
  //   }
  // };
  console.log(loginUser,"loginUser");
  
  const user = {
    "login_request_method": "login",
    "login_entity_number": loginUser.LoginEntityNumber,
    "login_finger_prints": "oilkl– lkoijhj– i987– 9089",
    "ip": "123– 456– 678",
    "login_ID": loginUser.LoginID,
    "login_password": loginUser.LoginPassword
  }
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");

  axios
    .post(basicUrl + '/login',
      user,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`

        }
      }
    )
    .then(res => {
      if (res.data === "dont login") {
        dispatch({
          type: LOGIN_FAIL,
          isAuthentitcated: false
        })
      }
      else {
        setshowSpiner(false)
        loginSuccess && loginSuccess(true)
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data
        })
        console.log(res,"res1234");
        
        dispatch({ type: 'SET_GENERAL_FORM_OPTIONS_MAP', payload: res.data.data.enums })
      }
    })
    .catch(err => {
      console.log("login",err);
      
      setshowSpiner(false)
      setShowDialogSave(true)
    });
};


// Logout User
export const logout = () => (
  dispatch: Function
) => {
  dispatch({
    type: LOGOUT_SUCCESS
  });
};

export const logout1 = (dispatch: any) => {
  dispatch({
    type: LOGOUT_SUCCESS
  });
};
export const CustomerLogout = (dispatch: any) => {
  dispatch({
    type: LOGOUT
  });
};

// Setup config/headers and token
export const tokenConfig = (getState: Function) => {
  // Get token from sessionStorage
  const token = getState().auth.token;

  // Headers
  const config: IConfigHeaders = {
    headers: {
      'Content-type': 'application/json'
    }
  };

  // If token, add to headers
  if (token) {
    console.log(token,"-56789");
    
    config.headers['x-auth-token'] = token;
  }
  return config;
}



export const updateUserBranchModules = (userCredentials: IUserCredentials | null,modules:[]) => (
  dispatch: Function
) => {
  
  const moduleObj = {
      "id_initiator": userCredentials && userCredentials["idInitiator"],
      "id_client": userCredentials && userCredentials["idClient"],
      "id_branch": userCredentials && userCredentials["idBranch"],
      "id_entity": userCredentials && userCredentials["idBranch"],
      "return_entity": 1,
      "attribute_request_method": "update",
      "attribute": "modules",
      "entity_type_id": "branch",
      "modules": modules
  }
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  axios
    .post(basicUrl + '/entity',
    moduleObj,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`

        }
      }
    )
    .then(res => {
      if (res.data["err_code"] === 0) {
        // setshowSpiner(false)
        // setShowDialogSave(true)
        dispatch({
          type: SET_MODULES_USER_BRANCH,
          payload: res?.data?.data
        })
      }
      else {
        // setshowSpiner(false)
        
      }
    })
    .catch(err => {
      console.log("module",err);
      
      // setshowSpiner(false)
      // setShowDialogSave(true)
    });
};

