import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import CustemTable from '../../shared/components/tabels/tableList';
import { findDefault } from '../customerDetails/CustomerDetails'
import './relatedFactors.css'

const RelatedFactors = (props: any) => {
    const { setshowSpiner, dataCustomer, setRelatedFactors, typesList, dataCustomerCheck } = props
    const [t] = useTranslation();
    const [itemsRelatedFactors, setItemsRelatedFactors] = useState([{}])
    const dataConversion = useSelector(({ conversionReducer }: any) => conversionReducer.dataConversion)

    const columns = [
        { key: '1', name: t('factorType'), fieldName: "factorTypeName", entity_name: "factorTypeName", minWidth: 200, maxWidth: 200 },
        { key: '2', name: t('fullName'), fieldName: "fullName", entity_name: "fullName", minWidth: 140, maxWidth: 140 },
        { key: '3', name: t('identityNumber'), fieldName: "idNumber", entity_name: "idNumber", minWidth: 90, maxWidth: 90 },
        { key: '4', name: t('address0'), fieldName: "fullAddress", entity_name: "fullAddress", minWidth: 160, maxWidth: 160 },
        { key: '5', name: t('phone'), fieldName: "telephone", minWidth: 90, maxWidth: 90 },
        { key: '6', name: t('dateBirth'), fieldName: "dateBirth", minWidth: 80, maxWidth: 80 },
        { key: '7', name: t('edit'), fieldName: "edit", minWidth: 90, maxWidth: 90 },
    ];
    const columnsChecks = [
        { key: '1', name: t('factorType'), fieldName: "factorTypeName", entity_name: "factorTypeName", minWidth: 65, maxWidth: 65 },
        { key: '2', name: t('fullName'), fieldName: "fullName", entity_name: "fullName", minWidth: 140, maxWidth: 140 },
        { key: '3', name: t('identityNumber'), fieldName: "idNumber", entity_name: "idNumber", minWidth: 90, maxWidth: 90 },
        // { key: '4', name: t('address0'), fieldName: "fullAddress", entity_name: "fullAddress", minWidth: 160, maxWidth: 160 },
        // { key: '5', name: t('phone'), fieldName: "telephone", minWidth: 90, maxWidth: 90 },
        // { key: '6', name: t('dateBirth'), fieldName: "dateBirth", minWidth: 80, maxWidth: 80 },
        { key: '7', name: t('edit'), fieldName: "edit", minWidth: 90, maxWidth: 90 },
    ];


    useEffect(() => {
        dataCustomer &&
            relatedFactorsData()
    }, [dataCustomer])


    ////fill table with insider's values
    const relatedFactorsData = () => {
        let arrRelatedFactors: any[] = []
        let indexAddress, indexEmail, indexPhone
        dataCustomer?.forEach((insider: any, i: number) => {
            indexAddress = findDefault(insider?.addresses)
            indexEmail = findDefault(insider?.emails)
            indexPhone = findDefault(insider?.telephones)
            let factorType
            if (dataCustomerCheck) {
                factorType = dataCustomerCheck?.related_entities?.find((item: any) => item?.id_entity === insider?.id_entity || item?.id_entity === insider?.idEntity)
            } else {
                factorType = dataConversion?.related_entities?.find((item: any) => item?.id_entity === insider?.id_entity || item?.id_entity === insider?.idEntity)
            }
            if (insider?.idNumber) {
                arrRelatedFactors.push(insider)
                if (arrRelatedFactors[i].factorTypeName === undefined) arrRelatedFactors[i].factorTypeName = factorType?.service_requisition_type_name
                if (arrRelatedFactors[i].entitySubTypeId === undefined) arrRelatedFactors[i].entitySubTypeId = factorType?.service_requisition_type_id
                arrRelatedFactors[i].fullAddress = arrRelatedFactors[i].addressName ? arrRelatedFactors[i].addressName + " " + arrRelatedFactors[i].addressNumber + " " + arrRelatedFactors[i].addressCityName : t("DoesNotExist")
                arrRelatedFactors[i].fullName = arrRelatedFactors[i].firstName + " " + arrRelatedFactors[i].lastName
                arrRelatedFactors[i].dateBirth = arrRelatedFactors[i].dateBirth
                //d
                // moment(arrRelatedFactors[i].dateBirth).format("DD/MM/YYYY")
                if (arrRelatedFactors[i].idCountryCode === undefined) arrRelatedFactors[i].idCountryCode = arrRelatedFactors[i]?.iDCountryCode ? arrRelatedFactors[i].iDCountryCode : arrRelatedFactors[i].idTypeCountryCode
                if (arrRelatedFactors[i].key === undefined) arrRelatedFactors[i].key = arrRelatedFactors[i].idEntity
            } else {
                arrRelatedFactors.push({
                    key: insider?.id_entity, factorTypeName: insider?.insider_type_name ? insider?.insider_type_name : factorType?.service_requisition_type_name, fullName: insider?.entity_name,
                    gender: insider?.gender?.gender_id, genderOther: insider?.gender?.gender_other, entitySubTypeId: insider?.insider_type_id ? insider?.insider_type_id : factorType?.service_requisition_type_id, firstName: insider?.properties?.first_name, lastName: insider?.properties?.last_name, classId: insider?.class?.class_id,
                    idNumber: insider?.identification?.ID_number, idTypeId: insider?.identification?.ID_type_id, idTypeOther: insider?.identification?.ID_type_other, idCountryCode: insider?.identification?.ID_country_code,
                    fullAddress: insider?.addresses ? (insider?.addresses[indexAddress]?.address_street + " " + insider?.addresses[indexAddress]?.address_number + " " + insider?.addresses[indexAddress]?.address_city) : t("DoesNotExist"),
                    address: insider?.addresses && insider?.addresses[indexAddress]?.address_street_code,
                    addressName: insider?.addresses && insider?.addresses[indexAddress]?.address_street,
                    addressNumber: insider?.addresses && insider?.addresses[indexAddress]?.address_number,
                    addressCity: insider?.addresses && insider?.addresses[indexAddress]?.address_city_code,
                    addressCityName: insider?.addresses && insider?.addresses[indexAddress]?.address_city,
                    addressZipCode: insider?.addresses && insider?.addresses[indexAddress]?.address_zip_code,
                    addressCountryCode: insider?.addresses && insider?.addresses[indexAddress]?.address_country_code,
                    addressId: insider?.addresses && insider?.addresses[indexAddress]?.address_id,
                    telephoneId: insider?.telephones && insider?.telephones[indexPhone]?.telephone_id,
                    telephone: insider?.telephones ? insider?.telephones[indexPhone]?.telephone_number : t("DoesNotExist"),
                    telephoneTypeId: insider?.telephones ? insider?.telephones[indexPhone]?.telephone_type_id : 1,
                    telephoneDefault: insider?.telephones ? insider?.telephones[indexPhone]?.is_default : 1,
                    telephoneCountryCode: insider?.telephones && insider?.telephones[indexPhone]?.telephone_country_code,
                    //d
                    // dateBirth:moment(insider?.properties?.date_birth).format("DD/MM/YYYY"),
                    dateBirth: insider?.properties?.date_birth,
                    note: insider?.properties?.note,
                    email: insider?.emails && insider?.emails[indexEmail]?.email_address,
                    emailId: insider?.emails && insider?.emails[indexEmail]?.email_id,
                    documentation: insider?.documentation,
                    default: insider?.is_default
                })
            }
        });
        setItemsRelatedFactors(arrRelatedFactors)
    }

    return (
        <div style={{ width: `${dataCustomerCheck ? "450px" : "980px"}` }}>
            {itemsRelatedFactors.length === 0 ? '' : <CustemTable tableName="relatedFactors" setRelatedFactors={setRelatedFactors} columns={dataCustomerCheck ? columnsChecks : columns} allItems={itemsRelatedFactors} renderItemColumn rederRow={"edit"} setshowSpiner={setshowSpiner} addCustem={true} isFooter={false} typesList={typesList} />}
        </div >
    )
}
export default RelatedFactors
