import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

export default function ExportSubjectEntity(props: any) {
    const { entity } = props
    const [data, setData]: any = useState([]);
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)

    useEffect(() => {

        let dataObj: any
        let addressObj: any = entity?.address
        debugger
        if (entity) {
            let address = addressObj?.address_country_name ?? "" + " " + addressObj?.address_city ?? "" + " " + addressObj?.address_zip_code ?? "" + " " + addressObj?.address_street ?? "" + " " + addressObj?.address_number ?? ""
            dataObj = [{ title: "שם פרטי", value: entity?.entity_name },
            { title: "שם משפחה", value: entity?.entity_name },
            { title: "תואר", value: "" },
            { title: "מספר זהות", value: entity?.entity_number },
            { title: "סוג מספר זהות", value: entity?.identification?.ID_type_name },
            { title: "מדינת דרכון/ ת\"ז או מדינת התאגדות", value: entity?.ID_country_code === "IL" ? "ישראל" : entity?.ID_country_code },
            { title: "מעמד (תושב/ תושב חוץ/ תושב אזור)", value: entity?.class_name },
            { title: "תאריך לידה", value: entity?.date_birth },
            { title: "מין", value: entity?.gender_name },
            {
                title: "עיסוק", value: entity?.industry_classification ? entity?.industry_classification?.industry_classification_name + ", " +
                    entity?.industry_classification?.industry_sub_classification_name : ""
            },
            { title: "קשר לתאגיד", value: entity?.compliance_officer_name },
            //לשים כאן את הכתובת לפי הסדר שמופיע בקובץ
            { title: "מען נשוא הידיעה", value: address ?? "" },
            ]
            setData(dataObj)
        }
    }, [entity])


    return (
        <>{data?.map((item: any, index: any) => (
            <tr key={index}>
                <td style={{ borderBottom: "1px solid black", borderTop: `${index === 0 ? " 1px solid black" : ""}`, borderRight: "1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px", width: "50%" }}
                    className={`td-reporting-details padding-input-${dir}`}>{item?.title}</td>
                <td style={{ borderBottom: " 1px solid black", borderTop: `${index === 0 ? " 1px solid black" : ""}`, borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}
                    className={`td-reporting-details padding-input-${dir}`}>{item?.value ?? ""}</td>
            </tr>))}
        </>
    )
}
