import React, { useEffect, useState } from "react";
import { Nav } from "@fluentui/react/lib/Nav";
import "../../scssPages/sidebar.scss";
import { INavStyles, INavLinkGroup, INavLink } from "@fluentui/react/lib/Nav";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { useLocation, withRouter } from "react-router-dom";
import "../../scssPages/sidebar.scss";
import { useTranslation } from "react-i18next";
import { registerIcons } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  SAVE_DRAFT_CONVERSION_CHANGES,
} from "../../store/actions/types";
import { DialogMessages } from "../../shared/components/dialog/DialogMessages";
import { globalPages } from "../../shared/components/CustomHooks/GlobalSidePages";

const navStyles: Partial<INavStyles> = { root: { width: 150 } };

initializeIcons();

registerIcons({
  icons: {
    FileDashedIcon: <i className="fal fa-file-dashed-line"></i>,
    FileIcon: <i className="fal fa-file"></i>,
    AbacuseIcon: <i className="fal fa-abacus"></i>,
  },
});

const ContentSideReports = (props: any) => {
  const [t] = useTranslation();
  const [dir, setDir] = useState("");
  const lang = useSelector(({ dataReducer }: any) => dataReducer?.language);
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("");
  const [showPermissionNote, setShowPermissionNote] = useState(false);
  const [msgPermission, setMsgPermission] = useState("");
  // const credentials = useSelector(({ authReducer }: any) => authReducer.data.user.credentials)
  const arrCredentials = useSelector(
    ({ authReducer }: any) => authReducer?.data?.credentials
  );
  const credentialsPagesDetails: any = globalPages;

  useEffect(() => {
    if (lang) {
      setDir(lang);
    }
  }, [lang]);

  useEffect(() => {
    const pathname = location.pathname.split("/");
    const key = pathname.length > 2 ? pathname.pop() : null;
    setSelectedKey(key || "exchange_pnl");
  }, []);

  const handleNavClick = async (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    ev?.preventDefault();
    // props.history.push(`/bookkeeping${item?.url}`);
    setSelectedKey(item?.key || "exchange_pnl");
    let path: any = item?.url?.split("/")[1];
    // if (credentials[path] && credentials[path] === "Deny" || !credentials[path]) {
    //   setMsgPermission(t('permissionAaccessThisPage'))
    //   setShowPermissionNote(true)
    // }
    // else {
    dispatch({
      type: SAVE_DRAFT_CONVERSION_CHANGES,
      payload: {},
    });
    props.history.push(`/reports${item?.url}`);
    // }
  };

  const navStyles: Partial<INavStyles> = {
    root: {
      i: { width: "25px" ,marginRight:'3px'},
    },
    link: {
      ":after": dir === "ltr" && {
        borderLeft: "2px solid transparent !important",
        borderRight: "2px solid rgb(0, 120, 212) !important",
      },
    },
  };

  // const navLinkGroups: INavLinkGroup[] = [
  //   {
  //     links: [
  //       {
  //         key: "sumProfit",
  //         url: "/exchange_pnl",
  //         iconProps: { iconName: "AbacuseIcon" },
  //         name: t("sumProfit"),
  //         onClick: handleNavClick
  //       },
  //       {
  //           key: "regularReports",
  //           url: "/impa_ctr",
  //           iconProps: { iconName: "FileIcon" },
  //           name: t("regularReports"),
  //           onClick: handleNavClick
  //         },
  //       {
  //           key: "unRegularReports",
  //           url: "/impa_utr",
  //           iconProps: { iconName: "FileIcon" },
  //           name: t("unRegularReports"),
  //           onClick: handleNavClick
  //         },
  //     ]
  //   }
  // ];
  const navLinkGroups: INavLinkGroup[] = [{ links: [] }];
  arrCredentials?.map(
    (module: any) =>
      module?.module_code === "reports" &&
      module?.pages?.map((page: any) =>
        navLinkGroups[0]?.links.push({
          className: credentialsPagesDetails[page?.page_code]?.className,
          key: credentialsPagesDetails[page?.page_code]?.key,
          iconProps: credentialsPagesDetails[page?.page_code]?.iconProps,
          name: t(credentialsPagesDetails[page?.page_code]?.name),
          styles: credentialsPagesDetails[page?.page_code]?.styles,
          url: credentialsPagesDetails[page?.page_code]?.url,
          onClick: handleNavClick,
        })
      )
  );

  return (
    <>
      <Nav
        className="items"
        onLinkClick={handleNavClick}
        styles={navStyles}
        groups={navLinkGroups}
        selectedKey={selectedKey}
      />
      {showPermissionNote && (
        <DialogMessages
          setansDelete={setShowPermissionNote}
          setshowModalDelete={setShowPermissionNote}
          subText={msgPermission}
          dialogType={"dialogMessage"}
        />
      )}
    </>
  );
};
export default withRouter(ContentSideReports);
