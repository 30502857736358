import { useBoolean } from '@fluentui/react-hooks';
import { PrimaryButton, Dialog,ContextualMenu, DialogFooter,
   DefaultButton, ISpinnerStyles } from 'office-ui-fabric-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyInput from 'react-currency-input-field';
import { useHistory } from 'react-router-dom';

import { CustomTextField } from '../../../components/TextField';
import { Details } from './Details'
import { CustomDialogBankAccount } from '../Dialog';
import { convertArrToDropdropdown} from '../../../../components/customerDetails/CustomerDetails'
import { CustomerDate } from '../../CustomHooks/concatTextFields'
import { useSelector } from "react-redux";
import CheckCarousel from '../../../../components/actions/CheckCarousel';
import ListRelatedFactors from '../../../../components/relatedFactors/ListRelatedFactors';

import "../dialog.scss";
import moment from 'moment';

export const setFocus = (tableType: any, convertions: any, rowNumber: any) => {
  const index = convertions[tableType]?.findIndex((item: any) => Number(item.row_number) === Number(rowNumber))
  if (tableType.includes('out')) {
    let nodes = document.querySelectorAll('.amount-out');
    (nodes[index] as HTMLElement)?.focus();
  }
  else {
    let nodes = document.querySelectorAll('.currency');
    (nodes[(index + 1) * 2 + 1] as HTMLElement)?.focus();
  }
}

export const CheckDetailsDialogComplete = (props: any) => {
  const { emptyRequiredFields, setShowDialog, setConvertions, rowNumber, convertions, tableType, userCredentials, conversionCustomer } = props
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const history = useHistory()
  const [t] = useTranslation();
  const [dir,setDir]=useState("")
  const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
  const [gatesData, setGatesData] = useState<any>(sessionStorage.getItem("gatesArr"))
  const [enumsDropdown, setenumsDropdown] = useState<any>({ "countryCode": [], "bank": [], "branch": [] })
  const [isValidDate, setIsValidDate] = useState<any>(true);
  const [searchData, setSearchData] = useState<any>();
  const [detailsButton, setDetailsButton] = useState(true)
  const [relatedButton, setRelatedButton] = useState(false)
  const [newBenObject, setnewBenObject] = useState<any>()
  const [newEndorsee, setnewEndorsee] = useState<any>()
  const [newHolder, setnewHolder] = useState<any>()
  const [requiredField, setRequiredField] = useState(false)
  const [showSpiner, setshowSpiner] = useState(false)
  const [arrScanChecksImgs, setArrScanChecksImgs] = useState<any>([])
  const [arrChecksPdfImgs, setArrChecksPdfImgs] = useState<any>([])
  const [defContraAccount, setdefContraAccount] = useState<any>()
  const contraAccounts = useSelector(({ authReducer }: any) => authReducer?.data?.user.contra_accounts)
  const [updateBankAccount, setUpdateBankAccount] = useState<any>(
    { key: '', country: '', accountNumber: '', bank: '', branch: '', bankName: '',
     branchAddress: '', branchName: '',routingNumber:'' })
  const spinnerStyles: Partial<ISpinnerStyles> = {
    root: {
      position: 'fixed',
      zIndex: '999999',
      fontWeight: 'bold',
    },
    circle: {
      color: '#FFB400 ',
      height: '100px',
      width: '100px',
    },
  }
  const [checkDeatils, setcheckDeatils] = useState<any>({})
  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
  };
const modelProps = {
    isBlocking: true,
    dragOptions: dragOptions 
};

useEffect(()=>{
  if(lang){
      setDir(lang)
  }
  },[lang])
  
  useEffect(() => {
    emptyRequiredFields && setRequiredField(true)
  }, [emptyRequiredFields])

  useEffect(() => {
    if (gatesData && typeof gatesData === "string") {
      setGatesData(JSON.parse(gatesData))
    }
  }, [gatesData])


  useEffect(() => {
    if (enums) {
      let arr = {
        "currencyCode": convertArrToDropdropdown([]),
        "countryCode": convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].general !== undefined) {
          let country = enums[i].general
          for (let j= 0; j< country.length; j++) {
            if (country[j].currency_code !== undefined)
              arr.currencyCode = convertArrToDropdropdown(country[j].currency_code)
            if (country[j].country_code !== undefined)
              arr.countryCode = convertArrToDropdropdown(country[j].country_code)
          }
        }
      }
      setenumsDropdown(arr);
    }
  }, [enums])

  useEffect(() => {
    let arrCheck: any = []
    for (let i = 0; i < contraAccounts.length; i++) {
      if (contraAccounts[i].contra_account_group_id === "cheque") {
        arrCheck.push({ key: contraAccounts[i].id_account, text: contraAccounts[i].account_name + ' ' + contraAccounts[i].account_number, groupId: contraAccounts[i].contra_account_group_id, accountNumber: contraAccounts[i].account_number, is_default: contraAccounts[i].is_default ? true : false })
      }
    } 
    let defaultContra:any=convertions?.contra_account?.account_number+" "+convertions?.contra_account?.account_name
    setdefContraAccount(defaultContra?.text)
  }, [contraAccounts])

  useEffect(() => {
    if (convertions && enumsDropdown?.currencyCode?.length > 0 && enumsDropdown?.countryCode?.length) {
      let defaultCurrency: any = enumsDropdown?.currencyCode?.find((currency: any) => currency?.key === convertions?.currency_code)
      let defaultCountry: any = enumsDropdown?.countryCode?.find((country: any) => country?.key === convertions?.bank?.country_code)
      let detailsCheck = { ...convertions }
      detailsCheck = detailsCheck?.cheque_cashing_details
      let copyCheckDeatils = {
        id_initiator: convertions?.id_initiator, id_client: convertions?.id_client,
        bank_account_id: convertions?.bank?.bank_account_id, id_account_holder: convertions?.account_holder_ID_number, id_beneficiary: convertions?.beneficiary_ID_number,
        id_endorsee: convertions?.id_endorsee, id_entity: convertions?.id_entity, related_entities: convertions?.related_entities,
        contra_id_account: defContraAccount, amount: convertions?.amount, currency_code: defaultCurrency?.text, id_cheque_beneficiary: detailsCheck?.id_cheque_beneficiary,
        cashing_intrest: detailsCheck?.cashing_intrest, cashing_amount: detailsCheck?.cashing_amount,  cheque_expenses: detailsCheck?.cheque_expenses,
        allowed_expenses: detailsCheck?.allowed_expenses, allowed_actual_expenses: detailsCheck?.allowed_actual_expenses??0, cheque_days: detailsCheck?.cheque_days, cheque_net: detailsCheck?.cheque_net, cheque_value_days: detailsCheck?.cheque_value_days,
        cheque_face_scan_path: detailsCheck?.cheque_face_scan_path, cheque_back_scan_path: detailsCheck?.cheque_back_scan_path, cheque_endorses_number: detailsCheck?.cheque_endorses_number ? detailsCheck?.cheque_endorses_number : 0,
        reference_number: convertions?.reference_number, cheque_date: convertions?.cheque_date, id_media_back: convertions?.media?.id_media_back,
        id_media_face: convertions?.media?.id_media_face, date_created: detailsCheck?.date_created, cheque_cashing_date: detailsCheck?.cheque_cashing_date,
        media_face_extension: detailsCheck?.media_face_extension, media_back_extension: detailsCheck?.media_back_extension,media:detailsCheck?.media
      }
      let bankD = { key: '', country: defaultCountry?.text, accountNumber: convertions?.bank?.bank_account_number,
       bank: convertions?.bank?.bank_code, branch: convertions?.bank?.bank_branch_code, 
       bankName: convertions?.bank?.bank_name, branchAddress: convertions?.bank?.bank_branch_address,
        branchName: convertions?.bank?.bank_branch_name,routingNumber:convertions?.bank?.routing_number }
      let holder = { idNumber: convertions?.account_holder_ID_number, firstName: convertions?.account_holder_name }
      let beneficiary = { idNumber: convertions?.beneficiary_ID_number, firstName: convertions?.beneficiary_name }
      let endorsee = { idNumber: convertions?.endorsee?.ID_number, firstName: convertions?.endorsee?.entity_name }
      setnewBenObject(beneficiary)
      setnewEndorsee(endorsee)
      setnewHolder(holder)
      setUpdateBankAccount(bankD)
      setcheckDeatils(copyCheckDeatils)
    }
  }, [convertions, enumsDropdown])

  useEffect(() => {
    if (setShowDialog) {
      history.location.state = "showCompletedManagment"
      toggleHideDialog()
    }
  }, [setShowDialog])

  useEffect(() => {
    if (checkDeatils?.id_media_face || checkDeatils?.id_media_back) {
      setUrls()
    }
    async function setUrls() {
      let copyArrScanChecksImgs: any = [], copyArrChecksPdfImgs: any = []
      if (checkDeatils?.media_face_extension === "scan" || checkDeatils?.media_back_extension === "scan") {
        let arrDate = checkDeatils?.date_created?.split("-")
        let year = moment(arrDate[0]).format("YY")
        let month = arrDate[1]
        let day = arrDate[2]
        checkDeatils?.id_media_face && copyArrScanChecksImgs.push({ original: `http://127.0.0.1:8887/${month}${year}/${day}/${updateBankAccount?.accountNumber}_${checkDeatils?.reference_number}_b.jpg` })
        checkDeatils?.id_media_back && copyArrScanChecksImgs.push({ original: `http://127.0.0.1:8887/${month}${year}/${day}/${updateBankAccount?.accountNumber}_${checkDeatils?.reference_number}_f.jpg` })
      }
      else {
        if (checkDeatils?.id_media_face && checkDeatils?.media_face_extension === ".pdf") {
          copyArrChecksPdfImgs.push(`https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.id_media_face}&id_entity=${checkDeatils.id_entity}&id_client=${checkDeatils?.id_client}&id_initiator=${checkDeatils?.id_initiator}`)
        }
        if (checkDeatils?.id_media_back && checkDeatils?.media_back_extension === ".pdf") {
          copyArrChecksPdfImgs.push(`https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.id_media_back}&id_entity=${checkDeatils.id_entity}&id_client=${checkDeatils?.id_client}&id_initiator=${checkDeatils?.id_initiator}`)
        }
        if (checkDeatils?.id_media_face && checkDeatils?.media_face_extension !== ".pdf") {
          copyArrScanChecksImgs.push({ original: `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.id_media_face}&id_entity=${checkDeatils.id_entity}&id_client=${checkDeatils?.id_client}&id_initiator=${checkDeatils?.id_initiator}` })
        }
        if (checkDeatils?.id_media_back && checkDeatils?.media_back_extension !== ".pdf") {
          copyArrScanChecksImgs.push({ original: `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.id_media_back}&id_entity=${checkDeatils.id_entity}&id_client=${checkDeatils?.id_client}&id_initiator=${checkDeatils?.id_initiator}` })
        }
      }
      copyArrScanChecksImgs.length > 0 && setArrScanChecksImgs(copyArrScanChecksImgs)
      copyArrChecksPdfImgs.length > 0 && setArrChecksPdfImgs(copyArrChecksPdfImgs)
    }
  }, [checkDeatils.id_media_face, checkDeatils?.id_media_back])

  const resetButtons = (setButton: any) => {
    setButton === "setRelatedButton" ? setRelatedButton(true) : setRelatedButton(false)
    setButton === "setDetailsButton" ? setDetailsButton(true) : setDetailsButton(false)
  }

  return (
    <>
      <Dialog minWidth="xxl" maxWidth="xxl" hidden={hideDialog} modalProps={modelProps} onDismiss={() => { toggleHideDialog(); setShowDialog(false) }} >
        <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{t('detailsChequeFromCustomer')}</p>
        <hr className="hr"></hr>

        <div className="wrapper-collateral">
          <div className={`warpper-fields-right-${dir}`} style={{ width: "30%" }}>
            <CustomTextField required={true} value={defContraAccount} readOnly={true} label={t('chequeCashing')} onChange={''} id={'contra_id_account'} />
          </div>
          <div className={`warpper-fields-left-${dir} text-a-${dir}`} >
            <DefaultButton className='button edit-button' style={{ backgroundColor: relatedButton ? '#EAE4DE' : "" }} onClick={() => { resetButtons("setRelatedButton") }} text={t("guarantor/beneficiary")} name="Related" />
            <DefaultButton className='button edit-button' style={{ backgroundColor: detailsButton ? '#EAE4DE' : "" }} onClick={() => { resetButtons("setDetailsButton") }} text={t('details')} name="details" />
          </div>
        </div>
        <hr className="hr" style={{ marginTop: "10px" }}></hr>
        <div>
          <div  className={`wrapper-collateral marginL-between-container-${dir}`}  style={{
            alignItems: "unset", width: convertions?.related_entities?.length >= 1 && relatedButton ? "100%" :
              conversionCustomer?.customerTypeId !== "1" && (conversionCustomer?.customerTypeId !== "2" && conversionCustomer?.idEntity !== checkDeatils?.id_account_holder) ? "800px" : "950px"
          }}>
            <div className={`warpper-fields-right-${dir} div-cheque-details-${dir}`} >
              <p className="title-text" >{t("cheque-details")}</p>
              <hr className="hr"></hr>
              <div className="wrapper-collateral">
                <div className={`warpper-fields-right-${dir}`}>
                  <label style={{ marginBottom: "4px" }}>{t('amount')} <span style={{ color: "rgb(164, 38, 44)" }}>*</span></label>
                  <CurrencyInput
                   className={`currency padding-currency-${dir}`}
                    id='amount'
                    name='amount'
                    placeholder="0.00"
                    required={(requiredField || emptyRequiredFields) && !checkDeatils?.amount ? true : false}
                    min="0"
                    readOnly={true}
                    value={checkDeatils?.amount}
                    style={(requiredField && !checkDeatils?.amount) ? { width: "100%", backgroundColor: '#FAF9F8', height: '32px', border: "3px solid #db3838" } : { width: "100%", height: '32px', backgroundColor: "#FAF9F8", borderWidth: "1px", borderStyle: "solid", outlineColor: "rgb(0, 120, 212)" }}
                    decimalsLimit={2}
                  />
                </div>
                <div className={`warpper-fields-left-${dir}`}>
                  <CustomTextField value={checkDeatils.currency_code} readOnly={true} label={t("currency")} onChange={""} id={'currency_code'} />
                </div>
              </div>
              <div className="wrapper-collateral" style={{ alignItems: "flex-start" }}>
                <div className={`warpper-fields-right-${dir}`}>
                  <CustomerDate isDefaultDate={false} searchCustomer={conversionCustomer} requiredProps={true} showRequiredFildes={(requiredField || emptyRequiredFields) && !checkDeatils?.cheque_date ? true : false} isValidDate={isValidDate} setIsValidDate={setIsValidDate} entity={checkDeatils} setEntity={setcheckDeatils} readOnly={true} idDate="cheque_date" label={t('repaymentDate')} />
                </div>
                <div className={`warpper-fields-left-${dir}`} >
              <CustomTextField required={true} requiredField={(requiredField || emptyRequiredFields) && !checkDeatils?.reference_number ? true : false} value={checkDeatils?.reference_number} readOnly={true} label={t('chequeNumber/reference')} onChange={""} id={'reference_number'} />
                </div>
              </div>

              <p className="title-text" >{t('bankDetails')}</p>
              <hr className="hr"></hr>
              <CustomDialogBankAccount showRequiredField={requiredField} notShowHolderButton={false} setSearchData={setSearchData} isCheck={true} bankDetails={updateBankAccount} setbankDetails={setUpdateBankAccount} conversions={"checkDetailsDialogPage"} userCredentials={userCredentials} />
            </div>
            {(detailsButton) &&
              <div className={`warpper-fields-left-${dir} div-all-details-${dir}`} >
                <Details newObject={newHolder} title={t("holderDetails")} isComplete={true} />
                <Details newObject={newBenObject} title={t("beneficiaryDetails")} isComplete={true} />
                <Details newObject={newEndorsee}  title={t("endorseeDetails")} isComplete={true} />
                <div style={{ width: "100%" }}>
                  <CheckCarousel showRequiredField={requiredField} checkDeatils={checkDeatils} setCheckDeatils={setcheckDeatils} userCredentials={userCredentials} updateBankAccount={updateBankAccount} idEntity={checkDeatils.idEntity} arrScanChecksImgs={arrScanChecksImgs} setArrScanChecksImgs={setArrScanChecksImgs} arrChecksPdfImgs={arrChecksPdfImgs} />
                </div>
              </div>}
            {relatedButton &&
              <div>
                <div>
                  {convertions?.related_entities?.length >= 1 && <ListRelatedFactors dataCustomerCheck={convertions} setshowSpiner={setshowSpiner} dataCustomer={convertions?.related_entities} />}
                </div>
              </div>
            }
          </div>
          <DialogFooter >
            <DefaultButton onClick={() => { toggleHideDialog(); setShowDialog(false); }} text={t("cancel")} />
            <PrimaryButton text={t("save")} />
          </DialogFooter>
        </div >
      </Dialog >
    </>
  )
}

