import { cloneDeep } from 'lodash';
import { SET_ACCOUNTS_TRANSFERS } from '../actions/types'
import { accountData } from '../actions/accountAction';

const initialState = {
    accountsTransfers: [],
    accountsInTransfers: []
};

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SET_ACCOUNTS_TRANSFERS: {
            let arrAccounts: any = []
            let arrInAccounts: any = []
            let i = 1
            action?.payload?.map((account: any) => {
                if (account?.PayoutMethod === "Cash" || account?.PayoutMethod === "Transfer" || account?.PayoutMethod === "Wire") {
                    arrInAccounts.push({ key: i, text: account?.Description })
                    i++
                }
                arrAccounts.push({ key: account?.PayoutMethod, text: account?.Description })

            })
            //             action?.payload?.map((accounts: any) => (
            // if (accounts) {
            //                 console.log("gg");

            //             }
            //             ))
            return {
                ...state,
                accountsTransfers: arrAccounts,
                accountsInTransfers: arrInAccounts

            }
        }
        default: return state;
    }
}