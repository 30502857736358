import { Dialog, ContextualMenu, PrimaryButton } from '@fluentui/react';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useBoolean } from '@fluentui/react-hooks';
import { CustomTextField } from '../../shared/components/TextField';
import { CustomDropdown } from '../../shared/components/Option';
import { actionNewAccount } from '../../store/actions/accountAction';
import { useDispatch, useSelector } from 'react-redux'
import { IUserCredentials } from './../customerDetails/CustomerDetails';
import { convertArrToDropdropdown } from '../../shared/components/CustomHooks/GlobalFunctions';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import { CustomToggle } from '../../shared/components/Toggle';

export default function AccountsAddAcountDialog(props: any) {
    const { setShowDialog, setRowData, rowData, itemToUpdate,search_fields,pageSize = 10, currentPage = 1 } = props

    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [t] = useTranslation();
    const [errServerMsg, setErrServerMsg] = useState<any>('empty');
    const [titleErrServer, setTitleErrServer] = useState<any>('');

    const [showDialogFailed, setShowDialogFailed] = useState<boolean>(false);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
    const dispatch = useDispatch()
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [newAccount, setNewAccount] = useState<any>({ balance_sub_group_id: "99", balance_group_id: "99" })
    const [enumsDropdown, setenumsDropdown] = useState<any>()
    const [showTextRequiredFiles, setShowTextRequiredFiles] = useState(false)

    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
    };
    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        dragOptions: dragOptions
    };

    useEffect(() => {
        if (itemToUpdate?.account_name) {
            setNewAccount(itemToUpdate)
        }
    }, [itemToUpdate])

    useEffect(() => {
        if (errServerMsg !== 'empty' && errServerMsg !== "success")
            setShowDialogFailed(true)
        if (errServerMsg === "success") {
            toggleHideDialog();
            setShowDialog(false);
        }
        // eslint-disable-next-line
    }, [errServerMsg])

    useEffect(() => {
        if (setShowDialog) {
            toggleHideDialog()
        }
    }, [setShowDialog, toggleHideDialog])

    useEffect(() => {
        if (enums) {
            let arr = {
                "accountTypeId": convertArrToDropdropdown([]),
                "statusId": convertArrToDropdropdown([]),
                "contraAccountGroupId": convertArrToDropdropdown([]),
                "balanceGroupId": convertArrToDropdropdown([]),
                "balanceSubGroupId": convertArrToDropdropdown([]),
            };
            for (let i = 0; i < enums.length; i++) {
                if (enums[i].account !== undefined) {
                    let account = enums[i].account
                    for (let i = 0; i < account.length; i++) {
                        if (account[i].account_type_id !== undefined) {
                            arr.accountTypeId = convertArrToDropdropdown(account[i].account_type_id)
                        }
                        if (account[i].status_id !== undefined) {
                            arr.statusId = convertArrToDropdropdown(account[i].status_id)
                        }
                        if (account[i].contra_account_group_id !== undefined) {
                            arr.contraAccountGroupId = convertArrToDropdropdown(account[i].contra_account_group_id)
                        }
                        if (account[i].balance_group_id !== undefined) {
                            arr.balanceGroupId = convertArrToDropdropdown(account[i].balance_group_id)
                            let arrFiltered = arr.balanceGroupId.filter((item: any) => item.key !== "99")
                            arr.balanceGroupId = arrFiltered;
                        }
                        if (account[i].balance_sub_group_id !== undefined) {
                            arr.balanceSubGroupId = enums[0]?.account[3]?.balance_sub_group_id?.map((g: any) => ({ key: g.enum_id, text: g.enum_value, parent: g.parent_value }));
                        }
                    }
                }
            }
            setenumsDropdown(arr);
        }
    }, [enums])

    useEffect(() => {
        if (enumsDropdown && newAccount && newAccount?.balance_group_id && newAccount?.balance_group_id !== '99') {
            let arr: any = enums[0]?.account[3]?.balance_sub_group_id;
            let arrSubGroupFiltered = arr?.filter((item: any) => item.parent_value === newAccount?.balance_group_id.toString()).map((g: any) => ({ key: g.enum_id, text: g.enum_value, parent: g.parent_value }));
            setenumsDropdown({ ...enumsDropdown, "balanceSubGroupId": arrSubGroupFiltered });
        }
    }, [newAccount]);

    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])

    const setFialdCreate = (id: any, value: any) => {
        newAccount[id] = value;
        setNewAccount({ ...newAccount })
        if (id === "balance_group_id")
            setNewAccount({ ...newAccount, balance_sub_group_id: "99" })
    }
    // const check = (currentValue: string) => newAccount[currentValue]?.length > 0;

    const hendleNewAccount = async () => {
        if (newAccount?.account_name && newAccount?.status_id && (newAccount?.account_type_id === 0 || newAccount?.account_type_id) && newAccount?.balance_group_id && newAccount?.balance_sub_group_id) {
            await dispatch(actionNewAccount(userCredentials, setRowData, newAccount, rowData,
                itemToUpdate?.id_account ? 'update' : 'create', search_fields, setErrServerMsg,
                setTitleErrServer, setShowDialogFailed, pageSize, currentPage))
        }
        else {
            setShowTextRequiredFiles(true)
        }
    }

    return (
        <Dialog minWidth="sm" maxWidth="sm" modalProps={modelProps} hidden={hideDialog} onDismiss={() => { toggleHideDialog(); setShowDialog(false) }} >
            <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                    {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} subText={errServerMsg} title={titleErrServer} setshowModalDelete={setShowDialogFailed} dialogType={"dialogMessage"} />}
                    <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{itemToUpdate?.account_number ? t("editAccount") : t("addAccount")}</p>
                </div>
                <hr className="hr" style={{ width: "400px" }}></hr>
                <CustomTextField autoFocus={true} requiredField={showTextRequiredFiles && !newAccount?.account_name ? true : false} value={newAccount?.account_name} readOnly={false} label={t("accountName")} onChange={(id: any, val: any) => (setFialdCreate(id, val))} id={'account_name'} />
                <CustomTextField value={newAccount?.account_number} readOnly={true} label={t("accountNumber")} onChange={(id: any, val: any) => (setFialdCreate(id, val))} id={'account_number'} />
                <div className="wrapper-collateral">
                    <div className={`warpper-fields-right-${dir}`}>
                        <CustomDropdown requiredField={showTextRequiredFiles && !newAccount?.status_id ? true : false} label={t("status")} otherInputId={''} readOnly={false} hasOtherValue={false} options={enumsDropdown?.statusId} onChange={setFialdCreate} selectedKey={newAccount.status_id?.toString()} id={'status_id'} othertextInput={t('')} />
                    </div>
                    <div className={`warpper-fields-left-${dir}`}>
                        <CustomDropdown requiredField={showTextRequiredFiles && !newAccount?.account_type_id ? true : false} label={t("accountType")} otherInputId={''} readOnly={false} hasOtherValue={false} options={enumsDropdown?.accountTypeId} onChange={setFialdCreate} selectedKey={newAccount?.account_type_id?.toString()} id={'account_type_id'} othertextInput={t('')} />
                    </div>
                </div>
                <div className="wrapper-collateral">
                    <div className={`warpper-fields-right-${dir}`}>
                        <CustomDropdown requiredField={showTextRequiredFiles && !newAccount?.balance_group_id ? true : false} label={t("balanceGroup")} placeholder={newAccount?.balance_group_id?.toString() === "99" ? "לא הוגדר" : ""}
                            selectedKey={newAccount?.balance_group_id?.toString()} otherInputId={''} readOnly={false} hasOtherValue={false} options={enumsDropdown?.balanceGroupId} onChange={setFialdCreate} id={'balance_group_id'} othertextInput={t('')} />
                    </div>
                    <div className={`warpper-fields-left-${dir}`}>
                        <CustomDropdown requiredField={showTextRequiredFiles && !newAccount?.balance_sub_group_id ? true : false} label={t("balanceSubgroup")} placeholder={newAccount?.balance_sub_group_id?.toString() === "99" ? "לא הוגדר" : ""}
                            selectedKey={newAccount?.balance_sub_group_id?.toString()} otherInputId={''} readOnly={false} hasOtherValue={false} options={enumsDropdown?.balanceSubGroupId} onChange={setFialdCreate} id={'balance_sub_group_id'} othertextInput={t('')} />
                    </div>
                </div>
                <div className="wrapper-collateral">
                    <div className={`warpper-fields-right-${dir}`}>
                        <CustomDropdown label={t("contraGroupType")} selectedKey={newAccount.contra_account_group_id} otherInputId={''} readOnly={false} hasOtherValue={false} options={enumsDropdown?.contraAccountGroupId} onChange={setFialdCreate} id={'contra_account_group_id'} othertextInput={t('')} />
                    </div>
                    <div className={`warpper-fields-left-${dir}`}>
                        <CustomTextField value={newAccount?.external_number} readOnly={false} label={t("externalAccountNumber")} onChange={(id: any, val: any) => (setFialdCreate(id, val))} id={'external_number'} />
                    </div>
                </div>
                <CustomTextField value={newAccount?.note} readOnly={false} label={t("details")} onChange={(id: any, val: any) => (setFialdCreate(id, val))} id={'note'} />
                <div style={{ marginTop: "8px" }}>
                    <CustomToggle onText={t("defaultAccount")} onChange={setFialdCreate} id={'is_default'} defaultChecked={newAccount.is_default} checked={newAccount.is_default} offText={t('defaultAccount')} />
                </div>
                <div style={{ marginTop: "8px" }}>
                    <CustomToggle onText={t("balancesInLoginScreen")} onChange={setFialdCreate} id={'is_in_home_page'} defaultChecked={newAccount.is_in_home_page} checked={newAccount.is_in_home_page} offText={t('balancesInLoginScreen')} />
                </div>
                <div style={{ display: 'flex', justifyContent: "end" }}>
                    <PrimaryButton className={`margin-between-input-${dir}`} style={{ marginTop: "3%" }} onClick={() => { toggleHideDialog(); setShowDialog(false) }} text={t("cancel")} />
                    <PrimaryButton className={`button-add-account-${dir}`} onClick={() => {
                        hendleNewAccount()
                    }} text={!itemToUpdate?.account_number ? t("add") : t("update")} />
                </div>
            </div>
        </Dialog >
    )
}
