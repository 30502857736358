import { useEffect, useState } from 'react'
import { CustomDropdown } from "../../shared/components/Option";
import { CustomTextField } from "../../shared/components/TextField";
import { useTranslation } from 'react-i18next';
import { DefaultButton, IconButton, PrimaryButton } from "@fluentui/react";
import { ICustomer, convertArrToDropdropdown } from '../../components/customerDetails/CustomerDetails'
import { Icons } from "../../modelsType/Icon";
import { useSelector } from 'react-redux'
import { Dialog,ContextualMenu, DialogFooter } from 'office-ui-fabric-react';
import { useBoolean } from '@fluentui/react-hooks';
import CustemTable from '../../shared/components/tabels/tableList';
import { CustomFilesDialog } from '../../shared/components/dialog/Dialog';
const blankCustomer: ICustomer = {
    gender: 1,
    otherGender: "",
    entityStatusId: 0,
    idTypeCountryCode: "",
    firstName: "",
    note: "",
    lastName: "",
    isLocked: false,
    isPopupMessage: false,
    dateBirth: "",
    genderOther: "",
    middleName: "",
    otherCustomerStatus: "",
    classId: 1,
    entitySubTypeId: 1,
    ViewNoteWhenPerformingAction: false,
    industryId: 0,
    CreditGroup: 0,
    idAffiliate: 0,
    idTypeId: "",
    idNumber: "",
    idTypeOther: "",
    addressCountryCode: "",
    addressState: "",
    idIdentifier: 0,
    address: "",
    addressNumber: "",
    addressCity: "",
    iDCountryCode: "",
    telephone: "",
    telephoneCountryCode: "",
    email: "",
    entityNumber: "",
    addressZipCode: "",
    customerTypeId: "",
    workerName: "",
    genderName: "",
    telephoneTypeId: 0,
    telephoneId: 0,
    addressTypeId: 0,
    emailTypeId: 0,
    emailId: 0,
    telephoneDefault: 1,
    addressDefault: 1,
    emailDefault: 1,
    addressId: 0
}

const UsersClientDialog = (props: any) => {
    const { setShowDialog } = props
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [t] = useTranslation();
    const [dir,setDir]=useState("")
    const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
    const [enumsDropdown, setenumsDropdown] = useState<any>({ "countryCode": [], "bank": [], "branch": [] })
    const [readOnly, setreadOnly] = useState(false)
    const columnsCollateralCustomer = [
        { key: '1', name:t('fileType'), fieldName: "type", entity_name: "type", minWidth: 100, maxWidth: 100 },
        { key: '2', name: t('fileName'), fieldName: "name", entity_name: "name", minWidth: 100, maxWidth: 100 },
        { key: '3', name: t('expirationDate'), fieldName: "date", entity_name: "date", minWidth: 100, maxWidth: 100 },
        { key: '4', name: t('operations'), fieldName: "operations", entity_name: "operations", minWidth: 100, maxWidth: 100 },
    ];
    const itemsCollateralCustomer = [
        { key: 1, type: 'שיק בטחון', name: 'שטרות', date: '15/01/2021' },
    ]
    const [addFiles, setaddFiles] = useState(false)
    const [customer, setCustomer] = useState<ICustomer>(blankCustomer)

    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
      };
    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        dragOptions: dragOptions 
    };
    useEffect(()=>{
        if(lang){
            setDir(lang)
        }
        },[lang])
        
    useEffect(() => {
        if (enums) {
            let arr = {
                "customersType": convertArrToDropdropdown([]),
                "genders": convertArrToDropdropdown([]),
                "typeIdentityNumbers": convertArrToDropdropdown([]),
                "status": convertArrToDropdropdown([]),
                // "ID_type_id": convertArrToDropdropdown(enums[3].customer[5].ID_type_id),
                "telephoneCountryCode": convertArrToDropdropdown(enums[6]?.telephone_country_code),
                "countryCode": convertArrToDropdropdown(enums[7]?.country_code)
            }
            setenumsDropdown(arr);
        }

    }, [enums])

    useEffect(() => {
        if (setShowDialog) {
            toggleHideDialog()
        }
    }, [setShowDialog])

    return (
        <>

            <Dialog minWidth="xl" maxWidth="xl" hidden={hideDialog} modalProps={modelProps} onDismiss={() => { toggleHideDialog(); setShowDialog(false) }} >
                <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{t("userDetails")}</p>
                <hr className="hr"></hr>
                <div className="wrap-dialog">
                    <p className="title-text">{t("personalDetails")}</p>
                    <hr className="hr"></hr>
                    <div className="wrapper-collateral">

                        <div className={`warpper-fields-right-${dir}`}>
                            <CustomTextField value={customer.firstName} readOnly={readOnly} label={t('firstName')} onChange={' '} id={'firstName'} iconProps={Icons.add} />
                            <CustomTextField value={customer.lastName} readOnly={readOnly} label={t('lastName')} onChange={' '} id={'lastName'} />
                            <CustomTextField value={customer.dateBirth} readOnly={readOnly} type="date" label={t('dateOfBirth')} onChange={' '} id={'dateBirth'} />
                            <CustomDropdown otherInputId={'genderName'} otherValue={customer.genderName} hasOtherValue={true} options={enumsDropdown.genders} label={t('gander')} readOnly={readOnly} onChange={' '} selectedKey={customer.gender} id={'gender'} othertextInput={t('other')} />
                            <CustomTextField value={''} readOnly={readOnly} label={t('other')} onChange={' '} id={'otherGender'} />

                        </div>
                        <div className={`warpper-fields-left-${dir}`}>
                            <CustomTextField value={customer.idNumber} readOnly={readOnly} label={t('identityNumber')} onChange={' '} id={'idNumber'} />
                            <CustomDropdown otherInputId={''} readOnly={readOnly} hasOtherValue={false} options={enumsDropdown.countryCode} label={t('countryIdentityNumber')} onChange={' '} selectedKey={customer.idTypeCountryCode} id={'idTypeCountryCode'} othertextInput={t('')} />
                            <CustomDropdown otherInputId={'idTypeOther'} readOnly={readOnly} otherValue={customer.idTypeOther} hasOtherValue={true} options={enumsDropdown.typeIdentityNumbers} label={t('typeIdentityNumber')} onChange={' '} selectedKey={customer.idTypeId} id={'idTypeId'} othertextInput={t('typeIdentityNumberOther')} />
                            <CustomTextField value={''} readOnly={readOnly} label={t('OtherTypeIDNumber')} onChange={' '} id={'otherNumber'} />
                            <CustomDropdown otherInputId={'genderName'} otherValue={customer.genderName} hasOtherValue={true} options={enumsDropdown.genders} label="רמת הרשאה" readOnly={readOnly} onChange={' '} selectedKey={customer.gender} id={'gender'} othertextInput={t('other')} />
                        </div>
                    </div>
                    <p className="title-text">{t("contactInformation")}</p>
                    <hr className="hr"></hr>
                    <div className="wrapper-collateral" style={{ alignItems: "unset" }}>

                        <div className={`warpper-fields-right-${dir}`}>
                            <p className="title-text" >{t("address")}</p>
                            <hr className="hr"></hr>
                            <CustomTextField value={customer.address} readOnly={readOnly} label={t('address')} onChange={' '} id={'address'} />
                            <CustomTextField value={customer.addressNumber} readOnly={readOnly} label={t('houseNumber')} onChange={' '} id={'addressNumber'} />
                            <CustomDropdown readOnly={readOnly} otherInputId={''} hasOtherValue={false} options={enumsDropdown.countryCode} label={t('city')} onChange={' '} selectedKey={customer.iDCityCode} id={'iDCityCode'} othertextInput={t('')} />
                            <CustomDropdown readOnly={readOnly} otherInputId={''} hasOtherValue={false} options={enumsDropdown.countryCode} label={t('country')} onChange={' '} selectedKey={customer.iDCountryCode} id={'iDCountryCode'} othertextInput={t('')} />
                            <CustomTextField value={customer.addressZipCode} readOnly={readOnly} label={t('addressZipCode')} onChange={' '} id={'addressZipCode'} />

                        </div>
                        <div className={`warpper-fields-left-${dir}`}>
                            <p className="title-text" >{t("phone")}</p>
                            <hr className="hr"></hr>
                            <CustomTextField readOnly={readOnly} value={customer.telephone} label={t('phone')} onChange={' '} id={'telephone'} />
                            <CustomDropdown readOnly={readOnly} otherInputId={''} hasOtherValue={false} options={enumsDropdown.telephoneCountryCode} label={t('countryPhone')} onChange={' '} selectedKey={customer.telephoneCountryCode} id={'telephoneCountryCode'} othertextInput={t('')} />
                            <br></br> <br></br>
                            <p className="title-text" >{t('email')}</p>
                            <hr className="hr"></hr>
                            <CustomTextField value={customer.email} readOnly={readOnly} label={t('emailAddress')} onChange={' '} id={'email'} type='email' />

                        </div>
                    </div>
                    <p className="title-text">{t("loginAndPermissions")}</p>
                    <hr className="hr"></hr>
                    <CustomTextField required={true} value={' '} label={t("username")} onChange={' '} id={'userName'} />
                    <CustomTextField required={true} value={' '} label={t("password")} onChange={' '} id={'password'} iconProps={Icons.redEye} />
                    <CustomDropdown otherInputId={' '} otherValue={customer.genderName} hasOtherValue={true} options={enumsDropdown.genders} label="הרשאות" onChange={' '} selectedKey={' '} id={'permissions'} othertextInput={t('permissions')} />
                    
                    <div className="atteched-field" >
                        <p className="title-text">{t("attachedFiles")}</p>
                        <IconButton
                            iconProps={Icons.pdf}
                            styles={{
                                icon: { color: 'blue', fontSize: 25 }
                            }}
                            className="button"
                            onClick={() => setaddFiles(true)}
                        />
                    </div>
                    {addFiles ? <CustomFilesDialog setShowDialog={setaddFiles} /> : ""}
                    <hr className="hr"></hr>
                    <CustemTable tableName="attachedFilesUser" columns={columnsCollateralCustomer} allItems={itemsCollateralCustomer} renderItemColumn rederRow={"operationsFiles"} addCustem={true} isFooter={true} />
                    <div className="note-field">
                        <p className="title-text">{t('note')}</p>
                        <CustomTextField value={''} onChange={''} id={'note'} /></div>
                    <DialogFooter >
                        <PrimaryButton onClick={() => { toggleHideDialog(); setShowDialog(false) }} text={t("update")} />
                        <DefaultButton onClick={() => { toggleHideDialog(); setShowDialog(false) }} text={t("cancel")} />
                    </DialogFooter>
                </div>
            </Dialog>
        </>

    )

}

export default UsersClientDialog
