import { useEffect } from 'react';
import { Dialog, ContextualMenu, PrimaryButton, DefaultButton, DialogFooter } from 'office-ui-fabric-react';
import { useBoolean } from '@fluentui/react-hooks';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import CustemTable from '../../../../shared/components/tabels/tableList';
import { RESET_CHEQUE_MANAGMENT_EVENT_DATA } from '../../../../store/actions/types';


const ChequesListDialog = (props: any) => {
    const { setShowDialog, confirmChequeEvent,chequeEventName} = props
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const chequeManagmentEventData = useSelector(({ discountReducer }: any) =>
        discountReducer?.chequeManagmentEventData)
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);


    const chequeManagmentData = [
        {
            key: '2', name: t('customerName'), fieldName: "entity_name",
            entity_name: "entity_name", minWidth: 190, maxWidth: 190
        },
        {
            key: '3', name: t('referenceNumber'), fieldName: "reference_number"
            , minWidth: 100, maxWidth: 100,

        },
        {
            key: '4', name: t('amount'), fieldName: "amount"
            , minWidth: 120, maxWidth: 120,
             onRender: (item: any) => (
                <p>{item?.amount.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
            ),
        },

        {
            key: '5', name: t('status'), fieldName: "status_name",
            entity_name: "status_name", minWidth: 120, maxWidth: 120
        },
        {
            key: '6', name: t('currencyCode'), fieldName: "currency_code",
            entity_name: "currency_code", minWidth: 40, maxWidth: 40
        },

    ];


    useEffect(() => {
        if (setShowDialog) {
            toggleHideDialog()
        }
    }, [setShowDialog, toggleHideDialog])

    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
    };
    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        dragOptions: dragOptions
    };


    const closeDialog = async () => {
        await dispatch({
            type: RESET_CHEQUE_MANAGMENT_EVENT_DATA,
            payload: {}
        })
        toggleHideDialog()
        setShowDialog(false)
    }
    const confirmChequeEventFunc = async () => {
        await confirmChequeEvent()
        await dispatch({
            type: RESET_CHEQUE_MANAGMENT_EVENT_DATA,
            payload: {}
        })
        toggleHideDialog()
        setShowDialog(false)
    }
    return (
        <>
            <Dialog minWidth="xl" maxWidth="xl" hidden={hideDialog} modalProps={modelProps} onDismiss={closeDialog}  >
                <div style={{ width: "100%" }}>
                    <h3>{t("youChosePerform")} {chequeEventName} {t("followingCheques")}</h3>
                    <hr className="hr"></hr>
                    {chequeManagmentEventData?.cheques_list && <div style={{ width: "700px" }}><CustemTable
                        tableName="homePageAccountChequesStatus" columns={chequeManagmentData}
                        allItems={chequeManagmentEventData?.cheques_list}
                        addCustem={true} isFooter={false} /></div>}
                    <h6>{t("confirmOrCancelAction")}</h6>
                    <DialogFooter>
                        <DefaultButton onClick={closeDialog} text={t("cancel")} />
                        <PrimaryButton className={`margin-between-input-${dir}`} onClick={confirmChequeEventFunc} text={t("confirm")} />
                    </DialogFooter>
                </div>
            </Dialog>
        </>
    )

}
export default ChequesListDialog