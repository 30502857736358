
export default function PrintTable(props: any) {

    const { actionData, arrTitle, printRef,title } = props

    return (

        <div style={{ display: "none" }}>
            <table style={{ border: '2px solid #e1e1e1', marginTop: '2%'}} ref={printRef}>
                <tbody>
                    <tr>
                        {arrTitle?.length && arrTitle?.map((row: any, index: number) => {
                            return (
                                <th key={index} style={{ border: 'none', backgroundColor: "#e1e1e1", height: '46px', minWidth: '170px', textAlign: 'start', color: 'gray' }}>
                                    {(title==="דוחות"||title==="Reports")?row :row?.key}
                                </th>)
                        })}
                    </tr>
                    {(actionData?.length) && actionData?.map((item: any, key: any) => {
                         return (
                            <tr key={key} className="hoverRow" style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }}>
                                {arrTitle?.map((field: any, index: number) => {
                                    return (<td key={index} className="tdCenter">{(title==="דוחות"||title==="Reports")?item[field]:item[field?.eng]}</td>)
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>

    )
}
