import { useBoolean } from '@fluentui/react-hooks';
import { PrimaryButton, Dialog, ContextualMenu, DialogFooter, DefaultButton, Dropdown } from 'office-ui-fabric-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomDropdown } from '../../Option';
import { CustomTextField } from '../../TextField';
import { createCustomerInConvertions } from '../../../../store/actions/customerActioin'
import { convertArrToDropdropdown, IUserCredentials } from '../../../../components/customerDetails/CustomerDetails'
import { useDispatch, useSelector } from "react-redux";
import { IdNumber } from '../../CustomHooks/concatTextFields'
import { Address } from '../../CustomHooks/Address'

import "../dialog.scss";
import { DialogMessages } from '../DialogMessages';

export const BeneficiaryDialog = (props: any) => {
  const { setShowDialog, setnewBenObject, setidBeneficiary,salvo, newBenObject } = props
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [t] = useTranslation();
  const [dir, setDir] = useState("")
  const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
  const dispatch = useDispatch()
  const authReducer = useSelector(({ authReducer }: any) => authReducer)
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
  const [enumsDropdown, setenumsDropdown] = useState<any>({ "countryCode": [], "bank": [], "branch": [] ,"CustomerType": [],})
  const [customerTypeDropdown, setcustomerTypeDropdown] = useState<any>({ "CustomerType": [], "bank": [], "branch": [] })

  const [validId, setValidId] = useState(true)
  const [showDialogRequiredFields, setShowDialogRequiredFields] = useState(false)
  const [requiredFields, setRequiredFields] = useState(false)

  const [defaultIdTypeId, setDefaultIdTypeId] = useState()
  const [newBeneficiary, setnewBeneficiary] = useState(newBenObject ? newBenObject : {
    gender: 1,
    otherGender: "",
    entityStatusId: 0,
    idTypeCountryCode: "",
    firstName: '',
    note: "",
    lastName: '',
    isLocked: false,
    isPopupMessage: false,
    dateBirth: "",
    genderOther: "",
    middleName: "",
    otherCustomerStatus: "",
    classId: 1,
    entitySubTypeId: 1,
    ViewNoteWhenPerformingAction: false,
    industryId: 0,
    CreditGroup: 0,
    idAffiliate: 0,
    idTypeId: "",
    idNumber: "",
    idTypeOther: "",
    addressCountryCode: "",
    idCountryCode: "",
    addressState: "",
    idIdentifier: 0,
    address: "",
    addressName: "",
    addressNumber: "",
    addressCity: "",
    addressCityName: "",
    //     iDCountryCode: "",
    telephone: "",
    telephoneCountryCode: "",
    CustomerType:"",
    email: "",
    entityNumber: "",
    addressZipCode: "",
    customerTypeId: "",
    workerName: "",
    genderName: "",
    telephoneTypeId: 1,
    telephoneId: 0,
    addressTypeId: 1,
    emailTypeId: 1,
    emailId: 0,
    telephoneDefault: 1,
    addressDefault: 1,
    emailDefault: 1,
    addressId: 0,
    login_entity_number: '',
    login_ID: '',
    login_password: ''
  })

  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
  };
  const modelProps = {
    isBlocking: true,
    topOffsetFixed: true,
    dragOptions: dragOptions
  };

  useEffect(() => {
    setUserCredentials({
      "idInitiator": authReducer.data.user.id_entity,
      "idClient": authReducer.data.user.id_client,
      "idBranch": authReducer.data.branch.id_entity,
      "idClientData": authReducer.data.client.id_client
    })

  }, [authReducer]);

  useEffect(() => {
    if (lang) {
      setDir(lang)
    }
  }, [lang])


  useEffect(() => {
    if (enums) {
      
      let arr = {
        "countryCode": convertArrToDropdropdown([]),
        "genders": convertArrToDropdropdown([]),
        "typeIdentityNumbers": convertArrToDropdropdown([]),
        "status": convertArrToDropdropdown([]),
        "telephoneCountryCode": convertArrToDropdropdown([]),
        "customersType": convertArrToDropdropdown([]),
        // "CustomerType":

      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].customer !== undefined) {
          let customer = enums[i].customer;
          for (let j = 0; j < customer.length; j++) {
            // if (customer[j].gender_id !== undefined)
            if (customer[j].gender_id)
             arr.genders = convertArrToDropdropdown(customer[j].gender_id)
            if (customer[j].ID_type_id !== undefined)
              arr.typeIdentityNumbers = convertArrToDropdropdown(customer[j].ID_type_id)
            if (customer[j].status_id !== undefined)
            console.log("123");
              arr.status = convertArrToDropdropdown(customer[j].status_id)
              if (customer[j].customer_type_id !== undefined)
              arr.customersType = convertArrToDropdropdown(customer[j].customer_type_id)
            // if (customer[j].customer_type_id === undefined)
            // console.log("undedeccc");
            //   arr.customersType = ["חברה","אדם פרטי"]
          }
        }
        if (enums[i].general !== undefined) {
          let country = enums[i].general          
          for (let i = 0; i < country.length; i++) {            
            if (country[i].country_code !== undefined)
              arr.countryCode = convertArrToDropdropdown(country[i].country_code)
            if (country[i].telephone_country_code !== undefined)
              arr.telephoneCountryCode = convertArrToDropdropdown(country[i].telephone_country_code, "tel")
            // if (country[i].Customer_type !== undefined)
            // console.log();
            
            //   arr.CustomerType = convertArrToDropdropdown(country[i].Customer_type, "Customer_type")
          }
        }

      }
      let defaultTelephoneValue: any
      (defaultTelephoneValue = arr?.telephoneCountryCode.find(({ is_default }: any) => is_default === true))

      let defaultCountryValue: any
      (defaultCountryValue = arr?.countryCode.find(({ is_default }: any) => is_default === true))
      let defaultCustomerType:any
      (defaultCustomerType = arr?.customersType.find(({ is_default }: any) => is_default === true))
      let defaultTypeValue: any
      (defaultTypeValue = arr?.typeIdentityNumbers.find(({ is_default }: any) => is_default === true))
      setDefaultIdTypeId(defaultTypeValue?.key)
      setnewBeneficiary({
        ...newBeneficiary, addressCountryCode: defaultCountryValue?.key, iDCountryCode: defaultCountryValue?.key, telephoneCountryCode: defaultTelephoneValue?.key, idTypeId: defaultTypeValue?.key,
        idTypeCountryCode: defaultCountryValue?.key, CustomerType:defaultCustomerType?.key ,
      })
      console.log(arr,"arrin");
      

      setenumsDropdown(arr);
    }
  }, [enums])

  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog()
    }
  }, [setShowDialog])
  const changeUser = (key: string, value: any) => {
    console.log("key12",key);
    console.log("valu",value);
    
    setnewBeneficiary({ ...newBeneficiary, [key]: value })
  }
  const check = (currentValue: string) => newBeneficiary[currentValue]?.length > 0;

  const createBeneficiary = () => {
    debugger;
    let requiredFields: any 
     console.log(salvo,"newBeneficiary.idTypeCountryCode");
    if(newBeneficiary.customerTypeId !==1){
      if(newBeneficiary.idTypeCountryCode === "IL" || salvo){
        console.log("לאאא");
        {requiredFields = ["firstName", "idNumber", "addressCityName", "addressNumber", "addressName"];}   
      }
      else 
      {requiredFields = ["lastName"];
      console.log("nnnn");
      
    }
      console.log(requiredFields,"requiredFields");
    }
    else{
      if(newBeneficiary.idTypeCountryCode === "IL" || salvo){
        console.log("יששש");
        {requiredFields = ["", "idNumber", "addressCityName", "addressNumber", "addressName"];}   
      }
      else {requiredFields = ["lastName",];
      console.log("iuiui");
    }
      console.log(requiredFields,"requiredFields");
    }
    debugger;
    if (requiredFields.every(check)) {
      dispatch(createCustomerInConvertions(newBeneficiary, userCredentials, setnewBenObject, setidBeneficiary))
      setShowDialog(false);
      toggleHideDialog();
    }
    else {
      setRequiredFields(true)
      setShowDialogRequiredFields(true)
    }
  }
  console.log(newBeneficiary.customerTypeId,"123");
  
  return (
    <>
      <Dialog hidden={hideDialog} modalProps={modelProps} onDismiss={() => { toggleHideDialog(); setShowDialog(false) }} >
        {showDialogRequiredFields && <DialogMessages setansDelete={setShowDialogRequiredFields}
          setshowModalDelete={setShowDialogRequiredFields} dialogType={"requiredFields"} />}
        <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{t("createNewCustomer")}</p>
        <hr className="hr"></hr>

        {/* className="wrap-dialog" */}
        <div >
        {/* <CustomDropdown readOnly={false} otherInputId={''} hasOtherValue={false} options={enumsDropdown.telephoneCountryCode} label={t("areaCode")} onChange={changeUser} selectedKey={newBeneficiary?.telephoneCountryCode} id={'telephoneCountryCode'} othertextInput={t('')} /> */}
        <div
                  className={`div-customer-details-${dir}`}
                >
                         <CustomDropdown
                    otherInputId={""}
                    hasOtherValue={false}
                    options={enumsDropdown?.customersType}
                    label={t("customerType")}
                    onChange={changeUser}
                    selectedKey={enums?.customerTypeId}
                    id={"customerTypeId"}
                    othertextInput={t("")}
                  /> 
                </div>
       {
          newBeneficiary.customerTypeId === "3" ||  newBeneficiary.customerTypeId === "10" ||  newBeneficiary.customerTypeId === "9" || newBeneficiary.customerTypeId === "5" ||  newBeneficiary.customerTypeId === "12"?
          <CustomTextField autoFocus={true} readOnly={false} value={newBeneficiary?.lastName}
           label={"שם תאגיד"} onChange={changeUser} id={'lastName'}
           requiredField={requiredFields && !newBeneficiary?.lastName ? true : false}
            />
           : <>
            <CustomTextField autoFocus={true} readOnly={false} value={newBeneficiary?.firstName}
           label={t("firstName")} onChange={changeUser} id={'firstName'}
           requiredField={requiredFields && !newBeneficiary?.firstName ? true : false}
            />
          <CustomTextField readOnly={false} value={newBeneficiary?.lastName}
            requiredField={requiredFields && !newBeneficiary?.lastName ? true : false}
           label={t("lastName")} onChange={changeUser} id={'lastName'} />
             {/* <CustomTextField autoFocus={true} readOnly={false} value={newBeneficiary?.firstName}
           label={t("firstName")} onChange={changeUser} id={'firstName'}
           requiredField={requiredFields && !newBeneficiary?.firstName ? true : false}
            />
          <CustomTextField readOnly={false} value={newBeneficiary?.lastName}
            requiredField={requiredFields && !newBeneficiary?.lastName ? true : false}
           label={t("lastName")} onChange={changeUser} id={'lastName'} /> */}
            </>
        }

        
          {/* <CustomTextField value={newBeneficiary?.idNumber} readOnly={readOnly} label={"מספר זהות"} onChange={changeUser} id={'idNumber'} />
            <CustomDropdown otherInputId={''} readOnly={readOnly} hasOtherValue={false} options={enumsDropdown.countryCode} label={t('countryIdentityNumber')} onChange={changeUser} selectedKey={newBeneficiary?.idTypeCountryCode} id={'idTypeCountryCode'} othertextInput={t('')} />
            <CustomDropdown otherInputId={'idTypeOther'} readOnly={readOnly} otherValue={newBeneficiary.idTypeOther} hasOtherValue={true} options={enumsDropdown.typeIdentityNumbers} label={t('typeIdentityNumber')} onChange={changeUser} selectedKey={newBeneficiary?.idTypeId} id={'idTypeId'} othertextInput={t('typeIdentityNumberOther')} /> */}
          <IdNumber showRequiredFildes={requiredFields} entity={newBeneficiary} 
          // <IdNumber  entity={newBeneficiary} 
          setEntity={setnewBeneficiary} readOnly={false}
           setResultId={setValidId}  typeIdDefault={defaultIdTypeId}
          resultId={validId} typeIdentityNumbers={enumsDropdown.typeIdentityNumbers}
           />
          {/* countryCode={enumsDropdown?.countryCode} */}
          <div className="wrapper-collateral">
            <div className={`warpper-fields-right-${dir}`}>
              <CustomTextField value={newBeneficiary?.telephone} readOnly={false}
               label={t("phoneNumber")} onChange={changeUser} id={'telephone'} />
            </div>
            <div className={`warpper-fields-left-${dir}`}>
              <CustomDropdown readOnly={false} otherInputId={''} hasOtherValue={false} options={enumsDropdown.telephoneCountryCode} label={t("areaCode")} onChange={changeUser} selectedKey={newBeneficiary?.telephoneCountryCode} id={'telephoneCountryCode'} othertextInput={t('')} />
            </div>
          </div>
          <Address setEntity={setnewBeneficiary} entity={newBeneficiary}
          showRequiredFildes={requiredFields}
           classStyle={true} />
        </div>
        <DialogFooter >
          <DefaultButton onClick={() => { toggleHideDialog(); setShowDialog(false) }} text={t("cancel")} />
          <PrimaryButton onClick={() => { createBeneficiary() }} text={t("create")} />
        </DialogFooter>
      </Dialog>
    </>
  )

}