import { FormEvent, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux'
import { Icon, Dropdown, IDropdownOption, PrimaryButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import { saveCurrencyEntry, createTransaction } from '../../../store/actions/convertionActions'
import { invoiceTransaction, receiptTransaction } from '../../../store/actions/accountAction'


export const PrintHtml = (props: any) => {
    const { action, setAction, userCredentials, setHtml, saveFunction, typeAction, conversionCustomer } = props

    const dispatch = useDispatch()
    const [t] = useTranslation();
    const [dir,setDir]=useState("")
    const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)
    let textFinishButton=typeAction === "receipt"?t("generateReceipt"):typeAction === "invoice"?t("generateInvoice"):t("finishTransaction")
    const menuProps: IDropdownOption[] = [
        { key: 'print_draft', text: t('printDraft'), data: { icon: 'print' } },
        { key: 'print_quote', text: t('printQuote'), data: { icon: 'print' } }
    ]
    useEffect(()=>{
        if(lang){
            setDir(lang)
        }
        },[lang])
        
    const print = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        let convert = cloneDeep(action)
        let document = convert["transaction_document"]
        let value: any = option?.key
        document.document_request_method = value
        setAction({ ...action, "transaction_document": document })
        if (typeAction === "money_in" || typeAction === "money_out")
            dispatch(saveCurrencyEntry("update", typeAction, convert, userCredentials, null, null, setHtml,))
        if (typeAction === "invoice")
            dispatch(invoiceTransaction("update", typeAction, conversionCustomer.entityName, convert, userCredentials, action.id_entity))
        if (typeAction === "receipt")
            dispatch(receiptTransaction("update", typeAction, conversionCustomer.entityName, convert, userCredentials, action.id_entity))
        if (typeAction === "exchange" || typeAction === "cheque_cashing") {
            dispatch(createTransaction("update", typeAction, conversionCustomer.entityName, convert, userCredentials, action.id_entity))
        }
       

    }




    const save = async () => {
        saveFunction()
    }

    const onRenderCaretDown = (): JSX.Element => {
        return <Icon iconName="print" className={`print-icon-${dir}`} />;
    };

    const onRenderOption = (option: IDropdownOption | undefined): JSX.Element => {
        return (
            <div>
                {option?.data && option?.data.icon && (
                    <Icon iconName={option?.data.icon} aria-hidden="true" title={option?.data.icon} />
                )}
                <span>{" " + option?.text}</span>
            </div>
        );
    };

    return (
        <>
            {(typeAction !== "money_in" && typeAction !== "money_out" && typeAction !== "receipt" && typeAction !== "invoice"&& typeAction !== "transfer") &&
             <Dropdown className={`dropDownStyle-${dir}`} options={menuProps} label={''} disabled={action?.id_transaction ? false : true}
                onChange={print} placeholder={t("print")} onRenderOption={onRenderOption} styles={{
                    title: { backgroundColor: "green", color: "white", width: "233px", marginBottom: "4px" }, root: {
                        textAlign: "center",
                        color: "white",
                        width: "234px",
                        float: "left"
                    }
                }
                }
                onRenderCaretDown={onRenderCaretDown}
                selectedKey={action?.transaction_document?.document_request_method} id={"transaction_document"}
            />}
            <PrimaryButton className={`bottun-${dir}`} style={{ width: "233px" }} readOnly={action?.id_transaction ? true : false} allowDisabledFocus checked={false} name="finish" onClick={save} text={textFinishButton} />
        </>
    )

}