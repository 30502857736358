import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from "react-currency-input-field";
import { ComboBox, IComboBox, IComboBoxOption } from "office-ui-fabric-react";

import { CustomDropdown } from "../../shared/components/Option";
import { DialogMessages } from "../../shared/components/dialog/DialogMessages";
import { CustomTextField } from "../../shared/components/TextField";
import { convertArrToDropdropdown } from "../customerDetails/CustomerDetails";
import { cloneDeep, isEqual } from "lodash";
import {
  CashPaymentDialog,
  CustomDialogCreditFromCustomer,
  CustomDialogTransferFromCustomer,
} from "../../shared/components/dialog/Dialog";
import { CheckDetailsDialog } from "../../shared/components/dialog/dialogTransaction/CheckDetailsDialog";
import { deteleFile } from "../../store/actions/FilesAction";
import { receiptTransaction } from "../../store/actions/accountAction";

import "./Receipt.scss";

export default function ReceiptTable(props: any) {
  const {
    setAction,
    action,
    ifChanges,
    setIfChanges,
    emptyInAmount,
    emptyContraAccount,
    emptyRequiredFields,
    readOnly,
    searchCustomer,
    userCredentials,
  } = props;
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);
  const dispatch = useDispatch();
  const dataReceipt = useSelector(
    ({ accountReducer }: any) => accountReducer?.dataAccount
  );
  const authReducer = useSelector(({ authReducer }: any) => authReducer);
  const contraAccounts = useSelector(
    ({ authReducer }: any) => authReducer?.data?.user.contra_accounts
  );
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [messageError, setMessageError] = useState("");
  const [serverMessageError, setServerMessageError] = useState<any>();
  const [scanButton, setScanButton] = useState(false);
  const [rowNumber, setRowNumber] = useState("1");
  const [transferFromCustomer, setTransferFromCustomer] = useState(false);
  const [checkDetails, setCheckDetails] = useState(false);
  const [cashPayment, setCashPayment] = useState(false);
  const [credit, setCredit] = useState(false);
  const [contraCash, setContraCash] = useState<any>([]);
  const [contraCheck, setContraCheck] = useState<any>([]);
  const [contraCreditCard, setContraCreditCard] = useState<any>([]);
  const [contraBankAccounts, setcontraBankAccounts] = useState<any>([]);
  const [saveDeleteRowActoin, setSaveDeleteRowActoin] = useState("");
  const [rowOfDelete, setRowOfDelete] = useState<any>();
  const [enumsDropdown, setenumsDropdown] = useState<any>({
    currencyCode: [],
    finanssialAssetCode: [],
  });
  const [listCurrency, setListCurrency] = useState<any>([]);
  const currencies = authReducer?.data
    ? authReducer?.data?.branch.setting?.currency_shortcuts
    : [];
  const [defaultfinanssialAsset, setDefaultfinanssialAsset] = useState();
  const [defaultCurrencyCode, setdefaultCurrencyCode] = useState();
  const [currenciesEnum, setCurrenciesEnum] = useState<any>([]);
  let arrGates = sessionStorage.getItem("gatesArr");
  const [gates, setGates] = useState<any>(arrGates ? JSON.parse(arrGates) : []);
  let num1: any;
  let num: any;

  useEffect(() => {
    if (enums) {
      let list: any = [];
      let listSomeCurrency: any = [];
      let listAllCurrency: any = [];
      let arr = {
        currencyCode: list,
        someOfcurrency: listSomeCurrency,
        allOfcurrency: listAllCurrency,
        finanssialAssetCode: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].general !== undefined) {
          let s = enums[i].general;
          for (let i = 0; i < s.length; i++) {
            if (s[i].currency_code !== undefined) {
              s[i].currency_code?.forEach((item: any) => {
                arr.allOfcurrency.push({
                  currency_symbol: item?.currency_symbol,
                  key: item?.enum_id,
                  text: item?.enum_value,
                  is_default: item?.is_default,
                });
                if (item?.is_selected) {
                  arr.someOfcurrency.push({
                    currency_symbol: item?.currency_symbol,
                    key: item?.enum_id,
                    text: item?.enum_value,
                    is_default: item?.is_default,
                  });
                }
              });
            }
          }
        }
        if (enums[i].exchange !== undefined) {
          let s = enums[i].exchange;
          for (let i = 0; i < s.length; i++) {
            if (s[i].financial_asset_code !== undefined)
              arr.finanssialAssetCode = convertArrToDropdropdown(
                s[i].financial_asset_code
              );
          }
        }
      }
      arr.someOfcurrency.push({
        currency_symbol: "",
        key: "More",
        text: t("more"),
        is_default: false,
      });
      arr.allOfcurrency.push({
        currency_symbol: "",
        key: "Less",
        text: t("less"),
        is_default: false,
      });
      arr.currencyCode = [...arr.someOfcurrency];
      let defaultfinanssialValue: any;
      defaultfinanssialValue = arr?.finanssialAssetCode.find(
        ({ is_default }: any) => is_default === true
      );
      let defaultcurrencyCodeValue: any;
      defaultcurrencyCodeValue = arr?.currencyCode.find(
        ({ is_default }: any) => is_default === true
      );
      setdefaultCurrencyCode(
        defaultcurrencyCodeValue && defaultcurrencyCodeValue.key
      );
      setDefaultfinanssialAsset(
        defaultfinanssialValue && defaultfinanssialValue.key
      );
      if (action?.financial_assets_in[0].amount_ils === 0) {
        let financialAssets: any = [];
        financialAssets = [...action?.financial_assets_in];
        financialAssets[0].financial_asset_code = defaultfinanssialValue?.key;
        financialAssets[0].currency_code = defaultcurrencyCodeValue?.key;
        setAction((prevState: any) => ({
          ...prevState,
          financial_assets_in: financialAssets,
        }));
        setenumsDropdown(arr);
      }
    }
  }, [enums]);

  useEffect(() => {
    let allCurrencies: any[] = [];
    let allEnums: any[] = [];
    if (
      currencies &&
      enumsDropdown.currencyCode.length > 0 &&
      currenciesEnum.length === 0
    ) {
      allCurrencies = [...enumsDropdown.currencyCode];
      enumsDropdown.currencyCode.forEach((c: any) => {
        let index = currencies.findIndex((i: any) => i === c.key);
        if (index !== -1) {
          allEnums.push(c);
          allCurrencies = allCurrencies.filter((item) => item !== c);
        }
      });

      allCurrencies.sort(function (a: any, b: any) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      allCurrencies.push({ key: 0, text: "הצג הכל" });
      setListCurrency(allEnums);
      setCurrenciesEnum(allCurrencies);
    }
  }, [currencies, enumsDropdown]);

  useEffect(() => {
    if (saveDeleteRowActoin === "save") {
      setSaveDeleteRowActoin("");
      setIfChanges(true);
      deleteRow();
    }
    if (saveDeleteRowActoin === "don't save") setSaveDeleteRowActoin("");
  }, [saveDeleteRowActoin]);

  useEffect(() => {
    if (contraAccounts) {
      let arrCash: any[] = [],
        arrCheck: any[] = [],
        arrCredit: any[] = [],
        arrBank: any[] = [];
      for (let i = 0; i < contraAccounts.length; i++) {
        if (contraAccounts[i].contra_account_group_id === "cash") {
          arrCash.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "cheque") {
          arrCheck.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "credit_card") {
          arrCredit.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "bank_account") {
          arrBank.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
      }
      setContraCash(arrCash);
      setContraCheck(arrCheck);
      setContraCreditCard(arrCredit);
      setcontraBankAccounts(arrBank);
    }
  }, [contraAccounts]);

  const checkRequiredFields = (element: any) => {
    if (
      element?.financial_asset_code === "3" &&
      (!element?.financial_asset_details?.cheque_date ||
        !element?.financial_asset_details?.reference_number ||
        !element.amount)
    )
      return true;
    if (
      element?.financial_asset_code === "12" &&
      !element?.financial_asset_details?.card_holder?.ID_number
    )
      return true;
    if (
      element?.financial_asset_code === "9" &&
      !element?.financial_asset_details?.account_holder?.ID_number
    )
      return true;
    return false;
  };

  const addFinancial = (key: string, value: any, isBalance?: any) => {
    const [id, row_number] = key.split(",");
    key = id;
    let arr: any = [],
      sum = 0;
    arr = [...action["financial_assets_in"]];
    let index = arr.findIndex(
      (item: any) => Number(item.row_number) === Number(row_number)
    );
    let i = index;
    if (id === "financial_asset_code") {
      setRowNumber(row_number);

      if (value === "12") {
        let defaultcontraCreditCard = contraCreditCard.find(
          (item: any) => item.is_default === true
        );
        if (value !== arr[i]?.financial_asset_code && defaultcontraCreditCard) {
          arr[i].contra_id_account = defaultcontraCreditCard?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCreditCard.length === 1
        ) {
          arr[i].contra_id_account = contraCreditCard[0].key;
        }
        setCredit(true);
      }
      if (value === "9") {
        let defaultcontraBankAccount = contraBankAccounts.find(
          (item: any) => item.is_default === true
        );
        if (
          value !== arr[i]?.financial_asset_code &&
          defaultcontraBankAccount
        ) {
          arr[i].contra_id_account = defaultcontraBankAccount?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraBankAccounts.length === 1
        ) {
          arr[i].contra_id_account = contraBankAccounts[0].key;
        }
        setTransferFromCustomer(true);
      }
      if (value === "1") {
        let defaultcontraCash = contraCash.find(
          (item: any) => item.is_default === true
        );
        if (value !== arr[i]?.financial_asset_code && defaultcontraCash) {
          arr[i].contra_id_account = defaultcontraCash?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCash.length === 1
        ) {
          arr[i].contra_id_account = contraCash[0].key;
        }
        setCashPayment(true);
      }
      if (value === "3") {
        let defaultcontraCheque = contraCheck.find(
          (item: any) => item.is_default === true
        );
        if (
          (value !== arr[i]?.financial_asset_code ||
            !arr[i].contra_id_account) &&
          defaultcontraCheque
        ) {
          arr[i].contra_id_account = defaultcontraCheque?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCheck.length === 1
        ) {
          arr[i].contra_id_account = contraCheck[0].key;
        }
        setCheckDetails(true);
      }

      if (arr[index]?.financial_asset_code !== value) {
        arr[index].financial_asset_details = {};
      }
      if (
        value === dataReceipt["financial_assets_in"][i].financial_asset_code
      ) {
        arr[index].financial_asset_details =
          dataReceipt["financial_assets_in"][i].financial_asset_details;
        if (dataReceipt["financial_assets_in"][i]?.contra_id_account)
          arr[i].contra_id_account =
            dataReceipt["financial_assets_in"][i]?.contra_id_account;
      }
      if (value === "18")
        arr[index].contra_id_account = searchCustomer?.accounts[0].id_account;
    }
    if (arr.length - 1 >= i) {
      let num = "0";
      if (id === "currency_code" && gates[value]) {
        let num1 = 1 / gates[value];
        num = num1.toFixed(2);
      }
      let temp: any = {
        currency_code: id === "currency_code" ? value : arr[i]?.currency_code,
        contra_id_account:
          id === "contra_id_account" ? value : arr[i]?.contra_id_account,
        financial_asset_code:
          id === "financial_asset_code" ? value : arr[i]?.financial_asset_code,
        amount: id === "amount" ? (value ? value : 0) : arr[i]?.amount,
        exchange_rate_ils:
          id === "exchange_rate_ils"
            ? value
            : id === "currency_code"
            ? parseFloat(num)
            : arr[i]?.exchange_rate_ils,
        amount_ils:
          id === "amount"
            ? value * arr[i]?.exchange_rate_ils
            : id === "exchange_rate_ils"
            ? value * arr[i]?.amount
            : id === "currency_code"
            ? arr[i]?.amount * parseFloat(num)
            : arr[i]?.amount_ils,
        row_number: arr[i]?.row_number,
        financial_asset_details: arr[i]?.financial_asset_details,
      };
      if (id === "amount" && isBalance)
        temp.amount = Math.round(value * 100) / 100;
      if (id === "exchange_rate_ils" && isBalance)
        temp.exchange_rate_ils = Math.round(value * 100) / 100;
      arr[i] = temp;
      for (let i = 0; i < arr.length; i++) {
        sum += arr[i]?.amount_ils ? Number(arr[i]?.amount_ils) : 0;
      }
    }
    let c: any = action;
    !isEqual(c[key], value) && setIfChanges && setIfChanges(true);
    setAction((prevState: any) => ({
      ...prevState,
      financial_assets_in: arr,
      total_amount_ils: sum,
    }));
  };

  const deleteRowMassage = (row_number: number) => {
    setRowOfDelete(row_number);
    setServerMessageError(true);
    setMessageError(t("delteRowMassege"));
  };

  const deleteRow = async () => {
    let arr: any = [],
      copyAction: any,
      idMediaFace: any,
      idMediaBack: any,
      sum = 0;
    copyAction = { ...action };
    arr = [...action["financial_assets_in"]];
    let index = arr.findIndex(
      (a: any) => Number(a.row_number) === Number(rowOfDelete)
    );
    if (arr[index]?.financial_asset_code === "3") {
      idMediaFace =
        arr[index]?.financial_asset_details?.media?.media_face?.id_media;
      idMediaBack =
        arr[index]?.financial_asset_details?.media?.media_back?.id_media;
    }
    arr.splice(index, 1);
    if (arr.length === 0)
      arr.push({
        currency_code: defaultCurrencyCode,
        financial_asset_code: defaultfinanssialAsset,
        amount: 0,
        exchange_rate_ils: 1,
        amount_ils: 0,
        row_number: 1,
        financial_asset_details: null,
      });

    for (let i = 0; i < arr.length; i++) {
      sum += Number(arr[i].amount_ils);
    }
    copyAction.financial_assets_in = arr;
    copyAction.total_amount_ils = sum;
    setAction(copyAction);
    idMediaFace &&
      (await dispatch(
        deteleFile(action.id_entity, idMediaFace, userCredentials)
      ));
    idMediaBack &&
      (await dispatch(
        deteleFile(action.id_entity, idMediaBack, userCredentials)
      ));
    if (idMediaFace || idMediaBack)
      await dispatch(
        receiptTransaction(
          "update",
          "receipt",
          dataReceipt.entity_name,
          copyAction,
          userCredentials,
          action.id_entity
        )
      );
  };

  const changeRate = async (event: any, item: any) => {
    let rate = event?.target?.id;
    let arr: any = [];
    num1 = 1 / gates[rate];
    num = num1.toFixed(2);
    let allCurrencies = [...enumsDropdown.currencyCode];
    let addarr: any = [];
    let c = {
      key: item.key,
      text: item.text,
      currency_symbol: item.currency_symbol,
      is_default: false,
    };
    const found = allCurrencies.some((el) => el.key === c.key);
    if (!found) addarr.push(c);
    addarr = addarr.concat(allCurrencies);
    setCurrenciesEnum(addarr);
    addarr = [];
    arr = cloneDeep(action.financial_assets_in);
    if (arr[arr.length - 1]?.amount_ils > 0) {
      await addRow(arr[arr.length - 1].row_number, rate);
    } else {
      arr[arr.length - 1].exchange_rate_ils = num;
      arr[arr.length - 1].currency_code = rate;
      arr[arr.length - 1].amount_ils =
        arr[arr.length - 1].amount * parseFloat(num);
      setAction((prevState: any) => ({
        ...prevState,
        financial_assets_in: arr,
      }));
    }
  };

  const addRow = async (rowNumber: Number, rate?: any) => {
    let arr: any = [];
    setIfChanges(true);
    arr = [...action["financial_assets_in"]];
    let maxRowNumber = Math.max.apply(
      Math,
      arr.map(function (item: any) {
        return item.row_number;
      })
    );
    // let defaultcontraCash = contraCash.find((item: any) => item.is_default === true)

    if (maxRowNumber === rowNumber) {
      arr.push({
        currency_code: defaultCurrencyCode,
        financial_asset_code: defaultfinanssialAsset,
        financial_asset_details: "",
        amount: 0,
        exchange_rate_ils: 1,
        amount_ils: 0,
        contra_id_account: "",
        // defaultfinanssialAsset === "1" && defaultcontraCash.key,
        row_number: maxRowNumber + 1,
      });
    }
    if (rate) {
      arr[arr.length - 1].exchange_rate_ils = num;
      arr[arr.length - 1].currency_code = rate;
      arr[arr.length - 1].amount_ils =
        arr[arr.length - 1].amount * parseFloat(num);
    }
    setAction({ ...action, financial_assets_in: arr });
  };

  const onChangeComboBoxOut = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string,
    id?: string
  ): void => {
    let val = option?.key;
    let key = id ? id : "currency_code";

    let copyEnumsDropDown: any = { ...enumsDropdown };
    if (val === "More" || val === "Less") {
      if (val === "More") {
        copyEnumsDropDown.currencyCode = copyEnumsDropDown?.allOfcurrency;
        setenumsDropdown(copyEnumsDropDown);
      }
      if (val === "Less") {
        copyEnumsDropDown.currencyCode = copyEnumsDropDown?.someOfcurrency;
        setenumsDropdown(copyEnumsDropDown);
      }
    } else addFinancial(key, val);
  };

  return (
    <div>
      {serverMessageError && (
        <DialogMessages
          setansDelete={setServerMessageError}
          subText={messageError}
          title={t("note")}
          setshowModalDelete={setServerMessageError}
          setSaveDataConversion={setSaveDeleteRowActoin}
          dialogType={"dialogMessage"}
        />
      )}
      {credit ? (
        <CustomDialogCreditFromCustomer
          emptyRequiredFields={emptyRequiredFields}
          contraCreditCard={contraCreditCard}
          typeAction={"receipt"}
          conversionCustomer={searchCustomer}
          setConvertions={setAction}
          convertions={action}
          rowNumber={rowNumber}
          tableType={"financial_assets_in"}
          setShowDialog={setCredit}
          userCredentials={userCredentials}
        />
      ) : (
        ""
      )}
      {checkDetails ? (
        <CheckDetailsDialog
          scanButton={scanButton}
          setScanButton={setScanButton}
          emptyRequiredFields={emptyRequiredFields}
          ifChanges={ifChanges}
          contraCheck={contraCheck}
          setIfChanges={setIfChanges}
          typeAction={"receipt"}
          sourcePage="discounts"
          conversionCustomer={searchCustomer}
          setConvertions={setAction}
          convertions={action}
          rowNumber={rowNumber}
          tableType={"financial_assets_in"}
          setShowDialog={setCheckDetails}
          userCredentials={userCredentials}
        />
      ) : (
        ""
      )}
      {transferFromCustomer && (
        <CustomDialogTransferFromCustomer
          emptyRequiredFields={emptyRequiredFields}
          contraBankAccounts={contraBankAccounts}
          typeAction={"receipt"}
          conversionCustomer={searchCustomer}
          rowNumber={rowNumber}
          tableType={"financial_assets_in"}
          userCredentials={userCredentials}
          setConvertions={setAction}
          convertions={action}
          setShowDialog={setTransferFromCustomer}
        />
      )}
      {cashPayment ? (
        <CashPaymentDialog
          setIfChanges={setIfChanges}
          setConvertions={setAction}
          contraCash={contraCash}
          convertions={action}
          rowNumber={rowNumber}
          tableType={"financial_assets_in"}
          setShowDialog={setCashPayment}
        />
      ) : (
        ""
      )}

      <div style={{ width: "650px" }}>
        <div style={{ width: "100%" }}>
          <div className="titleC">
            <p className="title-text boldC" style={{ width: "33%" }}>
              {t("receipt")}
            </p>
            <div className={`wrapKinds-${dir}`}>
              {listCurrency.map((item: any, index: any) => (
                <label
                  key={index}
                  title={item?.text}
                  onClick={(e: any) => {
                    !readOnly && changeRate(e, item);
                  }}
                  id={item.key}
                  className={
                    readOnly
                      ? `disabled iconCurrency-${dir} recipt-label${dir}`
                      : `iconCurrency-${dir} recipt-label${dir}`
                  }
                >
                  {item?.currency_symbol}
                </label>
              ))}
            </div>
          </div>
        </div>
        <hr className="hr" style={{ width: "100%", height: "2px" }}></hr>
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div
              className="title-receipt"
              style={{ backgroundColor: "#E1E1E1" }}
            >
              <p
                className={`field-receipt-table-${dir}`}
                style={{ width: "23%" }}
              >
                {t("currency")}
              </p>
              <p
                className={`field-receipt-table-${dir}`}
                style={{ width: "21%" }}
              >
                {t("type")}
              </p>
              <p
                className={`field-receipt-table-${dir}`}
                style={{ width: "14%" }}
              >
                {t("gate")}
              </p>
              <p
                className={`field-receipt-table-${dir}`}
                style={{ width: "15%" }}
              >
                {t("amount")}
              </p>
              <p
                className={`field-receipt-table-${dir}`}
                style={{ width: "19%" }}
              >
                {t("totalILS")}
              </p>
            </div>
          </div>
          <hr
            className="hr"
            style={{ width: "100%", height: "2px", marginBottom: "0" }}
          ></hr>
          {action?.financial_assets_in?.map((c: any, index: any) => (
            <div key={index} style={{ width: "100%" }}>
              <div className="inlineB" style={{ marginTop: "7px" }}>
                <div style={{ width: "23%" }}>
                  {!readOnly ? (
                    <ComboBox
                      id={`currency_code,${c.row_number}`}
                      required={true}
                      selectedKey={c?.currency_code}
                      autoComplete="on"
                      allowFreeform={true}
                        styles={
                          dir === "ltr"
                            ? {
                                root: {
                                  paddingRight: "9px !important",
                                  paddingLeft: "32px !important",
                                },
                              }
                            : {}
                        }
                      options={
                        enumsDropdown?.currencyCode &&
                        enumsDropdown.currencyCode
                      }
                      onChange={(
                        event: any,
                        option: any,
                        index: any,
                        value: any
                      ) =>
                        onChangeComboBoxOut(
                          event,
                          option,
                          index,
                          value,
                          `currency_code,${c.row_number}`
                        )
                      }
                    />
                  ) : (
                    <CustomDropdown
                      readOnly={readOnly}
                      otherInputId={""}
                      hasOtherValue={false}
                      options={enumsDropdown.currencyCode}
                      label={t("")}
                      onChange={addFinancial}
                      selectedKey={c?.currency_code}
                      id={`currency_code,${c.row_number}`}
                      othertextInput={t("")}
                    />
                  )}
                </div>
                <div style={{ width: "21%" }}>
                  <CustomDropdown
                    readOnly={readOnly}
                    otherInputId={""}
                    hasOtherValue={false}
                    options={enumsDropdown.finanssialAssetCode}
                    label={t("")}
                    requiredField={
                      (emptyContraAccount && !c.contra_id_account) ||
                      (emptyRequiredFields && checkRequiredFields(c))
                        ? true
                        : false
                    }
                    onChange={addFinancial}
                    selectedKey={
                      c?.financial_asset_code === "10"
                        ? "9"
                        : c?.financial_asset_code
                    }
                    id={`financial_asset_code,${c.row_number}`}
                    othertextInput={t("")}
                  />
                </div>
                <CurrencyInput
                  className={`padding-currency-${dir} currency-input`}
                  id={`exchange_rate_ils,${c.row_number}`}
                  name={`exchange_rate_ils,${c.row_number}`}
                  placeholder="0.00"
                  tabIndex={-1}
                  value={c?.exchange_rate_ils}
                  readOnly={
                    c?.currency_code === defaultCurrencyCode ? true : readOnly
                  }
                  style={{
                    width: "14%",
                    backgroundColor: readOnly ? "#FAF9F8" : "white",
                  }}
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    addFinancial(name || "", value)
                  }
                />
                <CurrencyInput
                  id={`amount,${c.row_number}`}
                  name={`amount,${c.row_number}`}
                  placeholder="0.00"
                  className={`amount-out padding-currency-${dir} currency-input`}
                  value={c?.amount}
                  readOnly={readOnly}
                  decimalsLimit={2}
                  style={{
                    border: !c?.amount && emptyInAmount && "3px solid #db3838",
                    width: "15%",
                    backgroundColor: readOnly ? "#FAF9F8" : "white",
                  }}
                  onValueChange={(value, name) =>
                    addFinancial(name || "", value)
                  }
                />

                <div style={{ width: "19%" }}>
                  <CustomTextField
                    label={""}
                    value={
                      Number(c?.amount_ils)
                        ? Number(c?.amount_ils)
                            .toFixed(2)
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : 0
                    }
                    readOnly={true}
                    onChange={addFinancial}
                    id={`amount_ils,${c.row_number}`}
                    styleObj={true}
                  />
                </div>
                <p
                  style={{
                    display: "grid",
                    paddingTop: "0",
                    textAlign: "center",
                    lineHeight: "0.8",
                    marginBottom: "0",
                    width: "fit-content",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    title={t("deleteRow")}
                    className={`delete-row-invoice-${dir}`}
                    onClick={() =>
                      readOnly ? "" : deleteRowMassage(c.row_number)
                    }
                  />
                  {c.amount_ils > 0 && (
                    <FontAwesomeIcon
                      onKeyDown={(e) =>
                        e.key === "Enter" && addRow(c.row_number)
                      }
                      className={
                        c.amount_ils > 0
                          ? `iconRefresh-${dir} add-row-invoice-${dir}`
                          : `iconDisabled add-row-invoice-${dir}`
                      }
                      icon={faPlusCircle}
                      title={t("addRow")}
                      onClick={() => {
                        addRow(c.row_number);
                      }}
                    />
                  )}
                </p>
              </div>
            </div>
          ))}
          <hr
            className="hr"
            style={{ width: "100%", marginTop: "3px", marginBottom: "0" }}
          ></hr>

          <div className={`calc-discount-${dir}`}>
            {action?.total_amount_ils !== 0 && (
              <div className={`title-calc-receipt-${dir}`}>{t("total")}:</div>
            )}
            {action?.total_amount_ils !== 0 && (
              <div className="total-calc-receipt">
                {action?.total_amount_ils
                  ?.toFixed(2)
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  {t("ILS")}
              </div>
            )}
            {action?.total_amount_ils === 0 && (
              <div className={`title-calc-receipt-${dir}`}>{t("total")}:</div>
            )}
            {action?.total_amount_ils === 0 && (
              <div className="total-calc-receipt">0.00 {t("ILS")}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
