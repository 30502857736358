import React, { useEffect, useState } from "react";
import { Nav } from "@fluentui/react/lib/Nav";
import "../../scssPages/sidebar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { INavStyles, INavLinkGroup, INavLink } from "@fluentui/react/lib/Nav";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { useLocation, withRouter } from "react-router-dom";
import "../../scssPages/sidebar.scss";
import { globalPages } from "../../shared/components/CustomHooks/GlobalSidePages";
import { useTranslation } from "react-i18next";
import { registerIcons } from "@fluentui/react";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  RESET_DATA_INVOICE,
  SAVE_DRAFT_CONVERSION_CHANGES,
} from "../../store/actions/types";
import { DialogMessages } from "../../shared/components/dialog/DialogMessages";
import { getGatesData } from "../../store/actions/convertionActions";

import "../../modelsType/Icons/css/all.css";

const navStyles: Partial<INavStyles> = { root: { width: 150 } };

initializeIcons();

registerIcons({
  icons: {
    Filter: <FontAwesomeIcon icon={faFilter} />,
    MoneyCheckIcon: <i className="fa-light fa-money-check"></i>,
    LandmarkIcon: <i className="fal fa-landmark"></i>,
    UserIcon: <i className="fal fa-user"></i>,
    ReceiptIcon: <i className="fal fa-receipt"></i>,
    InvoiceIcon: <i className="fal fa-file-invoice"></i>,
    ThListIcon: <i className="fal fa-coins"></i>,
  },
});

const ContentSideAccounting = (props: any) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("");
  const [showPermissionNote, setShowPermissionNote] = useState(false);
  const [msgPermission, setMsgPermission] = useState("");
  const arrCredentials = useSelector(
    ({ authReducer }: any) => authReducer?.data?.credentials
  );
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);
  const credentialsPagesDetails: any = globalPages;

  useEffect(() => {
    const pathname = location.pathname.split("/");
    const key = pathname.length > 2 ? pathname.pop() : null;
    setSelectedKey(key || "bookKeeping");
  }, []);

  const handleNavClick = async (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    ev?.preventDefault();
    setSelectedKey(item?.key || "bookKeeping");
    let path: any = item?.url.split("/")[1];
    if (item?.key === "receipt") {
      let isGet = sessionStorage.getItem("isGetRates");
      if (isGet !== "false") {
        sessionStorage.setItem("isGetRates", "false");
        await dispatch(getGatesData("ILS"));
      }
    }

    if (
      location.pathname === "/bookkeeping/money_in" ||
      location.pathname === "/bookkeeping/invoice" ||
      location.pathname === "/bookkeeping/receipt"
    ) {
      if (location.pathname !== "/bookkeeping/money_in") {
        sessionStorage.setItem("dataAccount", "");
        await dispatch({
          type: RESET_DATA_INVOICE,
          payload: null,
        });
      }
      dispatch({
        type: SAVE_DRAFT_CONVERSION_CHANGES,
        payload: { changeLocation: true, location: `/bookkeeping${item?.url}` },
      });
    } else {
      dispatch({
        type: SAVE_DRAFT_CONVERSION_CHANGES,
        payload: {},
      });
      props.history.push(`/bookkeeping${item?.url}`);
    }
  };

  const navStyles: Partial<INavStyles> = {
    root: {
      i: { width: "25px", marginRight: "3px" },
      icons: { fontWhight: "normal" },
    },
    chevronIcon: {
      fontWeight: "normal",
    },
    linkText: {
      margin: "0px 0px !important",
    },
    link: {
      ":after": dir === "ltr" && {
        borderLeft: "2px solid transparent !important",
        borderRight: "2px solid rgb(0, 120, 212) !important",
      },
    },
  };

  const navLinkGroups: INavLinkGroup[] = [{ links: [] }];
  arrCredentials?.map(
    (module: any) =>
      module?.module_code === "bookkeeping" &&
      module?.pages?.map((page: any) =>
        navLinkGroups[0]?.links.push({
          className: credentialsPagesDetails[page?.page_code]?.className,
          key: credentialsPagesDetails[page?.page_code]?.key,
          iconProps: credentialsPagesDetails[page?.page_code]?.iconProps,
          name: t(credentialsPagesDetails[page?.page_code]?.name),
          styles: credentialsPagesDetails[page?.page_code]?.styles,
          url: credentialsPagesDetails[page?.page_code]?.url,
          onClick: handleNavClick,
        })
      )
  );

  return (
    <>
      <Nav
        className="items"
        onLinkClick={handleNavClick}
        styles={navStyles}
        groups={navLinkGroups}
        selectedKey={selectedKey}
      />
      {showPermissionNote && (
        <DialogMessages
          setansDelete={setShowPermissionNote}
          setshowModalDelete={setShowPermissionNote}
          subText={msgPermission}
          dialogType={"dialogMessage"}
        />
      )}
    </>
  );
};
export default withRouter(ContentSideAccounting);
