
import AccountsTable from '../customerAccounts/AccountsTable';
import AccountCurrencyTable from './AccountCurrencyTable';
import AccountCurrencyBalanceTable from './AccountCurrencyBalanceTable';
import { useTranslation } from 'react-i18next';



export default function TabsAccounts(props: any) {
    const { tab, setTab, idAccount, accountsData, setAccountsData, actionIn,
        currencyList, setCrrencyList, idEntity, showAccountMovements } = props
    const [t] = useTranslation()
    return (
        <>
            <div style={{ display: "flex", marginLeft: "6px", fontSize: "15px", fontWeight: "bold" }}>

                <div style={{
                    cursor: "pointer",
                    marginLeft: "6px"
                    , borderBottom: `${tab === 1 ? "2px solid rgb(0, 120, 212)" : "none"}`
                }}
                    onClick={() => { setTab(1); showAccountMovements(idAccount) }}>
                    {t("account_movements")}
                </div>
                <div style={{
                    cursor: "pointer",
                    marginLeft: "6px",
                    borderBottom: `${tab === 2 ? "2px solid rgb(0, 120, 212)" : "none"}`
                }}
                    onClick={() => { setTab(2) }}>
                    {t("foreignCurrencyMovements")}
                </div>
                <div style={{
                    cursor: "pointer",
                    marginLeft: "6px",
                    borderBottom: `${tab === 3 ? "2px solid rgb(0, 120, 212)" : "none"}`
                }}
                    onClick={() => { setTab(3) }}>
                    {t("foreignCurrencyBalance")}
                </div>
            </div>
            <div style={{ width: "1350px" }}>
                {tab === 1 && <AccountsTable
                    idAccount={idAccount} accountsData={accountsData} setAccountsData={setAccountsData}
                    idEntity={idEntity} actionIn={actionIn} setCrrencyList={setCrrencyList} />
                }
                {tab === 2 && <AccountCurrencyTable currencyList={currencyList}
                    idAccount={idAccount} idEntity={idEntity} actionIn={actionIn} />
                }
                {tab === 3 && <AccountCurrencyBalanceTable
                    idAccount={idAccount} idEntity={idEntity} actionIn={actionIn} tab={tab} />
                }
            </div>
        </>
    )
}