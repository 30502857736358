import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  INavStyles,
  INavLinkGroup,
  INavLink,
  Nav,
} from "@fluentui/react/lib/Nav";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { useLocation, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { registerIcons } from "@fluentui/react";

import "../../scssPages/sidebar.scss";
import {
  SAVE_DRAFT_CONVERSION_CHANGES,
  RESET_DATA_CONVERSION,
} from "../../store/actions/types";
import { DialogMessages } from "../../shared/components/dialog/DialogMessages";
import { getGatesData } from "../../store/actions/convertionActions";
import { globalPages } from "../../shared/components/CustomHooks/GlobalSidePages";

initializeIcons();

registerIcons({
  icons: {
    MoneyCheckEditIcon: <i className="fal fa-money-check-edit"></i>,
    MoneyCheckIcon: <i className="fal fa-money-check"></i>,
    ThListIcon: <i className="fal fa-th-list"></i>,
  },
});

const ContentSideDiscount = (props: any) => {
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);

  const navStyles: Partial<INavStyles> = {
    root: {
      i: { width: "25px", marginRight: "3px" },
    },
    link: {
      ":after": dir === "ltr" && {
        borderLeft: "2px solid transparent !important",
        borderRight: "2px solid rgb(0, 120, 212) !important",
      },
    },
  };
  const [selectedKey, setSelectedKey] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPermissionNote, setShowPermissionNote] = useState(false);
  const [msgPermission, setMsgPermission] = useState("");
  // const credentials = useSelector(({ authReducer }: any) => authReducer.data.user.credentials)
  const arrCredentials = useSelector(
    ({ authReducer }: any) => authReducer?.data?.credentials
  );
  const credentialsPagesDetails: any = globalPages;

  useEffect(() => {
    const pathname = location.pathname.split("/");
    const key = pathname.length > 2 ? pathname.pop() : null;
    setSelectedKey(key || "check-discounts");
  }, []);

  const handleNavClick = async (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    ev?.preventDefault();
    setSelectedKey(item?.key || "check-discounts");
    let path: any = item?.url.split("/")[1];
    // if (credentials[path] && credentials[path] === "Deny" || !credentials[path]) {
    //   setMsgPermission(t('permissionAaccessThisPage'))
    //   setShowPermissionNote(true)
    // }
    // else {
    if (location.pathname === "/cheques_cashing/cheque_cashing") {
      sessionStorage.setItem("dataConversion", JSON.stringify(""));
      await dispatch({
        type: RESET_DATA_CONVERSION,
        payload: null,
      });
      await dispatch({
        type: SAVE_DRAFT_CONVERSION_CHANGES,
        payload: {
          changeLocation: true,
          location: `/cheques_cashing${item?.url}`,
        },
      });
    } else {
      if (location.pathname === "/cheques_cashing/currency-entry") {
        dispatch({
          type: SAVE_DRAFT_CONVERSION_CHANGES,
          payload: {
            changeLocation: true,
            location: `/cheques_cashing${item?.url}`,
          },
        });
      } else {
        if (item?.url === "/cheque_cashing") {
          let isGet = sessionStorage.getItem("isGetRates");
          if (isGet !== "false") {
            sessionStorage.setItem("isGetRates", "false");
            dispatch(getGatesData("ILS"));
          }
        }
        dispatch({
          type: SAVE_DRAFT_CONVERSION_CHANGES,
          payload: {},
        });
        props.history.push(`/cheques_cashing${item?.url}`);
      }
    }
    // }
  };

  // const navLinkGroups: INavLinkGroup[] = [
  //   {
  //     links: [
  //       {
  //         key: "check-discounts",
  //         className: "side-sub",
  //         iconProps: { iconName: "MoneyCheckEditIcon" }
  //         , styles: {
  //           icon: { color: 'red' }
  //         },
  //         name: t("checkDiscounts"),
  //         url: "/cheque_cashing",
  //         onClick: handleNavClick

  //       },
  //       {
  //         key: "check-management",
  //         className: "side-sub",
  //         iconProps: { iconName: "MoneyCheckIcon" }
  //         , styles: {
  //           icon: { color: '#1A1F71' }
  //         },
  //         name: t("checkManagement"),
  //         url: "/cheques_management",
  //         onClick: handleNavClick

  //       },
  //       {
  //         key: "discount-transaction",
  //         className: "side-sub",
  //         iconProps: { iconName: "ThListIcon" }
  //         , styles: {
  //           icon: { color: '#1A1F71' }
  //         },
  //         name: t("discountTransaction"),
  //         url: "/cheque_cashing_transactions_list",
  //         onClick: handleNavClick
  //       },

  //     ]
  //   }
  // ];

  const navLinkGroups: INavLinkGroup[] = [{ links: [] }];
  arrCredentials?.map(
    (module: any) =>
      module?.module_code === "cheques_cashing" &&
      module?.pages?.map((page: any) =>
        navLinkGroups[0]?.links.push({
          className: credentialsPagesDetails[page?.page_code]?.className,
          key: credentialsPagesDetails[page?.page_code]?.key,
          iconProps: credentialsPagesDetails[page?.page_code]?.iconProps,
          name: t(credentialsPagesDetails[page?.page_code]?.name),
          styles: credentialsPagesDetails[page?.page_code]?.styles,
          url: credentialsPagesDetails[page?.page_code]?.url,
          onClick: handleNavClick,
        })
      )
  );
  return (
    <>
      <Nav
        className="items"
        onLinkClick={handleNavClick}
        styles={navStyles}
        groups={navLinkGroups}
      />
      {showPermissionNote && (
        <DialogMessages
          setansDelete={setShowPermissionNote}
          setshowModalDelete={setShowPermissionNote}
          subText={msgPermission}
          dialogType={"dialogMessage"}
        />
      )}
    </>
  );
};
export default withRouter(ContentSideDiscount);
