
import { Checkbox } from 'office-ui-fabric-react'
import { useEffect } from 'react'
import moment from 'moment';


export default function GetServicePDF({ customerDetails,
    statement, listBenefits, listControllingHolder }) {

    function checkDefaultAddress(addresses) {
        let address = addresses?.find((item) => item.is_default === true)
        return address ? address : addresses && addresses[0]
    }
    useEffect(() => {
        if (listBenefits && customerDetails) {
        }
            if(listBenefits){
        console.log(listBenefits,"2345678");
    {listBenefits?.map((item, i) => {(console.log(item.addresses,"listBenefits"))
    {item.addresses.map((e) =>(console.log(e,"eeee")))}
    }
    )
}
}
    }, [listBenefits, customerDetails])
    console.log(customerDetails,"customerDetails");
     console.log(listBenefits,"listBenefits");

    return (
        <div id="getService">
            <div style={{ padding: "10px",lineHeight: 2,}}>
                <h1 style={{ fontSize: "25px", color: "blue", textAlign: "center", marginTop: "30px", lineHeight: 2.5, }}><u>הצהרת מקבל שירות</u></h1>
                {listBenefits?.map((item, i)  => (
                <div style={{ display: "flex", fontSize: "16px",lineHeight: 1.9, }}><span style={{ marginLeft: "8px" }} >אני</span>
                    <span style={{ marginLeft: "8px" }}><u>{item.entity_name}</u></span>
                    <span style={{ marginLeft: "8px" }}>בעל מספר הזהות</span>
                    <span style={{ marginLeft: "8px" }}><u>{item?.identification?.ID_number}</u></span>
                    <span style={{ marginLeft: "8px" }}>מצהיר בזה כי: </span></div>
                ))}
               <div style={{ display: "flex", fontSize: "16px", alignItems: "center" }}>
                    <span style={{ marginLeft: "8px", width: "20px" }}>(א)</span>
                    <Checkbox checked={!statement?.isBeneficiary ? true : false} style={{ marginLeft: "8px" }} />
                    <span style={{ marginLeft: "8px" }}>אני מבקש לקבל את שירות מתן האשראי עבור עצמי בלבד.</span>
                </div>
                <div style={{ display: "flex", fontSize: "16px", alignItems: "center" }}>
                    <span style={{ marginLeft: "8px", width: "20px" }}>(ב)</span>
                    <Checkbox checked={statement?.isBeneficiary ? true : false} style={{ marginLeft: "8px" }} />
                    <span style={{ marginLeft: "8px" }}>אני מבקש לקבל את שירות מתן האשראי עבור נהנה.</span>
                </div>
                <div style={{ fontSize: "16px" }}>
                    <div style={{ display: "flex", alignItems: "center"}}>
                        <span style={{ marginLeft: "8px", width: "20px" }}>(ג)</span>
                        <Checkbox checked={statement?.is_unknown_beneficiary ? true : false} style={{ marginLeft: "8px" }} />
                        <span style={{ marginLeft: "8px" }}>קיים נהנה, ואולם פרטי הזיהוי שלו טרם ידועים, הסיבה לכך:</span></div>
                    <div className='div-text' >{statement?.is_unknown_beneficiary_details}</div>
                </div>
                <div style={{ marginRight: "30px" }}></div>
                <div style={{ display: "flex", fontSize: "16px", alignItems: "center" }}>
                    <span style={{ marginLeft: "8px"}}>אני מתחייב למסור את פרטי הנהנה מיד עם היוודה זהותו.</span>
                </div>
                <div style={{ display: "flex", fontSize: "16px", alignItems: "center",lineHeight: 2.5, }}>
                    {/* <span style={{ marginLeft: "8px" }}>* נידרש אם אין לנותן השירות מספר זהות לאחר שנקט אמצעים סבירים להשגתו.</span> */}
                </div>
                <div style={{ display: "flex", fontSize: "16px", alignItems: "center" }}>
                    <span style={{ marginLeft: "8px", width: "20px" }}>(ד)</span>
                    <Checkbox checked={listBenefits.length > 0 ? true : false} style={{ marginLeft: "8px" }} />
                    <span style={{ marginLeft: "8px" }}>הנהנים משירות מתן האשראי שניתן בעבורי הם:</span>
                </div>
                <div style={{ fontSize: "14px", alignItems: "center" ,lineHeight: 2.3}}>
                    {customerDetails && <table style={{borderCollapse: "collapse" ,  textAlign: "center"}}>
                        <tr style={{ gap: "0px", display: "flex",}}>
                            <th style={{ width: "120px" ,border: "1px solid #dddddd"}}>שם</th>
                            <th style={{ width: "120px",border: "1px solid #dddddd" }}>מס. זהות</th>
                            <th style={{ width: "170px" ,border: "1px solid #dddddd"}}>תאריך לידה/התאגדות*</th>
                            <th style={{ width: "60px",border: "1px solid #dddddd" }} >סוג לקוח*</th>
                            <th style={{ width: "270px",border: "1px solid #dddddd" }}>מען*</th>
                        </tr>
                        {customerDetails &&
                            <tr style={{ gap: "0px", display: "flex", }} >
                            <td maxlength="10" style={{ width: "120px", lineHeight: 1.2 , border: "1px solid #dddddd"}}>{customerDetails?.entityName}</td>
                            <td style={{ width: "120px",border: "1px solid #dddddd" }}>{customerDetails?.idNumber}</td>
                            <td style={{ width: "170px" ,border: "1px solid #dddddd"}}>{moment(customerDetails.dateBirth?.date_birth).format("DD/MM/YY")}</td>
                            <td style={{ width: "60px",border: "1px solid #dddddd" }} >{customerDetails.customerTypeName}</td>
                            <td style={{ width: "270px",border: "1px solid #dddddd" }}>
                                {customerDetails.addressName} {customerDetails.addressNumber} {customerDetails.addressCityName} {customerDetails.addressCityName}
                            </td>
                        </tr>
                            // <tr style={{ gap: "15px", display: "flex" }} key={i}>
                            //     <td style={{ width: "120px" }}>{item.entity_name}</td>
                            //     <td style={{ width: "120px" }}>{item?.identification?.ID_number}</td>
                            //     <td style={{ width: "170px" }}>{moment(item?.date_birth).format("DD/MM/YY")}</td>
                            //     <td style={{ width: "60px" }} >{item?.gender?.gender_name}</td>
                            //     <td style={{ width: "270px" }}>
                            //         {item?.addresses && checkDefaultAddress(item?.addresses).address_street} {item?.addresses && checkDefaultAddress(item?.addresses).address_number} {item?.addresses && checkDefaultAddress(item?.addresses).address_city} {item?.addresses && checkDefaultAddress(item?.addresses).address_country_name}
                            //     </td>
                            // </tr>
                        }
                    </table>}
                    {/* <span style={{ marginLeft: "8px" }}>* נדרש אם אין לנותן השירות מספר זהות לאחר שנקט אמצעים סבירים להשגתו.</span> */}
                </div>
                <div style={{ fontSize: "16px" }}>לגבי מקבל שירות שהוא תאגיד:</div>
                <div style={{ display: "flex", fontSize: "16px", alignItems: "center" }}>
                    <span style={{ marginLeft: "8px", width: "20px" }}>(ה)</span>
                    <Checkbox checked={!statement?.isControllingHolder ? true : false} style={{ marginLeft: "8px" }} />
                    <span style={{ marginLeft: "8px" }}>אין בעל שליטה בתאגיד.</span>
                </div>
                <div style={{ display: "flex", fontSize: "16px", alignItems: "center" }}>
                    <span style={{ marginLeft: "8px", width: "20px" }}>(ו)</span>
                    <Checkbox checked={statement?.isControllingHolder ? true : false} style={{ marginLeft: "8px" }} />
                    <span style={{ marginLeft: "8px" }}>בעלי המניות בתאגיד הם:</span>
                </div>
                <div style={{ fontSize: "14px", alignItems: "center",lineHeight: 2.3,  }}>
                    <table style={{borderCollapse: "collapse" ,  textAlign: "center"}}>
                        <tr style={{ gap: "0px", display: "flex" ,textAlign: "center"}}>
                            <th style={{ width: "120px" ,border: "1px solid #dddddd"}}>שם</th>
                            <th style={{ width: "120px" ,border: "1px solid #dddddd" }} >מס. זהות</th>
                            <th style={{ width: "170px" ,border: "1px solid #dddddd"}} >תאריך לידה/התאגדות*</th>
                            <th style={{ width: "60px",border: "1px solid #dddddd" }} >מין*</th>
                            <th style={{ width: "270px",border: "1px solid #dddddd"}} >מען*</th>
                        </tr>
                        {listControllingHolder?.map((item, i) => (
                            <tr style={{ gap: "0px", display: "flex", textAlign: "center" }} key={i+"*"}>
                                <tr style={{ gap: "0PX", display: "flex" , textAlign: "center"}}>
                                    <td style={{ width: "120px",border: "1px solid #dddddd" }}>{item.entity_name}</td>
                                    <td style={{ width: "120px",border: "1px solid #dddddd" }}>{item?.identification?.ID_number}</td>
                                    <td style={{ width: "170px" ,border: "1px solid #dddddd" }}>{moment(item?.date_birth).format("DD/MM/YY")}</td>
                                    <td style={{ width: "60px" ,border: "1px solid #dddddd" }} >{item?.gender?.gender_name}</td>
                                    <td style={{ width: "270px" ,border: "1px solid #dddddd"}}>
                                        {item?.addresses && checkDefaultAddress(item?.addresses).address_street} {item?.addresses && checkDefaultAddress(item?.addresses).address_number} {item?.addresses && checkDefaultAddress(item?.addresses).address_city} {item?.addresses && checkDefaultAddress(item?.addresses).address_country_name}
                                    </td>
                                </tr>
                            </tr>))}


                    </table>
                    {/* <span style={{ marginLeft: "8px",lineHeight: 2.3, }}>* נדרש אם אין לנותן השירות מספר זהות לאחר שנקט אמצעים סבירים להשגתו.</span> */}
                </div>

                <div style={{ display: "flex", fontSize: "16px", alignItems: "center" }}>
                    <p>אני מתחייב להודיע לנותן השירות בכתב בהקדם האפשרי על כל שינוי בפרטים שמסרתי לעיל; ידוע לי כימסירת מידע כוזב, לרבות אי-מסיר פירוט של פרט החייב בדיווח, במטרה שלא יהיה דיווח או כדי לגרום לדיווח בלתי נכון לפי סעיף 7 לחוק, מהווה עבירה פלילית.</p>
                </div>
                <div style={{ display: "flex", fontSize: "16px", alignItems: "center" }}>
                    <span style={{ marginLeft: "8px" }}>תאריך</span>
                    <div>{moment().format("DD/MM/YY")}</div>
                </div>
                <div style={{ fontSize: "16px", alignItems: "center" }}>
                    <div>חתימה</div>
                    <div id="divSignature">

                    </div>
                </div>
            </div>
        </div>

    )
}
