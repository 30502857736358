import axios from "axios";
import JSZip from "jszip";
import { asBlob } from "html-docx-js-typescript";
import htmlDocx from 'html-docx-js';
// import { asBlob } from "html-docx-js";
import juice from 'juice';
import { basicUrl } from "../../shared/config";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { reportUnRegularDownloadFile, reportsDownloadFile } from "./FilesAction";
import { SET_DATA_OF_FIELDS_UN_REGULAR_REPORTS, SET_DATA_RESULTS_UN_REGULAR_REPORTS } from "./types";
import { log } from "console";

export const calculateProfit = (date: any, userCredentials: IUserCredentials | null,
    historyExchange: any, setDataTable: any, valuationMethod: any, setshowSpiner?: any,
    setShowDialogSave?: any, setShowDialogFailed?: any, setServerError?: any) => async (dispatch: Function) => {
        const profit = {
            "id_initiator": userCredentials && userCredentials.idInitiator,
            "id_client": userCredentials && userCredentials.idClient,
            "id_branch": userCredentials && userCredentials.idBranch,
            "report_type_id": "pnl_exchange",
            "valuation_method_id": valuationMethod,
            "date_start": date?.startDate,
            "date_end": date?.endDate,
            "exchange_rates": historyExchange
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const body = JSON.stringify(profit);
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        await axios.post(basicUrl + '/reports', body, config).then(res => {
            try {
                if (res.status === 200) {
                    if (res.data?.err_code && res.data["err_code"] === 0 || res?.data?.err_code === undefined) {
                        setDataTable(res?.data?.data[0] ? res?.data?.data[0] : res?.data?.data)
                        setshowSpiner && setshowSpiner(false)
                        setShowDialogSave && setShowDialogSave(true)
                    }
                    else {
                        setshowSpiner && setshowSpiner(false)
                        setServerError && setServerError(res.data?.ui_message?.ui_message)
                        setShowDialogFailed && setShowDialogFailed(true)
                    }
                }
            }
            catch (err) {
                setshowSpiner && setshowSpiner(false)
                setServerError && setServerError(res.data?.ui_message?.ui_message)
                setShowDialogFailed && setShowDialogFailed(true)
                console.log('error in create phone component', err)
            }
        }).catch(err => {
            console.log("error", err.message);
        })
    }
const downLooadZip = async (data: any, userCredentials: IUserCredentials | null, dispatch: any, t?: any) => {
    console.log(data, "------data");
    debugger
    let arr: any = []
    let zip: any = new JSZip();

    Object.values(data?.ctr_files).map((key: any) => {
        arr.push(key)
    })
    arr.map((item: any) => {
        let arrfiles: any = [], file: any
        item?.data?.map((item: any) => {
            let names: any = []
            Object.values(item).map((key: any) => {
                names.push(key)
            })
            names.map((item: any) => {
                file = file ? file + item : item
            })
            arrfiles.push(file)
            file = ""
            arrfiles.push("\r\n")
        })
        const blob = new Blob(arrfiles, { type: "text/plain;" });
        zip.file(item?.file_name, blob, { binary: true });

    })
    let arrBlob: any[] = []
    if (data?.attachments?.length > 0) {
        data.attachments?.map(async (item: any) => {
            await dispatch(reportsDownloadFile(item.id_entity,
                userCredentials, item.id_media, item?.media_file_name
                , arrBlob, data, zip))
        })
    }
    else {
        const content = await zip.generateAsync({ type: "blob" });
        const fileName = `${data?.zip_file_name}`;
        return saveAs(content, fileName)
    }
}

export const ProduceImpaReport = (month: any, year: any, userCredentials: IUserCredentials | null,
    t?: any) => async (dispatch: Function) => {
        const report = {
            "id_initiator": userCredentials && userCredentials.idInitiator,
            "id_client": userCredentials && userCredentials.idClient,
            "id_branch": userCredentials && userCredentials.idBranch,
            "report_type_id": "impa_ctr",
            "month": month,
            "year": year,
        }
        const body = JSON.stringify(report);
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };

        await axios.post(basicUrl + '/reports', body, config).then(async res => {
            try {
                if (res.status === 200) {
                    if (res.data?.err_code && res.data["err_code"] === 0 ||
                        res?.data?.err_code === undefined) {
                        downLooadZip(res?.data, userCredentials, dispatch, t)
                    }
                }
            }
            catch (err) {
                console.log('error in create phone component', err)
            }
        }).catch(err => {
            console.log("error", err.message);
        })
    }

export const ProduceItaReport = (squareMonth: any, squareYear: any, checked: any, userCredentials: IUserCredentials | null
) => async (dispatch: Function) => {
    ;

    const report = {
        "id_initiator": userCredentials && userCredentials.idInitiator,
        "id_client": userCredentials && userCredentials.idClient,
        "id_branch": userCredentials && userCredentials.idBranch,
        "report_type_id": "ita_ctr",
        "is_new_calculation": 1,
        "is_corrects_previous_report": checked === false ? 0 : 1,
        "quarter": squareMonth,
        "year": squareYear,
    }

    const body = JSON.stringify(report);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    await axios.post(basicUrl + '/reports', body, config).then(async res => {
        console.log("basicUrlנמצא בה ", basicUrl);

        try {
            console.log("123123");

            if (res.status === 200) {
                if (res.data?.err_code && res.data["err_code"] === 0 ||
                    res?.data?.err_code === undefined) {
                    downLooadZip(res?.data, userCredentials, dispatch)
                }
            }
            else {
                console.log("______________picher");

            }
        }
        catch (err) {
            console.log('error in create phone component---------------', err)
        }
    }).catch(err => {

        console.log("error----------------", err.message);
    })
}



export const unRegularReports = (dataRepoets: any, userCredentials: IUserCredentials | null, setDataReport: any
) => async (dispatch: Function) => {
    debugger
    const report = {
        "id_initiator": userCredentials && userCredentials.idInitiator,
        "id_client": userCredentials && userCredentials.idClient,
        "id_branch": userCredentials && userCredentials.idBranch,
        "report_type_id": "impa_utr",
        "impa_utr_characteristics_id": dataRepoets?.impa_utr_characteristics,
        "impa_utr_essence": dataRepoets?.impa_utr_essence,
        "report_year": dataRepoets?.year,
        "report_month": dataRepoets?.month,
        "entities": dataRepoets?.arrEntitiesToReports,
        "transactions": dataRepoets?.arrTransactionsToReports
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(report);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    await axios.post(basicUrl + '/reports', body, config).then(async res => {
        try {
            if (res.status === 200) {
                if (res.data["err_code"] === 0) {
                    // let zip: any = new JSZip();
                    // await dispatch({
                    //     type: SET_DATA_OF_FIELDS_UN_REGULAR_REPORTS,
                    //     payload: res.data?.data?.impa_utr_report
                    // })
                    setDataReport(res.data?.data?.impa_utr_report)
                    // let dataImpaReport = res?.data?.data?.impa_utr_report
                    // dataImpaReport?.impa_utr_attachments?.forEach(async (item: any, i: any) => {
                    //     if (i < 2) {
                    //         let dataFile = await dispatch(reportUnRegularDownloadFile(item?.id_entity, userCredentials, item?.id_media))
                    //         zip.folder('attachments').file("mediaName.pdf", dataFile, { binary: true });
                    //     }
                    // })
                    // let htmlContent: any =await document.getElementById("exportWord")?.outerHTML
                    // const blob: any = await asBlob(htmlContent)
                    // zip.file(`${dataImpaReport?.impa_utr_number}.docx`, blob, { binary: true });
                    // const content = await zip.generateAsync({ type: "blob" });
                    // const fileName = `${dataImpaReport?.impa_utr_zip_file_name}`;
                    // return await saveAs(content, fileName)
                }
                else {
                    alert(`שגיאה, ${res.data["err_description"]}`)
                }
            }
        }
        catch (err) {
            console.log('error in attach files', err)
        }
    }).catch(err => {
        console.log("error", err.message);
    })
}


export const getUnRportsData = (dataRepoets: any, entity: any, userCredentials: IUserCredentials | null
    , setShowSpiner: any, setServerMessageError: any, setMessageError: any, setTitleMessageError: any) => async (dispatch: Function) => {
        const report: any = {
            "id_initiator": userCredentials && userCredentials.idInitiator,
            "id_client": userCredentials && userCredentials.idClient,
            "id_branch": userCredentials && userCredentials.idBranch,
            "date_start": dataRepoets?.rangeTimeAll ? "2000-01-01" : dataRepoets?.dateStart,
            "date_end": dataRepoets?.rangeTimeAll ? "2099-01-01" : dataRepoets?.dateEnd,
            "report_type_id": "impa_utr_transactions",
            "impa_utr_type_id": dataRepoets?.reportType,
        }
        if (dataRepoets?.reportType === "structuring") {
            report["amount_threshold"] = dataRepoets?.amount_threshold
            report["duration_days"] = dataRepoets?.duration_days
        }
        if (dataRepoets?.reportType === "near_threshold") {
            report["amount_from"] = dataRepoets?.amount_from
            report["amount_to"] = dataRepoets?.amount_to
        }
        if (dataRepoets?.reportType === "trans_by_ID") {
            report["id_entity"] = entity?.idEntity
            report["ID_number"] = entity?.idNumber
        }
        if (dataRepoets?.reportType === "reports_by_parameters") {
            report["financial_assets_type"] = dataRepoets?.financial_assets_type
            report["financial_asset_code"] = dataRepoets?.financial_asset_code
            report["over_volume"] = dataRepoets?.over_volume
            report["transaction_type_id"] = dataRepoets?.transaction_type_id
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const body = JSON.stringify(report);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };

        await axios.post(basicUrl + '/reports', body, config).then(async res => {
            try {
                if (res.status === 200) {
                    // if ( res.data["err_code"] === 0) { 
                    setShowSpiner(false)
                    dispatch({
                        type: SET_DATA_RESULTS_UN_REGULAR_REPORTS,
                        payload: res.data?.data
                    })
                    // }
                }
                else {
                    setShowSpiner(false)
                    setMessageError && setMessageError("errorPerformingAction")
                    setTitleMessageError && setTitleMessageError("error")
                    setServerMessageError && setServerMessageError(true)
                }
            }
            catch (err) {
                console.log('error in create phone component', err)
                setTitleMessageError && setTitleMessageError(res.data?.ui_message?.ui_message_header)
                setMessageError && setMessageError(res.data?.ui_message?.ui_message)
                setServerMessageError && setServerMessageError(true)
            }
        }).catch(err => {
            console.log("error", err.message);
        })
    }

//not conection

// export const getHistoricalExchange = (date: any, userCredentials:IUserCredentials | null, setDataTable:any, valuationMethod:any, setshowSpiner:any) => async (dispatch: Function) => {
//     await axios({
//             method: "get",
//             // pxXIUcFB4nHcJSp0QYmhLmmwkxcYcpypfdwRKJQG
//             url: `https://api.currencyapi.com/v3/historical?apikey=aZUBDn80F1y6YTOkaq5i2qLQP0Jj4Ki8V6zquG7v&date=${date?.endDate}&base_currency=ILS`,
//         })
//         .then(async function (res) {\
//             calculateProfit(date,userCredentials,res?.data?.data,setDataTable,valuationMethod,setshowSpiner)
//         }).catch((err) => {
//             console.error(err);
//         });
// }

