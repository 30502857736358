import { cloneDeep } from 'lodash';
import { CREATE_CUSTOMER, CREATE_FAIL, READ_CUSTOMER,
     READ_FAIL, SET_DATA_CUSTOMER, RESET_CUSTOMER_OBJECT,SET_CUSTOMER_INSIDER } from '../actions/types';

const initialState = {
    dataCustomer: null,
    isCreate: null
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case READ_CUSTOMER: {
            sessionStorage.setItem('dataCustomer', JSON.stringify(action.payload));
            return {
                ...state,
                dataCustomer:action.payload,
            }
        }
        case SET_DATA_CUSTOMER:
            return {
                ...state,
                dataCustomer: action.payload,
                isCreate: true,
            }
        case CREATE_CUSTOMER:
            // sessionStorage.setItem('dataCustomer', JSON.stringify(action.valued));
            return {
                ...state,
                dataCustomer: action.value,
                isCreate: true,
            }
        case CREATE_FAIL:
            return {
                ...state,
                isCreate: false
            }
        case READ_FAIL:
            return {
                ...state,
                isCreate: false
            }
        case RESET_CUSTOMER_OBJECT:
            return {
                ...state,
                dataCustomer: null,
                isCreate: null
            }

        case SET_CUSTOMER_INSIDER: {
            let arr: any = [];
            arr = cloneDeep(state)
            let index = arr.dataCustomer?.insiders?.findIndex((entity: any) => entity?.id_entity === action.payload?.id_entity)
            if (index !== -1){ arr.dataCustomer.insiders[index].documentation= action.payload.documentation}
            sessionStorage.setItem('dataCustomer', JSON.stringify(arr.dataCustomer));
            return {
                ...state,
                dataCustomer: cloneDeep(arr.dataCustomer),
            }
        }
        default: return state;

    }

}