import moment from "moment";

export const findDefault = (arr: object[]) => {
    let ans = 0
    arr?.forEach((item: any, index: number) => {
        if (item?.is_default === true) {
            ans = index;
            return
        }
    })
    return ans
}


export const convertArrToDropdropdown = (arr: Object[], source?: String) => {
    let newArr: any[] = [];
    arr?.forEach((item: any) => {
        let text: any = item.enum_value + " (+" + item.enum_id + ")";

        item?.is_default === true ?
            newArr.push({ key: item.enum_id, text: source ? text : item.enum_value, is_default: true }) :
            newArr.push({ key: item.enum_id, text: source ? text : item.enum_value });
    })
    return newArr
}

export const isValidILID = (idNumber: any) => {
    let id = String(idNumber).trim();
    if (id.length > 9 || id.length < 5 || isNaN(idNumber)) return false;
    // Pad string with zeros up to 9 digits
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
    return true
}

export const checkAge = (dateBirth: any) => {
    let today = new Date();
    let x = moment(dateBirth).format('YYYY-MM-DD')
    let birthDate: any = moment(x, 'YYYY-MM-DD', true);
    birthDate = birthDate.toDate();
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const checkChequeDays = (date: any) => {
    let dayToday: any = moment().toDate();
    let dateCheck: any = moment(date, 'YYYY-MM-DD', true);
    dateCheck = dateCheck.toDate()
    let chequeDays: any = date
    chequeDays = Math.ceil((dateCheck - dayToday) / (1000 * 60 * 60 * 24)) > 0 ? Math.ceil((dateCheck - dayToday) / (1000 * 60 * 60 * 24)) : 0
    return chequeDays
}


export const customerInfoInObject = (customerInfo: any) => {
    let indexAddress, indexEmail, indexPhone;
    indexAddress = findDefault(customerInfo?.addresses)
    indexEmail = findDefault(customerInfo?.emails)
    indexPhone = findDefault(customerInfo?.telephones)
    const customerData = {
        idNumber: customerInfo.identification && customerInfo.identification?.ID_number,
        idTypeOther: customerInfo.identification && customerInfo.identification?.ID_type_other,
        customerTypeId: customerInfo.identification && customerInfo.properties?.customer_type_id,
        customerTypeName: customerInfo.identification && customerInfo.properties?.customer_type_name,
        idTypeCountryCode: customerInfo.identification && customerInfo.identification?.ID_country_code,
        workerName: customerInfo?.identification?.id_identifier,
        idTypeId: customerInfo.identification?.ID_type_id,
        idTypeName: customerInfo.identification?.ID_type_name,
        classId: customerInfo.class?.class_id,
        dateBirth: customerInfo.properties?.date_birth,
        firstName: customerInfo.properties?.first_name,
        entityName: customerInfo.entity_name,
        lastName: customerInfo.properties?.last_name,
        middleName: customerInfo.properties?.middle_name,
        entitySubTypeId: customerInfo.types && customerInfo.types[0]?.entity_sub_type_id,
        entityNumber: customerInfo.properties?.entity_number,
        isLocked: customerInfo.properties?.is_locked === "true" || customerInfo.properties?.is_locked === true ? true : false,
        isPopupMessage: customerInfo.properties?.is_popup_message === "true" || customerInfo.properties?.is_popup_message === true ? true : false,
        note: customerInfo.properties?.note,
        industryId: customerInfo.industry_classification?.industry_classification_id,
        CreditGroup: customerInfo.industry_classification?.industry_sub_classification_id,
        gender: customerInfo.gender?.gender_id,
        genderName: customerInfo.gender?.gender_other,
        entityStatusId: customerInfo.status?.status_id,
        address: customerInfo?.addresses && customerInfo?.addresses[indexAddress].is_deleted === false ? customerInfo?.addresses[indexAddress]?.address_street_code : "",
        addressName: customerInfo?.addresses && customerInfo?.addresses[indexAddress].is_deleted === false ? customerInfo?.addresses[indexAddress]?.address_street : "",
        addressState: customerInfo?.addresses && customerInfo?.addresses[indexAddress].is_deleted === false ? customerInfo?.addresses[indexAddress]?.address_state : "",
        addressNumber: customerInfo?.addresses && customerInfo?.addresses[indexAddress].is_deleted === false ? customerInfo?.addresses[indexAddress]?.address_number : "",
        addressCity: customerInfo?.addresses && customerInfo?.addresses[indexAddress].is_deleted === false ? customerInfo?.addresses[indexAddress]?.address_city_code : "",
        addressCityName: customerInfo?.addresses && customerInfo?.addresses[indexAddress].is_deleted === false ? customerInfo?.addresses[indexAddress]?.address_city : "",
        addressZipCode: customerInfo?.addresses && customerInfo?.addresses[indexAddress].is_deleted === false ? customerInfo?.addresses[indexAddress]?.address_zip_code : "",
        iDCountryCode: customerInfo?.addresses && customerInfo?.addresses[indexAddress].is_deleted === false ? customerInfo?.addresses[indexAddress]?.address_country_code : "",
        addressCountryCode: customerInfo?.addresses && customerInfo?.addresses[indexAddress].is_deleted === false ? customerInfo?.addresses[indexAddress]?.address_country_code : "",
        telephone: customerInfo.telephones && customerInfo.telephones[indexPhone]?.is_deleted === false ? customerInfo.telephones[indexPhone].telephone_number : "",
        telephoneCountryCode: customerInfo.telephones && customerInfo.telephones[indexPhone]?.telephone_country_code,
        email: customerInfo.emails && customerInfo.emails[indexEmail]?.is_deleted === false ? customerInfo.emails[indexEmail]?.email_address : "",
        telephoneTypeId: customerInfo.telephones && customerInfo.telephones[indexPhone]?.telephone_type_id,
        telephoneId: customerInfo.telephones && customerInfo.telephones[indexPhone]?.is_deleted === false ? customerInfo.telephones[indexPhone]?.telephone_id : "",
        telephoneDefault: 1,
        emailTypeId: customerInfo.emails && customerInfo.emails[indexEmail]?.email_type_id,
        emailId: customerInfo.emails && customerInfo.emails[indexEmail]?.is_deleted === false ? customerInfo.emails[indexEmail]?.email_id : "",
        emailDefault: 1,
        addressTypeId: customerInfo?.addresses && customerInfo?.addresses[indexAddress]?.address_type_id,
        addressId: customerInfo?.addresses && customerInfo?.addresses[indexAddress].is_deleted === false ? customerInfo?.addresses[indexAddress]?.address_id : "",
        addressDefault: 1,
        idEntity: customerInfo?.id_entity,
        insiders: customerInfo?.insiders,
        cards: customerInfo?.cards,
        bankAccounts: customerInfo?.bank_accounts,
        accounts: customerInfo?.accounts,
        priceProgram: customerInfo?.price_program,
        documentation: customerInfo?.documentation,
        isFullyIdentified: customerInfo?.is_fully_identified
    }
    return customerData;
}
export const calcChequeDetails = (discount: any) => {
    let details: any = discount?.financial_asset_details ? discount?.financial_asset_details : {}
    if (details?.cheque_days > 0) {
        let powR = (Math.pow(1 + (details.cashing_intrest ? Number(details.cashing_intrest) / 100 : 0), 12))
        let R = (powR - 1)
        let n = ((Number(details?.cheque_days ? details?.cheque_days : 0) + Number(details?.cheque_value_days ? details?.cheque_value_days : 0)) / 365);
        let powRS = Math.pow(1 + R, n)
        let RS = Number(discount?.amount_ils) * ((powRS) - 1)
        details.cashing_amount = Number(RS.toFixed(2))
    }
    else {
        details.cashing_amount = Number(discount?.amount_ils) * (details.cashing_intrest ? Number(details.cashing_intrest) / 100 : 0)
    }
    details.cheque_expenses = Number(details?.cashing_amount)
    details.cheque_expenses = Number(details.cheque_expenses).toFixed(2)
    details.cheque_expenses = Number(details.cheque_expenses)
    details.cheque_net = (Number((Number(discount?.amount_ils ? discount?.amount_ils : 0) - Number(details?.cheque_expenses ? details?.cheque_expenses : 0)) - (Number(details?.allowed_actual_expenses ? details?.allowed_actual_expenses : 0) + Number(details.allowed_expenses ? details.allowed_expenses : 0))).toFixed(2))
    let chequeExpenses = details?.cheque_expenses ? details?.cheque_expenses : 0
    let allowedExpenses = details?.allowed_expenses ? Number(details?.allowed_expenses) : 0
    let allowedActualExpenses = details?.allowed_actual_expenses ? Number(details?.allowed_actual_expenses) : 0
    // discount.fee = Number((chequeExpenses + allowedExpenses + allowedActualExpenses).toFixed(2))
    details.cheque_expenses = Number((chequeExpenses + allowedExpenses + allowedActualExpenses).toFixed(2))

    discount.fee = 0
    discount.financial_asset_details = details
    return discount
}
export const isCorporationFunc = (customer: any) => {
    let id
    if (customer?.customerTypeId)
        id = customer?.customerTypeId
    else
        if (customer?.identification?.ID_type_id)
            id = customer?.identification?.ID_type_id
        else
            if (customer?.idTypeId)
                id = customer?.idTypeId
    if (id === "3" || id === "5" || id === "9"
        || id === "12" || id === "10") {
        return true
    }
    else {
        return false
    }
}
export const isCorporationById = (id: any) => {
    if (id === "3" || id === "5" || id === "9"
        || id === "12" || id === "10") {
        return true
    }
    else {
        return false
    }
}
function isRequire(element: any, array: any) {
    const check = (currentValue: string) => element[currentValue] ? true : false;
    if (array.every(check)) {
        return false
    }
    else {
        return true
    }
}

export const checkRequiredFields = (financialAssetsCode: any, action: any,) => {
    let requiredFields, requiredFields2
    let required = false
    switch (financialAssetsCode) {
        case "3": {
            let arrDetailsRequireds = ["cheque_date", "reference_number",
                "id_beneficiary", "id_account_holder", "bank_account_id"]
            let arrActionRequireds = ["amount", "contra_id_account"]
            requiredFields = isRequire(action.financial_asset_details, arrDetailsRequireds)
            requiredFields2 = isRequire(action, arrActionRequireds)
            required = requiredFields || requiredFields2
            break;
        }
        case "12":
            if (action?.financial_asset_details && action?.financial_asset_details?.card_holder?.ID_number) {
                required = false;
                break;
            }
            else {
                required = true;
                break;
            }
        case "9":
            if (action?.financial_asset_details && action?.financial_asset_details?.account_holder?.ID_number) {
                required = false;
                break;
            }
            else {
                required = true
                break;
            }
    }
    return required
}

export const checkPermissionLevelOfPage = (credentials: any, location: any, setPermission?: any) => {
    let path: any = location.pathname.split("/")
    const moduleName = path[1]
    const pageName = path[2]

    let moduleObj = credentials?.find((item: any) => item?.module_code === moduleName)
        ?.pages.find((page: any) => page?.page_code === pageName);
    if (setPermission && moduleObj?.permission_level) {
        setPermission(moduleObj?.permission_level)
        return moduleObj?.permission_level
    }
    else
        return moduleObj?.permission_level
}



export const sortTableFields = (search_fielsd: [], data: any, setData: any) => {
    let arrResult = [...data]
    search_fielsd.forEach((item: any) => {

        if (item?.search_type === "equal") {
            if (item?.type_field === "number")
                arrResult = arrResult.filter((data: any) => Number(data?.[item?.field_name]) === Number(item?.search_value))
            else
                arrResult = arrResult.filter((data: any) => data?.[item?.field_name] === item?.search_value)
        }
        if (item?.search_type === "like") {
            if (item?.type_field === "string")
                arrResult = arrResult.filter((data: any) => data?.[item?.field_name]?.includes(item?.search_value))
            else {
                arrResult = arrResult.filter((data: any) => item?.search_value?.includes(data?.[item?.field_name]))
            }

        }
        if (item?.search_type === "begin_with") {
            arrResult = arrResult.filter((data: any) => data?.[item?.field_name].startsWith(item?.search_value))
        }
        if (item?.search_type === "less_than") {
            if (item?.type_field === "number")
                arrResult = arrResult.filter((data: any) => Number(item?.search_value) > Number(data?.[item?.field_name]))
            else
                arrResult = arrResult.filter((data: any) => item?.search_value > data?.[item?.field_name])
        }
        if (item?.search_type === "greater_than") {
            if (item?.type_field === "number")
                arrResult = arrResult.filter((data: any) => Number(item?.search_value) < Number(data?.[item?.field_name]))
            else
                arrResult = arrResult.filter((data: any) => item?.search_value < data?.[item?.field_name])
        }
    })
    setData(arrResult)
}
export const checkCurrencies = (someOfCurrencies: any, allOfCurrencies: any, action: any) => {
    let currencyIn, currencyInAll, currencyOut, currencyOutAll
    action?.financial_assets_in?.map((item: any) => {
        currencyIn = someOfCurrencies?.find((currency: any) => currency.key === item?.currency_code)
        if (currencyIn === undefined) {
            currencyInAll = allOfCurrencies?.find((currency: any) => currency.key === item?.currency_code)
            currencyInAll && someOfCurrencies?.push(currencyInAll)
        }
    })
    action?.financial_assets_out?.map((item: any) => {
        currencyOut = someOfCurrencies?.find((currency: any) => currency.key === item?.currency_code)
        if (currencyOut === undefined) {
            currencyOutAll = allOfCurrencies?.find((currency: any) => currency.key === item?.currency_code)
            currencyOutAll && someOfCurrencies?.push(currencyOutAll)
        }
    })
    return someOfCurrencies
}

export const dataURLtoFile = (dataurl: any, filename: any) => {    
    console.log(dataurl,"dataurl");
    console.log(filename,"filename");
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export const isFullyIdentified = (documents: any) => {
    const isFully = documents.filter((d: any) => d.type === 'חובה' && (!d?.id_media || d.id_media === ''));
    return isFully?.length === 0;
}