import axios from "axios";

import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { basicUrl } from "../../shared/config";

export const CreateEmail = async (customer: object, currUserCredentials: IUserCredentials | null, id_entity: string, setshowSpiner?: any,
    type?: string, setShowDialogSave?: any, setShowDialogFailed?: any, setServerError?: any, setReadOnly?: any) => {
    const email = {
        //@ts-ignore
        "id_initiator": currUserCredentials.idInitiator,
        //@ts-ignore
        "id_branch": currUserCredentials.idBranch,
        //@ts-ignore
        "id_entity": id_entity,
        //@ts-ignore
        "id_client": currUserCredentials.idClient,
        "return_entity": true,
        "attribute_request_method": "create",
        "attribute": "email",
        "entity_type_id": type ? "insider" : "customer",
        //@ts-ignore
        "email_type_id": customer.emailTypeId ? customer.emailTypeId : 1,
        //@ts-ignore
        "email_address": customer.email,
        "is_deleted": 0,
        //@ts-ignore
        "is_default": customer.emailDefault ? customer.emailDefault : false
    }
    const body = JSON.stringify(email);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    let res = await axios.post(basicUrl + '/entity', body, config)
    try {
        if (res.status === 200) {
            if (res.data["err_code"] === 0) {
                // return true
                setShowDialogSave && setShowDialogSave(true)
                setshowSpiner && setshowSpiner(false)
                setReadOnly && setReadOnly(true)
            }
            else {
                setshowSpiner && setshowSpiner(false)
                setServerError && setServerError(res.data?.ui_message?.ui_message)
                setShowDialogFailed && setShowDialogFailed(true)
                console.log(res.data?.err_description)
            }
        }
    }
    catch (err: any) {
        console.log('error in set email to customer component', err.message)
    }
    return false;
}




export const updateEmail = async (customer: object, userCredentials: IUserCredentials | null, currentIdEntity: string, id: any = 1, setShowDialogSave?: any, setReadOnly?: any, setshowSpiner?: any, type?: string, setShowDialogFailed?: any, setServerError?: any) => {

    const email = {
        "id_initiator": userCredentials && userCredentials.idInitiator,
        "id_client": userCredentials && userCredentials.idClient,
        "id_branch": userCredentials && userCredentials.idBranch,
        "id_entity": currentIdEntity,
        "return_entity": 1,
        "email_id": id,
        "attribute_request_method": "update",
        "attribute": "email",
        "entity_type_id": type === "user" ? "user" : "customer",
        //@ts-ignore
        "email_type_id": customer.emailTypeId,
        //@ts-ignore
        "email_address": customer.email,
        //@ts-ignore
        "is_default": customer.emailDefault,
    }

    const body = JSON.stringify(email);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    let res = await axios.post(basicUrl + '/entity', body, config)
    try {
        if (res.status === 200) {
            if (res.data["err_code"] === 0) {
                setShowDialogSave && setShowDialogSave(true)
                setReadOnly && setReadOnly(true)
                setshowSpiner && setshowSpiner(false)
            }
            else {
                setshowSpiner && setshowSpiner(false)
                setServerError && setServerError(res.data?.ui_message?.ui_message)
                setShowDialogFailed && setShowDialogFailed(true)
                console.log(res.data?.err_description)
            }
        }

    }
    catch (err: any) {
        setshowSpiner && setshowSpiner(false)
        setServerError && setServerError(err?.message)
        setShowDialogFailed && setShowDialogFailed(true)
        console.log('error in update email to customer component', err.message)
    }
    return false;
}




export const deleteEmail = async (userCredentials: IUserCredentials, currentIdEntity: string, emilId: any, setshowSpiner?: any) => {
    const email =
    {
        "id_initiator": userCredentials.idInitiator,
        "id_client": userCredentials.idClient,
        "id_branch": userCredentials.idBranch,
        "id_entity": currentIdEntity,
        "return_entity": 1,
        "email_id": emilId,
        "attribute_request_method": "delete",
        "entity_type_id": "customer",
        "attribute": "email",
        "is_deleted": true
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(email);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    let res = await axios.post(basicUrl + '/entity', body, config)
    try {
        if (res.status === 200) {
            if (res.data["err_code"] === 0) {
                setshowSpiner(false)
                return true

            }
            else {
                setshowSpiner(false)
            }
        }
        else {
            setshowSpiner && setshowSpiner(false)
        }
    }
    catch (err: any) {
        setshowSpiner && setshowSpiner(false)
        console.log('error in delete email to customer component', err.message)
    }
    return false;
}

