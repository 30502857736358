
import { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { CustomTextField } from '../../shared/components/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import CurrencyInput from 'react-currency-input-field';

import './Invoice.scss'

export default function InvoiceTable(props: any) {
    const { setAction, action, setIfChanges, readOnly, emptyAmount } = props
    const [t] = useTranslation()
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const vatClassificationId = authReducer?.branch?.setting?.vat_classification_id
    const [messageError, setMessageError] = useState('')
    const [serverMessageError, setServerMessageError] = useState<any>()
    const [saveDeleteRowActoin, setSaveDeleteRowActoin] = useState("")
    const [rowOfDelete, setRowOfDelete] = useState<any>()
    const [vatRate, setVatRate] = useState<any>(
        authReducer?.data?.branch?.setting?.vat_classification_id === "2" &&
        authReducer?.data?.branch?.setting?.vat_rate * 100
    )

    useEffect(() => {
        if (saveDeleteRowActoin === "save") {
            setSaveDeleteRowActoin("")
            setIfChanges(true)
            deleteRow()
        }
        if (saveDeleteRowActoin === "don't save")
            setSaveDeleteRowActoin("")

    }, [saveDeleteRowActoin])


    const setActionsDetails = (arr: any, copyAction: any) => {
        let sum = 0
        arr.forEach((item: any) => {
            sum += item?.amount_ils ? Number(item.amount_ils) : 0
        })
        copyAction.items = arr
        copyAction.sub_total = sum
        copyAction.total_amount_ils = Math.round(sum + (sum * copyAction.vat_rate))
        copyAction.vat_amount = copyAction.total_amount_ils / (1 + copyAction.vat_rate) * copyAction.vat_rate
        copyAction.sub_total_amount = (copyAction.total_amount_ils - copyAction.vat_amount)
        copyAction.amount_round = (copyAction.sub_total_amount - copyAction.sub_total)
        setAction(copyAction)
    }
    const changeAction = (key: string, value: any) => {
        const [id, row_number] = key.split(',');
        let arr = [], copyAction: any = {}
        copyAction = { ...action }
        arr = [...action.items]
        if (id !== "vat_rate") {
            let i = arr.findIndex((item: any) => Number(item.row_number) === Number(row_number))
            let temp: any = {
                description: (id === "description") ? value : arr[i]?.description,
                quantity: (id === "quantity") ? value : arr[i]?.quantity,
                amount: (id === "amount") ? value ? value : 0 : arr[i]?.amount,
                amount_ils: (id === "amount") ? value * arr[i]?.quantity : id === "quantity" ? value * arr[i]?.amount : arr[i]?.amount_ils,
                row_number: arr[i]?.row_number,
                currency_code: arr[i]?.currency_code,
                exchange_rate_ils: arr[i]?.exchange_rate_ils,
            }
            arr[i] = temp
        }
        else {
            setVatRate(value)
            copyAction.vat_rate = value / 100
        }
        setIfChanges(true)
        setActionsDetails(arr, copyAction)
    }

    const deleteRowMassage = (row_number: number) => {

        setRowOfDelete(row_number)
        setServerMessageError(true)
        setMessageError(t("delteRowMassege"))
    }
    const deleteRow = () => {
        let arr: any = [], copyAction: any
        copyAction = { ...action }
        arr = [...action["items"]]
        let index = arr.findIndex((a: any) => Number(a.row_number) === Number(rowOfDelete));
        arr.splice(index, 1)
        if (arr.length === 0)
            arr.push({
                row_number: 1,
                description: "",
                quantity: 1,
                amount: 0,
                exchange_rate_ils: 1,
                amount_ils: 0,
                currency_code: "ILS",
            })
        setActionsDetails(arr, copyAction)
    }

    const addRow = async (rowNumber: Number) => {
        let arr: any = []
        setIfChanges(true)
        arr = [...action["items"]]
        let maxRowNumber = Math.max.apply(Math, arr.map(function (item: any) { return item.row_number; }))
        if (maxRowNumber === rowNumber) {
            arr.push({
                row_number: maxRowNumber + 1,
                description: "",
                quantity: 1,
                amount: 0,
                exchange_rate_ils: 1,
                amount_ils: 0,
                currency_code: "ILS"
            })
        }
        setAction({ ...action, ["items"]: arr, })
    }

    return (
        <div>
            {serverMessageError && <DialogMessages setansDelete={setServerMessageError} subText={messageError} title={t("note")}
                setshowModalDelete={setServerMessageError} setSaveDataConversion={setSaveDeleteRowActoin} dialogType={"dialogMessage"} />}
            <p className="title-text-2" >{t("invoice")}</p>
            <hr className="hr" style={{ width: '650px' }}></hr>

            <div style={{ width: "650px" }}>

                <div style={{ width: "100%" }}>
                    <div style={{
                        backgroundColor: "#E1E1E1",
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap",
                        gridGap: "6px",
                        gap: "6px",
                    }}>
                        <p className={`title-invoice-table-${dir}`} style={{ width: "33%" }}>{t("details")}</p>
                        <p className={`title-invoice-table-${dir}`} style={{ width: "21%" }}>{t("amount")}</p>
                        <p className={`title-invoice-table-${dir}`} style={{ width: "19%" }}>{t("price")}</p>
                        <p className={`title-invoice-table-${dir}`} style={{ width: "21%" }}>{t("totalILS")}</p></div>
                </div>
                <hr className="hr" style={{ width: "100%", height: "2px", marginBottom: '0' }} ></hr>

                {action["items"]?.map((c: any, index: number) =>
                    <div key={index} style={{ width: "100%" }}>
                        <div className="inlineB" style={{ marginTop: "7px", height: "33px" }}>
                            <div style={{ width: "33%" }}>
                                <CustomTextField readOnly={readOnly} label={''} value={c?.description} onChange={changeAction} styleObj={true} id={`description,${c.row_number}`} />
                            </div>
                            <CurrencyInput
                                className={`padding-currency-${dir} currency-input`}
                                id={`quantity,${c.row_number}`}
                                name={`quantity,${c.row_number}`}
                                placeholder="0.00"
                                value={c?.quantity}
                                readOnly={readOnly}
                                style={{
                                    width: '21%', backgroundColor: readOnly ? "#FAF9F8" : "white",
                                }}
                                decimalsLimit={2}
                                onValueChange={(value, name) => changeAction(name || "", value)}
                            />
                            <CurrencyInput
                                className={`padding-currency-${dir} currency-input`}
                                id={`amount,${c.row_number}`}
                                name={`amount,${c.row_number}`}
                                placeholder="0.00"
                                value={c?.amount}
                                readOnly={readOnly}
                                style={{
                                    border: (!c?.amount && emptyAmount) && "3px solid #db3838", width: '19%',
                                    backgroundColor: readOnly ? "#FAF9F8" : "white"
                                }}
                                decimalsLimit={2}
                                onValueChange={(value, name) => changeAction(name || "", value)}
                            />
                            <div style={{ width: "21%" }}>
                                <CustomTextField label={''} value={Number(c?.amount_ils) ? Number(c?.amount_ils).toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0} readOnly={true} onChange={changeAction} styleObj={true} id={`amount_ils,${c.row_number}`} />
                            </div>
                            <p style={{ display: "grid", paddingTop: "0", textAlign: "center", lineHeight: "0.8", marginBottom: "0", width: "fit-content" }}>
                                <FontAwesomeIcon icon={faTimesCircle} className={`delete-row-invoice-${dir}`} title={t("deleteRow")} onClick={() => deleteRowMassage(c.row_number)} />
                                {c.amount_ils > 0 && <FontAwesomeIcon onKeyDown={e => e.key === 'Enter' && addRow(c.row_number)} className={c.amount_ils > 0 ? `iconRefresh-${dir} add-row-invoice-${dir}` : `iconDisabled add-row-invoice-${dir}`} icon={faPlusCircle} title={t("addRow")} onClick={() => { addRow(c.row_number) }} />}
                            </p>
                        </div>
                    </div>
                )}

                <div style={{ marginTop: "1px", width: "100%", height: "fit-content", border: "solid 2px lightgrey" }}>
                    <div className={`calc-discount-${dir}`}>
                        {action?.sub_total !== 0 && <div className={`title-calc-${dir}`}>
                            {t("totalBeforeVAT")}:
                        </div>}
                        {action?.sub_total !== 0 && <div className={`total-calc-${dir}`}>
                            {action?.sub_total?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}   {t("ILS")}
                        </div>}
                        {action?.sub_total === 0 && <div className={`title-calc-${dir}`}>
                            {t("totalBeforeVAT")}:  </div>}
                        {action?.sub_total === 0 && <div className={`total-calc-${dir}`}>
                            0.00   {t('ILS')}</div>}
                    </div>
                    <div className={`calc-discount-${dir}`} >
                        {action?.amount_round !== 0 && <div className={`title-calc-${dir}`}>
                            {t("rounding")}:   </div>}
                        {action?.amount_round !== 0 && <div className={`total-calc-${dir}`}>
                            <div className={`ltr-${dir}`}>{action?.amount_round?.toFixed(2)}</div>   {t("ILS")}
                        </div>}
                        {action?.amount_round === 0 && <div className={`title-calc-${dir}`}>{t("rounding")}:   </div>}
                        {action?.amount_round === 0 && <div className={`total-calc-${dir}`}>0.00  {t("ILS")}</div>}
                    </div>
                    <div className={`calc-discount-${dir}`} >
                        {action?.sub_total_amount !== 0 && <div className={`title-calc-${dir}`} >
                            {t("roundedTotal")}: </div>}
                        {action?.sub_total_amount !== 0 && <div className={`total-calc-${dir}`}>
                            <div className={`ltr-${dir}`}>{action?.sub_total_amount?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            {t('ILS')}
                        </div>}
                        {action?.sub_total_amount === 0 && <div className={`title-calc-${dir}`}>   {t("totalBeforeVAT")}: </div>}
                        {action?.sub_total_amount === 0 && <div className={`total-calc-${dir}`}>0.00   {t("ILS")}</div>}
                    </div>
                    {vatClassificationId === "2" && <div className={`calc-discount-${dir}`} >
                        <><div className={`div-invoice-${dir}`}>
                            <span className={`span-invoice-${dir}`} >  %</span>
                            <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold", textAlign: "center", width: "6%" }}><CustomTextField label={''} value={vatRate} onChange={changeAction} id='vat_rate,""' /></div>
                            <span className={`span-invoice-table-${dir}`} >{t('VATRate')}</span>
                        </div>
                            <p className={`p-invoice-table-${dir}`}>{action?.vat_amount ? t('vat')+" :"+  + " " + action?.vat_amount?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + t('ILS') :  t('vat')+": "+  + " " + "0.00 " + " " + t('ILS')}</p>
                            <p className={`p-invoice-table-${dir}`}>{action?.total_amount_ils ?  t('difference')+":" + " " + action?.total_amount_ils?.toFixed()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + t('ILS') :  t('totalPaymentIncludingVAT')+" :"  + " " + "0.00 " + " " + t('ILS')}</p>

                        </>
                    </div>}
                </div>
            </div>
        </div >
    )
}