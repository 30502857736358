import { useEffect, useState } from 'react'
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';

import ListRelatedFactors from './ListRelatedFactors'
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import EditableRelatedFactors from './EditableRelatedFactors'
import Subtitle from '../../shared/components/Subtitle';
import Title from '../../shared/components/Title';
import './relatedFactors.css'
import { readCustomerId } from '../../store/actions/customerActioin';
import { Icon } from 'office-ui-fabric-react';
import { IUserCredentials } from '../customerDetails/CustomerDetails';
import { RESET_CHANGED_LOCATION } from '../../store/actions/types';
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions';

const RelatedFactors = () => {
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const history = useHistory()
  const dispatch = useDispatch()
  const authReducer = useSelector(({ authReducer }: any) => authReducer)
  const changedLocation = useSelector(({ authReducer }: any) => authReducer?.changedLocation)
  const dataCustomer = useSelector(({ customerReducer }: any) => customerReducer.dataCustomer)
  const [addFactor, setaddFactor] = useState(false)
  const [showDialogSave, setShowDialogSave] = useState(false)
  const [showSpiner, setshowSpiner] = useState(false)
  const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
  const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
  const location = useLocation()
  const spinnerStyles: Partial<ISpinnerStyles> = {
    root: {
      position: 'fixed',
      zIndex: '999999',
      fontWeight: 'bold',
    },
    circle: {
      color: '#FFB400 ',
      height: '100px',
      width: '100px',
    },
  }


  useEffect(() => {
    if (changedLocation) {
      setaddFactor(true)
       dispatch({
        type:  RESET_CHANGED_LOCATION,
        payload: null
    })
    }
  }, [changedLocation])

  useEffect(() => {
    setUserCredentials({
      "idInitiator": authReducer.data.user.id_entity,
      "idClient": authReducer.data.client.id_entity,
      "idBranch": authReducer.data.branch.id_entity,
      "idClientData": authReducer.data.client.id_client
    })
  }, [authReducer]);

  useEffect(() => {
    history.location.state = "showDefaltInsider"
  }, [])



  return (
    <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
    ? 'page-read' : 'page-read-only'}`}>
      <div className={`sub-header-${dir} fixed-${dir}`} style={{ height: "60px" }}>
        <Title
          title={t("customers")} nameInTitle={dataCustomer?.entity_name} />
        <div className="divider"></div><div className="divider"></div>
        <div className="button"
          style={{
            cursor: "pointer"
          }}
          title='רענן'
          onClick={() => {
            dispatch(readCustomerId(dataCustomer?.id_entity,
              userCredentials, "", "", "", false));
          }}
        > <Icon iconName="refresh"></Icon></div>
      </div>
      <br></br> <br></br>
      <Subtitle title={t("RelatedFactors")} />
      {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
      {showDialogSave && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"save"} />}
      <div className={`content-div-${dir}`}>
        <div className={`attechedF width-related-${dir}`} >
          <p className={`title-text width-related-${dir}`} >{t('RelatedFactorsList')}</p>

          <div className="title-text addFile"  onClick={() => setaddFactor(true)} ><i className="fal fa-plus"></i></div>
          {/* <button onClick={()=>{ history.push("/customers/related-factors/edit")}}>גורמים קשורים</button>  */}
          {addFactor &&
            <EditableRelatedFactors setShowDialog={setaddFactor} setShowDialogSave={setShowDialogSave} setshowSpiner={setshowSpiner} />}
        </div>
        <hr className="hr" style={{ width: "980px" }}></hr>
        {dataCustomer?.insiders?.length > 0 ? <ListRelatedFactors setshowSpiner={setshowSpiner}
          dataCustomer={dataCustomer?.insiders} /> : <div style={{ textAlign: "center", marginTop: "18px", width: "66%" }}>{t('noDisplay')}
        </div>}</div>

    </div>
  )
}
export default RelatedFactors
