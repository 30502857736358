import axios from "axios";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { basicUrl } from "../../shared/config";

export const CreateAddress = async (customer: object, currUserCredentials: IUserCredentials | null, id_entity: string, setshowSpiner?: any, type?: string
    , setShowDialogSave?: any, setShowDialogFailed?: any, setServerError?: any, setReadOnly?: any) => {
    // (customer: object, currUserCredentials: IUserCredentials | null, id_entity: string,setReadOnly?: any, type?: string, setshowSpiner?: any
    //  , setServerMessageError, setMessageError )
    const address = {
        //@ts-ignore
        "id_initiator": currUserCredentials.idInitiator,
        //@ts-ignore
        "id_branch": currUserCredentials.idBranch,
        //@ts-ignore
        "id_entity": id_entity,
        //@ts-ignore
        "id_client": currUserCredentials.idClient,
        "return_entity": 1,
        "attribute_request_method": "create",
        "attribute": "address",
        "entity_type_id": type ? "insider" : "entity",
        //@ts-ignore
        "address_type_id": customer.addressTypeId ? customer.addressTypeId : 1,
        //@ts-ignore
        "address_street": customer.addressName,
        //@ts-ignore
        "address_street_code": customer.address,
        //@ts-ignore
        "address_city": customer.addressCityName,
        //@ts-ignore
        "address_number": customer.addressNumber,
        //@ts-ignore
        "address_city_code": customer.addressCity,
        //@ts-ignore
        "address_country_code": (customer.addressCountryCode&&customer.iDCountryCode) ?customer.addressCountryCode:customer.iDCountryCode? customer.iDCountryCode:customer.addressCountryCode ,

        // "address_country_code": type ? customer?.addressCountryCode : customer?.iDCountryCode ? customer?.iDCountryCode : customer?.addressCountryCode,
        //@ts-ignore
        "address_zip_code": customer.addressZipCode,
        "is_deleted": 0,
        //@ts-ignore
        "is_default": customer.addressDefault ? customer.addressDefault : false
    }
    const body = JSON.stringify(address);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    await axios.post(basicUrl + '/entity', body, config).then(res => {
        try {
            if (res.status === 200) {
                if (res.data["err_code"] === 0) {
                    setshowSpiner && setshowSpiner(false)
                    setShowDialogSave && setShowDialogSave(true)
                    setReadOnly && setReadOnly(true)
                }
                else {
                    setshowSpiner && setshowSpiner(false)
                    setServerError && setServerError(res.data?.ui_message?.ui_message)
                    console.log(res.data?.err_description)
                    setShowDialogFailed && setShowDialogFailed(true)
                }
            }
        }
        catch (err) {
            setshowSpiner && setshowSpiner(false)
            setServerError && setServerError(res.data?.ui_message?.ui_message)
            setShowDialogFailed && setShowDialogFailed(true)
            console.log('error in create address', err)
        }
    }).catch(err => {
        console.log("error", err.message);
    })
}




export const updateAddress = async (customer: object, userCredentials: IUserCredentials | null, currentIdEntity: string, id: any, setShowDialogSave?: any, setReadOnly?: any, setshowSpiner?: any, type?: string, setShowDialogFailed?: any, setServerError?: any) => {
    // (customer: object, userCredentials: IUserCredentials | null, currentIdEntity: string, id: any, setReadOnly?: any, type?: string, setshowSpiner?: any,setServerMessageError?: any, setMessageError)
    const address = {
        "id_initiator": userCredentials && userCredentials.idInitiator,
        "id_client": userCredentials && userCredentials.idClient,
        "id_branch": userCredentials && userCredentials.idBranch,
        "id_entity": currentIdEntity,
        "return_entity": 1,
        //@ts-ignore
        "address_id": id,
        "attribute_request_method": "update",
        "attribute": "address",
        "entity_type_id": type === "user" ? "user" : type === "insider" ? "insider" : "customer",
        //@ts-ignore
        "address_street": customer.addressName,
        //@ts-ignore
        "address_type_id": customer.addressTypeId,
        //@ts-ignore
        "address_street_code": customer.address,
        //@ts-ignore
        "address_city": customer.addressCityName,
        //@ts-ignore
        "address_number": customer.addressNumber,
        //@ts-ignore
        "address_city_code": customer.addressCity,
        //@ts-ignore
        "address_country_code": (customer.addressCountryCode&&customer.iDCountryCode) ?customer.addressCountryCode:customer.iDCountryCode? customer.iDCountryCode:customer.addressCountryCode ,
        //@ts-ignore
        "address_zip_code": customer.addressZipCode,
        "is_deleted": 0,
        //@ts-ignore
        "is_default": customer.addressDefault
    }
    const body = JSON.stringify(address);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    await axios.post(basicUrl + '/entity', body, config).then(res => {
        try {
            if (res.status === 200) {
                if (res.data["err_code"] === 0) {
                    setShowDialogSave && setShowDialogSave(true)
                    setReadOnly && setReadOnly(true)
                    setshowSpiner && setshowSpiner(false)
                }
                else {
                    setshowSpiner && setshowSpiner(false)
                    setServerError && setServerError(res.data?.ui_message?.ui_message)
                    setShowDialogFailed && setShowDialogFailed(true)
                }
            }
        }
        catch (err) {
            setshowSpiner && setshowSpiner(false)
            setServerError && setServerError(res.data?.ui_message?.ui_message)
            setShowDialogFailed && setShowDialogFailed(true)
            console.log('error in update address', err)
        }
    }).catch(err => {
        console.log("error", err.message);
    })
}


export const deleteAddress = async (userCredentials: IUserCredentials, currentIdEntity: string, addressId: any = 1, setshowSpiner?: any, isClient?: any) => {
    // (userCredentials: IUserCredentials, currentIdEntity: string, addressId: any = 1, isClient?: any, setshowSpiner?: any,setServerMessageError?: any, setMessageError)
    const address = {
        "id_initiator": userCredentials.idInitiator,
        "id_branch": userCredentials.idBranch,
        "id_client": userCredentials.idClient,
        "id_entity": currentIdEntity,
        "return_entity": 1,
        "address_id": addressId,
        "attribute_request_method": "delete",
        "attribute": "address",
        "entity_type_id": isClient === true ? "client" : "customer",
        "is_deleted": true
    }
    const body = JSON.stringify(address);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    await axios.post(basicUrl + '/entity', body, config).then(res => {
        try {
            if (res.status === 200) {
                if (res.data["err_code"] === 0) {
                    setshowSpiner && setshowSpiner(false)
                }
                else {
                    setshowSpiner && setshowSpiner(false)
                }
            }
        }
        catch (err) {
            setshowSpiner && setshowSpiner(false)
            console.log('error in update address', err)
        }
    }).catch(err => {
        console.log("error", err.message);
    })
}