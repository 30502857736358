import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Callout, getTheme, mergeStyleSets } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks';

import IconSvg from '../../modelsType/Icon/IconSvg';
import PrintTable from '../global/PrintTable';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import { IUserCredentials } from '../customerDetails/CustomerDetails';
import { CustomTextField } from '../../shared/components/TextField';
import { accountsFilter } from '../../store/actions/bankActions';

import '../../components/actions/discountsAction/checkManagment/AGtable.css';
import { readCopycompletedDoc } from '../../store/actions/completeDocAction';
import DialogCompleteTransactions from '../../shared/components/dialog/dialogTransaction/DialogCompleteTransactions';


const initialDataAllFilter = {
    credit: ["less_than", ""],
    debit: ["less_than", ""],
    currency_code: ["equal", ""],
    sum_amount: ["less_than", ""],
}

export default function AccountCurrencyBalanceTable(props: any) {
    const { idEntity, idAccount, actionIn, tab } = props
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    let componentRef = useRef(null);
    const _export = React.useRef<any>(null);
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [messageError, setMessageError] = useState('')
    const [serverMessageError, setServerMessageError] = useState<any>('')
    const [dialogTitle, setDialogTitle] = useState('')
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [accountMovements, setAccountMovements] = useState<any>()
    const [showDialogTransaction, setShowDialogTransaction] = useState(false)
    const [curentPage, setCurentPage] = useState(1);
    const [numResults, setnumResults] = useState(-1)
    const [numRecourd, setNumRecourd] = useState(10)
    const [accountsData, setAccountsData] = useState<any>()
    const [pageCount, setPageCount] = useState(0);
    const [actionName, setActionName] = useState('')
    const [loading, { setTrue: displayLoading, setFalse: hideLoading }] = useBoolean(true);
    const [firstTime, setFirstTime] = useState(true)
    const [arrTitle, setArrTitle] = useState([
        { key: "credit", eng: 'credit', sortValue: false, default: "less_than" },
        { key: "debit", eng: 'debit', sortValue: false, default: "less_than" },
        { key: "currencyCode", eng: 'currency_code', sortValue: false, default: "equal" },
        { key: "sumAmount", eng: 'sum_amount', sortValue: false, default: "less_than" },
    ])
    const [search_fields, setSearch_fields] = useState<any>([]);
    const [filter, setFilter] = useState({ key: '', value: false })
    const numRecourdList: IDropdownOption[] = [{ key: 10, text: "10" },
    { key: 50, text: "50" }, { key: 100, text: "100" }]
    const operatorStringList: IDropdownOption[] = [{ key: "like", text: t("like") },
    { key: "begin_with", text: t("begin_with") }, { key: "equal", text: t("equal") }]
    const operatorAmountList: IDropdownOption[] = [{ key: "less_than", text: t("less_than") },
    { key: "equal", text: t("equal") }, { key: "greater_than", text: t("greater_than") }]
    const arrWidth: any = ['120px', '120px', '100px', '190px']
    const [isValidDateCreate, setIsValidDateCreate] = useState(true)
    const [dataAllfilter, setDataAllFilter] = useState<any>(initialDataAllFilter)
    const [actionData, setaAtionData] = useState<any>([]);


    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])

    useEffect(() => {
        if (userCredentials && curentPage && tab) {
            displayLoading()
            dispatch(accountsFilter(idAccount, idEntity, userCredentials, "search_ledger_currencies_balance", setSearch_fields, setAccountsData
                , curentPage, search_fields?.length ? search_fields : [], numRecourd, setServerMessageError, setMessageError, setDialogTitle))
        }
        // eslint-disable-next-line
    }, [curentPage, userCredentials, tab]);


    useEffect(() => {
        if (accountsData) {
            let arr: any[] = []

            accountsData?.search_results?.sort((item1: any, item2: any) =>
                Number(item1?.row_number) - Number(item2?.row_number)
            );
            accountsData?.search_results?.forEach((a: any) => {
                arr.push({
                    credit: a.credit,
                    currency_code: a.currency_code,
                    debit: a.debit,
                    sum_amount: a.sum_amount
                })
            });
            setAccountMovements(arr)
        }
    }, [accountsData])

    useEffect(() => {
        if (accountsData) {
            if (firstTime === true) {
                setFirstTime(false)
                setnumResults(-1)
            }
            else {
                setnumResults(accountsData?.records_found)
            }
            getNumaPagse(accountsData?.records_found, accountsData.page_size)
            setCurentPage(accountsData.page_index)
        }
        // eslint-disable-next-line
    }, [accountsData])

    useEffect(() => {
        if (actionData)
            if (actionName === "print")
                if (handlePrint)
                    handlePrint()
    }, [actionData])

    const _onPageUpdate = async (pageno?: number) => {
        var currentPge = (pageno) ? pageno : curentPage;
        setCurentPage(currentPge);
        setnumResults(accountsData?.records_found)
    };

    useEffect(() => {
        if (accountsData?.search_results) {
            _onPageUpdate();
            hideLoading();
        }
    }, [accountsData?.search_results]);

    const enterFilter = (field: any, value: any, key: any) => {
        let index
        let arr = cloneDeep(search_fields)
        let data = cloneDeep(dataAllfilter)
        let item: any
        item = {
            "field_name": field.eng,
            "search_type": "",
            "search_value": ""
        }
        if (field.eng.includes("date"))
            index = arr.findIndex((x: any) => x.field_name === field.eng &&
                x.search_type === field.default)
        else
            index = arr.findIndex((x: any) => x.field_name === field.eng)
        if (index !== -1) {
            item = arr[index]
        }
        if (key === 'operator') {
            data[field.eng][0] = value
            item["search_type"] = value
        }

        else {
            item["search_value"] = value
            data[field.eng][1] = value
        }
        if (index !== -1) {
            let arr1 = search_fields
            arr1[index] = item
            setSearch_fields(arr1)
        } else {
            if (item["search_type"] === "") {
                item["search_type"] = field.default
            }
            setSearch_fields([...search_fields, item])
        }
        setDataAllFilter(data)
    }

    const sortCol = (col: any, index: any) => {
        if (col?.sortValue)
            accountMovements?.sort((a: any, b: any) => (a[col?.eng] > b[col?.eng] ? -1 : 1))
        else
            accountMovements?.sort((a: any, b: any) => (a[col?.eng] > b[col?.eng] ? 1 : -1))
        let item = { key: col.key, eng: col.eng, sortValue: !col.sortValue, filterValue: col.fliterValue, default: col?.default }
        let arr: any = [...arrTitle]
        arr[index] = item
        setArrTitle(arr)
    }
    const filterCol = (key: any) => {
        setFilter({ key: key, value: !filter.value })
    }
    const sendFilterConditions = () => {
        let arrToSend: any[] = []
        for (let index = 0; index < search_fields?.length; index++) {
            if (!search_fields[index].search_type || !search_fields[index].search_value) {
                setSearch_fields((item: any) => item.filter((x: any) => x.field_name !== search_fields[index].field_name));
            }
            else {
                arrToSend.push(search_fields[index])
            }
        }
        setFilter({ key: '', value: false })
        setDataAllFilter(initialDataAllFilter)
        displayLoading()
        dispatch(accountsFilter(idAccount, idEntity, userCredentials, "search_ledger_currencies_balance", setSearch_fields,
            setAccountsData, curentPage, arrToSend, numRecourd, setServerMessageError, setMessageError, setDialogTitle))
    }

    const funcAction = async () => {
        displayLoading()
        await dispatch(accountsFilter(idAccount, idEntity, userCredentials, "search_ledger_currencies_balance", undefined,
            setaAtionData, curentPage, [], accountsData?.records_found))
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const chooseNumRecord = (value: any, key: any) => {
        setNumRecourd(key.key)
        displayLoading()
        dispatch(accountsFilter(idAccount, idEntity, userCredentials, "search_ledger_currencies_balance", undefined, setAccountsData, curentPage, [], key?.key))
    }

    const getNumaPagse = (numResults: any, pageSize: any) => {
        let calcNumPages = 0;
        if (numResults > pageSize)
            calcNumPages = (numResults - numResults % numRecourd) / numRecourd
        if (numResults % pageSize >= 1)
            calcNumPages += 1
        setPageCount(calcNumPages);
    }
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };
    const displayTransaction = (index: any) => {
        if (accountMovements[index]?.id_transaction) {
            dispatch(readCopycompletedDoc(accountMovements[index]?.transaction_type_id, userCredentials,
                idEntity, accountMovements[index]?.id_document))
        }
        else {
            setMessageError(t("documentCannotDisplayed"))
            setServerMessageError(true)
        }
        setShowDialogTransaction(true)

    }

    return (
        <>
            {showDialogTransaction && < DialogCompleteTransactions setShowDialog={setShowDialogTransaction} />}
            {serverMessageError && <DialogMessages setansDelete={setServerMessageError}
                setshowModalDelete={setServerMessageError} subText={messageError} title={dialogTitle} dialogType={"dialogMessage"} />}
            <div style={{
                display: "flex", marginTop: "24px",
                width: "100%", justifyContent: "space-between"
            }}>
                <div style={{ display: "flex",justifyContent:"space-between",width:'470px' }}>
                    <div style={{ flexDirection: "row" }}>
                        <PrimaryButton onClick={() => { sendFilterConditions() }} text={t("filter")} />
                        <PrimaryButton className={`margin-between-input-${dir}`} onClick={() => {
                            setSearch_fields([])
                            setDataAllFilter(initialDataAllFilter)
                            displayLoading()
                            dispatch(accountsFilter(idAccount, idEntity, userCredentials, "search_ledger_currencies_balance", undefined, setAccountsData, curentPage, [], numRecourd))

                        }} text={t("cancelFilter")} />
                    </div>
                    {accountMovements?.length > 0 && <div style={{ display: "" }}>
                        {accountMovements?.length > 0 && <Dropdown
                            placeholder={t("NumberOfRowsToDisplay")}
                            id={''}
                            className={actionIn ? `num-rows-action-${dir}` : `num-rows-${dir}`}
                            options={numRecourdList}
                            onChange={(id: any, val: any) => (chooseNumRecord(id, val))}
                        />}

                    </div>}
                </div>
               
                {accountMovements?.length > 0 && <div style={{ display: "flex" }}>
                    {actionIn &&
                        <>
                            <div style={{ marginTop: "-98px", padding: "0px" }}><IconButton className='button'
                                id='print' onClick={() => { setActionName("print"); funcAction() }} style={{ margin: "0px" }} > <IconSvg name="printer" /></IconButton>
                            </div>
                            <div style={{ marginTop: "-100px", padding: "0px" }}>
                                <IconButton
                                    className="button" onClick={excelExport} style={{ margin: "0px" }}
                                > <IconSvg name="xl" /></IconButton>
                            </div>
                        </>
                    }
                </div>}
            </div>


            <div style={{ width: '100%', overflowX: 'auto', overflowY: "auto" }}>
                {accountMovements?.length === 0 ? <p style={{ marginBottom: '0' }}>{t('searchCustomerNotFound')}</p> :
                    <p style={{ marginBottom: '0' }}>{t('found')} {accountMovements?.length} {t('results')}</p>}
                {accountMovements?.length > 0 &&
                    <table style={{ border: '2px solid #e1e1e1', overflow: "auto" }}>
                        <tbody>
                            <tr>
                                {arrTitle?.map((item: any, key: any) => (
                                    <th className={` ${`padding-input-${dir}`} ${`th-table-${dir}`}`} key={key}
                                        style={{ width: arrWidth[key] }}>
                                        <div className='wrapper' >{(item?.eng === 'amount_ils' && t(item?.key) === "סכום בש''ח") ?
                                            <p style={{ margin: "0" }}> סכום &#8362;</p> : <p style={{ margin: "0" }}>{t(item?.key)}</p>}
                                            <div className={`cr-dr-span-${dir}`}>
                                                <i onClick={() => { sortCol(item, key) }} title={t("filter")}
                                                    className={`icon-sort-t-${dir} ${item.sortValue ? "fal fa-arrow-up" : "fal fa-arrow-down"} `}></i>
                                                {/* className={`icon-sort-t-${dir} fal fa-filter`} */}
                                                <i className={`icon-sort-t-${dir} ${dataAllfilter[item.eng][1] ? "fas" : "fal"} fa-filter`} id={item.eng} title={t("filter")}
                                                    onClick={() => { filterCol(item.key) }}></i>
                                            </div>
                                        </div> {filter.key === item.key && filter.value === true && <>
                                            <Callout
                                                coverTarget
                                                onKeyDown={(e: any) => e.key === "Enter" && (sendFilterConditions())}
                                                className={styles.callout}
                                                onDismiss={() => { filterCol(item.key) }}
                                                target={`#${item.eng}`}
                                                isBeakVisible={false}
                                                setInitialFocus >
                                                <FontAwesomeIcon icon={faTimes} title={t("close")} onClick={() => { filterCol(item.key) }} style={{ height: '12px', marginTop: '2%', marginBottom: '2%' }} className={`iconRefresh-${dir}`} />
                                                <div key={key + 1} >
                                                    {item.default !== "equal" ?
                                                        <Dropdown
                                                            placeholder={t("selectCondition")}
                                                            selectedKey={dataAllfilter[item.eng][0]}
                                                            id={item}
                                                            style={{ marginBottom: '2%' }}
                                                            onChange={(id: any, val: any, e: any) => (enterFilter(item, val.key, "operator"))}
                                                            options={item.eng.includes('currencyCode') ? operatorStringList : operatorAmountList}
                                                        />
                                                        :
                                                        <div className='table-spaces'><CustomTextField label={""}
                                                            placeholder={item.eng === "bank_branch_code" ? t("enterBranchNumber") : item.eng === "bank_code" ? t("enterBankNumber") : `${t("enter")} ${t(item?.key)}`}
                                                            value={search_fields ? dataAllfilter[item.eng][1] : `${"enter"} ${item?.key}`}
                                                            readOnly={false} onChange={(id: any, val: any) => (enterFilter(id, val, "value"))} id={item} />
                                                        </div>}
                                                </div>
                                            </Callout>
                                        </>}
                                    </th>
                                ))}
                            </tr>
                            {accountMovements?.map((item: any, key: any) => (
                                <tr key={key} className="hoverRow" style={{ borderBottom: '1px solid #e1e1e1', minHeight: '46px' }}>
                                    <td className={`td-movements-${dir} ltr-${dir}`}>{Number(item?.credit)?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td className={`td-movements-${dir}`}>{Number(item?.debit)?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td style={{
                                        border: 'none', height: '46px', justifyContent: "center",
                                        borderRight: "0.5px solid #e1e1e1", alignItems: "center", textAlign: 'center', color: "grey"
                                    }}>{item?.currency_code}</td>
                                    <td className={`ltr-${dir} td-movements-${dir}`} >
                                        {Number(item?.sum_amount) < 0 ?
                                            <div style={{ justifyContent: "space-between", alignItems: "center", display: "flex" }}><span className={`cr-dr-span-${dir}`}>(ז)</span>
                                                <span>{Math.abs(Number(item?.sum_amount))?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div> :
                                            <div style={{ justifyContent: "space-between", alignItems: "center", display: "flex" }}><span className={`cr-dr-span-${dir}`}>(ח)</span>  <span>{Number(item?.sum_amount)?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                                        }</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
            </div>
            <PrintTable actionData={actionData?.search_results} arrTitle={arrTitle} printRef={componentRef} />

            {/* {(accountMovements?.length > 0 && !loading) &&
                <div className="paginationContainer" style={{ maxWidth: '700px', display: 'flex', justifyContent: "center", margin: "20px 0px", direction: "ltr" }}>
                    <div className="searchWp__paginationContainer__pagination">
                        <Pagination
                            currentPage={curentPage}
                            totalPages={Math.ceil(accountsData?.records_found / numRecourd)}
                            onChange={(page) => _onPageUpdate(page)}
                            limiter={3}
                        />
                    </div>
                </div>} */}

            <div style={{ display: "none" }}>
                <ExcelExport data={accountMovements} fileName={`${moment().format("ss:mm:hh_DD_MM_YYYY")}`} ref={_export}>
                    {arrTitle?.map((item: any, key: any) => (
                        <ExcelExportColumn key={key}
                            field={item?.eng}
                            title={item?.key}
                            locked={true}
                            width={200}
                        />
                    ))}
                    <ExcelExportColumn
                        field={"total_account"}
                        title={t('balance')}
                        locked={true}
                        width={200}
                    />
                    <ExcelExportColumn
                        field={"exchange_rate_ils"}
                        title={t('exchangeRates')}
                        locked={true}
                        width={200}
                    />
                </ExcelExport>
            </div>
        </>
    )
}

const theme = getTheme();
const styles = mergeStyleSets({
    callout: {
        width: 200,
        padding: '9px',
        background: theme.semanticColors.bodyBackground,
    }, calloutDate: {
        width: 265,
        padding: '9px',
        background: theme.semanticColors.bodyBackground,
        bottom: "-442.396px !important"
    },
});

