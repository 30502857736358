import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default function NotPermission() {
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [t] = useTranslation();
    return (
    <div className={`content-div-${dir}`}>
      <h3 className='not-premission'>{t("notPremissionToPage")}</h3>
    </div>
  )
}
