// import { useBoolean } from '@fluentui/react-hooks';
// import { PrimaryButton, Dialog,ContextualMenu, DialogFooter
//   , DefaultButton } from 'office-ui-fabric-react';
// import { useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';

// import { CustomDropdown } from '../../Option';
// import { CustomTextField } from '../../TextField';
// import { createBankAccount } from "../../../../store/actions/bankActions"
// import { createCustomerInConvertions, updateCustomerInfo } from '../../../../store/actions/customerActioin'
// import { convertArrToDropdropdown } from '../../../../components/customerDetails/CustomerDetails'
// import { useDispatch, useSelector } from "react-redux";
// import { DialogMessages } from '../DialogMessages';
// import { CustomDialogBankAccount } from '../Dialog';
// import { Address } from '../../CustomHooks/Address'

// import "../dialog.scss";
// import DialogSearch from '../../../../components/global/DialogSearch';
// import { isEqual } from 'lodash';
// // import validator from 'validator';

// const initialHolder = {
//   gender: 1,
//   otherGender: "",
//   entityStatusId: 0,
//   idTypeCountryCode: "",
//   firstName: '',
//   note: "",
//   lastName: '',
//   isLocked: false,
//   isPopupMessage: false,
//   dateBirth: "",
//   genderOther: "",
//   middleName: "",
//   otherCustomerStatus: "",
//   classId: 1,
//   entitySubTypeId: 1,
//   ViewNoteWhenPerformingAction: false,
//   industryId: 0,
//   CreditGroup: 0,
//   idAffiliate: 0,
//   idTypeId: "",
//   idNumber: "",
//   idTypeOther: "",
//   addressCountryCode: "",
//   iDCountryCode: "",
//   addressState: "",
//   idIdentifier: 0,
//   address: "",
//   addressName:"",
//   addressNumber: "",
//   addressCity: "",
//   addressCityName: "",
//   telephone: "",
//   telephoneCountryCode: "",
//   email: "",
//   entityNumber: "",
//   addressZipCode: "",
//   customerTypeId: "",
//   workerName: "",
//   genderName: "",
//   telephoneTypeId: 1,
//   telephoneId: 0,
//   addressTypeId: 1,
//   emailTypeId: 1,
//   emailId: 0,
//   telephoneDefault: 1,
//   addressDefault: 1,
//   emailDefault: 1,
//   addressId: 0,
//   login_entity_number: '',
//   login_ID: '',
//   login_password: ''
// }

// export const HolderAccountDialog = (props: any) => {
//   const { setShowDialog, userCredentials, setidHolder, setnewHolder, idHolder, setidBankAccount, setnewBankAccount, newHolder, updateBankAccount, setUpdateBankAccount, conversionCustomer } = props
//   const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
//   const [t] = useTranslation();
//   const [dir,setDir]=useState("")
//   const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)
//   const dispatch = useDispatch()
//   const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
//   const [enumsDropdown, setenumsDropdown] = useState<any>({ "countryCode": [], "bank": [], "branch": [] })
//   const [readOnly, setreadOnly] = useState(false)
//   // const [showSpiner, setshowSpiner] = useState(false)
//   const [/*search*/, setsearch] = useState(false)
//   const [isNeedNewHolder, setisNeedNewHolder] = useState(true)
//   const [showDialogFailed, setShowDialogFailed] = useState(false)
//   const [showRequiredFildes, setShowRequiredFildes] = useState(false)
//   const [messageError, setMessageError] = useState('')
//   const [/*idEntity*/, setidEntity] = useState('')
//   const [/*defaultIdTypeId*/, setDefaultIdTypeId] = useState()
//   const [listBankAccounts, setlistBankAccounts] = useState<any>([{ key: "0", text: "חדש" }])
//   const [selectedBank, setSelectedBank] = useState<any>()
//   const [newHolderObj, setnewHolderObj] = useState(initialHolder)

//   const dragOptions = {
//     moveMenuItemText: 'Move',
//     closeMenuItemText: 'Close',
//     menu: ContextualMenu,
//     keepInBounds: true,
//   };
// const modelProps = {
//     isBlocking: true,
//     topOffsetFixed: true,
//     dragOptions: dragOptions 
// };
// useEffect(()=>{
//   if(lang){
//       setDir(lang)
//   }
//   },[lang])

//   useEffect(() => {
//     if (enums) {
//       let arr = {
//         "branch": convertArrToDropdropdown([]),
//         "bank": convertArrToDropdropdown([]),
//         "countryCode": convertArrToDropdropdown([]),
//         "currencyCode": convertArrToDropdropdown([]),
//         "genders": convertArrToDropdropdown([]),
//         "typeIdentityNumbers": convertArrToDropdropdown([]),
//         "status": convertArrToDropdropdown([]),
//         "telephoneCountryCode": convertArrToDropdropdown([]),
//         "customersType": convertArrToDropdropdown([]),
//         "industryClassification": convertArrToDropdropdown([])
//       };
//       for (let i = 0; i < enums.length; i++) {
//         if (enums[i].branch !== undefined) {
//           let branch = enums[i].branch
//           for (let i = 0; i < branch.length; i++) {
//             if (branch[i].entity_sub_type_id !== undefined)
//               arr.branch = convertArrToDropdropdown(branch[i].entity_sub_type_id)
//           }

//         }
//         if (enums[i].customer !== undefined) {
//           let customer = enums[i].customer;
//           for (let j = 0; j < customer.length; j++) {
//             if (customer[j].gender_id !== undefined)
//               arr.genders = convertArrToDropdropdown(customer[j].gender_id)
//             if (customer[j].ID_type_id !== undefined)
//               arr.typeIdentityNumbers = convertArrToDropdropdown(customer[j].ID_type_id)
//             if (customer[j].status_id !== undefined)
//               arr.status = convertArrToDropdropdown(customer[j].status_id)
//             if (customer[j].customer_type_id !== undefined)
//               arr.customersType = convertArrToDropdropdown(customer[j].customer_type_id)
//           }
//         }
//         if (enums[i].bank_account !== undefined) {
//           let bank = enums[i].bank_account
//           for (let i = 0; i < bank.length; i++) {
//             if (bank[i].bank_code !== undefined)
//               arr.bank = convertArrToDropdropdown(bank[i].bank_code)
//           }
//         }
//         if (enums[i].general !== undefined) {
//           let country = enums[i].general
//           for (let i = 0; i < country.length; i++) {
//             if (country[i].country_code !== undefined)
//               arr.countryCode = convertArrToDropdropdown(country[i].country_code)
//             if (country[i].telephone_country_code !== undefined)
//               arr.telephoneCountryCode = convertArrToDropdropdown(country[i].telephone_country_code, "tel")
//             if (country[i].currency_code !== undefined)
//               arr.currencyCode = convertArrToDropdropdown(country[i].currency_code)
//           }
//         }

//       }
//       let defaultTelephoneValue: any
//       (defaultTelephoneValue = arr?.telephoneCountryCode.find(({ is_default }: any) => is_default === true))
//       let defaultCountryValue: any
//       (defaultCountryValue = arr?.countryCode.find(({ is_default }: any) => is_default === true))
//       let defaultTypeValue: any
//       (defaultTypeValue = arr?.typeIdentityNumbers.find(({ is_default }: any) => is_default === true))
//       setDefaultIdTypeId(defaultTypeValue?.key)
//       setnewHolderObj({
//         // ...newHolderObj, addressCountryCode: defaultCountryValue?.key, iDCountryCode: defaultCountryValue?.key, telephoneCountryCode: defaultTelephoneValue?.key, idTypeId: defaultTypeValue?.key,
//         ...newHolderObj, addressCountryCode: defaultCountryValue?.key, telephoneCountryCode: defaultTelephoneValue?.key, idTypeId: defaultTypeValue?.key,
//         idTypeCountryCode: defaultCountryValue?.key
//       })
//       setenumsDropdown(arr);
//     }
//   }, [enums])

//   useEffect(() => {
//     if (newHolder) {
//       setnewHolderObj(newHolder)
//       setisNeedNewHolder(false)
//       let listBank: any[] = [{ key: "0", text: "חדש" }]
//       newHolder?.bankAccounts?.forEach((bank: any) => {
//         !bank?.is_deleted &&
//           listBank.push({ key: bank.bank_account_id, text: bank.bank_name + ' ' + bank.bank_account_number })
//       })
//       setlistBankAccounts(listBank)
//     }
//   }, [newHolder])
//   useEffect(() => {
//     if (setShowDialog) {
//       toggleHideDialog()
//     }
//   }, [setShowDialog])
//   const changeUser = (key: string, value: any) => {
//     setnewHolderObj({ ...newHolderObj, [key]: value })
//   }
//   const check = (currentValue: string) => updateBankAccount[currentValue]?.length > 0;

//   const createHolder = async () => {
//     // if (validId) {
//     if (isNeedNewHolder) {
//       let requiredFields: any = ["accountNumber", "bank", "branch", "country", "branchAddress"]
//       if(updateBankAccount.country!=='IL'){
//          requiredFields= ["accountNumber", "bankName", "branchName", "country", "branchAddress"]
//       }
//       if (requiredFields.every(check)) {
//         if (newHolderObj?.firstName && newHolderObj.lastName && 
//           newHolderObj?.idNumber
//           // &&newHolderObj?.addressCityName
//           // &&newHolderObj?.addressNumber &&newHolderObj?.addressName
//          ) {
//           toggleHideDialog(); setShowDialog(false);
//           await dispatch(createCustomerInConvertions(newHolderObj, userCredentials, setnewHolder, setidHolder, updateBankAccount, true, setidBankAccount, setUpdateBankAccount))
//         }
//         else {
//           setShowDialogFailed(true)
//           setShowRequiredFildes(true)
//           setMessageError(t('notificationOfRequiredFields'))
//         }
//       }
//       else {
//         setShowDialogFailed(true)
//         setShowRequiredFildes(true)
//         setMessageError(t('notificationOfRequiredFields'))
//       }
//     }
//     else {
//       if (!isEqual(newHolderObj, newHolder)) {
//         let entity: any = newHolderObj
//         await updateCustomerInfo(newHolderObj, userCredentials, entity?.idEntity)
//       }
//       if (selectedBank?.key === "0" || selectedBank?.key === undefined) {
//         await dispatch(createBankAccount(updateBankAccount, userCredentials, idHolder, undefined,
//            undefined, undefined, setidBankAccount, setUpdateBankAccount))
//       }
//       setShowDialog(false);
//       toggleHideDialog();
//     }
//     // }
//     // else {
//     //   setShowDialogFailed(true)
//     //   setMessageError(t('validFields'))
//     // }
//   }
//   const selectBank = (key: string, value: any) => {
//     let index = newHolder?.bankAccounts?.findIndex((bank: any) => bank.bank_account_id === value)
//     // setreadOnly(false)
//     if (index !== -1 && index !== undefined) {
//       let country = newHolder?.bankAccounts[index]?.country_code
//       let branchAddress = newHolder?.bankAccounts[index]?.bank_branch_address
//       let accountNumber = newHolder?.bankAccounts[index]?.bank_account_number
//       let bank = newHolder?.bankAccounts[index]?.bank_code
//       let branch = newHolder?.bankAccounts[index]?.bank_branch_code
//       let bankName = newHolder?.bankAccounts[index]?.bank_name
//       let branchName = newHolder?.bankAccounts[index]?.bank_branch_name
//       let routingNumber = newHolder?.bankAccounts[index]?.routing_number

//       setidBankAccount(newHolder?.bankAccounts[index].bank_account_id)
//       setSelectedBank({ key: newHolder?.bankAccounts[index].bank_account_id, text: newHolder?.bankAccounts[index]?.bank_name + ' ' + newHolder?.bankAccounts[index]?.bank_account_number })
//       setUpdateBankAccount({
//         ...updateBankAccount, "country": country, "branchAddress": branchAddress,"routingNumber":routingNumber,
//         "accountNumber": accountNumber, "bank": bank, "branch": branch, "bankName": bankName, "branchName": branchName
//       })
//     }
//     if (value === "0") {
//       setSelectedBank({ key: "0", text: "חדש" })
//     }

//   }

//   const cancelFunc = () => {
//     setShowDialog(false);
//     setnewHolderObj(initialHolder);
//     setnewHolder("");
//     setidHolder("")
//     toggleHideDialog();

//   }

//   return (
//     <>
//       <Dialog minWidth="xl" maxWidth="xl" modalProps={modelProps} hidden={hideDialog} onDismiss={cancelFunc} >
//         <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{t("detailsHolder")}</p>
//         <hr className="hr"></hr>
//         {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} subText={messageError} title={t('error')} setshowModalDelete={setShowDialogFailed} dialogType={"dialogMessage"} />}
//         <div className="wrapper-collateral" style={{ alignItems: "start", width: "100%" }}>
//           <div className={`warpper-fields-right-${dir}`} style={{ width: "100%", padding: 0 }}>
//             <div style={{ justifyContent: "space-between", display: "inline-flex",width:" 100%" }}>
//               <p className={`title-text details-holder-${dir}`} >{t("detailsHolder")}</p>
//               <DialogSearch setShowDialog={setsearch} setidEntity={setidEntity} setReadOnly={setreadOnly} autoFocus={true}
//                setnewBankAccount={setnewBankAccount} userCredentials={userCredentials} place={t('searchHolderCustomerDatabase')}
//               isSearchInsiders={false} setConversionCustomerId={setidHolder} setRelatedFactors={setnewHolder} /></div>
//             <hr className="hr"></hr>
//             <div className="wrapper-collateral">
//               <div className={`warpper-fields-right-${dir}`}>
//                 <CustomTextField requiredField={showRequiredFildes && !newHolderObj?.firstName ? true : false} readOnly={readOnly} required={true} value={newHolderObj?.firstName} label={t("firstName")} onChange={changeUser} id={'firstName'} />
//               </div><div className={`warpper-fields-left-${dir}`}>
//                 <CustomTextField requiredField={showRequiredFildes && !newHolderObj?.lastName ? true : false} readOnly={readOnly} required={true} value={newHolderObj?.lastName} label={t("lastName")} onChange={changeUser} id={'lastName'} />
//               </div> </div>
//             <CustomTextField requiredField={showRequiredFildes && !newHolderObj?.idNumber ? true : false} value={newHolderObj?.idNumber} readOnly={readOnly} required={true} label={t('identityNumber')} onChange={changeUser} id={'idNumber'} />
//             {/* <IdNumber entity={newHolderObj} setEntity={setnewHolderObj} readOnly={readOnly} setResultId={setValidId} resultId={validId} typeIdentityNumbers={enumsDropdown.typeIdentityNumbers} typeIdDefault={defaultIdTypeId} /> */}
//             <div className="wrapper-collateral">
//               <div className={`warpper-fields-right-${dir}`}>
//                 <CustomTextField value={newHolderObj?.telephone} readOnly={readOnly} label={t("phoneNumber")} onChange={changeUser} id={'telephone'} />
//               </div>
//               <div className={`warpper-fields-left-${dir}`}>
//                 <CustomDropdown readOnly={readOnly} otherInputId={''} hasOtherValue={false} options={enumsDropdown.telephoneCountryCode} label={t("areaCode")} onChange={changeUser} selectedKey={newHolderObj?.telephoneCountryCode} id={'telephoneCountryCode'} othertextInput={t('')} />
//               </div>
//             </div>
//             <Address setEntity={setnewHolderObj} entity={newHolderObj} 
//             classStyle={"Horizontal"} requiredFields={showRequiredFildes} />
//             {/* </div> */}
//             {/* <div className={`warpper-fields-left-${dir}`} style={{ marginLeft: "3%" }}> */}
//             <p className="title-text" >{t("bankDetails")}</p>
//             <hr className="hr"></hr>
//             {/* conversions={true} */}
//             <CustomDropdown label={t("holderAccount")} otherInputId={''} hasOtherValue={false} options={listBankAccounts} onChange={selectBank} selectedKey={selectedBank?.key} id={'selectedCardId'} othertextInput={t('')} />
//             <CustomDialogBankAccount showRequiredField={showRequiredFildes} notShowHolderButton={true} bankDetails={updateBankAccount} setbankDetails={setUpdateBankAccount} conversions={"HolderAccountDialogPage"} userCredentials={userCredentials} />

//           </div>
//         </div>
//         {/* </div> */}
//         <DialogFooter >
//           <DefaultButton onClick={cancelFunc} text={t("cancel")} />
//           <PrimaryButton onClick={() => { { createHolder(); } }} text={t("create")} />
//         </DialogFooter>
//       </Dialog>
//     </>
//   )

// }




import { useBoolean } from '@fluentui/react-hooks';
import {
  PrimaryButton, Dialog, ContextualMenu, DialogFooter
  , DefaultButton
} from 'office-ui-fabric-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomDropdown } from '../../Option';
import { CustomTextField } from '../../TextField';
import { createBankAccount } from "../../../../store/actions/bankActions"
import { createCustomerInConvertions, updateCustomerInfo } from '../../../../store/actions/customerActioin'
import { convertArrToDropdropdown } from '../../../../components/customerDetails/CustomerDetails'
import { useDispatch, useSelector } from "react-redux";
import { DialogMessages } from '../DialogMessages';
import { CustomDialogBankAccount } from '../Dialog';
import { Address } from '../../CustomHooks/Address'

import "../dialog.scss";
import DialogSearch from '../../../../components/global/DialogSearch';
import { isEqual } from 'lodash';
// import validator from 'validator';

const initialHolder = {
  gender: 1,
  otherGender: "",
  entityStatusId: 0,
  idTypeCountryCode: "",
  firstName: '',
  note: "",
  lastName: '',
  isLocked: false,
  isPopupMessage: false,
  dateBirth: "",
  genderOther: "",
  middleName: "",
  otherCustomerStatus: "",
  classId: 1,
  entitySubTypeId: 1,
  ViewNoteWhenPerformingAction: false,
  industryId: 0,
  CreditGroup: 0,
  idAffiliate: 0,
  idTypeId: "",
  idNumber: "",
  idTypeOther: "",
  addressCountryCode: "",
  iDCountryCode: "",
  addressState: "",
  idIdentifier: 0,
  address: "",
  addressName: "",
  addressNumber: "",
  addressCity: "",
  addressCityName: "",
  telephone: "",
  telephoneCountryCode: "",
  email: "",
  entityNumber: "",
  addressZipCode: "",
  customerTypeId: "",
  workerName: "",
  genderName: "",
  telephoneTypeId: 1,
  telephoneId: 0,
  addressTypeId: 1,
  emailTypeId: 1,
  emailId: 0,
  telephoneDefault: 1,
  addressDefault: 1,
  emailDefault: 1,
  addressId: 0,
  login_entity_number: '',
  login_ID: '',
  login_password: ''
}

export const HolderAccountDialog = (props: any) => {
  const { setShowDialog, userCredentials, setidHolder, setnewHolder, idHolder, setidBankAccount, setnewBankAccount, newHolder, updateBankAccount, setUpdateBankAccount, conversionCustomer } = props
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [t] = useTranslation();
  const [dir, setDir] = useState("")
  const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const dispatch = useDispatch()
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
  const [enumsDropdown, setenumsDropdown] = useState<any>({ "countryCode": [], "bank": [], "branch": [] })
  const [readOnly, setreadOnly] = useState(false)
  // const [showSpiner, setshowSpiner] = useState(false)
  const [/*search*/, setsearch] = useState(false)
  const [isNeedNewHolder, setisNeedNewHolder] = useState(true)
  const [showDialogFailed, setShowDialogFailed] = useState(false)
  const [showRequiredFildes, setShowRequiredFildes] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [/*idEntity*/, setidEntity] = useState('')
  const [/*defaultIdTypeId*/, setDefaultIdTypeId] = useState()
  const [listBankAccounts, setlistBankAccounts] = useState<any>([{ key: "0", text: "חדש" }])
  const [selectedBank, setSelectedBank] = useState<any>()
  const [newHolderObj, setnewHolderObj] = useState(initialHolder)
  const [checkedCorporation, setCheckedCorporation] = useState(false);
  const [bankInfo, setBankInfo] = useState<any>();

  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
  };
  const modelProps = {
    isBlocking: true,
    topOffsetFixed: true,
    dragOptions: dragOptions
  };
  useEffect(() => {
    if (lang) {
      setDir(lang)
    }
  }, [lang])

  useEffect(() => {
    setBankInfo(updateBankAccount)
  }, [updateBankAccount])

  useEffect(() => {
    if (enums) {
      let arr = {
        "branch": convertArrToDropdropdown([]),
        "bank": convertArrToDropdropdown([]),
        "countryCode": convertArrToDropdropdown([]),
        "currencyCode": convertArrToDropdropdown([]),
        "genders": convertArrToDropdropdown([]),
        "typeIdentityNumbers": convertArrToDropdropdown([]),
        "status": convertArrToDropdropdown([]),
        "telephoneCountryCode": convertArrToDropdropdown([]),
        "customersType": convertArrToDropdropdown([]),
        "industryClassification": convertArrToDropdropdown([])
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].branch !== undefined) {
          let branch = enums[i].branch
          for (let i = 0; i < branch.length; i++) {
            if (branch[i].entity_sub_type_id !== undefined)
              arr.branch = convertArrToDropdropdown(branch[i].entity_sub_type_id)
          }

        }
        if (enums[i].customer !== undefined) {
          let customer = enums[i].customer;
          for (let j = 0; j < customer.length; j++) {
            if (customer[j].gender_id !== undefined)
              arr.genders = convertArrToDropdropdown(customer[j].gender_id)
            if (customer[j].ID_type_id !== undefined)
              arr.typeIdentityNumbers = convertArrToDropdropdown(customer[j].ID_type_id)
            if (customer[j].status_id !== undefined)
              arr.status = convertArrToDropdropdown(customer[j].status_id)
            if (customer[j].customer_type_id !== undefined)
              arr.customersType = convertArrToDropdropdown(customer[j].customer_type_id)
          }
        }
        if (enums[i].bank_account !== undefined) {
          let bank = enums[i].bank_account
          for (let i = 0; i < bank.length; i++) {
            if (bank[i].bank_code !== undefined)
              arr.bank = convertArrToDropdropdown(bank[i].bank_code)
          }
        }
        if (enums[i].general !== undefined) {
          let country = enums[i].general
          for (let i = 0; i < country.length; i++) {
            if (country[i].country_code !== undefined)
              arr.countryCode = convertArrToDropdropdown(country[i].country_code)
            if (country[i].telephone_country_code !== undefined)
              arr.telephoneCountryCode = convertArrToDropdropdown(country[i].telephone_country_code, "tel")
            if (country[i].currency_code !== undefined)
              arr.currencyCode = convertArrToDropdropdown(country[i].currency_code)
          }
        }

      }
      let defaultTelephoneValue: any
      (defaultTelephoneValue = arr?.telephoneCountryCode.find(({ is_default }: any) => is_default === true))
      let defaultCountryValue: any
      (defaultCountryValue = arr?.countryCode.find(({ is_default }: any) => is_default === true))
      let defaultTypeValue: any
      (defaultTypeValue = arr?.typeIdentityNumbers.find(({ is_default }: any) => is_default === true))
      setDefaultIdTypeId(defaultTypeValue?.key)
      setnewHolderObj({
        // ...newHolderObj, addressCountryCode: defaultCountryValue?.key, iDCountryCode: defaultCountryValue?.key, telephoneCountryCode: defaultTelephoneValue?.key, idTypeId: defaultTypeValue?.key,
        ...newHolderObj, addressCountryCode: defaultCountryValue?.key, telephoneCountryCode: defaultTelephoneValue?.key, idTypeId: defaultTypeValue?.key,
        idTypeCountryCode: defaultCountryValue?.key
      })
      setenumsDropdown(arr);
    }
  }, [enums])

  useEffect(() => {
    if (newHolder) {
      setnewHolderObj(newHolder)
      setisNeedNewHolder(false)
      let listBank: any[] = [{ key: "0", text: "חדש" }]
      newHolder?.bankAccounts?.forEach((bank: any) => {
        !bank?.is_deleted &&
          listBank.push({ key: bank.bank_account_id, text: bank.bank_name + ' ' + bank.bank_account_number })
      })
      setlistBankAccounts(listBank)
    }
  }, [newHolder])
  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog()
    }
  }, [setShowDialog])
  const changeUser = (key: string, value: any) => {
    setnewHolderObj({ ...newHolderObj, [key]: value })
  }
  const check = (currentValue: string) => bankInfo[currentValue]?.length > 0;

  const createHolder = async () => {
    // if (validId) {
    if (isNeedNewHolder) {
      let requiredFields: any = [];
      console.log(newHolderObj, "000000v");

      if (newHolderObj.addressCountryCode !== 'IL') {
        console.log("iiiiiii", bankInfo.country);
        requiredFields = ["bankName", "branchAddress"]
      }
      else {
        console.log("88888");

        requiredFields = ["accountNumber", "bank", "branch", "country"]
      }
      debugger
      if (requiredFields.every(check)) {
        if (newHolderObj.addressCountryCode === 'IL') {
          if (newHolderObj?.firstName && newHolderObj.lastName
            && newHolderObj?.idNumber
            && newHolderObj?.addressCityName
            && newHolderObj?.addressNumber && newHolderObj?.addressName
          ) {
            toggleHideDialog();
            setShowDialog(true);

            await dispatch(createCustomerInConvertions(newHolderObj, userCredentials, setnewHolder, setidHolder, bankInfo, true, setidBankAccount, setUpdateBankAccount))
          }
          else {
            setShowDialogFailed(true)
            setShowRequiredFildes(true)
            setMessageError(t('notificationOfRequiredFields'))
          }
        }
        else {
          if (newHolderObj.lastName) {
            toggleHideDialog();
            setShowDialog(true);

            await dispatch(createCustomerInConvertions(newHolderObj, userCredentials, setnewHolder, setidHolder, bankInfo, true, setidBankAccount, setUpdateBankAccount))
          }
          else {
            setShowDialogFailed(true)
            setShowRequiredFildes(true)
            setMessageError(t('notificationOfRequiredFields'))
          }
        }
      }
      else {
        setShowDialogFailed(true)
        setShowRequiredFildes(true)
        setMessageError(t('notificationOfRequiredFields'))
      }
    }
    else {
      if (!isEqual(newHolderObj, newHolder)) {
        let entity: any = newHolderObj
        await updateCustomerInfo(newHolderObj, userCredentials, entity?.idEntity)
      }
      if (selectedBank?.key === "0" || selectedBank?.key === undefined) {
        debugger
        await dispatch(createBankAccount(bankInfo, userCredentials, idHolder, undefined,
          undefined, undefined, setidBankAccount, setUpdateBankAccount))
      }
      setShowDialog(false);
      toggleHideDialog();
    }
    // }
    // else {
    //   setShowDialogFailed(true)
    //   setMessageError(t('validFields'))
    // }
  }
  const selectBank = (key: string, value: any) => {
    let index = newHolder?.bankAccounts?.findIndex((bank: any) => bank.bank_account_id === value)
    // setreadOnly(false)
    if (index !== -1 && index !== undefined) {
      let country = newHolder?.bankAccounts[index]?.country_code
      let branchAddress = newHolder?.bankAccounts[index]?.bank_branch_address
      let accountNumber = newHolder?.bankAccounts[index]?.bank_account_number
      let bank = newHolder?.bankAccounts[index]?.bank_code
      let branch = newHolder?.bankAccounts[index]?.bank_branch_code
      let bankName = newHolder?.bankAccounts[index]?.bank_name
      let branchName = newHolder?.bankAccounts[index]?.bank_branch_name
      let routingNumber = newHolder?.bankAccounts[index]?.routing_number

      setidBankAccount(newHolder?.bankAccounts[index].bank_account_id)
      setSelectedBank({ key: newHolder?.bankAccounts[index].bank_account_id, text: newHolder?.bankAccounts[index]?.bank_name + ' ' + newHolder?.bankAccounts[index]?.bank_account_number })
      debugger
      setUpdateBankAccount({
        ...updateBankAccount, "country": country, "branchAddress": branchAddress, "routingNumber": routingNumber,
        "accountNumber": accountNumber, "bank": bank, "branch": branch, "bankName": bankName, "branchName": branchName
      })
    }
    if (value === "0") {
      setSelectedBank({ key: "0", text: "חדש" })
    }

  }

  const cancelFunc = () => {
    setShowDialog(false);
    setnewHolderObj(initialHolder);
    setnewHolder("");
    setidHolder("")
    toggleHideDialog();
    setBankInfo({
      key: '', country: '', accountNumber: '', bank: '', branch: '',
      bankName: '', branchAddress: '', branchName: '', routing: ''
    })
  }

  useEffect(() => {
    if (
      newHolderObj?.customerTypeId === "3" ||
      newHolderObj?.customerTypeId === "5" ||
      newHolderObj?.customerTypeId === "9" ||
      newHolderObj?.customerTypeId === "12" ||
      newHolderObj?.customerTypeId === "10"
    )
      setCheckedCorporation(true);
    else setCheckedCorporation(false);
  }, [newHolderObj]);

  console.log('updateBankAccount0000000000', updateBankAccount)
  return (
    <>
      <Dialog minWidth="xl" maxWidth="xl" modalProps={modelProps} hidden={hideDialog} onDismiss={cancelFunc} >
        <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{t("detailsHolder")}</p>
        <hr className="hr"></hr>
        {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} subText={messageError} title={t('error')} setshowModalDelete={setShowDialogFailed} dialogType={"dialogMessage"} />}
        <div className="wrapper-collateral" style={{ alignItems: "start", width: "100%" }}>
          <div className={`warpper-fields-right-${dir}`} style={{ width: "100%", padding: 0 }}>
            <div style={{ justifyContent: "space-between", display: "inline-flex", width: " 100%" }}>
              <p className={`title-text details-holder-${dir}`} >{t("detailsHolder")}</p>
              <DialogSearch setShowDialog={setsearch} setidEntity={setidEntity} setReadOnly={setreadOnly} autoFocus={true}
                setnewBankAccount={setnewBankAccount} userCredentials={userCredentials} place={t('searchHolderCustomerDatabase')}
                isSearchInsiders={false} setConversionCustomerId={setidHolder} setRelatedFactors={setnewHolder} /></div>
            <hr className="hr"></hr>

            <div
              className={`div-customer-details-${dir}`}
            >
              <CustomDropdown
                selectedKey={newHolderObj.customerTypeId}
                otherInputId={""}
                hasOtherValue={false}
                options={enumsDropdown?.customersType}
                label={t("customerType")}
                onChange={changeUser}
                id={"customerTypeId"}
                othertextInput={t("")}
              />
            </div>
            {checkedCorporation ?
              <CustomTextField autoFocus={true} readOnly={false} value={newHolderObj?.lastName}
                label={"שם תאגיד"} onChange={changeUser} id={'lastName'}
                requiredField={showRequiredFildes && !newHolderObj?.lastName ? true : false}
              />
              :
              <div className="wrapper-collateral">
                <div className={`warpper-fields-right-${dir}`}>
                  <CustomTextField requiredField={showRequiredFildes && !newHolderObj?.firstName ? true : false} readOnly={readOnly} required={true} value={newHolderObj?.firstName} label={t("firstName")} onChange={changeUser} id={'firstName'} />
                </div>
                <div className={`warpper-fields-left-${dir}`}>
                  <CustomTextField requiredField={showRequiredFildes && !newHolderObj?.lastName ? true : false} readOnly={readOnly} required={true} value={newHolderObj?.lastName} label={t("lastName")} onChange={changeUser} id={'lastName'} />
                </div>
              </div>
            }
            <CustomTextField requiredField={showRequiredFildes && !newHolderObj?.idNumber ? true : false} value={newHolderObj?.idNumber} readOnly={readOnly} required={true} label={t('identityNumber')} onChange={changeUser} id={'idNumber'} />
            {/* <IdNumber entity={newHolderObj} setEntity={setnewHolderObj} readOnly={readOnly} setResultId={setValidId} resultId={validId} typeIdentityNumbers={enumsDropdown.typeIdentityNumbers} typeIdDefault={defaultIdTypeId} /> */}
            <div className="wrapper-collateral">
              <div className={`warpper-fields-right-${dir}`}>
                <CustomTextField value={newHolderObj?.telephone} readOnly={readOnly} label={t("phoneNumber")} onChange={changeUser} id={'telephone'} />
              </div>
              <div className={`warpper-fields-left-${dir}`}>
                <CustomDropdown readOnly={readOnly} otherInputId={''} hasOtherValue={false} options={enumsDropdown.telephoneCountryCode} label={t("areaCode")} onChange={changeUser} selectedKey={newHolderObj?.telephoneCountryCode} id={'telephoneCountryCode'} othertextInput={t('')} />
              </div>
            </div>
            <Address setEntity={setnewHolderObj} entity={newHolderObj}
              classStyle={"Horizontal"} requiredFields={showRequiredFildes} />
            {/* </div> */}
            {/* <div className={`warpper-fields-left-${dir}`} style={{ marginLeft: "3%" }}> */}
            <p className="title-text" >{t("bankDetails")}</p>
            <hr className="hr"></hr>
            {/* conversions={true} */}
            <CustomDropdown label={t("holderAccount")} otherInputId={''} hasOtherValue={false} options={listBankAccounts} onChange={selectBank} selectedKey={selectedBank?.key} id={'selectedCardId'} othertextInput={t('')} />
            <CustomDialogBankAccount showRequiredField={showRequiredFildes} notShowHolderButton={true} bankDetails={bankInfo} setbankDetails={setBankInfo} conversions={"HolderAccountDialogPage"} userCredentials={userCredentials} />

          </div>
        </div>
        {/* </div> */}
        <DialogFooter >
          <DefaultButton onClick={cancelFunc} text={t("cancel")} />
          <PrimaryButton onClick={() => { { createHolder(); } }} text={t("create")} />
        </DialogFooter>
      </Dialog>
    </>
  )

}