import axios from "axios";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { basicUrl } from "../../shared/config";
import { SET_CHEQUE_MANAGMENT_EVENT_DATA} from './types'

export const managementCheckData = (userCredentials: IUserCredentials | null, setSearch_fields: any, setRowData: any, pageIndex: any,

    search_filter?: any, numRecord?: any, setServerMessageError?: any, setMessageError?: any, setDialogTitle?: any) => async (dispatch: Function) => {
        const filter = {
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "id_client": userCredentials && userCredentials["idClient"],
             "id_branch": userCredentials && userCredentials["idBranch"],
            "cheques_request_method": "search",
            "search_fields": search_filter ? search_filter : [],
            "page_size": numRecord ? numRecord : 10,
            "page_index": pageIndex
        }
debugger
let token = sessionStorage.getItem("token")
console.log(token,"token-------");
        const config = {

            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };
        console.log(JSON.stringify(filter),"bodyyyy");
        
        const body = JSON.stringify(filter)
        console.log(body,"body");
        
        await axios.post(basicUrl + '/cheques', body, config)
            .then(res => {
                try {
                    debugger
                    if (res.status === 200) {
                        if (res.data["err_code"] === 0) {
                            setRowData(res?.data)
                            console.log(res?.data,"res?.datares?.data");
                            setSearch_fields && setSearch_fields(res?.data?.search_fields)
                        }
                        else {
                            setServerMessageError(true)
                            setMessageError(res.data?.ui_message ? res.data?.ui_message?.ui_message : res.data?.err_description)
                            setDialogTitle(res.data?.ui_message ? res.data?.ui_message?.ui_message_header : "שגיאה")
                            console.log(res.data?.err_description)
                        }
                    }
                }
                catch (err) {
                    console.log('error in getDataAfterFilter', err)
                }
            })
    }

function t(arg0: string): any {
    throw new Error("Function not implemented.");
}

export const chequeEvent = (userCredentials: IUserCredentials | null,typeRequestMethod:string,
      chosenAccount: any, chosenAction: any, cheques: any, setServerMessageError: any, setServerMessageErrorServer:any,
       setMessageError: any, setDialogTitle: any, t?: any) => async (dispatch: Function) => {
        debugger
        const event: any = {
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "id_client": userCredentials && userCredentials["idClient"],
            "id_branch": userCredentials && userCredentials["idBranch"],
            "cheques_request_method": typeRequestMethod,
            "cheque_event_id":chosenAction?.id,
            "cheques": cheques
        }
        if (chosenAction.is_contra === true&&chosenAction?.id!==99) {
            event["contra_id_entity"] = chosenAccount?.idEntity
            event[ "contra_id_account"] = chosenAccount?.idAccount
        }
        if (chosenAction.status) {
            event["status_id"] = chosenAction?.status
        }
        if (chosenAction.accepted_amount) {
            event["accepted_amount"] = chosenAction?.accepted_amount
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };
        const body = JSON.stringify(event)
        await axios.post(basicUrl + '/cheques', body, config)
            .then(res => {
                debugger
                try {
                    if (res.status === 200) {
                        debugger
                        if (res.data["err_code"] === 0) {
                            dispatch({
                                type:SET_CHEQUE_MANAGMENT_EVENT_DATA,
                                payload: res?.data
                            })
                        }
                        else {
                            setServerMessageErrorServer && setServerMessageErrorServer(true)
                            setMessageError && setMessageError(res.data?.ui_message?.ui_message)
                            setDialogTitle && setDialogTitle(res.data?.ui_message?.ui_message_header)
                            console.log(res.data?.err_description)
                        }
                    }
                }
                catch (err) {
                    setServerMessageError && setServerMessageError(true)
                    setMessageError && setMessageError(t("errorPerformingActionMassege"))
                    setDialogTitle && setDialogTitle(t("error"))
                }
            })
    }

