import '../scssPages/base.scss';
import HeaderClient from './HeaderClient/HeaderClient';
import SidebarClient from './SideBarClient/SideBarClient';

const HomeClient = () => {
    return (
        <>
            <div className="grid-container">
                <HeaderClient />
                <SidebarClient />
                {/* <Route path="/" exact component={CustomerDetails}/> */}
            </div>
        </>
    )
}

export default HomeClient;