import HeaderUtils from '../shared/components/headerUtils/HeaderUtils'
import Subtitle from "../shared/components/Subtitle";
import { useTranslation } from 'react-i18next'
import './branchSettings.scss'
import { CustomTextField } from '../shared/components/TextField';
import { CustomDropdown } from '../shared/components/Option';
import { DefaultButton } from 'office-ui-fabric-react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
const BranchSettingsC = () => {
    const [t] = useTranslation();
    const [dir,setDir]=useState("")
    const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)
    
    useEffect(()=>{
        if(lang){
            setDir(lang)
        }
        },[lang])
    return (
        <>
            <HeaderUtils title={"settings"}  subTitle={"false"} createBtn={false} editBtn={true} deleteBtn={false} uploadBtn={true} />
            <hr className={`hr hr-user-client-${dir}`}></hr>
            <div className={`content-wrapper-${dir}`}  >
                <Subtitle title={t('branchSettings')} />
                <hr className="hr title-text"></hr>
                <div style={{width:"74%"}}>
                    <div className="titleB">
                    <p className="title-text">{t("name")}</p>
                    <p className={`title-text detailsB-${dir}`}>{t("details")}</p></div>
                </div>
                <hr className="hr" style={{ width: "74%",height:"2px" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{"מספר עוסק/מוסד :"}</p>
                    <div style={{width:"40%"}}>
                    <CustomTextField label={" "} value={'454513900364'} onChange={' '} id={"detailsBranch"} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{t('businessType')}:</p>
                    <div style={{width:"40%"}}>
                    <CustomDropdown otherInputId={''} hasOtherValue={false} options={[]} label={t('')} onChange={''} selectedKey={''} id={'type'} othertextInput={t('')} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{t("branchName")} :</p>
                    <div style={{width:"40%"}}>
                    <CustomTextField label={" "} value={''} onChange={(' ')} id={"detailsBranch"} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{t("branchName")} :</p>
                    <div style={{width:"40%"}}>
                    <CustomTextField label={" "} value={''} onChange={''} id={"detailsBranch"} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{"מיקוד :"}</p>
                    <div style={{width:"40%"}}>
                    <CustomTextField label={" "} value={' '} onChange={' '} id={"detailsBranch"} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{t("phone")}:</p>
                    <div style={{width:"40%"}}>
                    <CustomTextField label={" "} value={''} onChange={''} id={"detailsBranch"} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{"פקס :"}</p>
                    <div style={{width:"40%"}}>
                    <CustomTextField label={" "} value={''} onChange={''} id={"detailsBranch"} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{"מייל :"}</p>
                    <div style={{width:"40%"}}>
                    <CustomTextField label={" "} value={''} onChange={''} id={"detailsBranch"} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{"שיעור מעמ :"}</p>
                    <div style={{width:"40%"}}>
                    <CustomTextField label={" "} value={''} onChange={''} id={"detailsBranch"} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{"דיווח חודשי/דו חודשי :"}</p>
                    <div style={{width:"40%"}}>
                    <CustomDropdown otherInputId={''} hasOtherValue={false} options={[]} label={t('')} onChange={''} selectedKey={''} id={'type'} othertextInput={t('')} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold"> {t("roundingInDocuments")}: </p>
                    <div style={{width:"40%"}}>
                    <CustomTextField label={" "} value={' '} onChange={' '} id={"detailsBranch"} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{"תמונת לוגו"}</p>
                    <div style={{width:"40%"}}>
                    <div className={`logoB-${dir}`}>
                            <div className="showImage"></div>
                            <div className="buttonFile">
                                <DefaultButton type="file" text={"בחר קובץ תמונה"} name={' '} /></div></div></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{t('note1')}</p>
                    <div style={{width:"40%"}}>
                    <CustomTextField label={" "} value={' '} onChange={' '} id={"detailsBranch"} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{t('note2')}</p>
                    <div style={{width:"40%"}}>
                    <CustomTextField label={" "} value={' '} onChange={' '} id={"detailsBranch"} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
                <div style={{width:"74%"}}>
                    <div className="inlineB">
                    <p className="bold">{t('impaCode')}</p>
                    <div style={{width:"40%"}}>
                    <CustomTextField label={" "} value={' '} onChange={' '} id={"detailsBranch"} /></div></div>
                </div>
                <hr className="hr" style={{ width: "74%" }} ></hr>
        </div>
            {/* <div className="wrap-table">
                <CustemTable tableName="branchSettings" renderItemColumn columns={columns} allItems={items}   addCustem={true} isFooter={true} />
            </div> */}
        </>
    )
}

export default BranchSettingsC
