import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import { isEqual } from 'lodash';


import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils';
import { CustomDialogCollateral } from './CustomDialogCollateral';
import { CustomTextField } from '../../shared/components/TextField';
import { IUserCredentials } from '../customerDetails/CustomerDetails';
import { updateCreditFacilities } from '../../store/actions/collateralAction'
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import CustemTable from '../../shared/components/tabels/tableList';
import Subtitle from '../../shared/components/Subtitle';
import "./collateral.scss";
import "../../scssPages/form.scss";
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions';


const Collateral = () => {
    const dispatch = useDispatch()
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const userData = useSelector(({ customerReducer }: any) => customerReducer.dataCustomer)
    const [idEntity, setidEntity] = useState("")
    const [sumValues, setsumValues] = useState(0)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [readOnly, setreadOnly] = useState(true)
    const [showDialogSave, setShowDialogSave] = useState(false)
    const [showSpiner, setshowSpiner] = useState(false)
    const [itemsCollateralCustomer, setItemsCollateralCustomer] = useState([{}])
    const [addCollateral, setaddCollateral] = useState(false)
    const [ShowDialogUpdate, setShowDialogUpdate] = useState(false)
    const [creditFacilities, setCreditFacilities] = useState(userData?.credit_line ? userData?.credit_line : { cheques_3party: 0, cheques_self: 0, loans: 0, factoring: 0 })
    const [creditFacilitiesBeforeChanges, setcreditFacilitiesBeforeChanges] = useState(userData?.credit_line ? userData?.credit_line : { cheques_3party: 0, cheques_self: 0, loans: 0, factoring: 0 })
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    const columnsCollateralCustomer = [
        { key: '1', name: t('name'), fieldName: "name", entity_name: "name", minWidth: 100, maxWidth: 100 },
        { key: '2', name: t('type'), fieldName: "type", entity_name: "typeN", minWidth: 130, maxWidth: 130 },
        { key: '3', name: t('status'), fieldName: "status", entity_name: "status", minWidth: 60, maxWidth: 60 },
        { key: '4', name: t('dateAdded'), fieldName: "dateAdded", entity_name: "dateAdded", minWidth: 100, maxWidth: 100 },
        { key: '5', name: t('expirationDate'), fieldName: "expirationDate", entity_name: "expirationDate", minWidth: 100, maxWidth: 100 },
        { key: '6', name: t('faceValue'), fieldName: "faceValue", entity_name: "faceValue", minWidth: 90, maxWidth: 90 },
        { key: '7', name: t('value'), fieldName: "value", entity_name: "value", minWidth: 90, maxWidth: 90 },
        { key: '8', name: t('note'), fieldName: "note", entity_name: "note", minWidth: 80, maxWidth: 100 },
        { key: '9', name: t('operations'), fieldName: "operations", entity_name: "operations", minWidth: 90, maxWidth: 90 },
    ];

    const spinnerStyles: Partial<ISpinnerStyles> = {
        root: {
            position: 'fixed',
            zIndex: '999999',
            fontWeight: 'bold',
        },
        circle: {
            color: '#FFB400 ',
            height: '100px',
            width: '100px',
        },
    }

    useEffect(() => {
        setUserCredentials({
            "idInitiator": authReducer.data.user.id_entity,
            "idClient": authReducer.data.user.id_client,
            "idBranch": authReducer.data.branch.id_entity,
            "idClientData": authReducer.data.client.id_client
        })
    }, [authReducer]);

    useEffect(() => {
        setidEntity(userData?.id_entity)
        userData?.credit_line && setCreditFacilities(userData?.credit_line)
        collateralData()
        setcreditFacilitiesBeforeChanges(creditFacilities)
        let a = String((creditFacilities?.cheques_3party)).replace(/\D/g, '')
        let b = String((creditFacilities?.cheques_self)).replace(/\D/g, '')
        let c = String((creditFacilities?.loans)).replace(/\D/g, '')
        let d = String((creditFacilities?.factoring)).replace(/\D/g, '')
        let sum: any = Number(a) + Number(b) + Number(c) + Number(d)
        sum = sum.toLocaleString()
        setsumValues(sum)
    }, [userData])

    const collateralData = () => {
        let arrCollateral: object[] = []
        userData?.collateral?.forEach((c: any, index: number) => {
            c.is_deleted === false && arrCollateral.push({
                key: c.collateral_id, number: c.collateral_ID_number, name: c.collateral_name,
                type: c.type_name, typeId: c.type_id, status: c.status_name, statusId: c.status_id,
                dateAdded: c.date_created,
                expirationDate: c.exp_date,
                faceValue: c.face_value.toLocaleString(),
                value: c.valuation.toLocaleString(), note: c.details ? c.details : t("DoesNotExist"),
                collateralMedia: c.collateral_media
            })
        });
        setItemsCollateralCustomer(arrCollateral)
    }

    const changeCreditFacilities = (key: string, value: any) => {
        let a = String((creditFacilities?.cheques_3party)).replace(/\D/g, '')
        let b = String((creditFacilities?.cheques_self)).replace(/\D/g, '')
        let c = String((creditFacilities?.loans)).replace(/\D/g, '')
        let d = String((creditFacilities?.factoring)).replace(/\D/g, '')
        if (key === "loans")
            c = value.replace(/\D/g, '')
        if (key === "cheques_3party")
            a = value.replace(/\D/g, '')
        if (key === "cheques_self")
            b = value.replace(/\D/g, '')
        if (key === "factoring")
            d = value.replace(/\D/g, '')
        value = value.replace(/\D/g, '');
        value = Number(value)
        value = value.toLocaleString()
        let sum: any = Number(a) + Number(b) + Number(c) + Number(d)
        sum = sum.toLocaleString()
        setsumValues(sum)
        setCreditFacilities({ ...creditFacilities, [key]: value })
    }

    const saveProgram = async (statusProgram: boolean) => {
        if (userCredentials !== null && !isEqual(creditFacilities, userData?.credit_line)) {
            setshowSpiner(true)
            statusProgram &&
                dispatch(updateCreditFacilities(creditFacilities, userCredentials, idEntity, setShowDialogSave, setreadOnly, setshowSpiner))
        }
        else
            setreadOnly(true)
    }

    const cancelEdit = async () => {
        creditFacilities.cheques_3party = creditFacilitiesBeforeChanges.cheques_3party
        creditFacilities.cheques_self = creditFacilitiesBeforeChanges.cheques_self
        creditFacilities.loans = creditFacilitiesBeforeChanges.loans
        creditFacilities.factoring = creditFacilitiesBeforeChanges.factoring
        let a = String((creditFacilities?.cheques_3party)).replace(/\D/g, '')
        let b = String((creditFacilities?.cheques_self)).replace(/\D/g, '')
        let c = String((creditFacilities?.loans)).replace(/\D/g, '')
        let d = String((creditFacilities?.factoring)).replace(/\D/g, '')
        let sum: any = Number(a) + Number(b) + Number(c) + Number(d)
        sum = sum.toLocaleString()
        setsumValues(sum)
        setreadOnly(true)
    }


    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
        ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={"customers"} createBtn={false} editBtn={readOnly ? true : false} saveFunc={() => saveProgram(true)} deleteBtn={false} uploadBtn={readOnly ? false : true} editFunc={() => setreadOnly(false)} cancelFunc={() => cancelEdit()} disabledSave={readOnly ? true : false} />
            <br></br><br></br>
            {showDialogSave && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"save"} />}
            {ShowDialogUpdate && <DialogMessages setansDelete={setShowDialogUpdate} setshowModalDelete={setShowDialogUpdate} dialogType={"update"} />}
            {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
            <Subtitle title={t('obligo')} />
            <div className={`content-div-${dir}`} >
                <p className="title-text" >{t('customerCreditFacilities')}</p>
                <hr className="hr" style={{ width: "20.2%" }} ></hr>
                <div style={{ width: "50%" }}>
                    {/* NOT DELETE  50% "25%""35%"*/}
                    <div className="titleC">
                        <p className="title-text boldC" style={{ width: "15%", marginTop: "0" }}>{t("type")}</p>
                        <p className="title-text boldC" style={{ width: "77%", marginTop: "0" }}>{t("creditFacilities")}</p>
                        {/* NOT DELETE */}
                        {/* <p className="title-text boldC" style={{ width: "35%" }}>{"אשראי בפועל"}</p>*/}</div>
                </div>
                <hr className="hr" style={{ width: "20.2%", height: "3px", marginBottom: '0' }} ></hr>
                <div style={{ width: "20%" }}>
                    {/*NOT DELETE //50% */}
                    <div className="inlineB">
                        <p className="boldPP" style={{ width: "55%" }}>{t("thirdPartyChecks")}</p>
                        <div style={{ width: "42%", marginTop: "2px", marginBottom: "11px" }}>
                            <CustomTextField label={" "} value={readOnly ? Number(creditFacilities?.cheques_3party).toLocaleString() : creditFacilities?.cheques_3party} readOnly={readOnly} onChange={changeCreditFacilities} id={"cheques_3party"} /></div>
                        {/* NOT DELETE */}
                        {/* <div style={{ width: "35%" }}>
                            <CustomTextField label={" "} value={'45'} readOnly={true} onChange={' '} id={"cheques_3party"} /></div>*/}</div>
                </div>
                <hr className="hr" style={{ width: "20.2%", marginBottom: '0' }} ></hr>
                <div style={{ width: "20%" }}>
                    {/*NOT DELETE //50% */}
                    <div className="inlineB">
                        <p className="boldPP" style={{ width: "55%" }}>{t("self_checks")}</p>
                        <div style={{ width: "42%", marginTop: "2px", marginBottom: "11px" }}>
                            <CustomTextField label={" "} value={readOnly ? Number(creditFacilities?.cheques_self).toLocaleString() : creditFacilities?.cheques_self} readOnly={readOnly} onChange={changeCreditFacilities} id={"cheques_self"} /></div>
                        {/* NOT DELETE */}
                        {/* <div style={{ width: "35%" }}>
                            <CustomTextField label={" "} value={'45'} onChange={' '} readOnly={true} id={"cheques_self"} /></div> */}
                    </div>
                </div>
                <hr className="hr" style={{ width: "20.2%", marginBottom: '0' }} ></hr>
                <div style={{ width: "20%" }}>
                    <div className="inlineB">
                        <p className="boldPP" style={{ width: "55%" }}>{t("loans")}</p>
                        <div style={{ width: "42%", marginTop: "2px", marginBottom: "11px" }}>
                            <CustomTextField label={" "} value={readOnly ? Number(creditFacilities?.loans).toLocaleString() : creditFacilities?.loans} readOnly={readOnly} onChange={changeCreditFacilities} id={"loans"} /></div>
                        {/* NOT DELETE */}
                        {/* <div style={{ width: "35%" }}>
                            <CustomTextField label={" "} value={'45'} onChange={' '} readOnly={true} id={"loans"} /></div>*/}</div>
                </div>
                <hr className="hr" style={{ width: "20.2%", marginBottom: '0' }} ></hr>
                <div style={{ width: "20%" }}>
                    {/*NOT DELETE //50% */}
                    <div className="inlineB">
                        <p className="boldPP" style={{ width: "55%" }}>{t("factoring")}</p>
                        <div style={{ width: "42%", marginTop: "2px", marginBottom: "11px" }}>
                            <CustomTextField label={" "} value={readOnly ? Number(creditFacilities.factoring).toLocaleString() : creditFacilities.factoring} readOnly={readOnly} onChange={changeCreditFacilities} id={"factoring"} /></div>
                        {/* NOT DELETE */}
                        {/* <div style={{ width: "35%" }}>
                            <CustomTextField label={" "} value={'45'} onChange={' '} readOnly={true} id={"factoring"} /></div> */}</div>
                </div>
                <hr className="hr" style={{ width: "20.2%", marginBottom: '0' }} ></hr>
                <div style={{ width: "20%" }}>
                    {/*NOT DELETE //50% */}
                    <div className="inlineB">
                        <p className="boldPP" style={{ width: "55%" }}>{t('total')}</p>
                        <div style={{ width: "42%", marginTop: "2px", marginBottom: "11px" }}>
                            <CustomTextField label={" "} readOnly={true} value={sumValues} onChange={' '} id={"detailsBranch"} /></div>
                        {/* NOT DELETE */}
                        {/* <div style={{ width: "35%" }}>
                            <CustomTextField label={" "} readOnly={true} value={'45'} onChange={' '} id={"detailsBranch"} /></div>*/}</div>
                </div>
                <div className={`attechedCl attached-collateral-${dir}`}>
                    <p className="title-text" style={{ width: "1000px" }} >{t('customerCollateral')}</p>
                    <div className="title-text addFile" onClick={() => setaddCollateral(true)} ><i className="fal fa-plus"></i></div>
                    {addCollateral && <CustomDialogCollateral setshowSpiner={setshowSpiner}
                        userCredentials={userCredentials} idEntity={idEntity} setShowDialogUpdate={setShowDialogUpdate}
                        setShowDialog={setaddCollateral} />}
                </div>
                <hr className="hr" style={{ width: "1000px" }} ></hr>
                <div style={{ width: "1000px" }}>
                    {itemsCollateralCustomer.length === 0 ?
                        <div style={{ textAlign: "center", marginTop: "18px", width: "90%" }}>{t('noDisplay')}</div>
                        : <CustemTable tableName="customerCollateral" setshowSpiner={setshowSpiner} columns={columnsCollateralCustomer}
                            allItems={itemsCollateralCustomer} renderItemColumn rederRow={"operations"} addCustem={true} isFooter={false} />}
                </div>
            </div>
        </div>

    );
}
export default Collateral;

