import { useState, useEffect, useRef } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import moment from "moment";
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import { useReactToPrint } from 'react-to-print';
import CurrencyInput from 'react-currency-input-field';
import { useHistory, useLocation } from "react-router-dom";
import { Prompt } from "react-router";

import { IUserCredentials } from '../../../customerDetails/CustomerDetails';
import { SAVE_DRAFT_CONVERSION_CHANGES } from '../../../../store/actions/types'
import { deleteTransaction, saveCurrencyEntry } from '../../../../store/actions/convertionActions'
import FinancialAssets from './FinancialAssets'
import HeaderUtils from '../../../../shared/components/headerUtils/HeaderUtils'
import Subtitle from "../../../../shared/components/Subtitle";
import { CustomerDate } from '../../../../shared/components/CustomHooks/concatTextFields'
import { CustomTextField } from "../../../../shared/components/TextField";
import { CustomDropdown } from "../../../../shared/components/Option";
import { CustomPrompt } from '../../../../shared/components/CustomHooks/CustomPrompt'
import { PrintHtml } from '../../../../shared/components/CustomHooks/PrintHtml'
import { cloneDeep } from "lodash";
import { DialogMessages } from "../../../../shared/components/dialog/DialogMessages";
import { checkPermissionLevelOfPage } from "../../../../shared/components/CustomHooks/GlobalFunctions";
const spinnerStyles: Partial<ISpinnerStyles> = {
    root: {
        position: 'fixed',
        zIndex: '999999',
        fontWeight: 'bold',
    },
    circle: {
        color: '#FFB400 ',
        height: '100px',
        width: '100px',
    },
}
const initialState = {
    date: moment().format("YYYY-MM-DD"), id_account: "", total_amount_ils: 0,
    hour: moment(new Date()).format('HH:mm')
    , id_transaction: null, transaction_document: { document_request_method: null },
    financial_assets_in: [{
        currency_code: "", financial_asset_code: 1,
        amount: 0, exchange_rate_ils: 1, amount_ils: 0,
        row_number: 1, contra_id_account: null
    }],
    financial_assets_out: [{
        currency_code: "", financial_asset_code: 1,
        amount: 0, exchange_rate_ils: 1, amount_ils: 0,
        row_number: 1, contra_id_account: null
    }],
    expenses: {
        details: "עמלת משיכה",
        amount: 0,
        currency_code: "ILS",
        exchange_rate_ils: 1,
        contra_id_account: null,
        amount_ils: 0,
        is_include_vat: "1"
    },
};

const CurrencyEntry = () => {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const saveDraft = useSelector(({ conversionReducer }: any) => conversionReducer.saveDraftChanges)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [vatClassificationId, setVatClassificationId] = useState<any>()
    let componentRef = useRef(null);
    const [contraAccounts, setContraAccounts] = useState<any>([])
    const [readOnly, setReadOnly] = useState(true)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [isValidDate, setIsValidDate] = useState<any>(true);
    const [contraBankAccounts, setcontraBankAccounts] = useState<any>([])
    const typeOfActions = [{ key: "1", text: t('depositingCashInBusiness') }, { key: "2", text: t('withdrawingCashFromBusiness') }]
    const [chooseAction, setChooseAction] = useState<any>("1")
    const [showSpiner, setshowSpiner] = useState(false)
    const [sum, setSum] = useState(0)
    const [html, setHtml] = useState("")
    const [idCurrencyEntry, setIdCurrencyEntry] = useState(null)
    const optionsIncludeVat = [{ key: "1", text: t("no") }, { key: "2", text: t("yes") }]
    const [currencyEntry, setCurrencyEntry] = useState<any>(initialState)
    const [messageError, setMessageError] = useState(t('noteYourExitCancelAction'))
    const [isExist, setIsExist] = useState(false)
    const [isExist2, setIsExist2] = useState<any>(false)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)

    const [serverMessageError, setServerMessageError] = useState('')
    const [exsistMassage, setExsistMassage] = useState<any>(false)

    useEffect(() => {
        if (contraAccounts) {
            let arrBank: any[] = [];
            for (let i = 0; i < contraAccounts.length; i++) {
                if (contraAccounts[i].contra_account_group_id === "bank_account") {
                    arrBank.push({ key: contraAccounts[i].id_account, text: contraAccounts[i].account_name + ' ' + contraAccounts[i].account_number, groupId: contraAccounts[i].contra_account_group_id, accountNumber: contraAccounts[i].account_number })
                }
            }
            setcontraBankAccounts(arrBank)
        }
    }, [contraAccounts])


    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer?.data?.user?.id_entity,
                "idClient": authReducer?.data?.user?.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer?.data?.client?.id_client
            })
            setContraAccounts(authReducer?.data?.user.contra_accounts)
            setVatClassificationId(authReducer?.data?.branch?.setting?.entity_vat_classification_id)
        }
    }, [authReducer]);

    useEffect(() => {
        if (idCurrencyEntry) {
            sessionStorage.setItem('id_transaction', JSON.stringify(idCurrencyEntry));
            setCurrencyEntry({ ...currencyEntry, ["id_transaction"]: idCurrencyEntry })
        }
    }, [idCurrencyEntry])

    useEffect(() => {
        if (html)
            document?.getElementById("print")?.click();
    }, [html])


    const deleteTransactionFunc = async () => {
        await dispatch(deleteTransaction("delete", "money_in", currencyEntry, userCredentials, userCredentials?.idBranch))
        history.push(isExist2)
    }

    useEffect(() => {
        if (exsistMassage === "save") {
            deleteTransactionFunc()
        }
        if (exsistMassage === "don't save") {
            setIsExist2(false)
        }
    }, [exsistMassage])

    const create = async (key: any, value: any) => {
        let objectCurrency: any = { ...currencyEntry }
        objectCurrency[key] = value
        objectCurrency.expenses.contra_id_account = value
        setCurrencyEntry(objectCurrency)
        if (!currencyEntry?.id_transaction) {
            await dispatch(saveCurrencyEntry("create", chooseAction === "1" ? "money_in" : "money_out", objectCurrency,
                userCredentials, setIdCurrencyEntry, setshowSpiner, "", setServerMessageError, setMessageError, t))
            setReadOnly(false)
        }

    }
    const change = async (key: any, value: any) => {
        setCurrencyEntry({ ...currencyEntry, [key]: value })
    }

    const changeAction = (key: any, value: any) => {
        setChooseAction(value)
    }

    const changeFee = (key: any, value: any) => {
        let arrExpenses: any = cloneDeep(currencyEntry.expenses)
        let obj = {
            details: arrExpenses.details,
            amount: key === "amount" ? value : arrExpenses.amount,
            amount_ils: key === "amount" ? value : arrExpenses.amount,
            currency_code: arrExpenses.currency_code,
            exchange_rate_ils: arrExpenses.exchange_rate_ils,
            contra_id_account: key === "contra_id_account" ? value : arrExpenses.contra_id_account,
            is_include_vat: key === "is_include_vat" ? value : arrExpenses.is_include_vat
        }
        setCurrencyEntry({ ...currencyEntry, expenses: obj })
    }

    const save = async () => {
        if (currencyEntry?.id_transaction) {
            let copyCurrencyEntry: any = cloneDeep(currencyEntry)
            copyCurrencyEntry.expenses.is_include_vat = currencyEntry.expenses.is_include_vat === "1" ? false : true
            setshowSpiner(true)
            await dispatch(saveCurrencyEntry("complete", chooseAction === "1" ? "money_in" : "money_out", copyCurrencyEntry, userCredentials, "",
                setshowSpiner, setHtml, setServerMessageError, setMessageError, t))
            setCurrencyEntry(initialState)
            setChooseAction("1")
            setSum(0)
            dispatch({
                type: SAVE_DRAFT_CONVERSION_CHANGES,
                payload: {}
            })
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,

    });


    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
        ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={t("bookkeeping")} subTitle={"false"} createBtn={false} editBtn={false} deleteBtn={false} disabledSave={true} uploadBtn={false} />
            <br></br><br></br>
            {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
            {serverMessageError && <DialogMessages setansDelete={setServerMessageError} setshowModalDelete={setServerMessageError} subText={messageError} title={t('error')} dialogType={"dialogMessage"} />}

            <Subtitle title={t("foreignCurrencyEntry")} />
            {isExist2 && <DialogMessages setansDelete={setIsExist} setshowModalDelete={setIsExist} subText={messageError} title={t('note')} dialogType={"dialogMessage"} setSaveDataConversion={setExsistMassage} />}

            {(saveDraft?.changeLocation !== undefined || isExist) && <CustomPrompt typeAction="money_in" action={currencyEntry} ifChanges={true} saveDraft={saveDraft} subText={t('noteYourExitCancelAction')} userCredentials={userCredentials} />}
            <div style={{ display: "none" }}>
                <div ref={componentRef} dangerouslySetInnerHTML={{ __html: html }} />
            </div>
            <p id='print' className="button" onClick={handlePrint}>
            </p>
            <div className={`content-div-${dir}`}>
                <p className="title-text" >{t("actionDetails")}</p>
                <hr className="hr" style={{ width: "42vw" }}></hr>
                <div className="wrapper-collateral" style={{ alignItems: "self-end" }}>
                    <div className={`warpper-fields-right-${dir}`} style={{ width: "20%" }}>
                        <div >
                            <label style={{ marginBottom: "4px",fontWeight: 600,padding: "5px 0px",fontSize:" 14px" }}>
                                {t("selectAction")} <span style={{ color: "rgb(164, 38, 44)" }}>*</span></label>
                            <CustomDropdown readOnly={false} othertextInput={'*'} otherInputId={''} hasOtherValue={false}
                                options={typeOfActions} onChange={changeAction} selectedKey={chooseAction} id={'typeAction'} label="" />
                        </div>
                        {contraBankAccounts?.length >= 0 && 
                         <div >
                         <label style={{ marginBottom: "4px",fontWeight: 600,padding: "5px 0px",fontSize:" 14px" }}>
                            {chooseAction === "1" ? t("fromAccount") : chooseAction === "2" ? t("toAccount") : t("account")} <span style={{ color: "rgb(164, 38, 44)" }}>*</span></label>
                         <CustomDropdown othertextInput={'*'} readOnly={false} otherInputId={''} hasOtherValue={false}
                          options={contraBankAccounts} label={""} onChange={create} selectedKey={currencyEntry?.id_account} id={'id_account'} />
                       </div>}
                        <CustomerDate readOnly={readOnly} setIsValidDate={setIsValidDate} showRequiredFildes={false} isValidDate={isValidDate} entity={currencyEntry} setEntity={setCurrencyEntry} isDefaultDate={true} idDate="date" label={t('date')} source={"pastDate"} />
                        <label style={{ fontWeight: 500, fontSize: "14px" }}>{t("fee")}</label>
                        <CurrencyInput id={"amount"} name={"amount"} value={currencyEntry?.expenses?.amount}
                            readOnly={readOnly} decimalsLimit={10} className={`padding-currency-${dir}`}
                            style={{
                                width: '100%', height: '32px', backgroundColor: readOnly ? "white" : "#FAF9F8",
                                borderWidth: "1px", borderStyle: "solid", outlineColor: "rgb(0, 120, 212)", marginTop: "5px"
                            }}
                            onValueChange={(value, name) => changeFee(name || "", value)}
                        />
                        {vatClassificationId === "2" &&
                            < CustomDropdown onKeyDown={(e: any) => e.key === "Tab"} readOnly={readOnly} otherInputId={''} hasOtherValue={false} options={optionsIncludeVat} label={t('expenditureIncludingVAT')}
                                onChange={changeFee} selectedKey={currencyEntry?.expenses?.is_include_vat} id={"is_include_vat"} othertextInput={t('')} />}
                    </div>
                    <div className={`warpper-fields-left-${dir}`} style={{ width: "10%" }}>
                        <CustomTextField readOnly={readOnly} label={t('hour')} value={currencyEntry?.hour} onChange={change} id={"hour"} />
                    </div>
                </div>
                {chooseAction === "1" ? <FinancialAssets tableName="financial_assets_in" sourcePage="currencyEntry" action={currencyEntry} setAction={setCurrencyEntry} readOnly={readOnly} sum={sum} setSum={setSum} />
                    : <FinancialAssets tableName="financial_assets_out" sourcePage="currencyEntry" action={currencyEntry} setAction={setCurrencyEntry} readOnly={readOnly} sum={sum} setSum={setSum} />}

                <div style={{ width: "42vw", justifyContent: "end", display: "inline-grid", marginTop: "4px" }}>
                    <PrintHtml saveFunction={save} typeAction={chooseAction === "1" ? "money_in" : "money_out"} action={currencyEntry} setAction={setCurrencyEntry} setHtml={setHtml} userCredentials={userCredentials} />
                    <div>
                        <Prompt when={idCurrencyEntry !== "" && saveDraft?.changeLocation !== true} message={
                            (location: any, action) => {
                                if (isExist2 === false) {
                                    setIsExist2(location.pathname)
                                    return false;
                                }
                                return true;
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrencyEntry