import React from "react";
import { Nav } from "@fluentui/react/lib/Nav";
import "../../scssPages/sidebar.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { INavStyles, INavLinkGroup, INavLink } from "@fluentui/react/lib/Nav";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import {  withRouter } from "react-router-dom";
import '../../scssPages/sidebar.scss'
import { useTranslation } from "react-i18next";
import { registerIcons } from "@fluentui/react";
import { faFilter } from '@fortawesome/free-solid-svg-icons';
const navStyles: Partial<INavStyles> = { root: { width: 150 } };
initializeIcons();

registerIcons({
  icons: {
    Filter: <FontAwesomeIcon icon={faFilter} />
  }
});

const ContentSideSettingsC = (props: any) => {
  const [t] = useTranslation();
  const handleNavClick = ( ev?: React.MouseEvent<HTMLElement>,item?: INavLink ) => {
    ev?.preventDefault();
    props.history.push(item?.url);
  };
  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
            key: "branch_settings",
            url: "/branch_setting",
            // component: "BranchSettings",
            iconProps: { iconName: "Attach" },
            name: t("branchSettings"),
            onClick: handleNavClick
          },
          // {
          //   key: "price-plan",
          //   url: "/price-plan",
          //   iconProps: { iconName: "Attach" },
          //   // component: "PricePlan",
          //   name: t("planPrice"),
          //   onClick: handleNavClick
          // },
          {
            key: "users",
            url: "/users",
            component: "Users",
            iconProps: { iconName: "Attach" },
            name: t("users"),
            onClick: handleNavClick
          },
      ]
    }
  ];
  
  return (
    <>
    
      <Nav className="items"  onLinkClick={handleNavClick} styles={navStyles} groups={navLinkGroups}  />
    </>
  );
};
export default withRouter(ContentSideSettingsC);
