import { useState, useEffect } from "react"
import { cloneDeep } from 'lodash';

import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils'
import Subtitle from "../../shared/components/Subtitle";
import { useTranslation } from 'react-i18next'
import CustemTable from '../../shared/components/tabels/tableList';
import EditablePricePlan from './EditablePricePlan'
import { useSelector, useDispatch } from 'react-redux'
import { convertArrToDropdropdown, IUserCredentials } from '../customerDetails/CustomerDetails'
import { DialogMessages } from "../../shared/components/dialog/DialogMessages";
import { createPricePlanTemplate } from "../../store/actions/PricePlanTemplateAction";
import { useLocation } from "react-router";
import { checkPermissionLevelOfPage } from "../../shared/components/CustomHooks/GlobalFunctions";


const PricePlan = () => {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [itemsPricePlan, setItemsPricePLan] = useState([{}])
    const [addPlan, setaddPlan] = useState(false)
    const [pricePlan, setPricePlan] = useState<any>()
    const [showDialog, setshowDialog] = useState(false)
    const [showDialogSave, setShowDialogSave] = useState(false)
    const [/*items*/, setitems] = useState([{}])
    const programEnums = useSelector(({ dataReducer }: any) => {
        for (let i = 0; i < dataReducer.enums.length; i++) {
            if (dataReducer.enums[i].price_program !== undefined)
                return dataReducer.enums[i].price_program[1]?.status_id
        }
    })
    const columns = [
        { key: '1', name: t('planName'), fieldName: "planName", entity_name: "planName", minWidth: 140, maxWidth: 140 },
        { key: '2', name: t('creationDate'), fieldName: "creationDate", entity_name: "creationDate", minWidth: 150, maxWidth: 150 },
        { key: '3', name: t('status'), fieldName: "status", entity_name: "status", minWidth: 60, maxWidth: 60 },
        { key: '4', name: t('default'), fieldName: "default", entity_name: "default", minWidth: 80, maxWidth: 80 },
        { key: '5', name: t('note'), fieldName: "note", entity_name: "note", minWidth: 100, maxWidth: 100 },
        { key: '6', name: t('edit'), fieldName: "editPricePlan", entity_name: "editPricePlan", minWidth: 90, maxWidth: 90 },
    ];

    useEffect(() => {
        authReducer?.data?.branch?.price_programs_templates &&
            PricePLanData()
        setUserCredentials({
            "idInitiator": authReducer?.data?.user?.id_entity,
            "idClient": authReducer?.data?.client?.id_entity,
            "idBranch": authReducer?.data?.branch?.id_entity,
            "idClientData": authReducer?.data?.client?.id_client
        })
    }, [authReducer])

    useEffect(() => {
        programEnums &&
            setitems(convertArrToDropdropdown(programEnums))

    }, [programEnums])

    const addPlanFunc = async () => {
        await dispatch(createPricePlanTemplate(pricePlan, userCredentials?.idInitiator, userCredentials?.idClient, userCredentials?.idBranch, setShowDialogSave, setPricePlan))
        setaddPlan(true)
    }
    const PricePLanData = () => {
        let arrPricePlan: object[] = []
        let priceProgramProperties: any = {
            foreign_cheque_deposit_fee: {}, cheque_refund_fee: {}, new_account_fee: {}, israeli_cheque_deposit_fee: {},
            repeat_deposit_fee: {}, wire_rtgs_fee: {}, wire_fee: {}, abroad_wire_fee: {},
            cheque_collection_fee: {}, markup_conversion_fee: {}, cheque_cashing_cash_rate: {},
            cheque_cash_fee: {}, cheque_cashing_rate: {},
            arrears_interest: {}
        }
        authReducer?.data?.branch?.price_programs_templates?.forEach((p: any) => {
            p?.price_program_properties?.forEach((property: any) => {
                property?.value_max !== undefined ?
                    priceProgramProperties[property.name] = { calculation_type: property?.calculation_type, value_max: Number(property?.value_max), value_min: Number(property?.value_min), percent: Number(property?.percent) }
                    : priceProgramProperties[property.name] = { calculation_type: property?.calculation_type, value: Number(property?.value) }
            })
            arrPricePlan.push({
                key: p.price_program_id, planName: p.price_program_name, note: p.note,
                default: p.is_default, status: p.status_id, creationDate: p.date_open,
                //d
                // (moment(p.date_open).format("DD/MM/YYYY")),
                idInitiator: p.id_initiator, properties: cloneDeep(priceProgramProperties)
            })
        });
        setItemsPricePLan(arrPricePlan)
    }

    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
        ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={"settings"} subTitle={"false"} editFunc={() => setshowDialog(true)} createBtn={false} editBtn={false} deleteBtn={false} disabledSave={true} uploadBtn={false} />
            <br></br><br></br>
            {showDialogSave && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"save"} />}
            <Subtitle title={t('planPrice')} />
            <div className={`content-div-${dir}`}>
                <div className="atach-form" style={{ width: "720px" }}>
                    <p className="title-text"style={{ width: "720px" }}>{t('pricePlanList')}</p>
                    {/* <p className="title-text addFile" onClick={() => addPlanFunc()} >{"(+)"}</p> */}
                    <div className="title-text addFile"  onClick={() =>  addPlanFunc()} ><i className="fal fa-plus"></i></div>
                    {addPlan ? <EditablePricePlan edit={true} itemUpdate={pricePlan} setShowDialog={setaddPlan} setShowDialogSave={setShowDialogSave} /> : ""}
                </div>
                <hr className="hr" style={{ width: "720px" }} ></hr>
            </div>
            <div className={`div-item-price-plan-${dir}`} >
                {itemsPricePlan.length === 0 ? <div style={{ textAlign: "center", marginTop: "18px", width: "80%" }}>{t('noDisplay')}</div> : <CustemTable tableName="PricePlan" columns={columns} allItems={itemsPricePlan} renderItemColumn rederRow={"editPricePlan"} addCustem={true} isFooter={false} />}
                {showDialog ? <EditablePricePlan edit={true} itemUpdate={pricePlan} setShowDialog={setshowDialog} setShowDialogSave={setShowDialogSave} /> : ''}</div>
        </div>
    )
}

export default PricePlan
