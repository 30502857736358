import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils'
import { IUserCredentials } from '../customerDetails/CustomerDetails'
import Subtitle from '../../shared/components/Subtitle';
import { accountsFilter } from '../../store/actions/bankActions';

import { CustomDropdown } from "../../shared/components/Option";
import TabsAccount from '../accounts/TabsAccount';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions';

const CustomerAccounts = () => {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const dataCustomer = useSelector(({ customerReducer }: any) => customerReducer.dataCustomer)
    const [listAccounts, setListAccounts] = useState<any>([])
    const [currencyList, setCrrencyList] = useState<any>()
    const [tab, setTab] = useState<number>(1)
    const [selectedAccount, setSelectedAccount] = useState("")
    const [customerAccounts, setCustomerAccounts] = useState<any>()
    const [actionData, setaAtionData] = useState<any>([]);
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()

    const arrTitle = [
        { key: t('contraAccount'), eng: 'contra_account_name' },
        { key: t('details'), eng: 'details_accounts' },
        { key: t('reference'), eng: 'reference_1' },
        { key: t('reference2'), eng: 'reference_2' },
        { key: t('ledger'), eng: 'journal_number' },
        { key: t('date'), eng: 'date_value' },
        { key: t('currency'), eng: 'currency_code' },
        { key: t('amount'), eng: 'amount' },
        { key: t('amountILS'), eng: 'amount_ils' },
    ]

    useEffect(() => {
        if (dataCustomer && listAccounts?.length === 0 && userCredentials) {
            let arrAcounts: any[] = []
            dataCustomer?.accounts?.forEach((element: any) => {
                arrAcounts.push({ key: element?.id_account, text: element?.account_number })
            });
            setListAccounts(arrAcounts)
            if (arrAcounts?.length === 1) {
                setSelectedAccount(arrAcounts[0].key)
                dispatch(accountsFilter(arrAcounts[0].key, dataCustomer?.id_entity, userCredentials, "search_ledger",
                    undefined, setCustomerAccounts, 1, [], 10
                ))
            }
        }
        // eslint-disable-next-line
    }, [dataCustomer, userCredentials, customerAccounts])

    useEffect(() => {
        if (authReducer)
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
    }, [authReducer])

    const showAccountMovements =async (value: any) => {
           setSelectedAccount(value)
        dispatch(accountsFilter(value, dataCustomer?.id_entity, userCredentials, "search_ledger",
            undefined, setCustomerAccounts, 1, [], 10
            // , setServerMessageError, setMessageError, setDialogTitle
        ))
    }

    const selectAccount = (key: string, value: any) => {
        setSelectedAccount(value)
        dispatch(accountsFilter(value, dataCustomer?.id_entity, userCredentials, "search_ledger",
            undefined, setCustomerAccounts, 1, [], 10
            // , setServerMessageError, setMessageError, setDialogTitle
        ))
    }
    const funcAction = async () => {
        await dispatch(accountsFilter(customerAccounts?.id_account, dataCustomer?.id_entity,
            userCredentials, "search_ledger", undefined, setaAtionData, 1, [], customerAccounts?.records_found
        ))
    }

    return (
     <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
        ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={"customers"} PrintTitle={arrTitle}
                actionData={actionData?.search_results}
                funcAction={funcAction} editBtn={false} uploadBtn={false}
                disabledSave={true} showSelectConvertions={true}
                createXl={true} createPrint={true} createPDF={false} />
            <br></br><br></br>
            <Subtitle title={t("customer_accounts")} />
            <div className={`content-div-${dir}`} >
                <div className="wrapper-collateralPlan">
                    <div className="warpper-fields-rightP" style={{ width: "fit-content" }} >
                        <div style={{ display: "flex", width: "1300px" }} >

                            <div style={{ display: "flex", justifyContent: "start", width: "800px" }}>
                                <label className="title-text">{t("account_movements")}</label></div>
                                
                            <div style={{ display: "flex", flexDirection: "column-reverse" }} >
                                {listAccounts?.length > 1 ?
                                    <div style={{ display: "flex", justifyContent: "end", width: "500px", zIndex: 1 }}>
                                        <label style={{ width: "190px" }}>{t('SelectAccounToDisplayTransactions')}</label>
                                        <div style={{ width: "110px" }}><CustomDropdown otherInputId={''} hasOtherValue={false} options={listAccounts} label={''} onChange={selectAccount}
                                            selectedKey={selectedAccount} id={'id_account'} othertextInput={t('')} /></div>
                                    </div>
                                    : listAccounts?.length === 1 &&
                                    <div style={{ display: "flex", justifyContent: "end", width: "500px" }}><label >{t("accountNumber")}:  {listAccounts[0].text}</label>
                                    </div>}</div>
                        </div>
                        <hr className="hr" style={{ width: "1300px" }}></hr>
                        <TabsAccount
                            accountsData={customerAccounts} setAccountsData={setCustomerAccounts} tab={tab} setTab={setTab}
                            idEntity={dataCustomer?.id_entity} actionIn={false} idAccount={selectedAccount}
                            currencyList={currencyList} setCrrencyList={setCrrencyList} showAccountMovements={showAccountMovements}
                        />

                        {/* <div style={{ width: "1300px" }}>
                            <AccountsTable accountsData={customerAccounts} setAccountsData={setCustomerAccounts}
                                idAccount={selectedAccount} idEntity={dataCustomer?.id_entity}></AccountsTable>
                        </div> */}
                    </div>

                    {/* //dont delete yet */}
                    {/* <div style={{ width: "40%", justifySelf: "left" }}> */}
                    {/* <div style={{ width: "80%" }}> */}
                    {/* <CustomDropdown otherInputId={''} hasOtherValue={false} options={[]} label={"הצג פעולות מסוג"} onChange={''} selectedKey={''} id={'type'} othertextInput={t('')} />
                            <CustomTextField value={''} readOnly={false} type="date" label={"מתאריך"} onChange={''} id={'dateAction'} />
                            <CustomTextField value={''} readOnly={false} type="date" label={"עד תאריך"} onChange={''} id={'dateAction'} />
                            <CustomTextField label={"מסכום"} type="NUMBER" value={''} onChange={''} id={'sumStart'} />
                            <CustomTextField label={"עד סכום"} type="NUMBER" value={''} onChange={''} id={'sumEnd'} />
                            <CustomTextField value={''} readOnly={false} label={"אסמכתא"} onChange={''} id={'reference'} />
                            <PrimaryButton className='buttonService' allowDisabledFocus checked={false} text={t("סנן")} /> */}
                    {/* </div> */}
                    {/* </div> */}
                </div>

            </div>
        </div>
    )
}

export default CustomerAccounts
