import { useTranslation } from "react-i18next";
import HeaderUtils from '../shared/components/headerUtils/HeaderUtils';
import Subtitle from '../shared/components/Subtitle';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../shared/components/CustomHooks/GlobalFunctions';

export default function UserAlerts() {
    const [t] = useTranslation();
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
        ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={t("settings")} subTitle={"false"}/>
            <br></br><br></br>
            <Subtitle title={t('uiAlerts')} />
        </div>
    )
}
