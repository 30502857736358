import { useEffect, useState } from 'react'
import JSZip from "jszip";
import { asBlob } from "html-docx-js-typescript";
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import ExportReportingDetails from './ExportReportingDetails'
import ExportSubjectEntity from './ExportSubjectEntity'
import ExportSubjectCorporation from './ExportSubjectCorporation'
import ExportFactorsInvolved from './ExportFactorsInvolved'
import { isCorporationById } from '../../shared/components/CustomHooks/GlobalFunctions'
import { reportUnRegularDownloadFile } from '../../store/actions/FilesAction'

export default function ExportUnRegularReports(props: any) {
    const { dataReport } = props
    // const dataReport = useSelector(({ reportsReducer }: any) => reportsReducer?.dataOfFieldsUnRegularReports)
    const userCredentials = useSelector(({ authReducer }: any) => authReducer?.userCredentials)

    const [dataOfEntities, setDataOfEntities] = useState<any>()
    const [dataOfReporter, setDataOfReporter] = useState<any>()
    const [dataOfbranch, setDataOfbranch] = useState<any>()
    const [ifContinue, setIfContinue] = useState<any>(false)
    const dispatch = useDispatch();

    useEffect(() => {
        if (dataReport) {
            setDataOfEntities(dataReport?.impa_utr_entities)
            setDataOfReporter(dataReport?.impa_utr_reporter)
            setDataOfbranch(dataReport?.impa_utr_branch)
        }
    }, [dataReport])

    useEffect(() => {
        if (ifContinue) {
            createZipFolderToReport()
        }
    }, [ifContinue])

    const createZipFolderToReport = async () => {
        let zip: any = new JSZip();
        debugger;
        if (dataReport?.impa_utr_attachments?.length) {
            const promiseUploadFiles = dataReport?.impa_utr_attachments?.map(async (item: any, i: any) => {
                if (i < 2) {
                    let dataFile = await dispatch(reportUnRegularDownloadFile(item?.id_entity, userCredentials, item?.id_media))
                    zip.folder('attachments').file(`mediaName${i}.pdf`, dataFile, { binary: true });
                }
            })
            await Promise.all(promiseUploadFiles);
        }
        // dataReport?.impa_utr_attachments?.forEach(async (item: any, i: any) => {
        //     if (i < 2) {
        //         let dataFile = await dispatch(reportUnRegularDownloadFile(item?.id_entity, userCredentials, item?.id_media))
        //         zip.folder('attachments').file("mediaName.pdf", dataFile, { binary: true });
        //     }
        // })
        let htmlContent: any = await document.getElementById("exportWord")?.outerHTML
        const blob: any = await asBlob(htmlContent)
        zip.file(`${dataReport?.impa_utr_number}.docx`, blob, { binary: true });
        const content = await zip.generateAsync({ type: "blob" });
        const fileName = `${dataReport?.impa_utr_zip_file_name}`;
        saveAs(content, fileName)
    }

    return (
        <div>

            <div style={{
                width: "1700px", justifyContent: "center",
                fontSize: "12px", fontFamily: "david"
            }} id="exportWord" dir='rtl' >

                <h6 style={{
                    textAlign: "center", fontWeight: "bold",
                    textDecoration: "underline 2px", fontSize: "16px"
                }}
                    className='title-page text-start'><u>דיווח לא רגיל</u></h6>

                <div><ExportReportingDetails allData={dataReport}
                    dataOfbranch={dataOfbranch}
                    dataOfReporter={dataOfReporter}
                    setIfContinue={setIfContinue} /></div>
                <div>
                    <h6 className='title-page' style={{
                        textAlign: "center"
                        , fontWeight: "bold"
                        , textDecoration: "underline 2px"
                        , fontSize: "14px"
                    }}><u>נשוא הידיעה</u></h6>
                    <div className='div-width' style={{ width: "100%", direction: "rtl" }}>
                        <table style={{ width: "100%", fontSize: "12px", fontFamily: "david" }}>
                            <tbody>
                                {dataOfEntities?.primary_entities?.map((entity: any) => (
                                    <>
                                        {isCorporationById(entity?.gender_id) ?
                                            <ExportSubjectCorporation entity={entity} /> :
                                            <ExportSubjectEntity entity={entity} />}
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <h6 style={{
                        textAlign: "start", fontWeight: "bold",
                        textDecoration: "underline 2px", fontSize: "14px"
                    }}
                        className='title-page text-start'><u>תמצית הידיעה</u></h6>
                    <label style={{ textDecoration: "underline" }} className='label-page'><u>{dataReport?.impa_utr_characteristics}</u></label>
                </div>
                <div>
                    <h6 style={{
                        textAlign: "start", fontWeight: "bold",
                        textDecoration: "underline 2px", fontSize: "14px"
                    }} className='title-page text-start'><u>תוכן הידיעה</u></h6>
                    <label style={{ textDecoration: "underline" }} className='label-page'><u>{dataReport?.impa_utr_essence}</u></label>

                    <ExportFactorsInvolved dataOfEntities={dataOfEntities} />

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold"
                    }} className='div-report-details'>
                        <div style={{ width: "400px" }} className='contant-reports-details'>שם ומשפחה (עורך הדיווח):
                            <label style={{ textDecoration: "underline" }} className='label-page'><u>&ensp;{dataOfReporter?.entity_name}</u></label></div>
                        <div style={{ width: "400px" }} className='contant-reports-details'>תפקיד:
                            <label style={{ textDecoration: "underline" }} className='label-page'><u></u></label></div>
                        <div style={{ width: "400px" }} className='contant-reports-details'>טלפון:
                            <label style={{ textDecoration: "underline" }} className='label-page'><u>&ensp;{dataOfReporter?.telephone?.telephone_number}</u></label></div>
                        <div style={{ width: "400px" }} className='contant-reports-details'>פקסימליה:
                            <label style={{ textDecoration: "underline" }} className='label-page'><u>&ensp; </u></label>
                        </div>
                        <div style={{ width: "400px" }} className='contant-reports-details'>דואר אלקטרוני:
                            <label style={{ textDecoration: "underline" }} className='label-page'><u>&ensp; {dataOfReporter?.email?.email_address}</u></label></div>
                        <div style={{ width: "400px" }} className='contant-reports-details'>תאריך ושעה:
                            <label style={{ textDecoration: "underline" }} className='label-page'><u>&ensp;{moment().format("DD/MM/YYYY")}</u></label></div>
                        <div style={{ width: "400px" }} className='contant-reports-details'>חתימה:_______________________________</div>
                    </div>
                </div>

            </div >
        </div>
    )
}
