import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "@fluentui/react";
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import { useReactToPrint } from 'react-to-print';
import EditableRelatedFactors from '../relatedFactors/EditableRelatedFactors';
import ListRelatedFactors from '../relatedFactors/ListRelatedFactors';
import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils'
import { IUserCredentials } from '../customerDetails/CustomerDetails'
import { CustomDropdown } from "../../shared/components/Option";
import { saveGetService } from '../../store/actions/customerActioin'
import Subtitle from '../../shared/components/Subtitle';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import { customerInfoInObject } from '../../shared/components/CustomHooks/GlobalFunctions'
import UploadFiles from '../global/UploadFiles';
import CanvasDraw from "react-canvas-draw";
import { Label } from '@fluentui/react/lib';

import GenericPdfDownloader from '../pdfUtils/GenericPdfDownloader';
import GetServicePDF from '../pdfUtils/GetServicePDF';
import { CustomTextField } from "../../shared/components/TextField";
import { uploadFile } from '../../store/actions/FilesAction';

import "./GetService.scss"
import '../../modelsType/Icons/css/all.css'

const spinnerStyles: Partial<ISpinnerStyles> = {
    root: {
        position: 'fixed',
        zIndex: '999999',
        fontWeight: 'bold',
    },
    circle: {
        color: '#FFB400 ',
        height: '100px',
        width: '100px',
    },
}

const GetService = () => {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const [file, setFile] = useState<any>()
    const [pdfFile, setPdfFile] = useState<any>()
    const printRef = useRef(null);
    const refSignature = useRef(null);
    const dataCustomer = useSelector(({ customerReducer }: any) => customerReducer.dataCustomer)
    const [addFactor, setaddFactor] = useState(false)
    const [addFactorBenefits, setaddFactorBenefits] = useState(false)
    const [readOnly, setReadOnly] = useState(true)
    const [showSpiner, setshowSpiner] = useState(false)
    const [activeDocument, setActiveDocument] = useState<any>({})
    const [listBenefits, setListBenefits] = useState<any>([])
    const [listControllingHolder, setControllingHolder] = useState<any>([])
    const [listRepresentatives, setListRepresentatives] = useState<any>([])
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [showDialogSave, setShowDialogSave] = useState(false)
    const [showDialogMessage, setShowDialogMessage] = useState(false)
    const [message, setMessage] = useState("")
    const [statementBeforeChanges, setStatementBeforeChanges] = useState<any>({ isControllingHolder: false, isBeneficiary: false, id_insider: null })
    const [statement, setStatement] = useState<any>({
        isControllingHolder: false, isBeneficiary: false,
        is_unknown_beneficiary: false, is_unknown_beneficiary_details: false, id_insider: null
    })
    const [isCorporation, setIsCorporation] = useState(false)
    const customerDetails = customerInfoInObject(dataCustomer)
    const [showMessage, setShowMessage] = useState(false)
    const [updateActiveDocument, setUpdateActiveDocument] = useState("")
    const listRelated = [{ key: "8", text: t('controllingOwner') }, { key: "10", text: t('controllingOwnercorporationPoweraAtorney') }
        , { key: "12", text: t('controllingOwnerCorporationBeneficiary') }]
    const listBeneficiaryType = [{ key: "11", text: t('beneficiary') }]


    useEffect(() => {
        if (authReducer)
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
    }, [authReducer])

    useEffect(() => {
        if (dataCustomer?.documentation) {
            dataCustomer?.documentation?.forEach((doc: any) => {
                if (doc?.documentation_type_id === '6' && doc?.media_status_id.toString() !== "99")
                    setActiveDocument(doc)
            });
        }
    }, [dataCustomer])

    useEffect(() => {
        let idInsider = dataCustomer?.service_receiver_statement?.id_insider
        let isBeneficiary = dataCustomer?.service_receiver_statement?.is_beneficiary
        let isControllingInterestHolder = dataCustomer?.service_receiver_statement?.is_controlling_interest_holder
        let arrControllingHolder: any[] = [], arrBeneficiary: any[] = [], arrRepresentatives: any[] = []
        dataCustomer?.insiders?.forEach((insider: any) => {

            if (insider.insider_type_id === 10 || insider.insider_type_id === 12 || insider.insider_type_id === 8) {
                isControllingInterestHolder = true
                arrControllingHolder.push(insider)
            }
            if (insider.insider_type_id === 11) {
                isBeneficiary = true
                arrBeneficiary.push(insider)
            }
            if (insider?.insider_type_id === 9) {
                arrRepresentatives.push({ key: insider?.id_entity, text: insider?.entity_name })
            }
        })
        let typeId = dataCustomer?.properties?.customer_type_id
        if (typeId === "3" || typeId === "5" || typeId === "9" || typeId === "10" || typeId === "12") {
            setIsCorporation(true)
        }
        setControllingHolder(arrControllingHolder)
        setListBenefits(arrBeneficiary)
        setListRepresentatives(arrRepresentatives)

        setStatement({
            ...statement,
            "id_insider": idInsider !== undefined && idInsider !== false ? idInsider : statement?.id_insider,
            "isBeneficiary": isBeneficiary !== undefined && isBeneficiary !== false ? true : false,
            "isControllingHolder": isControllingInterestHolder !== undefined && isControllingInterestHolder !== false ? true : false
        })
        setStatementBeforeChanges({
            ...statement,
            "id_insider": idInsider !== undefined && idInsider !== false ? idInsider : null,
            "isBeneficiary": isBeneficiary !== undefined && isBeneficiary !== false ? true : false,
            "isControllingHolder": isControllingInterestHolder !== undefined && isControllingInterestHolder !== false ? true : false
        })
    }, [dataCustomer])

    useEffect(() => {
        if (updateActiveDocument === "save") {
            createFile()
        }
        if (updateActiveDocument === "don't save") {
            setShowMessage(false)
        }
        updateActiveDocument && setUpdateActiveDocument("")
    }, [updateActiveDocument])

    useEffect(() => {
        async function upload() {
            let file2 = ({
                media_name: t('service_requester_statement'), id_media: "", documentation_type_id: "6", media_type_id: "1", media_status_id: "1",
                media_exp_date: "2023-12-12", media_details: null, date_created: "", status: "פעיל", type: "חובה"
            })
            await dispatch(uploadFile(dataCustomer?.id_entity, userCredentials, file, file2, setPdfFile, "", "", "", t))
        }
        if (file) {
            upload()
        }
    }, [file])

    const updateStatement = (e: any) => {
        if (!readOnly) {
            if ((e.target.id === "noIsControllingHolder" && listControllingHolder?.length > 0) ||
                (e.target.id === "noIsBeneficiary" && listBenefits?.length > 0) ||
                (e.target.id === "is_unknown_beneficiary" && listBenefits?.length > 0)
            ) {
                setShowDialogMessage(true)
                let entity = e.target.id === "noIsBeneficiary" ? "נהנים" : e.target.id === "is_unknown_beneficiary" ? "נהנים" : "בעלי שליטה"
                setMessage(`${t('registered')} ${entity} ${t('toCustomer')}, ${t('firstDelete')} ${entity} ${t('selectCheckMark')}`)
            }
            else {
                if (e.target.id === "is_unknown_beneficiary") {
                    setStatement({ ...statement, [e.target.name]: true, ["isBeneficiary"]: '' })
                }
                else {
                    setStatement({
                        ...statement, [e.target.name]: e.target.id === e.target.name ? true : false,
                        ["is_unknown_beneficiary"]: false
                    })
                }
            }
        }
    }

    const save = async () => {
        if (!readOnly) {
            // if (!isEqual(statementBeforeChanges, statement)) {
            if ((statement?.isBeneficiary && listBenefits?.length === 0) || (statement?.isControllingHolder && listControllingHolder?.length === 0)) {
                setShowDialogMessage(true)
                setMessage(` ${t("statementThatThereAre")} ${statement?.isBeneficiary && listBenefits?.length === 0 ? t("beneficiaries") : t("controllingOwners")}  ${t('andYouDidntAddToTheList')}`)
            }
            else {
                setshowSpiner(true)
                createFile()
                await dispatch(saveGetService(statement, userCredentials, dataCustomer?.id_entity, setShowDialogSave, setshowSpiner))
            }
            // }
        }
    }


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });
    const addRelatedEntity = (key: string, value: any) => {
        setStatement({ ...statement, [key]: value })
    }

    const createFile = async () => {
        debugger
        
        await GenericPdfDownloader({ rootElementId: "getServices", downloadFileName: t('service_requester_statement'), setFile, refSignature })
    }
    console.log('customerDetails------',customerDetails)
    return (
        <>
            <HeaderUtils title="customers" editBtn={readOnly ? true : false} uploadBtn={readOnly ? false : true} funcAction={handlePrint}
                saveFunc={save} editFunc={() => setReadOnly(false)} disabledSave={true} pdfAction={"getService"} pdfName={t('service_requester_statement')} createPDF={true}
                createPrint={true} />
            <br></br><br></br>
            <Subtitle title={t("service_requester_statement")} />
            {showMessage && <DialogMessages setansDelete={setShowMessage} subText={message} title={t('note')}
                setshowModalDelete={setShowMessage} dialogType={"dialogMessage"} setSaveDataConversion={setUpdateActiveDocument} />}
            {showDialogMessage && <DialogMessages setansDelete={setShowDialogMessage} subText={message} title={t('note')} setshowModalDelete={setShowDialogMessage} dialogType={"dialogMessage"} />}
            {showDialogSave && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"save"} />}
            {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
            <div className={`content-div-${dir}`}>
                <div style={{
                    width: "100%",
                    gridTemplateColumns: "1fr 1fr",
                    display: "flex",
                    gridTemplateRows: "auto",
                    gridArea: "main"
                }}>
                    <div className="warpper-fields-rightP" id={"getService"} ref={printRef}>
                        <p className="title-text">{t("customerDetails")}</p>
                        <hr className="hr" ></hr>
                        {customerDetails ?
                            <div className="wrapDetails" style={{ width: "100%" }}>
                                <div>
                                    <div className="boldD" style={{ fontSize: "22px" }}><p className="boldD" style={{ marginBottom: "0%", fontSize: "19px", height: "25px" }}>{t("name")}</p>{customerDetails?.entityName ? customerDetails?.entityName : ""}</div>
                                    <p className="normal" style={{ fontSize: "19px", marginBottom: "0%" }}>{customerDetails?.idNumber ? customerDetails?.idNumber : ""}</p>
                                </div>
                                {customerDetails?.addressName ?
                                    <div>
                                        <div className="normal" style={{ fontSize: "19px", marginBottom: "0%" }}><p className="boldD" style={{ marginBottom: "0%", fontSize: "19px" }}>{t("address")}</p>{customerDetails?.addressName} {+" " + customerDetails?.addressNumber}</div>
                                        <p className="normal" style={{ fontSize: "19px", marginBottom: '0' }}>{customerDetails?.addressCityName ? customerDetails?.addressCityName : ""}</p>
                                    </div>
                                    : ""}
                                {customerDetails?.telephone ? <div>
                                    <div className="normal" style={{ fontSize: "19px" }}><p className="boldD" style={{ marginBottom: "0%", fontSize: "19px" }}>{t("phone")}</p>{customerDetails?.telephone}</div>
                                    <p className="normal" style={{ fontSize: "19px", marginBottom: '0' }}>{ }</p>
                                </div> : ""}
                                <div style={{ width: "22%" }}>
                                    {listRepresentatives?.length > 0 && <> <p className="boldD" style={{ marginBottom: "0%", fontSize: "19px" }}>{t("representative")}</p>
                                        <CustomDropdown readOnly={readOnly} otherInputId={''} hasOtherValue={false} options={listRepresentatives} label={''} onChange={addRelatedEntity} selectedKey={statement?.id_insider} id={'id_insider'} othertextInput={''} />
                                    </>}
                                </div>
                            </div>
                            : <div style={{ marginTop: "18px" }}>{t('noDisplay')}</div>}
                        <p className="title-text">{t("statementAboutbeneficiary")}</p>
                        <hr className="hr" ></hr>

                        <Checkbox className="checkBox" label={t("creditServiceForMyselfOnly")} name={"isBeneficiary"} id={"noIsBeneficiary"} disabled={false} onChange={updateStatement} checked={statement?.isBeneficiary === "" ? false : statement?.is_unknown_beneficiary === true ? false : !statement?.isBeneficiary} />
                        <Checkbox className="checkBox" label={t("creditServiceForBeneficiary")} name={"isBeneficiary"} id={"isBeneficiary"} disabled={false} onChange={updateStatement} checked={statement?.isBeneficiary === "" ? false : statement?.isBeneficiary} />
                        {listBenefits?.length > 0 && <p className="boldD" style={{ marginTop: "15px", marginBottom: "0%" }}>{t("beneficiariesOfServiceProvidedAre")}</p>}
                        <div style={{ width: "760px" }}>
                            {statement?.isBeneficiary &&
                                <div className="title-text addFile" onClick={() => { !readOnly && setaddFactorBenefits(true) }} ><i className="fal fa-plus"></i></div>
                            }
                            {addFactorBenefits && <EditableRelatedFactors typesList={listBeneficiaryType} setShowDialog={setaddFactorBenefits} setshowSpiner={setshowSpiner} />}
                            {listBenefits?.length > 0 && <ListRelatedFactors typesList={listBeneficiaryType} setshowSpiner={setshowSpiner} dataCustomer={listBenefits} />}
                        </div>
                        {<>
                            <Checkbox className="checkBox" label={t("beneficiaryIdentificationDetailsNotKnown")} name={"is_unknown_beneficiary"} id={"is_unknown_beneficiary"}
                                disabled={false} onChange={updateStatement} checked={statement?.is_unknown_beneficiary === "" ? false : statement?.is_unknown_beneficiary ? true : false} />
                            {statement?.is_unknown_beneficiary === true
                                && <div className="inline">
                                    <Label className="pQuestion" required>{t("reason")}</Label>
                                    <div className={`div-money-laundering-${dir}`}>
                                        <CustomTextField readOnly={readOnly} value={statement?.is_unknown_beneficiary_details}
                                            onChange={(id: string, e: any) => {
                                                setStatement({
                                                    ...statement, ["is_unknown_beneficiary_details"]: e,
                                                })
                                            }} id={'is_unknown_beneficiary_details'} />
                                    </div>
                                </div>
                            }
                        </>}
                        {isCorporation && <> <p className="title-text">{t("declarationControllingCorporation")}</p>
                            <hr className="hr" ></hr>
                            <Checkbox className="checkBox" label={t("noControllingCorporation")} name={"isControllingHolder"} id={"noIsControllingHolder"} disabled={false} onChange={updateStatement} checked={statement?.isControllingHolder === "" ? false : !statement?.isControllingHolder ? true : false} />
                            <Checkbox className="checkBox" label={t("controllingCorporation")} name={"isControllingHolder"} id={"isControllingHolder"} disabled={false} onChange={updateStatement} checked={statement?.isControllingHolder === "" ? false : statement?.isControllingHolder ? true : false} />
                            <div style={{ width: "760px" }}>
                                {statement?.isControllingHolder &&
                                    <div className="title-text addFile" onClick={() => { !readOnly && setaddFactor(true) }} ><i className="fal fa-plus"></i></div>
                                }
                                {addFactor && <EditableRelatedFactors typesList={listRelated} setShowDialog={setaddFactor} setshowSpiner={setshowSpiner} />}
                                {listControllingHolder?.length > 0 &&
                                    <>
                                        <p className="boldD" style={{ marginTop: "15px", marginBottom: "0%" }}>{t("controllingOfCorporationAre")}</p>
                                        <ListRelatedFactors typesList={listRelated} setshowSpiner={setshowSpiner} dataCustomer={listControllingHolder} />
                                    </>}
                            </div>
                        </>}
                        <p style={{ marginTop: "50px", marginBottom: "30px", fontWeight: "bold" }}>{t("changeInDetailsProvidedAbove")}</p>
                        <div id="signature">
                            <b>{t("signature")}</b>
                            <CanvasDraw
                                ref={refSignature}
                                style={{
                                    boxShadow: "0 0 2px #1b7d9e",
                                    width: "200px",
                                    height: "200px",
                                    marginBottom: "30px",
                                }}
                            />
                        </div>


                    </div>
                    <div className={`warpper-fields-leftP-${dir} create-file-${dir}`} >


                        <UploadFiles pdfFile={pdfFile} idEntity={dataCustomer?.id_entity} documentationTypeId={"6"} mediaName={t('service_requester_statement')} />

                        {/* <div className="titleC">
                            <p className="title-text">{t("scanFile")}</p></div>
                        <hr className="hr" style={{ width: "80%", height: "2px" }} ></hr>
                        <div style={{ width: "80%" }}>
                            <div className={`fileDetails-${dir}`}>
                                <div style={{ width: "100%", textAlign: "center" }}>
                                    <IconButton
                                        iconProps={Icons.pdf}
                                        styles={{
                                            icon: { color: 'red', fontSize: 45 }
                                        }}
                                        className="iconFile"
                                    /></div>
                                <p className="fileText">{"מסמך א"}</p>
                                <p className="fileText">{"25/20/2021"}</p>
                            </div>
                            <PrimaryButton onClick={() => ""} className='buttonService' allowDisabledFocus checked={false} text={"העלאת קובץ סרוק"} />
                        </div> */}
                    </div>
                </div>
                <div style={{ display: "none" }}>
                    <div id="getServices">
                        <GetServicePDF customerDetails={customerDetails}
                            listControllingHolder={listControllingHolder}
                            statement={statement}
                            listBenefits={listBenefits} /></div></div>
            </div >
        </>
    )
}
export default GetService