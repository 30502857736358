import { IDropdownStyles, TextField } from '@fluentui/react';
import React from 'react';
import ComponenetProps from '../../modelsType/type/interface';
import { useBoolean } from '@fluentui/react-hooks';
import "../../scssPages/form.scss"
import { IIconProps } from '@fluentui/react';

interface MyProps extends ComponenetProps {
    label?: string,
    required?: boolean,
    iconProps?: IIconProps
    type?: string,
    nameOfClassStyle?: string
    value?: any
    readOnly?: boolean
    disabled?: boolean
    min?: any
    max?: any
    autoFocus?: boolean
    ref?: any, setshowSpiner?: any
    placeholder?: string
    tabIndex?: number
    styleObj?: any,
    requiredField?: boolean,
    // onBlur:Callback
    // onKeyUp?: any
    onBlur?: any
    onKeyDown?: any,
    canRevealPassword?: any,
    autocomplete?: any,
    prefix?: any,
    suffix?: any
    // ?:IIconProps
}

const styleObject: Partial<IDropdownStyles> = {
    title: { backgroundColor: "white", fontWeight: "bold", fontSize: "15px" },
};
const stylesRead: Partial<IDropdownStyles> = {
    title: { backgroundColor: 'white' },
};
const stylesRequired: Partial<IDropdownStyles> = {
    title: { backgroundColor: '#FAF9F8', border: "solid red 2px" },
    callout: { minWidth: "fit-content", maxwidth: "fit-content" },

};
const stylesEdit: Partial<IDropdownStyles> = {
    title: { backgroundColor: '#FAF9F8' },
};


export const CustomTextField: React.FunctionComponent<MyProps> = (props) => {
    const { autocomplete, canRevealPassword, prefix, readOnly, min, max, value, onChange, label,
        onBlur, onKeyDown, id, required, iconProps, placeholder, type, nameOfClassStyle, autoFocus,
        ref, tabIndex, styleObj, requiredField, disabled, suffix } = props;

    return (

        <TextField
            required={required ? true : undefined}
            label={label}
            onChange={(e: any) => {
                onChange(id, e.target.value)
            }}
            componentRef={ref}
            iconProps={iconProps}
            tabIndex={tabIndex}
            className={`${nameOfClassStyle} text-field`}
            disabled={disabled}
            type={type}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            value={value}
            placeholder={placeholder}
            autoFocus={autoFocus}
            style={(!styleObj && !readOnly) ? { backgroundColor: "white", border: requiredField ? "solid red 2px" : "" } : styleObj && readOnly ?
                { backgroundColor: '#FAF9F8', fontWeight: "bold", fontSize: "15px" } : readOnly ?
                    { backgroundColor: "#FAF9F8", border: requiredField ? "solid red 2px" : "" }
                    : requiredField ? { backgroundColor: 'white', border: "solid red 2px" }
                        : { backgroundColor: "white" }}
            readOnly={readOnly}
            min={min}
            max={max}
            canRevealPassword={canRevealPassword}
            autoComplete={autocomplete}
            prefix={prefix}
            suffix={suffix}

        />
        // onClick={setMuted}

        // onChange={(e, selectedOption) => {
        //   }}

        // onChange={(e: any) => setLogin_entity_number(e.target.value)}

    );

}
interface AddInputProp extends MyProps {
    othertextItnput: string
    otherInputId: string
    otherValue: string
}
export const CustomTextFieldAddInput: React.FunctionComponent<AddInputProp> = (props) => {
    const [muted, { toggle: setMuted }] = useBoolean(false);
    const { readOnly, value = "", onChange, label, id, required, iconProps, othertextItnput, otherInputId, otherValue } = props;

    return (
        <>
            <TextField
                required={required ? true : undefined}
                label={label}
                onChange={(e: any) => {
                    onChange(id, e.currentTarget.value)
                }}
                iconProps={iconProps}
                onClick={setMuted}
                className="text-field"
                value={value}
                defaultValue={value}
                readOnly={readOnly}
                style={readOnly ? { backgroundColor: "#FAF9F8" } : { backgroundColor: "white" }}

            // onChange={(e, selectedOption) => {
            //     onChange(id,selectedOption?.key);
            //   }}

            // onChange={(e: any) => setLogin_entity_number(e.target.value)}
            />
            {muted || otherValue !== undefined ? <CustomTextField value={otherValue} readOnly={readOnly} label={othertextItnput} onChange={onChange} id={otherInputId} /> : false}
        </>

    );

}



