import { useBoolean } from "@fluentui/react-hooks";
import {
  PrimaryButton,
  Dialog,
  ContextualMenu,
  DialogFooter,
  DefaultButton,
  ISpinnerStyles,
  Spinner,
} from "office-ui-fabric-react";
import { ComboBox, IComboBoxOption, IComboBox } from "@fluentui/react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";
import { cloneDeep } from "lodash";

import { Icons } from "../../../../modelsType/Icon";
import EditableRelatedFactors from "../../../../components/relatedFactors/EditableRelatedFactors";
import DiscountDetails from "../../../../components/actions/discountsAction/checkTransaction/DiscountDetails";
import FairCredit from "../../../../components/actions/discountsAction/checkTransaction/FairCredit";
import { CustomDropdown } from "../../Option";
import { CustomTextField } from "../../../components/TextField";
import { Details } from "./Details";
import { CustomDialogBankAccount } from "../Dialog";
import { ScanCheck } from "./ScanCheck";
import {
  convertArrToDropdropdown,
  findDefault,
} from "../../../../components/customerDetails/CustomerDetails";
import { CustomerDate } from "../../CustomHooks/concatTextFields";
import { createTransaction } from "../../../../store/actions/convertionActions";
import { useDispatch, useSelector } from "react-redux";
import { DialogMessages } from "../DialogMessages";
import CheckCarousel from "../../../../components/actions/CheckCarousel";
import ListRelatedFactors from "../../../../components/relatedFactors/ListRelatedFactors";
import { calcChequeDetails } from "../../CustomHooks/GlobalFunctions";

import "../dialog.scss";
import moment from "moment";
import { receiptTransaction } from "../../../../store/actions/accountAction";
import { searchBankDetails } from "../../../../store/actions/bankActions";

export const setFocus = (tableType: any, convertions: any, rowNumber: any) => {
  const index = convertions[tableType]?.findIndex(
    (item: any) => Number(item.row_number) === Number(rowNumber)
  );
  if (tableType.includes("out")) {
    let nodes = document.querySelectorAll(".amount-out");
    (nodes[index] as HTMLElement)?.focus();
  } else {
    let nodes = document.querySelectorAll(".currency");
    (nodes[(index + 1) * 2 + 1] as HTMLElement)?.focus();
  }
};

export const CheckDetailsDialog = (props: any) => {
  const {
    emptyRequiredFields,
    typeAction,
    scanButton,
    setScanButton,
    contraCheck,
    setShowDialog,
    setConvertions,
    rowNumber,
    convertions,
    tableType,
    setsumIn,
    userCredentials,
    conversionCustomer,
    sourcePage,
    setIfChanges,
    ifChanges,
    isIconScanButton,
    SetIsIconScanButton,
    setAllScanChecks,
    bankRow,
    setCheckType
  } = props;
  const [addFactorDialog, setaddFactorDialog] = useState(false);
  const authReducer = useSelector(({ authReducer }: any) => authReducer);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [ifChangeRelatedType, setIfChangeRelatedType] = useState(false);
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);
  const dispatch = useDispatch();
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [gatesData, setGatesData] = useState<any>(
    sessionStorage.getItem("gatesArr")
  );
  const [itemsInsiders, setItemsInsiders] = useState<Object[]>([]);
  const [enumsDropdown, setenumsDropdown] = useState<any>({
    countryCode: [],
    bank: [],
    branch: [],
  });
  const [relatedFactors, setRelatedFactors] = useState<any>();
  const [showHolderAccount, setshowHolderAccount] = useState(false);
  // const [isFinishScan, setIsFinishScan] = useState(false)
  const [showEndorsee, setshowEndorsee] = useState(false);
  const [isValidDate, setIsValidDate] = useState<any>(true);
  const [searchData, setSearchData] = useState<any>();
  const [showBeneficiary, setshowBeneficiary] = useState(false)
  const [showDialogFailed, setShowDialogFailed] = useState(false)
  const [showDialogHolder, setshowDialogHolder] = useState(false)
  const [detailsButton, setDetailsButton] = useState(true)
  const [discountDetailsButton, setDiscountDetailsButton] = useState(false)
  const [relatedButton, setRelatedButton] = useState(false)
  const [newBenObject, setnewBenObject] = useState<any>()
  const [idBeneficiary, setidBeneficiary] = useState<any>("")
  const [newEndorsee, setnewEndorsee] = useState<any>()
  const [idEndorsee, setidEndorsee] = useState("")
  const [newHolder, setnewHolder] = useState<any>()
  const [idHolder, setidHolder] = useState("")
  const [idBankAccount, setidBankAccount] = useState("")
  const [convertionsBeforeChanges, setConvertionsBeforeChanges] = useState(convertions)
  const [note, setNote] = useState("")
  const [showNote, setShowNote] = useState(false)
  const [requiredDialog, setRequierdDialog] = useState(false)
  const [requiredField, setRequiredField] = useState(false)
  const index = convertions[tableType]?.findIndex((item: any) => Number(item?.row_number) === Number(rowNumber))
  const [showSpiner, setshowSpiner] = useState(false)
  const [/*search*/, setsearch] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [serverMessageError, setServerMessageError] = useState('')
  const [titleMessageError, setTitleMessageError] = useState('')
  const [arrScanChecksImgs, setArrScanChecksImgs] = useState<any>([])
  const [arrChecksPdfImgs, setArrChecksPdfImgs] = useState<any>([])
  const [updateBankAccount, setUpdateBankAccount] = useState<any>(
    {
      key: '', country: '', accountNumber: '', bank: '', branch: '',
      bankName: '', branchAddress: '', branchName: '', routing: ''
    })

  const Conversion = useSelector(
    ({ conversionReducer }: any) => conversionReducer?.dataConversion
  );
  const account = useSelector(
    ({ accountReducer }: any) => accountReducer?.dataAccount
  );
  let arrScanChecks = JSON.parse(sessionStorage.getItem("arrScanChecks") || "[]")
  const datacConversion =
    typeAction === "cheque_cashing" || typeAction === "exchange"
      ? Conversion
      : typeAction === "receipt"
        ? account
        : "";

  const spinnerStyles: Partial<ISpinnerStyles> = {
    root: {
      position: "fixed",
      zIndex: "999999",
      fontWeight: "bold",
    },
    circle: {
      color: "#FFB400 ",
      height: "100px",
      width: "100px",
    },
  };
  console.log('updateBankAccount00', updateBankAccount)
  const [arrInformationScan, setArrInformationScan] = useState<any>([
    { checkNumber: "", bankNumber: "", branchNumber: "", accountNumber: "" },
  ]);
  const [checkDeatils, setcheckDeatils] = useState<any>(
    sourcePage === "discounts"
      ? {
        bank_account_id: "",
        id_account_holder: "",
        id_beneficiary: "",
        id_endorsee: "",
        related_entities: [],
        allowed_expenses: 0,
        allowed_actual_expenses: 0,
        cashing_intrest: 0,
        fee: 0,
        cashing_amount: 0,
        cheque_expenses: 0,
        cheque_days: 0,
        cheque_net: 0,
        cheque_value_days: 0,
        date_created: "",
        cheque_cashing_date: "",
        cheque_face_scan_path: "",
        cheque_back_scan_path: "",
        reference_number: "",
        cheque_date: "",
        cheque_endorses_number: 0,
        id_media_face: "",
        id_media_back: "",
        media_face_extension: "",
        media_back_extension: "",
        media: { media_face: {}, media_back: {} },
      }
      : {
        reference_number: "",
        bank_account_id: "",
        id_account_holder: "",
        id_beneficiary: "",
        id_endorsee: "",
        cheque_date: "",
        cheque_endorses_number: 0,
        related_entities: [],
        date_created: "",
        cheque_face_scan_path: "",
        cheque_back_scan_path: "",
        media: { media_face: {}, media_back: {} },
        id_media_face: "",
        id_media_back: "",
        media_face_extension: "",
        media_back_extension: "",
      }
  );

  const dragOptions = {
    moveMenuItemText: "Move",
    closeMenuItemText: "Close",
    menu: ContextualMenu,
    keepInBounds: true,
  };
  const modelProps = {
    isBlocking: true,
    dragOptions: dragOptions,
  };

  useEffect(() => {
    emptyRequiredFields && setRequiredField(true);
  }, [emptyRequiredFields]);

  useEffect(() => {
    if (gatesData && typeof gatesData === "string") {
      setGatesData(JSON.parse(gatesData));
    }
  }, [gatesData]);


  useEffect(() => {
    debugger
    if (datacConversion && datacConversion[tableType]) {
      let dataAction = { ...datacConversion };
      let index = datacConversion[tableType]?.findIndex(
        (item: any) => Number(item?.row_number) === Number(rowNumber)
      );
      console.log(dataAction, "----dataAction");

      if (index === -1) {
        dataAction = convertions;
        index = convertions[tableType]?.findIndex(
          (item: any) => Number(item?.row_number) === Number(rowNumber)
        );
      }
      console.log(dataAction, "----dataAction");

      let details, detailsBeneficiary, detailsEndorsee, detailsCheck;
      // if (datacConversion[tableType][index]?.financial_asset_code === "3") {
      debugger
      details =
        dataAction[tableType][index] &&
        dataAction[tableType][index]?.financial_asset_details?.account_holder;
      console.log('details00000', details)
      if (!details || !details?.bank_name)
        details =
          dataAction[tableType][index] &&
          dataAction[tableType][index]?.financial_asset_details;
      detailsBeneficiary =
        dataAction[tableType][index] &&
        dataAction[tableType][index]?.financial_asset_details?.beneficiary;
      detailsEndorsee =
        dataAction[tableType][index] &&
        dataAction[tableType][index]?.financial_asset_details?.endorsee;

      detailsCheck =
        dataAction[tableType][index] &&
        dataAction[tableType][index]?.financial_asset_details;
      // }
      let indexAddress;
      let indexPhone;
      indexAddress = findDefault(details?.addresses);
      indexPhone = findDefault(details?.telephones);
      console.log(details, "details88888");

      let beneficiary = {
        gender: detailsBeneficiary?.gender?.gender_id,
        idTypeCountryCode: detailsBeneficiary?.identification?.ID_country_code,
        firstName: detailsBeneficiary?.first_name,
        lastName: detailsBeneficiary?.last_name,
        dateBirth: detailsBeneficiary?.properties?.date_birth,
        classId: detailsBeneficiary?.class?.class_id,
        entitySubTypeId: 1,
        idTypeId: detailsBeneficiary?.identification?.ID_type_id,
        idNumber: detailsBeneficiary?.identification?.ID_number,
        addressCountryCode:
          detailsBeneficiary?.addresses &&
          detailsBeneficiary?.addresses[indexAddress]?.address_country_code,
        addressState:
          detailsBeneficiary?.addresses &&
          detailsBeneficiary?.addresses[indexAddress]?.address_state,
        address:
          detailsBeneficiary?.addresses &&
          detailsBeneficiary?.addresses[indexAddress]?.address_street_code,
        addressName:
          detailsBeneficiary?.addresses &&
          detailsBeneficiary?.addresses[indexAddress]?.address_street,
        addressNumber:
          detailsBeneficiary?.addresses &&
          detailsBeneficiary?.addresses[indexAddress]?.address_number,
        addressCity:
          detailsBeneficiary?.addresses &&
          detailsBeneficiary?.addresses[indexAddress]?.address_city_code,
        addressCityName:
          detailsBeneficiary?.addresses &&
          detailsBeneficiary?.addresses[indexAddress]?.address_city,
        iDCountryCode: detailsBeneficiary?.identification?.ID_country_code,
        telephone:
          detailsBeneficiary?.telephones &&
          detailsBeneficiary?.telephones[indexPhone]?.telephone_number,
        telephoneCountryCode:
          detailsBeneficiary?.telephones &&
          detailsBeneficiary?.telephones[indexPhone]?.telephone_country_code,
        entityNumber: detailsBeneficiary?.entity_number,
        addressZipCode:
          detailsBeneficiary?.addresses &&
          detailsBeneficiary?.addresses[indexAddress]?.address_zip_code,
        telephoneTypeId:
          detailsBeneficiary?.telephones &&
          detailsBeneficiary?.telephones[indexPhone]?.telephone_type_id,
        telephoneId:
          detailsBeneficiary?.telephones &&
          detailsBeneficiary?.telephones[indexPhone]?.telephone_id,
        telephoneDefault: 1,
        addressDefault: 1,
        addressId:
          detailsBeneficiary?.addresses &&
          detailsBeneficiary?.addresses[indexAddress]?.address_id,
      };
      let holder = {
        gender: details?.gender?.gender_id,
        idTypeCountryCode: details?.identification?.ID_country_code,
        firstName: details?.first_name,
        lastName: details?.last_name,
        dateBirth: details?.properties?.date_birth,
        classId: details?.class?.class_id,
        entitySubTypeId: 1,
        idTypeId: details?.identification?.ID_type_id,
        idNumber: details?.identification?.ID_number,
        addressCountryCode:
          details?.addresses &&
          details?.addresses[indexAddress]?.address_country_code,
        addressState:
          details?.addresses && details?.addresses[indexAddress]?.address_state,
        address:
          details?.addresses &&
          details?.addresses[indexAddress]?.address_street_code,
        addressName:
          details?.addresses &&
          details?.addresses[indexAddress]?.address_street,
        addressNumber:
          details?.addresses &&
          details?.addresses[indexAddress]?.address_number,
        addressCity:
          details?.addresses &&
          details?.addresses[indexAddress]?.address_city_code,
        addressCityName:
          details?.addresses && details?.addresses[indexAddress]?.address_city,
        iDCountryCode: details?.identification?.ID_country_code,
        telephone:
          details?.telephones &&
          details?.telephones[indexPhone]?.telephone_number,
        telephoneCountryCode:
          details?.telephones &&
          details?.telephones[indexPhone]?.telephone_country_code,
        entityNumber: details?.entity_number,
        addressZipCode:
          details?.addresses &&
          details?.addresses[indexAddress]?.address_zip_code,
        telephoneTypeId:
          details?.telephones &&
          details?.telephones[indexPhone]?.telephone_type_id,
        telephoneId:
          details?.telephones && details?.telephones[indexPhone]?.telephone_id,
        telephoneDefault: 1,
        addressDefault: 1,
        addressId:
          details?.addresses && details?.addresses[indexAddress]?.address_id,
      };
      let endorsee = {
        gender: detailsEndorsee?.gender?.gender_id,
        idTypeCountryCode: detailsEndorsee?.identification?.ID_country_code,
        firstName: detailsEndorsee?.first_name,
        lastName: detailsEndorsee?.last_name,
        dateBirth: detailsEndorsee?.properties?.date_birth,
        classId: detailsEndorsee?.class?.class_id,
        entitySubTypeId: 1,
        idTypeId: detailsEndorsee?.identification?.ID_type_id,
        idNumber: detailsEndorsee?.identification?.ID_number,
        addressCountryCode:
          detailsEndorsee?.addresses &&
          detailsEndorsee?.addresses[indexAddress]?.address_country_code,
        addressState:
          detailsEndorsee?.addresses &&
          detailsEndorsee?.addresses[indexAddress]?.address_state,
        address:
          detailsEndorsee?.addresses &&
          detailsEndorsee?.addresses[indexAddress]?.address_street_code,
        addressName:
          detailsEndorsee?.addresses &&
          detailsEndorsee?.addresses[indexAddress]?.address_street,
        addressNumber:
          detailsEndorsee?.addresses &&
          detailsEndorsee?.addresses[indexAddress]?.address_number,
        addressCity:
          detailsEndorsee?.addresses &&
          detailsEndorsee?.addresses[indexAddress]?.address_city_code,
        addressCityName:
          detailsEndorsee?.addresses &&
          detailsEndorsee?.addresses[indexAddress]?.address_city,
        iDCountryCode: detailsEndorsee?.identification?.ID_country_code,
        telephone:
          detailsEndorsee?.telephones &&
          detailsEndorsee?.telephones[indexPhone]?.telephone_number,
        telephoneCountryCode:
          detailsEndorsee?.telephones &&
          detailsEndorsee?.telephones[indexPhone]?.telephone_country_code,
        entityNumber: detailsEndorsee?.entity_number,
        addressZipCode:
          detailsEndorsee?.addresses &&
          detailsEndorsee?.addresses[indexAddress]?.address_zip_code,
        telephoneTypeId:
          detailsEndorsee?.telephones &&
          detailsEndorsee?.telephones[indexPhone]?.telephone_type_id,
        telephoneId:
          detailsEndorsee?.telephones &&
          detailsEndorsee?.telephones[indexPhone]?.telephone_id,
        telephoneDefault: 1,
        addressDefault: 1,
        addressId:
          detailsEndorsee?.addresses &&
          detailsEndorsee?.addresses[indexAddress]?.address_id,
      };

      let arrRelatedEntity: any = [];
      let arrRelated: any = [];
      detailsCheck?.related_entities?.forEach((insider: any) => {
        arrRelatedEntity?.push(insider?.related_entitiy);
        arrRelated?.push({
          id_entity: insider?.id_entity,
          service_requisition_type_id: insider?.service_requisition_type_id,
        });
      });
      setItemsInsiders(arrRelatedEntity);

      let check =
        sourcePage === "discounts"
          ? {
            bank_account_id: detailsCheck?.bank_account_id,
            id_account_holder: detailsCheck?.id_account_holder,
            id_beneficiary: detailsCheck?.id_beneficiary,
            id_endorsee: detailsCheck?.id_endorsee,
            related_entities: arrRelated,
            id_cheque_beneficiary: detailsCheck?.id_cheque_beneficiary,
            cashing_intrest: detailsCheck?.cashing_intrest,
            cashing_amount: detailsCheck?.cashing_amount,
            cheque_expenses: detailsCheck?.cheque_expenses,
            allowed_expenses: detailsCheck?.allowed_expenses,
            allowed_actual_expenses:
              detailsCheck?.allowed_actual_expenses ?? 0,
            cheque_days: detailsCheck?.cheque_days,
            cheque_net: detailsCheck?.cheque_net,
            cheque_value_days: detailsCheck?.cheque_value_days,
            cheque_face_scan_path: detailsCheck?.cheque_face_scan_path,
            cheque_back_scan_path: detailsCheck?.cheque_back_scan_path,
            cheque_endorses_number: detailsCheck?.cheque_endorses_number
              ? detailsCheck?.cheque_endorses_number
              : 0,
            reference_number: checkDeatils?.reference_number || detailsCheck?.reference_number,
            cheque_date: detailsCheck?.cheque_date ?? moment().format("YYYY-MM-DD"),
            id_media_back: detailsCheck?.id_media_back,
            id_media_face: detailsCheck?.id_media_face,
            date_created: detailsCheck?.date_created,
            cheque_cashing_date: detailsCheck?.cheque_cashing_date,
            media_face_extension: detailsCheck?.media_face_extension,
            media_back_extension: detailsCheck?.media_back_extension,
            media: detailsCheck?.media,
          }
          : {
            reference_number: detailsCheck?.reference_number || checkDeatils?.reference_number,
            bank_account_id: detailsCheck?.bank_account_id,
            id_account_holder: detailsCheck?.id_account_holder,
            id_beneficiary: detailsCheck?.id_beneficiary,
            id_endorsee: detailsCheck?.id_endorsee,
            cheque_date: detailsCheck?.cheque_date ?? moment().format("YYYY-MM-DD"),
            cheque_endorses_number: detailsCheck?.cheque_endorses_number
              ? detailsCheck?.cheque_endorses_number
              : 0,
            related_entities: detailsCheck?.cheque_date?.related_entities,
            date_created: detailsCheck?.date_created,
            cheque_face_scan_path: detailsCheck?.cheque_face_scan_path,
            cheque_back_scan_path: detailsCheck?.cheque_back_scan_path,
            id_media_face: detailsCheck?.id_media_face,
            id_media_back: detailsCheck?.id_media_back,
            media_face_extension: detailsCheck?.media_face_extension,
            media_back_extension: detailsCheck?.media_back_extension,
            media: detailsCheck?.media,
          };
      let bankD = {
        key: '', country: details?.country_code ? details?.country_code : details?.country ? details?.country : "IL",
        accountNumber: details?.bank_account_number, bank: details?.bank_code, branch: details?.bank_branch_code,
        bankName: details?.bank_name, branchAddress: details?.bank_branch_address,
        branchName: details?.bank_branch_name, routingNumber: details?.routing_number
      }
      console.log(bankD, "bankd");
      console.log(details, "detailsss");

      if (bankD.bankName || bankD.accountNumber) {
        setUpdateBankAccount(bankD)
      }
      // if (check?.id_account_holder || check?.media?.media_back)
      setcheckDeatils(check)
      details && detailsCheck?.id_account_holder && setnewHolder(holder)
      detailsBeneficiary && detailsCheck?.id_beneficiary && setnewBenObject(beneficiary)
      detailsEndorsee && detailsCheck?.id_endorsee && setnewEndorsee(endorsee);
      if (details && !detailsCheck?.id_account_holder)
        setnewHolder(undefined)
    }
  }, [datacConversion])

  useEffect(() => {
    if (enums) {
      let list: any = [],
        listSomeCurrency: any = [],
        listAllCurrency: any = [];
      let arr = {
        branch: convertArrToDropdropdown([]),
        bank: convertArrToDropdropdown([]),
        countryCode: convertArrToDropdropdown([]),
        currencyCode: list,
        someOfcurrency: listSomeCurrency,
        allOfcurrency: listAllCurrency,
        genders: convertArrToDropdropdown([]),
        typeIdentityNumbers: convertArrToDropdropdown([]),
        status: convertArrToDropdropdown([]),
        telephoneCountryCode: convertArrToDropdropdown([]),
        customersType: convertArrToDropdropdown([]),
        industryClassification: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].branch !== undefined) {
          let branch = enums[i].branch;
          for (let i = 0; i < branch.length; i++) {
            if (branch[i].entity_sub_type_id !== undefined)
              arr.branch = convertArrToDropdropdown(
                branch[i].entity_sub_type_id
              );
          }
        }
        if (enums[i].customer !== undefined) {
          let customer = enums[i].customer;
          for (let j = 0; j < customer.length; j++) {
            if (customer[j].gender_id !== undefined)
              arr.genders = convertArrToDropdropdown(customer[j].gender_id);
            if (customer[j].ID_type_id !== undefined)
              arr.typeIdentityNumbers = convertArrToDropdropdown(
                customer[j].ID_type_id
              );
            if (customer[j].status_id !== undefined)
              arr.status = convertArrToDropdropdown(customer[j].status_id);
            if (customer[j].customer_type_id !== undefined)
              arr.customersType = convertArrToDropdropdown(
                customer[j].customer_type_id
              );
          }
        }
        if (enums[i].bank_account !== undefined) {
          let bank = enums[i].bank_account;
          for (let i = 0; i < bank.length; i++) {
            if (bank[i].bank_code !== undefined)
              arr.bank = convertArrToDropdropdown(bank[i].bank_code);
          }
        }
        if (enums[i].general !== undefined) {
          let general = enums[i].general;
          for (let i = 0; i < general.length; i++) {
            if (general[i].country_code !== undefined)
              arr.countryCode = convertArrToDropdropdown(
                general[i].country_code
              );
            if (general[i].telephone_country_code !== undefined)
              arr.telephoneCountryCode = convertArrToDropdropdown(
                general[i].telephone_country_code,
                "tel"
              );
            if (general[i].currency_code !== undefined) {
              general[i].currency_code?.forEach((item: any) => {
                arr.allOfcurrency.push({
                  currency_symbol: item?.currency_symbol,
                  key: item?.enum_id,
                  text: item?.enum_value,
                  is_default: item?.is_default,
                });
                if (item?.is_selected) {
                  arr.someOfcurrency.push({
                    currency_symbol: item?.currency_symbol,
                    key: item?.enum_id,
                    text: item?.enum_value,
                    is_default: item?.is_default,
                  });
                }
              });
            }
            // arr.currencyCode = convertArrToDropdropdown(general[i].currency_code)
          }
        }
      }

      arr.someOfcurrency.push({
        currency_symbol: "", key: "More",
        text: t("more"), is_default: false
      })
      arr.allOfcurrency.push({
        currency_symbol: "",
        key: "Less",
        text: t("less"),
        is_default: false,
      });
      arr.currencyCode = [...arr.someOfcurrency];
      let defaultStatusValue: any;
      defaultStatusValue = arr?.genders.find(
        ({ is_default }: any) => is_default === true
      );
      let defaultCtypeValue: any;
      defaultCtypeValue = arr?.customersType.find(
        ({ is_default }: any) => is_default === true
      );
      let defaultCStatusValue: any;
      defaultCStatusValue = arr?.status.find(
        ({ is_default }: any) => is_default === true
      );
      let defaultTypeValue: any;
      defaultTypeValue = arr?.typeIdentityNumbers.find(
        ({ is_default }: any) => is_default === true
      );
      let defaultTelephoneValue: any;
      defaultTelephoneValue = arr?.telephoneCountryCode.find(
        ({ is_default }: any) => is_default === true
      );
      let defaultCountryValue: any;
      defaultCountryValue = arr?.countryCode.find(
        ({ is_default }: any) => is_default === true
      );
      setenumsDropdown(arr);
    }
  }, [enums]);

  useEffect(() => {
    if (
      checkDeatils?.id_media_face ||
      checkDeatils?.id_media_back ||
      checkDeatils?.media?.media_face?.id_media ||
      checkDeatils?.media?.media_back?.id_media
    ) {
      setUrls();
    }
    async function setUrls() {
      let copyArrScanChecksImgs: any = [],
        copyArrChecksPdfImgs: any = [];
      if (
        checkDeatils?.media_face_extension === "scan" ||
        checkDeatils?.media?.media_face?.media_extension_file_name === "scan" ||
        checkDeatils?.media_back_extension === "scan" ||
        checkDeatils?.media?.media_back?.media_extension_file_name === "scan" ||
        checkDeatils?.media_face_extension === ".jpeg" ||
        checkDeatils?.media_back_extension === ".jpeg" ||
        checkDeatils?.media?.media_back?.media_extension_file_name ===
        ".jpeg" ||
        checkDeatils?.media?.media_face?.media_extension_file_name === ".jpeg"
      ) {
        checkDeatils?.id_media_face &&
          copyArrScanChecksImgs.push({
            original: `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.id_media_face}&id_entity=${conversionCustomer.idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}`,
          });
        checkDeatils?.id_media_back &&
          copyArrScanChecksImgs.push({
            original: `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.id_media_back}&id_entity=${conversionCustomer.idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}`,
          });
        checkDeatils?.media?.media_face?.id_media &&
          copyArrScanChecksImgs.push({
            original: `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.media?.media_face?.id_media}&id_entity=${conversionCustomer.idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}`,
          });

        checkDeatils?.media?.media_back?.id_media &&
          copyArrScanChecksImgs.push({
            original: `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.media?.media_back?.id_media}&id_entity=${conversionCustomer.idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}`,
          });
      } else {
        if (
          checkDeatils?.id_media_face &&
          checkDeatils?.media_face_extension === ".pdf"
        ) {
          copyArrChecksPdfImgs.push(
            `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.id_media_face}&id_entity=${conversionCustomer.idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}`
          );
        }
        if (
          checkDeatils?.id_media_back &&
          checkDeatils?.media_back_extension === ".pdf"
        ) {
          copyArrChecksPdfImgs.push(
            `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.id_media_back}&id_entity=${conversionCustomer.idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}`
          );
        }
        if (
          checkDeatils?.id_media_face &&
          checkDeatils?.media_face_extension !== ".pdf"
        ) {
          copyArrScanChecksImgs.push({
            original: `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.id_media_face}&id_entity=${conversionCustomer.idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}`,
          });
        }
        if (
          checkDeatils?.id_media_back &&
          checkDeatils?.media_back_extension !== ".pdf"
        ) {
          copyArrScanChecksImgs.push({
            original: `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.id_media_back}&id_entity=${conversionCustomer.idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}`,
          });
        }

        if (
          checkDeatils?.media?.media_face?.id_media &&
          checkDeatils?.media?.media_face?.media_extension_file_name === ".pdf"
        ) {
          copyArrChecksPdfImgs.push(
            `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.media?.media_face?.id_media}&id_entity=${conversionCustomer.idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}`
          );
        }
        if (
          checkDeatils?.media?.media_back?.id_media &&
          checkDeatils?.media?.media_back?.media_extension_file_name === ".pdf"
        ) {
          copyArrChecksPdfImgs.push(
            `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.media?.media_back?.id_media}&id_entity=${conversionCustomer.idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}`
          );
        }
        if (
          checkDeatils?.media?.media_face?.id_media &&
          checkDeatils?.media?.media_face?.media_extension_file_name !== ".pdf"
        ) {
          copyArrScanChecksImgs.push({
            original: `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.media?.media_face?.id_media}&id_entity=${conversionCustomer.idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}`,
          });
        }
        if (
          checkDeatils?.media?.media_back?.id_media &&
          checkDeatils?.media?.media_back?.media_extension_file_name !== ".pdf"
        ) {
          copyArrScanChecksImgs.push({
            original: `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.media?.media_back?.id_media}&id_entity=${conversionCustomer.idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}`,
          });
        }
      }
      copyArrScanChecksImgs.length > 0 && setArrScanChecksImgs(copyArrScanChecksImgs)
      copyArrChecksPdfImgs.length > 0 && setArrChecksPdfImgs(copyArrChecksPdfImgs)
      console.log(copyArrScanChecksImgs, "copyArrChecksPdfIm+++++gscopyArrChecksPdfImgs");

      setshowSpiner(false)

    }
  }, [
    checkDeatils.id_media_face,
    checkDeatils?.id_media_back,
    checkDeatils?.media?.media_face?.id_media,
    checkDeatils?.media?.media_back?.id_media,
  ]);

  useEffect(() => {
    if (relatedFactors) {
      setshowSpiner(false);
      if (relatedFactors?.key === "deleteRelatedType") {
        deleteRelatedEntity(relatedFactors);
      } else {
        if (relatedFactors?.key === "relatedTypeId") {
          let arrRelated: any = [
            ...convertions[tableType][index].financial_asset_details
              ?.related_entities,
          ];
          let i = arrRelated.findIndex(
            (item: any) => item.id_entity === relatedFactors.id
          );
          if (i !== -1)
            arrRelated[i].service_requisition_type_id = relatedFactors.typeId;
          else
            arrRelated.push({
              id_entity: relatedFactors.id,
              service_requisition_type_id: relatedFactors.typeId,
            });
          let arr: any = [...convertions[tableType]];
          arr[index].financial_asset_details.related_entities = arrRelated;

          setConvertions((convertions: any) => ({
            ...convertions,
            [tableType]: arr,
          }));
          setIfChangeRelatedType(true);
        } else {
          let arrInsiders: any[] = [...itemsInsiders];
          let index = arrInsiders.findIndex(
            (item: any) =>
              (item?.key === relatedFactors?.key && item?.key !== undefined) ||
              (item?.idEntity && item?.idEntity === relatedFactors?.idEntity) ||
              (item?.id_entity && item?.id_entity === relatedFactors?.key) ||
              (item?.id_entity && item?.id_entity === relatedFactors?.idEntity)
          );
          if (index === -1) {
            arrInsiders.push(relatedFactors);
            setItemsInsiders(arrInsiders);
            addRelatedEntity("", relatedFactors);
          } else {
            arrInsiders[index] = relatedFactors;
            setItemsInsiders(arrInsiders);
          }
        }
      }
    }
  }, [relatedFactors]);

  useEffect(() => {
    if (ifChangeRelatedType) {
      //ask if need the servermessages?

      if (typeAction === "receipt") {
        dispatch(
          receiptTransaction(
            "update",
            typeAction,
            conversionCustomer.entityName,
            convertions,
            userCredentials,
            convertions?.id_entity,
            t,
            setshowSpiner
          )
        );
      }
      if (typeAction === "cheque_cashing" || typeAction === "exchange") {
        dispatch(
          createTransaction(
            "update",
            typeAction,
            conversionCustomer.entityName,
            convertions,
            userCredentials,
            convertions?.id_entity,
            setshowSpiner
          )
        );
      }
      setIfChangeRelatedType(false);
    }
  }, [ifChangeRelatedType]);

  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog();
    }
  }, [setShowDialog]);

  useEffect(() => {
    if (contraCheck?.length === 1) {
      changeConDetails("contra_id_account", contraCheck[0].key);
    }
  }, [contraCheck]);

  useEffect(() => {
    if (idBeneficiary) {
      changeCheckDetails("id_beneficiary", idBeneficiary);
    }
  }, [idBeneficiary]);
  useEffect(() => {
    if (idEndorsee) {
      changeCheckDetails("id_endorsee", idEndorsee);
    }
  }, [idEndorsee]);
  useEffect(() => {
    if (idHolder || (!idHolder && checkDeatils?.id_account_holder)) {
      changeCheckDetails("id_account_holder", idHolder);
    }
  }, [idHolder]);

  useEffect(() => {
    if (idBankAccount) {
      changeCheckDetails("bank_account_id", idBankAccount);
    }
  }, [idBankAccount]);


  useEffect(() => {
    if (
      newHolder &&
      updateBankAccount?.accountNumber === undefined &&
      updateBankAccount?.bank_account_number === undefined
    ) {
      setshowHolderAccount(true);
    }
  }, [newHolder, updateBankAccount?.accountNumber]);

  const changeCheckDetails = async (key: string, value: any) => {
    if (key === "id_account_holder" && value === undefined) {
      await setcheckDeatils((checkDeatils: any) => ({
        ...checkDeatils,
        [key]: value,
        bank_account_id: value,
      }));
    }
    if (key === "id_endorsee") {
      await setcheckDeatils((checkDeatils: any) => ({
        ...checkDeatils,
        [key]: value,
        cheque_endorses_number: 1,
      }));
    } else {
      if (key === "cheque_endorses_number") {
        if (value === "") value = 0;
      }
      await setcheckDeatils((checkDeatils: any) => ({
        ...checkDeatils,
        [key]: value,
      }));
    }
  };
  const onChangeComboBox = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string,
    id?: string
  ): void => {
    let val = option?.key;
    let key = id ? id : "currency_code";

    let copyEnumsDropDown: any = { ...enumsDropdown };
    if (val === "More" || val === "Less") {
      if (val === "More") {
        copyEnumsDropDown.currencyCode = copyEnumsDropDown?.allOfcurrency;
        setenumsDropdown(copyEnumsDropDown);
      }
      if (val === "Less") {
        copyEnumsDropDown.currencyCode = copyEnumsDropDown?.someOfcurrency;
        setenumsDropdown(copyEnumsDropDown);
      }
    } else changeConDetails(key, val);
  };

  const changeConDetails = (key: string, value: any) => {
    let i = index;
    let arr: any = [];
    arr = [...convertions[tableType]];
    let temp = {
      currency_code: key === "currency_code" ? value : arr[i]?.currency_code,
      financial_asset_code:
        key === "financial_asset_code" ? value : arr[i]?.financial_asset_code,
      financial_asset_details: arr[i]?.financial_asset_details,
      amount: key === "amount" ? (Number(value) ? (Number(value)) : 0) : Number(arr[i]?.amount),
      exchange_rate_ils:
        key === "exchange_rate_ils" ? Number(value) : arr[i]?.exchange_rate_ils ? arr[i]?.exchange_rate_ils : 0,
      amount_ils:
        key === "amount"
          ? Number(value) * arr[i]?.exchange_rate_ils
          : key === "exchange_rate_ils"
            ? Number(value) * arr[i]?.amount
            : arr[i]?.amount_ils,
      contra_id_account:
        key === "contra_id_account" ? value : arr[i]?.contra_id_account,
      row_number: arr[i]?.row_number,
    };
    if (key === "currency_code" && gatesData && gatesData[value]) {
      let num1 = 1 / gatesData[value];
      let num = num1.toFixed(2);
      temp.exchange_rate_ils = Number(num);
    }
    temp.amount_ils = Number((
      Number(temp.exchange_rate_ils) * Number(temp.amount)
    ).toFixed(2));
    arr[i] = temp;

    let sum = 0;
    if (key === "amount" || key === "exchange_rate_ils") {
      for (let i = 0; i < arr.length; i++) {
        if (Number(arr[i]?.amount_ils))
          sum += Number(arr[i]?.amount_ils);
      }
      setsumIn && setsumIn(sum);
    }
    change(tableType, arr);
  };
  const change = async (key: string, value: any) => {
    // setIfChanges(true)
    await setConvertions({ ...convertions, [key]: value });
  };
  useEffect(() => {
    if (searchData) {
      console.log(searchData, "searchData------------");

      if (searchData?.search_results) {
        const arrData = searchData?.search_results;
        setcheckDeatils((checkDeatils: any) => ({
          ...checkDeatils,
          id_account_holder: arrData.id_entity,
          bank_account_id: arrData.bank_account_id,
        }));
        let bankD = {
          key: "",
          country: arrData?.country_code,
          bank: arrData?.bank_code,
          branch: arrData?.bank_branch_code,
          bankName: arrData?.bank_name,
          accountNumber: arrData?.bank_account_number,
          routingNumber: arrData?.routing_number,
          branchAddress: arrData?.bank_branch_address,
          branchName: arrData?.bank_branch_name,
        };
        if (bankD.bankName)
          setUpdateBankAccount(bankD);
        let indexAddress = findDefault(arrData?.addresses);
        let indexEmail = findDefault(arrData?.emails);
        let indexPhone = findDefault(arrData?.telephones);
        const customerData = {
          idNumber: arrData.identification?.ID_number,
          customerTypeId: arrData.properties?.customer_type_id,
          idTypeCountryCode: arrData.identification?.ID_country_code,
          idTypeId: arrData.identification?.ID_type_id,
          firstName: arrData.properties?.first_name,
          lastName: arrData.properties?.last_name,
          entitySubTypeId:
            arrData.types && arrData.types[0]?.entity_sub_type_id,
          entityNumber: arrData.properties?.entity_number,
          entityStatusId: arrData.status?.status_id,
          address:
            arrData?.addresses &&
            arrData?.addresses[indexAddress]?.address_street_code,
          addressName:
            arrData?.addresses &&
            arrData?.addresses[indexAddress]?.address_street,
          addressState:
            arrData?.addresses &&
            arrData?.addresses[indexAddress]?.address_state,
          addressNumber:
            arrData?.addresses &&
            arrData?.addresses[indexAddress]?.address_number,
          addressCity:
            arrData?.addresses &&
            arrData?.addresses[indexAddress]?.address_city_code,
          addressCityName:
            arrData?.addresses &&
            arrData?.addresses[indexAddress]?.address_city,
          addressZipCode:
            arrData?.addresses &&
            arrData?.addresses[indexAddress]?.address_zip_code,
          addressCountryCode:
            arrData?.addresses &&
            arrData?.addresses[indexAddress]?.address_country_code,
          telephone:
            arrData.telephones &&
              arrData.telephones[indexPhone]?.is_deleted === false
              ? arrData.telephones[indexPhone]?.telephone_number
              : "",
          telephoneCountryCode:
            arrData.telephones &&
            arrData.telephones[indexPhone]?.telephone_country_code,
          email: arrData.emails && arrData.emails[indexEmail]?.email_address,
          telephoneTypeId:
            arrData.telephones &&
            arrData.telephones[indexPhone]?.telephone_type_id,
          telephoneId:
            arrData.telephones && arrData.telephones[indexPhone]?.telephone_id,
          telephoneDefault: 1,
          emailTypeId:
            arrData.emails && arrData.emails[indexEmail]?.email_type_id,
          emailId: arrData.emails && arrData.emails[indexEmail]?.email_id,
          emailDefault: 1,
          addressTypeId:
            arrData?.addresses &&
            arrData?.addresses[indexAddress]?.address_type_id,
          addressId:
            arrData?.addresses && arrData?.addresses[indexAddress]?.address_id,
          addressDefault: 1,
        };
        setnewHolder(customerData);
      } else {
        console.log("00----------------00");

        console.log(searchData, "searchDatasearchData");

        if (searchData?.bank_name && searchData?.bank_branch_name && searchData?.bank_branch_address)

          setUpdateBankAccount({
            ...updateBankAccount, bankName: searchData?.bank_name,
            branchAddress: searchData?.bank_branch_address,
            branchName: searchData?.bank_branch_name,
            country: searchData?.country_code,
            accountNumber: searchData?.bank_account_number,
            routingNumber: searchData?.routing_number
          });
        setshowDialogHolder(true);
      }
    }
  }, [searchData]);

  // useEffect(() => {
  //   debugger
  //   if (checkDeatils?.reference_number && searchData && !searchData?.search_results) {
  //     setcheckDeatils({ ...checkDeatils, id_account_holder: '' });
  //     setnewHolder(undefined);
  //     setUpdateBankAccount({
  //       key: undefined, country: searchData?.country_code, accountNumber: searchData?.bank_account_number, bank: undefined, branch: undefined,
  //       bankName: searchData?.bank_name, branchAddress: searchData?.bank_branch_address, branchName: searchData?.bank_branch_name, routingNumber: searchData?.routing_number
  //     });
  //   }
  // }, [searchData]);

  const addRelatedEntity = (key: string, value: any) => {
    setIfChanges && setIfChanges(true);
    let arrRelated: any = checkDeatils.related_entities
      ? checkDeatils.related_entities
      : [];
    let relatedEntity = { id_entity: "", service_requisition_type_id: "" };
    if (value?.key) {
      relatedEntity = {
        id_entity: value?.key,
        service_requisition_type_id: value?.entitySubTypeId,
      };
      arrRelated.push(relatedEntity);
    }
    // setReadOnly(false)
    setcheckDeatils({ ...checkDeatils, related_entities: arrRelated });
  };
  const deleteRelatedEntity = (reletedType: any) => {
    setIfChanges && setIfChanges(true);
    let arrInsiders: any[] = [...itemsInsiders];
    let arrRelated: any = checkDeatils?.related_entities;
    arrRelated = arrRelated?.filter(
      (item: any) => item?.id_entity !== reletedType?.item?.key
    );
    arrInsiders = arrInsiders?.filter(
      (item: any) =>
        (item?.key !== reletedType?.item?.key && item?.key !== undefined) ||
        (item?.idEntity && item?.idEntity !== reletedType?.item?.key) ||
        (item?.id_entity && item?.id_entity !== reletedType?.item?.key) ||
        (item?.id_entity && item?.id_entity !== reletedType?.item?.key)
    );
    setItemsInsiders(arrInsiders);
    setcheckDeatils({ ...checkDeatils, related_entities: arrRelated });
    reletedType?.setshowSpiner(false);
  };

  const check = (currentValue: any) =>
    updateBankAccount[currentValue]?.length > 0;

  const createCheckObject = async () => {
    debugger
    let arrRequiers = ["accountNumber", "bankName", "branchName"];
    let arrRequiers2 = ["bank_account_number", "bank_branch_name", "bank_name"];
    if (
      (checkDeatils?.id_account_holder &&
        (arrRequiers.every(check) || arrRequiers2.every(check))) ||
      !checkDeatils?.id_account_holder
    ) {
      let arr: any = [];
      let convert = cloneDeep(convertions);
      arr = cloneDeep(convertions[tableType]);
      if (
        arr[index]?.contra_id_account &&
        checkDeatils?.id_account_holder &&
        checkDeatils?.id_beneficiary &&
        checkDeatils?.cheque_date &&
        checkDeatils?.reference_number &&
        arr[index].amount
      ) {
        setShowDialog(false);
        toggleHideDialog();
        let arrRelated: any = checkDeatils.related_entities
          ? checkDeatils.related_entities
          : [];
        arr[index]["financial_asset_details"] = checkDeatils;
        if (
          arr[index]["financial_asset_details"].media?.media_face?.id_media &&
          !arr[index]["financial_asset_details"]?.media?.media_face?.media_name
        )
          arr[index][
            "financial_asset_details"
          ].media.media_face.media_name = `${updateBankAccount?.accountNumber
            ? updateBankAccount?.accountNumber
            : 0
          }-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0
            }-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0
            }`;

        if (
          arr[index]["financial_asset_details"]?.media?.media_back?.id_media &&
          !arr[index]["financial_asset_details"]?.media?.media_back?.media_name
        )
          arr[index][
            "financial_asset_details"
          ].media.media_back.media_name = `${updateBankAccount?.accountNumber
            ? updateBankAccount?.accountNumber
            : 0
          }-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0
            }-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0
            }-B`;
        if (sourcePage === "discounts") {
          let temp2 = await calcChequeDetails(arr[index]);
          arr[index] = temp2;
        }
        if (!arr[index]["financial_asset_details"]?.date_created)
          arr[index]["financial_asset_details"].date_created = moment(
            new Date()
          ).format("YYYY-MM-DD");
        if (arr[index]["financial_asset_details"]?.related_entities)
          arr[index]["financial_asset_details"].related_entities = arrRelated;
        convert[tableType] = cloneDeep(arr);
        if (typeAction === "receipt") {
          dispatch(
            receiptTransaction(
              "update",
              typeAction,
              conversionCustomer.entityName,
              convert,
              userCredentials,
              convertions?.id_entity,
              t,
              setshowSpiner
            )
          );
        }
        if (typeAction === "cheque_cashing" || typeAction === "exchange") {
          dispatch(
            createTransaction(
              "update",
              typeAction,
              conversionCustomer?.entityName,
              convert,
              userCredentials,
              convertions?.id_entity,
              setshowSpiner,
              setServerMessageError,
              setMessageError,
              setTitleMessageError
            )
          );
        }
        setFocus(tableType, convertions, rowNumber);
      } else {
        setRequierdDialog(true);
        setRequiredField(true);
      }
    } else {
      setNote(t("cannotCreateHolderMessage"));
      setShowNote(true);
    }
  };

  const cancelCheckObject = async () => {
    // await dispatch(deteleFile(convertions?.id_entity, checkDeatils?.id_media_face, userCredentials))
    // await dispatch(deteleFile(convertions?.id_entity, checkDeatils?.id_media_back, userCredentials))
    setConvertions(convertionsBeforeChanges);
    setFocus(tableType, convertions, rowNumber);
    toggleHideDialog();
    setShowDialog(false);
  };

  const resetButtons = (setButton: any) => {
    setButton === "setRelatedButton"
      ? setRelatedButton(true)
      : setRelatedButton(false);
    setButton === "setDiscountDetailsButton"
      ? setDiscountDetailsButton(true)
      : setDiscountDetailsButton(false);
    setButton === "setDetailsButton"
      ? setDetailsButton(true)
      : setDetailsButton(false);
  };
  console.log(requiredField
    , checkDeatils
    , userCredentials
    , arrScanChecksImgs
    , arrChecksPdfImgs, "1234567890");

  console.log(enumsDropdown, "enumsD!!!!!++!!!!!!ropdown");

  return (
    <>
      <Dialog
        minWidth="xxl"
        maxWidth="xxl"
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          setConvertions(convertionsBeforeChanges);
          toggleHideDialog();
          setShowDialog(false);
        }}
      >
        <p
          className="title-text"
          style={{ fontSize: "30px", fontWeight: "bold" }}
        >
          {t("detailsChequeFromCustomer")}
        </p>
        <hr className="hr"></hr>
        {showSpiner && <Spinner className={`spinner-${dir}`} />}
        {serverMessageError && <DialogMessages setansDelete={setServerMessageError} subText={messageError} title={titleMessageError} setshowModalDelete={setServerMessageError} dialogType={"dialogMessage"} />}
        {showDialogFailed === true && <DialogMessages setansDelete={setShowDialogFailed} setshowModalDelete={setShowDialogFailed} dialogType={"failed"} />}
        {showDialogHolder === true && !showHolderAccount && <DialogMessages setansDelete={setshowDialogHolder} setshowModalDelete={setshowDialogHolder} setshowHolderAccount={setshowHolderAccount} dialogType={"holderAccount"} />}
        {showNote && <DialogMessages setansDelete={setShowNote} subText={note} title={t('note')} setshowModalDelete={setShowNote} dialogType={"dialogMessage"} />}
        {requiredDialog && <DialogMessages setansDelete={setRequierdDialog} setshowModalDelete={setRequierdDialog} dialogType={"requiredFields"} />}
        <div className="wrapper-collateral">
          <div
            className={`warpper-fields-right-${dir}`}
            style={{ width: "30%" }}
          >
            {contraCheck?.length > 1 ? (
              <div>
                <label style={{ marginBottom: "4px", fontWeight: 600 }}>
                  {t("selectChequeCashing")}{" "}
                  <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
                </label>
                <CustomDropdown
                  readOnly={false}
                  requiredField={
                    (requiredField || emptyRequiredFields) &&
                      !convertions[tableType][index]?.contra_id_account
                      ? true
                      : false
                  }
                  otherInputId={""}
                  hasOtherValue={false}
                  options={contraCheck}
                  label={""}
                  onChange={changeConDetails}
                  selectedKey={convertions[tableType][index]?.contra_id_account}
                  id={"contra_id_account"}
                  othertextInput={t("")}
                />
              </div>
            ) : (
              <CustomTextField
                required={true}
                value={
                  contraCheck?.find(
                    (e: any) =>
                      e.key === convertions[tableType][index]?.contra_id_account
                  )?.text
                }
                readOnly={true}
                label={t("chequeCashing")}
                onChange={""}
                id={"contra_id_account"}
              />
            )}
          </div>
          <div className={`warpper-fields-left-${dir} text-a-${dir}`}>
            <DefaultButton
              className={`bottun-${dir} edit-button`}
              style={{
                marginTop: "3px",
                backgroundColor: scanButton ? "#EAE4DE" : "",
              }}
              onClick={() => {
                setScanButton(true);
                sessionStorage.setItem("enableMultiScanCheck", "false");
                SetIsIconScanButton(false);
              }}
              name="scan"
              text={t("scanCheque")}
            />
            <DefaultButton
              className={`bottun-${dir} edit-button`}
              style={{ backgroundColor: relatedButton ? "#EAE4DE" : "" }}
              onClick={() => {
                resetButtons("setRelatedButton");
              }}
              text={t("guarantor/beneficiary")}
              name="eveningAndEnjoying"
            />
            {sourcePage === "discounts" && (
              <DefaultButton
                className="button edit-button"
                style={{
                  backgroundColor: discountDetailsButton ? "#EAE4DE" : "",
                }}
                onClick={() => {
                  resetButtons("setDiscountDetailsButton");
                }}
                text={t("discountDetails")}
                name="discountDetails"
              />
            )}
            <DefaultButton
              className={`bottun-${dir} edit-button`}
              style={{ backgroundColor: detailsButton ? "#EAE4DE" : "" }}
              onClick={() => {
                resetButtons("setDetailsButton");
              }}
              text={t("details")}
              name="details"
            />
          </div>
        </div>
        <hr className="hr" style={{ marginTop: "10px" }}></hr>
        <div>
          <div
            className="wrapper-collateral"
            style={{
              alignItems: "unset",
              width:
                itemsInsiders?.length >= 1 && relatedButton
                  ? "100%"
                  : conversionCustomer?.customerTypeId !== "1" &&
                    conversionCustomer?.customerTypeId !== "2" &&
                    conversionCustomer?.idEntity !==
                    checkDeatils?.id_account_holder
                    ? "800px"
                    : "950px",
            }}
          >
            <div
              className={`warpper-fields-right-${dir} marginL-between-container-${dir}`}
              style={{ width: discountDetailsButton === true ? "58%" : "50%" }}
            >
              <p className="title-text">{t("chequeDetails")}</p>
              <hr className="hr"></hr>
              <div className="wrapper-collateral">
                <div className={`warpper-fields-right-${dir}`}>
                  <label style={{ padding: "5px 0px", fontWeight: 600 }}>
                    {t("amount")}{" "}
                    <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
                  </label>
                  <CurrencyInput
                    className={`currency-input padding-currency-${dir}`}
                    id="amount"
                    name="amount"
                    placeholder="0.00"
                    required={
                      (requiredField || emptyRequiredFields) &&
                        !convertions[tableType][index]?.amount
                        ? true
                        : false
                    }
                    min="0.00"
                    readOnly={false}
                    value={convertions[tableType][index]?.amount}
                    style={
                      requiredField && !convertions[tableType][index]?.amount
                        ? {
                          width: "100%",
                          backgroundColor: "white",
                          border: "3px solid #db3838",
                        }
                        : { width: "100%", backgroundColor: "white" }
                    }
                    decimalsLimit={2}
                    onValueChange={(value, name) =>
                      changeConDetails(name || "", value)
                    }
                  />
                </div>
                <div className={`warpper-fields-left-${dir}`}>
                  <label style={{ padding: "5px 0px", fontWeight: 600 }}>
                    {t("currency")}{" "}
                    <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
                  </label>

                  <ComboBox
                    id={"currency_code"}
                    label={t("")}
                    styles={{
                      root: {
                        backgroundColor: "rgb(250, 249, 248)",
                        ...(dir === "ltr" && {
                          paddingRight: "9px !important",
                          paddingLeft: "32px !important",
                        }),
                      },
                    }}
                    required={true}
                    selectedKey={convertions[tableType][index]?.currency_code}
                    autoComplete="on"
                    allowFreeform={true}
                    options={enumsDropdown.currencyCode}
                    onChange={(
                      event: any,
                      option: any,
                      index: any,
                      value: any
                    ) =>
                      onChangeComboBox(
                        event,
                        option,
                        index,
                        value,
                        `currency_code`
                      )
                    }
                  />

                  {/* <CustomDropdown required={true} otherInputId={''} hasOtherValue={false} label={""} options={enumsDropdown.currencyCode} onChange={changeConDetails} selectedKey={convertions[tableType][index]?.currency_code} id={'currency_code'} othertextInput={t('')} /> */}
                </div>
              </div>
              <div
                className="wrapper-collateral"
                style={{ alignItems: "flex-start" }}
              >
                <div className={`warpper-fields-right-${dir}`}>
                  <CustomerDate
                    // isDefaultDate={true}
                    searchCustomer={conversionCustomer}
                    requiredProps={true}
                    showRequiredFildes={
                      (requiredField || emptyRequiredFields) &&
                        !checkDeatils?.cheque_date
                        ? true
                        : false
                    }
                    isValidDate={isValidDate}
                    setIsValidDate={setIsValidDate}
                    entity={checkDeatils}
                    setEntity={setcheckDeatils}
                    readOnly={false}
                    idDate="cheque_date"
                    label={t("repaymentDate")}
                  />
                </div>
                <div className={`warpper-fields-left-${dir}`}>
                  <CustomTextField
                    required={true}
                    requiredField={
                      !checkDeatils?.reference_number &&
                        (requiredField || emptyRequiredFields)
                        ? true
                        : false
                    }
                    value={checkDeatils?.reference_number}
                    readOnly={false}
                    label={t("chequeNumber/reference")}
                    onChange={changeCheckDetails}
                    id={"reference_number"}
                  />
                </div>
              </div>

              <p className="title-text" style={{ marginTop: "17px" }}>
                {t("bankDetails")}
              </p>
              <hr className="hr"></hr>
              <CustomDialogBankAccount
                showRequiredField={requiredField}
                notShowHolderButton={false}
                setSearchData={setSearchData}
                isCheck={true}
                bankRow={bankRow}
                rowNumber={rowNumber}
                bankDetails={updateBankAccount}
                setbankDetails={setUpdateBankAccount}
                conversions={"checkDetailsDialogPage"}
                userCredentials={userCredentials}
              />
            </div>
            {detailsButton && (
              // marginLeft: "3%",
              <div
                className={`warpper-fields-left-${dir} `}
                style={{ width: "60%" }}
              >
                <Details
                  requiredField={
                    requiredField &&
                      !idHolder &&
                      !checkDeatils?.id_account_holder
                      ? true
                      : false
                  }
                  changeCheckDetails={changeCheckDetails}
                  newObject={newHolder}
                  title={t("holderDetails")}
                  setNewObject={setnewHolder}
                  setNewObjectId={setidHolder}
                  setshowDialog={setshowHolderAccount}
                  showDialog={showHolderAccount}
                  setsearch={setsearch}
                  conversionCustomer={conversionCustomer}
                  userCredentials={userCredentials}
                  setidBankAccount={setidBankAccount}
                  setUpdateBankAccount={setUpdateBankAccount}
                  updateBankAccount={updateBankAccount}
                  idHolder={idHolder}
                />
                <Details
                  requiredField={
                    requiredField && !checkDeatils?.id_beneficiary
                      ? true
                      : false
                  }
                  changeCheckDetails={changeCheckDetails}
                  newObject={newBenObject}
                  title={t("beneficiaryDetails")}
                  setNewObject={setnewBenObject}
                  setNewObjectId={setidBeneficiary}
                  setshowDialog={setshowBeneficiary}
                  showDialog={showBeneficiary}
                  setsearch={setsearch}
                  conversionCustomer={conversionCustomer}
                  userCredentials={userCredentials}
                  setUpdateBankAccount={setUpdateBankAccount}
                />
                <Details
                  changeCheckDetails={changeCheckDetails}
                  newObject={newEndorsee}
                  title={t("endorseeDetails")}
                  setNewObject={setnewEndorsee}
                  setNewObjectId={setidEndorsee}
                  setshowDialog={setshowEndorsee}
                  showDialog={showEndorsee}
                  setsearch={setsearch}
                  conversionCustomer={conversionCustomer}
                  userCredentials={userCredentials}
                />
                <div style={{ width: "100%" }}>
                  <CheckCarousel
                    showRequiredField={requiredField}
                    checkDeatils={checkDeatils}
                    setCheckDeatils={setcheckDeatils}
                    userCredentials={userCredentials}
                    updateBankAccount={updateBankAccount}
                    idEntity={conversionCustomer?.idEntity}
                    arrScanChecksImgs={arrScanChecksImgs}
                    // isFinishScan={isFinishScan}
                    // setIsFinishScan={setIsFinishScan}
                    setArrScanChecksImgs={setArrScanChecksImgs}
                    arrChecksPdfImgs={arrChecksPdfImgs}
                  />
                </div>
              </div>
            )}
            {scanButton && (
              <div>
                <ScanCheck
                  setAllScanChecks={setAllScanChecks}
                  //  setIsFinishScan={setIsFinishScan}
                  isIconScanButton={isIconScanButton}
                  bankEnum={enumsDropdown?.bank}
                  updateBankAccount={updateBankAccount}
                  setArrScanChecksImgs={setArrScanChecksImgs}
                  setArrInformationScan={setArrInformationScan}
                  arrInformationScan={arrInformationScan}
                  setCheckDeatils={setcheckDeatils}
                  checkDeatils={checkDeatils}
                  setSearchData={setSearchData}
                  setUpdateBankAccount={setUpdateBankAccount}
                  setShowDialog={setScanButton}
                  idEntity={conversionCustomer?.idEntity}
                  setCheckType={setCheckType}
                />
              </div>
            )}
            {discountDetailsButton &&
              (conversionCustomer?.customerTypeId === "1" ||
                (conversionCustomer?.customerTypeId === "2" &&
                  conversionCustomer?.idEntity ===
                  checkDeatils?.id_account_holder)) ? (
              <div className="wrapper-collateral">
                <DiscountDetails
                  searchCustomer={conversionCustomer}
                  setActions={setConvertions}
                  actions={convertions}
                  setcheckDeatils={setcheckDeatils}
                  checkDeatils={checkDeatils}
                  tableType={tableType}
                  rowNumber={rowNumber}
                ></DiscountDetails>
                <FairCredit
                  financialAssets={convertions[tableType][index]}
                  checkDeatils={checkDeatils}
                ></FairCredit>
              </div>
            ) : (
              discountDetailsButton && (
                <DiscountDetails
                  searchCustomer={conversionCustomer}
                  setActions={setConvertions}
                  actions={convertions}
                  setcheckDeatils={setcheckDeatils}
                  checkDeatils={checkDeatils}
                  tableType={tableType}
                  rowNumber={rowNumber}
                ></DiscountDetails>
              )
            )}
            {relatedButton && (
              <div style={{ width: "50%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  <PrimaryButton
                    className={`bottun-${dir}`}
                    disabled={false}
                    allowDisabledFocus
                    checked={false}
                    onClick={() => setaddFactorDialog(true)}
                    name="add"
                    text={t("addFactor")}
                    iconProps={Icons.addFriend}
                  />
                  {addFactorDialog && (
                    <EditableRelatedFactors
                      setShowDialog={setaddFactorDialog}
                      setshowSpiner={setshowSpiner}
                      setRelatedFactors={setRelatedFactors}
                      isConversions={true}
                    />
                  )}
                </div>
                <div>
                  {itemsInsiders?.length >= 1 && (
                    <ListRelatedFactors
                      dataCustomerCheck={
                        datacConversion[tableType][index]
                          ?.financial_asset_details
                      }
                      setshowSpiner={setshowSpiner}
                      setRelatedFactors={setRelatedFactors}
                      dataCustomer={itemsInsiders}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <DialogFooter>
            <DefaultButton
              onClick={() => {
                cancelCheckObject();
              }}
              text={t("cancel")}
            />
            <PrimaryButton
              style={{ zIndex: 10 }}
              onClick={() => {
                createCheckObject();
              }}
              text={t("save")}
            />
          </DialogFooter>
        </div>
      </Dialog >
    </>
  );
};
