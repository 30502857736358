import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useBoolean } from "@fluentui/react-hooks";
import {
  Icon,
  ComboBox,
  IComboBoxOption,
  IComboBox,
  IComboBoxStyles,
} from "@fluentui/react";

import { convertArrToDropdropdown } from "./GlobalFunctions";
import { CustomTextField } from "./../../../shared/components/TextField";
import { CustomDropdown } from "./../../../shared/components/Option";
import { loadStreetEnums } from "../../../store/actions/dataActions";

export const Address = (props: any) => {
  const {
    setIfCahnges,
    setEntity,
    entity,
    classStyle,
    showRequiredFildes,
    readOnly,
  } = props;
  const [t] = useTranslation();
  const [dir, setDir] = useState("");
  const lang = useSelector(({ dataReducer }: any) => dataReducer?.language);
  const dispatch = useDispatch();
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [enumsDropdown, setenumsDropdown] = useState<any>({
    countryCode: [],
    addressType: [],
  });
  const [listStreets, setListStreets] = useState<any>([]);
  const [arrCities, setArrCities] = useState<IComboBoxOption[]>([]);
  const [arrStreets, setArrStreets] = useState<IComboBoxOption[]>([]);
  const [allowFreeform] = useBoolean(true);
  const authReducer = useSelector(({ authReducer }: any) => authReducer);
  const [newKey, setNewKey] = useState<any>(1);
  const [isLess, setisLess] = useState(true);
  const [, /*currentStreet*/ setCurrentStreet] = useState<any>();
  const [defaultCountry, setDefaultCountry] = useState<any>();
  const postalCode = `https://israelpost.co.il/%D7%A9%D7%99%D7%A8%D7%95%D7%AA%D7%99%D7%9D/%D7%90%D7%99%D7%AA%D7%95%D7%A8-%D7%9E%D7%99%D7%A7%D7%95%D7%93/`;

  const customStyles: Partial<IComboBoxStyles> = {
    root: { backgroundColor: "white" },
    input: { backgroundColor: "white" },
  };
  const requiredFieldStyle: Partial<IComboBoxStyles> = {
    root: {
      backgroundColor: readOnly ? "#FAF9F8" : "white",
      border: "solid red 2px",
    },
    input: { backgroundColor: readOnly ? "#FAF9F8" : "white" },
  };
  useEffect(() => {
    if (lang) {
      setDir(lang);
    }
  }, [lang]);

  useEffect(() => {
    defaultCountry &&
      !entity?.addressCountryCode &&
      setEntity({
        ...entity,
        addressCountryCode: defaultCountry,
      });
  }, [defaultCountry]);

  /////insert all enums
  useEffect(() => {
    if (enums) {
      let arr = {
        countryCode: convertArrToDropdropdown([]),
        selectedCountryCode: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].general !== undefined) {
          let country = enums[i].general;
          for (let i = 0; i < country.length; i++) {
            if (country[i].country_code !== undefined) {
              country[i].country_code?.forEach((country: any) => {
                if (country?.is_selected === true) {
                  arr.selectedCountryCode.push({
                    key: country.enum_id,
                    text: country.enum_value,
                    is_default: country?.is_default === true && true,
                  });
                }
                arr.countryCode.push({
                  key: country.enum_id,
                  text: country.enum_value,
                  is_default: country?.is_default === true && true,
                });
              });
              arr.selectedCountryCode.push({ key: "more", text: t("more") });
              arr.countryCode.unshift({ key: "less", text: t("less") });
            }
            if (country[i].address_city_code !== undefined) {
              let arrCitiesils: IComboBoxOption[] = [];
              country[i].address_city_code?.forEach((address: any) => {
                arrCitiesils.push({
                  key: address.enum_id,
                  text: address.enum_value,
                });
              });
              setArrCities(arrCitiesils);
            }
          }
        }
      }
      let defaultCountryValue: any;
      defaultCountryValue = arr?.countryCode.find(
        ({ is_default }: any) => is_default === true
      )?.key;
      setDefaultCountry(defaultCountryValue);
      setenumsDropdown(arr);
    }
  }, [enums]);

  useEffect(() => {
    if (isLess) {
      if (
        enumsDropdown?.selectedCountryCode?.length > 0 &&
        entity?.addressCountryCode
      ) {
        let isExsist = enumsDropdown.selectedCountryCode.find(
          (item: any) => item.key === entity?.addressCountryCode
        );
        if (!isExsist) setisLess(false);
      }
    }
  }, [enumsDropdown?.selectedCountryCode, entity.addressCountryCode]);

  useEffect(() => {
    if (listStreets?.length > 0) {
      let arr = convertAddressArrToDropdropdown(listStreets);
      if (
        entity.addressCity !== "" &&
        entity?.addressCountryCode === "IL" &&
        entity.addressName !== ""
      ) {
        // let isExit = arr.find((address: any) => address.key === Number(entity?.address));
        let isExit = arr.find(
          (addressStreet: any) =>
            Number(addressStreet.code) === Number(entity?.Street)
        );
        if (!isExit) {
          arr.push({
            key: Number(entity?.address),
            text: entity?.addressName ? entity?.addressName : "",
            code: Number(entity?.Street),
          });
          // setNewKey((entity?.address ? Number(entity?.address) : newKey) + 1);
          setNewKey(
            (entity?.address ? Number(entity?.address) : arr.length) + 1
          );
        } else {
          setEntity({ ...entity, Street: isExit?.key });
          setCurrentStreet(isExit);
          setNewKey(arr.length);
        }
      }
      setArrStreets(arr);
    }
  }, [listStreets]);

  useEffect(() => {
    if (entity.addressCityName !== "" && entity.addressCountryCode === "IL") {
      dispatch(
        loadStreetEnums(
          authReducer.data.client.id_entity,
          authReducer.data.client.id_entity,
          authReducer.data.branch.id_entity,
          String(entity.addressCity) ? String(entity.addressCity) : "",
          setListStreets
        )
      );
    }
  }, [entity.addressCityName]);

  const convertAddressArrToDropdropdown = (arr: Object[]) => {
    let newArr: any[] = [];
    arr?.forEach((item: any, i: number) => {
      item?.is_default === true
        ? newArr.push({
            key: i,
            text: item.enum_value,
            is_default: true,
            code: item.enum_id,
          })
        : newArr.push({ key: i, text: item.enum_value, code: item.enum_id });
    });
    return newArr;
  };

  const updateEntity = (key: string, value: any) => {
    if (
      key === "addressCountryCode" &&
      (value === "more" || value === "less")
    ) {
      setisLess(!isLess);
    } else {
      if (key === "addressCountryCode") {
        setIfCahnges && setIfCahnges(true);
        entity?.iDCountryCode
          ? setEntity({
              ...entity,
              [key]: value,
              ["iDCountryCode"]: value,
              ["address"]: "",
              ["addressCity"]: "",
              ["addressName"]: "",
              ["addressCityName"]: "",
            })
          : setEntity({
              ...entity,
              [key]: value,
              ["address"]: "",
              ["addressCity"]: "",
              ["addressName"]: "",
              ["addressCityName"]: "",
            });
      } else {
        setIfCahnges && setIfCahnges(true);
        setEntity({ ...entity, [key]: value });
      }
    }
  };

  const onChangeCity = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ): void => {
    let key: any = option?.key;
    let text: any = option?.text;
    setIfCahnges && setIfCahnges(true);
    setEntity({
      ...entity,
      addressCityName: text,
      addressCity: key,
      address: "",
      addressName: "",
    });
  };

  const onChangeStreet = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ): void => {
    let key: any = option?.key;
    let text: any = option?.text;
    if (allowFreeform && !option && value) {
      key = newKey;
      key = Number(key);
      let arr: IComboBoxOption[] = [...arrStreets];
      arr.push({ key: key!, text: value });
      setArrStreets(arr);
      setNewKey(newKey + 1);
    }

    let currentStreet: any = arrStreets.find(
      (street: any) => street.key === key
    );
    let currectStreet: any = arrStreets.find(
      (street: any) => street.code === currentStreet?.code
    );
    setCurrentStreet(currentStreet);
    setEntity({
      ...entity,
      address: currectStreet?.key,
      addressName: text ? text : value,
    });
    setIfCahnges && setIfCahnges(true);
  };

  return (
    <>
      <div className={`${classStyle && "wrapper-collateral"}`}>
        <div style={{ display: "flex", gap: "6px", width: "100%" }}>
          <div style={{ width: "50%" }}>
            {!readOnly ? (
              !isLess ? (
                <CustomDropdown
                  otherInputId={""}
                  hasOtherValue={false}
                  options={enumsDropdown.countryCode}
                  label={t("country")}
                  onChange={updateEntity}
                  selectedKey={entity?.addressCountryCode}
                  id={"addressCountryCode"}
                  othertextInput={t("")}
                />
              ) : (
                <CustomDropdown
                  otherInputId={""}
                  hasOtherValue={false}
                  options={enumsDropdown.selectedCountryCode}
                  label={t("country")}
                  onChange={updateEntity}
                  selectedKey={entity?.addressCountryCode}
                  id={"addressCountryCode"}
                  othertextInput={t("")}
                />
              )
            ) : (
              <CustomTextField
                id=""
                onChange={""}
                label={t("country")}
                readOnly={readOnly ? true : false}
              />
            )}
          </div>
          <div style={{ width: "50%" }}>
            {entity.addressCountryCode === "IL" && !readOnly ? (
              <ComboBox
                id={"addressCity"}
                label={t("city")}
                selectedKey={Number(entity.addressCity)}
                styles={
                  (dir === "ltr" && {
                    root: {
                      paddingRight: "9px !important",
                      paddingLeft: "32px !important",
                    },
                  }) ||
                  (showRequiredFildes && entity.addressCity === ""
                    ? requiredFieldStyle
                    : customStyles)
                }
                autoComplete="on"
                allowFreeform={true}
                options={arrCities}
                onChange={onChangeCity}
              />
            ) : (
              <CustomTextField
                readOnly={readOnly ? true : false}
                requiredField={
                  showRequiredFildes && entity?.addressCityName === ""
                    ? true
                    : false
                }
                value={entity.addressCityName}
                label={t("city")}
                onChange={updateEntity}
                id={"addressCityName"}
              />
            )}
          </div>
        </div>
      </div>
      <div className={`${classStyle && "wrapper-collateral"}`}>
        <div style={{ display: "flex", gap: "6px" }}>
          <div style={{ width: "70%" }}>
            {entity.addressCountryCode === "IL" &&
            entity.addressCity !== "" &&
            !readOnly ? (
              <div>
                <ComboBox
                  id={"address"}
                  label={t("street")}
                  styles={
                    (dir === "ltr" && {
                      root: {
                        paddingRight: "9px !important",
                        paddingLeft: "32px !important",
                      },
                    }) ||
                    (showRequiredFildes &&
                    (entity.address === "" || entity.addressName === "")
                      ? requiredFieldStyle
                      : customStyles)
                  }
                  selectedKey={
                    entity.address !== "" ? Number(entity.address) : ""
                  }
                  autoComplete="on"
                  allowFreeform={true}
                  options={arrStreets}
                  onChange={onChangeStreet}
                />
              </div>
            ) : (
              <CustomTextField
                requiredField={
                  showRequiredFildes && entity?.addressName === ""
                    ? true
                    : false
                }
                value={entity.addressName}
                label={t("street")}
                onChange={updateEntity}
                id={"addressName"}
              />
            )}
          </div>
          <div style={{ width: "30%" }}>
            <CustomTextField
              requiredField={
                showRequiredFildes && entity?.addressNumber === ""
                  ? true
                  : false
              }
              value={entity.addressNumber}
              label={t("number")}
              onChange={updateEntity}
              id={"addressNumber"}
            />
          </div>
        </div>
      </div>
      <div className="wrapper-collateral">
        <div className={`address-zip-code-${dir}`}>
          {/* requiredField={showRequiredFildes && entity?.addressZipCode === "" ? true : false}  */}
          <CustomTextField
            value={entity.addressZipCode}
            label={t("postalCode")}
            onChange={updateEntity}
            id={"addressZipCode"}
          />
        </div>
        <div
          onClick={() => {
            window.open(`${postalCode}`, "_blank");
          }}
          className={`div-adress-zip-code-${dir}`}
        >
          <Icon iconName="refresh"></Icon>
        </div>
      </div>
    </>
  );
};
