
import '../../scssPages/sub-header.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

interface Title{
    title:string
    nameInTitle?:string
}

const Title=(prop:Title)=>{
    return(
        <h1 className="title">{prop.title}
        {(prop.nameInTitle!==' '&& prop.nameInTitle) &&<FontAwesomeIcon icon={faChevronLeft} className="iconName" />}
        {prop.nameInTitle}</h1>
    );
} 

export default Title;