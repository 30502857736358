import { Nav, INavLinkGroup, INavStyles } from '@fluentui/react/lib/Nav';
import '../../scssPages/sidebar.scss';


export const SideMainClient = (props: any) => {

  const { setcategory } = props
  const navStyles: Partial<INavStyles> = {
    link: {
      color: '#FFB400 ',
      fontFamily: 'Segoe UI',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      height: '60px',
      padding: '10px',
      display: '-webkit-inline-box'
    },
  };

  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          key: 'ButtonC',
          //  iconProps: { iconName: "News"},         
          name: 'לקוחות',
          url: '',
          onClick: () => setcategory('customer')
        },
        {
          key: 'ButtonS',
          //  iconProps: { iconName: "Sunny" },
          name: 'הגדרות',
          url: '',
          onClick: () => setcategory('setting')

        }
        // ,{
        //   key: 'convert',
        //   //  iconProps: { iconName: "Sunny" },
        //   name: 'המרות',
        //   url: '',
        //   onClick: () => setcategory('convert')

        // },

      ],
    }]

  function linkClickHandler(event: any) {
    event.preventDefault();
  }

  return (
    <Nav className="category" styles={navStyles} onLinkClick={linkClickHandler} ariaLabel="Nav example similar to one found in this demo page" groups={navLinkGroups} />
  );
};


