import { cloneDeep } from 'lodash';
import { SET_DATA_CONVERSION, SAVE_DRAFT_CONVERSION_CHANGES, SET_GATES_DATA,
  SET_INSIDER_DATA_CONVERSION, RESET_DATA_CONVERSION,
  SET_DATA_NEW_TRANSACTION } from '../actions/types';

const initialState = {
    dataConversion: null,
    saveDraftChanges: null,
    gatesData: null,
    dataNewTransaction:{}
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SET_DATA_CONVERSION: {
            let data: any = cloneDeep(action.payload)
            sessionStorage.setItem('dataConversion', JSON.stringify(data));
            if (data?.transaction_type_id === "cheque_cashing") {
                data?.financial_assets_in?.forEach((element: any,) => {
                    if (element?.financial_asset_details?.cashing_intrest)
                        element.financial_asset_details.cashing_intrest *= 100
                })
            }
            return {
                ...state,
                dataConversion: cloneDeep(data),
            }
        }

        case RESET_DATA_CONVERSION: {
            sessionStorage.setItem('dataConversion', JSON.stringify(action.payload));
            return {
                ...state,
                dataConversion: null
            }
        }
        case SET_INSIDER_DATA_CONVERSION: {
            let arr: any = [];
            arr = cloneDeep(state)
            let index = arr.dataConversion.related_entities.findIndex((entity: any) => entity?.id_entity === action.payload?.id_entity)
            if (index !== -1) arr.dataConversion.related_entities[index].related_entitiy = action.payload
            sessionStorage.setItem('dataConversion', JSON.stringify(arr.dataConversion));
            return {
                ...state,
                dataConversion: cloneDeep(arr.dataConversion),
            }
        }
        case SAVE_DRAFT_CONVERSION_CHANGES: {
            return {
                ...state,
                saveDraftChanges: action.payload,
            }
        }
        case SET_GATES_DATA: {
            return {
                ...state,
                gatesData: action.payload,
            }
        } 
         case SET_DATA_NEW_TRANSACTION: {
            return {
                ...state,
                dataNewTransaction: action.payload,
            }
        }
        default: return state;
    }
}