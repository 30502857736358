import { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { PrimaryButton } from "@fluentui/react";

import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils'
import { DialogMessages } from "../../shared/components/dialog/DialogMessages";
import Subtitle from '../../shared/components/Subtitle';
import { CustomTextField } from '../../shared/components/TextField';
import { CustomDropdown } from "../../shared/components/Option";
import { CustomToggle } from "../../shared/components/Toggle";
import { UpdateDefaultPlan } from '../../store/actions/pricePlanAction'
import { readCustomerId } from '../../store/actions/customerActioin'
import { IUserCredentials } from '../customerDetails/CustomerDetails'
import "./customerPlan.css"
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions';

const CustomerPlan = (props: any) => {
    const { mode } = props
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const programProperties = useSelector(({ customerReducer }: any) => customerReducer?.dataCustomer?.price_program)
    const [items, setitems] = useState([{}])
    const [updateItems, setupdateItems] = useState([{}])
    const [updateItemsBeforeChanges, setUpdateItemsBeforeChanges] = useState([{}])
    const [defaultPlan, setdefaultPlan] = useState(programProperties?.is_linked_base_template || programProperties?.base_template_id ? true : false)
    // const [defaultPlan, setdefaultPlan] = useState(true)
    const [readOnly, setreadOnly] = useState(true)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const customerReducer = useSelector(({ customerReducer }: any) => customerReducer)
    const [idEntity, setidEntity] = useState("")
    const [listPrograms, setListProgram] = useState([{}])
    const [baseTemplateId, setbaseTemplateId] = useState('')
    const [selectedProgram, setselectedProgram] = useState('')
    const [showDialogSave, setShowDialogSave] = useState(false)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()

    // const changeRow = async (field: any, value: any, index: any) => {
    //     let arr
    //     let element
    //     arr = await [...updateItems]
    //     element = await arr.filter((i: any) => i.key === index)
    //     //@ts-ignore
    //     element[0][field] = value
    //     //@ts-ignore
    //     if (element[0]["calculation_type"] === "percent" || element[0]["calculation_type"] === "אחוז")
    //         //@ts-ignore
    //         element[0]["percent"] = value
    //     setupdateItems(arr)
    // }



    useEffect(() => {
        if (authReducer)
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
    }, [authReducer])

    useEffect(() => {
        let arrBranchPrograms: any = []
        if (programProperties?.base_template_id === undefined) {
            setListProgram([])
            setbaseTemplateId('undefined')
            setselectedProgram('undefined')
            setdefaultPlan(false)
        }
        else {
            setbaseTemplateId(programProperties?.base_template_id)
            setselectedProgram(programProperties?.base_template_id)
        }
        authReducer?.data?.branch?.price_programs_templates?.forEach((program: any, index: any) => {
            arrBranchPrograms.push({ key: program?.price_program_id, text: program?.price_program_name })
        })
        setListProgram(arrBranchPrograms)
    }, [authReducer, programProperties])

    useEffect(() => {
        setidEntity(customerReducer.dataCustomer?.id_entity)
    }, [customerReducer])

    useEffect(() => {
        let arr: object[] = []
        programProperties?.price_program_properties?.forEach((element: any, index: number) => {
            if (element.calculation_type === "const" || element.calculation_type === "קבוע" || element.calculation_type === "Constant")
                arr.push({
                    key: index, description: element.description, calculation_type: t(element.calculation_type),
                    value: element.value, type: element.type, name: element.name, order: element.order
                })
            else {
                arr.push({
                    key: index, description: element.description, calculation_type: t(element.calculation_type)
                    , percent: element.percent * 100, value_min: element.value_min, value_max: element.value_max, value: element.percent * 100
                    , fee: element.fee, name: element.name, order: element.order
                })
            }
        });
        setitems(arr)
        setupdateItems(arr)
        setUpdateItemsBeforeChanges(arr)
    }, [programProperties]);


    const editProgram = (key: string, value: any) => {
        // let properties: any = [...updateItems]
        let properties: any = cloneDeep(updateItems)
        const [id, place] = key.split('.');
        properties?.forEach((property: any) => {
            if (property?.name === id && (property?.calculation_type === "Percent" || property?.calculation_type === "אחוז")) {
                property[place] = value
            }
            else {
                if (property?.name === key)
                    //  Number()
                    property.value = value
            }
        })
        setupdateItems(properties)
    }
    const cancelEdit = (isTRue: boolean) => {
        setupdateItems(updateItemsBeforeChanges)
        setreadOnly(isTRue)
    }

    const saveProgram = async (statusProgram: boolean) => {
        if (userCredentials !== null) {

            let properties: any = [{}]
            properties = cloneDeep(updateItems)
            properties?.forEach((property: any) => {
                if ((property?.calculation_type === "Percent" || property?.calculation_type === "אחוז")) {
                    property.percent = Number(property.percent) / 100
                    property.value = property.percent
                }
            })
            setupdateItems(properties)
            //updateItems-properties
            statusProgram ?
                await UpdateDefaultPlan(userCredentials, idEntity, selectedProgram, properties, setShowDialogSave) :
                await UpdateDefaultPlan(userCredentials, idEntity, undefined, properties, setShowDialogSave)
            dispatch(readCustomerId(idEntity, userCredentials, "", "", "", false));
            setreadOnly(true);
        }
    }

    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
    ? 'page-read' : 'page-read-only'}`}>
            {mode !== "update" && <> <HeaderUtils title={"customers"} cancelFunc={() => { cancelEdit(true) }} editBtn={readOnly ? true : false} uploadBtn={readOnly ? false : true} saveFunc={() => saveProgram(false)} editFunc={() => setreadOnly(false)} disabledSave={readOnly ? true : false} />
            </>}
            <br></br><br></br>
            {showDialogSave && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"save"} />}
            <Subtitle title={t("customerPlan")} />
            <div className={`div-customer-plan-${dir}`}>
            </div>
            <div className={`content-div-${dir}`} >
                <p className="title-text">{t("fees")}</p>
                <div className="wrapper-collateralPlan">
                    <div className={`warpper-fields-rightPl-${dir}`}>
                        <hr className="hr" style={{ width: "100%" }}></hr>

                        <div style={{ width: "100%" }}>
                            <div className="titleC">
                                <p className="title-text boldC" style={{ width: "32.5%", marginTop: '0' }}>{t("type_action")}</p>
                                <p className="title-text boldC" style={{ width: "25.5%", marginTop: '0' }}>{t("calculationType")}</p>
                                <p className="title-text boldC" style={{ width: "60%", marginTop: '0' }}>{t("details")}</p></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0', height: "2px" }} ></hr>
                        {updateItems.map((item: any, index: number) => (
                            <div key={index}>
                                {item?.type === "fee" || item?.fee === "fee" ? <>
                                    <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                                    {(item?.calculation_type === "const" || item?.calculation_type === "קבוע" || item?.calculation_type === "Constant") ?
                                        < div style={{ width: "100%" }}>
                                            <div className={`inlineBP-${dir}`}>
                                                <p className="boldPP">{t(`${item?.name}`)}</p>
                                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                                    <p className="boldPP">{t("const")}</p></div>
                                                <div style={{ width: "60%", height: "fit-content", marginTop: "2px", marginBottom: " 6px" }}>
                                                    <div className="inside">
                                                        <CustomTextField label={" "} readOnly={readOnly} value={item.value} onChange={editProgram} id={item?.name} /></div></div></div>
                                        </div> :
                                        <div style={{ width: "100%" }}>
                                            <div className={`inlineBP-${dir}`} style={{ marginTop: "1px", marginBottom: "9px" }}>
                                                <p className="boldPP">{t(`${item?.name}`)}</p>
                                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                                    <p className="boldPP">{t("percent")}</p></div>
                                                <div className="fields">
                                                    <div className="field" >
                                                        <CustomTextField label={" "} readOnly={readOnly} value={item?.percent} onChange={editProgram} id={item?.name + ".percent"} prefix="%" />
                                                    </div>  <p className="boldF">{t("minimum")}</p>
                                                    <div className="field" >
                                                        <CustomTextField label={" "} readOnly={readOnly} value={item?.value_min} onChange={editProgram} id={item?.name + ".value_min"} /></div>
                                                    <p className="boldF">{t("maximum")}</p>
                                                    <div className="field" >
                                                        <CustomTextField label={" "} readOnly={readOnly} value={item?.value_max} onChange={editProgram} id={item?.name + ".value_max"} /></div>
                                                </div></div>
                                        </div>}
                                </> : ""}
                            </div>
                        ))}
                        <div style={{ marginTop: "78px", marginBottom: "68px" }}>
                            <p className="title-text" >{t("other_settings")}</p>
                            <hr className="hr" style={{ width: "100%" }} ></hr>
                            {/* <hr className="hr" style={{ width: "100%" }}></hr> */}

                            <div style={{ width: "100%" }}>
                                <div className="titleC">
                                    <p className="title-text boldC" style={{ width: "32.5%", marginTop: '0' }}>{t("name")}</p>
                                    <p className="title-text boldC" style={{ width: "25.5%", marginTop: '0' }}>{t("calculationType")}</p>
                                    <p className="title-text boldC" style={{ width: "60%", marginTop: '0' }}>{t("details")}</p></div>
                            </div>
                            <hr className="hr" style={{ width: "100%", marginBottom: '0', height: "3px" }} ></hr>
                            {updateItems.map((item: any, index: number) => (
                                <div key={index + 4}>
                                    {item?.type === "setting" && <>
                                        {/* <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr> */}
                                        < div style={{ width: "100%" }}>
                                            <div className={`inlineBP-${dir}`}>
                                                <p className="boldPP">{t(`${item?.name}`)}</p>
                                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                                    <p className="boldPP">{t("const")}</p></div>
                                                <div style={{ width: "60%", height: "fit-content", marginTop: "2px", marginBottom: " 6px" }}>
                                                    <div className="inside">
                                                        <CustomTextField label={" "} readOnly={readOnly} value={item.value} onChange={editProgram} id={item?.name} /></div></div></div>
                                        </div>
                                    </>}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={`warpper-fields-leftP-${dir}`} style={{ marginTop: "12px" }}>
                        <div className="titleC">
                            <p className="title-text boldC">{t("baseTemplate")}</p></div>
                        <hr className="hr" style={{ width: "80%", height: "2px" }} ></hr>
                        <div style={{ width: "80%" }}>
                            <CustomDropdown readOnly={readOnly} otherInputId={'idTypeOther'} hasOtherValue={true} options={listPrograms} label={''} onChange={(id: any, val: any) => { setselectedProgram(val); setbaseTemplateId(val) }} selectedKey={baseTemplateId} id={'idTypeId'} othertextInput={t('typeIdentityNumberOther')} /><br></br>
                            <CustomToggle onText={t('linkedToBaseTemplate')} readOnly={readOnly} onChange={() => setdefaultPlan(!defaultPlan)} id={'isLocked'} checked={defaultPlan} defaultChecked={defaultPlan} offText={t('linkedToBaseTemplate')} />
                            <PrimaryButton onClick={() => saveProgram(true)} ariaDescription="create"
                                disabled={defaultPlan && baseTemplateId !== '' && baseTemplateId !== 'undefined' ? false : true} className='buttonPlan' allowDisabledFocus checked={false} name="create" text={t('updateProgramFromBaseTemplate')} />
                        </div>
                    </div>
                </div>
                {/* <div className="marginRight">
                <TitleText title={t("customerPlan")}></TitleText></div>
            <div className="wrap-table">
                <CustemTable changeRow={changeRow} readOnly={readOnly} tableName={"plan"} columns={columns} allItems={updateItems} renderItemColumn addCustem={true} isFooter={true} /></div>
            {mode !== "update" ?
                <div className="marginRight">
                    <p>{t("baseTemplate")}</p>
                    <hr className="hr text-width" />
                    <div style={{ width: "40%" }}>
                        <CustomDropdown otherInputId={'idTypeOther'} hasOtherValue={true} options={baseProgramOption} label={''} onChange={(id: any, val: any) => setselectedProgram(val)} selectedKey={baseTemplateId} id={'idTypeId'} othertextInput={t('typeIdentityNumberOther')} />
                        <CustomToggle onText={t('linkedToBaseTemplate')} onChange={() => setdefaultPlan(true)} id={'isLocked'} defaultChecked={defaultPlan} offText={t('linkedToBaseTemplate')} />
                        <PrimaryButton onClick={() => saveProgram(true)} ariaDescription="create" className='button' allowDisabledFocus checked={false} name="create" text={t('updateProgramFromBaseTemplate')} />
                    </div>
                </div> : ''} */}
            </div>
        </div>
    )
}

export default CustomerPlan
