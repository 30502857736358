import { useEffect, useState } from 'react'
import { BeneficiaryDialog } from '../../shared/components/dialog/dialogTransaction/BeneficiaryDialog';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { customerInfoInObject } from '../../shared/components/CustomHooks/GlobalFunctions'
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import { IUserCredentials } from '../customerDetails/CustomerDetails'
import { getOpenTransactions, readDraftTransactions } from '../../store/actions/convertionActions'
import { invoiceTransaction, receiptTransaction } from '../../store/actions/accountAction'
import { CustomDropdown } from "../../shared/components/Option";
import DialogSearch from '../global/DialogSearch';
import { Icons } from '../../modelsType/Icon';
import { RESET_CUSTOMER_OBJECT } from '../../store/actions/types';

export default function EntityAccountDetails(props: any) {
    //chech! not every props in use 
    const { setIfChanges, ifChanges, actions, setActions, setReadOnly, readOnly,
        setActionCustomerId, searchCustomer, setSearchCustomer, typeAction,
        setSelectedDraft, selectedDraft, selectConversionList, setSelectConversionList } = props

    const dataAccount = useSelector(({ accountReducer }: any) => accountReducer?.dataAccount)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const mainAccounts = authReducer?.data?.branch?.main_accounts
    const { dataCustomer } = useSelector(({ customerReducer }: any) => customerReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const { state } = useLocation<any>()
    const dispatch = useDispatch()
    const [isOpenTransaction, setIsOpenTransaction] = useState(false)
    const [isCreate, setIsCreate] = useState(false)
    const [dialogBeforeChangeDraft, setDialogBeforeChangeDraft] = useState(false)
    const [listAccounts, setListAccounts] = useState<any>([{}])
    const [saveBeforeChangeDraft, setSaveBeforeChangeDraft] = useState("")
    const [addCustomer, setaddCustomer] = useState(false)
    const [exsistChanges, setExsistChanges] = useState(false)
    const [dialogExsistCustomerTransaction, setDialogExsistCustomerTransaction] = useState(false)
    const [exsistCustomerTransaction, setExsistCustomerTransaction] = useState("")
    const [openNewTransaction, setOpenNewTransaction] = useState('')
    const [customerTransaction, setCustomerTransaction] = useState('')
    const [draftList, setDraftList] = useState<any>([])

    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])

    useEffect(() => {
        if (dataAccount) {
            const { id_transaction } = dataAccount
            if (actions.id_transaction === null) {
                state !== "showCompleted" && isCreate && setSelectedDraft(id_transaction + ',' + dataAccount?.id_entity)
                setActions({ ...actions, "id_transaction": id_transaction })
                setIsCreate(false)
            }
        }
    }, [dataAccount])
    useEffect(() => {
        if (dataAccount && state?.source === "refresh") {
            setSelectedDraft(dataAccount?.id_transaction + ',' + dataAccount?.id_entity)
        }
    }, [dataAccount, state])

    useEffect(() => {
        if (searchCustomer) {
            let arrAccounts: object[] = []
            searchCustomer?.accounts?.forEach((account: any) => {
                arrAccounts.push({ key: account?.id_account, text: account?.account_name, account_number: account?.account_number })
            })
            setListAccounts(arrAccounts)
            setReadOnly(false)
            if (actions?.id_entity !== searchCustomer?.idEntity) {
                let income = mainAccounts?.find((account: any) => account?.contra_account_group_id === "income")
                // actions?.id_entity !== "" && setIfChanges(true)
                if (actions?.id_transaction === null) {
                    setActions((action: any) => ({
                        ...action, "id_entity": searchCustomer?.idEntity, "id_account_income": income?.id_account,
                        "id_account": arrAccounts.length === 1 ? searchCustomer.accounts[0].id_account : "",
                    }))
                }
                if (actions?.id_transaction !== null) {
                    setActions((action: any) => ({
                        ...action, "id_entity": searchCustomer?.idEntity, "id_account_income": income?.id_account,
                        "id_account": arrAccounts.length === 1 ? searchCustomer.accounts[0].id_account : ""
                    }))
                }
            }
        }
    }, [searchCustomer])

    useEffect(() => {
        let listConversion: object[] = []
        if (state !== "showCompleted") {
            if (selectConversionList?.length === 0) setDraftList([])
            selectConversionList?.forEach(async (item: any) => {
                listConversion.push({
                    key: item.id_transaction + "," + item.id_entity,
                    text: new Date(item.date_created).toLocaleDateString('he').split('T')[0] + " " + item.entity_name,
                })
            });
            if (selectConversionList?.length) {
                let index = selectConversionList?.findIndex((l: any) => l.id_entity === dataCustomer?.id_entity)

                if (index !== -1 && isCreate === false) {
                    setCustomerTransaction(selectConversionList[index].id_transaction + "," + selectConversionList[index].id_entity)
                    setExsistCustomerTransaction(t('exsistOpenTransactionToCustomer'))
                    setDialogExsistCustomerTransaction(true)
                }
                else {
                    if (dataCustomer?.id_entity && !actions?.id_transaction && isCreate === false) {
                        setSearchCustomer(customerInfoInObject(dataCustomer))
                    }
                }
            }
            else {
                if (dataCustomer?.id_entity && !actions?.id_transaction && isOpenTransaction) {
                    setSearchCustomer(customerInfoInObject(dataCustomer))
                }

            }
            listConversion?.length > 0 && setDraftList(listConversion)
        }
    }, [selectConversionList])

    useEffect(() => {
        if (openNewTransaction === "save") {
            customerInfoInObject(dataCustomer)
            setSearchCustomer()
        }
        if (openNewTransaction === "don't save") {
            getDraftData("", customerTransaction)
        }
    }, [openNewTransaction])

    useEffect(() => {
        async function openTransactions() {
            if (selectConversionList?.length === 0 && userCredentials) {
                setIsOpenTransaction(true)
                await getOpenTransactions(typeAction, userCredentials, setSelectConversionList)
            }
        }
        openTransactions()
    }, [userCredentials])

    useEffect(() => {
        async function openTransactions() {
            if (exsistChanges) {
                const [idTransaction, idEntity] = selectedDraft.split(",")
                if (dataAccount?.status_id === "draft") {
                    setIsOpenTransaction(true)
                    await getOpenTransactions(typeAction, userCredentials, setSelectConversionList)
                }
                dispatch(readDraftTransactions(typeAction, userCredentials, idEntity, idTransaction))
                setExsistChanges(false)
            }
        }
        openTransactions()
    }, [exsistChanges])

    useEffect(() => {
        if (saveBeforeChangeDraft === "save") {
            //ask if need the servermessages & spinner?
            dispatch({
                type: RESET_CUSTOMER_OBJECT,
            })
            typeAction === "invoice" && dispatch(invoiceTransaction("update", typeAction, searchCustomer?.entityName, actions,
                userCredentials, actions.id_entity,t, "", "", "", "", "", setExsistChanges))
            typeAction === "receipt" && dispatch(receiptTransaction("update", typeAction, searchCustomer?.entityName, actions,
                userCredentials, actions.id_entity,t, "", "", "", "", "", setExsistChanges))

            setIfChanges(false)
        }
        if (saveBeforeChangeDraft === "don't save") {
            setIfChanges(false)
            const [idTransaction, idEntity] = selectedDraft.split(",")
            dispatch(readDraftTransactions(typeAction, userCredentials, idEntity, idTransaction))
        }
    }, [saveBeforeChangeDraft])

    useEffect(() => {
        if (!readOnly && actions?.id_entity && actions?.id_account && actions.id_transaction === null) {
            typeAction === "invoice" && dispatch(invoiceTransaction("create", typeAction, searchCustomer.entityName, actions, userCredentials,
                searchCustomer?.idEntity,t, "", "", "", "", setSelectConversionList))
            typeAction === "receipt" && dispatch(receiptTransaction("create", typeAction, searchCustomer.entityName, actions, userCredentials,
                searchCustomer?.idEntity,t, "", "", "", "", setSelectConversionList))
        }
        setIsCreate(true)
    }, [actions?.id_entity, actions?.id_account])

    const getDraftData = (key: string, value: any) => {
        setSaveBeforeChangeDraft("")
        if (!ifChanges || dataAccount?.status_id === "completed") {
            setSelectedDraft(value)
            const [idTransaction, idEntity] = value.split(",")
            dispatch(readDraftTransactions(typeAction, userCredentials, idEntity, idTransaction))
        }
        else {
            if (ifChanges && dataAccount?.status_id !== "completed") {
                setSelectedDraft(value)
                setDialogBeforeChangeDraft(true)
            }
        }
    }

    const change = async (key: any, value: any) => {
        setIfChanges && setIfChanges(true)
        await setActions && setActions({ ...actions, [key]: value })
    }

    return (
        <div>
            {dialogBeforeChangeDraft && <DialogMessages setansDelete={setDialogBeforeChangeDraft} setshowModalDelete={setDialogBeforeChangeDraft} dialogType={"saveConversionData"} setSaveDataConversion={setSaveBeforeChangeDraft} />}
            {dialogExsistCustomerTransaction && <DialogMessages setansDelete={setDialogExsistCustomerTransaction} setshowModalDelete={setDialogExsistCustomerTransaction} subText={exsistCustomerTransaction} title={t('note')} dialogType={"dialogMessage"} setSaveDataConversion={setOpenNewTransaction} />}
            <div className={`content-div-${dir} div-entity-account-${dir}`}>
                <div className="wrap-gates" style={{ width: "100%" }}>
                    <div className="wrap-gates" style={{ width: "75vw" }}>
                        <div className="wrap-title-gates" style={{ width: "650px" }} >
                            <div className={`attechedAConDetails div-entity-account-details-${dir}`} >
                                <p className="title-text">{t("customerDetails")}</p>
                                {dataAccount?.status_id === "completed" && state === "showCompleted" ? "" : <div style={{ justifyContent: "space-between", display: "inline-flex" }} >
                                    <div style={{ display: "flex", marginBottom: "7px" }}>
                                        <div style={{ display: "flex", alignItems: "end" }}>
                                            <DialogSearch isUnderLine={true} autoFocus={true} setReadOnly={setReadOnly}
                                                userCredentials={userCredentials} isSearchInsiders={false} type="entityAccount"
                                                setRelatedFactors={setSearchCustomer} />
                                        </div>
                                        <div  className={`margin-between-input-${dir}`} style={{ width: "197px" }}>
                                            {dataAccount?.status_id === "completed" && state === "showCompleted" ?
                                                <div style={{ marginRight: "calc(80% - 200px)", display: "inline-grid" }}>
                                                    <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px", paddingTop: "25px", marginBottom: "4px" }}> {t('displayOnly')}</div>
                                                    <PrimaryButton className={`bottun-${dir}`} style={{ width: "235px", marginBottom: "4px", backgroundColor: "green", borderColor: "green", cursor: "default" }} allowDisabledFocus disabled={actions.id_transaction ? false : true} checked={false} name="cancel" text={t('printCopy')} iconProps={Icons.print} />
                                                    <PrimaryButton className={`bottun-${dir}`} style={{ width: "235px", marginBottom: "4px", cursor: "default" }} allowDisabledFocus checked={false} name="new" text={t('newTransaction')} />
                                                    <PrimaryButton className={`bottun-${dir}`} style={{ width: "235px", marginBottom: "4px", backgroundColor: "red", borderColor: "red", cursor: "default" }} allowDisabledFocus disabled={actions.id_transaction ? false : true} checked={false} name="cancel" text={t('cancelTransaction')} />
                                                </div>
                                                : <div>
                                                    <CustomDropdown otherInputId={''} IsTabIndex={selectedDraft && true} onKeyDown={(e: any) => e.key === "Tab"} disabled={dataAccount?.status_id === "completed" && state === "showCompleted"} hasOtherValue={false} options={draftList} label={typeAction === "receipt" ? t("openReceipts") + " (" + draftList.length + ")" : typeAction === "invoice" ? t("openInvoices") + " (" + draftList.length + ")" : ""}
                                                        onChange={getDraftData} selectedKey={selectedDraft} id={'draftTransacsionId'} othertextInput={t('')} />
                                                </div>}
                                        </div>
                                    </div>
                                    {addCustomer && <BeneficiaryDialog setShowDialog={setaddCustomer} setnewBenObject={setSearchCustomer} setidBeneficiary={setActionCustomerId} />}</div>}
                            </div>
                            <hr className="hr" style={{ marginBottom: '4px', width: "650px" }}></hr>

                            {searchCustomer ? <div className="wrapDetails" style={{ width: "100%" }} >
                                <div>
                                    <p className="boldD" style={{ fontSize: "22px" }}><label className="boldD" style={{ marginBottom: "0%", fontSize: "19px", height: "25px" }}>{t("name")}</label>{searchCustomer?.firstName && searchCustomer?.firstName}   {searchCustomer?.lastName && searchCustomer?.lastName}</p>
                                    <p className="normal" style={{ fontSize: "19px", marginBottom: "0%" }}>{searchCustomer?.idNumber ? searchCustomer?.idNumber : ""}</p>
                                </div>
                                {searchCustomer?.address ? <div>
                                    <p className="normal" style={{ fontSize: "19px", marginBottom: "0%" }}><label className="boldD" style={{ marginBottom: "0%", fontSize: "19px" }}>{t("address")}</label>{searchCustomer?.addressName && searchCustomer?.addressName}  {searchCustomer?.addressNumber && searchCustomer?.addressNumber}</p>
                                    <p className="normal" style={{ fontSize: "19px", marginBottom: '0' }}>{searchCustomer?.addressCityName && searchCustomer?.addressCityName}</p>
                                </div> : ""}
                                {searchCustomer?.telephone ? <div>
                                    <p className="normal" style={{ fontSize: "19px" }}><label className="boldD" style={{ marginBottom: "0%", fontSize: "19px" }}>{t("phone")}</label>{searchCustomer?.telephone && searchCustomer?.telephone}</p>
                                    <p className="normal" style={{ fontSize: "19px", marginBottom: '0' }}>{ }</p>
                                </div> : ""}
                                {listAccounts?.length > 1 ? <div style={{ width: "25%" }}>
                                    <p className="boldD">{t("customer_accounts")}</p>
                                    <CustomDropdown otherInputId={''} readOnly={readOnly} hasOtherValue={false} options={listAccounts} label={''} onChange={change} selectedKey={actions?.id_account} id={'id_account'} othertextInput={t('')} />
                                </div> : ""}
                            </div> : <div style={{ marginTop: "18px" }}>{t('noDisplay')}</div>}
                            {searchCustomer ? <hr className="hr" style={{ marginTop: "4px" }}></hr> : " "}
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}