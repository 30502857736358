import axios from "axios";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { createCustomer } from "./customerActioin"
import { basicUrl } from "../../shared/config";

export const createCreditCard = (creditCard: any, userCredentials: IUserCredentials , idEntity: any, setShowDialogSave?: any, setShowDialogFailed?: any, setshowSpiner?: any) => async (dispatch: Function) => {
        let idNewCustomer = ""
        if (!idEntity) {
            let res = await dispatch(createCustomer(creditCard, userCredentials,false))
            idNewCustomer = res?.data?.data?.id_entity}
      
        const newCardCredit = {
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "id_client": userCredentials && userCredentials["idClient"],
            "id_branch": userCredentials && userCredentials["idBranch"],
            "id_entity": idEntity ? idEntity : idNewCustomer,
            "return_entity": 1,
            "attribute_request_method": "create",
            "attribute": "card",
            "entity_type_id": "customer",
            "country_code": creditCard.country_code,
            "card_brand_code": creditCard.card_brand_code,
            "card_pan": creditCard.card_pan
        }
        const body = JSON.stringify(newCardCredit);
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };
        return axios.post(basicUrl + '/entity', body, config)
            .then(res => {
                try {
                    if (res.status === 200) {
                        if (res.data["err_code"] === 0) {
                            setShowDialogSave && setShowDialogSave(true)
                            setshowSpiner && setshowSpiner(false)
                            let newCard = res.data.data?.cards[0]
                            res.data.data?.cards?.forEach((card: any) => {
                                if (card.card_id > newCard?.card_id)
                                    newCard = card
                            })
                            let financialAssetDetails = {
                                "card_id": newCard?.card_id,
                                "id_card_holder": res.data.data.id_entity
                            }
                            return financialAssetDetails
                        }
                    }
                    else {
                        setshowSpiner(false)
                    }
                }
                catch (err) {
                    // err.message
                    setShowDialogFailed(true)
                    setshowSpiner(false)
                    console.log('error in create bankAcoount',)
                }
            })
    }
