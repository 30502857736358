import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import { CustomTextField } from '../../../../shared/components/TextField';

export default function FairCredit(props: any) {
    const { financialAssets, checkDeatils } = props
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const fairCredit = useSelector(({ authReducer }: any) => authReducer?.data?.branch?.fair_credit_limits)
    const [fairCreditObj, setFairyCreditObj] = useState({
        interest: 0, allowedInterest: 0,
        allowedExpenses: 0, totalInterestAndExpenses: 0
    })

    useEffect(() => {
        if (fairCredit)
            calculationFairCredit()
        // eslint-disable-next-line  
    }, [fairCredit, checkDeatils])

    const calculationFairCredit = async () => {
        let checkDetails = financialAssets?.financial_asset_details
        let days = Number(checkDetails?.cheque_days) ? Number(checkDetails?.cheque_days) + Number(checkDetails?.cheque_value_days) : Number(checkDetails?.cheque_value_days)
        let interest = await calculationInterest(days)
        let allowedInterest = interest * Number(financialAssets?.amount_ils)
        let allowedExpenses = await calculationAllowedExpenses(days)
        setFairyCreditObj({
            ...fairCreditObj, "interest": interest, "allowedInterest": allowedInterest,
            "allowedExpenses": allowedExpenses, "totalInterestAndExpenses": allowedInterest + allowedExpenses
        })
    }

    const calculationAllowedExpenses = async (days: any) => {
        let exclusion_credit_over_amount = fairCredit?.exclusion_credit_over_amount
        let exclusion_credit_over_days = fairCredit?.exclusion_credit_over_days
        let minimum_exclusion_amount = fairCredit?.minimum_exclusion_amount
        let exclusion_percentage_to_10k = fairCredit?.exclusion_percentage_to_10k
        let maximum_exclusion_amount_to_10k = fairCredit?.maximum_exclusion_amount_to_10k
        let exclusion_percentage_over_10k_to_10d = fairCredit?.exclusion_percentage_over_10k_to_10d
        let maximum_exclusion_amount_over_10k_over_10d = fairCredit?.maximum_exclusion_amount_over_10k_over_10d
        let exclusion_percentage_over_10k_over_10d = fairCredit?.exclusion_percentage_over_10k_over_10d
        let allowedExpenses = minimum_exclusion_amount
        let total_ils = Number(financialAssets?.amount_ils)
        if (total_ils > exclusion_credit_over_amount && days > exclusion_credit_over_days)
            allowedExpenses = 0
        else {
            if (total_ils <= 10000)
                allowedExpenses = Math.max(allowedExpenses, (Math.min(maximum_exclusion_amount_to_10k, (exclusion_percentage_to_10k * total_ils))))
            else {
                if (days <= 10)
                    allowedExpenses = Math.max(allowedExpenses, (exclusion_percentage_over_10k_to_10d * total_ils))
                else
                    allowedExpenses = Math.max(allowedExpenses, Math.min(maximum_exclusion_amount_over_10k_over_10d, (exclusion_percentage_over_10k_over_10d * total_ils)))
            }
        }
        return allowedExpenses
    }

    const calculationInterest = async (days: any) => {
        let interest = 0
        let interest_to_90_ils = fairCredit?.interest_to_90_ils
        let interest_over_90_ils = fairCredit?.interest_over_90_ils
        let interest_to_90_fx = fairCredit?.interest_to_90_fx
        let interest_over_90_fx = fairCredit?.interest_over_90_fx
        if (days > 90 && financialAssets?.currency_code === "ILS")
            interest = interest_over_90_ils
        if (days > 90 && financialAssets?.currency_code !== "ILS")
            interest = interest_over_90_fx
        if (days <= 90 && financialAssets?.currency_code === "ILS")
            interest = interest_to_90_ils
        if (days <= 90 && financialAssets?.currency_code !== "ILS")
            interest = interest_to_90_fx
        interest = Number((interest / (1 + interest)).toFixed(5))
        interest = Math.pow((1 + interest), (days / 365)) - 1
        return interest
    }

    return (
        <div style={{  width: "42%", height: "100%" }} >
            <p className="title-text" >{t('limitationsFairCreditLaw')}</p>
            <hr className="hr"></hr>
            <div className="wrapper-collateral">
                <div className={`warpper-fields-right-${dir}`}>
                    <CustomTextField type="text" prefix={"%"} readOnly={true} label={t('actualCost')} value={(fairCreditObj?.interest * 100).toFixed(3)} onChange={""} id={'interest'} />
                </div>
                <div className={`warpper-fields-left-${dir}`}>
                    <CustomTextField suffix='&#8362;' readOnly={true} label={t('actualAllowableCost')} value={Number(fairCreditObj?.allowedInterest).toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onChange={""} id={'allowedInterest'} />
                </div>
            </div>
            <CustomTextField suffix='&#8362;' value={Number(fairCreditObj?.allowedExpenses).toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} readOnly={true} label={t('AdditionalAllowedExpenses')} onChange={""} id={'allowedExpenses'} />
            <CustomTextField suffix='&#8362;' value={Number(fairCreditObj?.totalInterestAndExpenses).toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
            readOnly={true} label={t("totalInterestAndAllowableExpenses")} onChange={""} id={'totalInterestAndExpenses'} />
        </div>
    )
}