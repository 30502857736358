import React, { useEffect, useState, useRef } from "react";
import validator from "validator";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import GenericPdfDownloader from "../pdfUtils/GenericPDF";
import ExportCSV from "../XlUtils/ExportReactCSV";
import {
  DefaultButton,
  IconButton,
  PrimaryButton,
  Icon,
  IComboBoxStyles,
} from "@fluentui/react";
import { ComboBox, IComboBoxOption, IComboBox } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { ISpinnerStyles, Spinner } from "@fluentui/react/lib/Spinner";

import { CustomerDate } from "../../shared/components/CustomHooks/concatTextFields";
import { DialogMessages } from "../../shared/components/dialog/DialogMessages";
import {
  checkAge,
  checkPermissionLevelOfPage,
  customerInfoInObject,
} from "../../shared/components/CustomHooks/GlobalFunctions";
import { CustomDropdown } from "../../shared/components/Option";
import { CustomTextField } from "../../shared/components/TextField";
import { TextFeildNote } from "../../shared/components/Note";
import { CustomToggle } from "../../shared/components/Toggle";
import { Icons } from "../../modelsType/Icon";
import Subtitle from "../../shared/components/Subtitle";
import Title from "../../shared/components/Title";
import { RESET_CUSTOMER_OBJECT } from "../../store/actions/types";
import {
  createCustomer,
  readCustomerId,
  updateCustomerInfo,
  deleteCustomer,
} from "../../store/actions/customerActioin";
import {
  CreateAddress,
  updateAddress,
} from "../../store/actions/addressAction";
import { updatePhone, CreatePhone } from "../../store/actions/phonecAction";
import { loadEnums, loadStreetEnums } from "../../store/actions/dataActions";

import "./customerDetails.scss";
import "../../scssPages/sub-header.scss";
import {
  CreateEmail,
  deleteEmail,
  updateEmail,
} from "../../store/actions/emailAction";

export const findDefault = (arr: object[]) => {
  let ans = 0;
  arr?.forEach((item: any, index: number) => {
    if (item?.is_default === true) {
      ans = index;
      return;
    }
  });
  return ans;
};

//form's object
export interface ICustomer {
  [x: string]: any;
  gender: Number;
  otherGender: String;
  otherCustomerStatus: string;
  entityStatusId: Number;
  firstName: string;
  lastName: string;
  isLocked: boolean;
  isPopupMessage: boolean;
  idTypeCountryCode: string;
  note: string;
  dateBirth: string;
  middleName: any;
  classId: Number;
  entitySubTypeId: Number;
  ViewNoteWhenPerformingAction: boolean;
  industryId: Number;
  CreditGroup: Number;
  idAffiliate: Number;
  idNumber: string;
  idTypeId: string;
  idTypeOther: string;
  addressCountryCode: string;
  idIdentifier: Number;
  addressName?: string;
  address?: string;
  addressNumber: string;
  addressCity?: string;
  addressCityName?: string;
  iDCountryCode: string;
  telephone: string;
  telephoneCountryCode: string;
  email: string;
  entityNumber: string;
  addressZipCode: string;
  customerTypeId: string;
  workerName: string;
  genderName: string;
  addressTypeId: Number;
  emailTypeId: Number;
  emailId: Number;
  telephoneTypeId: Number;
  telephoneDefault: Number;
  addressDefault: Number;
  emailDefault: Number;
  telephoneId: Number;
  addressId: Number;
}

const blankCustomer: ICustomer = {
  gender: 1,
  otherGender: "",
  entityStatusId: 0,
  idTypeCountryCode: "",
  firstName: "",
  note: "",
  lastName: "",
  isLocked: false,
  isPopupMessage: false,
  dateBirth: "",
  genderOther: "",
  middleName: undefined,
  otherCustomerStatus: "",
  classId: 1,
  entitySubTypeId: 1,
  ViewNoteWhenPerformingAction: false,
  industryId: 0,
  CreditGroup: 0,
  idAffiliate: 0,
  idTypeId: "",
  idNumber: "",
  idTypeOther: "",
  addressCountryCode: "",
  addressState: "",
  idIdentifier: 0,
  address: "",
  addressName: "",
  addressNumber: "",
  addressCity: "",
  addressCityName: "",
  iDCountryCode: "",
  telephone: "",
  telephoneCountryCode: "",
  email: "",
  entityNumber: "",
  addressZipCode: "",
  customerTypeId: "",
  workerName: "",
  genderName: "",
  telephoneTypeId: 1,
  telephoneId: 0,
  addressTypeId: 1,
  emailTypeId: 1,
  emailId: 0,
  telephoneDefault: 1,
  addressDefault: 1,
  emailDefault: 1,
  addressId: 0,
};

export interface IUserCredentials {
  idInitiator: string;
  idClient: string;
  idBranch: string;
  idClientData: string;
}

///convert enum arr to dropdown
export const convertArrToDropdropdown = (arr: Object[], source?: String) => {
  console.log(arr,"arr");
  
  let newArr: any[] = [];
  arr?.forEach((item: any) => {
    let text: any = item.enum_value + " (+" + item.enum_id + ")";
    
    item?.is_default === true
      ? newArr.push({
        key: item.enum_id,
        text: source ? text : item.enum_value,
        is_default: true,
      })
      : newArr.push({
        key: item.enum_id,
        text: source ? text : item.enum_value,
      });
  });
  return newArr;
};

const convertAddressArrToDropdropdown = (arr: Object[]) => {
  let newArr: any[] = [];
  arr?.forEach((item: any, i: number) => {
    item?.is_default === true
      ? newArr.push({
        key: i,
        text: item.enum_value,
        is_default: true,
        code: item.enum_id,
      })
      : newArr.push({ key: i, text: item.enum_value, code: item.enum_id });
  });
  return newArr;
};

const CustomerDetails = () => {
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);
  const dispatch = useDispatch();
  const [readOnly, setReadOnly] = useState(true);
  const [, /*update*/ setUpdate] = useState(false);
  const [typeButton, setTypeButton] = useState<string | null>();
  const [customer, setCustomer] = useState<ICustomer>(blankCustomer);
  const [resultId, setResultId] = useState<any>(true);
  const [isValidDate, setIsValidDate] = useState<any>(true);
  const [showDialogFailed, setShowDialogFailed] = useState(false);
  let arrTypeCustomer = ["8", "11", "12"];
  let arrTypeIdCustomer = ["2", "12"];
  const [showDialogRequiredFields, setShowDialogRequiredFields] =
    useState(false);
  const [requiredFields, setRequiredFields] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [currentStreet, setCurrentStreet] = useState<any>();
  const { dataCustomer } = useSelector(
    ({ customerReducer }: any) => customerReducer
  );
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [currentIdEntity, setCurrentIdEntity] = useState("");
  const [userCredentials, setUserCredentials] =
    useState<IUserCredentials | null>(null);
  const authReducer = useSelector(({ authReducer }: any) => authReducer);
  const location = useLocation();
  const { state } = useLocation<any>();
  const [enumSecondaryIndustry, setEnumSecondaryIndustry] = useState(
    convertArrToDropdropdown([])
  );
  const [enumsDropdown, setenumsDropdown] = useState<any>();

  const [workersNames, setWorkersNames] = useState<any>([{}]);
  const printRef = useRef<HTMLFormElement>(null);
  const [showDialogSave, setShowDialogSave] = useState(false);
  const [ifDelete, setifDelete] = useState(false);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [showSpiner, setshowSpiner] = useState(false);
  const [isClient, setisClient] = useState(false);
  const [isLess, setisLess] = useState(true);
  const [isLessAddress, setisLessAddress] = useState(true);

  const [NewCustomer, setNewCustomer] = useState(false);
  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const [customerBeforeChanges, setcustomerBeforeChanges] =
    useState<ICustomer>(blankCustomer);
  const [checkedCorporation, setCheckedCorporation] = useState(false);
  const [openmiddleName, setOpenmiddleName] = useState(false);
  const [genderDefault, setGenderDefault] = useState<any>();
  const [typeIdDefault, setTypeIdDefault] = useState<any>();
  const [addressStreetCode, setAddressStreetCode] = useState(
    convertArrToDropdropdown([])
  );
  const [optionsCity, setOptionsCity] = useState<IComboBoxOption[]>([]);
  const [optionsStreet, setOptionsStreet] = useState<IComboBoxOption[]>([]);
  const [validEmail, setValidEmail] = useState(true);
  const [isWorker, setIsWorker] = useState("empty");
  const [isCreated, setIsCreated] = useState(false);
  const [allowFreeform] = useBoolean(true);
  const [permission, setPermission] = useState("");

  const credentials = useSelector(
    ({ authReducer }: any) => authReducer?.data?.credentials
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const postalCode = `https://israelpost.co.il/%D7%A9%D7%99%D7%A8%D7%95%D7%AA%D7%99%D7%9D/%D7%90%D7%99%D7%AA%D7%95%D7%A8-%D7%9E%D7%99%D7%A7%D7%95%D7%93/`;
  const [newKey, setNewKey] = useState<any>(1);

  const customStyles: Partial<IComboBoxStyles> = {
    root: {
      backgroundColor: readOnly ? "#FAF9F8" : "white",
    },
    input: {
      backgroundColor: readOnly ? "#FAF9F8" : "white",
    },
  };

  const requiredFieldStyle: Partial<IComboBoxStyles> = {
    root: {
      backgroundColor: readOnly ? "#FAF9F8" : "white",
      border: "solid red 2px",
    },
    input: { backgroundColor: readOnly ? "#FAF9F8" : "white" },
  };

  const spinnerStyles: Partial<ISpinnerStyles> = {
    root: {
      position: "fixed",
      zIndex: "999999",
      fontWeight: "bold",
    },
    circle: {
      color: "#FFB400 ",
      height: "100px",
      width: "100px",
    },
  };

  useEffect(() => {
    if (location && credentials?.length) {
      checkPermissionLevelOfPage(credentials, location, setPermission);
    }
  }, [location, credentials]);

  ///insert all enums of the form
  useEffect(() => {
    if (enums && !enumsDropdown) {
      let arr = {
        genders: convertArrToDropdropdown([]),
        gendersCorporation: convertArrToDropdropdown([]),
        typeIdentityNumbers: convertArrToDropdropdown([]),
        selectedTypeIdentityNumbers: convertArrToDropdropdown([]),
        status: convertArrToDropdropdown([]),
        statusAdded: convertArrToDropdropdown([]),
        telephoneCountryCode: convertArrToDropdropdown([]),
        countryCode: convertArrToDropdropdown([]),
        selectedCountryCode: convertArrToDropdropdown([]),
        customersType: convertArrToDropdropdown([]),
        industryClassification: convertArrToDropdropdown([]),
        addressCityCode: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].customer !== undefined) {
          let customer = enums[i].customer;
          for (let j = 0; j < customer.length; j++) {
            if (customer[j].gender_id !== undefined) {
              customer[j].gender_id?.forEach((gender: any) => {
                if (gender?.enum_id === "3")
                  arr.gendersCorporation.push({
                    key: gender.enum_id,
                    text: gender.enum_value,
                  });
                else
                  arr.genders.push({
                    key: gender.enum_id,
                    text: gender.enum_value,
                    is_default: gender?.is_default === true && true,
                  });
              });
            }
            if (customer[j].ID_type_id !== undefined) {
              customer[j].ID_type_id?.forEach((typeId: any) => {
                if (typeId?.is_selected)
                  arr.typeIdentityNumbers.push({
                    key: typeId.enum_id,
                    text: typeId.enum_value,
                    is_default: typeId?.is_default === true && true,
                  });
                else
                  arr.selectedTypeIdentityNumbers.push({
                    key: typeId.enum_id,
                    text: typeId.enum_value,
                    is_default: typeId?.is_default === true && true,
                  });
              });
            }
            if (customer[j].status_id !== undefined) {
              customer[j].status_id?.forEach((status: any) => {
                if (
                  status?.enum_value === "בתהליך אישור" ||
                  status?.enum_value === "פעיל"
                )
                  arr.statusAdded.push({
                    key: status.enum_id,
                    text: status.enum_value,
                    is_default: status?.is_default === true && true,
                  });
                arr.status.push({
                  key: status.enum_id,
                  text: status.enum_value,
                  is_default: status?.is_default === true && true,
                });
              });
            }
            if (customer[j].customer_type_id !== undefined)
              arr.customersType = convertArrToDropdropdown(
                customer[j].customer_type_id
              );
            if (customer[j].industry_classification_id !== undefined) {
              customer[j].industry_classification_id.forEach((element: any) => {
                arr.industryClassification.push({
                  key: element.enum_id,
                  text: element.enum_value,
                  industrySubClassification:
                    element.industry_sub_classification,
                });
              });
              
            }
            if (customer[j]?.address_city_code !== undefined) {
              arr.addressCityCode = convertArrToDropdropdown(
                customer[j].address_city_code
              );
            }
          }
        }
        if (enums[i].general !== undefined) {
          let general = enums[i].general;
          for (let j = 0; j < general.length; j++) {
            if (general[j].country_code !== undefined) {
              general[j].country_code?.forEach((country: any) => {
                if (country?.is_selected === true) {
                  arr.selectedCountryCode.push({
                    key: country.enum_id,
                    text: country.enum_value,
                    is_default: country?.is_default === true && true,
                  });
                }
                arr.countryCode.push({
                  key: country.enum_id,
                  text: country.enum_value,
                  is_default: country?.is_default === true && true,
                });
              });
              arr.selectedCountryCode.push({ key: "more", text: t("more") });
              arr.countryCode.unshift({ key: "less", text: t("less") });
            }
            if (general[j].telephone_country_code !== undefined)
              arr.telephoneCountryCode = convertArrToDropdropdown(
                general[j].telephone_country_code,
                "tel"
              );
            if (general[j].address_city_code !== undefined) {
              arr.addressCityCode = convertArrToDropdropdown(
                general[j].address_city_code
              );
              setOptionsCity(arr.addressCityCode);
            }
          }
        }
      }
      let defaultStatusValue: any;
      defaultStatusValue = arr?.genders.find(
        ({ is_default }: any) => is_default === true
      );
      setGenderDefault(defaultStatusValue);
      let defaultTypeValue: any;
      defaultTypeValue = arr?.selectedTypeIdentityNumbers.find(
        ({ is_default }: any) => is_default === true
      );
      setTypeIdDefault(defaultTypeValue);
      if ((!state && !dataCustomer) || state?.create) {
        let defaultCtypeValue: any;
        console.log(defaultCtypeValue,"defaultCtypeValue");
        
        defaultCtypeValue = arr?.customersType.find(
          ({ is_default }: any) => is_default === true
        );
        let defaultCStatusValue: any;
        defaultCStatusValue = arr?.status.find(
          ({ is_default }: any) => is_default === true
        );
        let defaultTelephoneValue: any;
        defaultTelephoneValue = arr?.telephoneCountryCode.find(
          ({ is_default }: any) => is_default === true
        );
        let defaultCountryValue: any;
        defaultCountryValue = arr?.countryCode.find(
          ({ is_default }: any) => is_default === true
        );
        setCustomer({
          ...customer,
          iDCountryCode: defaultCountryValue?.key,
          gender: defaultStatusValue?.key,
          telephoneCountryCode: defaultTelephoneValue?.key,
          idTypeId: defaultTypeValue?.key,
          idTypeCountryCode: defaultCountryValue?.key,
          entityStatusId: defaultCStatusValue?.key,
          customerTypeId: defaultCtypeValue?.key,
          workerName: authReducer?.data?.user?.id_entity,
        });
      }
      setenumsDropdown(arr);
    }
  }, [enums]);

  useEffect(() => {
    if (enumsDropdown?.selectedCountryCode && customer?.idNumber) {
      if (isLess) {
        let isExsist = enumsDropdown.selectedCountryCode.find(
          (item: any) => item.key === customer?.idTypeCountryCode
        );
        !isExsist && setisLess(false);
      }
      if (isLessAddress) {
        let isExsistAddress = enumsDropdown.selectedCountryCode.find(
          (item: any) => item.key === customer?.iDCountryCode
        );
        !isExsistAddress && setisLessAddress(false);
      }
    }
  }, [enumsDropdown?.selectedCountryCode, customer?.idNumber]);

  //details of user login
  useEffect(() => {
    let arrWorkers: object[] = [];
    if (!authReducer?.data?.user) return;
    setUserCredentials({
      idInitiator: authReducer.data.user.id_entity,
      idClient: authReducer.data.user.id_client,
      idBranch: authReducer.data.branch.id_entity,
      idClientData: authReducer.data.client.id_client,
    });
    authReducer?.data?.branch?.users?.forEach(async (user: any) => {
      arrWorkers.push({ key: user?.id_entity, text: user?.entity_name });
    });
    setWorkersNames(arrWorkers);
    const id_entity = authReducer.data.client.id_entity;
    const id_client = authReducer.data.client.id_entity;
    if (id_client === "00000000-0000-0000-0000-000000000001") setisClient(true);
    if (
      enumsDropdown?.countryCode?.length === 0 ||
      enumsDropdown?.telephoneCountryCode?.length === 0 ||
      enumsDropdown?.addressStreetCode?.length === 0
    ) {
      dispatch(loadEnums(id_entity, id_client));
    }
  }, [authReducer]);

  useEffect(() => {
    if (customer.addressCity !== "" && customer?.iDCountryCode === "IL") {
      dispatch(
        loadStreetEnums(
          authReducer.data.client.id_entity,
          authReducer.data.client.id_entity,
          authReducer.data.branch.id_entity,
          customer.addressCity ? customer.addressCity : "",
          setAddressStreetCode
        )
      );
    }
  }, [customer.addressCity]);

  useEffect(() => {
    let flag = 0;
    workersNames?.forEach(async (element: any) => {
      if (element?.key === customer.workerName) {
        flag = 1;
        setIsWorker("true");
      }
    });
    !flag && setIsWorker("false");
  }, [customer.workerName]);

  useEffect(() => {
    if (
      customer?.customerTypeId === "3" ||
      customer?.customerTypeId === "5" ||
      customer?.customerTypeId === "9" ||
      customer?.customerTypeId === "12" ||
      customer?.customerTypeId === "10"
    )
      setCheckedCorporation(true);
    if (customer?.idEntity)
      if (!customer?.addressId || !customer?.telephoneId) {
        let addressCountryValue = enumsDropdown?.countryCode.find(
          ({ is_default }: any) => is_default === true
        );
        let telephoneCountryCode = enumsDropdown?.telephoneCountryCode.find(
          ({ is_default }: any) => is_default === true
        );
        setCustomer({
          ...customer,
          telephoneCountryCode: !customer.telephoneId
            ? telephoneCountryCode?.key
            : customer.telephoneCountryCode,
          iDCountryCode: !customer.addressId
            ? addressCountryValue?.key
            : customer.iDCountryCode,
        });
        setcustomerBeforeChanges({
          ...customer,
          telephoneCountryCode: !customer.telephoneId
            ? telephoneCountryCode?.key
            : customer.telephoneCountryCode,
          iDCountryCode: !customer.addressId
            ? addressCountryValue?.key
            : customer.iDCountryCode,
        });
      }
  }, [customer.addressId, customer.telephoneId, showDialogSave]);

  useEffect(() => {
    if (addressStreetCode?.length > 0) {
      let arr = convertAddressArrToDropdropdown(addressStreetCode);
      if (
        customer.addressCity !== "" &&
        customer?.iDCountryCode === "IL" &&
        customer.addressName !== ""
      ) {
        let isExit = arr.find(
          (address: any) => address.code === Number(customer.address)
        );
        if (!isExit) {
          arr.push({
            key: arr.length,
            text: customer?.addressName ? customer.addressName : "",
            code: Number(customer?.address),
          });
          setNewKey(
            (customer?.address ? Number(customer?.address) : arr.length) + 1
          );
        } else {
          setCustomer({
            ...customer,
            address: isExit?.key,
          });
          setcustomerBeforeChanges({ ...customer, address: isExit?.key });
          setCurrentStreet(isExit);
          setNewKey(arr.length);
        }
      }
      setOptionsStreet(arr);
    }
  }, [addressStreetCode]);
 
  useEffect(() => {
    async function deleteCustomerF() {
      if (currentIdEntity && userCredentials !== null && ifDelete) {
        setshowSpiner(true);
        await deleteCustomer(
          userCredentials.idInitiator,
          currentIdEntity,
          userCredentials.idClient,
          userCredentials.idBranch,
          setShowDialogUpdate,
          setshowSpiner
        );
        setCustomer(blankCustomer);
        setShowDialogDelete(false);
      }
    }
    deleteCustomerF();
  }, [ifDelete]);

  //id of customer from search customer
  useEffect(() => {
    if (!state && !dataCustomer) {
      setCustomer(blankCustomer);
      setReadOnly(false);
      setNewCustomer(true);
    }
    if (state?.create) {
      setReadOnly(false);
      setNewCustomer(true);
    }
    if (state && userCredentials !== null && !state?.create) {
      const userId = state;
      getCustomerInfo();
    }
  }, [state, userCredentials]);

  useEffect(() => {
    if (isCreated === true && showDialogSave === true) {
      setReadOnly(true);
      setNewCustomer(false);
    }
  }, [isCreated, showDialogSave]);

  ////// enter customer data
  useEffect(() => {
    if (dataCustomer && !state?.create && !state) {
      let customerData: any = customerInfoInObject(dataCustomer);
      if (
        customerData?.customerTypeId === "3" ||
        customerData?.customerTypeId === "5" ||
        customerData?.customerTypeId === "9" ||
        customerData?.customerTypeId === "12" ||
        customer?.customerTypeId === "10"
      )
        setCheckedCorporation(true);
      setcustomerBeforeChanges(customerData);
      setCustomer(customerData);
      setCurrentIdEntity(customerData?.idEntity);
    }
  }, [dataCustomer]);

  useEffect(() => {
    if (customer.industryId) {
      enumsDropdown?.industryClassification?.forEach((item: any) => {
        if (item?.key === customer?.industryId) {
          setEnumSecondaryIndustry(
            convertArrToDropdropdown(item.industrySubClassification)
          );
        }
      });
    }
  }, [enumsDropdown, customer.industryId, customerBeforeChanges.industryId]);

  const getCustomerInfo = async () => {
    if (userCredentials !== null) {
      await dispatch(
        readCustomerId(
          currentIdEntity ? currentIdEntity : state,
          userCredentials,
          setCurrentIdEntity,
          setCustomer,
          setcustomerBeforeChanges,
          true
        )
      );
    }
  };

  const newCustomerFor = () => {
    setNewCustomer(true);
    setCheckedCorporation(false);
    setReadOnly(false);
    setUpdate(true);
    dispatch({
      type: RESET_CUSTOMER_OBJECT,
    });
    let gender = enumsDropdown?.genders.find(
      ({ is_default }: any) => is_default === true
    ).key;
    let defaultCountryValue = enumsDropdown?.countryCode.find(
      ({ is_default }: any) => is_default === true
    );
    let idTypeCountryCode = defaultCountryValue.key;
    let idTypeId = enumsDropdown?.selectedTypeIdentityNumbers.find(
      ({ is_default }: any) => is_default === true
    ).key;
    let telephoneCountryCode = enumsDropdown?.telephoneCountryCode.find(
      ({ is_default }: any) => is_default === true
    ).key;
    let customerTypeId = enumsDropdown?.customersType.find(
      ({ is_default }: any) => is_default === true
    ).key;
    let entityStatusId = enumsDropdown?.status.find(
      ({ is_default }: any) => is_default === true
    ).key;
    setcustomerBeforeChanges({
      ...blankCustomer,
      iDCountryCode: idTypeCountryCode,
      gender: gender,
      telephoneCountryCode: telephoneCountryCode,
      idTypeId: idTypeId,
      idTypeCountryCode: idTypeCountryCode,
      entityStatusId: entityStatusId,
      customerTypeId: customerTypeId,
      workerName: authReducer?.data?.user?.id_entity,
    });
    setCustomer({
      ...blankCustomer,
      iDCountryCode: idTypeCountryCode,
      gender: gender,
      telephoneCountryCode: telephoneCountryCode,
      idTypeId: idTypeId,
      idTypeCountryCode: idTypeCountryCode,
      entityStatusId: entityStatusId,
      customerTypeId: customerTypeId,
      workerName: authReducer?.data?.user?.id_entity,
    });
  };

  const check = (currentValue: string) => customer[currentValue]?.length > 0;

  //button create customer
  const createButton = async () => {
    if (userCredentials) {
      let requiredFields: any = [
        "lastName",
        "dateBirth",
        "idNumber",
        "addressCityName",
        "addressNumber",
        "addressName",
      ];
      if (
        requiredFields.every(check) &&
        (customer.firstName !== "" || checkedCorporation)
      ) {
        const age = checkAge(customer.dateBirth);
        if ((age >= 16 && !checkedCorporation) || checkedCorporation) {
          if (resultId && validEmail && isValidDate) {
            setshowSpiner(true);
            await setcustomerBeforeChanges(cloneDeep(customer));
            let toReadCustomer = true;
            await dispatch(
              createCustomer(
                customer,
                userCredentials,
                true,
                setshowSpiner,
                isClient,
                setShowDialogSave,
                setShowDialogFailed,
                setMessageError,
                setCurrentIdEntity,
                toReadCustomer
              )
            );
            setTypeButton("create");
            setUpdate(false);
            setIsCreated(true);
          } else {
            setShowDialogFailed(true);
            setMessageError(t("validFields"));
          }
        } else {
          alert(t("notPossiblCreateCustomerUnderTheAgeMassege"));
        }
      } else {
        setRequiredFields(true);
        setShowDialogRequiredFields(true);
      }
    }
  };

  //button to update customer
  const editButton = () => {
    setReadOnly(false);
  };

  //button save customer
  const saveButton = async () => {
    setIsCreated(false);
    let info = false;
    let address = false;
    let mail = false;
    let phone = false;
    if (userCredentials !== null) {
      let requiredFields: any = [
        "lastName",
        "dateBirth",
        "idNumber",
        "addressCityName",
        "addressNumber",
        "addressName",
      ];
      if (
        requiredFields.every(check) &&
        (customer.firstName !== "" || checkedCorporation)
      ) {
        if (resultId && isValidDate) {
          if (
            customer.idNumber !== customerBeforeChanges.idNumber ||
            customer.customerTypeId !== customerBeforeChanges.customerTypeId ||
            customer.idTypeId !== customerBeforeChanges.idTypeId ||
            customer.classId !== customerBeforeChanges.classId ||
            customer.dateBirth !== customerBeforeChanges.dateBirth ||
            customer.firstName !== customerBeforeChanges.firstName ||
            customer.lastName !== customerBeforeChanges.lastName ||
            customer.middleName !== customerBeforeChanges.middleName ||
            customer.entitySubTypeId !==
            customerBeforeChanges.entitySubTypeId ||
            customer.isLocked !== customerBeforeChanges.isLocked ||
            customer.isPopupMessage !== customerBeforeChanges.isPopupMessage ||
            customer.note !== customerBeforeChanges.note ||
            customer.gender !== customerBeforeChanges.gender ||
            customer.entityStatusId !== customerBeforeChanges.entityStatusId ||
            customer.entityNumber !== customerBeforeChanges.entityNumber ||
            customer.genderName !== customerBeforeChanges.genderName ||
            customer.idTypeCountryCode !==
            customerBeforeChanges.idTypeCountryCode ||
            customer.CreditGroup !== customerBeforeChanges.CreditGroup ||
            customer.industryId !== customerBeforeChanges.industryId ||
            customer.workerName !== customerBeforeChanges.workerName
          ) {
            info = true;
            setcustomerBeforeChanges(cloneDeep(customer));
            setshowSpiner(true);
            await updateCustomerInfo(
              customer,
              userCredentials,
              currentIdEntity,
              setShowDialogSave,
              setReadOnly,
              setshowSpiner,
              isClient,
              setShowDialogFailed,
              setMessageError
            );
          }
          if (
            customer.address !== customerBeforeChanges.address ||
            customer.addressState !== customerBeforeChanges.addressState ||
            customer.addressNumber !== customerBeforeChanges.addressNumber ||
            customer.addressCity !== customerBeforeChanges.addressCity ||
            customer.iDCountryCode !== customerBeforeChanges.iDCountryCode ||
            customer.addressZipCode !== customerBeforeChanges.addressZipCode ||
            customer.addressName !== customerBeforeChanges.addressName ||
            customer.addressCityName !== customerBeforeChanges.addressCityName
          ) {
            address = true;
            let customerData = { ...customer };
            customerData.address =
              currentStreet && customerData.addressCountryCode === "IL"
                ? currentStreet.code
                : customerData.address;
            customerData.addressName =
              currentStreet && customerData.addressCountryCode === "IL"
                ? currentStreet.text
                : customerData.addressName;
            setcustomerBeforeChanges(cloneDeep(customerData));

            if (userCredentials !== null) {
              setshowSpiner(true);
              customer.addressId
                ? //customer
                await updateAddress(
                  customerData,
                  userCredentials,
                  currentIdEntity,
                  customer.addressId,
                  setShowDialogSave,
                  setReadOnly,
                  setshowSpiner,
                  "",
                  setShowDialogFailed,
                  setMessageError
                )
                : await CreateAddress(
                  customerData,
                  userCredentials,
                  currentIdEntity,
                  setshowSpiner,
                  "",
                  setShowDialogSave,
                  setShowDialogFailed,
                  setMessageError,
                  setReadOnly
                );
            }
          }
          if (
            customer.telephone !== customerBeforeChanges.telephone ||
            customer.telephoneCountryCode !==
            customerBeforeChanges.telephoneCountryCode
          ) {
            phone = true;
            setcustomerBeforeChanges(cloneDeep(customer));
            if (userCredentials !== null) {
              setshowSpiner(true);
              customer.telephoneId
                ? await updatePhone(
                  customer,
                  userCredentials,
                  currentIdEntity,
                  customer.telephoneId,
                  setShowDialogSave,
                  setReadOnly,
                  setshowSpiner,
                  "",
                  setShowDialogFailed,
                  setMessageError
                )
                : await CreatePhone(
                  customer,
                  userCredentials,
                  currentIdEntity,
                  setshowSpiner,
                  "",
                  setShowDialogSave,
                  setShowDialogFailed,
                  setMessageError,
                  setReadOnly
                );
            }
          }

          setcustomerBeforeChanges(cloneDeep(customer));
          if (customer.email !== customerBeforeChanges.email) {
            if (validEmail) {
              mail = true;
              setcustomerBeforeChanges(cloneDeep(customer));
              if (userCredentials !== null) {
                setshowSpiner(true);
                if (customer.emailId)
                  customer.email === ""
                    ? await deleteEmail(
                      userCredentials,
                      currentIdEntity,
                      customer.emailId,
                      setshowSpiner
                    )
                    : await updateEmail(
                      customer,
                      userCredentials,
                      currentIdEntity,
                      customer.emailId,
                      setShowDialogSave,
                      setReadOnly,
                      setshowSpiner,
                      "",
                      setShowDialogFailed,
                      setMessageError
                    );
                else {
                  await CreateEmail(
                    customer,
                    userCredentials,
                    currentIdEntity,
                    setshowSpiner,
                    "",
                    setShowDialogSave,
                    setShowDialogFailed,
                    setMessageError,
                    setReadOnly
                  );
                }
              }
            } else {
              setReadOnly(false);
              setShowDialogFailed(true);
              setMessageError(t("validFields"));
            }
          }
        } else {
          setShowDialogFailed(true);
          setMessageError(t("validFields"));
        }
      } else {
        setRequiredFields(true);
        setShowDialogRequiredFields(true);
      }
    }
    if (
      info === false &&
      address === false &&
      mail === false &&
      phone === false
    )
      setReadOnly(true);
    else getCustomerInfo();
  };

  //send the update on customer
  const handleSubmit = (e: any) => {
    const { name } = e?.name;
    setTypeButton(name);
    const requestMethod = typeButton;
    e.preventDefault();
    setshowSpiner(true);
    if (userCredentials) {
      dispatch(createCustomer(customer, userCredentials, true, setshowSpiner));
    }
  };

  const updateIndustry = (key: string, value: any) => {
    if (key === "industryId") {
      enumsDropdown?.industryClassification?.forEach((item: any) => {
        if (item?.key === value) {
          setEnumSecondaryIndustry([]);
          setEnumSecondaryIndustry(
            convertArrToDropdropdown(item.industrySubClassification)
          );
          setCustomer({ ...customer, ["CreditGroup"]: -1, [key]: value });
        }
      });
    } else {
      setCustomer({ ...customer, [key]: value });
    }
  };

  //update sate customer in form
  const updateCustomer = (key: string, value: any, text: string) => {
    console.log(key,"keycostemer");
    console.log(value,"valuecostemer");

    if (key === "customerTypeId") {
      if (
        value === "3" ||
        value === "5" ||
        value === "9" ||
        value === "12" ||
        value === "10"
      ) {
        let genderValue: any = "",
          typeIdValue: any = "";
        setCheckedCorporation(true);
        setOpenmiddleName(false);
        genderValue = enumsDropdown?.gendersCorporation.find(
          ({ text }: any) => text === "תאגיד"
        );
        typeIdValue = enumsDropdown?.typeIdentityNumbers.find(
          ({ text }: any) => text === "מספר תאגיד"
        );
        if (arrTypeCustomer.includes(value)) {
          setCustomer({
            ...customer,
            [key]: value,
            ["gender"]: genderValue?.key,
            ["idNumber"]: "",
            ["idTypeId"]: "",
            ["firstName"]: "",
            ["middleName"]: "",
            ["idTypeCountryCode"]: "",
          });
        } else {
          setCustomer({
            ...customer,
            [key]: value,
            ["gender"]: genderValue?.key,
            ["idNumber"]: "",
            ["idTypeId"]: typeIdValue?.key,
            ["firstName"]: "",
            ["middleName"]: "",
          });
        }
        setResultId(true);
      } else {
        if (arrTypeCustomer.includes(value)) {
          setCustomer({
            ...customer,
            [key]: value,
            ["idTypeCountryCode"]: "",
            ["idTypeId"]: "",
          });
          setCheckedCorporation(false);
        } else {
          setCustomer({
            ...customer,
            [key]: value,
            ["gender"]: genderDefault?.key,
            ["idNumber"]: "",
            ["idTypeId"]: typeIdDefault?.key,
          });
          setResultId(true);
          setCheckedCorporation(false);
        }
      }
    } else {
      if (!checkedCorporation) {
        if (
          (key === "idTypeCountryCode" &&
            value === "IL" &&
            customer.idTypeId === typeIdDefault?.key) ||
          (key === "idTypeId" &&
            value === typeIdDefault?.key &&
            customer.idTypeCountryCode === "IL")
        ) {
          let result = validator.isIdentityCard(customer.idNumber, "he-IL");
          setResultId(result);
        } else if (
          key !== "idNumber" &&
          key !== "idTypeCountryCode" &&
          key !== "idTypeId" &&
          customer.idTypeCountryCode === "IL" &&
          customer.idTypeId === typeIdDefault?.key &&
          customer?.idNumber !== ""
        ) {
          let result = validator.isIdentityCard(customer.idNumber, "he-IL");
          setResultId(result);
        } else setResultId(true);
      }
      if (
        key === "idTypeCountryCode" &&
        (value === "more" || value === "less")
      ) {
        setisLess(!isLess);
      }
      if (key === "iDCountryCode" && (value === "more" || value === "less")) {
        setisLessAddress(!isLessAddress);
      } else {
        if (key === "iDCountryCode")
          setCustomer({
            ...customer,
            [key]: value,
            addressCountryCode: "",
            ["address"]: "",
            ["addressCity"]: "",
            ["addressName"]: "",
            ["addressCityName"]: "",
          });
        else {
          if (key === "iDCountryCode")
            setCustomer({
              ...customer,
              [key]: value,
              addressCountryCode: "",
              ["address"]: "",
              ["addressCity"]: "",
              ["addressName"]: "",
              ["addressCityName"]: "",
            });
          else {
            if (
              key === "idTypeCountryCode" &&
              arrTypeCustomer.includes(customer?.customerTypeId) &&
              value === "IL"
            ) {
              alert("לא יתכן שמדינת מספר זהות תהיה בישראל כאשר הלקוח זר");
            } else {
              if (
                key === "idTypeId" &&
                arrTypeCustomer.includes(customer?.customerTypeId) &&
                !arrTypeIdCustomer.includes(value)
              ) {
                alert("לא יתכן לבחור בסוג מספר זה כאשר הלקוח זר");
              } else {
                setCustomer({
                  ...customer,
                  [key]: value,
                });
              }
            }
          }
        }
      }
    }
  };

  //update sate customer in form toggle fieldes
  const updatetoggle = (key: string, value: any) => {
    setCustomer({
      ...customer,
      [key]: value,
    });
  };

  const cancelEdit = async () => {
    let isExsistAddress = enumsDropdown.selectedCountryCode.find(
      (item: any) => item.key === customerBeforeChanges?.iDCountryCode
    );
    !isExsistAddress && setisLessAddress(false);
    let isExsist = enumsDropdown.selectedCountryCode.find(
      (item: any) => item.key === customerBeforeChanges?.idTypeCountryCode
    );
    !isExsist && setisLess(false);
    setCustomer(cloneDeep(customerBeforeChanges));
    setValidEmail(true);
    if (
      customerBeforeChanges?.customerTypeId === "3" ||
      customerBeforeChanges?.customerTypeId === "5" ||
      customerBeforeChanges?.customerTypeId === "9" ||
      customerBeforeChanges?.customerTypeId === "12" ||
      customerBeforeChanges?.customerTypeId === "10"
    )
      setCheckedCorporation(true);
    else setCheckedCorporation(false);
    setReadOnly(true);
    setNewCustomer(false);
  };

  const onChangeCity = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ): void => {
    if (!readOnly) {
      let key: any = option?.key;
      setCustomer({
        ...customer,
        addressCityName: option?.text,
        addressCity: key,
        address: "",
        addressName: "",
      });
    }
  };

  const onChangeStreet = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ): void => {
    if (!readOnly) {
      let key: any = option?.key;
      let text: any = option?.text;
      if (allowFreeform && !option && value) {
        key = newKey;
        key = Number(key);
        let arr: IComboBoxOption[] = [...optionsStreet];
        arr.push({ key: key!, text: value });
        setOptionsStreet(arr);
        setNewKey(newKey + 1);
      }
      let currentStreet: any = optionsStreet.find(
        (street: any) => street.key === key
      );
      setCurrentStreet(currentStreet);
      setCustomer({
        ...customer,
        address: currentStreet?.key,
        addressName: text ? text : value,
      });
    }
  };

  const checkValidationEmail = (e: any) => {
    if (e.target.value !== "" && !readOnly) {
      let valid = validator["isEmail"](e.target.value);
      valid ? setValidEmail(true) : setValidEmail(false);
    } else {
      setValidEmail(true);
    }
  };
  const checkValidationId = (e: any) => {
    if (
      !checkedCorporation &&
      customer.idTypeId === "1" &&
      customer.idTypeCountryCode === "IL" &&
      customer.idTypeId === typeIdDefault?.key
    ) {
      if (e.target.value !== "" && !readOnly) {
        let valid = validator.isIdentityCard(e.target.value, "he-IL");
        valid ? setResultId(true) : setResultId(false);
      } else {
        setResultId(true);
      }
    } else {
      if (
        !arrTypeCustomer?.includes(customer?.customerTypeId) &&
        !arrTypeIdCustomer?.includes(customer?.idTypeId)
      ) {
        if (Number(e.target.value)) {
          setResultId(true);
        } else {
          setResultId(false);
        }
      } else {
        setResultId(true);
      }
    }
  };

  return (
    <div>
      <form id="customerPage" ref={printRef} onSubmit={(e) => handleSubmit(e)}>
        <div className={`sub-header-${dir} fixed-${dir}`}>
          <Title
            title={t("customers")}
            nameInTitle={
              customer?.firstName
                ? customer?.firstName + " " + customer?.lastName
                : customer?.lastName
            }
          />
          <div className="divider"></div>
          <div className="divider"></div>
          {NewCustomer === true && permission === "RW" && (
            <PrimaryButton
              ariaDescription="create"
              className="button"
              allowDisabledFocus
              onClick={createButton}
              checked={false}
              name="create"
              text={t("save-customer")}
              iconProps={Icons.addFriend}
            />
          )}
          {NewCustomer === false && permission === "RW" && (
            <PrimaryButton
              ariaDescription="create"
              className="button"
              allowDisabledFocus
              onClick={newCustomerFor}
              checked={false}
              name="create"
              text={t("createCustomer")}
              iconProps={Icons.addFriend}
            />
          )}
          {readOnly &&
            NewCustomer === false &&
            customer?.idNumber &&
            permission === "RW" && (
              <DefaultButton
                className="button edit-button"
                onClick={editButton}
                checked={false}
                text={t("edit")}
                iconProps={Icons.editContact}
                name="update"
              />
            )}
          {!readOnly
            ? permission === "RW" && (
              <DefaultButton
                className="button edit-button"
                checked={false}
                onClick={cancelEdit}
                text={t("cancel")}
                iconProps={Icons.editContact}
                name="cancel"
              />
            )
            : ""}
          {readOnly || NewCustomer === true
            ? ""
            : permission === "RW" && (
              <DefaultButton
                className="button save-upload"
                onClick={saveButton}
                disabled={readOnly}
                checked={false}
                text={t("save")}
                iconProps={Icons.cloudUpload}
              />
            )}
          <IconButton
            iconProps={Icons.pdf}
            styles={{
              icon: { color: "red", fontSize: 36 },
            }}
            className="button"
            onClick={() =>
              GenericPdfDownloader({
                rootElementId: "customerPage",
                downloadFileName: "פרטי לקוח",
              })
            }
          />
          <IconButton
            iconProps={Icons.print}
            styles={{
              icon: { color: "black", fontSize: 36 },
            }}
            className="button"
            onClick={handlePrint}
          />
          <ExportCSV csvData={[customer]} fileName={"xcelCustomer"} />
          <div
            className="button"
            style={{
              cursor: "pointer",
            }}
            title={t("refresh")}
            onClick={() => {
              dispatch(
                readCustomerId(
                  customer?.idEntity,
                  userCredentials,
                  "",
                  "",
                  "",
                  false
                )
              );
            }}
          >
            {" "}
            <Icon iconName="refresh"></Icon>
          </div>
        </div>

        {showDialogFailed && (
          <DialogMessages
            setansDelete={setShowDialogFailed}
            subText={messageError}
            title={t("error")}
            setshowModalDelete={setShowDialogFailed}
            dialogType={"dialogMessage"}
          />
        )}
        {showSpiner && (
          <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />
        )}
        {showDialogSave && (
          <DialogMessages
            setansDelete={setShowDialogSave}
            setshowModalDelete={setShowDialogSave}
            dialogType={"save"}
          />
        )}
        {showDialogRequiredFields && (
          <DialogMessages
            setansDelete={setShowDialogRequiredFields}
            setshowModalDelete={setShowDialogRequiredFields}
            dialogType={"requiredFields"}
          />
        )}
        {showDialogDelete && (
          <DialogMessages
            setansDelete={setShowDialogDelete}
            setifDelete={setifDelete}
            setshowModalDelete={setShowDialogDelete}
            dialogType={"delete"}
          />
        )}
        {showDialogUpdate && (
          <DialogMessages
            setansDelete={setShowDialogUpdate}
            setshowModalDelete={setShowDialogUpdate}
            dialogType={"update"}
          />
        )}
        <br></br>
        <br></br>
        <Subtitle title={t("customerDetails")} />

        <div className={`content-div-${dir}`} style={{ width: "50%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="title-text">{t("personalDetails")}</p>
            <div style={{ display: "flex", alignItems: "end" }}>
              <CustomToggle
                onText={t("customerLock")}
                readOnly={readOnly}
                onChange={updatetoggle}
                id={"isLocked"}
                defaultChecked={customer.isLocked}
                checked={customer.isLocked}
                offText={t("customerLock")}
              />
            </div>
          </div>
          <hr className="hr"></hr>

          <div
            className="wrapper-collateral"
            style={{ alignItems: "baseline" }}
          >
            <div className={`warpper-fields-right-${dir}`}>
              <div className="wrapper-collateral">
                <div
                  className={`div-customer-details-${dir}`}
                  style={{
                    width:
                      customer?.customerTypeId === "6" ||
                        customer?.customerTypeId === "7"
                        ? "95%"
                        : "100%",
                  }}
                >
                  <CustomDropdown
                    otherInputId={""}
                    hasOtherValue={false}
                    readOnly={readOnly}
                    options={enumsDropdown?.customersType}
                    label={t("customerType")}
                    onChange={updateCustomer}
                    selectedKey={customer?.customerTypeId}
                    id={"customerTypeId"}
                    othertextInput={t("")}
                  />
                </div>
                {(customer?.customerTypeId === "6" ||
                  customer?.customerTypeId === "7") && (
                    <div className={`customer-type-${dir}`}>
                      <label style={{ color: "red", fontWeight: 700 }}>
                        {"!"}
                      </label>{" "}
                    </div>
                  )}
              </div>

              {!checkedCorporation && (
                <div className="wrapper-collateral">
                  <div
                    style={{ width: "95%" }}
                    className={`div-customer-details-${dir}`}
                  >
                    <CustomTextField
                      value={customer?.firstName}
                      requiredField={
                        requiredFields && customer?.firstName === ""
                          ? true
                          : false
                      }
                      readOnly={readOnly}
                      required={true}
                      label={t("firstName")}
                      onChange={updateCustomer}
                      id={"firstName"}
                    />
                  </div>
                  <div
                    onClick={() => setOpenmiddleName(!openmiddleName)}
                    className={`middle-name-${dir}`}
                  >
                    <Icon iconName="Add"></Icon>{" "}
                  </div>
                </div>
              )}
              {openmiddleName && !checkedCorporation && (
                <CustomTextField
                  value={customer?.middleName}
                  readOnly={readOnly}
                  label={t("middleName")}
                  onChange={updateCustomer}
                  id={"middleName"}
                />
              )}
              <CustomTextField
                value={customer.lastName}
                readOnly={readOnly}
                requiredField={
                  requiredFields && customer?.lastName === "" ? true : false
                }
                required={true}
                label={
                  checkedCorporation ? t("corporationName") : t("lastName")
                }
                onChange={updateCustomer}
                id={"lastName"}
              />
              <CustomerDate
                isDefaultDate={false}
                setIsValidDate={setIsValidDate}
                isValidDate={isValidDate}
                showRequiredFildes={requiredFields}
                entity={customer}
                setEntity={setCustomer}
                readOnly={readOnly}
                idDate="dateBirth"
                label={
                  checkedCorporation ? t("establishmentDate") : t("dateOfBirth")
                }
                source={"pastDate"}
              />
              {!checkedCorporation && (
                <CustomDropdown
                  otherInputId={"genderName"}
                  otherValue={customer.genderName}
                  hasOtherValue={true}
                  options={
                    checkedCorporation
                      ? enumsDropdown?.gendersCorporation
                      : enumsDropdown?.genders
                  }
                  label={t("gander")}
                  readOnly={readOnly}
                  onChange={updateCustomer}
                  selectedKey={customer?.gender}
                  id={"gender"}
                  othertextInput={t("other")}
                />
              )}
            </div>
            <div className={`warpper-fields-left-${dir}`}>
              <CustomTextField
                onBlur={(e: any) => checkValidationId(e)}
                requiredField={
                  requiredFields && customer?.idNumber === "" ? true : false
                }
                value={customer.idNumber}
                readOnly={readOnly}
                required={true}
                label={
                  checkedCorporation
                    ? t("corporationNumber")
                    : t("identityNumber")
                }
                onChange={updateCustomer}
                id={"idNumber"}
              />
              {!resultId && (
                <p className="text-feild-note">
                  {!checkedCorporation
                    ? t("notificationOfValidId")
                    : t("notificationOfValidNmberCompany")}
                </p>
              )}
              {!isLess && (
                <CustomDropdown
                  otherInputId={""}
                  readOnly={readOnly}
                  hasOtherValue={false}
                  options={enumsDropdown?.countryCode}
                  label={
                    checkedCorporation
                      ? t("StateOfIncorporation")
                      : t("countryIdentityNumber")
                  }
                  onChange={updateCustomer}
                  selectedKey={customer?.idTypeCountryCode}
                  id={"idTypeCountryCode"}
                  othertextInput={t("")}
                />
              )}
              {isLess && (
                <CustomDropdown
                  otherInputId={""}
                  readOnly={readOnly}
                  hasOtherValue={false}
                  options={enumsDropdown?.selectedCountryCode}
                  label={
                    checkedCorporation
                      ? t("StateOfIncorporation")
                      : t("countryIdentityNumber")
                  }
                  onChange={updateCustomer}
                  selectedKey={customer?.idTypeCountryCode}
                  id={"idTypeCountryCode"}
                  othertextInput={t("")}
                />
              )}
              {checkedCorporation ? (
                <CustomDropdown
                  otherInputId={"idTypeOther"}
                  readOnly={readOnly}
                  otherValue={customer.idTypeOther}
                  hasOtherValue={true}
                  options={enumsDropdown?.typeIdentityNumbers}
                  label={
                    checkedCorporation
                      ? t("TypeOfCorporation")
                      : t("typeIdentityNumber")
                  }
                  onChange={updateCustomer}
                  selectedKey={customer?.idTypeId}
                  id={"idTypeId"}
                  othertextInput={t("typeIdentityNumberOther")}
                />
              ) : (
                <CustomDropdown
                  otherInputId={"idTypeOther"}
                  readOnly={readOnly}
                  otherValue={customer.idTypeOther}
                  hasOtherValue={true}
                  options={enumsDropdown?.selectedTypeIdentityNumbers}
                  label={
                    checkedCorporation
                      ? t("TypeOfCorporation")
                      : t("typeIdentityNumber")
                  }
                  onChange={updateCustomer}
                  selectedKey={customer?.idTypeId}
                  id={"idTypeId"}
                  othertextInput={t("typeIdentityNumberOther")}
                />
              )}
            </div>
          </div>

          <p className="sub-title-text" style={{ marginTop: "3%" }}>
            {t("contactInformation")}
          </p>
          <hr className="hr"></hr>
          <div className="wrapper-collateral" style={{ alignItems: "unset" }}>
            <div
              className={`warpper-fields-right-${dir} marginL-between-container-${dir}`}
            >
              <p className="title-text-2">{t("address")}</p>
              <hr className="hr"></hr>
              <div
                className="wrapper-collateral"
                style={{ alignItems: "self-end" }}
              >
                <div className={`warpper-fields-right-${dir}`}>
                  {!isLessAddress && (
                    <CustomDropdown
                      otherInputId={""}
                      readOnly={readOnly}
                      hasOtherValue={false}
                      options={enumsDropdown?.countryCode}
                      label={t("country")}
                      onChange={updateCustomer}
                      selectedKey={customer?.iDCountryCode}
                      id={"iDCountryCode"}
                      othertextInput={t("")}
                    />
                  )}

                  {isLessAddress && (
                    <CustomDropdown
                      otherInputId={""}
                      readOnly={readOnly}
                      hasOtherValue={false}
                      options={enumsDropdown?.selectedCountryCode}
                      label={t("country")}
                      onChange={updateCustomer}
                      selectedKey={customer?.iDCountryCode}
                      id={"iDCountryCode"}
                      othertextInput={t("")}
                    />
                  )}
                </div>
                <div className={`warpper-fields-left-${dir}`}>
                  {customer?.iDCountryCode === "IL" && !readOnly ? (
                    <ComboBox
                      id={"addressCity"}
                      label={t("city")}
                      styles={
                        (dir === "ltr" && {
                          root: {
                            paddingRight: "9px !important",
                            paddingLeft: "32px !important",
                          },
                        }) ||
                        (requiredFields && customer?.addressCity === ""
                          ? requiredFieldStyle
                          : customStyles)
                      }
                      selectedKey={Number(customer?.addressCity)}
                      autoComplete="on"
                      data-is-focusable={readOnly}
                      allowFreeform={!readOnly ? true : false}
                      options={optionsCity}
                      onChange={onChangeCity}
                    />
                  ) : (
                    <CustomTextField
                      value={customer.addressCityName}
                      readOnly={readOnly}
                      label={t("city")}
                      onChange={updateCustomer}
                      id={"addressCityName"}
                    />
                  )}
                </div>
              </div>
              <div
                className="wrapper-collateral"
                style={{ alignItems: "self-end" }}
              >
                <div className={`cusromer-country-${dir}`}>
                  {customer?.iDCountryCode === "IL" &&
                    customer.addressCity !== "" &&
                    !readOnly ? (
                    <div>
                      <ComboBox
                        id={"address"}
                        label={t("street")}
                        styles={
                          (dir === "ltr" && {
                            root: {
                              paddingRight: "9px !important",
                              paddingLeft: "32px !important",
                            },
                          }) ||
                          (requiredFields && customer.address === ""
                            ? requiredFieldStyle
                            : customStyles)
                        }
                        selectedKey={
                          customer.address !== ""
                            ? Number(customer.address)
                            : ""
                        }
                        autoComplete="on"
                        allowFreeform={!readOnly ? true : false}
                        options={optionsStreet}
                        onChange={onChangeStreet}
                      />
                    </div>
                  ) : (
                    <CustomTextField
                      value={customer?.addressName}
                      requiredField={
                        requiredFields && customer?.addressName === ""
                          ? true
                          : false
                      }
                      readOnly={readOnly}
                      label={t("street")}
                      onChange={updateCustomer}
                      id={"addressName"}
                    />
                  )}
                </div>
                <div className={`div-address-number-${dir}`}>
                  <CustomTextField
                    value={customer.addressNumber}
                    requiredField={
                      requiredFields && customer?.addressNumber === ""
                        ? true
                        : false
                    }
                    readOnly={readOnly}
                    label={t("houseNumber")}
                    onChange={updateCustomer}
                    id={"addressNumber"}
                  />
                </div>
              </div>

              <div
                className="wrapper-collateral"
                style={{ alignItems: "self-end" }}
              >
                <div
                  style={{ width: "95%" }}
                  className={`div-customer-details-${dir}`}
                >
                  <CustomTextField
                    value={customer.addressZipCode}
                    readOnly={readOnly}
                    label={t("addressZipCode")}
                    onChange={updateCustomer}
                    id={"addressZipCode"}
                  />
                </div>
                <div
                  onClick={() => {
                    window.open(`${postalCode}`, "_blank");
                  }}
                  className={`div-zip-code-${dir}`}
                >
                  <Icon iconName="refresh"></Icon>
                </div>
              </div>
            </div>
            <div className={`warpper-fields-left-${dir}`}>
              <p className="title-text-2">{t("Phone&Email")}</p>
              <hr className="hr"></hr>
              <div
                className="wrapper-collateral"
                style={{ alignItems: "self-end" }}
              >
                <div className={`customer-pgone-${dir}`}>
                  <CustomTextField
                    readOnly={readOnly}
                    value={customer.telephone}
                    label={t("phone")}
                    onChange={updateCustomer}
                    id={"telephone"}
                  />
                </div>
                <div className={`area-country-${dir}`}>
                  <CustomDropdown
                    readOnly={readOnly}
                    otherInputId={""}
                    hasOtherValue={false}
                    options={enumsDropdown?.telephoneCountryCode}
                    label={t("AreaCodeCountry")}
                    onChange={updateCustomer}
                    selectedKey={customer?.telephoneCountryCode}
                    id={"telephoneCountryCode"}
                    othertextInput={t("")}
                  />
                </div>
              </div>
              <div className="customer-email">
                <CustomTextField
                  onBlur={(e: any) => checkValidationEmail(e)}
                  value={customer.email}
                  readOnly={readOnly}
                  label={t("emailAddress")}
                  onChange={updateCustomer}
                  id={"email"}
                  type="email"
                />
              </div>
              {!validEmail && (
                <p className="text-feild-note">{t("validEmail")}</p>
              )}
            </div>
          </div>
          <p className="sub-title-text" style={{ marginTop: "3%" }}>
            {t("MoreDetails")}
          </p>
          <hr className="hr"></hr>
          <div className="wrapper-collateral" style={{ alignItems: "unset" }}>
            <div className={`warpper-fields-right-${dir}`}>
              {NewCustomer ? (
                <CustomDropdown
                  otherInputId={""}
                  readOnly={readOnly}
                  hasOtherValue={false}
                  options={enumsDropdown?.statusAdded}
                  label={t("customerStatus")}
                  onChange={updateCustomer}
                  selectedKey={customer.entityStatusId}
                  id={"entityStatusId"}
                  othertextInput={t("")}
                />
              ) : (
                <CustomDropdown
                  otherInputId={""}
                  readOnly={readOnly}
                  hasOtherValue={false}
                  options={enumsDropdown?.status}
                  label={t("customerStatus")}
                  onChange={updateCustomer}
                  selectedKey={customer.entityStatusId}
                  id={"entityStatusId"}
                  othertextInput={t("")}
                />
              )}
              {!NewCustomer && (
                <CustomTextField
                  value={customer.entityNumber}
                  tabIndex={-1}
                  readOnly={true}
                  label={t("customerNumber")}
                  onChange={updateCustomer}
                  id={"entityNumber"}
                />
              )}
              <CustomDropdown
                otherInputId={""}
                readOnly={readOnly || NewCustomer}
                hasOtherValue={false}
                options={workersNames}
                label={t("nameIDEmployee")}
                onChange={updateCustomer}
                selectedKey={customer.workerName}
                id={"workerName"}
                othertextInput={t("")}
              />
              {isWorker === "false" && (
                <p style={{ margin: "0" }} className="text-feild-note">
                  {t("employeeIDNotFound")}
                </p>
              )}
            </div>
            <div className={`warpper-fields-left-${dir}`}>
              {
                <>
                  <CustomDropdown
                    otherInputId={""}
                    readOnly={readOnly}
                    hasOtherValue={false}
                    options={enumsDropdown?.industryClassification}
                    label={t("areaOfPracticeOrIndustry")}
                    onChange={updateIndustry}
                    selectedKey={customer.industryId}
                    id={"industryId"}
                    othertextInput={t("")}
                  />
                  <CustomDropdown
                    otherInputId={""}
                    readOnly={
                      !readOnly && enumSecondaryIndustry.length > 0
                        ? false
                        : true
                    }
                    hasOtherValue={false}
                    options={enumSecondaryIndustry}
                    label={t("areaOfOccupationOrSecondaryIndustry")}
                    onChange={updateIndustry}
                    selectedKey={
                      Number(customer.CreditGroup) || customer.CreditGroup
                    }
                    id={"CreditGroup"}
                    othertextInput={t("")}
                  />
                </>
              }
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="sub-title-text" style={{ marginTop: "3.5%" }}>
              {t("note")}
            </p>
            <div style={{ display: "flex", alignItems: "end" }}>
              <CustomToggle
                onText={t("viewNoteWhenPerformingAction")}
                onChange={updatetoggle}
                id={"isPopupMessage"}
                readOnly={readOnly}
                defaultChecked={customer?.isPopupMessage}
                checked={customer?.isPopupMessage}
                offText={t("viewNoteWhenPerformingAction")}
              />
            </div>
          </div>
          <hr className="hr"></hr>
          <TextFeildNote
            label={t("")}
            value={customer?.note}
            onChange={updateCustomer}
            id={"note"}
          />
        </div>
      </form>
    </div>
  );
};
export default CustomerDetails;
