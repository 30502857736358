import { useEffect, useRef } from 'react';
import { Dialog,ContextualMenu, PrimaryButton } from 'office-ui-fabric-react';
import { useBoolean } from '@fluentui/react-hooks';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'


import { useReactToPrint } from 'react-to-print';
import { Icons } from '../../../../modelsType/Icon';
import { RESET_HTML_PAGE } from '../../../../store/actions/types';

const DialogCompleteTransactions = (props: any) => {
    const {setShowDialog } = props
    const history = useHistory()
    const dispatch = useDispatch()
    const html = useSelector(({ completeDocReducer }: any) => completeDocReducer?.htmlPage)
    let componentRef = useRef(null);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    useEffect(() => {
        if (setShowDialog) {
            toggleHideDialog()
        }
    }, [setShowDialog, toggleHideDialog])

    history.location.state = "showCompleted"
    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
      };
    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        dragOptions: dragOptions 
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const closeDialog = async () => {
        await dispatch({
            type: RESET_HTML_PAGE,
            payload: null
        })
        toggleHideDialog()
        setShowDialog(false)
    }

    return (
        <>
            {(html) && <Dialog minWidth="fit-ntent" maxWidth="fit-content"  hidden={hideDialog} modalProps={modelProps} onDismiss={closeDialog}  >
                <div>
                    <PrimaryButton id='print' className="button" iconProps={Icons.print} onClick={handlePrint} text="" />
                    <div ref={componentRef} dangerouslySetInnerHTML={{ __html: html }} />
                </div>
            </Dialog>}
        </>
    )

}
export default DialogCompleteTransactions
