import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ContextualMenu, Dialog, mergeStyleSets, DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import img from '../../assets/image.png'
//dont delete it
// import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils';
// import Subtitle from '../../shared/components/Subtitle';
// import Collapse from './Collapse';
// import SumPage from './SumPage';
import { homePageAccountData } from '../../store/actions/accountAction';
import { useBoolean } from '@fluentui/react-hooks';
import { IUserCredentials } from '../customerDetails/CustomerDetails';
// import CustemTable from '../../shared/components/tabels/tableList';
// import DetailsListGrouped from '../../shared/components/tabels/tableListWithGroup';
// import { DetailsList, SelectionMode } from '@fluentui/react';
import TableAccountBalance from './tableAccountBalance';
import TableAccountChequesCollection from './TableAccountChequesCollection';
import { accountsFilter } from '../../store/actions/bankActions';
import AccountsTable from '../customerAccounts/AccountsTable';
const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
};
const modelProps = {
    isBlocking: false,
    topOffsetFixed: true,
    dragOptions: dragOptions
};
export default function HomePageCostumer(props: any) {
    const { permissionHomePage } = props
    const data = useSelector(({ authReducer }: any) => authReducer?.data)
    const dataHomePage = useSelector(({ accountReducer }: any) => accountReducer?.dataHomePageAccount)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [t, i18n] = useTranslation();
    const dispatch = useDispatch()
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [showDialogAccount, setShowDialogAccount] = useState(false);
    const [messageError, setMessageError] = useState('')
    const [serverMessageError, setServerMessageError] = useState<any>('')
    const [dialogTitle, setDialogTitle] = useState('')
    const [selectedAccount, selSelectedAccount] = useState<any>()
    const [currencyList, setCrrencyList] = useState<any>([])
    const [accountsData, setAccountsData] = useState<any>()
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    // //dont delete it
    // const [showCollapse, setShowCollapse] = useState<any>([false, false, false, false, false])
    // const arrValue = [{ bank: 'בנק הפועלים', value: '180,000', coin: '?.dataCustomerדולר אמריקאי', valueCoin: "100,000", sum: '320,000' },
    // { bank: 'בנק הפועלים', value: '180,000', coin: 'דולר אמריקאי', valueCoin: "100,000", sum: '320,000' }]
    // const title = { key: 'שיקים למשמרת בבנקים', value: '300,00' }


    const classNames = mergeStyleSets({
        dirCell: {
            direction: 'ltr',
            paddingRight: "8px",
            borderLeft: " 0.5px solid #e1e1e1"
        },
        cellStyle: {
            paddingRight: "8px",
            borderLeft: " 0.5px solid #e1e1e1"
        }
    });
    const [accountChequesStatusGroups, setAccountChequesStatusGroups] = useState<any>([])

    const accountBalanceColumns = [
        {
            key: '1', name: t('accountName'), fieldName: "account_name",
            maxWidth: 250, number: false
        },
        {
            key: '2', name: t('accountNumber'), fieldName: "account_number",
            maxWidth: 100, number: false
        },
        {
            key: '2', name: t('balanceTo'), fieldName: "account_balance",
            maxWidth: 130, number: true
        },
        {
            key: '2', name: t('balanceTemporary'), fieldName: "account_balance_temp",
            maxWidth: 150, number: true
        },
        {
            key: '3', name: t('debitMovements'), fieldName: "debit", entity_name: "debit",
            maxWidth: 130, number: true

        },
        {
            key: '4', name: t('creditMovements'), fieldName: "credit", minWidth: 130
            , number: true
        },
        {
            key: '5', name: t('sumAmount'), fieldName: "sum_amount",
            maxWidth: 130, number: true
        },
        {
            key: '6', name: t('currencyCode'), fieldName: "currency_code", entity_name: "currency_code",
            maxWidth: 80, number: false
        },
    ];
    const accountChequesCollectionColumns = [
        {
            key: '1', name: t('maturityDays'), fieldName: "days_collection_header", minWidth: 150, maxWidth: 150,
            className: classNames.dirCell, number: false
        },
        {
            key: '2', name: t('dayBegin'), fieldName: "day_begin", entity_name: "day_begin", minWidth: 180, maxWidth: 180
            , className: classNames.cellStyle, number: false
        },
        {
            key: '3', name: t('dayEnd'), fieldName: "day_end", entity_name: "day_end",
            minWidth: 150, maxWidth: 150, className: classNames.cellStyle, number: false
        },
        {
            key: '4', name: t('totalMaturityDays'), fieldName: "days_collection", entity_name: "days_collection",
            minWidth: 270, maxWidth: 270, className: classNames.cellStyle, number: false
        },
        {
            key: '5', name: t('accountName'), fieldName: "contra_account_name", minWidth: 160,
            maxWidth: 160, className: classNames.cellStyle, number: false
        },
        {
            key: '6', name: t('accountNumber'), fieldName: "contra_account_number", minWidth: 240,
            maxWidth: 240, className: classNames.cellStyle, number: false
        },
        {
            key: '7', name: t('totalCountCheques'), fieldName: "count_cheques", minWidth: 280,
            maxWidth: 280, className: classNames.cellStyle, number: false
        },
        {
            key: '8', name: t('currencyCode'), fieldName: "currency_code", minWidth: 60,
            maxWidth: 60, className: classNames.cellStyle, number: false
        },
        {
            key: '9', name: t('sumAmount'), fieldName: "sum_amount", minWidth: 150,
            maxWidth: 150, className: classNames.cellStyle, number: false
        },
    ];

    const accountChequesStatusColumns = [
        {
            key: '2', name: t('status'), fieldName: "status", entity_name: "status", minWidth: 180,
            maxWidth: 180, className: classNames.cellStyle, number: false
        },
        {
            key: '3', name: t('sumAmount'), fieldName: "sum_amount", minWidth: 120,
            maxWidth: 120, className: classNames.dirCell, number: true
        },
        {
            key: '4', name: t('accountCheques'), fieldName: "count_cheques", minWidth: 130,
            maxWidth: 130, className: classNames.cellStyle, number: false
        },

        {
            key: '5', name: t('currencyCode'), fieldName: "currency_code", entity_name: "currency_code",
            minWidth: 60, maxWidth: 60, className: classNames.cellStyle
        },

    ];


    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer?.data?.user?.id_entity,
                "idClient": authReducer?.data?.user?.id_client,
                "idBranch": authReducer?.data?.branch?.id_entity,
                "idClientData": authReducer?.data?.client?.id_client
            })
        }
    }, [authReducer])

    useEffect(() => {
        if (userCredentials?.idClient && !dataHomePage) {
            dispatch(homePageAccountData(userCredentials))
        }
    }, [userCredentials])

    const showAccountMovements = async (value: any) => {
        setShowDialogAccount(true)
        toggleHideDialog();
        selSelectedAccount(value)
        await dispatch(accountsFilter(value?.id_account, value?.id_entity,
            userCredentials, "search_ledger", undefined, setAccountsData, 1, [], 10
            , setServerMessageError, setMessageError, setDialogTitle
        ))

    }


    function chequesStatus() {
        const newObjects: any = {};
        dataHomePage?.cheques?.cheques_status?.forEach((obj: any) => {
            const status = obj.status;
            if (!newObjects[status]) {
                newObjects[status] = {
                    status_id: obj.status_id,
                    status: obj.status,
                    statusCheques: []
                };
            }
            newObjects[status].statusCheques.push({
                currency_code: obj.currency_code,
                sum_amount: obj.sum_amount,
                count_cheques: obj.count_cheques
            });
        });

        const newArray = Object.values(newObjects);
        setAccountChequesStatusGroups(newArray)
    }
    useEffect(() => {
        if (dataHomePage) {
            chequesStatus()
        }
    }, [dataHomePage])

    return (
        <>
            <div className={`contain-title-home-${dir}`}>

                <h1 className={`titleHome-${dir}`}>{t('home_page')}</h1>
                <br></br><br></br>
                <div >
                    <h1 className={`subtitleHome-${dir}`} >{`${data?.user ? data.user.first_name + " " + data.user.last_name + "," : ""}  ${t('title_1')}`}</h1>
                    {!permissionHomePage && <h3 className='title-home-3'>{t('title_2')}</h3>}
                    {!permissionHomePage && <h5 className='title-home-5'>{t('title_3')}</h5>}
                </div>
                {!permissionHomePage && <div className='systems-icon'><img src={img}></img></div>}
            </div>
            {permissionHomePage && <div style={{ marginTop: "120px" }} className={`content-div-${dir}`}>

                <div style={{ width: "1100px", marginBottom: "80px" }}>
                    <p className="title-text" style={{ width: "1100px" }}>{t('accountBalances')}</p>
                    {dataHomePage?.account_balances ?
                        <TableAccountBalance items={dataHomePage?.account_balances} columns={accountBalanceColumns}
                            numSpanColumns={4} itemArrayName={"currency_balances"} showAccountMovements={showAccountMovements}></TableAccountBalance>
                        : t("searchCustomerNotFound")}
                </div>


                <div style={{ width: "900px", marginBottom: "80px" }}>
                    <p className="title-text" style={{ width: "1000px" }}>{t('agedChequeReport')}</p>
                    {dataHomePage?.cheques?.cheques_collection ?
                        <TableAccountChequesCollection data={dataHomePage?.cheques?.cheques_collection}
                            columns={accountChequesCollectionColumns}
                            numSpanColumns={4} itemArrayName={"cheques_collection_accounts"}
                            itemArrayName2={"cheques_collection_currency"}
                        />
                        : t("searchCustomerNotFound")}
                </div>
                <div style={{ width: "1100px", marginBottom: "80px" }}>
                    <p className="title-text" style={{ width: "1100px" }}>{t('chequesStatus')}</p>
                    {dataHomePage?.cheques?.cheques_status && accountChequesStatusGroups.length ?
                        <TableAccountBalance items={accountChequesStatusGroups} itemArrayName={"statusCheques"}
                            columns={accountChequesStatusColumns} numSpanColumns={1} ></TableAccountBalance>
                        : t("searchCustomerNotFound")}
                </div>

                {showDialogAccount && <Dialog minWidth="fit-content" maxWidth="fit-content"
                    hidden={hideDialog} modalProps={modelProps} onDismiss={() => { toggleHideDialog(); setShowDialogAccount(false) }}>
                    <div >
                        <p className="title-text" style={{ fontSize: "25px", fontWeight: "bold" }} >
                            {selectedAccount.account_name + "  " + selectedAccount?.account_number}</p>
                    </div>
                    <hr className="hr"></hr>
                    {accountsData?.records_found > 0 ? <AccountsTable
                        idAccount={selectedAccount?.id_account} accountsData={accountsData} setAccountsData={setAccountsData}
                        idEntity={selectedAccount?.id_entity} actionIn={false} setCrrencyList={setCrrencyList} /> :
                        <div >
                            לא נמצאו נתונים להצגה
                        </div>

                    }
                    {/* <PrimaryButton onClick={toggleHideDialog} text="Save" />
                    <DefaultButton onClick={toggleHideDialog} text="Cancel" /> */}
                </Dialog>
                }


            </div>}
            {/* //dont delete it */}
            {/* <div className={`home-page-div-${dir}`}>
                <div style={{ display: "flex" }}>
                    <div style={{ width: '45%' }}>
                        <p className="title-text" >שיקים</p>
                        <hr className="hr" style={{ width: "90%" }} ></hr>
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={0} />
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={1} />
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={2} />
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={3} />
                        <SumPage value={30000} />
                    </div>
                    <div style={{ width: '45%' }}>
                        <p className="title-text" >שיקים</p>
                        <hr className="hr" style={{ width: "90%" }} ></hr>
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={0} />
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={1} />
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={2} />
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={3} />
                        <SumPage value={30000} />
                    </div>
                </div>
                <div style={{ display: "flex", marginTop: '4%' }}>
                    <div style={{ width: '45%' }}>
                        <p className="title-text" >שיקים</p>
                        <hr className="hr" style={{ width: "90%" }} ></hr>
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={0} />
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={1} />
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={2} />
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={3} />
                        <SumPage value={30000} />
                    </div>
                    <div style={{ width: '45%' }}>
                        <p className="title-text" >שיקים</p>
                        <hr className="hr" style={{ width: "90%" }} ></hr>
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={0} />
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={1} />
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={2} />
                        <Collapse title={title} arrValue={arrValue} setShow={setShowCollapse} show={showCollapse} index={3} />
                        <SumPage value={30000} />
                    </div>
                </div>
            </div> */}

            {/* ////////// */}
        </>
    )
}
