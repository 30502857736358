import React from 'react';
import Parser from 'html-react-parser';

import icons from './iconLib';

function IconSvg(props: any) {
  const { name } = props;
  //@ts-ignore
  let icon = icons[name];
  if (!icon)
    console.error("There is no icon named: " + name);
  //@ts-ignore
  return (<> {icon && Parser(icons[name])} </>)
}
export default IconSvg;