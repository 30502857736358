
import React, { useState } from 'react';
import { useSelector } from 'react-redux';


export default function TableAccountChequesCollection(props: any) {

  const { data, columns, itemArrayName2, itemArrayName } = props
  const [hoveredRow, setHoveredRow] = useState<any>(null);

  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)

  function funcspan(row: any) {
    let sum = 0
    row?.[itemArrayName]?.forEach((item2: any) => {
      sum += item2?.[itemArrayName2]?.length
    })
    if (sum === 0)
      sum = 1
    return sum
  }


  return (
    <table style={{ border: '2px solid #e1e1e1', overflow: "auto" }}>
      <thead>
        <tr>
          {columns?.map((item: any, rowIndex: number) => (
            <React.Fragment key={rowIndex + "2"}>
              <th className={`padding-input-${dir} th-complete-table`}
                style={{ width: `${item?.maxWidth}px` }}>{item?.name}</th>
            </React.Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {data && data?.map((row: any, rowIndex: number) => {

          const ItemRows: any = row?.[itemArrayName]?.map(
            (balance: any, balanceIndex: any) => (
              <>
                {balanceIndex === 0 ? <React.Fragment
                  key={`${rowIndex}- ${balanceIndex}`}>
                  <>
                    <td className={`td-span-table-${dir} padding-input-${dir} `}
                      rowSpan={row?.[itemArrayName]?.[balanceIndex]?.[itemArrayName2].length}>
                      {balance?.contra_account_name}</td>

                    <td className={`td-span-table-${dir} padding-input-${dir} `}
                      rowSpan={row?.[itemArrayName]?.[balanceIndex]?.[itemArrayName2].length}>
                      {balance?.contra_account_number}</td>
                  </>

                  {row?.[itemArrayName]?.[balanceIndex]?.[itemArrayName2].map((currency: any, currencyIndex: number) => (
                    currencyIndex === 0 &&
                    <React.Fragment key={currencyIndex}>
                      <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={1}>{currency.count_cheques}</td>
                      <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={1}>{currency.currency_code}</td>
                      <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={1}>
                        {currency.sum_amount?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    </React.Fragment>
                  ))}

                  {row?.[itemArrayName]?.[balanceIndex]?.[itemArrayName2].map((currency: any, currencyIndex: number) => (
                    currencyIndex > 0 &&
                    <tr
                      className={hoveredRow === rowIndex ? 'hoverRow' : ''}
                      onMouseEnter={() => { setHoveredRow(rowIndex) }}
                      onMouseLeave={() => { setHoveredRow(null) }}
                      style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }}
                      key={currencyIndex}>
                      <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={1}>{currency.count_cheques}</td>
                      <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={1}>{currency.currency_code}</td>
                      <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={1}>
                        {currency.sum_amount?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    </tr>
                  ))}
                </React.Fragment > :
                  <>
                    <tr
                      className={hoveredRow === rowIndex ? 'hoverRow' : ''}
                      onMouseEnter={() => { setHoveredRow(rowIndex) }}
                      onMouseLeave={() => { setHoveredRow(null) }}
                      style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }} key={balanceIndex + "$"}>

                      <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={row?.[itemArrayName]?.[balanceIndex]?.[itemArrayName2].length}>{balance?.contra_account_name}</td>
                      <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={row?.[itemArrayName]?.[balanceIndex]?.[itemArrayName2].length}>{balance?.contra_account_number}</td>
                      {row?.[itemArrayName]?.[balanceIndex]?.[itemArrayName2].map((currency: any, currencyIndex: number) => (
                        currencyIndex === 0 &&
                        <React.Fragment key={currencyIndex}>
                          <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={1}>{currency.count_cheques}</td>
                          <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={1}>{currency.currency_code}</td>
                          <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={1}>
                            {currency.sum_amount?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </React.Fragment>
                      ))}
                    </tr>
                    {row?.[itemArrayName]?.[balanceIndex]?.[itemArrayName2].map((currency: any, currencyIndex: number) => (
                      currencyIndex > 0 &&
                      <tr
                        className={hoveredRow === rowIndex ? 'hoverRow' : ''}
                        onMouseEnter={() => { setHoveredRow(rowIndex) }}
                        onMouseLeave={() => { setHoveredRow(null) }}
                        style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }}
                        key={currencyIndex}>
                        <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={1}>{currency.count_cheques}</td>
                        <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={1}>{currency.currency_code}</td>
                        <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={1}>
                          {currency.sum_amount?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                      </tr>
                    ))}
                  </>
                }
              </>
            )
          );
          return (
            <React.Fragment key={rowIndex} >
              <tr
                className={hoveredRow === rowIndex ? 'hoverRow' : ''}
                onMouseEnter={() => { setHoveredRow(rowIndex) }}
                onMouseLeave={() => { setHoveredRow(null) }}
                style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }} key={rowIndex + "a"}>
                <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={funcspan(row)} >
                  {row?.days_collection_header}</td>
                <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={funcspan(row)}>
                  {row?.day_begin}</td>
                <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={funcspan(row)}>
                  {row?.day_end}</td>
                <td className={`td-span-table-${dir} padding-input-${dir} `} rowSpan={funcspan(row)}>
                  {row?.days_collection}</td>
                {
                  ItemRows?.[0]?.props.children
                  ??
                  <td colSpan={5} className={`td-span-table-${dir} padding-input-${dir} `}
                  >לא נמצאו נתונים להצגה</td>}
              </tr>
              {ItemRows?.slice(1)}

            </React.Fragment>
          );
        })}

      </tbody>
    </table >

  );
}; 
