import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Callout, Checkbox, getTheme, mergeStyleSets } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { CustomerDate } from '../../shared/components/CustomHooks/concatTextFields';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import { SAVE_DRAFT_CONVERSION_CHANGES } from '../../store/actions/types';
import { readCopycompletedDoc } from '../../store/actions/completeDocAction';
import { convertArrToDropdropdown, IUserCredentials } from '../customerDetails/CustomerDetails';
import { CustomTextField } from '../../shared/components/TextField';

import '../../components/actions/discountsAction/checkManagment/AGtable.css';
import DialogCompleteTransactions from '../../shared/components/dialog/dialogTransaction/DialogCompleteTransactions';
import moment from 'moment';
import { sortTableFields } from '../../shared/components/CustomHooks/GlobalFunctions';

const initialDataAllFilter = {
    document_number: ["equal", ""],
    entity_name: ["like", ""],
    ID_number: ["equal", ""],
    date_value: ["less_than", ""],
    transaction_type_id: ["like", ""],
    total_amount_ils: ["less_than", ""],


}
export default function UnRegularReportsTable(props: any) {
    const { data, selectTransactionsFunc, allChecked } = props
    const [t] = useTranslation();
    const [copyData, setCopyData] = useState(data?.results)
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    let componentRef = useRef(null);
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const enums = useSelector(({ dataReducer }: any) => dataReducer?.enums)
    const [showDialogTransaction, setShowDialogTransaction] = useState(false)
    const [messageError, setMessageError] = useState('')
    const [serverMessageError, setServerMessageError] = useState(false)
    const [dialogTitle, setDialogTitle] = useState('')
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [arrTitle, setArrTitle] = useState([
        { key: 'numTransaction', eng: 'document_number', sortValue: false, default: "equal", typeField: "string" },
        { key: "customerName", eng: 'entity_name', sortValue: false, default: "like", typeField: "string" },
        { key: "idNumber", eng: 'ID_number', sortValue: false, default: "equal", typeField: "string" },
        { key: "date", eng: 'date_value', sortValue: false, default: "less_than", typeField: "date" },
        { key: "transactionType", eng: 'transaction_type_id', sortValue: false, default: "like", typeField: "type" },
        { key: "totalILS", eng: 'total_amount_ils', sortValue: false, default: "less_than", typeField: "number" },
    ])

    const [enumsArr, setEnumsArr] = useState<any>()
    const [search_fields, setSearch_fields] = useState<any>([])
    const [filter, setFilter] = useState({ key: '', value: false })
    const operatorStringList: IDropdownOption[] = [{ key: "like", text: t("like") },
    { key: "begin_with", text: t("begin_with") }, { key: "equal", text: t("equal") }]
    const operatorDateList: IDropdownOption[] = [{ key: "less_than", text: t("from_date") },
    { key: "greater_than", text: t("until_date") }]
    const operatorAmountList: IDropdownOption[] = [{ key: "less_than", text: t("less_than") },
    { key: "equal", text: t("equal") }, { key: "greater_than", text: t("greater_than") }]
    const arrWidth: any = ['110px', '150px', '145px', '140px', '140px', '120px', '130px', '130px', '130px']
    const [isValidDateCreate, setIsValidDateCreate] = useState(true)
    const [dateFilter, setDateFilter] = useState({ date_value: "", date_value2: "" })
    const [dataAllfilter, setDataAllFilter] = useState<any>(initialDataAllFilter)

    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])

    useEffect(() => {
        if (data)
            setCopyData(data?.results)
    }, [data])

    useEffect(() => {
        if (enums) {
            let arr = {
                "transaction_type_id": convertArrToDropdropdown([])
            };
            for (let i = 0; i < enums?.length; i++) {
                if (enums[i].document !== undefined) {
                    let document = enums[i].document
                    for (let i = 0; i < document.length; i++) {
                        if (document[i].type_id !== undefined) {
                            arr.transaction_type_id = convertArrToDropdropdown(document[i].type_id)
                        }
                    }
                }
            }
            setEnumsArr(arr);
        }
    }, [enums]);

    useEffect(() => {
        if (dateFilter?.date_value) {
            enterFilter({
                key: t("date"), eng: 'date_value', sortValue: false,
                default: "greater_than", typeField: "date"
            }, dateFilter.date_value, "value")
        }
        // eslint-disable-next-line
    }, [dateFilter.date_value])

    useEffect(() => {
        if (dateFilter?.date_value2) {
            enterFilter({
                key: t("date"), eng: 'date_value', sortValue: false,
                default: "less_than", typeField: "date"
            }, dateFilter.date_value2, "value")
        }
        // eslint-disable-next-line
    }, [dateFilter.date_value2])
  

    const enterFilter = (field: any, value: any, key: any) => {

        let index
        let arr: any = cloneDeep(search_fields)
        let data = cloneDeep(dataAllfilter)
        let item: any, id: any
        item = {
            "field_name": field.eng,
            "search_type": "",
            "search_value": "",
            "type_field": field?.typeField
        }
        if (enumsArr[field.key]) {
            id = enumsArr[field.eng].find((x: any) => x.text === value)
        }
        if (field.eng.includes("date"))
            index = arr.findIndex((x: any) => x.field_name === field.eng && x.search_type === field.default)
        else
            index = arr.findIndex((x: any) => x.field_name === field.eng)

        if (index !== -1) {
            item = arr[index]
        }
        if (key === 'operator') {
            data[field.eng][0] = value
            item["search_type"] = value
        }
        else {
            item["search_value"] = id ? id.key : value
            data[field.eng][1] = value
        }
        if (index !== -1) {
            let arr1 = search_fields
            arr1[index] = item
            setSearch_fields(arr1)
        } else {
            if (item["search_type"] === "") {
                item["search_type"] = field.default
            }
            setSearch_fields([...search_fields, item])
        }
        setDataAllFilter(data)
    }

    const sortCol = (col: any, index: any) => {
        if (col?.sortValue)
            data?.sort((a: any, b: any) => (a[col?.eng] > b[col?.eng] ? -1 : 1))
        else
            data?.sort((a: any, b: any) => (a[col?.eng] > b[col?.eng] ? 1 : -1))
        let item = { key: col.key, eng: col.eng, sortValue: !col.sortValue, filterValue: col.fliterValue, default: col?.default }
        let arr: any = [...arrTitle]
        arr[index] = item
        setArrTitle(arr)
    }
    const filterCol = (key: any) => {
        setFilter({ key: key, value: !filter.value })
    }

    const displayTransaction = (index: any) => {
        if (data?.results?.[index]?.id_document) {
            dispatch(readCopycompletedDoc(data?.results?.[index]?.transaction_type_id, userCredentials,
                data?.results?.[index]?.id_entity, data?.results?.[index]?.id_document))
        }
        else {
            setMessageError(t("documentCannotDisplayed"))
            setServerMessageError(true)
        }
        setShowDialogTransaction(true)
        dispatch({
            type: SAVE_DRAFT_CONVERSION_CHANGES,
            payload: {}
        });
    }

    const sendFilterConditions = async () => {
        sortTableFields(search_fields, data?.results, setCopyData)
        setFilter({ key: '', value: false })
        setDataAllFilter(initialDataAllFilter)
    }

    return (
        <>
            {showDialogTransaction && < DialogCompleteTransactions setShowDialog={setShowDialogTransaction} />}
            {serverMessageError && <DialogMessages setansDelete={setServerMessageError} setshowModalDelete={setServerMessageError}
                subText={messageError} title={dialogTitle} dialogType={"dialogMessage"} />}
               {data?.rows_count ?<div style={{ display: "flex", marginTop: "24px" }}>
                <PrimaryButton onClick={() => { sendFilterConditions() }} text={t("filter")} />
                <PrimaryButton className={`margin-between-input-${dir}`}
                    onClick={() => {
                        setSearch_fields([])
                        setDataAllFilter(initialDataAllFilter)
                        setCopyData(data)
                    }}
                    text={t("cancelFilter")} />
            </div>:""}

            <div style={{ maxWidth: '120%' }} ref={componentRef} >
                {data?.rows_count>0 ? <p style={{ marginBottom: '0' }}>{t('found')} {copyData?.length} {t('results')}</p>
                    : <p style={{ marginBottom: '0' }}>{t('searchCustomerNotFound')}</p>
                }

                {data?.rows_count ?
                    <table id="table" style={{ border: '2px solid #e1e1e1', overflow: "auto", marginBottom: "100px" }}>
                        <tbody>
                            <tr>
                                <th className={`th-complete-table-${dir}`}>
                                    <th className={`th-agtable-${dir}`} style={{ minWidth: '45px' }}>
                                        <div className={`padding-input-${dir}`} >
                                            <Checkbox checked={allChecked} onChange={() => {
                                                selectTransactionsFunc("all")
                                            }} /></div>
                                    </th>
                                </th>
                                {arrTitle?.map((item: any, key: any) => (
                                    <th className="th-complete-table"
                                        key={key} style={{ width: arrWidth[key] }} >
                                        <div className='wrapper' >
                                            <p style={{ margin: "0" }}>{t(item.key)}</p>
                                            <div className={`cr-dr-span-${dir}`}>
                                                <i onClick={() => { sortCol(item, key) }} title={t("filter")}
                                                    className={`icon-sort-t-${dir} ${item.sortValue ? "fal fa-arrow-up" : "fal fa-arrow-down"} `}></i>
                                                {/* className={`icon-sort-t-${dir} fal fa-filter`} */}
                                                <i  className={`icon-sort-t-${dir} ${dataAllfilter[item.eng][1] ? "fas" : "fal"} fa-filter`}   id={item.eng} title={t("filter")}
                                                    onClick={() => {  filterCol(item.key) }}></i>
                                            </div>
                                        </div>
                                        {
                                            filter.key === item.key && filter.value === true && <>
                                                <Callout
                                                    coverTarget
                                                    className={item.eng === 'date_value' ? styles.calloutDate : styles.callout}
                                                    onDismiss={() => { filterCol(item.key) }}
                                                    target={`#${item.eng}`}
                                                    isBeakVisible={false}
                                                    setInitialFocus >
                                                    <FontAwesomeIcon icon={faTimes} title={t("close")} onClick={() => { filterCol(item.key) }}
                                                        style={{ height: '12px', marginTop: '2%', marginBottom: '2%' }} className={`iconRefresh-${dir}`} />
                                                    <div key={key + 1} >
                                                        {item.eng === 'date_value' ?
                                                            <>
                                                                <label>{t("from_date")}</label>
                                                                <div className='doc'><CustomerDate isDefaultDate={false} isValidDate={isValidDateCreate}
                                                                    setIsValidDate={setIsValidDateCreate} entity={dateFilter} setEntity={setDateFilter}
                                                                    readOnly={false} idDate="date_value" label={""} source={""} />
                                                                    <div className='table-spaces'></div>
                                                                    <label>{t("until_date")}</label>
                                                                </div>
                                                                <div className='doc'> <CustomerDate isDefaultDate={false} isValidDate={isValidDateCreate}
                                                                    setIsValidDate={setIsValidDateCreate} entity={dateFilter} setEntity={setDateFilter}
                                                                    readOnly={false} idDate="date_value2" label={""} source={""} />
                                                                </div></>
                                                            : item.default !== "equal" && item.eng !== "transaction_type_id" &&
                                                            <Dropdown
                                                                placeholder={t("selectCondition")}
                                                                selectedKey={dataAllfilter[item.eng][0]}
                                                                id={item}
                                                                style={{ marginBottom: '2%' }}
                                                                onChange={(id: any, val: any, e: any) => (enterFilter(item, val.key, "operator"))}
                                                                options={item.eng === 'entity_name' ? operatorStringList : item.eng.includes('date') ? operatorDateList : item.eng.includes('amount') && operatorAmountList}
                                                            />
                                                        }
                                                        {item.eng === "transaction_type_id" ?
                                                            <Dropdown
                                                                placeholder={t("selectDocumentType")}
                                                                id={'transaction_type_id'}
                                                                className={`select-document-${dir}`}
                                                                options={enumsArr?.transaction_type_id ?? []}
                                                                selectedKey={dataAllfilter[item.eng][1]}
                                                                onChange={(id: any, val: any, e: any) => {enterFilter(item, val.key, "value") }}
                                                            />
                                                            :
                                                            item.eng !== 'date_value' &&
                                                            <div className='table-spaces'><CustomTextField label={""}
                                                                placeholder={`${t("enter")} ${t(item?.key)}`}
                                                                value={search_fields ? dataAllfilter[item.eng][1] : `${t("enter")} ${t(item?.key)}`}
                                                                readOnly={false} onChange={(id: any, val: any) => (enterFilter(id, val, "value"))} id={item} />
                                                            </div>}
                                                    </div>
                                                </Callout>
                                            </>
                                        }
                                    </th>
                                ))}
                            </tr>


                            {copyData?.length > 0 && copyData?.map((item: any, index: any) => (
                                <tr key={index} className="hoverRow" style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }}>
                                    <td className={`td-complete-table-${dir} ${`padding-input-${dir}`}`}>
                                        <Checkbox checked={item?.is_reporting === (true || "true") ? true : false}
                                            onChange={(key, value) => {
                                                selectTransactionsFunc(item, index, value)
                                            }} />
                                    </td>
                                    <td className={`td-complete-table-${dir}`} onClick={() => { displayTransaction(index) }}>
                                        <span className='link-document'>{item?.document_number}</span></td>
                                    <td className={`td-complete-table-${dir}`}>{item?.entity_name}</td>
                                    <td className={`td-complete-table-${dir}`}>{item?.ID_number}</td>
                                    <td className={`td-complete-table-${dir}`}>{moment(item?.date_value).format("DD/MM/YYYY")}</td>
                                    <td className={`td-complete-table-${dir}`}>
                                        {enumsArr?.transaction_type_id?.find((typeid: any) => typeid?.key.includes(item?.transaction_type_id))?.text}
                                    </td>
                                    <td className={`td-complete-table-${dir}`}>{item?.total_amount_ils && Number(item?.total_amount_ils)?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>:""}
            </div >
        </>
    )
}

const theme = getTheme();
const styles = mergeStyleSets({
    callout: {
        width: 200,
        padding: '9px',
        background: theme.semanticColors.bodyBackground,
    }, calloutDate: {
        width: 265,
        padding: '9px',
        bottom: "-440.396px !important",
        background: theme.semanticColors.bodyBackground,
    },
});

