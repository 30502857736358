import {
    Dialog, ContextualMenu, DialogFooter, Dropdown
} from '@fluentui/react';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useBoolean } from '@fluentui/react-hooks';
import './DialogUnRegularReports.css';
import { DefaultButton, DialogContent, IDropdownOption, PrimaryButton } from 'office-ui-fabric-react';
import { TextFeildNote } from '../../shared/components/Note';
import { CustomDropdown } from '../../shared/components/Option';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { unRegularReports } from '../../store/actions/reportsAction';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';



const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
};
const modelProps = {
    isBlocking: true,
    topOffsetFixed: true,
    dragOptions: dragOptions
};

export default function DialogProduceUnRegularReports(props: any) {
    const { enumsDropdown, unRegularReportsData, setUnRegularReportsData, setShowDialog,setDataReport } = props

    const userCredentials = useSelector(({ authReducer }: any) => authReducer?.userCredentials)

    const [t] = useTranslation()
    const dispatch = useDispatch()
    const [requiredField, setRequiredField] = useState(false)
    const [showDialogRequiredFields, setShowDialogRequiredFields] = useState(false)

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const yearsList: IDropdownOption[] = [
        { key: String(moment().year() - 3), text: String(moment().year() - 3) },
        { key: String(moment().year() - 2), text: String(moment().year() - 2) },
        { key: String(moment().year() - 1), text: String(moment().year() - 1) },
        { key: String(moment().year()), text: String(moment().year()) },
        { key: String(moment().year() + 1), text: String(moment().year() + 1) },
        { key: String(moment().year() + 2), text: String(moment().year() + 2) },
        { key: String(moment().year() + 3), text: String(moment().year() + 3) },
        { key: String(moment().year() + 4), text: String(moment().year() + 4) },
        { key: String(moment().year() + 5), text: String(moment().year() + 5) },
        { key: String(moment().year() + 6), text: String(moment().year() + 6) },
        { key: String(moment().year() + 7), text: String(moment().year() + 7) },
    ]

    const monthesList: IDropdownOption[] = [
        { key: "01", text: t('january') }, { key: "02", text: t('february') },
        { key: "03", text: t('march') }, { key: "04", text: t('april') },
        { key: "05", text: t('may') }, { key: "06", text: t('june') },
        { key: "07", text: t('july') }, { key: "08", text: t('august') },
        { key: "09", text: t('september') }, { key: "10", text: t('october') },
        { key: "11", text: t('november') }, { key: "12", text: t('december') }]

    useEffect(() => {
        if (setShowDialog) {
            toggleHideDialog()
        }
    }, [setShowDialog, toggleHideDialog])


    const changeCharacteristics = async (id: any, e: any) => {
        setUnRegularReportsData((prevState: any) => {
            const copyUnRegularReportsData = [...unRegularReportsData?.impa_utr_characteristics];
            let isExsistIndex = copyUnRegularReportsData?.indexOf(e.key)
            if (isExsistIndex !== -1)
                copyUnRegularReportsData.splice(isExsistIndex)
            else
                copyUnRegularReportsData.push(e?.key)
            return { ...prevState, ["impa_utr_characteristics"]: copyUnRegularReportsData };
        });

    }

    const changeUnRegularReportsData = async (id: any, e: string) => {
        setUnRegularReportsData((prevState: any) => ({
            ...prevState,
            [id]: e,
        }));
    }
    const check = (currentValue: string) => unRegularReportsData[currentValue]?.length > 0;

    const createUnRegularReports = () => {
        let arrRequiredFields: any = ["month", "year", "impa_utr_characteristics",
            "impa_utr_essence"];
        if (arrRequiredFields.every(check)) {
            dispatch(unRegularReports(unRegularReportsData, userCredentials,setDataReport))
            toggleHideDialog()
            setShowDialog(false)
        }
        else {
            setRequiredField(true)
            setShowDialogRequiredFields(true)
        }
    }

    return (
        <>

            <Dialog minWidth="sm" maxWidth="sm" modalProps={modelProps} hidden={hideDialog} onDismiss={() => { toggleHideDialog(); setShowDialog(false) }} >

                {showDialogRequiredFields && <DialogMessages setansDelete={setShowDialogRequiredFields}
                    setshowModalDelete={setShowDialogRequiredFields} dialogType={"requiredFields"} />}

                <DialogContent>

                    <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>יצירת דיווח לא רגיל</p>
                    <h6>בחרת ליצור דיווח לא רגיל לרשות לאיסור הלבנת הון הכולל את הלקוחות הבאים:</h6>
                    <hr className="hr"></hr>
                    <table>
                        <tbody>
                            <tr>
                                <th style={{ border: '1px solid black', backgroundColor: "#F9F9F9", width: '6%', textAlign: 'center' }}>
                                    {t("customerName")}</th>
                                <th style={{ border: '1px solid black', backgroundColor: "#F9F9F9", width: '6%', textAlign: 'center' }}>
                                    {t("idNumber")}</th>
                            </tr>
                            {unRegularReportsData?.arrEntitiesData?.map((item: any, index: any) => (
                                <tr key={index}>
                                    <td style={{ border: '1px solid black', textAlign: 'center' }}>{item?.entity_name}</td>
                                    <td style={{ border: '1px solid black', textAlign: 'center' }}>{item?.ID_number}</td>
                                </tr>))}
                        </tbody>
                    </table>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <div style={{ width: "48%" }}>
                            <CustomDropdown otherInputId={''} readOnly={false} hasOtherValue={false} options={yearsList} label={t('בחר שנה')}
                                onChange={(id: any, key: any, val: any) => { changeUnRegularReportsData(id, key) }}
                                requiredField={requiredField && !unRegularReportsData?.year ? true : false}
                                selectedKey={unRegularReportsData?.year} id={'year'} othertextInput={t('')} />
                        </div>
                        <div style={{ width: "48%" }}>
                            <CustomDropdown otherInputId={''} readOnly={false} hasOtherValue={false}
                                options={monthesList} label={t('בחר חודש')}
                                requiredField={requiredField && !unRegularReportsData?.month ? true : false}
                                onChange={(id: any, key: any, val: any) => { changeUnRegularReportsData(id, key) }}
                                selectedKey={unRegularReportsData?.month} id={'month'} othertextInput={t('')} />
                        </div>
                    </div>
                    <div style={{ width: "600px", maxWidth: "600px" }}>
                        <Dropdown
                            placeholder={t("בחר תסמין")}
                            selectedKeys={unRegularReportsData?.impa_utr_characteristics}
                            multiSelect
                            options={enumsDropdown?.impaUtrCharacteristics}
                            required
                            style={{
                                border: requiredField && !unRegularReportsData?.impa_utr_characteristics
                                    ? "solid red 2px" : ""
                            }}
                            label={t("בחר תסמין")}
                            onChange={changeCharacteristics}
                            id={'impa_utr_characteristics'}
                        />
                    </div>
                    <TextFeildNote
                        style={{
                            border: requiredField && !unRegularReportsData?.impa_utr_essence
                                ? "solid red 2px" : ""
                        }}
                        label={t('תיאור הפעולה הבלתי רגילה')} onChange={changeUnRegularReportsData}
                        id={'impa_utr_essence'} value={unRegularReportsData?.impa_utr_essence} />

                </DialogContent>
                <DialogFooter>
                    <PrimaryButton onClick={createUnRegularReports} text={t("produceReport")} />
                    <DefaultButton onClick={toggleHideDialog} text={t("cancel")} />
                </DialogFooter>
            </Dialog >
        </>
    )
}
