import {SET_DATA_RESULTS_UN_REGULAR_REPORTS
    ,SET_DATA_OF_FIELDS_UN_REGULAR_REPORTS, 
    RESET_DATA_RESULTS_UN_REGULAR_REPORTS} from '../actions/types';

const initialState = {
    dataRsultsUnRegularReports:{},
    dataOfFieldsUnRegularReports:[]
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SET_DATA_RESULTS_UN_REGULAR_REPORTS: {
            return {
                ...state,
                dataRsultsUnRegularReports:action.payload,
            }
        }
        case SET_DATA_OF_FIELDS_UN_REGULAR_REPORTS: {
            return {
                ...state,
                dataOfFieldsUnRegularReports:action.payload,
            }
        }
        case RESET_DATA_RESULTS_UN_REGULAR_REPORTS: {
            return {
                ...state,
                dataRsultsUnRegularReports:action.payload,
                // dataOfFieldsUnRegularReports:action.payload
            }
        }
    
        default: return state;
    }
}