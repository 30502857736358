import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Callout, getTheme, mergeStyleSets } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { CustomerDate } from '../../shared/components/CustomHooks/concatTextFields';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import { SAVE_DRAFT_CONVERSION_CHANGES } from '../../store/actions/types';
import { readCompletedDoc, readCopycompletedDoc } from '../../store/actions/completeDocAction';
import { convertArrToDropdropdown, IUserCredentials } from '../customerDetails/CustomerDetails';
import { CustomTextField } from '../../shared/components/TextField';

import '../../components/actions/discountsAction/checkManagment/AGtable.css';
import DialogCompleteTransactions from '../../shared/components/dialog/dialogTransaction/DialogCompleteTransactions';
import moment from 'moment';

const initialDataAllFilter = {
    document_type_id: ["equal", ""],
    document_number: ["equal", ""],
    entity_name: ["like", ""],
    ID_number: ["equal", ""],
    total_amount_ils: ["less_than", ""],
    document_date: ["less_than", ""],
}

export default function ComleteEntityT(props: any) {
    const { path, type, data, setArrayResult, arrayResult, displayLoading,
        search_fields, setSearch_fields } = props
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    let componentRef = useRef(null);
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const enums = useSelector(({ dataReducer }: any) => dataReducer?.enums)
    const [showDialogTransaction, setShowDialogTransaction] = useState(false)
    const [messageError, setMessageError] = useState('')
    const [serverMessageError, setServerMessageError] = useState(false)
    const [dialogTitle, setDialogTitle] = useState('')
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [arrTitle, setArrTitle] = useState([
        { key: 'documentNumber', eng: 'document_number', sortValue: false, default: "equal" },
        { key: "customerName", eng: 'entity_name', sortValue: false, default: "like" },
        { key: "idNumber", eng: 'ID_number', sortValue: false, default: "equal" },
        { key: "totalILS", eng: 'total_amount_ils', sortValue: false, default: "less_than" },
        { key: "date", eng: 'document_date', sortValue: false, default: "less_than" },
    ])
    const [enumsArr, setEnumsArr] = useState<any>()
    const [filter, setFilter] = useState({ key: '', value: false })
    const operatorStringList: IDropdownOption[] = [{ key: "like", text: t("like") },
    { key: "begin_with", text: t("begin_with") }, { key: "equal", text: t("equal") }]
    const operatorDateList: IDropdownOption[] = [{ key: "less_than", text: t("from_date") },
    { key: "greater_than", text: t("until_date") }]
    const operatorAmountList: IDropdownOption[] = [{ key: "less_than", text: t("less_than") },
    { key: "equal", text: t("equal") }, { key: "greater_than", text: t("greater_than") }]
    const arrWidth: any = type === "documents" ? ['170px', '130px', '150px', '140px', '140px', '120px', '130px', '130px', '130px'] :
        ["130px", '130px', '150px', '140px', '140px', '120px', '130px', '130px', '130px']
    const [isValidDateCreate, setIsValidDateCreate] = useState(true)
    const [dateFilter, setDateFilter] = useState({ document_date: "", document_date2: "" })
    const [dataAllfilter, setDataAllFilter] = useState<any>(initialDataAllFilter)

    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])

    useEffect(() => {
        if (enums) {
            let arr = {
                "document_type_id": convertArrToDropdropdown([])
            };
            for (let i = 0; i < enums?.length; i++) {
                if (enums[i].document !== undefined) {
                    let document = enums[i].document
                    for (let i = 0; i < document.length; i++) {
                        if (document[i].type_id !== undefined) {
                            arr.document_type_id = convertArrToDropdropdown(document[i].type_id)
                        }
                    }
                }
            }
            setEnumsArr(arr);
        }
    }, [enums]);

    useEffect(() => {
        if (dateFilter?.document_date) {
            enterFilter({
                key: t("date"), eng: 'document_date', sortValue: false,
                default: "greater_than"
            }, dateFilter.document_date, "value")
        }
        // eslint-disable-next-line
    }, [dateFilter.document_date])

    useEffect(() => {
        if (dateFilter?.document_date2) {
            enterFilter({
                key: t("date"), eng: 'document_date', sortValue: false,
                default: "less_than"
            }, dateFilter.document_date2, "value")
        }
        // eslint-disable-next-line
    }, [dateFilter.document_date2])


    useEffect(() => {
        if (type === "documents") {
            let copyArrTitle = [...arrTitle]
            copyArrTitle.unshift({
                key: t("documentType"), eng: 'document_type_id',
                sortValue: false, default: "equal"
            })
            setArrTitle(copyArrTitle)
        }
    }, [type])

    const enterFilter = (field: any, value: any, key: any) => {
        let index
        let arr = cloneDeep(search_fields)
        let data = cloneDeep(dataAllfilter)
        let item: any, id: any
        item = {
            "field_name": field.eng,
            "search_type": "",
            "search_value": ""
        }
        if (enumsArr[field.key]) {
            id = enumsArr[field.eng].find((x: any) => x.text === value)
        }
        if (field.eng.includes("date"))
            index = arr.findIndex((x: any) => x.field_name === field.eng && x.search_type === field.default)
        else
            index = arr.findIndex((x: any) => x.field_name === field.eng)

        if (index !== -1) {
            item = arr[index]
        }
        if (key === 'operator') {
            data[field.eng][0] = value
            item["search_type"] = value
        }
        else {
            item["search_value"] = id ? id.key : value
            data[field.eng][1] = value
        }
        if (index !== -1) {
            let arr1 = search_fields
            arr1[index] = item
            setSearch_fields(arr1)
        } else {
            if (item["search_type"] === "") {
                item["search_type"] = field.default
            }
            setSearch_fields([...search_fields, item])
        }
        setDataAllFilter(data)
    }

    const sortCol = (col: any, index: any) => {
        if (col?.sortValue)
            data?.sort((a: any, b: any) => (a[col?.eng] > b[col?.eng] ? -1 : 1))
        else
            data?.sort((a: any, b: any) => (a[col?.eng] > b[col?.eng] ? 1 : -1))
        let item = { key: col.key, eng: col.eng, sortValue: !col.sortValue, filterValue: col.fliterValue, default: col?.default }
        let arr: any = [...arrTitle]
        arr[index] = item
        setArrTitle(arr)
    }
    const filterCol = (key: any) => {
        setFilter({ key: key, value: !filter.value })
    }

    const displayTransaction = (index: any) => {
        if (data[index]?.id_document) {
            dispatch(readCopycompletedDoc(data[index]?.document_type_id, userCredentials,
                data[index]?.id_entity, data[index]?.id_document))
        }
        else {
            setMessageError(t("documentCannotDisplayed"))
            setServerMessageError(true)
        }
        setShowDialogTransaction(true)
        dispatch({
            type: SAVE_DRAFT_CONVERSION_CHANGES,
            payload: {}
        });
    }

    const sendFilterConditions = async () => {
        let arrToSend: any[] = []
        for (let index = 0; index < search_fields.length; index++) {
            if (!search_fields[index].search_type || !search_fields[index].search_value) {
                setSearch_fields((item: any) => item.filter((x: any) => x.field_name !== search_fields[index].field_name));
            }
            else {
                arrToSend.push(search_fields[index])
            }
        }
        setFilter({ key: '', value: false })
        setDataAllFilter(initialDataAllFilter)
        type !== "documents" && arrToSend.push({
            field_name: "document_type_id",
            search_type: "equal",
            search_value: type
        })
        displayLoading && displayLoading()
        await dispatch(readCompletedDoc(type, userCredentials, setSearch_fields, setArrayResult,
            1, arrToSend, 10, setServerMessageError, setMessageError, setDialogTitle))
    }

    return (
        <>
            {showDialogTransaction && < DialogCompleteTransactions setShowDialog={setShowDialogTransaction} />}
            {serverMessageError && <DialogMessages setansDelete={setServerMessageError} setshowModalDelete={setServerMessageError}
                subText={messageError} title={dialogTitle} dialogType={"dialogMessage"} />}
            <div style={{ display: "flex", marginTop: "24px" }}>
                <PrimaryButton onClick={() => { sendFilterConditions() }} text={t("filter")} />
                <PrimaryButton className={`margin-between-input-${dir}`} onClick={() => {
                    setSearch_fields([])
                    setDataAllFilter(initialDataAllFilter)
                    displayLoading()
                    dispatch(readCompletedDoc(type, userCredentials, setSearch_fields, setArrayResult, 1, type === "documents" ? [] : [{
                        field_name: "document_type_id",
                        search_type: "equal",
                        search_value: type
                    }], 10, "search"))

                }} text={t("cancelFilter")} />
            </div>

            <div style={{ maxWidth: '120%' }} ref={componentRef} >
                {data?.length > 0 ? <p style={{ marginBottom: '0' }}>{t('found')} {arrayResult?.records_found} {t('results')}</p>
                    : (arrayResult?.records_found && data?.length > 0) && <p style={{ marginBottom: '0' }}>{t('found')} {arrayResult?.records_found} {t('results')}</p>
                }
                {arrayResult?.search_results.length > 0 && <table id="table" style={{ border: '2px solid #e1e1e1', overflow: "auto" }}>
                    <tbody>
                        <tr>
                            {arrTitle?.map((item: any, key: any) => (
                                <th className={`${key === 0 ? `padding-input-${dir} th-complete-table` : "th-complete-table"}`}
                                    key={key} style={{ width: arrWidth[key] }} >
                                    <div className='wrapper' >
                                        <p style={{ margin: "0" }}>{t(item.key)}</p>
                                        <div className={`cr-dr-span-${dir}`}>
                                            <i onClick={() => { sortCol(item, key) }} title={t("filter")}
                                                className={`icon-sort-t-${dir} ${item.sortValue ? "fal fa-arrow-up" : "fal fa-arrow-down"} `}></i>
                                            {/* className={`icon-sort-t-${dir} fal fa-filter`}  */}
                                            <i className={`icon-sort-t-${dir} ${dataAllfilter[item.eng][1] ? "fas" : "fal"} fa-filter`} id={item.eng} title={t("filter")}
                                                onClick={() => { filterCol(item.key) }}></i>
                                        </div>
                                    </div>
                                    {
                                        filter.key === item.key && filter.value === true && <>
                                            <Callout
                                                coverTarget
                                                className={item.eng === 'document_date' ? styles.calloutDate : styles.callout}
                                                onDismiss={() => { filterCol(item.key) }}
                                                target={`#${item.eng}`}
                                                isBeakVisible={false}
                                                setInitialFocus >
                                                <FontAwesomeIcon icon={faTimes} title={t("close")} onClick={() => { filterCol(item.key) }}
                                                    style={{ height: '12px', marginTop: '2%', marginBottom: '2%' }} className={`iconRefresh-${dir}`} />
                                                <div key={key + 1} >
                                                    {item.eng === 'document_date' ?
                                                        <>
                                                            <label>{t("from_date")}</label>
                                                            <div className='doc'><CustomerDate isDefaultDate={false} isValidDate={isValidDateCreate} setIsValidDate={setIsValidDateCreate} entity={dateFilter} setEntity={setDateFilter} readOnly={false} idDate="document_date" label={""} source={""} />
                                                                <div className='table-spaces'></div>
                                                                <label>{t("until_date")}</label>
                                                            </div>
                                                            <div className='doc'> <CustomerDate isDefaultDate={false} isValidDate={isValidDateCreate} setIsValidDate={setIsValidDateCreate} entity={dateFilter} setEntity={setDateFilter} readOnly={false} idDate="document_date2" label={""} source={""} />
                                                            </div></>
                                                        : item.default !== "equal" &&
                                                        <Dropdown
                                                            placeholder={t("selectCondition")}
                                                            selectedKey={dataAllfilter[item.eng][0]}
                                                            id={item}
                                                            style={{ marginBottom: '2%' }}
                                                            onChange={(id: any, val: any, e: any) => (enterFilter(item, val.key, "operator"))}
                                                            options={item.eng === 'entity_name' ? operatorStringList : item.eng.includes('date') ? operatorDateList : item.eng.includes('amount') && operatorAmountList}
                                                        />
                                                    }
                                                    {item.eng === "document_type_id" ?
                                                        <Dropdown
                                                            placeholder={t("selectDocumentType")}
                                                            id={'document_type_id'}
                                                            className={`select-document-${dir}`}
                                                            options={enumsArr?.document_type_id}
                                                            selectedKey={dataAllfilter[item.eng][1]}
                                                            onChange={(id: any, val: any, e: any) => { enterFilter(item, val.key, "value") }}
                                                        />
                                                        : item.eng !== 'document_date' &&
                                                        <div className='table-spaces'><CustomTextField label={""}
                                                            placeholder={`${t("enter")} ${t(item?.key)}`}
                                                            value={search_fields ? dataAllfilter[item.eng][1] : `${t("enter")} ${t(item?.key)}`}
                                                            readOnly={false} onChange={(id: any, val: any) => (enterFilter(id, val, "value"))} id={item} />
                                                        </div>}
                                                </div>
                                            </Callout>
                                        </>
                                    }
                                </th>
                            ))}
                            <th className={`th-complete-table-${dir}`}>

                                <div>{t("actions")}</div>
                            </th>
                        </tr>
                        {data.length > 0 && data?.map((item: any, key: any) => (
                            <tr key={key} className="hoverRow" style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }}>
                                {type === "documents" && <td className={`td-complete-table-${dir} padding-input-${dir}`}>{item?.document_type_name}</td>}
                                <td className={`td-complete-table-${dir}  ${type !== "documents" && `padding-input-${dir}`}`}
                                    onClick={() => { displayTransaction(key) }}> <span className='link-document'>{item?.document_number}</span></td>
                                <td className={`td-complete-table-${dir}`}>{item?.entity_name}</td>
                                <td className={`td-complete-table-${dir}`}>{item?.ID_number}</td>
                                <td className={`td-complete-table-${dir}`}>{item?.total_amount_ils && Number(item?.total_amount_ils)?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                <td className={`td-complete-table-${dir}`}>{moment(item?.document_date).format("DD/MM/YYYY")}</td>
                                <td className={`td-complete-table-${dir}`} style={{ textAlign: "center" }} >
                                    <i className={`fal fa-times  iconEdit-${dir}`} onClick={() => { alert("ביטול עיסקה") }} title={t("cancel")}></i>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>}
            </div >
        </>
    )
}

const theme = getTheme();
const styles = mergeStyleSets({
    callout: {
        width: 200,
        padding: '9px',
        background: theme.semanticColors.bodyBackground,
    }, calloutDate: {
        width: 265,
        padding: '9px',
        bottom: "-440.396px !important",
        background: theme.semanticColors.bodyBackground,
    },
});

