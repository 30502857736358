import axios from 'axios';
import { basicUrl } from '../../shared/config';
import { FETCH_PROTECTED_DATA_REQUEST, RECEIVE_PROTECTED_DATA, SET_ALL_ENUMS } from './types'
import { IConfigHeaders } from '../../modelsType/type/interface';

// const blankOptionsMap = {
//   customersCondition: null,
//   customersType: null,
//   genders: null,
//   typeIdentityNumbers: null,
//   customersStatus: null
// }

// const objectData = [{ "enumType": "class", "category": "customer", "name": "customersCondition" },
// { "enumType": "entity_sub_type", "category": "customer", "name": "customersType" },
// { "enumType": "gender", "category": "customer", "name": "genders" },
// { "enumType": "type", "category": "customer", "name": "typeIdentityNumbers" },
// { "enumType": "status", "category": "customer", "name": "customersStatus" },
// ]

export function receiveProtectedData(data: any) {
  return {
    type: RECEIVE_PROTECTED_DATA,
    payload: {
      data: data
    }
  }
}

export function fetchProtectedDataRequest() {
  return {
    type: FETCH_PROTECTED_DATA_REQUEST
  }
}

const config: IConfigHeaders = {
  headers: {
    'Content-type': 'application/json'
  }
};

export const loadEnums = (id_initiator: string, id_client: string) => (dispatch: Function) => {
  let data = {
    "id_initiator": id_initiator,
    "id_client": id_client,
    "field_name": [
      // "telephone_country_code",
      // "country_code",
      "address_city_code"
    ],
    "enum_request_method": "read",
    "user_language": "HE"
  }
  axios.post(basicUrl + "/enum", data, config)
    .then(function (response: any) {
      dispatch({
        type: SET_ALL_ENUMS,
        payload: response.data
      })
    })
    .catch(function (error: any) {
      console.log(error.message);
    });

}


export const loadStreetEnums = (id_initiator: string, id_client: string, id_branch: string, idCity: string, setListStreets: any) => (dispatch: Function) => {
  // setAddressStreetCode: any
  let data = {
    "id_initiator": id_initiator,
    "id_client": id_client,
    "id_branch": id_branch,
    "field_name": [
      "address_street_code"
    ],
    "address_city_code": idCity,
    "enum_request_method": "read",
    "user_language": "HE"
  }
  axios.post(basicUrl + "/enum", data, config)
    .then(function (response: any) {
      let arrStreet: any = []
      response?.data?.data?.forEach((item: any) => {
        if (item?.address_street_code !== undefined)
          arrStreet = item

      })
      setListStreets(arrStreet.address_street_code)
    })
    .catch(function (error: any) {
      console.log(error.message);
    });

}
export const loadZipCode = (location: string, street: string, numHouse: string, setZipCode: any) => (dispatch: Function) => {
  let data = {
    "location": "רחובות",
    "POB": "",
    "street": "בר אילן",
    "house": "26",
    "entrance": ""
  }
  axios.post(basicUrl + "/postalcode", data, config)
    .then(function (response: any) {
      setZipCode(response)
    })
    .catch(function (error: any) {
      console.log(error.message);
    });

}


export const loadOptions = () => async (dispatch: Function, getState: Function) => {
  // const optionsPrm = objectData.map(async currObj => {
  //   const entity =
  //   {
  //     "id_client ": 45654784,

  //     "enum_type": currObj.enumType,

  //     "id_initiator": 478541,

  //     "enum_request_method": "read",

  //     "category": currObj.category,

  //     "user_language": "HE"
  //   }
  //   const res = await axios.post(basicUrl + "/enum", entity, config)
  //     .then(checkHttpStatus)
  //     //if failed:
  //     .catch(err => {
  //       err.response ? returnErrors(err.response.data, err.response.status, 'GET_ERRORS') : returnErrors('the server is down pls try later', 'LOGIN_FAIL')
  //       dispatch({
  //         type: LOGIN_FAIL
  //       })
  //       dispatch({ type: SET_IS_FETCHING, isFetching: true });
  //       // return "";
  //     })
  //   return res.data;

  // });
  // const options = await Promise.all(optionsPrm)
  // const optionsMap = { ...blankOptionsMap }
  // objectData.forEach(({ name }, idx) => {
  //   //@ts-ignore
  //   optionsMap[name] = options[idx]
  // })

  // dispatch({ type: 'SET_GENERAL_FORM_OPTIONS_MAP', optionsMap })

}


