import axios from "axios";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { SET_PRICE_PROGRAMS_TEMPLATES } from '../actions/types';
import { basicUrl } from "../../shared/config";

let currUserCredentials: IUserCredentials | null = null

export const createPricePlanTemplate = (pricePlane: any, idInitiator: any, idClient: any, idBranch: any, setShowDialogSave: any, setPricePlan?: any) => async (dispatch: Function) => {
    const createPricePlan = {
        "id_initiator": idInitiator,
        "id_client": idClient,
        "id_branch": idBranch,
        "return_price_program": 1,
        "request_method": "create",
        "price_program_name": pricePlane?.planName ? pricePlane?.planName : "",
        "note": pricePlane?.note,
        "is_default": pricePlane?.default ? pricePlane?.default : false,
        "status_id": pricePlane?.status ? pricePlane?.status : 1,
        "price_program_properties": [
            {
                "type": "fee",
                "name": "new_account_fee",
                "description": "פתיחת חשבון",
                "calculation_type": "const",
                "value": pricePlane ? Number(pricePlane?.properties?.new_account_fee?.value) : 0,
                "order": 1
            },
            {
                "type": "fee",
                "name": "israeli_cheque_deposit_fee",
                "description": "עמלת הפקדה שיק ישראלי",
                "calculation_type": "const",
                "value": pricePlane ? Number(pricePlane?.properties?.israeli_cheque_deposit_fee?.value) : 0,
                "order": 2
            },
            {
                "type": "fee",
                "name": "foreign_cheque_deposit_fee",
                "description": "עמלת הפקדת שיק מטח",
                "calculation_type": "const",
                "value": pricePlane ? Number(pricePlane?.properties?.foreign_cheque_deposit_fee?.value) : 0,
                "order": 3
            },
            {
                "type": "fee",
                "name": "cheque_refund_fee",
                "description": "עמלת החזר שיק",
                "calculation_type": "const",
                "value": pricePlane ? Number(pricePlane?.properties?.cheque_refund_fee?.value) : 0,
                "order": 4
            },
            {
                "type": "fee",
                "name": "repeat_deposit_fee",
                "description": "עמלת הפקדה חוזרת",
                "calculation_type": "const",
                "value": pricePlane ? Number(pricePlane?.properties?.repeat_deposit_fee?.value) : 0,
                "order": 5
            },
            {
                "type": "fee",
                "name": "wire_rtgs_fee",
                "description": "עמלת העברת זהב",
                "calculation_type": "const",
                "value": pricePlane ? Number(pricePlane?.properties?.wire_rtgs_fee?.value) : 0,
                "order": 6
            },
            {
                "type": "fee",
                "name": "wire_fee",
                "description": "עמלת העברה רגילה",
                "calculation_type": "const",
                "value": pricePlane ? Number(pricePlane?.properties?.wire_fee?.value) : 0,
                "order": 7
            },
            {
                "type": "fee",
                "name": "abroad_wire_fee",
                "description": "עמלת העברה לחו\"ל",
                "calculation_type": "const",
                "value": pricePlane ? Number(pricePlane?.properties?.abroad_wire_fee?.value) : 0,
                "order": 8
            },
            {
                "type": "fee",
                "name": "cheque_collection_fee",
                "description": "דמי גביה לכל שיק בניכיוןB",
                "calculation_type": "const",
                "value": pricePlane ? Number(pricePlane?.properties?.cheque_collection_fee?.value) : 0,
                "order": 9
            },
            {
                "type": "fee",
                "name": "markup_conversion_fee",
                "description": " המרה מעל מחיר שוק",
                "calculation_type": "const",
                "value": pricePlane ? Number(pricePlane?.properties?.markup_conversion_fee?.value) : 0,
                "order": 10
            },
            {
                "fee": "fee",
                "name": "cheque_cashing_cash_rate",
                "description": "ריבית ניכיון שיק מזומן",
                "calculation_type": "percent",
                "value_max": pricePlane ? Number(pricePlane?.properties?.cheque_cashing_cash_rate?.value_max) : 0,
                "value_min": pricePlane ? Number(pricePlane?.properties?.cheque_cashing_cash_rate?.value_min) : 0,
                "percent": pricePlane ? Number(pricePlane?.properties?.cheque_cashing_cash_rate?.percent) : 0,
                "order": 11
            },
            {
                "fee": "fee",
                "name": "cheque_cash_fee",
                "description": "עמלת קבלת מזומן",
                "calculation_type": "percent",
                "value_max": pricePlane ? Number(pricePlane?.properties?.cheque_cash_fee?.value_max) : 0,
                "value_min": pricePlane ? Number(pricePlane?.properties?.cheque_cash_fee?.value_min) : 0,
                "percent": pricePlane ? Number(pricePlane?.properties?.cheque_cash_fee?.percent) : 0,
                "order": 12
            },
            {
                "fee": "fee",
                "name": "cheque_cashing_rate",
                "description": "ריבית ניכיון שיק דחוי",
                "calculation_type": "percent",
                "value_max": pricePlane ? Number(pricePlane?.properties?.cheque_cashing_rate?.value_max) : 0,
                "value_min": pricePlane ? Number(pricePlane?.properties?.cheque_cashing_rate?.value_min) : 0,
                "percent": pricePlane ? Number(pricePlane?.properties?.cheque_cashing_rate?.percent) : 0,
                "order": 13
            },
            {
                "fee": "fee",
                "name": "arrears_interest",
                "description": " ריבית פיגורים על יתרת חובה",
                "calculation_type": "percent",
                "value_max": pricePlane ? Number(pricePlane?.properties?.arrears_interest?.value_max) : 0,
                "value_min": pricePlane ? Number(pricePlane?.properties?.arrears_interest?.value_min) : 0,
                "percent": pricePlane ? Number(pricePlane?.properties?.arrears_interest?.percent) : 0,
                "order": 14
            },
            {
                "type": "setting",
                "name": "cheque_cashing_value_days",
                "description": "ימי ערך בניכיון שיק",
                "calculation_type": "const",
                "value": pricePlane ? Number(pricePlane?.properties?.cheque_cashing_value_days?.value) : 0,
                "order": 15
            },
        ]
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(createPricePlan);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    await axios.post(basicUrl + '/entity/price_program', body, config)
        .then(async res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        await dispatch({
                            type: SET_PRICE_PROGRAMS_TEMPLATES,
                            payload: res.data.data.price_program_template
                        })
                        let setUpdatePricePlan: any = {
                            key: '', planName: '', default: 0, status: 1, properties: {
                                foreign_cheque_deposit_fee: {}, cheque_refund_fee: {}, new_account_fee: {}, israeli_cheque_deposit_fee: {},
                                repeat_deposit_fee: {}, wire_rtgs_fee: {}, wire_fee: {}, abroad_wire_fee: {},
                                cheque_collection_fee: {}, markup_conversion_fee: {}, cheque_cashing_cash_rate: {},
                                cheque_cash_fee: {}, cheque_cashing_rate: {}, arrears_interest: {}, cheque_cashing_value_days: {}
                            }, note: ''
                        }
                        let properties: any = {}
                        let pricePlan=res?.data?.data?.price_program_template.find((item:any)=>item.price_program_name==="");
                        setUpdatePricePlan.properties =pricePlan?.price_program_properties
                        setUpdatePricePlan.key = pricePlan?.price_program_id
                        await setUpdatePricePlan?.properties?.forEach((element: any) => {
                            properties[element.name] = element
                        });
                        setUpdatePricePlan.properties = properties
                        setPricePlan && setPricePlan(setUpdatePricePlan)
                        !setPricePlan && setShowDialogSave(true)
                    }
                }
            }
            catch (err) {
                console.log('error in create price plan template',)
            }
        })
}


export const updatePricePlanTemplate = (pricePlane: any, idInitiator: any, idClient: any, idBranch: any, setShowDialogSave: any) => async (dispatch: Function) => {
    const updatePricePlanTemplate = {
        "id_initiator": idInitiator,
        "id_client": idClient,
        "id_branch": idBranch,
        "price_program_id": pricePlane.key,
        "return_price_program": 1,
        "request_method": "update",
        "note": pricePlane.note,
        "price_program_name": pricePlane.planName,
        "is_default": pricePlane.default,
        "status_id": pricePlane.status,
        "price_program_properties": [
            {
                "type": "fee",
                "name": "new_account_fee",
                "description": "פתיחת חשבון",
                "calculation_type": "const",
                "value": Number(pricePlane.properties.new_account_fee.value),
                "order": 1
            },
            {
                "type": "fee",
                "name": "israeli_cheque_deposit_fee",
                "description": "עמלת הפקדה שיק ישראלי",
                "calculation_type": "const",
                "value": Number(pricePlane.properties.israeli_cheque_deposit_fee.value),
                "order": 2
            },
            {
                "type": "fee",
                "name": "foreign_cheque_deposit_fee",
                "description": "עמלת הפקדת שיק מטח",
                "calculation_type": "const",
                "value": Number(pricePlane.properties.foreign_cheque_deposit_fee.value),
                "order": 3
            },
            {
                "type": "fee",
                "name": "cheque_refund_fee",
                "description": "עמלת החזר שיק",
                "calculation_type": "const",
                "value": Number(pricePlane.properties.cheque_refund_fee.value),
                "order": 4
            },
            {
                "type": "fee",
                "name": "repeat_deposit_fee",
                "description": "עמלת הפקדה חוזרת",
                "calculation_type": "const",
                "value": Number(pricePlane.properties.repeat_deposit_fee.value),
                "order": 5
            },
            {
                "type": "fee",
                "name": "wire_rtgs_fee",
                "description": "עמלת העברת זהב",
                "calculation_type": "const",
                "value": Number(pricePlane.properties.wire_rtgs_fee.value),
                "order": 6
            },
            {
                "type": "fee",
                "name": "wire_fee",
                "description": "עמלת העברה רגילה",
                "calculation_type": "const",
                "value": Number(pricePlane.properties.wire_fee.value),
                "order": 7
            },
            {
                "type": "fee",
                "name": "abroad_wire_fee",
                "description": "עמלת העברה לחו\"ל",
                "calculation_type": "const",
                "value": Number(pricePlane.properties.abroad_wire_fee.value),
                "order": 8
            },
            {
                "type": "fee",
                "name": "cheque_collection_fee",
                "description": "Cדמי גביה לכל שיק בניכיון",
                "calculation_type": "const",
                "value": Number(pricePlane.properties.cheque_collection_fee.value),
                "order": 9
            },
            {
                "type": "fee",
                "name": "markup_conversion_fee",
                "description": " המרה מעל מחיר שוק",
                "calculation_type": "const",
                "value": Number(pricePlane.properties.markup_conversion_fee.value),
                "order": 10
            },
            {
                "fee": "fee",
                "name": "cheque_cashing_cash_rate",
                "description": "ריבית ניכיון שיק מזומן",
                "calculation_type": "percent",
                "value_max": Number(pricePlane.properties.cheque_cashing_cash_rate.value_max),
                "value_min": Number(pricePlane.properties.cheque_cashing_cash_rate.value_min),
                "percent": Number(pricePlane.properties.cheque_cashing_cash_rate.percent),
                "order": 11
            },
            {
                "fee": "fee",
                "name": "cheque_cash_fee",
                "description": "עמלת קבלת מזומן",
                "calculation_type": "percent",
                "value_max": Number(pricePlane.properties.cheque_cash_fee.value_max),
                "value_min": Number(pricePlane.properties.cheque_cash_fee.value_min),
                "percent": Number(pricePlane.properties.cheque_cash_fee.percent),
                "order": 12
            },
            {
                "fee": "fee",
                "name": "cheque_cashing_rate",
                "description": "ריבית ניכיון שיק דחוי",
                "calculation_type": "percent",
                "value_max": Number(pricePlane.properties.cheque_cashing_rate.value_max),
                "value_min": Number(pricePlane.properties.cheque_cashing_rate.value_min),
                "percent": Number(pricePlane.properties.cheque_cashing_rate.percent),
                "order": 13
            },
            {
                "fee": "fee",
                "name": "arrears_interest",
                "description": " ריבית פיגורים על יתרת חובה",
                "calculation_type": "percent",
                "value_max": Number(pricePlane.properties.arrears_interest.value_max),
                "value_min": Number(pricePlane.properties.arrears_interest.value_min),
                "percent": Number(pricePlane.properties.arrears_interest.percent),
                "order": 14
            },
            {
                "type": "setting",
                "name": "cheque_cashing_value_days",
                "description": "ימי ערך בניכיון שיק",
                "calculation_type": "const",
                "value": Number(pricePlane.properties.cheque_cashing_value_days.value),
                "order": 15
            },
        ]
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(updatePricePlanTemplate);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    axios.post(basicUrl + '/entity/price_program', body, config)
        .then(async res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        setShowDialogSave&& setShowDialogSave(true)
                        await dispatch({
                            type: SET_PRICE_PROGRAMS_TEMPLATES,
                            payload: res.data.data.price_program_template
                        })
                    }
                }
            }
            catch (err) {
                console.log('error in update price plan template',)
            }
        })
}

export const deletePricePlanTemplate = (pricePlaneId: any, userCredentials: IUserCredentials|null, setShowDialogDelete: any,
     setServerMessageError?: any, setMessageError?: any,t?:any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const deletePricePlan = {
        "id_initiator":currUserCredentials&& currUserCredentials["idInitiator"],
        "id_client": currUserCredentials&&currUserCredentials["idClient"],
        "id_branch": currUserCredentials&&currUserCredentials["idBranch"],
        "price_program_id": pricePlaneId,
        "return_price_program": 1,
        "request_method": "delete"
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(deletePricePlan);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    axios.post(basicUrl + '/entity/price_program', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                       setShowDialogDelete&& setShowDialogDelete(true)
                        dispatch({
                            type: SET_PRICE_PROGRAMS_TEMPLATES,
                            payload: res.data.data.price_program_template
                        })
                    }
                    else {
                        setServerMessageError && setServerMessageError(true)
                        setMessageError && setMessageError(res.data?.ui_message?.ui_message)
                    }
                }
            }
            catch (err) {
                setServerMessageError && setServerMessageError(true)
                setMessageError && setMessageError(t("errorPerformingActionMassege"))
            }
        })
}

