import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export default function TableAccountBalance(props: any) {
    const { items, columns, numSpanColumns, itemArrayName, showAccountMovements } = props
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [hoveredRow, setHoveredRow] = useState<any>(null);
    return (
        <table style={{ border: '2px solid #e1e1e1', overflow: "auto" }}>
            <thead>
                <tr>
                    {columns?.map((item: any, rowIndex: number) => (
                        <React.Fragment key={rowIndex + "2"}>
                            <th className={`padding-input-${dir} th-complete-table`}
                                style={{ width: `${item?.maxWidth}px` }}>{item?.name}</th>
                        </React.Fragment>
                    ))}
                </tr>
            </thead>
            <tbody>
                {items?.map((row: any, rowIndex: number) => {
                    const ItemRows: any = row?.[itemArrayName]?.map(
                        (balance: any, balanceIndex: any) => (
                            <tr className={hoveredRow === rowIndex ? 'hoverRowT' : ''}
                                onMouseEnter={() => { setHoveredRow(rowIndex) }}
                                onMouseLeave={() => { setHoveredRow(null) }}
                                style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }}
                                key={`${rowIndex}- ${balanceIndex}`}>
                                {columns.slice(numSpanColumns).map((col: any, rowIndex: number) => (
                                    <td key={rowIndex + "q"} style={{ direction: col?.number ? "ltr" : "rtl" }}
                                        className={`td-span-table-${dir} padding-input-${dir}`}>
                                        {col?.number ? balance?.[col?.fieldName]?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            : balance?.[col?.fieldName]}</td>
                                ))
                                }
                            </tr>
                        )
                    );
                    return (
                        <React.Fragment key={rowIndex}>
                            <tr className={hoveredRow === rowIndex ? 'hoverRowT' : ''}
                                onMouseEnter={() => { setHoveredRow(rowIndex) }}
                                onMouseLeave={() => { setHoveredRow(null) }}
                                style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }} key={rowIndex + "a"}>
                                {columns.slice(0, numSpanColumns).map((col: any, rowIndex: number) => (
                                    <td key={rowIndex + "1"} className={`td-span-table-${dir} padding-input-${dir} `}
                                        style={{ direction: col?.number ? "ltr" : "rtl" }} onClick={() => {
                                            (col?.fieldName === "account_name" || col?.fieldName === "account_number")
                                                && showAccountMovements(row)
                                        }}
                                        rowSpan={row?.[itemArrayName]?.length}>
                                        <span className={`${col?.fieldName === "account_name" || col?.fieldName === "account_number" ?
                                            "link-document-home-page" : ""}`}> {col?.number ? row?.[col?.fieldName]?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                : row?.[col?.fieldName]}</span>
                                    </td>
                                ))}
                                {
                                    ItemRows?.[0]?.props.children
                                    ??
                                    <td colSpan={4} className={`td-span-table-${dir} padding-input-${dir} `}
                                    >לא נמצאו נתונים להצגה</td>}
                            </tr>
                            {ItemRows?.slice(1)}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table >
    );


}
