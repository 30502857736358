import React, { useEffect, useState } from "react";
import { Nav } from "@fluentui/react/lib/Nav";
import "../../scssPages/sidebar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { INavStyles, INavLinkGroup, INavLink } from "@fluentui/react/lib/Nav";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { useLocation, withRouter } from "react-router-dom";
import "../../scssPages/sidebar.scss";
import { useTranslation } from "react-i18next";
import { registerIcons } from "@fluentui/react";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { SAVE_DRAFT_CONVERSION_CHANGES } from "../../store/actions/types";
import { globalPages } from "../../shared/components/CustomHooks/GlobalSidePages";
import "../../modelsType/Icons/css/all.css";

const navStyles: Partial<INavStyles> = { root: { width: 150 } };

initializeIcons();

registerIcons({
  icons: {
    Filter: <FontAwesomeIcon icon={faFilter} />,
    // Pills: <FontAwesomeIcon icon={faPills} />,
    // UsersCog: <FontAwesomeIcon icon={faUsersCog} />,
    // UserFriends: <FontAwesomeIcon icon={faUserFriends} />,
    // Cogs: <FontAwesomeIcon icon={faCogs} />,
    // Landmark: <FontAwesomeIcon icon={ faLandmark} />,
    // User: <FontAwesomeIcon icon={faUser} />,
    // MoneyCheckAlt: <FontAwesomeIcon icon={faMoneyCheckAlt} />
    MoneyCheckIcon: <i className="fa-light fa-money-check"></i>,
    LandmarkIcon: <i className="fal fa-landmark"></i>,
    UserIcon: <i className="fal fa-user"></i>,
    ReceiptIcon: <i className="fal fa-receipt"></i>,
    InvoiceIcon: <i className="fal fa-file-invoice"></i>,
    ThListIcon: <i className="fal fa-coins"></i>,
  },
});

const ContentSideSearch = (props: any) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("");
  // const [showPermissionNote, setShowPermissionNote] = useState(false)
  // const [msgPermission, setMsgPermission] = useState('')
  // const credentials = useSelector(({ authReducer }: any) => authReducer.data.user.credentials)
  const arrCredentials = useSelector(
    ({ authReducer }: any) => authReducer?.data?.credentials
  );
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);
  const [credentialsPagesArrDetails, setCredentialsPagesArrDetails] =
    useState<any>();
  const credentialsPagesDetails: any = globalPages;

  // useEffect(() => {
  //   if (arrCredentials?.length) {
  //     let copyModule: any = []
  //     arrCredentials?.map((module: any) => {
  //       if (module?.module_code === "bookkeeping") {
  //         module?.pages?.map((page: any) => {
  //           if (credentialsPagesDetails[page?.page_code])
  //           copyModule.push({ ...credentialsPagesDetails[page?.page_name] })
  //         })
  //       }
  //     })
  //     setCredentialsPagesArrDetails(copyModule)
  //   }
  // }, [arrCredentials])

  useEffect(() => {
    const pathname = location.pathname.split("/");
    const key = pathname.length > 2 ? pathname.pop() : null;
    setSelectedKey(key || "search");
  }, []);

  const handleNavClick = async (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    ev?.preventDefault();
    setSelectedKey(item?.key || "search");
    let path: any = item?.url.split("/")[1];

    // if (credentials[path] && credentials[path] === "Deny" || !credentials[path]) {
    //   setMsgPermission(t('permissionAaccessThisPage'))
    //   setShowPermissionNote(true)
    // }

    // else {
    dispatch({
      type: SAVE_DRAFT_CONVERSION_CHANGES,
      payload: {},
    });
    props.history.push(`/search${item?.url}`);
    // }
  };

  const navStyles: Partial<INavStyles> = {
    root: {
      i: { width: "25px", marginRight: "3px" },
      icons: { fontWhight: "normal" },
    },
    chevronIcon: {
      fontWeight: "normal",
    },
    linkText: {
      margin: "0px 0px !important",
    },
    link: {
      ":after": dir === "ltr" && {
        borderLeft: "2px solid transparent !important",
        borderRight: "2px solid rgb(0, 120, 212) !important",
      },
    },
  };

  // const navLinkGroups: INavLinkGroup[] = [
  //   {
  //     links: [

  //       {
  //         key: "documents",
  //         className: "side-sub",
  //         iconProps: { iconName: "ThListIcon" }
  //         , styles: {
  //           icon: { color: '#1A1F71' }
  //         },
  //         name: t("search"),
  //         url: "/documents",
  //         onClick: handleNavClick
  //       },

  //     ]
  //   }
  // ];

  const navLinkGroups: INavLinkGroup[] = [{ links: [] }];
  arrCredentials?.map(
    (module: any) =>
      module?.module_code === "bookkeeping" &&
      module?.pages?.map((page: any) =>
        navLinkGroups[0]?.links.push({
          className: credentialsPagesDetails[page?.page_name]?.className,
          key: credentialsPagesDetails[page?.page_name]?.key,
          iconProps: credentialsPagesDetails[page?.page_name]?.iconProps,
          name: page?.name,
          styles: credentialsPagesDetails[page?.page_name]?.styles,
          url: credentialsPagesDetails[page?.page_name]?.url,
          onClick: handleNavClick,
        })
      )
  );
  // credentialsPagesArrDetails?.map((page: any) => (

  //     navLinkGroups[0]?.links.push(
  //       {
  //         className: page?.className,
  //         key: page?.key,
  //         iconProps: page?.iconProps,
  //         name: page?.name,
  //         styles:page?.styles,
  //         url: page?.url,
  //         onClick: handleNavClick
  //       })
  //   ))
  return (
    <>
      <Nav
        className="items"
        onLinkClick={handleNavClick}
        styles={navStyles}
        groups={navLinkGroups}
        selectedKey={selectedKey}
      />
      {/* {showPermissionNote && <DialogMessages setansDelete={setShowPermissionNote} setshowModalDelete={setShowPermissionNote} subText={msgPermission} dialogType={"dialogMessage"} />} */}
    </>
  );
};
export default withRouter(ContentSideSearch);
