import { PrimaryButton } from '@fluentui/react';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils';
import Subtitle from '../../shared/components/Subtitle';
import AccountsAddAcountFirstDialog from './AccountsAddAcountFirstDialog';
import AccountsAddAcountDialog from './AccountsAddAcountDialog';
import AccountsBookKeepingDialog from './AccountsBookKeepingDialog';
import AccountTable from './AccountTable';
import { accountData } from '../../store/actions/accountAction'
import { IUserCredentials } from '../customerDetails/CustomerDetails';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions';

export default function Accounts() {
  const dispatch = useDispatch()
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const authReducer = useSelector(({ authReducer }: any) => authReducer)
  const [showDialog, setShowDialog] = useState(false)
  const [showDialogAdd, setShowDialogAdd] = useState(false)
  const [showDialogAddFirst, setShowDialogAddFirst] = useState(false)
  const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
  const [rowData, setRowData] = useState<any>([]);
  const [actionData, setaAtionData] = useState<any>([]);
  const location = useLocation()
  const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)

    const arrTitle=[
      { key: "accountName", eng: 'account_name'},
      { key: "accountNumber", eng: 'account_number'},
      { key: "accountType", eng: 'account_type_id'},
      { key: "status", eng: 'status_id' },
      { key: "balanceGroup", eng: 'balance_group_id' },
      { key: "balanceSubgroup", eng: 'balance_sub_group_id'}]

  const funcAction = async () => {
    await dispatch(accountData(userCredentials, undefined, setaAtionData, 1,
       [], rowData?.records_found, "search"
    ))
  }


  useEffect(() => {
    setUserCredentials({
      "idInitiator": authReducer.data.user.id_entity,
      "idClient": authReducer.data.client.id_entity,
      "idBranch": authReducer.data.branch.id_entity,
      "idClientData": authReducer.data.client.id_client
    })
  }, [authReducer]);

  return (
    <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
    ? 'page-read' : 'page-read-only'}`}>
      <HeaderUtils title={t("bookkeeping")} PrintTitle={arrTitle} actionData={actionData?.search_results} 
      funcAction={funcAction}  subTitle={"false"} editBtn={false} createXl={true} createPrint={true}  createPDF={false} 
        uploadBtn={false} disabledSave={true} showSelectConvertions={true} />
      <br></br><br></br>
      <Subtitle title={t("accounts")} />
      <div className={`content-div-${dir}`}>
        <AccountTable rowData={rowData} actionData={actionData?.search_results} setRowData={setRowData}  />
        {showDialog && <AccountsBookKeepingDialog setShowDialog={setShowDialog} />}
        {showDialogAdd && <AccountsAddAcountDialog rowData={rowData} setRowData={setRowData} setShowDialog={setShowDialogAdd} />}
        {/* <PrimaryButton ariaDescription="create"  allowDisabledFocus
         checked={false} name="create" text={t("bookkeeping")} onClick={() => {
          setShowDialog(true) }} className={`create-bookkiping-${dir}`} /> */}
        {/* <PrimaryButton ariaDescription="create" allowDisabledFocus checked={false} 
        name="create" text={t("addPredefinedAccounts")} onClick={() => {
          setShowDialogAddFirst(true)
        }} /> */}

      </div>
    </div>
  )
}
