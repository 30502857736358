import { useTranslation } from 'react-i18next';

import Search from "../Search";

const TransactionConvert = () => {
    const [t] = useTranslation();
    return (
        <>
            <Search title={t("foreignCurrency")} path="/currency_exchange/exchange" subTitle="transactionConvert" type="exchange_slip" />
        </>
    )
}

export default TransactionConvert
