export const setTypeId = (idTypeId: any) => {
    let typeId
    switch (idTypeId) {
        case "1":
            typeId = 1
            break;
        case "2":
            typeId = 1
            break;
        case "3":
            typeId = 1
            break;
        case "4":
            typeId = 1
            break;
        case "99":
            typeId = 1
            break;
        case "5":
            typeId = 3
            break;
        case "10":
            typeId = 3
            break;
        case "11":
            typeId = 3
            break;
        case "13":
            typeId = 3
            break;
        case "14":
            typeId = 3
            break;
        case "15":
            typeId = 3
            break;
        case "16":
            typeId = 3
            break;
        case "17":
            typeId = 3
            break;
        case "18":
            typeId = 3
            break;
        case "19":
            typeId = 3
            break;
        case "20":
            typeId = 3
            break;
        case "12":
            typeId = 12
            break;
        default:
            break;
    }
    return typeId
}