import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaste, faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import DialogSearch from '../../../../components/global/DialogSearch';
import { HolderAccountDialog } from './HolderAccountDialog';
import { BeneficiaryDialog } from './BeneficiaryDialog';
import { EndorseeDialog } from './EndorseeDialog';
import { useEffect, useState } from 'react';
import { DialogMessages } from '../DialogMessages';
import { useSelector } from 'react-redux';


export const Details = (props: any) => {
    const { title, newObject, setNewObject, setNewObjectId, setshowDialog, showDialog, changeCheckDetails, requiredField,
        setsearch, conversionCustomer, userCredentials, setidBankAccount, setUpdateBankAccount, updateBankAccount, idHolder } = props
    const [messageError, setMessageError] = useState('')
    const [showDialogFailed, setShowDialogFailed] = useState(false)
    const [newHolderDetails, setNewHolderDetails] = useState("")
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const { state } = useLocation<any>()

    const func = (entity: any) => {
        if (title === "מושך" || title === "Drawee") {
            let customerAccount: any
            entity?.bankAccounts?.forEach((account: any) => {
                if (account?.bank_code === updateBankAccount?.bank &&
                    account?.bank_branch_code === updateBankAccount?.branch &&
                    account?.bank_account_number === updateBankAccount?.accountNumber) {
                    customerAccount = account
                }
            })
            if (customerAccount) {
                setidBankAccount(customerAccount?.bank_account_id)
                setNewObject(entity);
                setNewObjectId(entity?.idEntity)
            }
            else {
                if (updateBankAccount?.accountNumber && updateBankAccount?.branch && updateBankAccount?.bank) {
                    setShowDialogFailed(true)
                    setMessageError(t("accountNotRegisteredOfDdraweeSelectedMassege"))
                }
                else {
                    setshowDialog(true)
                    setNewObject(entity);
                    setNewObjectId(entity?.idEntity);
                }
            }
        }
        else {
            setNewObject(entity);
            setNewObjectId(entity?.idEntity);
        }
    }

    useEffect(() => {
        if (newHolderDetails === "save") {
            setNewHolderDetails("")
            if (newObject) {
                setNewObject(newObject);
                setNewObjectId(newObject?.idEntity);
            }
            else {
                setNewObject(conversionCustomer);
                setNewObjectId(conversionCustomer?.idEntity);
            }
            setshowDialog(true)
        }
        if (newHolderDetails === "don't save") {
            setNewHolderDetails("")
            setNewObject()
            setNewObjectId();
        }
        // eslint-disable-next-line
    }, [newHolderDetails])

    useEffect(() => {
        newObject?.idEntity && (title === "מושך" || title === "Drawee") && !idHolder && !showDialog && func(newObject);
        // eslint-disable-next-line
    }, [newObject])

    const deleteDetails = () => {
        if (title === "מושך" || title === "Drawee") {
            setidBankAccount(undefined)
            setNewObjectId(undefined)
        }

        (title === "מוטב" || title === "Beneficiary") ? changeCheckDetails("id_beneficiary", undefined) :
            (title === "נסב" || title === "Endorsee") && changeCheckDetails("id_endorsee", undefined)
    }
    return (
        <>
            {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} subText={messageError} title={t('error')} setshowModalDelete={setShowDialogFailed} dialogType={"dialogMessage"} setSaveDataConversion={setNewHolderDetails} />}

            <div className={`attechedAConDetails div-details-wrraper-${dir}`}

                style={{ border: (requiredField ? '2px solid red' : '1px solid grey') }}>
                <div className={`div-details-title-${dir}`} style={{ display: "flex" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{
                            display: "inline-grid", fontSize: "x-large",
                            fontWeight: "normal", marginTop: "5px", marginLeft: "5px", marginRight: "5px"
                        }}>
                            <p style={{ height: "3px", fontSize: 'large', fontWeight: "bold" }}>{t("detailsChequePage")}</p>
                            <p style={{ height: "3px", fontSize: 'large', fontWeight: "bold" }}>{t(title)}</p>
                        </div>
                        <div className={`div-details-${dir}`}></div>

                    </div>
                    {newObject ? <div style={{ display: "inline-grid", fontSize: "small", fontWeight: "normal" }}>
                        <p style={{ margin: "0", fontSize: 'larger', fontWeight: "bold" }}>{newObject?.firstName && newObject?.firstName}  {newObject?.lastName && newObject?.lastName}  {newObject?.idNumber && newObject?.idNumber}</p>
                        <div style={{ display: 'flex', }}>
                            <p className={`p-details-new-${dir}`} >{newObject?.addressName ? newObject?.addressName : ""}  {newObject?.addressNumber ? newObject?.addressNumber : ""} {newObject?.addressCityName && newObject?.addressCityName}</p>
                            <p style={{ margin: "0" }}>{newObject?.telephone ? newObject?.telephone : ""} </p>
                        </div>
                    </div > : ""}
                </div>
                <div style={{ backgroundColor: 'rgba(234, 229, 229, 0.4)', display: "inline-grid" }}>
                    <div style={{ display: "flex", height: "37px", margin: "auto" }}>
                        {!newObject ? <>
                            <DialogSearch setShowDialog={setsearch} userCredentials={userCredentials} isSearchInsiders={false} setConversionCustomerId={setNewObjectId} setRelatedFactors={setNewObject} />
                            <FontAwesomeIcon icon={faPaste} title={`${t("copyChequePage")} ${t(title)}`} style={{ fontSize: "20px" }} onClick={() => { /*setNewObject(conversionCustomer); setNewObjectId(conversionCustomer?.idEntity)*/; func(conversionCustomer) }} className={`iconRefresh-${dir}`} />
                            <FontAwesomeIcon icon={faPlus} title={`${t("createChequePage")} ${t(title)}`} onClick={() => setshowDialog(true)} className={`iconRefresh-${dir} details-plus-icon-${dir}`} />
                        </> :
                            state !== "showCompletedManagment" ? <FontAwesomeIcon icon={faTimesCircle} title={`${t("deleteChequePage")} ${t(title)}`} onClick={() => {
                                {
                                    setNewObject(""); setNewObjectId(""); deleteDetails();
                                    // setUpdateBankAccount({
                                    //     key: undefined, country: "", countryCode: "", accountNumber: undefined, bank: undefined, branch: "",
                                    //     bankName: undefined, branchAddress: undefined, branchName: undefined, routingNumber: undefined
                                    // })
                                }
                            }} className={`iconRefresh-${dir}  details-plus-icon-${dir}`} />
                                : ""}
                    </div>
                    {
                        showDialog && (title === "מוטב" || title === "Beneficiary") ? <BeneficiaryDialog setShowDialog={setshowDialog} setnewBenObject={setNewObject} setidBeneficiary={setNewObjectId} /> :
                            showDialog && (title === "נסב" || title === "Endorsee") ? <EndorseeDialog setShowDialog={setshowDialog} setnewEndorsee={setNewObject} newEndorsee={newObject} setidEndorsee={setNewObjectId} /> :
                                showDialog && (title === "מושך" || title === "Drawee") && <HolderAccountDialog conversionCustomer={conversionCustomer} setShowDialog={setshowDialog} userCredentials={userCredentials} setnewHolder={setNewObject} newHolder={newObject} setidHolder={setNewObjectId} setidBankAccount={setidBankAccount} setUpdateBankAccount={setUpdateBankAccount} updateBankAccount={updateBankAccount} idHolder={idHolder} />
                    }

                </div>
            </div >
        </>
    )

}

