import DialogTopOffsetFixedExample from './examplepop';

const PriceList=()=>{
    return(
        <>
        <h1>retgh</h1>
         <DialogTopOffsetFixedExample/>
        </>
    );
}

export default PriceList;