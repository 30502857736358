import * as React from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import CustomerDetails from '../customerDetails/CustomerDetails';
import Contact from '../contact/Contact';
import RelatedFactorsC from '../relatedFactorsC/RelatedFactorsC';
import BankAccounts from '../BankAccounts';
import CustomerSearch from '../customerSearch/CustomerSearch';
import ClientPlan from '../ClientPlan/ClientPlan';


const SideSubClient: React.FunctionComponent = () => {

  return (
    <div>
      <Switch>
        <Route exact path="/customer-search" component={CustomerSearch} />
        <Route exact path='/customer-details/:userId?' component={CustomerDetails} />
        <Route path='/related-factors' component={RelatedFactorsC} />
        <Route exact path='/bank-accounts' component={BankAccounts} />
        <Route exact path='/contact' component={Contact} />
        <Route path='/customer-plan' component={ClientPlan} />
      </Switch>
    </div>
  );
};
export default withRouter(SideSubClient)