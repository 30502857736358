import axios from "axios";
import { ICustomer, IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { basicUrl } from "../../shared/config";
import { checkHttpStatus } from "../../utils";
import { CREATE_CUSTOMER, READ_CUSTOMER } from '../actions/types';
import { CreateAddress } from './addressAction'
import { CreatePhone } from './phonecAction'
import { CreateEmail } from './emailAction'
import { customerInfoInObject } from '../../shared/components/CustomHooks/GlobalFunctions'
import { createBankAccount } from "./bankActions";
import { setTypeId } from '../../shared/components/CustomHooks/CheckCutomerType'

let currUserCredentials: IUserCredentials | null = null

const setClassIdValue = (customerTypeId: any) => {
  let classId
  switch (customerTypeId) {
    case "9":
      classId = 3
      break;
    case "8":
      classId = 2
      break;
    case "7":
      classId = 6
      break;
    case "6":
      classId = 6
      break;
    case "5":
      classId = 3
      break;
    case "4":
      classId = 1
      break;
    case "3":
      classId = 3
      break;
    case "2":
      classId = 1
      break;
    case "12":
      classId = 4
      break;
    case "11":
      classId = 2
      break;
    case "10":
      classId = 1
      break;
    case "1":
      classId = 1
      break;
    default:
      break;
  }
  return classId
}



export const createCustomer = (customer: ICustomer, userCredentials: IUserCredentials | null, isToSetData?: any,
  setshowSpiner?: any, isClient?: any, setShowDialogSave?: any, setShowDialogFailed?: any,
  setServerError?: any, setCurrentIdEntity?: any, toReadCustomer?: any) => async (dispatch: any,) => {
    currUserCredentials = userCredentials
    let classId = await setClassIdValue(customer?.customerTypeId)
    // Headers
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`

      }
    };
    const createCustomer = {
      "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
      "id_client": currUserCredentials && currUserCredentials["idClient"],
      "id_branch": currUserCredentials && currUserCredentials["idBranch"],
      "entity_request_method": "create",
      "ID_country_code": customer?.idTypeCountryCode,
      "ID_number": customer?.idNumber,
      "ID_type_id": customer?.idTypeId,
      "status_id": customer?.entityStatusId,
      "class_id": classId,
      "entity_type_id": isClient === true ? "client" : "customer",
      "customer_type_id": customer?.customerTypeId ? customer?.customerTypeId : await setTypeId(customer?.idTypeId),
      "gender_other": customer?.genderName,
      "ID_type_other": customer?.idTypeOther,
      "first_name": customer?.firstName,
      "last_name": customer?.lastName,
      "middle_name": customer?.middleName,
      "entity_name": (customer?.lastName + " " + customer.firstName),
      "entity_number": customer?.entityNumber,
      // "first_name_en": "Amit",
      // "last_name_en": "Keresanty",
      // "entity_name_en": "Amit Keresanty",
      "date_birth": customer?.dateBirth,
      "gender_id": customer?.gender,
      "id_identifier": customer.workerName ? customer.workerName : currUserCredentials && currUserCredentials["idInitiator"],
      "is_identified": true,
      "industry_classification_id": customer.industryId === 0 ? null : customer.industryId,
      "industry_sub_classification_id": customer.CreditGroup === 0 ? null : customer.CreditGroup,
      "is_loaded_documentation": false,
      "is_popup_message": customer.isPopupMessage,
      "is_locked": customer.isLocked,
      "note": customer.note,
      "permission_group_id": null,
      "return_entity": true,
      "user_language": customer?.user_language,
      // user_time_zone: "Israel Standard Time"

    }
    const body = JSON.stringify(createCustomer);
    console.log(createCustomer,"createCustomer333");
    
    let res = await axios.post(basicUrl + '/entity', body, config)
      .then(checkHttpStatus)
      .then(async (res) => {
        try {
          if (res.status === 200) {
            if (res.data["err_code"] === 0) {
              if (isToSetData) {
                dispatch({
                  type: CREATE_CUSTOMER,
                  value: res.data.data
                })
              }
              await CreateAddress(customer, userCredentials, res.data.data.id_entity, setshowSpiner, isClient);
              customer.telephone && await CreatePhone(customer, userCredentials, res.data.data.id_entity, setshowSpiner, isClient);
              customer.email && await CreateEmail(customer, userCredentials, res.data.data.id_entity, setshowSpiner, isClient)
              setCurrentIdEntity && setCurrentIdEntity(res.data.data.id_entity)
              setshowSpiner && setshowSpiner(false)
              setShowDialogSave && setShowDialogSave(true)
              if (toReadCustomer === true) {
                await dispatch(readCustomerId(res.data.data.id_entity, userCredentials))
              }
              return res;
            }
            else {
              setshowSpiner && setshowSpiner(false)
              setServerError(res.data?.ui_message?.ui_message)
              setShowDialogFailed && setShowDialogFailed(true)
            }
          }
        }
        catch (e) {
          setshowSpiner && setshowSpiner(false)
          setServerError(res.data?.ui_message?.ui_message)
          setShowDialogFailed && setShowDialogFailed(true)
        }
      })
      .catch(err => {
        console.log("dataerror", err)
        setshowSpiner && setshowSpiner(false)
      })
    return res;
  }

export const createCustomerInConvertions = (customer: ICustomer, userCredentials: IUserCredentials | null, setnewBenObject: any, setCurrentIdEntity?: any, updateBankAccount?: any, isHolder?: boolean, setidBankAccount?: any, setnewBankAccount?: any) => async (dispatch: any,) => {
 debugger;
  currUserCredentials = userCredentials
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };
  const createCustomer = {
    "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
    "id_client": currUserCredentials && currUserCredentials["idClient"],
    "id_branch": currUserCredentials && currUserCredentials["idBranch"],
    "entity_request_method": "create",
    "ID_country_code": customer.idTypeCountryCode,
    "ID_number": customer.idNumber,
    "ID_type_id": customer.idTypeId,
    "status_id": 1,
    "class_id": 1,
    "customer_type_id": customer?.customerTypeId ? customer.customerTypeId : await setTypeId(customer?.idTypeId),
    "entity_type_id": "customer",
    "first_name": customer.firstName,
    "last_name": customer.lastName,
    "entity_name": (customer.lastName + " " + customer.firstName),
    "entity_number": customer.entityNumber,
    "id_identifier": customer?.workerName ? customer?.workerName : currUserCredentials && currUserCredentials["idInitiator"],
    "is_identified": true,
    "return_entity": true,

  }
  const body = JSON.stringify(createCustomer);
console.log(createCustomer,"createCustomer");

  await axios.post(basicUrl + '/entity', body, config)
    .then(checkHttpStatus)
    .then(async (res) => {
      console.log(res,"resut");
      
      try {
        if (res.status === 200) {
          console.log(res,"resin");
          
          if (res.data["err_code"] === 0) {
            // dispatch({
            //   type: CREATE_CUSTOMER,
            //   value: res.data
            // })
            await CreateAddress(customer, userCredentials, res.data.data.id_entity);
            await CreatePhone(customer, userCredentials, res.data.data.id_entity);
            // let isCreate = await CreateEmail(customer, userCredentials, res.data.data.id_entity, isClient)
            if (isHolder === true) {
              dispatch(createBankAccount(updateBankAccount, userCredentials, res.data.data.id_entity,
                undefined, undefined, undefined, setidBankAccount, setnewBankAccount))
            }
            await dispatch(readCustomerId(res.data.data.id_entity, userCredentials, setCurrentIdEntity, "", "", false, "", "", setnewBenObject))
          }
          else
          alert("אירעה שגיאה, לא ניתן להשלים את הפעולה")
        }
        else{
          console.log(res,res.status,"res");
          
          
        }
      }
      catch (e) {
        console.log("errordata", res, e)
      }
    })
    .catch(err => {
      console.log("dataerror", err)
    })
}
export const createEndorseeInConvertions = (customer: ICustomer, userCredentials: IUserCredentials | null, setnewBenObject: any, setCurrentIdEntity?: any) => async (dispatch: any,) => {
  currUserCredentials = userCredentials
  // Headers
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };
  const createCustomer = {
    "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
    "id_client": currUserCredentials && currUserCredentials["idClient"],
    "id_branch": currUserCredentials && currUserCredentials["idBranch"],
    "entity_request_method": "create",
    "ID_number": customer.idNumber,
    "ID_country_code": customer.idTypeCountryCode,
    "ID_type_id": customer.idTypeId,
    "status_id": 1,
    // "customer_type_id": 2,
    "customer_type_id": customer?.customerTypeId ? customer.customerTypeId : await setTypeId(customer?.idTypeId),
    "entity_type_id": "customer",
    "first_name": customer.firstName,
    "last_name": customer.lastName,
    "class_id": 1,
    "entity_name": (customer.lastName + " " + customer.firstName),
    "entity_number": customer.entityNumber,
    "id_identifier": customer?.workerName ? customer?.workerName : currUserCredentials && currUserCredentials["idInitiator"],
    "is_identified": true,
    "return_entity": true,

  }
  const body = JSON.stringify(createCustomer);
  await axios.post(basicUrl + '/entity', body, config)
    .then(checkHttpStatus)
    .then(async (res) => {
      try {
        if (res.status === 200) {
          if (res.data["err_code"] === 0) {
            dispatch(readCustomerId(res.data.data.id_entity, userCredentials, setCurrentIdEntity, "", "", false, "", "", setnewBenObject))
            return true;
          }
        }
        else{
          console.log(res.status,"res");
          console.log(res,"res3");
          
          
        }
      }
      catch (e) {
        console.log("errordata", res, e)
      }
    })
    .catch(err => {
      console.log("dataerror", err)
    })
  return true;
}
export const readCustomerId = (idEntity: string, userCredentials: IUserCredentials | null,
  setCurrentIdEntity?: any, setCustomer?: any, setcustomerBeforeChanges?: any,
  setState?: Boolean, setshowSpiner?: any, setReadOnly?: any, setNewEntity?: any,
  setRelatedFactors?: any, setnewBankAccount?: any,) => async (dispatch: Function) => {

    const readCustomer = {
      "entity_request_method": "read",
      "id_initiator": userCredentials && userCredentials.idInitiator,
      "id_client": userCredentials && userCredentials.idClient,
      "id_branch": userCredentials && userCredentials.idBranch,
      "id_entity": idEntity
    }
    const body = JSON.stringify(readCustomer);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`

      }
    };
    await axios.post(basicUrl + '/entity', body, config)
      .then(res => {
        try {
          if (res.status === 200) {
            if (res.data["err_code"] === 0) {
              const customerInfo = res.data.data;
              if (setState)
                setCurrentIdEntity && setCurrentIdEntity(customerInfo.id_entity)
              let customerData = customerInfoInObject(customerInfo)
              // if (!customerData.addressId || !customerData.telephoneId)
              //   // setEmptyFields && setEmptyFields(true)
              // setReadOnly && setReadOnly(false)
              if (setnewBankAccount) {
                setnewBankAccount(customerInfo.bank_accounts && customerInfo.bank_accounts[0])
              }
              if (setState) {
                setcustomerBeforeChanges(customerData)
                setCustomer(customerData);
              }
              if (setNewEntity) {
                setNewEntity(customerData)
                setCurrentIdEntity && setCurrentIdEntity(customerInfo.id_entity)
              }
              else {
                if (setRelatedFactors) {
                  setRelatedFactors(customerData)
                }
                else {
                  dispatch({
                    type: READ_CUSTOMER,
                    payload: customerInfo
                  })
                }
              }
              setshowSpiner && setshowSpiner(false)
            }
            else {
              setshowSpiner && setshowSpiner(false)
            }
          }
          else {
            setshowSpiner && setshowSpiner(false)
          }
        }
        catch (err) {
          console.log(res.status)
          console.log('error in get info customer', err)
        }
      })
  }





export const deleteCustomer = (idInitiator: string, id_entity: string, idClient: string,
  idBranch: string, setShowDialogUpdate?: any, setshowSpiner?: any) => {
  const deleteDetails = {
    "id_initiator": idInitiator,
    "id_entity": id_entity,
    "id_client": idClient,
    "id_branch": idBranch,
    "entity_request_method": "delete"
  }
  const body = JSON.stringify(deleteDetails);
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };

  axios.post(basicUrl + '/entity', body, config)
    .then(res => {
      try {
        if (res.status === 200) {
          if (res.data["err_code"] === 0) {
            setShowDialogUpdate(true);
            setshowSpiner(false)
            return true;
          }
          else {
            setshowSpiner(false)
          }
        }
        else {
          setshowSpiner(false)
        }
      }
      catch (err) {
        setshowSpiner(false)
        console.warn('error in deleted customer', err)
      }
    })
    .catch(err => {
      console.log("error", err.message);
      setshowSpiner(false)
      return false;
    })
}



export const updateCustomerInfo = async (customer: ICustomer, userCredentials: IUserCredentials | null, currentIdEntity: string, setShowDialogSave?: any,
  setReadOnly?: any, setshowSpiner?: any, isClient?: any, setShowDialogFailed?: any, setServerError?: any) => {

  let classId = await setClassIdValue(customer?.customerTypeId)

  const updateDetails = {
    "id_initiator": userCredentials?.idInitiator,
    "id_branch": userCredentials?.idBranch,
    "id_client": userCredentials?.idClient,
    "id_entity": currentIdEntity,
    "ID_type_other": customer.idTypeOther,
    "entity_type_id": isClient === true ? "client" : "customer",
    "entity_request_method": "update",
    "status_id": customer.entityStatusId,
    "first_name": customer.firstName,
    "middle_name": customer.middleName,
    "gender_id": customer.gender,
    "gender_other": customer.genderName,
    "return_entity": false,
    // "last_name_en": "Hooooome",
    "ID_country_code": customer.idTypeCountryCode,
    "ID_number": customer.idNumber,
    "ID_type_id": customer.idTypeId,
    "class_id": classId,
    "customer_type_id": customer?.customerTypeId ? customer?.customerTypeId : await setTypeId(customer?.idTypeId),
    "last_name": customer.lastName,
    "entity_name": (customer.lastName + " " + customer.firstName),
    "industry_classification_id": customer.industryId === 0 ? null : customer.industryId,
    "industry_sub_classification_id": customer.CreditGroup === 0 ? null : customer.CreditGroup,
    "date_birth": customer.dateBirth,
    "id_identifier": customer?.workerName ? customer?.workerName : userCredentials?.idInitiator,
    "is_identified": true,
    "is_loaded_documentation": false,
    "is_locked": customer.isLocked,
    "is_popup_message": customer.isPopupMessage,
    "note": customer.note,
    "permission_group_id": null,
    "is_fully_identified": customer.isFullyIdentified
  }
  const body = JSON.stringify(updateDetails);
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };

  await axios.post(basicUrl + '/entity', body, config)
    .then(async (res) => {
      try {
        if (res.status === 200) {
          if (res.data["err_code"] === 0) {
            await setShowDialogSave(true)
            await setshowSpiner && setshowSpiner(false)
            await setReadOnly(true)
            return true;
          }
          else {
            await setshowSpiner && setshowSpiner(false)
            await setServerError && setServerError(res.data?.ui_message?.ui_message)
            await setShowDialogFailed && setShowDialogFailed(true)
          }
        }
      }
      catch (err) {
        await setServerError && setServerError(res.data?.ui_message?.ui_message)
        await setShowDialogFailed && setShowDialogFailed(true)
        console.warn('error in update customer', err)
        await setshowSpiner && setshowSpiner(false)
      }
    })
    .catch(async (err) => {
      await setshowSpiner && setshowSpiner(false)
      console.log("error", err.message);
      return false;
    })
}

/////search customer 
export const searchCustomer = (clientData: any, customerSearch: string, curentPage: any, fromCustmer?: boolean, setSearchData?: any, setshowSpiner?: any,) => async (dispatch: Function) => {
  const search = {
    "id_initiator": clientData.id_entity,
    "id_client": clientData.id_client,
    "id_branch": currUserCredentials && currUserCredentials["idBranch"],
    "search_fields": [
      "name",
      "address",
      "thelephone"
    ],

    "search_type": fromCustmer ? "like" : "list",
    "search_entity_type_id": [
      "customer"
    ],

    "order_by": "DESC",
    "page_size": 10,
    "page_index": curentPage,
    "search_value": customerSearch

  }
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };
  const body = JSON.stringify(search);
  await axios.post(basicUrl + "/entity/search", body, config)
    .then(res => {
      try {
        if (res.status === 200) {
          
          if (res.data["err_code"] === 0 || res.data["err_code"] === 1) {
            setshowSpiner && setshowSpiner(false)
            setSearchData(res)
            // return true;
          }
          else {
            setshowSpiner && setshowSpiner(false)
          }
        }
        else {
          setshowSpiner && setshowSpiner(false)
        }
      }
      catch (err) {
        console.warn('error in update customer', err)
        setshowSpiner && setshowSpiner(false)
      }
    })
}


//// know your customer questionnaire 
export const kycQuestions = (objQustion: any, insiderEntity: any, idInitiator: string, idClient: string, idBranch: string, IdEntity: string, setShowDialogSave: any, setreadOnly: any, customerQ: any, setshowSpiner?: any) => async (dispatch: Function) => {
  let questions = {
    "id_initiator": idInitiator,
    "id_client": idClient,
    "id_entity": IdEntity,
    "id_branch": idBranch,
    "return_entity": 1,
    "attribute_request_method": "update",
    "attribute": "kyc_questionnaire",
    "entity_type_id": "customer",
    "insider": insiderEntity,
    "kyc_questionnaire": [
      {
        "order": 1,
        "question_type": "build_in",
        "question_code": "preferred_branch",
        "answer": objQustion.preferred_branch.answer,
        "details": objQustion.preferred_branch.details
      },
      {
        "order": 2,
        "question_type": "build_in",
        "question_code": "job_title",
        "answer": objQustion.job_title.answer,
      },
      {
        "order": 3,
        "question_type": "build_in",
        "question_code": "business_type",
        "answer": objQustion.business_type.answer,
      }, {
        "order": 4,
        "question_type": "build_in",
        "question_code": "industry_branch",
        "answer": objQustion.industry_branch.answer,
      }, {
        "order": 5,
        "question_type": "build_in",
        "question_code": "service_product_involved",
        "answer": objQustion.service_product_involved.answer,
      },
      {
        "order": 6,
        "question_type": "build_in",
        "question_code": "for_other_beneficiary",
        "answer": objQustion.for_other_beneficiary.answer,
        "details": objQustion.for_other_beneficiary.details
      },
      {
        "order": 7,
        "question_type": "build_in",
        "question_code": "pep_il",
        "answer": objQustion.pep_il.answer,
        "details": objQustion.pep_il.details
      },
      {
        "order": 8,
        "question_type": "build_in",
        "question_code": "pep_abroad",
        "answer": objQustion.pep_abroad.answer,
        "details": objQustion.pep_abroad.details
      },
      {
        "order": 9,
        "question_type": "build_in",
        "question_code": "client_escort",
        "answer": objQustion.client_escort.answer,
        "details": objQustion.client_escort.details,
      },
      {
        "order": 10,
        "question_type": "build_in",
        "question_code": "requested_method",
        "answer": objQustion.requested_method.answer
      },
      {
        "order": 11,
        "question_type": "build_in",
        "question_code": "main_suppliers",
        "answer": objQustion.main_suppliers.answer
      },
      {
        "order": 12,
        "question_type": "build_in",
        "question_code": "main_customers",
        "answer": objQustion.main_customers.answer
      },
      {
        "order": 13,
        "question_type": "build_in",
        "question_code": "annual_turnover",
        "answer": Number(String(objQustion.annual_turnover.answer).replace(/\D/g, ''))
      },
      {
        "order": 14,
        "question_type": "build_in",
        "question_code": "owned_other_businesses",
        "answer": objQustion.owned_other_businesses.answer,
        "details": objQustion.owned_other_businesses.details
      },
      {
        "order": 15,
        "question_type": "build_in",
        "question_code": "association_business_member",
        "answer": objQustion.association_business_member.answer,
        "details": objQustion.association_business_member.details
      },
      {
        "order": 16,
        "question_type": "build_in",
        "question_code": "expected_activity",
        "answer": objQustion.expected_activity.answer
      },
      {
        "order": 17,
        "question_type": "build_in",
        "question_code": "expected_volume_activity",
        "answer": Number(String(objQustion.expected_volume_activity.answer).replace(/\D/g, ''))
      },
      {
        "order": 18,
        "question_type": "build_in",
        "question_code": "funds_source",
        "answer": objQustion.funds_source.answer
      },
      {
        "order": 19,
        "question_type": "build_in",
        "question_code": "involved_in_business",
        "answer": Number(String(objQustion.involved_in_business.answer).replace(/\D/g, '')),
        "details": objQustion.involved_in_business.details
      },
      {
        "order": 20,
        "question_type": "build_in",
        "question_code": "bank_refuse",
        "answer": objQustion.bank_refuse.answer,
        "details": objQustion.bank_refuse.details
      },
      {
        "order": 21,
        "question_type": "build_in",
        "question_code": "business_activity",
        "answer": Number(String(objQustion.business_activity.answer).replace(/\D/g, '')),
      },
      {
        "order": 22,
        "question_type": "build_in",
        "question_code": "credit_refund_source",
        "answer": objQustion?.credit_refund_source.answer,
      },
    ]
  }
  // var newObj = Object.assign({}, questions?.kyc_questionnaire, customerQ);
  customerQ.length && customerQ?.forEach((q: any) => {
    questions.kyc_questionnaire.push(q)
  });
  const body = JSON.stringify(questions);
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };

  await axios.post(basicUrl + '/entity', body, config)
    .then(async res => {
      try {
        if (res.status === 200) {
          if (res.data["err_code"] === 0) {
            setShowDialogSave(true)
            setreadOnly(true)
            setshowSpiner(false)
            dispatch({
              type: READ_CUSTOMER,
              payload: res.data.data
            })
            // return true;
          }
          else {
            setshowSpiner(false)
          }
        }
        else {
          setshowSpiner(false)
        }
      }
      catch (err) {
        setshowSpiner(false)
        console.warn('error in update customer', err)
      }
    })
    .catch(err => {
      console.log("error", err.message);
      // return false;
    })
}


export const saveGetService = (getService: any, userCredentials: IUserCredentials | null, idEntity: string, setShowDialogSave?: any, setshowSpiner?: any) => async (dispatch: Function) => {
  const save = {
    "attribute_request_method": "update",
    "attribute": "service_receiver_statement",
    "return_entity": true,
    "id_initiator": userCredentials && userCredentials["idInitiator"],
    "id_branch": userCredentials && userCredentials["idBranch"],
    "id_client": userCredentials && userCredentials["idClient"],
    "id_entity": idEntity,
    "id_insider": getService?.id_insider,
    "entity_type_id": "customer",
    "is_beneficiary": getService?.isBeneficiary,
    "is_controlling_interest_holder": getService?.isControllingHolder,
    "is_unknown_beneficiary": getService?.is_unknown_beneficiary,
    "is_unknown_beneficiary_details": getService?.is_unknown_beneficiary_details
  }

  const body = JSON.stringify(save);
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };

  axios.post(basicUrl + '/entity', body, config)
    .then(async (res) => {
      try {
        if (res.status === 200) {
          if (res.data["err_code"] === 0) {
            setShowDialogSave(true)
            setshowSpiner && setshowSpiner(false)
            dispatch({
              type: READ_CUSTOMER,
              payload: res.data.data
            })
          }
          else {
            setshowSpiner && setshowSpiner(false)
          }
        }
      }
      catch (err) {
        console.warn('error in update customer', err)
        await setshowSpiner && setshowSpiner(false)
      }
    })
}

