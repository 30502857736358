import { useEffect } from 'react';
import { CustomTextField } from '../../../../shared/components/TextField';
import CurrencyInput from 'react-currency-input-field';
import { calcChequeDetails } from '../../../../shared/components/CustomHooks/GlobalFunctions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


export default function DiscountDetails(props: any) {

  const { actions, setActions, rowNumber, tableType, setcheckDeatils, checkDeatils, searchCustomer } = props
  const index = actions[tableType]?.findIndex((item: any) => Number(item.row_number) === Number(rowNumber))

  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)

  useEffect(() => {
    if (searchCustomer || actions) {
      initializingVariables();
    }

  }, [searchCustomer, actions])


  const initializingVariables = () => {
    let details: any = checkDeatils
    let arr: any = []
    arr = [...actions.financial_assets_in]
    arr[index].financial_asset_details = checkDeatils
    let temp2 = calcChequeDetails(arr[index])
    details = temp2.financial_asset_details;
    setcheckDeatils((checkDeatils: any) => ({ ...checkDeatils }))
  }

  useEffect(() => {
    initializingVariables()
  }, [checkDeatils.cheque_date])

  const changeCheckDetailsDetails = async (key: string, value: any) => {

    let details: any = checkDeatils
    details[key] = value
    setcheckDeatils((checkDeatils: any) => ({ ...checkDeatils, [key]: value }))
    let arr: any = [...actions.financial_assets_in]
    arr[index]["financial_asset_details"] = details
    setActions((actions: any) => ({ ...actions, [tableType]: arr }))
    initializingVariables()
  }

  const changeActionDetails = async (key: string, value: any) => {
    let arr: any = [...actions.financial_assets_in]
    arr[index][key] = value
    arr[index]["amount_ils"] = value * arr[index]["amount"]
    setActions((actions: any) => ({ ...actions, [tableType]: arr }))
    initializingVariables()
  }

  return (
    <div className={`warpper-fields-right-${dir}`} style={{ width: "58%", height: '100%' }} >
      <p className="title-text" >{t('discountDetails')} </p>
      <hr className="hr"></hr>
      <div className="wrapper-collateral">
        <div className={`warpper-fields-right-${dir}`}>
          <CustomTextField value={actions[tableType][index]?.exchange_rate_ils} type="text" label={t("exchangeRates")} onChange={changeActionDetails} id={'exchange_rate_ils'} />
        </div>
        <div className={`warpper-fields-left-${dir}`}>
          <CustomTextField suffix='&#8362;' value={String(actions[tableType][index]?.amount_ils)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} readOnly={true} label={t('chequeAmount')} onChange={changeActionDetails} id={'amount_ils'} />
        </div>
      </div>
      <div className="wrapper-collateral">
        <div className={`warpper-fields-right-${dir}`}>
          <CustomTextField type="text" value={checkDeatils?.cheque_days} readOnly={true} label={t('maturityDays')} onChange={""} id={'cheque_days'} />
        </div>
        <div className={`div-discount-details-${dir}`}> + </div>
        <div className={`warpper-fields-right-${dir}`}>
          <CustomTextField value={checkDeatils?.cheque_value_days} label={t('valueDays')} onChange={changeCheckDetailsDetails} id={'cheque_value_days'} />
        </div>
        <div className={`div-discount-details-${dir}`}> = </div>
        <div className={`warpper-fields-left-${dir}`}>
          <CustomTextField type="text" value={Number(checkDeatils?.cheque_value_days) + Number(checkDeatils?.cheque_days)} readOnly={true} label={t("totalDays")} onChange={""} id={'setTotalDay'} />
        </div>
      </div>
      <div className="wrapper-collateral" style={{ gap: "6px" }}>
        <div>
          <label style={{ fontWeight: 600, padding: "5px 0px" }}>{t('insert')}</label>
          <div className='ddd' style={{ display: "flex" }}>
            <p className={`prefix-currency-${dir}`}>%</p>
            <CurrencyInput
              className={`prefix-input prefix-currency-padding-${dir}`}
              id='cashing_intrest'
              name='cashing_intrest'
              placeholder="0.00"
              value={checkDeatils?.cashing_intrest}
              style={{ width: "100%", height: '32px', backgroundColor: "#FAF9F8", borderWidth: "1px", borderStyle: "solid", outlineColor: "rgb(0, 120, 212)" }}
              decimalsLimit={2}
              onValueChange={(value: any, name) => { changeCheckDetailsDetails(name || "", value) }}
            />
          </div>
        </div>
        <CustomTextField suffix='&#8362;' value={Number(checkDeatils?.cashing_amount).toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} readOnly={true} label={t('interestDiscount')} onChange={changeCheckDetailsDetails} id={'cashing_amount'} />
        <CustomTextField value={Number(actions[tableType][index]?.amount_ils) && Number(checkDeatils?.cheque_expenses) ? ((Number(checkDeatils?.cheque_expenses) / ((Number(actions[tableType][index]?.amount_ils) - Number(checkDeatils?.cheque_expenses)))) * 100).toFixed(2) : 0}
          readOnly={true} prefix="%" label={t('shortActualCost')} onChange={""} id={''} />
      </div>

      <div className="wrapper-collateral">
        <div className={`warpper-fields-right-${dir}`} >
          <label style={{ fontWeight: 600, marginTop: "6px", marginBottom: "6px" }}>{t('AdditionalAllowedExpenses')}</label>
          <div className='ddd' style={{ display: "flex" }}>
            <CurrencyInput
              className={`prefix-input padding-currency-${dir}`}
              id='allowed_expenses'
              name='allowed_expenses'
              placeholder="0.00"
              value={checkDeatils?.allowed_expenses}
              style={{
                width: "100%", height: '32px',
                backgroundColor: "#FAF9F8", borderWidth: "1px",
                borderStyle: "solid", outlineColor: "rgb(0, 120, 212)",
                borderLeft: "none"
              }}
              decimalsLimit={2}
              onValueChange={(value, name) => changeCheckDetailsDetails(name || "", value)}
            />
            <label className='suffix-currency'>&#8362;</label>
          </div>
        </div>
        <div className={`warpper-fields-left-${dir}`}>
          <label style={{ fontWeight: 600, marginTop: "6px", marginBottom: "6px" }}>{t('allowableExpensesIncurred')}</label>
          <div className='ddd' style={{ display: "flex" }}>
            <CurrencyInput
              className={`prefix-input padding-currency-${dir}`}
              id='allowed_actual_expenses'
              name='allowed_actual_expenses'
              placeholder="0.00"
              style={{
                width: "100%", height: '32px',
                backgroundColor: "#FAF9F8", borderWidth: "1px",
                borderStyle: "solid", outlineColor: "rgb(0, 120, 212)",
                borderLeft: "none"
              }}
              value={checkDeatils?.allowed_actual_expenses}
              decimalsLimit={2}
              onValueChange={(value, name) => changeCheckDetailsDetails(name || "", value)}
            />
            <label className='suffix-currency'>&#8362;</label>
          </div>
        </div>
      </div>
      <CustomTextField suffix='&#8362;' value={(Number(checkDeatils?.cheque_expenses)).toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} label={t("totalCashingExpenses")} onChange={""} readOnly={true} id={''} />

      {/* <CustomTextField suffix='&#8362;' value={(Number(actions[tableType][index]?.fee)).toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} label={t("totalCashingExpenses")} onChange={""} readOnly={true} id={''} /> */}
    </div>)
}