import axios from "axios";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { READ_CUSTOMER } from '../actions/types';
import { basicUrl } from "../../shared/config";

let currUserCredentials: IUserCredentials | null = null

// export const createCustomerCollateral = (collateral: any, setUpdateCollateral: any, userCredentials: IUserCredentials, idEntity: string, setShowDialogCreate: any, setshowSpiner?: any) => async (dispatch: Function) => {
export const createCustomerCollateral = (collateral: any, setUpdateCollateral: any, userCredentials: IUserCredentials, idEntity: string, 
  setServerMessageError?: any, setMessageError?: any, setTitleMessageError?: any, setshowSpiner?: any,t?:any) => async (dispatch: Function) => {

  collateral.faceValue = collateral.faceValue.replace(/\D/g, '');
  collateral.value = collateral.value.replace(/\D/g, '')
  currUserCredentials = userCredentials

  const createCollateral = {
    "id_initiator": currUserCredentials["idInitiator"],
    "id_entity": idEntity,
    "id_branch": currUserCredentials["idBranch"],
    "id_client": currUserCredentials["idClient"],
    "type_id": collateral.typeId,
    "collateral_name": collateral.name,
    "collateral_ID_number": collateral.number,
    "face_value": collateral.faceValue,
    "valuation": collateral.value,
    "status_id": collateral.statusId,
    "exp_date": collateral.expirationDate,
    "details": collateral.note,
    "return_entity": 1,
    "attribute_request_method": "create",
    "attribute": "collateral",
    "entity_type_id": "customer"
  }

  const body = JSON.stringify(createCollateral);
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };
  axios.post(basicUrl + '/entity', body, config)
    .then(res => {
      try {
        if (res.status === 200) {
          if (res.data["err_code"] === 0) {
            let arrColateral = res.data.data.collateral
            let higtCollateralId = Math.max.apply(Math, arrColateral.map(function (item: any) { return item.collateral_id; }))
            let index = arrColateral.findIndex((item: any) => Number(item.collateral_id) === Number(higtCollateralId))
            setUpdateCollateral({ ...collateral, "key": arrColateral[index].collateral_id })
            // setShowDialogCreate(true)
            setServerMessageError && setServerMessageError(true)
            setMessageError && setMessageError(t("savedSuccessfully"))
            setTitleMessageError && setTitleMessageError(t("save"))
            setshowSpiner(false)
            dispatch({
              type: READ_CUSTOMER,
              payload: res.data.data
            })
          }
          else {
            setTitleMessageError&& setTitleMessageError(res.data?.ui_message?.ui_message_header)
            setMessageError&&setMessageError(res.data?.ui_message?.ui_message)
            setServerMessageError&&setServerMessageError(true)
            console.log(res.data?.err_description)
            setshowSpiner(false)
          }
        }
      }
      catch (err) {
        setServerMessageError && setServerMessageError(true)
        setMessageError && setMessageError(t("errorPerformingAction"))
        setTitleMessageError && setTitleMessageError(t("error"))
        console.log('error in create colletrall')
      }
    })
}


export const updateCustomerCollateral = (collateral: any, userCredentials: IUserCredentials, idEntity: string, setShowDialogUpdate: any, setshowSpiner?: any) => async (dispatch: Function) => {


  collateral.faceValue = String(collateral.faceValue).replace(/\D/g, '');
  collateral.value = String(collateral.value).replace(/\D/g, '')
  //d
  // let dateMomentExp_date = moment(collateral.expirationDate).format("YYYY-MM-DD")
  currUserCredentials = userCredentials
  const updateCollateral = {
    "id_initiator": currUserCredentials["idInitiator"],
    "id_entity": idEntity,
    "id_branch": currUserCredentials["idBranch"],
    "id_client": currUserCredentials["idClient"],
    "collateral_id": collateral.key,
    "collateral_name": collateral.name,
    "collateral_ID_number": collateral.number,
    "type_id": collateral.typeId,
    "status_id": collateral.statusId,
    "face_value": collateral.faceValue,
    "valuation": collateral.value,
    //d
    // "exp_date": dateMomentExp_date,
    "exp_date": collateral.expirationDate,
    "details": collateral.note,
    "collateral_media": collateral.collateralMedia,
    "return_entity": 1,
    "attribute_request_method": "update",
    "attribute": "collateral",
    "entity_type_id": "customer"
  }
  const body = JSON.stringify(updateCollateral);
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };

  axios.post(basicUrl + '/entity', body, config)
    .then(res => {
      try {
        if (res.status === 200) {
          if (res.data["err_code"] === 0) {
            dispatch({
              type: READ_CUSTOMER,
              payload: res.data.data
            })
            setshowSpiner(false)
            setShowDialogUpdate(true)
          }
        }
        else {
          setshowSpiner(false)
        }
      }
      catch (err) {
        setshowSpiner(false)
        console.log('error in update colletrall',)
      }
    })
}


export const deleteCustomerColatteral = (userCredentials: IUserCredentials, collateralId: any, idEntity: string, setshowSpiner?: any) => async (dispatch: Function) => {
  currUserCredentials = userCredentials
  const deleteCollateral = {
    "id_initiator": currUserCredentials["idInitiator"],
    "id_entity": idEntity,
    "id_branch": currUserCredentials["idBranch"],
    "id_client": currUserCredentials["idClient"],
    "collateral_id": collateralId,
    "attribute_request_method": "delete",
    "attribute": "collateral",
    "entity_type_id": "customer"
  }
  const body = JSON.stringify(deleteCollateral);
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };
  axios.post(basicUrl + '/entity', body, config)
    .then(res => {
      try {
        if (res.status === 200) {
          if (res.data["err_code"] === 0) {
            setshowSpiner(false)
            const readCustomer = {
              "entity_request_method": "read",
              "id_initiator": userCredentials.idInitiator,
              "id_client": userCredentials.idClient,
              "id_entity": idEntity
            }
            const body = JSON.stringify(readCustomer);
            let token = sessionStorage.getItem("token")
            console.log(token,"token-------");
            const config = {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

              }
            };

            axios.post(basicUrl + '/entity', body, config).then(res => {
              setshowSpiner(false)
              dispatch({
                type: READ_CUSTOMER,
                payload: res.data.data
              })
            })
          }
        }
        else {
          setshowSpiner(false)
        }
      }
      catch (err) {
        console.log('error in delete colletrall')
      }
    })
}


export const updateCreditFacilities = (creditFacilities: any, userCredentials: IUserCredentials, idEntity: string, setShowDialogSave: any, setreadOnly: any, setshowSpiner: any) => async (dispatch: Function) => {
  creditFacilities.cheques_3party = String(creditFacilities.cheques_3party).replace(/\D/g, '');
  creditFacilities.cheques_self = String(creditFacilities.cheques_self).replace(/\D/g, '');
  creditFacilities.loans = String(creditFacilities.loans).replace(/\D/g, '');
  creditFacilities.factoring = String(creditFacilities.factoring).replace(/\D/g, '');
  currUserCredentials = userCredentials
  const updateCreditFacilities = {
    "id_initiator": currUserCredentials["idInitiator"],
    "id_client": currUserCredentials["idClient"],
    "id_branch": currUserCredentials["idBranch"],
    "id_entity": idEntity,
    "return_entity": "1",
    "attribute_request_method": "update",
    "attribute": "credit_line",
    "entity_type_id": "customer",
    "cheques_3party": creditFacilities.cheques_3party,
    "cheques_self": creditFacilities.cheques_self,
    "loans": creditFacilities.loans,
    "factoring": creditFacilities.factoring
  }

  const body = JSON.stringify(updateCreditFacilities);
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };

  axios.post(basicUrl + '/entity', body, config)
    .then(res => {
      try {
        if (res.status === 200) {
          if (res.data["err_code"] === 0) {
            setShowDialogSave(true)
            setreadOnly(true)
            setshowSpiner(false)
            dispatch({
              type: READ_CUSTOMER,
              payload: res.data.data
            })
          }

        }
        else {
          setshowSpiner(false)
        }
      }
      catch (err) {
        console.log('error in updateCreditFacilities')

      }
    })
}



export const createCollateralMedia = (collateralId: string, collateralMedia: any, userCredentials: IUserCredentials, idEntity: string, type?: any) => async (dispatch: Function) => {
  currUserCredentials = userCredentials
  const createCollateralMedia = {
    "id_entity": idEntity,
    "id_initiator": currUserCredentials["idInitiator"],
    "id_branch": currUserCredentials["idBranch"],
    "id_client": currUserCredentials["idClient"],
    "collateral_id": collateralId,
    "status_id": collateralMedia?.media_status_id,
    "id_media": collateralMedia?.id_media,
    "details": collateralMedia?.note,
    "type_id": Number(collateralMedia?.media_type_id),
    "media_name": collateralMedia?.media_name,
    "expiration_date": collateralMedia?.media_exp_date,
    "return_entity": 1,
    "attribute_request_method": "create",
    "attribute": "collateral_media",
    "entity_type_id": type ? "insider" : "customer"
  }
  const body = JSON.stringify(createCollateralMedia);
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };

  axios.post(basicUrl + '/entity', body, config)
    .then(res => {
      try {
        if (res.status === 200) {
          if (res.data["err_code"] === 0) {
            dispatch({
              type: READ_CUSTOMER,
              payload: res.data.data
            })
          }
        }

      }
      catch (err) {
        console.log('error in createCollateralMedia',)
      }
    })
}





//  (collateralId: string, collateralMedia: any, userCredentials: IUserCredentials, idEntity: string) => async (dispatch: Function) => {
//check in dispatch this in dialog if it ok
export const updateCollateralMedia = (collateralId: string, collateralMedia: any, userCredentials: IUserCredentials, idEntity:
  string, setshowSpiner?: any, setServerMessageError?: any, setMessageError?: any,t?:any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const updateCollateralMedia = {
      "id_entity": idEntity,
      "id_initiator": currUserCredentials["idInitiator"],
      "id_branch": currUserCredentials["idBranch"],
      "id_client": currUserCredentials["idClient"],
      "collateral_id": collateralId,
      "id_media": collateralMedia?.id_media,
      "details": collateralMedia?.note,
      "status_id": collateralMedia?.media_status_id,
      "type_id": Number(collateralMedia?.media_type_id),
      "media_name": collateralMedia?.media_name,
      "expiration_date": collateralMedia?.media_exp_date,
      "media_id": collateralMedia?.media_id,
      "attribute_request_method": "update",
      "attribute": "collateral_media",
      "entity_type_id": "customer"
    }

    const body = JSON.stringify(updateCollateralMedia);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`

      }
    };

    axios.post(basicUrl + '/entity', body, config)
      .then(res => {
        try {
          if (res.status === 200) {
            if (res.data["err_code"] === 0) {
              const readCustomer = {
                "entity_request_method": "read",
                "id_initiator": userCredentials.idInitiator,
                "id_client": userCredentials.idClient,
                "id_branch": userCredentials.idBranch,
                "id_entity": idEntity
              }
              const body = JSON.stringify(readCustomer);
              let token = sessionStorage.getItem("token")
              console.log(token,"token-------");
              const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`

                }
              };
              axios.post(basicUrl + '/entity', body, config).then(res => {
                dispatch({
                  type: READ_CUSTOMER,
                  payload: res.data.data
                })
              })
              setMessageError && setMessageError(t("updateSuccessful"))
              setServerMessageError && setServerMessageError(true)
              setshowSpiner && setshowSpiner(false)
            }
            else {
              setshowSpiner && setshowSpiner(false)
              setMessageError && setMessageError(t("errorUpdatingData"))
              setServerMessageError && setServerMessageError(true)
            }
          }
        }
        catch (err) {
          console.log('error in updateCollateralMedia',)
        }
      })
  }

export const deleteCollateralMedia = (collateralId: string, collateralMedia: any, userCredentials: IUserCredentials, idEntity: string) => async (dispatch: Function) => {
  currUserCredentials = userCredentials
  const deleteCollateralMedia = {
    "id_entity": idEntity,
    "id_initiator": currUserCredentials["idInitiator"],
    "id_branch": currUserCredentials["idBranch"],
    "id_client": currUserCredentials["idClient"],
    "collateral_id": collateralId,
    "media_id": collateralMedia?.media_id,
    "attribute_request_method": "delete",
    "attribute": "collateral_media",
    "entity_type_id": "customer"
  }



  const body = JSON.stringify(deleteCollateralMedia);
  let token = sessionStorage.getItem("token")
  console.log(token,"token-------");
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`

    }
  };

  await axios.post(basicUrl + '/entity', body, config)
    .then(res => {
      try {
        if (res.status === 200) {
          if (res.data["err_code"] === 0) {
            const readCustomer = {
              "entity_request_method": "read",
              "id_initiator": userCredentials.idInitiator,
              "id_client": userCredentials.idClient,
              "id_entity": idEntity
            }
            const body = JSON.stringify(readCustomer);
            let token = sessionStorage.getItem("token")
            console.log(token,"token-------");
            const config = {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

              }
            };

            axios.post(basicUrl + '/entity', body, config).then(res => {
              dispatch({
                type: READ_CUSTOMER,
                payload: res.data.data
              })
            })

          }
        }
      }
      catch (err) {
        console.log('error in deleteCollateralMedia',)
      }
    })
}
