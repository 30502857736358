import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import HeaderUtils from '../../../../shared/components/headerUtils/HeaderUtils';
import Subtitle from '../../../../shared/components/Subtitle';
import AGtable from './AGtable';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../../../../shared/components/CustomHooks/GlobalFunctions';

export default function CheckManagement() {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    
    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
        ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={t("cashings")} subTitle={"false"} editBtn={false} uploadBtn={false} disabledSave={true} showSelectConvertions={true} />
            <br></br><br></br>
            <Subtitle title={t("checkManagement")} />
            <div className={`content-div-${dir}`}>
                <AGtable />
            </div>
        </div>
    )
}
