import { useEffect, useState } from 'react'
import { CustomDropdown } from "../../shared/components/Option";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils'
import { UpdateDefaultPlan } from '../../store/actions/pricePlanAction'
import { readCustomerId } from '../../store/actions/customerActioin'
import { IUserCredentials } from '../customerDetails/CustomerDetails'
import "./ClientPlan.css"
import Subtitle from '../../shared/components/Subtitle';
import { CustomTextField } from '../../shared/components/TextField';

const ClientPlan = (props: any) => {
    const { mode, setShowDialogSave } = props
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const programProperties = useSelector(({ customerReducer }: any) => customerReducer?.dataCustomer?.price_program)
    const [updateItems, setupdateItems] = useState([{}])
    const [readOnly, setreadOnly] = useState(true)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const customerReducer = useSelector(({ customerReducer }: any) => customerReducer)
    const [idEntity, setidEntity] = useState("")
    const [selectedProgram, setselectedProgram] = useState('')
    
    const saveProgram = async (statusProgram: boolean) => {
        alert("success")
        if (userCredentials !== null) {
            statusProgram ?
                await UpdateDefaultPlan(userCredentials, idEntity, selectedProgram, updateItems, setShowDialogSave) :
                await UpdateDefaultPlan(userCredentials, idEntity, undefined, updateItems, setShowDialogSave)
            dispatch(readCustomerId(idEntity, userCredentials, "", "", "", false));
        }
    }

    useEffect(() => {
        if (authReducer)
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
    }, [authReducer])

    useEffect(() => {
        setidEntity(customerReducer.dataCustomer?.id_entity)
    }, [customerReducer])


    useEffect(() => {
        let arr: object[] = []
        programProperties?.price_program_properties?.forEach((element: any, index: any) => {
            if (element.calculation_type === "Constant" ||element.calculation_type === "const"|| element.calculation_type === "קבוע")
                arr.push({
                    key: index, description: element.description, calculation_type: t(element.calculation_type),
                    value: element.value, type: element.type, fee_name: element.fee_name, order: element.order
                })
            else
                arr.push({
                    key: index, description: element.description, calculation_type: t(element.calculation_type)
                    , percent: element.percent, value_min: element.value_min, value_max: element.value_max, value: element.percent
                    , fee: element.fee, fee_name: element.fee_name, order: element.order
                })
        });
        setupdateItems(arr)
        setselectedProgram(programProperties?.base_template_id)

        // eslint-disable-next-line
    }, [programProperties]);


    return (
        <>
            {mode !== "update" ? <> <HeaderUtils title={"customers"} editBtn={true} uploadBtn={true} 
            saveFunc={() => saveProgram(false)}  editFunc={() => setreadOnly(false)} />
            </> : ""}
            <br></br><br></br><br></br><br></br>
            <div className={`div-client-plan-${dir}`}>
                <div className="attechedCl" >
                    <Subtitle title={"מחירון ללקוח"} />
                    <div style={{ width: "28%" }}>
                        <CustomDropdown otherInputId={''} label={""} hasOtherValue={true} options={[]} readOnly={readOnly} onChange={''} selectedKey={''} id={'typePlan'} othertextInput={t('')} /></div>
                </div>
                <hr className="hr" style={{ width: "1000px" }} ></hr></div>
            <div className={`wrap-dialogPlan${dir}`}>
                <div className="wrapper-collateralPlan">
                    <div className={`warpper-fields-rightPl-${dir}`}>
                        <div style={{ width: "100%" }}>
                            <div className="titleC">
                                <p className="title-text boldC" style={{ width: "33%" }}>{t("type_action")}</p>
                                <p className="title-text boldC" style={{ width: "25%" }}>{t("calculationType")}</p>
                                <p className="title-text boldC" style={{ width: "60%" }}>{t("details")}</p></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", height: "2px", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{t("new_account_fee")}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("const")}</p></div>
                                <div style={{ width: "60%" }}>
                                    <div className="inside">
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} /></div></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{t("israeli_cheque_deposit_fee")}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("const")}</p></div>
                                <div style={{ width: "60%" }}><div className="inside">
                                    <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} /></div></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{"עמלת הפקדת שיק מט'ח "}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("const")}</p></div>
                                <div style={{ width: "60%" }}><div className="inside">
                                    <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} /></div></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{t("cheque_refund_fee")}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("const")}</p></div>
                                <div style={{ width: "60%" }}><div className="inside">
                                    <CustomTextField label={" "} value={'454513900364'} onChange={' '} id={"detailsBranch"} /></div></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{t("repeat_deposit_fee")}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("const")}</p></div>
                                <div style={{ width: "60%" }}><div className="inside">
                                    <CustomTextField label={" "} value={'454513900364'} onChange={' '} id={"detailsBranch"} /></div></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{t("wire_rtgs_fee")}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("const")}</p></div>
                                <div style={{ width: "60%" }}><div className="inside">
                                    <CustomTextField label={" "} value={'454513900364'} onChange={' '} id={"detailsBranch"} /></div></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{t("wire_fee")}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("const")}</p></div>
                                <div style={{ width: "60%" }}><div className="inside">
                                    <CustomTextField label={" "} value={'454513900364'} onChange={' '} id={"detailsBranch"} /></div></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{t("wire_fee")}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("percent")}</p></div>
                                <div className="fields">
                                    <div className="field" >
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} />
                                    </div>  <p className="boldF">{t("minimum")}</p>
                                    <div className="field" >
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} /></div>
                                    <p className="boldF">{" "}</p>
                                    <div className="field" >
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} /></div>
                                </div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{"ריבית חודשית ניכיון שיק מזומן "}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("percent")}</p></div>
                                <div className="fields">
                                    <div className="field" >
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} />
                                    </div>  <p className="boldF">{t("minimum")}</p>
                                    <div className="field" >
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} /></div>
                                    <p className="boldF">{t("maximum")}</p>
                                    <div className="field" >
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} /></div>
                                </div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{t("cheque_cash_fee")}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("percent")}</p></div>
                                <div className="fields">
                                    <div className="field" >
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} />
                                    </div>  <p className="boldF">{t("minimum")}</p>
                                    <div className="field" >
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} /></div>
                                    <p className="boldF">{t("maximum")}</p>
                                    <div className="field" >
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} /></div>
                                </div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{"ריבית חודשית ניכיון שיק דחוי "}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("percent")}</p></div>
                                <div className="fields">
                                    <div className={`field field-client-plan-${dir}`} >
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} />
                                    </div>  <p className="boldF">{t("minimum")}</p>
                                    <div className="field" >
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} /></div>
                                    <p className="boldF">{t("maximum")}</p>
                                    <div className="field" >
                                        <CustomTextField label={" "} value={'0'} onChange={' '} id={"detailsBranch"} /></div>
                                </div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{t("cheque_collection_fee")+"88"}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("const")}</p></div>
                                <div style={{ width: "60%" }}><div className="inside">
                                    <CustomTextField label={" "} value={'454513900364'} onChange={' '} id={"detailsBranch"} /></div></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className="inlineB">
                                <p className="boldPP">{t("markup_conversion_fee")}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("const")}</p></div>
                                <div style={{ width: "60%" }}><div className="inside">
                                    <CustomTextField label={" "} value={'454513900364'} onChange={' '} id={"detailsBranch"} /></div></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>
                        <div style={{ width: "100%" }}>
                            <div className={`inlineBP-${dir}`}>
                                <p className="boldPP">{"ריבית פיגורים"}</p>
                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                    <p className="boldPP">{t("percent")}</p></div>
                                <div style={{ width: "60%" }}><div className="inside">
                                    <CustomTextField label={" "} value={'2.5%'} onChange={' '} id={"detailsBranch"} /></div></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>

                    </div>


                    {/* <div className={`warpper-fields-leftP-${dir}`}>
                <div className="titleC">
                    <p className="title-text boldC">{"תבנית בסיס"}</p></div>
                    <hr className="hr" style={{ width: "80%",height:"2px" }} ></hr>
                    <div style={{ width: "80%" }} >
                        <CustomDropdown otherInputId={'idTypeOther'} hasOtherValue={true} options={baseProgramOption} label={''} onChange={(id: any, val: any) => setselectedProgram(val)} selectedKey={baseTemplateId} id={'idTypeId'} othertextInput={t('typeIdentityNumberOther')} /><br></br>
                        <CustomToggle  onText={t('linkedToBaseTemplate')} onChange={() => setdefaultPlan(true)} id={'isLocked'} defaultChecked={defaultPlan} offText={t('linkedToBaseTemplate')} />
                        <PrimaryButton onClick={() => saveProgram(true)} iconProps={Icons.addFriend} ariaDescription="create" className='buttonPlan' allowDisabledFocus checked={false} name="create" text={t('updateProgramFromBaseTemplate')} />
                    </div>
                </div> */}
                </div>

            </div>

            {/* <div className="marginRight">
                <TitleText title={t("customerPlan")}></TitleText></div>
            <div className="wrap-table">
                <CustemTable changeRow={changeRow} readOnly={readOnly} tableName={"plan"} columns={columns} allItems={updateItems} renderItemColumn addCustem={true} isFooter={true} /></div>
            {mode !== "update" ?
                <div className="marginRight">
                    <p>{t("baseTemplate")}</p>
                    <hr className="hr text-width" />
                    <div style={{ width: "40%" }}>
                        <CustomDropdown otherInputId={'idTypeOther'} hasOtherValue={true} options={baseProgramOption} label={''} onChange={(id: any, val: any) => setselectedProgram(val)} selectedKey={baseTemplateId} id={'idTypeId'} othertextInput={t('typeIdentityNumberOther')} />
                        <CustomToggle onText={t('linkedToBaseTemplate')} onChange={() => setdefaultPlan(true)} id={'isLocked'} defaultChecked={defaultPlan} offText={t('linkedToBaseTemplate')} />
                        <PrimaryButton onClick={() => saveProgram(true)} ariaDescription="create" className='button' allowDisabledFocus checked={false} name="create" text={t('updateProgramFromBaseTemplate')} />
                    </div>
                </div> : ''} */}
        </>
    )
}

export default ClientPlan
