import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import { Label } from '@fluentui/react/lib/Label';
import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import { cloneDeep, isEqual } from 'lodash';

import './branchSettings.scss'
import { convertArrToDropdropdown, } from './customerDetails/CustomerDetails'
import { settingUsersUpdate } from '../store/actions/settingsAction'
import HeaderUtils from '../shared/components/headerUtils/HeaderUtils'
import Subtitle from "../shared/components/Subtitle";
import { CustomTextField } from '../shared/components/TextField';
import { DialogMessages } from '../shared/components/dialog/DialogMessages';
import { IUserCredentials } from './customerDetails/CustomerDetails';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../shared/components/CustomHooks/GlobalFunctions';

const BranchUsersSettings = () => {
    const [t] = useTranslation();
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const [readOnly, setreadOnly] = useState(true)
    const settings: any = useSelector(({ authReducer }: any) => authReducer?.data?.user?.setting)
    const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [showDialogSave, setShowDialogSave] = useState(false)
    const [showDialogFailed, setShowDialogFailed] = useState(false)
    const [idEntity, setIdEntity] = useState("")
    const [showSpiner, setshowSpiner] = useState(false)
    const [showNoteCurrencies, setshowNoteCurrencies] = useState(false)
    const [updateSetting, setUpdateSetting] = useState(settings)
    const [selectedKeys, setSelectedKeys] = React.useState<any[]>(updateSetting?.currency_shortcuts);
    const [showTextRequiredFiles, setShowTextRequiredFiles] = useState(false)
    const [showRequiredFields, setShowRequiredFields] = useState(false)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [updateSettingBeforeChanges, setupdateSettingBeforeChanges] = useState(settings)
    const [enumsDropdown, setenumsDropdown] = useState<any>({ "currencyCode": [] })
    const dropdownStyles: Partial<IDropdownStyles> = {
        title: { backgroundColor: readOnly ? '#FAF9F8' : "white" },
        callout: { minWidth: "fit-content", maxwidth: "fit-content" },
    };


    useEffect(() => {
        if (enums) {
            let arr = {
                "currencyCode": convertArrToDropdropdown([]),
                "customersType": convertArrToDropdropdown([]),
                "popUpScanCheques": convertArrToDropdropdown([]),
            };
            for (let i = 0; i < enums.length; i++) {
                if (enums[i].general !== undefined) {
                    let s = enums[i].general;
                    for (let i = 0; i < s.length; i++) {
                        if (s[i].currency_code !== undefined)
                            arr.currencyCode = convertArrToDropdropdown(s[i].currency_code).filter(item => item.key !== "ILS")
                    }
                }
            }
            for (let i = 0; i < enums.length; i++) {
                if (enums[i].customer !== undefined) {
                    let customer = enums[i].customer;
                    for (let j = 0; j < customer.length; j++) {
                        if (customer[j].customer_type_id !== undefined)
                            arr.customersType = convertArrToDropdropdown(customer[j].customer_type_id)
                    }
                }
            }
            for (let i = 0; i < enums.length; i++) {
                if (enums[i].cheque_cashing !== undefined) {
                    let cashing = enums[i].cheque_cashing;
                    for (let j = 0; j < cashing.length; j++) {
                        if (cashing[j].cheque_scanning_popup !== undefined)
                            arr.popUpScanCheques = convertArrToDropdropdown(cashing[j].cheque_scanning_popup)
                    }
                }
            }
            setenumsDropdown(arr);
        }
    }, [enums])

    const spinnerStyles: Partial<ISpinnerStyles> = {
        root: {
            position: 'fixed',
            zIndex: '999999',
            fontWeight: 'bold',
        },
        circle: {
            color: '#FFB400 ',
            height: '100px',
            width: '100px',
        },
    }

    useEffect(() => {
        if (enumsDropdown?.customersType && !updateSetting?.cash_turnover) {
            let arrCashTurnover: any = []
            enumsDropdown?.customersType.map((item: any) => {
                arrCashTurnover.push({
                    "customer_type_id": item.key,
                    "customer_type_name": item.text,
                    "over_volume": "",
                })
            })
            setUpdateSetting({ ...updateSetting, "cash_turnover": arrCashTurnover })
        }

    }, [enumsDropdown])

    useEffect(() => {
        setUserCredentials({
            "idInitiator": authReducer.data.user.id_entity,
            "idClient": authReducer.data.client.id_entity,
            "idBranch": authReducer.data.branch.id_entity,
            "idClientData": authReducer.data.client.id_client
        })
        setIdEntity(authReducer.data.user.id_entity)
    }, [authReducer]);


    useEffect(() => {
        if (settings) {
            let copySettings = { ...settings }
            copySettings["expected_volume_activity"] = (Number(copySettings?.expected_volume_activity) * 100)
            setUpdateSetting(copySettings)
        }
    }, [settings])

    useEffect(() => {
        selectedKeys?.length > 0 && setUpdateSetting({ ...updateSetting, "currency_shortcuts": selectedKeys })
    }, [selectedKeys])

    const onChange = (e: any, option?: IDropdownOption<any>): void => {
        if (option) {
            if ((updateSetting?.currency_shortcuts?.length < 5 && option.selected) || !option.selected) {
                setSelectedKeys(option.selected ? [...selectedKeys, option.key as string] : selectedKeys.filter(key => key !== option.key))
                !option.selected && setshowNoteCurrencies(false)
            }
            else
                setshowNoteCurrencies(true)
        }
    };

    const onChangeScanningCheque = (e: any, option?: IDropdownOption<any>): void => {
        setUpdateSetting({ ...updateSetting, cheque_scanning_popup: option?.key })
    };

    const changeSettings = (key: string, value: any) => {
        if (key?.includes(",")) {
            let arrKeys = key.split(",")
            if (arrKeys[0] === "cash_turnover") {
                let updatedCashTurnover: any = updateSetting?.cash_turnover?.map((item: any) =>
                    item.customer_type_id === arrKeys[1] ? { ...item, over_volume: value } : item
                );
                setUpdateSetting({ ...updateSetting, "cash_turnover": updatedCashTurnover })
            }
            else {
                setUpdateSetting((prevState: any) => {
                    return {
                        ...prevState, [arrKeys[0]]: {
                            ...prevState[arrKeys[0]], [arrKeys[1]]: value
                        }
                    }
                });
            }
        }
        else {
            setUpdateSetting({ ...updateSetting, [key]: value })
        }
    }
    const checkRequiredFieldsFunc = () => {
        let fullCashTurnover = false
        updateSetting?.cash_turnover?.map((item: any) => {
            if (!item?.over_volume)
                fullCashTurnover = true

        })
        if (fullCashTurnover) {
            return false
        }
        if (updateSetting?.structuring?.amount_threshold && updateSetting?.structuring?.duration_days
            && updateSetting?.expected_volume_activity && updateSetting?.near_threshold?.amount_from &&
            updateSetting?.near_threshold?.amount_to && updateSetting?.cheque_scanning_popup) {
            return true
        }
        return false
    }

    const saveProgram = async (statusProgram: boolean) => {
        if (checkRequiredFieldsFunc()) {
            if (statusProgram && userCredentials !== null && !isEqual(updateSetting, settings)) {
                setshowSpiner(true)
                let copySettings = { ...updateSetting }
                copySettings.expected_volume_activity = (Number(copySettings?.expected_volume_activity) / 100)
                await dispatch(settingUsersUpdate(copySettings, userCredentials, idEntity, setShowDialogSave, setShowDialogFailed, setshowSpiner))
                setupdateSettingBeforeChanges(cloneDeep(updateSetting))
            }
            setreadOnly(true)
        }
        else {
            setShowTextRequiredFiles(true)
            setShowRequiredFields(true)
        }
    }
    const cancelEdit = async (statusProgram: boolean) => {
        setUpdateSetting(cloneDeep(updateSettingBeforeChanges))
        setreadOnly(true)
    }
    const getCurrency = () => {
        let arrCoins = updateSetting?.currency_shortcuts.map((c: any) => enumsDropdown.currencyCode?.find((item: any) => item.key === c)?.text);
        return arrCoins.join(' ,');
    }
    return (
        <div className={`${checkPermissionLevelOfPage(credentials, location) === "RW"
            ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={"settings"} subTitle={"false"} disabledSave={readOnly ? true : false} createBtn={false} saveFunc={() => saveProgram(true)}
                editFunc={() => setreadOnly(false)} cancelFunc={() => cancelEdit(true)} editBtn={readOnly ? true : false} deleteBtn={false} uploadBtn={readOnly ? false : true}
                createXl={false} createPrint={false} createPDF={false} />
            <br></br><br></br>
            {showDialogSave && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"save"} />}
            {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} setshowModalDelete={setShowDialogFailed} dialogType={"failed"} />}
            {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
            {showRequiredFields && <DialogMessages setansDelete={setShowRequiredFields} setshowModalDelete={setShowRequiredFields} dialogType={"requiredFields"} />}
            <Subtitle title={t('branchSettings')} />
            <div className={`content-div-${dir}`}>
                <div style={{ width: " 600px" }}>
                    <div className="titleC">
                        <p className="title-text boldC" style={{ width: "355px" }}>{t("name")}</p>
                        <p className="title-text boldC" style={{ width: "200px" }}>{t("details")}</p>
                    </div>
                </div>

                <hr className="hr" style={{ width: " 600px", height: "2px", marginBottom: '8px' }} ></hr>
                {showNoteCurrencies && <p className="text-feild-note">{t("select5TypesOfCurrency")}</p>}
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("currencyShortcuts")}</Label>
                        <div style={{ width: "200px" }}>
                            {readOnly ?
                                <CustomTextField readOnly={readOnly}
                                    value={getCurrency()}
                                    id={'currency_shortcuts'}
                                    onChange={""} />
                                : <Dropdown
                                    onMouseLeave={() => setshowNoteCurrencies(false)}
                                    placeholder={t("selectCurrencyTypes")}
                                    selectedKeys={updateSetting?.currency_shortcuts}
                                    multiSelect
                                    options={enumsDropdown.currencyCode}
                                    onChange={onChange}
                                    id={'currency_shortcuts'}
                                    styles={dropdownStyles}
                                />}

                        </div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div>
                    <Label className="boldBS" style={{ width: "355px" }} >{t("structuringDefault")}</Label>
                    <div style={{ width: " 600px" }}>
                        <div className={`inlineBS-${dir}`}>
                            <Label className="boldBS" style={{ width: "355px" }} required>{t("amount_threshold")}</Label>
                            <div style={{ width: "200px" }}>
                                <CustomTextField readOnly={readOnly} value={updateSetting?.structuring?.amount_threshold} 
                                onChange={changeSettings} id={"structuring,amount_threshold"} 
                                requiredField={showTextRequiredFiles && !updateSetting?.structuring?.amount_threshold ? true : false} /></div>
                        </div>
                    </div>
                    <div style={{ width: " 600px" }}>
                        <div className={`inlineBS-${dir}`}>
                            <Label className="boldBS" style={{ width: "355px" }} required>{t("duration_days")}</Label>
                            <div style={{ width: "200px" }}>
                                <CustomTextField readOnly={readOnly} value={updateSetting?.structuring?.duration_days}
                                 onChange={changeSettings} id={"structuring,duration_days"}
                                 requiredField={showTextRequiredFiles && !updateSetting?.structuring?.duration_days ? true : false}  /></div>
                        </div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>

                <div>
                    <Label className="boldBS" style={{ width: "355px" }}>{t("nearThresholdDefault")}</Label>
                    <div style={{ width: " 600px" }}>
                        <div className={`inlineBS-${dir}`}>
                            <Label className="boldBS" style={{ width: "355px" }} required>{t("from_amount")}</Label>
                            <div style={{ width: "200px" }}>
                                <CustomTextField readOnly={readOnly} value={updateSetting?.near_threshold?.amount_from}
                                 onChange={changeSettings} id={"near_threshold,amount_from"} 
                                 requiredField={showTextRequiredFiles && !updateSetting?.near_threshold?.amount_from ? true : false} /></div>
                        </div>
                    </div>
                    <div style={{ width: " 600px" }}>
                        <div className={`inlineBS-${dir}`}>
                            <Label className="boldBS" style={{ width: "355px" }} required>{t("to_amount")}</Label>
                            <div style={{ width: "200px" }}>
                                <CustomTextField readOnly={readOnly} value={updateSetting?.near_threshold?.amount_to}
                                 onChange={changeSettings} id={"near_threshold,amount_to"}
                                 requiredField={showTextRequiredFiles && !updateSetting?.near_threshold?.amount_to ? true : false}  /></div>
                        </div>
                    </div>
                </div> <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <Label className="boldBS" style={{ width: "355px" }}>{t("cashTurnoverDefault")}</Label>
                    {updateSetting?.cash_turnover?.map((item: any, i: number) => (
                        <div key={i} className={`inlineBS-${dir}`}>
                            <Label className="boldBS" style={{ width: "355px" }} required>{item?.customer_type_name + "  - מעל ערך"}</Label>
                            <div style={{ width: "200px" }}>
                                <CustomTextField readOnly={readOnly} value={item?.over_volume} onChange={changeSettings} 
                                id={`cash_turnover,${item?.customer_type_id}`} 
                                requiredField={showTextRequiredFiles && !item?.over_volume ? true : false} 
                                /></div>
                        </div>
                    ))}
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("annualTurnoverDefault")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} value={updateSetting?.expected_volume_activity} prefix={"%"}
                             onChange={changeSettings} id={"expected_volume_activity"}
                              requiredField={showTextRequiredFiles && !updateSetting?.expected_volume_activity ? true : false} /></div>
                    </div>
                </div> <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("popupScanningCheque")}</Label>
                        <div style={{ width: "200px" }}>
                            {readOnly ?
                                <CustomTextField readOnly={readOnly}
                                    value={enumsDropdown.popUpScanCheques?.find((item: any) => item.key === updateSetting?.cheque_scanning_popup)?.text}
                                    id={'cheque_scanning_popup'}
                                    onChange={""} />
                                :
                                <Dropdown
                                    placeholder={t("popupScanningCheque")}
                                    selectedKey={updateSetting?.cheque_scanning_popup}
                                    options={enumsDropdown.popUpScanCheques}
                                    onChange={onChangeScanningCheque}
                                    id={'cheque_scanning_popup'}
                                    styles={dropdownStyles}
                                />}
                        </div>
                    </div>

                </div><hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
            </div>
        </div>
    )
}
export default BranchUsersSettings
