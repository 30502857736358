
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import React from 'react';
import { IExampleItem } from '@fluentui/example-data';
import { FocusZone, FocusZoneDirection, List } from '@fluentui/react/lib';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import SearchBoxSmall from '../../shared/components/TextSearch';
import { searchCustomer, readCustomerId } from '../../store/actions/customerActioin'
import { accountData } from '../../store/actions/accountAction';
import { SET_DATA_NEW_TRANSACTION } from '../../store/actions/types';


const DialogSearch = (props: any) => {
  const { place, arr, index, chosenAction,userCredentials, onChooseFunc, setSearch_fields, type, isUnderLine,
    setConversionCustomerId, setReadOnly, isSearchInsiders, setRelatedFactors, setnewBankAccount,
    setidEntity, autoFocus, valueSearch, setTextSearch, } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const [curentPage, setCurentPage] = useState(1);
  const [customerSearch, setCutomerSearch] = useState("");
  const [searchData, setSearchData] = useState<any>();
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const clientData = useSelector((state: any) => state.authReducer?.data?.user)
  const datacConversion = useSelector(({ conversionReducer }: any) => conversionReducer.dataConversion)
  const [searchList, setSearchList] = useState<any[]>()
  const [list, setList] = useState<any[]>()
  let labelText = type === "searchAccount" ? place?.place ? place?.place :
    t('selectAcount') : place ? place : type === "entityAccount" ? "" : t('search')
  const classNames = mergeStyleSets({
    listGridExample: {
      overflow: 'hidden',
      fontSize: 15,
      position: 'absolute',
      boxShadow: "rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px",
      height: `${searchList?.length && searchList?.length * 2}`,
      width: "189px",
      backgroundColor: "white",
      zIndex: 3
    },
  })

  useEffect(() => {
    if (valueSearch && valueSearch !== "") {
      setCutomerSearch(valueSearch);
      getData()
    }
  }, [valueSearch])

  useEffect(() => {
    if ((chosenAction && chosenAction !== "") || customerSearch !== chosenAction) {
      setCutomerSearch(chosenAction ? chosenAction : "");
    }
  }, [chosenAction])

  useEffect(() => {
    if (customerSearch?.length > 1 && customerSearch !== chosenAction) {
      const timeoutId = setTimeout(() => getData(), 300);
      return () => clearTimeout(timeoutId);
    }
  }, [customerSearch])

  useEffect(() => {
    let searchSelect: object[] = []
    if (searchData) {
      if (searchData?.data?.search_results) {
        const arrData = searchData?.data?.search_results;
        arrData?.forEach((data: any) => {

          data.status_id === 1 ?
            searchSelect.push({ key: type === 'searchAccount' ? data.id_account : data.id_entity, name: type === 'searchAccount' ? data.account_name + ", " + data.account_number : data.entity_name + ", " + data.ID_number, status: data.status, color: "black", idEntity: data.id_entity })
            : searchSelect.push({ key: data.id_entity, name: data.entity_name, status: data.status, color: "grey" })
        })
        setSearchList(searchSelect)
        setList(searchSelect)
      }
      else {
        setRelatedFactors && setRelatedFactors(0)
      }
    }
  }, [searchData])

  const onChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
    if (newValue) {
      const [value, idNumber] = newValue.split(',');
      newValue = value
    }
    setTextSearch && setTextSearch(newValue ? newValue : "")
    setCutomerSearch(newValue ? newValue : "");
    if (newValue && newValue?.length > 2 && list) {
      let filtered = list.filter(item => item.name.toLowerCase().includes(newValue?.toLowerCase()) === true)
      setSearchList(filtered)
    }
    if (!newValue) {
      setSearchList([])
      setList([])
    }
  };

  //load and get data from search

  const getData = async () => {
    if (type === "searchAccount")
      dispatch(accountData(userCredentials, setSearch_fields, setSearchData, 1,
        [{ field_name: "account_name", search_type: "like", search_value: customerSearch }], 100, "search", 'searchAccount'))
    else
      await dispatch(searchCustomer(clientData, customerSearch, curentPage, false, setSearchData))

  }

  const getDetails = async (value: any) => {
    let idEntity = value.target.id
    setidEntity && setidEntity(idEntity)
    if (userCredentials) {
      if (type === "customerSearch") {
        await dispatch(readCustomerId(idEntity, userCredentials, setConversionCustomerId, "", "", false, "", setReadOnly ? setReadOnly : "", ""))
        history.push("./customer_details")
      }
      else {
        if (type === "searchCustomerConversion" && datacConversion?.id_entity) {
          await dispatch({
            type: SET_DATA_NEW_TRANSACTION,
            payload: {
              isNew: true,
              idCustomer: idEntity
            }
          })
        }
        else {
          if (isSearchInsiders) {

            dispatch(readCustomerId(idEntity, userCredentials, setConversionCustomerId, "", "", false, "", setReadOnly ? setReadOnly : "", "", setRelatedFactors))
          }
          else {
            dispatch(readCustomerId(idEntity, userCredentials, setConversionCustomerId, "", "", false, "", setReadOnly ? setReadOnly : "", setRelatedFactors, "", setnewBankAccount ? setnewBankAccount : ""))
          }
        }
      }
      setSearchList([])
    }
  }


  const onRenderCell = (item?: IExampleItem | undefined, index?: number | undefined): JSX.Element => {

    return (
      <div id={item?.key} data-is-focusable className={`div-dialog-search-${dir}`}
        style={item?.color === "black" ? { color: item?.color,opacity:0.97} : { color: item?.color }}>
        {item?.name}
      </div>
    );
  };
  ///check why arrive here a lot
  window.onclick = (() => {
    if (customerSearch) {
      setList([]);
      setSearchList([])
      if (!chosenAction)
        setCutomerSearch("")
    }
  })

  return (
    <>
      <FocusZone direction={FocusZoneDirection.vertical}>
        <SearchBoxSmall isUnderLine={isUnderLine} autoFocus={autoFocus ? autoFocus : false} onChange={onChange}
          label={labelText} value={customerSearch} />
        <List items={searchList} onRenderCell={onRenderCell} onClick={(e) => {
          if (type === 'searchAccount') {
            setList([]);
            setSearchList([])
            onChooseFunc('againstAccount', e.target, index !== undefined ? index : -1, searchData, arr && place,);
          }
          else getDetails(e)
        }}
          className={searchList?.length && searchList?.length > 0 ? classNames.listGridExample : ""} />
      </FocusZone>
    </>
  )
}
export default DialogSearch