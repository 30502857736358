import React, { useEffect, useState } from "react";
import { Nav } from "@fluentui/react/lib/Nav";
import "../../scssPages/sidebar.scss";
import { INavStyles, INavLinkGroup, INavLink } from "@fluentui/react/lib/Nav";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { useLocation, withRouter } from "react-router-dom";
import "../../scssPages/sidebar.scss";
import { useTranslation } from "react-i18next";
import { registerIcons } from "@fluentui/react";
import { useSelector } from "react-redux";
import { DialogMessages } from "../../shared/components/dialog/DialogMessages";
import { globalPages } from "../../shared/components/CustomHooks/GlobalSidePages";

initializeIcons();

registerIcons({
  icons: {
    CreditIcon: <i className="fal fa-credit-card-blank"></i>,
    planIcon: <i className="fal fa-users-cog"></i>,
    UsersMedicalIcon: <i className="fal fa-users-medical"></i>,
    CogsIcon: <i className="fal fa-cogs"></i>,
    EmailIcon: <i className="fal fa-envelope"></i>,
  },
});

const ContentSideSettings = (props: any) => {
  const [t] = useTranslation();
  const location = useLocation();
  const [showPermissionNote, setShowPermissionNote] = useState(false);
  const [msgPermission, setMsgPermission] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  // const credentials = useSelector(({ authReducer }: any) => authReducer.data.user.credentials)
  const arrCredentials = useSelector(
    ({ authReducer }: any) => authReducer?.data?.credentials
  );
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);

  const credentialsPagesDetails: any = globalPages;

  useEffect(() => {
    const pathname = location.pathname.split("/");
    const key = pathname.length > 2 ? pathname.pop() : null;
    setSelectedKey(key || "branch_settings");
  }, []);

  const handleNavClick = (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    ev?.preventDefault();
    let path: any = item?.url.split("/")[1];
    // if (credentials[path] && credentials[path] === "Deny" || !credentials[path]) {
    //   setMsgPermission(t('permissionAaccessThisPage'))
    //   setShowPermissionNote(true)
    // }
    // else {
    props.history.push(`/setting${item?.url}`);
    // }
    setSelectedKey(item?.key || "branch-settings");
  };

  const navStyles: Partial<INavStyles> = {
    root: {
      i: { width: "25px", marginRight: "3px" },
    },
    link: {
      ":after": dir === "ltr" && {
        borderLeft: "2px solid transparent !important",
        borderRight: "2px solid rgb(0, 120, 212) !important",
      },
    },
  };

  // const navLinkGroups: INavLinkGroup[] = [
  //   {
  //     links: [
  //       {
  //         key: "branch_settings",
  //         url: "/branch_setting",
  //         iconProps: { iconName: "CogsIcon" },
  //         name: t("branchSettings"),
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "price-plan",
  //         url: "/price_program_templates",
  //         iconProps: { iconName: "planIcon" },
  //         name: t("planPrice"),
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "users",
  //         url: "/users",
  //         component: "Users",
  //         iconProps: { iconName: "UsersMedicalIcon" },
  //         name: t("users"),
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "role-fair-credit",
  //         url: "/fair_credit",
  //         iconProps: { iconName: "CreditIcon" },
  //         name: t("roleFairCredit"),
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "userEmail",
  //         url: "/email_setting",
  //         iconProps: { iconName: "EmailIcon" },
  //         name: t("userEmail"),
  //         onClick: handleNavClick
  //       },
  //     ]
  //   }
  // ];

  const navLinkGroups: INavLinkGroup[] = [{ links: [] }];

  arrCredentials?.map(
    (module: any) =>
      module?.module_code === "setting" &&
      module?.pages?.map((page: any) =>
        navLinkGroups[0]?.links.push({
          className: credentialsPagesDetails[page?.page_code]?.className,
          key: credentialsPagesDetails[page?.page_code]?.key,
          iconProps: credentialsPagesDetails[page?.page_code]?.iconProps,
          name: t(credentialsPagesDetails[page?.page_code]?.name),
          styles: credentialsPagesDetails[page?.page_code]?.styles,
          url: credentialsPagesDetails[page?.page_code]?.url,
          onClick: handleNavClick,
        })
      )
  );

  return (
    <>
      {showPermissionNote && (
        <DialogMessages
          setansDelete={setShowPermissionNote}
          setshowModalDelete={setShowPermissionNote}
          subText={msgPermission}
          dialogType={"dialogMessage"}
        />
      )}
      <Nav
        className="items"
        onLinkClick={handleNavClick}
        styles={navStyles}
        groups={navLinkGroups}
        selectedKey={selectedKey}
      />
    </>
  );
};
export default withRouter(ContentSideSettings);
