import * as React from 'react';
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks';
import { TextField } from '@fluentui/react';

const modelProps = {
  isBlocking: true,
  topOffsetFixed: true,
};


export const DialogTopOffsetFixedExample: React.FunctionComponent = () => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);



  return (
    <>
      <DefaultButton secondaryText="Opens the Sample Dialog" onClick={toggleHideDialog} text="Open Dialog" />
      <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} modalProps={modelProps}>
       
        <DialogFooter>
            <TextField/>
            <TextField/>
            <TextField/>
            <TextField/>
            <DefaultButton onClick={toggleHideDialog} text="Cancel" />
          <PrimaryButton onClick={toggleHideDialog} text="Save" />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DialogTopOffsetFixedExample;