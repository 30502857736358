import { useBoolean } from "@fluentui/react-hooks";
import {
  PrimaryButton,
  Dialog,
  ContextualMenu,
  DialogFooter,
  DefaultButton,
  IComboBoxStyles,
} from "office-ui-fabric-react";
import {
  ComboBox,
  IComboBoxOption,
  IComboBox,
  Icon,
  Spinner,
  ISpinnerStyles,
  Checkbox,
} from "@fluentui/react";
import { useState, useEffect } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { CustomDropdown } from "../Option";
import { CustomTextField } from "../TextField";
import { CustomToggle } from "../Toggle";
import validator from "validator";
import { customerInfoInObject } from "../CustomHooks/GlobalFunctions";
import { IdNumber } from "../CustomHooks/concatTextFields";
import { convertArrToDropdropdown } from "../../../components/customerDetails/CustomerDetails";
import {
  updateCollateralMedia,
  createCollateralMedia,
} from "../../../store/actions/collateralAction";
import {
  getBranchesPerBank,
  bankAccountUpdate,
  createBankAccount,
  searchBankDetails,
  createTransferFromCustomer,
} from "../../../store/actions/bankActions";
import {
  CreateAddress,
  updateAddress,
} from "../../../store/actions/addressAction";
import { createCreditCard } from "../../../store/actions/creditCardAction";
import { CustomerDate } from "../CustomHooks/concatTextFields";
import { CreatePhone, updatePhone } from "../../../store/actions/phonecAction";
import { CreateEmail, updateEmail } from "../../../store/actions/emailAction";
import { createTransaction } from "../../../store/actions/convertionActions";
import {
  readCustomerId,
  updateCustomerInfo,
} from "../../../store/actions/customerActioin";
import { useDispatch, useSelector } from "react-redux";
import { loadStreetEnums } from "../../../store/actions/dataActions";
import CustemTable from "../tabels/tableList";
import { DialogMessages } from "./DialogMessages";
import "./dialog.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
import DialogSearch from "../../../components/global/DialogSearch";
import { deteleFile, uploadFile } from "../../../store/actions/FilesAction";
import { updateDocument } from "../../../store/actions/documentAction";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
import { useLocation } from "react-router-dom";
import { receiptTransaction } from "../../../store/actions/accountAction";

const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: {
    maxWidth: "300px",
    backgroundColor: "white",
  },
  input: {
    backgroundColor: "white",
  },
  optionsContainerWrapper: {
    width: "240px",
  },
};
const RequiredcomboBoxStyles: Partial<IComboBoxStyles> = {
  root: {
    backgroundColor: "#FAF9F8",
    border: "solid red 1px",
  },
};

const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: true,
};
const modelProps = {
  // styles: dialogStyles,
  isBlocking: true,
  topOffsetFixed: true,
  dragOptions: dragOptions,
};

interface Iprop {
  textButton: string;
}

const spinnerStyles: Partial<ISpinnerStyles> = {
  root: {
    position: "fixed",
    zIndex: "999999",
    fontWeight: "bold",
  },
  circle: {
    color: "#FFB400 ",
    height: "100px",
    width: "100px",
  },
};

class UpdateAddress {
  TypeAddress: Number;
  Country: string;
  Street: string;
  Number: string;
  PostalCode: string;
  City: String;
  CityName: string;
  StreetName: string;
  AddressDefault: boolean;

  constructor(
    typeAddress: Number = -1,
    country: string = "",
    street: string = "",
    streetName: string = "",
    number = "",
    city = "",
    cityName = "",
    postalCode = "",
    addressDefault = false
  ) {
    this.TypeAddress = typeAddress;
    this.Country = country;
    this.Street = street;
    this.StreetName = streetName;
    this.Number = number;
    this.PostalCode = postalCode;
    this.City = city;
    this.CityName = cityName;
    this.AddressDefault = addressDefault;
  }
}

class UpdateEmail {
  TypeAddressEmail: string;
  EmailAddress: string;
  EmailDefault: boolean;
  constructor(
    typeAddressEmail: string = "",
    emailAddress = "",
    emailDefault = false
  ) {
    this.TypeAddressEmail = typeAddressEmail;
    this.EmailAddress = emailAddress;
    this.EmailDefault = emailDefault;
  }
}

class UpdatePhone {
  TypePhone: Number;
  Phone: String;
  PhoneDefault: boolean;
  AreaCodeCountry: Number;
  constructor(
    typePhone: Number = -1,
    phoneDefault = false,
    phone: string = "",
    areaCodeCountry = 0
  ) {
    this.TypePhone = typePhone;
    this.PhoneDefault = phoneDefault;
    this.Phone = phone;
    this.AreaCodeCountry = areaCodeCountry;
  }
}

// add update address
export const CustemDialogAddress = (props: any) => {
  const {
    setShowDialog,
    userCredentials,
    idEntity,
    update = false,
    itemUpdate = null,
    setshowModalUpdate = null,
    setshowSpiner,
  } = props;
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);

  const dispatch = useDispatch();

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [enumsDropdown, setenumsDropdown] = useState<any>({
    countryCode: [],
    addressType: [],
  });
  const [address, setAddress] = useState(new UpdateAddress());
  const [addressBeforeChanges, setAddressBeforeChanges] = useState(
    new UpdateAddress()
  );
  const [listStreets, setListStreets] = useState<any>([]);
  const [arrCities, setArrCities] = useState<IComboBoxOption[]>([]);
  const [arrStreets, setArrStreets] = useState<IComboBoxOption[]>([]);
  const authReducer = useSelector(({ authReducer }: any) => authReducer);
  const [textBtn, setTextBtn] = useState("create");
  const [currentStreet, setCurrentStreet] = useState<any>();
  const postalCode = `https://israelpost.co.il/%D7%A9%D7%99%D7%A8%D7%95%D7%AA%D7%99%D7%9D/%D7%90%D7%99%D7%AA%D7%95%D7%A8-%D7%9E%D7%99%D7%A7%D7%95%D7%93/`;
  const [allowFreeform, { toggle: toggleAllowFreeform }] = useBoolean(true);
  const customStyles: Partial<IComboBoxStyles> = {
    root: { backgroundColor: "white" },
    input: { backgroundColor: "white" },
  };
  const [newKey, setNewKey] = useState<any>(1);
  const [showTextRequiredFiles, setShowTextRequiredFiles] = useState(false);
  const [requiredDialog, setRequierdDialog] = useState(false);
  const [isLess, setisLess] = useState(true);

  const requiredFieldStyle: Partial<IComboBoxStyles> = {
    root: { backgroundColor: "#FAF9F8", border: "solid red 2px" },
    input: { backgroundColor: "#FAF9F8" },
  };

  useEffect(() => {
    if (update === true) {
      setTextBtn("update");
    }
  }, [update]);

  //open dialog after click from the previous page
  useEffect(() => {
    if (setShowDialog) toggleHideDialog();
  }, [setShowDialog, toggleHideDialog]);

  /////insert all enums of the dialog
  useEffect(() => {
    if (enums) {
      let arr = {
        addressType: convertArrToDropdropdown([]),
        countryCode: convertArrToDropdropdown([]),
        selectedCountryCode: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].customer !== undefined) {
          let customer = enums[i].customer;
          for (let j = 0; j < customer.length; j++) {
            if (customer[j].address_type_id !== undefined) {
              arr.addressType = convertArrToDropdropdown(
                customer[j].address_type_id
              );
            }
          }
        }
        if (enums[i].general !== undefined) {
          let country = enums[i].general;
          for (let i = 0; i < country.length; i++) {
            if (country[i].country_code !== undefined) {
              country[i].country_code?.forEach((country: any) => {
                if (country?.is_selected === true) {
                  arr.selectedCountryCode.push({
                    key: country.enum_id,
                    text: country.enum_value,
                    is_default: country?.is_default === true && true,
                  });
                }
                arr.countryCode.push({
                  key: country.enum_id,
                  text: country.enum_value,
                  is_default: country?.is_default === true && true,
                });
              });
              arr.selectedCountryCode.push({ key: "more", text: t("more") });
              arr.countryCode.unshift({ key: "less", text: t("less") });
            }
            if (country[i].address_city_code !== undefined) {
              let arrCitiesils: IComboBoxOption[] = [];
              country[i].address_city_code?.forEach((address: any) => {
                arrCitiesils.push({
                  key: address.enum_id,
                  text: address.enum_value,
                });
              });
              setArrCities(arrCitiesils);
            }
          }
        }
      }
      if (itemUpdate === null) {
        let defaultStatusValue: any;
        defaultStatusValue = arr?.countryCode.find(
          ({ is_default }: any) => is_default === true
        );
        setAddress({ ...address, Country: defaultStatusValue.key });
        setAddressBeforeChanges({
          ...addressBeforeChanges,
          Country: defaultStatusValue.key,
        });
      }
      setenumsDropdown(arr);
    }
  }, [enums]);

  useEffect(() => {
    if (isLess) {
      if (enumsDropdown?.selectedCountryCode) {
        let isExsist = enumsDropdown.selectedCountryCode.find(
          (item: any) => item.key === address.Country
        );
        if (!isExsist) setisLess(false);
      }
    }
  }, [enumsDropdown?.selectedCountryCode, address.Country]);

  const convertAddressArrToDropdropdown = (arr: Object[]) => {
    let newArr: any[] = [];
    arr?.forEach((item: any, i: number) => {
      item?.is_default === true
        ? newArr.push({
          key: i,
          text: item.enum_value,
          is_default: true,
          code: item.enum_id,
        })
        : newArr.push({ key: i, text: item.enum_value, code: item.enum_id });
    });
    return newArr;
  };

  useEffect(() => {
    if (listStreets?.length > 0) {
      let arr = convertAddressArrToDropdropdown(listStreets);
      if (
        address.City !== "" &&
        address?.Country === "IL" &&
        address.StreetName !== ""
      ) {
        let isExit = arr.find(
          (addressStreet: any) =>
            Number(addressStreet.code) === Number(address?.Street)
        );
        if (!isExit) {
          arr.push({
            key: arr.length,
            text: address?.StreetName ? address?.StreetName : "",
            code: Number(address?.Street),
          });
          setNewKey(
            (address?.Street ? Number(address?.Street) : arr.length) + 1
          );
        } else {
          setAddress({ ...address, Street: isExit?.key });
          setCurrentStreet(isExit);
          setNewKey(arr.length);
        }
      }
      setArrStreets(arr);
    }
  }, [listStreets]);

  useEffect(() => {
    if (address.CityName !== "" && address.Country === "IL") {
      dispatch(
        loadStreetEnums(
          authReducer.data.client.id_entity,
          authReducer.data.client.id_entity,
          authReducer.data.branch.id_entity,
          String(address.City) ? String(address.City) : "",
          setListStreets
        )
      );
    }
  }, [address.CityName]);

  useEffect(() => {
    let addressData;
    if (itemUpdate !== null && update) {
      addressData = new UpdateAddress(
        itemUpdate.typeAddressId,
        itemUpdate.addressCountryCode,
        itemUpdate.street,
        itemUpdate.streetName,
        itemUpdate.number,
        itemUpdate.city,
        itemUpdate.cityName,
        itemUpdate.postalCode,
        itemUpdate.addressDefault
      );
      setAddress(addressData);
      setAddressBeforeChanges(addressData);
    }
  }, [itemUpdate]);

  const onChangeCity = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ): void => {
    let key: any = option?.key;
    let text: any = option?.text;
    setAddress({
      ...address,
      CityName: text,
      City: key,
      Street: "",
      StreetName: "",
    });
  };

  const onChangeStreet = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ): void => {
    let key: any = option?.key;
    let text: any = option?.text;
    if (allowFreeform && !option && value) {
      key = newKey;
      key = Number(key);
      let arr: IComboBoxOption[] = [...arrStreets];
      arr.push({ key: key!, text: value });
      setArrStreets(arr);
      setNewKey(newKey + 1);
    }
    let currentStreet: any = arrStreets.find(
      (street: any) => street.key === key
    );
    let currectStreet: any = arrStreets.find(
      (street: any) => street.code === currentStreet?.code
    );
    setCurrentStreet(currentStreet);
    setAddress({
      ...address,
      Street: currectStreet?.key,
      StreetName: text ? text : value,
    });
  };

  const updateUser = (key: string, value: any) => {
    let newCus: any = { ...address };
    if (key === "Country" && (value === "more" || value === "less")) {
      setisLess(!isLess);
    } else {
      if (key === "Country") {
        (newCus as any)[key] = value;
        (newCus as any)["Street"] = "";
        (newCus as any)["StreetName"] = "";
        (newCus as any)["City"] = "";
        (newCus as any)["CityName"] = "";
        setAddress(newCus);
      } else {
        (newCus as any)[key] = value;
        setAddress(newCus);
      }
    }
  };
  const saveAddressBtn = async () => {
    let addressData = {
      address:
        currentStreet && address.Country === "IL"
          ? currentStreet.code
          : address.Street,
      addressName:
        currentStreet && address.Country === "IL"
          ? currentStreet.text
          : address.StreetName,
      addressCity: address.City,
      addressCityName: address.CityName,
      addressNumber: address.Number,
      iDCountryCode: address.Country,
      addressZipCode: address.PostalCode,
      addressTypeId: address.TypeAddress,
      addressDefault: address.AddressDefault,
    };
    if (address.CityName && address.Country && address.TypeAddress) {
      if (!isEqual(address, addressBeforeChanges)) {
        setshowSpiner(true);
        setShowDialog && setShowDialog(false);
        toggleHideDialog();
        setshowModalUpdate && setshowModalUpdate(false);
        if (update && itemUpdate !== null) {
          await updateAddress(
            addressData,
            userCredentials,
            idEntity,
            itemUpdate?.addressId
          );
        } else {
          await CreateAddress(addressData, userCredentials, idEntity);
        }
        // await updateAddress(customer, userCredentials, currentIdEntity, customer.addressId, "", "",setshowSpiner,setShowDialogFailed,setMessageError)
        //  await CreateAddress(customer, userCredentials, currentIdEntity,"", "", setshowSpiner,setShowDialogFailed, setMessageError)
        await dispatch(
          readCustomerId(
            idEntity,
            userCredentials,
            "",
            "",
            "",
            false,
            setshowSpiner
          )
        );
      }
    } else {
      setShowTextRequiredFiles(true);
      setRequierdDialog(true);
    }
  };

  return (
    <>
      <Dialog
        minWidth="xl"
        maxWidth="xl"
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          setShowDialog && setShowDialog(false);
          if (setshowModalUpdate !== null) setshowModalUpdate(false);
        }}
      >
        {requiredDialog && (
          <DialogMessages
            setansDelete={setRequierdDialog}
            setshowModalDelete={setRequierdDialog}
            dialogType={"requiredFields"}
          />
        )}
        <p
          className="title-text"
          style={{ fontSize: "30px", fontWeight: "bold" }}
        >
          {t("address")}
        </p>
        <hr className="hr"></hr>
        <div>
          <label style={{ marginBottom: "4px", fontWeight: 600 }}>
            {" "}
            {t("addressType")}{" "}
            <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
          </label>
          <CustomDropdown
            otherInputId={""}
            requiredField={
              showTextRequiredFiles &&
                (!address.TypeAddress || address.TypeAddress === -1)
                ? true
                : false
            }
            hasOtherValue={false}
            options={enumsDropdown.addressType}
            label={""}
            onChange={updateUser}
            selectedKey={address.TypeAddress}
            id={"TypeAddress"}
            othertextInput={t("")}
          />
          <label style={{ padding: "5px 0px", fontWeight: 600 }}>
            {t("country")} <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
          </label>
          {!isLess && (
            <CustomDropdown
              requiredField={
                showTextRequiredFiles && !address.Country ? true : false
              }
              otherInputId={""}
              hasOtherValue={false}
              options={enumsDropdown.countryCode}
              label={""}
              onChange={updateUser}
              selectedKey={address.Country}
              id={"Country"}
              othertextInput={t("")}
            />
          )}
          {isLess && (
            <CustomDropdown
              requiredField={
                showTextRequiredFiles && !address.Country ? true : false
              }
              otherInputId={""}
              hasOtherValue={false}
              options={enumsDropdown.selectedCountryCode}
              label={""}
              onChange={updateUser}
              selectedKey={address.Country}
              id={"Country"}
              othertextInput={t("")}
            />
          )}
          {address.Country === "IL" ? (
            <ComboBox
              id={"City"}
              label={t("city")}
              required={true}
              selectedKey={Number(address.City)}
              autoComplete="on"
              styles={
                (dir === "ltr" && {
                  root: {
                    paddingRight: "9px !important",
                    paddingLeft: "32px !important",
                  },
                }) ||
                (showTextRequiredFiles && !address.City
                  ? requiredFieldStyle
                  : customStyles)
              }
              allowFreeform={true}
              options={arrCities}
              onChange={onChangeCity}
            />
          ) : (
            <CustomTextField
              required={true}
              requiredField={
                showTextRequiredFiles && !address.CityName ? true : false
              }
              value={address.CityName}
              label={t("city")}
              onChange={updateUser}
              id={"CityName"}
            />
          )}
          {address.Country === "IL" && address.City !== "" ? (
            <div>
              <ComboBox
                id={"Street"}
                label={t("street")}
                // showTextRequiredFiles && !address.Street ? requiredFieldStyle :
                styles={
                  (dir === "ltr" && {
                    root: {
                      paddingRight: "9px !important",
                      paddingLeft: "32px !important",
                    },
                  }) ||
                  customStyles
                }
                selectedKey={
                  address.Street !== "" ? Number(address.Street) : ""
                }
                autoComplete="on"
                allowFreeform={true}
                options={arrStreets}
                onChange={onChangeStreet}
              />
            </div>
          ) : (
            // requiredField={showTextRequiredFiles && !address.Number ? true : false}
            //required={true}required={true} required={true} requiredField={showTextRequiredFiles && !address.Street ? true : false}
            <CustomTextField
              value={address.StreetName}
              label={t("street")}
              onChange={updateUser}
              id={"StreetName"}
            />
          )}
          <CustomTextField
            value={address.Number}
            label={t("number")}
            onChange={updateUser}
            id={"Number"}
          />
          <div className="wrapper-collateral">
            <div
              className={`address-zip-code-rtl-${dir}`}
              style={{ width: "100%" }}
            >
              <CustomTextField
                value={address.PostalCode}
                label={t("postalCode")}
                onChange={updateUser}
                id={"PostalCode"}
              />
            </div>
            <div
              onClick={() => {
                window.open(`${postalCode}`, "_blank");
              }}
              className={`div-adress-zip-code-${dir}`}
            >
              <Icon iconName="refresh"></Icon>
            </div>
          </div>
          <div style={{ marginTop: "8px" }}>
            <CustomToggle
              offText={t("addressDefault")}
              onText={t("addressDefault")}
              onChange={updateUser}
              id={"AddressDefault"}
              defaultChecked={address.AddressDefault}
            />
          </div>
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
              setShowDialog(false);
            }}
            text={t("cancel")}
          />
          <PrimaryButton
            onClick={() => {
              saveAddressBtn();
            }}
            text={t(textBtn)}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

///add update phone number

export const CustemDialogPhone = (props: any) => {
  const {
    setShowDialog,
    userCredentials,
    idEntity,
    update = false,
    itemUpdate = null,
    setshowModalUpdate = null,
    setshowSpiner,
  } = props;
  const [t] = useTranslation();
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [enumsDropdown, setenumsDropdown] = useState<any>({
    telephoneTypeId: [],
    telephoneCountryCode: [],
  });
  // const authReducer = useSelector(({ authReducer }: any) => authReducer)
  const [textBtn, setTextBtn] = useState("create");
  const [showTextRequiredFiles, setShowTextRequiredFiles] = useState(false);
  const [requiredDialog, setRequierdDialog] = useState(false);
  const dispatch = useDispatch();

  // const dialogContentProps = {
  //   title: t('phone'),
  // };

  const [phone, setPhone] = useState(new UpdatePhone());
  const [phoneBeforeChanges, setPhoneBeforeChanges] = useState(
    new UpdatePhone()
  );
  const updateUser = (key: string, value: any) => {
    let newCus = { ...phone };
    (newCus as any)[key] = value;
    setPhone(newCus);
  };
  const savePhoneBtn = async () => {
    if (phone?.Phone && phone?.AreaCodeCountry && phone?.TypePhone) {
      if (!isEqual(phone, phoneBeforeChanges)) {
        setshowSpiner(true);
        toggleHideDialog();
        setshowModalUpdate && setshowModalUpdate(false);
        setShowDialog && setShowDialog(false);
        let phoneData = {
          telephone: phone.Phone,
          telephoneCountryCode: phone.AreaCodeCountry,
          telephoneTypeId: phone.TypePhone,
          telephoneDefault: phone.PhoneDefault,
        };
        update && itemUpdate !== null
          ? await updatePhone(
            phoneData,
            userCredentials,
            idEntity,
            itemUpdate.telephoneId
          )
          : await CreatePhone(phoneData, userCredentials, idEntity);
        dispatch(
          readCustomerId(
            idEntity,
            userCredentials,
            "",
            "",
            "",
            false,
            setshowSpiner
          )
        );
      }
    } else {
      setShowTextRequiredFiles(true);
      setRequierdDialog(true);
    }
  };

  useEffect(() => {
    if (update === true) {
      setTextBtn("update");
    }
  }, [update]);

  useEffect(() => {
    if (setShowDialog) toggleHideDialog();
  }, [setShowDialog, toggleHideDialog]);

  ////check if need to open it
  // useEffect(() => {
  //   const id_entity = authReducer.data.client.id_entity
  //   const id_client = authReducer.data.client.id_client
  //   dispatch(loadEnums(id_entity, id_client))
  // }, [authReducer])

  useEffect(() => {
    if (enums) {
      let arr = {
        telephoneCountryCode: convertArrToDropdropdown([]),
        telephoneTypeId: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].general !== undefined) {
          let tel = enums[i].general;
          for (let i = 0; i < tel.length; i++) {
            if (tel[i].telephone_country_code !== undefined)
              arr.telephoneCountryCode = convertArrToDropdropdown(
                tel[i].telephone_country_code,
                "tel"
              );
          }
        }
        if (enums[i].customer !== undefined) {
          let customer = enums[i].customer;
          for (let j = 0; j < customer.length; j++) {
            if (customer[j].telephone_type_id !== undefined)
              arr.telephoneTypeId = convertArrToDropdropdown(
                customer[j].telephone_type_id
              );
          }
        }
      }
      if (itemUpdate === null) {
        let defaultStatusValue: any;
        defaultStatusValue = arr?.telephoneCountryCode.find(
          ({ is_default }: any) => is_default === true
        );
        setPhone({ ...phone, AreaCodeCountry: defaultStatusValue.key });
        setPhoneBeforeChanges({
          ...phoneBeforeChanges,
          AreaCodeCountry: defaultStatusValue.key,
        });
      }
      setenumsDropdown(arr);
    }
  }, [enums]);

  useEffect(() => {
    let phoneData;
    if (itemUpdate !== null && update) {
      phoneData = new UpdatePhone(
        itemUpdate.typePhoneId,
        itemUpdate.telephoneDefault,
        itemUpdate.phone,
        itemUpdate.AreaCodeCountry
      );
      setPhone(phoneData);
      setPhoneBeforeChanges(phoneData);
    }
  }, [itemUpdate]);

  return (
    <>
      <Dialog
        minWidth="xl"
        maxWidth="xl"
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          setShowDialog && setShowDialog(false);
          if (setshowModalUpdate !== null) setshowModalUpdate(false);
        }}
      >
        {requiredDialog && (
          <DialogMessages
            setansDelete={setRequierdDialog}
            setshowModalDelete={setRequierdDialog}
            dialogType={"requiredFields"}
          />
        )}
        <p
          className="title-text"
          style={{ fontSize: "30px", fontWeight: "bold" }}
        >
          {t("phone")}
        </p>
        <hr className="hr"></hr>
        <div>
          <label style={{ marginBottom: "4px", fontWeight: 600 }}>
            {t("TypePhone")}{" "}
            <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
          </label>
          <CustomDropdown
            requiredField={
              showTextRequiredFiles &&
                (!phone.TypePhone || phone.TypePhone === -1)
                ? true
                : false
            }
            otherInputId={""}
            hasOtherValue={false}
            options={enumsDropdown.telephoneTypeId}
            label={""}
            onChange={updateUser}
            selectedKey={phone.TypePhone}
            id={"TypePhone"}
            othertextInput={t("")}
          />
          <CustomTextField
            required={true}
            requiredField={showTextRequiredFiles && !phone.Phone ? true : false}
            value={phone.Phone}
            label={t("phone")}
            onChange={updateUser}
            id={"Phone"}
          />
          <CustomDropdown
            requiredField={
              showTextRequiredFiles && !phone.AreaCodeCountry ? true : false
            }
            otherInputId={""}
            hasOtherValue={false}
            options={enumsDropdown.telephoneCountryCode}
            label={t("AreaCodeCountry")}
            onChange={updateUser}
            selectedKey={phone.AreaCodeCountry}
            id={"AreaCodeCountry"}
            othertextInput={t("")}
          />
          <div style={{ marginTop: "8px" }}>
            <CustomToggle
              offText={t("PhoneDefault")}
              onText={t("PhoneDefault")}
              onChange={updateUser}
              id={"PhoneDefault"}
              defaultChecked={phone.PhoneDefault}
            />
          </div>
        </div>

        <DialogFooter>
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
              setShowDialog(false);
            }}
            text={t("cancel")}
          />
          <PrimaryButton
            onClick={() => {
              savePhoneBtn();
            }}
            text={t(textBtn)}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

//add update email
export const CustemDialogEmail = (props: any) => {
  const {
    setShowDialog,
    userCredentials,
    setshowModalUpdate = null,
    idEntity,
    update = false,
    itemUpdate = null,
    setshowSpiner,
  } = props;
  const [t] = useTranslation();
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [showDialogFailed, setShowDialogFailed] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [enumsDropdown, setenumsDropdown] = useState<any>({ emailTypeId: [] });
  const authReducer = useSelector(({ authReducer }: any) => authReducer);
  const [textBtn, setTextBtn] = useState("create");
  const dispatch = useDispatch();
  const [validEmail, setValidEmail] = useState(true);
  const [email, setEmail] = useState(new UpdateEmail());
  const [emailBeforeChanges, setEmailBeforeChanges] = useState(
    new UpdateEmail()
  );
  const [showTextRequiredFiles, setShowTextRequiredFiles] = useState(false);
  const [requiredDialog, setRequierdDialog] = useState(false);

  const updateUser = (key: string, value: any) => {
    let newCus = { ...email };
    (newCus as any)[key] = value;
    setEmail(newCus);
  };

  ///insert all enums of the dialog
  useEffect(() => {
    if (enums) {
      let arr = {
        emailTypeId: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].customer !== undefined) {
          let customer = enums[i].customer;
          for (let i = 0; i < customer.length; i++) {
            if (customer[i].email_type_id !== undefined)
              arr.emailTypeId = convertArrToDropdropdown(
                customer[i].email_type_id
              );
          }
        }
      }
      setenumsDropdown(arr);
    }
  }, [enums]);

  ////check if we need to open it
  // useEffect(() => {
  //   const id_entity = authReducer.data.client.id_entity
  //   const id_client = authReducer.data.client.id_client
  //   dispatch(loadEnums(id_entity, id_client))
  // }, [authReducer])

  useEffect(() => {
    let mailData;
    if (itemUpdate !== null && update) {
      mailData = new UpdateEmail(
        itemUpdate.emailTypeId,
        itemUpdate.emailAddress,
        itemUpdate.emailDefault
      );
      setEmail(mailData);
      setEmailBeforeChanges(mailData);
    }
  }, [itemUpdate]);

  //open dialog after click from the previous page
  useEffect(() => {
    if (update === true) {
      setTextBtn("update");
    }
  }, [update]);

  useEffect(() => {
    if (setShowDialog) toggleHideDialog();
  }, [setShowDialog, toggleHideDialog]);

  const checkValidationEmail = (e: any) => {
    if (e.target.value !== "") {
      let valid = validator["isEmail"](e.target.value);
      valid ? setValidEmail(true) : setValidEmail(false);
    } else {
      setValidEmail(true);
    }
  };

  const saveMailBtn = async () => {
    if (email.TypeAddressEmail && email.EmailAddress) {
      if (!isEqual(email, emailBeforeChanges)) {
        if (validEmail) {
          setshowSpiner(true);
          setShowDialog && setShowDialog(false);
          toggleHideDialog();
          let emailData = {
            email: email.EmailAddress,
            emailTypeId: email.TypeAddressEmail,
            emailDefault: email.EmailDefault,
          };
          update && itemUpdate !== null
            ? await updateEmail(
              emailData,
              userCredentials,
              idEntity,
              itemUpdate.emailId
            )
            : await CreateEmail(emailData, userCredentials, idEntity);
          dispatch(
            readCustomerId(
              idEntity,
              userCredentials,
              "",
              "",
              "",
              false,
              setshowSpiner
            )
          );
        } else {
          setShowDialogFailed(true);
          setMessageError(t("validFields"));
        }
      }
    } else {
      setShowTextRequiredFiles(true);
      setRequierdDialog(true);
    }
  };

  return (
    <>
      {/* {!update ? <PrimaryButton text={textButton} onClick={toggleHideDialog} /> : ''} */}
      <Dialog
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          setShowDialog && setShowDialog(false);
          if (setshowModalUpdate !== null) setshowModalUpdate(false);
        }}
      >
        {requiredDialog && (
          <DialogMessages
            setansDelete={setRequierdDialog}
            setshowModalDelete={setRequierdDialog}
            dialogType={"requiredFields"}
          />
        )}
        <p
          className="title-text"
          style={{ fontSize: "30px", fontWeight: "bold" }}
        >
          {t("email")}
        </p>
        <hr className="hr"></hr>
        {showDialogFailed && (
          <DialogMessages
            setansDelete={setShowDialogFailed}
            subText={messageError}
            title={t("error")}
            setshowModalDelete={setShowDialogFailed}
            dialogType={"dialogMessage"}
          />
        )}
        {/* className="wrap-dialog" */}
        <div>
          <label style={{ marginBottom: "4px", fontWeight: 600 }}>
            {t("emailAddressType")}{" "}
            <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
          </label>
          <CustomDropdown
            requiredField={
              showTextRequiredFiles && !email.TypeAddressEmail ? true : false
            }
            otherInputId={""}
            hasOtherValue={false}
            options={enumsDropdown.emailTypeId}
            label={""}
            onChange={updateUser}
            selectedKey={email.TypeAddressEmail}
            id={"TypeAddressEmail"}
            othertextInput={t("")}
          />
          <CustomTextField
            required={true}
            requiredField={
              showTextRequiredFiles && !email.EmailAddress ? true : false
            }
            onBlur={(e: any) => checkValidationEmail(e)}
            value={email.EmailAddress}
            label={t("emailAddress")}
            onChange={updateUser}
            id={"EmailAddress"}
          />
          {!validEmail && <p className="text-feild-note">{t("validEmail")}</p>}
          <div style={{ marginTop: "8px" }}>
            <CustomToggle
              offText={t("defaultEmail")}
              onText={t("defaultEmail")}
              onChange={updateUser}
              id={"EmailDefault"}
              defaultChecked={email.EmailDefault}
            />
          </div>
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
              setShowDialog(false);
            }}
            text={t("cancel")}
          />
          <PrimaryButton
            onClick={() => {
              saveMailBtn();
            }}
            text={t(textBtn)}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export const CustomDialogBankAccount = (props: any) => {
  const {
    showRequiredField,
    conversions,
    notShowHolderButton,
    index,
    isUpdate,
    tableType,
    setShowDialog,
    itemUpdate,
    isNewObject,
    userCredentials,
    idEntity,
    update,
    setShowDialogUpdate,
    setShowDialogSave,
    setshowSpiner,
    bankDetails,
    setbankDetails,
    setSearchData,
    isCheck,
  } = props;
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);
  const { state } = useLocation<any>();
  const dispatch = useDispatch();
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [enumsDropdown, setenumsDropdown] = useState<any>({
    countryCode: [],
    bank: [],
    branch: [],
  });
  const [enumsBranch, setEnumsBranch] = useState<any>({ branch: [] });
  const [branchDetails, setBranchDetails] = useState([]);
  const [readOnly, setreadOnly] = useState(false);
  const [showDropDown, setShowDropDown] = useState(true);
  const [showTextRequiredFiles, setShowTextRequiredFiles] = useState(false);
  const [requiredDialog, setRequierdDialog] = useState(false);
  const [showDialogFailed, setShowDialogFailed] = useState(false);
  const [isLess, setisLess] = useState(true);
  const [isLessBank, setisLessBank] = useState(true);
  const [updateBankAccount, setUpdateBankAccount] = useState(
    itemUpdate
      ? itemUpdate
      : bankDetails
        ? bankDetails
        : {
          key: "",
          country: "",
          accountNumber: "",
          bank: "",
          branch: "",
          branchAddress: "",
          IBAN: "",
          note: "",
          branchName: "",
          bankName: "",
          routingNumber: "",
        }
  );
  console.log('updateBankAccount', updateBankAccount)
  useEffect(() => {
    if (showRequiredField) {
      setShowTextRequiredFiles(true);
    }
  }, [showRequiredField]);

  useEffect(() => {
    if (enums) {
      console.log(enums, "enumsss");

      let arr = {
        branch: convertArrToDropdropdown([]),
        bank: convertArrToDropdropdown([]),
        selectedBank: convertArrToDropdropdown([]),
        countryCode: convertArrToDropdropdown([]),
        selectedCountryCode: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].branch !== undefined) {
          let branch = enums[i].branch;
          for (let i = 0; i < branch.length; i++) {
            if (branch[i].entity_sub_type_id !== undefined) {
              arr.branch = convertArrToDropdropdown(
                branch[i].entity_sub_type_id
              );
            }
          }
        }
        if (enums[i].bank_account !== undefined) {
          let bank = enums[i].bank_account;
          for (let i = 0; i < bank.length; i++) {
            if (bank[i].bank_code !== undefined) {
              bank[i].bank_code?.forEach((bank: any) => {
                if (bank?.is_selected === true) {
                  arr.selectedBank.push({
                    key: bank.enum_id,
                    text: bank.enum_value,
                    is_default: bank?.is_default === true && true,
                  });
                }
                arr.bank.push({
                  key: bank.enum_id,
                  text: bank.enum_value,
                  is_default: bank?.is_default === true && true,
                });
              });
              arr.selectedBank.push({ key: "more", text: t("more") });
              arr.bank.unshift({ key: "less", text: t("less") });
            }
          }
        }
        if (enums[i].general !== undefined) {
          let country = enums[i].general;
          for (let i = 0; i < country.length; i++) {
            if (country[i].country_code !== undefined) {
              country[i].country_code?.forEach((country: any) => {
                if (country?.is_selected === true) {
                  arr.selectedCountryCode.push({
                    key: country.enum_id,
                    text: country.enum_value,
                    is_default: country?.is_default === true && true,
                  });
                }
                arr.countryCode.push({
                  key: country.enum_id,
                  text: country.enum_value,
                  is_default: country?.is_default === true && true,
                });
              });
              arr.selectedCountryCode.push({ key: "more", text: t("more") });
              arr.countryCode.unshift({ key: "less", text: t("less") });
            }
          }
        }
      }
      if (itemUpdate === undefined && !updateBankAccount?.country) {
        let defaultCauntryValue: any;
        defaultCauntryValue = arr?.countryCode.find(
          ({ is_default }: any) => is_default === true
        );
        setUpdateBankAccount({
          ...updateBankAccount,
          country: defaultCauntryValue.key,
        });
      }
      setenumsDropdown(arr);
    }
  }, [enums]);

  useEffect(() => {
    if (isLess) {
      if (enumsDropdown?.selectedCountryCode?.length > 0 && updateBankAccount) {
        let isExsist = enumsDropdown.selectedCountryCode.find(
          (item: any) => item.key === updateBankAccount?.country
        );
        if (!isExsist) setisLess(false);
      }
    }
  }, [enumsDropdown?.selectedCountryCode, updateBankAccount?.country]);

  useEffect(() => {
    if (isLessBank) {
      if (
        enumsDropdown?.selectedBank?.length > 0 &&
        updateBankAccount?.accountNumber
      ) {
        let isExsist = enumsDropdown.selectedBank.find(
          (item: any) => item.key === updateBankAccount?.bank
        );
        if (!isExsist) setisLessBank(false);
      }
    }
  }, [enumsDropdown?.selectedBank, updateBankAccount?.bank]);

  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog();
    }
  }, [setShowDialog]);

  useEffect(() => {
    if (conversions) {
      setbankDetails(updateBankAccount);
    }
  }, [updateBankAccount]);

  useEffect(() => {
    if (isCheck === false) {
      setUpdateBankAccount(bankDetails);
      if (bankDetails?.country !== "IL") {
        setShowDropDown(false);
      } else {
        setShowDropDown(true);
      }
    } else {
      // if (bankDetails && bankDetails?.accountNumber)
      //   setUpdateBankAccount(bankDetails);
      // else
      if (bankDetails && bankDetails?.bank_account_number)
        setUpdateBankAccount({
          ...updateBankAccount,
          country: bankDetails?.country_code,
          branchAddress: bankDetails.bank_branch_address,
          accountNumber: bankDetails.bank_account_number,
          bank: bankDetails.bank_code,
          branch: bankDetails.bank_branch_code,
          bankName: bankDetails.bank_name,
          branchName: bankDetails.bank_branch_name,
        });
      else
        if (bankDetails?.bankName || (bankDetails?.accountNumber && bankDetails.accountNumber !== ''))
          setUpdateBankAccount(bankDetails);
    }
  }, [bankDetails]);

  useEffect(() => {
    if (branchDetails.length > 0) {
      let items: any = [];
      branchDetails?.forEach((e) => {
        items.push({
          key: e["enum_id"],
          text: e["enum_value"] + ` (${e["enum_id"]})`,
          address: e["branch_address"] + " " + e["city_name"] + "  " + "ישראל",
        });
      });
      setEnumsBranch(items);
    }
  }, [branchDetails]);

  // useEffect(() => {
  //   itemUpdate && fill()
  //   async function fill() {
  //     setUpdateBankAccount(itemUpdate)
  //     if (itemUpdate?.country === "IL") {
  //       setShowDropDown(true)
  //       setEnumsBranch([])
  //       await dispatch(getBranchesPerBank(itemUpdate.bank, userCredentials, setBranchDetails))
  //     }
  //     else {
  //       setShowDropDown(false)
  //     }
  //   }
  // }, [itemUpdate])

  useEffect(() => {
    getBranches();
    async function getBranches() {
      if (
        updateBankAccount?.country === "IL" &&
        updateBankAccount?.bank &&
        updateBankAccount?.branch &&
        (enumsBranch?.length === 0 || conversions)
      ) {
        await dispatch(
          getBranchesPerBank(
            updateBankAccount.bank,
            userCredentials,
            setBranchDetails
          )
        );
      }
    }
  }, [updateBankAccount]);

  const changeBankAccount = async (key: string, value: any) => {
    key === "country" && value !== "IL" && setShowDropDown(false);
    key === "country" && value === "IL" && setShowDropDown(true);
    if (key === "bank" && updateBankAccount?.country === "IL") {
      if (value === "more" || value === "less") {
        setisLessBank(!isLessBank);
      } else {
        setEnumsBranch([]);
        await dispatch(
          getBranchesPerBank(value, userCredentials, setBranchDetails)
        );
        let getSelectedIndex = enumsDropdown.bank.findIndex(
          (x: any) => x.key === value
        );
        setUpdateBankAccount({
          ...updateBankAccount,
          ["bankName"]: enumsDropdown?.bank[getSelectedIndex]?.text,
          [key]: value,
          ["branch"]: "",
          ["branchAddress"]: "",
          ["branchName"]: "",
          ["branchDisplay"]: "",
        });
      }
    }
    if (key === "branch" && updateBankAccount?.country === "IL") {
      enumsBranch.forEach((b: any) => {
        if (b.key === value) {
          setUpdateBankAccount({
            ...updateBankAccount,
            ["branchAddress"]: b.address,
            ["branchName"]: b.text,
            [key]: value,
          });
        }
      });
    } else {
      if (key === "country" && (value === "more" || value === "less")) {
        setisLess(!isLess);
      } else {
        key === "country"
          ? setUpdateBankAccount({
            ...updateBankAccount,
            [key]: value,
            ["bank"]: "",
            ["bankName"]: "",
            ["branch"]: "",
            ["branchName"]: "",
            ["branchAddress"]: "",
          })
          : key !== "bank" &&
          setUpdateBankAccount({ ...updateBankAccount, [key]: value });
      }
    }
  };

  const changeBankComboBox = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string,
    id?: string
  ): void => {
    let val = option?.key;
    let key = id ? id : "bank";
    changeBankAccount(key, val);
  };
  const save = () => {
    if (!isEqual(updateBankAccount, itemUpdate)) {
      if (
        updateBankAccount.country !== "" &&
        updateBankAccount.bankName !== "" &&
        updateBankAccount.branchName !== "" &&
        updateBankAccount.accountNumber !== ""
      ) {
        toggleHideDialog();
        setShowDialog(false);
        setshowSpiner(true);
        update === true
          ? dispatch(
            bankAccountUpdate(
              updateBankAccount,
              userCredentials,
              idEntity,
              setShowDialogUpdate,
              setShowDialogFailed,
              setshowSpiner
            )
          )
          : dispatch(
            createBankAccount(
              updateBankAccount,
              userCredentials,
              idEntity,
              setShowDialogSave,
              setShowDialogFailed,
              setshowSpiner
            )
          );
        // dispatch(createBankAccount(updateBankAccount, userCredentials, idEntity, setShowDialogSave, setShowDialogFailed, setshowSpiner))
        // (bankAccount: any, userCredentials: IUserCredentials | null, idEntity: string,setServerMessageError?: any, setMessageError:any, setshowSpiner?: any, setidBankAccount?: any, setnewBankAccount?: any)
      } else {
        setShowTextRequiredFiles(true);
        setRequierdDialog(true);
      }
    } else {
      toggleHideDialog();
      setShowDialog(false);
    }
  };
  const searchDetailsOfBank = () => {
    if (conversions) {
      setbankDetails(updateBankAccount);
      // if (updateBankAccount.country && updateBankAccount.accountNumber && updateBankAccount.bank && updateBankAccount.branch && isCheck)
      dispatch(
        searchBankDetails(updateBankAccount, userCredentials, setSearchData, "")
      );
    }
  };

  const dialogFields = (): JSX.Element => {
    return (
      <Dialog
        minWidth="xl"
        maxWidth="xl"
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          setShowDialog(false);
        }}
      >
        <p
          className="title-text"
          style={{ fontSize: "30px", fontWeight: "bold" }}
        >
          {t("bankAccount")}
        </p>
        <hr className="hr"></hr>
        <div style={{ width: "50vh" }}>
          <p className="title-text">{t("accountDetails")}</p>
          <hr className="hr"></hr>
          {bankAccountFields()}
          <CustomTextField
            value={updateBankAccount?.IBAN ? updateBankAccount?.IBAN : ""}
            readOnly={readOnly}
            label={t("IBAN")}
            onChange={changeBankAccount}
            id={"IBAN"}
          />

          {requiredDialog && (
            <DialogMessages
              setansDelete={setRequierdDialog}
              setshowModalDelete={setRequierdDialog}
              dialogType={"requiredFields"}
            />
          )}
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
              setShowDialog(false);
            }}
            text={t("cancel")}
          />
          <PrimaryButton
            onClick={() => {
              save();
            }}
            text={t("save")}
          />
        </DialogFooter>
      </Dialog>
    );
  };

  const bankAccountFields = (): JSX.Element => {
    return (
      <div style={{ width: "100%" }}>
        <div className="wrapper-collateral">
          <div className={`warpper-fields-right-${dir}`}>
            <div>
              <label style={{ padding: "5px 0px", fontWeight: 600 }}>
                {t("country")}{" "}
                <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
              </label>
              {state !== "showCompletedManagment" ? (
                !isLess ? (
                  <CustomDropdown
                    readOnly={readOnly}
                    otherInputId={""}
                    hasOtherValue={false}
                    options={enumsDropdown?.countryCode}
                    label={""}
                    onChange={changeBankAccount}
                    selectedKey={updateBankAccount?.country}
                    id={"country"}
                    othertextInput={t("")}
                  />
                ) : (
                  <CustomDropdown
                    readOnly={readOnly}
                    otherInputId={""}
                    hasOtherValue={false}
                    options={enumsDropdown.selectedCountryCode}
                    label={""}
                    onChange={changeBankAccount}
                    selectedKey={updateBankAccount?.country}
                    id={"country"}
                    othertextInput={t("")}
                  />
                )
              ) : (
                <CustomTextField
                  readOnly={true}
                  value={updateBankAccount?.country}
                  label={t("country")}
                  onChange={changeBankAccount}
                  id={"country"}
                />
              )}
            </div>
          </div>
          <div className={`warpper-fields-left-${dir}`}>
            {showDropDown &&
              updateBankAccount?.country === "IL" &&
              state !== "showCompletedManagment" ? (
              <div>
                {!readOnly ? (
                  <div>
                    {!isLessBank && (
                      <ComboBox
                        id={"bank"}
                        label={t("bank")}
                        required={true}
                        selectedKey={updateBankAccount?.bank}
                        autoComplete="on"
                        allowFreeform={true}
                        styles={
                          (dir === "ltr" && {
                            root: {
                              paddingRight: "9px !important",
                              paddingLeft: "32px !important",
                            },
                          }) ||
                          (showTextRequiredFiles && !updateBankAccount?.bank
                            ? RequiredcomboBoxStyles
                            : comboBoxStyles)
                        }
                        options={enumsDropdown.bank}
                        onChange={(
                          event: any,
                          option: any,
                          index: any,
                          value: any
                        ) =>
                          changeBankComboBox(
                            event,
                            option,
                            index,
                            value,
                            "bank"
                          )
                        }
                      />
                    )}
                    {isLessBank && (
                      <ComboBox
                        id={"bank"}
                        label={t("bank")}
                        required={true}
                        styles={
                          (dir === "ltr" && {
                            root: {
                              paddingRight: "9px !important",
                              paddingLeft: "32px !important",
                            },
                          }) ||
                          (showTextRequiredFiles && !updateBankAccount?.bank
                            ? RequiredcomboBoxStyles
                            : comboBoxStyles)
                        }
                        selectedKey={updateBankAccount?.bank}
                        autoComplete="on"
                        allowFreeform={true}
                        options={enumsDropdown.selectedBank}
                        onChange={(
                          event: any,
                          option: any,
                          index: any,
                          value: any
                        ) =>
                          changeBankComboBox(
                            event,
                            option,
                            index,
                            value,
                            "bank"
                          )
                        }
                      />
                    )}
                  </div>
                ) : (
                  <CustomDropdown
                    readOnly={updateBankAccount?.country === "" ? true : false}
                    requiredField={
                      showTextRequiredFiles && !updateBankAccount?.bank
                        ? true
                        : false
                    }
                    otherInputId={""}
                    hasOtherValue={false}
                    options={enumsDropdown.selectedBank}
                    label={""}
                    onChange={changeBankAccount}
                    selectedKey={updateBankAccount?.bank}
                    id={"bank"}
                    othertextInput={t("")}
                  />
                )}
              </div>
            ) : (
              <div>
                <CustomTextField
                  value={
                    updateBankAccount?.bankName
                      ? updateBankAccount?.bankName
                      : ""
                  }
                  requiredField={
                    showTextRequiredFiles && !updateBankAccount?.bankName
                      ? true
                      : false
                  }
                  readOnly={
                    state === "showCompletedManagment" ? true : readOnly
                  }
                  required={true}
                  label={t("bank")}
                  onChange={changeBankAccount}
                  id={"bankName"}
                />
              </div>
            )}
          </div>
        </div>
        <div className="wrapper-collateral">
          <div className={`warpper-fields-right-${dir}`}>
            {showDropDown &&
              enumsBranch.length > 0 &&
              state !== "showCompletedManagment" ? (
              <div>
                <label style={{ marginBottom: "4px" }}>
                  {t("branch")}{" "}
                  <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
                </label>
                <CustomDropdown
                  readOnly={
                    updateBankAccount?.bank === "" || enumsBranch?.length === 0
                      ? true
                      : false
                  }
                  requiredField={
                    showTextRequiredFiles && !updateBankAccount?.bank
                      ? true
                      : false
                  }
                  otherInputId={""}
                  hasOtherValue={false}
                  options={enumsBranch}
                  label={""}
                  onChange={changeBankAccount}
                  selectedKey={updateBankAccount?.branch}
                  id={"branch"}
                  othertextInput={"*"}
                />
              </div>
            ) : (
              <CustomTextField
                value={
                  updateBankAccount?.branchName
                    ? updateBankAccount?.branchName
                    : ""
                }
                readOnly={state === "showCompletedManagment" ? true : readOnly}
                requiredField={
                  showTextRequiredFiles && !updateBankAccount?.branchName
                    ? true
                    : false
                }
                required={true}
                label={t("branch")}
                onChange={changeBankAccount}
                id={"branchName"}
              />
            )}
          </div>
          <div className={`warpper-fields-left-${dir}`}>
            <CustomTextField
              value={
                updateBankAccount?.accountNumber
                  ? updateBankAccount?.accountNumber
                  : ""
              }
              requiredField={
                showTextRequiredFiles && !updateBankAccount?.accountNumber
                  ? true
                  : false
              }
              readOnly={state === "showCompletedManagment" ? true : readOnly}
              required={true}
              label={t("accountNumber")}
              onKeyDown={(e: any) => {
                e.key === "Enter" && searchDetailsOfBank();
              }}
              onChange={changeBankAccount}
              id={"accountNumber"}
            />
          </div>
        </div>

        <div
          className={
            updateBankAccount?.country === "US" ? "wrapper-collateral" : ""
          }
        >
          <div
            className={
              updateBankAccount?.country === "US"
                ? `warpper-fields-right-${dir}`
                : ""
            }
          >
            <CustomTextField
              value={
                updateBankAccount?.branchAddress
                  ? updateBankAccount?.branchAddress
                  : ""
              }
              readOnly={updateBankAccount?.country === "IL" ? true : false}
              required={true}
              requiredField={
                showTextRequiredFiles && !updateBankAccount?.branchAddress
                  ? true
                  : false
              }
              label={t("branchAddress")}
              onChange={changeBankAccount}
              id={"branchAddress"}
            />
          </div>
          {updateBankAccount?.country === "US" && (
            <div className={`warpper-fields-left-${dir}`}>
              <CustomTextField
                value={
                  updateBankAccount?.routingNumber
                    ? updateBankAccount?.routingNumber
                    : ""
                }
                requiredField={
                  showTextRequiredFiles && !updateBankAccount?.routingNumber
                    ? true
                    : false
                }
                readOnly={readOnly}
                required={true}
                label="routing"
                onBlur={searchDetailsOfBank}
                onChange={changeBankAccount}
                id={"routingNumber"}
              />
            </div>
          )}
        </div>
        {notShowHolderButton !== true && state !== "showCompletedManagment" && (
          <PrimaryButton
            style={{ width: "100%", marginTop: "12px" }}
            onClick={() => {
              searchDetailsOfBank();
            }}
            text={t("holderSearch")}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {/* {serverMessageError && <DialogMessages setansDelete={setServerMessageError} setshowModalDelete={setServerMessageError} subText={messageError} title={t('error')}  dialogType={"dialogMessage"} />} */}
      {showDialogFailed === true && (
        <DialogMessages
          setansDelete={setShowDialogFailed}
          setshowModalDelete={setShowDialogFailed}
          dialogType={"failed"}
        />
      )}
      {conversions ? bankAccountFields() : dialogFields()}
    </>
  );
};

export const CustomFilesDialog = (props: any) => {
  const {
    setShowDialog = null,
    setMedia,
    setShowParentDialog,
    isCreatedCollateral,
    itemUpdate,
    userCredentials,
    idEntity,
    idCollateral,
    update,
    conversions,
    type,
  } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [enumsDropdown, setenumsDropdown] = useState<any>({
    collteralMedia: [],
  });
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [messageError, setMessageError] = useState("");
  const [serverMessageError, setServerMessageError] = useState(false);
  const [fileDetails, setFileDetails] = useState(
    itemUpdate
      ? itemUpdate
      : {
        media_type_id: "",
        media_name: "",
        note: "",
        media_exp_date: moment().add(1, "year").format("YYYY-MM-DD"),
        media_status_id: "1",
        id_media: "",
      }
  );
  const [requireFiles, setRequireFiles] = useState(false);
  const [isValidDate, setIsValidDate] = useState(true);

  //open dialog after click from the previous page
  useEffect(() => {
    if (setShowDialog !== null) toggleHideDialog();
  }, [setShowDialog]);

  useEffect(() => {
    if (itemUpdate) {
      setFileDetails(itemUpdate);
    }
  }, [itemUpdate]);

  useEffect(() => {
    if (enums) {
      let arr = {
        collteralMedia: convertArrToDropdropdown([]),
        status: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].collateral_media !== undefined) {
          let collateralMedia = enums[i].collateral_media;
          for (let j = 0; j < collateralMedia.length; j++) {
            if (collateralMedia[j].type_id !== undefined) {
              arr.collteralMedia = convertArrToDropdropdown(
                collateralMedia[j].type_id
              );
            }
            if (collateralMedia[j].status_id !== undefined) {
              arr.status = convertArrToDropdropdown(
                collateralMedia[j].status_id
              );
            }
          }
          setenumsDropdown(arr);
        }
      }
    }
  }, [enums]);

  const changeCollateralMedia = (key: string, value: any) => {
    setFileDetails({ ...fileDetails, [key]: value });
  };

  const saveCollateralMedia = async () => {
    if (!isEqual(fileDetails, itemUpdate)) {
      update === true
        ? await dispatch(
          updateCollateralMedia(
            idCollateral,
            fileDetails,
            userCredentials,
            idEntity,
            undefined,
            setServerMessageError,
            setMessageError,
            t
          )
        )
        : await dispatch(
          createCollateralMedia(
            idCollateral,
            fileDetails,
            userCredentials,
            idEntity,
            type
          )
        );
    }
    if (isCreatedCollateral) setShowParentDialog && setShowParentDialog(false);
  };

  const onFileChange = (event: any) => {
    dispatch(
      uploadFile(
        idEntity,
        userCredentials,
        event.target.files[0],
        fileDetails,
        setFileDetails,
        undefined,
        setServerMessageError,
        setMessageError,
        t
      )
    );
  };

  const check = (currentValue: any) => fileDetails[currentValue];
  const clickInput = () => {
    let arrRequiers = [
      "media_type_id",
      "media_name",
      "media_exp_date",
      "media_status_id",
    ];

    if (arrRequiers.every(check) || fileDetails?.id_media !== "") {
      let input = document.getElementById("files");
      if (input !== null) {
        input.click();
      }
    } else {
      setRequireFiles(true);
    }
  };

  return (
    <>
      <Dialog
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          if (setShowDialog !== null) setShowDialog(false);
        }}
      >
        {serverMessageError && (
          <DialogMessages
            setansDelete={setServerMessageError}
            subText={messageError}
            title={t("note")}
            setshowModalDelete={setServerMessageError}
            dialogType={"dialogMessage"}
          />
        )}

        <p
          className="title-text"
          style={{ fontSize: "25px", fontWeight: "bold" }}
        >
          {t("fileUploadEdit")}
        </p>
        <hr className="hr"></hr>
        <div className="wrap-fields">
          {!conversions && (
            <CustomDropdown
              options={enumsDropdown.collteralMedia}
              readOnly={false}
              label={t("fileType")}
              onChange={changeCollateralMedia}
              selectedKey={fileDetails?.media_type_id?.toString()}
              requiredField={!fileDetails?.media_type_id && requireFiles}
              id={"media_type_id"}
              othertextInput={""}
              hasOtherValue={false}
              otherInputId={""}
            />
          )}

          <CustomTextField
            value={fileDetails?.media_name}
            readOnly={false}
            requiredField={!fileDetails?.media_name && requireFiles}
            label={t("fileName")}
            onChange={changeCollateralMedia}
            id={"media_name"}
          />

          <CustomTextField
            value={fileDetails?.note}
            readOnly={false}
            label={t("note")}
            onChange={changeCollateralMedia}
            id={"note"}
          />

          <input
            id="files"
            style={{ display: "none" }}
            type="file"
            onChange={(e) => onFileChange(e)}
          />

          <PrimaryButton
            style={{ width: "100%", marginTop: "10px" }}
            onClick={clickInput}
            type="file"
            text={t("selectFile")}
          />
        </div>

        <DialogFooter>
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
              if (setShowDialog !== null) setShowDialog(false);
            }}
            text={t("cancel")}
          />

          <PrimaryButton
            onClick={() => {
              toggleHideDialog();
              if (setShowDialog !== null) setShowDialog(false);
              saveCollateralMedia();
            }}
            text={update === true ? t("update") : t("create")}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};
////////////// upload files
export const CustomFilesDocumentsDialog = (props: any) => {
  const {
    showDialog,
    setShowDialog = null,
    documents,
    itemUpdate,
    userCredentials,
    idOtherEntity,
    idEntity,
    isNew,
    isUpdate,
    updateIndex,
    setShowDialogUpdate,
    setshowSpiner,
    setRelatedFactors,
    actionType,
  } = props;
  const [t, i18n] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);

  const dispatch = useDispatch();
  const insiders = useSelector(
    ({ customerReducer }: any) => customerReducer?.dataCustomer?.insiders
  );
  const datacConversion = useSelector(
    ({ conversionReducer }: any) => conversionReducer.dataConversion
  );
  const authReducer = useSelector(({ authReducer }: any) => authReducer);
  const dataCustomer = useSelector(
    ({ customerReducer }: any) => customerReducer?.dataCustomer
  );
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [enumsDropdown, setenumsDropdown] = useState<any>({ type: [] });
  const [isValidDate, setIsValidDate] = useState(true);
  const [spiner, setSpiner] = useState(false);
  const [showDialogSave, setShowDialogSave] = useState(false);
  const [message, setMessage] = useState("");
  const [requireFiles, setRequireFiles] = useState(false);

  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [documentsList, setDocumentsList] = useState([]);
  const [insiderDocuments, setInsiderDocuments] = useState<any>([]);
  const [file, setFile] = useState<any>({
    media_name: "",
    id_media: "",
    documentation_type_id: null,
    media_type_id: "1",
    media_status_id: "1",
    media_exp_date: "",
    media_details: null,
    date_created: "",
    status: "פעיל",
    type: "חובה",
  });
  useEffect(() => {
    documents && setDocumentsList(documents);
  }, [documents]);

  useEffect(() => {
    itemUpdate && setFile(itemUpdate);
  }, [itemUpdate]);

  //open dialog after click from the previous page
  useEffect(() => {
    if (setShowDialog !== null) toggleHideDialog();
  }, [setShowDialog]);

  useEffect(() => {
    if (showDialog === false) toggleHideDialog();
  }, [showDialog]);

  useEffect(() => {
    if (enums) {
      let arr = {
        type: convertArrToDropdropdown([]),
        status: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].documentation !== undefined) {
          let documentation = enums[i].documentation;
          for (let j = 0; j < documentation.length; j++) {
            if (documentation[j].type_id !== undefined) {
              arr.type = convertArrToDropdropdown(documentation[j].type_id);
            }
            if (documentation[j].status_id !== undefined) {
              arr.status = convertArrToDropdropdown(documentation[j].status_id);
            }
          }
          setenumsDropdown(arr);
        }
      }
    }
  }, [enums]);

  const changeCollateralMedia = (key: string, value: any) => {
    let type = enumsDropdown.type.find((item: any) => item.key === value);
    if (key === "documentation_type_id" && value !== "99") {
      setFile({ ...file, [key]: value, ["media_name"]: type.text });
    } else {
      if (key === "documentation_type_id" && value === "99") {
        setFile({ ...file, [key]: value, ["media_name"]: "" });
      } else {
        setFile({ ...file, [key]: value });
      }
    }
  };

  const onFileChange = async (event: any) => {
    setSpiner(true);
    let copyFile = { ...file };
    copyFile["media_extension_file_name"] = `.${event.target.files[0].name
      .split(".")
      .pop()}`;
    await dispatch(
      uploadFile(
        idOtherEntity ? idOtherEntity : idEntity,
        userCredentials,
        event.target.files[0],
        copyFile,
        setFile,
        setSpiner,
        setShowDialogSave,
        setMessage,
        t
      )
    );
  };

  const addDeletedDocuments = async (documents: any) => {
    switch (actionType) {
      case "SET_USER":
        let user = authReducer?.data?.branch?.users.find(
          (user: any) => user?.id_entity === idOtherEntity
        );
        user.documentation?.forEach((doc: any) => {
          if (doc?.media_status_id === "99") documents.push(doc);
        });
        return documents;
      case "SET_CUSTOMER_INSIDER" || "SET_INSIDER_DATA_CONVERSION":
        let list = insiders
          ? [...insiders]
          : [
            ...datacConversion?.related_entities?.filter(
              (insider: any) =>
                insider?.service_requisition_type_id === "11" ||
                insider?.service_requisition_type_id === "13"
            ),
          ];
        let insider: any = await list?.find(
          (insider: any) => insider.id_entity === idOtherEntity
        );
        if (insider) {
          (insider?.documentation
            ? insider?.documentation
            : insider.related_entitiy?.documentation
          )?.forEach((doc: any) => {
            if (doc?.media_status_id === "99") documents.push(doc);
          });
        }
        return documents;
      case "READ_CUSTOMER":
        dataCustomer?.documentation?.forEach((doc: any) => {
          if (doc?.media_status_id === "99") documents.push(doc);
        });
        return documents;
      default:
        return documents;
    }
  };

  const delFile = () => {
    dispatch(
      deteleFile(
        setRelatedFactors ? datacConversion?.id_entity : idEntity,
        file?.id_media,
        userCredentials
      )
    );
    setShowDialog(false);
    toggleHideDialog();
  };

  const save = async () => {
    if (file?.id_media) {
      let documents: any = insiderDocuments?.length
        ? [...insiderDocuments]
        : [...documentsList],
        update: any = "",
        index: any = -1,
        first = false;

      let newFile = cloneDeep(file);
      newFile.date_created = moment(new Date()).format("YYYY-MM-DD");
      if (isUpdate) {
        documents[updateIndex] = file.date_created ? file : newFile;
        documents = await addDeletedDocuments(documents);
      } else {
        documents?.forEach((doc: any, i: any) => {
          if (
            doc?.documentation_type_id === file?.documentation_type_id &&
            doc?.id_media
          ) {
            doc.media_status_id = "99";
            doc.status = "מחוק";
            update = true;
            first = true;
          } else {
            if (
              doc?.documentation_type_id === file?.documentation_type_id &&
              !first
            ) {
              update = false;
              index = i;
            }
          }
        });
        if (update === true || (update === "" && isNew))
          documents?.push(newFile);
        else documents[index] = newFile;
      }
      let indexFullyIdentified = documents.find(
        (item: any) => item?.id_media === "" && item.media_status_id === "1"
      );
      setshowSpiner && setshowSpiner(true);
      let copyDataCustomer = { ...dataCustomer };
      copyDataCustomer = customerInfoInObject(copyDataCustomer);
      copyDataCustomer.isFullyIdentified =
        indexFullyIdentified === undefined || indexFullyIdentified === -1
          ? true
          : false;
      if (
        copyDataCustomer?.isFullyIdentified !==
        dataCustomer?.is_fully_identified
      ) {
        await updateCustomerInfo(
          copyDataCustomer,
          userCredentials,
          copyDataCustomer?.idEntity
        );
      }
      await dispatch(
        updateDocument(
          documents,
          userCredentials,
          setRelatedFactors ? datacConversion?.id_entity : idEntity,
          setShowDialogUpdate,
          "",
          setshowSpiner,
          idOtherEntity,
          actionType
        )
      );

      toggleHideDialog();
      setShowDialog !== null && setShowDialog(false);
    } else alert(t("noFileAttached"));
  };
  const check = (currentValue: any) => file[currentValue];

  const clickInput = () => {
    let arrRequiers = ["media_type_id", "media_name", "media_status_id"];
    if (arrRequiers.every(check)) {
      let input = document.getElementById("files");
      if (input !== null) {
        input.click();
      }
    } else {
      setRequireFiles(true);
    }
  };

  return (
    <>
      <Dialog
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          if (setShowDialog !== null) setShowDialog(false);
        }}
      >
        {spiner && (
          <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />
        )}

        <p
          className="title-text"
          style={{ fontSize: "25px", fontWeight: "bold" }}
        >
          {t("fileUploadEdit")}
        </p>

        <hr className="hr"></hr>
        <div className="wrap-fields">
          <label style={{ marginBottom: "3px" }}>
            {t("fileType")}
            <span style={{ color: "rgb(164 38 44)" }}> *</span>
          </label>

          <CustomDropdown
            options={enumsDropdown.type}
            label={""}
            requiredField={!file?.documentation_type_id && requireFiles}
            onChange={changeCollateralMedia}
            selectedKey={file?.documentation_type_id}
            id={t("documentation_type_id")}
            othertextInput={""}
            hasOtherValue={false}
            otherInputId={""}
          />

          {file?.documentation_type_id === "99" && (
            <CustomTextField
              value={file?.media_name}
              label={t("fileName")}
              requiredField={!file?.media_name && requireFiles}
              required={true}
              onChange={changeCollateralMedia}
              id={"media_name"}
            />
          )}
          {file?.date_created && (
            <div>
              <label style={{ marginBottom: "3px", marginTop: "3px" }}>
                {t("status")}
                <span style={{ color: "rgb(164 38 44)" }}> *</span>
              </label>
              <CustomDropdown
                options={enumsDropdown.status}
                label={""}
                required={true}
                requiredField={!file?.media_status_id && requireFiles}
                onChange={changeCollateralMedia}
                selectedKey={file?.media_status_id?.toString()}
                id={t("media_status_id")}
                othertextInput={""}
                hasOtherValue={false}
                otherInputId={""}
              />
            </div>
          )}

          <div className="doc">
            <CustomerDate
              readOnly={false}
              setIsValidDate={setIsValidDate}
              requiredProps={true}
              showRequiredFildes={!file?.media_exp_date && requireFiles}
              isValidDate={isValidDate}
              entity={file}
              setEntity={setFile}
              isDefaultDate={false}
              idDate="media_exp_date"
              label={t("expirationDate")}
              source="futureDate"
            />
          </div>
          <CustomTextField
            value={file?.media_details}
            label={t("note")}
            onChange={changeCollateralMedia}
            id={t("media_details")}
          />

          <input
            id="files"
            style={{ display: "none" }}
            type="file"
            onChange={(e) => onFileChange(e)}
          />

          <PrimaryButton
            style={{ marginTop: "10px" }}
            onClick={clickInput}
            type="file"
            text={t("selectFile")}
          />

          {showDialogSave && (
            <p style={{ color: "red", textAlign: "center" }}>{message}</p>
          )}
        </div>

        <DialogFooter>
          <DefaultButton
            onClick={() => {
              showDialogSave ? delFile() : toggleHideDialog();
              if (setShowDialog !== null) setShowDialog(false);
            }}
            text={t("cancel")}
          />

          <PrimaryButton
            onClick={() => {
              save();
            }}
            text={t("update")}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

///conver
export const CustomDialogTransferFromCustomer = (props: any) => {
  const {
    emptyRequiredFields,
    typeAction,
    setShowDialog,
    setConvertions,
    rowNumber,
    convertions,
    contraBankAccounts,
    tableType,
    userCredentials,
    setshowSpiner,
    conversionCustomer,
  } = props;
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [readOnly, setreadOnly] = useState(true)
  const [isNewObject, setisNewObject] = useState(false)
  const [ifChanges, setIfChanges] = useState(false)
  const dispatch = useDispatch()
  const [defaultCountry, setDefaultCountry] = useState("")
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
  const [searchCustomer, setSearchCustomer] = useState<any>()
  const [selectedBank, setSelectedBank] = useState<any>()
  const [showDialogFailed, setShowDialogFailed] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [resultId, setResultId] = useState<any>(true)
  const [defaultIdTypeId, setDefaultIdTypeId] = useState()
  const [InternationalTransfer, setInternationalTransfer] = useState(false)
  const [requiredDialog, setRequierdDialog] = useState(false)
  const [requiredField, setRequiredField] = useState(false)
  const [enumsDropdown, setenumsDropdown] = useState<any>({ "countryCode": [], "bank": [], "branch": [], "card_brand_code": [], "typeIdentityNumbers": [] })
  const [listBankAccounts, setlistBankAccounts] = useState<any>([{ key: "0", text: "חדש" }])
  const index = convertions[tableType]?.findIndex((item: any) => Number(item.row_number) === Number(rowNumber))
  const [bankTransfer, setBankTransfer] = useState<any>(
    {
      key: '', firstName: '', lastName: '', idTypeCountryCode: '', idTypeId: '', idNumber: '', country: '',
      accountNumber: '', bank: '', branch: '', branchAddress: '', routingNumber: '', IBAN: '', note: '',
      branchName: '', bankName: '', bic_code: '', transfer_purpose: ''
    })
  const Conversion = useSelector(({ conversionReducer }: any) => conversionReducer.dataConversion)
  const account = useSelector(({ accountReducer }: any) => accountReducer.dataAccount)
  const datacConversion = (typeAction === "cheque_cashing" || typeAction === "exchange") ? Conversion :
    (typeAction === "receipt") ? account : ""

  useEffect(() => {
    if (datacConversion && datacConversion[tableType]) {
      let details;
      let index = datacConversion[tableType]?.findIndex(
        (item: any) => Number(item.row_number) === Number(rowNumber)
      );
      if (
        index !== -1 &&
        (datacConversion[tableType][index].financial_asset_code === "9" ||
          datacConversion[tableType][index].financial_asset_code === "10")
      ) {
        details =
          datacConversion[tableType][index] &&
          datacConversion[tableType][index]?.financial_asset_details
            ?.account_holder;
      }
      let mDetails = datacConversion[tableType][index] && datacConversion[tableType][index]?.financial_asset_details
      bankTransfer.firstName = details?.first_name
      bankTransfer.lastName = details?.last_name
      bankTransfer.idTypeCountryCode = details?.identification.ID_country_code
      bankTransfer.idTypeId = details?.identification.ID_type_id
      bankTransfer.idNumber = details?.ID_number
      bankTransfer.country = details?.country_code
      bankTransfer.accountNumber = details?.bank_account_number
      bankTransfer.bank = details?.bank_code
      bankTransfer.branch = details?.bank_branch_code
      bankTransfer.branchAddress = details?.bank_branch_address
      bankTransfer.bankName = details?.bank_name
      bankTransfer.branchName = details?.bank_branch_name
      bankTransfer.bic_code = mDetails?.bic_code
      bankTransfer.transfer_purpose = mDetails?.transfer_purpose
      bankTransfer.routingNumber = mDetails?.routingNumber

      setSelectedBank({ key: mDetails?.account_holder?.bank_account_id, text: mDetails?.account_holder?.bank_name + ' ' + mDetails?.account_holder?.bank_account_number })
      details && setSearchCustomer(customerInfoInObject(details))
    }
  }, [datacConversion]);

  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog();
    }
  }, [setShowDialog]);

  useEffect(() => {
    if (contraBankAccounts?.length === 1) {
      changeConDetails("contra_id_account", contraBankAccounts[0].key);
    }
  }, [contraBankAccounts]);

  useEffect(() => {
    if (enums) {
      let arr = {
        branch: convertArrToDropdropdown([]),
        bank: convertArrToDropdropdown([]),
        countryCode: convertArrToDropdropdown([]),
        selectedCountryCode: convertArrToDropdropdown([]),
        card_brand_code: convertArrToDropdropdown([]),
        typeIdentityNumbers: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].branch !== undefined) {
          let branch = enums[i].branch;
          for (let i = 0; i < branch.length; i++) {
            if (branch[i].entity_sub_type_id !== undefined)
              arr.branch = convertArrToDropdropdown(
                branch[i].entity_sub_type_id
              );
          }
        }
        if (enums[i].general !== undefined) {
          let country = enums[i].general;
          for (let i = 0; i < country.length; i++) {
            if (country[i].country_code !== undefined)
              arr.countryCode = convertArrToDropdropdown(
                country[i].country_code
              );
          }
        }
        if (enums[i].customer !== undefined) {
          let c = enums[i].customer;
          for (let i = 0; i < c.length; i++) {
            if (c[i].ID_type_id !== undefined)
              arr.typeIdentityNumbers = convertArrToDropdropdown(
                c[i].ID_type_id
              );
          }
        }
      }
      let defaultTypeValue: any;
      defaultTypeValue = arr?.typeIdentityNumbers.find(
        ({ is_default }: any) => is_default === true
      );
      setDefaultIdTypeId(defaultTypeValue?.key);
      let defaultCountryValue: any;
      defaultCountryValue = arr?.countryCode.find(
        ({ is_default }: any) => is_default === true
      );
      setDefaultCountry(defaultCountryValue?.key);

      if (
        bankTransfer.accountNumber === undefined ||
        bankTransfer.accountNumber === ""
      )
        setBankTransfer({
          ...bankTransfer,
          idTypeId: defaultTypeValue?.key,
          idTypeCountryCode: defaultCountryValue?.key,
          country: defaultCountryValue?.key,
        });
      setenumsDropdown(arr);
    }
  }, [enums]);

  const changeBankTranfer = (key: string, value: any) => {
    value !== "" && !ifChanges && setIfChanges(true);
    setBankTransfer({ ...bankTransfer, [key]: value });
  };
  const change = async (key: string, value: any) => {
    await setConvertions({ ...convertions, [key]: value });
  };
  const changeConDetails = (key: string, value: any) => {
    let i = index;
    let arr: any = [];
    arr = [...convertions[tableType]];
    let temp = {
      currency_code: key === "currency_code" ? value : arr[i]?.currency_code,
      financial_asset_code:
        key === "financial_asset_code" ? value : arr[i]?.financial_asset_code,
      financial_asset_details: arr[i]?.financial_asset_details,
      amount: key === "amount" ? Number(value) : arr[i]?.amount,
      exchange_rate_ils:
        key === "exchange_rate_ils" ? Number(value) : arr[i]?.exchange_rate_ils,
      amount_ils:
        key === "amount"
          ? value * arr[i]?.exchange_rate_ils
          : key === "exchange_rate_ils"
            ? value * arr[i]?.amount
            : arr[i]?.amount_ils,
      contra_id_account:
        key === "contra_id_account" ? value : arr[i]?.contra_id_account,
      row_number: arr[i]?.row_number,
    };
    arr[i] = temp;
    change(tableType, arr);
  };
  const selectBank = (key: string, value: any) => {
    let index = searchCustomer?.bankAccounts?.findIndex(
      (bank: any) => bank.bank_account_id === value
    );
    if (index !== -1 && index !== undefined) {
      let country = searchCustomer?.bankAccounts[index]?.country_code
      let branchAddress = searchCustomer?.bankAccounts[index]?.bank_branch_address
      let accountNumber = searchCustomer?.bankAccounts[index]?.bank_account_number
      let bank = searchCustomer?.bankAccounts[index]?.bank_code
      let branch = searchCustomer?.bankAccounts[index]?.bank_branch_code
      let bankName = searchCustomer?.bankAccounts[index]?.bank_name
      let branchName = searchCustomer?.bankAccounts[index]?.bank_branch_name
      let routingNumber = searchCustomer?.bankAccounts[index]?.routingNumber

      setSelectedBank({ key: searchCustomer?.bankAccounts[index].bank_account_id, text: searchCustomer?.bankAccounts[index]?.bank_name + ' ' + searchCustomer?.bankAccounts[index]?.bank_account_number })
      setBankTransfer({
        ...bankTransfer, "country": country, "branchAddress": branchAddress, "routingNumber": routingNumber,
        "accountNumber": accountNumber, "bank": bank, "branch": branch, "bankName": bankName, "branchName": branchName
      })
    }

    else {
      if (value === "0") {
        let country = defaultCountry
        let branchAddress = ""
        let accountNumber = ""
        let bank = ""
        let branch = ""
        let bankName = ""
        let branchName = ""
        let routingNumber = ""

        setSelectedBank({ key: "0", text: "חדש" })
        setisNewObject(true)
        setBankTransfer({
          ...bankTransfer, ["country"]: country, ["branchAddress"]: branchAddress, ["routingNumber"]: routingNumber,
          ["accountNumber"]: accountNumber, ["bank"]: bank, ["branch"]: branch, ["bankName"]: bankName, ["branchName"]: branchName
        })
      }
    }
  };
  useEffect(() => {
    if (bankTransfer.country && bankTransfer.country !== "IL") {
      setInternationalTransfer(true);
      changeConDetails("financial_asset_code", "10");
    } else {
      setInternationalTransfer(false);
      changeConDetails("financial_asset_code", "9");
    }
  }, [bankTransfer.country]);

  const updateFinancialAssets = (financialAssetDetails: any) => {
    let arr: any = [];
    arr = [...convertions[tableType]];
    arr[index].financial_asset_details = financialAssetDetails;
    setConvertions({ ...convertions, [tableType]: arr });
  };

  const check = (currentValue: string) =>
    bankTransfer[currentValue]?.length > 0;

  const createbankTransfer = async () => {
    let copyConvertions: any = convertions;
    if (
      tableType === "financial_assets_out" &&
      typeAction === "cheque_cashing"
    ) {
      let properties: any =
        searchCustomer?.priceProgram?.price_program_properties;
      if (bankTransfer?.country === "IL") {
        let propValue = properties?.find(
          (prop: any) => prop.name === "wire_fee"
        );
        copyConvertions[tableType][index].fee = propValue?.value;
        copyConvertions[tableType][index].amount_ils -= propValue?.value;
      } else {
        let propValue = properties?.find(
          (prop: any) => prop.name === "abroad_wire_fee"
        );
        copyConvertions[tableType][index].fee = propValue?.value;
        copyConvertions[tableType][index].amount_ils -= propValue?.value;
      }
    }

    let financialAssetDetails: any;
    let requiredFields: any = [
      "idNumber",
      "idTypeId",
      "firstName",
      "lastName",
      "accountNumber",
      "bankName",
      "branchName",
      "branchAddress",
    ];
    if (
      convertions[tableType][index].contra_id_account &&
      requiredFields.every(check)
    ) {
      if (searchCustomer?.idEntity && ifChanges) {
        await updateCustomerInfo(
          bankTransfer,
          userCredentials,
          searchCustomer?.idEntity
        );
      }

      if (
        searchCustomer?.idEntity &&
        selectedBank?.key &&
        selectedBank?.key !== "0"
      ) {
        financialAssetDetails = {
          bank_account_id: selectedBank?.key,
          id_account_holder: searchCustomer?.idEntity,
          transfer_purpose: bankTransfer?.transfer_purpose,
          bic_code: bankTransfer?.bic_code,
        };
      } else {
        if (resultId) {
          if (searchCustomer === 0) {
            financialAssetDetails = await dispatch(
              createTransferFromCustomer(
                bankTransfer,
                userCredentials,
                undefined
              )
            );
          } else {
            financialAssetDetails = await dispatch(
              createTransferFromCustomer(
                bankTransfer,
                userCredentials,
                searchCustomer?.idEntity
              )
            );
          }
        } else {
          setShowDialogFailed(true);
          setMessageError(t("validFields"));
        }
      }
      updateFinancialAssets(financialAssetDetails);
      //ask if need the servermessages?
      if (typeAction === "receipt") {
        dispatch(
          receiptTransaction(
            "update",
            typeAction,
            conversionCustomer.entityName,
            copyConvertions,
            userCredentials,
            copyConvertions?.id_entity,
            t,
            setshowSpiner
          )
        );
      }
      if (typeAction === "cheque_cashing" || typeAction === "exchange") {
        dispatch(
          createTransaction(
            "update",
            typeAction,
            conversionCustomer.entityName,
            copyConvertions,
            userCredentials,
            copyConvertions?.id_entity,
            setshowSpiner
          )
        );
      }
      if (
        (searchCustomer?.idEntity &&
          selectedBank?.key &&
          selectedBank?.key !== "0") ||
        resultId
      ) {
        setShowDialog(false);
        toggleHideDialog();
      }
      setFocus(tableType, convertions, rowNumber);
    } else {
      setRequierdDialog(true);
      setRequiredField(true);
    }
  };
  useEffect(() => {
    if (searchCustomer && searchCustomer !== 0) {
      let obj;
      setreadOnly(true);
      if (searchCustomer.idNumber !== bankTransfer.idNumber) {
        obj = {
          key: "",
          firstName: "",
          lastName: "",
          idTypeCountryCode: "",
          idTypeId: "",
          idNumber: "",
          country: "",
          accountNumber: "",
          bank: "",
          branch: "",
          branchAddress: "",
          IBAN: "",
          note: "",
          branchName: "",
          bankName: "",
          bic_code: "",
          transfer_purpose: "",
        };
        obj.firstName = searchCustomer?.firstName;
        obj.lastName = searchCustomer?.lastName;
        obj.idNumber = searchCustomer?.idNumber;
        obj.idTypeId = searchCustomer?.idTypeId;
        obj.idTypeCountryCode = searchCustomer?.idTypeCountryCode;
        setSelectedBank({ key: "", text: "" });
        setBankTransfer(obj);
      }
      let listBank: any[] = [{ key: "0", text: "חדש" }];
      searchCustomer?.bankAccounts?.forEach((bank: any) => {
        listBank.push({
          key: bank.bank_account_id,
          text: bank.bank_name + " " + bank.bank_account_number,
        });
      });
      setlistBankAccounts(listBank);
    } else {
      searchCustomer === 0 && setreadOnly(false);
    }
  }, [searchCustomer]);
  return (
    <>
      <Dialog
        minWidth="xl"
        maxWidth="xl"
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          setShowDialog(false);
        }}
      >
        <p
          className="title-text"
          style={{ fontSize: "30px", fontWeight: "bold" }}
        >
          {tableType === "financial_assets_in"
            ? t("bankTransferFromCustomer")
            : t("bankTransferToCustomer")}
        </p>
        <hr className="hr"></hr>
        {showDialogFailed && (
          <DialogMessages
            setansDelete={setShowDialogFailed}
            subText={messageError}
            title={t("error")}
            setshowModalDelete={setShowDialogFailed}
            dialogType={"dialogMessage"}
          />
        )}

        {requiredDialog && (
          <DialogMessages
            setansDelete={setRequierdDialog}
            setshowModalDelete={setRequierdDialog}
            dialogType={"requiredFields"}
          />
        )}
        <div>
          <p className="title-text" style={{ marginTop: "20px" }}>
            {tableType === "financial_assets_in"
              ? t("recipientAccountDetails")
              : t("transferringAccountDetails")}
          </p>

          <hr className="hr" style={{ marginBottom: "0" }}></hr>
          {contraBankAccounts?.length > 1 ? (
            <div>
              <label style={{ marginBottom: "4px", fontWeight: 600 }}>
                {t("account")}{" "}
                <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
              </label>
              <CustomDropdown
                requiredField={
                  requiredField &&
                    !convertions[tableType][index]?.contra_id_account
                    ? true
                    : false
                }
                readOnly={false}
                otherInputId={""}
                hasOtherValue={false}
                id={"contra_id_account"}
                othertextInput={t("")}
                options={contraBankAccounts}
                label={""}
                onChange={changeConDetails}
                selectedKey={convertions[tableType][index]?.contra_id_account}
              />
            </div>
          ) : (
            <CustomTextField
              value={
                contraBankAccounts?.find(
                  (e: any) =>
                    e.key === convertions[tableType][index]?.contra_id_account
                )?.text
              }
              readOnly={true}
              label={t("account")}
              onChange={""}
              id={"contra_id_account"}
            />
          )}
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className={`sub-title-text account-holder-${dir}`}>
              {tableType === "financial_assets_in"
                ? t("detailsTransferringAccountHolder")
                : t("detailsReceivingAccountHolder")}{" "}
            </p>

            <div
              style={{
                marginTop: "20px",
                justifyContent: "space-between",
                display: "inline-flex",
              }}
            >
              <DialogSearch
                userCredentials={userCredentials}
                isSearchInsiders={false}
                setRelatedFactors={setSearchCustomer}
              />
              <FontAwesomeIcon
                icon={faPaste}
                title={t("copyCustomer")}
                className={`iconRefresh-${dir} icon-copy-${dir}`}
                onClick={() => setSearchCustomer(conversionCustomer)}
              />
            </div>
          </div>
          <hr
            className="hr"
            style={{ marginBottom: "0", marginTop: "3px" }}
          ></hr>
          <div className="wrapper-collateral">
            <div className={`warpper-fields-right-${dir}`}>
              <CustomTextField
                required={true}
                value={bankTransfer?.firstName}
                readOnly={false}
                requiredField={
                  (requiredField || emptyRequiredFields) &&
                    !bankTransfer?.firstName
                    ? true
                    : false
                }
                label={t("firstNameAccountHolder")}
                onChange={changeBankTranfer}
                id={"firstName"}
              />
            </div>
            <div className={`warpper-fields-left-${dir}`}>
              <CustomTextField
                required={true}
                value={bankTransfer?.lastName}
                readOnly={false}
                requiredField={
                  (requiredField || emptyRequiredFields) &&
                    !bankTransfer?.lastName
                    ? true
                    : false
                }
                label={t("lastNameAccountHolder")}
                onChange={changeBankTranfer}
                id={"lastName"}
              />
            </div>
          </div>
          <div className="wrapper-id">
            <IdNumber
              entity={bankTransfer}
              showRequiredFildes={
                (requiredField || emptyRequiredFields) &&
                  !bankTransfer?.idNumber
                  ? true
                  : false
              }
              setIfCahnges={setIfChanges}
              setEntity={setBankTransfer}
              readOnly={false}
              setResultId={setResultId}
              resultId={resultId}
              typeIdentityNumbers={enumsDropdown.typeIdentityNumbers}
              typeIdDefault={defaultIdTypeId}
            />
          </div>
          {tableType === "financial_assets_in" ? (
            ""
          ) : (
            <CustomTextField
              value={bankTransfer?.transfer_purpose}
              label={t("transferPurpose")}
              onChange={changeBankTranfer}
              id={"transfer_purpose"}
            />
          )}
          <p className="sub-title-text" style={{ marginTop: "20px" }}>
            {tableType === "financial_assets_in"
              ? t("tansferringBankAccountDetails")
              : t("receivingBankSADetails")}
          </p>
          <hr className="hr" style={{ margin: "5px 0px" }}></hr>
          <CustomDropdown
            placeholder={listBankAccounts.length > 1 ? t("selectAcount") : ""}
            otherInputId={""}
            hasOtherValue={false}
            options={listBankAccounts}
            label={""}
            onChange={selectBank}
            selectedKey={selectedBank?.key}
            id={"selectedCardId"}
            othertextInput={t("")}
          />
          <CustomDialogBankAccount
            showRequiredField={requiredField || emptyRequiredFields}
            notShowHolderButton={true}
            bankDetails={bankTransfer}
            isCheck={false}
            isNewObject={isNewObject}
            setbankDetails={setBankTransfer}
            conversions={true}
            userCredentials={userCredentials}
          />
          {tableType === "financial_assets_in" ? (
            ""
          ) : InternationalTransfer === true ? (
            <CustomTextField
              value={bankTransfer?.bic_code}
              label={t("fundsTransferCode")}
              onChange={changeBankTranfer}
              id={"bic_code"}
            />
          ) : (
            ""
          )}
        </div>

        <DialogFooter>
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
              setShowDialog(false);
              setFocus(tableType, convertions, rowNumber);
            }}
            text={t("cancel")}
          />
          <PrimaryButton
            onClick={() => {
              createbankTransfer();
            }}
            text={t("create")}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};
export const CustomDialogCreditFromCustomer = (props: any) => {
  const {
    emptyRequiredFields,
    typeAction,
    contraCreditCard,
    setShowDialog,
    setConvertions,
    rowNumber,
    convertions,
    tableType,
    userCredentials,
    setShowDialogSave,
    setshowSpiner,
    conversionCustomer,
  } = props;

  const authReducer = useSelector(({ authReducer }: any) => authReducer);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);
  const dispatch = useDispatch();
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [resultId, setResultId] = useState<any>(true);
  const [enumsDropdown, setenumsDropdown] = useState<any>({
    countryCode: [],
    bank: [],
    branch: [],
    card_brand_code: [],
    typeIdentityNumbers: [],
  });
  const [readOnly, setreadOnly] = useState(true);
  const [ifChanges, setIfChanges] = useState(false);
  const [showDialogFailed, setShowDialogFailed] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState<any>();
  const [selectedCard, setSelectedCard] = useState<any>();
  const [isContraAccount, setIsContraAccount] = useState(false);
  const [listFreditCards, setListCreditcards] = useState<any>([
    { key: "0", text: "חדש" },
  ]);
  const index = convertions[tableType]?.findIndex(
    (item: any) => Number(item.row_number) === Number(rowNumber)
  );
  const [defaultCountry, setDefaultCountry] = useState<any>();
  const [defaultIdTypeId, setDefaultIdTypeId] = useState();
  const [isLess, setisLess] = useState(true);
  const [showNote, setShowNote] = useState(false);
  const [note, setNote] = useState("");
  const [requiredField, setRequiredField] = useState(false);
  const [creditFromCustomer, setcreditFromCustomer] = useState<any>({
    firstName: "",
    lastName: "",
    idTypeCountryCode: "",
    idTypeId: "",
    idNumber: "",
    card_brand_code: "",
    card_brand_name: "",
    country_code: "",
    card_pan: "",
  });
  const Conversion = useSelector(
    ({ conversionReducer }: any) => conversionReducer.dataConversion
  );
  const account = useSelector(
    ({ accountReducer }: any) => accountReducer.dataAccount
  );
  const datacConversion =
    typeAction === "cheque_cashing" || typeAction === "exchange"
      ? Conversion
      : typeAction === "receipt"
        ? account
        : "";

  useEffect(() => {
    if (datacConversion && datacConversion[tableType]) {
      let index = datacConversion[tableType]?.findIndex(
        (item: any) => Number(item.row_number) === Number(rowNumber)
      );
      let details =
        datacConversion[tableType][index] &&
        datacConversion[tableType][index].financial_asset_details?.card_holder;
      let obj = {
        firstName: details?.first_name,
        lastName: details?.last_name,
        idTypeCountryCode: details?.identification.ID_country_code,
        idTypeId: details?.identification.ID_type_id,
        idNumber: details?.ID_number,
        card_brand_code: details?.card_brand_code.toLocaleString(),
        card_brand_name: details?.card_brand_name,
        country_code: details?.country_code,
        card_pan: details?.card_pan,
      };
      setcreditFromCustomer(obj);
      setSelectedCard({
        key: details?.card_id,
        text: details?.card_brand_name,
      });
      details && setSearchCustomer(customerInfoInObject(details));
    }
  }, [datacConversion]);
  useEffect(() => {
    if (enums) {
      let arr = {
        branch: convertArrToDropdropdown([]),
        bank: convertArrToDropdropdown([]),
        countryCode: convertArrToDropdropdown([]),
        selectedCountryCode: convertArrToDropdropdown([]),
        card_brand_code: convertArrToDropdropdown([]),
        typeIdentityNumbers: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].branch !== undefined) {
          let branch = enums[i].branch;
          for (let i = 0; i < branch.length; i++) {
            if (branch[i].entity_sub_type_id !== undefined)
              arr.branch = convertArrToDropdropdown(
                branch[i].entity_sub_type_id
              );
          }
        }
        if (enums[i].general !== undefined) {
          let country = enums[i].general;
          for (let i = 0; i < country.length; i++) {
            if (country[i].country_code !== undefined) {
              country[i].country_code?.forEach((country: any) => {
                if (country?.is_selected === true) {
                  arr.selectedCountryCode.push({
                    key: country.enum_id,
                    text: country.enum_value,
                    is_default: country?.is_default === true && true,
                  });
                }
                arr.countryCode.push({
                  key: country.enum_id,
                  text: country.enum_value,
                  is_default: country?.is_default === true && true,
                });
              });
              arr.selectedCountryCode.push({ key: "more", text: t("more") });
              arr.countryCode.unshift({ key: "less", text: t("less") });
              //   arr.countryCode = convertArrToDropdropdown(country[i].country_code)
            }
          }
        }
        if (enums[i].customer !== undefined) {
          let c = enums[i].customer;
          for (let i = 0; i < c.length; i++) {
            if (c[i].ID_type_id !== undefined)
              arr.typeIdentityNumbers = convertArrToDropdropdown(
                c[i].ID_type_id
              );
          }
        }
        if (enums[i].exchange !== undefined) {
          let card = enums[i].exchange;
          for (let i = 0; i < card.length; i++) {
            if (card[i].card_brand_code !== undefined)
              arr.card_brand_code = convertArrToDropdropdown(
                card[i].card_brand_code
              );
          }
        }
      }

      let defaultCardValue: any;
      defaultCardValue = arr?.card_brand_code.find(
        ({ is_default }: any) => is_default === true
      );
      let defaultTypeValue: any;
      defaultTypeValue = arr?.typeIdentityNumbers.find(
        ({ is_default }: any) => is_default === true
      );
      setDefaultIdTypeId(defaultTypeValue?.key);
      let defaultCountryValue: any;
      defaultCountryValue = arr?.countryCode.find(
        ({ is_default }: any) => is_default === true
      );
      setDefaultCountry(defaultCountryValue);
      if (
        datacConversion[tableType] &&
        !datacConversion[tableType][index]?.financial_asset_details?.card_holder
      ) {
        setcreditFromCustomer({
          ...creditFromCustomer,
          idTypeId: defaultTypeValue?.key,
          country_code: defaultCountryValue?.key,
          idTypeCountryCode: defaultCountryValue?.key,
          card_brand_code: defaultCardValue?.key,
        });
      }
      setenumsDropdown(arr);
    }
  }, [enums]);

  useEffect(() => {
    if (
      enumsDropdown?.selectedCountryCode &&
      creditFromCustomer?.country_code
    ) {
      if (isLess) {
        let isExsist = enumsDropdown.selectedCountryCode.find(
          (item: any) => item.key === creditFromCustomer?.country_code
        );
        !isExsist && setisLess(false);
      }
    }
  }, [enumsDropdown?.selectedCountryCode, creditFromCustomer?.country_code]);

  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog();
    }
  }, [setShowDialog]);
  useEffect(() => {
    if (contraCreditCard?.length === 1) {
      changeConDetails("contra_id_account", contraCreditCard[0].key);
    }
  }, [contraCreditCard]);

  useEffect(() => {
    emptyRequiredFields && setRequiredField(true);
  }, [emptyRequiredFields]);

  useEffect(() => {
    let listCards: any[] = [{ key: "0", text: "חדש" }];
    if (searchCustomer && searchCustomer !== 0) {
      if (creditFromCustomer?.idNumber !== searchCustomer?.idNumber) {
        setreadOnly(true);
        let obj = {
          firstName: "",
          lastName: "",
          idTypeCountryCode: "",
          idTypeId: "",
          idNumber: "",
          card_brand_code: "",
          card_brand_name: "",
          country_code: "",
          card_pan: "",
        };
        obj.firstName = searchCustomer?.firstName;
        obj.lastName = searchCustomer?.lastName;
        obj.idNumber = searchCustomer?.idNumber;
        obj.idTypeId = searchCustomer?.idTypeId;
        obj.idTypeCountryCode = searchCustomer.idTypeCountryCode;
        obj.card_brand_name = "";
        obj.card_brand_code = "";
        setSelectedCard({
          key: obj.card_brand_code,
          text: obj.card_brand_name,
        });
        if (searchCustomer) {
          setcreditFromCustomer(obj);
        }
      }
      searchCustomer?.cards?.forEach((card: any) => {
        listCards.push({
          key: card.card_id,
          text: card.card_brand_name + " " + card.card_pan,
        });
      });
    } else {
      searchCustomer === 0 && setreadOnly(false);
    }
    setListCreditcards(listCards);
  }, [searchCustomer]);

  const selectCard = (key: string, value: any) => {
    let index = searchCustomer?.cards?.findIndex(
      (card: any) => card.card_id === value
    );
    if (index !== undefined && index !== -1) {
      let card_brand_code = searchCustomer?.cards[index]?.card_brand_code;
      let card_brand_name = searchCustomer?.cards[index]?.card_brand_name;
      let card_pan = searchCustomer?.cards[index]?.card_pan;
      let country_code = searchCustomer?.cards[index]?.country_code;
      setSelectedCard({
        key: searchCustomer?.cards[index].card_id,
        text: searchCustomer?.cards[index]?.card_brand_name,
      });
      setcreditFromCustomer({
        ...creditFromCustomer,
        card_brand_code: card_brand_code,
        card_brand_name: card_brand_name,
        card_pan: card_pan,
        country_code: country_code,
      });
    } else {
      if (value === "0") {
        let card_brand_code = "";
        let card_brand_name = "";
        let card_pan = "";
        let country_code = defaultCountry?.key;
        setSelectedCard({ key: "0", text: "חדש" });
        setcreditFromCustomer({
          ...creditFromCustomer,
          card_brand_code: card_brand_code,
          card_brand_name: card_brand_name,
          card_pan: card_pan,
          country_code: country_code,
        });
      }
    }
  };

  const changeCreditCustomer = (key: string, value: any) => {
    if (key === "idTypeCountryCode" && (value === "more" || value === "less")) {
      setisLess(!isLess);
    } else {
      value !== "" && !ifChanges && setIfChanges(true);
      if (key === "card_brand_code") {
        let getSelectedIndex = enumsDropdown?.card_brand_code.findIndex(
          (x: any) => x.key === value
        );
        setcreditFromCustomer({
          ...creditFromCustomer,
          [key]: value,
          card_brand_name:
            enumsDropdown?.card_brand_code[getSelectedIndex].text,
        });
      } else {
        setcreditFromCustomer({ ...creditFromCustomer, [key]: value });
      }
    }
  };

  const updateFinancialAssets = (financialAssetDetails: any) => {
    let arr: any = [];
    arr = [...convertions[tableType]];
    arr[index].financial_asset_details = financialAssetDetails;
    setConvertions({ ...convertions, [tableType]: arr });
  };

  const change = async (key: string, value: any) => {
    await setConvertions({ ...convertions, [key]: value });
  };
  const changeConDetails = (key: string, value: any) => {
    let i = index;
    let arr: any = [];
    arr = [...convertions[tableType]];
    let temp = {
      currency_code: key === "currency_code" ? value : arr[i]?.currency_code,
      financial_asset_code:
        key === "financial_asset_code" ? value : arr[i]?.financial_asset_code,
      financial_asset_details: arr[i]?.financial_asset_details,
      amount: key === "amount" ? Number(value) : arr[i]?.amount,
      exchange_rate_ils:
        key === "exchange_rate_ils" ? Number(value) : arr[i]?.exchange_rate_ils,
      amount_ils:
        key === "amount"
          ? value * arr[i]?.exchange_rate_ils
          : key === "exchange_rate_ils"
            ? value * arr[i]?.amount
            : arr[i]?.amount_ils,
      contra_id_account:
        key === "contra_id_account" ? value : arr[i]?.contra_id_account,
      row_number: arr[i]?.row_number,
    };
    arr[i] = temp;
    setIsContraAccount(true);
    change(tableType, arr);
  };
  const createCreditCustomer = async () => {
    let emptyRequiredFields = false;
    for (var key in creditFromCustomer) {
      if (isEmpty(creditFromCustomer[key])) emptyRequiredFields = true;
    }
    if (
      convertions[tableType][index]?.contra_id_account &&
      !emptyRequiredFields
    ) {
      let financialAssetDetails: any;
      if (searchCustomer?.idEntity && ifChanges) {
        await updateCustomerInfo(
          creditFromCustomer,
          userCredentials,
          searchCustomer?.idEntity
        );
      }

      if (
        searchCustomer?.idEntity &&
        selectedCard?.key &&
        selectedCard?.key !== "0"
      ) {
        financialAssetDetails = {
          card_id: selectedCard?.key,
          id_card_holder: searchCustomer?.idEntity,
        };
      } else {
        if (searchCustomer === 0) {
          financialAssetDetails = await dispatch(
            createCreditCard(creditFromCustomer, userCredentials, undefined)
          );
        } else {
          financialAssetDetails = await dispatch(
            createCreditCard(
              creditFromCustomer,
              userCredentials,
              searchCustomer?.idEntity
            )
          );
        }
      }
      updateFinancialAssets(financialAssetDetails);
      // setServerMessageError,setMessageError

      if (typeAction === "receipt") {
        dispatch(
          receiptTransaction(
            "update",
            typeAction,
            conversionCustomer.entityName,
            convertions,
            userCredentials,
            convertions?.id_entity,
            t,
            setshowSpiner
          )
        );
      }
      if (typeAction === "cheque_cashing" || typeAction === "exchange") {
        dispatch(
          createTransaction(
            "update",
            typeAction,
            conversionCustomer.entityName,
            convertions,
            userCredentials,
            convertions?.id_entity,
            setshowSpiner
          )
        );
      }
      setFocus(tableType, convertions, rowNumber);
      setShowDialog(false);
      toggleHideDialog();
    } else {
      setNote(t("notificationOfRequiredFields"));
      setShowNote(true);
      setRequiredField(true);
    }
  };
  return (
    <>
      <Dialog
        minWidth="xl"
        maxWidth="xl"
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          setShowDialog(false);
        }}
      >
        <p
          className="title-text"
          style={{ fontSize: "30px", fontWeight: "bold" }}
        >
          {t("receiptOnCreditFromCustomer")}
        </p>
        <hr className="hr"></hr>
        {showDialogFailed === true && (
          <DialogMessages
            setansDelete={setShowDialogFailed}
            setshowModalDelete={setShowDialogFailed}
            dialogType={"failed"}
          />
        )}
        {showNote && (
          <DialogMessages
            setansDelete={setShowNote}
            subText={note}
            title={t("error")}
            setshowModalDelete={setShowNote}
            dialogType={"dialogMessage"}
          />
        )}
        <div>
          <p className="title-text" style={{ marginTop: "20px" }}>
            {t("creditCardDetails")}
          </p>
          <hr className="hr" style={{ marginBottom: "0" }}></hr>
          {contraCreditCard?.length > 1 ? (
            <div>
              <label style={{ padding: "5px 0px", fontWeight: 600 }}>
                {t("selectCreditCard")}
                <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
              </label>
              <CustomDropdown
                required={true}
                requiredField={
                  (requiredField || emptyRequiredFields) &&
                    !convertions[tableType][index]?.contra_id_account
                    ? true
                    : false
                }
                readOnly={false}
                otherInputId={""}
                hasOtherValue={false}
                options={contraCreditCard}
                label={""}
                onChange={changeConDetails}
                selectedKey={convertions[tableType][index]?.contra_id_account}
                id={"contra_id_account"}
                othertextInput={t("")}
              />
            </div>
          ) : (
            <CustomTextField
              required={true}
              value={
                contraCreditCard?.find(
                  (e: any) =>
                    e.key === convertions[tableType][index]?.contra_id_account
                )?.text
              }
              readOnly={true}
              label={t("creditRegister")}
              onChange={""}
              id={"contra_id_account"}
            />
          )}
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "end",
            }}
          >
            <p className={`sub-title-text account-holder-${dir}`}>
              {t("cardHolderdetails")}{" "}
            </p>
            <div
              style={{
                marginTop: "20px",
                justifyContent: "space-between",
                display: "inline-flex",
              }}
            >
              <DialogSearch
                userCredentials={userCredentials}
                isSearchInsiders={false}
                setRelatedFactors={setSearchCustomer}
              />
              <FontAwesomeIcon
                icon={faPaste}
                title={t("copyCustomer")}
                onClick={() => {
                  setSearchCustomer(conversionCustomer);
                }}
                className={`iconRefresh-${dir} icon-copy-${dir}`}
              />{" "}
            </div>
          </div>
          <hr className="hr" style={{ marginBottom: "0" }}></hr>
          <div className="wrapper-collateral">
            <div className={`warpper-fields-right-${dir}`}>
              <CustomTextField
                required={true}
                requiredField={
                  (requiredField || emptyRequiredFields) &&
                    !creditFromCustomer?.firstName
                    ? true
                    : false
                }
                value={creditFromCustomer?.firstName}
                readOnly={false}
                label={t("firstnameCardHolder")}
                onChange={changeCreditCustomer}
                id={"firstName"}
              />
            </div>
            <div className={`warpper-fields-left-${dir}`}>
              <CustomTextField
                required={true}
                requiredField={
                  (requiredField || emptyRequiredFields) &&
                    !creditFromCustomer?.lastName
                    ? true
                    : false
                }
                value={creditFromCustomer?.lastName}
                readOnly={false}
                label={t("lastNameCardHolder")}
                onChange={changeCreditCustomer}
                id={"lastName"}
              />
            </div>
          </div>
          <div className="wrapper-id" style={{ marginBottom: "20px" }}>
            <IdNumber
              showRequiredFildes={
                (requiredField || emptyRequiredFields) &&
                !creditFromCustomer?.idNumber
              }
              entity={creditFromCustomer}
              setIfCahnges={setIfChanges}
              setEntity={setcreditFromCustomer}
              readOnly={false}
              setResultId={setResultId}
              resultId={resultId}
              typeIdentityNumbers={enumsDropdown.typeIdentityNumbers}
              typeIdDefault={defaultIdTypeId}
            />
          </div>
          <p className="sub-title-text">{t("cardDetails")}</p>
          <hr className="hr" style={{ marginBottom: "0" }}></hr>
          <div className="wrapper-collateral">
            <div className={`warpper-fields-right-${dir}`}>
              <CustomDropdown
                required={true}
                requiredField={
                  (requiredField || emptyRequiredFields) && !selectedCard?.key
                    ? true
                    : false
                }
                readOnly={false}
                otherInputId={""}
                hasOtherValue={false}
                options={listFreditCards}
                label={t("selectCard")}
                onChange={selectCard}
                selectedKey={selectedCard?.key}
                id={"selectedCardId"}
                othertextInput={t("")}
              />
            </div>
            <div className={`warpper-fields-left-${dir}`}>
              <label style={{ padding: "5px 0px", fontWeight: 600 }}>
                {t("cardBrandName")}
                <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
              </label>
              <CustomDropdown
                required={true}
                readOnly={selectedCard?.key === "0" ? false : readOnly}
                otherInputId={"card_brand_other"}
                otherValue={""}
                hasOtherValue={true}
                options={enumsDropdown?.card_brand_code}
                label={""}
                onChange={changeCreditCustomer}
                selectedKey={
                  creditFromCustomer?.card_brand_code !== ""
                    ? creditFromCustomer?.card_brand_code
                    : ""
                }
                requiredField={
                  (requiredField || emptyRequiredFields) &&
                    !creditFromCustomer?.card_brand_code
                    ? true
                    : false
                }
                id={"card_brand_code"}
                othertextInput={t("other")}
              />
            </div>
          </div>
          <div className="wrapper-collateral">
            <div className={`warpper-fields-right-${dir}`}>
              {!isLess && (
                <CustomDropdown
                  required={true}
                  readOnly={selectedCard?.key === "0" ? false : readOnly}
                  requiredField={
                    (requiredField || emptyRequiredFields) &&
                      !creditFromCustomer?.country_code
                      ? true
                      : false
                  }
                  otherInputId={""}
                  hasOtherValue={false}
                  options={enumsDropdown?.countryCode}
                  label={t("issuingCountry")}
                  onChange={changeCreditCustomer}
                  selectedKey={creditFromCustomer?.country_code}
                  id={"country_code"}
                  othertextInput={t("")}
                />
              )}
              {isLess && (
                <CustomDropdown
                  required={true}
                  readOnly={selectedCard?.key === "0" ? false : readOnly}
                  requiredField={
                    (requiredField || emptyRequiredFields) &&
                      !creditFromCustomer?.country_code
                      ? true
                      : false
                  }
                  otherInputId={""}
                  hasOtherValue={false}
                  options={enumsDropdown?.selectedCountryCode}
                  label={t("issuingCountry")}
                  onChange={changeCreditCustomer}
                  selectedKey={creditFromCustomer?.country_code}
                  id={"country_code"}
                  othertextInput={t("")}
                />
              )}
            </div>
            <div className={`warpper-fields-left-${dir}`}>
              <CustomTextField
                required={true}
                value={creditFromCustomer?.card_pan}
                readOnly={selectedCard?.key === "0" ? false : readOnly}
                label={t("lastDigit")}
                onChange={changeCreditCustomer}
                requiredField={
                  (requiredField || emptyRequiredFields) &&
                    !creditFromCustomer?.card_pan
                    ? true
                    : false
                }
                id={"card_pan"}
              />
            </div>
          </div>
        </div>

        <DialogFooter>
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
              setShowDialog(false);
              setFocus(tableType, convertions, rowNumber);
            }}
            text={t("cancel")}
          />
          <PrimaryButton
            onClick={() => {
              createCreditCustomer();
            }}
            text={t("create")}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};
export const CustomDialogCheckTable = (props: any) => {
  const { setShowDialog, itemUpdate, update } = props;
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);

  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [, /*enumsDropdown*/ setenumsDropdown] = useState<any>({
    countryCode: [],
    bank: [],
    branch: [],
  });
  const [, /*enumsBranch*/ setEnumsBranch] = useState<any>({ branch: [] });
  const [branchDetails, setBranchDetails] = useState([]);
  const [showDialogFailed, setShowDialogFailed] = useState(false);
  const [updateBankAccount, setUpdateBankAccount] = useState(
    itemUpdate
      ? itemUpdate
      : {
        key: "",
        country: "",
        accountNumber: "",
        bank: "",
        branch: "",
        branchAddress: "",
        IBAN: "",
        note: "",
        branchName: "",
        bankName: "",
      }
  );
  console.log(updateBankAccount, "updateBankAccount=====");


  useEffect(() => {
    if (enums) {
      let arr = {
        branch: convertArrToDropdropdown([]),
        bank: convertArrToDropdropdown([]),
        countryCode: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].branch !== undefined) {
          let branch = enums[i].branch;
          for (let i = 0; i < branch.length; i++) {
            if (branch[i].entity_sub_type_id !== undefined)
              arr.branch = convertArrToDropdropdown(
                branch[i].entity_sub_type_id
              );
          }
        }
        if (enums[i].bank_account !== undefined) {
          let bank = enums[i].bank_account;
          for (let i = 0; i < bank.length; i++) {
            if (bank[i].bank_code !== undefined)
              arr.bank = convertArrToDropdropdown(bank[i].bank_code);
          }
        }
        if (enums[i].general !== undefined) {
          let country = enums[i].general;
          for (let i = 0; i < country.length; i++) {
            if (country[i].country_code !== undefined)
              arr.countryCode = convertArrToDropdropdown(
                country[i].country_code
              );
          }
        }
      }
      if (update === undefined) {
        let defaultCauntryValue: any;
        defaultCauntryValue = arr?.countryCode.find(
          ({ is_default }: any) => is_default === true
        );
        setUpdateBankAccount({
          ...updateBankAccount,
          country: defaultCauntryValue.key,
        });
      }

      setenumsDropdown(arr);
    }
  }, [enums]);

  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog();
    }
  }, [setShowDialog]);
  const columns = [
    {
      key: "1",
      name: t("maturityDate"),
      fieldName: "date",
      entity_name: "date",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: "2",
      name: t("amount"),
      fieldName: "sumCheck",
      entity_name: "sumCheck",
      minWidth: 70,
      maxWidth: 70,
    },
    {
      key: "3",
      name: t("currency"),
      fieldName: "currencyCheck",
      entity_name: "currencyCheck",
      minWidth: 50,
      maxWidth: 50,
    },
    {
      key: "4",
      name: t("account"),
      fieldName: "account",
      entity_name: "account",
      minWidth: 70,
      maxWidth: 70,
    },
    {
      key: "5",
      name: t("bank"),
      fieldName: "bank",
      entity_name: "bank",
      minWidth: 70,
      maxWidth: 70,
    },
    {
      key: "6",
      name: t("branch"),
      fieldName: "branch",
      entity_name: "branch",
      minWidth: 50,
      maxWidth: 50,
    },
    {
      key: "7",
      name: t("chequeNumber"),
      fieldName: "numCheck",
      entity_name: "numCheck",
      minWidth: 50,
      maxWidth: 50,
    },
    {
      key: "8",
      name: t("maturityDays"),
      fieldName: "days",
      entity_name: "days",
      minWidth: 70,
      maxWidth: 70,
    },
    {
      key: "9",
      name: t("operations"),
      fieldName: "operationsCheck",
      entity_name: "operationsCheck",
      minWidth: 90,
      maxWidth: 90,
    },
  ];
  const items = [
    {
      key: 0,
      date: "10/12/2021",
      sumCheck: "22,000",
      currencyCheck: "ש'ח",
      account: "125541",
      bank: "12- לאומי",
      branch: "253",
      numCheck: "95698",
      days: "5",
    },
    {
      key: 1,
      date: "10/12/2021",
      sumCheck: "22,000",
      currencyCheck: "ש'ח",
      account: "125541",
      bank: "12- לאומי",
      branch: "253",
      numCheck: "95698",
      days: "5",
    },
    {
      key: 2,
      date: "10/12/2021",
      sumCheck: "22,000",
      currencyCheck: "ש'ח",
      account: "125541",
      bank: "12- לאומי",
      branch: "253",
      numCheck: "95698",
      days: "5",
    },
    {
      key: 3,
      date: "10/12/2021",
      sumCheck: "22,000",
      currencyCheck: "ש'ח",
      account: "125541",
      bank: "12- לאומי",
      branch: "253",
      numCheck: "95698",
      days: "5",
    },
  ];

  useEffect(() => {
    let items: any = [];
    branchDetails?.forEach((e) => {
      items.push({
        key: e["enum_id"],
        text: e["enum_value"] + ` (${e["enum_id"]})`,
        address: e["branch_address"],
      });
    });
    setEnumsBranch(items);
  }, [branchDetails]);

  return (
    <>
      {showDialogFailed === true && (
        <DialogMessages
          setansDelete={setShowDialogFailed}
          setshowModalDelete={setShowDialogFailed}
          dialogType={"failed"}
        />
      )}
      ‏
      <Dialog
        minWidth="xl"
        maxWidth="xl"
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          setShowDialog(false);
        }}
      >
        <p
          className="title-text"
          style={{ fontSize: "30px", fontWeight: "bold" }}
        >
          {t("chequesReceivedFromCustomer")}
        </p>
        <hr className="hr"></hr>
        <div className={`div-list-cheque-${dir}`}>
          <div
            className="attechedA"
            style={{ width: "900px", marginTop: "2%" }}
          >
            <p className="title-text">{t("chequesList")}</p>
            <p className="title-text" style={{ cursor: "pointer" }}>
              {"(+)"}
            </p>
          </div>{" "}
          <hr className="hr" style={{ width: "900px", marginBottom: "0" }}></hr>
          <div style={{ width: "900px" }}>
            <CustemTable
              tableName="checkTable"
              columns={columns}
              allItems={items}
              renderItemColumn
              rederRow={"operationsCheck"}
              addCustem={true}
              isFooter={false}
            />
          </div>
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
              setShowDialog(false);
            }}
            text={t("cancel")}
          />
          <PrimaryButton
            onClick={() => {
              ("");
            }}
            text={t("update")}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export const CashPaymentDialog = (props: any) => {
  const {
    setShowDialog,
    itemUpdate,
    contraCash,
    rowNumber,
    setConvertions,
    convertions,
    tableType,
    setIfChanges,
  } = props;
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [t, i18n] = useTranslation();
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [enumsDropdown, setenumsDropdown] = useState<any>({
    countryCode: [],
    bank: [],
    branch: [],
  });
  const [readOnly, setreadOnly] = useState(false);
  const [value, setValue] = useState<any>();
  const [key, setKey] = useState<any>();
  const index = convertions[tableType]?.findIndex(
    (item: any) => Number(item.row_number) === Number(rowNumber)
  );

  useEffect(() => {
    if (enums) {
      let arr = {
        branch: convertArrToDropdropdown([]),
        bank: convertArrToDropdropdown([]),
        countryCode: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].branch !== undefined) {
          let branch = enums[i].branch;
          for (let i = 0; i < branch.length; i++) {
            if (branch[i].entity_sub_type_id !== undefined) {
              arr.branch = convertArrToDropdropdown(
                branch[i].entity_sub_type_id
              );
            }
          }
        }
        if (enums[i].bank_account !== undefined) {
          let bank = enums[i].bank_account;
          for (let i = 0; i < bank.length; i++) {
            if (bank[i].bank_code !== undefined)
              arr.bank = convertArrToDropdropdown(bank[i].bank_code);
          }
        }
        if (enums[i].general !== undefined) {
          let country = enums[i].general;
          for (let i = 0; i < country.length; i++) {
            if (country[i].country_code !== undefined)
              arr.countryCode = convertArrToDropdropdown(
                country[i].country_code
              );
          }
        }
      }
      setenumsDropdown(arr);
    }
  }, [enums]);

  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog();
    }
  }, [setShowDialog]);

  useEffect(() => {
    if (convertions && !value) {
      if (convertions[tableType][index]?.contra_id_account) {
        setValue(convertions[tableType][index]?.contra_id_account);
      } else {
        let defaultcontraCash = contraCash.find(
          (item: any) => item.is_default === true
        );
        let arr: any = [...convertions[tableType]];
        arr[index].contra_id_account = defaultcontraCash?.key;
        setConvertions((convertions: any) => ({
          ...convertions,
          [tableType]: arr,
        }));
        setValue(defaultcontraCash?.key);
      }
    }
  }, [convertions]);

  const change = async () => {
    let i = index;
    let arr: any = [];
    arr = [...convertions[tableType]];
    let temp = {
      currency_code: key === "currency_code" ? value : arr[i]?.currency_code,
      financial_asset_code:
        key === "financial_asset_code" ? value : arr[i]?.financial_asset_code,
      financial_asset_details: arr[i]?.financial_asset_details,
      amount: key === "amount" ? Number(value) : arr[i]?.amount,
      exchange_rate_ils:
        key === "exchange_rate_ils" ? Number(value) : arr[i]?.exchange_rate_ils,
      amount_ils:
        key === "amount"
          ? value * arr[i]?.exchange_rate_ils
          : key === "exchange_rate_ils"
            ? value * arr[i]?.amount
            : arr[i]?.amount_ils,
      contra_id_account:
        key === "contra_id_account" ? value : arr[i]?.contra_id_account,
      row_number: arr[i]?.row_number,
    };
    arr[i] = temp;
    setIfChanges && setIfChanges(true);
    setConvertions((convertions: any) => ({
      ...convertions,
      [tableType]: arr,
    }));

    setShowDialog(false);
    toggleHideDialog();
  };

  const changeConDetails = (keyC: string, valueC: any) => {
    setValue(valueC);
    setKey(keyC);
  };

  return (
    <>
      <Dialog
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          setShowDialog(false);
        }}
      >
        <p
          className="title-text"
          style={{ fontSize: "25px", fontWeight: "bold" }}
        >
          {t("cashReceiptPayment")}
        </p>
        <hr className="hr"></hr>
        {/* className="wrap-dialog" */}
        <div>
          <p className="title-text">{t("cashRegisterDetails")}</p>
          <hr className="hr"></hr>
          <CustomDropdown
            readOnly={readOnly}
            otherInputId={""}
            hasOtherValue={false}
            options={contraCash && contraCash}
            label={t("selectCashRegister")}
            onChange={changeConDetails}
            selectedKey={value}
            id={"contra_id_account"}
            othertextInput={t("")}
          />
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
              setShowDialog(false);
              setFocus(tableType, convertions, rowNumber);
            }}
            text={t("cancel")}
          />
          <PrimaryButton
            onClick={() => {
              change();
              setFocus(tableType, convertions, rowNumber);
            }}
            text={t("update")}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export const IncomePaymentDialog = (props: any) => {
  const {
    setShowDialog,
    contraIncomes,
    rowNumber,
    setConvertions,
    convertions,
    tableType,
    setIfChanges,
  } = props;
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [t, i18n] = useTranslation();
  const [readOnly, setreadOnly] = useState(false);
  const [value, setValue] = useState<any>();
  const [key, setKey] = useState<any>();
  const index = convertions[tableType]?.findIndex(
    (item: any) => Number(item.row_number) === Number(rowNumber)
  );

  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog();
    }
  }, [setShowDialog]);

  useEffect(() => {
    if (convertions && !value) {
      if (convertions[tableType][index]?.contra_id_account) {
        setValue(convertions[tableType][index]?.contra_id_account);
      } else {
        let defaultcontraIncome = contraIncomes.find(
          (item: any) => item.is_default === true
        );
        let arr: any = [...convertions[tableType]];
        arr[index].contra_id_account = defaultcontraIncome?.key;
        setConvertions((convertions: any) => ({
          ...convertions,
          [tableType]: arr,
        }));
        setValue(defaultcontraIncome?.key);
      }
    }
  }, [convertions]);

  const change = async () => {
    let i = index;
    let arr: any = [];
    arr = [...convertions[tableType]];
    let temp = {
      ...arr[i],
      contra_id_account:
        key === "contra_id_account" ? value : arr[i]?.contra_id_account,
    };
    arr[i] = temp;
    setIfChanges && setIfChanges(true);
    setConvertions((convertions: any) => ({
      ...convertions,
      [tableType]: arr,
    }));
    setShowDialog(false);
    toggleHideDialog();
  };

  const changeConDetails = (keyC: string, valueC: any) => {
    setValue(valueC);
    setKey(keyC);
  };

  return (
    <>
      <Dialog
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          setShowDialog(false);
        }}
      >
        <p
          className="title-text"
          style={{ fontSize: "25px", fontWeight: "bold" }}
        >
          {t("incomess")}
        </p>
        <hr className="hr"></hr>
        <div>
          <p className="title-text">{t("chooseIncomeAccount")}</p>
          <hr className="hr"></hr>
          <CustomDropdown
            readOnly={readOnly}
            otherInputId={""}
            hasOtherValue={false}
            options={contraIncomes && contraIncomes}
            label={t("")}
            onChange={changeConDetails}
            selectedKey={value}
            id={"contra_id_account"}
            othertextInput={t("")}
          />
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
              setShowDialog(false);
              setFocus(tableType, convertions, rowNumber);
            }}
            text={t("cancel")}
          />
          <PrimaryButton
            onClick={() => {
              change();
              setFocus(tableType, convertions, rowNumber);
            }}
            text={t("update")}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export const setFocus = (tableType: any, convertions: any, rowNumber: any) => {
  const index = convertions[tableType]?.findIndex(
    (item: any) => Number(item.row_number) === Number(rowNumber)
  );
  if (tableType.includes("out")) {
    let nodes = document.querySelectorAll(".amount-out");
    (nodes[index] as HTMLElement)?.focus();
    let nodesDiscount = document.querySelectorAll(".discount-amount-out");
    (nodesDiscount[index] as HTMLElement)?.focus();
  } else {
    let nodes = document.querySelectorAll(".currency");
    (nodes[index * 2 + 1] as HTMLElement)?.focus();
    let nodesDiscount = document.querySelectorAll(".currency-discount");
    (nodesDiscount[index * 4 + 1] as HTMLElement)?.focus();
  }
};

export const CheckToCustomerDialog = (props: any) => {
  const {
    setShowDialog,
    itemUpdate,
    setConvertions,
    rowNumber,
    convertions,
    tableType,
    setsumIn,
    userCredentials,
    /*contraBankAccounts,*/ conversionCustomer,
    typeAction,
  } = props;
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);

  const [requiredField, setRequiredField] = useState(false);
  const [showRequiredDislogFields, setShowRequiredDislogFields] =
    useState(false);
  const dispatch = useDispatch();
  const [isValidDate, setIsValidDate] = useState<any>(true);
  const [contraBankAccounts, setcontraBankAccounts] = useState<any>([]);
  const authReducer = useSelector(({ authReducer }: any) => authReducer);
  const [contraAccounts, setcontraAccounts] = useState(
    authReducer?.data?.user.contra_accounts
  );
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [enumsDropdown, setenumsDropdown] = useState<any>({
    countryCode: [],
    bank: [],
    branch: [],
  });
  const [readOnly, setreadOnly] = useState(false);
  const datacConversion = useSelector(
    ({ conversionReducer }: any) => conversionReducer.dataConversion
  );
  const index = convertions[tableType]?.findIndex(
    (item: any) => Number(item.row_number) === Number(rowNumber)
  );
  const [checkDeatils, setcheckDeatils] = useState<any>({
    reference_number: "",
    cheque_date: "",
    is_customer_order_cheque: true,
    beneficiary_name: "",
  });

  useEffect(() => {
    if (contraAccounts) {
      let arrBank: any[] = [];
      for (let i = 0; i < contraAccounts.length; i++) {
        if (contraAccounts[i].contra_account_group_id === "bank_account") {
          arrBank.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
          });
        }
      }
      setcontraBankAccounts(arrBank);
    }
  }, [contraAccounts]);

  useEffect(() => {
    if (contraBankAccounts.length === 1) {
      changeConDetails("contra_id_account", contraBankAccounts[0].key);
    }
  }, [contraBankAccounts]);

  useEffect(() => {
    if (datacConversion && datacConversion[tableType]) {
      let index = datacConversion[tableType]?.findIndex(
        (item: any) => Number(item.row_number) === Number(rowNumber)
      );
      let detailsCheck =
        datacConversion[tableType][index]?.financial_asset_details;
      let check = {
        reference_number: detailsCheck?.reference_number,
        cheque_date: detailsCheck?.cheque_date,
        beneficiary_name: detailsCheck?.beneficiary_name,
        is_customer_order_cheque:
          detailsCheck?.is_customer_order_cheque === undefined
            ? true
            : detailsCheck?.is_customer_order_cheque,
        //  bank_account_id: detailsCheck?.bank_account_id, id_account_holder: detailsCheck?.id_account_holder, id_beneficiary: detailsCheck?.id_beneficiary
        // , id_endorsee: detailsCheck?.id_endorsee, cheque_endorses_number: detailsCheck?.cheque_endorses_number
      };
      setcheckDeatils(check);
    }
  }, [datacConversion]);

  useEffect(() => {
    if (enums) {
      let arr = {
        branch: convertArrToDropdropdown([]),
        bank: convertArrToDropdropdown([]),
        countryCode: convertArrToDropdropdown([]),
        currencyCode: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].branch !== undefined) {
          let branch = enums[i].branch;
          for (let i = 0; i < branch.length; i++) {
            if (branch[i].entity_sub_type_id !== undefined)
              arr.branch = convertArrToDropdropdown(
                branch[i].entity_sub_type_id
              );
          }
        }
        if (enums[i].bank_account !== undefined) {
          let bank = enums[i].bank_account;
          for (let i = 0; i < bank.length; i++) {
            if (bank[i].bank_code !== undefined)
              arr.bank = convertArrToDropdropdown(bank[i].bank_code);
          }
        }
        if (enums[i].general !== undefined) {
          let country = enums[i].general;
          for (let i = 0; i < country.length; i++) {
            if (country[i].country_code !== undefined)
              arr.countryCode = convertArrToDropdropdown(
                country[i].country_code
              );
            if (country[i].currency_code !== undefined)
              arr.currencyCode = convertArrToDropdropdown(
                country[i].currency_code
              );
          }
        }
      }
      setenumsDropdown(arr);
    }
  }, [enums]);

  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog();
    }
  }, [setShowDialog]);

  const createCheckObject = () => {
    if (
      checkDeatils?.cheque_date &&
      checkDeatils?.reference_number &&
      convertions[tableType][index]?.amount &&
      convertions[tableType][index]?.contra_id_account &&
      ((!checkDeatils?.is_customer_order_cheque &&
        checkDeatils?.beneficiary_name) ||
        checkDeatils?.is_customer_order_cheque)
    ) {
      setShowDialog(false);
      toggleHideDialog();
      let arr: any = [];
      arr = [...convertions[tableType]];
      arr[index].financial_asset_details = checkDeatils;
      setConvertions({ ...convertions, [tableType]: arr });
      //ask if need the servermessages & spinner?,setshowSpiner,setServerMessageError, setMessageError
      dispatch(
        createTransaction(
          "update",
          typeAction,
          conversionCustomer.entityName,
          convertions,
          userCredentials,
          convertions.id_entity
        )
      );
      // dispatch(createTransaction("update", typeAction, conversionCustomer.entityName, convertions, userCredentials, convertions.id_entity, "", ""))
    } else {
      setRequiredField(true);
      setShowRequiredDislogFields(true);
    }
  };
  const changeCheckDetails = async (key: string, value: any) => {
    await setcheckDeatils({ ...checkDeatils, [key]: value });
  };
  const change = async (key: string, value: any) => {
    await setConvertions({ ...convertions, [key]: value });
  };

  const changeConDetails = (key: string, value: any) => {
    let i = index,
      num = "0",
      gatesData: any,
      arr: any = [];
    arr = [...convertions[tableType]];
    if (key === "currency_code") {
      gatesData = sessionStorage.getItem("gatesArr");
      gatesData = JSON.parse(gatesData);
      if (gatesData && gatesData[value]) {
        let num1 = 1 / gatesData[value];
        num = num1.toFixed(2);
      }
    }
    let temp = {
      currency_code: key === "currency_code" ? value : arr[i]?.currency_code,
      financial_asset_code:
        key === "financial_asset_code" ? value : arr[i]?.financial_asset_code,
      financial_asset_details: arr[i]?.financial_asset_details,
      exchange_rate_ils:
        key === "exchange_rate_ils"
          ? Number(value)
          : key === "currency_code"
            ? parseFloat(num)
            : arr[i]?.exchange_rate_ils,
      amount: key === "amount" ? value : arr[i]?.amount,
      amount_ils:
        key === "amount"
          ? Number(value) * arr[i]?.exchange_rate_ils
          : key === "exchange_rate_ils"
            ? Number(value) * arr[i]?.amount
            : key === "currency_code"
              ? arr[i]?.amount * parseFloat(num)
              : arr[i]?.amount_ils,
      contra_id_account:
        key === "contra_id_account" ? value : arr[i]?.contra_id_account,
      row_number: arr[i]?.row_number,
    };
    arr[i] = temp;
    change(tableType, arr);
  };
  console.log('enums', enumsDropdown)
  return (
    <>
      <Dialog
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          setShowDialog(false);
        }}
      >
        {showRequiredDislogFields && (
          <DialogMessages
            setansDelete={setShowRequiredDislogFields}
            setshowModalDelete={setShowRequiredDislogFields}
            dialogType={"requiredFields"}
          />
        )}
        <p
          className="title-text"
          style={{ fontSize: "25px", fontWeight: "bold" }}
        >
          {t("chequeDeliveredToCustomer")}
        </p>
        <hr className="hr"></hr>
        <div className="wrap-dialog">
          <p className="title-text">{t("chequeDetails")}</p>
          <hr className="hr"></hr>
          <div className="wrapper-collateral">
            <div className={`warpper-fields-right-${dir}`}>
              <label className="amount-check-out">{t("amount")}</label>
              <CurrencyInput
                className={`currency padding-currency-${dir} currency-input`}
                id={"amount"}
                name={"amount"}
                placeholder="0.00"
                min="0"
                value={convertions[tableType][index]?.amount}
                readOnly={readOnly}
                style={{
                  width: "100%",
                  border:
                    requiredField && !convertions[tableType][index]?.amount
                      ? "3px solid #db3838"
                      : "",
                }}
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  changeConDetails(name || "", value)
                }
              />
              {/* <CustomTextField value={convertions[tableType][index]?.amount} min="0" readOnly={readOnly} label={"סכום"} onChange={changeConDetails} id={'amount'} /> */}
            </div>
            <div className={`warpper-fields-left-${dir}`}>
              <CustomDropdown
                requiredField={
                  requiredField && !convertions[tableType][index]?.currency_code
                    ? true
                    : false
                }
                otherInputId={""}
                hasOtherValue={false}
                options={enumsDropdown.currencyCode}
                label={t("currency")}
                onChange={changeConDetails}
                selectedKey={convertions[tableType][index]?.currency_code}
                id={"currency_code"}
                othertextInput={t("")}
              />
            </div>
          </div>
          <CustomTextField
            requiredField={
              requiredField && !checkDeatils?.reference_number ? true : false
            }
            value={checkDeatils?.reference_number}
            readOnly={readOnly}
            label={t("chequeNumber/reference")}
            onChange={changeCheckDetails}
            id={"reference_number"}
          />
          <CustomerDate
            showRequiredFildes={requiredField}
            emptysetIsValidDate={setIsValidDate}
            readOnly={readOnly}
            isValidDate={isValidDate}
            entity={checkDeatils}
            setEntity={setcheckDeatils}
            isDefaultDate={true}
            idDate="cheque_date"
            label={t("repaymentDate")}
            source={"futureDate"}
          />
          {contraBankAccounts.length > 1 ? (
            <CustomDropdown
              readOnly={false}
              otherInputId={""}
              hasOtherValue={false}
              options={contraBankAccounts}
              label={t("selectBankAcount")}
              onChange={changeConDetails}
              selectedKey={convertions[tableType][index]?.contra_id_account}
              id={"contra_id_account"}
              othertextInput={t("")}
              requiredField={
                requiredField &&
                  !convertions[tableType][index]?.contra_id_account
                  ? true
                  : false
              }
            />
          ) : (
            <CustomTextField
              requiredField={
                requiredField &&
                  !convertions[tableType][index]?.contra_id_account
                  ? true
                  : false
              }
              value={
                contraBankAccounts?.find(
                  (e: any) =>
                    e.key === convertions[tableType][index]?.contra_id_account
                )?.text
              }
              readOnly={true}
              label={t("bankAccount")}
              onChange={""}
              id={"contra_id_account"}
            />
          )}

          <Checkbox
            className="padding-items"
            label={t("chequeToDepositCustomer")}
            checked={
              checkDeatils?.is_customer_order_cheque === undefined
                ? true
                : checkDeatils?.is_customer_order_cheque
            }
            onChange={() => {
              setcheckDeatils({
                ...checkDeatils,
                is_customer_order_cheque: checkDeatils?.is_customer_order_cheque
                  ? false
                  : true,
                beneficiary_name:
                  !checkDeatils?.is_customer_order_cheque === false
                    ? checkDeatils?.beneficiary_name
                    : "",
              });
            }}
          />
          {checkDeatils?.is_customer_order_cheque === false && (
            <CustomTextField
              requiredField={
                requiredField && !checkDeatils?.beneficiary_name ? true : false
              }
              value={checkDeatils?.beneficiary_name}
              readOnly={readOnly}
              label={t("thirdPartyBeneficiaryName")}
              onChange={changeCheckDetails}
              id={"beneficiary_name"}
            />
          )}
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              setFocus(tableType, convertions, rowNumber);
              toggleHideDialog();
              setShowDialog(false);
            }}
            text={t("cancel")}
          />
          <PrimaryButton
            onClick={() => {
              setFocus(tableType, convertions, rowNumber);
              createCheckObject();
            }}
            text={t("create")}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};
export const AdvancedSearchDialog = (props: any) => {
  const { setShowDialog } = props;
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);

  const dispatch = useDispatch();
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [enumsDropdown, setenumsDropdown] = useState<any>({
    countryCode: [],
    bank: [],
    branch: [],
  });
  const [readOnly, setreadOnly] = useState(false);

  useEffect(() => {
    if (enums) {
      let arr = {
        branch: convertArrToDropdropdown([]),
        bank: convertArrToDropdropdown([]),
        countryCode: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].branch !== undefined) {
          let branch = enums[i].branch;
          for (let i = 0; i < branch.length; i++) {
            if (branch[i].entity_sub_type_id !== undefined)
              arr.branch = convertArrToDropdropdown(
                branch[i].entity_sub_type_id
              );
          }
        }
        if (enums[i].bank_account !== undefined) {
          let bank = enums[i].bank_account;
          for (let i = 0; i < bank.length; i++) {
            if (bank[i].bank_code !== undefined)
              arr.bank = convertArrToDropdropdown(bank[i].bank_code);
          }
        }
        if (enums[i].general !== undefined) {
          let country = enums[i].general;
          for (let i = 0; i < country.length; i++) {
            if (country[i].country_code !== undefined)
              arr.countryCode = convertArrToDropdropdown(
                country[i].country_code
              );
          }
        }
      }
      // if (update === undefined) {
      //   let defaultCauntryValue: any
      //   (defaultCauntryValue = arr?.countryCode.find(({ is_default }: any) => is_default === true))
      //   setUpdateBankAccount({ ...updateBankAccount, country: defaultCauntryValue.key })
      // }
      setenumsDropdown(arr);
    }
  }, [enums]);

  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog();
    }
  }, [setShowDialog]);

  return (
    <>
      <Dialog
        hidden={hideDialog}
        modalProps={modelProps}
        onDismiss={() => {
          toggleHideDialog();
          setShowDialog(false);
        }}
      >
        <p
          className="title-text"
          style={{ fontSize: "25px", fontWeight: "bold" }}
        >
          {t("progressiveSearch")}
        </p>
        <hr className="hr"></hr>
        <div className="wrapper-collateral">
          <div className={`warpper-fields-right-${dir}`}>
            <CustomTextField
              value={""}
              readOnly={false}
              label={t("customerName")}
              onChange={""}
              id={"name"}
            />
          </div>
          <div className={`warpper-fields-left-${dir}`}>
            <CustomDropdown
              otherInputId={""}
              hasOtherValue={false}
              options={enumsDropdown.currencyCode}
              label={t("searchType")}
              onChange={""}
              selectedKey={""}
              id={"search_type"}
              othertextInput={t("")}
            />
          </div>
        </div>
        <div className="wrapper-collateral">
          <div className={`warpper-fields-right-${dir}`}>
            <CustomTextField
              value={""}
              readOnly={false}
              label={t("amount")}
              onChange={""}
              id={"name"}
            />
          </div>
          <div className={`warpper-fields-left-${dir}`}>
            <CustomDropdown
              otherInputId={""}
              hasOtherValue={false}
              options={enumsDropdown.currencyCode}
              label={t("searchType")}
              onChange={""}
              selectedKey={""}
              id={"search_type"}
              othertextInput={t("")}
            />
          </div>
        </div>
        <div className="wrapper-collateral">
          <div className={`warpper-fields-right-${dir}`}>
            <CustomTextField
              value={""}
              readOnly={false}
              label={"תאריך"}
              onChange={""}
              id={"name"}
            />
          </div>
          <div className={`warpper-fields-left-${dir}`}>
            <CustomDropdown
              otherInputId={""}
              hasOtherValue={false}
              options={enumsDropdown.currencyCode}
              label={t("searchType")}
              onChange={""}
              selectedKey={""}
              id={"search_type"}
              othertextInput={t("")}
            />
          </div>
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
              setShowDialog(false);
            }}
            text={t("cancel")}
          />
          <PrimaryButton
            onClick={() => {
              toggleHideDialog();
              setShowDialog(false);
            }}
            text={t("search")}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};
