import { useState, useEffect } from "react"
import HeaderUtils from '../shared/components/headerUtils/HeaderUtils'
import Subtitle from "../shared/components/Subtitle";
import { useTranslation } from 'react-i18next'
import { Pagination } from "@pnp/spfx-controls-react/lib/Pagination";
import { useSelector, useDispatch } from 'react-redux'
import { useBoolean } from '@fluentui/react-hooks';
import { IUserCredentials } from "./customerDetails/CustomerDetails";
import SearchBoxSmall from "../shared/components/TextSearch";
import { PrimaryButton } from '@fluentui/react';
import { AdvancedSearchDialog } from '../shared/components/dialog/Dialog';
import { readCompletedDoc } from "../store/actions/completeDocAction";
import ComleteEntityT from "./global/ComleteEntityT";
import { useLocation } from "react-router";
import { checkPermissionLevelOfPage } from "../shared/components/CustomHooks/GlobalFunctions";

export default function Search(props: any) {
    const { title, type, subTitle, path } = props;
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [item, setItem] = useState<any>([])
    const [arrayResult, setArrayResult] = useState<any>()
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [numResults, setnumResults] = useState(-1)
    const [curentPage, setCurentPage] = useState(1);
    const [search_fields, setSearch_fields] = useState<any>([]);
    const [advancedSearchDialog, setadvancedSearchDialog] = useState(false);
    const [numRecourd, setNumRecourd] = useState(10)
    const [loading, { setTrue: displayLoading, setFalse: hideLoading }] = useBoolean(true);
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    let arrSearch: any = []

    let objectItem: any[] = [];
    useEffect(() => {
       if( type !== "documents") { arrSearch.push({
            field_name: "document_type_id",
            search_type: "equal",
            search_value: type
        })
        setSearch_fields(arrSearch)
    }
    }, [type])

    useEffect(() => {
        if (authReducer)
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
    }, [authReducer])

    useEffect(() => {
        if (userCredentials && curentPage >= 1) {
            displayLoading()
            dispatch(readCompletedDoc(type, userCredentials, undefined,
                setArrayResult, curentPage,search_fields , numRecourd))
        }
        // eslint-disable-next-line
    }, [curentPage]);

    const _onPageUpdate = async (pageno?: number) => {
        var currentPge = (pageno) ? pageno : curentPage;
        setCurentPage(currentPge);
        setnumResults(arrayResult?.records_found)
    };

   
    const getCompletedData = async () => {
        displayLoading()
        await dispatch(readCompletedDoc(type, userCredentials, undefined,
            setArrayResult, curentPage,search_fields , numRecourd))
    }

    useEffect(() => {
        if (arrayResult) {
            _onPageUpdate();
            hideLoading();
            arrayResult?.search_results?.sort((a: any, b: any) => b.date_created.localeCompare(a.date_created));
            arrayResult?.search_results?.forEach((obj: any, index: number) => {
                objectItem?.push({
                    key: ++index, entity_name: obj.entity_name, ID_number: obj?.ID_number,
                    document_number: obj?.document_number, document_type_name: obj?.document_type_name,
                    document_type_id: obj?.document_type_id, id_document: obj?.id_document,
                    total_amount_ils: obj.total_amount_ils, document_date: obj.document_date,
                    id_entity: obj.id_entity, id_transaction: obj.id_transaction
                })
            });
            setItem(objectItem);
        }
        // eslint-disable-next-line
    }, [arrayResult])


    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
        ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={title} subTitle={"false"} createBtn={false} editBtn={false}
                deleteBtn={false} disabledSave={true} uploadBtn={false} />
            <br></br><br></br>
            <Subtitle title={t(subTitle)} />
            <div className={`content-div-${dir}`} style={{ width: "76%" }}>
                <p className="title-text" style={{ marginBottom: "2%" }}>{`${t("transactionsList")} ${title}`}</p>
                <div className="content-search">
                    <SearchBoxSmall label={t('search')} />
                    <PrimaryButton className={`bottun-${dir}`} onClick={getCompletedData} text={t("searchTransaction")} />
                    <PrimaryButton className={`bottun-${dir}`} onClick={() => setadvancedSearchDialog(true)} text={t("progressiveSearch")} />
                    {advancedSearchDialog ? <AdvancedSearchDialog setShowDialog={setadvancedSearchDialog} /> : ""}

                </div>
                {numResults === 0 ? <p style={{ marginBottom: '0' }}>{t('searchCustomerNotFound')}</p> : ""}
                {arrayResult?.search_results.length > 0 && <div style={{ width: "863px" }}><ComleteEntityT
                    type={type} data={item} arrayResult={arrayResult} displayLoading={displayLoading}
                    search_fields={search_fields} setSearch_fields={setSearch_fields}
                    setArrayResult={setArrayResult} path={path} /></div>}


                {arrayResult?.search_results.length > 0 && !loading &&
                    <div className="paginationContainer" style={{ maxWidth:`${type !== "documents"?"755px":"863px"}`, display: 'flex', justifyContent: "center", margin: "20px 0px", direction: "ltr" }}>
                        <div className="searchWp__paginationContainer__pagination">
                            <Pagination
                                currentPage={curentPage}
                                totalPages={Math.ceil(arrayResult?.records_found / arrayResult?.page_size)}
                                onChange={(page) => _onPageUpdate(page)}
                                limiter={3}
                            />
                        </div>
                    </div>}
            </div>
        </div>
    )
}
