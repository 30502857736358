import { useEffect, useState } from 'react'
import HeaderUtils from '../shared/components/headerUtils/HeaderUtils';
import Subtitle from '../shared/components/Subtitle';
import { CustomTextField } from '../shared/components/TextField';
import { CustomToggle } from '../shared/components/Toggle';
import { useDispatch, useSelector } from 'react-redux';
import { IUserCredentials } from './customerDetails/CustomerDetails';
import { saveEmailProp } from '../store/actions/settingsAction';
import { cloneDeep } from 'lodash';
import { DialogMessages } from '../shared/components/dialog/DialogMessages';
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../shared/components/CustomHooks/GlobalFunctions';

export default function UserEmail() {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    const [emailProp, setEmailProp] = useState<any>({});
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const dispatch = useDispatch()
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [readOnly, setreadOnly] = useState(true)
    const [updatEmailPropBeforeChanges, setUpdatEmailPropBeforeChanges] = useState()
    const [showDialogSave, setShowDialogSave] = useState(false)
    const [showDialogFailed, setShowDialogFailed] = useState(false)
    const [showSpiner, setshowSpiner] = useState(false)
    const [showTextRequiredFiles, setShowTextRequiredFiles] = useState(false)
    const [showRequiredFields, setShowRequiredFields] = useState(false)

    const spinnerStyles: Partial<ISpinnerStyles> = {
        root: {
            position: 'fixed',
            zIndex: '999999',
            fontWeight: 'bold',
        },
        circle: {
            color: '#FFB400 ',
            height: '100px',
            width: '100px',
        },
    }
    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
            setEmailProp(authReducer.data.branch.email_setting)
            setUpdatEmailPropBeforeChanges(authReducer.data.branch.email_setting)

        }
    }, [authReducer])

    const enterDetails = (id: any, value: any) => {
        setEmailProp({ ...emailProp ,[id]:value})
    }
    const check = (currentValue: string) => emailProp[currentValue]?.length > 0;

    const save = async () => {
        if (userCredentials) {
            let requiredFields: any = ["email_address", "password","display_name", "description", "smtp_host_name", "smtp_username", "smtp_password"]
            if (requiredFields.every(check)) {
                await dispatch(saveEmailProp(emailProp, userCredentials, setShowDialogSave, setShowDialogFailed, setshowSpiner))
                setUpdatEmailPropBeforeChanges(cloneDeep(emailProp))
                setreadOnly(true)
            }
            else {
                setShowTextRequiredFiles(true)
                setShowRequiredFields(true)
            }
        }
    }
    const cancelEdit = async () => {
        setEmailProp(cloneDeep(updatEmailPropBeforeChanges))
        setreadOnly(true)
    }

    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
        ? 'page-read' : 'page-read-only'}`}>
            <div>
                <HeaderUtils title={t("settings")} subTitle={"false"} disabledSave={readOnly ? true : false} createBtn={false} saveFunc={() => save()} editFunc={() => setreadOnly(false)} cancelFunc={() => cancelEdit()} editBtn={readOnly ? true : false} deleteBtn={false} uploadBtn={readOnly ? false : true} />
                <br></br><br></br>
                {showDialogSave && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"save"} />}
                {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} setshowModalDelete={setShowDialogFailed} dialogType={"failed"} />}
                {showRequiredFields && <DialogMessages setansDelete={setShowRequiredFields} setshowModalDelete={setShowRequiredFields} dialogType={"requiredFields"} />}
                {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
                <Subtitle title={t('email')} />
                <div className={`content-div-${dir}`}>
                    <div style={{ display: "flex", width: "86%" }}>
                        <p className="title-text" >{t("generalEmailSettings")}</p>
                    </div>
                    <hr className="hr" style={{ width: "340px" }} ></hr>
                    <div style={{ display: "flex", width: "340px" }}>
                        <p className="boldPP" style={{ width: "200px" }}>{t('email')}</p>
                        {/* <CustomTextField readOnly={false} label={"מספר חשבון"} onChange={(id: any, val: any) => (enterDetails(id, val))} id={'account_number'} /> */}
                        <CustomTextField requiredField={showTextRequiredFiles && !emailProp?.email_address ? true : false} readOnly={readOnly} value={emailProp?.email_address} onChange={(id: any, val: any) => (enterDetails(id, val))} id={"email_address"} />
                    </div>

                    <div style={{ display: "flex", width: "340px" }}>
                        <p className="boldPP" style={{ width: "200px" }}>{t("password")}</p>
                        {/* <div style={{ width: "40%" }}>iconProps={Icons.redEye} */}
                        <CustomTextField requiredField={showTextRequiredFiles && !emailProp?.password ? true : false} value={emailProp?.password} label={" "} readOnly={readOnly} onChange={(id: any, val: any) => (enterDetails(id, val))} id={"password"} />
                        {/* </div> */}
                    </div>
                    <div style={{ display: "flex", width: "340px" }}>
                        <p className="boldPP" style={{ width: "200px" }}>{t("displayName")}</p>
                        {/* requiredField={showTextRequiredFiles && !emailProp?.display_name ? true : false}  */}
                        <CustomTextField label={" "} value={emailProp?.display_name} readOnly={readOnly} onChange={(id: any, val: any) => (enterDetails(id, val))} id={"display_name"} />
                    </div>
                    <div style={{ display: "flex", width: "340px" }}>
                        <p className="boldPP" style={{ width: "200px" }}>{t("description")}</p>
                        <CustomTextField label={" "} readOnly={readOnly} requiredField={showTextRequiredFiles && !emailProp?.description ? true : false} value={emailProp?.description} onChange={(id: any, val: any) => (enterDetails(id, val))} id={"description"} />
                    </div>

                    <div style={{ display: "flex", width: "86%" }}>
                        <p className="title-text" >{t("generalEmailSettings")}</p>
                    </div>
                    <hr className="hr" style={{ width: "340px" }} ></hr>
                    <div style={{ display: "flex", width: "340px" }}>
                        <p className="boldPP" style={{ width: "200px" }}>{t("smtpHostname")}</p>
                        <CustomTextField label={" "} readOnly={readOnly} requiredField={showTextRequiredFiles && !emailProp?.smtp_host_name ? true : false} value={emailProp?.smtp_host_name} onChange={(id: any, val: any) => (enterDetails(id, val))} id={"smtp_host_name"} />
                    </div>
                    <div style={{ display: "flex", width: "340px" }}>
                        <p className="boldPP" style={{ width: "200px" }}>{t("smtpUsername")}</p>
                        <CustomTextField label={" "} readOnly={readOnly} requiredField={showTextRequiredFiles && !emailProp?.smtp_username ? true : false} value={emailProp?.smtp_username} onChange={(id: any, val: any) => (enterDetails(id, val))} id={"smtp_username"} />
                    </div>
                    <div style={{ display: "flex", width: "340px" }}>
                        <p className="boldPP" style={{ width: "200px" }}>{t("smtpPassword")}</p>
                        {/* <div style={{ width: "40%" }}> iconProps={Icons.redEye}*/}
                        <CustomTextField label={" "} readOnly={readOnly} requiredField={showTextRequiredFiles && !emailProp?.smtp_password ? true : false} value={emailProp?.smtp_password} onChange={(id: any, val: any) => (enterDetails(id, val))} id={"smtp_password"} />
                        {/* </div> */}
                    </div>
                    <div style={{ display: "flex", width: "340px",alignItems:"center" }}>
                        <p className="boldPP" style={{ width: "180px" }}>ssl</p>
                        {t("yes/no")}
                        <div className={`toggle-user-email-${dir}`}>
                            <CustomToggle onText={t("yes")} readOnly={readOnly} onChange={(id: any, val: any) => (enterDetails(id, val))} id={'ssl'} defaultChecked={false} checked={emailProp.ssl ? true : false} offText={t("no")} />
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}