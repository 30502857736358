import React, { useEffect, useState } from 'react';
import "./contact.scss";
import "../../scssPages/form.scss";
import CustemTable from '../../shared/components/tabels/tableList';
import { useTranslation } from 'react-i18next';
import Subtitle from '../../shared/components/Subtitle';
import {useDispatch, useSelector } from "react-redux";
import Title from '../../shared/components/Title';
import { Icon } from '@fluentui/react';
import { CustemDialogAddress, CustemDialogEmail, CustemDialogPhone } from '../../shared/components/dialog/Dialog';
import { IUserCredentials } from '../customerDetails/CustomerDetails';
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import { readCustomerId } from '../../store/actions/customerActioin';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions';

export interface IDetailsListItem {
  key: number;
  value: number;
  name: string;
}
const Contact = () => {

  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const dispatch = useDispatch()
  const userData = useSelector(({ customerReducer }: any) => customerReducer.dataCustomer)
  const [itemsAddress, setItemsAddress] = useState([{}])
  const customerReducer = useSelector(({ customerReducer }: any) => customerReducer)
  const [idEntity, setidEntity] = useState("")
  const [itemsTelephones, setItemsTelephones] = useState([{}])
  const [itemsEmails, setItemsEmails] = useState([{}])
  const [isClient,/*setisClient*/] = useState(false)
  const [showSpiner, setshowSpiner] = useState(false)
  const authReducer = useSelector(({ authReducer }: any) => authReducer)
  const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
  const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
  const location = useLocation()
  const columnsAddress = [
    { key: '1', name: t('addressType'), fieldName: "typeAddress", entity_name: "typeAddress", minWidth: 100, maxWidth: 100 },
    { key: '2', name: t("street"), fieldName: "streetName", entity_name: "streetName", minWidth: 110, maxWidth: 110 },
    { key: '3', name: t("number"), fieldName: "number", entity_name: "number", minWidth: 40, maxWidth: 100 },
    { key: '4', name: t('city'), fieldName: "cityName", entity_name: "cityName", minWidth: 100, maxWidth: 100 },
    { key: '5', name: t('postalCode'), fieldName: "postalCode", entity_name: "postalCode", minWidth: 50, maxWidth: 100 },
    { key: '6', name: t('country'), fieldName: "country", entity_name: "country", minWidth: 68, maxWidth: 100 },
    { key: '7', name: t('operations'), fieldName: "edit", minWidth: 90, maxWidth: 90 },

  ];
  const columnsTelephones = [
    { key: '1', name: t('TypePhone'), fieldName: "TypePhone", entity_name: "TypePhone", minWidth: 100, maxWidth: 100 },
    { key: '2', name: t('phone'), fieldName: "phone", entity_name: "phone", minWidth: 50, maxWidth: 100 },
    { key: '3', name: t('AreaCodeCountry'), fieldName: "AreaCodeCountry", entity_name: "AreaCodeCountry", minWidth: 100, maxWidth: 100 },
    { key: '4', name: t('operations'), fieldName: "edit", entity_name: "operations", minWidth: 90, maxWidth: 90 },
  ];
  const columnsEmail = [
    { key: '1', name: t('emailAddressType'), fieldName: "emailAddressType", entity_name: "emailAddressType", minWidth: 120, maxWidth: 120 },
    { key: '2', name: t('emailAddress'), fieldName: "emailAddress", entity_name: "emailAddress", minWidth: 180, maxWidth: 180 },
    { key: '3', name: t('operations'), fieldName: "edit", entity_name: "operations", minWidth: 90, maxWidth: 90 },
  ];
  const spinnerStyles: Partial<ISpinnerStyles> = {
    root: {
      position: 'fixed',
      zIndex: '999999',
      fontWeight: 'bold',
    },
    circle: {
      color: '#FFB400 ',
      height: '100px',
      width: '100px',
    },
  }
  const headerStyle = {
    cellTitle: {
      color: "#1A1F71",
      background: '#F4F2FF',
      border: '8px'
    }
  }


  useEffect(() => {

    setUserCredentials({
      "idInitiator": authReducer.data.user.id_entity,
      "idClient": isClient === true ? '00000000-0000-0000-0000-000000000001' : authReducer.data.user.id_client,
      "idBranch": authReducer.data.branch.id_entity,
      "idClientData": authReducer.data.client.id_client
    })

  }, [authReducer]);
  useEffect(() => {
    setidEntity(customerReducer.dataCustomer?.id_entity)
  }, [customerReducer])
  const AddressData = () => {
    let arrAddress: object[] = [];


    userData?.addresses?.forEach((element: any, index: number) => {
      if (element.is_deleted === false)
        arrAddress.push({
          key: element?.address_id, typeAddress: element.address_type_name, typeAddressId: element.address_type_id.toString(),
          street: element?.address_street_code, streetName: element?.address_street, number: element.address_number, cityName: element.address_city,
          city: element?.address_city_code, postalCode: element.address_zip_code, country: element.address_country_name, operations: "",
          addressId: element.address_id, addressDefault: element.is_default, addressCountryCode: element.address_country_code
        })
    });
    setItemsAddress(arrAddress)
  }

  const TelephonesData = () => {
    let arrTelephones: object[] = []
    userData?.telephones?.forEach((element: any, index: number) => {
      if (element.is_deleted === false)
        arrTelephones.push({
          key: index++, TypePhone: element.telephone_type_name, typePhoneId: element.telephone_type_id.toString(), phone: element.telephone_number,
          AreaCodeCountry: element.telephone_country_code, operations: "", telephoneId: element.telephone_id, telephoneDefault: element.is_default
        })
    });
    setItemsTelephones(arrTelephones)
  }

  const emailData = () => {
    let arrEmails: object[] = []
    userData?.emails?.forEach((element: any, index: number) => {
      if (element.is_deleted === false)
        arrEmails.push({ key: index++, emailAddressType: element.email_type_name, emailTypeId: element.email_type_id.toString(), emailAddress: element.email_address, operations: "", emailId: element.email_id, emailDefault: element.is_default })
    });
    setItemsEmails(arrEmails)
  }

  useEffect(() => {
    AddressData()
    TelephonesData()
    emailData()
  }, [userData])


  const [addAddress, setaddAddress] = useState(false)
  const [addPhone, setaddPhone] = useState(false)
  const [addEmail, setaddEmail] = useState(false)
  return (
    <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
    ? 'page-read' : 'page-read-only'}`}>
      <div className={`sub-header-${dir} fixed-${dir}`}>
        <Title
          title={t("customers")} nameInTitle={userData?.entity_name} />
        <div className="divider"></div><div className="divider"></div>
        
        {/* <IconButton
          iconProps={Icons.pdf}
          styles={{
            icon: { color: 'red', fontSize: 36 }
          }}
          className="button"
          onClick={() => GenericPdfDownloader({ rootElementId: "customerPage", downloadFileName: "noname" })}
        /> */}
        {/* <IconButton
          iconProps={Icons.print}
          styles={{
            icon: { color: 'black', fontSize: 36 }
          }}
          className="button"

        /> */}
        {/* <ExportCSV fileName={"xcelCustomer"} /> */}
         <div className="button"
          style={{
            cursor: "pointer"
          }}
          title='רענן'
          onClick={() => {
            dispatch(readCustomerId(userData?.id_entity,
              userCredentials, "", "", "", false));
          }}
        > <Icon iconName="refresh"></Icon></div>
      </div>
      <br></br><br></br>
      {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
      <Subtitle title={t("contactInformation")} />
      <div className={`content-div-${dir}`}>
        <div className={`attechedCon atteched-contact-${dir}`} >
          <p className="title-text"style={{ width: "670px" }} >{t('addresses')}</p>
          <div className="title-text addFile"  onClick={() => setaddAddress(true)} ><i className="fal fa-plus"></i></div>
          {addAddress ? <CustemDialogAddress setshowSpiner={setshowSpiner} setShowDialog={setaddAddress} idEntity={idEntity} userCredentials={userCredentials} /> : ""}
        </div>
        <hr className="hr" style={{ width: "670px" }}></hr>
      </div>
      <div className={`address-contact-${dir}`}>
        {itemsAddress.length === 0 ? <div style={{ marginTop: "18px" }}>{t('noDisplay')}</div> : <CustemTable tableName="address" setshowSpiner={setshowSpiner} columns={columnsAddress} allItems={itemsAddress} renderItemColumn rederRow={"edit"} addCustem={true} isFooter={false} />}</div>


      <div className={`content-div-${dir}`}>
        <div className={`attechedCon atteched-contact-${dir}`}>
          <p className="title-text" style={{ width: "670px" }}>{t('phones')}</p>
          <div className="title-text addFile"  onClick={() => setaddPhone(true)} ><i className="fal fa-plus"></i></div>
          {addPhone ? <CustemDialogPhone setshowSpiner={setshowSpiner} setShowDialog={setaddPhone} userCredentials={userCredentials} idEntity={idEntity} /> : ""}
        </div>
        <hr className="hr" style={{ width: "670px" }}></hr>
      </div>
      <div className={`phone-contact-${dir}`}>
        {itemsTelephones.length === 0 ? <div style={{ marginTop: "18px" }}>{t('noDisplay')}</div> : <CustemTable tableName="phone" setshowSpiner={setshowSpiner} columns={columnsTelephones} allItems={itemsTelephones} renderItemColumn rederRow={"edit"} addCustem={true} isFooter={false} />}
      </div>

      <div className={`content-div-${dir}`}>
        <div className={`attechedCon atteched-contact-${dir}`}>
          <p className="title-text" style={{ width: "670px" }}>{t('emails')}</p>
          <div className="title-text addFile"  onClick={() => setaddEmail(true)} ><i className="fal fa-plus"></i></div>
          {addEmail ? <CustemDialogEmail setshowSpiner={setshowSpiner} setShowDialog={setaddEmail} userCredentials={userCredentials} idEntity={idEntity} /> : ""}
        </div>
        <hr className="hr" style={{ width: "670px" }}></hr>
      </div>
      <div className={`email-contact-${dir}`}>
        {itemsEmails.length === 0 ? <div style={{ marginTop: "18px" }}>{t('noDisplay')}</div> : <CustemTable tableName="email" setshowSpiner={setshowSpiner} columns={columnsEmail} allItems={itemsEmails} renderItemColumn rederRow={"edit"} addCustem={true} isFooter={false} />}
      </div>

    </div>

  );
}
export default Contact;

