
import './header.scss';
import { Logout } from '../auth/Logout';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { IAppNavbar } from '../../modelsType/type/interface';
import { useEffect, useState } from 'react';
import { IconButton, registerIcons } from 'office-ui-fabric-react';
import { IButtonProps } from '@fluentui/react/lib/Button';

import { useHistory } from 'react-router';

import '../../modelsType/Icons/css/all.css'
import { SET_LANGUAGE } from '../../store/actions/types';

const Header = ({ auth }: IAppNavbar) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch()
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const history = useHistory();
  const [userName, setUserName] = useState("");

  registerIcons({
    icons: {
      language: <i className={"i-globe fal fa-globe"}></i>,
    }
  });

  const authReducer = useSelector(({ authReducer }: any) => authReducer)
  const overflowProps: IButtonProps = { ariaLabel: 'More commands' };
  useEffect(() => {
    if (!authReducer?.data?.user) return
    setUserName(authReducer.data.user.first_name + " " + authReducer.data.user.last_name);
  }, [authReducer]);



  useEffect(() => {
    let userLanguage = authReducer?.data?.user?.preferences?.user_language
    if (userLanguage) {
      let dir = userLanguage === "HE" ? "rtl" : "ltr"
      let lang = userLanguage === "HE" ? "he" : "en"
      document.body.dir = dir
      document.documentElement.lang = lang;
      document.documentElement.dir = dir
      i18n.changeLanguage(lang);
      dispatch({
        type: SET_LANGUAGE,
        payload: dir
      })

    }
  }, [authReducer])
  // const isLogin = useSelector((state) => state.auth)
  const handleHomePage = () => {

    history.push("/home_page")
  }

  return (
    <>
      <div className="header">
        <div className="menueHeader">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="user">
          <div >
            <IconButton
              onClick={handleHomePage}
              className="iconHeaders fal fa-warehouse"
              styles={{
                icon: { color: "rgba(217, 157, 12, 0.88)", fontSize: 15 },
                root: {
                  selectors: {
                    ':active .ms-Button-icon': {
                      color: 'rgba(217, 157, 12, 0.88)'
                    }
                  },
                },
                rootHovered: { backgroundColor: '1A1F71' },
                rootPressed: { backgroundColor: '1A1F71' }
              }}
            /></div>
          <p className={`user-name p-header-${dir}`} >{userName}</p>
          <Logout />
        </div>
        <div className='divider'>
        </div>

        <div className="name-company">
          <p >InSight Systems 2.0</p>
        </div>


        {/* <FontIcon iconName="IncreaseIndentArrowMirrored" className="logoutIcon"/> */}
      </div>
      {/* למה זה לא מופיעה */}

    </>
  )
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,


});

export default connect(mapStateToProps, null)(Header);
