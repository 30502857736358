import React, { useState } from 'react'
import { Checkbox, Stack, ICheckboxStyles } from 'office-ui-fabric-react'
import { useDispatch, useSelector } from 'react-redux'

import Subtitle from '../../shared/components/Subtitle'
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions'
import { useLocation } from 'react-router'
import { updateUserBranchModules } from '../../store/actions/authActions'

export default function SettingsModulesClient() {

    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const modules = useSelector(({ authReducer }: any) => authReducer?.data?.modules)
    const modulesUserBranch = useSelector(({ authReducer }: any) => authReducer?.data?.user?.modules)
    const userCredentials = useSelector(({ authReducer }: any) => authReducer?.userCredentials)
    const [modulesArr, setModulesArr] = useState<any>(modulesUserBranch ?? modules)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation();
    const dispatch = useDispatch();
    const moduleCheckboxStyles: ICheckboxStyles = {
        checkbox: {
            marginBottom: "20px"
        },
        label: {
            span: {
                fontSize: "20px",
                fontWeight: "bold"
            }
        },
    };
    const PagesCheckboxStyles: ICheckboxStyles = {
        checkbox: {
            marginBottom: "15px",
            marginLeft: "30px",
        },
    };

    const handleModuleCheckboxChange = (moduleId: any, checked: any) => {
        setModulesArr(modulesArr.map((module: any) => {
            if (module.menu_id === moduleId) {
                const updatedPages = module.pages.map((page: any) => ({
                    ...page,
                    is_customer_default: checked
                }));
                return { ...module, pages: updatedPages };
            }
            return module;
        }));
    };

    const handlePageCheckboxChange = (moduleId: any, pageId: any, checked: any) => {
        setModulesArr(modulesArr.map((module: any) => {
            if (module.menu_id === moduleId) {
                const updatedPages = module.pages.map((page: any) => {
                    if (page.menu_id === pageId) {
                        return { ...page, is_customer_default: checked };
                    }
                    return page;
                });
                return { ...module, pages: updatedPages };
            }
            return module;
        }));
    };
    const updateUserBranchModulesFunc = () => {
        dispatch(updateUserBranchModules(userCredentials, modulesArr))
    }


    return (
        <div className={`${checkPermissionLevelOfPage(credentials, location) === "RW"
            ? 'page-read' : 'page-read-only'}`}>
            <div className={`content-div-${dir}`}>
                <Subtitle title={"מודולים ללקוחות"} />
                <button onClick={updateUserBranchModulesFunc} >עדכון</button>
                <Stack tokens={modulesArr?.length}>
                    {modulesArr?.map((module: any) => (
                        <div key={module.menu_id}>
                            <Checkbox
                                label={module.menu_heb}
                                checked={module.pages.every((page: any) => page.is_customer_default)}
                                onChange={(ev: any, checked: any) =>
                                    handleModuleCheckboxChange(module.menu_id, checked)
                                }
                                styles={moduleCheckboxStyles}
                            />
                            <Stack tokens={module?.pages?.length} style={{ marginLeft: "20px" }}>
                                {module?.pages.map((page: any) => (
                                    <Checkbox
                                        key={page.menu_id}
                                        label={page.page_name_heb}
                                        checked={page.is_customer_default}
                                        onChange={(ev, checked) =>
                                            handlePageCheckboxChange(module.menu_id, page.menu_id, checked)
                                        }
                                        styles={PagesCheckboxStyles}
                                    />
                                ))}
                            </Stack>
                        </div>
                    ))}
                </Stack>
            </div>
        </div>
    )
}

