
import { Checkbox, DefaultButton, Icon, IconButton, PrimaryButton } from "@fluentui/react";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { ISpinnerStyles, Spinner, Label, TextField } from '@fluentui/react/lib';
import { cloneDeep, isEqual } from 'lodash';
import { useReactToPrint } from 'react-to-print';


import { DialogMessages } from "../../shared/components/dialog/DialogMessages";
import Title from "../../shared/components/Title";
import Subtitle from "../../shared/components/Subtitle";
import { CustomTextField } from "../../shared/components/TextField";
import { kycQuestions, readCustomerId } from '../../store/actions/customerActioin'
import { Icons } from "../../modelsType/Icon";
import "./moneyLaundering.scss";
import GenericPdfDownloader from "../pdfUtils/GenericPdfDownloader";
import UploadFiles from "../global/UploadFiles";
import { checkPermissionLevelOfPage, customerInfoInObject } from "../../shared/components/CustomHooks/GlobalFunctions";
import { CustomDropdown } from "../../shared/components/Option";
import { IUserCredentials } from "../customerDetails/CustomerDetails";
import CanvasDraw from "react-canvas-draw";
import { uploadFile } from "../../store/actions/FilesAction";
import MoneyLaunderingPDF from "../pdfUtils/MoneyLaunderingPDF";
import SettingsModulesClient from "../backOffice/SettingsModulesClient";
import { useLocation } from "react-router";

export const MoneyLaundering = () => {
    const dispatch = useDispatch()
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const userData = useSelector(({ customerReducer }: any) => customerReducer.dataCustomer?.kyc_questionnaire)
    const [readOnly, setreadOnly] = useState(true)
    const printRef = useRef(null);
    const refSignature = useRef(null);
    const [file, setFile] = useState<any>()
    const [insiderAction, setInsiderAction] = useState(false)
    const [pdfFile, setPdfFile] = useState<any>()
    const [activeDocument, setActiveDocument] = useState<any>({})
    const [showTextFOB, setShowTextFOB] = useState(false)
    const [showTextPB, setShowTextPB] = useState(false)
    const [showTextPI, setShowTextPI] = useState(false)
    const [showTextPA, setShowTextPA] = useState(false)
    const [showTextCE, setShowTextCE] = useState(false)
    const [showTextABM, setShowTextABM] = useState(false)
    const [showTextOOB, setShowTextOOB] = useState(false)
    const [showTextMA, setShowTextMA] = useState(false)
    const [showTextBR, setShowTextBR] = useState(false)
    const [showSpiner, setshowSpiner] = useState(false)

    const [addQ, setAddQ] = useState<number[]>([])
    const [check, setCheck] = useState(false)
    const [preferredBranch, setPreferredBranch] = useState()
    const [expectedActivity, setExpectedActivity] = useState()
    const [counter, setcounter] = useState(0)
    const [showDialogSave, setShowDialogSave] = useState(false)
    const [selectedKeysEA, setSelectedKeysEA] = React.useState<string[]>([]);
    const [selectedKeysPB, setSelectedKeysPB] = React.useState<string[]>([]);
    const [update, setUpdate] = useState(userData?.kyc_questionnaire ? true : false)
    const dataCustomer = useSelector(({ customerReducer }: any) => customerReducer?.dataCustomer)
    const customerDetails = customerInfoInObject(dataCustomer)
    const [customerQuestion, setCustomerQuestion] = useState<any>([])
    const [showDialogRequiredFields, setShowDialogRequiredFields] = useState(false)
    const [showRequiredFields, setShowRequiredFields] = useState(false)
    const [showNote, setShowNote] = useState(false)
    const [checkedCorporation, setCheckedCorporation] = useState<any>("false")
    const [listRepresentatives, setListRepresentatives] = useState<any>([])
    const [arrMessangers, setArrMessangers] = useState<any>([])
    const [insiderEntity, setInsiderEntity] = useState<any>({})
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const location = useLocation()
    const [permission, setPermission] = useState('')
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const [statement, setStatement] = useState<any>({ isControllingHolder: "", isBeneficiary: "", id_insider: null })
    const [noteMsg, setNoteMsg] = useState('')

    const [kycQuestionnaire, setKycQuestionnaire] = useState<any>(userData?.kyc_questionnaire ? userData?.kyc_questionnaire : {
        preferred_branch: { answer: [], details: "" }, for_other_beneficiary: { answer: "", details: "" }, pep_il: { answer: "", details: "" },
        pep_abroad: { answer: "", details: "" }, association_business_member: { answer: "", details: "" }, owned_other_businesses: { answer: "", details: "" },
        client_escort: { answer: "", details: "" }, job_title: { answer: "" }, industry_branch: { answer: "" }
        , business_type: { answer: "" }, service_product_involved: { answer: "" }, credit_refund_source: { answer: "" },
        bank_refuse: { answer: "", details: "" }, requested_method: { answer: "" }, main_suppliers: { answer: "" }
        , main_customers: { answer: "" }, annual_turnover: { answer: "" }, involved_in_business: { answer: "" }, funds_source: { answer: "" }, expected_activity: { answer: [] }, business_activity: { answer: "" }, expected_volume_activity: { answer: "" }
    })
    const [kycQuestionnaireBeforeChanges, setKycQuestionnaireBeforeChanges] = useState<any>(userData?.kyc_questionnaire ? userData?.kyc_questionnaire : {
        preferred_branch: { answer: [], details: "" }, for_other_beneficiary: { answer: "", details: "" }, pep_il: { answer: "", details: "" },
        pep_abroad: { answer: "", details: "" }, association_business_member: { answer: "", details: "" }, owned_other_businesses: { answer: "", details: "" },
        client_escort: { answer: "", details: "" }, job_title: { answer: "" }, industry_branch: { answer: "" }
        , business_type: { answer: "" }, service_product_involved: { answer: "" }, credit_refund_source: { answer: "" },
        bank_refuse: { answer: "", details: "" }, requested_method: { answer: "" }, main_suppliers: { answer: "" }
        , main_customers: { answer: "" }, annual_turnover: { answer: "" }, involved_in_business: { answer: "" }, funds_source: { answer: "" }, expected_activity: { answer: [] }, business_activity: { answer: "" }, expected_volume_activity: { answer: "" }
    })
    const spinnerStyles: Partial<ISpinnerStyles> = {
        root: {
            position: 'fixed',
            zIndex: '999999',
            fontWeight: 'bold',
        },
        circle: {
            color: '#FFB400 ',
            height: '100px',
            width: '100px',
        },
    }
    const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 604 } };
    const dropdownStylesRequired: Partial<IDropdownStyles> = {
        title: { backgroundColor: readOnly ? '#FAF9F8' : "white", border: "solid red 2px" },
        callout: { minWidth: "fit-content", maxwidth: "fit-content" },
        dropdown: { width: 604 }
    };

    const arrPreferredBranch: IDropdownOption[] = [{ key: "1", text: t('liveInArea') }, { key: "2", text: t('DissatisfactionFromAnotherPerson') },
    { key: "3", text: t('personalStatusChange') },
    { key: "4", text: t('referralByAnotherCustomer') }, { key: "5", text: t('workingInArea') }
        , { key: "6", text: t('recommendation') }, { key: "7", text: t('needForCredit') }, { key: "8", text: t('nonAcceptance') }, { key: "9", text: t('other') }]

    const arrExpectedActivity: IDropdownOption[] = [{ key: "1", text: t('saleROrRedemption') }, { key: "2", text: t('transfersFromIsrael') },
    { key: "3", text: t('currencyChange') }, { key: "4", text: t('discountServices') }, { key: "5", text: t('deliveryFinancial') },
    { key: "6", text: t('currencyExchange') }, { key: "7", text: t('transfersFromAbroad') }, { key: "8", text: t('discountingCheques') }]

    useEffect(() => {
        if (userData?.kyc_questionnaire) {
            setData()
        }
    }, [userData])

    useEffect(() => {
        if (location && credentials?.length) {
            checkPermissionLevelOfPage(credentials, location, setPermission)
        }
    }, [location, credentials])
    useEffect(() => {
        if (dataCustomer?.documentation) {
            dataCustomer?.documentation?.forEach((doc: any) => {
                if (doc?.documentation_type_id === '5' && doc?.media_status_id.toString() !== "99")
                    setActiveDocument(doc)
            });
        }
    }, [dataCustomer])

    useEffect(() => {
        setUserCredentials({
            "idInitiator": authReducer.data.user.id_entity,
            "idClient": authReducer.data.client.id_entity,
            "idBranch": authReducer.data.branch.id_entity,
            "idClientData": authReducer.data.client.id_client
        })
    }, [authReducer]);

    useEffect(() => {
        if (kycQuestionnaire?.expected_activity) {
            let q = cloneDeep(kycQuestionnaire)
            let temp: any = q["expected_activity"]
            temp.answer = selectedKeysEA
            setKycQuestionnaire({ ...kycQuestionnaire, ["expected_activity"]: temp })
        }
    }, [selectedKeysEA])

    useEffect(() => {
        if (kycQuestionnaire?.preferred_branch) {
            selectedKeysPB.forEach(key => { key === "9" ? setCheck(true) : setCheck(false) })
            let q = cloneDeep(kycQuestionnaire)
            let temp: any = q["preferred_branch"]
            temp.answer = selectedKeysPB
            setKycQuestionnaire({ ...kycQuestionnaire, ["preferred_branch"]: temp })
        }
    }, [selectedKeysPB])

    useEffect(() => {
        let ans: any = arrPreferredBranch.find((preferredBranch: any) => preferredBranch.key === kycQuestionnaire.preferred_branch?.answer[0])
        let ans2: any = arrExpectedActivity.find((expectedActivity: any) => expectedActivity.key === kycQuestionnaire?.expected_activity?.answer[0])

        if (ans !== undefined) {
            setPreferredBranch(ans.text)
        }
        if (ans2 !== undefined) {
            setExpectedActivity(ans2.text)
        }
    }, [arrPreferredBranch, kycQuestionnaire, arrExpectedActivity])

    useEffect(() => {

        if (check === true)
            setShowTextPB(true)
        else {
            if (kycQuestionnaire?.preferred_branch) {
                let q = cloneDeep(kycQuestionnaire)
                let temp: any = q["preferred_branch"]
                temp.details = ""
                setKycQuestionnaire({ ...kycQuestionnaire, ["preferred_branch"]: temp })
            }
            setShowTextPB(false)
        }

    }, [check])


    useEffect(() => {
        async function upload() {
            let file2 = ({
                media_name: t('money_laundering'), id_media: "", documentation_type_id: "5", media_type_id: "1", media_status_id: "1",
                media_exp_date: "2023-12-12", media_details: null, date_created: "", status: "פעיל", type: "חובה"
            })
            await dispatch(uploadFile(dataCustomer?.id_entity, userCredentials, file, file2, setPdfFile, "", "", "", t))
        }
        if (file) {
            upload()
        }
    }, [file])


    useEffect(() => {
        let insider = dataCustomer?.kyc_questionnaire?.insider
        let type = dataCustomer?.properties?.customer_type_id
        let arrRepresentatives: any[] = []
        let arrMessangersCopy: any[] = []
        dataCustomer?.insiders?.forEach((insider: any) => {
            if (insider.insider_type_id === 10 || insider.insider_type_id === 9 || insider.insider_type_id === 8 || insider.insider_type_id === 6) {
                arrRepresentatives.push({ key: insider?.id_entity, text: insider?.entity_name, typeId: insider.insider_type_id })
            }
            if (insider.insider_type_id === 9) {
                arrMessangersCopy.push(insider)
            }
        })
        setArrMessangers(arrMessangersCopy);
        setListRepresentatives(arrRepresentatives);
        setStatement({
            ...statement,
            "id_insider": insider?.id_entity !== undefined && insider?.id_entity !== "false" ? insider?.id_entity : statement?.id_insider,
        })
        setInsiderEntity(insider ? insider : insiderEntity)
        if (type === "3" || type === "5" ||
            type === "9" || type === "12" || type === "10") {
            setCheckedCorporation(true)
        }
    }, [dataCustomer])

    const createFile = async () => {
        await GenericPdfDownloader({ rootElementId: "MoneyLaundering", downloadFileName: t('money_laundering'), setFile, refSignature })

    }

    const addRelatedEntity = (key: string, value: any) => {
        setStatement({ ...statement, [key]: value })
        let insider = listRepresentatives?.find((insider: any) => value === insider?.key)
        let insiderObj = {
            id_entity: insider?.key,
            service_requisition_type_id: insider?.typeId
        }
        setInsiderEntity(insiderObj)
    }
    const setData = () => {
        let KYC: any = {
            preferred_branch: { answer: [], details: "" }, for_other_beneficiary: { answer: "", details: "" }, pep_il: { answer: "", details: "" },
            pep_abroad: { answer: "", details: "" }, association_business_member: { answer: "", details: "" }, owned_other_businesses: { answer: "", details: "" },
            client_escort: { answer: "", details: "" }, job_title: { answer: "" }, industry_branch: { answer: "" }
            , business_type: { answer: "" }, service_product_involved: { answer: "" }, credit_refund_source: { answer: "" },
            bank_refuse: { answer: "", details: "" }, requested_method: { answer: "" }, main_suppliers: { answer: "" }
            , main_customers: { answer: "" }, annual_turnover: { answer: "" }, involved_in_business: { answer: "" }, funds_source: { answer: "" }, expected_activity: { answer: [] },
            expected_volume_activity: { answer: "" }, business_activity: { answer: "" }
        }
        let arrCustomerQ: any = []
        userData?.kyc_questionnaire?.forEach((kyc: any) => {
            switch (kyc.question_code) {
                case "preferred_branch":
                    kyc.details !== "" ?
                        setShowTextPB(true) : setShowTextPB(false)
                    break;
                case "for_other_beneficiary":
                    kyc.answer === "yes" ?
                        setShowTextFOB(true) : setShowTextFOB(false)
                    break;
                case "bank_refuse":
                    kyc.answer === "yes" ?
                        setShowTextBR(true) : setShowTextBR(false)
                    break;
                case "pep_il":
                    kyc.answer === "yes" ?
                        setShowTextPI(true) : setShowTextPI(false)
                    break;
                case "pep_abroad":
                    kyc.answer === "yes" ?
                        setShowTextPA(true) : setShowTextPA(false)
                    break;
                case "association_business_member":
                    kyc.answer === "yes" ?
                        setShowTextABM(true) : setShowTextABM(false)
                    break;
                case "owned_other_businesses":
                    kyc.answer === "yes" ?
                        setShowTextOOB(true) : setShowTextOOB(false)
                    break;
                case "client_escort":
                    kyc.answer === "yes" ?
                        setShowTextCE(true) : setShowTextCE(false)
                    break;
                default:
                    break;
            }
            kyc.question_code && kyc.details !== undefined ?
                KYC[kyc.question_code] = { answer: kyc.answer, details: kyc.details } :
                kyc.question_code && (KYC[kyc.question_code] = { answer: kyc.answer })
            kyc?.type === "custom" && arrCustomerQ.push(kyc)
        });
        setSelectedKeysEA(KYC.expected_activity.answer)
        setSelectedKeysPB(KYC.preferred_branch.answer)
        let q = cloneDeep(KYC)
        setKycQuestionnaire(q)
        setKycQuestionnaireBeforeChanges(KYC)
        setCustomerQuestion(arrCustomerQ)
        setAddQ(arrCustomerQ);
        let insider = userData?.insider
        setInsiderEntity(userData?.insider)
        setStatement({
            ...statement,
            "id_insider": insider?.id_entity !== undefined && insider?.id_entity !== "false" ? insider?.id_entity : null,
        })
    }

    const addQe = () => {
        let c = counter + 1;
        setcounter(c)
        const list = [...addQ];
        list.push(c);
        setAddQ(list);
    }
    const removeQe = (index: number) => {
        const list = [...addQ];
        list.splice(index, 1);
        setAddQ(list);
        const items = [...customerQuestion];
        items.splice(index, 1)
        setCustomerQuestion(items)
    }

    const setAnswer = (e: any, key: string, setShow: any) => {
        let q = cloneDeep(kycQuestionnaire)
        let temp: any = q[key]
        if (e.target.ariaLabel === "כן" || e.target.ariaLabel === "Yes") {
            temp.answer = "yes"
            setShow(true)
        }
        else {
            temp.answer = "no"
            temp.details = ""
            setShow(false)
        }
        setKycQuestionnaire({ ...kycQuestionnaire, [key]: temp })
    }


    const changeCheckBox = (e: any, checked: any) => {
        if (e.target.id && checked === true) {
            if (e.target.id.includes("for_other_beneficiary")) {
                setAnswer(e, "for_other_beneficiary", setShowTextFOB)
            }
            if (e.target.id.includes("pep_il")) {
                setAnswer(e, "pep_il", setShowTextPI)
            }
            if (e.target.id.includes("pep_abroad")) {
                setAnswer(e, "pep_abroad", setShowTextPA)
            }
            if (e.target.id.includes("association_business_member")) {
                setAnswer(e, "association_business_member", setShowTextABM)
            }
            if (e.target.id.includes("owned_other_businesses")) {
                setAnswer(e, "owned_other_businesses", setShowTextOOB)
            }
            if (e.target.id.includes("client_escort")) {
                setAnswer(e, "client_escort", setShowTextCE)
            }
            if (e.target.id.includes("bank_refuse")) {
                setAnswer(e, "bank_refuse", setShowTextBR)
            }
        }
    }

    const save = async () => {
        let check = false
        for (var key in kycQuestionnaire) {
            if ((checkedCorporation === true && (kycQuestionnaire[key]["answer"] === "" || kycQuestionnaire[key]["answer"]?.length === 0)) || (
                (key !== 'main_suppliers' && key !== 'main_customers' && key !== 'involved_in_business') && checkedCorporation === "false"
                && (kycQuestionnaire[key]["answer"] === "" || kycQuestionnaire[key]["answer"]?.length === 0))) {
                setShowDialogRequiredFields(true)
                setShowRequiredFields(true)
                check = true
                break
            }
        }
        if (authReducer !== null && !check) {
            if (!isEqual(kycQuestionnaire, kycQuestionnaireBeforeChanges) || !isEqual(insiderEntity, userData?.insider)) {
                let insider = statement?.id_insider
                let type = customerDetails?.customerTypeId
                if ((insider === undefined) && (type === "3" || type === "5" ||
                    type === "9" || type === "12" || type === "10")) {
                    setInsiderAction(true)
                }
                else {
                    createFile()
                    setshowSpiner(true)
                    await dispatch(kycQuestions(kycQuestionnaire, insiderEntity, authReducer.data.user.id_entity, authReducer.data.user.id_client, authReducer.data.branch.id_entity, dataCustomer?.id_entity, setShowDialogSave, setreadOnly, customerQuestion, setshowSpiner))
                    setUpdate(true)
                }
            }

        }
    }

    const changeDetail = (key: string, value: any) => {
        let q = cloneDeep(kycQuestionnaire)
        let temp: any = q[key]
        temp.details = value
        setKycQuestionnaire({ ...kycQuestionnaire, [key]: temp })
    }

    const checkKycQuestionnaire = (event: any, id: String) => {
        let key: any = id
        let value: any = event?.target?.value;
        if ((value?.replace(/\D/g, '') === "") && (key === "annual_turnover" || key === "business_activity"
            || key === "involved_in_business" || key === "expected_volume_activity") && value !== "") {
            setNoteMsg(`בשדה זה יש להזין רק מספרים`)
            setShowNote(true)
        }
        if (key === "expected_volume_activity") {
            if (Number(value?.replace(/\D/g, '')) > Number(String(kycQuestionnaire["annual_turnover"].answer)?.replace(/\D/g, ''))) {
                setNoteMsg("שים לב, היקף הפעילות הצפוי גדול ממחזור שנתי על העסק")
                setShowNote(true)
            }
        }
        if (key === "involved_in_business") {
            if (Number(value.replace(/\D/g, '')) > Number(String(kycQuestionnaire["business_activity"].answer)?.replace(/\D/g, ''))) {
                setNoteMsg(t('involvedInBusinessMessage'))
                setShowNote(true)
            }
        }
    }
    const changeKycQuestionnaire = (key: string, value: any) => {
        debugger
        let valueAfterCheck = value
        let q = cloneDeep(kycQuestionnaire)
        let temp: any = q[key]
        if (temp.details !== undefined) temp.details = temp.answer === "yes" ? valueAfterCheck : temp.details
        else temp.answer !== "yes" && (key === "annual_turnover" || key === "business_activity" || key === "involved_in_business" || key === "expected_volume_activity") ?
            (temp.answer = value) : (temp.answer = valueAfterCheck)
        setKycQuestionnaire({ ...kycQuestionnaire, [key]: temp })


    }

    const onChange = (e: any, option?: IDropdownOption<any>): void => {
        if (option) {
            e.target.id === "expected_activity" ?
                setSelectedKeysEA(option.selected ? [...selectedKeysEA, option.key as string] : selectedKeysEA.filter(key => key !== option.key),)
                : setSelectedKeysPB(option.selected ? [...selectedKeysPB, option.key as string] : selectedKeysPB.filter(key => key !== option.key),)
        }
    };

    const addQuestion = (key: string, value: any) => {
        const [id, index] = key.split(',');
        let i = Number(index)
        let arr: any = []
        arr = [...customerQuestion]
        if ((arr.length - 1) >= i) {
            let temp = { type: "custom", question_text: (id === "question_text") ? value : arr[i]?.question_text, question_answer: (id === "question_answer") ? value : arr[i]?.question_answer }
            arr[i] = temp
        }
        else
            arr.push({ type: "custom", question_text: (id === "question_text") ? value : arr[i]?.question_text, question_answer: (id === "question_answer") ? value : arr[i]?.question_answer })
        setCustomerQuestion(arr)
    }
    const cancelEdit = async (statusProgram: boolean) => {
        await setData()
        setreadOnly(true)
    }

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const getCommaNumber = () => {
        console.log('kycQuestionnaire?.annual_turnover?.answer', kycQuestionnaire?.annual_turnover?.answer)
        return (kycQuestionnaire?.annual_turnover?.answer).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return (
        <>
            <div className={`sub-header-${dir} fixed-${dir}`}>
                {/* {showMessage && <DialogMessages setansDelete={setShowMessage} subText={message} title={t('note')}
                    setshowModalDelete={setShowMessage} dialogType={"dialogMessage"} setSaveDataConversion={setUpdateActiveDocument} />} */}
                {insiderAction &&
                    <DialogMessages setansDelete={setInsiderAction} setshowModalDelete={setInsiderAction}
                        subText={t('MoneyLaunderingCannotCompletedControllingOwners')} title={t('note')} dialogType={"dialogMessage"}
                    />}

                <Title
                    title={t("customers")} nameInTitle={dataCustomer?.entity_name} />
                <div className="divider"></div><div className="divider"></div>
                {readOnly && permission === "RW" && <DefaultButton className='button edit-button' checked={false} onClick={() => { setreadOnly(false) }} text={update ? t('edit') : t('create')} iconProps={Icons.editContact} name="update" />}
                {!readOnly && permission === "RW" && <DefaultButton className='button save-upload' checked={false} onClick={() => save()} text={t('save')} iconProps={Icons.cloudUpload} />}
                {!readOnly && permission === "RW" && update ? <DefaultButton className='button edit-button' checked={false} onClick={() => cancelEdit(true)} text={t("cancel")} iconProps={Icons.editContact} name="cancel" /> : ""}
                {showNote && <DialogMessages setansDelete={setShowNote} subText={noteMsg} title={t('note')} setshowModalDelete={setShowNote} dialogType={"dialogMessage"} />}
                <IconButton
                    iconProps={Icons.pdf}
                    styles={{
                        icon: { color: 'red', fontSize: 36 }
                    }}
                    className="button"
                    onClick={() => { GenericPdfDownloader({ rootElementId: "moneyLaundringPage", downloadFileName: t('money_laundering') }) }}
                />
                <IconButton
                    iconProps={Icons.print}
                    styles={{
                        icon: { color: 'black', fontSize: 36 }
                    }}
                    onClick={handlePrint}
                    className="button"
                />
                {/* <ExportCSV fileName={"xcelCustomer"} /> */}
                <div className="button"
                    style={{
                        cursor: "pointer"
                    }}
                    title={t("refresh")}
                    onClick={() => {
                        dispatch(readCustomerId(dataCustomer?.id_entity,
                            userCredentials, "", "", "", false));
                    }}
                > <Icon iconName="refresh"></Icon></div>
            </div>
            <br></br><br></br>
            {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
            {showDialogRequiredFields && <DialogMessages setansDelete={setShowDialogRequiredFields} setshowModalDelete={setShowDialogRequiredFields} dialogType={"requiredFields"} />}
            <Subtitle title={t('questionnaire')} />



            <div className={`content-div-${dir}`}>

                <div style={{ width: "100%", gridTemplateColumns: "1fr 1fr", display: "flex", gridTemplateRows: "auto", gridArea: "main" }}>
                    <div className="warpper-fields-rightP" id="moneyLaundringPage" ref={printRef}>
                        {showDialogSave && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"save"} />}
                        <p className="title-text">{t('details')}</p>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div className="wrapDetails">
                            <div>
                                <p ><span className="boldD">{t("questionnaireFillerDetails")}</span><span className="normal">{authReducer?.data?.user?.entity_name + "  תפקיד: עובד  "}</span></p>
                                <p><span className="boldD">{t("registrationAndReportingDuties")}</span><span className="normal">{authReducer?.data?.branch?.setting?.compliance_officer_name + "  תז: " + authReducer?.data?.branch?.setting?.compliance_officer_ID_number}</span></p>
                            </div>
                        </div>

                        <p className="title-text">{t("customerDetails")}</p>
                        <hr className="hr" ></hr>
                        {customerDetails ?
                            <div className="wrapDetails" style={{ width: "100%" }}>
                                <div>
                                    <div className="boldD" style={{ fontSize: "22px" }}><p className="boldD" style={{ marginBottom: "0%", fontSize: "19px", height: "25px" }}>{t("name")}</p>{customerDetails?.entityName ? customerDetails?.entityName : ""}</div>
                                    <p className="normal" style={{ fontSize: "19px", marginBottom: "0%" }}>{customerDetails?.idNumber ? customerDetails?.idNumber : ""}</p>
                                </div>
                                {customerDetails?.addressName ?
                                    <div>
                                        <div className="normal" style={{ fontSize: "19px", marginBottom: "0%" }}><p className="boldD" style={{ marginBottom: "0%", fontSize: "19px" }}>{t("address")}</p>{customerDetails?.addressName} {+" " + customerDetails?.addressNumber}</div>
                                        <p className="normal" style={{ fontSize: "19px", marginBottom: '0' }}>{customerDetails?.addressCityName ? customerDetails?.addressCityName : ""}</p>
                                    </div>
                                    : ""}
                                {customerDetails?.telephone ? <div>
                                    <div className="normal" style={{ fontSize: "19px" }}><p className="boldD" style={{ marginBottom: "0%", fontSize: "19px" }}>{t("phone")}</p>{customerDetails?.telephone}</div>
                                    <p className="normal" style={{ fontSize: "19px", marginBottom: '0' }}>{ }</p>
                                </div> : ""}
                                <div style={{ width: "22%" }}>
                                    {listRepresentatives?.length > 0 && <> <p className="boldD" style={{ marginBottom: "0%", fontSize: "19px" }}>{t("representative")}</p>
                                        <CustomDropdown readOnly={readOnly} otherInputId={''} hasOtherValue={false} options={listRepresentatives} label={''} onChange={addRelatedEntity} selectedKey={statement?.id_insider} id={'id_insider'} othertextInput={''} />
                                    </>}
                                </div>
                            </div>
                            : <div style={{ marginTop: "18px" }}>{t('noDisplay')}</div>}
                        <div style={{ width: "74%", marginBottom: "1.5%" }} >
                            {!readOnly ?
                                <Dropdown
                                    placeholder={t("select_options")}
                                    selectedKeys={kycQuestionnaire?.preferred_branch?.answer}
                                    multiSelect
                                    options={arrPreferredBranch}
                                    required
                                    label={t("whychooseThisBranch")}
                                    onChange={onChange}
                                    id={'preferred_branch'}
                                    disabled={readOnly}
                                    styles={showRequiredFields && selectedKeysPB?.length === 0 ? dropdownStylesRequired : dropdownStyles}
                                /> :
                                <div style={{ width: "100%" }}>
                                    <CustomTextField onChange={""} label={t("whychooseThisBranch")} readOnly={readOnly} value={preferredBranch} id={''} />
                                </div>
                            }
                            {showTextPB === true &&
                                <div style={{ width: "74%", marginTop: "3%" }}>
                                    <CustomTextField requiredField={showRequiredFields && kycQuestionnaire?.preferred_branch?.details === "" ? true : false} readOnly={readOnly} value={kycQuestionnaire?.preferred_branch?.details} onChange={changeDetail} id={'preferred_branch'} />
                                </div>}
                        </div>
                        <p className="title-text">{t("MoreDetails")}</p>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "74%" }}>
                            <Label style={showRequiredFields && kycQuestionnaire?.for_other_beneficiary?.answer === "" ? { width: "fit-content", border: "solid red 2px" } : { width: "fit-content" }} className="pQuestion" required>{t("performingActivityForSomeoneElse")}</Label>
                            <Checkbox label={t("no")} onChange={changeCheckBox} id={"no_for_other_beneficiary"} disabled={readOnly} checked={kycQuestionnaire?.for_other_beneficiary?.answer === "" ? false : !showTextFOB} />
                            <div className="answer" >
                                <Checkbox label={t("yes")} onChange={changeCheckBox} id={"for_other_beneficiary"} disabled={readOnly} checked={showTextFOB} />
                                {showTextFOB === true
                                    && <div className="inline">
                                        <Label className="pQuestion" required>{t("forWho")}</Label>
                                        <div className={`div-money-laundering-${dir}`}>
                                            <CustomTextField readOnly={readOnly} value={kycQuestionnaire?.for_other_beneficiary?.details}
                                                onChange={changeKycQuestionnaire} id={'for_other_beneficiary'} onBlur={(e: any) => { checkKycQuestionnaire(e, 'for_other_beneficiary') }} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>

                        <div style={{ width: "74%" }}>
                            <Label style={showRequiredFields && kycQuestionnaire?.bank_refuse?.answer === "" ? { width: "fit-content", border: "solid red 2px" } : { width: "fit-content" }}
                                className="pQuestion" required>{t("relatedToMoneyLaunderingOrTerroristFinancing")}</Label>
                            <Checkbox label={t("no")} onChange={changeCheckBox} id={"no_bank_refuse"} disabled={readOnly} checked={kycQuestionnaire?.bank_refuse?.answer === "" ? false : !showTextBR} />
                            <div className="answer" >
                                <Checkbox label={t("yes")} onChange={changeCheckBox} id={"bank_refuse"} disabled={readOnly} checked={showTextBR} />
                                {showTextBR === true && <div className={`inline-${dir}`}>
                                    <Label className="pQuestion" required>{t("detail")}</Label>
                                    <div className={`div-money-laundering-${dir}`}>
                                        <CustomTextField readOnly={readOnly} value={kycQuestionnaire?.bank_refuse?.details} onChange={changeKycQuestionnaire} id={'bank_refuse'} /></div></div>}
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "74%" }}>
                            <Label style={showRequiredFields && kycQuestionnaire?.pep_il?.answer === "" ? { width: "fit-content", border: "solid red 2px" } : { width: "fit-content" }}
                                className="pQuestion" required>{t("performActivityHoldPublicPositionIsrael")}</Label>
                            <Checkbox label={t("no")} onChange={changeCheckBox} id={'no_pep_il'} disabled={readOnly} checked={kycQuestionnaire?.pep_il?.answer === "" ? false : !showTextPI} />
                            <div className="answer" >
                                <Checkbox label={t("yes")} onChange={changeCheckBox} id={"pep_il"} disabled={readOnly} checked={showTextPI} />
                                {showTextPI === true && <div className={`inline-${dir}`}>
                                    <p className="pQuestion">{t("whatPublicRole")}</p>
                                    <div className={`div-money-laundering-${dir}`}>
                                        <CustomTextField readOnly={readOnly} value={kycQuestionnaire?.pep_il?.details}
                                            onBlur={(e: any) => { checkKycQuestionnaire(e, 'pep_il') }} onChange={changeKycQuestionnaire} id={'pep_il'} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "74%" }}>
                            <Label style={showRequiredFields && kycQuestionnaire?.pep_abroad?.answer === "" ? { width: "fit-content", border: "solid red 2px" } : { width: "fit-content" }}
                                className="pQuestion" required>{t("performActivityHoldPublicPositionAbroad")}</Label>
                            <Checkbox label={t("no")} onChange={changeCheckBox} id={'no_pep_abroad'} disabled={readOnly} checked={kycQuestionnaire?.pep_abroad?.answer === "" ? false : !showTextPA} />
                            <div className="answer" >
                                <Checkbox label={t("yes")} onChange={changeCheckBox} id={"pep_abroad"} disabled={readOnly} checked={showTextPA} />
                                {showTextPA === true && <div className={`inline-${dir}`}>
                                    <Label className="pQuestion" required>{t("whatPublicRole")}</Label>
                                    <div className={`div-money-laundering-${dir}`}>
                                        <CustomTextField readOnly={readOnly} value={kycQuestionnaire?.pep_abroad?.details}
                                            onBlur={(e: any) => { checkKycQuestionnaire(e, 'pep_abroad') }} onChange={changeKycQuestionnaire} id={'pep_abroad'} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "74%" }}>
                            <Label style={showRequiredFields && kycQuestionnaire?.association_business_member?.answer === "" ? { width: "fit-content", border: "solid red 2px" } : { width: "fit-content" }}
                                className="pQuestion" required>{t("businessMemberOfBusinessAssociation")}</Label>
                            <Checkbox label={t("no")} onChange={changeCheckBox} id={'no_association_business_member'} disabled={readOnly} checked={kycQuestionnaire?.association_business_member?.answer === "" ? false : !showTextABM} />
                            <div className="answer" >
                                <Checkbox label={t("yes")} onChange={changeCheckBox} id={"association_business_member"} disabled={readOnly} checked={showTextABM} />
                                {showTextABM === true && <div className={`inline-${dir}`}>
                                    <Label className="pQuestion" required>{t("detail")}</Label>
                                    <div className={`div-money-laundering-${dir}`}>
                                        <CustomTextField readOnly={readOnly} value={kycQuestionnaire?.association_business_member?.details}
                                            onChange={changeKycQuestionnaire} id={'association_business_member'}
                                            onBlur={(e: any) => { checkKycQuestionnaire(e, 'association_business_member') }} /></div></div>}
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "74%" }}>
                            <Label style={showRequiredFields && kycQuestionnaire?.owned_other_businesses?.answer === "" ? { width: "fit-content", border: "solid red 2px" } : { width: "fit-content" }}
                                className="pQuestion" required>{t("youOwnOtherBusinesses")}</Label>
                            <Checkbox label={t("no")} onChange={changeCheckBox} id={'no_owned_other_businesses'} disabled={readOnly} checked={kycQuestionnaire?.owned_other_businesses?.answer === "" ? false : !showTextOOB} />
                            <div className="answer" >
                                <Checkbox label={t("yes")} onChange={changeCheckBox} id={"owned_other_businesses"} disabled={readOnly} checked={showTextOOB} />
                                {showTextOOB === true && <div className="inline">
                                    <Label className="pQuestion" required>{t("detail")}</Label>
                                    <div className={`div-money-laundering-${dir}`}>
                                        <CustomTextField readOnly={readOnly} value={kycQuestionnaire?.owned_other_businesses?.details}
                                            id={'owned_other_businesses'} onChange={changeKycQuestionnaire}
                                            onBlur={(e: any) => { checkKycQuestionnaire(e, 'owned_other_businesses') }} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "74%" }}>
                            <Label style={showRequiredFields && kycQuestionnaire?.client_escort?.answer === "" ? { width: "fit-content", border: "solid red 2px" } : { width: "fit-content" }}
                                className="pQuestion" required>{t("customeWithEscort")}</Label>
                            <Checkbox label={t("no")} onChange={changeCheckBox} id={'no_client_escort'} disabled={readOnly} checked={kycQuestionnaire?.client_escort?.answer === "" ? false : !showTextCE} />
                            <div className="answer" >
                                <Checkbox label={t("yes")} onChange={changeCheckBox} id={"client_escort"} disabled={readOnly} checked={showTextCE} />
                                {showTextCE === true && <div className={`inline-${dir}`}>
                                    <Label className="pQuestion" required>{t("detailsWho")}</Label>
                                    <div className={`div-money-laundering-${dir}`}>
                                        <CustomTextField readOnly={readOnly} value={kycQuestionnaire?.client_escort?.details}
                                            id={'client_escort'} onChange={changeKycQuestionnaire}
                                            onBlur={(e: any) => { checkKycQuestionnaire(e, 'client_escort') }} /></div></div>}
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <Label className="pQuestion" required>{t("businessRole")}</Label>
                        <div className="moreQ">
                            <CustomTextField
                                readOnly={readOnly}
                                value={kycQuestionnaire?.job_title?.answer}
                                id={'job_title'} onChange={changeKycQuestionnaire}
                                onBlur={(e: any) => { checkKycQuestionnaire(e, 'job_title') }}
                            />
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <Label className="pQuestion" required>{t("businessFieldType")}</Label>
                        <div className="moreQ">
                            <CustomTextField
                                readOnly={readOnly}
                                value={kycQuestionnaire?.business_type?.answer}
                                id={'business_type'} onChange={changeKycQuestionnaire}
                                onBlur={(e: any) => { checkKycQuestionnaire(e, 'business_type') }}
                            />
                        </div>


                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <Label className="pQuestion" required>{t("industryBranch")}</Label>
                        <div className="moreQ">
                            <CustomTextField
                                readOnly={readOnly}
                                value={kycQuestionnaire?.industry_branch?.answer}
                                id={'industry_branch'}
                                onChange={changeKycQuestionnaire}
                                onBlur={(e: any) => { checkKycQuestionnaire(e, 'industry_branch') }}
                            />
                        </div>

                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        {/* <Label className="pQuestion" required>{t("serviceProductInvolved")}</Label> */}
                        {/* <div className="moreQ">
                            <CustomTextField
                                readOnly={readOnly}
                                value={kycQuestionnaire && kycQuestionnaire.service_product_involved
                                    ? kycQuestionnaire?.service_product_involved.answer : ""}
                                id={'service_product_involved'}
                                onChange={changeKycQuestionnaire}
                                onBlur={(e: any) => { checkKycQuestionnaire(e, 'service_product_involved') }}
                            />
                        </div> */}

                        {/* <hr className="hr" style={{ width: "100%" }} ></hr> */}
                        <Label className="pQuestion" required>{t("desiredWorkMethod")}</Label>
                        <div className="moreQ">
                            <CustomTextField requiredField={showRequiredFields && kycQuestionnaire?.requested_method?.answer === "" ? true : false} readOnly={readOnly} value={kycQuestionnaire?.requested_method?.answer} onChange={changeKycQuestionnaire} id={'requested_method'} /></div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <Label className="pQuestion" required>{t("mainCustomers")}</Label>
                        <div className="moreQ">
                            <CustomTextField requiredField={checkedCorporation === true && showRequiredFields
                                && kycQuestionnaire?.main_customers?.answer === "" ? true : false}
                                readOnly={checkedCorporation === "false" ? true : readOnly}
                                value={kycQuestionnaire?.main_customers?.answer}
                                id={'main_customers'} onChange={changeKycQuestionnaire}
                                onBlur={(e: any) => { checkKycQuestionnaire(e, 'main_customers') }} /></div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <Label className="pQuestion" required>{t("mainSuppliers")}</Label>
                        <div className="moreQ">
                            <CustomTextField requiredField={checkedCorporation === true && showRequiredFields
                                && kycQuestionnaire?.main_suppliers?.answer === "" ? true : false}
                                readOnly={checkedCorporation === "false" ? true : readOnly}
                                value={kycQuestionnaire?.main_suppliers?.answer}
                                id={'main_suppliers'} onChange={changeKycQuestionnaire}
                                onBlur={(e: any) => { checkKycQuestionnaire(e, 'main_suppliers') }} />
                        </div>

                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        {checkedCorporation === "false" ? <Label className="pQuestion" required>{t("amounSalaryInNewShekels")}</Label>
                            : <Label className="pQuestion">{t("annualTurnoverBsinessInNewShekels")}</Label>}
                        <div className="moreQ">
                            <CustomTextField requiredField={showRequiredFields && kycQuestionnaire?.annual_turnover?.answer === "" ? true : false}
                                readOnly={readOnly}
                                value={kycQuestionnaire?.annual_turnover?.answer?.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                id={'annual_turnover'} onChange={(key: any, value: any) => changeKycQuestionnaire(key, value.replace(/,/g, ''))}
                                onBlur={(e: any) => { checkKycQuestionnaire(e, 'annual_turnover') }} />
                            <>₪</>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <Label className="pQuestion" required>{t("scopeExpectedBusinessActivity")}</Label>
                        <div className="moreQ">
                            <CustomTextField requiredField={showRequiredFields && kycQuestionnaire?.expected_volume_activity?.answer === "" ? true : false}
                                readOnly={readOnly}
                                value={kycQuestionnaire?.expected_volume_activity?.answer?.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                id={'expected_volume_activity'} onChange={(key: any, value: any) => changeKycQuestionnaire(key, value.replace(/,/g, ''))}
                                onBlur={(e: any) => { checkKycQuestionnaire(e, 'expected_volume_activity') }} />
                            <>₪</>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        {checkedCorporation === "false" ? <Label className="pQuestion" required>{t("senior_on_job")}</Label>
                            : <Label className="pQuestion" required>{t("yearsBusinessRunning")}</Label>}
                        <div className="moreQ">
                            <CustomTextField requiredField={showRequiredFields && kycQuestionnaire?.business_activity?.answer === "" ? true : false}
                                readOnly={readOnly}
                                value={kycQuestionnaire?.business_activity?.answer?.toLocaleString()}
                                id={'business_activity'} onChange={changeKycQuestionnaire}
                                onBlur={(e: any) => { checkKycQuestionnaire(e, 'business_activity') }} /></div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <p className="pQuestion">{t("howManyYearsInvolvedBusiness")}</p>
                        <div className="moreQ">
                            <CustomTextField requiredField={checkedCorporation === true && showRequiredFields && kycQuestionnaire?.involved_in_business?.answer === "" ? true : false}
                                readOnly={checkedCorporation === "false" ? true : readOnly}
                                value={kycQuestionnaire?.involved_in_business?.answer?.toLocaleString()}
                                id={'involved_in_business'} onChange={changeKycQuestionnaire}
                                onBlur={(e: any) => { checkKycQuestionnaire(e, 'involved_in_business') }} /></div>

                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <Label className="pQuestion" required>{t("sourceFundsForServicesProvided")}</Label>
                        <div className="moreQ">
                            <CustomTextField requiredField={showRequiredFields && kycQuestionnaire?.funds_source?.answer === "" ? true : false} readOnly={readOnly}
                                id={'funds_source'} value={kycQuestionnaire?.funds_source?.answer}
                                onChange={changeKycQuestionnaire} onBlur={(e: any) => { checkKycQuestionnaire(e, 'funds_source') }} /></div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <Label className="pQuestion" required>{t("sourceFundsForCredit")}</Label>
                        <div className="moreQ">
                            <CustomTextField requiredField={showRequiredFields && kycQuestionnaire?.credit_refund_source?.answer === "" ? true : false} readOnly={readOnly}
                                id={'credit_refund_source'} value={kycQuestionnaire?.credit_refund_source?.answer}
                                onChange={changeKycQuestionnaire} onBlur={(e: any) => { checkKycQuestionnaire(e, 'credit_refund_source') }} /></div>

                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "74%", marginBottom: "1.5%" }} >
                            {!readOnly ?
                                <Dropdown
                                    placeholder={t("select_options")}
                                    selectedKeys={kycQuestionnaire?.expected_activity?.answer}
                                    multiSelect
                                    // eslint-disable-next-line react/jsx-no-bind
                                    options={arrExpectedActivity}
                                    label={t("type_of_activity_expected")}
                                    onChange={onChange}
                                    id={'expected_activity'}
                                    disabled={readOnly}
                                    styles={showRequiredFields && selectedKeysEA?.length === 0 ? dropdownStylesRequired : dropdownStyles}

                                /> :
                                <div style={{ width: "100%" }}>
                                    <TextField label={t('type_of_activity_expected')} readOnly={readOnly} value={expectedActivity} id={''} />
                                </div>}
                        </div>
                        {Array.from(Array(addQ.length)).map((c, index) => {
                            return (
                                <div key={index}>
                                    <hr className="hr" style={{ width: "100%" }} ></hr>
                                    <div className="addQ">
                                        <div style={{ width: "80%" }}>
                                            <div className="moreQe">
                                                <CustomTextField label={t("question")} readOnly={readOnly} value={customerQuestion[index]?.question_text} onChange={addQuestion} id={`question_text,${index}`} /></div>
                                            <div className="moreQe" >
                                                <CustomTextField value={customerQuestion[index]?.question_answer} readOnly={readOnly} label={t("answer")} onChange={addQuestion} id={`question_answer,${index}`} /></div></div>
                                        <div className={`delete-money-laundering-${dir}`}>
                                            <PrimaryButton disabled={readOnly} ariaDescription="delete" className='button' onClick={() => removeQe(index)} name="delete" text={t("deletion")} /></div>
                                    </div>
                                </div>
                            );
                        })}

                        <div style={{ width: "15%" }}>
                            <PrimaryButton disabled={readOnly} ariaDescription="add" className='button' onClick={() => addQe()} name="add" text={t('add_question')} />
                        </div>
                        <b>{t("signature")}</b>
                        <CanvasDraw
                            ref={refSignature}
                            style={{
                                boxShadow: "0 0 2px #1b7d9e",
                                width: "200px",
                                height: "200px"
                            }} />


                    </div>
                    <div className={`warpper-fields-leftP-${dir} money-laudering-file-${dir}`} >
                        {/* <PrimaryButton onClick={create}
                            text={t('create_file')} /> */}
                        <UploadFiles pdfFile={pdfFile} idEntity={dataCustomer?.id_entity} documentationTypeId={"5"} mediaName={t("moneyLaundering")} />
                    </div>
                </div>
                <div style={{ display: "none" }}>
                    <div id="MoneyLaundering">
                        <MoneyLaunderingPDF
                            arrMessangers={arrMessangers}
                            arrPreferredBranch={arrPreferredBranch}
                            arrExpectedActivity={arrExpectedActivity}
                            kycQuestionnaire={kycQuestionnaire}
                            statement={statement} />
                    </div>
                </div>
            </div>


        </>
    )
}
export default MoneyLaundering;