import { IIconProps, ISearchBoxStyles, SearchBox } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface MyProps {
  onChange?: any
  label?: string,
  required?: boolean,
  iconProps?: IIconProps,
  type?: string,
  nameOfClassStyle?: string,
  autoFocus?: boolean
  value?: string,
  onSearch?: any,
  isUnderLine?: boolean
  // ?:IIconProps
}

const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: 190, backgroundColor: "white", fontSize: "12.9px" } };



const SearchBoxSmall = (props: MyProps) => {
  const { onChange, label, autoFocus, value, onSearch, isUnderLine } = props;
  const [dir, setDir] = useState("")
  const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)

  useEffect(() => {
    if (lang) {
      setDir(lang)
    }
  }, [lang])

  return (
    <SearchBox
      styles={searchBoxStyles}
      placeholder={label}
      // onEscape={ev => {
      // }}
      // onClear={ev:any => {
      // }}
      underlined={isUnderLine}
      onSearch={onSearch}
      autoFocus={autoFocus}
      onChange={onChange}
      value={value}
      className={`${label === "" && `search-small-${dir}`}`}
    />

  );
}
export default SearchBoxSmall