

const initialState = {
    data: {
        generalFormOptionsMap: null
    },
    enums: [],
    language: "rtl"

};

export default function (state = initialState, action: any) {
    switch (action.type) {
        case 'SET_GENERAL_FORM_OPTIONS_MAP':
            sessionStorage.setItem('enums', JSON.stringify(action.payload));
            return {
                ...state,
                data: {
                    ...state.data,
                    generalFormOptionsMap: action.payload
                },
                enums: action.payload
            }
        case "SET_ALL_ENUMS":
            let concatEnums = state.enums.concat(action.payload.data)
            sessionStorage.setItem('enums', JSON.stringify(concatEnums));
            return {
                ...state,
                data: {
                    ...state.data,
                    generalFormOptionsMap: action.payload
                },
                enums: concatEnums
            }
        case "SET_ENUMS": {
            return {
                ...state,
                data: {
                    ...state.data,
                    generalFormOptionsMap: action.payload
                },
                enums: action.payload
            }
        }
        case "SET_LANGUAGE": {
            return {
                ...state,
                language: action.payload
            }
        }

        // case RECEIVE_PROTECTED_DATA:

        //     return {
        //         ...state,
        //        data:{
        //            ...state.data,
        //            [action.valueType]: action.value
        //        }

        //       }
        //       // set isLoading true  
        // case  FETCH_PROTECTED_DATA_REQUEST:
        //     return {
        //         ...state,
        //         isFetching: true
        //     } 

        // case  SET_IS_FETCHING:
        //     return {
        //         ...state,
        //         isFetching: action.isFetching
        //     } 

        default: return state;
    }
}
