import { PrimaryButton } from "@fluentui/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';

import { CustomerDate } from "../../shared/components/CustomHooks/concatTextFields";
import { DialogMessages } from "../../shared/components/dialog/DialogMessages";
import HeaderUtils from "../../shared/components/headerUtils/HeaderUtils";
import { CustomDropdown } from "../../shared/components/Option";
import Subtitle from "../../shared/components/Subtitle";
import { calculateProfit } from "../../store/actions/reportsAction";
import { convertArrToDropdropdown, IUserCredentials } from "../customerDetails/CustomerDetails";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useTranslation } from 'react-i18next';
import { checkPermissionLevelOfPage } from "../../shared/components/CustomHooks/GlobalFunctions";
import { useLocation } from "react-router";

export default function SumProfit() {
    let tableRef = useRef(null);
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [messageError, setMessageError] = useState('')
    const [serverMessageError, setServerMessageError] = useState<any>(false)
    const [dialogTitle, setDialogTitle] = useState('')
    const [isValidDate, setIsValidDate] = useState<any>(true)
    const [isValidEndDate, setIsValidEndDate] = useState<any>(true)
    const [valuationMethod, setaValuationMethod] = useState<any>();
    const [enumsDropdown, setenumsDropdown] = useState<any>({ "exchange_pnl": [] })
    const [showSpiner, setshowSpiner] = useState(false)
    // const [display, setDisplay] = useState(false)

    const enums = useSelector(({ dataReducer }: any) => dataReducer?.enums)

    const [date, setDate] = useState<any>({
        startDate: "",
        endDate: ""
    })
    const [dataTable, setDataTable] = useState<any>()


    const rowData = [
        "currency_code",
        "opening_balance_amount",
        "opening_balance_exchange_rate_ils",
        "opening_balance_amount_ils", "in_amount",
        "in_exchange_rate_ils", "in_amount_ils",
        "out_amount", "out_exchange_rate_ils", "out_amount_ils",
        "closing_balance_amount", "closing_balance_exchange_rate_ils",
        "closing_balance_amount_ils", "profit_amount_ils"]

    const titleData = [
        { key: t("BalanceBeginPeriod"), eng: 'opening_balance' },
        { key:t("in"), eng: 'in' },
        { key:t("out"), eng: 'out' },
        { key:t("BalanceEndPeriod"), eng: 'closing_balance' },
    ]
    const spinnerStyles: Partial<ISpinnerStyles> = {
        root: {
            position: 'fixed',
            zIndex: '999999',
            fontWeight: 'bold',
        },
        circle: {
            color: '#FFB400 ',
            height: '100px',
            width: '100px',
        },
    }

    useEffect(() => {
        if (enums) {
            let arr: any = {
                "exchange_pnl": convertArrToDropdropdown([]),
            };
            for (let i = 0; i < enums.length; i++) {
                if (enums[i]?.exchange_pnl !== undefined) {
                    let exchangePnl = enums[i].exchange_pnl
                    for (let i = 0; i < exchangePnl.length; i++) {
                        if (exchangePnl[i].valuation_method_id !== undefined) {
                            arr.exchange_pnl = convertArrToDropdropdown(exchangePnl[i].valuation_method_id)
                        }
                    }
                }
            }
            let isDefault = arr?.exchange_pnl.find((item: any) => item?.is_default === true)
            setaValuationMethod(isDefault?.key)
            setenumsDropdown(arr);
        }
    }, [enums])

    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])

    const CalcProfit = async () => {
        if (date.endDate && date.startDate) {
            if (date.endDate >= date.startDate) {
                if (date.endDate > moment().format("YYYY-MM-DD") || date.startDate > moment().format("YYYY-MM-DD")) {
                    setMessageError(t("earlierDateThanCurrentMassege"))
                    setDialogTitle(t('note'))
                    setServerMessageError(true)
                }
                else {
                    setshowSpiner(true)
                    await dispatch(calculateProfit(date, userCredentials, [], setDataTable, valuationMethod, setshowSpiner))
                    // await dispatch(getHistoricalExchange(date, userCredentials, setDataTable, valuationMethod, setshowSpiner))

                }
            }
            else {
                setMessageError(t("StartDateEarlierEndDateMassege"))
                setDialogTitle(t('note'))
                setServerMessageError(true)
            }
        }
        else {
            setMessageError(t("enterStartdateAndEndDate"))
            setDialogTitle(t('note'))
            setServerMessageError(true)
        }
    }

    const print = async () => {
        if (handlePrint !== undefined) {
            handlePrint()
        }

    }
    const handlePrint = useReactToPrint(
        { content: () => tableRef.current, });

    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
        ? 'page-read' : 'page-read-only'}`}>
            {showSpiner && <Spinner styles={spinnerStyles}
             className={`spinner-${dir}`} />}
            {serverMessageError && <DialogMessages setansDelete={setServerMessageError}
                setshowModalDelete={setServerMessageError} subText={messageError}
                title={dialogTitle} dialogType={"dialogMessage"} />}
            <HeaderUtils title={t("foreignCurrency")} subTitle={"false"} disabledSave={true} funcAction={print}
                uploadBtn={false} actionData={dataTable?.report_body} PrintTitle={rowData}
                excelDate={dataTable?.report_body}   createXl={true} createPrint={true}  createPDF={false} />
            <br></br><br></br>

            <Subtitle title={t("calculationProfit")} />
            <div className={`content-div-${dir}`}>
                <p className="title-text"> {t("clculationProfitBetweenDates")} {(date?.startDate !== moment().format("YYYY-MM-DD") || date?.endDate !== moment().format("YYYY-MM-DD"))
                    && <span> {t('betweenDates')} {moment(date?.startDate).format("DD/MM/YYYY")} - {moment(date?.endDate).format("DD/MM/YYYY")}</span>}</p>

                <hr className="hr" style={{ width: "1190px" }} ></hr>
                <div style={{ width: "640px", display: "flex" }} >
                    <div style={{ width: "160px" }}>
                        <CustomerDate readOnly={false} setIsValidDate={setIsValidDate} label={t("srartDate")}
                            showRequiredFildes={false} isValidDate={isValidDate} entity={date}
                            setEntity={setDate} isDefaultDate={true} idDate="startDate" source={""} />
                    </div>
                    <div  className={`margin-between-input-${dir}`} style={{ width: "160px" }}>
                        <CustomerDate readOnly={false} setIsValidDate={setIsValidEndDate} label={t("endDate")}
                            showRequiredFildes={false} isValidDate={isValidEndDate} entity={date}
                            setEntity={setDate} isDefaultDate={true} idDate="endDate" source={""} />
                    </div>
                    <div  className={`margin-between-input-${dir}`} style={{ width: "160px" }}>
                        <CustomDropdown otherInputId={''} readOnly={false} hasOtherValue={false}
                            options={enumsDropdown?.exchange_pnl} label={t("calculationMethod")}
                            onChange={(id: any, key: any, val: any) => { setaValuationMethod(key) }}
                            selectedKey={valuationMethod} id={''} othertextInput={""} />
                    </div>
                    <div  className={`margin-between-input-${dir}`} style={{ display: "flex", alignItems: "end", width: "160px" }}>
                        <PrimaryButton style={{ width: "70%" }} onClick={CalcProfit} text={t("calculate")} />
                    </div>
                </div>
                <ReactToPrint
                    documentTitle={""}
                    content={() => tableRef.current}
                />

                {(dataTable && dataTable?.report_body) ?
                    <table style={{ border: '2px solid #e1e1e1', marginTop: '2%' }}>
                        <tbody>
                            <tr>
                                <th rowSpan={2} style={{
                                    border: 'none', backgroundColor: "#e1e1e1", height:
                                        '46px', minWidth: '170px', textAlign: 'center', color: 'gray'
                                }}>
                                   {t("currency")}
                                </th>
                                {titleData?.length && titleData?.map((row: any, index: number) => {
                                    return (
                                        <th colSpan={3} key={index + "$"} style={{
                                            border: 'none', backgroundColor: "#e1e1e1",
                                            height: '46px', minWidth: '210px', textAlign: 'center', color: 'gray'
                                        }}>
                                            {row.key}
                                        </th>
                                    )
                                })}
                                <th rowSpan={2} style={{
                                    border: 'none', backgroundColor: "#e1e1e1", height: '46px',
                                    minWidth: '180px', textAlign: 'center', color: 'gray'
                                }}>
                                      {t("profit")}
                                </th>
                            </tr>
                            <tr>
                                {titleData?.length && titleData?.map((row: any, index: number) => {
                                    return (
                                        <>
                                            <th colSpan={1} key={index + "h"} className={`sub-title-sum-profit-${dir}`}>{t("amount")}</th>
                                            <th colSpan={1} key={index + "i"} className={`sub-title-sum-profit-${dir}`}>{t("exchangeRates")}</th>
                                            <th colSpan={1} key={index + "j"} className={`sub-title-sum-profit-${dir}`}>{t("total")}</th></>
                                    )
                                })}</tr>
                            {(titleData?.length && dataTable?.report_body) && dataTable?.report_body?.map((item: any, key: any) => {
                                return (
                                    <tr key={key + "@"} className="hoverRow" style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }}>

                                        {rowData.map((field: any, index: number) => {
                                            return (
                                                (field === "profit_amount_ils" && item[field] < 0) ?
                                                    <td key={index + "#"} style={{ color: "#27c427" }}
                                                         className={`td-sum-profit-${dir} ltr-${dir}`}>
                                                        {Math.abs(Number(item[field])).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                    : (field === "profit_amount_ils" && item[field] > 0) ?
                                                        <td style={{ color: "red" }} key={index + "b"} className={`td-sum-profit-${dir} ltr-${dir}`}>
                                                            {Math.abs(Number(item[field])).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        : field === "currency_code" ? <td key={index + "c"} className={`td-currency-sum-profit-${dir} td-border`}>{item["currency_code_name"] + "  " + "(" + item[field] + ")"}</td> :
                                                            field.includes("te_ils") ? <td key={index + "d"} className={`td-sum-profit-${dir} ltr-${dir} ${index % 3 === 0 && "td-border"}`}  >
                                                                {Math.abs(Number(item[field])).toFixed(2)}
                                                            </td> :
                                                                <td key={index + "d"} className={`td-sum-profit-${dir} ltr-${dir} ${index % 3 === 0 && "td-border"}`}  >
                                                                    {Math.abs(Number(item[field])).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                </td>)
                                        })}
                                    </tr>
                                )
                            })}
                            <tr>
                                <th className={`td-border th-sum-profit-${dir}`}></th>
                                <th  ></th>
                                <th  className={`th-sum-profit-${dir}`}> {t("total")}</th>
                                <th className={`td-border th-sum-profit-${dir}`}>{Math.abs(Number(dataTable?.total_opening_balance_amount_ils)).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                <th ></th>
                                <th  className={`th-sum-profit-${dir}`}> {t("total")}</th>
                                <th  className={`td-border th-sum-profit-${dir}`} >{Math.abs(Number(dataTable?.total_in_amount_ils)).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                <th ></th>
                                <th className={`th-sum-profit-${dir}`}> {t("total")}</th>
                                <th  className={`td-border th-sum-profit-${dir}`}>{Math.abs(Number(dataTable?.total_out_amount_ils)).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                <th ></th>
                                <th className={`th-sum-profit-${dir}`} > {t("total")}</th>
                                <th className={`td-border th-sum-profit-${dir}`}>{Math.abs(Number(dataTable?.total_closing_balance_amount_ils)).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                {dataTable?.total_profit_amount_ils < 0 ?
                                    <th className={`td-border th-sum-profit-${dir}`} style={{ color: "#27c427"}} > {Math.abs(Number(dataTable?.total_profit_amount_ils)).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                    : <th className={`th-sum-profit-red-${dir}`} >{Math.abs(Number(dataTable?.total_profit_amount_ils)).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                }
                            </tr>
                        </tbody>
                    </table> : dataTable && <p>{t('noDataDisplay')}</p>}
            </div>


            <div style={{ display: "none" }}>
                <div ref={tableRef}>
                    <p style={{ textAlign: 'center', color: 'gray' }}>{authReducer?.data?.branch?.entity_name}</p>
                    <p style={{ textAlign: 'center', color: 'gray' }} className="title-text">{t('operationsForeignCurrency')}:
                        {date?.startDate ? moment(date?.startDate).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")}  {t('untilDate')} : {date?.endDate ? moment(date?.endDate).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")}</p>
                    <table style={{ border: '2px solid #e1e1e1', marginTop: '2%' }}>
                        <tbody>
                            <tr>
                                <th rowSpan={2} style={{
                                    border: 'none', backgroundColor: "#e1e1e1", height:
                                        '46px', minWidth: '170px', textAlign: 'center', color: 'gray'
                                }}>
                                   {t("currency")}
                                </th>
                                {titleData?.length && titleData?.map((row: any, index: number) => {
                                    return (
                                        <th colSpan={3} key={index + "$"} style={{
                                            border: 'none', backgroundColor: "#e1e1e1",
                                            height: '46px', minWidth: '210px', textAlign: 'center', color: 'gray'
                                        }}>
                                            {row.key}
                                        </th>
                                    )
                                })}
                                <th rowSpan={2} style={{
                                    border: 'none', backgroundColor: "#e1e1e1", height: '46px',
                                    minWidth: '180px', textAlign: 'center', color: 'gray'
                                }}>
                                    {t("profit")}
                                </th>
                            </tr> <tr>
                                {titleData?.length && titleData?.map((row: any, index: number) => {
                                    return (
                                        <td key={index + "/"}>
                                            <th key={index + "h"}className={`sub-title-sum-profit-${dir}`}>{t("amount")} </th>
                                            <th key={index + "i"} className={`sub-title-sum-profit-${dir}`}>{} </th>
                                            <th key={index + "j"} className={`sub-title-sum-profit-${dir}`}>{t("total")} </th></td >
                                    )
                                })}</tr>
                            {(titleData?.length && dataTable?.report_body) && dataTable?.report_body?.map((item: any, key: any) => {
                                return (
                                    <tr key={key + "@"} className="hoverRow" style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }}>

                                        {rowData.map((field: any, index: number) => {
                                            return (
                                                (field === "profit_amount_ils" && item[field] < 0) ?
                                                    <td style={{ color: "#27c427" }}
                                                        key={index + "a"} className={`td-sum-profit-${dir}`}>
                                                        {Math.abs(item[field]).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                                                    : (field === "profit_amount_ils" && item[field] > 0) ?
                                                        <td style={{ color: "red" }} key={index + "b"} className={`td-sum-profit-${dir}`}>
                                                            {Math.abs(item[field].toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</td>

                                                        : field === "currency_code" ?
                                                            <td key={index + "c"} className={`td-sum-profit-${dir} td-border`}>{item["currency_code_name"] + "  " + "(" + item[field] + ")"}</td> :

                                                            <td key={index + "d"} className={`td-sum-profit-${dir} ${index % 3 === 0 && "td-border"}`}  >
                                                                {field.includes("te_ils") ? Math.abs(Number(item[field])).toFixed(2) :

                                                                    Math.abs(Number(item[field])).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>)
                                        })}
                                    </tr>
                                )
                            })}
                            <tr>
                                <th className={`td-border th-sum-profit-${dir}`}></th>
                                <th  ></th>
                                <th className={`th-sum-profit-${dir}`}>{t("total")}</th>
                                <th className={`td-border th-sum-profit-${dir}`}>{Math.abs(Number(dataTable?.total_opening_balance_amount_ils)).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                <th ></th>
                                <th className={`th-sum-profit-${dir}`}>{t("total")}</th>
                                <th className={`td-border th-sum-profit-${dir}`}>{Math.abs(Number(dataTable?.total_in_amount_ils)).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                <th ></th>
                                <th className={`th-sum-profit-${dir}`}>{t("total")}</th>
                                <th className={`td-border th-sum-profit-${dir}`}>{Math.abs(Number(dataTable?.total_out_amount_ils)).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                <th ></th>
                                <th className={`th-sum-profit-${dir}`} >{t("total")}</th>
                                <th className={`td-border th-sum-profit-${dir}`}>{Math.abs(Number(dataTable?.total_closing_balance_amount_ils)).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                {dataTable?.total_profit_amount_ils < 0 ?
                                    <th className={`td-border th-sum-profit-${dir}`} style={{ color: "#27c427"}} > {Math.abs(Number(dataTable?.total_profit_amount_ils)).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                    : <th className={`th-sum-profit-red-${dir}`} >{Math.abs(Number(dataTable?.total_profit_amount_ils)).toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                }
                            </tr>
                        </tbody>
                    </table>
                </div></div>
        </div>
    )
}
