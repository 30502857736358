import { useEffect, useState } from 'react';
import { Checkbox } from 'office-ui-fabric-react'
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import moment from 'moment';
import { isCorporationFunc } from '../../shared/components/CustomHooks/GlobalFunctions';

export default function MoneyLaunderingPDF({ kycQuestionnaire,
    arrPreferredBranch, arrExpectedActivity, arrMessangers, statement }) {
    const [t] = useTranslation();
    const dataCustomer = useSelector(({ customerReducer }) => customerReducer.dataCustomer)
    const authReducer = useSelector(({ authReducer }) => authReducer)
    const [entity, setentity] = useState()
    const date = moment().format("DD/MM/YY");

    useEffect(() => {
        let entityObj
        if (dataCustomer && statement?.id_insider) {
            entityObj = dataCustomer?.insiders.find((insider) => insider.id_entity === statement?.id_insider)
            setentity(entityObj)
        }
        else {
            if (!isCorporationFunc(dataCustomer) && !statement?.id_insider && dataCustomer) {
                setentity(dataCustomer)
            }
        }
    }, [dataCustomer, statement])
    return (
        <div id="MoneyLaundering">
            <div style={{ padding: "60px", lineHeight: 3, fontSize: "13px" }}>
                <h1 style={{ fontSize: "25px", color: "blue", textAlign: "center", marginTop: "30px" }}><u>טופס הכר את הלקוח</u></h1>
                <div style={{ fontSize: "13px" }}>
                    <span style={{ width: "17px" }}>(א)</span>
                    <span style={{ marginRight: "8px" }}>פרטים אישיים:</span>
                </div>
                <div style={{ display: "flex", fontSize: "13px" }}>
                    <span style={{ marginLeft: "8px" }}>שם פרטי ומשפחה:</span>
                    <span style={{ marginLeft: "8px" }}><u>{dataCustomer?.entity_name}</u></span>
                    <span style={{ marginLeft: "8px" }}> סוג לקוח:</span>
                    <span style={{ marginLeft: "8px" }}><u>{isCorporationFunc(dataCustomer)?"תאגיד":"יחיד"}</u></span>
                    <span style={{ marginLeft: "8px" }}>  תחום עיסוק: </span>
                    <span style={{ marginLeft: "8px" }}><u>{kycQuestionnaire?.business_type?.answer}</u></span>
                </div>
                <div style={{ display: "flex", fontSize: "13px" }}>
                    <span style={{ marginLeft: "8px" }}>   תפקידך בעסק: </span>
                    <span style={{ marginLeft: "8px" }}><u>{kycQuestionnaire?.job_title?.answer}</u></span>
                    {/* <span style={{ marginLeft: "8px" }}>שם אחראי על חובות הרישום והדיווח:</span>
                    <span style={{ marginLeft: "8px" }}><u>{authReducer?.data?.branch?.setting?.compliance_officer_name + "  תז: " +
                        authReducer?.data?.branch?.setting?.compliance_officer_ID_number}</u></span> */}
                </div>
                <div>
                    <div style={{ fontSize: "13px" }}>
                        <span style={{ width: "17px" }}>(ב)</span>
                        <span style={{ marginRight: "8px" }}>מדוע בחרת בסניף זה?</span>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "33% 33% 33%" }}>
                        {arrPreferredBranch?.map((item, i) => (
                            <div style={{ display: "flex", marginLeft: "8px", alignItems: "center" }} key={i}>
                                {kycQuestionnaire?.preferred_branch?.answer?.includes(item?.key) ?
                                    <Checkbox checked={true}></Checkbox> :
                                    <Checkbox checked={false}></Checkbox>}
                                <span>{item?.text}</span>

                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ fontSize: "13px" }}>(ג) פרטי זיהוי נוספים:</div>
                <div >
                    <span style={{ marginLeft: "10px" }}>
                        • האם אתה מבצע את הפעילות בעבור אחר? אם כן בעבור מי
                        מבוצעת הפעילות?</span>
                    <div className='div-text'>{
                        kycQuestionnaire?.for_other_beneficiary?.answer === "no" ? "לא" :
                            kycQuestionnaire?.for_other_beneficiary?.answer === "yes" ? "כן" + "  "
                                + kycQuestionnaire?.for_other_beneficiary?.details : "לא ידוע"
                    }</div>
                </div>
                <div >
                    <span style={{ marginLeft: "10px" }}>
                        • האם אתה או מי שאתה מבצע בעבורו את הפעילות מחזיק בתפקיד
                        ציבורי בישראל? אם כן מהו התפקיד הציבורי?</span>
                    <div className='div-text'>{
                        kycQuestionnaire?.pep_il?.answer === "no" ? "לא" :
                            kycQuestionnaire?.pep_il?.answer === "yes" ? "כן" + "  " +
                                kycQuestionnaire?.pep_il?.details : "לא ידוע"}</div>
                </div>
                <div >
                    <span style={{ marginLeft: "10px" }}>
                        • האם אתה או מי שאתה מבצע בעבורו את הפעילות מחזיק בתפקיד
                        ציבורי בחול? אם כן מהו התפקיד הציבורי?</span>
                    <div className='div-text'>{
                        kycQuestionnaire?.pep_abroad?.answer === "no" ? "לא" :
                            kycQuestionnaire?.pep_abroad?.answer === "yes" ? "כן" + "  " +
                                kycQuestionnaire?.pep_abroad?.details : "לא ידוע"
                    }</div>
                </div>
                <div >
                    <span style={{ marginLeft: "10px" }}>
                        • {t("IsMessenger")}</span>
                    <div className='div-text' style={{ display:"flex"}}>{arrMessangers.length > 0 ?
                        arrMessangers.map((messanger) => {
                            return (
                                <div style={{textDecoration: "underline-overline" }}>
                                    <span style={{ marginLeft: "8px", textUnderlinOffset: "auto" }}>{messanger?.entity_name}</span>
                                    <span style={{ marginLeft: "8px" }}>{messanger?.identification?.ID_number}</span>
                                    <span style={{ marginLeft: "8px" }}>{messanger?.insider_type_name+",  "}</span>
                                </div>
                            )
                        }) : "לא"}</div>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        • האם הלקוח הגיע עם מלווה? אם כן מיהו ומה תפקידו?</span>
                    <div className='div-text'>{
                        kycQuestionnaire?.client_escort?.details === "no" ? "לא" :
                            kycQuestionnaire?.client_escort?.details?.answer === "yes" ? "כן" + "  " +
                                kycQuestionnaire?.client_escort?.details?.details : "לא ידוע"
                    }</div>
                </div>
                <div >
                    <span style={{ marginLeft: "10px" }}>
                        • מהי שיטת העבודה המבוקשת?</span>
                    <div className='div-text'>{kycQuestionnaire?.requested_method?.answer
                        && kycQuestionnaire?.requested_method?.answer
                    }</div>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        • האם תאגיד בנקאי או כל תאגיד אחר סירב לתת לך שירות מסיבות
                        הקשורות להלבנת הון או מימון טרור?</span>
                    <div className='div-text'>
                        {kycQuestionnaire?.bank_refuse?.answer === "no" ? "לא" :
                            kycQuestionnaire?.bank_refuse?.answer === "yes" ? "כן" + "  " +
                                kycQuestionnaire?.bank_refuse?.details : "לא ידוע"
                        }</div>
                </div>
                <div style={{ fontSize: "13px" }}>
                    <span style={{ width: "17px" }}>(ד)</span>
                    <span style={{ marginRight: "8px" }}>פרטי העסק:</span>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        שם העסק/תאגיד
                    </span>
                    <div className='div-text'>{dataCustomer?.entity_name}</div>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        {t("yearsBusinessRunning")}
                    </span>
                    <div className='div-text'>{kycQuestionnaire?.business_activity?.answer?.toLocaleString()}</div>
                </div>

                <div>
                    <span style={{ marginLeft: "10px" }}>
                        {t("industryBranch")}
                    </span>
                    <div className='div-text'>{kycQuestionnaire?.business_activity?.answer?.toLocaleString()}</div>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        {t("serviceProductInvolved")}
                    </span>
                    <div className='div-text'>{kycQuestionnaire?.business_activity?.answer?.toLocaleString()}</div>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        {t("howManyYearsInvolvedBusiness")}
                    </span>
                    <div className='div-text'>{kycQuestionnaire?.involved_in_business?.answer?.toLocaleString()}</div>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        {t("mainSuppliers")}</span>
                    <div className='div-text'>{kycQuestionnaire?.main_suppliers?.answer}</div>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        {t("mainCustomers")}</span>
                    <div className='div-text'>{kycQuestionnaire?.main_customers?.answer}</div>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        {t("annualTurnoverBsinessInNewShekels")}</span>
                    <div className='div-text'>{kycQuestionnaire?.annual_turnover?.answer}</div>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        {t("youOwnOtherBusinesses")}</span>
                    <div className='div-text'>
                        {kycQuestionnaire?.owned_other_businesses?.answer === "no" ? "לא" :
                            kycQuestionnaire?.owned_other_businesses?.answer === "yes" ? "כן" + "  " +
                                kycQuestionnaire?.owned_other_businesses?.details : "לא ידוע"
                        } </div>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        {t("businessMemberOfBusinessAssociation")}</span>
                    <div className='div-text' >{kycQuestionnaire?.association_business_member?.answer === "no" ? "לא" :
                        kycQuestionnaire?.association_business_member?.answer === "yes" ? "כן" + "  "
                            + kycQuestionnaire?.association_business_member?.details : "לא ידוע"}</div>
                </div>
                <div>
                    <div style={{ fontSize: "13px" }}>
                        <span style={{ width: "17px" }}>(ה)</span>
                        <span style={{ marginRight: "8px" }}>פעילות צפויה</span>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "40% 60% " }}>
                        {arrExpectedActivity.map((item, i) => (
                            <div style={{ display: "flex", alignItems: "center" }} key={i}>
                                {kycQuestionnaire?.expected_activity?.answer?.includes(item?.key) ?
                                    <Checkbox checked={true}></Checkbox> :
                                    <Checkbox checked={false}></Checkbox>}
                                <span>{item?.text}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ fontSize: "13px" }}>
                    <span style={{ width: "17px" }}>(ו)</span>
                    <span style={{ marginRight: "8px" }}>היקף פעילות</span>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        {t("scopeExpectedBusinessActivity")}
                    </span>
                    <div className='div-text' >{kycQuestionnaire?.expected_volume_activity?.answer?.toLocaleString()}</div>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        {t("sourceFundsForServicesProvided")}
                    </span>
                    <div className='div-text' >{kycQuestionnaire?.funds_source?.answer}</div>
                </div>
                <div>
                    <span style={{ marginLeft: "10px" }}>
                        {t("sourceFundsForCredit")}
                    </span>
                    <div className='div-text' >{kycQuestionnaire?.credit_refund_source?.answer}</div>
                </div>
                <div>
                    ידוע לי כי מסירת מידע כוזב, לרבות אי-מסירת עדכון של פרט החייב
                    בדיווח, במטרה שלא יהיה דיווח או כדי לגרום לדיווח בלתי נכון לפי סעיף
                    7 לחוק, מהווה עבירה פלילית.
                </div>
                <div >
                    <div style={{ display: "flex" }}>
                        <span style={{ marginRight: "8px" }}>תאריך</span>
                        <span style={{ marginRight: "8px" }}><u>{date}</u></span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <span style={{ marginRight: "8px" }}>חתימה</span>
                        <div id="divSignature"> </div>
                    </div>
                </div>
                <div><u>זיהוי הפרטים על ידי נותן השירות</u></div>
                <div>
                    <span style={{ marginRight: "8px" }}>אני מאשר כי ביום</span>
                    <span style={{ marginRight: "8px" }}><u>{date}</u></span>
                    <span style={{ marginRight: "8px" }}>הופיע לפני</span>
                    <span style={{ marginRight: "8px" }}><u>{entity?.entity_name}</u></span>
                    <span style={{ marginRight: "8px" }}>אשר זיהה עצמו באמצעות:</span>
                    <span style={{ marginRight: "8px" }}><u>{entity?.identification?.ID_type_name} שמספרו:  {entity?.identification?.ID_number}</u></span>
                    <span style={{ marginRight: "8px" }}>שהעתקם מצ"ב.</span>
                </div>

                <div>
                    <span style={{ marginRight: "8px" }}>שם העובד שמילא את הטופס</span>
                    <span style={{ marginRight: "8px" }}><u>{authReducer?.data?.user?.entity_name}</u></span>
                    <span style={{ marginRight: "8px" }}>מס' זהות</span>
                    <span style={{ marginRight: "8px" }}><u>{authReducer?.data?.user?.entity_number}</u></span>
                    <div style={{ marginRight: "8px" }}>חתימה</div>
                    <span style={{ marginRight: "8px" }}>____________</span>
                </div>
            </div>
        </div>

    )
}
