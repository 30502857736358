import React from "react";
import { Nav } from "@fluentui/react/lib/Nav";
import "../../scssPages/sidebar.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { INavStyles, INavLinkGroup, INavLink } from "@fluentui/react/lib/Nav";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { /*useHistory, useLocation,*/ withRouter } from "react-router-dom";
import '../../scssPages/sidebar.scss'
import { useTranslation } from "react-i18next";
import { registerIcons } from "@fluentui/react";
import { faFilter } from '@fortawesome/free-solid-svg-icons';
const navStyles: Partial<INavStyles> = { root: { width: 150 } };
initializeIcons();
// registerIcons({
//   icons: {
//     university: <FontAwesomeIcon icon='university' />
//   }
// });


registerIcons({
  icons: {
    Filter: <FontAwesomeIcon icon={faFilter} />
  }
});

const ContentSideClient = (props: any) => {
  const [t] = useTranslation(); ///function of translate
  // const [selectedKey, setSelectedKey] = useState()
  // const history = useHistory()
  // const location = useLocation()
  const handleNavClick = (

    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {

    ev?.preventDefault();
    props.history.push(item?.url);
    // if (location.pathname.includes("/customer-details")) {
    //   props.history.push("/customer-details")
    // }
  };

  // useEffect(() => {
  //   if (location.pathname.includes("/customer-details")) {   
  //   }
  // }, [location])

  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          key: "home-page",
          className: "side-sub",
          iconProps: { iconName: "Home" }
          , styles: {
            icon: { color: '#1A1F71' }
          },
          name: t("homePage"),
          url: "home-page",
          onClick: handleNavClick

        },
        {
          key: "customer-search",
          iconProps: { iconName: "Search" },
          styles: {
            icon: { color: '#1A1F71' }
          },
          name: t("customerSearch"),
          url: "/customer-search",
          onClick: handleNavClick
        },
        {
          key: "customer-details",
          iconProps: { iconName: "Search" },
          styles: {
            icon: { color: '#1A1F71' }
          },
          url: "/customer-details",
          name: t("customerDetails"),

          component: "CustomerDetails",
          onClick: handleNavClick
        },
        {
          key: "related-factors",
          iconProps: { iconName: "Search" },
          styles: {
            icon: { color: '#1A1F71' },
          },
          url: "/related-factors",
          name: t("RelatedFactors"),

          component: "CustomerDetails",
          onClick: handleNavClick
        },
        {
          key: "customer-plan",
          iconProps: { iconName: "Search" },
          styles: {
            icon: { color: '#1A1F71' }
          },
          url: "/customer-plan",
          name: t("customerPlan"),

          component: "CustomerPlan",
          onClick: handleNavClick
        },
        {
          key: "3",

          iconProps: { iconName: 'Search' },
          url: "/bank-accounts",
          component: "BankAccounts",
          name: t("bankAccounts"),
          onClick: handleNavClick
        },


        {
          key: "5",
          iconProps: { iconName: "Sunny" },
          url: "/obligo",
          component: "Obligo",
          name: t("obligo"),
          onClick: handleNavClick
        },
        {
          key: "6",
          iconProps: { iconName: "Sunny" },
          url: "/moneyLaundering",
          component: "moneyLaundering",
          name: t("moneyLaundering"),
          onClick: handleNavClick
        },
        // {
        //   key: "7",
        //   iconProps: { iconName: "Sunny" },
        //   url: "/price-list",
        //   component: "PriceList",
        //   name: t("spriceList"),
        //   onClick: handleNavClick
        // },
        {
          key: "8",
          iconProps: { iconName: "Sunny" },
          url: "/contact",
          component: "Contact",
          name: t("contactInformation"),
          onClick: handleNavClick
        },
        // {
        //   key: "9",
        //   iconProps: { iconName: "Sunny" },
        //   url: "/loans",
        //   component: "Loans",
        //   name: t("loans"),
        //   onClick: handleNavClick
        // },
        {
          key: "10",
          iconProps: { iconName: "Attach" },
          url: "/documents",
          component: "Documents",
          name: t("documents_b"),
          onClick: handleNavClick
        },
        // {
        //   key: "12",
        //   url: "/concentration-of-ctions",
        //   component: "ConcentrationOfActions",
        //   iconProps: { iconName: "Attach" },
        //   name: t("concentrationOfActions"),
        //   onClick: handleNavClick
        // },
        // {
        //   key: "13",
        //   url: "/log-changes",
        //   component: "LogChanges",
        //   iconProps: { iconName: "Attach" },
        //   name: t("logChanges"),
        //   onClick: handleNavClick
        // },
        // {
        //   key: "14",
        //   iconProps: { iconName: "Sunny" },
        //   url: "/moneyLaundering2",
        //   component: "GetService",
        //   name: "הלבנת הון 2",
        //   onClick: handleNavClick
        // },
      ]
    }
  ];
  return (
    <>
      <Nav className="items" onLinkClick={handleNavClick} styles={navStyles} groups={navLinkGroups} />
    </>
  );
};
export default withRouter(ContentSideClient);
