import moment from 'moment';
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import './Reports.scss'

export default function ReportingDetailsTable(props: any) {

    const { allData, dataOfbranch, dataOfReporter,setIfContinue } = props
    const [data, setData]: any = useState([]);
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    // const allData = useSelector(({ reportsReducer }: any) => reportsReducer?.dataOfFieldsUnRegularReports)
    // const dataOfbranch = useSelector(({ reportsReducer }: any) => reportsReducer?.dataOfFieldsUnRegularReports?.impa_utr_branch)
    // const dataOfReporter = useSelector(({ reportsReducer }: any) => reportsReducer?.dataOfFieldsUnRegularReports?.impa_utr_reporter)

    useEffect(() => {
        let dataObj: any
        debugger
        if (dataOfbranch) {
            dataObj = [{ title: "שם מלא של הגורם המדווח", value: dataOfbranch?.entity_name },
            { title: " תעודה מזהה של הגורם המדווח", value: dataOfbranch?.ID_number },
            { title: "מספר סניף מדווח", value: dataOfbranch?.entity_number },
            { title: "מען הסניף", value: "" },
            { title: "תאריך כתיבת הדיווח", value: moment().format("DD/MM/YYYY") },
            { title: "מספר דיווח", value: allData?.impa_utr_number },
            {
                // dataOfbranch?.telephone_number + ",  " +
                title: "טלפון ופסקימליה של הגורם המדווח", value:
                    dataOfbranch?.fax_number
            },
            { title: "מען גורם המדווח", value: dataOfReporter["address"]?.compliance_officer_name },
            { title: "שם פרטי ושם משפחה של עובד עורך הדיווח", value: dataOfReporter?.entity_name },
            { title: "ת\"ז של עורך הדיווח", value: dataOfReporter?.ID_number },
            { title: "תפקיד עורך הדיווח", value: dataOfReporter?.compliance_officer_name },
            { title: "טלפון עורך הדיווח", value: dataOfReporter?.telephone?.telephone_number },
            ]
            setData(dataObj)
        }
    }, [dataOfbranch])
    
    useEffect(() => {
        if (data?.length > 0) {
            setIfContinue(true)
        }
    }, [data])

    return (
        <div className="div-width" style={{ width: "100%", direction: "rtl" }} >
            <h6 className='title-page' style={{
                textAlign: "center"
                , fontWeight: "bold"
                , textDecoration: "underline 2px"
                , fontSize: "14px"
            }}><u>פרטי המדווחים</u></h6>
            <table style={{ width: "100%", fontSize: "12px", fontFamily: "david" }}>
                <tbody>
                    <tr>
                        <th style={{ borderBottom: " 1px solid black", borderRight: "1px solid black",
                         borderLeft: "1px solid black", borderTop: "1px solid black", width: '50%',
                          textAlign: 'center',"margin":"0px",padding:"0" }}>
                            סוג הגוף המדווח</th>
                        <th style={{ borderBottom: " 1px solid black", borderLeft: "1px solid black", borderTop: "1px solid black", width: '50%'
                        , textAlign: 'center',margin:"0px",padding:"0px" }}>
                            נותן שירות פיננסי</th>
                    </tr>
                    {data?.map((item: any, index: any) => (
                        <tr key={index} >
                            <td style={{ borderBottom: " 1px solid black", borderRight: "1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{item?.title}</td>
                            <td style={{ borderBottom: " 1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px" }}>{item?.value}</td>
                        </tr>))}
                </tbody>
            </table>
        </div>
    )
}
