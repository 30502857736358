import { useState } from "react";
import {
  Nav,
  INavLinkGroup,
  INavStyles,
  INavLink,
} from "@fluentui/react/lib/Nav";
import { registerIcons } from "@fluentui/react";
import "../../scssPages/sidebar.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import {
  RESET_DATA_CONVERSION,
  RESET_DATA_INVOICE,
  SAVE_DRAFT_CONVERSION_CHANGES,
} from "../../store/actions/types";
import { DialogMessages } from "../../shared/components/dialog/DialogMessages";
import { getGatesData } from "../../store/actions/convertionActions";

import "../../modelsType/Icons/css/all.css";
import { useTranslation } from "react-i18next";

registerIcons({
  icons: {
    Users: <i className="fal fa-user"></i>,
    CogsIcon: <i className="fal fa-cogs"></i>,
    HandHoldingUsd: <i className="fal fa-usd-circle"></i>,
    landmarkIcon: <i className="fal fa-landmark"></i>,
    FileIcon: <i className="fal fa-file"></i>,
    FolderIcon: <i className="fal fa-copy"></i>,
  },
});

export const SideMain = (props: any) => {
  const { setcategory, category } = props;
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);
  const location = useLocation();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPermissionNote, setShowPermissionNote] = useState(false);
  const [msgPermission, setMsgPermission] = useState("");
  const modules = useSelector(
    ({ authReducer }: any) => authReducer?.data?.credentials
  );
  const [displaySetting, setdisplaySetting] = useState<any>();
  const [modulesArrDetails, setModulesArrDetails] = useState<any>();
  const moduleDetails: any = {
    customers: {
      className: "linkItems",
      key: "customers",
      iconProps: { iconName: "Users", color: "#FFB400" },
      styles: { icon: { color: "#FFB400" } },
      name: t("customers_link"),
      category: "customers",
    },
    currency_exchange: {
      className: "linkItems",
      key: "currency_exchange",
      iconProps: { iconName: "HandHoldingUsd", color: "#FFB400" },
      styles: { icon: { color: "#FFB400" } },
      name: t("exchanges_link"),
      category: "currency_exchange",
    },
    cheques_cashing: {
      className: "linkItems",
      key: "cheques_cashing",
      iconProps: { iconName: "landmarkIcon", color: "#FFB400" },
      styles: { icon: { color: "#FFB400" } },
      name: t("cashings_link"),
      category: "cheques_cashing",
    },
    bookkeeping: {
      className: "linkItems",
      key: "bookkeeping",
      iconProps: { iconName: "FolderIcon", color: "#FFB400" },
      styles: { icon: { color: "#FFB400" } },
      name: t("bookkeepings_link"),
      category: "bookkeeping",
    },
    reports: {
      className: "fal fa-file linkItems ",
      key: "reports",
      iconProps: { iconName: "FileIcon" },
      name: t("reports_link"),
      category: "reports",
    },
    setting: {
      className: "linkItems",
      key: "setting",
      style: { marginBottom: "50px" },
      iconProps: { iconName: "CogsIcon" },
      name: t("settings_link"),
      category: "setting",
    },
  };
  // const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.user?.credentials)
  const changedLocation = useSelector(
    ({ authReducer }: any) => authReducer?.changedLocation
  );
  const navStyles: Partial<INavStyles> = {
    link: {
      color: "#FFB400 ",
      fontFamily: "Segoe UI",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      height: "60px",
      padding: "10px",

      ":after": dir === "ltr" && {
        borderLeft: "2px solid transparent !important",
        borderRight: "2px solid rgb(0, 120, 212) !important",
      },
    },
    root: {
      selectors: {
        ".ms-Button-icon": {
          color: "#FFB400",
        },
        " .ms-Button-icon:active": {
          color: "blue",
        },
      },
      i: { marginTop: "8px" },
      span: { display: "inline-grid" },
      setting: {
        marginTop: "0px",
        marginRight: "3px",
      },
    },

    navItem: {
      order: 99,
      icon: {
        className: "linkItems",
        fontFamily: "Segoe UI",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        height: "60px",
        padding: "10px",
      },
      selectors: {
        [`.linkItems:checked`]: {
          display: "inline",
        },
      },
    },
  };
  useEffect(() => {
    if (modules?.length) {
      let copyModule: any = [];
      modules?.map((module: any) => {
        if (
          module.module_code !== "setting" &&
          module?.module_code !== "home_page"
        ) {
          copyModule.push({
            ...moduleDetails[module?.module_code],
            url: `/${moduleDetails[module?.module_code]?.key}/${
              module.pages[0].page_code
            }`,
          });
        }
        if (module.module_code === "setting") setdisplaySetting(true);
      });
      setModulesArrDetails(copyModule);
    }
  }, [modules]);

  useEffect(() => {
    const key = location.pathname.split("/")[1];
    setcategory(key || "customers");
  }, []);

  useEffect(() => {
    if (changedLocation) {
      setcategory(changedLocation?.state?.category || "customers");
      history.push(changedLocation?.pathname);
    }
  }, [changedLocation]);

  const handleNavClick = async (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    ev?.preventDefault();
    let path: any = item?.url.split("/")[2];
    // if (credentials[path] && credentials[path] === "Deny" || !credentials[path]) {
    //   history.push(`/${item?.url.split("/")[1]}`)
    //   setMsgPermission(t('permissionAaccessThisPage'))
    //   setcategory(item?.category)
    //   location.state = ({ category: item?.category })
    //   setShowPermissionNote(true)
    // }
    // else {
    if (location.pathname.split("/")[1] !== item?.category) {
      const url = item?.url || "";
      let arrUrl: any = [
        "/currency_exchange/exchange",
        "/bookkeeping/money_in",
        "/cheques_cashing/cheque_cashing",
        "/bookkeeping/invoice",
        "/bookkeeping/receipt",
      ];
      let index = arrUrl.findIndex((item: any) => item === location.pathname);
      if (
        (category === "currency_exchange" ||
          category === "cheques_cashing" ||
          category === "bookkeeping") &&
        index !== -1
      ) {
        if (category === "bookkeeping") {
          sessionStorage.setItem("dataAccount", "");
          await dispatch({
            type: RESET_DATA_INVOICE,
            payload: null,
          });
        } else {
          sessionStorage.setItem("dataConversion", "");
          await dispatch({
            type: RESET_DATA_CONVERSION,
            payload: null,
          });
        }
        await dispatch({
          type: SAVE_DRAFT_CONVERSION_CHANGES,
          payload: {
            changeLocation: true,
            location: url,
            setcategory: setcategory,
            nameCategory: item?.category,
          },
        });
      } else {
        if (item?.category === "cheques_cashing") {
          let isGet = sessionStorage.getItem("isGetRates");
          if (isGet !== "false") {
            sessionStorage.setItem("isGetRates", "false");
            await dispatch(getGatesData("ILS"));
          }
        }
        setcategory(item?.category);
        location.state = { category: item?.category };
        await dispatch({
          type: SAVE_DRAFT_CONVERSION_CHANGES,
          payload: {},
        });
        history.replace(url);
      }
    }

    // }
  };

  const navLinkGroups: INavLinkGroup[] = [{ links: [] }];
  modulesArrDetails?.map((module: any) =>
    navLinkGroups[0]?.links.push({
      className: module?.className,
      key: module?.key,
      iconProps: module?.iconProps,
      name:
        module?.category === "bookkeeping"
          ? t("bookkeepings_link")
          : t(module?.category),
      category: module?.category,
      url: module?.url,
      onClick: handleNavClick,
    })
  );

  const navLinkGroups2: INavLinkGroup[] = [
    {
      links: [
        {
          className: "linkItems",
          key: "setting",
          style: { marginBottom: "50px" },
          iconProps: { iconName: "CogsIcon" },
          name: t("settings_link"),
          category: "setting",
          url: `/setting/${
            modules?.find((item: any) => item?.module_code === "setting")
              ?.pages[0].page_code
          }`,
          onClick: handleNavClick,
        },
      ],
    },
  ];

  function linkClickHandler(event: any) {
    event.preventDefault();
  }

  return (
    <>
      {showPermissionNote && (
        <DialogMessages
          setansDelete={setShowPermissionNote}
          setshowModalDelete={setShowPermissionNote}
          subText={msgPermission}
          dialogType={"dialogMessage"}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Nav
          className="category"
          styles={navStyles}
          onLinkClick={linkClickHandler}
          ariaLabel="Nav example similar to one found in this demo page"
          groups={navLinkGroups}
          selectedKey={category}
        />
        {displaySetting && (
          <Nav
            className="category"
            styles={navStyles}
            onLinkClick={linkClickHandler}
            ariaLabel="Nav example similar to one found in this demo page"
            groups={navLinkGroups2}
            selectedKey={category}
          />
        )}
      </div>
    </>
  );
};
