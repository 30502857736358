import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useBoolean } from '@fluentui/react-hooks';
import { PrimaryButton, Dialog,ContextualMenu, DialogFooter, DefaultButton } from 'office-ui-fabric-react';

import { IUserCredentials } from '../customerDetails/CustomerDetails'
import { updatePricePlanTemplate, createPricePlanTemplate, deletePricePlanTemplate } from '../../store/actions/PricePlanTemplateAction'
import { CustomTextField } from '../../shared/components/TextField';
import { CustomToggle } from '../../shared/components/Toggle';

import "./pricePlan.css"
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import moment from 'moment';

const EditablePricePlan = (props: any) => {
    const { setShowDialog, edit, itemUpdate, setShowDialogUpdate, setShowDialogSave } = props
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [requiredField, setRequiredField] = useState(false)
    const [showRequiredDislogFields, setShowRequiredDislogFields] = useState(false)
    const [readOnly, /*setreadOnly*/] = useState(false)
    const [updatePricePlan, setUpdatePricePlan] = useState<any>(itemUpdate ? itemUpdate : {
        key: '', planName: '', default: 0, status: 1, properties: {
            foreign_cheque_deposit_fee: {}, cheque_refund_fee: {}, new_account_fee: {}, israeli_cheque_deposit_fee: {},
            repeat_deposit_fee: {}, wire_rtgs_fee: {}, wire_fee: {}, abroad_wire_fee: {},
            cheque_collection_fee: {}, markup_conversion_fee: {}, cheque_cashing_cash_rate: {},
            cheque_cash_fee: {}, cheque_cashing_rate: {}, arrears_interest: {}, cheque_cashing_value_days: {}
        }, note: ''
    })

    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
      };
    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        dragOptions: dragOptions 
    };

    useEffect(() => {
        itemUpdate && setUpdatePricePlan(itemUpdate)
    }, [itemUpdate])

    useEffect(() => {
        if (setShowDialog) {
            toggleHideDialog()
        }
    }, [setShowDialog])

    useEffect(() => {
        setUserCredentials({
            "idInitiator": authReducer.data.user.id_entity,
            "idClient": authReducer.data.client.id_entity,
            "idBranch": authReducer.data.branch.id_entity,
            "idClientData": authReducer.data.client.id_client
        })
    }, [authReducer]);

    const changePricePlan = (key: string, value: any) => {
        setUpdatePricePlan({ ...updatePricePlan, [key]: value })
    }
    const changeProperties = (key: string, value: any) => {
        let properties = updatePricePlan?.properties
        properties[key].value = value
        setUpdatePricePlan({ ...updatePricePlan, "properties": properties })
    }
    const changevalues = (key: string, value: any) => {
        const [id, place] = key.split('.');
        let properties = updatePricePlan.properties
        properties[id][place] = value
        setUpdatePricePlan({ ...updatePricePlan, "properties": properties })
    }

    const cancelPricePlan = async () => {
       let pricePlan= authReducer?.data?.branch?.price_programs_templates.find((item:any)=>item.price_program_name==="");
        if (updatePricePlan?.planName&&!pricePlan) {
            toggleHideDialog();
            setShowDialog(false)
        }
        else {
            await dispatch(deletePricePlanTemplate(updatePricePlan?.key, userCredentials, "", "", "",t))
            toggleHideDialog();
            setShowDialog(false)
        }
    }

    const savePricePlan = async () => {
        if (updatePricePlan?.planName) {
            toggleHideDialog();
            setShowDialog(false);
            if (edit !== undefined) {
                await dispatch(updatePricePlanTemplate(updatePricePlan, userCredentials?.idInitiator, userCredentials?.idClient, userCredentials?.idBranch, setShowDialogUpdate))
            } else {
                await dispatch(createPricePlanTemplate(updatePricePlan, userCredentials?.idInitiator, userCredentials?.idClient, userCredentials?.idBranch, setShowDialogSave))
            }
        }
        else {
            setRequiredField(true)
            setShowRequiredDislogFields(true)
        }
    }

    return (
        <>
            <Dialog hidden={hideDialog} modalProps={modelProps} onDismiss={() => { toggleHideDialog(); setShowDialog(false) }} containerClassName={'ms-dialogMainOverride textDialog'}>
                <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{t("planPrice")}</p>
                <hr className="hr" ></hr>
                {showRequiredDislogFields && <DialogMessages setansDelete={setShowRequiredDislogFields} setshowModalDelete={setShowRequiredDislogFields} dialogType={"requiredFields"} />}
                <div className="wrap-dialog" style={{ width: "60%" }}>
                    <div className="wrapper-collateral">
                        <div className={`warpper-fields-right-${dir}`} style={{ width: "50%" }}>
                            <CustomTextField autoFocus={true} requiredField={requiredField && !updatePricePlan?.planName ? true : false} value={updatePricePlan?.planName} readOnly={readOnly} required={true} label={t('planName')} onChange={changePricePlan} id={'planName'} />
                        </div>
                        {edit !== undefined && <div className={`warpper-fields-left-${dir}`} style={{ width: "47%" }}>
                            <CustomTextField value={moment(updatePricePlan?.creationDate).format("DD/MM/YYYY")} readOnly={true} onChange={''} label={t('creationDate')} id={'creationDate'} />
                        </div>}
                    </div>
                    <div className={`warpper-fields-right-${dir}`} style={{ width: "100%" }}>
                        <CustomTextField label={t('note')} value={updatePricePlan?.note} onChange={changePricePlan} id={'note'} />
                    </div>
                    <div className={`warpper-fields-left-${dir}`} style={{ width: "98%", marginTop: "14px" }}>
                        <CustomToggle onText={t('defaultPlan')} readOnly={readOnly} onChange={changePricePlan} id={'default'} defaultChecked={updatePricePlan.default} checked={updatePricePlan.default} offText={t('defaultPlan')} />
                    </div>
                </div>
                <br></br><br></br>

                <div className={`wrapper-collateralPlan div-editable-plan-${dir}`}>
                    <div className={`warpper-fields-rightPl-${dir}`} style={{ width: "100%" }} >
                        <div style={{ width: "100%" }}>
                            <div className="titleC">
                                <p className="title-text boldC" style={{ width: "32.5%", marginTop: '0' }}>{t("type_action")}</p>
                                <p className="title-text boldC" style={{ width: "25.5%", marginTop: '0' }}>{t("calculationType")}</p>
                                <p className="title-text boldC" style={{ width: "60%", marginTop: '0' }}>{t("details")}</p></div>
                        </div>
                        <hr style={{ width: "100%", height: "3px" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("new_account_fee")}</p>
                            <div style={{ width: "25.5%" }}>
                                <p className="bold">{t("const")}</p>
                            </div>
                            <div style={{ width: "60%" }}>
                                <div style={{ width: "27%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.new_account_fee?.value} onChange={changeProperties} id={"new_account_fee"} />
                                </div>
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("israeli_cheque_deposit_fee")}</p>
                            <div style={{ width: "25.5%" }}>
                                <p className="bold">{t("const")}</p></div>
                            <div style={{ width: "60%" }}>
                                <div style={{ width: "27%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.israeli_cheque_deposit_fee?.value} onChange={changeProperties} id={"israeli_cheque_deposit_fee"} />
                                </div>
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("foreign_cheque_deposit_fee")}</p>
                            <div style={{ width: "25%" }}>
                                <p className="bold">{t("const")}</p></div>
                            <div style={{ width: "60%" }}>
                                <div style={{ width: "27%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.foreign_cheque_deposit_fee?.value} onChange={changeProperties} id={"foreign_cheque_deposit_fee"} /></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("cheque_refund_fee")}</p>
                            <div style={{ width: "25%" }}>
                                <p className="bold">{t("const")}</p></div>
                            <div style={{ width: "60%" }}>
                                <div style={{ width: "27%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.cheque_refund_fee?.value} onChange={changeProperties} id={"cheque_refund_fee"} /></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("repeat_deposit_fee")}</p>
                            <div style={{ width: "25%" }}>
                                <p className="bold">{t("const")}</p></div>
                            <div style={{ width: "60%" }}>
                                <div style={{ width: "27%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.repeat_deposit_fee?.value} onChange={changeProperties} id={"repeat_deposit_fee"} /></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("wire_rtgs_fee")}</p>
                            <div style={{ width: "25%" }}>
                                <p className="bold">{t("const")}</p></div>
                            <div style={{ width: "60%" }}>
                                <div style={{ width: "27%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.wire_rtgs_fee?.value} onChange={changeProperties} id={"wire_rtgs_fee"} /></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("wire_fee")}</p>
                            <div style={{ width: "25%" }}>
                                <p className="bold">{t("const")}</p></div>
                            <div style={{ width: "60%" }}>
                                <div style={{ width: "27%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.wire_fee?.value} onChange={changeProperties} id={"wire_fee"} /></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("wire_fee")}</p>
                            <div style={{ width: "25%" }}>
                                <p className="bold">{t("const")}</p></div>
                            <div style={{ width: "60%" }}>
                                <div style={{ width: "27%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.abroad_wire_fee?.value} onChange={changeProperties} id={"abroad_wire_fee"} /></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("cheque_cashing_cash_rate")}</p>
                            <p className="boldF" style={{ width: "24%" }}>{t("percent")}</p>
                            <div className="fields" >
                                <div className="field" style={{ width: "60%" }} >
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.cheque_cashing_cash_rate?.percent} onChange={changevalues} id={"cheque_cashing_cash_rate.percent"} />
                                </div>  <p className="boldF">{t("minimum")}</p>
                                <div className="field" >
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.cheque_cashing_cash_rate?.value_min} onChange={changevalues} id={"cheque_cashing_cash_rate.value_min"} /></div>
                                <p className="boldF">{t("maximum")}</p>
                                <div className="field" >
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.cheque_cashing_cash_rate?.value_max} onChange={changevalues} id={"cheque_cashing_cash_rate.value_max"} /></div>
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("cheque_cash_fee")}</p>
                            <p className="boldF" style={{ width: "24%" }}>{t("percent")}</p>
                            <div className="fields" >
                                <div className="field" style={{ width: "60%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.cheque_cash_fee?.percent} onChange={changevalues} id={"cheque_cash_fee.percent"} />
                                </div>  <p className="boldF">{t("minimum")}</p>
                                <div className="field" >
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.cheque_cash_fee?.value_min} onChange={changevalues} id={"cheque_cash_fee.value_min"} /></div>
                                <p className="boldF">{t("maximum")}</p>
                                <div className="field" >
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.cheque_cash_fee?.value_max} onChange={changevalues} id={"cheque_cash_fee.value_max"} /></div>
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("cheque_cashing_rate")}</p>
                            <p className="boldF" style={{ width: "24%" }}>{t("percent")}</p>
                            <div className="fields">
                                <div className="field" style={{ width: "60%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.cheque_cashing_rate?.percent} onChange={changevalues} id={"cheque_cashing_rate.percent"} />
                                </div>  <p className="boldF">{t("minimum")}</p>
                                <div className="field" >
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.cheque_cashing_rate?.value_min} onChange={changevalues} id={"cheque_cashing_rate.value_min"} /></div>
                                <p className="boldF">{t("maximum")}</p>
                                <div className="field" >
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.cheque_cashing_rate?.value_max} onChange={changevalues} id={"cheque_cashing_rate.value_max"} /></div>
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("arrears_interest")}</p>
                            <p className="boldF" style={{ width: "24%" }}>{t("percent")}</p>
                            <div className="fields">
                                <div className="field" style={{ width: "60%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.arrears_interest?.percent} onChange={changevalues} id={"arrears_interest.percent"} />
                                </div>  <p className="boldF">{t("minimum")}</p>
                                <div className="field" >
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.arrears_interest?.value_min} onChange={changevalues} id={"arrears_interest.value_min"} /></div>
                                <p className="boldF">{t("maximum")}</p>
                                <div className="field" >
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.arrears_interest?.value_max} onChange={changevalues} id={"arrears_interest.value_max"} /></div>
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("cheque_collection_fee") + "81"}</p>
                            <div style={{ width: "25%" }}>
                                <p className="bold">{t("const")}</p></div>
                            <div style={{ width: "60%" }}>
                                <div style={{ width: "27%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.cheque_collection_fee?.value} onChange={changeProperties} id={"cheque_collection_fee"} /></div></div>
                        </div>
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <p className="boldPP" style={{ width: "32.5%" }}>{t("cheque_collection_fee") + "82"}</p>
                            <div style={{ width: "25%" }}>
                                <p className="bold">{t("const")}</p></div>
                            <div style={{ width: "60%" }}>
                                <div style={{ width: "27%" }}>
                                    <CustomTextField label={" "} value={updatePricePlan?.properties?.markup_conversion_fee?.value} onChange={changeProperties} id={"markup_conversion_fee"} /></div></div>
                        </div>


                        <p className="title-text" >{t("other_fees")}</p>
                        <hr className="hr" style={{ width: "100%" }}></hr>

                        <div style={{ width: "100%" }}>
                            <div className="titleC">
                                <p className="title-text boldC" style={{ width: "33%" }}>{t("name")}</p>
                                <p className="title-text boldC" style={{ width: "25%" }}>{t("calculationType")}</p>
                                <p className="title-text boldC" style={{ width: "60%" }}>{"details"}</p></div>
                        </div>
                        <hr style={{ width: "100%", height: "3px" }} ></hr>
                        {/* <hr className="hr" style={{ width: "100%" }} ></hr> */}
                        <div style={{ width: "100%" }}>
                            <div className="inlineB">
                                <p className="boldPP">{t("cheque_cashing_value_days")}</p>
                                <div style={{ width: "25%" }}>
                                    <p className="bold">{t("const")}</p></div>
                                <div style={{ width: "60%" }}>
                                    <div style={{ width: "27%" }}>
                                        <CustomTextField label={" "} value={updatePricePlan?.properties?.cheque_cashing_value_days?.value} onChange={changeProperties} id={"cheque_cashing_value_days"} /></div></div>
                            </div>
                        </div>

                        {/* {updatePricePlan?.map((item: any, index: number) => (
                            <div key={index}>
                                {item?.type === "fee" || item?.fee === "fee" ? <>
                                    <hr className="hr" style={{ width: "100%", marginBottom: '0' }} ></hr>

                                    {(item?.calculation_type === "const" || item?.calculation_type === "קבוע") ?
                                        < div style={{ width: "100%" }}>
                                            <div className={`inlineBP-${dir}`}>
                                                <p className="boldPP">{item?.description}</p>
                                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                                    <p className="boldPP">{"קבוע"}</p></div>
                                                <div style={{ width: "60%", height: "fit-content", marginTop: "2px", marginBottom: " 6px" }}>
                                                    <div className="inside">
                                                        <CustomTextField label={" "} readOnly={readOnly} value={item.value} onChange={changeProperties} id={item?.name} /></div></div></div>
                                        </div> :
                                        <div style={{ width: "100%" }}>
                                            <div className={`inlineBP-${dir}`} style={{ marginTop: "1px", marginBottom: "9px" }}>
                                                <p className="boldPP">{item?.description}</p>
                                                <div style={{ width: "25%", height: "fit-content", alignSelf: "center" }}>
                                                    <p className="boldPP">{t("percent")}</p></div>
                                                <div className="fields">
                                                    <div className="field" >
                                                        <CustomTextField label={" "} readOnly={readOnly} value={item?.percent} onChange={changeProperties} id={item?.name + ".percent"} prefix="%" />
                                                    </div>  <p className="boldF">{t("minimum")}</p>
                                                    <div className="field" >
                                                        <CustomTextField label={" "} readOnly={readOnly} value={item?.value_min} onChange={changeProperties} id={item?.name + ".value_min"} /></div>
                                                    <p className="boldF">{t("maximum")}</p>
                                                    <div className="field" >
                                                        <CustomTextField label={" "} readOnly={readOnly} value={item?.value_max} onChange={changeProperties} id={item?.name + ".value_max"} /></div>
                                                </div></div>
                                        </div>}
                                </> : ""}
                            </div>
                        ))} */}
                        <hr className="hr" style={{ width: "100%" }} ></hr>
                    </div>
                </div>
                <DialogFooter >
                    <DefaultButton onClick={() => { cancelPricePlan() }} text={t("cancel")} />
                    <PrimaryButton onClick={() => { savePricePlan() }} text={t("save")} />
                </DialogFooter>
            </Dialog >
        </>
    )
}

export default EditablePricePlan
