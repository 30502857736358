import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DialogMessages } from '../dialog/DialogMessages';
import { deleteTransaction, createTransaction } from '../../../store/actions/convertionActions'
import { invoiceTransaction, receiptTransaction } from '../../../store/actions/accountAction'

export const CustomPrompt = (props: any) => {
    const { action, ifChanges, saveDraft, subText, userCredentials,
        typeAction, entityName, sumOut, sumIn, resetFunc } = props
    const [t] = useTranslation();
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const [saveDataActoin, setSaveDataActoin] = useState("")
    const [showDialogSaveData, setShowDialogSaveData] = useState(false)

    useEffect(() => {
        if (saveDraft) {
            if (saveDraft?.changeLocation === true && location.pathname !== saveDraft?.location) {
                if (saveDraft?.setcategory) {
                    if (saveDraft?.nameCategory && action?.id_transaction !== null && ifChanges)
                        setShowDialogSaveData(true)
                    else {
                        checkIfDeleteTransaction()
                        saveDraft?.setcategory(saveDraft.nameCategory)
                        location.state = ({ category: saveDraft.nameCategory })
                        history.replace(saveDraft?.location)
                    }
                }
                else {
                    if (saveDraft?.location && saveDraft?.location !== location.pathname) {
                        if (action?.id_transaction !== null && ifChanges)
                            setShowDialogSaveData(true)
                        else {
                            checkIfDeleteTransaction()
                            history.replace(saveDraft?.location)
                        }
                    }
                }
            }
        }
    }, [saveDraft])

    const checkIfDeleteTransaction = async () => {
        if ((typeAction === "invoice" || typeAction === "receipt") && sumIn === 0 && action?.id_transaction) {
            await dispatch(deleteTransaction("delete", typeAction, action, userCredentials, action.id_entity))

        }
        else {
            if (sumIn === 0 && sumOut === 0 && action?.id_transaction) {
                await dispatch(deleteTransaction("delete", typeAction, action, userCredentials, typeAction === "money_in" ? userCredentials["idBranch"] : action.id_entity))
            }
        }
    }

    const changeLocation = async () => {
        checkIfDeleteTransaction()
        if (saveDraft?.setcategory) {
            saveDraft?.setcategory(saveDraft.nameCategory)
            location.state = ({ category: saveDraft.nameCategory })
            history.replace(saveDraft?.location)
        }
        else saveDraft?.location && history.replace(saveDraft?.location)

    }

    const activeAction = async () => {
        if (typeAction === "money_in")
            await dispatch(deleteTransaction("delete", typeAction, action, userCredentials, userCredentials["idBranch"]))
        if (typeAction === "invoice") {
            await dispatch(invoiceTransaction("update", typeAction, entityName, action, userCredentials, action.id_entity))
        }
        if (typeAction === "receipt") {
            await dispatch(receiptTransaction("update", typeAction, entityName, action, userCredentials, action.id_entity))
        }
        else {
            await dispatch(createTransaction("update", typeAction, entityName, action, userCredentials, action.id_entity))
        }
        resetFunc && resetFunc()
        changeLocation()
    }

    useEffect(() => {
        if (saveDataActoin === "save") {
            activeAction()
        }
        if (saveDataActoin === "don't save" && typeAction !== "money_in")
            changeLocation()
    }, [saveDataActoin])



    return (
        <>
            {showDialogSaveData && typeAction === "money_in" ? <DialogMessages setansDelete={setShowDialogSaveData}
                setshowModalDelete={setShowDialogSaveData} subText={subText} title={t('note')}
                dialogType={"dialogMessage"} setSaveDataConversion={setSaveDataActoin} />

            :showDialogSaveData && <DialogMessages setansDelete={setShowDialogSaveData}
                setshowModalDelete={setShowDialogSaveData} dialogType={"saveConversionData"}
                setSaveDataConversion={setSaveDataActoin} />}

        </>
    )
}