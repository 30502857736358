import axios from 'axios';
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


export default function Gates(props: any) {
    const { gates, listCurrency, setGates } = props;
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    var num: any = 0;
    var num1: any = 0;
    const [minutes, setMinutes] = useState<any>();
    const [seconds, setSeconds] = useState<any>();
    let timeoutId: any

    // open it when we do a build
    useEffect(() => {
        let isGet = sessionStorage.getItem("isGetRates")
        if (isGet !== "false") {
            sessionStorage.setItem('isGetRates', "false");
            getData();
        }
        else {
            let num = new Date();
            CheckTime(num)
            let arrGates = sessionStorage.getItem("gatesArr")
            if (arrGates) {
                let arr = JSON.parse(arrGates)
                setGates(arr)
            }

        }
    }, [])

    const CheckTime = async (d: any) => {
        let s = d.getSeconds();
        if (s === 0) {
            let m = (5 - (d.getMinutes() % 5));
            setMinutes(m)
            setSeconds(0)
        }
        else {
            let m = d.getMinutes();
            m = (5 - (m % 5) - 1)
            setMinutes(m)
            setSeconds(60 - s)
        }
    }
    const getData = async () => {
        axios({
            method: "get",
            url: 'https://api.currencyapi.com/v2/latest?apikey=aZUBDn80F1y6YTOkaq5i2qLQP0Jj4Ki8V6zquG7v&base_currency=ILS&datetime_end',
        }).then(function (response:any) {
            setGates(response?.data?.data);
            sessionStorage.setItem('gatesArr', JSON.stringify(response?.data?.data));
            let d = new Date();
            CheckTime(d)
        }).catch((err:any) => {
            console.error(err);
        });
    }
    // open it when we do a build
    useEffect(() => {
        timeoutId = setTimeout(() => myInterval(), 1000);
        return () => clearTimeout(timeoutId);
    }, [seconds, minutes])

    const myInterval = async () => {
        if (seconds > 0) {
            setSeconds(seconds - 1)
        }
        if (seconds === 0) {
            if (minutes === 0) {
                await getData();

            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        }
    }

    return (
        <div style={{ width: "100%" }} className={`margin-between-input-${dir}`}>
            <div className="gates-title" style={{ width: "100%" }}>
                <p className="gates-text" > {t("nextUpdateAbout")} {minutes}:{seconds < 10 ? `0${seconds}` : seconds} </p>
            </div>
            {/* <hr className="hr" style={{ width: "100%" }}></hr> */}
            <div className="gates">
                {listCurrency.map((item: any, index: any) => (
                    num1 = gates[item.key] && 1 / gates[item.key],
                    num = num1 ? num1.toFixed(3) : 0,
                    item !== "ILS" &&
                    <div key={index} className='spaceCol'>
                        <label className="boldDC" >{num}</label>
                        <label title={item?.text}
                            className={`iconRefresh-${dir} label-gates-${dir}`} >
                            {item?.currency_symbol}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}