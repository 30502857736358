import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
// import IdleTimer from 'react-idle-timer'

////dont delete open it in the end of project
// import { useIdleTimer } from 'react-idle-timer'

import { SET_AUTH_DATA, SET_DATA_CUSTOMER, SET_ENUMS, SET_DATA_CONVERSION, SET_DATA_INVOICE, REGISTER_SUCCESS } from '../store/actions/types'
import Home from './Home';
import Login from './auth/Login';
import HomeClient from './HomeClient';
import LoginClient from './auth/LoginClient';

const AppNav = () => {

    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [isClient, setisClient] = useState(false)
    const history = useHistory()
    const location = useLocation()
    // let check: any = null;
    const dispatch = useDispatch()

    const isLogin = useSelector((state: any) => {
        // check = state.authReducer.isAuthentitcated;
        // return check
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        
        if (token)
            return true;
    
        else
            return false
    })



    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    // const [isLog, setIsLog] = useState(isLogin)
    // const [showDialogSave, setShowDialogSave] = useState(false)
    // if (isLog === false) {
    //     logout1(dispatch)
    // }
    useEffect(() => {
        if (authReducer.data === null && authReducer.token && sessionStorage.length > 0) {
            if (sessionStorage.getItem("authReducer")) {
                dispatch({
                    type: SET_AUTH_DATA,
                    payload: JSON.parse(sessionStorage.getItem("authReducer") || "")
                })
                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: JSON.parse(sessionStorage.getItem("authReducer") || "")
                })
            }
            if (sessionStorage.getItem("dataCustomer")) {
                dispatch({
                    type: SET_DATA_CUSTOMER,
                    payload: JSON.parse(sessionStorage.getItem("dataCustomer") || "")
                })
            }
            if (sessionStorage.getItem("enums")) {
                dispatch({
                    type: SET_ENUMS,
                    payload: JSON.parse(sessionStorage.getItem("enums") || "")
                })
            }
            if (sessionStorage.getItem("dataConversion")) {
                dispatch({
                    type: SET_DATA_CONVERSION,
                    payload: JSON.parse(sessionStorage.getItem("dataConversion") || "")
                })
                history.location.state = { source: "refresh" }
            }
            if (sessionStorage.getItem("dataAccount")) {
                dispatch({
                    type: SET_DATA_INVOICE,
                    payload: JSON.parse(sessionStorage.getItem("dataAccount") || "")
                })
                history.location.state = { source: "refresh" }
            }
        }
        // eslint-disable-next-line
    }, [authReducer])

    useEffect(() => {
        location.pathname.includes("office") && setisClient(true)
    }, [location])

    //////// dont delete!!!!!!! run at the end of development
    // exit from the application after five minutes of inactivity
    // const handleOnIdle = () => {
    //     if (authReducer?.token) {
    //         alert("indle")
    //         sessionStorage.length > 0 && window.sessionStorage.clear();
    //         window.location.replace("/");
    //     }
    // };
    // // /active the function
    // const { } = useIdleTimer({
    //     timeout: 1000 * 60*5 ,
    //     onIdle: handleOnIdle,
    // })


    return (
        <div className={`dir-${dir}`}>
            {/* <Login/> */}
            {isLogin ? isClient ? <HomeClient /> : <Home /> : isClient ? <LoginClient /> : <Login />}
            {/* <Route path='/customers/related-factors/edit' component={EditableRelatedFactors} /> */}

            {/* 
            {/* {isLogin && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"login"} />} */}
            {/* <Home /> <Login /> */}

            {/* {auth && auth.isAuthenticated ?<Home/> :<Login/> } */}
        </div>
    );


};
// const mapStateToProps = (state: IAuthReduxProps) => ({
//     auth: state.auth
//   });
export default AppNav;
// export default connect(mapStateToProps, null)(AppNav)