import  { useEffect, useState } from 'react'
import CustemTable from '../../shared/components/tabels/tableList';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils'
import './UsersClient.scss'
import Subtitle from '../../shared/components/Subtitle';
import UsersClientDialog from './UsersClientDialog';

const UsersClient = () => {
    const [t] = useTranslation();
    const [dir,setDir]=useState("")
    const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const customerReducer = useSelector(({ customerReducer }: any) => customerReducer)
    const [/*customerName*/, setCustomerName] = useState('')
    const columns = [
        { key: '2', name: t('fullName') , fieldName: "fullName", entity_name: "fullName", minWidth: 70, maxWidth: 70 },
        { key: '3', name: t('identityNumber'), fieldName: "identityNumber", entity_name: "identityNumber", minWidth: 90, maxWidth: 90 },
        { key: '4', name: t('address0') , fieldName: "address", entity_name: "address", minWidth: 70, maxWidth: 70 },
        { key: '5', name: t('phone'), fieldName: "phone", minWidth: 100, maxWidth: 100 },
        { key: '6', name: "רמת הרשאה" , fieldName: "levelPermission", minWidth: 100, maxWidth: 100 },
        { key: '7', name: t('status') , fieldName: "status", minWidth: 100, maxWidth: 100 },
        { key: '8', name: t('edit') , fieldName: "editUser", minWidth: 100, maxWidth: 100 },
    ];
    const items = [
        { key: 0, fullName: "יעקב כהן", identityNumber: "44512552", address: "שדה בר 12", branchAddress: "הרצל 12 תל אביב", city: "ירושלים", country: "ישראל", phone: "050-811-9652" },
        { key: 0, fullName: "יעקב כהן", identityNumber: "44512552", address: "שדה בר 12", branchAddress: "הרצל 12 תל אביב", city: "ירושלים", country: "ישראל", phone: "050-811-9652" },
        { key: 0, fullName: "יעקב כהן", identityNumber: "44512552", address: "שדה בר 12", branchAddress: "הרצל 12 תל אביב", city: "ירושלים", country: "ישראל", phone: "050-811-9652" },
        { key: 0, fullName: "יעקב כהן", identityNumber: "44512552", address: "שדה בר 12", branchAddress: "הרצל 12 תל אביב", city: "ירושלים", country: "ישראל", phone: "050-811-9652" },
    ]
    const [addUser, setaddUser] = useState(false)

    useEffect(() => {
        if (customerReducer)
            setCustomerName(customerReducer.dataCustomer?.properties?.entity_name)
    }, [customerReducer])
    
  useEffect(()=>{
    if(lang){
        setDir(lang)
    }
    },[lang])
    
   
    return (
        <>
            <HeaderUtils title={"settings"} subTitle={"false"} createBtn={true} editBtn={true} deleteBtn={true} uploadBtn={true} />
            <hr className={`hr-user-client-${dir} hr`} ></hr>
            <div className="atteched">
                    <Subtitle title={t('usersList')} />
                    <div className="title-text addFile"  onClick={() => setaddUser(true)} ><i className="fal fa-plus"></i></div>
                    {addUser ? <UsersClientDialog setShowDialog={setaddUser} /> : ""}
                </div>
                <hr className="hr wrap-title"  ></hr>
            <div className="wrap-table">
            {items?.length===0?<div style={{ textAlign:"center",marginTop:"18px", width: "80%"}}>{t('noDisplay')}</div>:<CustemTable tableName="usersList" columns={columns} allItems={items} renderItemColumn rederRow={"edit"} addCustem={true} isFooter={true} />}
            </div>
        </>
    )
}

export default UsersClient
