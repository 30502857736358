import  * as React from 'react';
import { useSelector } from 'react-redux';
import '../../scssPages/sub-header.scss';

interface Title {
    title: string
}

const Subtitle = (prop: Title) => {

    const [dir,setDir]=React.useState("")
    const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)

    React.useEffect(()=>{
        if(lang){
            setDir(lang)
        }
        },[lang])
        
    return (
        <div  >
            <h1 className={`subtitle-${dir}`} >{prop.title}</h1>
        </div>
        // className="subBar"
    )
}

export default Subtitle;