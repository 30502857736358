import { cloneDeep } from 'lodash';
import {
    USERֹֹ_LOADED,
    USERֹֹ_LOADING,
    AUTH_ERROR,
    SET_USER,
    SET_USERS,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGOUT,
    SET_SETTINGS,
    SET_USER_SETTINGS,
    SET_MAIN_ACCOUNTS,
    SET_FAIR_CREDIT,
    SET_PRICE_PROGRAMS_TEMPLATES,
    SET_EMAIL_SETTING,
    SET_AUTH_DATA,
    SET_CONTRA_ACCOUNTS,
    CHANGED_LOCATION,
    RESET_CHANGED_LOCATION,
    SET_MODULES_USER_BRANCH
} from '../actions/types'

const initialState = {
    token: sessionStorage.getItem('token'),
    isAuthentitcated: null,
    isLoading: false,
    user: null,
    data: null,
    changedLocation: null,
    userCredentials: {},
    contraCashAccount: [],
    contraChequeAccount: [],
    contraCreditCardAccount: [],
    contraBankAccount: [],
};

export default function (state = initialState, action: any) {
    switch (action.type) {
        case USERֹֹ_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case USERֹֹ_LOADED:
            return {
                ...state,
                // isAuthenticted:true,  
                isLoading: false,
                user: action.payload
            };
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            sessionStorage.setItem('token', action.payload.token);
            let data = cloneDeep(action.payload)
            sessionStorage.setItem('authReducer', JSON.stringify(data));
            const credentialsObj = data?.data?.credentials?.map((item: any) => ({
                ...item,
                pages: item.pages.sort((a: any, b: any) => a.menu_order - b.menu_order),
            }));
            data.data.credentials = credentialsObj
            let userCredentialsObj = {
                "idInitiator": data?.data?.user?.id_entity,
                "idClient": data?.data?.user?.id_client,
                "idBranch": data?.data?.branch?.id_entity,
                "idClientData": data?.data?.client?.id_client
            }
            const contraAccounts = data?.data?.user?.contra_accounts
            let arrCash: any = [], arrBank: any = [], arrCredit: any = [], arrCheck: any = []
            contraAccounts?.map((account: any) => {
                if (account?.contra_account_group_id === "cash") {
                    arrCash.push({
                        key: account?.id_account, text: account?.account_name + ' ' + account?.account_number,
                        groupId: account?.contra_account_group_id, accountNumber: account?.account_number
                    })
                }
                if (account?.contra_account_group_id === "bank_account") {
                    arrBank.push({
                        key: account?.id_account, text: account?.account_name + ' ' + account?.account_number,
                        groupId: account?.contra_account_group_id, accountNumber: account?.account_number
                    })
                }
                if (account?.contra_account_group_id === "credit_card") {
                    arrCredit.push({
                        key: account?.id_account, text: account?.account_name + ' ' + account?.account_number,
                        groupId: account?.contra_account_group_id, accountNumber: account?.account_number
                    })
                }
                if (account?.contra_account_group_id === "cheque") {
                    arrCheck.push({
                        key: account?.id_account, text: account?.account_name + ' ' + account?.account_number,
                        groupId: account?.contra_account_group_id, accountNumber: account?.account_number
                    })
                }
            })
            return {
                ...state,
                ...data,
                isAuthentitcated: true,
                isLoading: false,
                userCredentials: userCredentialsObj,
                contraCashAccount: arrCash,
                contraChequeAccount: arrBank,
                contraCreditCardAccount: arrCredit,
                contraBankAccount: arrCheck,
            };
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case REGISTER_FAIL:
        case LOGOUT: {
            return {
                token: "",
                isAuthentitcated: null,
                isLoading: false,
                user: null
            }
        }
        case SET_SETTINGS: {
            let arr: any = [];
            arr = { ...state }
            arr.data.branch.setting = action.payload
            sessionStorage.setItem('authReducer', JSON.stringify(arr));
            return {
                ...state,
                data: arr.data
            }
        }
        case SET_USER_SETTINGS: {
            let arr: any = [];
            arr = { ...state }
            arr.data.user.setting = action.payload
            sessionStorage.setItem('authReducer', JSON.stringify(arr));
            return {
                ...state,
                data: arr.data
            }
        }
        case SET_MAIN_ACCOUNTS: {
            let arr: any = [];
            arr = { ...state }
            arr.data.branch.main_accounts = action.payload
            sessionStorage.setItem('authReducer', JSON.stringify(arr));
            return {
                ...state,
                data: arr.data
            }
        }
        case SET_EMAIL_SETTING: {
            let arr: any = [];
            arr = { ...state }
            sessionStorage.setItem('authReducer', JSON.stringify(arr));
            arr.data.branch.email_setting = action.payload
            return {
                ...state,
                data: arr.data
            }
        }
        case SET_FAIR_CREDIT: {
            let arr: any = [];
            arr = { ...state }
            arr.data.branch.fair_credit_limits = action.payload
            sessionStorage.setItem('authReducer', JSON.stringify(arr));
            return {
                ...state,
                data: arr.data
            }
        }
        case SET_USERS: {
            let arr: any = [];
            arr = { ...state }
            arr.data.branch.users = action.payload
            return {
                ...state,
                data: arr.data
            }
        }
        case SET_CONTRA_ACCOUNTS: {
            let arr: any = [], contraAccounts, index;
            arr = { ...state }
            contraAccounts = arr.data?.user?.contra_accounts
            index = contraAccounts.findIndex((item: any) => item?.id_account === action?.payload?.id_account)
            if (contraAccounts[index]) contraAccounts[index].is_default = action?.payload?.is_default
            arr.data.user.contra_accounts = contraAccounts
            return {
                ...state,
                data: arr.data
            }
        }
        case SET_USER: {
            let arr: any = [];
            arr = cloneDeep(state)
            let index = arr.data.branch?.users?.findIndex((entity: any) => entity?.id_entity === action.payload?.id_entity)
            ///// if change from server related_entitiy to other thing update here....
            if (index !== -1) { arr.data.branch.users[index] = action.payload }
            sessionStorage.setItem('authReducer', JSON.stringify(arr));
            return {
                ...state,
                data: cloneDeep(arr.data)
            }
        }
        case SET_PRICE_PROGRAMS_TEMPLATES: {
            let arr: any = [];
            arr = { ...state }
            arr.data.branch.price_programs_templates = action.payload
            sessionStorage.setItem('authReducer', JSON.stringify(arr));
            return {
                ...state,
                data: arr.data
            }
        }
        case SET_AUTH_DATA: {

            let data = cloneDeep(action.payload)
            return {
                ...state,
                ...data,
                // ...action.payload,
                isAuthentitcated: true,
                isLoading: false,
            };
        }
        case CHANGED_LOCATION: {
            return {
                ...state,
                changedLocation: action.payload
            }
        }
        case RESET_CHANGED_LOCATION: {
            return {
                ...state,
                changedLocation: null
            }
        }
        case SET_MODULES_USER_BRANCH: {
            return {
                ...state,
                changedLocation: null
            }
        }
        default: return state;
    }
}