import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useSelector, useDispatch } from 'react-redux'
import { Dialog, ContextualMenu, DialogFooter, IDropdownOption } from 'office-ui-fabric-react';
import { useBoolean } from '@fluentui/react-hooks';
import { isEqual } from 'lodash';

import { CustomerDate } from '../../shared/components/CustomHooks/concatTextFields'
import { Address } from '../../shared/components/CustomHooks/Address'
import { IdNumber, Phone, Email } from '../../shared/components/CustomHooks/concatTextFields'
import DialogSearch from '../global/DialogSearch'
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import { CustomDropdown } from "../../shared/components/Option";
import { CustomTextField } from "../../shared/components/TextField";
import { convertArrToDropdropdown } from '../../components/customerDetails/CustomerDetails'
import { createInsider, updateInsiderDetails, attachInsiderToCustomer } from "../../store/actions/relatedFactorsActions"
import { IUserCredentials } from '../customerDetails/CustomerDetails';
import DocumentsList from '../DocumentsList';
import { SET_CUSTOMER_INSIDER, SET_INSIDER_DATA_CONVERSION } from '../../store/actions/types';
import { CustomToggle } from '../../shared/components/Toggle';
import { useLocation } from 'react-router-dom';
import { checkAge, isCorporationFunc } from '../../shared/components/CustomHooks/GlobalFunctions';

const EditableRelatedFactors = (props: any) => {
    const { idEntityTransaction, setShowDialog, setShowDialogUpdate, itemUpdate, setShowDialogSave,
        setshowSpiner, update = false, setRelatedFactors = undefined, isConversions, typesList } = props
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const { state } = useLocation<any>()
    const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
    const userData = useSelector(({ customerReducer }: any) => customerReducer.dataCustomer)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [enumsDropdown, setenumsDropdown] = useState<any>({ "customersType": [], "genders": [], "typeIdentityNumbers": [], "status": [], "ID_type_id": [], "telephoneCountryCode": [], "countryCode": [], })
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [showDialogFailed, setShowDialogFailed] = useState(false)
    const [serverMessageError, setServerMessageError] = useState<any>('')
    const [messageError, setMessageError] = useState('')
    const [checkedCorporation, setCheckedCorporation] = useState(false)
    const [readOnly, setReadOnly] = useState(false)
    const [idEntity, setidEntity] = useState("")
    const [resultId, setResultId] = useState<any>(true)
    const [validEmail, setValidEmail] = useState(true)
    const [isValidDate, setIsValidDate] = useState<any>(true);
    const [defaultIdTypeId, setDefaultIdTypeId] = useState()
    const [defaultTypeValue, setDefaultTypeValue] = useState()
    const [showTextRequiredFiles, setShowTextRequiredFiles] = useState(false)
    const [requiredDialog, setRequierdDialog] = useState(false)
    const [searchCustomer, setSearchCustomer] = useState<any>()
    const [ifCahnges, setIfCahnges] = useState(false)
    const [ifCahngedType, setIfCahngedType] = useState(false)
    const [items, setItems] = useState<any>([])
    const [activeDocumentsList, setActiveDocumentsList] = useState<any>([])
    const [documents, setDocuments] = useState<any>([])
    const [showBeneficiaryFields, setShowBeneficiaryFields] = useState(false)
    const listRelatedType: IDropdownOption[] = [{ key: "11", text: t('beneficiary') }, { key: "13", text: t('guarantor') }]
    const [updateInsider, setUpdateInsider] = useState(itemUpdate ? itemUpdate : {
        gender: 0, genderOther: "", entitySubTypeId: 0, factorTypeName: "", firstName: "", lastName: "", classId: 1,
        idNumber: "", idTypeId: "", idTypeOther: "", idCountryCode: "", default: false,
        address: "", addressName: "", addressNumber: "", addressCity: "", addressCityName: "", addressZipCode: "", addressCountryCode: '', telephone: "",
        telephoneCountryCode: "", dateBirth: "", note: "", email: "", emailTypeId: 1, telephoneDefault: 1,
        addressDefault: 1, emailDefault: 1, addressId: 0, telephoneTypeId: 1, key: "", fullName: ""
    })

    const datacConversion = useSelector(({ conversionReducer }: any) => conversionReducer.dataConversion)


    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
    };
    const modelProps = {
        dragOptions: dragOptions,
        isBlocking: true,
        isModeless: false,
    };

    useEffect(() => {
        typesList?.length === 1 && typesList[0].key === "11" && setShowBeneficiaryFields(true)
    }, [typesList])

    useEffect(() => {
        if (enums) {
            let arr = {
                "entitySubType": convertArrToDropdropdown([]),
                "countryCode": convertArrToDropdropdown([]),
                "genders": convertArrToDropdropdown([]),
                "typeIdentityNumbers": convertArrToDropdropdown([]),
                "telephoneCountryCode": convertArrToDropdropdown([]),
            }
            for (let i = 0; i < enums.length; i++) {
                if (enums[i].general !== undefined) {
                    let general = enums[i].general;
                    for (let j = 0; j < general.length; j++) {
                        if (general[j].country_code !== undefined)
                            arr.countryCode = convertArrToDropdropdown(general[j].country_code)
                        if (general[j].telephone_country_code !== undefined)
                            arr.telephoneCountryCode = convertArrToDropdropdown(general[j].telephone_country_code)
                    }
                }
                if (enums[i].customer !== undefined) {
                    let customer = enums[i].customer;
                    for (let j = 0; j < customer.length; j++) {
                        if (customer[j].gender_id !== undefined)
                            arr.genders = convertArrToDropdropdown(customer[j].gender_id)
                        if (customer[j].ID_type_id !== undefined)
                            arr.typeIdentityNumbers = convertArrToDropdropdown(customer[j].ID_type_id)
                    }
                }
                if (enums[i].insider !== undefined) {
                    let insider = enums[i].insider;
                    for (let j = 0; j < insider?.length; j++) {
                        if (insider[j].insider_type_id !== undefined)
                            arr.entitySubType = convertArrToDropdropdown(insider[j].insider_type_id)
                    }
                }
            }
            let defaultStatusValue: any
            (defaultStatusValue = arr?.countryCode.find(({ is_default }: any) => is_default === true))
            let defaultTypeIdValue: any;
            (defaultTypeIdValue = arr?.typeIdentityNumbers.find(({ is_default }: any) => is_default === true))
            setDefaultIdTypeId(defaultTypeIdValue?.key)
            let defaultTypeValue: any
            (defaultTypeValue = arr?.entitySubType.find(({ is_default }: any) => is_default === true))
            setDefaultTypeValue(defaultTypeValue.key)
            if (itemUpdate === undefined) {
                let defaultGenderValue: any
                (defaultGenderValue = arr?.genders.find(({ is_default }: any) => is_default === true))
                let defaultTelValue: any
                (defaultTelValue = arr?.telephoneCountryCode.find(({ is_default }: any) => is_default === true))
                setUpdateInsider({
                    ...updateInsider, addressCountryCode: defaultStatusValue?.key, gender: defaultGenderValue?.key, idTypeId: defaultTypeIdValue?.key,
                    telephoneCountryCode: defaultTelValue?.key, entitySubTypeId: !typesList ? defaultTypeValue?.key : "", idCountryCode: defaultStatusValue?.key
                })
            }
            setenumsDropdown(arr);
        }
    }, [enums])


    useEffect(() => {
        setUserCredentials({
            "idInitiator": authReducer.data.user.id_entity,
            "idClient": authReducer.data.user.id_client,
            "idBranch": authReducer.data.branch.id_entity,
            "idClientData": authReducer.data.client.id_client
        })

    }, [authReducer]);

    useEffect(() => {
        if (!idEntityTransaction) {
            setidEntity(userData?.id_entity)
        }
        else {
            setidEntity(idEntityTransaction)
        }
    }, [userData])



    useEffect(() => {
        if (serverMessageError !== '') {
            if (serverMessageError === "true") {
                setShowDialogFailed(true)
                setMessageError(t("IDNumberAlreadyInSystem"))
            }
            else {
                toggleHideDialog();
                setShowDialog(false);
            }
        }
    }, [serverMessageError])

    useEffect(() => {
        if (setShowDialog) {
            toggleHideDialog()
        }
    }, [setShowDialog, toggleHideDialog])

    useEffect(() => {
        itemUpdate && setUpdateInsider(itemUpdate)
    }, [itemUpdate])

    useEffect(() => {
        let documentList: any = [], activeList: any = []
        if (updateInsider?.documentation && !isEqual(updateInsider?.documentation?.filter((insider: any) => insider?.media_status_id !== "99"), documents)) {
            updateInsider?.documentation?.forEach((item: any) => {
                item?.media_status_id !== "99" && item?.id_media &&
                    activeList.push({ id_media: item?.id_media, media_name: item?.media_name, documentation_type_id: item?.documentation_type_id, media_type_id: "1", type: "חובה", media_status_id: item?.media_status_id, status: item?.media_status_id === "99" ? "מחוק" : "פעיל", date_created: item?.date_created, media_exp_date: item?.media_exp_date, media_details: item?.media_details })

                documentList.push({ id_media: item?.id_media, media_name: item?.media_name, documentation_type_id: item?.documentation_type_id, media_type_id: "1", type: "חובה", media_status_id: item?.media_status_id, status: item?.media_status_id === "99" ? "מחוק" : "פעיל", date_created: item?.date_created, media_exp_date: item?.media_exp_date, media_details: item?.media_details })
            })
            setItems(documentList)
            setActiveDocumentsList(activeList)
        }
        if (updateInsider?.idTypeId) {
            setCheckedCorporation(isCorporationFunc(updateInsider))
        }

    }, [updateInsider])


    useEffect(() => {
        if (searchCustomer && searchCustomer !== 0) {
            serverMessageError !== '' && setServerMessageError('')
            setUpdateInsider({
                gender: searchCustomer?.gender ? searchCustomer?.gender : updateInsider?.gender, genderOther: searchCustomer?.genderOther, entitySubTypeId: (setRelatedFactors || typesList) ? "" : defaultTypeValue, factorTypeName: "", firstName: searchCustomer?.firstName, lastName: searchCustomer?.lastName, classId: searchCustomer?.classId,
                idNumber: searchCustomer?.idNumber, idTypeId: searchCustomer?.idTypeId, idTypeOther: searchCustomer?.idTypeOther, idCountryCode: searchCustomer?.idTypeCountryCode ? searchCustomer?.idTypeCountryCode : updateInsider?.idCountryCode,
                address: searchCustomer?.address, addressName: searchCustomer?.addressName, addressNumber: searchCustomer?.addressNumber, addressCity: searchCustomer?.addressCity, addressCityName: searchCustomer?.addressCityName, addressZipCode: searchCustomer?.addressZipCode, addressCountryCode: searchCustomer?.addressCountryCode ? searchCustomer?.addressCountryCode : updateInsider?.addressCountryCode, telephone: searchCustomer?.telephone,
                telephoneCountryCode: searchCustomer?.telephoneCountryCode ? searchCustomer?.telephoneCountryCode : updateInsider?.telephoneCountryCode, dateBirth: searchCustomer?.dateBirth ? searchCustomer?.dateBirth : updateInsider?.dateBirth, note: searchCustomer?.note, email: searchCustomer?.email, emailTypeId: searchCustomer?.emailTypeId ? searchCustomer?.emailTypeId : 1, telephoneDefault: searchCustomer?.telephoneDefault ? searchCustomer?.telephoneDefault : 1,
                addressDefault: searchCustomer?.addressDefault ? searchCustomer?.addressDefault : 1, emailId: searchCustomer?.emailId, emailDefault: searchCustomer?.emailDefault ? searchCustomer?.emailDefault : 1, addressId: searchCustomer?.addressId, telephoneId: searchCustomer?.telephoneId, telephoneTypeId: searchCustomer?.telephoneTypeId, key: searchCustomer?.idEntity, fullName: searchCustomer?.firstName + " " + searchCustomer?.lastName,
                fullAddress: searchCustomer?.address + " " + searchCustomer?.addressNumber + " " + searchCustomer?.addressCity,
            })
        }
    }, [searchCustomer])


    //update state insider in form
    const changeInsider = (key: string, value: any) => {
        showTextRequiredFiles && setShowTextRequiredFiles(false)
        serverMessageError !== '' && setServerMessageError('')
        if (key === "entitySubTypeId") {
            let subTypeName
            if (setRelatedFactors) {
                setIfCahngedType(true)
                subTypeName = listRelatedType?.find(({ key }: any) => key === value)
            }
            else {
                // setIfCahngedType(true)
                setIfCahnges(true)
                subTypeName = enumsDropdown?.entitySubType.find(({ key }: any) => key === value)
            }
            if (updateInsider?.entitySubTypeId !== "10" && updateInsider?.entitySubTypeId !== "9") {
                setUpdateInsider({ ...updateInsider, [key]: value, "factorTypeName": subTypeName.text, default: false });
            }
            else {
                setUpdateInsider({ ...updateInsider, [key]: value, "factorTypeName": subTypeName.text });
            }
        }
        else {
            let defaultInsider: any = true
            if (key === "default" && value === false && updateInsider?.default === true) {
                defaultInsider = userData?.insiders?.find((item: any) => item?.insider_type_id.toString() === "9" || item?.insider_type_id.toString() === "10")
            }
            if (defaultInsider && defaultInsider?.id_entity !== updateInsider?.key) {
                setIfCahnges(true)
                setUpdateInsider({ ...updateInsider, [key]: value });
            }
            else {
                setMessageError(t("defaultCannotChangedIsOneFactorMassege"))
                setShowDialogFailed(true)

            }
        }
    }

    const check = (currentValue: string) => updateInsider[currentValue]?.length > 0 || updateInsider[currentValue]?.toLocaleString()?.length > 0;
    const save = async () => {
        let requairedFildes: any = ["gender", "entitySubTypeId", "lastName",
            "idNumber", "idTypeId", "dateBirth", "addressCityName", "addressNumber", "addressName"]

        let entity = itemUpdate ? itemUpdate : searchCustomer
        let isEntityTransaction
        idEntityTransaction ? isEntityTransaction = datacConversion?.id_transaction : isEntityTransaction = false
        if (!isEqual(updateInsider, entity)) {
            if ((requairedFildes.every(check) && setRelatedFactors && updateInsider.factorTypeName) ||
                (requairedFildes.every(check) && setRelatedFactors === undefined)) {
                if (resultId && validEmail && isValidDate) {
                    const age = checkAge(updateInsider.dateBirth)
                    if (age >= 16 && !checkedCorporation || checkedCorporation) {
                        if (ifCahnges || searchCustomer || ifCahngedType) {
                            setshowSpiner(true)
                            serverMessageError !== '' && setServerMessageError('')
                            if (setRelatedFactors && update && ifCahngedType) {
                                setIfCahngedType(false)
                                setRelatedFactors({ key: "relatedTypeId", typeId: updateInsider?.entitySubTypeId, id: updateInsider?.key ? updateInsider?.key : updateInsider?.idEntity })
                                toggleHideDialog();
                                setShowDialog(false);
                            }
                            if (isConversions && searchCustomer && !update) {
                                !ifCahnges && setRelatedFactors(updateInsider)
                                ifCahnges && await dispatch(updateInsiderDetails(updateInsider, userCredentials, idEntity, updateInsider.key, setShowDialogUpdate, setShowDialogFailed, setMessageError, setshowSpiner, setRelatedFactors, "", isEntityTransaction))
                                toggleHideDialog();
                                setShowDialog(false);
                            }
                            else {
                                if (update && ifCahnges) {
                                    await dispatch(updateInsiderDetails(updateInsider, userCredentials, idEntity, updateInsider.key, setShowDialogUpdate, setShowDialogFailed, setMessageError, setshowSpiner, setRelatedFactors, "", isEntityTransaction))
                                    toggleHideDialog();
                                    setShowDialog(false);
                                }
                                else {
                                    if (searchCustomer?.idNumber) {
                                        if (ifCahnges) {
                                            await dispatch(updateInsiderDetails(updateInsider, userCredentials, idEntity, updateInsider.key, setShowDialogUpdate, setShowDialogFailed, setMessageError, setshowSpiner, setRelatedFactors, true, isEntityTransaction))
                                        }
                                        else {
                                            await dispatch(attachInsiderToCustomer(updateInsider, userCredentials, idEntity, searchCustomer?.idEntity, setshowSpiner, isEntityTransaction))
                                        }
                                        toggleHideDialog();
                                        setShowDialog(false);
                                    }
                                    else {
                                        !update && await dispatch(createInsider(updateInsider, userCredentials, idEntity, setShowDialogSave, "", setshowSpiner, setRelatedFactors, isConversions, setServerMessageError, isEntityTransaction))
                                    }
                                }
                            }
                            setIfCahnges(false)
                        }
                    }
                    else {
                        alert(t("notPossiblCreateCustomerUnderTheAgeMassege"))
                    }
                }
                else {
                    setShowDialogFailed(true)
                    setMessageError(t('validFields'))
                }
            }
            else {
                setShowTextRequiredFiles(true)
                setRequierdDialog(true)
            }
        }
        else {
            toggleHideDialog();
            setShowDialog(false);
        }
    }

    return (
        <>
            {/*className="doc" */}
            <Dialog hidden={hideDialog} minWidth="xl" maxWidth="xl" modalProps={modelProps} onDismiss={() => { toggleHideDialog(); setShowDialog && setShowDialog(false) }} >
                {requiredDialog && <DialogMessages setansDelete={setRequierdDialog} setshowModalDelete={setRequierdDialog} dialogType={"requiredFields"} />}
                <div >
                    <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{t("addRepresentativeFactor")}</p>
                    <hr className="hr" ></hr>
                    <div style={{ width: (update && !showBeneficiaryFields) ? "77vw" : "28vw", gridTemplateColumns: "1fr 1fr", display: "flex", gridTemplateRows: "auto", gridArea: "main" }}>

                        <div className={`div-factors-${dir}`} style={{ width: (update && !showBeneficiaryFields) ? "35%" : "100%" }}>
                            {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} setshowModalDelete={setShowDialogFailed}
                                subText={messageError} title={t('error')} dialogType={"dialogMessage"} />}
                            {/* //לא להוסיף זאת עושה בעיה עם יצירת גורם קשור עם אותו ת"ז לבדוק */}
                            {/* {serverMessageError && <DialogMessages setansDelete={setServerMessageError} 
                            subText={messageError} title={t('error')} setshowModalDelete={setServerMessageError} dialogType={"dialogMessage"} />} */}
                            {/* {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} subText={messageError} title={t('error')} setshowModalDelete={setShowDialogFailed} dialogType={"dialogMessage"} />}
                            <DialogSearch userCredentials={userCredentials} valueSearch={serverMessageError === "true" ? updateInsider?.idNumber : ""} setshowSpiner={setshowSpiner} isSearchInsiders={true} setRelatedFactors={setSearchCustomer} />
                            */}
                            <div style={{ justifyContent: "space-between", display: "inline-flex", width: "100%" }}>
                                <p className="title-text" >{t("personalDetails")}</p>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <DialogSearch userCredentials={userCredentials} valueSearch={serverMessageError === "true" ? updateInsider?.idNumber : ""} setshowSpiner={setshowSpiner}
                                        isSearchInsiders={true} setRelatedFactors={setSearchCustomer} place={t('SearchInDatabase')} /></div></div>
                            <hr className="hr"></hr>
                            <CustomDropdown tabIndex="1" requiredField={showTextRequiredFiles && !updateInsider?.entitySubTypeId ? true : false} otherInputId={''} otherValue={''} hasOtherValue={true}
                                options={setRelatedFactors ? listRelatedType : typesList ? typesList : enumsDropdown?.entitySubType}
                                label={t("factorType")} onChange={changeInsider} selectedKey={updateInsider?.entitySubTypeId?.toLocaleString()} id={'entitySubTypeId'} othertextInput={t(' ')} />
                            <div className="wrapper-collateral">
                                <div className={`warpper-fields-right-${dir}`} style={{ alignItems: "inherit" }}  >

                                    {!checkedCorporation && <CustomTextField value={updateInsider?.firstName} readOnly={readOnly}
                                        requiredField={showTextRequiredFiles && updateInsider?.firstName === "" ? true : false} required={true}
                                        label={t('firstName')} onChange={changeInsider} id={'firstName'} />}
                                </div>
                                <div className={`warpper-fields-left-${dir}`} >

                                    <CustomTextField onChange={changeInsider} id={'lastName'}
                                        requiredField={showTextRequiredFiles && updateInsider?.lastName === "" ? true : false}
                                        required={true} value={updateInsider?.lastName} readOnly={readOnly}
                                        label={checkedCorporation ? t('corporationName') : t('lastName')} />

                                </div>
                            </div>
                            <div className="wrapper-collateral">
                                <div className={`warpper-fields-right-${dir}`} style={{ alignItems: "inherit" }}  >
                                    <CustomDropdown otherInputId={'genderOther'} otherValue={updateInsider?.genderOther} hasOtherValue={true} options={enumsDropdown.genders} label={t('gander')} readOnly={readOnly} onChange={changeInsider} selectedKey={updateInsider?.gender} id={'gender'} othertextInput={t('other')} />
                                </div>

                                <div className={`warpper-fields-left-${dir}`} > <CustomerDate isDefaultDate={false} isValidDate={isValidDate} setShowTextRequiredFiles={setShowTextRequiredFiles}
                                    showRequiredFildes={showTextRequiredFiles && updateInsider?.dateBirth === "" ? true : false} setIsValidDate={setIsValidDate} entity={updateInsider}
                                    setEntity={setUpdateInsider} readOnly={readOnly} idDate="dateBirth" label={t('dateOfBirth')} source={"pastDate"} setIfCahnges={setIfCahnges} />
                                </div>
                            </div>
                            <div className='wrapper-id' style={{ marginBottom: '20px' }}>
                                <IdNumber entity={updateInsider} setIfCahnges={setIfCahnges} setEntity={setUpdateInsider} showRequiredFildes={showTextRequiredFiles} readOnly={readOnly} setResultId={setResultId} resultId={resultId} typeIdentityNumbers={enumsDropdown.typeIdentityNumbers} typeIdDefault={defaultIdTypeId} />
                            </div>


                            {/* {!showBeneficiaryFields &&  */}
                            <>
                                <p className="sub-title-text space">{t("contactInformation")}</p>
                                <hr className="hr"></hr>
                                <div className="wrapper-collateral " style={{ alignItems: "unset" }}>

                                    <div className={`warpper-fields-right-${dir} marginL-between-container-${dir}`}>
                                        <p className="title-text-2" >{t("address")}</p>
                                        <hr className="hr"></hr>
                                        <Address setEntity={setUpdateInsider}
                                            showRequiredFildes={showTextRequiredFiles}
                                            entity={updateInsider} setIfCahnges={setIfCahnges} />
                                    </div>

                                    <div className={`warpper-fields-left-${dir}`}>
                                        <Phone entity={updateInsider} setEntity={setUpdateInsider} setIfCahnges={setIfCahnges} readOnly={readOnly} />
                                        <Email entity={updateInsider} setEntity={setUpdateInsider} setIfCahnges={setIfCahnges} readOnly={readOnly} setValidEmail={setValidEmail} validEmail={validEmail} />
                                    </div>
                                </div>
                            </>
                            {/* } */}
                        </div>
                        {!showBeneficiaryFields &&
                            update &&
                            <div className={`div-factor-document-${dir}`}>
                                <DocumentsList activeDocumentsList={activeDocumentsList} items={items}
                                    actionType={setRelatedFactors ? SET_INSIDER_DATA_CONVERSION : SET_CUSTOMER_INSIDER} idOtherEntity={updateInsider?.key} setRelatedFactors={setRelatedFactors} />
                            </div>
                        }
                    </div>
                    {(!showBeneficiaryFields && state === "showDefaltInsider") &&
                        <div className="note-field">
                            <p className="title-text-2">{t('note')}</p>
                            <CustomTextField value={updateInsider?.note} onChange={changeInsider} id={'note'} />
                            <div style={{ marginTop: "8px" }}> <CustomToggle onText={t('defaultFactor')} readOnly={(updateInsider?.entitySubTypeId?.toLocaleString() === "9" || updateInsider?.entitySubTypeId?.toLocaleString() === "10") ? false : true} onChange={changeInsider} id={'default'}
                                defaultChecked={updateInsider?.default} checked={updateInsider?.default} offText={t('defaultFactor')} />
                            </div>
                        </div>}

                    <DialogFooter >
                        <DefaultButton onClick={() => { toggleHideDialog(); setShowDialog(false) }} text={t("cancel")} />
                        <PrimaryButton onClick={save} text={update ? t("update") : t("save")} />
                    </DialogFooter>
                </div>
            </Dialog>
        </>
    )
}
export default EditableRelatedFactors
