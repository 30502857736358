import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, PrimaryButton, ContextualMenu, Checkbox } from '@fluentui/react';

import { getBranchesPerBank, searchBankDetails } from '../../../../store/actions/bankActions';
import { IUserCredentials } from '../../../../components/customerDetails/CustomerDetails';
import moment from "moment";
import { uploadCheckFile } from '../../../../store/actions/FilesAction';
import { settingUsersUpdate } from '../../../../store/actions/settingsAction';
import { dataURLtoFile } from '../../CustomHooks/GlobalFunctions';
import { log } from 'console';
import { login } from '../../../services/login.service';

export const ScanCheck = (props: any) => {
    const { bankEnum, setUpdateBankAccount, updateBankAccount, checkDeatils, setArrScanChecksImgs, setIsFinishScan,
        fullCheck, setCheckDeatils, setShowDialog, arrInformationScan, setArrInformationScan, idEntity, setSearchData, isIconScanButton, allScanChecks, setAllScanChecks, setCheckType } = props

    const [t] = useTranslation();
    const dispatch = useDispatch()
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const settings = useSelector(({ authReducer }: any) => authReducer?.data?.user?.setting)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
    const scanILSref = useRef<any>(null);
    const [length, setLength] = useState<any>(1);
    const [currentLine, setCurrentLine] = useState<any>(0);
    const [lastTargetId, setLastTargetId] = useState<any>(-1);
    const [isUSCheck, setISUSCheck] = useState<any>(false);
    const [isILSCheck, setISILSCheck] = useState<any>(false);
    const [defaultScanCheck, setDefaultScanCheck] = useState<any>(false);
    const [kindDfaultScanCheque, setKindDefaultScanCheque] = useState<any>();
    const [connection, setConnection] = useState<any>(null);
    const [newAllScanChecks, setNewAllScanChecks] = useState<any>([]);
    const [branchDetails, setBranchDetails] = useState([])
    const [backCheckImage, setBackCheckImage] = useState<any>({})
    const [faceCheckImage, setFaceCheckImage] = useState<any>({})
    const [backCheckImage1, setBackCheckImage1] = useState<any>({})
    const [faceCheckImage1, setFaceCheckImage1] = useState<any>({})
    const [fullCheckbace, setFullCheckbace] = useState<any>([])
    // const fullCheckbace = [{
    //     imageContent : "",
    //     imageName : "",

    // }]
    // const fullCheckbace = [];
    const newAllScanChecksRef = useRef(newAllScanChecks)

    const fullCheckbaceRef = useRef(fullCheckbace)

    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
    };
    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        dragOptions: dragOptions
    };

    window.addEventListener(
        "message",
        (ev: MessageEvent<{ type: string; message: object }>) => {
            if (ev.data.type === 'current-check') {
                setCheckType('US');
                setAllScanChecks && setAllScanChecks(ev.data.message);
                closeDialog();
            }
        }
    );



    useEffect(() => {
        setUserCredentials({
            "idInitiator": authReducer.data.user.id_entity,
            "idClient": authReducer.data.user.id_client,
            "idBranch": authReducer.data.branch.id_entity,
            "idClientData": authReducer.data.client.id_client
        })
    }, [authReducer]);

    useEffect(() => {
        if (arrInformationScan[0]?.checkNumber?.includes(":")) {
            // const timeoutId = setTimeout(() => finishScanUSCheck(), 3000);
            // return () => clearTimeout(timeoutId);
        }
        if (arrInformationScan.length > 0 && branchDetails.length > 0) {
            if (!fullCheck || fullCheck == undefined) {
                const timeoutId = setTimeout(() => finishScan(), 3000);
                return () => clearTimeout(timeoutId);
            }

        }

    }, [arrInformationScan, branchDetails])

    useEffect(() => {
        const newConnection: any = new HubConnectionBuilder()
            .withUrl('http://localhost:5001/myhub', { withCredentials: true })
            .withAutomaticReconnect()
            .configureLogging(3)
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {

        if (faceCheckImage?.["media_face"] && backCheckImage?.["media_back"]) {

            setCheckDeatils({ ...checkDeatils, media: { ...faceCheckImage, ...backCheckImage } })

            toggleHideDialog(); setShowDialog(false)

        }
    }, [faceCheckImage, backCheckImage])

    // useEffect(() => {
    //     if (connection) {
    //         connection.start()
    //             .then((result: any) => {
    //                 console.log('Connected!');

    //                 connection.on('Error', (message: any) => {
    //                     alert(`error from SignalR service: ${message}`);
    //                 });
    //                 connection.on('Image', (image: any) => {
    //                     alert(`Image from local server: ${image}`);
    //                 });
    //             })
    //             .catch((e:any) => console.log('Connection failed: ', e));
    //     }
    //     // return(()=>{
    //     //     connection.stop()
    //     // })
    // }, [connection]);

    // const sendMessage = async () => {
    //     // const data = { chequeId: 1234 };
    //     if (connection.state === 'Connected') {
    //         try {
    //             // await connection.send('SendMessage', 'D:\טכנאי.png');
    //             await connection.send('SendMessage', 'C:/MTDCC/Photos/c.jpg');

    //         }
    //         catch (e) {
    //             console.log(e);
    //         }
    //     }
    //     else {
    //         alert('No connection to server yet.');
    //     }
    // }


    //////////here
    // useEffect(() => {
    //     if (connection) {
    //         connection.start()
    //             .then(async (result: any) => {
    //                 console.log('Connected!', result);
    //                 connection.on('Error', (message: any) => {
    //                     alert(`error from SignalR service: ${message}`);
    //                 });
    //                 connection.on('Image', async (image: any) => {
    //                     let fileName
    //                     sessionStorage.getItem("authReducer")
    //                     let side = sessionStorage.getItem("sideImg")
    //                     let file = await dataURLtoFile(`data:image/jpg;base64,${image}`, `id_media_${side}.jpg`);
    //                     console.log("checkDeatilshe");

    //                     if (side === "face") {
    //                         fileName = `${updateBankAccount?.accountNumber ? updateBankAccount?.accountNumber : 0}-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0}-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0}`
    //                         sessionStorage.setItem("sideImg", "back")
    //                         await dispatch(uploadCheckFile(idEntity, userCredentials, file, checkDeatils, setCheckDeatils, fileName, "id_media_face"))

    //                     }
    //                     else {
    //                         fileName = `B-${updateBankAccount?.accountNumber ? updateBankAccount?.accountNumber : 0}-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0}-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0}`
    //                         await dispatch(uploadCheckFile(idEntity, userCredentials, file, checkDeatils, setCheckDeatils, fileName, "id_media_back"))

    //                     }
    //                 });
    //             })
    //             .catch((e: any) => console.log('Connection failed: ', e));

    //     }
    //     // return () => {
    //     //     connection?.stop();
    //     // }
    // }, [connection]);

    const sendToGetImage = async (CheckDetails: any, bankDetails: any, side: any) => {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let r = '0';
        let d;
        const removeLast3 = year % 100;
        if (date / 100! >= 1) {
            d = `${r}${date}`;

        }
        else {
            d = date;
        }

        if (connection.state === 'Connected') {

            try {
                // await connection.send('SendMessage', 'C:/MTDCC/Photos/c.jpg');
                // await connection.send('SendMessage', `C:/MTDCC/Photos/0${month}${removeLast3}/${d}/${bankDetails.accountNumber}_${CheckDetails.reference_number}${side}.jpg`);
                // const image = `C:/MTDCC/Photos/0${month}${removeLast3}/${d}/${bankDetails.accountNumber}_${CheckDetails.reference_number}${side}.jpg`;
                await connection.send('SendMessage', `C:/MTDCC/Photos/${bankDetails.accountNumber}_${CheckDetails.reference_number}${side}.jpg`);
                const image = `C:/MTDCC/Photos/0${month}${removeLast3}/${d}/${bankDetails.accountNumber}_${CheckDetails.reference_number}${side}.jpg`;
                console.log(image, ")))))))))))))))))))))))))))))");

            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            alert('No connection to server yet.');
        }
    }

    function closeDialog() {
        toggleHideDialog();
        setShowDialog(false)
    }
    // async function finishScanUSCheck() {
    //     const arrScanChecks = JSON.parse(sessionStorage.getItem("arrScanChecks") || "[]");
    //     const micrInfoParsed = arrScanChecks[0].micr;
    //     let copyUpdateBankAccount = {
    //         country: "US",
    //         accountNumber: micrInfoParsed.accountNumber,
    //         routingNumber: micrInfoParsed.routingNumber
    //     }
    //     let copyCheckDetails: any = checkDeatils
    //     copyCheckDetails["reference_number"] = micrInfoParsed.checkNumber
    //     copyCheckDetails["date_created"] = moment().format("DD/MM/YY");
    //     setCheckDeatils(copyCheckDetails)
    //     let fileF = dataURLtoFile(`data:image/jpeg;base64,${arrScanChecks[0].imageFront}`, "id_media_face.jpeg");
    //     let fileNameF = `${updateBankAccount?.accountNumber ? updateBankAccount?.accountNumber : 0}-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0}-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0}`
    //     await dispatch(searchBankDetails(copyUpdateBankAccount, userCredentials, setSearchData, ''))
    //     await dispatch(uploadCheckFile(idEntity, userCredentials, fileF, faceCheckImage, setFaceCheckImage, fileNameF, "id_media_face"))
    //     let fileB = dataURLtoFile(`data:image/jpeg;base64,${arrScanChecks[0].imageBack}`, "id_media_back.jpeg");
    //     let fileNameB = `B-${updateBankAccount?.accountNumber ? updateBankAccount?.accountNumber : 0}-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0}-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0}`
    //     dispatch(uploadCheckFile(idEntity, userCredentials, fileB, backCheckImage, setBackCheckImage, fileNameB, "id_media_back"))
    //     // sessionStorage.setItem("arrScanChecks", "[]");
    // }

    const finishScan = async () => {
        if (arrInformationScan.length > 1) {
            const fullarry = [{}];
            console.log("arrInfo", arrInformationScan)
            let tempNewAllScanChecks = [...newAllScanChecks]
            for (let i = 0; i < arrInformationScan.length; i++) {
                if (arrInformationScan[i]["branchNumber"] != "") {
                    let branchAddress, branchName
                    branchDetails?.forEach(e => {
                        if (e["enum_id"] === arrInformationScan[i]["branchNumber"]) {
                            branchName = e["enum_value"]
                            branchAddress = e["branch_address"]
                        }
                    })
                    let index = bankEnum.findIndex((x: any) => x.key === arrInformationScan[i]["bankNumber"]);
                    let copyUpdateBankAccount = {
                        key: '', country: updateBankAccount?.country,
                        accountNumber: arrInformationScan[i]["accountNumber"],
                        bank: arrInformationScan[i]["bankNumber"],
                        branch: arrInformationScan[i]["branchNumber"],
                        bankName: bankEnum[index].text,
                        branchAddress: branchAddress,
                        branchName: branchName
                    }
                    let copyCheckDetails: any = { ...checkDeatils }
                    copyCheckDetails["reference_number"] = arrInformationScan[i]["checkNumber"]
                    copyCheckDetails["date_created"] = moment().format("DD/MM/YY");
                    setUpdateBankAccount(copyUpdateBankAccount)
                    sessionStorage.setItem("sideImg", "face")
                    // await sendToGetImage(copyCheckDetails, copyUpdateBankAccount, "F")
                    // await sendToGetImage(copyCheckDetails, copyUpdateBankAccount, "B")
                    await sendToGetImage(copyCheckDetails, copyUpdateBankAccount, "f")
                    await sendToGetImage(copyCheckDetails, copyUpdateBankAccount, "b")
                    console.log(copyCheckDetails, "copyCheckDetail435678s");
                    console.log("copyCheckDetails", copyCheckDetails);
                    const newObject: any[] = copyCheckDetails;
                    console.log("New object", newObject)
                    tempNewAllScanChecks = [...tempNewAllScanChecks, newObject]
                    console.log("tmpeNewAll", tempNewAllScanChecks)
                    setCheckType('IL');
                    setNewAllScanChecks(tempNewAllScanChecks);
                    newAllScanChecksRef.current = tempNewAllScanChecks;
                }
            }
            setShowDialog(false)

        }
        else {
            let branchAddress, branchName

            branchDetails?.forEach(e => {
                if (e["enum_id"] === arrInformationScan[0]["branchNumber"]) {
                    branchName = e["enum_value"]
                    branchAddress = e["branch_address"]
                }
            })

            let index = bankEnum.findIndex((x: any) => x.key === arrInformationScan[0]["bankNumber"]);

            let copyUpdateBankAccount = {
                key: '', country: updateBankAccount?.country,
                accountNumber: arrInformationScan[0]["accountNumber"],
                bank: arrInformationScan[0]["bankNumber"],
                branch: arrInformationScan[0]["branchNumber"],
                bankName: bankEnum[index].text,
                branchAddress: branchAddress,
                branchName: branchName
            }
            let copyCheckDetails: any = checkDeatils
            copyCheckDetails["reference_number"] = arrInformationScan[0]["checkNumber"]
            copyCheckDetails["date_created"] = moment().format("DD/MM/YY");
            setUpdateBankAccount(copyUpdateBankAccount)
            debugger;
            sessionStorage.setItem("sideImg", "face")
            // await sendToGetImage(copyCheckDetails, copyUpdateBankAccount, "F")
            // await sendToGetImage(copyCheckDetails, copyUpdateBankAccount, "B")
            await sendToGetImage(copyCheckDetails, copyUpdateBankAccount, "f")
            await sendToGetImage(copyCheckDetails, copyUpdateBankAccount, "b")
            setCheckType('IL');
            setCheckDeatils(copyCheckDetails)
            setShowDialog(false)
        }
        toggleHideDialog();
    }

    const setScanInfo = async (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        // console.log(newValue,"newValue");

        let arrScanInfo: any = arrInformationScan
        let target: any = event.target
        let index = Number(target.name)
        if (target.id === "accountNumber" && arrScanInfo[index].accountNumber === "" && fullCheck === true) {
            setLength(length + 1)
            // arrScanInfo.push({ checknumber: "", bankNumber: "", branchNumber: "", accountNumber: "" })


            // setTimeout(() => {
            //     setCurrentLine((prevValue:number)=>
            //     {
            //      console.log(`prev:${prevValue}`)   
            //       return  prevValue+1;

            //     });
            //     console.log('currentline:'+length)
            // }, 1000);



        }
        // console.log(target.value,"target.value");

        if (target.id === "bankNumber")
            await dispatch(getBranchesPerBank(newValue, userCredentials, setBranchDetails))
        if (target.id?.includes("checkNumber")) {

            arrScanInfo[index]["checkNumber"] = newValue
        }
        else
            arrScanInfo[index][target.id] = newValue
        // console.log(arrScanInfo);

        if (target.id === "accountNumber" && arrInformationScan[index].accountNumber.length == 5
            && index != lastTargetId && fullCheck === true) {
            setTimeout(() => {


                arrScanInfo.push({ checknumber: "", bankNumber: "", branchNumber: "", accountNumber: "" })
                setCurrentLine(arrScanInfo.length - 1);
                setLastTargetId(index);
            }, 1000);
        }

        setArrInformationScan(arrScanInfo)

    }

    const setChequeScanningPopup = async (kindDefaultScanCheque: any) => {
        let copySettings: any = { ...settings }
        if (kindDefaultScanCheque && defaultScanCheck) {
            copySettings.cheque_scanning_popup = kindDefaultScanCheque
            await dispatch(settingUsersUpdate(copySettings, userCredentials, idEntity))
        }
    }



    // useEffect(() => {
    //     const newConnection: any = new HubConnectionBuilder()
    //         .withUrl('http://localhost:5001/myhub', { withCredentials: true })
    //         .withAutomaticReconnect()
    //         .configureLogging(0)
    //         .build();

    //     setConnection(newConnection);
    // }, []);

    useEffect(() => {
        console.log("test - newAllSacns", newAllScanChecks)
        const updateImagesDataToAllChecks = () => {
            // TO DO: check double scaning case

            console.log(fullCheckbaceRef.current, "test - fullCheckbaceRef----");
            console.log(newAllScanChecksRef.current, "test - newAllScanChecksRef----");

            if (newAllScanChecksRef.current.length > 0 && fullCheckbaceRef.current.length > 0) {
                console.log("test - newAllScanChecksRef.current, before", newAllScanChecksRef.current)
                console.log(fullCheckbaceRef.current, 'test - fullCheckbaceRef.current---------');
                for (let index = 0; index < newAllScanChecksRef.current.length; index++) {
                    for (let y = 1; y < fullCheckbaceRef.current.length; y++) {
                        console.log(fullCheckbaceRef.current[y].imageName, ", fullCheckbaceRef.current[y]");
                        const name = fullCheckbaceRef.current[y].imageName?.includes(newAllScanChecksRef.current[index].reference_number);
                        console.log(name, "test - name");

                        if (name) {
                            const feac = fullCheckbaceRef.current[y].imageName.includes(('f'));
                            const Feac = fullCheckbaceRef.current[y].imageName.includes(('F'));
                            if (feac || Feac) {
                                newAllScanChecksRef.current[index].media.media_face = fullCheckbaceRef.current[y].imageContent;
                            }
                            else {
                                newAllScanChecksRef.current[index].media.media_back = fullCheckbaceRef.current[y].imageContent;
                            }

                        }

                    }

                }

                console.log("test - newAllScanChecksRef.current, after", newAllScanChecksRef.current)

                // for (let i = 0; i < newAllScanChecks; i++) {

                //     newAllScanChecks[i].media.faceCheckImage = fullCheckface[i];
                //     newAllScanChecks[i].media.backCheckImage = fullCheckbace[i];

                // }
                if (fullCheckbaceRef.current.length == (newAllScanChecksRef.current.length) * 2) {
                    setAllScanChecks(newAllScanChecksRef.current);

                }

            }
        }

        const socketInit = async () => {

            try {
                if (connection) {
                    await connection.start()
                    connection.on('Error', (message: any) => {
                        alert(`error from SignalR service: ${message}`);
                    });
                    connection.on('Image', async (image: any) => {
                        console.log("test - newAllScanChecks in useEffect", image)

                        // const newObj = { ...checkDeatils, media :{ faceCheckImage :image, backCheckImage:image }};
                        // setCheckDeatils(newObj)
                        // let fileName
                        // sessionStorage.getItem("authReducer")
                        // let side = sessionStorage.getItem("sideImg")
                        // let file = await dataURLtoFile(`data:image/jpg;base64,${image}`, `id_media_${side}.jpg`);
                        // console.log("checkDeatilshe",side);

                        // if (side === "face") {
                        //     console.log("345678");
                        //     fileName = `${updateBankAccount?.accountNumber ? updateBankAccount?.accountNumber : 0}-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0}-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0}`
                        //     sessionStorage.setItem("sideImg", "back")
                        //     await dispatch(uploadCheckFile(idEntity, userCredentials, file, checkDeatils, setCheckDeatils, fileName, "id_media_face"))

                        // }
                        // else {
                        //     fileName = `B-${updateBankAccount?.accountNumber ? updateBankAccount?.accountNumber : 0}-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0}-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0}`
                        //     await dispatch(uploadCheckFile(idEntity, userCredentials, file, checkDeatils, setCheckDeatils, fileName, "id_media_back"))
                        // }
                        debugger;

                        if (newAllScanChecksRef.current.length) {
                            const newData = image;
                            fullCheckbaceRef.current = [...fullCheckbaceRef.current, newData]
                            setFullCheckbace([...fullCheckbaceRef.current, newData]) // in order to rerender
                            console.log("test - fullCheckbaceRef.current", fullCheckbaceRef.current)

                            //if (newAllScanChecksRef.current.length > )
                            updateImagesDataToAllChecks()
                        }
                        else {
                            let fileName
                            sessionStorage.getItem("authReducer")
                            console.log(allScanChecks, "allScanChecks");


                            let side = sessionStorage.getItem("sideImg")
                            let file = await dataURLtoFile(`data:image/jpg;base64,${image.imageContent}`, `id_media_${side}.jpg`);
                            console.log(file, "file");
                            if (side === "face") {
                                fileName = `${updateBankAccount?.accountNumber ? updateBankAccount?.accountNumber : 0}-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0}-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0}`
                                sessionStorage.setItem("sideImg", "back")
                                await dispatch(uploadCheckFile(idEntity, userCredentials, file, checkDeatils, setCheckDeatils, fileName, "id_media_face"))

                            }
                            else {
                                fileName = `B-${updateBankAccount?.accountNumber ? updateBankAccount?.accountNumber : 0}-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0}-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0}`
                                await dispatch(uploadCheckFile(idEntity, userCredentials, file, checkDeatils, setCheckDeatils, fileName, "id_media_back"))


                            }
                            checkDeatils["media"]["media_face"] = Image;
                            checkDeatils["media"]["media_back"] = Image;
                        }

                    }

                    );

                }
            } catch (e) {
                console.log("UseEffect socket connection, e: ", e)
            }

        }


        socketInit()

    }, [connection, newAllScanChecks, fullCheckbace]
    );
    const sendMessage = async () => {
        if (connection.state == 'Connected') {

            try {
                // 'D:\טכנאי.png'
                await connection.send('SendMessage', 'C:/MTDCC/Photos/c.jpg');
            }
            catch (e) {
            }
        }
        else {
            alert('No connection to server yet.');
        }
    }

    const resetButtons = (setButton: any) => {
        let kindDefaultScanCheque

        if (setButton === "ILSCheck") {
            setISILSCheck(true)
            kindDefaultScanCheque = "scan_israeli_cheques"
        }
        else
            setISILSCheck(false)

        if (setButton === "USCheck") {
            setISUSCheck(true)
            kindDefaultScanCheque = "scan_us_cheques"
        }
        else setISUSCheck(false)

        if (setButton === "enterDetailsManually") {
            kindDefaultScanCheque = "enter_details_manually"
            toggleHideDialog();
            setShowDialog(false)
        }
        setKindDefaultScanCheque(kindDefaultScanCheque)
        setChequeScanningPopup(kindDefaultScanCheque)
    }

    const handleDismiss = () => {
        debugger
        if ((isUSCheck || settings?.cheque_scanning_popup === "scan_us_cheques")) {
            const iframe: any = document.querySelector("iframe");
            setCheckType('US');
            iframe?.contentWindow.postMessage({ type: 'close', message: '*' });
        }
        else {

            closeDialog();
        }
    }


    return (

        <Dialog minWidth={`${isUSCheck || isILSCheck ? "xl" : ""}`} maxWidth={`${isUSCheck || isILSCheck ? "xl" : ""}`} hidden={hideDialog} modalProps={modelProps}
            onDismiss={handleDismiss} >
            <div style={{
                width: (isUSCheck || isILSCheck || settings?.cheque_scanning_popup === "scan_us_cheques"
                    || settings?.cheque_scanning_popup === "scan_israeli_cheques")
                    ? "850px" : "", height: (isUSCheck || settings?.cheque_scanning_popup === "scan_us_cheques") ? "650px" : "", marginTop: "15px", display: "grid"
            }}>
                {(((settings?.cheque_scanning_popup === "show_popup" || !settings?.cheque_scanning_popup) && !kindDfaultScanCheque)
                    || (settings?.cheque_scanning_popup === "enter_details_manually" && !isIconScanButton && !isILSCheck && !isUSCheck)) &&
                    <><PrimaryButton style={{ marginBottom: "15px" }} onClick={() => { resetButtons("ILSCheck") }} text={t("ILSCheck")} />
                        <PrimaryButton style={{ marginBottom: "15px" }} onClick={() => { resetButtons("USCheck") }} text={t("USCheck")} />
                        <PrimaryButton onClick={() => { resetButtons("enterDetailsManually") }} text={t("enterDetailsManually")} />
                        <div className={`checkbox-previous-report-${dir}`} style={{ marginTop: "10px" }}>
                            <Checkbox label={t("בחר לברירת מחדל")} checked={defaultScanCheck} onChange={() => { setDefaultScanCheck(!defaultScanCheck) }} />
                        </div>
                    </>}

                {(isILSCheck || settings?.cheque_scanning_popup === "scan_israeli_cheques") &&
                    <>
                        {/* sendMessage */}
                        {/* <button onClick={() => { sendToGetImage("", "", "") }}>Images</button> */}
                        <div>
                            <button onClick={sendMessage}>Get Images from Local Computer</button>
                        </div>
                        <p className="title-text" style={{ fontSize: "25px", fontWeight: "bold" }}>{t('startScanning')}</p>
                        <hr className="hr"></hr>
                        {
                            fullCheck ?
                                <PrimaryButton onClick={finishScan}>סיום סריקה </PrimaryButton>
                                : <div></div>
                        }
                        {(checkDeatils.id_media_face && checkDeatils.id_media_back)
                            || (checkDeatils?.media?.media_face?.id_media && checkDeatils?.media?.media_back?.id_media) && <p style={{ color: "red", textAlign: "center" }}>{t('noteAnotherScanUpdateImage')}</p>}

                        {
                            arrInformationScan?.map((item: any, i: number) => (

                                <div key={i} style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}>
                                    {/* <textarea autoFocus={i === 0 && true} 
                            style={{border:"none"}}
                            onChange={
                            }{}
                            
                            name={`${i}`} id={"checkNumber"} 
                            cols={30} rows={10}></textarea> */}
                                    {/* onBlur={() => {scanILSref.current.focus()}} */}

                                    <TextField componentRef={scanILSref} autoFocus={i === currentLine} onChange={setScanInfo} resizable={false} borderless={false} name={`${i}`} id={"checkNumber"} />
                                    <TextField componentRef={scanILSref} onChange={setScanInfo} resizable={false} borderless={true} name={`${i}`} id="bankNumber" />
                                    <TextField onChange={setScanInfo} resizable={false} borderless={true} name={`${i}`} id="branchNumber" />
                                    <TextField onChange={setScanInfo} resizable={false} borderless={true} name={`${i}`} id="accountNumber" />
                                </div>
                            ))}
                    </>}

                {(isUSCheck || settings?.cheque_scanning_popup === "scan_us_cheques") &&
                    <iframe width="100%" height="100%" src={`${process.env.PUBLIC_URL}/scan.html`} />
                }
            </div>
        </Dialog >


    );
};