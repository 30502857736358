import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '@fluentui/react';
import ImageGallery from 'react-image-gallery';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { deteleFile, uploadCheckFile } from '../../store/actions/FilesAction';
import { useDispatch, useSelector } from 'react-redux';


export default function CheckCarousel(props: any) {

    const { arrScanChecksImgs, setArrScanChecksImgs, arrChecksPdfImgs, idEntity, updateBankAccount,
        userCredentials, setCheckDeatils, checkDeatils, showRequiredField, isFinishScan, setIsFinishScan } = props
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [arrChecksImgs, setArrChecksImgs] = useState<any>([])
    const dispatch = useDispatch()
    const { state } = useLocation<any>()
    const [showSpiner, setShowSpiner] = useState(true)
    const [/*numPages*/, setNumPages] = useState(null);
    const [pageNumber, /*setPageNumber*/] = useState(1);

    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
    }

    const spinnerStyles: Partial<ISpinnerStyles> = {
        root: {
            position: 'fixed',
            zIndex: '999999',
            fontWeight: 'bold',
        },
        circle: {
            color: '#FFB400 ',
            height: '100px',
            width: '100px',
        },
    }

    const setCheckImages = async (t: any) => {
        debugger;
        console.log(t,"23456789");
        
        let img = t.files[0];
        let urls = URL.createObjectURL(img)
        let arr: any = [...arrChecksImgs]
        arr.push({ original: urls })
        setArrChecksImgs(arr);
        let source = t.id === "file_f" ? "id_media_face" : t.id === "file_b" ? "id_media_back" : ""
        let source2 = t.id === "file_f" ? "media_face" : t.id === "file_b" ? "media_back" : ""
        if (checkDeatils?.media?.[source2]?.id_media) {
            await dispatch(deteleFile(idEntity, checkDeatils?.media?.[source2]?.id_media, userCredentials))
        }
        let fileName = source === "id_media_face" ? `${updateBankAccount?.accountNumber ? updateBankAccount?.accountNumber : 0}-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0}-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0}` :
            `${updateBankAccount?.accountNumber ? updateBankAccount?.accountNumber : 0}-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0}-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0}-B`
            await dispatch(uploadCheckFile(idEntity, userCredentials, img, checkDeatils, setCheckDeatils, fileName, source))
    }

    const setCheckScanImages = async (t: any) => {
        let arrImg: any = []
        let img1 = t?.files[0];
        let img2 = t?.files[1];
        arrImg.push(img1)
        arrImg.push(img2)
        console.log(t?.files,"t?.files");
        console.log(arrImg,"arrImg");
        
        arrImg.map(async (img: any) => {
            let arr: any = [...arrChecksImgs]
            arr.push({ original: img })
            setArrChecksImgs(arr);
            let source = img?.name?.includes("_f") ? "id_media_face" : img?.name?.includes("_b") ? "id_media_back" : ""
            let source2 = t.id === "file_f" ? "media_face" : t.id === "file_b" ? "media_back" : ""
            if (checkDeatils?.media?.[source2]?.id_media) {
                await dispatch(deteleFile(idEntity, checkDeatils?.media?.[source2]?.id_media, userCredentials))
            }
            let fileName = source === "id_media_face" ? `${updateBankAccount?.accountNumber ? updateBankAccount?.accountNumber : 0}-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0}-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0}` :
                `B-${updateBankAccount?.accountNumber ? updateBankAccount?.accountNumber : 0}-${updateBankAccount?.branch ? updateBankAccount?.branch : 0}-${updateBankAccount?.bank ? updateBankAccount?.bank : 0}-${checkDeatils?.reference_number ? checkDeatils?.reference_number : 0}`
            await dispatch(uploadCheckFile(idEntity, userCredentials, img, checkDeatils, setCheckDeatils, fileName, source))
        })

    }

    const clickInputFileF = () => {
        let input = document.getElementById('file_f');
        if (input !== null) {
            input.click()
        }
    }
    const clickInputFileB = () => {
        let input = document.getElementById('file_b');
        if (input !== null) {
            input.click()
        }
    }
    const Checkit = (e: any) => {
        setShowSpiner(false)
    }
    const changeSrc = async (e: any) => {
        let copyArrScanChecksImgs = []
        console.log(checkDeatils,"checkDeatils");
        console.log(checkDeatils?.media.media_back.id_media,"checkDeatils?.media.media_back.id_media");
        
        checkDeatils?.media.media_back.id_media && copyArrScanChecksImgs.push({ original: `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.media.media_back.id_media}&id_entity=${idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}` })
        checkDeatils?.media.media_face.id_media && copyArrScanChecksImgs.push({ original: `https://app.insys.co.il/v1/api/media/download?id_media=${checkDeatils?.media.media_face.id_media}&id_entity=${idEntity}&id_client=${userCredentials?.idClient}&id_initiator=${userCredentials?.idInitiator}` })
        setArrScanChecksImgs(copyArrScanChecksImgs)
        console.log(copyArrScanChecksImgs,"ArrScanChecksImgs");
        
    }

    useEffect(() => {
        if (checkDeatils.media_face)
            setCheckDeatils({
                ...checkDeatils,
                media: { ...checkDeatils.media, media_face: checkDeatils.media_face }
            })
        if (checkDeatils.media_back)
            setCheckDeatils({
                ...checkDeatils,
                media: { ...checkDeatils.media, media_back: checkDeatils.media_back }
            })
    }, [checkDeatils.media_face, checkDeatils.media_back]);
    console.log(arrScanChecksImgs,"arrScanChecksImgsarrScanChecksImgs");
    
    return (
        <>
            <div className="wrapper-collateral" style={{ justifyContent: "center", alignItems: "center", display: "flex", marginTop: "8vh" }}>
                {showSpiner && arrScanChecksImgs?.length > 0 && <Spinner styles={spinnerStyles} />}
            </div>
            <div >
                {(arrScanChecksImgs?.length > 0 && arrScanChecksImgs[0]?.original) &&
                    <div style={{ height: "250px" }}>
                        {/* <img width="200px" height={"200px"} src="https://insys.co.il/api/apimedia/download?id_media=996875FB-30B8-ED11-ADED-005056AA2213&id_entity=9A6D277F-1B4B-EC11-8552-18473D5CD3D0&id_client=615500A1-6F43-487F-93A6-68F16C5340C8&id_initiator=F1086F27-C281-4DA4-B8E5-66B30910A887"></img> 
                        <img width="200px" height={"200px"} src="https://insys.co.il/api/apimedia/download?id_media=D4509C9E-2EB8-ED11-ADED-005056AA2213&id_entity=9A6D277F-1B4B-EC11-8552-18473D5CD3D0&id_client=615500A1-6F43-487F-93A6-68F16C5340C8&id_initiator=F1086F27-C281-4DA4-B8E5-66B30910A887"></img> */}

                        <ImageGallery onImageError={(e: any) => { changeSrc(e) ;console.log(e,"ee");
                         }} onImageLoad={(e: any) => { Checkit(e); console.log(e,"eeee");
                         }} items={arrScanChecksImgs} showPlayButton={false} useBrowserFullscreen={false} />
                        {/* */}
                    </div>}
                <div>
                    {arrChecksPdfImgs[0] && <div >{arrChecksPdfImgs[0] && <Document file={arrChecksPdfImgs[0]} onLoadSuccess={onDocumentLoadSuccess} >
                        <Page pageNumber={pageNumber} height={250} />
                    </Document>}</div>}
                    {arrChecksPdfImgs[1] && <div >{arrChecksPdfImgs[1] && <Document file={arrChecksPdfImgs[1]} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} height={250} />
                    </Document>}</div>}
                </div>
            </div>

            <div className="wrapper-collateral" style={{ justifyContent: "center", alignItems: "center", display: "flex", marginTop: "5px" }}>
                {state !== "showCompletedManagment" && <PrimaryButton
                
                    onClick={() => { clickInputFileF() }}>{checkDeatils.media?.media_face?.id_media ? t("updateChequeInnerPhoto") : t("attachChequeInnerPhoto")}</PrimaryButton>}
                <input id="file_f" type="file" style={{ display: "none" }}
                    accept=".pdf,.png,.jpg,.jpeg" onChange={(e) => setCheckImages(e.target)}></input>
                {state !== "showCompletedManagment" && <PrimaryButton className={`bottun-${dir}`}

                    onClick={() => { clickInputFileB() }}> {checkDeatils.media?.media_back?.id_media ? t("updateChequeBackPhoto") : t("attachChequeBackPhoto")}</PrimaryButton>} 
                <input id="file_b" accept=".pdf,.png,.jpg,.jpeg" style={{ display: "none" }} type="file"
                    onChange={(e) => setCheckImages(e.target)}></input>

                <input style={{ display: "none" }} id="scan_file" multiple={true}
                    onChange={(e) => { setCheckScanImages(e.target) ; console.log(e.target);
                     }}
                    accept=".pdf,.png,.jpg,.jpeg" type="file"
                ></input>
            </div>
        </>);
}