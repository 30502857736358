import React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useBoolean } from '@fluentui/react-hooks';
import { useTranslation } from "react-i18next";

const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
};
const modalPropsStyles = { main: { maxWidth: 450 } };
const dialogSaveConversionData = {
    type: DialogType.normal,
    title: 'שמירה',
    subText: 'האם ברצונך לשמור שינויים?',
};

const dialogSaveConversionDataFailed = {
    type: DialogType.normal,
    title: '',
    subText: "השמירה נכשלה, האם ברצונך להשאר בעמוד או להמשיך ?"
};
const dialogContentPropsDelete = {
    type: DialogType.normal,
    title: 'מחיקה',
    // t("deletion")
    subText: 'האם אתה בטוח שברצונך למחוק?',
};
const dialogContentPropsFailed = {
    type: DialogType.normal,
    title: 'שגיאה',
    subText: 'נסה שנית, ישנה שגיאה באחד השדות',
};
const dialogNotFoundHolderAccount = {
    type: DialogType.normal,
    title: '',
    subText: 'מושך השיק אינו קיים במערכת – נדרש להזין את פרטי המושך',
};
const dialogContentPropsCreated = {
    type: DialogType.normal,
    title: 'שמירה',
    subText: 'הפעולה בוצעה בהצלחה:)',
};
const dialogContentPropsUpdate = {
    type: DialogType.normal,
    title: 'עדכון',
    subText: 'העדכון בוצע בהצלחה',
};
const dialogContentPropsSave = {
    type: DialogType.normal,
    title: 'שמירה',
    subText: 'נשמר בהצלחה',
};
const dialogContentPropsLogin = {
    type: DialogType.normal,
    title: '',
    subText: 'כניסתך בוצעה בהצלחה',
};
const dialogContentPropsLoginNot = {
    type: DialogType.normal,
    title: 'שגיאה',
    subText: 'שגיאה בעת הזנת הנתונים, נסה שנית',
};
const dialogContentPropsFinishDealFail = {
    type: DialogType.normal,
    title: 'הערה',
    subText: 'לא ניתן לסיים את העסקה, עליך לבצע פעולות מאוזנות ו/או למלאות את כל שדות החובה',
};
const dialogContentPropsRequiredFields = {
    type: DialogType.normal,
    title: 'הערה',
    subText: 'עליך להזין את כל שדות החובה',
};


export const DialogMessages = (props: any) => {
    const { subText, title, setansDelete, setshowModalDelete, dialogType, setifDelete,
        setshowHolderAccount, setSaveDataConversion, setContinueAfterFailed, confirm } = props
    const [t, i18n] = useTranslation();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(true);


    const modalProps = React.useMemo(

        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: isDraggable ? dragOptions : undefined,
        }),
        [isDraggable],
    );

    const check = () => {
        if (dialogType !== undefined) {
            if (dialogType === "delete")
                return dialogContentPropsDelete
            if (dialogType === "create") {
                return dialogContentPropsCreated
            }
            if (dialogType === "save")
                return dialogContentPropsSave
            if (dialogType === "update")
                return dialogContentPropsUpdate
            if (dialogType === "login")
                return dialogContentPropsLogin
            if (dialogType === "notLogin")
                return dialogContentPropsLoginNot
            if (dialogType === "holderAccount")
                return dialogNotFoundHolderAccount
            if (dialogType === "saveConversionData")
                return dialogSaveConversionData
            if (dialogType === "saveConversionDataFailed")
                return dialogSaveConversionDataFailed
            if (dialogType === "failed")
                return dialogContentPropsFailed
            if (dialogType === "FinishDealFail")
                return dialogContentPropsFinishDealFail
            if (dialogType === "requiredFields")
                return dialogContentPropsRequiredFields
            if (dialogType === "dialogMessage") {
                const error = {
                    type: DialogType.normal,
                    title: t(title),
                    subText: subText,
                }
                return error
            }
        }
        return
    }
    return (
        <>
            <Dialog

                hidden={hideDialog}
                onDismiss={() => { toggleHideDialog(); setshowModalDelete(false) }}
                dialogContentProps={check()}
                modalProps={modalProps}
            >

                {
                    dialogType === "saveConversionData" ?
                        <DialogFooter>
                            <DefaultButton onClick={() => { toggleHideDialog(); setansDelete(false); setshowModalDelete(false); setSaveDataConversion !== undefined && setSaveDataConversion("don't save") }} text={t("לא לשמור")} />
                            <PrimaryButton onClick={() => { toggleHideDialog(); setansDelete(true); setshowModalDelete(false); setSaveDataConversion !== undefined && setSaveDataConversion("save"); setifDelete !== undefined && setifDelete(true); setshowHolderAccount !== undefined && setshowHolderAccount(true) }} text={t("שמירה")} />
                            <DefaultButton onClick={() => { toggleHideDialog(); setansDelete(false) }} text="ביטול" />
                        </DialogFooter>
                        :
                        dialogType === "saveConversionDataFailed" ?
                            <DialogFooter>
                                <PrimaryButton onClick={() => { toggleHideDialog(); setansDelete(true); setshowModalDelete(false); setContinueAfterFailed && setContinueAfterFailed(true) }} text="להמשיך" />
                                <DefaultButton onClick={() => { toggleHideDialog(); setansDelete(false); setshowModalDelete(false); setContinueAfterFailed && setContinueAfterFailed(false) }} text="להישאר" />
                            </DialogFooter>
                            : confirm === true ?
                                <DialogFooter>
                                    <PrimaryButton onClick={() => { toggleHideDialog(); setansDelete(true); setshowModalDelete(false); setSaveDataConversion !== undefined && setSaveDataConversion("save"); setifDelete !== undefined && setifDelete(true); setshowHolderAccount !== undefined && setshowHolderAccount(true) }} text={t("confirm")} />
                                </DialogFooter> :
                                <DialogFooter>
                                    <DefaultButton onClick={() => { toggleHideDialog(); setansDelete(false); setshowModalDelete(false); setSaveDataConversion !== undefined && setSaveDataConversion("don't save") }} text={t("cancel")} />
                                    <PrimaryButton onClick={() => { toggleHideDialog(); setansDelete(true); setshowModalDelete(false); setSaveDataConversion !== undefined && setSaveDataConversion("save"); setifDelete !== undefined && setifDelete(true); setshowHolderAccount !== undefined && setshowHolderAccount(true) }} text={t("confirm")} />
                                </DialogFooter>
                }
            </Dialog>
        </>
    );
};