import moment from 'moment';
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

export default function ExportSubjectCorporation(props: any) {
    const { entity } = props

    const [data, setData]: any = useState([]);
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)

    useEffect(() => {

        let dataObj: any
        if (entity) {
            let addressObj: any = entity["address"]
            let address = addressObj?.address_street + " " + addressObj?.address_number + " " + addressObj?.address_city + " " + addressObj?.address_zip_code
            dataObj = [{ title: "שם מלא של תאגיד", value: entity?.entity_name },
            { title: "מספר רישום תאגיד", value: entity?.ID_number },
            { title: "תאריך רישום תאגיד", value: moment(entity?.identification?.date_modified).format("MM/DD/YYYY") },
            { title: "מדינת הרישום", value: entity?.identification?.ID_country_code_name },
            {
                title: "תיאור תחום עסקים", value: entity?.industry_classification?.industry_classification_name + ", " +
                    entity?.industry_classification?.industry_sub_classification_name
            },
            //לשים כאן את הכתובת לפי הסדר שמופיע בקובץ
            { title: "מען נשוא הידיעה", value: address },
            ]
            setData(dataObj)
        }
    }, [entity])
    return (
        <>{data?.map((item: any, index: any) => (
            <tr key={index}>
                <td style={{ borderBottom: "1px solid black", borderTop: `${index === 0 ? " 1px solid black" : ""}`, borderRight: "1px solid black", borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px", width: "50%", ...(index === data.length - 1 && { borderBottom: '0px !important' }) }}
                    className={`td-reporting-details padding-input-${dir}`}>{item?.title}</td>
                <td style={{ borderBottom: " 1px solid black", borderTop: `${index === 0 ? " 1px solid black" : ""}`, borderLeft: "1px solid black", textAlign: "start", paddingRight: "8px", ...(index === data.length - 1 && { borderBottom: '0px !important' }) }}
                    className={`td-reporting-details padding-input-${dir}`}>{item?.value}</td>
            </tr>))}</>
    )
}
