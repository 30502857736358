



import React from 'react';
import { Provider } from 'react-redux'
import { i18n } from "./translations/i18n";
import AppNav from './components/AppNav';
import store from './store/store';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Documents from './components/Documents';
import RelatedFactors from './components/relatedFactors/RelatedFactors'


function App() {

  // useEffect(() => {
  //   store.dispatch(loadOptions()); 
  // }, []);



  //// dont delete it is acive i18n
  const handleOnclick = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    // setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  };



  return (
    <>
      <Provider store={store}>
        <AppNav />
      </Provider>
    </>
  );
}

export default App;


function dispatch(arg0: { type: string; payload: { changeLocation: boolean; }; }) {
  throw new Error('Function not implemented.');
}

