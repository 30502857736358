import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { PrimaryButton, Checkbox } from '@fluentui/react'
import { useDispatch, useSelector } from 'react-redux'

import { CustomDropdown } from '../../shared/components/Option'
import { ProduceImpaReport, ProduceItaReport } from '../../store/actions/reportsAction'
import { IUserCredentials } from '../customerDetails/CustomerDetails'
import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils'
import Subtitle from '../../shared/components/Subtitle'

import '../../shared/components/headerUtils/headerUtils.css'
import '../../../src/scssPages/rtl.scss'
import '../../../src/scssPages/ltr.scss'
import moment from 'moment';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions';

const checkBoxStyles = {
    text: {
        fontWeight: "bold",
    }
};

export default function RegularReports() {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    let square = Math.floor(new Date().getMonth() / 3 + 1);
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const dispatch = useDispatch()
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const monthesList: IDropdownOption[] = [
        { key: "1", text: t('january') }, { key: "2", text: t('february') },
        { key: "3", text: t('march') }, { key: "4", text: t('april') },
        { key: "5", text: t('may') }, { key: "6", text: t('june') },
        { key: "7", text: t('july') }, { key: "8", text: t('august') },
        { key: "9", text: t('september') }, { key: "10", text: t('october') },
        { key: "11", text: t('november') }, { key: "12", text: t('december') }]
    const squareMonthesList: IDropdownOption[] = [{ key: "1", text: t('januaryMarch') }, { key: "2", text: t('aprilJune') },
    { key: "3", text: t('julySeptember') }, { key: "4", text: t('octoberDecember') }]
    const yearsList: IDropdownOption[] = [{ key: "1", text: String(moment().year() - 1) }, { key: "2", text: String(moment().year()) }
        , { key: "3", text: String(moment().year() + 1) }, { key: "4", text: String(moment().year() + 2) }, { key: "5", text: String(moment().year() + 3) }]
    const [monthReports, setMonthReports] = useState(monthesList?.find((item: any) => item.key === String(month))?.key)
    const [yearReports, setYearReports] = useState(yearsList?.find((item: any) => item.text === String(year))?.key)
    const [yearToSend, setYearToSend] = useState(yearsList?.find((item: any) => item.text === String(year))?.text)
    const [squareMonthReports, setSquareMonthReports] = useState(squareMonthesList?.find((item: any) => item.key === String(square))?.key)
    const [squareYearReports, setSquareYearReports] = useState(yearsList?.find((item: any) => item.text === String(year))?.key)
    const [squareYearToSend, setSquareYearToSend] = useState(yearsList?.find((item: any) => item.text === String(year))?.text)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [checked, setChecked] = useState<any>(false)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()

    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])


    const setReportsFunc = (id: any, key: any, value: any, set: any) => {
        set(key)
        if (id === "year") {
            setYearToSend(value)
        }
        if (id === "squareYear") {
            setSquareYearToSend(value)
        }
    }
    const ProduceReportFunc = async (month: any, name: string) => {
        if (name === "impaCtr")
            await dispatch(ProduceImpaReport(month, yearToSend, userCredentials, t))
        if (name === "itaCtr")
            await dispatch(ProduceItaReport(month, squareYearToSend, checked, userCredentials))

    }
    return (
        <div className={`${checkPermissionLevelOfPage(credentials, location) === "RW"
            ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={t("reports_link")} subTitle={"false"} disabledSave={true} createBtn={false}
                editBtn={false} deleteBtn={false} uploadBtn={false} action={false} />
            <br></br><br></br>
            {/* {showDialogSave && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"save"} />}
            {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} setshowModalDelete={setShowDialogFailed} dialogType={"failed"} />}
            {showSpiner && <Spinner styles={spinnerStyles}  className={`spinner-${dir}`} />} */}
            <Subtitle title={t('regularReports')} />
            <div className={`content-div-${dir}`} >
                <p className="title-text" >{t("reportingSystemsList")}</p>
                <hr className="hr" style={{ width: "662px" }} ></hr>
                <div className="content-search" style={{ marginTop: "2%" }} >
                    <p className="boldPP" style={{ width: "260px", fontSize: "20px" }}>{t('productionMonthlyReportImpa')}</p>
                    <div style={{ width: "150px" }}>
                        <CustomDropdown otherInputId={''} readOnly={false} hasOtherValue={false}
                            options={monthesList} label={t('')}
                            onChange={(id: any, key: any, val: any) => { setReportsFunc(id, key, val, setMonthReports) }} selectedKey={monthReports} id={'month'} othertextInput={t('')} />
                    </div>
                    <div className={`margin-between-input-${dir}`} style={{ width: "150px" }}>
                        <CustomDropdown otherInputId={''} readOnly={false} hasOtherValue={false} options={yearsList} label={t('')}
                            onChange={(id: any, key: any, val: any) => { setReportsFunc(id, key, val, setYearReports) }} selectedKey={yearReports} id={'year'} othertextInput={t('')} />
                    </div>
                    <PrimaryButton className={`bottun-${dir}`} allowDisabledFocus checked={false} name="impaCtr"
                        onClick={() => { ProduceReportFunc(monthReports, "impaCtr") }} text={t("produceReport")} />

                </div>
                <div className="content-search" style={{ marginTop: "2%" }}>
                    <p className="boldPP" style={{ width: "260px", fontSize: "20px" }}>{t('quarterlyProductionReportForTaxAuthority')}</p>
                    <div style={{ width: "150px" }}>
                        <CustomDropdown otherInputId={''} readOnly={false} hasOtherValue={false} options={squareMonthesList} label={t('')}
                            onChange={(id: any, key: any, val: any) => { setReportsFunc(id, key, val, setSquareMonthReports) }} selectedKey={squareMonthReports} id={"month"} othertextInput={t('')} />
                    </div>
                    <div className={`margin-between-input-${dir}`} style={{ width: "150px" }}>
                        <CustomDropdown otherInputId={''} readOnly={false} hasOtherValue={false} options={yearsList} label={t('')}
                            onChange={(id: any, key: any, val: any) => { setReportsFunc(id, key, val, setSquareYearReports) }} selectedKey={squareYearReports} id={'squareYear'} othertextInput={t('')} />
                    </div>
                    <PrimaryButton className={`bottun-${dir}`} allowDisabledFocus checked={false} name="itaCtr"
                        onClick={() => { ProduceReportFunc(squareMonthReports, "itaCtr") }} text={t("produceReport")} />
                </div>
                <div className="content-search" style={{ marginTop: "2%" }}>
                    <p className="boldPP " style={{ width: "260px" }}></p>
                    <div className={`checkbox-previous-report-${dir}`}>
                        <Checkbox styles={checkBoxStyles} label={t("markReport")} checked={checked} onChange={() => { setChecked(!checked) }} />
                    </div>

                </div>
               
            </div>
        </div>
    )
}
