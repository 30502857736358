import axios from "axios";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { basicUrl } from "../../shared/config";



export const CreatePhone = async (customer: object, currUserCredentials: IUserCredentials | null, id_entity: string, setshowSpiner?: any,
     type?: string,setShowDialogSave?:any, setShowDialogFailed?:any, setServerError?:any,setReadOnly?:any) => {
    const phone = {
        //@ts-ignore
        "id_initiator": currUserCredentials.idInitiator,
        //@ts-ignore
        "id_branch": currUserCredentials.idBranch,
        //@ts-ignore
        "id_entity": id_entity,
        //@ts-ignore
        "id_client": currUserCredentials.idClient,
        "return_entity": true,
        "attribute_request_method": "create",
        "attribute": "telephone",
        "entity_type_id": type ? "insider" : "customer",
        //@ts-ignore
        "telephone_number": customer.telephone,
        //@ts-ignore
        "telephone_country_code": customer.telephoneCountryCode,
        //@ts-ignore
        "telephone_type_id": customer.telephoneTypeId ? customer.telephoneTypeId : 1,
        "is_deleted": 0,
        //@ts-ignore
        "is_default": customer.telephoneDefault ? customer.telephoneDefault : false,
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(phone);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    await axios.post(basicUrl + '/entity', body, config).then(res => {
        try {
            if (res.status === 200) {
                if (res.data["err_code"] === 0) {
                    setshowSpiner && setshowSpiner(false)
                    setShowDialogSave &&  setShowDialogSave(true)
                    setReadOnly&&setReadOnly(true)
                }
            }
            else {
                setshowSpiner && setshowSpiner(false)
                setServerError && setServerError(res.data?.ui_message?.ui_message)
                setShowDialogFailed && setShowDialogFailed(true)
                console.log(res.data?.err_description)
            }
        }
        catch (err) {
            setshowSpiner && setshowSpiner(false)
            setServerError && setServerError(res.data?.ui_message?.ui_message)
            setShowDialogFailed && setShowDialogFailed(true)
            console.log('error in create phone component', err)
        }
    }).catch(err => {
        console.log("error", err.message);
    })
}

export const updatePhone = async (customer: object, userCredentials: IUserCredentials | null, currentIdEntity: string, phoneId: any, setShowDialogSave?: any, setReadOnly?: any, setshowSpiner?: any, type?: string, setShowDialogFailed?: any, setServerError?: any) => {
    const phone = {
        "id_initiator": userCredentials && userCredentials.idInitiator,
        "id_client": userCredentials && userCredentials.idClient,
        "id_branch": userCredentials && userCredentials.idBranch,
        "id_entity": currentIdEntity,
        "return_entity": 1,
        "attribute_request_method": "update",
        "attribute": "telephone",
        "telephone_id": phoneId,
        "entity_type_id": type === "user" ? "user" : type === "insider" ? "insider" : "customer",
        //@ts-ignore
        "is_default": customer.telephoneDefault,
        //@ts-ignore
        "telephone_number": customer.telephone,
        //@ts-ignore
        "telephone_type_id": customer.telephoneTypeId,
        //@ts-ignore
        "telephone_country_code": customer.telephoneCountryCode,
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(phone);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    await axios.post(basicUrl + '/entity', body, config).then(res => {
        try {
            if (res.status === 200) {
                if (res.data["err_code"] === 0) {
                    setShowDialogSave && setShowDialogSave(true)
                    setReadOnly && setReadOnly(true)
                    if (setshowSpiner)
                        setshowSpiner(false)
                }
                else {
                    setshowSpiner && setshowSpiner(false)
                    setServerError && setServerError(res.data?.ui_message?.ui_message)
                    setShowDialogFailed && setShowDialogFailed(true)
                    console.log(res.data?.err_description)
                }
            }

        }
        catch (err) {
            setshowSpiner && setshowSpiner(false)
            setServerError && setServerError(res.data?.ui_message?.ui_message)
            setShowDialogFailed && setShowDialogFailed(true)
        }
    }).catch(err => {
        console.log("error", err.message);
        setshowSpiner && setshowSpiner(false)
        setServerError && setServerError(err?.message)
        setShowDialogFailed && setShowDialogFailed(true)
    })
}



export const deletePhone = async (userCredentials: IUserCredentials, currentIdEntity: string, phoneID: any, setshowSpiner?: any, isClient?: any) => {

    const phone = {
        "id_initiator": userCredentials.idInitiator,
        "id_client": userCredentials.idClient,
        "id_branch": userCredentials.idBranch,
        "id_entity": currentIdEntity,
        "return_entity": 1,
        "attribute_request_method": "delete",
        "attribute": "telephone",
        "telephone_id": phoneID,
        "entity_type_id": isClient === true ? "client" : "customer",
        "is_deleted": true
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(phone);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    await axios.post(basicUrl + '/entity', body, config).then(res => {
        try {
            if (res.status === 200) {
                if (res.data["err_code"] === 0) {
                    setshowSpiner(false)
                }
            }
            else {
                setshowSpiner(false)
            }
        }
        catch (err) {
            setshowSpiner(false)
            console.log('error in delete phone component', err)
        }
    })
        .catch(err => {
            console.log("error", err.message);
        })

}

