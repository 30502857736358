import React, { useEffect, useState } from "react";
import { Nav } from "@fluentui/react/lib/Nav";
import "../../scssPages/sidebar.scss";

import { INavStyles, INavLinkGroup, INavLink } from "@fluentui/react/lib/Nav";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { useLocation, withRouter } from "react-router-dom";
import "../../scssPages/sidebar.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { registerIcons } from "@fluentui/react";
import {
  RESET_DATA_CONVERSION,
  SAVE_DRAFT_CONVERSION_CHANGES,
} from "../../store/actions/types";
import "../../modelsType/Icons/css/all.css";
import { globalPages } from "../../shared/components/CustomHooks/GlobalSidePages";

initializeIcons();

registerIcons({
  icons: {
    moneyCheckEditIcon: <i className="fal fa-money-check-edit-alt"></i>,
    CoinsIcov: <i className="fal fa-coins"></i>,
    ThListIcon: <i className="fal fa-coins"></i>,
  },
});

const ContentSideConvert = (props: any) => {
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);

  const navStyles: Partial<INavStyles> = {
    root: {
      i: { width: "25px", marginRight: "3px" },
    },
    link: {
      ":after": dir === "ltr" && {
        borderLeft: "2px solid transparent !important",
        borderRight: "2px solid rgb(0, 120, 212) !important",
      },
    },
  };
  // const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.user?.credentials)

  // const [showPermissionNote, setShowPermissionNote] = useState(false)
  // const [msgPermission, setMsgPermission] = useState('')
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState("");
  const arrCredentials = useSelector(
    ({ authReducer }: any) => authReducer?.data?.credentials
  );
  const credentialsPagesDetails: any = globalPages;

  useEffect(() => {
    const pathname = location.pathname.split("/");
    const key = pathname.length > 2 ? pathname.pop() : null;

    setSelectedKey(key || "currency_exchange");
  }, []);

  const handleNavClick = async (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    ev?.preventDefault();
    // props.history.push(`/converts${item?.url}`);

    setSelectedKey(item?.key || "currency_exchange");
    let path: any = item?.url.split("/")[1];
    // if (credentials[path] && credentials[path] === "Deny" || !credentials[path]) {
    //   setMsgPermission(t('permissionAaccessThisPage'))
    //   setShowPermissionNote(true)
    // }
    // else {
    if (location.pathname === "/currency_exchange/exchange") {
      sessionStorage.setItem("dataConversion", JSON.stringify(""));
      if (item?.url !== "/currency_exchange") {
        await dispatch({
          type: RESET_DATA_CONVERSION,
          payload: null,
        });
      }
      await dispatch({
        type: SAVE_DRAFT_CONVERSION_CHANGES,
        payload: {
          changeLocation: true,
          location: `/currency_exchange${item?.url}`,
        },
      });
    } else {
      // else {
      dispatch({
        type: SAVE_DRAFT_CONVERSION_CHANGES,
        payload: {},
      });
      props.history.push(`/currency_exchange${item?.url}`);
      // }
    }

    // }
  };

  const navLinkGroups: INavLinkGroup[] = [{ links: [] }];
  arrCredentials?.map(
    (module: any) =>
      module?.module_code === "currency_exchange" &&
      module?.pages?.map((page: any) =>
        navLinkGroups[0]?.links.push({
          className: credentialsPagesDetails[page?.page_code]?.className,
          key: credentialsPagesDetails[page?.page_code]?.key,
          iconProps: credentialsPagesDetails[page?.page_code]?.iconProps,
          name: t(credentialsPagesDetails[page?.page_code]?.name),
          styles: credentialsPagesDetails[page?.page_code]?.styles,
          url: credentialsPagesDetails[page?.page_code]?.url,
          onClick: handleNavClick,
        })
      )
  );

  return (
    <>
      <Nav
        className="items"
        onLinkClick={handleNavClick}
        styles={navStyles}
        groups={navLinkGroups}
        selectedKey={selectedKey}
      />
    </>
  );
};
export default withRouter(ContentSideConvert);
