import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { IconButton } from "@fluentui/react";
import IconSvg from "../../modelsType/Icon/IconSvg";

const ExportCSV = (props: any) => {
    const { csvData, fileName } = props

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData: any, fileName: any) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <IconButton
            // iconProps={Icons.print}
            styles={{
                icon: { color: 'green', fontSize: 36 }
            }}
            className="button"
            onClick={(e) => exportToCSV(csvData, fileName)}
        >
            <IconSvg name="xl" />
        </IconButton>
    )
}
export default ExportCSV