import { useEffect, useState } from 'react';
import { PrimaryButton } from 'office-ui-fabric-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IUserCredentials } from '../customerDetails/CustomerDetails';
import { downloadFile, uploadFile } from '../../store/actions/FilesAction'
import { IconButton } from '@fluentui/react';
import { Icons } from '../../modelsType/Icon';
import { CustomFilesDocumentsDialog } from '../../shared/components/dialog/Dialog';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages'
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { updateDocument } from '../../store/actions/documentAction';


export default function UploadFiles(props: any) {
    const { idEntity, documentationTypeId, mediaName, pdfFile } = props
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const dataCustomer = useSelector(({ customerReducer }: any) => customerReducer?.dataCustomer)
    const dispatch = useDispatch()
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const message = t("replaceDocumentmessage")
    const [activeDocument, setActiveDocument] = useState<any>({})
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [addFiles, setAddFiles] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [updateActiveDocument, setUpdateActiveDocument] = useState("")
    const [documentsList, setDocmentsList] = useState<any>([])
    const [itemUpdate, setItemUpdate] = useState<any>({})
    const [file, setFile] = useState<any>({
        media_name: "", id_media: "", documentation_type_id: null, media_type_id: "1", media_status_id: "1",
        media_exp_date: "", media_details: null, date_created: "", status: "פעיל", type: "חובה"
    })
    useEffect(() => {
        setUserCredentials({
            "idInitiator": authReducer.data.user.id_entity,
            "idClient": authReducer.data.user.id_client,
            "idBranch": authReducer.data.branch.id_entity,
            "idClientData": authReducer.data.client.id_client
        })
    }, [authReducer]);

    useEffect(() => {
        documentationTypeId && setItemUpdate({ id_media: "", media_name: mediaName ? mediaName : "", documentation_type_id: documentationTypeId, media_type_id: "1", type: "חובה", media_status_id: "1", status: "", date_created: "", media_exp_date: "", media_details: null })
    }, [documentationTypeId, mediaName])

    useEffect(() => {
        if (dataCustomer?.documentation) {
            dataCustomer?.documentation?.forEach((doc: any) => {
                if (doc?.documentation_type_id === documentationTypeId && doc?.media_status_id.toString() !== "99")
                    setActiveDocument(doc)
            });
        }
    }, [dataCustomer])

    useEffect(() => {
        if (authReducer) {
            let documentNameList, documentList: any = []
            documentNameList = authReducer.data.documentation_requirements.filter((item: any) => (item?.customer_type_id === dataCustomer?.properties?.customer_type_id))
            let documentAllList = authReducer.data.documentation_requirements.filter((item: any) => (item?.customer_type_id === "ALL"))
            documentNameList = documentNameList.concat(documentAllList)
            let customerDocuments: any = []
            let activeDocuments: any = []
            // if (!idInsider && !isInsider && !setRelatedFactors) {
            if (dataCustomer?.documentation) { customerDocuments = [...dataCustomer?.documentation] }
            documentNameList.map((doc: any) => {
                let fromCustomer = false
                if (dataCustomer?.documentation) {
                    customerDocuments = customerDocuments.filter((item: any) => (doc?.documentation_type_id !== item?.documentation_type_id))
                    dataCustomer?.documentation.forEach((item: any) => {
                        if (doc?.documentation_type_id === item?.documentation_type_id) {
                            documentList.push({ id_media: item?.id_media, media_name: item?.media_name, documentation_type_id: item?.documentation_type_id, media_type_id: "1", type: "חובה", media_status_id: item?.media_status_id, status: item?.media_status_id === "99" ? "מחוק" : "פעיל", date_created: item?.date_created, media_exp_date: item?.media_exp_date, media_details: item?.media_details })
                        }
                        if ((doc?.documentation_type_id === item?.documentation_type_id) && item?.media_status_id !== "99") {
                            activeDocuments.push({ id_media: item?.id_media, media_name: item?.media_name, documentation_type_id: item?.documentation_type_id, media_type_id: "1", type: "חובה", media_status_id: item?.media_status_id, status: item?.media_status_id === "99" ? "מחוק" : "פעיל", date_created: item?.date_created, media_exp_date: item?.media_exp_date, media_details: item?.media_details })
                            fromCustomer = true
                        }
                    });
                }
                if (!fromCustomer) {
                    documentList.push({ id_media: "", media_name: doc?.documentation_type, documentation_type_id: doc?.documentation_type_id, media_type_id: "1", type: "חובה", media_status_id: "1", status: "פעיל", date_created: "", media_exp_date: "", media_details: null })
                    activeDocuments.push({ id_media: "", media_name: doc?.documentation_type, documentation_type_id: doc?.documentation_type_id, media_type_id: "1", type: "חובה", media_status_id: "1", status: "פעיל", date_created: "", media_exp_date: "", media_details: null })
                }
            })
            customerDocuments?.forEach((item: any) => {
                documentList.push({ id_media: item?.id_media, media_name: item?.media_name, documentation_type_id: item?.documentation_type_id, media_type_id: "1", type: "חובה", media_status_id: item?.media_status_id, status: item?.media_status_id === "99" ? "מחוק" : "פעיל", date_created: item?.date_created, media_exp_date: item?.media_exp_date, media_details: item?.media_details })
                if (item?.media_status_id !== "99")
                    activeDocuments.push({ id_media: item?.id_media, media_name: item?.media_name, documentation_type_id: item?.documentation_type_id, media_type_id: "1", type: "חובה", media_status_id: item?.media_status_id, status: item?.media_status_id === "99" ? "מחוק" : "פעיל", date_created: item?.date_created, media_exp_date: item?.media_exp_date, media_details: item?.media_details })
            });
            setDocmentsList(documentList)
        }
    }, [authReducer, dataCustomer])


    useEffect(() => {
        if (file?.id_media) {
            save("file")
        }
        if (pdfFile?.id_media) {
            save("pdfFile")
        }
    }, [file, pdfFile])

    useEffect(() => {
        if (updateActiveDocument === "save") {
            if ((documentationTypeId !== "5" && documentationTypeId !== "6"))
                setAddFiles(true)
            else
                clickInput()
        }
        if (updateActiveDocument === "don't save")
            setAddFiles(false)
        updateActiveDocument && setUpdateActiveDocument("")
    }, [updateActiveDocument])

    const download = () => {
        activeDocument?.id_media &&
            dispatch(downloadFile(idEntity, userCredentials, activeDocument?.id_media, activeDocument?.media_name, "pdf"))
    }
    const clickInput = () => {
        let input = document.getElementById('files');
        if (input !== null) {
            input.click()
        }
    }
    const save = async (source: any) => {
        let fileSave: any
        if (source === "pdfFile") {
            fileSave = pdfFile
        }
        if (source === "file") {
            fileSave = file
        }
        if (fileSave?.id_media) {
            let documents: any = [...documentsList], update: any = "", index: any = -1, first = false
            let newFile = cloneDeep(fileSave)
            newFile.date_created = moment(new Date()).format('YYYY-MM-DD')
            documents?.forEach((doc: any, i: any) => {
                if (doc?.documentation_type_id === fileSave?.documentation_type_id && doc?.id_media) {
                    doc.media_status_id = "99"
                    doc.status = "מחוק"
                    update = true
                    first = true
                }
                else {
                    if (doc?.documentation_type_id === fileSave?.documentation_type_id && !first) {
                        update = false
                        index = i
                    }
                }
            });
            if (update === true)
                documents?.push(newFile)
            else
                documents[index] = newFile
            //   setshowSpiner && setshowSpiner(true)
            await dispatch(updateDocument(documents, userCredentials, idEntity, "", "", "", "", "READ_CUSTOMER"))
        }
        else
            alert(t('noFileAttached'))

    }
  
    const onFileChange = async (event: any) => {
        var futureYear = moment().add(1, 'year').format("YYYY-MM-DD");
        let file = ({
            media_name: mediaName, id_media: "", documentation_type_id: documentationTypeId, media_type_id: "1", media_status_id: "1",
            media_exp_date: futureYear.toString(), media_details: null, date_created: "", status: "פעיל", type: "חובה"
        })
        await dispatch(uploadFile(idEntity, userCredentials, event.target.files[0], file, setFile, "", "", "",t))
    };

    return (
        <>
            {showMessage && <DialogMessages setansDelete={setShowMessage} subText={message} title={t('note')}
                setshowModalDelete={setShowMessage} dialogType={"dialogMessage"} setSaveDataConversion={setUpdateActiveDocument} />}
            <div className="titleC">
                <p className="title-text">{t("scanFile")}</p></div>
            <hr className="hr" style={{ width: "80%" }} ></hr>
            <div style={{ width: "80%" }}>
                {activeDocument?.id_media && <div className={`fileDetails-${dir}`} >
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <IconButton onClick={() => download()} title={t('downloadFile')} iconProps={Icons.articles}
                            styles={{ icon: { color: 'black', fontSize: 45 } }} className="iconFile" />
                    </div>
                    <p className="fileText">{activeDocument?.media_name}</p>
                    <p className="fileText">{moment(activeDocument?.media_exp_date).format("DD/MM/YYYY")}</p>
                </div>}
                <div className="wrapper-collateral" style={{ justifyContent: "center", alignItems: "center", display: "flex", marginTop: "5px" }}>
                    {(documentationTypeId !== "5" && documentationTypeId !== "6") ? <PrimaryButton className='buttonService' allowDisabledFocus onClick={() => { activeDocument?.id_media ? setShowMessage(true) : setAddFiles(true) }} checked={false} iconProps={Icons.cloudUpload} text={t("uploadFile")} />
                        : <PrimaryButton style={{ width: "100%", marginTop: "10px" }} onClick={() => { activeDocument?.id_media ? setShowMessage(true) : clickInput() }} type="file" text={t("uploadFile")} />
                    }
                </div>
                {/* <div className="wrapper-collateral" style={{ justifyContent: "center", alignItems: "center", display: "flex", marginTop: "5px" }}>
                    <PrimaryButton className='buttonService' onClick={() => download()} iconProps={Icons.cloudDownload} text="הורדת קובץ" />
                </div> */}
                {(addFiles && documentationTypeId !== "5" && documentationTypeId !== "6") &&
                    <CustomFilesDocumentsDialog itemUpdate={itemUpdate && itemUpdate} documents={dataCustomer?.documentation ? dataCustomer?.documentation : documentsList}
                        setShowDialog={setAddFiles} userCredentials={userCredentials} idEntity={idEntity} actionType={"READ_CUSTOMER"} />
                }
                <input style={{ display: "none" }} id="files" type="file" onChange={(e) => onFileChange(e)} />

            </div>
        </>);

}