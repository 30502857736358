import React, { useEffect, useState } from 'react'
import { useBoolean } from '@fluentui/react-hooks';
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, ContextualMenu, DialogFooter } from 'office-ui-fabric-react';
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useTranslation } from 'react-i18next';

import { CustomDropdown } from "../../shared/components/Option";
import { CustomTextField } from "../../shared/components/TextField";
import { convertArrToDropdropdown } from '../../components/customerDetails/CustomerDetails'
import { CustomerDate } from '../../shared/components/CustomHooks/concatTextFields'
import { createUser, updateUserInfo } from '../../store/actions/usersAction';
import { Address } from '../../shared/components/CustomHooks/Address'
import { IdNumber, Phone, Email } from '../../shared/components/CustomHooks/concatTextFields'
import DocumentsList from '../DocumentsList';
import { SET_USER } from '../../store/actions/types';
import { isEqual } from 'lodash';

const UsersDialog = (props: any) => {
  const { setShowDialog, itemUpdate, update, userCredentials, setShowDialogCreate, setshowSpiner, branchNumber } = props
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [t] = useTranslation();
  const [dir,setDir]=useState("")
  const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const dispatch = useDispatch()
  const [isValidDate, setIsValidDate] = React.useState<any>(true);
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
  const [showTextRequiredFiles, setShowTextRequiredFiles] = useState(false)
  const [enumsDropdown, setenumsDropdown] = useState<any>({ "countryCode": [], "bank": [], "branch": [] })
  const [resultId, setResultId] = useState<any>(true)
  const [defaultIdTypeId, setDefaultIdTypeId] = useState()
  const [items, setItems] = useState<any>([])
  const [activeDocumentsList, setActiveDocumentsList] = useState<any>([])
  const [validEmail, setValidEmail] = useState(true)
  const [showUpdatePasswordMsg, setShowUpdatePasswordMsg] = useState()
  const [updateUser, setupdateUser] = useState(itemUpdate ? itemUpdate : {
    gender: 1,
    otherGender: "",
    entityStatusId: 0,
    idTypeCountryCode: "",
    firstName: '',
    note: "",
    lastName: '',
    isLocked: false,
    isPopupMessage: false,
    dateBirth: "",
    genderOther: "",
    middleName: "",
    otherCustomerStatus: "",
    classId: 1,
    entitySubTypeId: 1,
    ViewNoteWhenPerformingAction: false,
    industryId: 0,
    CreditGroup: 0,
    idAffiliate: 0,
    idTypeId: "",
    idNumber: "",
    idTypeOther: "",
    addressCountryCode: "",
    addressState: "",
    idIdentifier: 0,
    address: "",
    addressName: "",
    addressNumber: "",
    addressCity: "",
    addressCityName: "",
    iDCountryCode: "",
    telephone: "",
    telephoneCountryCode: "",
    email: "",
    entityNumber: "",
    addressZipCode: "",
    customerTypeId: "",
    workerName: "",
    genderName: "",
    telephoneTypeId: 1,
    telephoneId: 0,
    addressTypeId: 1,
    emailTypeId: 1,
    emailId: 0,
    telephoneDefault: 1,
    addressDefault: 1,
    emailDefault: 1,
    addressId: 0,
    login_entity_number: "",
    // "900001"
    login_ID: '',
    login_password: '',
    permission_group_id: '',
    user_language:"HE"
  })

  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
  };
  const modelProps = {
    isBlocking: false,
    topOffsetFixed: true,
    dragOptions: dragOptions
  };
  useEffect(()=>{
    if(lang){
        setDir(lang)
    }
    },[lang])
    
  useEffect(() => {
    if (itemUpdate) {
      let activeList: any = [], documentList: any = []
      setupdateUser(itemUpdate)
      itemUpdate?.documentation?.forEach((item: any) => {
        item?.media_status_id !== "99" && item?.id_media &&
          activeList.push({ id_media: item?.id_media, media_name: item?.media_name, documentation_type_id: item?.documentation_type_id, media_type_id: "1", type: "חובה", media_status_id: item?.media_status_id, status: item?.media_status_id === "99" ? "מחוק" : "פעיל", date_created: item?.date_created, media_exp_date: item?.media_exp_date, media_details: item?.media_details })
        documentList.push({ id_media: item?.id_media, media_name: item?.media_name, documentation_type_id: item?.documentation_type_id, media_type_id: "1", type: "חובה", media_status_id: item?.media_status_id, status: item?.media_status_id === "99" ? "מחוק" : "פעיל", date_created: item?.date_created, media_exp_date: item?.media_exp_date, media_details: item?.media_details })
      })
      setItems(documentList)
      setActiveDocumentsList(activeList)

    }
  }, [itemUpdate])

  useEffect(() => {
    if (enums) {
      let arr = {
        "genders": convertArrToDropdropdown([]),
        "typeIdentityNumbers": convertArrToDropdropdown([]),
        "status": convertArrToDropdropdown([]),
        "telephoneCountryCode": convertArrToDropdropdown([]),
        "countryCode": convertArrToDropdropdown([]),
        "permissionGroupId": convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].customer !== undefined) {
          let customer = enums[i].customer;
          for (let j = 0; j < customer.length; j++) {
            if (customer[j].gender_id !== undefined)
              arr.genders = convertArrToDropdropdown(customer[j].gender_id)
            if (customer[j].ID_type_id !== undefined)
              arr.typeIdentityNumbers = convertArrToDropdropdown(customer[j].ID_type_id)
            if (customer[j].status_id !== undefined)
              arr.status = convertArrToDropdropdown(customer[j].status_id)
          }

        }
        if (enums[i].general !== undefined) {
          let general = enums[i].general
          for (let j = 0; j < general.length; j++) {
            if (general[j].telephone_country_code !== undefined)
              arr.telephoneCountryCode = convertArrToDropdropdown(general[j].telephone_country_code)
            if (general[j].country_code !== undefined)
              arr.countryCode = convertArrToDropdropdown(general[j].country_code)
          }
        }
        if (enums[i].user !== undefined) {
          let user = enums[i].user
          for (let j = 0; j < user.length; j++) {
            if (user[j].permission_group_id !== undefined)
              arr.permissionGroupId = convertArrToDropdropdown(user[j].permission_group_id)
          }
        }
      }
      let defaultTelephoneValue: any
      (defaultTelephoneValue = arr?.telephoneCountryCode.find(({ is_default }: any) => is_default === true))
      let defaultCountryValue: any
      (defaultCountryValue = arr?.countryCode.find(({ is_default }: any) => is_default === true))
      if (itemUpdate === undefined) {
        let defaultStatusValue: any
        (defaultStatusValue = arr?.genders.find(({ is_default }: any) => is_default === true))
        let defaultCStatusValue: any
        (defaultCStatusValue = arr?.status.find(({ is_default }: any) => is_default === true))
        let defaultTypeValue: any
        (defaultTypeValue = arr?.typeIdentityNumbers.find(({ is_default }: any) => is_default === true))
        setDefaultIdTypeId(defaultTypeValue?.key)
        setupdateUser({
          ...updateUser, iDCountryCode: defaultCountryValue?.key, gender: defaultStatusValue?.key
          , telephoneCountryCode: defaultTelephoneValue?.key, idTypeId: defaultTypeValue?.key, idTypeCountryCode: defaultCountryValue?.key, entityStatusId: defaultCStatusValue?.key
          , login_entity_number: branchNumber
        })
      }
      setenumsDropdown(arr);
    }
  }, [enums])

  useEffect(() => {
    if (setShowDialog)
      toggleHideDialog()
  }, [setShowDialog])

  const changeUser = (key: string, value: any) => {
    setupdateUser({ ...updateUser, [key]: value })
  }
  const check = (currentValue: string) => updateUser[currentValue]?.length > 0;

  const updateUserOrCreate = () => {
    debugger
    let requiredFields: any = ["dateBirth", "idNumber", "lastName", "firstName",]
    //  "permission_group_id",]
    let permissionRequireds: any = ["login_ID", "login_password"]
    if (!isEqual(updateUser, itemUpdate)) {
      if ((requiredFields.every(check) && !update && permissionRequireds.every(check)) || (update === true && requiredFields.every(check))) {
        if (resultId && validEmail && isValidDate) {
          console.log(update,"update");
          
          setShowDialog(false);
          setshowSpiner(true);
          toggleHideDialog();
          update === true ?
            dispatch(updateUserInfo(updateUser, userCredentials, updateUser?.key, setShowDialogCreate, setshowSpiner)) :
            dispatch(createUser(updateUser, userCredentials, setshowSpiner, setShowDialogCreate));
        }
        else
          alert("enter valid fields")
      }
      else
        setShowTextRequiredFiles(true)
    }
    else {
      setShowDialog(false);
      toggleHideDialog();
    }
  }

  return (
    <>
    {/* className='doc' */}
      <Dialog  minWidth="xl" maxWidth="xl" modalProps={modelProps} hidden={hideDialog} onDismiss={() => { toggleHideDialog(); setShowDialog(false) }} >
        <div>
          <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{t("userDetails")}</p>
          <hr className="hr"></hr>
          <div style={{ width: update && activeDocumentsList.length > 0 ? "82vw" : "50vw", gridTemplateColumns: "1fr 1fr", display: "flex", gridTemplateRows: "auto", gridArea: "main" }}>
            <div className={`div-user-dialog-${dir}`}  style={{ 
        width: update && activeDocumentsList.length > 0 ? "35vw" : "50%" }}>
                <p className="title-text">{t("personalDetails")}</p>
                <hr className="hr"></hr>
                <div className="wrapper-collateral">
                  <div className={`div-details-user-${dir}`}>
                    <CustomTextField required={true} requiredField={showTextRequiredFiles && !updateUser?.firstName ? true : false} value={updateUser?.firstName} readOnly={false} label={t('firstName')} onChange={changeUser} id={'firstName'} />
                    <CustomTextField required={true} requiredField={showTextRequiredFiles && !updateUser?.lastName ? true : false} value={updateUser?.lastName} readOnly={false} label={t('lastName')} onChange={changeUser} id={'lastName'} />
                    <CustomerDate isDefaultDate={false} setShowTextRequiredFiles={setShowTextRequiredFiles} showRequiredFildes={showTextRequiredFiles && !updateUser?.dateBirth ? true : false} isValidDate={isValidDate} setIsValidDate={setIsValidDate} entity={updateUser} setEntity={setupdateUser} readOnly={false} idDate="dateBirth" label={t('dateOfBirth')} source={"pastDate"} />
                    <CustomDropdown otherInputId={'genderName'} otherValue={updateUser?.genderName} hasOtherValue={true} options={enumsDropdown.genders} label={t('gander')} readOnly={false} onChange={changeUser} selectedKey={updateUser?.gender} id={'gender'} othertextInput={t('other')} />
                  </div>
                  <div className={`permission-level-${dir}`}>
                    <IdNumber showRequiredFildes={showTextRequiredFiles} entity={updateUser} setEntity={setupdateUser} readOnly={false} setResultId={setResultId} resultId={resultId} typeIdentityNumbers={enumsDropdown.typeIdentityNumbers} typeIdDefault={defaultIdTypeId} />
                    {/* countryCode={enumsDropdown?.countryCode} */}
                    <CustomDropdown otherInputId={''} hasOtherValue={false} required options={enumsDropdown?.permissionGroupId} label={t('permissionLevel')} onChange={changeUser} selectedKey={updateUser?.permission_group_id} id={'permission_group_id'} othertextInput={t('')} />
                  </div>
                </div>
              <p style={{ marginTop: "25px" }} className="sub-title-text">{t("contactInformation")}</p>
              <hr className="hr"></hr>
              <div className="wrapper-collateral" style={{ alignItems: "inherit" }} >
                <div className={`warpper-fields-right-${dir} marginL-between-container-${dir}`}>
                  <p className="title-text-2" >{t("address")}</p>
                  <hr className="hr"></hr>
                  <Address setEntity={setupdateUser} showRequiredFildes={showTextRequiredFiles} entity={updateUser} />
                </div>
                <div className={`warpper-fields-left-${dir}`} style={{ alignItems: "inherit" }}>
                  <Phone entity={updateUser} setEntity={setupdateUser} />
                  <Email entity={updateUser} setEntity={setupdateUser} setValidEmail={setValidEmail} validEmail={validEmail} />
                </div>
                {/* </div> */}
              </div>

            </div>
            <div  className={`marginR-between-container-${dir} div-js-${dir}`}  style={{width: update && activeDocumentsList.length > 0 ? "35vw" : "50%" }}>
              <p className="title-text">{t("loginAndPermissions")}</p>
              <hr className="hr"></hr>
              <div className="wrapper-collateral" >
                <div className={`warpper-fields-right-${dir}`}>
                  <CustomTextField required={true} requiredField={showTextRequiredFiles && !updateUser?.login_ID ? true : false} readOnly={update ? true : false} value={updateUser?.login_ID} label={t("username")} onChange={changeUser} id={'login_ID'} />
                  <CustomDropdown otherInputId={''} hasOtherValue={false} required options={[{key:"HE",text:"עברית"},{key:"EN",text:"English"}]} label={t('selectLnguage')} onChange={changeUser} selectedKey={updateUser?.user_language} id={'user_language'} othertextInput={t('')} />
                
                </div>
                <div className={`warpper-fields-left-${dir}`}>
                  <CustomTextField required={true} type="password" canRevealPassword requiredField={showTextRequiredFiles && !updateUser?.login_password ? true : false} value={updateUser?.login_password} label={t("password")} onChange={changeUser} id={'login_password'} />
                  <CustomDropdown otherInputId={''} hasOtherValue={false} required options={[]} label={t('timeZone')} onChange={()=>{}} selectedKey={""} id={''} othertextInput={t('')} />

                </div>
              </div>
              <CustomTextField readOnly={true} value={updateUser?.login_entity_number} label={t("branchNumber")} onChange={changeUser} id={'login_entity_number'} />
              {update && <DocumentsList activeDocumentsList={activeDocumentsList} items={items} idOtherEntity={updateUser?.key} actionType={SET_USER} />}
            </div>
          </div>
          <div className="note-field" style={{width: update && activeDocumentsList.length > 0 ? "35vw" : "50%"}}>
            <p className="title-text">{t('note')}</p>
            <CustomTextField value={''} onChange={changeUser} id={'note'} /></div>
          <DialogFooter >
            <DefaultButton onClick={() => { toggleHideDialog(); setShowDialog(false) }} text={t("cancel")} />
            <PrimaryButton onClick={() => { updateUserOrCreate() }} text={update ? t("update") : t("create")} />
          </DialogFooter>
        </div>
      </Dialog>
    </>
  )
}
export default UsersDialog