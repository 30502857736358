import axios from "axios";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { basicUrl } from "../../shared/config";
import {  SET_HTML_PAGE } from "./types";

export const readCompletedDoc = (type:any,userCredentials: IUserCredentials | null, setSearch_fields: any, setArrayResult: any, pageIndex: any
    , search_filter?: any , numRecord?: any, setServerMessageError?: any, setMessageError?: any, setDialogTitle?: any) => async (dispatch: Function) => {
     
      const transaction = {
          "id_initiator": userCredentials && userCredentials["idInitiator"],
          "id_client": userCredentials && userCredentials["idClient"],
          "id_branch": userCredentials && userCredentials["idBranch"],
          "document_request_method": "search",
          "search_fields": search_filter ? search_filter : [],
          "page_size": numRecord ? numRecord : 10,
          "page_index": pageIndex,
          "order_fields": []
      }
      const body = JSON.stringify(transaction);
      let token = sessionStorage.getItem("token")
      console.log(token,"token-------");
      const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`

          }
      };
     await axios.post(basicUrl + '/document', body, config)
          .then(res => {
              try {
                  if (res.status === 200) {
                      if (res.data["err_code"] === 0) {
                          setSearch_fields &&setSearch_fields(res?.data?.data?.search_fields)
                         setArrayResult(res?.data?.data)
                   
                      }
                      else {
                          setDialogTitle&& setDialogTitle(res.data?.ui_message?.ui_message_header)
                          setMessageError&&setMessageError(res.data?.ui_message?.ui_message)
                          setServerMessageError&&setServerMessageError(true)
                          console.log(res.data?.err_description)
                      }
                  }
              }
              catch (err) {
                  console.log('error in read the documents')
              }
          })
  }
  
  
  export const readCopycompletedDoc = (type: any, userCredentials: IUserCredentials | null, idEntity: any, idDocument: any) => async (dispatch: Function) => {
      const transaction = {
          "document_request_method": "read_copy",
          "id_branch": userCredentials && userCredentials["idBranch"],
          "id_initiator": userCredentials && userCredentials["idInitiator"],
        //   "document_type_id": type,
          "id_entity": idEntity,
          "id_client": userCredentials && userCredentials["idClient"],
          "id_document": idDocument
      }
      const body = JSON.stringify(transaction);
      let token = sessionStorage.getItem("token")
      console.log(token,"token-------");
      const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`

          }
      };
      axios.post(basicUrl + '/document', body, config)
          .then(res => {
              try {
                  if (res.status === 200) {
                      if (res.data["err_code"] === 0) {
                          dispatch({
                              type: SET_HTML_PAGE,
                              payload: res?.data?.data?.document_html
                          })
                      }
                  }
              }
              catch (err) {
                  console.log('error in create openTransactions',)
              }
          })
  }