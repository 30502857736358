import axios from "axios";

import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { basicUrl } from "../../shared/config";
import { SET_DATA_HOME_PAGE_ACCOUNT, SET_DATA_INVOICE } from "./types";
import { getOpenTransactions } from "./convertionActions"

export const accountData = (userCredentials: IUserCredentials | null, setSearch_fields: any, setRowData: any, pageIndex: any,
    search_filter: any, numRecord: any, type: any, whereFrom?: any) => async (dispatch: Function) => {
        const obj = {
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "id_client": userCredentials && userCredentials["idClient"],
            "id_branch": userCredentials && userCredentials["idBranch"],
            "account_request_method": type,
            "search_fields": search_filter,
            "page_size": numRecord,
            "page_index": pageIndex
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };
        const body = JSON.stringify(obj)
        await axios.post(basicUrl + '/account', body, config)
            .then(res => {
                debugger
                try {
                    if (res.status === 200) {
                        if (whereFrom === 'searchAccount') {
                            setRowData(res);
                        }
                        else {
                            setRowData(res?.data);
                            setSearch_fields(res?.data?.search_fields)
                        }
                    }
                }
                catch (err) {
                    console.log('error in getDataAfterFilter', err)
                }
            })
    }

export const homePageAccountData = (userCredentials: IUserCredentials | null) =>
    async (dispatch: Function) => {
        const obj = {
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "id_client": userCredentials && userCredentials["idClient"],
            "id_branch": userCredentials && userCredentials["idBranch"],
            "account_request_method": "home_page",
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                
            }
        };
        const body = JSON.stringify(obj)
        await axios.post(basicUrl + '/account', body, config)
            .then(async res => {
                
                try {
                    if (res.status === 200) {
                        await dispatch({
                            type: SET_DATA_HOME_PAGE_ACCOUNT,
                            payload: res?.data?.data?.search_results
                        })
                    }
                }
                catch (err) {
                    console.log('error in getDataAfterFilter', err)
                }
            })
    }





export const actionNewAccount = (userCredentials: IUserCredentials | null, setRowData: any,
    newAccount: any, rowData: any, type: any, search_fields: any, setErrServerMsg: any,
    setTitleErrServer: any, setErrServer: any, pageSize?:number, currentPage?:number ) => async (dispatch: Function) => {
        const obj = {
            "account_type_id": newAccount?.account_type_id,
            "account_group_id": 'account',
            "account_name": newAccount?.account_name,
            "account_number": newAccount?.account_number,
            "status_id": newAccount?.status_id,
            "note": newAccount.note ? newAccount?.note : '',
            'external_number': newAccount?.external_number ? newAccount.external_number : '',
            "balance_group_id": newAccount?.balance_group_id,
            "is_default": newAccount?.is_default ? 1 : 0,
            "is_in_home_page": newAccount?.is_in_home_page ? 1 : 0,
            "balance_sub_group_id": newAccount?.balance_sub_group_id,
            "contra_account_group_id": newAccount?.contra_account_group_id ? newAccount.contra_account_group_id : '',
            "id_account": newAccount?.id_account ? newAccount?.id_account : "",
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "id_client": userCredentials && userCredentials["idClient"],
            "id_branch": userCredentials && userCredentials["idBranch"],
            "id_entity": userCredentials && userCredentials["idBranch"],
            "account_request_method": type
            
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };
        const body = JSON.stringify(obj)
        axios.post(basicUrl + '/account', body, config)
            .then(async res => {
                try {
                    if (res.status === 200 && res.data.err_code === 0) {
                        await dispatch(accountData(userCredentials, undefined, setRowData, currentPage, search_fields, pageSize, "search"))
                        setErrServerMsg("success")
                    } else {
                        setErrServer(true)
                        setErrServerMsg(res?.data?.ui_message?.ui_message)
                        setTitleErrServer(res?.data?.ui_message?.ui_message_header)
                    }
                }
                catch (err: any) {
                    setErrServer(true)
                    setErrServerMsg('הנתונים לא נשמרו')
                    setTitleErrServer('שגיאה')
                }
            })
    }

export const receiveLogOrder = (logOrder: any, type: any, userCredentials: IUserCredentials | null,
    setServerMessageError: any, setMessageError: any, setTitleMessageError: any,
    setShowSpiner: any, t?: any) => async (dispatch: Function) => {
        const logOrderObj = {
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "id_client": userCredentials && userCredentials["idClient"],
            "id_branch": userCredentials && userCredentials["idBranch"],
            "date_value": logOrder?.date_value,
            "journal_request_method": type,
            "reference_1": logOrder?.reference_1,
            "reference_2": logOrder?.reference_2,
            "details": logOrder?.details,
            "total_amount_ils": logOrder?.total_amount_ils,
            "orders": logOrder?.orders
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };
        const body = JSON.stringify(logOrderObj)
        axios.post(basicUrl + '/journal_entry', body, config)
            .then(res => {
                try {
                    if (res.status === 200 && res.data.err_code === 0) {
                        setShowSpiner(false)
                        setServerMessageError(true)
                        setTitleMessageError(t("save"))
                        setMessageError(t("actionCompletedSuccessfullyMassege"))
                    } else {
                        setShowSpiner(false)
                        setTitleMessageError && setTitleMessageError(res.data?.ui_message?.ui_message_header)
                        setMessageError && setMessageError(res.data?.ui_message?.ui_message)
                        setServerMessageError && setServerMessageError(true)
                        console.log(res.data?.err_description)
                    }
                }
                catch (err: any) {
                    setShowSpiner(false)

                    console.log(res.data?.err_description);
                    setTitleMessageError && setTitleMessageError(res.data?.ui_message?.ui_message_header)
                    setMessageError && setMessageError(res.data?.ui_message?.ui_message)
                    setServerMessageError && setServerMessageError(true)
                }
            })
    }


export const invoiceTransaction = (type: string, typeAction: any, entityName: any, action: any,
    userCredentials: IUserCredentials | null, idEntity: any, t?: any
    , setshowSpiner?: any, setServerMessageError?: any, setMessageError?: any,
    setTitleMessageError?: any, setSelectConversionList?: any, setExsistChanges?: any,
    setSaveDataFailed?: any, setSaveDatasuccess?: any) => async (dispatch: Function) => {

        const constObject = {
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "id_client": userCredentials && userCredentials["idClient"],
            "id_branch": userCredentials && userCredentials["idBranch"],
            "id_entity": idEntity,
            "entity_name": entityName,
            "id_account": action?.id_account,
            "id_account_income": action?.id_account_income,
            "id_transaction": action?.id_transaction,
            "documentation": action?.documentation,
            "transaction_document": {
                "document_request_method": action?.transaction_document?.document_request_method
            },
            "date_value": action?.date_value,
            "note": action?.note,
            "transaction_type_id": typeAction,
            "transaction_request_method": type,
            "items": action?.items,
            "sub_total": action?.sub_total,
            "vat_rate": action?.vat_rate,
            "vat_amount": action?.vat_amount,
            "sub_total_amount": action?.sub_total_amount,
            "amount_round": action?.amount_round,
            "total_amount_ils": action?.total_amount_ils
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const body = JSON.stringify(constObject);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };

        await axios.post(basicUrl + '/transaction', body, config)
            .then(async res => {
                try {
                    if (res.status === 200) {
                        if (res.data["err_code"] === 0) {
                            if (type === "complete" || type === "create")
                                await getOpenTransactions(typeAction, userCredentials,
                                    setSelectConversionList)

                            await dispatch({
                                type: SET_DATA_INVOICE,
                                payload: res.data.data
                            })
                            setshowSpiner && setshowSpiner(false)
                            setSaveDatasuccess && setSaveDatasuccess(true)
                            setServerMessageError && setServerMessageError(true)
                            setMessageError && setMessageError("actionCompletedSuccessfullyMassege")
                            setTitleMessageError && setTitleMessageError(t("note"))
                            setExsistChanges && setExsistChanges(true)
                            setSaveDataFailed && setSaveDataFailed(false)

                        }
                        else {
                            setSaveDataFailed && setSaveDataFailed(true)
                            setshowSpiner && setshowSpiner(false)
                            setTitleMessageError && setTitleMessageError(res.data?.ui_message?.ui_message_header)
                            setMessageError && setMessageError(res.data?.ui_message?.ui_message)
                            setServerMessageError && setServerMessageError(true)
                            console.log(res.data?.err_description)

                        }
                    }
                }
                catch (err) {
                    setSaveDataFailed && setSaveDataFailed(true)
                    setshowSpiner && setshowSpiner(false)
                    console.log('error in create exchangeObject', err)
                }
            })

    }

export const receiptTransaction = (type: string, typeAction: any, entityName: any, action: any,
    userCredentials: IUserCredentials | null, idEntity: any,
    t?: any, setshowSpiner?: any, setServerMessageError?: any, setMessageError?: any,
    setTitleMessageError?: any, setSelectConversionList?: any, setExsistChanges?: any,
    setSaveDataFailed?: any, setSaveDatasuccess?: any) => async (dispatch: Function) => {

        const constObject = {
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "id_client": userCredentials && userCredentials["idClient"],
            "id_branch": userCredentials && userCredentials["idBranch"],
            "id_entity": idEntity,
            "id_account": action?.id_account,
            "id_transaction": action?.id_transaction,
            "transaction_document": {
                "document_request_method": action?.transaction_document?.document_request_method
            },
            "transaction_type_id": typeAction,
            "transaction_request_method": type,
            "financial_assets_in": action?.financial_assets_in,
            "total_amount_ils": action?.total_amount_ils
        }
        const body = JSON.stringify(constObject);
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };

        await axios.post(basicUrl + '/transaction', body, config)
            .then(async res => {
                try {
                    if (res.status === 200) {
                        if (res.data["err_code"] === 0) {
                            if (type === "complete" || type === "create")
                                await getOpenTransactions(typeAction, userCredentials, setSelectConversionList)
                            await dispatch({
                                type: SET_DATA_INVOICE,
                                payload: res.data.data
                            })
                            setshowSpiner && setshowSpiner(false)
                            setSaveDatasuccess && setSaveDatasuccess(true)
                            setServerMessageError && setServerMessageError(true)
                            setMessageError && setMessageError("actionCompletedSuccessfullyMassege")
                            setTitleMessageError && setTitleMessageError(t("note"))
                            setExsistChanges && setExsistChanges(true)
                            setSaveDataFailed && setSaveDataFailed(false)

                        }
                        else {
                            setSaveDataFailed && setSaveDataFailed(true)
                            setshowSpiner && setshowSpiner(false)
                            setTitleMessageError && setTitleMessageError(res.data?.ui_message?.ui_message_header)
                            setMessageError && setMessageError(res.data?.ui_message?.ui_message)
                            setServerMessageError && setServerMessageError(true)
                            console.log(res.data?.err_description)
                        }
                    }
                }
                catch (err) {
                    setSaveDataFailed && setSaveDataFailed(true)
                    setshowSpiner && setshowSpiner(false)
                    console.log('error in create exchangeObject', err)
                }
            })

    }




