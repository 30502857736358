import { useState } from 'react';
import './login.scss';
import { connect, useSelector } from 'react-redux';
import { TextField, Label} from '@fluentui/react/lib';
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import { initializeIcons, PrimaryButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { login } from '../../store/actions/authActions';
import { IAuthReduxProps, ILoginModal } from '../../modelsType/type/interface';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';

initializeIcons();

const LoginClient = ({
    isAuthenticated,
    error,
     login
     
     
    // clearErrors
}: ILoginModal) => {

    class User {
        LoginEntityNumber: string;
        LoginPassword: string;
        LoginID: string;
        LoginFingerPrint: string;
        constructor(loginEntityNumber = "", loginPassword = "", loginID = "", loginFingerPrint = "") {
            this.LoginEntityNumber = loginEntityNumber;
            this.LoginPassword = loginPassword;
            this.LoginID = loginID;
            this.LoginFingerPrint = loginFingerPrint
        }
    }
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [isClickLogin, setIsClickLogin] = useState(false)
    const [showDialogSave, setShowDialogSave] = useState(false)
    const [userLogin, setUserLogin] = useState(new User(''));
    const [showSpiner, setshowSpiner] = useState(false)
    const updateUserLogin = (key: string, value: any) => {
        let newUser = { ...userLogin };
        (newUser as any)[key] = value;
        setUserLogin(newUser);
    }
    const spinnerStyles: Partial<ISpinnerStyles> = {
        root:{
            position:'fixed'  ,
            zIndex:'999999',
            fontWeight:'bold', 
        },
        circle: {
         color:'#FFB400 ',
         height:'100px',
         width:'100px',       
        },
    }
     function LoginTime(event: any) {
        setshowSpiner(true)
        setIsClickLogin(true);
        // login(userLogin)
        login(userLogin,setShowDialogSave,setshowSpiner)
    
        // setShowDialogSave(true)
        event.preventDefault();
    }
    return (
        <div className="grid-container-login" >
            { showDialogSave&& <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"notLogin"} />}
    {showSpiner && <Spinner styles={spinnerStyles}  className={`spinner-${dir}`}/>}
            
            <form className="login" onSubmit={(e) => LoginTime(e)}>
                <Label className="label-login" style={{color:"#1A7128"}} htmlFor='login_entity_number'>{t("business")}</Label>
                <TextField
                    id='login_entity_number'
                    className="text-field-login"
                    required
                    placeholder={t("business")}
                    onChange={(e: any) => {
                        updateUserLogin('LoginEntityNumber', e.currentTarget.value)
                    }}
                    underlined
                />
                <Label className="label-login" style={{color:"#1A7128"}} htmlFor='login_ID'>{t("login.userName")}</Label>
                <TextField
                    id='login_ID'
                    className="text-field-login"
                    required
                    placeholder={t("login.userName")}
                    onChange={(e: any) => {
                        updateUserLogin('LoginID', e.currentTarget.value)
                    }}
                    underlined
                />
                <Label className="label-login" style={{color:"#1A7128"}} htmlFor='login_password'>{t("login.password")}</Label>
                <TextField
                    id='login_password'
                    className="text-field-login"
                    type="password"
                    placeholder={t("login.password")}
                    required
                    canRevealPassword
                    revealPasswordAriaLabel="Show password"
                    onChange={(e: any) => {
                        updateUserLogin('LoginPassword', e.currentTarget.value)
                    }}
                    underlined
                />
                <Label className="label-login fontStyle" htmlFor='login_password'>{t("login.forgotPassword")}</Label>

                {/* disabled={isClickLogin}  */}
                <PrimaryButton className='button btn-login' checked={false} text={t('login.sigin')} type="submit" />
                <PrimaryButton className='button btn-login' checked={false} text={t('login.signout')} />

            </form>





        </div >
    );
};
const mapStateToProps = (state: IAuthReduxProps) => ({
    isAuthenticated: state.authReducer.isAuthenticated,
    error: state.error,
});


export default connect(mapStateToProps, { login })(LoginClient);
