import { IIconProps } from '@fluentui/react';

export class Icons {
  static readonly add: IIconProps = { iconName: 'Add' };
  static readonly addFriend: IIconProps = { iconName: 'AddFriend' }
  static readonly editContact: IIconProps = { iconName: 'EditContact' };
  static readonly userRemove: IIconProps = { iconName: 'UserRemove' }
  static readonly calendar: IIconProps = { iconName: 'Calendar' };
  static readonly cloudUpload: IIconProps = { iconName: 'CloudUpload' };
  static readonly cloudDownload: IIconProps = { iconName: 'CloudDownload' };
  static readonly pdf: IIconProps = { iconName: 'PDF' };
  static readonly print: IIconProps = { iconName: 'Print' };
  static readonly search: IIconProps = { iconName: 'Search' };
  static readonly redEye: IIconProps = { iconName: 'RedEye' };
  static readonly articles: IIconProps = { iconName: 'Articles' };
  static readonly u: IIconProps = { iconName: 'grillHot' };

}

