import axios from "axios";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { basicUrl } from "../../shared/config";
import { isFullyIdentified } from "../../shared/components/CustomHooks/GlobalFunctions";

let currUserCredentials: IUserCredentials | null = null

export const updateDocument = (documents: any, userCredentials: IUserCredentials | null, idEntity: string, setShowDialogSave?: any,
    setShowDialogFailed?: any, setShowSpiner?: any, idOtherEntity?: any, actionType?: any) => async (dispatch: Function) => {
        currUserCredentials = userCredentials
        const document = {
            "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
            "id_client": currUserCredentials && currUserCredentials["idClient"],
            "id_branch": currUserCredentials && currUserCredentials["idBranch"],
            "id_entity": idOtherEntity ? idOtherEntity : idEntity,
            "return_entity": 1,
            "attribute_request_method": "update",
            "attribute": "documentation",
            "entity_type_id": "customer",
            "documentation": documents,
            "is_fully_identified": isFullyIdentified(documents)
        }
        const body = JSON.stringify(document);
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };
        await axios.post(basicUrl + '/entity', body, config)
            .then(async res => {
                try {
                    if (res.status === 200) {
                        if (res.data["err_code"] === 0) {
                            await dispatch({
                                type: actionType,
                                payload: res.data.data
                            })
                        }
                        setShowSpiner && setShowSpiner(false)
                        setShowDialogSave && setShowDialogSave(true)

                    }
                    else {
                        setShowSpiner && setShowSpiner(false)
                        setShowDialogFailed && setShowDialogFailed(true)
                    }

                }
                catch (err) {
                    setShowDialogFailed && setShowDialogFailed(true)
                    setShowSpiner && setShowSpiner(false)
                    console.log('error in create document', err)
                }
            })
    }


