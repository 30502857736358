
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const GenericPdfDownloader = (props: any) => {
    const { rootElementId, downloadFileName, setFile } = props

    let input: any = document.getElementById(rootElementId);
    
    if (input !== null)
    html2canvas(input)
        .then(async (canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF("p", "mm", "a4");
            pdf.addImage(imgData, 'JPEG', 15, 40, 180, 220);
            if (setFile) {
                let blob = pdf.output('blob');
                let file = new File([blob], downloadFileName, { type: "application/pdf" })
                setFile(file)
            }
            else
                pdf.save(`${downloadFileName}.pdf`);
        })
}

export default GenericPdfDownloader;








