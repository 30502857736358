export const USERֹֹ_LOADED = 'USERֹֹ_LOADED';
export const USERֹֹ_LOADING = 'USERֹֹ_LOADING';
export const AUTH_ERROR = "AUTH ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS"
export const CLEAR_ERRORS = "CLEAR_ERRORS"
export const FETCH_PROTECTED_DATA_REQUEST = "FETCH_PROTECTED_DATA_REQUEST";
export const RECEIVE_PROTECTED_DATA = "FETCH_PROTECTED_DATA_REQUEST";
export const SET_IS_FETCHING = "SET_IS_FETCHING"
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const READ_CUSTOMER = "READ_CUSTOMER";
export const READ_FAIL = "READ_FAIL";
export const CREATE_FAIL = "CREATE_FAIL";
export const SET_ALL_ENUMS = "SET_ALL_ENUMS"
export const LOGOUT = "LOGOUT"
export const SET_SETTINGS = "SET_SETTINGS"
export const SET_USER_SETTINGS = "SET_USER_SETTINGS"
export const SET_MAIN_ACCOUNTS = "SET_MAIN_ACCOUNTS"
export const SET_EMAIL_SETTING = "SET_EMAIL_SETTING"
export const SET_FAIR_CREDIT = "SET_FAIR_CREDIT"
export const SET_PRICE_PROGRAMS_TEMPLATES = "SET_PRICE_PROGRAMS_TEMPLATES"
export const SET_AUTH_DATA = "SET_AUTH_DATA"
export const SET_DATA_CUSTOMER = "SET_DATA_CUSTOMER"
export const SET_ENUMS = "SET_ENUMS"
export const SET_LANGUAGE= "SET_LANGUAGE"
export const SET_USERS = "SET_USERS"
export const SET_USER = "SET_USER"
export const SET_CONTRA_ACCOUNTS = "SET_CONTRA_ACCOUNTS"
export const SET_DATA_CONVERSION = "SET_DATA_CONVERSION"
export const RESET_DATA_CONVERSION = "RESET_DATA_CONVERSION"
export const SET_GATES_DATA = "SET_GATES_DATA"
export const SAVE_DRAFT_CONVERSION_CHANGES = "SAVE_DRAFT_CONVERSION_CHANGES"
export const RESET_CUSTOMER_OBJECT = "RESET_CUSTOMER_OBJECT"
export const SET_INSIDER_DATA_CONVERSION = "SET_INSIDER_DATA_CONVERSION"
export const SET_CUSTOMER_INSIDER = "SET_CUSTOMER_INSIDER"
export const SET_DATA_INVOICE = "SET_DATA_INVOICE"
export const RESET_DATA_INVOICE = "RESET_DATA_INVOICE"
export const SET_HTML_PAGE = "SET_HTML_PAGE"
export const RESET_HTML_PAGE = "RESET_HTML_PAGE"
export const CHANGED_LOCATION = "CHANGED_LOCATION"
export const RESET_CHANGED_LOCATION = " RESET_CHANGED_LOCATION"
export const SET_DATA_NEW_TRANSACTION = " SET_DATA_NEW_TRANSACTION"
export const SET_DATA_HOME_PAGE_ACCOUNT = " SET_DATA_HOME_PAGE_ACCOUNT"
export const SET_CHEQUE_MANAGMENT_EVENT_DATA = " SET_CHEQUE_MANAGMENT_EVENT_DATA"
export const RESET_CHEQUE_MANAGMENT_EVENT_DATA = " RESET_CHEQUE_MANAGMENT_EVENT_DATA"
export const SET_ACCOUNTS_TRANSFERS = " SET_ACCOUNTS_TRANSFERS"
export const SET_MODULES_USER_BRANCH = " SET_MODULES_USER_BRANCH"
export const SET_DATA_RESULTS_UN_REGULAR_REPORTS = " SET_DATA_RESULTS_UN_REGULAR_REPORTS"
export const SET_DATA_OF_FIELDS_UN_REGULAR_REPORTS = " SET_DATA_OF_FIELDS_UN_REGULAR_REPORTS"
export const RESET_DATA_RESULTS_UN_REGULAR_REPORTS = " RESET_DATA_RESULTS_UN_REGULAR_REPORTS"
