import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils'
import Subtitle from '../../shared/components/Subtitle'
import { Label } from '@fluentui/react/lib/Label';
import DialogSearch from '../global/DialogSearch';
import { useEffect, useState } from 'react';
import { IUserCredentials } from '../customerDetails/CustomerDetails';
import { useDispatch, useSelector } from 'react-redux'
import { createAccount, saveAccount } from '../../store/actions/settingsAction';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import AccountsAddAcountFirstDialog from '../accounts/AccountsAddAcountFirstDialog';
import { PrimaryButton } from 'office-ui-fabric-react';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions';


export default function BookKeeping() {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [errServerMsg, setErrServerMsg] = useState<any>('');
    const [titleMassage, setTitleMassage] = useState<any>('');
    const [showDialogFailed, setShowDialogFailed] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [search_fields, setSearch_fields] = useState<any>([]);
    const [showDialogAddFirst, setShowDialogAddFirst] = useState(false)
    const [arrFormat, setArrFormat] = useState<any>({});
    const [detailsFormat, setDetailsFormat] = useState<any>(['', '', '', '', '', '', '', '', '', '']);
    const [accountDetails, setAccountDetails] = useState<any>(['', '', '', '', '', '', '', '', '', '']);
    const [chosenAction, setChosenAction] = useState<any>(['', '', '', '', '', '', '', '', '', ''])
    const [arrToSend, setArrToSend] = useState<any>()
    const [arrTitles, setArrTitle] = useState<any>()
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    const [accountDetailsBeforeChanges, setAccountDetailsBeforeChanges] = useState<any>();


    const dispatch = useDispatch()

    useEffect(() => {
        errServerMsg && setShowDialogFailed(true)
    }, [errServerMsg])



    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])

    useEffect(() => {
        if (authReducer?.data?.branch?.main_accounts) {
            let arrDetails: any = [], arrDetailsId: any = [], arrTitle: any = [], arrToSend: any = []
            authReducer?.data?.branch?.main_accounts?.forEach((item: any, i: number) => {
                arrDetails.push(item?.account_name + " " + item?.account_number)
                arrDetailsId[item.account_code] = item?.id_account
                arrTitle.push(item?.account_name)
                arrToSend.push(item?.account_code)
            })
            setArrToSend(arrToSend)
            setArrTitle(arrTitle)
            setDetailsFormat(arrDetails)
            setArrFormat(arrDetailsId)
            setAccountDetails(authReducer?.data?.branch?.main_accounts)
            setAccountDetailsBeforeChanges(authReducer?.data?.branch?.main_accounts)
        }
    }, [authReducer?.data?.branch?.main_accounts])

    const chooseAction = (id: any, value: any, index: any, searchList: any, place: any) => {
        if (arrToSend[index] === place && !readOnly) {
            let b = chosenAction
            b[index] = value.innerHTML
            setChosenAction([...b])
            let a: any = [...accountDetails]
            let nameAccount = value.innerHTML.split(",")[1]
            nameAccount = nameAccount.replace(/ /g, '')
            let detail = searchList.data.search_results?.find((x: any) => x.account_number === nameAccount)
            a[index] = {
                "order": (index + 1),
                "account_name": detail?.account_name,
                "account_code": arrToSend[index],
                "balance_group_id": detail?.balance_group_id,
                "id_account": detail?.id_account,
                "account_number": detail?.account_number
            }
            setAccountDetails(a)
            let c = detailsFormat
            /////להוריד לפני השליחה+" "+ a[index].id_account
            c[index] = a[index].account_name + " " + a[index].account_number
            setDetailsFormat(c)
            setArrFormat({ ...arrFormat, [place]: a[index].id_account })
        }
    }

    const save = async (istrue: boolean) => {
        if (userCredentials) {
            await dispatch(saveAccount(accountDetails, userCredentials, setErrServerMsg, setTitleMassage))
            setChosenAction(['', '', '', '', '', '', '', '', '', ''])
            setReadOnly(istrue)
        }
    }
    const create = () => {
        if (userCredentials) {
            dispatch(createAccount(userCredentials, setErrServerMsg))
        }
    }

    const cancelEdit = async () => {
        let arrDetails: any = [], arrDetailsId: any = []
        accountDetailsBeforeChanges?.forEach((item: any, i: number) => {
            arrDetails.push(item?.account_name + " " + item?.account_number)
            arrDetailsId[item.account_code] = item?.id_account
        })
        setDetailsFormat(arrDetails)
        setArrFormat(arrDetailsId)
        setChosenAction(['', '', '', '', '', '', '', '', '', ''])
        setAccountDetails(cloneDeep(accountDetailsBeforeChanges))
        setReadOnly(true)
    }

    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
        ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={t("bookkeeping")} subTitle={"false"} createBtn={false} createFunc={() => { create() }} saveFunc={() => save(true)}
                deleteBtn={false} uploadBtn={readOnly ? false : true} editBtn={readOnly ? true : false} editFunc={() => setReadOnly(false)}
                cancelFunc={() => cancelEdit()} disabledSave={readOnly ? true : false} createXl={false} createPrint={false} createPDF={false} />
            <br/>
            <br/>
            <Subtitle title={t("mainAccount")} />
            {showDialogAddFirst && <AccountsAddAcountFirstDialog setShowDialog={setShowDialogAddFirst} />}
            {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} subText={errServerMsg}
                title={titleMassage} setshowModalDelete={setShowDialogFailed} dialogType={"dialogMessage"} />}
            <div className={`content-div-${dir}`} >
                <p style={{ color: "red", fontSize: "14px" }}>{t("mainAccountsCreated")}</p>
                <div style={{ width: "100%" }}>
                    <div className="titleC" style={{ width: "76%", justifyContent: "space-between" }}>
                        <p className="title-text boldC" style={{ width: "20%" }}>{t("accountType")}</p>
                        <p className="title-text boldC" style={{ width: "30%" }}>{t("accountName")}</p>
                        <p className="title-text boldC" style={{ width: "44%" }} >{t("search")}</p>
                    </div>
                </div>
                <hr className="hr" style={{ width: "55%", height: "2px", marginBottom: "0px" }} ></hr>
                {arrTitles?.map((item: any, key: any) => (
                    <div key={key + 1}>
                        <div style={{ width: "76%" }}>
                            <div className={`inline-bk-${dir}`} style={{ display: "flex", alignItems: "center", paddingBottom: "0px" }}>
                                <Label className="bold-bk" style={{ width: "20%" }} >{t(arrToSend[key])}</Label>
                                <div style={{
                                    alignItems: "center", display: "flex", width: '30%',
                                    minHeight: '32px'
                                }}>
                                    {/* <p style={{ width: "43%" }} ></p> */}
                                    {detailsFormat[key] ? detailsFormat[key] : ""}
                                </div>
                                <div style={{ width: "44%" }}>
                                    {/* +1 */}
                                    <DialogSearch index={key} arr={true} place={arrToSend[key]}
                                     chosenAction={chosenAction[key]} type='searchAccount' 
                                     setSearch_fields={setSearch_fields} userCredentials={userCredentials}
                                      onChooseFunc={chooseAction} />
                                </div>
                            </div>
                        </div>
                        <hr className="hr" style={{ width: "55%", marginBottom: '8px' }} ></hr>
                    </div>
                ))}
                <PrimaryButton ariaDescription="create" allowDisabledFocus checked={false}
                    name="create" text={t("addPredefinedAccounts")} onClick={() => {
                        setShowDialogAddFirst(true)
                    }} />
            </div>
        </div>
    )
}
