import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

import Subtitle from '../../shared/components/Subtitle';
import { useTranslation } from "react-i18next";
import { IUserCredentials } from '../customerDetails/CustomerDetails';
import { invoiceTransaction } from '../../store/actions/accountAction'
import InvoiceTable from './InvoiceTable';
import { TextFeildNote } from '../../shared/components/Note';
import { PrintHtml } from '../../shared/components/CustomHooks/PrintHtml';
import EntityInvoiceDetails from './EntityAccountDetails';
import { deleteTransaction } from '../../store/actions/convertionActions';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import { RESET_CUSTOMER_OBJECT, RESET_DATA_INVOICE } from '../../store/actions/types';
import { useHistory, useLocation } from 'react-router-dom';
import { checkPermissionLevelOfPage, customerInfoInObject } from '../../shared/components/CustomHooks/GlobalFunctions';
import { CustomPrompt } from '../../shared/components/CustomHooks/CustomPrompt';
import { CustomerDate } from '../../shared/components/CustomHooks/concatTextFields';
import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils';

const initialInvoice = {
    sub_total: 0, amount_round: 0, transaction_document: { document_request_method: null },
    vat_rate: 0, vat_amount: 0, total_amount_ils: 0,
    sub_total_amount: 0, note: "", date_value: moment().format("YYYY-MM-DD"),
    id_entity: "", id_account: "", id_account_income: "",
    entity_name: "", id_transaction: null, documentation: [],
    items: [{
        currency_code: "ILS",
        row_number: 1,
        description: "",
        quantity: 1,
        amount: 0,
        exchange_rate_ils: 1,
        amount_ils: 0
    }]
}

export default function Invoice() {

    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const dataAccount = useSelector(({ accountReducer }: any) => accountReducer?.dataAccount)
    const saveDraft = useSelector(({ conversionReducer }: any) => conversionReducer.saveDraftChanges)

    const vatClassificationId = authReducer?.data?.branch?.setting?.vat_classification_id
    const vatRateSetting = authReducer?.data?.branch?.setting?.vat_rate
    const [selectedDraft, setSelectedDraft] = useState("")
    const [isValidDateCreate, setIsValidDateCreate] = useState()
    const [emptyAmount, setEmptyAmount] = useState(false)
    const [beforeDelete, setbeforeDelete] = useState(false)
    const [titleMessageError, setTitleMessageError] = useState("")
    const [messageError, setMessageError] = useState("")
    const [serverMessageError, setServerMessageError] = useState(false)
    const [showSpiner, setShowSpiner] = useState(false)
    const [ifChanges, setIfChanges] = useState(false)
    const [invoiceCustomer, setInvoiceCustomer] = useState<any>()
    const [selectConversionList, setSelectConversionList] = useState<any>([])
    const [readOnly, setReadOnly] = useState<any>(true)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    const { state } = useLocation<any>()
    let componentRef = useRef(null);
    const history = useHistory<any>()
    const [ifDelete, setifDelete] = useState(false)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [action, setAction] = useState<any>(initialInvoice)

    const spinnerStyles: Partial<ISpinnerStyles> = {
        root: {
            position: 'fixed',
            zIndex: '999999',
            fontWeight: 'bold',
        },
        circle: {
            color: '#FFB400 ',
            height: '100px',
            width: '100px',
        },
    }

    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
            if (!dataAccount?.id_transaction) {
                setAction({ ...action, "vat_rate": vatClassificationId === "2" ? Number(vatRateSetting) : 0 })
            }
        }
    }, [authReducer])

    useEffect(() => {
        const reset = async () => {
            if (dataAccount?.status_id === "deleted" || (dataAccount?.status_id === "completed")) {
                await resetPageData()
            }
        }
        reset()
    }, [dataAccount?.status_id])


    useEffect(() => {
        if (dataAccount?.transaction_document?.document_html && selectedDraft) {
            document?.getElementById("print")?.click();
            let copydataAccount = { ...dataAccount }
            let printDocument: any = copydataAccount["transaction_document"]
            printDocument = { document_request_method: null }
            setAction({ ...action, "transaction_document": printDocument })
        }
    }, [dataAccount?.transaction_document])

    useEffect(() => {
        if (dataAccount) {
            const { id_transaction } = dataAccount
            if (action.id_transaction === null && dataAccount.transaction_request_method === "create") {
                setSelectedDraft(id_transaction + ',' + action?.id_entity)
                setAction({ ...action, "id_transaction": id_transaction })
            }
            if (selectedDraft !== "" || state === "showCompleted") {
                setDraftInvoiceData()
            }
        }
    }, [dataAccount])

    useEffect(() => {
        if (dataAccount && state?.source === "refresh") {
            history.location.state = {}
            setSelectedDraft(dataAccount?.id_transaction + ',' + dataAccount?.id_entity)
            setDraftInvoiceData()
        }
    }, [dataAccount, state])

    const setDraftItems = () => {
        let items: any = []
        if (dataAccount?.status_id !== "deleted") {
            let copyAction: any = { ...action }
            dataAccount["items"]?.length && dataAccount["items"].forEach((item: any) => {
                items.push({
                    description: item?.description,
                    quantity: item?.quantity,
                    amount: item?.amount,
                    amount_ils: item?.amount_ils,
                    row_number: item?.row_number,
                    currency_code: item?.currency_code,
                    exchange_rate_ils: item?.exchange_rate_ils,
                })
            })
            copyAction.items = items
            copyAction.date_value = dataAccount?.date_value
            copyAction.note = dataAccount?.note
            copyAction.id_transaction = dataAccount?.id_transaction
            copyAction.id_entity = dataAccount?.id_entity
            copyAction.id_account = dataAccount?.id_account
            copyAction.id_account_income = dataAccount?.id_account_income
            copyAction.total_amount_ils = dataAccount?.total_amount_ils
            copyAction.sub_total = dataAccount?.sub_total
            copyAction.total_amount_ils = dataAccount?.total_amount_ils
            copyAction.vat_amount = dataAccount?.vat_amount ? dataAccount?.vat_amount : 0
            copyAction.sub_total_amount = dataAccount?.sub_total_amount
            copyAction.amount_round = dataAccount?.amount_round
            copyAction.vat_rate = dataAccount?.vat_rate ? dataAccount?.vat_rate : 0
            setAction(copyAction)
        }
    }

    const setDraftInvoiceData = () => {
        let customerInfo = dataAccount?.entity && customerInfoInObject(dataAccount?.entity)
        setInvoiceCustomer(customerInfo)
        setDraftItems()
    }

    useEffect(() => {
        async function deleteFunc() {
            if (ifDelete && dataAccount?.status_id === "draft") {
                await dispatch(deleteTransaction("delete", "invoice", action, userCredentials, action.id_entity, setSelectConversionList))
                // , setShowDialogSave, "", setshowSpiner
                // dispatch(deleteTransaction("delete", "cheque_cashing", discounts, userCredentials, discounts.id_entity,setshowSpiner,setServerMessageError, setMessageError, setSelectConversionList ))
                setifDelete(false)
                dispatch({
                    type: RESET_CUSTOMER_OBJECT,
                })
            }
        }
        deleteFunc()
    }, [ifDelete])

    const deleteDeal = async () => {
        if (dataAccount?.status_id === "draft") {
            setbeforeDelete(true)
            setServerMessageError(true)
            setMessageError(t("cancelproductionReceipt"))
            setTitleMessageError(t("cancel"))
        }
        else if (dataAccount?.status_id === "completed") {
            await dispatch(deleteTransaction("cancel", "invoice", action, userCredentials, action.id_entity, setSelectConversionList))
            // , setShowDialogSave, "", setshowSpiner
        }
    }
    const resetPageData = async () => {

        let reset: any
        await dispatch({
            type: RESET_DATA_INVOICE,
            payload: null
        })
        setInvoiceCustomer(reset)
        setIfChanges(false)
        setSelectedDraft("")
        setReadOnly(true)
        setEmptyAmount(false)
        setAction(initialInvoice)
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const finishDeal = async () => {
        let emptyItem
        action?.items?.forEach((item: any) => {
            if (!item?.amount) {
                setEmptyAmount(true)
                emptyItem = true
            }
        });
        if (emptyItem) {
            setServerMessageError(true)
            setTitleMessageError(t("note"))
            setMessageError(t("mandatoryFieldsMassege"))
        }
        else {
            await dispatch(invoiceTransaction("complete", "invoice", invoiceCustomer.entityName,
                action, userCredentials, invoiceCustomer.idEntity, t,
                setShowSpiner, setServerMessageError, setMessageError,
                setTitleMessageError, setSelectConversionList))
            await dispatch({
                type: RESET_CUSTOMER_OBJECT,
            })
        }
    }

    return (
        <div className={`${checkPermissionLevelOfPage(credentials, location) === "RW"
            ? 'page-read' : 'page-read-only'}`}>
            <p id='print' className="button" onClick={handlePrint} />
            <div style={{ display: "none" }}>
                <div ref={componentRef} dangerouslySetInnerHTML={{ __html: dataAccount?.transaction_document?.document_html }} />
            </div>
            {showSpiner && <Spinner styles={spinnerStyles} />}
            {saveDraft?.changeLocation !== undefined && <CustomPrompt sumIn={action?.total_amount_ils} typeAction="invoice" entityName={invoiceCustomer?.entityName} action={action} ifChanges={ifChanges} saveDraft={saveDraft} subText={t('saveChanges')} userCredentials={userCredentials} resetFunc={resetPageData} />}
            {serverMessageError && <DialogMessages setansDelete={setServerMessageError} subText={messageError} title={titleMessageError}
                setshowModalDelete={setServerMessageError} dialogType={"dialogMessage"} setifDelete={beforeDelete === true ? setifDelete : undefined} />}
            {/* {beforeDelete && <DialogMessages setansDelete={setbeforeDelete} setifDelete={setifDelete} setshowModalDelete={setbeforeDelete} dialogType={"delete"} />} */}
            <div>
                {/* <div className={`sub-header-${dir} fixed-${dir}`} style={{ height: "60px" }}>
                    <Title
                        title={t("invoice")} />
                    <div className="divider"></div><div className="divider"></div>
                </div>
                <br />
                <br /> */}
                <HeaderUtils title={t("bookkeeping")} subTitle={"false"} disabledSave={true} createBtn={false}
                    saveFunc={""} editFunc={false} cancelFunc={""} />
                <br/><br/>
                <Subtitle title={t('invoice')} />

                <div className={`content-div-${dir}`}  style={{ height: "100vh" }}  >
                    <div className="content-search" style={{marginTop:"2%"}} >
                        <EntityInvoiceDetails setSelectedDraft={setSelectedDraft} selectedDraft={selectedDraft}
                            typeAction="invoice" ifChanges={ifChanges} setIfChanges={setIfChanges} actions={action}
                            setActions={setAction} readOnly={readOnly} setReadOnly={setReadOnly} setSelectConversionList={setSelectConversionList}
                            selectConversionList={selectConversionList} setSearchCustomer={setInvoiceCustomer} searchCustomer={invoiceCustomer} />
                    </div>

                    <InvoiceTable setAction={setAction} action={action} setIfChanges={setIfChanges} readOnly={readOnly} emptyAmount={emptyAmount} />
                    <div className="wrapper-collateral">
                        <div className={`warpper-fields-left-${dir}`} style={{ width: "10%" }}>
                            <CustomerDate isValidDate={isValidDateCreate} setIsValidDate={setIsValidDateCreate} isDefaultDate={true} entity={action} setEntity={setAction} readOnly={readOnly} idDate="date_value" label={t("date")} source={""} />
                        </div>
                    </div>
                    <div >
                        <div style={{ width: "650px", marginTop: '4%' }}>

                            <div style={{ justifyContent: "space-between", display: "flex" }} >
                                <p className="title-text" style={{ marginTop: '0%' }}>{t('note')}</p>
                            </div>
                            <hr className="hr" ></hr>
                            <TextFeildNote label={t('')} onChange={(e: any, value: any) => { setIfChanges(true); setAction({ ...action, "note": value }) }} value={action?.note} id={'note'} />
                            {selectedDraft && dataAccount?.status_id === "draft" &&
                                <div style={{ width: "650px", display: "inline-grid", marginTop: "20px", justifyContent: "end", }}>
                                    <PrintHtml saveFunction={finishDeal} typeAction="invoice" conversionCustomer={invoiceCustomer} action={action} setAction={setAction} userCredentials={userCredentials} />
                                    <PrimaryButton className={`bottun-${dir}`} onClick={() => { deleteDeal(); }} style={{ width: "233px", marginTop: "4px", backgroundColor: "red", borderColor: "red" }} allowDisabledFocus disabled={false} checked={false} name="delete" text={t("cancel")} />
                                </div>}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
