import { useBoolean } from '@fluentui/react-hooks';
import { PrimaryButton, Dialog,ContextualMenu, DialogFooter, DefaultButton } from 'office-ui-fabric-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

import { CustomTextField } from '../../TextField';
import { createEndorseeInConvertions } from '../../../../store/actions/customerActioin'
import { convertArrToDropdropdown, IUserCredentials } from '../../../../components/customerDetails/CustomerDetails'
import { DialogMessages } from '../DialogMessages';
import { IdNumber } from '../../CustomHooks/concatTextFields'

import "../dialog.scss";

export const EndorseeDialog = (props: any) => {
  const { setShowDialog, setidEndorsee, setnewEndorsee, newEndorsee } = props
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [t] = useTranslation();
  const dispatch = useDispatch()
  const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
  const authReducer = useSelector(({ authReducer }: any) => authReducer)
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
  const [enumsDropdown, setenumsDropdown] = useState<any>({ "countryCode": [], "bank": [], "branch": [] })
  const [resultId, setResultId] = useState<any>(true)
  const [defaultIdTypeId, setDefaultIdTypeId] = useState()
  const [showDialogFailed, setShowDialogFailed] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [newEndorseeObj, setnewEndorseeObj] = useState(newEndorsee ? newEndorsee : {
    gender: 1,
    otherGender: "",
    entityStatusId: 0,
    idTypeCountryCode: "",
    firstName: '',
    note: "",
    lastName: '',
    isLocked: false,
    isPopupMessage: false,
    dateBirth: "",
    genderOther: "",
    middleName: "",
    otherCustomerStatus: "",
    classId: 1,
    entitySubTypeId: 1,
    ViewNoteWhenPerformingAction: false,
    industryId: 0,
    CreditGroup: 0,
    idAffiliate: 0,
    idTypeId: "",
    idNumber: "",
    idTypeOther: "",
    addressCountryCode: "",
    addressState: "",
    idIdentifier: 0,
    address: "",
    addressNumber: "",
    addressCity: "",
    iDCountryCode: "",
    telephone: "",
    telephoneCountryCode: "",
    email: "",
    entityNumber: "",
    addressZipCode: "",
    customerTypeId: "",
    workerName: "",
    genderName: "",
    telephoneTypeId: 1,
    telephoneId: 0,
    addressTypeId: 1,
    emailTypeId: 1,
    emailId: 0,
    telephoneDefault: 1,
    addressDefault: 1,
    emailDefault: 1,
    addressId: 0,
    login_entity_number: '',
    login_ID: '',
    login_password: ''
  })

  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
  };
const modelProps = {
    isBlocking: true,
    topOffsetFixed: true,
    dragOptions: dragOptions 
};

  useEffect(() => {
    if (enums) {
      let arr = {
        "countryCode": convertArrToDropdropdown([]),
        "typeIdentityNumbers": convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].customer !== undefined) {
          let customer = enums[i].customer;
          for (let j = 0; j < customer.length; j++) {
            if (customer[j].ID_type_id !== undefined)
              arr.typeIdentityNumbers = convertArrToDropdropdown(customer[j].ID_type_id)
          }
        }
        if (enums[i].general !== undefined) {
          let country = enums[i].general
          for (let i = 0; i < country.length; i++) {
            if (country[i].country_code !== undefined)
              arr.countryCode = convertArrToDropdropdown(country[i].country_code)
          }
        }

      }
      let defaultTypeIdValue: any;
      (defaultTypeIdValue = arr?.typeIdentityNumbers.find(({ is_default }: any) => is_default === true))
      setDefaultIdTypeId(defaultTypeIdValue?.key)
      let defaultCountryValue: any
      (defaultCountryValue = arr?.countryCode.find(({ is_default }: any) => is_default === true))
      setenumsDropdown(arr);
      setnewEndorseeObj({ ...newEndorseeObj, idTypeCountryCode: defaultCountryValue.key, idTypeId: defaultTypeIdValue.key })
    }
  }, [enums])


  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog()
    }
  }, [setShowDialog])
  useEffect(() => {
    setUserCredentials({
      "idInitiator": authReducer.data.user.id_entity,
      "idClient": authReducer.data.user.id_client,
      "idBranch": authReducer.data.branch.id_entity,
      "idClientData": authReducer.data.client.id_client
    })

  }, [authReducer]);
  const changeUser = (key: string, value: any) => {
    setnewEndorseeObj({ ...newEndorseeObj, [key]: value })
  }
  const createEndorsee = () => {
    if (resultId) {
      setShowDialog(false);
      toggleHideDialog();
      dispatch(createEndorseeInConvertions(newEndorseeObj, userCredentials, setnewEndorsee, setidEndorsee))
    }
    else {
      setShowDialogFailed(true)
      setMessageError(t('validFields'))
    }
  }
  return (
    <>
      <Dialog hidden={hideDialog} modalProps={modelProps} onDismiss={() => { toggleHideDialog(); setShowDialog(false) }} >
        <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{t("detailsEndorsee")}</p>
        <hr className="hr"></hr>
        {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} subText={messageError} title={t('error')} setshowModalDelete={setShowDialogFailed} dialogType={"dialogMessage"} />}
        <div className="wrap-dialog">
          <CustomTextField autoFocus={true} readOnly={false} value={newEndorseeObj?.firstName} label={t("firstName")} onChange={changeUser} id={'firstName'} />
          <CustomTextField readOnly={false} value={newEndorseeObj?.lastName} label={"lastName"} onChange={changeUser} id={'lastName'} />
          <IdNumber entity={newEndorseeObj} setEntity={setnewEndorseeObj} readOnly={false} setResultId={setResultId} resultId={resultId}  typeIdentityNumbers={enumsDropdown.typeIdentityNumbers} typeIdDefault={defaultIdTypeId} />
          {/* countryCode={enumsDropdown?.countryCode} */}
          {/* <IdNumber readOnly={readOnly} /> */}
          {/* <CustomTextField value={newEndorseeObj?.idNumber} readOnly={readOnly} label={"מספר זהות"} onChange={changeUser} id={'idNumber'} /> */}
        </div>
        <DialogFooter >
          <DefaultButton onClick={() => { toggleHideDialog(); setShowDialog(false) }} text={t("cancel")} />
          <PrimaryButton onClick={() => { createEndorsee() }} text={t("update")} />
        </DialogFooter>
      </Dialog>
    </>
  )

}