
import html2pdf from 'html2pdf.js'

const GenericPdfDownloader = async (props) => {
    const { rootElementId, downloadFileName, setFile, refSignature } = props
    let element = document.getElementById(rootElementId);
    const signature = refSignature.current.getDataURL()

    let divSignature = document.getElementById("divSignature");
    divSignature.innerHTML = '<img src="' + signature + '" width= "200px" height= "200px" />'

    var opt = {
        margin: 2,
        filename: `${downloadFileName}.pdf`,
    }
    if (setFile) {
        await html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdfObj) {
            const perBlob = pdfObj.output('blob');
            let file = new File([perBlob], `${downloadFileName}.pdf`,
                { type: "application/pdf" })
            setFile(file)
        });

    }
    else {
        html2pdf().set(opt).from(element).save(downloadFileName);
    }
}
export default GenericPdfDownloader;