import { Switch, withRouter } from "react-router-dom";
import Accounts from '../accounts/Accounts';
import BookKeeping from '../bookKeeping/BookKeeping';
import CurrencyEntry from '../actions/convertionsAction/CurrencyEntry/CurrencyEntry';
import LogOrder from '../LogOrder';
import Invoice from '../invoice/Invoice';
import Receipt from '../receipt/Receipt';
import PrivateRoute from './PrivateRoute';
import TransactionAccounts from '../TransactionAccounts/TransactionAccounts';
import Contact from '../contact/Contact';
import Representatives from '../Representatives';
import PriceList from '../PriceList';
import BankAccounts from '../BankAccounts';
import Loans from '../Loans';
import Documents from '../Documents';
import RelatedFactors from '../relatedFactors/RelatedFactors'
import CustomerPlan from '../customerPlan/CustomerPlan'
import Collateral from '../collateral/Collateral';
import MoneyLaundering from '../moneyLaundering/MoneyLaundering';
import GetService from '../GetService/GetService';
import CustomerAccounts from '../customerAccounts/CustomerAccounts';
import SettingsModulesClient from '../backOffice/SettingsModulesClient';
import CustomerDetails from '../customerDetails/CustomerDetails';
import CustomerLocator from '../customerSearch/CustomerSearch';
import CheckDiscounts from '../actions/discountsAction/checkDiscount/CheckDiscounts';
import CheckManagement from '../actions/discountsAction/checkManagment/CheckManagement'
import DiscountTrnsaction from '../DiscountTransaction';
import RegularReports from '../reports/RegularReports';
import UnRegularReports from '../reports/UnRegularReports';
import SumProfit from '../bookKeeping/SumProfit';
import Reports from '../reports/Reports';
import BranchSettings from '../branchSettings';
import BranchUsersSettings from '../brunchUsersSettings';
import PricePlan from '../pricePlan/PricePlan'
import RoleFairCredit from '../RoleFairCredit/RoleFairCredit';
import Users from '../users/Users';
import UserEmail from '../UserEmail';
import UserAlerts from '../UserAlerts';
import Convertions from '../actions/convertionsAction/Convertions/Convertions';
import Stocktaking from '../stocktaking/Stocktaking';
import TransactionConvert from '../TransactionConvert/TransactionConvert';

const SideSubGlobal = (props: any) => {
    const { category } = props
    return (
        <div>
            <Switch>
                <PrivateRoute path={`/${category}/accounts_chart`} name="accounts_chart" component={Accounts} />
                <PrivateRoute path={`/${category}/money_in`} name="money_in" component={CurrencyEntry} />
                <PrivateRoute path={`/${category}/main_accounts`} name="main_accounts" component={BookKeeping} />
                <PrivateRoute path={`/${category}/journal_entries`} name="journal_entries" component={LogOrder} />
                <PrivateRoute path={`/${category}/invoice`} name="invoice" component={Invoice} />
                <PrivateRoute path={`/${category}/receipt`} name="receipt" component={Receipt} />
                <PrivateRoute path={`/${category}/documents`} name="documents" component={TransactionAccounts} />
                <PrivateRoute path={`/${category}/customer_search`} name="customer_search" component={CustomerLocator} />
                <PrivateRoute path={`/${category}/customer_details`} name="customer_details" component={CustomerDetails} />
                <PrivateRoute path={`/${category}/customer_related_entities`} name="customer_related_entities" component={RelatedFactors} />
                <PrivateRoute path={`/${category}/customer_bank_accounts`} name="customer_bank_accounts" component={BankAccounts} />
                <PrivateRoute path={`/${category}/customer_price_program`} name="customer_price_program" component={CustomerPlan} />
                <PrivateRoute path={`/${category}/customer_contacts`} name="customer_contacts" component={Contact} />
                <PrivateRoute path={`/${category}/representatives`} name="representatives" component={Representatives} />
                <PrivateRoute path={`/${category}/customer_kyc_questionnaire`} name="customer_kyc_questionnaire" component={MoneyLaundering} />
                <PrivateRoute path={`/${category}/service_receiver_statement`} name="service_receiver_statement" component={GetService} />
                <PrivateRoute path={`/${category}/price-list`} name="price-list" component={PriceList} />
                <PrivateRoute path={`/${category}/customer_obligation_collateral`} name="customer_obligation_collateral" component={Collateral} />
                <PrivateRoute path={`/${category}/loans`} name="loans" component={Loans} />
                <PrivateRoute path={`/${category}/customer_documentation`} name="customer_documentation" component={Documents} />
                <PrivateRoute path={`/${category}/customer_customer_accounts`} name="customer_customer_accounts" component={CustomerAccounts} />
                <PrivateRoute path={`/${category}/clients_modoules`} name="clients_modoules" component={SettingsModulesClient} />
                <PrivateRoute path={`/${category}/cheque_cashing`} name="cheque_cashing" component={CheckDiscounts} />
                <PrivateRoute path={`/${category}/cheques_management`} name="cheques_management" component={CheckManagement} />
                <PrivateRoute path={`/${category}/cheque_cashing_transactions_list`} name="cheque_cashing_transactions_list" component={DiscountTrnsaction} />
                <PrivateRoute path={`/${category}/exchange`} name="exchange" component={Convertions} />
                <PrivateRoute path={`/${category}/stocktaking`} name="stocktaking" component={Stocktaking} />
                <PrivateRoute path={`/${category}/exchange_transactions_list`} name="exchange_transactions_list" component={TransactionConvert} />
                <PrivateRoute path={`/${category}/exchange_pnl`} name="exchange_pnl" component={SumProfit} />
                <PrivateRoute path={`/${category}/impa_ctr`} name="impa_ctr" component={RegularReports} />
                <PrivateRoute path={`/${category}/impa_utr`} name="impa_utr" component={UnRegularReports} />
                <PrivateRoute path={`/${category}/reports`} name="reports" component={Reports} />
                <PrivateRoute path={`/${category}/branch_setting`} name="branch_setting" component={BranchSettings} />
                <PrivateRoute path={`/${category}/impa_utr_filter_defaults`} name="impa_utr_filter_defaults" component={BranchUsersSettings} />
                <PrivateRoute path={`/${category}/price_program_templates`} name="price_program_templates" component={PricePlan} />
                <PrivateRoute path={`/${category}/users`} name="users" component={Users} />
                <PrivateRoute path={`/${category}/fair_credit`} name="fair_credit" component={RoleFairCredit} />
                <PrivateRoute path={`/${category}/email_setting`} name="email_setting" component={UserEmail} />
                <PrivateRoute path={`/${category}/ui_alerts`} name="ui_alerts" component={UserAlerts} />
            </Switch>
        </div>
    );
};

export default withRouter(SideSubGlobal)