import * as React from 'react';
import { TextField } from '@fluentui/react';
import ComponenetProps from '../../modelsType/type/interface';
import "../../scssPages/form.scss"

interface MyProps extends ComponenetProps {
  value ?:any
  label: string,
  style?:any
}

export const TextFeildNote: React.FunctionComponent<MyProps> = (props) => {
  const { onChange, label, id,value,style } = props;
  return (

    <TextField
      label={label} multiline rows={3}
      className="note"
      onChange={(e: any) => {
        onChange(id, e.currentTarget.value)
      }}
      value={value}
      style={style}
    // onChange={(e: any) => setLogin_entity_number(e.target.value)}
    />

  );
}