import * as React from 'react';
import {
  DetailsList, Selection, IColumn, SelectionMode, DetailsRow,
  IDetailsFooterProps
} from '@fluentui/react/lib/DetailsList';
import { DefaultButton, IRenderFunction } from '@fluentui/react';
import { useEffect, useState } from 'react';
import "./detaiList.scss"
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DialogCompleteTransactions from '../dialog/dialogTransaction/DialogCompleteTransactions'
import {
  CustemDialogAddress, CustemDialogEmail, CustemDialogPhone, CustomDialogBankAccount,
  CustomFilesDialog, CustomFilesDocumentsDialog
} from '../dialog/Dialog';
import { CustomDialogCollateral } from '../../../components/collateral/CustomDialogCollateral'
import { useSelector, useDispatch } from "react-redux";
import { IUserCredentials } from '../../../components/customerDetails/CustomerDetails';
import { deleteAddress } from '../../../store/actions/addressAction';
import { deleteEmail } from '../../../store/actions/emailAction';
import { deletePhone } from '../../../store/actions/phonecAction';
import { readCustomerId, updateCustomerInfo } from '../../../store/actions/customerActioin'
import { deleteBankAccount } from '../../../store/actions/bankActions'
import { DialogMessages } from '../dialog/DialogMessages';
import { deleteCustomerColatteral, deleteCollateralMedia } from '../../../store/actions/collateralAction'
import { deletePricePlanTemplate } from '../../../store/actions/PricePlanTemplateAction'
import EditableRelatedFactors from '../../../components/relatedFactors/EditableRelatedFactors'
import { deleteInsider } from '../../../store/actions/relatedFactorsActions'
import { CustomTextField } from "../TextField";
import EditablePricePlan from "../../../components/pricePlan/EditablePricePlan"
import { CustomDropdown } from '../Option';
import { Icons } from '../../../modelsType/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPen, faTv, faBarcode, faInfo, faDownload, faEye, faFileImport } from "@fortawesome/free-solid-svg-icons";
import UsersDialog from '../../../components/users/usersDialog';
import { deleteUser, readUser } from '../../../store/actions/usersAction';
import { readDraftTransactions } from '../../../store/actions/convertionActions';
import { SAVE_DRAFT_CONVERSION_CHANGES } from '../../../store/actions/types';
import { deteleFile, downloadFile, previewFile } from '../../../store/actions/FilesAction';
import moment from 'moment';
import { customerInfoInObject } from '../CustomHooks/GlobalFunctions';
export interface IDetailsListItem {
  key: number;
  name: string;
  value: number;
}

export interface IDetailsListState {
  selectionDetails?: string;
  columns: [] | IColumn[];
  allItems: any[];
  styleHeader?: string
  isSelcted?: boolean;
  isFooter?: boolean;
  rederRow?: string;
  search?: boolean;
  addCustem?: boolean;
  textBottun?: string;
  renderItemColumn?: any
  tableName?: string
  changeRow?: any
  readOnly?: boolean,
  setshowSpiner?: any,
  idEntityUser?: any,
  setShowDialogCreate?: any
  setShowDialogSearch?: any,
  setReadOnly?: any,
  setRelatedFactors?: any,
  typesList?: any,
  path?: any,
  idOtherEntity?: any,
  setDleteDocument?: any,
  actionType?: any,
}

const footerStyle = {
  root: {
    background: '#E1E1E1',

  }
}

const CustemTable: React.FunctionComponent<IDetailsListState> = (props) => {
  const { changeRow, tableName, readOnly, allItems, columns, path,
    isSelcted = false, idOtherEntity, isFooter = true, rederRow = "",
    setReadOnly, textBottun = "", setshowSpiner, idEntityUser,
    setShowDialogCreate, setShowDialogSearch, setRelatedFactors,
    typesList, setDleteDocument, actionType } = props

  const [showDialogTransaction, setShowDialogTransaction] = useState(false)

  let isSelection = SelectionMode.none
  if (isSelcted === true) {
    isSelection = SelectionMode.single
  }
  const renderRow = rederRow;

  const authReducer = useSelector(({ authReducer }: any) => authReducer)
  const customerReducer = useSelector(({ customerReducer }: any) => customerReducer)
  const [idEntity, setidEntity] = useState("")
  const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const [/*selectedItem*/, setSelectedItem] = useState<Object | undefined>(undefined)
  const [ansDelete, setansDelete] = useState(false)
  const [showModalDelete, setshowModalDelete] = useState(false)
  const [showModalUpdate, setshowModalUpdate] = useState(false)
  const [htmlData, sethtmlData] = useState<any>()
  const [renderIndex, setrenderIndex] = useState(-1)
  const history = useHistory()
  const { state } = useLocation<any>()
  const dispatch = useDispatch()
  const [isClient, setisClient] = useState(false)
  const [showDialogUpdate, setShowDialogUpdate] = useState(false)
  const [showDialogDelete, setShowDialogDelete] = useState(false)
  const [messageError, setMessageError] = useState<any>("")
  const [serverMessageError, setServerMessageError] = useState<any>(false)
  const [/*selectedRelatedType*/, setSelectedRelatedType] = useState<any>([])
  const tableStyle = {
    root: {
      selectors: {
        '.ms-DetailsRow-fields': {
          textAlign: `${dir === "rtl" ? "left" : "right"} !important`,
        }
      }
    }
  }
  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedItem(selection.getSelection())
    }
  })


  useEffect(() => {
    setidEntity(customerReducer.dataCustomer?.id_entity)
  }, [customerReducer])


  useEffect(() => {
    setUserCredentials({
      "idInitiator": authReducer.data.user.id_entity,
      "idClient": authReducer.data.user.id_client,
      "idBranch": authReducer.data.branch.id_entity,
      "idClientData": authReducer.data.client.id_client
    })
    if (authReducer.data.client.id_entity === '00000000-0000-0000-0000-000000000001')
      setisClient(true)

  }, [authReducer]);


  useEffect(() => {
    async function deleteRow() {
      if (ansDelete && userCredentials !== null && renderIndex >= 0) {
        setshowSpiner && setshowSpiner(true)
        switch (tableName) {
          case "address":
            // await deleteAddress(userCredentials, idEntity, allItems[renderIndex].addressId,isClient setshowSpiner, ...check it )
            await deleteAddress(userCredentials, idEntity, allItems[renderIndex].addressId, setshowSpiner, isClient)
            break;
          case "usersList":
            await deleteUser(userCredentials, idEntityUser, allItems[renderIndex].key, setShowDialogUpdate, setshowSpiner)
            dispatch(readUser(userCredentials.idBranch, userCredentials))
            break;
          case "phone":
            await deletePhone(userCredentials, idEntity, allItems[renderIndex].telephoneId, setshowSpiner, isClient)
            break;
          case "email":
            await deleteEmail(userCredentials, idEntity, allItems[renderIndex].emailId, setshowSpiner)
            break;
          case "customerCollateral":
            await dispatch(deleteCustomerColatteral(userCredentials, allItems[renderIndex].key, idEntity, setshowSpiner))
            break;
          case "attachedFiles":
            await dispatch(deteleFile(idEntity, allItems[renderIndex]?.id_media, userCredentials))
            await dispatch(deleteCollateralMedia(allItems[renderIndex]?.collateralId, allItems[renderIndex], userCredentials, idEntity))
            break;
          case "bankAccounts":
            await dispatch(deleteBankAccount(userCredentials, allItems[renderIndex].key, idEntity, setShowDialogDelete, setshowSpiner))
            break;
          case "PricePlan":
            await dispatch(deletePricePlanTemplate(allItems[renderIndex].key, userCredentials, setShowDialogDelete, setServerMessageError, setMessageError, t))
            break;
          case "documentsList":
            await deleteDocument(renderIndex)
            break
          case "relatedFactors":
            setRelatedFactors ?
              deleteReletedType(allItems[renderIndex], renderIndex,)
              : await dispatch(deleteInsider(allItems[renderIndex], userCredentials, idEntity, setShowDialogDelete, "", setshowSpiner))
            break;
          default: return;
        }
        dispatch(readCustomerId(idEntity, userCredentials, "", "", "", false));
      }
      setansDelete(false)
    }
    deleteRow();
  }, [showModalDelete, allItems])

  useEffect(() => {
    const updateRow = async () => {
      if (showModalUpdate && renderIndex >= 0)
        if (showModalUpdate && renderIndex >= 0) {
          let data;
          switch (tableName) {
            case "address":
              data = <CustemDialogAddress setshowSpiner={setshowSpiner} setShowDialog={setshowModalUpdate} itemUpdate={allItems[renderIndex]} userCredentials={userCredentials} idEntity={idEntity} textButton={t(textBottun)} update={true} />
              break;
            case "phone":
              data = <CustemDialogPhone setshowSpiner={setshowSpiner} setShowDialog={setshowModalUpdate} itemUpdate={allItems[renderIndex]} userCredentials={userCredentials} idEntity={idEntity} textButton={t(textBottun)} update={true} />
              break;
            case "email":
              data = <CustemDialogEmail setshowSpiner={setshowSpiner} setShowDialog={setshowModalUpdate} itemUpdate={allItems[renderIndex]} userCredentials={userCredentials} idEntity={idEntity} textButton={t(textBottun)} update={true} />
              break;
            case "customerCollateral":
              data = <CustomDialogCollateral setShowDialogUpdate={setShowDialogUpdate} setshowSpiner={setshowSpiner} setShowDialog={setshowModalUpdate} itemUpdate={allItems[renderIndex]} userCredentials={userCredentials} idEntity={idEntity} textButton={t(textBottun)} update={true} />
              break;
            case "attachedFiles":
              data = <CustomFilesDialog setShowDialog={setshowModalUpdate} idCollateral={allItems[renderIndex].collateralId} itemUpdate={allItems[renderIndex]} userCredentials={userCredentials} idEntity={idEntity} update={true} />
              break;
            case "bankAccounts":
              data = <CustomDialogBankAccount notShowHolderButton={true} setshowSpiner={setshowSpiner} setShowDialog={setshowModalUpdate} itemUpdate={allItems[renderIndex]} userCredentials={userCredentials} idEntity={idEntity} textButton={t(textBottun)} update={true} setShowDialogUpdate={setShowDialogUpdate} />
              break;
            case "relatedFactors":
              data = <EditableRelatedFactors setshowSpiner={setshowSpiner} setShowDialog={setshowModalUpdate} itemUpdate={allItems[renderIndex]} userCredentials={userCredentials} idEntity={idEntity} update={true} setRelatedFactors={setRelatedFactors} setShowDialogUpdate={setShowDialogUpdate} typesList={typesList} />
              break;
            case "conversionsFactors":
              data = <EditableRelatedFactors setshowSpiner={setshowSpiner} setShowDialog={setshowModalUpdate} itemUpdate={allItems[renderIndex]} update={true} setShowDialogUpdate={setShowDialogUpdate} setRelatedFactors={setRelatedFactors} />
              break;
            case "usersList":
              data = <UsersDialog setshowSpiner={setshowSpiner} setShowDialog={setshowModalUpdate} itemUpdate={allItems[renderIndex]} userCredentials={userCredentials} idEntity={idEntityUser} textButton={t(textBottun)} update={true} setShowDialogUpdate={setShowDialogUpdate} setShowDialogCreate={setShowDialogCreate} />
              break;
            case "documentsList":
              data = <CustomFilesDocumentsDialog actionType={actionType} setshowSpiner={setshowSpiner} setShowDialog={setshowModalUpdate} itemUpdate={allItems[renderIndex]} idOtherEntity={idOtherEntity} userCredentials={userCredentials} setShowDialogUpdate={setShowDialogUpdate} setRelatedFactors={setRelatedFactors} idEntity={idEntity} isUpdate={true} documents={allItems} updateIndex={renderIndex} />
              break;
            case "PricePlan":
              data = <EditablePricePlan setShowDialog={setshowModalUpdate} edit={true} itemUpdate={allItems[renderIndex]} setShowDialogUpdate={setShowDialogUpdate} />
              break;
          }
          sethtmlData(data);
        }
    }
    updateRow();
  }, [showModalUpdate, allItems])


  const getCustomerInfo = async (idEntity: any) => {
    setshowSpiner(true)
    setShowDialogSearch(false)
    userCredentials !== null &&
      await dispatch(readCustomerId(idEntity, userCredentials, "", "", "",
        false, setshowSpiner, setReadOnly, "", setRelatedFactors));
  }

  const downloadOrPreviewFile = (index: number, type: any) => {
    let extensionFileName;
    if (type === previewFile)
      extensionFileName = allItems[index]?.media_extension_file_name?.split(".").pop()
    else
      extensionFileName = allItems[index]?.media_extension_file_name
    dispatch(type(idOtherEntity ? idOtherEntity : idEntity, userCredentials,
      allItems[index]?.id_media, allItems[index]?.media_name,
      extensionFileName ? extensionFileName : "pdf"))
  }

  const deleteDocument = async (index: any) => {
    let copyDataCustomer = { ...customerReducer?.dataCustomer }
    copyDataCustomer = customerInfoInObject(copyDataCustomer)
    copyDataCustomer.isFullyIdentified = false
    if (copyDataCustomer?.isFullyIdentified !== customerReducer?.dataCustomer?.is_fully_identified) {
      await updateCustomerInfo(copyDataCustomer, userCredentials, copyDataCustomer?.idEntity)
    }
    setDleteDocument && setDleteDocument({ index: index, id_media: allItems[index].id_media, id: idOtherEntity ? idOtherEntity : idEntity });
  }


  const deleteReletedType = (item: string, index: any) => {
    let relatedTypes: any[] = [...allItems]
    relatedTypes = relatedTypes.filter((_, i) => i !== index)
    setSelectedRelatedType(relatedTypes)
    setRelatedFactors({ key: "deleteRelatedType", item: item, setshowSpiner: setshowSpiner })
  }

  const renderItemColumn = (item: any, index: any, column: any): JSX.Element | "" | null => {
    let fieldContent = item[column.fieldName];
    if (tableName === "accountMovements" && (column.fieldName === "amount" || column.fieldName === "total_account" ||
      column.fieldName === "amount_ils")) {
      return <span className={`ltr-${dir}`} >{item[column.fieldName]}</span>
    }
    if (column.fieldName === "media_name" && tableName === "documentsList") {
      if (item?.media_name === "הכר את הלקוח " || item?.media_name ==="הכר את הלקוח") {
        return <label title="לעמוד הכר את הלקוח" className="kycQuestionnaireLabel" onClick={() => {
          history.push("/customers/customer_kyc_questionnaire")
        }}>{item?.media_name}</label>
      }
      if (item?.media_name === "הצהרת מקבל שירות"||item?.media_name === "הצהרת מבקש שירות") {
        return <label title="לעמוד הצהרת מקבל שירות" className="kycQuestionnaireLabel" onClick={() => {
          history.push("/customers/service_receiver_statement")
        }}>{item?.media_name}</label>
      }
    }
    switch (column.fieldName) {
      case renderRow:
        let indexName = allItems[index].idEntity 
        if (renderRow === "operations" && tableName !== "transactionConvert")

          return <>
            <FontAwesomeIcon icon={faPen} size="lg" title={t('edit')} name={index + "1"} className={`iconEdit-${dir}`}
              onClick={() => { setrenderIndex(index); setshowModalUpdate(true); }} />
            <FontAwesomeIcon icon={faTrashAlt} className={`iconDelete-${dir}`} title={t("deletion")} name={index + "2"}
              onClick={() => { setshowModalDelete(true); setrenderIndex(index); }} size="lg" />
          </>
        if (renderRow === "operations" && tableName === "transactionConvert")
          return <>
            <FontAwesomeIcon icon={faEye} size="lg" title={t('display')} name={index + "1"} className={`iconEdit-${dir}`}
              onClick={() => {
                dispatch(readDraftTransactions("exchange", userCredentials, allItems[index]?.id_entity,
                  allItems[index]?.id_transaction))
                dispatch({
                  type: SAVE_DRAFT_CONVERSION_CHANGES,
                  payload: {}
                });
                setShowDialogTransaction(true)
              }} />

          </>

        if (renderRow === "operationsDocuments")
          return <>
            <FontAwesomeIcon icon={faPen} style={{ cursor: (allItems[index].media_status_id === "99") ? "text" : "pointer", color: (allItems[index].media_status_id === "99") ? "#dadfe3" : "" }} size="lg" title={t('edit')} name={index + "4"}
              className={`iconEdit-${dir}`} onClick={() => { allItems[index].media_status_id !== "99" && setrenderIndex(index); allItems[index].media_status_id !== "99" && setshowModalUpdate(true) }} />
            <FontAwesomeIcon icon={faTrashAlt} style={{ cursor: (!allItems[index]?.id_media || allItems[index].media_status_id === "99") ? "text" : "pointer", color: (!allItems[index]?.id_media || allItems[index].media_status_id === "99") ? "#dadfe3" : "" }}
              className={`iconDelete-${dir}`} title={t("deletion")} name={index + "3"} onClick={() => { (allItems[index]?.id_media && allItems[index].media_status_id !== "99") && setshowModalDelete(true); setrenderIndex(index) }} size="lg" />
            <FontAwesomeIcon className={`iconEdit-${dir}`} id="fileImport" icon={faFileImport} style={{ cursor: (!allItems[index]?.id_media) ? "text" : "pointer", color: (!allItems[index]?.id_media) ? "#dadfe3" : "" }} title={t('display')} name={index + "2"} onClick={() => {
              (allItems[index]?.id_media) && downloadOrPreviewFile(index, previewFile)
            }} size="lg" />
            <FontAwesomeIcon id="download" icon={faDownload} style={{ cursor: (!allItems[index]?.id_media) ? "text" : "pointer", color: (!allItems[index]?.id_media) ? "#dadfe3" : "" }} title={t('download')} name={index + "2"} onClick={() => {
              (allItems[index]?.id_media)
                && downloadOrPreviewFile(index, downloadFile)
            }} size="lg" />
          </>
        if (renderRow === "operationsService")
          return <>
            <FontAwesomeIcon icon={faPen} size="lg" title={t('edit')} name={index + "5"} className={`iconEdit-${dir}`} onClick={() => { setrenderIndex(index); setshowModalUpdate(true) }} />
            <FontAwesomeIcon icon={faTrashAlt} className={`iconDelete-${dir}`} title={t("deletion")} name={index + "6"} onClick={() => { setshowModalDelete(true); setrenderIndex(index) }} size="lg" />
          </>
        if (renderRow === "operationsFiles")
          return <>
            <FontAwesomeIcon icon={faPen} style={{ cursor: "pointer" }} size="lg" title={t('edit')} name={index + "4"} className={`iconEdit-${dir}`} onClick={() => { setrenderIndex(index); setshowModalUpdate(true); }} />
            <FontAwesomeIcon icon={faTrashAlt} style={{ cursor: "pointer" }} className={`iconDelete-${dir}`} title={t("deletion")} name={index + "3"} onClick={() => { setshowModalDelete(true); setrenderIndex(index) }} size="lg" />
            <FontAwesomeIcon className={`iconEdit-${dir}`} id="fileImport" icon={faFileImport} style={{ cursor: (!allItems[index]?.id_media) ? "text" : "pointer", color: (!allItems[index]?.id_media) ? "#dadfe3" : "" }} title={t('display')} name={index + "2"} onClick={() => {
              (allItems[index]?.id_media) && dispatch(previewFile(idOtherEntity ? idOtherEntity : idEntity, userCredentials,
                allItems[index]?.id_media, allItems[index]?.media_name, "pdf"))
            }} size="lg" />
            <FontAwesomeIcon icon={faDownload} style={{ cursor: "pointer", }} title={t('download')} name={index + "2"} onClick={() => {
              (allItems[index]?.id_media) && downloadOrPreviewFile(index, downloadFile)
            }} size="lg" />

          </>
        if (renderRow === "operationsCheck")
          return <>
            <FontAwesomeIcon icon={faPen} size="lg" title={t('edit')} name={index} className={`iconEdit-${dir}`} onClick={() => { setrenderIndex(index); setshowModalUpdate(true) }} />
            <FontAwesomeIcon icon={faTrashAlt} className={`iconDelete-${dir}`} title={t("deletion")} name={index + "9"} onClick={() => { setshowModalDelete(true); setrenderIndex(index); }} size="lg" />
            <FontAwesomeIcon icon={faBarcode} className={`iconScan-${dir}`} title={t('scanning')} name={index + "10"} onClick={() => { setrenderIndex(index); setshowModalUpdate(true) }} size="lg" />
            <FontAwesomeIcon icon={faTv} className={`iconDisplay-${dir}`} title={t('display')} name={index + "11"} onClick={() => { setrenderIndex(index); setshowModalUpdate(true) }} size="lg" />
            <FontAwesomeIcon icon={faInfo} className={`iconInfo-${dir}`} title={t('moreInfo')} name={index + "12"} onClick={() => { setrenderIndex(index); setshowModalUpdate(true) }} size="lg" />
          
          </>
        if (renderRow === "link")
          return <DefaultButton text={t("select")} className="detailsBtn" name={indexName + "1"} onClick={() => {
            history.push({ pathname: "./customer_details", state: indexName })
          }
          } />
        if (renderRow === "conversions")
          return <DefaultButton iconProps={Icons.addFriend} text={t("select")} className="detailsBtn" name={indexName + "2"} onClick={() => { getCustomerInfo(indexName) }} />
        if (renderRow === "edit")
          return <>
            {(tableName === "email" && allItems[index]?.emailDefault) && <label title={t('default')} style={{ color: "red", width: "2px", fontWeight: "bold" }}>. </label>}
            {(tableName === "address" && allItems[index]?.addressDefault) && <label title={t('default')} style={{ color: "red", width: "2px", fontWeight: "bold" }}>. </label>}
            {(tableName === "phone" && allItems[index]?.telephoneDefault) && <label title={t('default')} style={{ color: "red", width: "2px", fontWeight: "bold" }}>. </label>}
            <FontAwesomeIcon icon={faPen} size="lg" title={t('edit')} name={index + "15"} className={`${(allItems[index]?.emailDefault || allItems[index]?.addressDefault || allItems[index]?.telephoneDefault) ? `iconEdit-${dir}` : `iconEditWithFEdault-${dir} iconEdit-${dir}`} `} onClick={() => { state !== "showCompletedManagment" && setrenderIndex(index); setshowModalUpdate(true) }} />
            <FontAwesomeIcon icon={faTrashAlt} className={`iconDelete-${dir}`} title={t("deletion")} name={index + "14"} onClick={() => { state !== "showCompletedManagment" && setshowModalDelete(true); setrenderIndex(index); }} size="lg" />
          </>
        if (renderRow === "editPricePlan")
          return <>
            <FontAwesomeIcon icon={faPen} size="lg" title={t('edit')} name={index + "15"} className={`iconEdit-${dir}`} onClick={() => { setrenderIndex(index); setshowModalUpdate(true) }} />
            <FontAwesomeIcon icon={faTrashAlt} className={`iconDelete-${dir}`} title={t("deletion")} name={index + "16"} onClick={() => { setshowModalDelete(true); setrenderIndex(index); setshowSpiner && setshowSpiner(true) }} size="lg" />
          </>
        if (renderRow === "operationsFactor")
          return <>
            <FontAwesomeIcon icon={faPen} size="lg" title={t('edit')} name={index + "17"} className={`iconEdit-${dir}`} onClick={() => { setrenderIndex(index); setshowModalUpdate(true) }} />
            <FontAwesomeIcon icon={faTrashAlt} className={`iconDelete-${dir}`} title={t("deletion")} name={index + "18"} onClick={() => { setshowModalDelete(true); setrenderIndex(index); }} size="lg" />
            <FontAwesomeIcon icon={faTv} className={`iconDisplay-${dir}`} title={t('display')} name={index + "19"} onClick={() => { setrenderIndex(index); setshowModalUpdate(true) }} size="lg" />
          </>
        return null
      case "details":
        return tableName === "plan" ?
          <CustomTextField label={" "} value={item.value} readOnly={readOnly} required={true}
            onChange={(id: any, val: any) => changeRow(id, val, index)} id={"value"} /> : ""

      case "media_exp_date":
        return tableName === "documentsList" ?
          <p >{item?.media_exp_date && moment(item.media_exp_date).format("DD/MM/YYYY")}</p> : ""

      case "date_created":
        return tableName === "documentsList" ?
          <p >{item?.date_created && moment(item.date_created).format("DD/MM/YYYY")}</p> :
          tableName === "attachedFiles" ?
            <p >{item?.date_created && moment(item.date_created).format("DD/MM/YYYY")}</p> :
            tableName === "chequeHistoryTable" ?
              <p >{item?.date_created && moment(item.date_created).format("DD/MM/YYYY HH:mm:ss")}</p> :
              <span >{fieldContent}</span>
      case "date_created":

      case "expirationDate":
        return tableName === "customerCollateral" ?
          <p >{item?.expirationDate && moment(item.expirationDate).format("DD/MM/YYYY")}</p> : ""

      case "dateAdded":
        return tableName === "customerCollateral" ?
          <p >{item?.dateAdded && moment(item.dateAdded).format("DD/MM/YYYY")}</p> : ""

      case "dateBirth":
        return tableName === "relatedFactors" ?
          <p >{item?.dateBirth && moment(item.dateBirth).format("DD/MM/YYYY")}</p> : ""

      case "creationDate":
        return tableName === "PricePlan" ?
          <p >{item?.creationDate && moment(item.creationDate).format("DD/MM/YYYY")}</p> : ""

      case "date_value":
        return tableName === "accountMovements" ?
          <p >{item?.date_value && moment(item.date_value).format("DD/MM/YYYY")}</p> : ""

      case "value_min":
        return tableName === "plan" && (item.calculation_type === "אחוז" || item.calculation_type === "Percent") ?
          <CustomTextField label={t("minimum")} value={item.value_min} readOnly={readOnly} required={true} onChange={(id: any, val: any) => changeRow(id, val, index)} id={"value_min"} /> : ""

      case "value_max":
        return tableName === "plan" && (item.calculation_type === "אחוז" || item.calculation_type === "Percent") ?
          <CustomTextField label={t("maximum")} value={item.value_max} readOnly={readOnly} required={true} onChange={(id: any, val: any) => changeRow(id, val, index)} id={"value_max"} /> : ""

      case "creditFacilities":
        return tableName === "collateral" ?
          <CustomTextField label={" "} value={item.creditFacilities} onChange={(id: any, val: any) => changeRow(id, val, index)} id={"creditFacilities"} /> : ""

      case "actualCredit":
        return tableName === "collateral" ?
          <CustomTextField label={" "} value={item.actualCredit} onChange={(id: any, val: any) => changeRow(id, val, index)} id={"actualCredit"} /> : ""

      case "currency":
        return tableName === "convertions" ?
          <CustomDropdown otherInputId={''} hasOtherValue={false} options={[]} label={t('')} onChange={''} selectedKey={''} id={'currency'} othertextInput={t('')} /> : ""

      case "typeC":
        return tableName === "convertions" ?
          <CustomDropdown otherInputId={''} hasOtherValue={false} options={[]} label={t('')} onChange={''} selectedKey={''} id={'type'} othertextInput={t('')} /> : ""

      case "sum":
        return tableName === "convertions" ?
          <CustomTextField value={'967.90'} onChange={''} id={"sum"} /> : ""
      case "gate":
        return tableName === "convertions" ?
          <CustomTextField value={'3.475'} onChange={''} id={"gate"} /> : ""

      case "total":
        return tableName === "convertions" ?
          <div style={{ display: "flex" }}> <CustomTextField readOnly={true} value={'3624'} onChange={''} id={"total"} />
            <p className="deleteX">x</p></div> : ""

      default:
        return <span >{fieldContent}</span>;
    }
  }


  let onRenderDetailsFooter: IRenderFunction<IDetailsFooterProps> = (detailsFooterProps?: IDetailsFooterProps) => {
    if (!props) {
      return null;
    }
    if (isFooter === false) {
      return null;
    }
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}

        itemIndex={-1}
        styles={footerStyle}

      />
    );
  };



  return (

    <div>
      {showDialogTransaction && < DialogCompleteTransactions path={path} setShowDialog={setShowDialogTransaction} />}
      {showModalDelete ? <DialogMessages setansDelete={setansDelete} setshowModalDelete={setshowModalDelete} dialogType={"delete"} /> : ""}
      {serverMessageError && <DialogMessages setansDelete={setServerMessageError} setshowModalDelete={setServerMessageError} subText={messageError} title={t('error')} dialogType={"dialogMessage"} />}
      {showModalUpdate ? htmlData : ""}
      {showDialogUpdate === true && <DialogMessages setansDelete={setShowDialogUpdate} setshowModalDelete={setShowDialogUpdate} dialogType={"update"} />}
      {showDialogDelete === true && <DialogMessages setansDelete={setShowDialogDelete} setshowModalDelete={setShowDialogDelete} dialogType={"create"} />}
      <div className="continar">
        <DetailsList
          className="tableCss"
          styles={tableStyle}
          items={allItems}
          columns={columns}
          selection={selection}
          selectionMode={isSelection}
          // selectionPreservedOnEmptyClick={true}
          onRenderDetailsFooter={onRenderDetailsFooter}
          onRenderItemColumn={renderItemColumn}

        // onRenderRow={onRenderRows}

        />
      </div>
    </div>
  );

}


export default CustemTable;


