import { useTranslation } from "react-i18next";
import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils';
import Subtitle from '../../shared/components/Subtitle';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions';
import TransfersAbroadPage from '../TransfersAbroad/TransfersAbroadPage';
import SettingsModulesClient from '../backOffice/SettingsModulesClient';
import UnRegularReports from './UnRegularReports';
import ExportUnRegularReports from './ExportUnRegularReports';

export default function Reports() {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    return (
        <div className={`${checkPermissionLevelOfPage(credentials, location) === "RW"
            ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={t("reports")} subTitle={"false"} disabledSave={false} />
            <br></br><br></br>
            <Subtitle title={t('reports')} />
            <div className={`content-div-${dir}`}>
                {/* <ExportUnRegularReports/> */}
                {/* <TransfersAbroadPage> </TransfersAbroadPage> */}
                {/* <UnRegularReports></UnRegularReports> */}
            </div>

        </div>
        // <div>
        //     <SettingsModulesClient></SettingsModulesClient>
        // </div>
    )
}