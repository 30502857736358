import  { useEffect, useState } from 'react'
import CustemTable from '../../shared/components/tabels/tableList';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import './relatedFactorsC.scss'
import Subtitle from '../../shared/components/Subtitle';
import { DefaultButton, IconButton, PrimaryButton } from 'office-ui-fabric-react';
import Title from '../../shared/components/Title';
import { Icons } from '../../modelsType/Icon';
import ExportCSV from '../XlUtils/ExportReactCSV';
import GenericPdfDownloader from '../pdfUtils/GenericPDF';
import EditableFactorsClient from './EditableFactorsClient';

const RelatedFactorsC = () => {

  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const customerReducer = useSelector(({ customerReducer }: any) => customerReducer)
  const [customerName, setCustomerName] = useState('')

  const columns = [
    { key: '1', name: t('factorType'), fieldName: "factorType", entity_name: "factorType", minWidth: 70, maxWidth: 70 },
    { key: '2', name: t('fullName'), fieldName: "fullName", entity_name: "fullName", minWidth: 70, maxWidth: 70 },
    { key: '3', name: t('identityNumber'), fieldName: "identityNumber", entity_name: "identityNumber", minWidth: 90, maxWidth: 90 },
    { key: '4', name: t('address0'), fieldName: "address", entity_name: "address", minWidth: 70, maxWidth: 70 },
    { key: '8', name: t('phone'), fieldName: "phone", minWidth: 100, maxWidth: 100 },
    { key: '9', name: t('edit'), fieldName: "edit", minWidth: 90, maxWidth: 90 },
  ];
  const items = [
    { key: 0, factorType: "שליח", fullName: "יעקב כהן", identityNumber: "44512552", address: "שדה בר 12", branchAddress: "הרצל 12 תל אביב", city: "ירושלים", country: "ישראל", phone: "050-811-9652" },
    { key: 0, factorType: "שליח", fullName: "יעקב כהן", identityNumber: "44512552", address: "שדה בר 12", branchAddress: "הרצל 12 תל אביב", city: "ירושלים", country: "ישראל", phone: "050-811-9652" },
    { key: 0, factorType: "שליח", fullName: "יעקב כהן", identityNumber: "44512552", address: "שדה בר 12", branchAddress: "הרצל 12 תל אביב", city: "ירושלים", country: "ישראל", phone: "050-811-9652" },
    { key: 0, factorType: "שליח", fullName: "יעקב כהן", identityNumber: "44512552", address: "שדה בר 12", branchAddress: "הרצל 12 תל אביב", city: "ירושלים", country: "ישראל", phone: "050-811-9652" },
  ]
  const [addFactor, setaddFactor] = useState(false)
  useEffect(() => {
    if (customerReducer)
      setCustomerName(customerReducer.dataCustomer?.properties?.entity_name)
  }, [customerReducer])
  return (
    <>
      <div className={`sub-header-${dir} fixed-${dir}`}>
        <Title
          title={t("customers")} nameInTitle={customerReducer.dataCustomer?.properties?.entity_name} />
        <div className="divider"></div><div className="divider"></div>
        {/* <button name="root" onClick={(e)=>activeButton(e)} >נכגחאו</button> */}
        <PrimaryButton ariaDescription="create" className='button' allowDisabledFocus checked={false} name="create" text={t('createFactor')} iconProps={Icons.addFriend} />
        <DefaultButton className='button edit-button' checked={false} text={t('operations')} iconProps={Icons.editContact} name="update" />
        <DefaultButton className='button delete-button' checked={false} iconProps={Icons.userRemove} text={t('deletion')} id={'Deletion'} name="delete" />
        <DefaultButton className='button save-upload' checked={false} text={t('save')} iconProps={Icons.cloudUpload} />
        <IconButton
          iconProps={Icons.pdf}
          styles={{
            icon: { color: 'red', fontSize: 36 }
          }}
          className="button"
          onClick={() => GenericPdfDownloader({ rootElementId: "customerPage", downloadFileName: "noname" })}
        />
        <IconButton
          iconProps={Icons.print}
          styles={{
            icon: { color: 'black', fontSize: 36 }
          }}
          className="button"
        />
        <ExportCSV fileName={"xcelCustomer"} />

      </div>

      <br></br> <br></br> <br></br> <br></br>

      {/* <HeaderUtils title={"customers"} createBtn={true} editBtn={true} deleteBtn={true} uploadBtn={true} /> */}
      <div className="wrap-title">
        <div className={`attechedF atteched-contact-${dir}`} >
          <Subtitle title={t('RelatedFactorsList')} />
          <div className="title-text addFile"  onClick={() => setaddFactor(true)} ><i className="fal fa-plus"></i></div>
          {addFactor ? <EditableFactorsClient setShowDialog={setaddFactor} edit={true} /> : ""}
        </div>
        <hr className="hr" style={{ width: "610px" }}></hr>
      </div>
      <div className={`factor-table-${dir}`}>
        <CustemTable tableName="relatedFactors" columns={columns} allItems={items} renderItemColumn rederRow={"edit"} addCustem={true} isFooter={false} /></div>
 
    </>
  )
}

export default RelatedFactorsC
