import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import { Label } from '@fluentui/react/lib/Label';
import { IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import { cloneDeep, isEqual } from 'lodash';
import { Checkbox } from '@fluentui/react';

import './branchSettings.scss'
import { settingUpdate } from '../store/actions/settingsAction'
import HeaderUtils from '../shared/components/headerUtils/HeaderUtils'
import Subtitle from "../shared/components/Subtitle";
import { CustomTextField } from '../shared/components/TextField';
import { CustomDropdown } from '../shared/components/Option';
import { DialogMessages } from '../shared/components/dialog/DialogMessages';
import { IUserCredentials } from './customerDetails/CustomerDetails';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../shared/components/CustomHooks/GlobalFunctions';

const BranchSettings = () => {
    const [t] = useTranslation();
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const [readOnly, setreadOnly] = useState(true)
    const settings = useSelector(({ authReducer }: any) => authReducer?.data?.branch?.setting)
    const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [showDialogSave, setShowDialogSave] = useState(false)
    const [showDialogFailed, setShowDialogFailed] = useState(false)
    const [idEntity, setIdEntity] = useState("")
    const [showSpiner, setshowSpiner] = useState(false)
    const [showTextRequiredFiles, setShowTextRequiredFiles] = useState(false)
    const [showRequiredFields, setShowRequiredFields] = useState(false)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [updateSetting, setUpdateSetting] = useState(settings)
    const [updateSettingBeforeChanges, setupdateSettingBeforeChanges] = useState(settings)
    const arrvatClassificationName: IDropdownOption[] = [{ key: "1", text: t('authorizedDealer') }, { key: "2", text: t('financialInstitution') }]
    const arrReportingFrequency: IDropdownOption[] = [{ key: "1", text: t('monthly') }, { key: "2", text: t('biMonthly') }]
    const [enumsDropdown, setenumsDropdown] = useState<any>({ "currencyCode": [] })
    const dropdownStyles: Partial<IDropdownStyles> = {
        title: { backgroundColor: readOnly ? '#FAF9F8' : "white" },
        callout: { minWidth: "fit-content", maxwidth: "fit-content" },
    };

    const spinnerStyles: Partial<ISpinnerStyles> = {
        root: {
            position: 'fixed',
            zIndex: '999999',
            fontWeight: 'bold',
        },
        circle: {
            color: '#FFB400 ',
            height: '100px',
            width: '100px',
        },
    }

    useEffect(() => {
        setUserCredentials({
            "idInitiator": authReducer.data.user.id_entity,
            "idClient": authReducer.data.client.id_entity,
            "idBranch": authReducer.data.branch.id_entity,
            "idClientData": authReducer.data.client.id_client
        })
        setIdEntity(authReducer.data.branch.id_entity)
    }, [authReducer]);


    const changeSettings = (key: string, value: any) => {
   
            if (key === "vat_classification_id") {
                let name: any = arrvatClassificationName.find(name => name.key === value)
                setUpdateSetting({ ...updateSetting, [key]: value, ["vat_classification_name"]: name.text })
            }
            else {
                setUpdateSetting({ ...updateSetting, [key]: value })
            }
    }

    const check = (currentValue: string) => updateSetting[currentValue]?.length > 0;

    const saveProgram = async (statusProgram: boolean) => {
        let requiredFields: any = ["city", "entity_ID_number", "vat_classification_id", "branch_name", "impa_branch_number",
            "address", "postal_code", "telephone_number", "vat_rate", "reporting_frequency", "round_digits", "impa_code"]
        if (requiredFields.every(check) ) {
            if (statusProgram && userCredentials !== null && !isEqual(updateSetting, settings)) {
                setshowSpiner(true)
                let copySettings = { ...updateSetting }
                await dispatch(settingUpdate(copySettings, userCredentials, idEntity, setShowDialogSave, setShowDialogFailed, setshowSpiner))
                setupdateSettingBeforeChanges(cloneDeep(updateSetting))
            }
            setreadOnly(true)
        }
        else {
            setShowTextRequiredFiles(true)
            setShowRequiredFields(true)
        }
    }
    const cancelEdit = async (statusProgram: boolean) => {
        setUpdateSetting(cloneDeep(updateSettingBeforeChanges))
        setreadOnly(true)
    }
    return (
        <div className={`${checkPermissionLevelOfPage(credentials, location) === "RW"
            ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={"settings"} subTitle={"false"} disabledSave={readOnly ? true : false} createBtn={false} saveFunc={() => saveProgram(true)}
                editFunc={() => setreadOnly(false)} cancelFunc={() => cancelEdit(true)} editBtn={readOnly ? true : false} deleteBtn={false} uploadBtn={readOnly ? false : true}
                createXl={false} createPrint={false} createPDF={false} />
            <br></br><br></br>
            {showDialogSave && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"save"} />}
            {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} setshowModalDelete={setShowDialogFailed} dialogType={"failed"} />}
            {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
            {showRequiredFields && <DialogMessages setansDelete={setShowRequiredFields} setshowModalDelete={setShowRequiredFields} dialogType={"requiredFields"} />}
            <Subtitle title={t('branchSettings')} />
            <div className={`content-div-${dir}`}>
                <div style={{ width: " 600px" }}>
                    <div className="titleC">
                        <p className="title-text boldC" style={{ width: "355px" }}>{t("name")}</p>
                        <p className="title-text boldC" style={{ width: "200px" }}>{t("details")}</p>
                    </div>
                </div>

                <hr className="hr" style={{ width: " 600px", height: "2px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t('dealerInstitutionNumber')}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField requiredField={showTextRequiredFiles && !updateSetting?.entity_ID_number ? true : false} readOnly={readOnly} value={updateSetting?.entity_ID_number} onChange={changeSettings} id={"entity_ID_number"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t('reportinBranchNumber')}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles && !updateSetting?.cma_branch_number ? true : false}
                                value={updateSetting?.cma_branch_number} onChange={(changeSettings)} id={"cma_branch_number"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t('businessType')} </Label>
                        <div style={{ width: "200px" }}>
                            <CustomDropdown requiredField={showTextRequiredFiles && !updateSetting?.vat_classification_id ? true : false} otherInputId={''} readOnly={readOnly} hasOtherValue={false} options={arrvatClassificationName} label={t('')} onChange={changeSettings} selectedKey={updateSetting?.vat_classification_id} id={'vat_classification_id'} othertextInput={t('')} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("branchName")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles && !updateSetting?.branch_name ? true : false} value={updateSetting?.branch_name} onChange={(changeSettings)} id={"branch_name"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("branchStreetAndNumber")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles && !updateSetting?.address ? true : false} value={updateSetting?.address} onChange={changeSettings} id={"address"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("city")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles && !updateSetting?.city ? true : false} value={updateSetting?.city} onChange={changeSettings} id={"city"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("addressZipCode")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField requiredField={showTextRequiredFiles && !updateSetting?.postal_code ? true : false} readOnly={readOnly} value={updateSetting?.postal_code} onChange={changeSettings} id={"postal_code"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("phone")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles && !updateSetting?.telephone_number ? true : false} value={updateSetting?.telephone_number} onChange={changeSettings} id={"telephone_number"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("fax")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles && !updateSetting?.fax_number ? true : false} value={updateSetting?.fax_number} onChange={changeSettings} id={"fax_number"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("mail")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles && !updateSetting?.email_address ? true : false} value={updateSetting?.email_address} onChange={changeSettings} id={"email_address"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("VATRate")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles && !updateSetting?.vat_rate ? true : false} value={updateSetting?.vat_rate} onChange={changeSettings} id={"vat_rate"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("monthlyRreport")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomDropdown otherInputId={''} readOnly={readOnly} hasOtherValue={false} options={arrReportingFrequency} label={t('')} onChange={changeSettings} selectedKey={updateSetting?.reporting_frequency} id={'reporting_frequency'} othertextInput={t('')} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required> {t("roundingInDocuments")}: </Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles && !updateSetting?.round_digits ? true : false} value={updateSetting?.round_digits} onChange={changeSettings} id={"round_digits"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                {/* <div style={{ width: "46%" }}>
                    <div className={`inlineBS-${dir}`}>
                        <p className="bold" style={{ width: "25%" }}>{"תמונת לוגו"}</p>
                        <div style={{ width: "60%", marginRight: "25%" }}>
                            <div className="logoB">
                                <div className="showImage"></div>
                                <div className="buttonFile" style={{marginRight:"10px"}}>
                                    <DefaultButton type="file" text={"בחר קובץ תמונה"} name={' '} /></div></div></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: "74%", marginTop: "100px" }} ></hr> */}
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} >{t('note1')}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} value={updateSetting?.note_1} onChange={changeSettings} id={"note_1"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} >{t('note2')}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} value={updateSetting?.note_2} onChange={changeSettings} id={"note_2"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t('impaCode')}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles && !updateSetting?.impa_code ? true : false} value={updateSetting?.impa_code} onChange={changeSettings} id={"impa_code"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t('impaBranchNumber')}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles && !updateSetting?.impa_branch_number ? true : false} value={updateSetting?.impa_branch_number} onChange={changeSettings} id={"impa_branch_number"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("complianceOfficerName")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} value={updateSetting?.compliance_officer_name} onChange={changeSettings} id={"compliance_officer_name"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("complianceOfficerIDNumber")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} value={updateSetting?.compliance_officer_ID_number} onChange={changeSettings} id={"compliance_officer_ID_number"} /></div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t('transactionsAboveThresholdMissingDocuments')}</Label>
                        <div style={{ width: "200px" }}>
                            <Checkbox disabled={readOnly} checked={updateSetting?.prevent_transactions_fully_identified === undefined ?
                                true : updateSetting?.prevent_transactions_fully_identified}
                                onChange={() => {
                                    changeSettings("prevent_transactions_fully_identified"
                                        , !updateSetting?.prevent_transactions_fully_identified
                                    )
                                }} id={"prevent_transactions_fully_identified"} /></div>
                    </div>
                </div><hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t('transactionsUunauthorizedCorporation')}</Label>
                        <div style={{ width: "200px" }}>
                            <Checkbox disabled={readOnly} checked={updateSetting?.prevent_transactions_none_authorized === undefined ?
                                true : updateSetting?.prevent_transactions_none_authorized
                            }
                                onChange={() => {
                                    changeSettings("prevent_transactions_none_authorized",
                                        !updateSetting?.prevent_transactions_none_authorized
                                    )
                                }} id={"prevent_transactions_none_authorized"} /></div>
                    </div>
                </div>
                {/* <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div>
                    <Label className="boldBS" style={{ width: "355px" }} >{t("structuringDefault")}</Label>
                    <div style={{ width: " 600px" }}>
                        <div className={`inlineBS-${dir}`}>
                            <Label className="boldBS" style={{ width: "355px" }} required>{t("amount_threshold")}</Label>
                            <div style={{ width: "200px" }}>
                                <CustomTextField readOnly={readOnly} value={updateSetting?.structuring?.amount_threshold} onChange={changeSettings} id={"structuring,amount_threshold"} /></div>
                        </div>
                    </div>
                    <div style={{ width: " 600px" }}>
                        <div className={`inlineBS-${dir}`}>
                            <Label className="boldBS" style={{ width: "355px" }} required>{t("duration_days")}</Label>
                            <div style={{ width: "200px" }}>
                                <CustomTextField readOnly={readOnly} value={updateSetting?.structuring?.duration_days} onChange={changeSettings} id={"structuring,duration_days"} /></div>
                        </div>
                    </div>
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>

                <div>
                    <Label className="boldBS" style={{ width: "355px" }}>{t("nearThresholdDefault")}</Label>
                    <div style={{ width: " 600px" }}>
                        <div className={`inlineBS-${dir}`}>
                            <Label className="boldBS" style={{ width: "355px" }} required>{t("from_amount")}</Label>
                            <div style={{ width: "200px" }}>
                                <CustomTextField readOnly={readOnly} value={updateSetting?.near_threshold?.amount_from} onChange={changeSettings} id={"near_threshold,amount_from"} /></div>
                        </div>
                    </div>
                    <div style={{ width: " 600px" }}>
                        <div className={`inlineBS-${dir}`}>
                            <Label className="boldBS" style={{ width: "355px" }} required>{t("to_amount")}</Label>
                            <div style={{ width: "200px" }}>
                                <CustomTextField readOnly={readOnly} value={updateSetting?.near_threshold?.amount_to} onChange={changeSettings} id={"near_threshold,amount_to"} /></div>
                        </div>
                    </div>
                </div> <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <Label className="boldBS" style={{ width: "355px" }}>{t("cashTurnoverDefault")}</Label>
                    {updateSetting?.cash_turnover?.map((item: any, i: number) => (
                        <div key={i} className={`inlineBS-${dir}`}>
                            <Label className="boldBS" style={{ width: "355px" }} required>{item?.customer_type_name + "  - מעל ערך"}</Label>
                            <div style={{ width: "200px" }}>
                                <CustomTextField readOnly={readOnly} value={item?.over_volume} onChange={changeSettings} id={`cash_turnover,${item?.customer_type_id}`} /></div>
                        </div>
                    ))}
                </div>
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("annualTurnoverDefault")}</Label>
                        <div style={{ width: "200px" }}>
                            <CustomTextField readOnly={readOnly} value={updateSetting?.annual_turnover} prefix={"%"} onChange={changeSettings} id={"annual_turnover"} /></div>
                    </div>
                </div> <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
                <div style={{ width: " 600px" }}>
                    <div className={`inlineBS-${dir}`}>
                        <Label className="boldBS" style={{ width: "355px" }} required>{t("popupScanningCheque")}</Label>
                        <div style={{ width: "200px" }}>
                        {readOnly ?
                                <CustomTextField readOnly={readOnly}
                                value={enumsDropdown.popUpScanCheques?.find((item:any)=>item.key===updateSetting?.cheque_scanning_popup)?.text} 
                                id={'cheque_scanning_popup'}
                                onChange={""} />
                                : 
                            <Dropdown
                                placeholder={t("popupScanningCheque")}
                                selectedKey={updateSetting?.cheque_scanning_popup}
                                options={enumsDropdown.popUpScanCheques}
                                onChange={onChangeScanningCheque}
                                id={'cheque_scanning_popup'}
                                styles={dropdownStyles}
                            />}
                        </div>
                    </div>

                </div> */}
                <hr className="hr" style={{ width: " 600px", marginBottom: '8px' }} ></hr>
            </div>
        </div>
    )
}
export default BranchSettings
