import { useEffect, useState } from 'react'
import { BeneficiaryDialog } from '../../shared/components/dialog/dialogTransaction/BeneficiaryDialog';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { customerInfoInObject } from '../../shared/components/CustomHooks/GlobalFunctions'
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import { IUserCredentials } from '../../components/customerDetails/CustomerDetails'
import { createTransaction, getOpenTransactions, readDraftTransactions } from '../../store/actions/convertionActions'
import { CustomDropdown } from "../../shared/components/Option";
import DialogSearch from './DialogSearch';
import { Icons } from '../../modelsType/Icon';
import { cloneDeep } from 'lodash';
import { CustomTextField } from '../../shared/components/TextField';
import EditableRelatedFactors from '../relatedFactors/EditableRelatedFactors';
import moment from 'moment';
import { useBoolean } from '@fluentui/react-hooks';

export default function EntityDetails(props: any) {
    //chech! not every props in use 
    const { setIfChanges, ifChanges, actions, setActions, setReadOnly, readOnly, listInsiders, setListInsiders,
        setActionCustomerId, searchCustomer, setSearchCustomer, typeAction,salvo,
        setSelectedDraft, selectedDraft, selectConversionList, setSelectConversionList } = props
    const datacConversion = useSelector(({ conversionReducer }: any) => conversionReducer.dataConversion)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const { dataCustomer } = useSelector(({ customerReducer }: any) => customerReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const { state } = useLocation<any>()
    const dispatch = useDispatch()
    const [isOpenTransaction, setIsOpenTransaction] = useState(false)
    const [isCreate, setIsCreate] = useState(false)
    const [dialogBeforeChangeDraft, setDialogBeforeChangeDraft] = useState(false)
    const [listAccounts, setListAccounts] = useState<any>([{}])
    const [saveBeforeChangeDraft, setSaveBeforeChangeDraft] = useState("")
    const [addCustomer, setaddCustomer] = useState(false)
    const [representative, setRepresentative] = useState()
    const [exsistChanges, setExsistChanges] = useState(false)
    const [dialogExsistCustomerTransaction, setDialogExsistCustomerTransaction] = useState(false)
    const [exsistCustomerTransaction, setExsistCustomerTransaction] = useState("")
    const [openNewTransaction, setOpenNewTransaction] = useState('')
    const [customerTransaction, setCustomerTransaction] = useState('')
    const [draftList, setDraftList] = useState<any>([])
    const [addFactorDialog, setaddFactorDialog] = useState(false)
    const [/*showSpiner */, setshowSpiner] = useState(false)
    const [/*showDialogSave */, setShowDialogSave] = useState(false)
    const [titleMessageError, setTitleMessageError] = useState("")
    const [messageError, setMessageError] = useState("")
    const [serverMessageError, setServerMessageError] = useState(false)
    const [loading, { setTrue: displayLoading, setFalse: hideLoading }] = useBoolean(true);
    const [isAutoFocus, setIsAutoFocus] = useState<boolean>(false)

    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])

    useEffect(() => {
        if (datacConversion) {
            const { id_transaction } = datacConversion
            if (actions.id_transaction === null) {
                state !== "showCompleted" && isCreate && setSelectedDraft(id_transaction + ',' + datacConversion?.id_entity)
                setActions({ ...actions, "id_transaction": id_transaction })
                setIsCreate(false)
            }
            if (selectedDraft !== "" || state === "showCompleted") setDraftConversionsData()

        }
    }, [datacConversion])


    useEffect(() => {
        if (datacConversion && state?.source === "refresh") {
            setSelectedDraft(datacConversion?.id_transaction + ',' + datacConversion?.id_entity)
            setDraftConversionsData()
        }
    }, [datacConversion, state])


    useEffect(() => {
        if (dataCustomer) {
            setIsAutoFocus(false)
            hideLoading();
        }
        else {
            setIsAutoFocus(true)
            hideLoading();
        }
    }, [dataCustomer?.id_entity])

    useEffect(() => {
        
        if (searchCustomer) {
            if (searchCustomer?.isLocked === false ||
                (searchCustomer?.isLocked === true && datacConversion?.status_id === "draft")) {
                let arrInsiders: any[] = []
                let arrAccounts: object[] = []
                let financialAssets: any
                if (typeAction === "cheque_cashing") {
                    financialAssets = cloneDeep(actions?.financial_assets_in)
                    let prop: any = searchCustomer?.priceProgram?.price_program_properties
                    let properties: any = {};
                    prop?.forEach((element: any) => {
                        properties[element.name] = element.value ? element.value : element.percent;
                    });
                    let financialAssetsDetails = {
                        cashing_intrest: properties?.cheque_cashing_cash_rate ? (properties?.cheque_cashing_cash_rate * 100) : 1, cheque_days: 0
                        , cheque_value_days: properties?.cheque_cashing_value_days ? properties?.cheque_cashing_value_days : 0,
                        allowed_expenses: properties?.cheque_collection_fee ? properties?.cheque_collection_fee : 0, cheque_date: moment().format("YYYY-MM-DD")
                    }
                    financialAssets[0].financial_asset_details = financialAssetsDetails
                }
                let related_entities: any
                if (actions?.related_entities) related_entities = [...actions?.related_entities]
                searchCustomer?.insiders?.forEach((insider: any) => {
                    if (!insider?.is_deleted && (insider.insider_type_id?.toLocaleString() === "9" || insider.insider_type_id?.toLocaleString() === "10")) {
                        const isDefault = searchCustomer?.insiders.find((item: any) => item.is_default === true && item.insider_type_id?.toLocaleString() === "9" || item.insider_type_id?.toLocaleString() === "10")
                        if ((insider?.is_default && related_entities?.length === 0) || (!isDefault && related_entities?.length === 0)) {
                            setRepresentative(insider?.id_insider ? insider?.id_insider : insider?.id_entity)
                            related_entities.push({ id_entity: insider?.id_insider ? insider?.id_insider : insider?.id_entity, service_requisition_type_id: insider.insider_type_id })
                        }
                        insider?.id_insider ?
                            arrInsiders.push(
                                { key: insider?.id_insider, text: insider?.insider_name, typeId: insider.insider_type_id })
                            : arrInsiders.push({ key: insider?.id_entity, text: insider?.entity_name, typeId: insider.insider_type_id })
                    }
                })
                searchCustomer?.accounts?.forEach((account: any) => {
                    arrAccounts.push({ key: account?.id_account, text: account?.account_name, account_number: account?.account_number })
                })
                setListInsiders(arrInsiders)
                setListAccounts(arrAccounts)

                if (!actions?.id_account && arrAccounts.length > 1)
                    setReadOnly(true)
                else
                    setReadOnly(false)
                if (actions?.id_entity !== searchCustomer?.idEntity) {
                    if (actions?.id_transaction === null) {
                        if (typeAction === "cheque_cashing") {
                            setActions((action: any) => ({ ...action, "financial_assets_in": financialAssets, "id_entity": searchCustomer?.idEntity, "id_account": arrAccounts.length === 1 ? searchCustomer.accounts[0].id_account : "", "related_entities": related_entities }))
                        }
                        else {
                            setActions((action: any) => ({ ...action, "id_entity": searchCustomer?.idEntity, "id_account": arrAccounts.length === 1 ? searchCustomer.accounts[0].id_account : "", "related_entities": related_entities }))
                        }
                    }
                    if (actions?.id_transaction !== null) {
                        setActions((action: any) => ({ ...action, "id_entity": searchCustomer?.idEntity, "id_account": arrAccounts.length === 1 ? searchCustomer.accounts[0].id_account : "" }))
                    }
                }
            }
            else {
                setServerMessageError(true)
                setTitleMessageError(t("note"))
                setMessageError(t("customerlockedMassege"))
            }
        }

    }, [searchCustomer])

    useEffect(() => {
        let listConversion: object[] = []
        if (state !== "showCompleted") {
            if (selectConversionList?.length === 0) setDraftList([])
            selectConversionList?.forEach(async (item: any) => {
                listConversion.push({
                    key: item.id_transaction + "," + item.id_entity,
                    text: new Date(item.date_created).toLocaleDateString('he').split('T')[0] + " " + item.entity_name,
                })
            });
            if (selectConversionList?.length) {
                let index = selectConversionList?.findIndex((l: any) => l.id_entity === dataCustomer?.id_entity)

                if (index !== -1 && isCreate === false) {
                    setCustomerTransaction(selectConversionList[index].id_transaction + "," + selectConversionList[index].id_entity)
                    setExsistCustomerTransaction(t('exsistOpenTransactionToCustomer'))
                    setDialogExsistCustomerTransaction(true)
                }
                else {
                    if (dataCustomer?.id_entity && !actions?.id_transaction && isCreate === false) {
                        setSearchCustomer(customerInfoInObject(dataCustomer))
                    }
                }
            }
            else {
                if (dataCustomer?.id_entity && !actions?.id_transaction && isOpenTransaction) {
                    setSearchCustomer(customerInfoInObject(dataCustomer))
                }

            }
            listConversion?.length > 0 && setDraftList(listConversion)
        }
    }, [selectConversionList])


    useEffect(() => {
        if (openNewTransaction === "save") {
            setSearchCustomer(customerInfoInObject(dataCustomer))
        }
        if (openNewTransaction === "don't save") {
            getDraftData("", customerTransaction)
        }
    }, [openNewTransaction])

    useEffect(() => {
        async function openTransactions() {
            if (selectConversionList?.length === 0 && userCredentials) {
                setIsOpenTransaction(true)
                await getOpenTransactions(typeAction, userCredentials, setSelectConversionList)
            }
        }
        openTransactions()
    }, [userCredentials])

    useEffect(() => {
        async function openTransactions() {
            if (exsistChanges) {
                const [idTransaction, idEntity] = selectedDraft.split(",")
                if (datacConversion?.status_id === "draft") {
                    setIsOpenTransaction(true)
                    await getOpenTransactions(typeAction, userCredentials, setSelectConversionList)
                }
                dispatch(readDraftTransactions(typeAction, userCredentials, idEntity, idTransaction))
                setExsistChanges(false)
            }
        }
        openTransactions()
    }, [exsistChanges])

    useEffect(() => {
        if (saveBeforeChangeDraft === "save") {
            //ask if need the servermessages & spinner?
            dispatch(createTransaction("update", typeAction, searchCustomer?.entityName, actions, userCredentials, actions.id_entity, "", "", "", "", "", setExsistChanges))
            setIfChanges(false)
        }
        if (saveBeforeChangeDraft === "don't save") {
            setIfChanges(false)
            const [idTransaction, idEntity] = selectedDraft.split(",")
            dispatch(readDraftTransactions(typeAction, userCredentials, idEntity, idTransaction))
        }
    }, [saveBeforeChangeDraft])



    useEffect(() => {
        if (listInsiders?.length > 0) {
            if (!readOnly) {
                if (actions?.related_entities?.length === 1 && actions?.id_entity && actions?.id_account && actions.id_transaction === null) {
                    dispatch(createTransaction("create", typeAction, searchCustomer.entityName, actions, userCredentials, searchCustomer?.idEntity, "", "", "", "", setSelectConversionList))
                }
                setIsCreate(true)
            }
        }
        else {
            if (!readOnly && actions?.id_entity && actions?.id_account && actions.id_transaction === null) {
                dispatch(createTransaction("create", typeAction, searchCustomer.entityName, actions, userCredentials, searchCustomer?.idEntity, "", "", "", "", setSelectConversionList))
            }
            setIsCreate(true)
        }
        if (actions.id_transaction && actions?.related_entities.length > datacConversion?.related_entities?.length + 1 && representative
            || actions.id_transaction && actions?.related_entities.length > datacConversion?.related_entities?.length) {
            dispatch(createTransaction("update", typeAction, searchCustomer.entityName, actions, userCredentials, actions.id_entity))
        }

    }, [actions?.id_entity, actions?.related_entities, actions?.id_account])


    const setDraftConversionsData = () => {
        datacConversion?.related_entities?.forEach((entity: any) => {
            if (Number(entity.service_requisition_type_id) === 9 || Number(entity.service_requisition_type_id) === 10) {
                setRepresentative(entity?.id_entity)
            }
        })
    }

    const addRelatedEntity = (key: string, value: any) => {
        setIfChanges && setIfChanges(true)
        let arrRelated: any = [...actions?.related_entities]
        let relatedEntity = { id_entity: "", service_requisition_type_id: "" }
        let cuurentrepresentative: any
        cuurentrepresentative = listInsiders?.find((insider: any) => value === insider?.key)
        let index = arrRelated.findIndex((item: any) =>
            item.service_requisition_type_id === 9 || item.service_requisition_type_id === 10)
        if (index !== -1) {
            relatedEntity = {
                id_entity: value,
                service_requisition_type_id: cuurentrepresentative?.typeId
            }
            arrRelated[index] = relatedEntity
        }
        else {
            relatedEntity = {
                id_entity: value,
                service_requisition_type_id: cuurentrepresentative?.typeId
            }
            arrRelated.push(relatedEntity)
        }
        setRepresentative(value)
        setReadOnly && setReadOnly(false)
        setActions && setActions({ ...actions, "related_entities": arrRelated })
    }

    const getDraftData = (key: string, value: any) => {
        setSaveBeforeChangeDraft("")
        if (!ifChanges || datacConversion?.status_id === "completed") {
            setSelectedDraft(value)
            const [idTransaction, idEntity] = value.split(",")
            dispatch(readDraftTransactions(typeAction, userCredentials, idEntity, idTransaction))
        }
        else {
            if (ifChanges && datacConversion?.status_id !== "completed") {
                setSelectedDraft(value)
                setDialogBeforeChangeDraft(true)
            }
        }
    }

    const change = async (key: any, value: any) => {
        setReadOnly(false)
        setIfChanges && setIfChanges(true)
        await setActions && setActions({ ...actions, [key]: value })
    }
    return (
        <>
            <div className={`content-div-${dir} div-entity-details-${dir}`}>

                {serverMessageError && <DialogMessages setansDelete={setServerMessageError} subText={messageError} title={titleMessageError}
                    setshowModalDelete={setServerMessageError} dialogType={"dialogMessage"} />}
                {dialogBeforeChangeDraft && <DialogMessages setansDelete={setDialogBeforeChangeDraft}
                    setshowModalDelete={setDialogBeforeChangeDraft} dialogType={"saveConversionData"}
                    setSaveDataConversion={setSaveBeforeChangeDraft} />}

                {dialogExsistCustomerTransaction && <DialogMessages setansDelete={setDialogExsistCustomerTransaction}
                    setshowModalDelete={setDialogExsistCustomerTransaction} subText={exsistCustomerTransaction} title={t('note')}
                    dialogType={"dialogMessage"} setSaveDataConversion={setOpenNewTransaction} />}

                {!loading && (
                    <div className="wrap-gates" >
                        <div className="wrap-title-gates" >
                            <div className={`attechedAConDetails entity-details-gaes-${dir}`}>
                                <p className="title-text">{t("customerDetails")}</p>
                                {datacConversion?.status_id === "completed" && state === "showCompleted" ? "" : <div style={{ justifyContent: "space-between", display: "inline-flex" }} >
                                    <DialogSearch autoFocus={isAutoFocus} setReadOnly={setReadOnly} userCredentials={userCredentials}
                                        isSearchInsiders={false} setRelatedFactors={setSearchCustomer} type={"searchCustomerConversion"} />
                                    <PrimaryButton style={{ marginTop: "0px", marginLeft: "0px" }} className='button' allowDisabledFocus checked={false} onClick={() => setaddCustomer(true)} name="add" text={t("createCustomer")} />
                                    {addCustomer && <BeneficiaryDialog setShowDialog={setaddCustomer} setnewBenObject={setSearchCustomer} setidBeneficiary={setActionCustomerId} salvo={salvo} />}

                                </div>}
                            </div>
                            <hr className="hr" style={{ marginBottom: '4px' }}></hr>
                            {/* //isLocked */}
                            <div style={{ display: "flex" }}> {(searchCustomer && (searchCustomer?.isLocked === false
                                || (searchCustomer?.isLocked === true && datacConversion?.status_id === "draft"))) ?

                                <div className="wrapDetails" style={{ width: "100%" }} >
                                    <div style={{ display: "flex" }}>
                                        <div style={{ fontSize: "15px", padding: "0px 4px", marginTop: "39px" }}>
                                            {searchCustomer?.isFullyIdentified === false || !searchCustomer?.idNumber || searchCustomer?.idNumber?.length === 0 ?
                                                <i style={{ color: "red" }} className="fal fa-times" title="ללקוח זה לא קיים כל המסמכים הנדרשים"> </i>
                                                : searchCustomer?.isFullyIdentified === true && <i style={{ color: "#008000" }} className="fal fa-check" title="ללקוח זה קיים כל המסמכים הנדרשים"></i>}
                                        </div><div>
                                            <p className="boldD" style={{ fontSize: "22px" }}><label className="boldD" style={{ marginBottom: "0%", fontSize: "19px", height: "25px" }}>{t("name")}</label>{searchCustomer?.firstName && searchCustomer?.firstName}   {searchCustomer?.lastName && searchCustomer?.lastName}:  {searchCustomer?.customerTypeName && searchCustomer?.customerTypeName}</p>
                                            <p className="normal" style={{ fontSize: "19px", marginBottom: "0%" }}>{searchCustomer?.idNumber ? searchCustomer?.idNumber : ""}</p>
                                        </div></div>
                                    {searchCustomer?.address ? <div>
                                        <p className="normal" style={{ fontSize: "19px", marginBottom: "0%" }}><label className="boldD" style={{ marginBottom: "0%", fontSize: "19px" }}>{t("address")}</label>{searchCustomer?.addressName && searchCustomer?.addressName}  {searchCustomer?.addressNumber && searchCustomer?.addressNumber}</p>
                                        <p className="normal" style={{ fontSize: "19px", marginBottom: '0' }}>{searchCustomer?.addressCityName && searchCustomer?.addressCityName}</p>
                                    </div> : ""}
                                    {searchCustomer?.telephone ? <div>
                                        <p className="normal" style={{ fontSize: "19px" }}><label className="boldD" style={{ marginBottom: "0%", fontSize: "19px" }}>{t("phone")}</label>{searchCustomer?.telephone && searchCustomer?.telephone}</p>
                                        <p className="normal" style={{ fontSize: "19px", marginBottom: '0' }}>{ }</p>
                                    </div> : ""}
                                    <div style={{ width: "27.7%" }}>
                                        {listInsiders?.length > 1 ? <> <p className="boldD" style={{ marginBottom: "0%", fontSize: "19px" }}>{t("representative")}</p>
                                            <CustomDropdown otherInputId={''} readOnly={searchCustomer?.insiders?.length ? false : true} hasOtherValue={false} options={listInsiders} label={''} onChange={addRelatedEntity} selectedKey={representative} id={'representative'} othertextInput={t('')} /></>
                                            : listInsiders?.length === 1 ? <> <p className="boldD" style={{ marginBottom: "0%", fontSize: "19px" }}>{t("representative")}</p> <CustomTextField readOnly={readOnly} value={listInsiders[0].text} onChange={""} id={"representative"} /></>
                                                : ""}
                                        <PrimaryButton style={{ width: "100%", margin: listAccounts?.length > 1 ? "-5px" : "0", marginTop: listAccounts?.length > 1 ? "27px" : "8px" }} className='button' disabled={readOnly} allowDisabledFocus checked={false} onClick={() => setaddFactorDialog(true)} name="add" text={t("addRepresentativeFactor")} iconProps={Icons.addFriend} />
                                        {addFactorDialog &&
                                            <EditableRelatedFactors idEntityTransaction={searchCustomer?.idEntity} setShowDialog={setaddFactorDialog} setShowDialogSave={setShowDialogSave} setshowSpiner={setshowSpiner} />}
                                    </div>
                                    {listAccounts?.length > 1 ? <div style={{ width: "25%" }}>
                                        <p className="boldD">{t("customer_accounts")}</p>
                                        <CustomDropdown otherInputId={''} readOnly={searchCustomer?.accounts?.length ? false : true} hasOtherValue={false} options={listAccounts} label={''} onChange={change} selectedKey={actions?.id_account} id={'id_account'} othertextInput={t('')} />
                                    </div> : ""}
                                </div> : <div style={{ marginTop: "18px" }}>{t('noDisplay')}</div>}

                            </div>

                            {searchCustomer ? <hr className="hr" style={{ marginTop: "4px" }}></hr> : ""}
                        </div>
                    </div>)}
            </div >

            <div className={`Ongates-${dir}`}>
                <div className={`${typeAction === "cheque_cashing" && 'Ongates-discount'}`}>
                    {datacConversion?.status_id === "completed" && state === "showCompleted" ?
                        <div className={`dic-disply-${dir}`}>
                            <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px", paddingTop: "25px", marginBottom: "4px" }}> {t('displayOnly')}</div>
                            <PrimaryButton className={`bottun-${dir}`} style={{ width: "235px", marginBottom: "4px", backgroundColor: "green", borderColor: "green", cursor: "default" }} allowDisabledFocus disabled={actions.id_transaction ? false : true} checked={false} name="cancel" text={t('printCopy')} iconProps={Icons.print} />
                            <PrimaryButton className={`bottun-${dir}`} style={{ width: "235px", marginBottom: "4px", cursor: "default" }} allowDisabledFocus checked={false} name="new" text={t('newTransaction')} />
                            <PrimaryButton className={`bottun-${dir}`} style={{ width: "235px", marginBottom: "4px", backgroundColor: "red", borderColor: "red", cursor: "default" }} allowDisabledFocus disabled={actions.id_transaction ? false : true} checked={false} name="cancel" text={t('cancelTransaction')} iconProps={Icons.addFriend} />
                        </div>
                        : <div style={{ marginTop: "-9px", width: "197px" }}>
                            <CustomDropdown otherInputId={''} label={t("openTransactions") + " (" + draftList.length + ")"} IsTabIndex={selectedDraft && true} onKeyDown={(e: any) => e.key === "Tab"} disabled={datacConversion?.status_id === "completed" && state === "showCompleted"} hasOtherValue={false} options={draftList} onChange={getDraftData} selectedKey={selectedDraft} id={'draftTransacsionId'} othertextInput={t('')} />
                        </div>}
                </div>
            </div>

        </>
    )
}