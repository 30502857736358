import axios from "axios";
import { ICustomer, IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { basicUrl } from "../../shared/config";
import { checkHttpStatus } from "../../utils";
import { SET_USERS } from '../actions/types';
import { returnErrors } from "./errorActions";
import { CreateAddress, updateAddress } from './addressAction'
import { CreatePhone, updatePhone } from './phonecAction'
import { CreateEmail, updateEmail } from './emailAction'
import { setTypeId } from '../../shared/components/CustomHooks/CheckCutomerType'

let currUserCredentials: IUserCredentials | null = null

export const createUser = (customer: ICustomer, userCredentials: IUserCredentials, setshowSpiner?: any, setShowDialogCreate?: any) => async (dispatch: any,) => {
    currUserCredentials = userCredentials
    // Headers
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    const createCustomer = {
        "id_initiator": currUserCredentials["idInitiator"],
        "id_client": currUserCredentials["idClient"],
        "id_branch": currUserCredentials["idBranch"],
        "entity_request_method": "create",
        "ID_country_code": customer.idTypeCountryCode,
        "ID_number": customer.idNumber,
        "ID_type_id": customer.idTypeId,
        "status_id": customer.entityStatusId,
        "class_id": 1,
        "entity_type_id": "user",
        "entity_sub_type_id": 1,
        "gender_other": customer.genderName,
        "ID_type_other": customer.idTypeOther,
        "first_name": customer.firstName,
        "last_name": customer.lastName,
        "middle_name": customer.middleName,
        "entity_name": (customer.lastName + " " + customer.firstName),
        "first_name_en": "Amit",
        "last_name_en": "Keresanty",
        "entity_name_en": "Amit Keresanty",
        "date_birth": customer.dateBirth,
        "gender_id": customer.gender,
        "id_identifier": customer?.workerName ? customer?.workerName : currUserCredentials && currUserCredentials["idInitiator"],
        "is_identified": true,
        "is_loaded_documentation": false,
        "is_locked": true,
        "note": customer.note,
        "permission_group_id": customer?.permission_group_id,
        "return_entity": true,
        "user_language": customer?.user_language,
        "user_time_zone": 'Israel Standard Time'
    }
    const body = JSON.stringify(createCustomer);
    await axios.post(basicUrl + '/entity', body, config)
        .then(checkHttpStatus)
        .then(async (res) => {
            try {
                if (res.status === 200 && res.data["err_code"] === 0) {
                    await CreateAddress(customer, userCredentials, res.data.data.id_entity);
                    await CreatePhone(customer, userCredentials, res.data.data.id_entity);
                    await createUserName(customer, userCredentials, res.data.data.id_entity)
                    let isCreate = await CreateEmail(customer, userCredentials, res.data.data.id_entity)
                    await dispatch(readUser(userCredentials.idBranch, userCredentials))
                    setshowSpiner(false)
                    setShowDialogCreate(true)
                    return isCreate;
                }
                else {
                    setshowSpiner(false)
                }
            }
            catch (e) {
                setshowSpiner(false)
            }
        })
        .catch(err => {
            err.response ? returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL') : returnErrors('the server is down pls try later', 'LOGIN_FAIL')

            console.warn('error in login component', err)
        })
    return true;
}
export const createUserName = async (customer: ICustomer, userCredentials: IUserCredentials, id_entity: string) => {
    currUserCredentials = userCredentials
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    const user = {
        "id_initiator": currUserCredentials["idInitiator"],
        "id_client": currUserCredentials["idClient"],
        "id_branch": currUserCredentials["idBranch"],
        "id_entity": id_entity,
        "return_entity": 1,
        "attribute_request_method": "create",
        "attribute": "login",
        "entity_type_id": "user",
        "login_entity_number": customer?.login_entity_number,
        "login_ID": customer?.login_ID,
        "login_password": customer?.login_password
    }
    const body = JSON.stringify(user);

    axios.post(basicUrl + '/entity', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        // const customerInfo = res.data.data;
                        // dispatch({
                        //     type: SET_USERS,
                        //     payload: customerInfo.users
                        // })

                    }
                }
            }
            catch (err) {
                // err.message
                console.log('error in get info customer',)
            }
        })
}
export const readUser = (idEntity: string, userCredentials: IUserCredentials | null) => async (dispatch: Function) => {
    const readCustomer = {
        "entity_request_method": "read",
        "id_initiator": userCredentials && userCredentials.idInitiator,
        "id_branch": userCredentials && userCredentials.idBranch,
        "id_client": userCredentials && userCredentials.idClient,
        "id_entity": idEntity
    }
    const body = JSON.stringify(readCustomer);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/entity', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        const customerInfo = res.data.data;
                        dispatch({
                            type: SET_USERS,
                            payload: customerInfo.users
                        })

                    }
                }
            }
            catch (err) {
                console.log('error in get info customer',)
            }
        })
}


export const deleteUser = (userCredentials: IUserCredentials, id_entity: string, userId: any, setShowDialogUpdate?: any, setshowSpiner?: any) => {

    const deleteDetails = {
        "id_initiator": userCredentials["idInitiator"],
        "id_entity": userId,
        "id_client": userCredentials["idClient"],
        "id_branch": userCredentials["idBranch"],
        "entity_type_id": "user",
        "attribute_request_method": "delete",
        "attribute": "login",
        "return_entity": 1
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");

    const body = JSON.stringify(deleteDetails);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/entity', body, config)
        .then(res => {
            try {

                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        setShowDialogUpdate(true);
                        setshowSpiner(false)
                        return true;
                    }
                    else {
                        setshowSpiner(false)
                    }
                }
            }
            catch (err) {
                setshowSpiner(false)
                console.warn('error in deleted customer', err)
            }
        })
        .catch(err => {
            console.log("error", err.message);
            setshowSpiner(false)
            return false;
        })
}


export const updateUserInfo = (customer: ICustomer, userCredentials: IUserCredentials, idEntity: string, setShowDialogCreate: any, setshowSpiner?: any) => async (dispatch: Function) => {
    const updateDetails = {
        "id_initiator": userCredentials.idInitiator,
        "id_branch": userCredentials.idBranch,
        "id_client": userCredentials.idClient,
        "id_entity": idEntity,
        "ID_type_other": customer.idTypeOther ? customer.idTypeOther : null,
        "entity_type_id": "user",
        "entity_request_method": "update",
        "status_id": customer.entityStatusId,
        "first_name": customer.firstName,
        "middle_name": customer.middleName ? customer.middleName : null,
        "gender_id": customer.gender,
        "gender_other": customer.genderName ? customer.genderName : null,
        "return_entity": true,
        "last_name_en": "Hooooome",
        "ID_country_code": customer.idTypeCountryCode,
        "ID_number": customer.idNumber,
        "ID_type_id": customer.idTypeId,
        "class_id": 1,
        "customer_type_id": customer?.customerTypeId ? customer?.customerTypeId : await setTypeId(customer?.idTypeId),
        "entity_sub_type_id": 1,
        "last_name": customer.lastName,
        "entity_name": (customer.lastName + " " + customer.firstName),
        "first_name_en": "Amit",
        "entity_name_en": "Amit Keresanty",
        "industry_classification_id": customer.industryId ? customer.industryId : null,
        "industry_sub_classification": customer.CreditGroup ? customer.CreditGroup : null,
        "date_birth": customer.dateBirth,
        //d
        // "date_birth": moment(customer.dateBirth).format("YYYY-MM-DD"),
        "id_identifier": userCredentials.idInitiator,
        "is_identified": true,
        "is_loaded_documentation": false,
        "is_locked": customer.isLocked,
        "note": customer.note ? customer.note : null,
        "permission_group_id": customer?.permission_group_id,
        "user_language":customer?.user_language


    }
    console.log(updateDetails,"updateDetails");
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(updateDetails);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    axios.post(basicUrl + '/entity', body, config)
        .then(async res => {
            debugger
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        customer?.emailId ?
                            await updateEmail(customer, userCredentials, idEntity, customer.emailId, '', '', '', "user")
                            : customer?.email && await CreateEmail(customer, userCredentials, res.data.data.id_entity, "", "user")
                        customer?.addressId ? await updateAddress(customer, userCredentials, idEntity, customer.addressId, '', '', '', "user")
                            : customer?.addressCity && await CreateAddress(customer, userCredentials, res.data.data.id_entity, "", "user");
                        customer?.telephoneId ? await updatePhone(customer, userCredentials, idEntity, customer.telephoneId, '', '', '', "user")
                            : customer?.telephone && await CreatePhone(customer, userCredentials, res.data.data.id_entity, "", "user");
                        customer?.login_password && await updateUserName(customer, userCredentials, idEntity, '', '', '', '', "user")
                        // await createUserName(customer, userCredentials, res.data.data.id_entity)
                        dispatch(readUser(userCredentials.idBranch, userCredentials))
                        setShowDialogCreate(true)
                        setshowSpiner(false)
                        // dispatch({
                        //     type: SET_USERS,
                        //     payload: res.data.data.setting
                        // })
                        // return true;
                    }
                    else {
                        setshowSpiner(false)
                    }
                }
            }
            catch (err) {
                console.warn('error in update customer', err)
                setshowSpiner(false)
            }
        })
        .catch(err => {
            console.log("error", err.message);
            return false;
        })
}


// export const createUserlMedia = (userId: string, userMedia: any, userCredentials: IUserCredentials, idEntity: string) => async (dispatch: Function) => {

//     currUserCredentials = userCredentials
//     const createUserMedia = {
//       "id_entity": idEntity,
//       "id_initiator": currUserCredentials["idInitiator"],
//       "id_branch": currUserCredentials["idBranch"],
//       "id_client": currUserCredentials["idClient"],
//     //   "collateral_id": collateralId,
//       "type_id": userMedia.type,
//       "media_name": userMedia.name,
//       "media_exp_date": userMedia.expirationDate,
//       "details": userMedia.note,
//       "return_entity": 1,
//       "attribute_request_method": "create",
//       "attribute": "collateral_media",
//       "entity_type_id": "user"
//     }

//     const body = JSON.stringify(createUserMedia);
//     const config = {
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     };

//     axios.post(basicUrl + '/entity', body, config)
//       .then(res => {
//         try {
//           if (res.status === 200) {
//             if (res.data["err_code"] === 0) {
//               dispatch({
//                 type: READ_CUSTOMER,
//                 payload: res.data.data
//               })
//             }
//           }
//         }
//         catch (err) {
//           console.log('error in createCollateralMedia', )
//         }
//       })
//   }
export const updateUserName = async (customer: ICustomer, userCredentials: IUserCredentials, currentIdEntity: string, id: any, type?: any, setShowDialogSave?: any, setReadOnly?: any, setshowSpiner?: any) => {
    currUserCredentials = userCredentials
    const userName = {
        "id_initiator": currUserCredentials["idInitiator"],
        "id_client": currUserCredentials["idClient"],
        "id_branch": currUserCredentials["idBranch"],
        "id_entity": currentIdEntity,
        "attribute_request_method": "update",
        // "login_finger_prints": "oilkl– lkoijhj– i987– 9089",
        "entity_type_id": "user",
        "login_entity_number": customer?.login_entity_number,
        // "ip": "123-456-789",
        "attribute": "login",
        "login_ID": customer?.login_ID,
        "login_password": customer?.login_password,
        "return_entity": 1,
    }
    const body = JSON.stringify(userName);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    await axios.post(basicUrl + '/entity', body, config).then(res => {
        try {
            if (res.status === 200) {
                if (res.data["err_code"] === 0) {
                    setShowDialogSave && setShowDialogSave(true)
                    setReadOnly && setReadOnly(true)
                    if (setshowSpiner)
                        setshowSpiner(false)

                }
            }
        }
        catch (err) {
            if (setshowSpiner)
                setshowSpiner(false)
            console.log('error in update address', err)
        }
    }).catch(err => {
        console.log("error", err.message);
    })
}


