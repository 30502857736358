import axios from "axios";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { basicUrl } from "../../shared/config";

export const CreateCustomizedPlan = async () => {
    const plan = {
        "id_initiator": "14aeeb2e-84dd-eb11-8518-18473d5cd3d0",
        "id_client": "615500a1-6f43-487f-93a6-68f16c5340c8",
        "id_branch": "44900e18-a873-4cdc-8383-3778301dee85",
        "id_entity": "b1a7c5e0-1e13-ec11-8535-18473d5cd3d0",
        "attribute_request_method": "update",
        "is_linked_base_template": false,
        "attribute": "price_program",
        "entity_type_id": "customer",
        "return_entity": 1,
        "price_program_properties": [
            {
                "type": "fee",
                "fee_name": "new_account_fee",
                "description ": " פתיחת חשבון",
                "calculation_type": "const",
                "value": 0,
                "order": 1
            },
            {
                "type": "fee",
                "fee_name": "israeli_cheque_deposit_fee",
                "description ": "עמלת הפקדה שיק ישראלי",
                "calculation_type": "const",
                "value": 0,
                "order": 2
            },
            {
                "type": "fee",
                "fee_name": "foreign_cheque_deposit_fee",
                "description ": "עמלת הפקדת שיק מטח",
                "calculation_type": "const",
                "value": 1,
                "order": 3
            },
            {
                "type": "fee",
                "fee_name": "cheque_refund_fee",
                "description ": "עמלת החזר שיק",
                "calculation_type": "const",
                "value": 40,
                "order": 4
            },
            {
                "type": "fee",
                "fee_name": "repeat_deposit_fee",
                "description ": "עמלת הפקדה חוזרת",
                "calculation_type": "const",
                "value": 17,
                "order": 5
            },
            {
                "type": "fee",
                "fee_name": "wire_rtgs_fee",
                "description ": "עמלת העברת זהב",
                "calculation_type": "const",
                "value": 40,
                "order": 6
            },
            {
                "type": "fee",
                "fee_name": "wire_fee",
                "description ": "עמלת העברה רגילה",
                "calculation_type": "const",
                "value": 25,
                "order": 7
            },
            {
                "type": "fee",
                "fee_name": "abroad_wire_fee",
                "description ": "עמלת העברה לחו\"ל",
                "calculation_type": "const",
                "value": 120,
                "order": 8
            },
            {
                "type": "fee",
                "fee_name": "cheque_collection_fee",
                "description ": " Aדמי גביה לכל שיק בניכיון",
                "calculation_type": "const",
                "value": 17,
                "order": 9
            },
            {
                "type": "fee",
                "fee_name": "markup_conversion_fee",
                "description ": " המרה מעל מחיר שוק",
                "calculation_type": "const",
                "value": 0,
                "order": 10
            },
            {
                "fee": "fee",
                "fee_name": "cheque_cashing_cash_rate",
                "description ": "ריבית ניכיון שיק מזומן",
                "calculation_type": "percent",
                "value_max": 25,
                "value_min": 999999,
                "percent": 0.01,
                "order": 11
            },
            {
                "fee": "fee",
                "fee_name": "cheque_cash_fee",
                "description ": "עמלת קבלת מזומן",
                "calculation_type": "percent",
                "value_max": 1,
                "value_min": 1,
                "percent": 1,
                "order": 12
            },
            {
                "fee": "fee",
                "fee_name": "cheque_cashing_rate",
                "description ": "ריבית ניכיון שיק דחוי",
                "calculation_type": "percent",
                "value_max": 0,
                "value_min": 999999,
                "percent": 0.025,
                "order": 13
            },
            {
                "fee": "fee",
                "fee_name": "arrears_interest",
                "description ": " ריבית פיגורים על יתרת חובה",
                "calculation_type": "percent",
                "value_max": 1,
                "value_min": 1,
                "percent": 0.5,
                "order": 14
            }
        ]
    }

    const body = JSON.stringify(plan);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    await axios.post(basicUrl + '/entity', body, config).then(res => {
        try {
            if (res.status === 200) {
                if (res.data["err_code"] === 0) {
                }
            }
        }
        catch (err) {
            console.log('error in ceate price plan component', err)
        }
    })
        .catch(err => {
            console.log("error", err.message);
        })

}


export const UpdateDefaultPlan = async (userCredentials: IUserCredentials, idEntity: any, baseTemplateId: any, updateItems: any, setShowDialogSave: any) => {
    const plan = baseTemplateId === undefined ?
        {
            "id_initiator": userCredentials.idInitiator,
            "id_client": userCredentials.idClient,
            "id_branch": userCredentials.idBranch,
            "id_entity": idEntity,
            "attribute_request_method": "update",
            "attribute": "price_program",
            "entity_type_id": "customer",
            "return_entity": 1,
            "is_linked_base_template": 0,
            "price_program_properties": updateItems

        } :
        {
            "id_initiator": userCredentials.idInitiator,
            "id_client": userCredentials.idClient,
            "id_branch": userCredentials.idBranch,
            "id_entity": idEntity,
            "attribute_request_method": "update",
            "base_template_id": baseTemplateId,
            "attribute": "price_program",
            "entity_type_id": "customer",
            "return_entity": 1,
            "is_linked_base_template": 1
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
    const body = JSON.stringify(plan);
    console.log("body price", body, plan);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    let res = await axios.post(basicUrl + '/entity', body, config)
    try {
        if (res.status === 200) {
            if (res.data["err_code"] === 0) {
                setShowDialogSave(true)
            }
        }
    }
    catch (err) {
        console.log('error in update price plan component', err)
    }


}
