import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';

import Subtitle from '../shared/components/Subtitle';
import { DialogMessages } from '../shared/components/dialog/DialogMessages';
import HeaderUtils from '../shared/components/headerUtils/HeaderUtils'
import CustemTable from '../shared/components/tabels/tableList';
import { CustomDialogBankAccount } from '../shared/components/dialog/Dialog'
import { IUserCredentials } from './customerDetails/CustomerDetails';
import './BankAccount.scss'
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../shared/components/CustomHooks/GlobalFunctions';


const BankAccounts = () => {
  const [t] = useTranslation();
  const userData = useSelector(({ customerReducer }: any) => customerReducer.dataCustomer)
  const [itemsBankAccount, setItemsBankaccounts] = useState([{}])
  const authReducer = useSelector(({ authReducer }: any) => authReducer)
  const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
  const [dir,setDir]=useState("")
  const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const [idEntity, setidEntity] = useState("")
  const [showDialogSave, setShowDialogSave] = useState(false)
  const [showSpiner, setshowSpiner] = useState(false)
  const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
  const location = useLocation()
  const columns = [
    { key: '2', name: t('accountNumber'), fieldName: "accountNumber", entity_name: "accountNumber", minWidth: 80, maxWidth: 80 },
    { key: '3', name: t('bank'), fieldName: "bankName", entity_name: "bankName", minWidth: 140, maxWidth: 140 },
    { key: '4', name: t('branch'), fieldName: "branchDisplay", entity_name: "branch", minWidth: 230, maxWidth: 230 },
    { key: '5', name: t('branchAddress'), fieldName: "branchAddress", entity_name: "branchAddress", minWidth: 250, maxWidth: 250 },
    //not delete
    // { key: '6', name: t('IBAN'), fieldName: "IBAN", entity_name: "IBAN", minWidth: 200, maxWidth:200 },
    { key: '7', name: t('edit'), fieldName: "edit", entity_name: "edit", minWidth: 90, maxWidth: 90, },
  ];
  const spinnerStyles: Partial<ISpinnerStyles> = {
    root: {
      position: 'fixed',
      zIndex: '999999',
      fontWeight: 'bold',
    },
    circle: {
      color: '#FFB400 ',
      height: '100px',
      width: '100px',
    },
  }

  useEffect(()=>{
    if(lang){
        setDir(lang)
    }
    },[lang])
    
  useEffect(() => {
    setUserCredentials({
      "idInitiator": authReducer.data.user.id_entity,
      "idClient": authReducer.data.user.id_client,
      "idBranch": authReducer.data.branch.id_entity,
      "idClientData": authReducer.data.client.id_client
    })

  }, [authReducer]);

  useEffect(() => {
    setidEntity(userData?.id_entity)
    bankAccountData()
    // eslint-disable-next-line
  }, [userData])



  const bankAccountData = () => {
    let arrBankAccounts: object[] = []
    userData?.bank_accounts?.forEach((b: any, index: number) => {
      b.is_deleted === false && arrBankAccounts.push({
        key: b.bank_account_id, accountNumber: b.bank_account_number, bank: b.bank_code,
        bankName: b.bank_name, branchName: b.bank_branch_name, branchAddress: b.bank_branch_address,
        //not delete
        // IBAN: b.IBAN,
        branch: b.country_code === "IL" ? b.bank_branch_code : b.bank_branch_name,
        note: b?.details, country: b.country_code, branchDisplay: b.country_code === "IL" ? b?.bank_branch_name : b?.bank_branch_name,
        routingNumber: b?.routing_number
      })
    });
    setItemsBankaccounts(arrBankAccounts)
  }

  const [addAccount, setaddAccount] = useState(false)

  return (
    <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
    ? 'page-read' : 'page-read-only'}`}>

      <HeaderUtils title={"customers"} createBtn={false} editBtn={false} deleteBtn={false} uploadBtn={false} disabledSave={true}
        createXl={false} createPrint={false} createPDF={false} />
      <br></br><br></br>
      {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`}  />}
      {showDialogSave === true && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"save"} />}
      <Subtitle title={t('bankAccounts')} />
      <div className={`content-div-${dir}`}>
        <div className={`attechedAdd div-account-${dir}`} >
          <p className="title-text" style={{ width: "870px" }} >{t('accounts_table')}</p>
          <div className="title-text addFile" onClick={() => setaddAccount(true)} ><i className="fal fa-plus"></i></div>
          {addAccount ? <CustomDialogBankAccount notShowHolderButton={true} setShowDialog={setaddAccount} setshowSpiner={setshowSpiner} userCredentials={userCredentials} idEntity={idEntity} setShowDialogSave={setShowDialogSave} /> : ""}
        </div>
        <hr className="hr" style={{ width: "870px" }}></hr>
      </div>
      <div className={`div-2-account-${dir}`}>
        {itemsBankAccount.length === 0 ? <div style={{ textAlign: "center", marginTop: "18px", width: "90%" }}>{t('noDisplay')}</div> :
        <div style={{textAlign:"left"}}> <CustemTable tableName="bankAccounts" setshowSpiner={setshowSpiner} columns={columns} allItems={itemsBankAccount} 
        renderItemColumn rederRow={"edit"} addCustem={true} isFooter={false} /></div>}</div>
    </div>
  );
}

export default BankAccounts;