import axios from "axios";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { SET_SETTINGS, SET_EMAIL_SETTING, SET_MAIN_ACCOUNTS, SET_USER_SETTINGS } from '../actions/types';
import { basicUrl } from "../../shared/config";
import { cloneDeep } from "lodash";

let currUserCredentials: IUserCredentials | null = null

export const settingUpdate = (settingDetails: any, userCredentials: IUserCredentials | null, idEntity: string, setShowDialogSave?: any, setShowDialogFailed?: any, setshowSpiner?: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const updateSetting = {
        "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
        "id_client": currUserCredentials && currUserCredentials["idClient"],
        "id_branch": currUserCredentials && currUserCredentials["idBranch"],
        "id_entity": currUserCredentials && currUserCredentials["idBranch"],
        "return_entity": 1,
        "attribute_request_method": "update",
        "attribute": "setting",
        "entity_type_id": "branch",
        "city": settingDetails.city,
        "email_address": settingDetails.email_address,
        "vat_classification_id": settingDetails.vat_classification_id,
        "vat_classification_name": settingDetails.vat_classification_name,
        "impa_code": settingDetails.impa_code,
        "impa_branch_number": settingDetails.impa_branch_number,
        "entity_ID_number": settingDetails.entity_ID_number,
        "logo": "base64",
        "note_1": settingDetails.note_1,
        "note_2": settingDetails.note_2,
        "postal_code": settingDetails.postal_code,
        "reporting_frequency": settingDetails.reporting_frequency,
        "round_digits": settingDetails.round_digits,
        "vat_rate": settingDetails?.vat_rate,
        // "branch_number":"001",
        "cma_branch_number": settingDetails?.cma_branch_number,
        "prevent_transactions_none_authorized": settingDetails?.prevent_transactions_none_authorized === true ? 1
            : settingDetails?.prevent_transactions_none_authorized === false ? 0 :
                settingDetails?.prevent_transactions_fully_identified === undefined && 1,
        "prevent_transactions_fully_identified": settingDetails?.prevent_transactions_fully_identified === true ? 1
            : settingDetails?.prevent_transactions_fully_identified === false ? 0 :
                settingDetails?.prevent_transactions_fully_identified === undefined && 1,
        "branch_name": settingDetails.branch_name,
        "compliance_officer_name": settingDetails.compliance_officer_name,
        "compliance_officer_ID_number": settingDetails.compliance_officer_ID_number,
        "manager_name": settingDetails.manager_name,
        "currency_shortcuts": settingDetails.currency_shortcuts,
        "manager_name_ID_number": settingDetails.manager_name_ID_number,
        "manager_ID_number": settingDetails.manager_ID_number,
        "address": settingDetails.address,
        // "entity_vat_classification_id": settingDetails.entity_vat_classification_id,
        "fax_number": settingDetails.fax_number,
        "telephone_number": settingDetails.telephone_number,
        "structuring": settingDetails?.structuring,
        "near_threshold": settingDetails?.near_threshold,
        "annual_turnover":settingDetails?.annual_turnover,
        "cash_turnover": settingDetails?.cash_turnover,
        // "cheque_scanning_popup": settingDetails?.cheque_scanning_popup
    }
    const body = JSON.stringify(updateSetting);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/entity', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        dispatch({
                            type: SET_SETTINGS,
                            payload: res.data.data.setting
                        })
                       setshowSpiner&& setshowSpiner(false)
                       setShowDialogSave&& setShowDialogSave(true)

                    }
                    else {
                        setshowSpiner && setshowSpiner(false)
                    }
                }
                else {
                    setshowSpiner && setshowSpiner(false)
                }
            }
            catch (err) {
                setShowDialogFailed && setShowDialogFailed(true)
                setshowSpiner && setshowSpiner(false)
                console.log('error in update colletrall', err)
            }
        })
}



export const settingUsersUpdate = (settingDetails: any, userCredentials: IUserCredentials | null, idEntity: string, setShowDialogSave?: any, setShowDialogFailed?: any, setshowSpiner?: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const updateSetting = {
        "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
        "id_client": currUserCredentials && currUserCredentials["idClient"],
        "id_branch": currUserCredentials && currUserCredentials["idBranch"],
        "id_entity":idEntity,
        "return_entity": 1,
        "attribute_request_method": "update",
        "attribute": "setting",
        "entity_type_id": "user",
        "expected_volume_activity": settingDetails?.expected_volume_activity,
        "currency_shortcuts": settingDetails.currency_shortcuts,
        "structuring": settingDetails?.structuring,
        "near_threshold": settingDetails?.near_threshold,
        "cash_turnover": settingDetails?.cash_turnover,
        "cheque_scanning_popup": settingDetails?.cheque_scanning_popup
    }
    const body = JSON.stringify(updateSetting);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/entity', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        dispatch({
                            type: SET_USER_SETTINGS,
                            payload: res.data.data.setting
                        })
                       setshowSpiner&& setshowSpiner(false)
                       setShowDialogSave&& setShowDialogSave(true)

                    }
                    else {
                        setshowSpiner && setshowSpiner(false)
                    }
                }
                else {
                    setshowSpiner && setshowSpiner(false)
                }
            }
            catch (err) {
                setShowDialogFailed && setShowDialogFailed(true)
                setshowSpiner && setshowSpiner(false)
                console.log('error in update colletrall', err)
            }
        })
}



export const createAccount = (userCredentials: IUserCredentials, setErrServerMsg: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const CreateAccounts =
    {
        "id_initiator": currUserCredentials["idInitiator"],
        "id_client": currUserCredentials["idClient"],
        "id_entity": currUserCredentials["idBranch"],
        "return_entity": 1,
        "attribute_request_method": "create",
        "attribute": "main_accounts",
        "entity_type_id": "branch",
    }
    const body = JSON.stringify(CreateAccounts);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/entity', body, config)
        .then(res => {
            if (res.data.err_code === 0 && res.status === 200) {
                dispatch({
                    type: SET_MAIN_ACCOUNTS,
                    payload: res.data.data.main_accounts
                })
                // setshowSpiner(false)
                // setShowDialogSave(true)
                setErrServerMsg("הנתונים נשמרו בהצלחה")

            } else {
                setErrServerMsg("הנתונים לא נשמרו")
            }
        })
}


export const saveAccount = (arrFormat: any, userCredentials: IUserCredentials, setErrServerMsg: any, setTitleMassage: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    // const updateSetting =
    // {
    //     "vat_incomes_id_account": arrFormat["vat_incomes_id_account"] ? arrFormat["vat_incomes_id_account"] : '',
    //     "vat_expenses_id_account": arrFormat["vat_expenses_id_account"] ? arrFormat["vat_expenses_id_account"] : '',
    //     "vat_id_account": arrFormat["vat_id_account"] ? arrFormat["vat_id_account"] : '',
    //     "main_cashbox_id_account": arrFormat["main_cashbox_id_account"] ? arrFormat["main_cashbox_id_account"] : '',
    //     "main_creditbox_id_account": arrFormat["main_creditbox_id_account"] ? arrFormat["main_creditbox_id_account"] : '',
    //     "main_chequesbox_id_account": arrFormat["main_chequesbox_id_account"] ? arrFormat["main_chequesbox_id_account"] : '',
    //     "incomes_id_account": arrFormat["incomes_id_account"] ? arrFormat["incomes_id_account"] : '',
    //     "expenses_id_account": arrFormat["expenses_id_account"] ? arrFormat["expenses_id_account"] : '',
    //     "sub_cheques_cashing_expenses_id_account": arrFormat["sub_cheques_cashing_expenses_id_account"] ? arrFormat["sub_cheques_cashing_expenses_id_account"] : '',
    //     "amount_round_id_account": arrFormat["amount_round_id_account"] ? arrFormat["amount_round_id_account"] : '',
    //     "id_initiator": currUserCredentials["idInitiator"],
    //     "id_client": currUserCredentials["idClient"],
    //     "id_branch": currUserCredentials["idBranch"],
    //     "id_entity": currUserCredentials["idBranch"],
    //     "return_entity": 1,
    //     "attribute_request_method": "update",
    //     "attribute": "main_accounts",
    //     "entity_type_id": "branch",
    // }
    const updateSetting =
    {
        "id_initiator": currUserCredentials["idInitiator"],
        "id_client": currUserCredentials["idClient"],
        // "id_branch": currUserCredentials["idBranch"],
        "id_entity": currUserCredentials["idBranch"],
        "return_entity": 1,
        "attribute_request_method": "update",
        "attribute": "main_accounts",
        "entity_type_id": "branch",
        "main_accounts": arrFormat
    }
    const body = JSON.stringify(updateSetting);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/entity', body, config)
        .then(res => {
            if (res.data.err_code === 0 && res.status === 200) {
                dispatch({
                    type: SET_MAIN_ACCOUNTS,
                    payload: res.data.data.main_accounts
                })
                // setshowSpiner(false)
                // setShowDialogSave(true)
                setTitleMassage("שמירה")
                setErrServerMsg("הנתונים נשמרו בהצלחה")

            } else {
                setTitleMassage("שגיאה")
                setErrServerMsg("הנתונים לא נשמרו")
            }
        })
}

export const saveEmailProp = (emailProp: any, userCredentials: IUserCredentials, setShowDialogSave: any, setShowDialogFailed: any, setshowSpiner?: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const updateSetting =
    {
        "id_initiator": currUserCredentials["idInitiator"],
        "id_client": currUserCredentials["idClient"],
        "id_branch": currUserCredentials["idBranch"],
        "id_entity": currUserCredentials["idBranch"],
        "return_entity": 1,
        "attribute_request_method": "update",
        "attribute": "email_setting",
        "entity_type_id": "branch",
        "description": emailProp["description"] ? emailProp["description"] : '',
        "display_name": emailProp["display_name"] ? emailProp["display_name"] : '',
        "email_address": emailProp["email_address"] ? emailProp["email_address"] : '',
        "password": emailProp["password"] ? emailProp["password"] : '',
        "smtp_host_name": emailProp["smtp_host_name"] ? emailProp["smtp_host_name"] : '',
        "smtp_password": emailProp["smtp_password"] ? emailProp["smtp_password"] : '',
        "smtp_username": emailProp["smtp_username"] ? emailProp["smtp_username"] : '',
        "ssl": emailProp["ssl"] ? emailProp["ssl"] : '',
    }
    const body = JSON.stringify(updateSetting);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/entity', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        dispatch({
                            type: SET_EMAIL_SETTING,
                            payload: res.data.data.email_setting
                        })
                        setshowSpiner(false)
                        setShowDialogSave(true)
                    }
                }
                else {
                    setshowSpiner(false)
                }
            } catch (err) {
                setShowDialogFailed(true)
                setshowSpiner(false)
                console.log('error in update colletrall', err)
            }
        })

}


export const readFairCredit = (userCredentials: IUserCredentials, setFairCreditData: any, setFairCreditBeforeChanges?: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const fairCreditObj =
    {
        "id_initiator": currUserCredentials["idInitiator"],
        "id_branch": currUserCredentials["idBranch"],
        "definition_request_method": "read",
        "definition_name": "fair_credit_limits"
    }
    const body = JSON.stringify(fairCreditObj);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/Definitions', body, config)
        .then(res => {
            if (res.data.err_code === 0 && res.status === 200) {
                let copyFairCredit = cloneDeep(res.data.data)
                let arr = ["boi_interest", "fx_interest", "addition_interest_to_90_ils", "addition_interest_to_90_fx", "addition_max_interest_ils", "addition_interest_over_90_ils",
                    "addition_interest_over_90_fx", "addition_max_interest_fx", "exclusion_percentage_to_10k", "exclusion_percentage_over_10k_to_10d", "exclusion_percentage_over_10k_over_10d"]
                arr.forEach((item: any) => {
                    copyFairCredit[item] *= 100
                })
                // res.data.data
                setFairCreditData(copyFairCredit)

                setFairCreditBeforeChanges && setFairCreditBeforeChanges(copyFairCredit)

                // dispatch({
                //     type: SET_FAIR_CREDIT,
                //     payload: res.data?.data
                // })
            }

        })
}

export const saveFairCredit = (fairCredit: any, userCredentials: IUserCredentials, setShowDialogSave?: any, setShowDialogFailed?: any, setshowSpiner?: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const fairCreditObj =
    {
        "id_initiator": currUserCredentials["idInitiator"],
        "id_branch": currUserCredentials["idBranch"],
        "definition_request_method": "update",
        "definition_name": "fair_credit_limits",
        "definition_value": {
            "boi_interest": Number(fairCredit?.boi_interest),
            "fx_interest": Number(fairCredit?.fx_interest),
            "addition_interest_to_90_ils": Number(fairCredit?.addition_interest_to_90_ils),
            "addition_interest_over_90_ils": Number(fairCredit?.addition_interest_over_90_ils),
            "addition_interest_to_90_fx": Number(fairCredit?.addition_interest_to_90_fx),
            "addition_interest_over_90_fx": Number(fairCredit?.addition_interest_over_90_fx),
            "addition_max_interest_fx": Number(fairCredit?.addition_max_interest_fx),
            "addition_max_interest_ils": Number(fairCredit?.addition_max_interest_ils),
            "exclusion_credit_over_amount": Number(fairCredit?.exclusion_credit_over_amount),
            "exclusion_credit_over_days": Number(fairCredit?.exclusion_credit_over_days),
            "minimum_exclusion_amount": Number(fairCredit?.minimum_exclusion_amount),
            "exclusion_percentage_to_10k": Number(fairCredit?.exclusion_percentage_to_10k),
            "maximum_exclusion_amount_to_10k": Number(fairCredit?.maximum_exclusion_amount_to_10k),
            "exclusion_percentage_over_10k_to_10d": Number(fairCredit?.exclusion_percentage_over_10k_to_10d),
            "exclusion_percentage_over_10k_over_10d": Number(fairCredit?.exclusion_percentage_over_10k_over_10d),
            "maximum_exclusion_amount_over_10k_over_10d": Number(fairCredit?.maximum_exclusion_amount_over_10k_over_10d)
        }
    }
    const body = JSON.stringify(fairCreditObj);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/Definitions', body, config)
        .then(res => {
            setshowSpiner && setshowSpiner(false)
            if (res.data.err_code === 0 && res.status === 200) {
                setShowDialogSave && setShowDialogSave(true)
                // dispatch({
                //     type: SET_FAIR_CREDIT,
                //     payload: res.data?.data
                // })
            }
            else {
                setShowDialogFailed && setShowDialogFailed(true)
            }
        })
}

