
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react'

import HeaderUtils from '../shared/components/headerUtils/HeaderUtils';
import Subtitle from '../shared/components/Subtitle';
import DocumentsList from './DocumentsList';
import { READ_CUSTOMER } from '../store/actions/types';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../shared/components/CustomHooks/GlobalFunctions';

const Documents = () => {
    const [t] = useTranslation();
    const customerReducer = useSelector(({ customerReducer }: any) => customerReducer)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [items, setItems] = useState<any>([])
    const [activeDocumentsList, setActiveDocumentsList] = useState<any>([])
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation();
    
    useEffect(() => {
        debugger;
        if (authReducer) {
            let documentNameList, documentList: any = []
            documentNameList = authReducer.data.documentation_requirements.filter((item: any) => (item?.customer_type_id === customerReducer?.dataCustomer?.properties?.customer_type_id))
            let documentAllList = authReducer.data.documentation_requirements.filter((item: any) => (item?.customer_type_id === "ALL"))
            documentNameList = documentNameList.concat(documentAllList)
            let customerDocuments: any = []
            let activeDocuments: any = []
            if (customerReducer?.dataCustomer?.documentation) { customerDocuments = [...customerReducer?.dataCustomer?.documentation] }
            documentNameList.forEach((doc: any) => {
                let fromCustomer = false
                if (customerReducer?.dataCustomer?.documentation) {
                    customerDocuments = customerDocuments.filter((item: any) => (doc?.documentation_type_id !== item?.documentation_type_id))
                    customerReducer?.dataCustomer?.documentation.forEach((item: any) => {
                        if (doc?.documentation_type_id === item?.documentation_type_id) {
                            documentList.push({
                                id_media: item?.id_media, media_name: item?.media_name,
                                documentation_type_id: item?.documentation_type_id,
                                media_type_id: "1", type: "חובה", media_status_id: item?.media_status_id,
                                status: item?.media_status_id === "99" ? "מחוק" : "פעיל",
                                date_created: item?.date_created, media_exp_date: item?.media_exp_date,
                                media_details: item?.media_details, media_extension_file_name: item?.media_extension_file_name
                            })
                        }
                        if ((doc?.documentation_type_id === item?.documentation_type_id) && item?.media_status_id !== "99") {
                            activeDocuments.push({
                                id_media: item?.id_media, media_name: item?.media_name,
                                 documentation_type_id: item?.documentation_type_id, media_type_id: "1", type: "חובה", media_status_id: item?.media_status_id,
                                status: item?.media_status_id === "99" ? "מחוק" : "פעיל",
                                 date_created: item?.date_created, media_exp_date: item?.media_exp_date,
                                  media_details: item?.media_details, media_extension_file_name: item?.media_extension_file_name
                           
                            })
                            fromCustomer = true

                        }
                    });
                }
                if (!fromCustomer) {
                    documentList.push({
                        id_media: "", media_name: doc?.documentation_type,
                        documentation_type_id: doc?.documentation_type_id, media_type_id: "1",
                        type: "חובה", media_status_id: "1", status: "פעיל", date_created: "",
                        media_exp_date: "", media_details: null, media_extension_file_name: doc?.media_extension_file_name
                    })

                    activeDocuments.push({
                        id_media: "", media_name: doc?.documentation_type,
                        documentation_type_id: doc?.documentation_type_id, media_type_id: "1",
                        type: "חובה", media_status_id: "1", status: "פעיל", date_created: "",
                        media_exp_date: "", media_details: null, media_extension_file_name: doc?.media_extension_file_name
                    })
                }
            })
            customerDocuments?.forEach((item: any) => {
                documentList.push({
                    id_media: item?.id_media, media_name: item?.media_name,
                    documentation_type_id: item?.documentation_type_id, media_type_id: "1",
                    type: "חובה", media_status_id: item?.media_status_id,
                    status: item?.media_status_id === "99" ? "מחוק" : "פעיל",
                    date_created: item?.date_created, media_exp_date: item?.media_exp_date
                    , media_details: item?.media_details, media_extension_file_name: item?.media_extension_file_name
                })

                if (item?.media_status_id !== "99") {
                    activeDocuments.push({
                        id_media: item?.id_media, media_name: item?.media_name,
                        documentation_type_id: item?.documentation_type_id, media_type_id: "1",
                        type: "חובה", media_status_id: item?.media_status_id,
                        status: item?.media_status_id === "99" ? "מחוק" : "פעיל",
                        date_created: item?.date_created, media_exp_date: item?.media_exp_date
                        , media_details: item?.media_details, media_extension_file_name: item?.media_extension_file_name
                    })
                }
            });
            setItems(documentList)
            setActiveDocumentsList(activeDocuments)
            console.log(customerDocuments,"customerDocuments");
            console.log(activeDocuments,"activeDocuments");
            

        }
        
    }, [authReducer, customerReducer])

    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
        ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={"customers"} createBtn={false} editBtn={false}
                deleteBtn={false} disabledSave={true} uploadBtn={false}
                createXl={false} createPrint={false} createPDF={false} />
            <br></br><br></br>
            <Subtitle title={t('documents')} />
            <DocumentsList activeDocumentsList={activeDocumentsList} items={items}
                actionType={READ_CUSTOMER} />

        </div>

    )
}
export default Documents;