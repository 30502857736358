import { cloneDeep } from 'lodash';
import { RESET_CHEQUE_MANAGMENT_EVENT_DATA, SET_CHEQUE_MANAGMENT_EVENT_DATA } from '../actions/types';

const initialState = {
    chequeManagmentEventData:{}
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SET_CHEQUE_MANAGMENT_EVENT_DATA: {
            // sessionStorage.setItem('dataConversion', JSON.stringify(data));
            return {
                ...state,
                chequeManagmentEventData:action.payload,
            }
        }
        case RESET_CHEQUE_MANAGMENT_EVENT_DATA: {
            // sessionStorage.setItem('dataConversion', JSON.stringify(data));
            return {
                ...state,
                chequeManagmentEventData:{},
            }
        }
    
        default: return state;
    }
}