import { useEffect } from 'react';
import { Dialog, ContextualMenu, PrimaryButton, DefaultButton, DialogFooter } from 'office-ui-fabric-react';
import { useBoolean } from '@fluentui/react-hooks';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import CustemTable from '../../../../shared/components/tabels/tableList';


const ChequeHistoryDialog = (props: any) => {
    const { setShowDialog, chequeItem } = props
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const chequeHistoryColumn = [
        {
            key: '2', name: t('creationDate'), fieldName: "date_created",
            entity_name: "date_created", minWidth: 170, maxWidth: 170,

        },
        {
            key: '3', name: t('event'), fieldName: "cheque_event",
            entity_name: "cheque_event", minWidth: 190, maxWidth: 190
        },

        {
            key: '4', name: t('user'), fieldName: "initiator_name",
            entity_name: "initiator_name", minWidth: 120, maxWidth: 120
        },
    ];


    useEffect(() => {
        if (setShowDialog) {
            toggleHideDialog()
        }
    }, [setShowDialog, toggleHideDialog])


    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
    };
    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        dragOptions: dragOptions
    };


    const closeDialog = async () => {
        toggleHideDialog()
        setShowDialog(false)
    }

    return (
        <>
            <Dialog minWidth="xl" maxWidth="xl" hidden={hideDialog} modalProps={modelProps} onDismiss={closeDialog}  >
                <div style={{ width: "100%" }}>
                    <h3>{t("history")}</h3>
                    <hr className="hr"></hr>
                    {chequeItem?.cheque_events_log && <div style={{ width: "600px" }}><CustemTable
                        tableName="chequeHistoryTable" columns={chequeHistoryColumn}
                        allItems={chequeItem?.cheque_events_log}
                        addCustem={true} isFooter={false} /></div>}
                    <DialogFooter>
                        <PrimaryButton className={`margin-between-input-${dir}`} onClick={closeDialog} text={t("confirm")} />
                    </DialogFooter>
                </div>
            </Dialog>
        </>
    )

}
export default ChequeHistoryDialog