import { RESET_HTML_PAGE, SET_HTML_PAGE
 } from '../actions/types';

const initialState = {
    htmlPage:null,
    detailsDownLoadFile:{}
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        
        case SET_HTML_PAGE: {
            return {
                ...state,
                htmlPage: action.payload
            }
        }
        case RESET_HTML_PAGE: {
            return {
                ...state,
                htmlPage: action.payload
            }
        }
        default: return state;
    }
}