import { CustomTextField } from '../../shared/components/TextField'

export default function TableDetails(props: any) {
    const {value,ilsId,fxId,updateFairCredit,change,readOnly, showRequiredFields}=props
    return <>
        <div style={{ width: "50%", marginBottom: "5px" }}>
            <div className="inlineB">
                <p className="boldPP" style={{ width: "40%" }}>{value}</p>
                <div style={{ width: "20%" }}>
                    <CustomTextField label={" "} requiredField={showRequiredFields&&updateFairCredit&&!updateFairCredit[ilsId]?true:false} value={updateFairCredit?updateFairCredit[ilsId]:""} readOnly={readOnly} onChange={change} prefix="%" id={ilsId} />
                    {/* (updateFairCredit&&updateFairCredit[ilsId]*100) */}
                </div>
                <div style={{ width: "20%" }}>
                      {/* (updateFairCredit&&updateFairCredit[ilsId]*100) */}
                    <CustomTextField label={" "} requiredField={showRequiredFields&&updateFairCredit&&!updateFairCredit[fxId]?true:false} value={updateFairCredit?updateFairCredit[fxId]:""} readOnly={readOnly} onChange={change} prefix="%" id={fxId} />
                </div>
            </div>
        </div>
    </>
}
