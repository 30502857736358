import { Toggle } from "@fluentui/react/lib/Toggle";
import React from "react";
import ComponenetProps from "../../modelsType/type/interface";
import "../../scssPages/form.scss"

interface MyProps extends ComponenetProps {
  onText: string,
  defaultChecked: boolean
  nameOfClasStyle?: string
  offText: string
  checked?: boolean,
  readOnly?: boolean
}

export const CustomToggle: React.FunctionComponent<MyProps> = (props) => {

  // const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  // const labelId: string = useId('dialogLabel');
  // const subTextId: string = useId('subTextLabel');

  const { onChange, id, onText, defaultChecked, offText, checked, readOnly } = props;
  return (
    <Toggle
      offText={offText}
      onText={onText}
      defaultChecked={defaultChecked ? true : undefined}
      checked={checked}
      disabled={readOnly}
      onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        onChange(id, checked)
      }}
    />
  );
};


