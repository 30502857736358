import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";
import { useDispatch, useSelector } from "react-redux";
import {
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { identity, isEqual } from "lodash";
import { DefaultButton, TextField } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComboBox, IComboBoxOption, IComboBox } from "@fluentui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Discount.scss";

import { CustomTextField } from "../../../../shared/components/TextField";
import { CustomDropdown } from "../../../../shared/components/Option";
import { convertArrToDropdropdown } from "../../../customerDetails/CustomerDetails";
import {
  CashPaymentDialog,
  CustomDialogCreditFromCustomer,
  CustomDialogTransferFromCustomer,
} from "../../../../shared/components/dialog/Dialog";
import { CheckDetailsDialog } from "../../../../shared/components/dialog/dialogTransaction/CheckDetailsDialog";
import {
  calcChequeDetails,
  checkChequeDays,
  checkCurrencies,
} from "../../../../shared/components/CustomHooks/GlobalFunctions";
import { DialogMessages } from "../../../../shared/components/dialog/DialogMessages";
import { deteleFile, uploadMultiScanChecks } from "../../../../store/actions/FilesAction";
import { createTransaction } from "../../../../store/actions/convertionActions";
import moment from "moment";
import { Icons } from "../../../../modelsType/Icon";
import { ScanCheck } from "../../../../shared/components/dialog/dialogTransaction/ScanCheck";
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import CheckImageProcessor from "../../../CheckImageProcessor";
import { getGatesData } from "../../../../store/actions/convertionActions";

const spinnerStyles: Partial<ISpinnerStyles> = {
  root: {
    position: 'fixed',
    zIndex: '999999',
    fontWeight: 'bold',
  },
  circle: {
    color: '#FFB400 ',
    height: '100px',
    width: '100px',
  },
}

const DiscountFinancialAssetsIn = (props: any) => {
  const {
    isNegativeBalance,
    emptyRequiredFields,
    action,
    setAction,
    userCredentials,
    searchCustomer,
    readOnly,
    ifChanges,
    setIfChanges,
    typeAction,
    sumIn,
    setSumIn,
    setSumOut,
    setSumFee,
    emptyInContraAccount,
    emptyInAmount,
  } = props;
  const [t] = useTranslation();

  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);
  const dispatch = useDispatch();
  const settings = useSelector(
    ({ authReducer }: any) => authReducer?.data?.user?.setting
  );
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const contraAccounts = useSelector(
    ({ authReducer }: any) => authReducer?.data?.user.contra_accounts
  );
  const datacConversion = useSelector(
    ({ conversionReducer }: any) => conversionReducer?.dataConversion
  );
  const [gatesData, setGatesData] = useState<any>(
    sessionStorage.getItem("gatesArr")
  );

  const [enumsDrown, setenumsDrown] = useState<any>({
    countryCode: [],
    bank: [],
    branch: [],
  });

  const [enumsDropdown, setenumsDropdown] = useState<any>({
    currencyCode: [],
    finanssialAssetCode: [],
    bankNumber: [],
    bank: [],
    branch: [],
  });

  const [defaultCurrencyCode, setdefaultCurrencyCode] = useState();
  const [defaultFinanssialAsset, setDefaultFinanssialAsset] = useState();
  const [TransferFromCustomer, setTransferFromCustomer] = useState(false);
  const [checkDetails, setCheckDetails] = useState(false);
  const [cashPayment, setCashPayment] = useState(false);
  const [contraCash, setContraCash] = useState<any>([]);
  const [contraCheck, setContraCheck] = useState<any>([]);
  const [contraCreditCard, setContraCreditCard] = useState<any>([]);
  const [contraBankAccounts, setcontraBankAccounts] = useState<any>([]);
  const [tableType, setTableType] = useState("financial_assets_in");
  const [rowNumber, setRowNumber] = useState("1");
  const [rowOfDelete, setRowOfDelete] = useState<any>();
  const [credit, setCredit] = useState(false);
  const [scanButton, setScanButton] = useState(false);
  const [isIconScanButton, SetIsIconScanButton] = useState(false);
  const [showDialogFailed, setShowDialogFailed] = useState(false);
  const [saveDeleteRowActoin, setSaveDeleteRowActoin] = useState("");
  const [messageError, setMessageError] = useState("");
  const [clickScan, setClickScan] = useState(false);
  const [allScanChecks, setAllScanChecks] = useState<any>([]);
  const [showSpiner, setshowSpiner] = useState(false);
  const [checkType, setCheckType] = useState<string>('');
  const [arrInformationScan, setArrInformationScan] = useState<any>([
    { checkNumber: "", bankNumber: "", branchNumber: "", accountNumber: "" },

  ]);
  const [checkDeatils, setcheckDeatils] = useState<any>(
    {
      reference_number: "",
      bank_account_id: "",
      id_account_holder: "",
      id_beneficiary: "",
      id_endorsee: "",
      cheque_date: "",
      cheque_endorses_number: 0,
      related_entities: [],
      date_created: "",
      cheque_face_scan_path: "",
      cheque_back_scan_path: "",
      media: { media_face: {}, media_back: {} },
      id_media_face: "",
      id_media_back: "",
      media_face_extension: "",
      media_back_extension: "",
    }
  );
  useEffect(() => {
    console.log('gatesData', gatesData)
    if (gatesData && typeof gatesData === "string") {
      setGatesData(JSON.parse(gatesData));
    }
  }, [gatesData]);

  useEffect(() => {
    if (saveDeleteRowActoin === "save") {
      setSaveDeleteRowActoin("");
      setIfChanges(false);
      deleteFileFunc();
    }
    if (saveDeleteRowActoin === "don't save") setSaveDeleteRowActoin("");
  }, [saveDeleteRowActoin]);

  useEffect(() => {
    if (contraAccounts) {
      let arrCash: any[] = [],
        arrCheck: any[] = [],
        arrCredit: any[] = [],
        arrBank: any[] = [];
      for (let i = 0; i < contraAccounts.length; i++) {
        if (contraAccounts[i].contra_account_group_id === "cash") {
          arrCash.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "cheque") {
          arrCheck.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "credit_card") {
          arrCredit.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "bank_account") {
          arrBank.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
      }
      setContraCash(arrCash);
      setContraCheck(arrCheck);
      setContraCreditCard(arrCredit);
      setcontraBankAccounts(arrBank);
    }
  }, [contraAccounts]);

  useEffect(() => {
    if (enums) {
      let list: any = [];
      let listSomeCurrency: any = [];
      let listAllCurrency: any = [];
      let arr = {
        currencyCode: list,
        someOfcurrency: listSomeCurrency,
        allOfcurrency: listAllCurrency,
        finanssialAssetCode: convertArrToDropdropdown([]),
        bank: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].general !== undefined) {
          let s = enums[i].general;
          for (let i = 0; i < s.length; i++) {
            if (s[i].currency_code !== undefined) {
              s[i].currency_code?.forEach((item: any) => {
                arr.allOfcurrency.push({
                  currency_symbol: item?.currency_symbol,
                  key: item?.enum_id,
                  text: item?.enum_value,
                  is_default: item?.is_default,
                });
                if (item?.is_selected) {
                  arr.someOfcurrency.push({
                    currency_symbol: item?.currency_symbol,
                    key: item?.enum_id,
                    text: item?.enum_value,
                    is_default: item?.is_default,
                  });
                }
              });
            }
          }
        }
        if (enums[i].bank_account !== undefined) {
          let bank = enums[i].bank_account;
          for (let i = 0; i < bank.length; i++) {
            if (bank[i].bank_code !== undefined)
              arr.bank = convertArrToDropdropdown(bank[i].bank_code);
          }
        }
        if (enums[i].exchange !== undefined) {
          let s = enums[i].exchange;
          for (let i = 0; i < s.length; i++) {
            if (s[i].financial_asset_code !== undefined)
              arr.finanssialAssetCode = convertArrToDropdropdown(
                s[i].financial_asset_code
              );
          }
        }
      }
      arr.someOfcurrency.push({
        currency_symbol: "",
        key: "More",
        text: t("more"),
        is_default: false,
      });
      arr.allOfcurrency.push({
        currency_symbol: "",
        key: "Less",
        text: t("less"),
        is_default: false,
      });
      arr.currencyCode = [...arr.someOfcurrency];
      let defaultfinanssialValue: any;
      defaultfinanssialValue = arr?.finanssialAssetCode.find(
        ({ key }: any) => key === "3"
      );
      let defaultcurrencyCodeValue: any;
      defaultcurrencyCodeValue = arr?.currencyCode.find(
        ({ is_default }: any) => is_default === true
      );
      setdefaultCurrencyCode(
        defaultcurrencyCodeValue && defaultcurrencyCodeValue.key
      );
      setDefaultFinanssialAsset(
        defaultfinanssialValue && defaultfinanssialValue.key
      );
      if (action?.financial_assets_in[0].amount_ils === 0) {
        let financialAssets: any = [];
        financialAssets = [...action?.financial_assets_in];
        financialAssets[0].financial_asset_code = defaultfinanssialValue?.key;
        financialAssets[0].currency_code = defaultcurrencyCodeValue?.key;
        const sortArray = financialAssets.sort((a: any, b: any) => a.row_number - b.row_number);
        setAction({ ...action, financial_assets_in: sortArray });
        let filteredFinanssialAssetCode = arr.finanssialAssetCode.filter((i: any) => i.key !== "0");
        setenumsDropdown({ ...arr, finanssialAssetCode: filteredFinanssialAssetCode });
      }
    }
  }, [enums]);

  // useEffect(() => {
  //   if (action?.id_transaction) {
  //     let copyEnumsDropdown: any = { ...enumsDropdown };
  //     let listCurrencies = checkCurrencies(
  //       copyEnumsDropdown.someOfcurrency,
  //       copyEnumsDropdown.allOfcurrency,
  //       action
  //     );
  //     copyEnumsDropdown.currencyCode = [...listCurrencies];
  //     setenumsDropdown(copyEnumsDropdown);
  //   }
  // }, [action]);

  const handleMultiCheck = async () => {
    console.log(allScanChecks, "allScanChecks======");

    if (allScanChecks[0].mediaBack) {
      await addMultiScanRow();
    }
    else {
      setshowSpiner(true);
      await dispatch(uploadMultiScanChecks(action.id_entity, userCredentials, allScanChecks, setAllScanChecks, checkType));
    }
  }

  useEffect(() => {
    if (allScanChecks?.length > 0)
      handleMultiCheck();
  }, [allScanChecks]);

  async function deleteFileFunc() {
    let arr: any = [],
      copyAction: any;
    copyAction = { ...datacConversion };
    arr = [...action?.financial_assets_in];
    let index = arr.findIndex(
      (a: any) => Number(a.row_number) === Number(rowOfDelete)
    );
    let idMediaFace, idMediaBack: any;
    if (arr[index]?.financial_asset_code === "3") {
      idMediaFace =
        arr[index]?.financial_asset_details?.media?.media_face?.id_media;
      idMediaBack =
        arr[index]?.financial_asset_details?.media?.media_back?.id_media;
    }
    arr.splice(index, 1);
    if (arr.length === 0) {
      let prop: any = searchCustomer?.priceProgram?.price_program_properties;
      let properties: any = {};
      prop?.forEach((element: any) => {
        properties[element.name] = element.value
          ? element.value
          : element.percent;
      });
      let financialAssetsDetails = {
        cashing_intrest: properties?.cheque_cashing_cash_rate
          ? properties?.cheque_cashing_cash_rate * 100
          : 1,
        cheque_days: 0,
        cheque_value_days: properties?.cheque_cashing_value_days
          ? properties?.cheque_cashing_value_days
          : 0,
        allowed_expenses: properties?.cheque_collection_fee
          ? properties?.cheque_collection_fee
          : 0,
        cheque_date: moment().format("YYYY-MM-DD"),
      };
      arr.push({
        currency_code: defaultCurrencyCode,
        financial_asset_code: defaultFinanssialAsset,
        amount: 0,
        exchange_rate_ils: 1,
        amount_ils: 0,
        row_number: 1,
        financial_asset_details: financialAssetsDetails,
      });
    }
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += Number(arr[i].amount_ils);
    }
    setSumIn(sum);
    const sortArray = arr.sort((a: any, b: any) => a.row_number - b.row_number);
    setAction({ ...action, financial_assets_in: sortArray, total_amount_ils: sum });
    copyAction["financial_assets_out"] = [...action["financial_assets_out"]];
    copyAction.financial_assets_in = arr;
    idMediaFace &&
      (await dispatch(
        deteleFile(action.id_entity, idMediaFace, userCredentials)
      ));
    idMediaBack &&
      (await dispatch(
        deteleFile(action.id_entity, idMediaBack, userCredentials)
      ));
    await dispatch(
      createTransaction(
        "update",
        typeAction,
        datacConversion.entity_name,
        copyAction,
        userCredentials,
        action.id_entity
      )
    );
  }

  const addRow = async (rowNumber: Number) => {
    let arr: any = [];
    arr = [...action?.financial_assets_in];
    let prop: any = searchCustomer?.priceProgram?.price_program_properties;
    let properties: any = {};
    prop?.forEach((element: any) => {
      properties[element.name] = element.value
        ? element.value
        : element.percent;
    });
    let maxRowNumber = Math.max.apply(
      Math,
      arr.map(function (item: any) {
        return item.row_number;
      })
    );
    // if (maxRowNumber === rowNumber) {
    arr.push({
      currency_code: defaultCurrencyCode,
      financial_asset_code: defaultFinanssialAsset,
      amount: 0,
      exchange_rate_ils: 1,
      amount_ils: 0,
      contra_id_account:
        contraCheck?.length === 1 && defaultFinanssialAsset === "3"
          ? contraCheck[0].key
          : null,
      //לבדוק את הברירת מחדל של האחוז
      row_number: maxRowNumber + 1,
      financial_asset_details: prop
        ? {
          cashing_intrest: properties?.cheque_cashing_cash_rate
            ? properties?.cheque_cashing_cash_rate * 100
            : 1,
          cheque_days: 0,
          cheque_date: moment().format("YYYY-MM-DD"),
          cheque_value_days: properties?.cheque_cashing_value_days
            ? properties?.cheque_cashing_value_days
            : 0,
          allowed_expenses: properties?.cheque_collection_fee
            ? properties?.cheque_collection_fee
            : 0,
        }
        : {},
    });
    // }
    const sortArray = arr.sort((a: any, b: any) => a.row_number - b.row_number);
    setAction({ ...action, financial_assets_in: sortArray });
  };
  let defaultcontraCheque = contraCheck.find(
    (item: any) => item.is_default === true
  );
  console.log('gatesData', gatesData)
  const addMultiScanRow = async () => {
    debugger
    console.log(arrInformationScan, "arrInformationScan-----===");

    let arr: any = [];
    arr = [...action?.financial_assets_in];

    let prop: any = searchCustomer?.priceProgram?.price_program_properties;
    let properties: any = {};
    prop?.forEach((element: any) => {
      properties[element.name] = element.value
        ? element.value
        : element.percent;
    });
    const lastElement = arr?.[arr?.length - 1];
    if ((allScanChecks.length > 1 || !checkDetails) && !lastElement.amount &&
      !lastElement.financial_asset_details?.reference_number &&
      !lastElement.financial_asset_details?.beneficiary &&
      !lastElement.financial_asset_details?.account_holder) {
      arr = arr.slice(0, -1)
    }
    let maxRowNumber = 0;
    if (arr.length > 0)
      maxRowNumber = Math.max.apply(
        Math,
        arr.map(function (item: any) {
          return item.row_number ? item.row_number : 0;
        })
      );
    console.log('allScanChecks.........', allScanChecks)
    if (allScanChecks.length > 1)

      allScanChecks.map((check: any, index: number) => {
        console.log(defaultFinanssialAsset, "defaultFinanssialAsset");
        console.log('arrInformationScan', arrInformationScan)
        console.log('arrInformationScan', arrInformationScan)
        arr = [...arr, {
          currency_code: checkType === "IL" ? "ILS" : "USD",
          financial_asset_code: defaultFinanssialAsset,
          amount: 0,
          exchange_rate_ils: gatesData ? (1 / gatesData?.[checkType === "IL" ? "ILS" : "USD"]).toFixed(2) : 1,
          amount_ils: 0,
          contra_id_account: defaultcontraCheque?.key,
          row_number: maxRowNumber ? maxRowNumber + index + 1 : index + 1,
          financial_asset_details: {
            cashing_intrest: properties?.cheque_cashing_cash_rate
              ? properties?.cheque_cashing_cash_rate * 100
              : 1,
            cheque_days: 0,
            cheque_date: moment().format("YYYY-MM-DD"),
            cheque_value_days: properties?.cheque_cashing_value_days
              ? properties?.cheque_cashing_value_days
              : 0,
            allowed_expenses: properties?.cheque_collection_fee
              ? properties?.cheque_collection_fee
              : 0,
            reference_number: checkType === "IL" ? arrInformationScan[index].checkNumber : check?.micr?.checkNumber,
            bank_account_number: checkType === "IL" ? arrInformationScan[index].accountNumber : check?.micr?.accountNumber,
            routing_number: checkType === "IL" ? check?.routingNumber : check?.micr?.routingNumber,
            country_code: checkType,
            media: { media_face: { ...check?.mediaFace }, media_back: { ...check?.mediaBack } }
          }
        }]
        // arr = [...arr, {
        //   currency_code: "USD",
        //   financial_asset_code: defaultFinanssialAsset,
        //   amount: 0,
        //   exchange_rate_ils: gatesData && (1 / gatesData?.["USD"]).toFixed(2),
        //   amount_ils: 0,
        //   contra_id_account: defaultcontraCheque?.key,
        //   row_number: maxRowNumber ? maxRowNumber + index + 1 : index + 1,
        //   financial_asset_details: {
        //     cashing_intrest: properties?.cheque_cashing_cash_rate
        //       ? properties?.cheque_cashing_cash_rate * 100
        //       : 1,
        //     cheque_days: 0,
        //     cheque_date: moment().format("YYYY-MM-DD"),
        //     cheque_value_days: properties?.cheque_cashing_value_days
        //       ? properties?.cheque_cashing_value_days
        //       : 0,
        //     allowed_expenses: properties?.cheque_collection_fee
        //       ? properties?.cheque_collection_fee
        //       : 0,
        //     reference_number: check?.micr?.checkNumber,
        //     bank_account_number: check?.micr?.accountNumber,
        //     routing_number: check?.micr?.routingNumber,
        //     country_code: "US",
        //     media: { media_face: { ...check?.mediaFace }, media_back: { ...check?.mediaBack } }
        //   }
        // }]
      })
    else {
      if (allScanChecks.length === 1) {
        let checkItem = {
          currency_code: checkType === "IL" ? "ILS" : "USD",
          financial_asset_code: defaultFinanssialAsset,
          amount: 0,
          exchange_rate_ils: gatesData ? (1 / gatesData?.[checkType === "IL" ? "IL" : "USD"]).toFixed(2):1,
          amount_ils: 0,
          contra_id_account: defaultcontraCheque?.key,
          row_number: checkDetails ? rowNumber : maxRowNumber + 1,
          financial_asset_details: {
            cashing_intrest: properties?.cheque_cashing_cash_rate
              ? properties?.cheque_cashing_cash_rate * 100
              : 1,
            cheque_days: 0,
            cheque_date: moment().format("YYYY-MM-DD"),
            cheque_value_days: properties?.cheque_cashing_value_days
              ? properties?.cheque_cashing_value_days
              : 0,
            allowed_expenses: properties?.cheque_collection_fee
              ? properties?.cheque_collection_fee
              : 0,
            reference_number: checkType === "IL" ? arrInformationScan[0].checkNumber : allScanChecks[0]?.micr?.checkNumber,
            bank_account_number: checkType === "IL" ? arrInformationScan[0].accountNumber : allScanChecks[0]?.micr?.accountNumber,
            routing_number: checkType === "IL" ? allScanChecks[0]?.routingNumber : allScanChecks[0]?.micr?.routingNumber,
            country_code: checkType,
            media: { media_face: { ...allScanChecks[0]?.mediaFace }, media_back: { ...allScanChecks[0]?.mediaBack } }
          }
        }
        if (checkDetails) {
          console.log(checkItem, "checkItem=======");

          arr = arr.map((item: any) => item.row_number === Number(rowNumber) ? checkItem : item);
        }

        else arr = [...arr, checkItem];
      }
    }
    const sortArray = arr.sort((a: any, b: any) => a.row_number - b.row_number);
    setAction({ ...action, financial_assets_in: sortArray });
    debugger
    await dispatch(createTransaction("update", "cheque_cashing", action.entity_name,
      { ...action, financial_assets_in: sortArray }, userCredentials, action.id_entity));
    setshowSpiner(false);
  };

  const removeFObject = (row_number: number) => {
    setRowOfDelete(row_number);
    setShowDialogFailed(true);
    setMessageError(t("DeleteRowMassege"));
  };

  const onChangeComboBox = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string,
    id?: string
  ): void => {
    let val = option?.key;
    let key = id ? id : "currency_code";
    let copyEnumsDropDown: any = { ...enumsDropdown };
    if (val === "More" || val === "Less") {
      if (val === "More") {
        copyEnumsDropDown.currencyCode = copyEnumsDropDown?.allOfcurrency;
        setenumsDropdown(copyEnumsDropDown);
      }
      if (val === "Less") {
        copyEnumsDropDown.currencyCode = copyEnumsDropDown?.someOfcurrency;
        setenumsDropdown(copyEnumsDropDown);
      }
    } else addFinancialIn(key, val);
  };
  const calcSum = (arr: any) => {
    debugger
    let sum = 0,
      total_expenses = 0,
      total_net_amount = 0,
      num1 = 0,
      num2 = 0,
      amount_round = 0;
    for (let i: any = 0; i < arr.length; i++) {
      sum += Number(arr[i]?.amount_ils) ? Number(arr[i]?.amount_ils) : 0;
      total_expenses +=
        Number(
          arr[i]?.financial_asset_details?.cheque_expenses
            ? arr[i]?.financial_asset_details?.cheque_expenses
            : 0
        )
      // +
      // Number(
      //   arr[i]?.financial_asset_details?.allowed_expenses
      //     ? arr[i]?.financial_asset_details?.allowed_expenses
      //     : 0
      // ) +
      // Number(
      //   arr[i]?.financial_asset_details?.allowed_actual_expenses
      //     ? arr[i]?.financial_asset_details?.allowed_actual_expenses
      //     : 0
      // );
    }
    total_net_amount += Number(sum) - Number(total_expenses?.toFixed(2));
    num1 = Number(Math.round(total_net_amount));
    num2 = Number(total_net_amount?.toFixed(2));
    amount_round = num1 - num2;
    total_net_amount = Math.round(total_net_amount);
    total_expenses = Number(total_expenses?.toFixed(2));
    const sortArray = arr.sort((a: any, b: any) => a.row_number - b.row_number);
    setAction({
      ...action,
      ["financial_assets_in"]: sortArray,
      total_amount_ils: sum,
      total_expenses: total_expenses,
      amount_round: amount_round,
      total_net_amount: total_net_amount,
    });
  };

  const onChangeCashing = (key: string, val: any) => {
    const [id, row_number] = key.split(",");
    let temp,
      arr: any = [];
    arr = [...action["financial_assets_in"]];
    let index = arr.findIndex(
      (item: any) => Number(item.row_number) === Number(row_number)
    );
    arr[index].financial_asset_details[id] = val;
    temp = calcChequeDetails(arr[index]);
    arr[index] = temp;
    setIfChanges(true);
    calcSum(arr);
  };

  const addFinancialIn = (key: string, value: any, isBalance?: any) => {
    debugger
    let FinancialAssets =
      isBalance === true ? "financial_assets_out" : "financial_assets_in";
    const [id, row_number] = key.split(",");
    key = id;
    let arr: any = [];
    arr = [...action[FinancialAssets]];
    let index = arr.findIndex(
      (item: any) => Number(item.row_number) === Number(row_number)
    );
    let i = index;
    if (id === "financial_asset_code") {
      setTableType("in");
      setRowNumber(row_number);

      if (value === "12") {
        let defaultcontraCreditCard = contraCreditCard.find(
          (item: any) => item.is_default === true
        );
        if (value !== arr[i]?.financial_asset_code && defaultcontraCreditCard) {
          arr[i].contra_id_account = defaultcontraCreditCard?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCreditCard.length === 1
        ) {
          arr[i].contra_id_account = contraCreditCard[0].key;
        }
        setCredit(true);
        setTableType(FinancialAssets);
      }
      if (value === "9") {
        let defaultcontraBankAccount = contraBankAccounts.find(
          (item: any) => item.is_default === true
        );
        if (
          value !== arr[i]?.financial_asset_code &&
          defaultcontraBankAccount
        ) {
          arr[i].contra_id_account = defaultcontraBankAccount?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraBankAccounts.length === 1
        ) {
          arr[i].contra_id_account = contraBankAccounts[0].key;
        }
        setTransferFromCustomer(true);
        setTableType(FinancialAssets);
      }
      if (value === "1") {
        let defaultcontraCash = contraCash.find(
          (item: any) => item.is_default === true
        );
        if (value !== arr[i]?.financial_asset_code && defaultcontraCash) {
          arr[i].contra_id_account = defaultcontraCash?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCash.length === 1
        ) {
          arr[i].contra_id_account = contraCash[0].key;
        }

        setTableType(FinancialAssets);
        setCashPayment(true);
      }
      if (value === "3") {
        let defaultcontraCheque = contraCheck.find(
          (item: any) => item.is_default === true
        );
        if (
          (value !== arr[i]?.financial_asset_code ||
            !arr[i].contra_id_account) &&
          defaultcontraCheque
        ) {
          arr[i].contra_id_account = defaultcontraCheque?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCheck.length === 1
        ) {
          arr[i].contra_id_account = contraCheck[0].key;
        }
        setCheckDetails(true);
        setTableType(FinancialAssets);
      }
      if (arr[index]?.financial_asset_code !== value) {
        arr[index].financial_asset_details = {};
      }
      if (
        value === datacConversion[FinancialAssets][index]?.financial_asset_code
      ) {
        arr[index].financial_asset_details =
          datacConversion[FinancialAssets][index].financial_asset_details;
        if (datacConversion[FinancialAssets][i]?.contra_id_account)
          arr[i].contra_id_account =
            datacConversion[FinancialAssets][i]?.contra_id_account;
      }

      if (value === "18") {
        arr[index].contra_id_account = searchCustomer?.accounts[0]?.id_account;
        arr[index].financial_asset_details = {};
      }
    }

    if (arr.length - 1 >= i) {
      let num = "0";
      let temp: any = {
        currency_code: id === "currency_code" ? value : arr[i]?.currency_code,
        financial_asset_code:
          id === "financial_asset_code" ? value : arr[i]?.financial_asset_code,
        financial_asset_details: arr[i]?.financial_asset_details,
        amount:
          id === "amount"
            ? Number(value).toFixed(2)
              ? value
              : 0
            : arr[i]?.amount,
        exchange_rate_ils:
          id === "exchange_rate_ils"
            ? value
            : id === "currency_code"
              ? parseFloat(num)
              : arr[i]?.exchange_rate_ils,
        amount_ils:
          id === "amount"
            ? value * arr[i]?.exchange_rate_ils
            : id === "exchange_rate_ils"
              ? value * arr[i]?.amount
              : id === "currency_code"
                ? arr[i]?.amount * parseFloat(num)
                : arr[i]?.amount_ils,
        contra_id_account:
          id === "contra_id_account" ? value : arr[i]?.contra_id_account,
        row_number: arr[i]?.row_number,
      };
      if (key === "currency_code" && gatesData && gatesData[value]) {
        let num1 = 1 / gatesData[value];
        let num = num1.toFixed(2);
        temp.exchange_rate_ils = num;
      }
      temp.amount_ils = (temp.exchange_rate_ils * temp.amount).toFixed(2);
      if (id === "amount" && isBalance === true) {
        temp.fee = arr[i]?.fee ? arr[i]?.fee : 0;
        temp.amount_ils = (temp?.amount_ils ? temp?.amount_ils : 0) - temp?.fee;
      }
      arr[i] = temp;
      if (temp && temp.financial_asset_code === "3" && value !== "3") {
        if (key === "cheque_date") {
          let chequeDays, kindCashingIntrest;
          chequeDays = checkChequeDays(
            temp.financial_asset_details?.cheque_date
          );
          chequeDays !== undefined && chequeDays > 0
            ? (kindCashingIntrest = "cheque_cashing_rate")
            : (kindCashingIntrest = "cheque_cashing_cash_rate");
          let prop: any =
            searchCustomer?.priceProgram?.price_program_properties;
          let properties: any = {};
          prop?.forEach((element: any) => {
            properties[element.name] = element.value
              ? element.value
              : element.percent;
          });
          chequeDays = checkChequeDays(value);

          if (
            properties[kindCashingIntrest] * 100 ===
            temp.financial_asset_details?.cashing_intrest
          ) {
            chequeDays !== undefined && chequeDays > 0
              ? (kindCashingIntrest = "cheque_cashing_rate")
              : (kindCashingIntrest = "cheque_cashing_cash_rate");
            temp.financial_asset_details.cashing_intrest =
              properties[kindCashingIntrest] * 100;
          }
          temp.financial_asset_details.cheque_days = chequeDays;
          temp.financial_asset_details.cheque_date = value;
        }
        let temp2 = temp;
        temp2 = calcChequeDetails(temp);
      }
      let sum = 0,
        total_expenses = 0,
        total_net_amount = 0,
        amount_round = 0,
        num1 = 0,
        num2 = 0,
        sumFee = 0;
      for (let i: any = 0; i < arr.length; i++) {
        if (isBalance === true) {
          sum += Number(arr[i]?.amount_ils) ? Number(arr[i]?.amount_ils) : 0;
          sumFee += arr[i]?.fee ? Number(arr[i]?.fee) : 0;
        } else {
          sum += Number(arr[i]?.amount_ils) ? Number(arr[i]?.amount_ils) : 0;
          total_expenses +=
            Number(
              arr[i]?.financial_asset_details?.cheque_expenses
                ? arr[i]?.financial_asset_details?.cheque_expenses
                : 0
            )
          // +
          // Number(
          //   arr[i]?.financial_asset_details?.allowed_expenses
          //     ? arr[i]?.financial_asset_details?.allowed_expenses
          //     : 0
          // ) +
          // Number(
          //   arr[i]?.financial_asset_details?.allowed_actual_expenses
          //     ? arr[i]?.financial_asset_details?.allowed_actual_expenses
          //     : 0
          // );
        }
      }
      if (isBalance === true) {
        setSumOut(sum);
        setSumFee(sumFee);
        if (FinancialAssets === 'financial_assets_in') {
          arr = arr.sort((a: any, b: any) => a.row_number - b.row_number);
        }
        setAction({ ...action, [FinancialAssets]: arr });
      } else {
        setSumIn(sum);

        total_net_amount += Number(sum) - Number(total_expenses?.toFixed(2));
        num1 = Number(Math.round(total_net_amount));
        num2 = Number(total_net_amount?.toFixed(2));
        amount_round = num1 - num2;
        total_net_amount = Math.round(total_net_amount);
        total_expenses = Number(total_expenses?.toFixed(2));
        let c: any = action;
        !ifChanges &&
          !isEqual(c[FinancialAssets], arr) &&
          value !== "" &&
          setIfChanges(true);
        if (FinancialAssets === 'financial_assets_in') {
          arr = arr.sort((a: any, b: any) => a.row_number - b.row_number);
        }
        setAction({
          ...action,
          [FinancialAssets]: arr,
          total_amount_ils: sum,
          total_expenses: total_expenses,
          amount_round: amount_round,
          total_net_amount: total_net_amount,
        });
      }
    }
  };
  const BalanceDeal = async (amount: any, exchange_rate_ils: any, key: any) => {
    let arrOut: any = [],
      sumOut = 0,
      sumFee = 0;
    arrOut = [...action["financial_assets_out"]];
    for (let i = 0; i < arrOut.length; i++) {
      sumOut += arrOut[i].amount_ils ? Number(arrOut[i].amount_ils) : 0;
      sumFee += arrOut[i].fee ? Number(arrOut[i].fee) : 0;
    }
    let balance =
      (action?.total_net_amount -
        (sumOut + sumFee - amount * exchange_rate_ils)) /
      exchange_rate_ils;
    balance >= 0 && addFinancialIn(key, balance, true);
  };
  const calculateBalance = () => {
    let arrOut = [...action.financial_assets_out];
    if (
      sumIn &&
      arrOut?.length === 1 &&
      arrOut[0].currency_code === "ILS" &&
      Number(arrOut[0].exchange_rate_ils) === 1
    ) {
      BalanceDeal(
        arrOut[0].amount,
        arrOut[0]?.exchange_rate_ils,
        `amount,${arrOut[0]?.row_number}`
      );
    }
  };

  const checkRequiredFields = (element: any) => {
    if (
      element?.financial_asset_code === "3" &&
      (!element?.financial_asset_details?.cheque_date ||
        !element?.financial_asset_details?.reference_number ||
        !element.amount)
    )
      return true;
    if (
      element?.financial_asset_code === "12" &&
      !element?.financial_asset_details?.card_holder?.ID_number
    )
      return true;
    if (
      element?.financial_asset_code === "9" &&
      !element?.financial_asset_details?.account_holder?.ID_number
    )
      return true;
    return false;
  };
  const displayDetailsTransaction = (c: any) => {
    sessionStorage.setItem("enableMultiScanCheck", "false");
    let arr = [...action["financial_assets_in"]];
    let i = arr.findIndex(
      (item: any) => Number(item.row_number) === Number(c?.row_number)
    );

    if (c?.financial_asset_code === "12") {
      setCredit(true);
    }
    if (c?.financial_asset_code === "9") {
      setTransferFromCustomer(true);
    }
    if (c?.financial_asset_code === "1") {
      setCashPayment(true);
    }
    if (c?.financial_asset_code === "3") {
      setCheckDetails(checkDeatils => !checkDeatils);
      let defaultcontraCheque = contraCheck.find(
        (item: any) => item.is_default === true
      );
      if (!arr[i]?.contra_id_account && defaultcontraCheque) {
        arr[i].contra_id_account = defaultcontraCheque?.key;
        const sortArray = arr.sort((a: any, b: any) => a.row_number - b.row_number);
        setAction({ ...action, financial_assets_in: sortArray });
      }
      if (
        !c.financial_asset_details?.media?.media_face?.id_media &&
        !c.financial_asset_details?.media?.media_back?.id_media &&
        !c.financial_asset_details?.id_media_face &&
        !c.financial_asset_details?.id_media_back &&
        settings?.cheque_scanning_popup !== "enter_details_manually"
      )
        setScanButton(true);
      SetIsIconScanButton(true);
      setRowNumber(c?.row_number);
    }
  };

  console.log('checkDetails0000', checkDetails)
  return (
    <>
      {/* <CheckImageProcessor /> */}
      {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
      {showDialogFailed && (
        <DialogMessages
          setansDelete={setShowDialogFailed}
          subText={messageError}
          title={t("note")}
          setshowModalDelete={setShowDialogFailed}
          dialogType={"dialogMessage"}
          setSaveDataConversion={setSaveDeleteRowActoin}
        />
      )}
      {credit ? (
        <CustomDialogCreditFromCustomer
          emptyRequiredFields={emptyRequiredFields}
          contraCreditCard={contraCreditCard}
          typeAction={typeAction}
          conversionCustomer={searchCustomer}
          setConvertions={setAction}
          convertions={action}
          rowNumber={rowNumber}
          tableType={tableType}
          setShowDialog={setCredit}
          userCredentials={userCredentials}
        />
      ) : (
        ""
      )}

      {checkDetails ? (
        <CheckDetailsDialog
          scanButton={scanButton}
          setScanButton={setScanButton}
          emptyRequiredFields={emptyRequiredFields}
          ifChanges={ifChanges}
          bankRow={arrInformationScan}
          contraCheck={contraCheck}
          setIfChanges={setIfChanges}
          typeAction={typeAction}
          sourcePage="discounts"
          isIconScanButton={isIconScanButton}
          SetIsIconScanButton={SetIsIconScanButton}
          conversionCustomer={searchCustomer}
          setConvertions={setAction}
          convertions={action}
          rowNumber={rowNumber}
          tableType={tableType}
          setShowDialog={setCheckDetails}
          setAllScanChecks={setAllScanChecks}
          /*setsumIn={setsumIn}*/ userCredentials={userCredentials}
          setCheckType={setCheckType}
        />
      ) : (
        ""
      )}

      {TransferFromCustomer && (
        <CustomDialogTransferFromCustomer
          emptyRequiredFields={emptyRequiredFields}
          contraBankAccounts={contraBankAccounts}
          typeAction={typeAction}
          conversionCustomer={searchCustomer}
          rowNumber={rowNumber}
          tableType={tableType}
          userCredentials={userCredentials}
          setConvertions={setAction}
          convertions={action}
          setShowDialog={setTransferFromCustomer}
        />
      )}

      {cashPayment ? (
        <CashPaymentDialog
          setIfChanges={setIfChanges}
          setConvertions={setAction}
          contraCash={contraCash}
          convertions={action}
          rowNumber={rowNumber}
          tableType={tableType}
          setShowDialog={setCashPayment}
        />
      ) : (
        ""
      )}
      {clickScan && (
        <div>
          <ScanCheck
            setAllScanChecks={setAllScanChecks}
            allScanChecks={allScanChecks}
            setIsFinishScan={() => { }}
            isIconScanButton={isIconScanButton}
            bankEnum={enumsDropdown?.bank}
            updateBankAccount={{}}
            setArrScanChecksImgs={() => { }}
            setArrInformationScan={setArrInformationScan}
            arrInformationScan={arrInformationScan}
            fullCheck={true}
            setCheckDeatils={() => { }}
            checkDeatils={checkDeatils}
            setSearchData={() => { }}
            setUpdateBankAccount={() => { }}
            setShowDialog={setClickScan}
            idEntity={action?.id_entity}
            setCheckType={setCheckType}
          />
        </div>
      )}
      <div className="flex-row-between">
        <p className="title-text-2">{t("in")}</p>
        <DefaultButton
          className="button edit-button"
          onClick={() => { setClickScan(true); sessionStorage.setItem("enableMultiScanCheck", "true") }}
          checked={false}
          text={t("scanning")}
          iconProps={Icons.print}
          name="update"
          disabled={readOnly}
        />
      </div>
      <hr className="hr" style={{ width: "76vw" }}></hr>
      <div style={{ width: "76vw" }}>
        <div style={{ width: "100%" }}>
          <div className="title-in" style={{ backgroundColor: "#E1E1E1" }}>
            <p className={`title-discount-in-${dir}`} style={{ width: "13%" }}>
              {t("currency")}
            </p>
            <p className={`title-discount-in-${dir}`} style={{ width: "10%" }}>
              {t("type")}
            </p>
            <p
              className={`title-discount-in-${dir}`}
              style={{ width: "2.5%" }}
            ></p>
            <p className={`title-discount-in-${dir}`} style={{ width: "6%" }}>
              {t("gate")}
            </p>
            <p className={`title-discount-in-${dir}`} style={{ width: "9%" }}>
              {t("amount")}
            </p>
            <p className={`title-discount-in-${dir}`} style={{ width: "8%" }}>
              {t("dueDate")}
            </p>
            <p className={`title-discount-in-${dir}`} style={{ width: "6%" }}>
              {t("days")}
            </p>
            <p className={`title-discount-in-${dir}`} style={{ width: "6%" }}>
              {t("percent")}
            </p>
            <p className={`title-discount-in-${dir}`} style={{ width: "7%" }}>
              {t("interestDiscount")}
            </p>
            <p className={`title-discount-in-${dir}`} style={{ width: "7%" }}>
              {t("AdditionalAllowedExpenses")}
            </p>
            <p className={`title-discount-in-${dir}`} style={{ width: "8%" }}>
              {t("expenses")}
            </p>
            <p className={`title-discount-in-${dir}`} style={{ width: "11%" }}>
              {t("netILS")}
            </p>
          </div>
        </div>
        <hr
          className="hr"
          style={{ width: "100%", height: "2px", marginBottom: "0" }}
        ></hr>
        {action.financial_assets_in?.map((c: any, index: number) => (
          <div key={index} style={{ width: "100%" }}>
            <div
              className="inlineB"
              style={{
                marginTop: "7px",
                height: "33px",
                display: "flex",
              }}
            >
              <div style={{ width: "13%" }}>
                {!readOnly ? (
                  <ComboBox
                    id={`currency_code,${c.row_number}`}
                    label={t("")}
                    styles={{
                      root: {
                        backgroundColor: "rgb(250, 249, 248)",
                        ...(dir === "ltr" && {
                          paddingRight: "9px !important",
                          paddingLeft: "32px !important",
                        }),
                      },
                    }}
                    required={true}
                    selectedKey={c?.currency_code}
                    autoComplete="on"
                    allowFreeform={true}
                    options={enumsDropdown.currencyCode}
                    onChange={(
                      event: any,
                      option: any,
                      index: any,
                      value: any
                    ) =>
                      onChangeComboBox(
                        event,
                        option,
                        index,
                        value,
                        `currency_code,${c.row_number}`
                      )
                    }
                  />
                ) : (
                  <CustomDropdown
                    readOnly={readOnly}
                    onKeyDown={(e: any) => e.key === "Tab"}
                    otherInputId={""}
                    hasOtherValue={false}
                    options={enumsDropdown.currencyCode}
                    label={t("")}
                    onChange={addFinancialIn}
                    selectedKey={c?.currency_code}
                    id={`currency_code,${c.row_number}`}
                    othertextInput={t("")}
                  />
                )}
              </div>
              <div style={{ width: "10%" }}>
                <CustomDropdown
                  readOnly={readOnly}
                  onKeyDown={(e: any) => e.key === "Tab"}
                  otherInputId={""}
                  hasOtherValue={false}
                  options={enumsDropdown.finanssialAssetCode}
                  requiredField={
                    (emptyInContraAccount && !c.contra_id_account) ||
                      (emptyRequiredFields && checkRequiredFields(c))
                      ? true
                      : false
                  }
                  label={t("")}
                  onChange={addFinancialIn}
                  selectedKey={
                    c?.financial_asset_code === "10"
                      ? "9"
                      : c?.financial_asset_code
                  }
                  id={`financial_asset_code,${c.row_number}`}
                  othertextInput={t("")}
                />
              </div>
              <div
                className="scan-div"
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
              >
                <i
                  title="הצג פרטים"
                  onClick={() => {
                    !readOnly && displayDetailsTransaction(c);
                  }}
                >
                  [...]
                </i>
              </div>
              <CurrencyInput
                className={`padding-currency-${dir} currency-input currency-discount`}
                id={`exchange_rate_ils,${c.row_number}`}
                name={`exchange_rate_ils,${c.row_number}`}
                placeholder="0.00"
                tabIndex={-1}
                value={c?.exchange_rate_ils}
                decimalsLimit={2}
                allowNegativeValue={false}
                readOnly={
                  c?.currency_code === defaultCurrencyCode ? true : readOnly
                }
                style={{
                  width: "6%",
                  backgroundColor: readOnly ? "#FAF9F8" : "white",
                }}
                onValueChange={(value, name) =>
                  addFinancialIn(name || "", value)
                }
              />
              <div style={{ width: "9%", display: "flex" }}>
                <CurrencyInput
                  className={`padding-currency-${dir} currency-input currency-discount`}
                  id={`amount,${c.row_number}`}
                  name={`amount,${c.row_number}`}
                  placeholder="0.00"
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  autoFocus={true}
                  value={c?.amount}
                  onBlur={() =>
                    action.financial_assets_out?.length === 1 &&
                    calculateBalance()
                  }
                  required={emptyRequiredFields && !c?.amount ? true : false}
                  readOnly={readOnly}
                  style={
                    emptyInAmount && !c?.amount
                      ? {
                        width: "100%",
                        backgroundColor: readOnly ? "#FAF9F8" : "white",
                      }
                      : {
                        width: "100%",
                        backgroundColor: readOnly ? "#FAF9F8" : "white",
                      }
                  }
                  onValueChange={(value, name) => {
                    c?.amount !== Number(value) &&
                      addFinancialIn(name || "", value);
                  }}
                />
              </div>
              <div style={{ width: "8%" }}>
                <DatePicker
                  selected={
                    c.financial_asset_details?.cheque_date &&
                    new Date(c.financial_asset_details?.cheque_date)
                  }
                  className={`${readOnly
                    ? "readOnly-date"
                    : c.financial_asset_code !== "3"
                      ? "disabled-date disabled-discount"
                      : "input-date"
                    }`}
                  readOnly={c.financial_asset_code !== "3" || readOnly}
                  closeOnScroll={true}
                  onCalendarClose={() =>
                    action.financial_assets_out?.length === 1 &&
                    calculateBalance()
                  }
                  placeholderText="dd/mm/yyyy"
                  locale={"he-IL"}
                  dateFormat={[
                    "dd/MM/yyyy",
                    "dd-MM-yyyy",
                    "dd.MM.yyyy",
                    "ddMMyyyy",
                    "dd,MM,yyyy",
                  ]}
                  onChange={(date: any) => {
                    addFinancialIn(
                      `cheque_date,${c.row_number}`,
                      moment(date).format("YYYY-MM-DD")
                    );
                  }}
                />
              </div>
              <div
                style={{ width: "6%" }}
                className={`${c.financial_asset_code !== "3" ? "disabled-discount" : ""
                  }`}
              >
                <CustomTextField
                  label={""}
                  tabIndex={-1}
                  disabled={c.financial_asset_code !== "3" ? true : false}
                  placeholder="0"
                  value={
                    c.financial_asset_code === "3"
                      ? Number(
                        c?.financial_asset_details?.cheque_days
                          ? c?.financial_asset_details?.cheque_days
                          : 0
                      ) +
                      Number(
                        c?.financial_asset_details?.cheque_value_days
                          ? c?.financial_asset_details?.cheque_value_days
                          : 0
                      )
                      : 0
                  }
                  readOnly={true}
                  onChange={addFinancialIn}
                  styleObj={false}
                  id={`["financial_asset_details"].cheque_days,${c.row_number}`}
                />
              </div>
              {/* <div style={{ display: "flex",width:"6%" }}>
                                <p className={`prefix-currency-${dir}`}>%</p>
                                <CurrencyInput
                                    className={`${c.financial_asset_code === "3" ?
                                        `currency-input currency-discount padding-currency-${dir}` : `currency-discount padding-currency-${dir}`}`}
                                    id="cashing_intrest"
                                    name={`cashing_intrest,${c.row_number}`}
                                    placeholder="0.00"
                                    onBlur={() => action.financial_assets_out?.length === 1 && calculateBalance()}
                                    disabled={c.financial_asset_code !== "3" ? true : false}
                                    value={c?.financial_asset_details?.cashing_intrest ? c?.financial_asset_details?.cashing_intrest : 0}
                                    readOnly={readOnly}
                                    style={c.financial_asset_code === "3" ? {
                                        width: '80%', backgroundColor: readOnly ? "#FAF9F8" : "white",
                                    } : { width: '80%', backgroundColor: "rgb(250, 249, 248)", color: "rgb(161, 159, 157)", height: "32px" }}
                                    decimalsLimit={2}
                                    onValueChange={(value, name) => onChangeCashing(name || "", value)}
                                />
                            </div> */}

              {/* <label style={{ fontWeight: 600, padding: "5px 0px" }}>{t('insert')}</label> */}
              <div style={{ display: "flex", width: "6%" }}>
                <p className={`prefix-in-currency-${dir}`}>%</p>
                <CurrencyInput
                  className={`currency-precent prefix-in-currency-padding-${dir}
                                    ${c.financial_asset_code !== "3"
                      ? "disabled-discount"
                      : ""
                    }`}
                  id="cashing_intrest"
                  name={`cashing_intrest,${c.row_number}`}
                  placeholder="0.00"
                  onBlur={() =>
                    action.financial_assets_out?.length === 1 &&
                    calculateBalance()
                  }
                  disabled={c.financial_asset_code !== "3" ? true : false}
                  value={
                    c?.financial_asset_details?.cashing_intrest
                      ? c?.financial_asset_details?.cashing_intrest
                      : 0
                  }
                  readOnly={readOnly}
                  style={
                    c.financial_asset_code === "3"
                      ? {
                        borderWidth: "1px",
                        width: "100%",
                        backgroundColor: readOnly ? "#FAF9F8" : "white",
                        height: "32px",
                        paddingRight: " 30px",
                      }
                      : {
                        width: "100%",
                        backgroundColor: "rgb(250, 249, 248)",
                        color: "rgb(161, 159, 157)",
                        borderWidth: "1px",
                        height: "32px",
                        paddingRight: " 30px",
                      }
                  }
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    onChangeCashing(name || "", value)
                  }
                />
              </div>

              <div
                style={{ width: "7%" }}
                className={`${c.financial_asset_code !== "3" ? "disabled-discount" : ""
                  }`}
              >
                <CustomTextField
                  tabIndex={-1}
                  value={
                    c?.financial_asset_details?.cashing_amount
                      ? Number(c?.financial_asset_details?.cashing_amount)
                        .toFixed(2)
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0
                  }
                  readOnly={true}
                  disabled={c.financial_asset_code !== "3" ? true : false}
                  id={"cashing_amount"}
                  onChange={""}
                  styleObj={false}
                />
              </div>
              <CurrencyInput
                className={`${c.financial_asset_code === "3" && "currency-input"
                  } 
                                currency padding-currency-${dir}
                                ${c.financial_asset_code !== "3"
                    ? "disabled-discount"
                    : ""
                  }`}
                id="allowed_expenses"
                name={`allowed_expenses,${c.row_number}`}
                onBlur={() =>
                  action.financial_assets_out?.length === 1 &&
                  calculateBalance()
                }
                placeholder="0.00"
                disabled={c.financial_asset_code !== "3" ? true : false}
                value={
                  c?.financial_asset_details?.allowed_expenses
                    ? c?.financial_asset_details?.allowed_expenses
                    : 0
                }
                style={
                  c.financial_asset_code === "3"
                    ? {
                      width: "7%",
                      backgroundColor: readOnly ? "#FAF9F8" : "white",
                    }
                    : {
                      width: "7%",
                      backgroundColor: "rgb(250, 249, 248)",
                      color: "rgb(161, 159, 157)",
                      height: "32px",
                    }
                }
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  onChangeCashing(name || "", value)
                }
              />

              <CurrencyInput
                tabIndex={-1}
                className={`padding-currency-${dir} padding-currency chequeExpenses
                                ${c.financial_asset_code !== "3"
                    ? "disabled-discount"
                    : ""
                  }`}
                id={`["financial_asset_details"].,${c?.row_number}`}
                name={`["financial_asset_details"].,${c?.row_number}`}
                placeholder="0.00"
                value={Number(
                  (c?.financial_asset_details?.cheque_expenses
                    ? Number(c?.financial_asset_details?.cheque_expenses)
                    : 0
                  ).toFixed(2)
                )}
                readOnly={true}
                disabled={c.financial_asset_code !== "3" ? true : false}
                decimalsLimit={2}
              />
              <div style={{ width: "11%" }}>
                <TextField
                  className={`text-discount-in-${dir}`}
                  style={{
                    backgroundColor: "rgb(250, 249, 248)",
                    fontWeight: "bold",
                    fontSize: "15px",
                    border:
                      isNegativeBalance &&
                        Number(c?.financial_asset_details?.cheque_net) < 0
                        ? "solid red 2px"
                        : "",
                  }}
                  tabIndex={-1}
                  label={""}
                  value={
                    c.financial_asset_code === "3"
                      ? Number(c?.financial_asset_details?.cheque_net)
                        ? Number(c?.financial_asset_details?.cheque_net)
                          .toFixed(2)
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "0"
                      : Number(c.amount_ils)
                        .toFixed(2)
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  readOnly={true}
                  id={`amount_ils,${c.row_number}`}
                />
              </div>
              <p
                style={{
                  display: "grid",
                  paddingTop: "0",
                  textAlign: "center",
                  lineHeight: "0.8",
                  marginBottom: "0",
                  width: "fit-content",
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className={`delete-row-icon-${dir}`}
                  title={t("deleteRow")}
                  onClick={() => removeFObject(c.row_number)}
                />
                {c.amount_ils > 0 && (
                  <FontAwesomeIcon
                    onKeyDown={(e) => e.key === "Enter" && addRow(c.row_number)}
                    className={
                      c.amount_ils > 0
                        ? `iconRefresh add-row-icon-${dir}`
                        : `iconDisabled add-row-icon-${dir}`
                    }
                    icon={faPlusCircle}
                    title={t("addRow")}
                    onClick={() => {
                      addRow(c.row_number);
                    }}
                  />
                )}
              </p>
            </div>
          </div>
        ))}




        {scanButton && <div></div>}
        <div
          style={{
            marginTop: "1px",
            width: "100%",
            height: "fit-content",
            border: "solid 2px lightgrey",
          }}
        >
          <div className={`calc-discount-${dir}`}>
            {sumIn !== 0 && (
              <div className={`title-calc-discount-${dir}`}>
                {t("totalFaceValue")}
              </div>
            )}
            {sumIn !== 0 && (
              <div className="total-calc-discount">
                {Number(sumIn)
                  ?.toFixed(2)
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                {t("ILS")}
              </div>
            )}
            {sumIn === 0 && (
              <div className={`title-calc-discount-${dir}`}>
                {t("totalFaceValue")}
              </div>
            )}
            {sumIn === 0 && (
              <div className=" total-calc-discount">0.00 {t("ILS")}</div>
            )}
          </div>
          <div className={`calc-discount-${dir}`}>
            {action?.amount_round !== 0 && (
              <div className={`title-calc-discount-${dir}`}>
                {t("digitRounding")}:{" "}
              </div>
            )}
            {action?.amount_round !== 0 && (
              <div className="total-calc-discount">
                <div className={`ltr-${dir}`}>
                  {action?.amount_round?.toFixed(2)}
                </div>
                &nbsp;{t("ILS")}
              </div>
            )}
            {action?.amount_round === 0 && (
              <div className={`title-calc-discount-${dir}`}>
                {t("digitRounding")}:{" "}
              </div>
            )}
            {action?.amount_round === 0 && (
              <div className="total-calc-discount">0.00 {t("ILS")}</div>
            )}
          </div>
          <div className={`calc-discount-${dir}`}>
            {action?.total_net_amount !== 0 && (
              <div className={`title-calc-discount-${dir}`}>
                {t("totalNet")}{" "}
              </div>
            )}
            {action?.total_net_amount !== 0 && (
              <div className="total-calc-discount">
                <div className={`ltr-${dir}`}>
                  {action?.total_net_amount
                    ?.toFixed(2)
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
                &nbsp;{t("ILS")}
              </div>
            )}
            {action?.total_net_amount === 0 && (
              <div className={`title-calc-discount-${dir}`}>
                {t("totalNetILS")}:{" "}
              </div>
            )}
            {action?.total_net_amount === 0 && (
              <div className="total-calc-discount">0.00 {t("ILS")}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DiscountFinancialAssetsIn;
