import * as React from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import BranchSettingsC from '../branchSettingsC';
import UsersClient from '../usersClient/UsersClient';

const SideSubSettingsC: React.FunctionComponent = () => {

    return (
        <div>
            <Switch>
                <Route exact path="/setting" component={BranchSettingsC} />
                <Route exact path='/users' component={UsersClient} />
            </Switch>
        </div>
    );
};
export default withRouter(SideSubSettingsC)