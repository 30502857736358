import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useBoolean } from '@fluentui/react-hooks';
import { cloneDeep } from 'lodash';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { useTranslation } from 'react-i18next';
import { Pagination } from "@pnp/spfx-controls-react/lib/Pagination";


import { CustomTextField } from '../../../../shared/components/TextField';
import { chequeEvent, managementCheckData } from '../../../../store/actions/discountAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IUserCredentials } from '../../../customerDetails/CustomerDetails';
import { Callout, getTheme, mergeStyleSets } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react';
import { Checkbox } from '@fluentui/react';
import { CustomerDate } from '../../../../shared/components/CustomHooks/concatTextFields';
import { convertArrToDropdropdown } from '../../../../shared/components/CustomHooks/GlobalFunctions';
import moment from 'moment';
import { DialogMessages } from '../../../../shared/components/dialog/DialogMessages';
import { DefaultButton, Dialog, DialogFooter, ContextualMenu } from 'office-ui-fabric-react';
import { TextFeildNote } from '../../../../shared/components/Note';
import { CheckDetailsDialogComplete } from '../../../../shared/components/dialog/dialogTransaction/CheckDetailsDialogComplete';

import './AGtable.css';
import "../../../../scssPages//pagination.scss"
import { readCopycompletedDoc } from '../../../../store/actions/completeDocAction';
import DialogCompleteTransactions from '../../../../shared/components/dialog/dialogTransaction/DialogCompleteTransactions';
import { accountData } from '../../../../store/actions/accountAction';
import ChequesListDialog from './ChequesListDialog';
import CurrencyInput from 'react-currency-input-field';
import ChequeHistoryDialog from './ChequeHistoryDialog';
import { log } from 'util';

const initialDataAllFilter = {
    document_number: ["equal", ""],
    ID_number: ["equal", ""],
    entity_name: ["like", ""],
    account_holder_name: ["like", ""],
    beneficiary_name: ["like", ""],
    bank_account_number: ["equal", ""],
    reference_number: ["like", ""],
    amount: ["less_than", ""],
    currency_code: ["equal", ""],
    cheque_date: ["less_than", ""],
    status_id: ["equal", "1"],
}

export default function AGtable() {
    const [t] = useTranslation();
    const dispatch = useDispatch()

    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const enums = useSelector(({ dataReducer }: any) => dataReducer?.enums)
    const chequeManagmentEventData = useSelector(({ discountReducer }: any) => discountReducer?.chequeManagmentEventData)
    const [messageError, setMessageError] = useState('')
    const [serverMessageError, setServerMessageError] = useState<any>('')
    const [serverMessageErrorServer, setServerMessageErrorServer] = useState<any>('')
    const [showDialogTransaction, setShowDialogTransaction] = useState(false)
    const [openChecquesListDialog, setOpenChecquesListDialog] = useState(false)
    const [dialogTitle, setDialogTitle] = useState(t('note'))
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [arrTitle, setArrTitle] = useState([
        { key: "numTransaction", eng: 'document_number', sortValue: false, default: "like" },
        { key: "numIdentification", eng: 'ID_number', sortValue: false, default: "like" },
        { key: "customerName", eng: 'entity_name', sortValue: false, default: "like" },
        { key: "holderName", eng: 'account_holder_name', sortValue: false, default: "like" },
        { key: "beneficiaryName", eng: 'beneficiary_name', sortValue: false, default: "like" },
        { key: "account", eng: 'bank_account_number', sortValue: false, default: "like" },
        { key: "numCheque", eng: 'reference_number', sortValue: false, default: "like" },
        { key: "amount", eng: 'amount', sortValue: false, default: "less_than" },
        { key: "currency", eng: 'currency_code', sortValue: false, default: "like" },
        { key: "repaymentDate", eng: 'cheque_date', sortValue: false, default: "less_than" },
        { key: "status", eng: 'status_id', sortValue: false, default: "equal" },
    ])
    const defaultSort = {
        field_name: "cheque_date",
        search_type: "greater_than",
        search_value: moment(new Date()).format("YYYY-MM-DD")
    }
    const [search_fields, setSearch_fields] = useState<any>([defaultSort]);
    const [checkCheqes, setCheckCheqes] = useState<any>([]);
    const [rowData, setRowData] = useState<any>();
    const [listContraAccount, setListContraAccount] = useState<any>();
    const [displayListContraAccount, setDisplayListContraAccount] = useState<any>([]);

    const [search_fields_account, setSearch_fields_account] = useState<any>();


    const [curentPage, setCurentPage] = useState(1);
    const [numResults, setnumResults] = useState(-1)
    const [showError, setShowError] = useState(0)
    const [numRecourd, setNumRecourd] = useState(10)
    const [actionsList, setActionList] = useState<any>([])
    const [chosenAction, setChosenAction] = useState<any>({})
    const [chosenAccount, setChosenAccount] = useState<any>({})
    const [loading, { setTrue: displayLoading, setFalse: hideLoading }] = useBoolean(true);
    const [filter, setFilter] = useState({ key: '', value: false })
    const operatorStringList: IDropdownOption[] = [{ key: "like", text: t("like") },
    { key: "begin_with", text: t("begin_with") }, { key: "equal", text: t("equal") }]
    const operatorAmountList: IDropdownOption[] = [{ key: "less_than", text: t("less_than") },
    { key: "equal", text: t("equal") }, { key: "greater_than", text: t("greater_than") }]
    const numRecourdList: IDropdownOption[] = [{ key: 10, text: "10" }, { key: 50, text: "50" }, { key: 100, text: "100" }]
    const arrWidth: any = ['115px', '100px', '175px', '175px', '175px', '100px', '100px', '90px', '85px', '121px', '120px', '130px', '130px']
    const [isValidDateCreate, setIsValidDateCreate] = useState(true)
    const [dateFilter, setDateFilter] = useState({ cheque_date: "", cheque_date2: "" })
    const [dataAllfilter, setDataAllFilter] = useState<any>(initialDataAllFilter)
    const [checkedObj, setCheckObj] = useState<any>({})
    const [allChecked, setAllChecked] = useState<any>(false)
    const [note, setNote] = useState<any>({ cheque: {}, note: "" })
    const [chequeDetailsDialog, setChequeDetailsDialog] = useState<any>(false)
    const [chequeHistoryDialog, setChequeHistoryDialog] = useState<any>(false)
    const [chequeItem, setChequeItem] = useState<any>(false)
    const [saveLastAction, setSaveLastAction] = useState<any>()


    const [checkDeatils, setCheckDeatils] = useState<any>(
        {
            bank_account_id: "", id_account_holder: "", id_beneficiary: "",
            id_endorsee: "", related_entities: [], allowed_expenses: 0
            , allowed_actual_expenses: 0, cashing_intrest: 0, fee: 0,
            cashing_amount: 0, cheque_expenses: 0,
            cheque_days: 0, cheque_net: 0, cheque_value_days: 0, date_created: "", cheque_cashing_date: "",
            cheque_face_scan_path: "", cheque_back_scan_path: "", reference_number: "", cheque_date: ""
            , cheque_endorses_number: 0, id_media_face: "", id_media_back: ""
            , media_face_extension: "", media_back_extension: "", media: { media_face: {}, media_back: {} }
        })
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
    };
    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        dragOptions: dragOptions
    };

    useEffect(() => {
        if (enums) {
            console.log("enums", enums);

            let arr: any = {
                "chequesManagement": convertArrToDropdropdown([]),
                "status": convertArrToDropdropdown([]),
            };
            for (let i = 0; i < enums.length; i++) {
                if (enums[i]?.cheques_management !== undefined) {
                    let chequesManagement = enums[i].cheques_management
                    for (let i = 0; i < chequesManagement.length; i++) {
                        if (chequesManagement[i].cheque_event_id !== undefined) {
                            chequesManagement[i]?.cheque_event_id.forEach((element: any) => {
                                arr.chequesManagement.push({
                                    key: element?.cheque_event_id,
                                    text: element?.cheque_event_name,
                                    contra_account_group_id: element?.contra_account_group_id,
                                    contra_account_placeholder: element?.contra_account_placeholder,
                                    is_contra_account: element?.is_contra_account,
                                })
                            });
                        }
                        if (chequesManagement[i].status_id !== undefined) {

                            arr.status = convertArrToDropdropdown(chequesManagement[i].status_id)

                        }
                    }
                }
                setActionList(arr);
            }
        }
    }, [enums])

    useEffect(() => {
        console.log(search_fields, "search_fields");

        if (userCredentials) {
            displayLoading()
            dispatch(managementCheckData(userCredentials, setSearch_fields_account,
                setRowData, curentPage, search_fields.length === 0 ? [] : search_fields, numRecourd, setServerMessageError,
                setMessageError, setDialogTitle))
        }
        // eslint-disable-next-line
    }, [userCredentials, curentPage])


    const _onPageUpdate = async (pageno?: number) => {
        var currentPge = (pageno) ? pageno : curentPage;
        setCurentPage(currentPge);
        setnumResults(rowData?.records_found)
    };

    useEffect(() => {
        if (rowData?.search_results) {
            _onPageUpdate();
            hideLoading();
        }
    }, [rowData?.search_results]);

    useEffect(() => {
        console.log(dateFilter, "dateFilter1233");
        if (dateFilter.cheque_date) {
            enterFilter({
                key: "repaymentDate", eng: 'cheque_date', sortValue: false,
                default: "greater_than"
            }, dateFilter.cheque_date, "value")
        }
        // eslint-disable-next-line
    }, [dateFilter.cheque_date])

    useEffect(() => {
        console.log(dateFilter, "dateFilter123++3");
        if (dateFilter.cheque_date2) {
            enterFilter({
                key: "ת. פרעון", eng: 'cheque_date', sortValue: false,
                default: "less_than"
            }, dateFilter.cheque_date2, "value")
        }
        // eslint-disable-next-line
    }, [dateFilter.cheque_date2])

    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])

    useEffect(() => {
        if (listContraAccount?.search_results?.length) {
            let arr: any = [...displayListContraAccount]
            listContraAccount?.search_results?.forEach((account: any, index: number) => {
                arr.push({
                    text: account?.account_name + "  " + account?.account_number, key: index,
                    id_entity: account?.id_entity, id_account: account?.id_account,
                })
            });
            setDisplayListContraAccount(arr)
        }
    }, [listContraAccount])

    useEffect(() => {
        if (chequeManagmentEventData?.cheques_list?.length) {
            setOpenChecquesListDialog(true)

        }
    }, [chequeManagmentEventData])


    const enterFilter = (field: any, value: any, key: any) => {
        let index
        let arr = cloneDeep(search_fields)
        let data = cloneDeep(dataAllfilter)
        let item: any
        console.log(arr, "arr111");
        console.log(item, "itemmmmm");


        item = {
            "field_name": field.eng,
            "search_type": "",
            "search_value": ""
        }
        console.log(item, "itemmm111mm");

        if (field?.eng?.includes("date"))
            index = arr.findIndex((x: any) => x.field_name === field.eng && x.search_type === field.default)
        else
            index = arr.findIndex((x: any) => x.field_name === field.eng)


        if (index !== -1) {
            item = arr[index]
        }
        if (key === 'operator') {
            data[field.eng][0] = value
            item["search_type"] = value
        }

        else {
            item["search_value"] = value
            data[field.eng][1] = value
        }
        if (index !== -1) {
            let arr1 = search_fields
            arr1[index] = item
            setSearch_fields(arr1)
        } else {
            if (item["search_type"] === "") {
                item["search_type"] = field.default
            }
            setSearch_fields([...search_fields, item])
        }
        setDataAllFilter(data)
    }



    const chooseNumRecord = (value: any, key: any) => {
        setNumRecourd(key.key)
        displayLoading()
        dispatch(managementCheckData(userCredentials, setSearch_fields, setRowData,
            curentPage, search_fields, key.key, setServerMessageError, setMessageError, setDialogTitle))
    }
    const sortCol = (col: any, index: any) => {
        if (col?.sortValue)
            rowData?.search_results.sort((a: any, b: any) => (a[col?.eng] > b[col?.eng] ? -1 : 1))
        else
            rowData?.search_results.sort((a: any, b: any) => (a[col?.eng] > b[col?.eng] ? 1 : -1))
        let item = { key: col.key, eng: col.eng, sortValue: !col.sortValue, filterValue: col.fliterValue, default: col?.default }
        let arr: any = [...arrTitle]
        arr[index] = item
        setArrTitle(arr)
    }
    const filterCol = (key: any) => {
        setFilter({ key: key, value: !filter.value })
    }
    const sendFilterConditions = async () => {
        let arrToSend: any[] = []
        for (let index = 0; index < search_fields.length; index++) {
            if (!search_fields[index].search_type || !search_fields[index].search_value) {
                setSearch_fields((item: any) => item.filter((x: any) => x.field_name !== search_fields[index].field_name));
            }
            else {
                arrToSend.push(search_fields[index])
            }
        }
        setFilter({ key: '', value: false })
        setDataAllFilter(initialDataAllFilter)
        displayLoading()
        console.log(filter, "filterאמת");

        await dispatch(managementCheckData(userCredentials, setSearch_fields, setRowData, 1, arrToSend,
            numRecourd, setServerMessageError, setMessageError, setDialogTitle))

    }
    const addCheckCheqe = (cheqe: any) => {
        debugger
        if (cheqe === "all") {
            let copyCheckedObj: any = { ...checkedObj }
            rowData?.search_results.forEach((cheque: any) => {
                copyCheckedObj[cheque.id_cheque] = !allChecked
            });
            setCheckObj(copyCheckedObj)
            setAllChecked(!allChecked)

        }
        else {
            let checked, isCheque = checkCheqes.find((x: any) => x.id_cheque === cheqe.id_cheque)
            if (isCheque)
                setCheckCheqes(checkCheqes.filter((item: any) => item.id_cheque === cheqe.id_cheque))
            else setCheckCheqes([...checkCheqes, cheqe])
            if (checkedObj[cheqe.id_cheque] === undefined)
                checked = true
            else
                checked = !checkedObj[cheqe.id_cheque]
            setCheckObj({ ...checkedObj, [cheqe.id_cheque]: checked })
        }
    }
    const actionForCheque = async (typeRequestMethod: string) => {
        debugger
        if (chosenAction.action) {
            let copyCheques: any = []


            console.log(rowData?.search_results, "rowData?.search_results");

            rowData?.search_results.forEach((cheque: any) => {
                console.log(checkedObj[cheque.id_cheque], "checkedObj");

                if (checkedObj[cheque.id_cheque] === true) {
                    copyCheques.push({
                        "id_entity": cheque?.id_entity,
                        "id_cheque": cheque?.id_cheque
                    })
                }
                console.log(copyCheques, "copyCheques");

            });
            console.log(chosenAction?.is_contra, "hosenAction?.is_contra");
            console.log(chosenAccount.idAccount, "idAccountchosenAccount");
            console.log(!chosenAction?.is_contra, "!chosenAction?.is_contra");

            if (chosenAction?.is_contra || chosenAccount?.idAccount || !chosenAction?.is_contra) {
                if ((chosenAction?.id === 31 && chosenAction?.accepted_amount) || chosenAction?.id !== 31) {

                    if (copyCheques.length > 0 && typeRequestMethod === 'confirm_update_cheque') {
                        setSaveLastAction(copyCheques);
                        if (chosenAction?.id === 10 && !chosenAccount.idAccount) {
                            setShowError(6);
                            return;
                        }
                        await dispatch(chequeEvent(userCredentials, typeRequestMethod, chosenAccount, chosenAction,
                            copyCheques, setServerMessageError, setServerMessageErrorServer, setMessageError, setDialogTitle, t))
                        displayLoading()
                        await dispatch(managementCheckData(userCredentials, setSearch_fields, setRowData, curentPage, [], numRecourd,
                            setServerMessageError, setMessageError, setDialogTitle))
                    }
                    else {
                        if (typeRequestMethod === 'update_cheque') {
                            await dispatch(chequeEvent(userCredentials, typeRequestMethod, chosenAccount, chosenAction,
                                saveLastAction, setServerMessageError, setServerMessageErrorServer, setMessageError, setDialogTitle, t))
                            displayLoading()
                            await dispatch(managementCheckData(userCredentials, setSearch_fields, setRowData, curentPage, [], numRecourd,
                                setServerMessageError, setMessageError, setDialogTitle))
                            setSaveLastAction(null);
                        }
                        if (chosenAction?.id === 10 && !chosenAccount.idAccount)
                            setShowError(6);
                        setShowError(5);
                    }
                }
                else {
                    setShowError(3)
                }
            }
            else {
                setShowError(4);
            }
        }
        else {
            setShowError(1);
        }
    }
    const confirmChequeEvent = async () => {
        actionForCheque("update_cheque")
        // actionForCheque("execute")

    }

    const saveNote = async () => {
        let chequeItem = []
        chequeItem.push({
            "id_entity": note?.cheque?.id_entity,
            "id_cheque": note?.cheque?.id_cheque,
            "note": note?.note
        })
        chosenAction.id = 99
        // await dispatch(chequeEvent(userCredentials, "execute", chosenAccount, chosenAction,
        //     chequeItem, setServerMessageError, setMessageError, setDialogTitle, t))
        await dispatch(chequeEvent(userCredentials, "update_cheque", chosenAccount, chosenAction,
            chequeItem, setServerMessageError, setServerMessageErrorServer, setMessageError, setDialogTitle, t))
        displayLoading()
        await dispatch(managementCheckData(userCredentials, setSearch_fields, setRowData, 1, [], numRecourd,
            setServerMessageError, setMessageError, setDialogTitle))
        toggleHideDialog()
    }

    const chooseAction = async (id: any, value: any, key: any) => {
        debugger
        let objAccount: any = chosenAccount, objAction: any = chosenAction, accountGroupId = true
        console.log(objAction, "objAction");
        console.log(chosenAction, "chosenAction");
        console.log(chosenAccount, "chosenAccount");


        console.log(id, "id");
        console.log(value, "value");
        console.log(key, "key");
        setShowError(0)
        if (key === 'action') {
            debugger;
            if (id === "status")
                objAction["status"] = value.key
            else {
                objAction[key] = value.text
                objAction["place"] = value?.contra_account_placeholder
                objAction["is_contra"] = value?.is_contra_account
                objAction["group_id"] = value?.contra_account_group_id
                objAction["id"] = value.key
                debugger;
                if (!value?.is_contra_account) {
                    objAccount["againstAccount"] = ""
                    setChosenAccount({ ...objAccount })
                    console.log("objAccount!!!!!!!1", objAccount);

                }
                else {
                    debugger
                    const array = value?.contra_account_group_id?.trim().slice(1, -1).split(",")
                    setDisplayListContraAccount([])
                    array.forEach(async (element: any) => {
                        await dispatch(accountData(userCredentials, setSearch_fields,
                            setListContraAccount, 1, [{
                                "field_name": "contra_account_group_id",
                                "search_type": "like",
                                "search_value": element.trim()
                            }], 1000, "search"))
                    });
                }
            }
            setChosenAction({ ...objAction })
            console.log("chosenAccount", chosenAccount);

        }
        else {
            objAccount["id"] = chosenAccount?.id
            objAccount["idEntity"] = value?.id_entity
            objAccount["idAccount"] = value?.id_account
            setChosenAccount({ ...objAccount })
            console.log("chosen++++++++++++Account", chosenAccount);

        }
    }

    const opennoteDialog = (item: any) => {
        setNote({ cheque: item, note: item.note })
        toggleHideDialog()
    }
    const changeNote = (key: any, value: any) => {
        key && setNote({ ...note, note: value })
    }

    const chequeDetailsFunc = (cheqe: any) => {
        setChequeDetailsDialog(true)
        setCheckDeatils(cheqe)
    }
    const displayTransaction = (index: any) => {
        const data = rowData?.search_results[index]
        if (data?.id_document) {
            dispatch(readCopycompletedDoc(data.document_type_id, userCredentials,
                data?.id_entity, data?.id_document))
        }
        else {
            setMessageError(t("documentCannotDisplayed"))
            setServerMessageError(true)
        }
        setShowDialogTransaction(true)
    }

    return (
        <>
            {serverMessageError && <DialogMessages setansDelete={setServerMessageError}
                setshowModalDelete={setServerMessageError} subText={"לא נמצאו שקים עם תאריך פרעון עתידי"} title={dialogTitle} dialogType={"dialogMessage"} />}
            {serverMessageErrorServer && <DialogMessages setansDelete={setServerMessageErrorServer}
                setshowModalDelete={setServerMessageErrorServer} subText={"אירעה שגיאה, לא ניתן להשלים את הפעולה"} title={dialogTitle} dialogType={"dialogMessage"} />}
            {showDialogTransaction && < DialogCompleteTransactions setShowDialog={setShowDialogTransaction} />}
            <Dialog hidden={hideDialog} modalProps={modelProps} onDismiss={() => { toggleHideDialog(); }}>
                <p className="title-text" style={{ fontSize: "25px", fontWeight: "bold" }}>{t('note')}</p>
                <hr className="hr"></hr>
                <TextFeildNote label={t('')} value={note?.note} onChange={changeNote} id={'note'} />
                <DialogFooter>
                    <DefaultButton onClick={toggleHideDialog} text={t("cancel")} />
                    <PrimaryButton onClick={saveNote} text={t("save")} />
                </DialogFooter>
            </Dialog>
            {openChecquesListDialog &&
                <ChequesListDialog setShowDialog={setOpenChecquesListDialog}
                    confirmChequeEvent={confirmChequeEvent} chequeEventName={chosenAction?.action}></ChequesListDialog>
            }
            {chequeHistoryDialog &&
                <ChequeHistoryDialog setShowDialog={setChequeHistoryDialog}
                    chequeItem={chequeItem} ></ChequeHistoryDialog>
            }
            {chequeDetailsDialog &&
                <CheckDetailsDialogComplete sourcePage="discounts" setConvertions={setCheckDeatils}
                    convertions={checkDeatils} setShowDialog={setChequeDetailsDialog} userCredentials={userCredentials} />}
            <p className={`div-select-action-${dir}`}>{t("selectActionToPerformCheque")}</p>
            <div style={{ display: "flex" }}>
                <div className={`${chosenAction?.is_contra && `select-action-ml-${dir}`}`} >
                    <Dropdown
                        label=''
                        placeholder={t("selectAction")}
                        id={'action'}
                        style={{ width: '248px', backgroundColor: 'rgb(250, 249, 248)' }}
                        options={actionsList?.chequesManagement}
                        onChange={(id: any, val: any) => (chooseAction(id, val, 'action'))}
                    />
                </div>
                {chosenAction?.is_contra && displayListContraAccount?.length > 0 ? <div>
                    <Dropdown
                        placeholder={chosenAction?.place}
                        id={'contra_id'}
                        className={`select-status-${dir}`}
                        options={displayListContraAccount}
                        onChange={(id: any, val: any) =>
                            (chooseAction("", val, ''))}
                    />
                </div> : ""}
                {chosenAction?.id === 31 &&
                    <div style={{ display: "grid" }}>
                        <label style={{ fontSize: "14px", marginTop: "-21px" }}>{t("amount")}</label>
                        <CurrencyInput
                            className={`currency-input`}
                            style={{ marginTop: "-6px" }}
                            id="accepted_amount"
                            placeholder="0.00"
                            value={chosenAction?.accepted_amount}
                            decimalsLimit={2}
                            onValueChange={(value: any) => {
                                setShowError(0);
                                setChosenAction({ ...chosenAction, accepted_amount: value })
                            }}
                        />
                    </div>
                }
                {chosenAction?.id === 98 && <Dropdown
                    placeholder={t("selectStatus")}
                    id={'status'}
                    className={`select-status-${dir}`}
                    options={actionsList?.status}
                    onChange={(id: any, val: any) =>
                        (chooseAction("status", val, 'action'))}
                />}
                <PrimaryButton className="button" style={{ marginTop: "0" }}
                    onClick={() => { actionForCheque("confirm_update_cheque") }} text={t("performAction")} />
            </div>
            <p style={{ color: 'red' }}>{(showError === 1 && !chosenAction.action) ? t("selectActionToPerform") : (showError === 5 && checkCheqes.length < 1) ?
                t("selectChequeToPerformAction") : showError === 3 ? t("enterAmount") : showError === 4 ? t("enterContraAccount") : showError === 6 && t("selectBankToPerformAction")}
            </p>
            <div style={{ width: "100%" }}>
                {/* {
                    rowData?.search_results &&  */}
                <div style={{
                    display: "flex", marginTop: "24px",
                    justifyContent: "space-between", width: '98%'
                }}>
                    <div style={{ display: "flex" }} >
                        <PrimaryButton onClick={() => { sendFilterConditions() }} text={t("filter")} />
                        <PrimaryButton className={`margin-between-input-${dir}`} onClick={() => {
                            setSearch_fields([])
                            setDateFilter({ cheque_date: "", cheque_date2: "" })
                            initialDataAllFilter.status_id = ["equal", ""]
                            setDataAllFilter(initialDataAllFilter)
                            displayLoading()
                            dispatch(managementCheckData(userCredentials, setSearch_fields, setRowData, curentPage,
                                [], numRecourd, setServerMessageError, setMessageError, setDialogTitle))
                        }} text={t("cancelFilter")} />
                    </div>
                    {rowData?.search_results?.length > 0 && <div>
                        <Dropdown
                            placeholder={t("NumberOfRowsToDisplay")}
                            id={''}
                            style={{ width: '170px' }}
                            options={numRecourdList}
                            onChange={(id: any, val: any) => (chooseNumRecord(id, val))}
                        />
                    </div>}
                </div>
                {/* } */}

                <div style={{ width: '98%', overflowX: 'auto', overflowY: "auto" }}>
                    {numResults !== (-1) ? numResults > 0 ? <p style={{ marginBottom: '0' }}>{t('found')} {numResults} {t('results')}</p> : <p style={{ marginBottom: '0' }}>{t('searchCustomerNotFound')}</p> :
                        <p style={{ marginBottom: '0' }}>{t('found')} {rowData?.records_found} {t('results')}</p>}

                    {rowData?.search_results && <table style={{ border: '2px solid #e1e1e1', overflow: "auto" }}>
                        <tbody>
                            <tr>
                                <th className={`th-agtable-${dir}`} style={{ minWidth: '45px' }}>
                                    <div className={`padding-input-${dir}`} >
                                        <Checkbox checked={allChecked} onChange={() => {
                                            addCheckCheqe("all")
                                        }} /></div>
                                </th>
                                {arrTitle?.map((item: any, key: any) => (
                                    <th key={key} className={`th-agtable-${dir}`} style={{ width: arrWidth[key] }}>
                                        <div className='wrapper' style={{ alignItems: "center", textAlign: "start" }}>
                                            <p style={{ margin: "0px" }}>{t(item.key)}</p>
                                            <div className={`cr-dr-span-${dir}`}>
                                                {item.sortValue ?
                                                    <i onClick={() => { sortCol(item, key) }} title={t("filter")} className={`icon-sort-t-${dir} fal fa-arrow-up`}></i> :
                                                    <i onClick={() => { sortCol(item, key) }} title={t("filter")} className={`icon-sort-t-${dir} fal fa-arrow-down`} ></i>}
                                                <i className={`icon-sort-t-${dir} ${dataAllfilter[item.eng][1] ? "fas" : "fal"} fa-filter`} id={item.eng} title={t("filter")}
                                                    onClick={() => { filterCol(item.key) }}></i>
                                            </div>
                                        </div>
                                        {filter.key === item.key && filter.value === true && <>
                                            <Callout
                                                coverTarget
                                                className={item.eng === 'cheque_date' ? styles.calloutDate : styles.callout}
                                                onDismiss={() => { filterCol(item.key) }}
                                                target={`#${item.eng}`}
                                                isBeakVisible={false}
                                                setInitialFocus >
                                                <FontAwesomeIcon icon={faTimes} title={t("close")} onClick={() => { filterCol(item.key) }}
                                                    style={{ height: '12px', marginTop: '2%', marginBottom: '2%' }} className={`iconRefresh-${dir}`} />
                                                <div key={key + 1} >
                                                    {item.eng === 'cheque_date' ?
                                                        <>
                                                            <label>{t("from_date")}</label>
                                                            <div className='doc'><CustomerDate isDefaultDate={false} isValidDate={isValidDateCreate}
                                                                setIsValidDate={setIsValidDateCreate} entity={dateFilter} setEntity={setDateFilter}
                                                                readOnly={false} idDate="cheque_date" label={""} source={""} /></div>
                                                            <div className='table-spaces'>
                                                                <label>{t("until_date")}</label>
                                                            </div>
                                                            <div className='doc'><CustomerDate style isDefaultDate={false} isValidDate={isValidDateCreate}
                                                                setIsValidDate={setIsValidDateCreate} entity={dateFilter} setEntity={setDateFilter}
                                                                readOnly={false} idDate="cheque_date2" label={""} source={""} /></div>
                                                        </>
                                                        : item.default !== "like" &&
                                                        <div></div>
                                                        // < Dropdown
                                                        //     placeholder={t("selectCondition")}
                                                        //     selectedKey={dataAllfilter[item.eng][0]}
                                                        //     id={item}
                                                        //     style={{ marginBottom: '2%' }}
                                                        //     onChange={(id: any, val: any, e: any) => (enterFilter(item, val.key, "operator"))}
                                                        //     options={item.eng === 'entity_name' || item.eng === 'account_holder_name' || item.eng === 'reference_number' ||
                                                        //         item.eng === 'beneficiary_name' ? operatorStringList : item.eng.includes('amount') && operatorAmountList}
                                                        // />
                                                    }
                                                    {item.eng === 'status_id' ?
                                                        <Dropdown
                                                            placeholder={t("selectStatus")}
                                                            id={'status'}
                                                            className={`select-status-${dir}`}
                                                            options={actionsList?.status}
                                                            selectedKey={dataAllfilter[item.eng][1]}
                                                            onChange={(id: any, val: any, e: any) => { enterFilter(item, val.key, "value") }}
                                                        />

                                                        : item.eng !== 'cheque_date' &&
                                                        <div className='table-spaces'>
                                                            <CustomTextField label={""}
                                                                onKeyDown={(e: any) => e.key === "Enter" && sendFilterConditions()}
                                                                placeholder={item.eng === "bank_branch_code" ? t("enterBranchNumber") :
                                                                    item.eng === "bank_code" ? t("enterBankNumber") : `${t("enter")} ${t(item?.key)}`}
                                                                value={search_fields ? dataAllfilter[item.eng][1] : `${t("enter")}${t(item?.key)}`}
                                                                readOnly={false} onChange={(id: any, val: any) => (enterFilter(id, val, "value"))} id={item} />
                                                        </div>}   </div>
                                            </Callout>
                                        </>}
                                    </th>
                                ))}
                                <th className={`th-agtable-${dir}`} style={{ height: "46px", minWidth: '110px' }}>
                                    <p style={{ margin: "0px" }}>{t("chequeLocation")}</p>
                                </th>
                                <th className={`th-agtable-${dir}`} style={{ height: "46px", minWidth: '47px' }}>
                                    <p style={{ margin: "0px" }}>{t('note')} </p>
                                </th>
                                <th className={`th-agtable-${dir} `}
                                    style={{ height: "46px", minWidth: '47px', paddingLeft: "8px" }}>
                                    <p style={{ margin: "0px" }}>{t('history')} </p>
                                </th>
                            </tr>

                            {rowData?.search_results.map((item: any, key: any) => (
                                <tr key={key} className="hoverRow" style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }}>
                                    <td className={`td-agtable-${dir} padding-input-${dir}`}>
                                        <Checkbox checked={checkedObj[item?.id_cheque] ? true : false} onChange={() => {
                                            addCheckCheqe(item)
                                        }} />
                                    </td>
                                    <td className={`td-agtable-title-${dir}`}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { displayTransaction(key) }}
                                    >{item?.document_number}</td>
                                    <td className={`td-agtable-title-${dir}`}>{item?.ID_number}</td>
                                    <td className={`td-agtable-title-${dir}`}>{item?.entity_name}</td>
                                    <td className={`td-agtable-title-${dir}`}>{item?.account_holder_name}</td>
                                    <td className={`td-agtable-title-${dir}`}>{item?.beneficiary_name}</td>
                                    <td className={`td-agtable-title-${dir}`}>{item?.bank?.bank_account_number}</td>
                                    <td className={`td-agtable-title-${dir}`} style={{ cursor: "pointer" }} onClick={() => { chequeDetailsFunc(item) }} title={t("chequeDetails")}>{item?.reference_number}</td>
                                    <td className={`td-agtable-title-${dir}`}>{item?.amount.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td className={`td-agtable-title-${dir}`} style={{ textAlign: "center" }}>{item?.currency_code}</td>
                                    <td className={`td-agtable-title-${dir}`}>{moment(item?.cheque_date).format("DD/MM/YYYY")}</td>
                                    <td className={`td-agtable-title-${dir}`}>{item?.status?.status_name}</td>
                                    <td className={`td-agtable-title-${dir}`}>{item?.contra_account?.account_name}</td>
                                    <td className={`td-agtable-title-${dir}`} style={{ cursor: "pointer", textAlign: "center" }}>
                                        {item.note ? <i title={t("note")} className="fad fa-sticky-note" onClick={() => { opennoteDialog(item) }} />
                                            : <i title={t("note")} className="fal fa-sticky-note" onClick={() => { opennoteDialog(item) }} />}
                                    </td>
                                    <td className={`td-agtable-title-${dir}`} style={{ cursor: "pointer", textAlign: "center" }}>
                                        <i title={t("history")} className="fal fa-history" onClick={() => { setChequeHistoryDialog(true); setChequeItem(item) }}></i>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                </div>
            </div>
            {
                (rowData?.search_results && !loading) &&
                <div className="paginationContainer" style={{ maxWidth: '98%', display: 'flex', justifyContent: "center", margin: "20px 0px", direction: "ltr" }}>
                    <div className="searchWp__paginationContainer__pagination">
                        <Pagination
                            currentPage={curentPage}
                            totalPages={Math.ceil(rowData?.records_found / numRecourd)}
                            onChange={(page) => _onPageUpdate(page)}
                            limiter={3}
                        />
                    </div>
                </div>
            }
        </>
    )
}

const theme = getTheme();
const styles = mergeStyleSets({
    callout: {
        width: 200,
        padding: '9px',
        background: theme.semanticColors.bodyBackground,
    }, calloutDate: {
        width: 265,
        padding: '9px',
        background: theme.semanticColors.bodyBackground,
        bottom: "-452.396px !important"
    },
});

