import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import { useReactToPrint } from 'react-to-print';

import Subtitle from '../../shared/components/Subtitle';
import { useTranslation } from "react-i18next";
import { IUserCredentials } from '../customerDetails/CustomerDetails';
import { receiptTransaction } from '../../store/actions/accountAction'
import { PrintHtml } from '../../shared/components/CustomHooks/PrintHtml';
import EntityInvoiceDetails from '../invoice/EntityAccountDetails';
import { deleteTransaction } from '../../store/actions/convertionActions';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import { RESET_CUSTOMER_OBJECT, RESET_DATA_INVOICE } from '../../store/actions/types';
import { useHistory, useLocation } from 'react-router-dom';
import { checkPermissionLevelOfPage, checkRequiredFields, customerInfoInObject } from '../../shared/components/CustomHooks/GlobalFunctions';
import { CustomPrompt } from '../../shared/components/CustomHooks/CustomPrompt';
import ReceiptTable from './ReceiptTable';
import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils';

const initialReceipt = {
    id_entity: "", transaction_document: { document_request_method: null },
    transaction_type_id: 1, total_amount_ils: 0, id_transaction: null, id_account: "",
    financial_assets_in: [{
        currency_code: "", financial_asset_code: 0,
        financial_asset_details: null, amount: 0, exchange_rate_ils: 1, amount_ils: 0,
        contra_id_account: null, row_number: 1
    }]
}

export default function Receipt() {

    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const dataReceipt = useSelector(({ accountReducer }: any) => accountReducer?.dataAccount)
    const saveDraft = useSelector(({ conversionReducer }: any) => conversionReducer.saveDraftChanges)
    const [selectedDraft, setSelectedDraft] = useState("")
    const [emptyContraAccount, setEmptyContraAccount] = useState(false)
    const [beforeDelete, setbeforeDelete] = useState(false)
    const [emptyRequiredFields, setEmptyRequiredFields] = useState(false)
    const [titleMessageError, setTitleMessageError] = useState("")
    const [messageError, setMessageError] = useState("")
    const [serverMessageError, setServerMessageError] = useState(false)
    const [showSpiner, setShowSpiner] = useState(false)
    const [ifChanges, setIfChanges] = useState(false)
    const contraAccounts = useSelector(({ authReducer }: any) => authReducer?.data?.user.contra_accounts)
    const [contraCashAccounts, setcontraCashAccounts] = useState<any>([])
    const [receiptCustomer, setReceiptCustomer] = useState<any>()
    const [selectConversionList, setSelectConversionList] = useState<any>([])
    const [readOnly, setReadOnly] = useState<any>(true)
    const [emptyInAmount, setEmptyInAmount] = useState(false)
    const { state } = useLocation<any>()
    let componentRef = useRef(null);
    const history = useHistory<any>()
    const [ifDelete, setifDelete] = useState(false)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [action, setAction] = useState<any>(initialReceipt)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()

    const spinnerStyles: Partial<ISpinnerStyles> = {
        root: {
            position: 'fixed',
            zIndex: '999999',
            fontWeight: 'bold',
        },
        circle: {
            color: '#FFB400 ',
            height: '100px',
            width: '100px',
        },
    }
    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])

    useEffect(() => {
        if (dataReceipt?.status_id === "deleted" || (dataReceipt?.status_id === "completed")) {
            resetPageData()
        }
    }, [dataReceipt?.status_id])

    useEffect(() => {
        if (contraAccounts) {
            let arrCash: any[] = [], arrCheck: any[] = [], arrCredit: any[] = [],
                arrBank: any[] = [];;
            for (let i = 0; i < contraAccounts.length; i++) {
                if (contraAccounts[i].contra_account_group_id === "cash") {
                    arrCash.push({ key: contraAccounts[i].id_account, text: contraAccounts[i].account_name + ' ' + contraAccounts[i].account_number, groupId: contraAccounts[i].contra_account_group_id, accountNumber: contraAccounts[i].account_number, is_default: contraAccounts[i].is_default ? true : false })
                }
            }
            setcontraCashAccounts(arrCash)
        }
    }, [contraAccounts])

    useEffect(() => {
        if (dataReceipt?.transaction_document?.document_html && selectedDraft) {
            document?.getElementById("print")?.click();
            let copydataReceipt = { ...dataReceipt }
            let printDocument: any = copydataReceipt["transaction_document"]
            printDocument = { document_request_method: null }
            setAction({ ...action, "transaction_document": printDocument })
        }
    }, [dataReceipt?.transaction_document])

    useEffect(() => {
        if (dataReceipt) {
            const { id_transaction } = dataReceipt
            if (action.id_transaction === null && dataReceipt.transaction_request_method === "create") {
                setSelectedDraft(id_transaction + ',' + action?.id_entity)
                setAction({ ...action, "id_transaction": id_transaction })
            }
            if (selectedDraft !== "" || state === "showCompleted") {
                setDraftReceiptData()
            }
        }
    }, [dataReceipt])

    useEffect(() => {
        if (dataReceipt && state?.source === "refresh") {
            history.location.state = {}
            setSelectedDraft(dataReceipt?.id_transaction + ',' + dataReceipt?.id_entity)
            setDraftReceiptData()
        }
    }, [dataReceipt, state])

    const setDraftItems = () => {
        let items: any = []
        if (dataReceipt?.status_id !== "deleted") {
            let copyAction: any = { ...action }
            dataReceipt["financial_assets_in"]?.length &&
                dataReceipt["financial_assets_in"].forEach((item: any) => {
                    items.push({
                        currency_code: item?.currency_code,
                        financial_asset_code: item?.financial_asset_code,
                        financial_asset_details: item?.financial_asset_details,
                        amount: item?.amount,
                        exchange_rate_ils: item?.exchange_rate_ils,
                        amount_ils: item?.amount_ils,
                        contra_id_account: item?.contra_id_account,
                        row_number: item?.row_number,
                    })
                })
            copyAction.financial_assets_in = items
            copyAction.id_transaction = dataReceipt?.id_transaction
            copyAction.id_entity = dataReceipt?.id_entity
            copyAction.id_account = dataReceipt?.id_account
            copyAction.total_amount_ils = dataReceipt?.total_amount_ils
            setAction(copyAction)
        }
    }

    const setDraftReceiptData = () => {
        let customerInfo = dataReceipt?.entity && customerInfoInObject(dataReceipt?.entity)
        setReceiptCustomer(customerInfo)
        setDraftItems()
    }

    useEffect(() => {
        async function deleteFunc() {
            if (ifDelete && dataReceipt?.status_id === "draft") {
                await dispatch(deleteTransaction("delete", "receipt", action, userCredentials, action.id_entity, setSelectConversionList))
                // , setShowDialogSave, "", setshowSpiner
                setifDelete(false)
                dispatch({
                    type: RESET_CUSTOMER_OBJECT,
                })
            }
        }
        deleteFunc()
    }, [ifDelete])

    const deleteDeal = async () => {
        if (dataReceipt?.status_id === "draft") {
            setbeforeDelete(true)
            setServerMessageError(true)
            setMessageError(t("cancelproductionReceipt"))
            setTitleMessageError(t("cancel"))
        }
        else if (dataReceipt?.status_id === "completed") {
            await dispatch(deleteTransaction("cancel", "receipt", action, userCredentials, action.id_entity, setSelectConversionList))
            // , setShowDialogSave, "", setshowSpiner
        }
    }
    const resetPageData = async () => {
        let reset: any
        await dispatch({
            type: RESET_DATA_INVOICE,
            payload: null
        })
        setReceiptCustomer(reset)
        setEmptyContraAccount(false)
        setIfChanges(false)
        await setSelectedDraft("")
        setReadOnly(true)
        setEmptyInAmount(false)
        setEmptyRequiredFields(false)
        setAction(initialReceipt)

    }

    const finishDeal = async () => {
        let emptyIn = false, requiredFields, emptyContraAccount = false
        let copyAction = { ...action }
        await action.financial_assets_in.forEach(async (element: any) => {
            if (!element?.amount) {
                setEmptyInAmount(true)
                emptyIn = true
            }
            if (!element.contra_id_account) {

                if (element.financial_asset_code === "1") {
                    let defaultcontraCash = contraCashAccounts.find((item: any) => item.is_default === true)
                    let index = copyAction?.financial_assets_in?.findIndex((item: any) => Number(item.row_number) === Number(element?.row_number));
                    if (index !== -1 && defaultcontraCash) {
                        copyAction.financial_assets_in[index].contra_id_account = defaultcontraCash.key
                    }
                    else {
                        setEmptyContraAccount(true)
                        emptyContraAccount = true
                    }
                }
            }
            requiredFields = await checkRequiredFields(element.financial_asset_code, element)
            requiredFields && setEmptyRequiredFields(true)

        });
        if (emptyIn || requiredFields || emptyContraAccount) {
            setServerMessageError(true)
            setTitleMessageError(t("note"))
            setMessageError(t("mandatoryFieldsMassege"))

        }
        else {
            await dispatch(receiptTransaction("complete", "receipt", receiptCustomer.entityName, copyAction, userCredentials,
                receiptCustomer.idEntity, t, setShowSpiner, setServerMessageError, setMessageError, setTitleMessageError, setSelectConversionList))
            await dispatch({
                type: RESET_CUSTOMER_OBJECT,
            })
        }
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div className={`${checkPermissionLevelOfPage(credentials, location) === "RW"
            ? 'page-read' : 'page-read-only'}`}>

            <p id='print' className="button" onClick={handlePrint} />
            <div style={{ display: "none" }}>
                <div ref={componentRef} dangerouslySetInnerHTML={{ __html: dataReceipt?.transaction_document?.document_html }} />
            </div>
            {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
            {saveDraft?.changeLocation !== undefined && <CustomPrompt sumIn={action?.total_amount_ils} typeAction="receipt" entityName={receiptCustomer?.entityName} action={action} ifChanges={ifChanges} saveDraft={saveDraft} subText={t('saveChanges')} userCredentials={userCredentials} resetFunc={resetPageData} />}
            {serverMessageError && <DialogMessages setansDelete={setServerMessageError} setifDelete={beforeDelete === true ? setifDelete : undefined} subText={messageError} title={titleMessageError}
                setshowModalDelete={setServerMessageError} dialogType={"dialogMessage"} />}
            <div>
                {/* <div className={`sub-header-${dir} fixed-${dir}`} style={{ height: "60px" }}>
                    <Title
                        title={t("receipt")} />
                    <div className="divider"></div><div className="divider"></div>
                </div> */}
                <HeaderUtils title={t("bookkeeping")} subTitle={"false"} disabledSave={true} createBtn={false}
                    saveFunc={""} editFunc={false} cancelFunc={""} />
                <br></br><br></br>
                <Subtitle title={t('receipt')} />
                <div className={`content-div-${dir}`}  style={{ height: "100vh" }}  >
                    <div className="content-search" style={{marginTop:"2%"}}   >
                        <EntityInvoiceDetails setSelectedDraft={setSelectedDraft} selectedDraft={selectedDraft}
                            typeAction="receipt" ifChanges={ifChanges} setIfChanges={setIfChanges} actions={action}
                            setActions={setAction} readOnly={readOnly} setReadOnly={setReadOnly} setSelectConversionList={setSelectConversionList}
                            selectConversionList={selectConversionList} setSearchCustomer={setReceiptCustomer} searchCustomer={receiptCustomer} />

                    </div>
                    <ReceiptTable setAction={setAction} action={action} userCredentials={userCredentials} ifChanges={ifChanges} emptyRequiredFields={emptyRequiredFields}
                        emptyContraAccount={emptyContraAccount} setIfChanges={setIfChanges} readOnly={readOnly} searchCustomer={receiptCustomer} emptyInAmount={emptyInAmount} />
                    <div >
                        <div style={{ width: "650px", marginTop: '4%' }}>
                            {selectedDraft && dataReceipt?.status_id === "draft" &&
                                <div style={{ width: "650px", display: "inline-grid", marginTop: "20px", justifyContent: "end", }}>
                                    <PrintHtml saveFunction={finishDeal} typeAction="receipt" conversionCustomer={receiptCustomer} action={action} setAction={setAction} userCredentials={userCredentials} />
                                    <PrimaryButton className={`bottun-${dir}`} onClick={() => { deleteDeal(); }} style={{ width: "233px", marginTop: "4px", backgroundColor: "red", borderColor: "red" }} allowDisabledFocus disabled={false} checked={false} name="delete" text={t("cancel")} />
                                </div>}
                            <div ></div>
                        </div>
                    </div>
                </div>

            </div >
        </div >
    )
}
