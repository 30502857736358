import { useState,useEffect } from 'react';
import '../../scssPages/sidebar.scss'
import ContentSideClient from './ContentSideClient';
import { SideMainClient } from './SideMainClient';
import ContentSideSettingsC from './ContentSideSettingsC'
import SideSubClient from './SideSubClient'
import SideSubSettingsC from './SideSubSettingsC'
import { useSelector } from 'react-redux';
const SidebarClient = () => {
    const [category, setcategory] = useState('')
    const [dir,setDir]=useState("")
    const lang = useSelector(({ dataReducer }: any) => dataReducer?.language)
    
    useEffect(()=>{
        if(lang){
            setDir(lang)
        }
        },[lang])
        
    return (
        <>
            <div className={`sidebar-${dir}`}>
                <div style={{ backgroundColor: "#1A7128", width: "65px" }}>
                    <SideMainClient setcategory={setcategory} /></div>
                {category === "customer" &&
                    <div style={{ minWidth: "150px", maxWidth: "150px" }}>
                        <ContentSideClient /></div>}
                {category === "setting" &&
                    <div style={{ minWidth: "150px", maxWidth: "150px" }}>
                        < ContentSideSettingsC /></div>
                }
                {/* {category === "convert" &&
                    <div style={{ minWidth: "150px", maxWidth: "150px" }}>
                        < ContentSideConvert /></div>
                } */}


            </div>
            {category === "customer" &&
                <SideSubClient />}
            {category === "settings" &&
                <SideSubSettingsC />}
            {/* {category === "convert" &&
                <SideConvert />} */}
            

        </>
    );
}
export default SidebarClient;