import React, { useState, useEffect } from "react";
import { Nav } from "@fluentui/react/lib/Nav";
import "../../scssPages/sidebar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { INavStyles, INavLinkGroup, INavLink } from "@fluentui/react/lib/Nav";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { useLocation, withRouter } from "react-router-dom";
import "../../scssPages/sidebar.scss";
import { useTranslation } from "react-i18next";
import { registerIcons } from "@fluentui/react";
import { globalPages } from "../../shared/components/CustomHooks/GlobalSidePages";

// import {
//   faSearch, faUser, faUserFriends, faIdCardAlt, faUserSecret, faIdCard, faUserTag,
//   faChalkboardTeacher, faFile, faUserShield, faInfo, faWarehouse
// } from '@fortawesome/free-solid-svg-icons';

const navStyles: Partial<INavStyles> = { root: { width: 150 } };
initializeIcons();

registerIcons({
  icons: {
    Filter: <FontAwesomeIcon icon="filter" />,
    SearchIcon: <i className="fal fa-search"></i>,
    UserIcon: <i className="fal fa-user"></i>,
    CardIcon: <i className="fal fa-id-card-alt"></i>,
    UserFriendsIcon: <i className="fal fa-user-friends"></i>,
    UserTagIcon: <i className="fal fa-user-tag"></i>,
    IdCardIcon: <i className="fal fa-id-card"></i>,
    ChalkboardTeacherIcon: <i className="fal fa-chalkboard-teacher"></i>,
    infoIcon: <i className="fal fa-info-square"></i>,
    FileUserIcon: <i className="fal fa-file-user"></i>,
    UserSecretIcon: <i className="fal fa-user-secret"></i>,
    UserShieldIcon: <i className="fal fa-user-shield"></i>,
  },
});

const Sidebar = (props: any) => {
  const [t, i18n] = useTranslation();
  const [selectedKey, setSelectedKey] = useState("");
  const { dataCustomer } = useSelector(
    ({ customerReducer }: any) => customerReducer
  );
  const location = useLocation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);

  // const [showPermissionNote, setShowPermissionNote] = useState(false)
  // const [msgPermission, setMsgPermission] = useState('')
  // const credentials = useSelector(({ authReducer }: any) => authReducer.data.user.credentials)
  const arrCredentials = useSelector(
    ({ authReducer }: any) => authReducer?.data?.credentials
  );
  const credentialsPagesDetails: any = globalPages;

  useEffect(() => {
    const pathname = location.pathname.split("/");
    const key = pathname.length > 2 ? pathname.pop() : null;

    setSelectedKey(key || "customer_search");
  }, []);

  const handleNavClick = (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    ev?.preventDefault();
    let path: any = item?.url.split("/")[1];
    // if (credentials[path] && credentials[path] === "Deny" || !credentials[path]) {
    //   setMsgPermission(t('permissionAaccessThisPage'))
    //   setShowPermissionNote(true)
    // }
    // else {
    props.history.push(`/customers${item?.url}`);
    // }
    setSelectedKey(item?.key || "customer_search");
  };

  const navStyles: Partial<INavStyles> = {
    root: {
      i: { width: "25px", marginRight: "3px" },
    },
    link: {
      ":after": dir === "ltr" && {
        borderLeft: "2px solid transparent !important",
        borderRight: "2px solid rgb(0, 120, 212) !important",
      },
    },
  };

  const CustomerLink: INavLinkGroup[] = [
    {
      links: [
        {
          key: "customer-search",
          iconProps: { iconName: "SearchIcon" },
          styles: {
            icon: { color: "#1A1F71" },
          },
          name: t("customerSearch"),
          url: "/customer_search",
          onClick: handleNavClick,
        },
      ],
    },
  ];
  // const navLinkGroups: INavLinkGroup[] = [
  //   {
  //     links: [
  //       {
  //         key: "customer-search",
  //         iconProps: { iconName: "SearchIcon" },
  //         styles: {
  //           icon: { color: '#1A1F71' }
  //         },
  //         name: t("customerSearch"),
  //         url: "/customer_search",
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "customer-details",
  //         iconProps: { iconName: "UserIcon" },
  //         styles: {
  //           icon: { color: '#1A1F71', fontWeight: "light" }
  //         },
  //         url: "/customer_details",
  //         name: t("customerDetails"),

  //         component: "CustomerDetails",
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "related-factors",
  //         iconProps: { iconName: "UserFriendsIcon" },
  //         styles: {
  //           icon: { color: '#1A1F71' },
  //         },
  //         url: "/customer_related_entities",
  //         name: t("RelatedFactors"),

  //         component: "CustomerDetails",
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "bank-accounts",
  //         iconProps: { iconName: 'CardIcon' },
  //         url: "/customer_bank_accounts",
  //         component: "BankAccounts",
  //         name: t("bankAccounts"),
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "money-laundering",
  //         iconProps: { iconName: "UserShieldIcon" },
  //         url: "/customer_kyc_questionnaire",
  //         component: "MoneyLaundering",
  //         name: t("money_laundering"),
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "service-receiver-statement",
  //         iconProps: { iconName: "UserSecretIcon" },
  //         url: "/service_receiver_statement",
  //         component: "GetService",
  //         name: t("service_requester_statement"),
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "contact",
  //         iconProps: { iconName: "IdCardIcon" },
  //         url: "/customer_contacts",
  //         component: "Contact",
  //         name: t("contactInformation"),
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "documents",
  //         iconProps: { iconName: "FileUserIcon" },
  //         url: "/customer_documentation",
  //         component: "Documents",
  //         name: t("documents_b"),
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "customer-accounts",
  //         url: "/customer_customer_accounts",
  //         component: "CustomerAccounts",
  //         iconProps: { iconName: "ChalkboardTeacherIcon" },
  //         name: t("customer_account"),
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "obligo",
  //         iconProps: { iconName: "infoIcon" },
  //         url: "/customer_obligation_collateral",
  //         component: "Obligo",
  //         name: t("obligo"),
  //         onClick: handleNavClick
  //       },
  //       {
  //         key: "customer-plan",
  //         iconProps: { iconName: "UserTagIcon" },
  //         styles: {
  //           icon: { color: '#1A1F71' }
  //         },
  //         url: "/customer_price_program",
  //         name: t("customerPlan"),

  //         component: "CustomerPlan",
  //         onClick: handleNavClick
  //       },
  //     ]
  //   }
  // ];
  const navLinkGroups: INavLinkGroup[] = [{ links: [] }];
  arrCredentials?.map(
    (module: any) =>
      module?.module_code === "customers" &&
      module?.pages?.map((page: any) =>
        navLinkGroups[0]?.links.push({
          className: credentialsPagesDetails[page?.page_code]?.className,
          key: credentialsPagesDetails[page?.page_code]?.key,
          iconProps: credentialsPagesDetails[page?.page_code]?.iconProps,
          name: t(credentialsPagesDetails[page?.page_code]?.name),
          styles: credentialsPagesDetails[page?.page_code]?.styles,

          url: credentialsPagesDetails[page?.page_code]?.url,
          onClick: handleNavClick,
        })
      )
  );

  return (
    <>
      <Nav
        className="items"
        onLinkClick={handleNavClick}
        styles={navStyles}
        groups={dataCustomer ? navLinkGroups : CustomerLink}
        selectedKey={selectedKey}
      />
      {/* {showPermissionNote && <DialogMessages setansDelete={setShowPermissionNote} setshowModalDelete={setShowPermissionNote} subText={msgPermission} dialogType={"dialogMessage"} />} */}
    </>
  );
};
export default withRouter(Sidebar);
