import axios from "axios";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import { createCustomer, readCustomerId } from "./customerActioin"
import { READ_CUSTOMER } from '../actions/types';
import { basicUrl } from "../../shared/config";

let currUserCredentials: IUserCredentials | null = null

export const createBankAccount = (bankAccount: any, userCredentials: IUserCredentials | null, idEntity: string, setShowDialogSave: any, setShowDialogFailed: any, setshowSpiner?: any, setidBankAccount?: any, setnewBankAccount?: any) => async (dispatch: Function) => {
    // (bankAccount: any, userCredentials: IUserCredentials | null, idEntity: string,setServerMessageError?: any, setMessageError:any, setshowSpiner?: any, setidBankAccount?: any, setnewBankAccount?: any)
    currUserCredentials = userCredentials
    const createBankAccount =
    {
        "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
        "id_client": currUserCredentials && currUserCredentials["idClient"],
        "id_branch": currUserCredentials && currUserCredentials["idBranch"],
        "id_entity": idEntity,
        "return_entity": 1,
        "attribute_request_method": "create",
        "attribute": "bank_account",
        "entity_type_id": "customer",
        "country_code": bankAccount.country,
        "bank_name": bankAccount.bankName,
        "bank_branch_name": bankAccount.branchName,
        "bank_code": bankAccount.bank,
        "bank_details": bankAccount?.note,
        "bank_branch_code": bankAccount.branch,
        "routing_number": bankAccount.routingNumber,
        "bank_branch_address": bankAccount.branchAddress,
        "bank_account_number": bankAccount.accountNumber,
        "IBAN": bankAccount?.IBAN
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(createBankAccount);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    axios.post(basicUrl + '/entity', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        if (setidBankAccount === undefined)
                            dispatch({
                                type: READ_CUSTOMER,
                                payload: res.data.data
                            })
                        setidBankAccount && setidBankAccount(res.data.data.bank_accounts[res.data.data.bank_accounts.length - 1].bank_account_id)
                        setnewBankAccount && setnewBankAccount(res.data.data.bank_accounts[res.data.data.bank_accounts.length - 1])
                        setShowDialogSave && setShowDialogSave(true)
                        setshowSpiner && setshowSpiner(false)
                    }
                    else {
                        setshowSpiner(false)
                        setShowDialogFailed(true)
                    }
                }
                else {
                    setshowSpiner(false)
                }
            }
            catch (err) {
                // err.message
                setShowDialogFailed && setShowDialogFailed(true)
                setshowSpiner && setshowSpiner(false)
                console.log('error in create bankAcoount',)
            }
        })
}


export const bankAccountUpdate = (bankAccount: any, userCredentials: IUserCredentials, idEntity: string, setShowDialogSave: any, setShowDialogFailed: any, setshowSpiner?: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const updateBankAccount = {
        "id_initiator": currUserCredentials["idInitiator"],
        "id_client": currUserCredentials["idClient"],
        "id_branch": currUserCredentials["idBranch"],
        "id_entity": idEntity,
        "bank_account_id": bankAccount.key,
        "return_entity": 1,
        "attribute_request_method": "update",
        "attribute": "bank_account",
        "entity_type_id": "customer",
        "country_code": bankAccount.country,
        "bank_name": bankAccount.bankName,
        "bank_branch_name": bankAccount.branchName,
        "bank_code": bankAccount.bank,
        "bank_branch_code": bankAccount.branch,
        "routing_number": bankAccount.routingNumber,
        "bank_branch_address": bankAccount.branchAddress,
        "bank_account_number": bankAccount.accountNumber,
        "IBAN": bankAccount.IBAN
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");

    const body = JSON.stringify(updateBankAccount);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/entity', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        dispatch({
                            type: READ_CUSTOMER,
                            payload: res.data.data
                        })
                        setShowDialogSave(true)
                        setshowSpiner(false)
                    }
                }
                else {
                    setshowSpiner(false)
                }
            }
            catch (err) {
                setShowDialogFailed && setShowDialogFailed(true)
                setshowSpiner(false)
                console.log('error in update colletrall',)
            }
        })
}


export const deleteBankAccount = (userCredentials: IUserCredentials, bankAccountId: any, idEntity: string, setShowDialogSave: any, setshowSpiner: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const deleteBankAccount = {
        "id_initiator": currUserCredentials["idInitiator"],
        "id_client": currUserCredentials["idClient"],
        "id_branch": currUserCredentials["idBranch"],
        "id_entity": idEntity,
        "bank_account_id": bankAccountId,
        "return_entity": 1,
        "attribute_request_method": "delete",
        "attribute": "bank_account",
        "entity_type_id": "customer"
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const body = JSON.stringify(deleteBankAccount);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    axios.post(basicUrl + '/entity', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        const readCustomer = {
                            "entity_request_method": "read",
                            "id_initiator": userCredentials.idInitiator,
                            "id_client": userCredentials.idClient,
                            "id_branch": userCredentials.idBranch,
                            "id_entity": idEntity
                        }
                        let token = sessionStorage.getItem("token")
                        console.log(token,"token-------");
                        const body = JSON.stringify(readCustomer);
                        const config = {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`

                            }
                        };

                        axios.post(basicUrl + '/entity', body, config).then(res => {
                            dispatch(readCustomerId(idEntity, userCredentials, "", "", "", false));
                            setShowDialogSave(true)
                            setshowSpiner(false)
                        })
                    }
                }
                else {
                    setshowSpiner(false)
                }
            }
            catch (err) {
                console.log('error in delete colletrall')
            }
        })
}


export const getBranchesPerBank = (bankCode: any, userCredentials: IUserCredentials | null, setBranchDetails: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const branches = {
        "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
        "id_client": currUserCredentials && currUserCredentials["idClient"],
        "id_branch": currUserCredentials && currUserCredentials["idBranch"],
        "field_name": ["bank_branch_code"],
        "bank_code": bankCode,
        "enum_request_method": "read",
        "user_language": "HE"
    }



    const body = JSON.stringify(branches);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };

    axios.post(basicUrl + '/enum', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        setBranchDetails(res.data.data[0].bank_branch_code)
                    }
                }
            }
            catch (err) {
                console.log('error in deleteCollateralMedia',)
                // err.message
            }
        })
}
export const searchBankDetails = (bankData: any, userCredentials: IUserCredentials | null, setSearchData?: any, setshowSpiner?: any,) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    const search = {
        "id_initiator": currUserCredentials?.idInitiator,
        "id_client": currUserCredentials?.idClient,
        "id_branch": currUserCredentials?.idBranch,
        "bank_account_number": bankData.accountNumber,
        "bank_branch_code": bankData.branch,
        "bank_code": bankData.bank,
        "country_code": bankData.country,
        "routing_number": bankData.routingNumber ? bankData.routingNumber : null,
        "search_type": "bank_account"
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    const body = JSON.stringify(search);
    await axios.post(basicUrl + "/entity/search", body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0 || res.data["err_code"] === 1) {
                        //   setshowSpiner(false)
                        setSearchData(res.data)
                        // return true;
                    }
                    else {
                        setshowSpiner && setshowSpiner(false)
                    }
                }
                else {
                    setshowSpiner && setshowSpiner(false)
                }
            }
            catch (err) {
                console.warn('error in update customer', err)
                setshowSpiner && setshowSpiner(false)
            }
        })
}

export const createTransferFromCustomer = (bankTransfer: any, userCredentials: IUserCredentials | null, idEntity: any, setShowDialogSave?: any, setShowDialogFailed?: any, setshowSpiner?: any) => async (dispatch: Function) => {
    currUserCredentials = userCredentials
    let idNewCustomer = ""
    if (!idEntity) {
        let res = await dispatch(createCustomer(bankTransfer, userCredentials, false))
        idNewCustomer = res?.data?.data?.id_entity
    }
    else {

    }
    const createBankAccount =
    {
        "id_initiator": currUserCredentials && currUserCredentials["idInitiator"],
        "id_client": currUserCredentials && currUserCredentials["idClient"],
        "id_branch": currUserCredentials && currUserCredentials["idBranch"],
        "id_entity": idEntity ? idEntity : idNewCustomer,
        "return_entity": 1,
        "attribute_request_method": "create",
        "attribute": "bank_account",
        "entity_type_id": "customer",
        "country_code": bankTransfer.country,
        "bank_name": bankTransfer.bankName,
        "bank_branch_name": bankTransfer.branchName,
        "bank_code": bankTransfer.bank,
        "bank_details": bankTransfer?.note,
        "bank_branch_code": bankTransfer.branch,
        "bank_branch_address": bankTransfer.branchAddress,
        "bank_account_number": bankTransfer.accountNumber,
        "IBAN": bankTransfer?.IBAN
    }
    const body = JSON.stringify(createBankAccount);
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        }
    };
    return axios.post(basicUrl + '/entity', body, config)
        .then(res => {
            try {
                if (res.status === 200) {
                    if (res.data["err_code"] === 0) {
                        let newBank = res.data.data?.bank_accounts[0]
                        res.data.data?.bank_accounts?.forEach((b: any) => {
                            if (b.bank_account_id > newBank?.bank_account_id)
                                newBank = b
                        })
                        let financialAssetDetails = {
                            "bank_account_id": newBank?.bank_account_id,
                            "id_account_holder": res.data.data.id_entity,
                            "transfer_purpose": bankTransfer?.transfer_purpose,
                            "bic_code": bankTransfer?.bic_code
                        }
                        setShowDialogSave && setShowDialogSave(true)
                        setshowSpiner && setshowSpiner(false)
                        return financialAssetDetails
                    }
                }
                else {
                    setshowSpiner && setshowSpiner(false)
                }
            }
            catch (err) {
                setShowDialogFailed && setShowDialogFailed(true)
                setshowSpiner && setshowSpiner(false)
                console.log('error in create bankAcoount', err)
            }
        })
}

export const accountsFilter = (idAccount: any, idEntity: any, userCredentials: IUserCredentials | null,
    accountRequestMethodString:any, setSearch_fields: any, setRowData: any, pageIndex: any, search_filter?: any, numRecord?: any,
    setServerMessageError?: any, setMessageError?: any, setDialogTitle?: any) => async (dispatch: Function) => {
       console.log(search_filter,"setSearch_fields");
        debugger
       
        const filter = {
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "id_client": userCredentials && userCredentials["idClient"],
            "id_branch": userCredentials && userCredentials["idBranch"],
            "id_entity": idEntity,
            "id_account": idAccount,
            // "home_page",
            "account_request_method": accountRequestMethodString,
            "search_fields": search_filter ? search_filter : [],
            "page_size": numRecord ? numRecord : 10,
            "page_index": pageIndex,
            "order_fields": []
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        };
        const body = JSON.stringify(filter)
    await axios.post(basicUrl + '/account', body, config)
            .then(res => {
                debugger
                try {
                    if (res.status === 200) {
                        if (res.data["err_code"] === 0) {
                            setRowData && setRowData(res?.data.data?res?.data.data:res.data)
                            setSearch_fields && setSearch_fields(res?.data.data?res?.data.data.search_fields:res?.data?.search_fields)
                        }
                        else {
                            setRowData && setRowData({})
                            setSearch_fields && setSearch_fields([])
                            setServerMessageError && setServerMessageError(true)
                            setMessageError && setMessageError(res.data?.ui_message?.ui_message
                                ? res.data?.ui_message?.ui_message : res.data?.err_description
                            )
                            setDialogTitle && setDialogTitle(res.data?.ui_message?.ui_message_header ?
                                res.data?.ui_message?.ui_message_header : "שגיאה")

                            console.log(res.data?.err_description)
                        }
                    }
                }
                catch (err) {
                    console.log('error in getDataAfterFilter', err)
                }
            })
    }

   


