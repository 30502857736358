import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useBoolean } from '@fluentui/react-hooks';
import {
  PrimaryButton, Dialog, DialogFooter,
  ContextualMenu, DefaultButton
} from 'office-ui-fabric-react';
import { isEqual } from 'lodash';

import { CustomerDate } from '../../shared/components/CustomHooks/concatTextFields'
import { CustomFilesDialog } from '../../shared/components/dialog/Dialog'
import CustemTable from '../../shared/components/tabels/tableList';
import { CustomDropdown } from '../../shared/components/Option';
import { CustomTextField } from '../../shared/components/TextField';
import { convertArrToDropdropdown } from '../customerDetails/CustomerDetails'
import { updateCustomerCollateral, createCustomerCollateral } from "../../store/actions/collateralAction"
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';

export const CustomDialogCollateral = (props: any) => {
  const { setShowDialog, itemUpdate, update, idEntity, userCredentials, setShowDialogUpdate, setshowSpiner } = props
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const dispatch = useDispatch()
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
  const [itemsMediaCollateral, setItemsMediaCollateral] = useState<any>([{}])
  const [enumsDropdown, setenumsDropdown] = useState<any>({ "collateralType": [], "collateralStatus": [] })
  const [readOnly, /*setreadOnly*/] = useState(false)
  const [addFiles, setaddFiles] = useState(false)
  const [isCreatedCollateral, setIsCreatedCollateral] = useState(false)
  const [isValidDate, setIsValidDate] = useState(true)
  const [messageError, setMessageError] = useState('')
  const [serverMessageError, setServerMessageError] = useState('')
  const [titleMessageError, setTitleMessageError] = useState('')
  const [showTextRequiredFiles, setShowTextRequiredFiles] = useState(false)
  const [requiredDialog, setRequierdDialog] = useState(false)
  const [updateCollateral, setUpdateCollateral] = useState(itemUpdate ? itemUpdate : {
    key: '', name: '',
    type: '', status: '', dateAdded: '', expirationDate: '', faceValue: '', value: '', note: ''
  })

  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
  };
  const modelProps = {
    isBlocking: false,
    topOffsetFixed: true,
    dragOptions: dragOptions
  };

  const columnsCollateralCustomer = [
    { key: '1', name: t('name'), fieldName: "media_name", entity_name: "media_name", minWidth: 100, maxWidth: 100 },
    { key: '2', name: t('type'), fieldName: "type_name", entity_name: "type_name", minWidth: 100, maxWidth: 100 },
    { key: '4', name: t('dateAdded'), fieldName: "date_created", entity_name: "date_created", minWidth: 100, maxWidth: 100 },
    { key: '9', name: t('operations'), fieldName: "operationsFiles", entity_name: "operationsFiles", minWidth: 280, maxWidth: 280 },
  ];

  useEffect(() => {
    if (itemUpdate) {
      setUpdateCollateral(itemUpdate)
      collateralMediaData()
    }
  }, [itemUpdate])


  useEffect(() => {
    if (enums) {
      let arr = {
        "collateralStatus": convertArrToDropdropdown([]),
        "collateralType": convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].collateral !== undefined) {
          let col = enums[i].collateral
          for (let i = 0; i < col.length; i++) {
            if (col[i].type_id !== undefined)
              arr.collateralType = convertArrToDropdropdown(col[i].type_id)
            if (col[i].status_id !== undefined)
              arr.collateralStatus = convertArrToDropdropdown(col[i].status_id)
          }
        }
      }
      setenumsDropdown(arr);
      if (update === undefined) {
        let defaultStatusValue: any, defaultTypeValue: any
        (defaultStatusValue = arr?.collateralStatus.find(({ is_default }: any) => is_default === true))
        defaultTypeValue = arr.collateralType.find(({ is_default }: any) => is_default === true)
        setUpdateCollateral({ ...updateCollateral, statusId: defaultStatusValue?.key, typeId: defaultTypeValue?.key })
      }
    }
  }, [enums])

  useEffect(() => {
    if (titleMessageError === "שמירה" || titleMessageError === "save")
      setIsCreatedCollateral(true)
  }, [titleMessageError])

  useEffect(() => {
    if (setShowDialog) {
      toggleHideDialog()
    }
  }, [setShowDialog])

  const collateralMediaData = () => {
    let arrMediaCollateral: object[] = []
    itemUpdate?.collateralMedia?.forEach((c: any, index: number) => {
      c.is_deleted === false && arrMediaCollateral.push({
        id_media: c.id_media, media_name: c.media_name, note: c.details, type_name: c.type_name,
        media_type_id: c.type_id, media_exp_date: c.expiration_date, media_status_id: c.status_id,
        date_created: c?.date_created, collateralId: itemUpdate.key, media_id: c.media_id
      })
    });
    setItemsMediaCollateral(arrMediaCollateral)
  }

  const updateCollateralPerCustomer = () => {
    if (!isEqual(updateCollateral, itemUpdate)) {
      if (updateCollateral?.typeId && updateCollateral?.value && updateCollateral?.faceValue &&
        updateCollateral?.statusId && updateCollateral?.name && updateCollateral?.number) {
        if (isValidDate) {
            setshowSpiner(true)
            if (update === true) {
              toggleHideDialog();
              setShowDialog(false);
              dispatch(updateCustomerCollateral(updateCollateral, userCredentials,
                idEntity, setShowDialogUpdate, setshowSpiner))
            }
            else {
              dispatch(createCustomerCollateral(updateCollateral, setUpdateCollateral,
                userCredentials, idEntity, setServerMessageError, setMessageError,
                setTitleMessageError, setshowSpiner, t))
            }
         
        }
      }
      else {
        setShowTextRequiredFiles(true)
        setRequierdDialog(true)
      }

    }
  }

  const changeCollateral = (key: string, value: any) => {
    if (key === "value" || key === "faceValue") {
      value = value.replace(/\D/g, '');
      value = Number(value)
      value = value.toLocaleString()
    }
    setUpdateCollateral({ ...updateCollateral, [key]: value })
  }

  return (
    <>
      <Dialog minWidth="xl" maxWidth="xl" hidden={hideDialog} modalProps={modelProps} onDismiss={() => { toggleHideDialog(); setShowDialog(false) }} >
        {requiredDialog && <DialogMessages setansDelete={setRequierdDialog}
          setshowModalDelete={setRequierdDialog} dialogType={"requiredFields"} />}
        {serverMessageError && <DialogMessages setansDelete={setServerMessageError} dialogType={"dialogMessage"}
          setshowModalDelete={setServerMessageError} subText={messageError} title={titleMessageError} />}

        <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{t("collateral")}</p>
        <hr className="hr"></hr>
        <div className={`div-dialig-collareral-${dir}`}>
          <p className="title-text">{t("details")}</p>
          <hr className="hr"></hr>
          <div className="wrapper-collateral">
            <div className={`warpper-fields-right-${dir}`}>
              <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles &&
                updateCollateral?.name === "" ? true : false} required={true} value={updateCollateral?.name}
                label={t('collateral.name')} onChange={changeCollateral} id={'name'} />
              <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles &&
                !updateCollateral?.number ? true : false} required={true} value={updateCollateral?.number}
                label={t('collateral.number')} onChange={changeCollateral} id={'number'} />
              <label style={{ padding: "5px 0px" }}>{t('type')} <span style={{ color: "rgb(164, 38, 44)" }}>*</span></label>
              <CustomDropdown readOnly={readOnly} requiredField={showTextRequiredFiles &&
                !updateCollateral?.typeId ? true : false} otherInputId={''} hasOtherValue={false}
                required options={enumsDropdown.collateralType} onChange={changeCollateral} label={""}
                selectedKey={updateCollateral?.typeId?.toLocaleString()} id={'typeId'} othertextInput={t('')} />
              <CustomDropdown readOnly={readOnly} requiredField={showTextRequiredFiles &&
                !updateCollateral?.statusId ? true : false} otherInputId={''} hasOtherValue={false}
                required options={enumsDropdown.collateralStatus} label={t('status')} onChange={changeCollateral}
                selectedKey={updateCollateral?.statusId?.toLocaleString()} id={'statusId'} othertextInput={t('')} />
            </div>
            <div className={`warpper-fields-left-${dir}`}>
              <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles &&
                updateCollateral?.value === "" ? true : false} required={true}
                value={update ? updateCollateral?.value : (updateCollateral?.value)}
                label={t('value')} onChange={changeCollateral} id={'value'} />
              <CustomTextField readOnly={readOnly} requiredField={showTextRequiredFiles &&
                updateCollateral?.faceValue === "" ? true : false}
                required={true} value={update ? updateCollateral?.faceValue : (updateCollateral?.faceValue)}
                label={t('faceValue')} onChange={changeCollateral} id={'faceValue'} />
              <CustomerDate isDefaultDate={false} isValidDate={isValidDate} setIsValidDate={setIsValidDate}
                entity={updateCollateral} setEntity={setUpdateCollateral} readOnly={readOnly} idDate="expirationDate"
                label={t('expirationDate')} source={"futureDate"} />

            </div>
          </div>
          <div className="note-field">
            <CustomTextField value={updateCollateral?.note} label={t('note')} onChange={changeCollateral} id={'note'} /></div>
          {isCreatedCollateral || update ? <div className="atteched-field" >
            <p className="title-text">{t("attachedFiles")}</p>
            <div className="title-text addFileCol" onClick={() => setaddFiles(true)} ><i className="fal fa-plus"></i></div>
          </div> : ""}
          {addFiles ? <CustomFilesDialog isCreatedCollateral={isCreatedCollateral} setShowParentDialog={setShowDialog}
            setShowDialog={setaddFiles} idCollateral={updateCollateral?.key} itemUpdate={updateCollateral?.collateral_media}
            userCredentials={userCredentials} idEntity={idEntity} /> : ""}
          {!isCreatedCollateral && !update ? <p style={{ color: "red" }}>{t("textToCreateFile")}</p> : <hr className="hr"></hr>}
          {itemsMediaCollateral.length === 0 ? '' : itemsMediaCollateral[0].collateralId &&
            <CustemTable tableName="attachedFiles" columns={columnsCollateralCustomer} allItems={itemsMediaCollateral}
              renderItemColumn rederRow={"operationsFiles"} addCustem={true} isFooter={false} />}
          <DialogFooter >
            <DefaultButton onClick={() => { toggleHideDialog(); setShowDialog(false) }} text={t("cancel")} />
            <PrimaryButton onClick={() => { updateCollateralPerCustomer() }} text={update === true ? t("update") : t("create")} />
          </DialogFooter>
        </div>
      </Dialog>

    </>
  )
}
