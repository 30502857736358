import { useEffect, useState, useRef } from "react";
import { DefaultButton, IComboBoxStyles, PrimaryButton } from "@fluentui/react";
import { CustomDropdown } from "../../../../shared/components/Option";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  faPlusCircle,
  faCalculator,
  faTimesCircle,
  faDotCircle,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { ISpinnerStyles, Spinner } from "@fluentui/react/lib/Spinner";
import { ComboBox, IComboBoxOption, IComboBox } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrencyInput from "react-currency-input-field";
import { isEqual, cloneDeep } from "lodash";

import { loadEnums } from "../../../../store/actions/dataActions";
import {
  checkAge,
  checkPermissionLevelOfPage,
  checkRequiredFields,
  customerInfoInObject,
  isCorporationFunc,
} from "../../../../shared/components/CustomHooks/GlobalFunctions";
import { CustomToggle } from "../../../../shared/components/Toggle";
import { DialogMessages } from "../../../../shared/components/dialog/DialogMessages";
import Subtitle from "../../../../shared/components/Subtitle";
import HeaderUtils from "../../../../shared/components/headerUtils/HeaderUtils";
import Gates from "./Gates";
import { PrintHtml } from "../../../../shared/components/CustomHooks/PrintHtml";
import {
  IUserCredentials,
  convertArrToDropdropdown,
} from "../../../customerDetails/CustomerDetails";
import {
  createTransaction,
  deleteTransaction,
  getOpenTransactions,
  readDraftTransactions,
} from "../../../../store/actions/convertionActions";
import { CustomTextField } from "../../../../shared/components/TextField";
import { Icons } from "../../../../modelsType/Icon";
import { useHistory, useLocation } from "react-router-dom";
import {
  CashPaymentDialog,
  IncomePaymentDialog,
  CustomDialogCheckTable,
  CheckToCustomerDialog,
  CustomDialogCreditFromCustomer,
  CustomDialogTransferFromCustomer,
} from "../../../../shared/components/dialog/Dialog";
import { CheckDetailsDialog } from "../../../../shared/components/dialog/dialogTransaction/CheckDetailsDialog";
import { TextFeildNote } from "../../../../shared/components/Note";
import EditableRelatedFactors from "../../../relatedFactors/EditableRelatedFactors";
import ListRelatedFactors from "../../../relatedFactors/ListRelatedFactors";
import {
  RESET_CUSTOMER_OBJECT,
  SET_DATA_CONVERSION,
  RESET_DATA_CONVERSION,
  CHANGED_LOCATION,
} from "../../../../store/actions/types";
import EntityDetails from "../../../global/EntityDetails";
import { deteleFile, uploadMultiScanChecks } from "../../../../store/actions/FilesAction";
import { readCustomerId } from "../../../../store/actions/customerActioin";

import "./Convertions.scss";
import { ScanCheck } from "../../../../shared/components/dialog/dialogTransaction/ScanCheck";
import moment from "moment";

const Convertions = () => {
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language);
  const dispatch = useDispatch();
  let componentRef = useRef(null);
  const location = useLocation();
  const credentials = useSelector(
    ({ authReducer }: any) => authReducer?.data?.credentials
  );
  const settings = useSelector(
    ({ authReducer }: any) => authReducer?.data?.user?.setting
  );
  const history = useHistory();
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums);
  const [readOnly, setreadOnly] = useState(true);
  const authReducer = useSelector(({ authReducer }: any) => authReducer);
  const [userCredentials, setUserCredentials] =
    useState<IUserCredentials | null>(null);
  const [docAction, setDocAction] = useState(false);
  const [addDoc, setAddDoc] = useState("");
  const [insiderAction, setInsiderAction] = useState(false);
  const [addInsider, setAddInsider] = useState("");
  const [TransferFromCustomer, setTransferFromCustomer] = useState(false);
  const [credit, setCredit] = useState(false);
  const [checkTable, setcheckTable] = useState(false);
  const [checkDetails, setcheckDetails] = useState(false);
  const [cashPayment, setcashPayment] = useState(false);
  const [incomePayment, setIncomePayment] = useState(false);
  const [showDialogSave, setShowDialogSave] = useState(false);
  const [showDialogFinishFail, setshowDialogFinishFail] = useState(false);
  const [showSpiner, setshowSpiner] = useState(false);
  const [tableType, settableType] = useState("");
  const [rowNumber, setRowNumber] = useState("");
  const [addFactorDialog, setaddFactorDialog] = useState(false);
  const [listInsiders, setListInsiders] = useState([{}]);
  const [sumIn, setsumIn] = useState(0);
  const [sumOut, setsumOut] = useState(0);
  const [scanButton, setScanButton] = useState(false);
  const [isIconScanButton, SetIsIconScanButton] = useState(false);

  let num1: any;
  let num: any;
  const [emptyInContraAccount, setEmptyInContraAccount] = useState(false);
  const [emptyOutContraAccount, setEmptyOutContraAccount] = useState(false);
  const [emptyRequiredFields, setEmptyRequiredFields] = useState(false);
  const [emptyInAmount, setEmptyInAmount] = useState(false);
  const [emptyOutAmount, setEmptyOutAmount] = useState(false);
  const [relatedFactors, setRelatedFactors] = useState<any>();
  const [defaultCurrencyCode, setdefaultCurrencyCode] = useState();
  const [defaultfinanssialAsset, setdefaultfinanssialAsset] = useState();
  const [itemsInsiders, setItemsInsiders] = useState<Object[]>([]);
  const [conversionCustomer, setConversionCustomer] = useState<any>();
  const [conversionCustomerId, setConversionCustomerId] = useState<any>();
  const [savedateConversion, setSaveDataConversion] = useState("");
  const [checkDetailsOut, setcheckDetailsOut] = useState(false);
  const [showDialogSaveData, setShowDialogSaveData] = useState(false);
  const [dialogBeforeChangeDraft, setDialogBeforeChangeDraft] = useState(false);
  const [ifChanges, setIfChanges] = useState(false);
  const [exsistChanges, setExsistChanges] = useState(false);
  const [saveBeforeChangeDraft, setSaveBeforeChangeDraft] = useState("");
  const [showDialogFailed, setShowDialogFailed] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [serverMessageError, setServerMessageError] = useState<any>("");
  const [titleMessageError, setTitleMessageError] = useState("");
  const [contraAccounts /*setcontraAccounts*/] = useState(
    authReducer?.data?.user.contra_accounts
  );
  const dataNewTransaction = useSelector(
    ({ conversionReducer }: any) => conversionReducer.dataNewTransaction
  );
  const [contraCash, setContraCash] = useState<any>([]);
  const [contraCheck, setContraCheck] = useState<any>([]);
  const [contraCreditCard, setContraCreditCard] = useState<any>([]);
  const [contraBankAccounts, setcontraBankAccounts] = useState<any>([]);
  const [contraIncomes, setContraIncomes] = useState<any>([]);
  const [currencies /*setCurrencies*/] = useState(
    authReducer?.data
      ? authReducer?.data?.user.setting?.currency_shortcuts
      : []
  );
  const [saveDataFailed, setSaveDataFailed] = useState(false);
  const [saveDatasuccess, setSaveDatasuccess] = useState(false);
  const [continueAfterFailed, setContinueAfterFailed] = useState();
  const [selectConversionList, setSelectConversionList] = useState<any>([]);
  const [beforeDelete, setbeforeDelete] = useState(false);
  const [ifDelete, setifDelete] = useState(false);
  const [, /*showHtmlData*/ setShowHtmlData] = useState(false);
  const [selectedDraft, setSelectedDraft] = useState("");
  const { state } = useLocation<any>();
  const [gates, setGates] = useState<any>({});
  const [isShortcut, setIsShortcut] = useState<any>(false);
  const [isShortcutRateIn, setIsShortcutRateIn] = useState<any>(false);
  const [isShortcutRateOut, setIsShortcutRateOut] = useState<any>(false);
  let isShortcut2 = false;
  const [listCurrency, setListCurrency] = useState<any>([]);
  const [ifChangeRelatedType, setIfChangeRelatedType] = useState(false);
  const [rowOfDelete, setRowOfDelete] = useState<any>();
  const [saveDeleteRowActoin, setSaveDeleteRowActoin] = useState("");
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [clickScan, setClickScan] = useState(false);
  const saveDraft = useSelector(
    ({ conversionReducer }: any) => conversionReducer.saveDraftChanges
  );
  const [allScanChecks, setAllScanChecks] = useState<any>([]);
  const datacConversion = useSelector(
    ({ conversionReducer }: any) => conversionReducer.dataConversion
  );
  const [checkType, setCheckType] = useState('IL');
  const [convertions, setConvertions] = useState({
    id_entity: "",
    documentation: [],
    note: "",
    related_entities: [],
    transaction_document: { document_request_method: null },
    transaction_type_id: 1,
    total_amount_ils: 0,
    id_transaction: null,
    id_account: "",
    is_unusual_reporting: false,
    financial_assets_in: [
      {
        currency_code: defaultCurrencyCode,
        financial_asset_code: defaultfinanssialAsset,
        financial_asset_details: null,
        amount: 0,
        exchange_rate_ils: 1,
        amount_ils: 0,
        contra_id_account: null,
        row_number: 1,
      },
    ],

    financial_assets_out: [
      {
        currency_code: defaultCurrencyCode,
        financial_asset_code: defaultfinanssialAsset,
        financial_asset_details: null,
        amount: 0,
        exchange_rate_ils: 1,
        amount_ils: 0,
        contra_id_account: null,
        row_number: 1,
      },
    ],
  });
  const [indexTab, setIndexTab] = useState(0);
  const [enumsDropdown, setenumsDropdown] = useState<any>({
    currencyCode: [],
    finanssialAssetCode: [],
  });
  const [enumDropdownOut, setEnumDropdownOut] = useState<any>();
  const [enumDropdownIn, setEnumDropdownIn] = useState<any>();
  const [currenciesEnum, setCurrenciesEnum] = useState<any>([]);
  const spinnerStyles: Partial<ISpinnerStyles> = {
    root: {
      position: "fixed",
      zIndex: "999999",
      fontWeight: "bold",
    },
    circle: {
      color: "#FFB400 ",
      height: "100px",
      width: "100px",
    },
  };
  const comboStyles: Partial<IComboBoxStyles> = {
    root: {
      direction: "rtl",
      ...(dir === "ltr" && {
        paddingRight: "9px !important",
        paddingLeft: "32px !important",
      }),
    },
  };

  useEffect(() => {
    async function dataNewTransactionFunc() {
      if (dataNewTransaction?.isNew === true) {
        await resetPageData();
        await dispatch(
          readCustomerId(
            dataNewTransaction?.idCustomer,
            userCredentials,
            null,
            "",
            "",
            false,
            "",
            setreadOnly,
            setConversionCustomer,
            ""
          )
        );
      }
    }
    dataNewTransactionFunc();
  }, [dataNewTransaction]);

  useEffect(() => {
    async function addInsiderFunc() {
      if (addInsider === "save") {
        setAddInsider("");
        await dispatch(
          createTransaction(
            "update",
            "exchange",
            datacConversion.entity_name,
            convertions,
            userCredentials,
            convertions.id_entity
          )
        );
        await dispatch(
          readCustomerId(
            convertions?.id_entity,
            userCredentials,
            "",
            "",
            "",
            false
          )
        );
        const location = {
          pathname: "/customers/customer_related_entities",
          state: { category: "customers" },
        };
        await dispatch({
          type: CHANGED_LOCATION,
          payload: location,
        });
        await dispatch({
          type: RESET_DATA_CONVERSION,
          payload: null,
        });
      }
      if (addInsider === "don't save") {
        setAddInsider("");
      }
    }

    addInsiderFunc();
  }, [addInsider]);

  useEffect(() => {
    async function addDocFunc() {
      if (addDoc === "save") {
        setAddDoc("");
        await dispatch(
          createTransaction(
            "update",
            "exchange",
            datacConversion.entity_name,
            convertions,
            userCredentials,
            convertions.id_entity
          )
        );
        await dispatch(
          readCustomerId(
            convertions?.id_entity,
            userCredentials,
            "",
            "",
            "",
            false
          )
        );
        const location = {
          pathname: "/customers/customer_documentation",
          state: { category: "customers" },
        };
        dispatch({
          type: CHANGED_LOCATION,
          payload: location,
        });
      }
      if (addDoc === "don't save") {
        setAddDoc("");
      }
    }
    addDocFunc();
  }, [addDoc]);
  useEffect(() => {
    if (contraAccounts) {
      let arrCash: any[] = [],
        arrCheck: any[] = [],
        arrCredit: any[] = [],
        arrBank: any[] = [],
        arrIncome: any[] = [];
      for (let i = 0; i < contraAccounts.length; i++) {
        if (contraAccounts[i].contra_account_group_id === "cash") {
          arrCash.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "cheque") {
          arrCheck.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "credit_card") {
          arrCredit.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "bank_account") {
          arrBank.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "income") {
          arrIncome.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
      }
      setContraCash(arrCash);
      setContraCheck(arrCheck);
      setContraCreditCard(arrCredit);
      setcontraBankAccounts(arrBank);
      setContraIncomes(arrIncome);
    }
  }, [contraAccounts]);

  useEffect(() => {
    let financialAssetsIn: any = [...convertions["financial_assets_in"]];
    let financialAssetsOut: any = [...convertions["financial_assets_out"]];
    if (
      contraCash.length > 0 &&
      !financialAssetsIn[0].contra_id_account &&
      !financialAssetsOut[0].contra_id_account &&
      defaultfinanssialAsset === "1"
    ) {
      financialAssetsIn[0].currency_code = defaultCurrencyCode;
      financialAssetsIn[0].financial_asset_code = defaultfinanssialAsset;
      financialAssetsOut[0].currency_code = defaultCurrencyCode;
      financialAssetsOut[0].financial_asset_code = defaultfinanssialAsset;
      const sortArray = financialAssetsIn.sort((a: any, b: any) => a.row_number - b.row_number);
      setConvertions((convertions) => ({
        ...convertions,
        financial_assets_in: sortArray,
        financial_assets_out: financialAssetsOut,
      }));
    }
  }, [contraCash, convertions.id_transaction]);

  useEffect(() => {
    if (datacConversion) {
      const { id_transaction } = datacConversion;
      if (
        convertions.id_transaction === null &&
        datacConversion.transaction_request_method === "create"
      ) {
        setSelectedDraft(id_transaction + "," + convertions?.id_entity);
        const sortArray = convertions?.financial_assets_in?.sort((a: any, b: any) => a.row_number - b.row_number);
        setConvertions({ ...convertions, financial_assets_in: sortArray, id_transaction: id_transaction });
      }
      if (selectedDraft !== "" || state === "showCompleted")
        setDraftConversionsData();
    }
  }, [datacConversion]);

  useEffect(() => {
    if (datacConversion && state?.source === "refresh") {
      history.location.state = {};
      setSelectedDraft(
        datacConversion?.id_transaction + "," + datacConversion?.id_entity
      );
      setDraftConversionsData();
    }
  }, [datacConversion, state]);

  useEffect(() => {
    if (saveDraft) {
      if (
        saveDraft?.changeLocation === true &&
        location.pathname !== saveDraft?.location
      ) {
        if (saveDraft?.setcategory) {
          if (
            saveDraft?.nameCategory &&
            convertions?.id_transaction !== null &&
            ifChanges
          )
            setShowDialogSaveData(true);
          else {
            checkIfDeleteTransaction();
            saveDraft?.setcategory(saveDraft.nameCategory);
            location.state = { category: saveDraft.nameCategory };
            history.replace(saveDraft?.location);
          }
        } else {
          if (
            saveDraft?.location &&
            saveDraft?.location !== "/currency_exchange/exchange"
          ) {
            if (convertions?.id_transaction !== null && ifChanges)
              setShowDialogSaveData(true);
            else {
              checkIfDeleteTransaction();
              history.replace(saveDraft?.location);
            }
          }
        }
      }
    }
  }, [saveDraft]);

  const changeLocation = () => {
    checkIfDeleteTransaction();
    if (saveDraft?.setcategory) {
      saveDraft?.setcategory(saveDraft.nameCategory);
      location.state = { category: saveDraft.nameCategory };
      history.replace(saveDraft?.location);
    } else saveDraft?.location && history.replace(saveDraft?.location);
  };

  const checkIfDeleteTransaction = async () => {
    if (sumIn === 0 && sumOut === 0)
      await dispatch(
        deleteTransaction(
          "delete",
          "exchange",
          convertions,
          userCredentials,
          convertions.id_entity
        )
      );
  };

  useEffect(() => {
    async function savedateConversionFunc() {
      if (savedateConversion === "save") {
        setshowSpiner(true);
        await dispatch(
          createTransaction(
            "update",
            "exchange",
            conversionCustomer?.entityName,
            convertions,
            userCredentials,
            convertions.id_entity,
            setshowSpiner,
            setServerMessageError,
            setMessageError,
            setTitleMessageError,
            "",
            "",
            setSaveDataFailed,
            setSaveDatasuccess
          )
        );
        resetPageData();
      }
      if (savedateConversion === "don't save") changeLocation();
    }
    savedateConversionFunc();
  }, [savedateConversion]);

  useEffect(() => {
    saveDatasuccess === true && changeLocation();
  }, [saveDatasuccess]);

  useEffect(() => {
    state === "showCompleted" && setreadOnly(true);
  }, [readOnly]);

  useEffect(() => {
    setSaveDataConversion("");
    setSaveDataFailed(false);
    if (continueAfterFailed) {
      setIfChanges(false);
      changeLocation();
    } else
      saveDraft?.setcategory && saveDraft?.setcategory(saveDraft?.nameCategory);
  }, [continueAfterFailed]);

  useEffect(() => {
    if (enums) {
      let list: any = [],
        listSomeCurrency: any = [],
        listAllCurrency: any = [];
      let arr = {
        currencyCode: list,
        someOfcurrency: listSomeCurrency,
        allOfcurrency: listAllCurrency,
        finanssialAssetCode: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].general !== undefined) {
          let s = enums[i].general;
          for (let i = 0; i < s.length; i++) {
            if (s[i].currency_code !== undefined) {
              s[i].currency_code?.forEach((item: any) => {
                arr.allOfcurrency.push({
                  currency_symbol: item?.currency_symbol,
                  key: item?.enum_id,
                  text: item?.enum_value,
                  is_default: item?.is_default,
                });
                if (item?.is_selected) {
                  arr.someOfcurrency.push({
                    currency_symbol: item?.currency_symbol,
                    key: item?.enum_id,
                    text: item?.enum_value,
                    is_default: item?.is_default,
                  });
                }
              });
            }
          }
        }
        if (enums[i].exchange !== undefined) {
          let s = enums[i].exchange;
          for (let i = 0; i < s.length; i++) {
            if (s[i].financial_asset_code !== undefined)
              arr.finanssialAssetCode = convertArrToDropdropdown(
                s[i].financial_asset_code
              );
          }
        }
      }
      arr.someOfcurrency.push({
        currency_symbol: "",
        key: "More",
        text: t("more"),
        is_default: false,
      });
      arr.allOfcurrency.push({
        currency_symbol: "",
        key: "Less",
        text: t("less"),
        is_default: false,
      });
      arr.currencyCode = [...arr.someOfcurrency];
      let finanssialAssetCodeOut = [...arr.finanssialAssetCode];
      let indexOut = finanssialAssetCodeOut.findIndex((item) => item.key === "12");
      finanssialAssetCodeOut?.splice(indexOut, 1);
      let finanssialAssetCodeIn = [...arr.finanssialAssetCode];
      let indexIn = finanssialAssetCodeIn.findIndex((item) => item.key === "0");
      finanssialAssetCodeIn?.splice(indexIn, 1);
      let defaultfinanssialValue: any;
      defaultfinanssialValue = arr?.finanssialAssetCode.find(
        ({ is_default }: any) => is_default === true
      );
      let defaultcurrencyCodeValue: any;
      defaultcurrencyCodeValue = arr?.currencyCode.find(
        ({ is_default }: any) => is_default === true
      );
      setdefaultCurrencyCode(
        defaultcurrencyCodeValue && defaultcurrencyCodeValue.key
      );
      setdefaultfinanssialAsset(
        defaultfinanssialValue && defaultfinanssialValue.key
      );
      setenumsDropdown(arr);
      setEnumDropdownOut(finanssialAssetCodeOut);
      setEnumDropdownIn(finanssialAssetCodeIn);
    }
  }, [enums]);

  useEffect(() => {
    if (authReducer) {
      setUserCredentials({
        idInitiator: authReducer.data.user.id_entity,
        idClient: authReducer.data.user.id_client,
        idBranch: authReducer.data.branch.id_entity,
        idClientData: authReducer.data.client.id_client,
      });
      if (!userCredentials && enumsDropdown?.currencyCode?.length === 0) {
        dispatch(
          loadEnums(
            authReducer.data.client.id_entity,
            authReducer.data.client.id_entity
          )
        );
      }
    }
  }, [authReducer]);

  useEffect(() => {
    let allCurrencies: any[] = [];
    let allEnums: any[] = [];
    let userCurrencies: any[] = [];
    if (
      currencies &&
      enumsDropdown.currencyCode.length > 0 &&
      currenciesEnum.length === 0
    ) {
      allCurrencies = [...enumsDropdown.currencyCode];
      enumsDropdown.currencyCode.forEach((c: any) => {
        let index = currencies.findIndex((i: any) => i === c.key);
        if (index !== -1) {
          allEnums.push(c);
          allCurrencies = allCurrencies.filter((item) => item !== c);
        }
      });

      enumsDropdown.allOfcurrency.forEach((c: any) => {
        let index = currencies.findIndex((i: any) => i === c.key);
        if (index !== -1) {
          userCurrencies.push(c);
        }
      });

      allCurrencies.sort(function (a: any, b: any) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      allCurrencies.push({ key: 0, text: "הצג הכל" });
      setListCurrency(userCurrencies);
      setCurrenciesEnum(allCurrencies);
    }
  }, [currencies, enumsDropdown]);

  useEffect(() => {
    if (ifChangeRelatedType) {
      //ask if need the servermessages?
      dispatch(
        createTransaction(
          "update",
          "exchange",
          conversionCustomer.entityName,
          convertions,
          userCredentials,
          convertions.id_entity,
          "",
          setServerMessageError,
          setMessageError,
          setTitleMessageError
        )
      );
      // dispatch(createTransaction("update", "exchange", conversionCustomer.entityName, convertions, userCredentials, convertions.id_entity, setShowDialogSave))
      setIfChangeRelatedType(false);
    }
  }, [ifChangeRelatedType]);

  useEffect(() => {
    if (currenciesEnum.length > 0) {
      setenumsDropdown({ ...enumsDropdown, currencyCode: currenciesEnum });
    }
  }, [currenciesEnum]);

  useEffect(() => {
    if (defaultCurrencyCode) {
      let change = false;
      let arr = [...convertions.financial_assets_in];

      if (
        (arr &&
          datacConversion?.financial_assets_in &&
          datacConversion?.financial_assets_in[0].amount_ils === 0) ||
        (arr && !selectedDraft)
      ) {
        arr[0].currency_code = defaultCurrencyCode;
        change = true;
      }
      let arr2 = [...convertions.financial_assets_out];
      if (
        (arr2 &&
          datacConversion?.financial_assets_out &&
          datacConversion?.financial_assets_out[0].amount_ils === 0) ||
        (arr && !selectedDraft)
      ) {
        arr2[0].currency_code = defaultCurrencyCode;
        change = true;
      }
      const sortArray = arr.sort((a: any, b: any) => a.row_number - b.row_number);
      change &&
        setConvertions((convertions) => ({
          ...convertions,
          financial_assets_in: sortArray,
          financial_assets_out: arr2,
        }));
    }
  }, [defaultCurrencyCode]);

  useEffect(() => {
    if (defaultfinanssialAsset) {
      let change = false;
      let arr = [...convertions.financial_assets_in];
      if (
        (arr &&
          datacConversion?.financial_assets_in &&
          datacConversion?.financial_assets_in[0].amount_ils === 0) ||
        (arr && !selectedDraft)
      ) {
        change = true;
        arr[0].financial_asset_code = defaultfinanssialAsset;
      }
      let arr2 = [...convertions.financial_assets_out];
      if (
        (arr2 &&
          datacConversion?.financial_assets_out &&
          datacConversion?.financial_assets_out[0].amount_ils === 0) ||
        (arr && !selectedDraft)
      ) {
        change = true;
        arr2[0].financial_asset_code = defaultfinanssialAsset;
      }
      const sortArray = arr.sort((a: any, b: any) => a.row_number - b.row_number);
      change &&
        setConvertions((convertions) => ({
          ...convertions,
          financial_assets_in: sortArray,
          financial_assets_out: arr2,
        }));
    }
  }, [defaultfinanssialAsset]);

  useEffect(() => {
    setConvertions({
      ...convertions,
      total_amount_ils: sumIn,
    });
  }, [sumIn]);

  useEffect(() => {
    if (relatedFactors) {
      setshowSpiner(false);
      if (relatedFactors?.key === "deleteRelatedType")
        deleteRelatedEntity(relatedFactors);
      else {
        if (relatedFactors?.key === "relatedTypeId") {
          setIfChangeRelatedType(true);
          let arrRelated: any = [...convertions.related_entities];
          let index = arrRelated.findIndex(
            (item: any) => item.id_entity === relatedFactors.id
          );
          if (index !== -1)
            arrRelated[index].service_requisition_type_id =
              relatedFactors.typeId;
          setConvertions({ ...convertions, related_entities: arrRelated });
        } else {
          let arrInsiders: any[] = [...itemsInsiders];
          let index = arrInsiders.findIndex(
            (item: any) =>
              (item?.key === relatedFactors?.key && item?.key !== undefined) ||
              (item?.idEntity && item?.idEntity === relatedFactors?.idEntity) ||
              (item?.id_entity && item?.id_entity === relatedFactors?.key) ||
              (item?.id_entity && item?.id_entity === relatedFactors?.idEntity)
          );
          if (index === -1) {
            arrInsiders.push(relatedFactors);
            setItemsInsiders(arrInsiders);
            addRelatedEntity("", relatedFactors);
          } else {
            arrInsiders[index] = relatedFactors;
            setItemsInsiders(arrInsiders);
          }
        }
      }
    }
  }, [relatedFactors]);

  useEffect(() => {
    if (exsistChanges) {
      const [idTransaction, idEntity] = selectedDraft.split(",");
      if (datacConversion?.status_id === "draft") {
        getOpenTransactions(
          "exchange",
          userCredentials,
          setSelectConversionList
        );
      }
      dispatch(
        readDraftTransactions(
          "exchange",
          userCredentials,
          idEntity,
          idTransaction
        )
      );
      setExsistChanges(false);
    }
  }, [exsistChanges]);

  useEffect(() => {
    if (
      datacConversion?.status_id === "completed" &&
      state !== "showCompleted" &&
      state
    ) {
      document?.getElementById("print")?.click();
    }
  }, [datacConversion?.status_id]);

  useEffect(() => {
    if (datacConversion?.transaction_document?.document_html && selectedDraft) {
      document?.getElementById("print")?.click();
      let convert = cloneDeep(convertions);
      let printDocument: any = convert["transaction_document"];
      printDocument = { document_request_method: null };
      setConvertions({ ...convertions, transaction_document: printDocument });
    }
  }, [datacConversion?.transaction_document]);

  useEffect(() => {
    if (saveBeforeChangeDraft === "save") {
      dispatch(
        createTransaction(
          "update",
          "exchange",
          conversionCustomer?.entityName,
          convertions,
          userCredentials,
          convertions.id_entity,
          setshowSpiner,
          setServerMessageError,
          setMessageError,
          setTitleMessageError,
          "",
          setExsistChanges
        )
      );
      setIfChanges(false);
    }
    if (saveBeforeChangeDraft === "don't save") {
      setIfChanges(false);
      const [idTransaction, idEntity] = selectedDraft.split(",");
      dispatch(
        readDraftTransactions(
          "exchange",
          userCredentials,
          idEntity,
          idTransaction
        )
      );
    }
  }, [saveBeforeChangeDraft]);

  useEffect(() => {
    if (selectedDraft && datacConversion?.status_id === "draft") {
      if (conversionCustomer?.isPopupMessage) {
        setServerMessageError(true);
        setMessageError(conversionCustomer?.note);
        setTitleMessageError(t("note"));
      }
    }
  }, [selectedDraft]);

  useEffect(() => {
    if (ifDelete && datacConversion?.status_id === "draft") {
      dispatch(
        deleteTransaction(
          "delete",
          "exchange",
          convertions,
          userCredentials,
          convertions.id_entity,
          setSelectConversionList,
          setShowDialogSave,
          "",
          setshowSpiner
        )
      );
      setifDelete(false);
      dispatch({
        type: RESET_CUSTOMER_OBJECT,
      });
    }
  }, [ifDelete]);

  useEffect(() => {
    if (
      datacConversion?.status_id === "deleted" ||
      (datacConversion?.status_id === "completed" && state !== "showCompleted")
    )
      resetPageData();
  }, [datacConversion?.status_id]);

  const setDraftFinancialAssets = (financialAssetsArr: any) => {
    let financialAssets: any = [],
      sum = 0;
    datacConversion[financialAssetsArr] &&
      datacConversion[financialAssetsArr].forEach((item: any) => {
        financialAssets.push({
          financial_asset_details: item?.financial_asset_details,
          currency_code: item?.currency_code,
          financial_asset_code: item?.financial_asset_code,
          amount: item?.amount,
          exchange_rate_ils: item?.exchange_rate_ils,
          amount_ils: item?.amount_ils,
          contra_id_account: item?.contra_id_account,
          row_number: item?.row_number,
        });
        sum += item.amount_ils ? item.amount_ils : 0;
      });
    financialAssetsArr === "financial_assets_out"
      ? setsumOut(sum)
      : setsumIn(sum);
    if (financialAssetsArr === "financial_assets_in")
      financialAssets = financialAssets?.sort((a: any, b: any) => a.row_number - b.row_number);
    setConvertions((convertions) => ({
      ...convertions,
      [financialAssetsArr]: financialAssets,
      ["note"]: datacConversion?.note,
    }));
  };
  const PlusIndexTab = () => {
    let index = indexTab;
    index += 1;
    setIndexTab(index);
  };
  const setDraftConversionsData = () => {
    let arrInsiders: any[] = [];
    let arrRelated: any = [];
    const {
      id_transaction,
      id_entity,
      total_amount_ils,
      id_account,
      is_unusual_reporting,
    } = datacConversion;
    datacConversion?.related_entities?.forEach((entity: any) => {
      arrRelated.push({
        id_entity: entity?.id_entity,
        service_requisition_type_id: entity?.service_requisition_type_id,
      });
      if (
        Number(entity.service_requisition_type_id) === 11 ||
        Number(entity.service_requisition_type_id) === 13
      ) {
        arrInsiders.push(
          entity?.related_entitiy
            ? entity?.related_entitiy
            : entity?.related_entity
        );
      }
    });
    setConvertions((convertions) => ({
      ...convertions,
      id_transaction: id_transaction,
      id_entity: id_entity,
      total_amount_ils: total_amount_ils,
      id_account: id_account,
      related_entities: arrRelated,
      is_unusual_reporting: is_unusual_reporting,
    }));
    setItemsInsiders(arrInsiders);
    let customerInfo =
      datacConversion?.entity && customerInfoInObject(datacConversion?.entity);
    setConversionCustomer(customerInfo);
    setConversionCustomerId(datacConversion?.id_entity);
    setDraftFinancialAssets("financial_assets_in");
    setDraftFinancialAssets("financial_assets_out");
  };
  const deleteRelatedEntity = (reletedType: any) => {
    setIfChanges(true);
    let arrInsiders: any[] = [...itemsInsiders];
    let arrRelated: any = [...convertions.related_entities];
    arrRelated = arrRelated.filter(
      (item: any) => item?.id_entity !== reletedType?.item?.key
    );
    arrInsiders = arrInsiders.filter(
      (item: any) =>
        (item?.key !== reletedType?.item?.key && item?.key !== undefined) ||
        (item?.idEntity && item?.idEntity !== reletedType?.item?.key) ||
        (item?.id_entity && item?.id_entity !== reletedType?.item?.key) ||
        (item?.id_entity && item?.id_entity !== reletedType?.item?.key)
    );
    setItemsInsiders(arrInsiders);
    setConvertions({ ...convertions, related_entities: arrRelated });
    reletedType.setshowSpiner(false);
  };
  const addRelatedEntity = (key: string, value: any) => {
    setIfChanges(true);
    let arrRelated: any = [...convertions.related_entities];
    let relatedEntity = { id_entity: "", service_requisition_type_id: "" };
    if (key === "representative") {
      let cuurentrepresentative: any;
      cuurentrepresentative = listInsiders?.find(
        (insider: any) => value === insider?.key
      );
      let index = arrRelated.findIndex(
        (item: any) =>
          item.service_requisition_type_id === 9 ||
          item.service_requisition_type_id === 10
      );
      if (index !== -1) {
        relatedEntity = {
          id_entity: value,
          service_requisition_type_id: cuurentrepresentative?.typeId,
        };
        arrRelated[index] = relatedEntity;
      } else {
        relatedEntity = {
          id_entity: value,
          service_requisition_type_id: cuurentrepresentative?.typeId,
        };
        arrRelated.push(relatedEntity);
      }
    } else {
      if (value?.key) {
        relatedEntity = {
          id_entity: value?.key,
          service_requisition_type_id: value?.entitySubTypeId,
        };
        arrRelated.push(relatedEntity);
      }
    }
    setreadOnly(false);
    setConvertions({ ...convertions, related_entities: arrRelated });
  };
  const change = async (key: any, value: any) => {
    let c: any = convertions;
    !ifChanges && !isEqual(c[key], value) && setIfChanges(true);
    await setConvertions({ ...convertions, [key]: value });
  };

  useEffect(() => {
    if (
      datacConversion?.status_id === "completed" &&
      state !== "showCompleted" &&
      state
    ) {
      document?.getElementById("print")?.click();
    }
  }, [datacConversion?.status_id]);

  useEffect(() => {
    if (datacConversion?.transaction_document?.document_html && selectedDraft) {
      document?.getElementById("print")?.click();
      let convert = cloneDeep(convertions);
      let printDocument: any = convert["transaction_document"];
      printDocument = { document_request_method: null };
      setConvertions({ ...convertions, transaction_document: printDocument });
    }
  }, [datacConversion?.transaction_document]);

  useEffect(() => {
    if (saveBeforeChangeDraft === "save") {
      dispatch(
        createTransaction(
          "update",
          "exchange",
          conversionCustomer?.entityName,
          convertions,
          userCredentials,
          convertions.id_entity,
          setshowSpiner,
          setServerMessageError,
          setMessageError,
          setTitleMessageError,
          "",
          setExsistChanges
        )
      );
      setIfChanges(false);
    }
    if (saveBeforeChangeDraft === "don't save") {
      setIfChanges(false);

      const [idTransaction, idEntity] = selectedDraft.split(",");
      dispatch(
        readDraftTransactions(
          "exchange",
          userCredentials,
          idEntity,
          idTransaction
        )
      );
    }
  }, [saveBeforeChangeDraft]);

  useEffect(() => {
    if (saveDeleteRowActoin === "save") {
      setSaveDeleteRowActoin("");
      setIfChanges(false);
      deleteFileFunc();
    }
    if (saveDeleteRowActoin === "don't save") setSaveDeleteRowActoin("");
  }, [saveDeleteRowActoin]);

  async function deleteFileFunc() {
    let arr: any = [],
      copyAction: any;
    copyAction = datacConversion;
    arr = [...convertions?.financial_assets_in];
    let index = arr.findIndex(
      (a: any) => Number(a.row_number) === Number(rowOfDelete)
    );
    let idMediaFace, idMediaBack: any;
    if (arr[index]?.financial_asset_code === "3") {
      idMediaFace =
        arr[index]?.financial_asset_details?.media?.media_face?.id_media;
      idMediaBack =
        arr[index]?.financial_asset_details?.media?.media_back?.id_media;
    }
    arr.splice(index, 1);
    if (arr.length === 0)
      arr.push({
        currency_code: defaultCurrencyCode,
        financial_asset_code: defaultfinanssialAsset,
        amount: 0,
        exchange_rate_ils: 1,
        amount_ils: 0,
        row_number: 1,
      });
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += Number(arr[i].amount_ils);
    }
    setsumIn(sum);
    const sortArray = arr.sort((a: any, b: any) => a.row_number - b.row_number);
    setConvertions({ ...convertions, financial_assets_in: sortArray });
    copyAction.financial_assets_in = arr;
    idMediaFace &&
      (await dispatch(
        deteleFile(convertions.id_entity, idMediaFace, userCredentials)
      ));
    idMediaBack &&
      (await dispatch(
        deteleFile(convertions.id_entity, idMediaBack, userCredentials)
      ));
    await dispatch(
      createTransaction(
        "update",
        "exchange",
        datacConversion.entity_name,
        copyAction,
        userCredentials,
        convertions.id_entity
      )
    );
  }

  const removeFObject = (row_number: number) => {
    setRowOfDelete(row_number);
    setShowDialogDelete(true);
    setMessageError(t("DeleteRowMassege"));
  };

  const removeFObjectOut = (row_number: number) => {
    setIfChanges(true);
    let copyConvertions: any;
    copyConvertions = { ...datacConversion };
    const items = [...convertions.financial_assets_out];
    let index = items.findIndex(
      (item) => Number(item.row_number) === Number(row_number)
    );
    items.splice(index, 1);
    if (items.length === 0)
      items.push({
        currency_code: defaultCurrencyCode,
        financial_asset_code: defaultfinanssialAsset,
        financial_asset_details: null,
        amount: 0,
        exchange_rate_ils: 1,
        amount_ils: 0,
        contra_id_account: null,
        row_number: 1,
      });
    let sum = 0;
    for (let i = 0; i < items.length; i++) {
      sum += items[i].amount_ils;
    }
    setsumOut(sum);
    setConvertions({ ...convertions, financial_assets_out: items });
    copyConvertions.financial_assets_out = items;
    if (!isEqual(items, datacConversion?.financial_assets_out)) {
      dispatch({
        type: SET_DATA_CONVERSION,
        payload: copyConvertions,
      });
    }
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const finishDeal = async () => {
    if (datacConversion?.ID_number || datacConversion?.entity?.ID_number) {
      let copyConvertions: any = { ...convertions };
      if (conversionCustomer?.isLocked === true) {
        setServerMessageError(true);
        setTitleMessageError(t("note"));
        setMessageError(t("customerlockedMassege"));
      } else {
        setEmptyInContraAccount(false);
        let emptyIn = false,
          emptyOut = false;
        convertions.financial_assets_in.forEach((element: any) => {
          if (!element.contra_id_account) {
            if (element.financial_asset_code === "1") {
              let defaultcontraCash = contraCash.find(
                (item: any) => item.is_default === true
              );
              let index = copyConvertions?.financial_assets_in?.findIndex(
                (item: any) =>
                  Number(item.row_number) === Number(element?.row_number)
              );
              if (index !== -1 && defaultcontraCash) {
                copyConvertions.financial_assets_in[index].contra_id_account =
                  defaultcontraCash.key;
              } else {
                setEmptyInContraAccount(true);
                emptyIn = true;
              }
            } else {
              setEmptyInContraAccount(true);
              emptyIn = true;
            }
          }
          // if (
          //   element.financial_asset_code === "3" &&
          //   (!element?.financial_asset_details?.cheque_date ||
          //     !element?.financial_asset_details?.reference_number ||
          //     !element.amount)
          // ) {
          //   setEmptyRequiredFields(true);
          // }
          const requiredFields = checkRequiredFields(element.financial_asset_code, element);
          if (requiredFields) {
            setEmptyRequiredFields(true)
          }
          if (!Number(element.amount)) {
            setEmptyInAmount(true);
            emptyIn = true;
          }
        });
        convertions.financial_assets_out.forEach((element: any) => {
          if (!element.contra_id_account) {
            if (element.financial_asset_code === "1" || element.financial_asset_code === "0") {
              let defaultcontraCash = contraCash.find(
                (item: any) => item.is_default === true
              );
              let index = copyConvertions?.financial_assets_out?.findIndex(
                (item: any) =>
                  Number(item.row_number) === Number(element?.row_number)
              );
              if (index !== -1 && defaultcontraCash) {
                copyConvertions.financial_assets_out[index].contra_id_account =
                  defaultcontraCash.key;
              } else {
                setEmptyOutContraAccount(true);
                emptyOut = true;
              }
            } else {
              setEmptyOutContraAccount(true);
              emptyOut = true;
            }
          }
          if (
            element.financial_asset_code === "3" &&
            (!element?.financial_asset_details?.cheque_date ||
              !element?.financial_asset_details?.reference_number ||
              !element.amount)
          ) {
            setEmptyRequiredFields(true);
          }
          if (!Number(element.amount)) {
            setEmptyOutAmount(true);
            emptyOut = true;
          }
        });
        if (convertions?.id_transaction !== null) {
          if (
            Number(sumIn.toFixed(2)) - Number(sumOut.toFixed(2)) !== 0 ||
            (convertions.financial_assets_out &&
              convertions.financial_assets_out[0].amount_ils === 0) ||
            (convertions.financial_assets_in[0] &&
              convertions.financial_assets_in[0].amount_ils === 0) ||
            emptyIn ||
            emptyOut
          ) {
            setshowDialogFinishFail(true);
          } else {
            if (
              convertions?.total_amount_ils > 50000 &&
              conversionCustomer?.isFullyIdentified === false
            ) {
              setDocAction(true);
            } else {
              let insider = conversionCustomer?.insiders?.find(
                (item: any) =>
                  item?.insider_type_id === 8 && item?.is_deleted === false
              );
              let type = datacConversion?.entity?.properties?.customer_type_id;
              if (
                (insider === -1 || insider === undefined) &&
                (type === "3" ||
                  type === "5" ||
                  type === "9" ||
                  type === "12" ||
                  type === "10")
              ) {
                setInsiderAction(true);
              } else {
                const age = checkAge(conversionCustomer.dateBirth);
                if (
                  (age >= 16 && !isCorporationFunc(conversionCustomer)) ||
                  isCorporationFunc(conversionCustomer)
                ) {
                  setshowSpiner(true);
                  await dispatch(
                    createTransaction(
                      "complete",
                      "exchange",
                      conversionCustomer.entityName,
                      copyConvertions,
                      userCredentials,
                      convertions.id_entity,
                      setshowSpiner,
                      setServerMessageError,
                      setMessageError,
                      setTitleMessageError,
                      setSelectConversionList
                    )
                  );
                  await dispatch({
                    type: RESET_CUSTOMER_OBJECT,
                  });
                  await setShowHtmlData(true);
                } else {
                  setMessageError("לא ניתן לבצע את הפעולה, הלקוח מתחת גיל 16");
                  setTitleMessageError(t("note"));
                  setServerMessageError(true);
                }
              }
            }
          }
        }
      }
    } else {
      setServerMessageError(true);
      setTitleMessageError(t("note"));
      setMessageError("notHaveIdentityNumber");
    }
  };

  const deleteDeal = async () => {
    if (datacConversion?.status_id === "draft") {
      setbeforeDelete(true);
    } else if (datacConversion?.status_id === "completed") {
      // await dispatch(deleteTransaction("cancel", "exchange", convertions, userCredentials,
      //  convertions.id_entity,setshowSpiner,setServerMessageError, setMessageError, setSelectConversionList ))
      await dispatch(
        deleteTransaction(
          "cancel",
          "exchange",
          convertions,
          userCredentials,
          convertions.id_entity,
          setSelectConversionList,
          setShowDialogSave,
          "",
          setshowSpiner
        )
      );
    }
  };
  const BalanceDeal = async (
    amount: any,
    exchange_rate_ils: any,
    tableType: any,
    key: any
  ) => {
    console.log(amount,"amount");
    
    if (tableType === "in") {
      let balance =
        (sumOut - (sumIn - amount * exchange_rate_ils)) / exchange_rate_ils;
      balance >= 0 && addFinancialIn(key, balance, true);
    }
    if (tableType === "out") {
      let balance =
        (sumIn - (sumOut - amount * exchange_rate_ils)) / exchange_rate_ils;
      balance >= 0 && addFinancialOut(key, balance, true);
    }
  };
  const BalanceDealByRate = async (
    amount: any,
    amount_ils: any,
    tableType: any,
    key: any
  ) => {
    if (tableType === "in") {
      let balance = (sumOut - sumIn + amount_ils) / amount;
      balance >= 0 && addFinancialIn(key, balance, true);
    }
    if (tableType === "out") {
      let balance = (sumIn - sumOut + amount_ils) / amount;
      balance >= 0 && addFinancialOut(key, balance, true);
    }
  };
  const resetPageData = async () => {
    let reset: any;
    await dispatch({
      type: RESET_DATA_CONVERSION,
      payload: null,
    });
    setsumOut(0);
    setsumIn(0);
    setConversionCustomer(reset);
    setIfChanges(false);
    setSelectedDraft("");
    emptyOutContraAccount && setEmptyOutContraAccount(false);
    emptyInContraAccount && setEmptyInContraAccount(false);
    emptyRequiredFields && setEmptyRequiredFields(false);
    emptyInAmount && setEmptyInAmount(false);
    emptyOutAmount && setEmptyOutAmount(false);
    setItemsInsiders([]);
    setreadOnly(true);
    setConvertions({
      id_entity: "",
      documentation: [],
      note: "",
      related_entities: [],
      transaction_document: { document_request_method: null },
      transaction_type_id: 1,
      total_amount_ils: 0,
      id_transaction: null,
      id_account: "",
      is_unusual_reporting: false,
      financial_assets_in: [
        {
          currency_code: defaultCurrencyCode,
          financial_asset_code: defaultfinanssialAsset,
          financial_asset_details: null,
          amount: 0,
          exchange_rate_ils: 1,
          amount_ils: 0,
          contra_id_account: null,
          row_number: 1,
        },
      ],

      financial_assets_out: [
        {
          currency_code: defaultCurrencyCode,
          financial_asset_code: defaultfinanssialAsset,
          financial_asset_details: null,
          amount: 0,
          exchange_rate_ils: 1,
          amount_ils: 0,
          contra_id_account: null,
          row_number: 1,
        },
      ],
    });
  };

  useEffect(() => { }, [isShortcutRateOut, isShortcutRateIn]);

  useEffect(() => {
    if (
      datacConversion?.status_id === "deleted" ||
      (datacConversion?.status_id === "completed" && state !== "showCompleted")
    )
      resetPageData();
  }, [datacConversion?.status_id]);

  const addRow = async (
    financialName: string,
    rowNumber: Number,
    rate?: any
  ) => {
    let arr: any = [];
    if (financialName === "financial_assets_in") {
      arr = [...convertions.financial_assets_in];
    }
    if (financialName === "financial_assets_out") {
      arr = [...convertions.financial_assets_out];
    }
    //check it
    // let defaultcontraCash = contraCash.find((item: any) => item.is_default === true)
    let maxRowNumber = Math.max.apply(
      Math,
      arr.map(function (item: any) {
        return item.row_number;
      })
    );
    if (maxRowNumber === rowNumber) {
      arr.push({
        currency_code: defaultCurrencyCode,
        financial_asset_code: defaultfinanssialAsset,
        financial_asset_details: "",
        amount: 0,
        exchange_rate_ils: 1,
        amount_ils: 0,
        //check it
        // contra_id_account: defaultfinanssialAsset === "1" && defaultcontraCash.key,
        row_number: maxRowNumber + 1,
      });
    }
    if (isShortcut === true || isShortcut2 === true) {
      arr[arr.length - 1].exchange_rate_ils = num;
      arr[arr.length - 1].currency_code = rate;
      arr[arr.length - 1].amount_ils =
        arr[arr.length - 1].amount * parseFloat(num);
      if (financialName === "financial_assets_in") {
        const sortArray = arr.sort((a: any, b: any) => a.row_number - b.row_number);
        arr = [...sortArray];
      }
      setConvertions({ ...convertions, [financialName]: arr });
      arr[arr.length - 1].amount &&
        calcSum(
          financialName === "financial_assets_in" ? setsumIn : setsumOut,
          arr
        );
      await setIsShortcut(false);
      isShortcut2 = false;
    } else {
      setIsShortcutRateOut(false);
      setIsShortcutRateIn(false);
    }
    await change(financialName, arr);
  };

  const addMultiScanRow = async () => {
    const defaultcontraCheque = contraCheck.find(
      (item: any) => item.is_default === true
    );

    let arr: any = [];
    arr = [...convertions.financial_assets_in];
    const lastElement = arr?.[arr?.length - 1];
    if ((allScanChecks.length > 1 || !checkDetails) && !lastElement.amount &&
      !lastElement.financial_asset_details?.reference_number &&
      !lastElement.financial_asset_details?.beneficiary &&
      !lastElement.financial_asset_details?.account_holder) {
      arr = arr.slice(0, -1)
    }
    let maxRowNumber = 0;
    if (arr.length > 0)
      maxRowNumber = Math.max.apply(
        Math,
        arr.map(function (item: any) {
          return item.row_number;
        })
      );
    if (allScanChecks.length > 1)
      allScanChecks.map((check: any, index: number) => {
        arr = [...arr, {
          currency_code: "USD",
          financial_asset_code: "3",
          amount: 0,
          exchange_rate_ils: gates && (1 / gates["USD"]).toFixed(2),
          contra_id_account: defaultcontraCheque?.key,
          amount_ils: 0,
          row_number: maxRowNumber + index + 1,
          financial_asset_details: {
            reference_number: check?.micr?.checkNumber,
            bank_account_number: check?.micr?.accountNumber,
            routing_number: check?.micr?.routingNumber,
            country_code: "US",
            cheque_date: moment().format("YYYY-MM-DD"),
            media: {
              media_face: { ...check?.mediaFace }, media_back: { ...check?.mediaBack }
            }
          }
        }];
      });
    else
      if (allScanChecks.length === 1) {
        let checkItem = {
          currency_code: "USD",
          financial_asset_code: "3",
          amount: 0,
          exchange_rate_ils: gates && (1 / gates["USD"]).toFixed(2),
          contra_id_account: defaultcontraCheque?.key,
          amount_ils: 0,
          row_number: checkDetails ? rowNumber : maxRowNumber + 1,
          financial_asset_details: {
            reference_number: allScanChecks[0]?.micr?.checkNumber,
            bank_account_number: allScanChecks[0]?.micr?.accountNumber,
            routing_number: allScanChecks[0]?.micr?.routingNumber,
            country_code: "US",
            cheque_date: moment().format("YYYY-MM-DD"),
            media: {
              media_face: { ...allScanChecks[0]?.mediaFace }, media_back: { ...allScanChecks[0]?.mediaBack }
            }
          }
        }
        if (checkDetails)
          arr = arr.map((item: any) => item.row_number === Number(rowNumber) ? checkItem : item);
        else arr = [...arr, checkItem];
      }
    
    const sortArray = arr.sort((a: any, b: any) => a.row_number - b.row_number);
    await change("financial_assets_in", sortArray);
    await dispatch(createTransaction("update", "exchange", datacConversion.entity_name.entity_name,
      { ...convertions, financial_assets_in: sortArray }, userCredentials, convertions.id_entity));
    setshowSpiner(false);
  }

  const calcSum = (setSum: any, arr: any, source?: any) => {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i]?.amount_ils ? arr[i]?.amount_ils : 0;
    }
    setSum(Number(sum.toFixed(2)));
  };

  const onChangeComboBoxIn = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string,
    id?: string
  ): void => {
    let val = option?.key;
    let key = id ? id : "currency_code";
    let copyEnumsDropDown: any = { ...enumsDropdown };
    if (val === "More" || val === "Less") {
      if (val === "More") {
        copyEnumsDropDown.currencyCode = copyEnumsDropDown?.allOfcurrency;
        setenumsDropdown(copyEnumsDropDown);
      }
      if (val === "Less") {
        copyEnumsDropDown.currencyCode = copyEnumsDropDown?.someOfcurrency;
        setenumsDropdown(copyEnumsDropDown);
      }
    } else addFinancialIn(key, val);
  };

  const addFinancialIn = (key: string, value: any, isBalance?: any) => {
    const [id, place, row_number] = key.split(",");
    let arr: any = [];
    let arr2: any = [];
    let rate: any = 0;
    let isaAmount = false;

    arr = [...convertions.financial_assets_in];
    arr2 = [...convertions.financial_assets_out];
    let index = arr.findIndex(
      (item: any) => Number(item.row_number) === Number(row_number)
    );
    let i = index;

    if (id === "financial_asset_code") {
      settableType("in");
      setRowNumber(row_number);
      if (value === "12") {
        let defaultcontraCreditCard = contraCreditCard.find(
          (item: any) => item.is_default === true
        );
        if (value !== arr[i]?.financial_asset_code && defaultcontraCreditCard) {
          arr[i].contra_id_account = defaultcontraCreditCard?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCreditCard.length === 1
        ) {
          arr[i].contra_id_account = contraCreditCard[0].key;
        }
        setCredit(true);
        settableType("financial_assets_in");

        // setCredit(true); settableType("financial_assets_in");
      }
      if (value === "9") {
        let defaultcontraBankAccount = contraBankAccounts.find(
          (item: any) => item.is_default === true
        );
        if (
          value !== arr[i]?.financial_asset_code &&
          defaultcontraBankAccount
        ) {
          arr[i].contra_id_account = defaultcontraBankAccount?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraBankAccounts.length === 1
        ) {
          arr[i].contra_id_account = contraBankAccounts[0].key;
        }
        setTransferFromCustomer(true);
        settableType("financial_assets_in");
      }

      if (value === "1") {
        settableType("financial_assets_in");
        let defaultcontraCash = contraCash.find(
          (item: any) => item.is_default === true
        );
        if (value !== arr[i]?.financial_asset_code && defaultcontraCash) {
          arr[i].contra_id_account = defaultcontraCash?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCash.length === 1
        ) {
          arr[i].contra_id_account = contraCash[0].key;
        }
        setcashPayment(true);
      }
      if (value === "3") {
        let defaultcontraCheque = contraCheck.find(
          (item: any) => item.is_default === true
        );
        if (value !== arr[i]?.financial_asset_code && defaultcontraCheque) {
          arr[i].contra_id_account = defaultcontraCheque?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCheck.length === 1
        ) {
          arr[i].contra_id_account = contraCheck[0].key;
        }
        setcheckDetails(true);
        settableType("financial_assets_in");
      }
      if (value === "18")
        arr[index].contra_id_account =
          conversionCustomer?.accounts[0].id_account;
    }
    let enter = true;
    rate = arr[i].amount_ils / arr[i].amount;
    if (
      id === "exchange_rate_ils" &&
      String(arr[i]?.exchange_rate_ils) === value
    ) {
      if (Number(rate.toFixed(2)) === Number(value)) {
        isaAmount = true;
        enter = false;
      }
    }

    if (id === "amount" && String(arr[i]?.amount) === value) {
      let rate2 = arr[i].amount_ils / rate;
      if (Number(rate2.toFixed(2)) === Number(value)) {
        isaAmount = true;
        enter = false;
      }
    }
    if (enter) {
      if (arr.length - 1 >= i) {
        let num = "0";
        if (id === "currency_code" && gates[value]) {
          let num1 = 1 / gates[value];
          num = num1.toFixed(2);
          if (value === 'ILS')
            num = '1'
          if (!num)
            num = '0'
        }
        if (id === "currency_code" && value === 'ILS') {
          num = '1'
        }
        let temp = {
          currency_code: id === "currency_code" ? value : arr[i]?.currency_code,
          financial_asset_code:
            id === "financial_asset_code"
              ? value
              : arr[i]?.financial_asset_code,
          financial_asset_details: arr[i]?.financial_asset_details,
          amount:
            id === "amount"
              ? value
                ? value === "0"
                  ? 0
                  : value
                : 0
              : arr[i]?.amount,
          exchange_rate_ils:
            id === "exchange_rate_ils"
              ? value
              : id === "currency_code"
                ? parseFloat(num)
                : arr[i]?.exchange_rate_ils,
          amount_ils:
            id === "amount"
              ? value * arr[i]?.exchange_rate_ils
              : id === "exchange_rate_ils"
                ? value * arr[i]?.amount
                : id === "currency_code"
                  ? arr[i]?.amount * parseFloat(num)
                  : arr[i]?.amount_ils,
          contra_id_account:
            id === "contra_id_account" ? value : arr[i]?.contra_id_account,
          row_number: arr[i]?.row_number,
        };
        arr[i] = temp;
        if (
          id === "amount" ||
          id === "exchange_rate_ils" ||
          id === "currency_code"
        ) {
          calcSum(setsumIn, arr);
        }

        if (id === "amount" && isBalance)
          arr[i].amount = Math.round(value * 100) / 100;
        if (id === "exchange_rate_ils" && isBalance)
          arr[i].exchange_rate_ils = Math.round(value * 100) / 100;
      }
      change("financial_assets_in", arr);
    }
    if (
      (id === "amount" && isaAmount) ||
      (id === "exchange_rate_ils" && isaAmount)
    )
      arr[i].exchange_rate_ils = Math.round(rate * 100) / 100;
  };
  const calculateBalance = (tableName: any) => {
    if (tableName === "in") {
      let arrOut = [...convertions.financial_assets_out];
      if (
        sumIn &&
        arrOut?.length === 1 &&
        arrOut[0].currency_code === "ILS" &&
        Number(arrOut[0].exchange_rate_ils) === 1
      ) {
        BalanceDeal(
          arrOut[0].amount,
          arrOut[0]?.exchange_rate_ils,
          "out",
          `amount,${0},${arrOut[0]?.row_number}`
        );
      }
    }
    if (tableName === "out") {
      let arrIn = [...convertions.financial_assets_in];
      if (
        sumOut &&
        arrIn?.length === 1 &&
        arrIn[0].currency_code === "ILS" &&
        Number(arrIn[0].exchange_rate_ils) === 1
      ) {
        BalanceDeal(
          arrIn[0].amount,
          arrIn[0]?.exchange_rate_ils,
          "in",
          `amount,${0},${arrIn[0]?.row_number}`
        );
      }
    }
  };
  const onChangeComboBoxOut = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string,
    id?: string
  ): void => {
    let val = option?.key;
    let key = id ? id : "currency_code";
    let copyEnumsDropDown: any = { ...enumsDropdown };
    if (val === "More" || val === "Less") {
      if (val === "More") {
        copyEnumsDropDown.currencyCode = copyEnumsDropDown?.allOfcurrency;
        setenumsDropdown(copyEnumsDropDown);
      }
      if (val === "Less") {
        copyEnumsDropDown.currencyCode = copyEnumsDropDown?.someOfcurrency;
        setenumsDropdown(copyEnumsDropDown);
      }
    } else addFinancialOut(key, val);
  };
  const addFinancialOut = (key: string, value: any, isBalance?: any) => {
    const [id, place, row_number] = key.split(",");
    let arr: any = [];
    let arr2: any = [];
    let rate: any = 0;
    let isaAmount = false;
    arr = [...convertions.financial_assets_out];
    arr2 = [...convertions.financial_assets_in];
    let index = arr.findIndex(
      (item: any) => Number(item.row_number) === Number(row_number)
    );
    let i = index;

    if (id === "financial_asset_code") {
      settableType("out");
      setRowNumber(row_number);

      if (value === "1") {
        settableType("financial_assets_out");
        let defaultcontraCash = contraCash.find(
          (item: any) => item.is_default === true
        );
        if (value !== arr[i]?.financial_asset_code && defaultcontraCash) {
          arr[i].contra_id_account = defaultcontraCash?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCash.length === 1
        ) {
          arr[i].contra_id_account = contraCash[0].key;
        }
        setcashPayment(true);
      }
      if (value === "12") {
        let defaultcontraCreditCard = contraCreditCard.find(
          (item: any) => item.is_default === true
        );
        if (value !== arr[i]?.financial_asset_code && defaultcontraCreditCard) {
          arr[i].contra_id_account = defaultcontraCreditCard?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCreditCard.length === 1
        ) {
          arr[i].contra_id_account = contraCreditCard[0].key;
        }
        setCredit(true);
        settableType("financial_assets_out");
      }
      if (value === "3") {
        let defaultcontraCheque = contraCheck.find(
          (item: any) => item.is_default === true
        );
        if (value !== arr[i]?.financial_asset_code && defaultcontraCheque) {
          arr[i].contra_id_account = defaultcontraCheque?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCheck.length === 1
        ) {
          arr[i].contra_id_account = contraCheck[0].key;
        }
        setcheckDetailsOut(true);
        settableType("financial_assets_out");
      }

      if (value === "9") {
        let defaultcontraBankAccount = contraBankAccounts.find(
          (item: any) => item.is_default === true
        );
        if (
          value !== arr[i]?.financial_asset_code &&
          defaultcontraBankAccount
        ) {
          arr[i].contra_id_account = defaultcontraBankAccount?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraBankAccounts.length === 1
        ) {
          arr[i].contra_id_account = contraBankAccounts[0].key;
        }
        setTransferFromCustomer(true);
        settableType("financial_assets_out");
      }
      if (value === "18")
        arr[index].contra_id_account =
          conversionCustomer?.accounts[0].id_account;
      if (value === "0") {
        let defaultcontraIncome = contraIncomes.find(
          (item: any) => item.is_default === true
        );
        if (
          value !== arr[i]?.financial_asset_code &&
          defaultcontraIncome
        ) {
          arr[i].contra_id_account = defaultcontraIncome?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraIncomes.length === 1
        ) {
          arr[i].contra_id_account = contraIncomes[0].key;
        }
        setIncomePayment(true);
        settableType("financial_assets_out");
      }
    }
    let enter = true;
    rate = arr[i]?.amount_ils / arr[i].amount;
    if (!rate)
      rate = 0;
    if (
      id === "exchange_rate_ils" &&
      String(arr[i]?.exchange_rate_ils) === value
    ) {
      if (Number(rate.toFixed(2)) === Number(value)) {
        isaAmount = true;
        enter = false;
      }
    }
    if (id === "amount" && String(arr[i]?.amount) === value) {
      let rate2 = arr[i].amount_ils / rate;
      if (Number(rate2.toFixed(2)) === Number(value)) {
        enter = false;
        isaAmount = true;
      }
    }
    if (enter) {
      if (arr.length - 1 >= i) {
        let num = "0";
        if (id === "currency_code" && gates[value]) {
          let num1 = 1 / gates[value];
          num = num1.toFixed(2);
          if (value === 'ILS')
            num = '1'
          if (!num)
            num = '0'
        }
        if (id === "currency_code" && value === 'ILS') {
          num = '1'
        }
        let temp = {
          currency_code: id === "currency_code" ? value : arr[i]?.currency_code,
          financial_asset_code:
            id === "financial_asset_code"
              ? value
              : arr[i]?.financial_asset_code,
          financial_asset_details: arr[i]?.financial_asset_details,
          amount: id === "amount" ? (value ? value : 0) : arr[i]?.amount ? 0 : arr[i]?.amount,
          exchange_rate_ils:
            id === "exchange_rate_ils"
              ? value
              : id === "currency_code"
                ? parseFloat(num)
                : arr[i]?.exchange_rate_ils,
          amount_ils:
            id === "amount"
              ? value * arr[i]?.exchange_rate_ils
              : id === "exchange_rate_ils"
                ? value * arr[i]?.amount
                : id === "currency_code"
                  ? arr[i]?.amount * parseFloat(num)
                  : arr[i]?.amount_ils,
          contra_id_account:
            id === "contra_id_account" ? value : arr[i]?.contra_id_account,
          row_number: arr[i]?.row_number,
        };
        arr[i] = temp;
        if (
          ((id === "exchange_rate_ils" && arr[i].amount === 0) ||
            id === "currency_code") &&
          arr[i].amount === 0
        ) {
          let balance =
            sumIn -
            (sumOut - arr[i].amount * arr[i].exchange_rate_ils) /
            arr[i].exchange_rate_ils;
          if (!balance)
            balance = 0;
          arr[i].amount = Math.round(balance * 100) / 100;
          arr[i].amount_ils = balance * arr[i].exchange_rate_ils;
        }
        if (
          id === "amount" ||
          id === "exchange_rate_ils" ||
          id === "currency_code"
        ) {
          calcSum(setsumOut, arr);
        }
      }
      if (id === "amount" && isBalance)
        arr[i].amount = Math.round(value * 100) / 100;
      if (id === "exchange_rate_ils" && isBalance)
        arr[i].exchange_rate_ils = Math.round(value * 100) / 100;
      change("financial_assets_out", arr);
    }
    if (
      (id === "amount" && isaAmount) ||
      (id === "exchange_rate_ils" && isaAmount)
    )
      arr[i].exchange_rate_ils = Math.round(rate * 100) / 100;
  };
  const changeRate = async (event: any, item: any) => {
    const [tableName, rate] = event?.target?.id?.split(",");
    let arr: any = [];
    num1 = 1 / gates[rate];
    num = num1.toFixed(2);
    let allCurrencies = [...enumsDropdown.currencyCode];
    let addarr: any = [];
    let c = {
      key: item.key,
      text: item.text,
      currency_symbol: item.currency_symbol,
      is_default: false,
    };
    const found = allCurrencies.some((el) => el.key === c.key);
    if (!found) addarr.push(c);
    addarr = addarr.concat(allCurrencies);
    setCurrenciesEnum(addarr);
    addarr = [];
    if (tableName === "financial_assets_in") {
      arr = [...convertions.financial_assets_in];
      setIsShortcutRateIn(true);
      setIsShortcutRateOut(false);
    } else {
      arr = [...convertions.financial_assets_out];
      setIsShortcutRateOut(true);
      setIsShortcutRateIn(false);
    }
    if (arr[arr.length - 1]?.amount_ils > 0) {
      await setIsShortcut(true);
      isShortcut2 = true;
      await addRow(tableName, arr[arr.length - 1].row_number, rate);
      setFocus(tableName, convertions, arr[arr.length - 1].row_number);
    } else {
      arr[arr.length - 1].exchange_rate_ils = num;
      arr[arr.length - 1].currency_code = rate;
      arr[arr.length - 1].amount_ils =
        arr[arr.length - 1].amount * parseFloat(num);
      if (
        arr[arr.length - 1].amount === 0 &&
        tableName === "financial_assets_out" &&
        sumIn - sumOut > 0
      ) {
        let balance =
          (sumIn -
            (sumOut -
              arr[arr.length - 1].amount *
              arr[arr.length - 1].exchange_rate_ils)) /
          arr[arr.length - 1].exchange_rate_ils;
        arr[arr.length - 1].amount = Math.round(balance * 100) / 100;
        arr[arr.length - 1].amount_ils =
          balance * arr[arr.length - 1].exchange_rate_ils;
      }
      if (tableName === 'financial_assets_in') {
        const sortArray = arr.sort((a: any, b: any) => a.row_number - b.row_number);
        arr = [...sortArray]
      }
      setConvertions({ ...convertions, [tableName]: arr });
      arr[arr.length - 1].amount &&
        calcSum(
          tableName === "financial_assets_in" ? setsumIn : setsumOut,
          arr,
          "shortCuts"
        );
      setFocus(tableName, convertions, arr.length - 1);
    }
  };
  const setFocus = (tableType: any, convertions: any, rowNumber: any) => {
    const index = convertions[tableType]?.findIndex(
      (item: any) => Number(item.row_number) === Number(rowNumber)
    );
    if (!convertions[tableType][index + 1]?.amount) {
      if (tableType.includes("_assets_in")) {
        let nodes = document.querySelectorAll(".currency");
        (nodes[index + 1] as HTMLElement)?.focus();
      } else {
        if (tableType.includes("out")) {
          let nodes = document.querySelectorAll(".amount-out");
          (nodes[index + 1] as HTMLElement)?.focus();
        }
      }
    } else {
      if (tableType.includes("out")) {
        let nodes = document.querySelectorAll(".amount-out");
        (nodes[index + 1] as HTMLElement)?.focus();
      } else {
        let nodes = document.querySelectorAll(".currency");
        (nodes[(index + 1) * 2 + 1] as HTMLElement)?.focus();
      }
    }
  };

  // const checkRequiredFields = (element: any) => {
  //   if (
  //     element?.financial_asset_code === "3" &&
  //     (!element?.financial_asset_details?.cheque_date ||
  //       !element?.financial_asset_details?.reference_number ||
  //       !element.amount)
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };
  const displayDetailsTransaction = (c: any, financialName: string) => {

    setRowNumber(c?.row_number);
    settableType(financialName);
    if (c?.financial_asset_code === "12") {
      setCredit(true);
    }
    if (c?.financial_asset_code === "9") {
      setTransferFromCustomer(true);
    }
    if (c?.financial_asset_code === "1") {
      setcashPayment(true);
    }
    if (c?.financial_asset_code === "0") {
      setIncomePayment(true);
    }
    if (
      c?.financial_asset_code === "3" &&
      financialName === "financial_assets_in"
    ) {
      sessionStorage.setItem("enableMultiScanCheck", "false")
      let index: any = convertions["financial_assets_in"]?.findIndex(
        (item: any) => Number(item.row_number) === Number(c?.row_number)
      );
      let arr: any = [...convertions["financial_assets_in"]];
      setcheckDetails(true);
      arr[index].financial_asset_code = "3";
      const sortArray = arr.sort((a: any, b: any) => a.row_number - b.row_number);
      setConvertions((convertions) => ({
        ...convertions,
        financial_assets_in: sortArray,
      }));
      if (
        !c.financial_asset_details?.media?.media_face?.id_media &&
        !c.financial_asset_details?.media?.media_back?.id_media &&
        !c.financial_asset_details?.id_media_face &&
        !c.financial_asset_details?.id_media_back &&
        settings?.cheque_scanning_popup !== "enter_details_manually"
      )
        setScanButton(true);
      SetIsIconScanButton(true);
    }
    if (
      c?.financial_asset_code === "3" &&
      financialName === "financial_assets_out"
    ) {
      setcheckDetailsOut(true);
    }
  };

  const handleMultiCheck = async () => {
    if (allScanChecks[0].mediaBack) {
      await addMultiScanRow();
    }
    else {
      setshowSpiner(true);
      await dispatch(uploadMultiScanChecks(convertions.id_entity, userCredentials, allScanChecks, setAllScanChecks,checkType));
    }
  }

  useEffect(() => {
    if (allScanChecks?.length > 0) {
      handleMultiCheck();
    }
  }, [allScanChecks]);

  // const printCopy = () => {
  //     document?.getElementById("print")?.click();
  // }

  return (
    <div
      className={`${checkPermissionLevelOfPage(credentials, location) === "RW"
        ? "page-read"
        : "page-read-only"
        }`}
    >
      {state === "showCompleted" ? (
        ""
      ) : (
        <>
          <HeaderUtils
            title={t("foreignCurrency")}
            subTitle={"false"}
            editBtn={false}
            uploadBtn={false}
            disabledSave={true}
            showSelectConvertions={true}
          />{" "}
          <br></br>
          <br></br>
        </>
      )}
      {showDialogFinishFail && (
        <DialogMessages
          setansDelete={setshowDialogFinishFail}
          setshowModalDelete={setshowDialogFinishFail}
          dialogType={"FinishDealFail"}
        />
      )}
      {showDialogSaveData && (
        <DialogMessages
          setansDelete={setShowDialogSaveData}
          setshowModalDelete={setShowDialogSaveData}
          dialogType={"saveConversionData"}
          setSaveDataConversion={setSaveDataConversion}
        />
      )}
      {saveDataFailed && (
        <DialogMessages
          setansDelete={setSaveDataFailed}
          setshowModalDelete={setSaveDataFailed}
          dialogType={"saveConversionDataFailed"}
          setContinueAfterFailed={setContinueAfterFailed}
        />
      )}
      {dialogBeforeChangeDraft && (
        <DialogMessages
          setansDelete={setDialogBeforeChangeDraft}
          setshowModalDelete={setDialogBeforeChangeDraft}
          dialogType={"saveConversionData"}
          setSaveDataConversion={setSaveBeforeChangeDraft}
        />
      )}
      {beforeDelete && (
        <DialogMessages
          setansDelete={setbeforeDelete}
          setifDelete={setifDelete}
          setshowModalDelete={setbeforeDelete}
          dialogType={"delete"}
        />
      )}

      {serverMessageError && (
        <DialogMessages
          setansDelete={setServerMessageError}
          setshowModalDelete={setServerMessageError}
          subText={messageError}
          title={titleMessageError}
          dialogType={"dialogMessage"}
          confirm={conversionCustomer?.note ? true : false}
        />
      )}

      {/* {datacConversion?.status_id==="completed"&& datacConversion?.document_html&& handlePrint} */}
      <p id="print" className="button" onClick={handlePrint}>
        {" "}
      </p>
      {/* {datacConversion?.document_html && = useReactToPrint({
        content: () => componentRef.current,

    }) */}

      <div style={{ display: "none" }}>
        <div
          ref={componentRef}
          dangerouslySetInnerHTML={{
            __html: datacConversion?.transaction_document?.document_html,
          }}
        />
      </div>
      {docAction && (
        <DialogMessages
          setansDelete={setDocAction}
          setshowModalDelete={setDocAction}
          subText={t("actionCannotCompletedCustomerDocuments")}
          title={t("note")}
          dialogType={"dialogMessage"}
          setSaveDataConversion={setAddDoc}
        />
      )}

      {insiderAction && (
        <DialogMessages
          setansDelete={setInsiderAction}
          setshowModalDelete={setInsiderAction}
          subText={t("actionCannotCompletedControllingOwners")}
          title={t("note")}
          dialogType={"dialogMessage"}
          setSaveDataConversion={setAddInsider}
        />
      )}

      {showSpiner && (
        <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />
      )}
      {showDialogSave && (
        <DialogMessages
          setansDelete={setShowDialogSave}
          setshowModalDelete={setShowDialogSave}
          dialogType={"create"}
        />
      )}
      {showDialogFailed && (
        <DialogMessages
          setansDelete={setShowDialogFailed}
          setshowModalDelete={setShowDialogFailed}
          dialogType={"failed"}
        />
      )}
      {showDialogDelete && (
        <DialogMessages
          setansDelete={setShowDialogDelete}
          subText={messageError}
          title={t("error")}
          setshowModalDelete={setShowDialogDelete}
          dialogType={"dialogMessage"}
          setSaveDataConversion={setSaveDeleteRowActoin}
        />
      )}
      {TransferFromCustomer && (
        <CustomDialogTransferFromCustomer
          typeAction="exchange"
          conversionCustomer={conversionCustomer}
          rowNumber={rowNumber}
          tableType={tableType}
          userCredentials={userCredentials}
          contraBankAccounts={contraBankAccounts}
          setshowSpiner={setshowSpiner}
          setConvertions={setConvertions}
          convertions={convertions}
          setShowDialog={setTransferFromCustomer}
        />
      )}
      {credit ? (
        <CustomDialogCreditFromCustomer
          contraCreditCard={contraCreditCard}
          typeAction="exchange"
          conversionCustomer={conversionCustomer}
          /*contraCreditCard={contraCreditCard}*/ setConvertions={
            setConvertions
          }
          convertions={convertions}
          rowNumber={rowNumber}
          tableType={tableType}
          setShowDialog={setCredit}
          setshowSpiner={setshowSpiner}
          userCredentials={userCredentials}
        />
      ) : (
        ""
      )}
      {checkTable ? (
        <CustomDialogCheckTable
          conversions={true}
          setShowDialog={setcheckTable}
        />
      ) : (
        ""
      )}
      {checkDetails ? (
        <CheckDetailsDialog
          setAllScanChecks={setAllScanChecks}
          scanButton={scanButton}
          setScanButton={setScanButton}
          contraCheck={contraCheck}
          emptyRequiredFields={emptyRequiredFields}
          ifChanges={ifChanges}
          typeAction="exchange"
          setIfChanges={setIfChanges}
          conversionCustomer={conversionCustomer}
          isIconScanButton={isIconScanButton}
          SetIsIconScanButton={SetIsIconScanButton}
          setConvertions={setConvertions}
          convertions={convertions}
          rowNumber={rowNumber}
          tableType={tableType}
          setShowDialog={setcheckDetails}
          setsumIn={setsumIn}
          userCredentials={userCredentials}
        />
      ) : (
        ""
      )}
      {cashPayment ? (
        <CashPaymentDialog
          contraCash={contraCash}
          setIfChanges={setIfChanges}
          setConvertions={setConvertions}
          convertions={convertions}
          rowNumber={rowNumber}
          tableType={tableType}
          setShowDialog={setcashPayment}
        />
      ) : (
        ""
      )}
      {incomePayment && (
        <IncomePaymentDialog
          contraIncomes={contraIncomes}
          setIfChanges={setIfChanges}
          setConvertions={setConvertions}
          convertions={convertions}
          rowNumber={rowNumber}
          tableType={tableType}
          setShowDialog={setIncomePayment}
        />
      )}
      {clickScan && (
        <div>
          <ScanCheck
            setAllScanChecks={setAllScanChecks}
            setIsFinishScan={() => { }}
            isIconScanButton={isIconScanButton}
            bankEnum={enumsDropdown?.bank}
            updateBankAccount={{}}
            setArrScanChecksImgs={() => { }}
            setArrInformationScan={() => { }}
            arrInformationScan={[]}
            setCheckDeatils={() => { }}
            checkDeatils={{}}
            setSearchData={() => { }}
            setUpdateBankAccount={() => { }}
            setShowDialog={setClickScan}
            idEntity={convertions?.id_entity}
            setCheckType={setCheckType}
          />
        </div>
      )}
      {checkDetailsOut ? (
        <CheckToCustomerDialog
          typeAction="exchange"
          conversionCustomer={conversionCustomer}
          setConvertions={setConvertions}
          convertions={convertions}
          rowNumber={rowNumber}
          tableType={tableType}
          setShowDialog={setcheckDetailsOut}
          setsumIn={setsumIn}
          userCredentials={userCredentials}
        />
      ) : (
        ""
      )}
      {state !== "showCompleted" ? (
        <Subtitle title={t("currencyExchange")} />
      ) : (
        <div className="dialogSubTitle">{t("currencyExchange")}</div>
      )}
      <div
        className={`${state !== "showCompleted" ? `content-div-${dir}` : ""}`}
      >
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "78vw",
          }}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <EntityDetails
              setSelectedDraft={setSelectedDraft}
              selectedDraft={selectedDraft}
              listInsiders={listInsiders}
              setListInsiders={setListInsiders}
              setActionCustomerId={conversionCustomerId}
              typeAction="exchange"
              salvo={true}
              ifChanges={ifChanges}
              setIfChanges={setIfChanges}
              actions={convertions}
              setActions={setConvertions}
              readOnly={readOnly}
              setReadOnly={setreadOnly}
              setSelectConversionList={setSelectConversionList}
              selectConversionList={selectConversionList}
              setSearchCustomer={setConversionCustomer}
              searchCustomer={conversionCustomer}
            />

            {datacConversion?.status_id !== "completed" &&
              state !== "showCompleted" && (
                <div
                  className="OngatesConversions"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Gates
                    gates={gates}
                    setGates={setGates}
                    listCurrency={listCurrency}
                  ></Gates>
                </div>
              )}
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <p className="title-text">{t("actionDetails")}</p>
          <hr className="hr" style={{ width: "78vw" }}></hr>
          <div className="wrapper-collateralPlan" style={{ width: "78vw" }}>
            <div
              className={`warpper-fields-rightCon-${dir}`}
              style={{ width: "50%" }}
            >
              <div style={{ width: "100%" }}>
                <div className="titleC">
                  <p className="title-text boldC">{t("in")}</p>
                  <div className={`wrapKinds-${dir}`}>
                    {listCurrency.map((item: any, index: any) => (
                      <label
                        key={index}
                        title={item?.text}
                        onClick={(e: any) => {
                          !readOnly && changeRate(e, item);
                        }}
                        id={`financial_assets_in,${item.key}`}
                        className={
                          readOnly
                            ? `disabled iconCurrency-${dir}`
                            : `iconCurrency-${dir} label-convertion-${dir}`
                        }
                      >
                        {item?.currency_symbol}
                      </label>
                    ))}

                    <DefaultButton
                      className="button edit-button"
                      onClick={() => { setClickScan(true); sessionStorage.setItem("enableMultiScanCheck", "true") }}
                      checked={false}
                      text={t("scanning")}
                      iconProps={Icons.print}
                      name="update"
                      disabled={readOnly}
                    />
                  </div>
                </div>
              </div>
              <hr className="hr" style={{ width: "100%", height: "2px" }}></hr>

              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <div
                    className="title-convertion"
                    style={{ backgroundColor: "#E1E1E1" }}
                  >
                    <p
                      className={`padding-input-${dir}`}
                      style={{ width: "21%", marginBottom: "0" }}
                    >
                      {t("currency")}
                    </p>
                    <p
                      className={`padding-input-${dir}`}
                      style={{ width: "20%", marginBottom: "0" }}
                    >
                      {t("type")}
                    </p>
                    <p
                      className={`padding-input-${dir}`}
                      style={{ width: "4.5%", marginBottom: "0" }}
                    >
                      {" "}
                    </p>
                    <p
                      className={`padding-input-${dir}`}
                      style={{ width: "12%", marginBottom: "0" }}
                    >
                      {t("gate")}
                    </p>
                    <p
                      className={`padding-input-${dir}`}
                      style={{ width: "18%", marginBottom: "0" }}
                    >
                      {t("amount")}
                    </p>
                    <p
                      className={`padding-input-${dir}`}
                      style={{ width: "16.5%", marginBottom: "0" }}
                    >
                      {t("totalILS")}
                    </p>
                  </div>
                </div>
                <hr
                  className="hr"
                  style={{ width: "100%", height: "2px", marginBottom: "0" }}
                ></hr>
                {convertions.financial_assets_in?.map((c, index) => (
                  <div key={index} style={{ width: "100%" }}>
                    <div className="inlineB" style={{ marginTop: "7px" }}>
                      <div style={{ width: "21%" }}>
                        {!readOnly ? (
                          <ComboBox
                            styles={comboStyles}
                            id={`currency_code,${c.row_number}`}
                            label={t("")}
                            required={true}
                            selectedKey={c?.currency_code}
                            autoComplete="on"
                            allowFreeform={true}
                            options={enumsDropdown.currencyCode}
                            onChange={(
                              event: any,
                              option: any,
                              index: any,
                              value: any
                            ) =>
                              onChangeComboBoxIn(
                                event,
                                option,
                                index,
                                value,
                                `currency_code,${index},${c.row_number}`
                              )
                            }
                          />
                        ) : (
                          <CustomDropdown
                            IsTabIndex={
                              c.row_number ===
                                convertions.financial_assets_in[
                                  convertions.financial_assets_in.length - 1
                                ].row_number
                                ? isShortcutRateIn
                                : false
                            }
                            readOnly={readOnly}
                            onKeyDown={(e: any) =>
                              e.key === "Tab" && PlusIndexTab()
                            }
                            otherInputId={""}
                            hasOtherValue={false}
                            options={enumsDropdown.currencyCode}
                            label={t("")}
                            onChange={addFinancialIn}
                            selectedKey={c?.currency_code}
                            id={`currency_code,${index},${c.row_number}`}
                            othertextInput={t("")}
                          />
                        )}
                      </div>
                      <div style={{ width: "20%" }}>
                        <CustomDropdown
                          IsTabIndex={
                            c.row_number ===
                              convertions.financial_assets_in[
                                convertions.financial_assets_in.length - 1
                              ].row_number
                              ? isShortcutRateIn
                              : false
                          }
                          onKeyDown={(e: any) =>
                            e.key === "Tab" && PlusIndexTab()
                          }
                          requiredField={
                            (emptyInContraAccount && !c.contra_id_account) ||
                              (emptyRequiredFields && checkRequiredFields(c.financial_asset_code, c))
                              ? true
                              : false
                          }
                          readOnly={readOnly}
                          otherInputId={""}
                          hasOtherValue={false}
                          options={enumDropdownIn}
                          label={t("")}
                          onChange={addFinancialIn}
                          selectedKey={
                            c?.financial_asset_code === "10"
                              ? "9"
                              : c?.financial_asset_code
                          }
                          id={`financial_asset_code,${index},${c.row_number}`}
                          othertextInput={t("")}
                        />
                      </div>
                      <div
                        className="scan-div-con"
                        style={{
                          color: "blue",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          title="הצג פרטים"
                          onClick={() => {
                            !readOnly &&
                              displayDetailsTransaction(
                                c,
                                "financial_assets_in"
                              );
                          }}
                        >
                          [...]
                        </i>
                      </div>
                      <CurrencyInput
                        className={`currency-input padding-currency-${dir}`}
                        id={`exchange_rate_ils,${index},${c.row_number}`}
                        name={`exchange_rate_ils,${index},${c.row_number}`}
                        placeholder="0.00"
                        tabIndex={-1}
                        value={c?.exchange_rate_ils}
                        readOnly={
                          c?.currency_code === defaultCurrencyCode
                            ? true
                            : readOnly
                        }
                        style={{
                          width: "12%",
                          backgroundColor: readOnly ? "#FAF9F8" : "white",
                        }}
                        decimalsLimit={2}
                        onValueChange={(value, name) =>
                          addFinancialIn(name || "", value)
                        }
                      />

                      <p
                        className={`dotBalance-${dir}`}
                        onClick={() =>
                          c?.amount > 0 &&
                          BalanceDealByRate(
                            c?.amount,
                            c?.amount_ils,
                            "in",
                            `exchange_rate_ils,${index},${c?.row_number}`
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faDotCircle}
                          title={t("balanceTransaction")}
                          onKeyDown={(e) =>
                            e.key === "Enter" &&
                            c?.amount > 0 &&
                            BalanceDealByRate(
                              c?.amount,
                              c?.amount_ils,
                              "in",
                              `exchange_rate_ils,${index},${c?.row_number}`
                            )
                          }
                          className="iconBalance"
                        />
                      </p>
                      <CurrencyInput
                        className={`currency-input padding-currency-${dir} currency`}
                        id={`amount,${index},${c?.row_number}`}
                        name={`amount,${index},${c?.row_number}`}
                        placeholder="0.00"
                        autoFocus={true}
                        // convertions.financial_assets_out?.length
                        onBlur={() =>
                          convertions.financial_assets_out?.length === 1 &&
                          calculateBalance("in")
                        }
                        value={c?.amount}
                        readOnly={readOnly}
                        required={
                          emptyInAmount && !Number(c?.amount) ? true : false
                        }
                        style={
                          emptyInAmount && !Number(c?.amount)
                            ? {
                              width: "18%",
                              backgroundColor: readOnly ? "#FAF9F8" : "white",
                              border: "3px solid #db3838",
                            }
                            : {
                              width: "18%",
                              backgroundColor: readOnly ? "#FAF9F8" : "white",
                            }
                        }
                        decimalsLimit={2}
                        onValueChange={(value, name) =>
                          addFinancialIn(name || "", value)
                        }
                      />
                      <p
                        className={`balance-${dir}`}
                        onClick={() =>
                          c?.exchange_rate_ils &&
                          BalanceDeal(
                            c?.amount,
                            c?.exchange_rate_ils,
                            "in",
                            `amount,${index},${c?.row_number}`
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faCalculator}
                          title={t("balanceTransaction")}
                          onKeyDown={(e) =>
                            e.key === "Enter" &&
                            c?.exchange_rate_ils &&
                            BalanceDeal(
                              c?.amount,
                              c?.exchange_rate_ils,
                              "in",
                              `amount,${index},${c?.row_number}`
                            )
                          }
                          className="iconBalance"
                        />
                      </p>
                      <div style={{ width: "16.5%" }}>
                        <CustomTextField
                          label={""}
                          value={
                            Number(c?.amount_ils)
                              ? Number(c?.amount_ils)
                                .toFixed(2)
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : 0
                          }
                          readOnly={true}
                          onChange={addFinancialIn}
                          styleObj={true}
                          id={`amount_ils,${index},${c.row_number}`}
                        />
                      </div>

                      <p
                        style={{
                          display: "grid",
                          paddingTop: "0",
                          textAlign: "center",
                          lineHeight: "0.8",
                          marginBottom: "0",
                          width: "fit-content",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className={`circle-icon-${dir}`}
                          onClick={() => removeFObject(c.row_number)}
                        />
                        {c.amount_ils > 0 && (
                          <FontAwesomeIcon
                            onKeyDown={(e) =>
                              e.key === "Enter" &&
                              addRow("financial_assets_in", c.row_number)
                            }
                            className={
                              c.amount_ils > 0
                                ? `plus-icon-${dir}`
                                : "iconDisabled"
                            }
                            icon={faPlusCircle}
                            title={t("addRow")}
                            onClick={() => {
                              addRow("financial_assets_in", c.row_number);
                            }}
                          />
                        )}
                      </p>

                    </div>
                  </div>
                ))}
                <hr
                  className="hr"
                  style={{ width: "100%", marginTop: "3px", marginBottom: "0" }}
                ></hr>
                <div>
                  <div className={`calc-convertion-${dir}`}>
                    {sumIn !== 0 && (
                      <div className={`title-calc-convertion-${dir}`}>
                        {t("total")}:
                      </div>
                    )}
                    {sumIn !== 0 && (
                      <div className={`total-calc-convertion-1-${dir}`}>
                        {sumIn
                          ?.toFixed(2)
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        {t("ILS")}
                      </div>
                    )}
                    {sumIn === 0 && (
                      <div className={`title-calc-convertion-${dir}`}>
                        {t("total")}:
                      </div>
                    )}
                    {sumIn === 0 && (
                      <div className={`total-calc-convertion-1-${dir}`}>
                        0.00 {t("ILS")}
                      </div>
                    )}
                  </div>
                  <div className={`calc-convertion-${dir}`}>
                    {Number((sumIn - sumOut).toFixed(2)) < 0 && (
                      <div
                        className={`title-calc-difference-convertion-${dir}`}
                      >
                        {t("difference")}:
                      </div>
                    )}
                    {Number((sumIn - sumOut).toFixed(2)) < 0 && (
                      <div
                        className={`total-calc-difference-convertion-1-${dir}`}
                      >
                        {(-1 * (sumIn - sumOut))
                          .toFixed(2)
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        {t("ILS")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={`warpper-fields-leftCon-${dir} div-out-${dir}`}>
              <div style={{ width: "100%" }}>
                <div className="titleC">
                  <p className="title-text boldC" style={{ width: "33%" }}>
                    {t("out")}
                  </p>

                  <div className={`wrapKinds-${dir}`}>
                    {listCurrency.map((item: any, index: any) => (
                      <label
                        key={index}
                        title={item?.text}
                        onClick={(e: any) => {
                          !readOnly && changeRate(e, item);
                        }}
                        id={`financial_assets_out,${item.key}`}
                        className={
                          readOnly
                            ? `disabled iconCurrency-${dir}`
                            : `iconCurrency-${dir} label-convertion2-${dir}`
                        }
                      >
                        {item?.currency_symbol}

                      </label>
                    ))}

                  </div>
                </div>
              </div>
              <hr className="hr" style={{ width: "100%", height: "2px" }}></hr>
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <div
                    className="title-convertion"
                    style={{ backgroundColor: "#E1E1E1" }}
                  >
                    <p
                      className={`padding-input-${dir}`}
                      style={{ width: "21%", marginBottom: "0" }}
                    >
                      {t("currency")}
                    </p>
                    <p
                      className={`padding-input-${dir}`}
                      style={{ width: "20%", marginBottom: "0" }}
                    >
                      {t("type")}
                    </p>
                    <p
                      className={`padding-input-${dir}`}
                      style={{ width: "4.5%", marginBottom: "0" }}
                    >
                      {" "}
                    </p>
                    <p
                      className={`padding-input-${dir}`}
                      style={{ width: "12%", marginBottom: "0" }}
                    >
                      {t("gate")}
                    </p>
                    <p
                      className={`padding-input-${dir}`}
                      style={{ width: "18%", marginBottom: "0" }}
                    >
                      {t("amount")}
                    </p>
                    <p
                      className={`padding-input-${dir}`}
                      style={{ width: "16.5%", marginBottom: "0" }}
                    >
                      {t("totalILS")}
                    </p>
                  </div>
                </div>
                <hr
                  className="hr"
                  style={{ width: "100%", height: "2px", marginBottom: "0" }}
                ></hr>
                {convertions.financial_assets_out?.map((c, index) => (
                  <div key={index} style={{ width: "100%" }}>
                    <div className="inlineB" style={{ marginTop: "7px" }}>
                      <div style={{ width: "21%" }}>
                        {!readOnly ? (
                          <ComboBox
                            id={`currency_code,${c.row_number}`}
                            label={t("")}
                            required={true}
                            selectedKey={c?.currency_code}
                            autoComplete="on"
                            styles={comboStyles}
                            // styles={showTextRequiredFiles && !address.City ? requiredFieldStyle : customStyles}
                            allowFreeform={true}
                            options={enumsDropdown.currencyCode}
                            onChange={(
                              event: any,
                              option: any,
                              index: any,
                              value: any
                            ) =>
                              onChangeComboBoxOut(
                                event,
                                option,
                                index,
                                value,
                                `currency_code,${index},${c.row_number}`
                              )
                            }
                          />
                        ) : (
                          <CustomDropdown
                            IsTabIndex={
                              c.row_number ===
                                convertions.financial_assets_out[
                                  convertions.financial_assets_out.length - 1
                                ].row_number
                                ? isShortcutRateOut
                                : false
                            }
                            readOnly={readOnly}
                            otherInputId={""}
                            hasOtherValue={false}
                            options={enumsDropdown.currencyCode}
                            label={t("")}
                            onChange={addFinancialOut}
                            selectedKey={c?.currency_code}
                            id={`currency_code,${index},${c.row_number}`}
                            othertextInput={t("")}
                          />
                        )}
                      </div>
                      <div style={{ width: "20%" }}>
                        <CustomDropdown
                          IsTabIndex={
                            c.row_number ===
                              convertions.financial_assets_out[
                                convertions.financial_assets_out.length - 1
                              ].row_number
                              ? isShortcutRateOut
                              : false
                          }
                          requiredField={
                            (emptyOutContraAccount && !c.contra_id_account) ||
                              (emptyRequiredFields && checkRequiredFields(c.financial_asset_code, c))
                              ? true
                              : false
                          }
                          readOnly={readOnly}
                          otherInputId={""}
                          hasOtherValue={false}
                          options={enumDropdownOut}
                          label={t("")}
                          onChange={addFinancialOut}
                          selectedKey={
                            c?.financial_asset_code === "10"
                              ? "9"
                              : c?.financial_asset_code
                          }
                          id={`financial_asset_code,${index},${c.row_number}`}
                          othertextInput={t("")}
                        />
                      </div>
                      <div
                        className="scan-div-con"
                        style={{
                          color: "blue",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          title="הצג פרטים"
                          onClick={() => {
                            !readOnly &&
                              displayDetailsTransaction(
                                c,
                                "financial_assets_out"
                              );
                          }}
                        >
                          [...]
                        </i>
                      </div>
                      <CurrencyInput
                        className={`currency-input padding-currency-${dir}`}
                        id={`exchange_rate_ils,${index},${c.row_number}`}
                        name={`exchange_rate_ils,${index},${c.row_number}`}
                        placeholder="0.00"
                        tabIndex={-1}
                        value={c?.exchange_rate_ils}
                        readOnly={
                          c?.currency_code === defaultCurrencyCode
                            ? true
                            : readOnly
                        }
                        style={{
                          width: "12%",
                          backgroundColor: readOnly ? "#FAF9F8" : "white",
                        }}
                        decimalsLimit={2}
                        // decimalScale={2}
                        onValueChange={(value, name) =>
                          addFinancialOut(name || "", value)
                        }
                      />
                      <p
                        className={`dotBalancel-${dir}`}
                        onClick={() =>
                          c?.amount > 0 &&
                          BalanceDealByRate(
                            c?.amount,
                            c?.amount_ils,
                            "out",
                            `exchange_rate_ils,${index},${c?.row_number}`
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faDotCircle}
                          title={t("blanceTransaction")}
                          onKeyDown={(e) =>
                            e.key === "Enter" &&
                            c?.amount > 0 &&
                            BalanceDealByRate(
                              c?.amount,
                              c?.amount_ils,
                              "out",
                              `exchange_rate_ils,${index},${c?.row_number}`
                            )
                          }
                          className="iconBalance"
                        />
                      </p>
                      <CurrencyInput
                        id={`amount,${index},${c.row_number}`}
                        name={`amount,${index},${c.row_number}`}
                        placeholder="0.00"
                        className={`currency-input padding-currency-${dir} amount-out`}
                        onBlur={() =>
                          convertions.financial_assets_out?.length === 1 &&
                          calculateBalance("out")
                        }
                        value={c?.amount}
                        readOnly={readOnly}
                        required={
                          emptyOutAmount && !Number(c?.amount) ? true : false
                        }
                        style={
                          emptyInAmount && !Number(c?.amount)
                            ? {
                              width: "17%",
                              backgroundColor: readOnly ? "#FAF9F8" : "white",
                              border: "3px solid #db3838",
                            }
                            : {
                              width: "18%",
                              backgroundColor: readOnly ? "#FAF9F8" : "white",
                            }
                        }
                        decimalsLimit={2}
                        onValueChange={(value, name) =>
                          addFinancialOut(name || "", value)
                        }
                      />
                      <p
                        className={`balance-out-${dir}`}
                        onClick={() =>
                          c?.exchange_rate_ils &&
                          BalanceDeal(
                            c?.amount,
                            c?.exchange_rate_ils,
                            "out",
                            `amount,${index},${c?.row_number}`
                          )
                        }
                      >
                        <FontAwesomeIcon
                          onKeyDown={(e) =>
                            e.key === "Enter" &&
                            c?.exchange_rate_ils &&
                            BalanceDeal(
                              c?.amount,
                              c?.exchange_rate_ils,
                              "out",
                              `amount,${index},${c?.row_number}`
                            )
                          }
                          icon={faCalculator}
                          title={t("balanceTransaction")}
                          className="iconBalance"
                        />
                      </p>
                      <div style={{ width: "16.5%" }}>
                        <CustomTextField
                          label={""}
                          value={
                            Number(c?.amount_ils)
                              ? Number(c?.amount_ils)
                                .toFixed(2)
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : 0
                          }
                          readOnly={true}
                          onChange={addFinancialOut}
                          id={`amount_ils,${index},${c.row_number}`}
                          styleObj={true}
                        />
                      </div>
                      <p
                        style={{
                          display: "grid",
                          paddingTop: "0",
                          textAlign: "center",
                          lineHeight: "0.8",
                          marginBottom: "0",
                          width: "fit-content",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className={`circle-icon-${dir}`}
                          title={t("deleteRow")}
                          onClick={() =>
                            readOnly ? "" : removeFObjectOut(c.row_number)
                          }
                        />
                        {c.amount_ils > 0 && (
                          <FontAwesomeIcon
                            onKeyDown={(e) =>
                              e.key === "Enter" &&
                              addRow("financial_assets_out", c.row_number)
                            }
                            className={
                              c.amount_ils > 0
                                ? ` plus-icon-${dir}`
                                : `iconDisabled plus-icon-${dir}`
                            }
                            icon={faPlusCircle}
                            title={t("addRow")}
                            onClick={() => {
                              addRow("financial_assets_out", c.row_number);
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </div>
                ))}
                <hr
                  className="hr"
                  style={{ width: "100%", marginTop: "3px", marginBottom: "0" }}
                ></hr>

                <div>
                  <div className={`calc-convertion-${dir}`}>
                    {sumOut !== 0 && (
                      <div className={`title-calc-convertion-${dir}`}>
                        {t("total")}:
                      </div>
                    )}
                    {sumOut !== 0 && (
                      <div className={`total-calc-convertion-${dir}`}>
                        {sumOut
                          ?.toFixed(2)
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        {t("ILS")}
                      </div>
                    )}
                    {sumOut === 0 && (
                      <div className={`title-calc-convertion-${dir}`}>
                        {t("total")}:
                      </div>
                    )}
                    {sumOut === 0 && (
                      <div className={`total-calc-convertion-${dir}`}>
                        0.00 {t("ILS")}
                      </div>
                    )}
                  </div>
                  <div className={`calc-convertion-${dir}`}>
                    {Number((sumIn - sumOut).toFixed(2)) > 0 && (
                      <div
                        className={`total-text-difference-convertion-${dir}`}
                      >
                        {t("difference")}:
                      </div>
                    )}
                    {Number((sumIn - sumOut).toFixed(2)) > 0 && (
                      <div
                        className={`total-calc-difference-convertion-${dir}`}
                      >
                        {(sumIn - sumOut)
                          .toFixed(2)
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        {t("ILS")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {selectedDraft && datacConversion?.status_id === "draft" && (
            <div
              style={{
                width: "78vw",
                display: "inline-grid",
                marginTop: "20px",
                justifyContent: "end",
              }}
            >
              <PrintHtml
                saveFunction={finishDeal}
                typeAction="exchange"
                conversionCustomer={conversionCustomer}
                action={convertions}
                setAction={setConvertions}
                userCredentials={userCredentials}
              />
              <PrimaryButton
                className="button"
                onClick={() => {
                  deleteDeal();
                }}
                style={{
                  width: "233px",
                  marginTop: "4px",
                  backgroundColor: "red",
                  borderColor: "red",
                }}
                allowDisabledFocus
                disabled={convertions.id_transaction ? false : true}
                checked={false}
                name="delete"
                text={t("deleteTransaction")}
              />
            </div>
          )}
        </div>
        <div>
          <div style={{ width: "78vw" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="title-text" style={{ marginTop: "4%" }}>
                {t("note")}
              </p>
              <div style={{ marginTop: "4%" }}>
                <CustomToggle
                  onText={t("selectNon-standardReporting")}
                  readOnly={readOnly}
                  onChange={change}
                  id={"is_unusual_reporting"}
                  defaultChecked={convertions?.is_unusual_reporting}
                  checked={convertions?.is_unusual_reporting}
                  offText={t("selectNon-standardReporting")}
                />
              </div>
            </div>
            <hr className="hr"></hr>
            <TextFeildNote
              label={t("")}
              onChange={change}
              id={"note"}
              value={convertions?.note}
            />
          </div>
        </div>
        <div
          className={`add-eneficiary-${dir}`}
          style={{ marginBottom: "10px" }}
        >
          <PrimaryButton
            style={{ marginLeft: "0px" }}
            disabled={readOnly}
            allowDisabledFocus
            checked={false}
            onClick={() => setaddFactorDialog(true)}
            name="add"
            text={t("addBeneficiary/guarantor")}
            iconProps={Icons.addFriend}
          />
          {addFactorDialog && (
            <EditableRelatedFactors
              setShowDialog={setaddFactorDialog}
              setshowSpiner={setshowSpiner}
              setRelatedFactors={setRelatedFactors}
              isConversions={true}
            />
          )}
        </div>
        {itemsInsiders.length >= 1 && (
          <ListRelatedFactors
            setshowSpiner={setshowSpiner}
            setRelatedFactors={setRelatedFactors}
            dataCustomer={itemsInsiders}
          />
        )}
        {/* //Not delete it */}

        {/* <div style={{ marginRight: "240px", width: "89%", marginBottom: "2%" }}>
                <div className="attechedA" style={{ width: "80%", marginTop: "1%" }}>
                    <p className="title-text">{"קבצים"}</p>
                    <p className="title-text" onClick={() => setAddFile(true)} style={{ cursor: "pointer" }} >{"(+)"}</p>
                    {AddFile ? <CustomFilesDialog conversions={true} setShowDialog={setAddFile} /> : ""}
                </div>
                <hr className="hr" style={{ width: "80%" }}></hr>
                <div className={`myFiles-${dir}`}>
                    <div className={`fileDetails-${dir}`}>
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <IconButton
                                iconProps={Icons.pdf}
                                
                                styles={{
                                    icon: { color: 'black', fontSize: 45 }
                                }}
                                className="iconFile"
                            /></div>
                        <p className="fileText">{"מסמך א"}</p>
                        <p className="fileText">{"25/20/2021"}</p>
                    </div>
                    <div className={`fileDetails-${dir}`}>
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <IconButton
                                iconProps={Icons.pdf}
                                styles={{
                                    icon: { color: 'black', fontSize: 45 }
                                }}
                                className="iconFile"
                            /></div>
                        <p className="fileText">{"מסמך ב"}</p>
                        <p className="fileText">{"25/20/2021"}</p>
                    </div>
                    <div className={`fileDetails-${dir}`}>
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <IconButton
                                iconProps={Icons.pdf}
                                styles={{
                                    icon: { color: 'black', fontSize: 45 }
                                }}
                                className="iconFile"
                            /></div>
                        <p className="fileText">{"מסמך ג"}</p>
                        <p className="fileText">{"25/20/2021"}</p>
                    </div>
                </div>
            </div> */}
      </div>
    </div>
  );
};
export default Convertions;
