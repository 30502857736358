import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import '../../../scssPages/global.scss';
import validator from 'validator';
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { he } from "date-fns/locale";

import { checkChequeDays, convertArrToDropdropdown } from './GlobalFunctions'
import { CustomTextField } from "./../../../shared/components/TextField";
import { CustomDropdown } from "./../../../shared/components/Option";

registerLocale('he-IL', he)

export const Phone = (props: any) => {
    const { entity, setEntity, readOnly, showRequiredFildes, setIfCahnges } = props
    const [t] = useTranslation();
    const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
    const [enumsDropdown, setenumsDropdown] = useState<any>({ "telephoneCountryCode": [] })

    useEffect(() => {
        if (enums) {
            let arr = {
                "telephoneCountryCode": convertArrToDropdropdown([]),
            };
            for (let i = 0; i < enums.length; i++) {
                if (enums[i].general !== undefined) {
                    let general = enums[i].general;
                    for (let j = 0; j < general.length; j++) {
                        if (general[j].telephone_country_code !== undefined)
                            arr.telephoneCountryCode = convertArrToDropdropdown(general[j].telephone_country_code, "tel")
                    }
                }
            }
            let defaultTelephoneValue: any
            (defaultTelephoneValue = arr?.telephoneCountryCode.find(({ is_default }: any) => is_default === true))
            setEntity({
                ...entity, telephoneCountryCode: defaultTelephoneValue?.key
            })
            setenumsDropdown(arr);
        }
    }, [enums])


    const updateEntity = (key: string, value: any) => {
        setIfCahnges && setIfCahnges(true)
        setEntity({ ...entity, [key]: value })
    }

    return (
        <>
            <p className="title-text-2" >{t("Phone&Email")}</p>
            <hr className="hr"></hr>
            <div style={{ display: "flex", gap: "6px" }} >
                <div style={{ width: "60%" }}>
                    <CustomTextField requiredField={showRequiredFildes && entity?.telephone === "" ? true : false} readOnly={readOnly} value={entity.telephone} label={t('phone')} onChange={updateEntity} id={'telephone'} />
                </div>
                <div style={{ width: "40%" }}>
                    <CustomDropdown readOnly={readOnly} otherInputId={''} hasOtherValue={false} options={enumsDropdown.telephoneCountryCode} label={t('AreaCodeCountry')} onChange={updateEntity} selectedKey={entity?.telephoneCountryCode} id={'telephoneCountryCode'} othertextInput={''} />
                </div>
            </div>
        </>
    )
}

export const Email = (props: any) => {
    const { entity, setEntity, readOnly, setValidEmail, validEmail, showRequiredFildes, setIfCahnges } = props
    const [t] = useTranslation();

    const updateEntity = (key: string, value: any) => {
        setIfCahnges && setIfCahnges(true)
        setEntity({ ...entity, [key]: value })
    }

    const checkValidationEmail = (e: any) => {
        if (e.target.value !== "" && !readOnly) {
            let valid = validator['isEmail'](e.target.value)
            valid ? setValidEmail(true) : setValidEmail(false)
        }
        else {
            setValidEmail(true)
        }
    }

    return (
        <>
            {/* <p className="title-text-2" style={{ marginTop: "80px" }}  >{t('email')}</p>
            <hr className="hr"></hr> */}
            <CustomTextField requiredField={showRequiredFildes && entity?.email === "" ? true : false} onBlur={(e: any) => checkValidationEmail(e)} value={entity.email} readOnly={readOnly} label={t('emailAddress')} onChange={updateEntity} id={'email'} type='email' />
            {!validEmail && <p className="text-feild-note">{t('validEmail')}</p>}
        </>
    )
}

export const IdNumber = (props: any) => {
    const { entity, setEntity, readOnly, setResultId, resultId, setIfCahnges,
        typeIdentityNumbers, typeIdDefault, showRequiredFildes } = props
    // , countryCode
    const [t] = useTranslation();
    const [isLess, setisLess] = useState(true)
    const [enumsDropdown, setenumsDropdown] = useState<any>({ "customersType": [], "genders": [], "typeIdentityNumbers": [], "status": [], "ID_type_id": [], "telephoneCountryCode": [], "countryCode": [], "industryClassification": [] })
    const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)

    useEffect(() => {
        if (enums) {
            let arr = {
                "countryCode": convertArrToDropdropdown([]),
                "selectedCountryCode": convertArrToDropdropdown([]),
            };
            for (let i = 0; i < enums.length; i++) {
                if (enums[i].general !== undefined) {
                    let general = enums[i].general;
                    for (let j = 0; j < general.length; j++) {
                        if (general[j].country_code !== undefined) {
                            general[j].country_code?.forEach((country: any) => {
                                if (country?.is_selected === true) {
                                    arr.selectedCountryCode.push({ key: country.enum_id, text: country.enum_value, is_default: country?.is_default === true && true });
                                }
                                arr.countryCode.push({ key: country.enum_id, text: country.enum_value, is_default: country?.is_default === true && true })
                            })
                            arr.selectedCountryCode.push({ key: "more", text: t('more') })
                            arr.countryCode.unshift({ key: "less", text: t('less') })
                        }
                    }
                }
            }
            setenumsDropdown(arr);
        }
    }, [enums])

    useEffect(() => {
        if (isLess) {
            let id = entity?.idCountryCode ? entity?.idCountryCode : entity?.idTypeCountryCode
            if (enumsDropdown?.selectedCountryCode?.length > 0 && id) {
                let isExsist = enumsDropdown.selectedCountryCode.find((item: any) => item.key === id)
                if (!isExsist)
                    setisLess(false)
            }
        }
    }, [enumsDropdown?.selectedCountryCode, entity.idCountryCode, entity.idTypeCountryCode])



    const updateEntity = (key: string, value: any) => {
        // if (!checkedCorporation)
        if ((key === "idCountryCode" || key === "idTypeCountryCode") && value === "IL" && entity.idTypeId === typeIdDefault || key === "idTypeId" &&
            value === typeIdDefault && (entity.idCountryCode === "IL" || entity.idTypeCountryCode === "IL")) {
            if (entity.idNumber && !readOnly) {
                let result = validator.isIdentityCard(entity.idNumber, 'he-IL');
                setResultId(result);
            }
        } else
            setResultId(true)
        if ((key === "idCountryCode" || key === "idTypeCountryCode") && (value === "more" || value === "less")) {
            setisLess(!isLess)
        }
        else {
            setIfCahnges && setIfCahnges(true)
            setEntity({ ...entity, [key]: value })
        }
    }


    const checkValidationId = (e: any) => {
        // !checkedCorporation &&
        if ((entity.idCountryCode === "IL" || entity.idTypeCountryCode) && entity.idTypeId === typeIdDefault) {
            if (e.target.value !== "" && !readOnly) {
                let valid = validator.isIdentityCard(e.target.value, 'he-IL');
                valid ? setResultId(true) : setResultId(false)
            }
            else {
                setResultId(true)
            }
        }
    }

    return (
        <>
            <div>
                <CustomTextField requiredField={showRequiredFildes && !entity?.idNumber ? true : false} onBlur={(e: any) => checkValidationId(e)} value={entity.idNumber} readOnly={readOnly} required={true} label={t('identityNumber')} onChange={updateEntity} id={'idNumber'} />
                {!resultId && <small className="text-feild-note">{t('notificationOfValidId')}</small>}
            </div>
            {!isLess && <CustomDropdown otherInputId={''} readOnly={readOnly} hasOtherValue={false} options={enumsDropdown.countryCode} label={t('countryIdentityNumber') + "    "} onChange={updateEntity} selectedKey={entity?.idCountryCode ? entity?.idCountryCode : entity?.idTypeCountryCode} id={entity?.idCountryCode ? 'idCountryCode' : "idTypeCountryCode"} othertextInput={t('')} />}
            {isLess && <CustomDropdown otherInputId={''} readOnly={readOnly} hasOtherValue={false} options={enumsDropdown.selectedCountryCode} label={t('countryIdentityNumber') + "    "} onChange={updateEntity} selectedKey={entity?.idCountryCode ? entity?.idCountryCode : entity?.idTypeCountryCode} id={entity?.idCountryCode ? 'idCountryCode' : "idTypeCountryCode"} othertextInput={t('')} />}
            <CustomDropdown otherInputId={'idTypeOther'} readOnly={readOnly} otherValue={entity.idTypeOther} hasOtherValue={true} options={typeIdentityNumbers} label={t('typeIdentityNumber')} onChange={updateEntity} selectedKey={entity?.idTypeId} id={'idTypeId'} othertextInput={t('typeIdentityNumberOther')} />
        </>
    )
}


export const CustomerDate = (props: any) => {
    const { entity, setEntity, readOnly, idDate, setIsValidDate, label,
        showRequiredFildes, source, setIfCahnges, isDefaultDate, searchCustomer } = props
    const [startDate, setStartDate] = useState<any>();
    const [t] = useTranslation();
    const [validDate, setValidDate] = React.useState<any>("no");
    const [validTimeDate, setValidTimeDate] = React.useState<any>(true);
    const [isFirst, setIsFirst] = React.useState<any>(true);
    let calendarRef = useRef<any>(null);
    let d = new Date();

    useEffect(() => {
        if (entity[idDate] === "" && isDefaultDate && isFirst) {
            if (label === t("from_date")) {
                let startCurrentMonth: any = moment().startOf('month').format('YYYY-MM-DD');
                setEntity((entity: any) => ({ ...entity, [idDate]: startCurrentMonth }))
            }
            else
                if (label === t("to_date")) {
                    let endCurrentMonth: any = moment().endOf('month').format('YYYY-MM-DD');
                    setEntity((entity: any) => ({ ...entity, [idDate]: endCurrentMonth }))
                }
                else {
                    let dayToday: any = moment(d).format("YYYY-MM-DD");
                    setEntity((entity: any) => ({ ...entity, [idDate]: dayToday }))
                }
            setStartDate(d);
        }
        else {
            if (entity[idDate] !== "" && entity[idDate]?.includes("/")) {
                // if (entity[idDate] !== "" && entity[idDate]?.includes("/")) {
                let momentDate = moment(entity[idDate], "D/M/YYYY", true)
                setEntity({ ...entity, [idDate]: moment(momentDate).format("YYYY-MM-DD") })
            }
            else {
                if (entity[idDate] !== "" && entity[idDate] !== "Invalid date" &&
                    entity[idDate] !== undefined && startDate === undefined) {
                    setStartDate(new Date(entity[idDate]))
                }
                if (entity[idDate] === "" && startDate !== "") {
                    setStartDate(undefined)
                }
                if (entity[idDate] !== "" && entity[idDate] !== undefined
                    && startDate !== "" && isFirst) {
                    setStartDate(new Date(entity[idDate]))
                }
            }
        }
        setIsFirst(true)

    }, [entity[idDate], entity])

    const changeChequeDate = (date: any) => {
        let value = date ? moment(date).format("YYYY-MM-DD") : ""
        let chequeDays: any, kindCashingIntrest: any, properties: any = {}, cashingIntrest: any
        chequeDays = checkChequeDays(entity?.cheque_date)
        chequeDays !== undefined && chequeDays > 0 ?
            kindCashingIntrest = "cheque_cashing_rate" : kindCashingIntrest = "cheque_cashing_cash_rate"
        let prop: any = searchCustomer?.priceProgram?.price_program_properties
        prop?.forEach((element: any) => {
            properties[element.name] = element.value ? element.value : element.percent;
        });
        chequeDays = checkChequeDays(value)
        if ((properties[kindCashingIntrest] * 100) === entity?.cashing_intrest) {
            chequeDays !== undefined && chequeDays > 0 ?
                kindCashingIntrest = "cheque_cashing_rate" : kindCashingIntrest = "cheque_cashing_cash_rate"
            cashingIntrest = (properties[kindCashingIntrest] * 100)
        }
        else {
            cashingIntrest = entity?.cashing_intrest
        }
        setEntity({
            ...entity, [idDate]: value, "cashing_intrest":
                cashingIntrest, "cheque_days": chequeDays
        })
        setStartDate(date)
    }

    const checkFutureDate = (date: any) => {
        let aDate = moment(d).format("D/M/YYYY")
        let aDat2 = moment(date).format("D/M/YYYY")
        if (d < date || aDate === aDat2) {
            setValidTimeDate(true)
        }
        else {
            setValidTimeDate(false)
        }
    }

    const checkPastDate = (date: any) => {
        if (date && d > date) {
            setValidTimeDate(true)
        }
        else {
            setValidTimeDate(false)
        }
    }
    const clickOutside = () => {
        if (calendarRef.current) {
            calendarRef.current?.setOpen(false)
        }
    }

    const checkValidationDate = (date: any) => {

        let value = date ? moment(date).format("YYYY-MM-DD") : ""
        if (value === "") {
            setEntity({ ...entity, [idDate]: value })
            setStartDate(date)
        }
        else {
            if (value !== entity[idDate]) {
                setIsFirst(false)
                let aDate = moment(value);
                let isValid = aDate.isValid();
                setValidDate(isValid)
                if (source === "futureDate" && isValid)
                    checkFutureDate(aDate)
                if (source === "pastDate" && isValid)
                    checkPastDate(aDate)
                if (validDate && validTimeDate)
                    setIsValidDate && setIsValidDate(true)
                // if (idDate === "cheque_date" && entity?.cashing_intrest && isValid) {
                if (idDate === "cheque_date" && isValid) {
                    changeChequeDate(date)
                }
                else {
                    setEntity({ ...entity, [idDate]: value })
                    setStartDate(date)
                }
                setIfCahnges && setIfCahnges(true)
            }
        }
    }

    return (
        <>
            <div>
                {label && <p style={{ fontWeight: 600, padding: "5px 0px", marginBottom: 0, fontSize: "14px" }}>
                    {label + "  "}<label style={{ color: "rgb(164, 38, 44)" }}>{source === "pastDate" && "*"}</label></p>}

                <DatePicker selected={startDate && startDate}
                    className={`${readOnly ? "readOnly-date" : "input-date"} ${showRequiredFildes && !startDate && "require-date"}`}
                    readOnly={readOnly}
                    enableTabLoop={false}
                    ref={calendarRef}
                    closeOnScroll={true}
                    // placeholderText="dd/mm/yyyy"
                    locale={"he-IL"}
                    // popperPlacement="bottom-start"
                    dateFormat={["dd/MM/yyyy", "dd-MM-yyyy",
                        "dd.MM.yyyy", "ddMMyyyy", "dd,MM,yyyy"]}
                    onClickOutside={clickOutside}
                    // portalId="date-picker-portal"
                    onChange={(date: any) => {
                        checkValidationDate(date)
                    }} />

                {!validTimeDate && <small style={{ color: "red", margin: "0px", width: "100%", height: "10px" }}>
                    {source === "pastDate" ? t('notPossibleEnterFutureDate') : t('notPossibleEnterPastDate')}</small>}
                {!validDate && <small style={{ color: "red", margin: "0px", width: "100%", height: "10px" }}>{t('dateEnteredInvalid')}</small>}
            </div>
        </>
    )
}

