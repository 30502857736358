import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute({ component: Component, ...rest }: { component: React.ComponentType<any>, [key: string]: any }) {
  const modules = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)


  const checkPermission = () => {
    if (modules) {
      let permission = false
      modules?.map((module: any) => {
        module?.pages?.map((page: any) => {
          if (page?.page_code === rest?.name) {
            if (rest?.name === "home_page") {
              rest.setPermissionHomePage(true)
            }
            permission = true
          }
        });
      });
      return permission
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        checkPermission() && rest?.name === "home_page" && modules ? (
          <Component {...props} permissionHomePage={rest?.permissionHomePage} />
        ) : checkPermission() && modules ? (
          <Component {...props} />
        ) : modules && (
          <Redirect to="/not_premission" />
        )
      }
    />
  );
}

