import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cloneDeep } from 'lodash';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { useTranslation } from 'react-i18next';
import { CustomTextField } from '../../shared/components/TextField';
import { accountData } from '../../store/actions/accountAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IUserCredentials } from './../customerDetails/CustomerDetails';
import { Callout, Dialog, ContextualMenu, getTheme, mergeStyleSets } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { PrimaryButton } from '@fluentui/react';
import { checkPermissionLevelOfPage, convertArrToDropdropdown } from '../../shared/components/CustomHooks/GlobalFunctions';
import AccountsAddAcountDialog from './AccountsAddAcountDialog';
import { accountsFilter } from '../../store/actions/bankActions';
import { Pagination } from "@pnp/spfx-controls-react/lib/Pagination";

import '../../components/actions/discountsAction/checkManagment/AGtable.css';
import moment from 'moment';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import TabsAccount from './TabsAccount';
import { useLocation } from 'react-router';


const initialDataAllFilter = {
    account_name: ["like", ""],
    account_number: ["equal", ""],
    account_type_id: ["equal", ""],
    status_id: ["equal", ""],
    balance_group_id: ["equal", ""],
    balance_sub_group_id: ["equal", ""],
}

export default function AccountTable(props: any) {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const dispatch = useDispatch()
    const { rowData, setRowData } = props
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const enums = useSelector(({ dataReducer }: any) => dataReducer?.enums)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const location = useLocation()
    const [permission, setPermission] = useState('')
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const [tab, setTab] = useState<number>(1)
    const [loading, { setTrue: displayLoading, setFalse: hideLoading }] = useBoolean(true);
    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
    };

    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        dragOptions: dragOptions
    };

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [arrTitle, setArrTitle] = useState([
        { key: "accountName", eng: 'account_name', sortValue: false, default: "like" },
        { key: "accountNumber", eng: 'account_number', sortValue: false, default: "equal" },
        { key: "accountType", eng: 'account_type_id', sortValue: false, default: "equal" },
        { key: "status", eng: 'status_id', sortValue: false, default: "equal" },
        { key: "balanceGroup", eng: 'balance_group_id', sortValue: false, default: "equal" },
        { key: "balanceSubgroup", eng: 'balance_sub_group_id', sortValue: false, default: "equal" }])
    const [messageError, setMessageError] = useState('')
    const [serverMessageError, setServerMessageError] = useState<any>('')
    const [dialogTitle, setDialogTitle] = useState('')
    const [search_fields, setSearch_fields] = useState<any>([]);
    const [accountsData, setAccountsData] = useState<any>()
    const [curentPage, setCurentPage] = useState<any>(1);
    const [showDialogAccount, setShowDialogAccount] = useState(false);
    const [numResults, setnumResults] = useState(-1)
    const [numRecourd, setNumRecourd] = useState(10)
    const [currencyList, setCrrencyList] = useState<any>([])
    const [dataAllfilter, setDataAllFilter] = useState<any>(initialDataAllFilter)
    const [filter, setFilter] = useState({ key: '', value: false })
    const operatorStringList: IDropdownOption[] = [{ key: "like", text: t("like") },
    { key: "begin_with", text: t("begin_with") }, { key: "equal", text: t("equal") }]
    const numRecourdList: IDropdownOption[] = [{ key: 10, text: "10" }, { key: 50, text: "50" }, { key: 100, text: "100" }]
    const [showDialogAdd, setShowDialogAdd] = useState(false)
    const [itemToUpdate, setItemToUpdate] = useState<any>({})
    const [enumsArr, setEnumsArr] = useState<any>()
    const [selectedAccount, setSelectedAccount] = useState<any>()
    const balanceGroupId = useSelector(({ authReducer }: any) => authReducer?.data?.enums[0]?.account[2]?.balance_group_id)
    const balanceSubGroupId = useSelector(({ authReducer }: any) => authReducer?.data?.enums[0]?.account[3]?.balance_sub_group_id)


    useEffect(() => {
        if (enums) {
            let arr = {
                "account_type_id": convertArrToDropdropdown([]),
                "status_id": convertArrToDropdropdown([]),
                "contraAccountGroupId": convertArrToDropdropdown([]),
                "balance_group_id": convertArrToDropdropdown([]),
                "balance_sub_group_id": convertArrToDropdropdown([]),
            };
            for (let i = 0; i < enums.length; i++) {
                if (enums[i].account !== undefined) {
                    let account = enums[i].account
                    for (let i = 0; i < account.length; i++) {
                        if (account[i].account_type_id !== undefined) {
                            arr.account_type_id = convertArrToDropdropdown(account[i].account_type_id)
                        }
                        if (account[i].status_id !== undefined) {
                            arr.status_id = convertArrToDropdropdown(account[i].status_id)
                        }
                        if (account[i].contra_account_group_id !== undefined) {
                            arr.contraAccountGroupId = convertArrToDropdropdown(account[i].contra_account_group_id)
                        }
                        if (account[i].balance_group_id !== undefined) {
                            arr.balance_group_id = convertArrToDropdropdown(account[i].balance_group_id)
                            let index = arr.balance_group_id.findIndex((item: any) => item.key === "99")
                            if (index !== -1)
                                arr.balance_group_id.splice(index, 1)
                        }
                        if (account[i].balance_sub_group_id !== undefined) {
                            arr.balance_sub_group_id = convertArrToDropdropdown(account[i].balance_sub_group_id)
                            let index = arr.balance_sub_group_id.findIndex((item: any) => item.key === "99")
                            if (index !== -1)
                                arr.balance_sub_group_id.splice(index, 1)
                        }
                    }
                }
            }
            setEnumsArr(arr);
        }
    }, [enums]);

    useEffect(() => {
        async function getAccountData() {
            displayLoading()
            await dispatch(accountData(userCredentials, setSearch_fields,
                setRowData, curentPage, search_fields.length === 0 ? [] : search_fields, numRecourd, "search"))
        }
        if (userCredentials) {
            getAccountData()
        }
        // eslint-disable-next-line
    }, [userCredentials, curentPage]);

    useEffect(() => {
        if (showDialogAccount) {
            toggleHideDialog()
        }
    }, [showDialogAccount, toggleHideDialog])

    const _onPageUpdate = async (pageno?: number) => {
        var currentPge = (pageno) ? pageno : curentPage;
        setCurentPage(currentPge);
        setnumResults(rowData?.records_found)
    };

    useEffect(() => {
        if (rowData?.search_results) {
            _onPageUpdate();
            hideLoading();
        }
    }, [rowData?.search_results]);

    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer])

    useEffect(() => {
        if (location && credentials?.length) {
            let path: any = location.pathname.split("/")
            checkPermissionLevelOfPage(credentials,location, setPermission)
        }
    }, [location, credentials])

    const enterFilter = (field: any, value: any, key: any) => {
        let arr = cloneDeep(search_fields)
        let data = cloneDeep(dataAllfilter)
        let item: any
        item = {
            "field_name": field.eng,
            "search_type": "",
            "search_value": ""
        }
        let index = arr.findIndex((x: any) => x.field_name === field.eng)
        if (index !== -1) {
            item = arr[index]
        }
        if (key === 'operator') {
            data[field.eng][0] = value
            item["search_type"] = value
        }

        else {
            item["search_value"] = value
            data[field.eng][1] = value
        }
        if (index !== -1) {
            let arr1 = search_fields
            arr1[index] = item
            setSearch_fields(arr1)
        } else {
            if (item["search_type"] === "") {
                item["search_type"] = field.default
            }
            setSearch_fields([...search_fields, item])
        }
        setDataAllFilter(data)
    }
    const chooseNumRecord = (value: any, key: any) => {
        setNumRecourd(key.key)
        displayLoading()
        dispatch(accountData(userCredentials, setSearch_fields, setRowData,
            curentPage, search_fields, key.key, "search"))
    }


    const sortCol = (col: any, index: any) => {
        if (col?.sortValue)
            rowData?.search_results.sort((a: any, b: any) => (a[col?.eng] > b[col?.eng] ? -1 : 1))
        else
            rowData?.search_results.sort((a: any, b: any) => (a[col?.eng] > b[col?.eng] ? 1 : -1))
        let item = { key: col.key, eng: col.eng, sortValue: !col.sortValue, filterValue: col.fliterValue, default: col?.default }
        let arr: any = [...arrTitle]
        arr[index] = item
        setArrTitle(arr)
    }
    const filterCol = (key: any) => {
        setFilter({ key: key, value: !filter.value })
    }
    const sendFilterConditions = async () => {
        let arrToSend: any[] = []
        for (let index = 0; index < search_fields?.length; index++) {
            if (!search_fields[index]?.search_type || !search_fields[index]?.search_value)
                setSearch_fields((item: any) => item.filter((x: any) => x.field_name !== search_fields[index].field_name));
            else arrToSend.push(search_fields[index])
        }
        setFilter({ key: '', value: false })
        setDataAllFilter(initialDataAllFilter)
        displayLoading()
        await dispatch(accountData(userCredentials, setSearch_fields, setRowData, 1, arrToSend, numRecourd, "search"))

    }
    const updateItem = (item: any) => {
        setItemToUpdate(item)
        setShowDialogAdd(true)
    }

    const showAccountMovements = async (value: any) => {
        if (value === selectedAccount?.id_account)
            value = selectedAccount
        else
            setSelectedAccount(value)
        setTab(1)

        await dispatch(accountsFilter(value?.id_account, value?.id_entity,
            userCredentials, "search_ledger", undefined, setAccountsData, curentPage, [], numRecourd
            , setServerMessageError, setMessageError, setDialogTitle
        ))
        setShowDialogAccount(true)
    }

    const getBalanceGroupName = (groupId: any) => {
        const selectedGroup = balanceGroupId?.find((g: any) => Number(g.enum_id) === groupId);
        return selectedGroup?.enum_value;
    }

    const getBalanceSubGroupName = (subGroupId: any) => {
        const selectedSubGroup = balanceSubGroupId?.find((g: any) => Number(g.enum_id) === subGroupId);
        return selectedSubGroup?.enum_value;
    }

    return (
        <div className={`${permission === "RW"
            ? 'page-read' : 'page-read-only'}`}>
            {showDialogAdd && <AccountsAddAcountDialog pageSize={numRecourd} currentPage={curentPage} rowData={rowData}
                itemToUpdate={itemToUpdate} setServerMessageError={setServerMessageError}
                setDialogTitle={setDialogTitle} setMessageError={setMessageError}
                setRowData={setRowData} setShowDialog={setShowDialogAdd} search_fields={search_fields} />}
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", width: "1120px" }}>
                    <p className="title-text" >{t("accountsChart")}</p>
                    <div className="title-text addFile" onClick={() => { setShowDialogAdd(true); setItemToUpdate({}) }} ><i title={t("createAccount")} className="fal fa-plus"></i></div>
                </div>
                <hr className="hr" style={{ width: "1120px" }} ></hr>
                <div style={{
                    display: "flex", marginTop: "24px",
                    width: "1120px", justifyContent: "space-between"
                }}>
                    <div style={{ display: "flex" }}>
                        <PrimaryButton onClick={() => { sendFilterConditions() }} text={t("filter")} />
                        <PrimaryButton className={`margin-between-input-${dir}`}
                            onClick={() => {
                                setSearch_fields([])
                                setDataAllFilter(initialDataAllFilter)
                                displayLoading()
                                dispatch(accountData(userCredentials, setSearch_fields,
                                    setRowData, curentPage, [], numRecourd, "search"))
                            }} text={t("cancelFilter")} /></div>
                    {rowData?.search_results?.length > 0 && <div>
                        <Dropdown
                            placeholder={t("NumberOfRowsToDisplay")}
                            id={''}
                            className={`dropdowm-num-rows-${dir}`}
                            options={numRecourdList}
                            onChange={(id: any, val: any) => (chooseNumRecord(id, val))}
                        /></div>}
                </div>
                <div style={{ maxWidth: '98%', overflowX: 'auto' }}>
                    {numResults !== (-1) ? numResults > 0 ? <p style={{ marginBottom: '0' }}>{t('found')} {numResults} {t('results')}</p> : <p style={{ marginBottom: '0' }}>{t('searchCustomerNotFound')}</p> : ""}
                    {rowData?.search_results?.length > 0 &&
                        <table style={{ border: '2px solid #e1e1e1', minWidth: "1120px" }}>
                            <tbody>
                                <tr>
                                    {arrTitle?.map((item: any, key: any) => (
                                        <th className={`${key === 0 ? `padding-input-${dir}` : ""}`} key={key + "1"} style={{
                                            border: 'none', backgroundColor: "#e1e1e1",
                                            height: '46px', minWidth: key === 0 ? '150px' : '120px', textAlign: 'start', color: 'gray'
                                        }}>
                                            {t(item.key)}
                                            <i onClick={() => { sortCol(item, key) }} title={t("filter")}
                                                className={`icon-sort-t-${dir} ${item.sortValue ? "fal fa-arrow-up" : "fal fa-arrow-down"} `}></i>
                                            {/* className={`icon-sort-t-${dir} fal fa-filter`} */}
                                            <i  className={`icon-sort-t-${dir} ${dataAllfilter[item.eng][1] ? "fas" : "fal"} fa-filter`} id={item.eng} title={t("filter")}
                                                onClick={() => { filterCol(item.key) }}></i>
                                            {filter.key === item.key && filter.value === true && <>
                                                <Callout
                                                    onKeyDown={(e: any) => e.key === "Enter" && filterCol(item.key)}
                                                    coverTarget
                                                    className={styles.callout}
                                                    onDismiss={() => { filterCol(item.key) }}
                                                    target={`#${item.eng}`}
                                                    isBeakVisible={false}
                                                    setInitialFocus >
                                                    <FontAwesomeIcon icon={faTimes} title={t("close")}
                                                        onClick={() => { filterCol(item.key) }}
                                                        style={{ height: '12px', marginTop: '2%', marginBottom: '2%' }} className={`iconRefresh-${dir}`} />
                                                    <div key={key + "1"} >
                                                        {item.default !== "equal" &&
                                                            <div className='table-spaces'> <Dropdown
                                                                placeholder={t("selectCondition")}
                                                                selectedKey={dataAllfilter[item.eng][0]}
                                                                id={item}
                                                                style={{ marginBottom: '2%' }}
                                                                onChange={(id: any, val: any, e: any) => (enterFilter(item, val.key, "operator"))}
                                                                options={item.eng.includes('account_na') && operatorStringList}
                                                            /></div>
                                                        }
                                                        {item.eng.includes('_id') ?
                                                            <div className='table-spaces'>
                                                                <Dropdown
                                                                    placeholder={t("select") + " " + t(item?.key)}
                                                                    id={item.eng}
                                                                    className={`drop-down-account-table-${dir}`}
                                                                    options={item?.eng && enumsArr[item.eng]}
                                                                    selectedKey={dataAllfilter[item?.eng][1]}
                                                                    onChange={(id: any, val: any, e: any) => { enterFilter(item, val.key, "value") }}
                                                                /></div>
                                                            :
                                                            <div className='table-spaces'>
                                                                <CustomTextField label={""}
                                                                    placeholder={`${t("enter")} ${t(item?.key)}`}
                                                                    value={search_fields ? dataAllfilter[item.eng][1] : `${t("enter")} ${t(item?.key)}`}
                                                                    readOnly={false} onChange={(id: any, val: any) => (enterFilter(id, val, "value"))} id={item} />
                                                            </div>}</div>
                                                </Callout>
                                            </>
                                            }
                                        </th>
                                    ))}
                                    <th style={{ border: 'none', backgroundColor: "#e1e1e1", textAlign: 'start', minWidth: '70px', color: 'gray' }}>{t("operations")}</th>
                                    <th style={{ border: 'none', backgroundColor: "#e1e1e1", textAlign: 'start', minWidth: '53px', color: 'gray' }} >{t("movements")}</th>
                                </tr>
                                {rowData?.search_results?.map((item: any, key: any) => (
                                    <>
                                        <tr key={key} className="hoverRow" style={{ borderBottom: '1px solid #e1e1e1', minHeight: '60px' }}>
                                            <td className={`td-account-${dir} padding-input-${dir}`} >{item.account_name}</td>
                                            <td className='td-account-table'>{item?.account_number}</td>
                                            <td className='td-account-table'>{item?.account_type_name} </td>
                                            <td className='td-account-table'> {item?.status_name}</td>
                                            <td className='td-account-table'> {getBalanceGroupName(item?.balance_group_id)}  </td>
                                            <td className='td-account-table'> {getBalanceSubGroupName(item?.balance_sub_group_id)}</td>
                                            <td style={{ border: 'none', textAlign: 'center', color: "grey" }}>
                                                <i className={`iconEdit-${dir} fal fa-pen`} title={t("edit")} onClick={() => { updateItem(item) }}></i>
                                            </td>

                                            <td style={{ border: 'none', textAlign: 'center', color: "grey" }}>
                                                <i className={`iconEdit-${dir} fal fa-eye`} title={t("display")} onClick={() => { showAccountMovements(item) }}></i>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>}
                </div>
                {serverMessageError && <DialogMessages setansDelete={setServerMessageError}
                    setshowModalDelete={setServerMessageError} subText={messageError} title={dialogTitle} confirm={true} dialogType={"dialogMessage"} />}
                {/*  */}
                {showDialogAccount && accountsData?.records_found > 0 && <Dialog minWidth="fit-content" maxWidth="fit-content"
                    hidden={hideDialog} modalProps={modelProps} onDismiss={() => { toggleHideDialog(); setShowDialogAccount(false) }}>


                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div >
                            <p className="title-text" style={{ fontSize: "25px", fontWeight: "bold" }} >
                                {selectedAccount.account_name + "  " + selectedAccount?.account_number}</p>
                            {/* className={`account-balance-${dir}`} */}
                            <label className='currency-'>
                                <span>{t("balanceTemporaryTo")}
                              {"   "}{moment().format("DD/MM/YYYY HH:mm")} -</span>
                                {Number(accountsData?.account_balance_temp) < 0 ?
                                    <span style={{ fontSize: "22px" ,fontWeight:"bold" }}> {Math.abs(Number(accountsData?.account_balance_temp))?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  (ז)</span> :
                                    <span style={{ fontSize: "22px",fontWeight:"bold" }} > {Math.abs(Number(accountsData?.account_balance_temp))?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  (ח)</span>
                                }
                            </label>
                            <label className={`padding-currency-${dir}`}>
                                <span>{t("balanceTo"  )}-</span>
                                {Number(accountsData?.account_balance) < 0 ?
                                    <span  style={{ fontSize: "22px" ,fontWeight:"bold" }}> {Math.abs(Number(accountsData?.account_balance_temp))?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  (ז)</span> :
                                    <span  style={{ fontSize: "22px",fontWeight:"bold" }} > {Math.abs(Number(accountsData?.account_balance_temp))?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  (ח)</span>
                                }
                            </label>
                        </div>
                    </div>
                    <hr className="hr"></hr>
                    <TabsAccount
                        accountsData={accountsData} setAccountsData={setAccountsData} tab={tab} setTab={setTab}
                        idEntity={selectedAccount?.id_entity} actionIn={true} idAccount={selectedAccount?.id_account}
                        currencyList={currencyList} setCrrencyList={setCrrencyList} showAccountMovements={showAccountMovements}
                    />
                </Dialog>}

                <div className="paginationContainer" style={{ maxWidth: '1120px', display: 'flex', justifyContent: "center", margin: "20px 0px", direction: "ltr" }}>
                    <div className="searchWp__paginationContainer__pagination">
                        {(!loading && rowData?.search_results?.length > 0) &&
                            <Pagination
                                currentPage={curentPage}
                                totalPages={Math.ceil(rowData?.records_found / numRecourd)}
                                onChange={(page) => _onPageUpdate(page)}
                                limiter={3}
                            />
                        } 
                        </div>
                </div>
            </div>


        </div>
    )
}
const theme = getTheme();
const styles = mergeStyleSets({
    callout: {
        width: 200,
        padding: '9px',
        background: theme.semanticColors.bodyBackground,
    },
});