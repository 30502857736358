
import './headerClient.scss';
import { Logout } from '../auth/Logout';
import { connect, useSelector } from 'react-redux';
import { IAppNavbar } from '../../modelsType/type/interface';
import { useEffect, useState } from 'react';

const HeaderClient = ({ auth }: IAppNavbar) => {
 
  const [userName, setUserName] = useState("");

  const authReducer = useSelector(({ authReducer }: any) => authReducer)

  useEffect(() => {
    if (!authReducer?.data?.user) return
    setUserName(authReducer.data.user.first_name + " " + authReducer.data.user.last_name);
  }, [authReducer]);
  // const isLogin = useSelector((state) => state.auth)

 
  return (
    <>
      <div className="header" style={{ backgroundColor: "#1A7128" }}>
        <div className="menueHeader">
          <div></div>
          <div></div>
          <div></div>
        </div>
        {/* <FontAwesomeIcon className="userIcon" icon={"user-circle"} /> */}

        <div className="user">
          <p className="user-name">{userName}</p>
          <Logout />
        </div>
        <div className='divider'>

        </div>
        <div className="name-company">
          <p >InSight Systems 2.0</p>

        </div>


        {/* <FontIcon iconName="IncreaseIndentArrowMirrored" className="logoutIcon"/> */}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,


});

export default connect(mapStateToProps, null)(HeaderClient);
