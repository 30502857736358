import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faTimesCircle,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";
import { cloneDeep, isEqual } from "lodash";
import { ComboBox, IComboBoxOption, IComboBox } from "@fluentui/react";

import { CustomTextField } from "../../../../shared/components/TextField";
import { CustomDropdown } from "../../../../shared/components/Option";
import { convertArrToDropdropdown } from "../../../customerDetails/CustomerDetails";
import { CheckDetailsDialog } from "../../../../shared/components/dialog/dialogTransaction/CheckDetailsDialog";
import {
  CashPaymentDialog,
  CheckToCustomerDialog,
  CustomDialogCreditFromCustomer,
  CustomDialogTransferFromCustomer,
} from "../../../../shared/components/dialog/Dialog";
import DialogSearch from "../../../global/DialogSearch";
import { createTransaction } from "../../../../store/actions/convertionActions";
import { DialogMessages } from "../../../../shared/components/dialog/DialogMessages";
import { checkCurrencies } from "../../../../shared/components/CustomHooks/GlobalFunctions";

const FinancialAssets = (props: any) => {

  const { action, setAction, readOnly, sum, setSum, tableName, sourcePage, isDoubleId,
    typeAction, searchCustomer, userCredentials, ifChanges, isNegativeBalance, emptyRequiredFields,
    setIfChanges, emptyOutContraAccount, sumFee, setSumFee, emptyOutAmount } = props
  const [t] = useTranslation();
  const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
  const dispatch = useDispatch();
  const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
  const contraAccounts = useSelector(({ authReducer }: any) => authReducer?.data?.user.contra_accounts)
  const datacConversion = useSelector(({ conversionReducer }: any) => conversionReducer.dataConversion)
  const [enumsDropdown, setenumsDropdown] = useState<any>({ "currencyCode": [] })
  const [currencyEnter, setCurrencyEnter] = useState(false)
  const [defaultCurrencyCode, setdefaultCurrencyCode] = useState()
  const [/*search_fields*/, setSearch_fields] = useState<any>([]);
  const [defaultfinanssialAsset, setDefaultfinanssialAsset] = useState()
  const [contraCashAccounts, setcontraCashAccounts] = useState<any>([])
  const [TransferFromCustomer, setTransferFromCustomer] = useState(false)
  const [cashPayment, setCashPayment] = useState(false)
  const [contraCheck, setContraCheck] = useState<any>([])
  const [contraCreditCard, setContraCreditCard] = useState<any>([])
  const [contraBankAccounts, setcontraBankAccounts] = useState<any>([])
  const [checkDetails, setCheckDetails] = useState(false)
  const [checkDetailsOut, setcheckDetailsOut] = useState(false)
  const [credit, setCredit] = useState(false)
  const [tableType, setTableType] = useState(tableName)
  const [rowNumber, setRowNumber] = useState("1")
  const [showDialogFailed, setShowDialogFailed] = useState(false)
  const [rowOfDelete, setRowOfDelete] = useState<any>()
  const [saveDeleteRowActoin, setSaveDeleteRowActoin] = useState("")
  const [messageError, setMessageError] = useState('')
  let arrGates = sessionStorage.getItem("gatesArr")
  const gates = (arrGates ? JSON.parse(arrGates) : [])
  const arrWidth = sourcePage === "logOrder" ? ["21%", "190px", '14%', "16%", '16%', "16%"] :
    sourcePage === "currencyEntry" ? ["21%", "22%", '15%', "18%", '18%', "17%"] :
      sourcePage === "checkDiscount" ? ["22%", "20%", '11%', "16%", '10%', "18%"] :
        ["21%", "18%", '12%', "15%", '12%', "15%"]
  const widthContainer: String = sourcePage === "logOrder" ? "720px" : sourcePage === "currencyEntry" ?
    "42vw" : sourcePage === "checkDiscount" ? "780px" : "780px"

  useEffect(() => {
    if (contraAccounts) {
      let arrCash: any[] = [],
        arrCheck: any[] = [],
        arrCredit: any[] = [],
        arrBank: any[] = [];
      for (let i = 0; i < contraAccounts.length; i++) {
        if (contraAccounts[i].contra_account_group_id === "cash") {
          arrCash.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "cheque") {
          arrCheck.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "credit_card") {
          arrCredit.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
        if (contraAccounts[i].contra_account_group_id === "bank_account") {
          arrBank.push({
            key: contraAccounts[i].id_account,
            text:
              contraAccounts[i].account_name +
              " " +
              contraAccounts[i].account_number,
            groupId: contraAccounts[i].contra_account_group_id,
            accountNumber: contraAccounts[i].account_number,
            is_default: contraAccounts[i].is_default ? true : false,
          });
        }
      }
      setcontraCashAccounts(arrCash);
      setContraCheck(arrCheck);
      setContraCreditCard(arrCredit);
      setcontraBankAccounts(arrBank);
    }
  }, [contraAccounts]);

  useEffect(() => {
    if (enums) {
      let list: any = [];
      let listSomeCurrency: any = [];
      let listAllCurrency: any = [];
      let arr = {
        currencyCode: list,
        someOfcurrency: listSomeCurrency,
        allOfcurrency: listAllCurrency,
        finanssialAssetCode: convertArrToDropdropdown([]),
      };
      for (let i = 0; i < enums.length; i++) {
        if (enums[i].general !== undefined) {
          let s = enums[i].general;
          for (let i = 0; i < s.length; i++) {
            if (s[i].currency_code !== undefined) {
              s[i].currency_code?.forEach((item: any) => {
                arr.allOfcurrency.push({
                  currency_symbol: item?.currency_symbol,
                  key: item?.enum_id,
                  text: item?.enum_value,
                  is_default: item?.is_default,
                });
                if (item?.is_selected) {
                  arr.someOfcurrency.push({
                    currency_symbol: item?.currency_symbol,
                    key: item?.enum_id,
                    text: item?.enum_value,
                    is_default: item?.is_default,
                  });
                }
              });
            }
          }
        }

        if (enums[i].exchange !== undefined) {
          let s = enums[i].exchange;
          for (let i = 0; i < s.length; i++) {
            if (s[i].financial_asset_code !== undefined) {
              arr.finanssialAssetCode = convertArrToDropdropdown(s[i].financial_asset_code)
            }
          }
        }
      }
      arr.someOfcurrency.push({
        currency_symbol: "", key: "More",
        text: t("more"), is_default: false
      })
      arr.allOfcurrency.push({
        currency_symbol: "", key: "Less",
        text: t("less"), is_default: false
      })
      arr = { ...arr, currencyCode: arr.someOfcurrency }
      if (sourcePage === "checkDiscount") {
        let index = arr.finanssialAssetCode.findIndex(item => item.key === '12');
        arr.finanssialAssetCode?.splice(index, 1)
      }
      let defaultfinanssialValue: any
      (defaultfinanssialValue = arr?.finanssialAssetCode.find(({ is_default }: any) => is_default === true))
      let defaultcurrencyCodeValue = arr?.currencyCode.find((item: any) => item?.is_default === true)
      setdefaultCurrencyCode(defaultcurrencyCodeValue && defaultcurrencyCodeValue.key)
      setDefaultfinanssialAsset(defaultfinanssialValue && defaultfinanssialValue.key)
      let financialAssets: any = []
      financialAssets = [...action[tableName]]
      financialAssets[0].financial_asset_code = defaultfinanssialValue?.key
      financialAssets[0].currency_code = defaultcurrencyCodeValue?.key
      setAction({ ...action, [tableName]: financialAssets })
      setenumsDropdown(arr);
    }
  }, [enums, tableName])

  useEffect(() => {
    let financialAssets: any = [...action[tableName]];
    if (
      contraCashAccounts.length > 0 &&
      !financialAssets[0].contra_id_account &&
      defaultfinanssialAsset === "1" &&
      sourcePage !== "logOrder"
    ) {
      let defaultcontraCash = contraCashAccounts.find(
        (item: any) => item.is_default === true
      );
      financialAssets[0].contra_id_account = defaultcontraCash?.key;
      setAction({ ...action, [tableName]: financialAssets });
    }
  }, [contraCashAccounts, tableName]);

  useEffect(() => {
    if (saveDeleteRowActoin === "save") {
      setSaveDeleteRowActoin("");
      setIfChanges && setIfChanges(false);
      deleteRow();
    }
    if (saveDeleteRowActoin === "don't save") setSaveDeleteRowActoin("");
  }, [saveDeleteRowActoin]);

  useEffect(() => {
    if (action?.id_transaction) {
      let copyEnumsDropdown: any = { ...enumsDropdown }
      let listCurrencies = checkCurrencies(copyEnumsDropdown.someOfcurrency, copyEnumsDropdown.allOfcurrency, action)
      copyEnumsDropdown.currencyCode = [...listCurrencies]
      setenumsDropdown(copyEnumsDropdown);
    }
  }, [action])

  const addRow = async (rowNumber: Number) => {
    let arr: any = [];
    arr = [...action[tableName]];
    let defaultcontraCash = contraCashAccounts.find(
      (item: any) => item.is_default === true
    );
    let maxRowNumber = Math.max.apply(
      Math,
      arr.map(function (item: any) {
        return item.row_number;
      })
    );
    if (maxRowNumber === rowNumber) {
      arr.push({
        currency_code: defaultCurrencyCode,
        financial_asset_code: defaultfinanssialAsset,
        amount: 0,
        exchange_rate_ils: 1,
        amount_ils: 0,
        row_number: maxRowNumber + 1,
        contra_id_account:
          defaultfinanssialAsset === "1" && defaultcontraCash?.key,
      });
    }
    setAction({ ...action, [tableName]: arr });
  };

  const removeFObject = (row_number: number) => {
    setRowOfDelete(row_number);
    setShowDialogFailed(true);
    setMessageError(t("DeleteRowMassege"));
  };

  const deleteRow = async () => {
    let arr: any = [], copyAction: any, otherTable
    copyAction = { ...datacConversion }
    otherTable = tableName === "financial_assets_in" ? "financial_assets_out" : "financial_assets_in"
    arr = [...action[tableName]]
    let index = arr.findIndex((a: any) => Number(a.row_number) === Number(rowOfDelete));
    arr.splice(index, 1)
    if (arr.length === 0)
      arr.push({
        currency_code: defaultCurrencyCode, financial_asset_code: defaultfinanssialAsset,
        amount: 0, exchange_rate_ils: 1, amount_ils: 0,
        row_number: 1
      })
    let sum = 0, sumFee = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i].amount_ils ? Number(arr[i].amount_ils) : 0
      if (sourcePage === "checkDiscount") {
        sumFee += arr[i].fee ? Number(arr[i].fee) : 0
      }
    }
    setSum(sum)
    if (sourcePage === "checkDiscount") setSumFee(sumFee)
    setAction({ ...action, [tableName]: arr, "total_amount_ils": sum })
    copyAction[tableName] = arr;
    if (action[otherTable])
      copyAction[otherTable] = [...action[otherTable]]
    if (datacConversion && !isEqual(copyAction[tableName], datacConversion[tableName])) {
      await dispatch(createTransaction("update", typeAction, datacConversion.entity_name, copyAction, userCredentials, action.id_entity))
    }
  }

  const onChangeComboBox = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption,
    index?: number, value?: string, id?: string): void => {
    let val = option?.key
    let key = id ? id : "currency_code"
    let copyEnumsDropDown: any = { ...enumsDropdown }
    if (val === "More" || val === "Less") {
      if (val === "More") {
        copyEnumsDropDown.currencyCode = copyEnumsDropDown?.allOfcurrency
        setenumsDropdown(copyEnumsDropDown)
      }
      if (val === "Less") {
        copyEnumsDropDown.currencyCode = copyEnumsDropDown?.someOfcurrency
        setenumsDropdown(copyEnumsDropDown)
      }
    }
    else
      addFinancialIn(key, val)
  }

  const BalanceDeal = async (amount: any, exchange_rate_ils: any, key: any) => {
    let sumOut = 0,
      sumIn = 0;
    let arrOut: any = [],
      arrIn: any = [];
    arrOut = [...action["financial_assets_out"]];
    arrIn = [...action["financial_assets_in"]];
    sumIn = action?.total_net_amount;
    sumOut = sum + sumFee;
    let balance =
      (sumIn - (sumOut - amount * exchange_rate_ils)) / exchange_rate_ils;
    console.log(balance,"balance");
    balance >= 0 && addFinancialIn(key, balance, true);
  };

  const addFinancialIn = (key: string, value: any, isBalance?: any) => {
    debugger
    const [id, row_number] = key.split(",");
    key = id;
    let arr: any = [],
      sum = 0,
      sumFee = 0;
    arr = [...action[tableName]];
    let index = arr.findIndex(
      (item: any) => Number(item.row_number) === Number(row_number)
    );
    let i = index;
    if (id === "financial_asset_code") {
      setRowNumber(row_number);

      if (value === "12") {
        let defaultcontraCreditCard = contraCreditCard.find(
          (item: any) => item.is_default === true
        );
        if (value !== arr[i]?.financial_asset_code && defaultcontraCreditCard) {
          arr[i].contra_id_account = defaultcontraCreditCard?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCreditCard.length === 1
        ) {
          arr[i].contra_id_account = contraCreditCard[0].key;
        }

        setCredit(true);
        setTableType(tableName);
      }
      if (value === "9") {
        let defaultcontraBankAccount = contraBankAccounts.find(
          (item: any) => item.is_default === true
        );
        if (
          value !== arr[i]?.financial_asset_code &&
          defaultcontraBankAccount
        ) {
          arr[i].contra_id_account = defaultcontraBankAccount?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraBankAccounts.length === 1
        ) {
          arr[i].contra_id_account = contraBankAccounts[0].key;
        }
        setTransferFromCustomer(true);
        setTableType(tableName);
      }

      if (value === "1") {
        let defaultcontraCash = contraCashAccounts.find(
          (item: any) => item.is_default === true
        );
        if (value !== arr[i]?.financial_asset_code && defaultcontraCash) {
          arr[i].contra_id_account = defaultcontraCash?.key;
        } else if (
          value !== arr[i]?.financial_asset_code &&
          contraCashAccounts.length === 1
        ) {
          arr[i].contra_id_account = contraCashAccounts[0].key;
        }

        setTableType(tableName);
        setCashPayment(true);
      }
      if (value === "3") {
        sourcePage === "checkDiscount"
          ? setcheckDetailsOut(true)
          : setCheckDetails(true);
        setTableType(tableName);
      }

      if (arr[index]?.financial_asset_code !== value) {
        arr[index].financial_asset_details = {};
      }
      if (value === datacConversion[tableName][index]?.financial_asset_code) {
        arr[index].financial_asset_details =
          datacConversion[tableName][index].financial_asset_details;
        arr[i].contra_id_account =
          datacConversion[tableName][i]?.contra_id_account;
      }
      if (value === "18")
        arr[index].contra_id_account = searchCustomer?.accounts[0].id_account;
    }
    if (arr.length - 1 >= i) {
      let num = "0";
      if (id === "currency_code" && gates[value]) {
        let num1 = 1 / gates[value];
        num = num1.toFixed(2);
      }
      let temp: any = {
        currency_code: id === "currency_code" ? value : arr[i]?.currency_code,
        contra_id_account:
          id === "contra_id_account" ? value : arr[i]?.contra_id_account,
        financial_asset_code:
          id === "financial_asset_code" ? value : arr[i]?.financial_asset_code,
        amount: id === "amount" ? (value ? value : 0) : arr[i]?.amount,
        exchange_rate_ils:
          id === "exchange_rate_ils"
            ? value
            : id === "currency_code"
              ? parseFloat(num)
              : arr[i]?.exchange_rate_ils,
        amount_ils:
          id === "amount"
            ? value * arr[i]?.exchange_rate_ils
            : id === "exchange_rate_ils"
              ? value * arr[i]?.amount
              : id === "currency_code"
                ? arr[i]?.amount * parseFloat(num)
                : arr[i]?.amount_ils,
        row_number: arr[i]?.row_number,
        financial_asset_details: arr[i]?.financial_asset_details,
      };
      if (sourcePage === "logOrder") {
        temp.accountName = arr[i]?.accountName;
        temp.cr_dr = tableName;
        temp.isDouble = arr[i]?.isDouble;
      }
      if (id === "amount" && isBalance)
        temp.amount = Math.round(value * 100) / 100;
      if (id === "exchange_rate_ils" && isBalance)
        temp.exchange_rate_ils = Math.round(value * 100) / 100;
      if (sourcePage === "checkDiscount") {
        if (id === "fee") {
          temp.fee = value ? value : 0;
          temp.amount_ils = temp.amount_ils - Number(temp.fee);
        } else {
          temp.fee = arr[i]?.fee ? arr[i]?.fee : 0;
          temp.amount_ils = temp.amount_ils - Number(temp?.fee);
        }
      }
      arr[i] = temp;
      for (let i = 0; i < arr.length; i++) {
        sum += arr[i]?.amount_ils ? Number(arr[i]?.amount_ils) : 0;
        if (sourcePage === "checkDiscount")
          sumFee += arr[i]?.fee ? Number(arr[i]?.fee) : 0;
      }
      setSum(sum);
      if (sourcePage === "checkDiscount") setSumFee(sumFee);
    }
    let c: any = action;
    !isEqual(c[key], value) && setIfChanges && setIfChanges(true);
    setAction({ ...action, [tableName]: arr, total_amount_ils: sum });
  };

  const chooseAction = (
    id: any,
    value: any,
    index: any,
    searchList: any,
    place: any
  ) => {
    let arr: any = [],
      arrContra: any = [],
      contraTableName;
    contraTableName = tableName === "debit" ? "credit" : "debit";
    arr = cloneDeep(action[tableName]);
    arrContra = cloneDeep(action[contraTableName]);
    let i = arr.findIndex(
      (item: any) => Number(item.row_number) === Number(index)
    );
    let account = arrContra.findIndex(
      (item: any) => item.contra_id_account === value.id
    );
    if (account !== -1) {
      arr[i].isDouble = true;
    } else {
      arr[i].isDouble = false;
    }
    arr[i].accountName = value.innerHTML;
    ///////////check which id i have to save
    arr[i].contra_id_account = value.id;
    setAction({ ...action, [tableName]: arr });
  };

  const displayDetailsTransaction = (c: any, financialName: string) => {
    setRowNumber(c?.row_number);
    setTableType(financialName);
    if (c?.financial_asset_code === "12") {
      setCredit(true);
    }
    if (c?.financial_asset_code === "9") {
      setTransferFromCustomer(true);
    }
    if (c?.financial_asset_code === "1") {
      setCashPayment(true);
    }
    if (c?.financial_asset_code === "3") {
      setcheckDetailsOut(true);
    }
  };

  const checkRequiredFields = (element: any) => {
    if (
      element?.financial_asset_code === "3" &&
      (!element?.financial_asset_details?.cheque_date ||
        !element?.financial_asset_details?.reference_number ||
        !element.amount)
    )
      return true;
    if (
      element?.financial_asset_code === "12" &&
      !element?.financial_asset_details?.card_holder?.ID_number
    )
      return true;
    if (
      element?.financial_asset_code === "9" &&
      !element?.financial_asset_details?.account_holder?.ID_number
    )
      return true;
    return false;
  };

  return (
    <>
      {showDialogFailed && (
        <DialogMessages
          setansDelete={setShowDialogFailed}
          subText={messageError}
          title={t("note")}
          setshowModalDelete={setShowDialogFailed}
          dialogType={"dialogMessage"}
          setSaveDataConversion={setSaveDeleteRowActoin}
        />
      )}

      {credit &&
        sourcePage !== "currencyEntry" &&
        sourcePage !== "checkDiscount" ? (
        <CustomDialogCreditFromCustomer
          typeAction={typeAction}
          contraCreditCard={contraCreditCard}
          conversionCustomer={searchCustomer}
          setConvertions={setAction}
          convertions={action}
          rowNumber={rowNumber}
          tableType={tableType}
          setShowDialog={setCredit}
          userCredentials={userCredentials}
          emptyRequiredFields={emptyRequiredFields}
        />
      ) : (
        ""
      )}

      {checkDetails && sourcePage !== "currencyEntry" ? (
        <CheckDetailsDialog
          typeAction={typeAction}
          contraCheck={contraCheck}
          setIfChanges={setIfChanges}
          ifChanges={ifChanges}
          sourcePage={sourcePage}
          conversionCustomer={searchCustomer}
          setConvertions={setAction}
          convertions={action}
          rowNumber={rowNumber}
          tableType={tableType}
          setShowDialog={setCheckDetails}
          userCredentials={userCredentials}
          emptyRequiredFields={emptyRequiredFields}
        />
      ) : (
        ""
      )}

      {checkDetailsOut && sourcePage !== "currencyEntry" ? (
        <CheckToCustomerDialog
          typeAction={typeAction}
          conversionCustomer={searchCustomer}
          setConvertions={setAction}
          convertions={action}
          rowNumber={rowNumber}
          tableType={tableType}
          setShowDialog={setcheckDetailsOut}
          userCredentials={userCredentials}
        />
      ) : (
        ""
      )}

      {TransferFromCustomer && sourcePage !== "currencyEntry" && (
        <CustomDialogTransferFromCustomer
          emptyRequiredFields={emptyRequiredFields}
          contraBankAccounts={contraBankAccounts}
          typeAction={typeAction}
          conversionCustomer={searchCustomer}
          rowNumber={rowNumber}
          tableType={tableType}
          userCredentials={userCredentials}
          setConvertions={setAction}
          convertions={action}
          setShowDialog={setTransferFromCustomer}
        />
      )}
      {cashPayment && sourcePage !== "currencyEntry" ? (
        <CashPaymentDialog
          setIfChanges={setIfChanges}
          setConvertions={setAction}
          contraCash={contraCashAccounts}
          convertions={action}
          rowNumber={rowNumber}
          tableType={tableType}
          setShowDialog={setCashPayment}
        />
      ) : (
        ""
      )}

      <p className="title-text-2">
        {tableName === "financial_assets_in"
          ? t("in")
          : tableName === "financial_assets_out"
            ? t("out")
            : tableName === "credit"
              ? t("credit")
              : tableName === "debit"
                ? t("debit")
                : ""}
      </p>

      <hr className="hr" style={{ width: `${widthContainer}` }}></hr>
      <div style={{ width: `${widthContainer}` }}>
        <div style={{ width: "100%" }}>
          <div
            style={{
              backgroundColor: "#E1E1E1",
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              gridGap: "6px",
              gap: "6px",
            }}
          >
            <p className={`file-type-${dir}`} style={{ width: arrWidth[0] }}>
              {t("currency")}
            </p>
            <p className={`file-type-${dir}`} style={{ width: arrWidth[1] }}>
              {sourcePage === "currencyEntry"
                ? t("cash")
                : sourcePage === "logOrder"
                  ? t("account")
                  : t("type")}
            </p>
            {sourcePage === "checkDiscount" && (
              <p
                className={`padding-input-${dir}`}
                style={{ width: "4.5%", marginBottom: "0" }}
              >
                {" "}
              </p>
            )}
            <p className={`file-type-${dir}`} style={{ width: arrWidth[2] }}>
              {t("gate")}
            </p>
            <p className={`file-type-${dir}`} style={{ width: arrWidth[3] }}>
              {t("amount")}
            </p>
            {sourcePage === "checkDiscount" && (
              <p style={{ width: arrWidth[4], display: 'none' }} className={`fee-${dir}`}>
                {t("fee")}
              </p>
            )}
            <p style={{ width: arrWidth[5] }} className={`total-ils-${dir}`}>
              {t("totalILS")}
            </p>
          </div>
        </div>
        <hr
          className="hr"
          style={{ width: "100%", height: "2px", marginBottom: "0" }}
        ></hr>
        {action[tableName]?.map((c: any, index: number) => (
          <div key={index} style={{ width: "100%" }}>
            <div
              className={`${sourcePage === "checkDiscount" ? "inlineB" : "inlineB"
                }`}
              style={{ marginTop: "7px", height: "33px" }}
            >
              <div style={{ width: `${arrWidth[0]}` }}>
                {!readOnly ? (
                  <ComboBox
                    id={`currency_code,${c.row_number}`}
                    label={t("")}
                    required={true}
                    selectedKey={c?.currency_code}
                    autoComplete="on"
                    allowFreeform={true}
                    options={enumsDropdown.currencyCode}
                    onChange={(
                      event: any,
                      option: any,
                      index: any,
                      value: any
                    ) =>
                      onChangeComboBox(
                        event,
                        option,
                        index,
                        value,
                        `currency_code,${c.row_number}`
                      )
                    }
                    styles={
                      dir === "ltr"
                        ? {
                          root: {
                            paddingRight: "9px !important",
                            paddingLeft: "32px !important",
                          },
                        }
                        : {}
                    }
                  />
                ) : (
                  <CustomDropdown
                    onKeyDown={(e: any) => e.key === "Tab"}
                    readOnly={readOnly}
                    otherInputId={""}
                    hasOtherValue={false}
                    options={enumsDropdown.currencyCode}
                    label={t("")}
                    onChange={addFinancialIn}
                    selectedKey={c?.currency_code}
                    id={`currency_code,${c.row_number}`}
                    othertextInput={t("")}
                  />
                )}
              </div>
              {sourcePage === "logOrder" ? (
                <div
                  style={{
                    height: "35px",
                    border:
                      ((c?.isDouble && isDoubleId) ||
                        (emptyOutContraAccount && !c.contra_id_account)) &&
                      "1px solid red",
                  }}
                >
                  <DialogSearch
                    index={c.row_number}
                    setSearch_fields={setSearch_fields}
                    chosenAction={c?.accountName}
                    type="searchAccount"
                    userCredentials={userCredentials}
                    onChooseFunc={chooseAction}
                  />
                </div>
              ) : (
                <div style={{ width: `${arrWidth[1]}` }}>
                  <CustomDropdown
                    onKeyDown={(e: any) => e.key === "Tab"}
                    readOnly={readOnly}
                    otherInputId={""}
                    hasOtherValue={false}
                    requiredField={
                      (emptyOutContraAccount && !c.contra_id_account) ||
                        (checkRequiredFields(c) && emptyRequiredFields)
                        ? true
                        : false
                    }
                    options={
                      sourcePage === "currencyEntry"
                        ? contraCashAccounts
                        : enumsDropdown.finanssialAssetCode
                    }
                    label={t("")}
                    onChange={addFinancialIn}
                    selectedKey={
                      sourcePage === "currencyEntry"
                        ? c?.contra_id_account
                        : c.financial_asset_code
                    }
                    id={
                      sourcePage === "currencyEntry"
                        ? `contra_id_account,${c.row_number}`
                        : `financial_asset_code,${c.row_number}`
                    }
                    othertextInput={t("")}
                  />
                </div>
              )}

              {sourcePage === "checkDiscount" && (
                <div
                  className="scan-div-con"
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                >
                  <i
                    title="הצג פרטים"
                    onClick={() => {
                      !readOnly &&
                        displayDetailsTransaction(c, "financial_assets_out");
                    }}
                  >
                    [...]
                  </i>
                </div>
              )}
              <CurrencyInput
                id={`exchange_rate_ils,${c.row_number}`}
                name={`exchange_rate_ils,${c.row_number}`}
                placeholder="0.00"
                className={`padding-currency-${dir} currency-input`}
                value={c?.exchange_rate_ils}
                tabIndex={-1}
                allowNegativeValue={false}
                readOnly={
                  c?.currency_code === defaultCurrencyCode ? true : readOnly
                }
                style={{
                  backgroundColor: `${readOnly ? "#FAF9F8" : "white"}`,
                  width: `${arrWidth[2]}`,
                }}
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  addFinancialIn(name || "", value)
                }
              />
              <CurrencyInput
                className={`discount-amount-out padding-currency-${dir}
                              currency-input ${emptyOutAmount &&
                  !c?.amount &&
                  `empty-amount-${dir}`
                  }`}
                id={`amount,${c?.row_number}`}
                name={`amount,${c?.row_number}`}
                placeholder="0.00"
                value={c?.amount}
                readOnly={readOnly}
                allowNegativeValue={false}
                required={emptyOutAmount && !c?.amount ? true : false}
                style={
                  emptyOutAmount && !c?.amount
                    ? {
                      width: `${arrWidth[3]}`,
                    }
                    : {
                      width: `${arrWidth[3]}`,
                      backgroundColor: readOnly ? "#FAF9F8" : "white",
                    }
                }
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  addFinancialIn(name || "", value)
                }
              />
              {sourcePage === "checkDiscount" && (
                <p
                  className={`p-check-discount-${dir}`}
                  onClick={() =>{
                    console.log(c?.exchange_rate_ils,"c?.exchange_rate_ils");
                    console.log(c?.amount,"c?.amount");    

                     c?.exchange_rate_ils &&
                    BalanceDeal(
                      c?.amount,
                      c?.exchange_rate_ils,
                      `amount,${c.row_number}`
                    
                    )
                  }
                   
                  }
                >
                  <FontAwesomeIcon
                    icon={faCalculator}
                    title={t("balanceTransaction")}
                    onKeyDown={(e) =>
                      e.key === "Enter" &&
                      c?.exchange_rate_ils &&
                      BalanceDeal(
                        c?.amount,
                        c?.exchange_rate_ils,
                        `amount,${c.row_number}`
                      )
                    }
                    className="iconBalance"
                  />
                </p>
              )}
              {sourcePage === "checkDiscount" && (
                <CurrencyInput
                  id={`fee,${c?.row_number}`}
                  className={`padding-currency-${dir} currency-input`}
                  name={`fee,${c?.row_number}`}
                  placeholder="0.00"
                  value={c?.fee}
                  allowNegativeValue={false}
                  readOnly={readOnly}
                  style={{
                    display: 'none',
                    width: `${arrWidth[4]}`,
                    backgroundColor: readOnly ? "#FAF9F8" : "white",
                  }}
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    addFinancialIn(name || "", value)
                  }
                />
              )}
              <div style={{ width: `${arrWidth[5]}` }}>
                <CustomTextField
                  label={""}
                  value={
                    Number(c?.amount_ils)
                      ? Number(c?.amount_ils)
                        .toFixed(2)
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0
                  }
                  readOnly={true}
                  onChange={addFinancialIn}
                  styleObj={"bold"}
                  id={`amount_ils,${c.row_number}`}
                  requiredField={isNegativeBalance && c?.amount_ils === 0}
                />
              </div>
              <p
                style={{
                  display: "grid",
                  paddingTop: "0",
                  textAlign: "center",
                  lineHeight: "0.8",
                  marginBottom: "0",
                  width: "fit-content",
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className={`delete-row-icon-${dir}`}
                  title={t("deleteRow")}
                  onClick={() => removeFObject(c.row_number)}
                />
                {c.amount_ils > 0 && (
                  <FontAwesomeIcon
                    onKeyDown={(e) => e.key === "Enter" && addRow(c.row_number)}
                    className={
                      c.amount_ils > 0
                        ? `iconRefresh-${dir} add-row-icon-${dir}`
                        : `iconDisabled add-row-icon-${dir}`
                    }
                    icon={faPlusCircle}
                    title={t("addRow")}
                    onClick={() => {
                      addRow(c.row_number);
                    }}
                  />
                )}
              </p>
            </div>
          </div>
        ))}
        <hr
          className="hr"
          style={{ width: "100%", marginTop: "3px", marginBottom: "0" }}
        ></hr>

        {sourcePage === "checkDiscount" && (
          <div style={{ justifyContent: "flex-end" }}>
            <div className={`calc-discount-2-${dir}`}>
              {sum !== 0 && (
                <div className={`title-calc-discount-out-${dir}`}>
                  {t("total")}:{" "}
                </div>
              )}
              {sum !== 0 && (
                <div className="total-calc-discount-out">
                  <div className={`ltr-${dir}`}>
                    {sum?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </div>
                  &nbsp;{t("ILS")}
                </div>
              )}
              {sum === 0 && (
                <div className={`title-calc-discount-out-${dir}`}>
                  {" "}
                  {t("total")}:{" "}
                </div>
              )}
              {sum === 0 && (
                <div className="total-calc-discount-out">0.00 {t("ILS")}</div>
              )}
            </div>
            <div className={`calc-discount-${dir}`}>
              {(sumFee !== 0 || sum !== 0) && (
                <div className={`title-calc-discount-out-${dir}`}>
                  {t("totalBeforeFees")}
                </div>
              )}
              {(sumFee !== 0 || sum !== 0) && (
                <div className="total-calc-discount-out">
                  {(Number(sum) + Number(sumFee))
                    .toFixed(2)
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  &nbsp;{t("ILS")}
                </div>
              )}
              {sumFee === 0 && sum === 0 && (
                <div className={`title-calc-discount-out-${dir}`}>
                  {t("totalBeforeFees")}
                </div>
              )}
              {sumFee === 0 && sum === 0 && (
                <div className="total-calc-discount-out">0.00 {t("ILS")}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default FinancialAssets;
