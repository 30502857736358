import axios from "axios";

import { basicUrl } from "../../shared/config";
import { IUserCredentials } from "../../components/customerDetails/CustomerDetails";
import moment from "moment";
import JSZip from "jszip";
import { useTranslation } from 'react-i18next';
import { dataURLtoFile } from "../../shared/components/CustomHooks/GlobalFunctions";


export const uploadFile = (idEntity: any, userCredentials: IUserCredentials | null, mediFile: any,
    fileData: any, setFileData?: any, setshowSpiner?: any, setShowDialogSave?: any, setMessage?: any, t?: any) => async (dispatch: Function) => {
        const file: any = {
            "IdEntity": idEntity,
            "IdClient": userCredentials ? userCredentials["idClient"] : "",
            "IdInitiator": userCredentials ? userCredentials["idInitiator"] : "",
            "StatusId": fileData?.media_status_id,
            "TypeId": fileData?.media_type_id,
            "MediaName": fileData?.media_name,
            "media_extension_file_name": fileData?.media_extension_file_name,
            "MediaExternalFileName": "IMPAAAA",
            "MediaExpDate": fileData?.media_exp_date ? fileData?.media_exp_date : "2200-01-01",
            "MediaDetails": fileData?.media_details,
            "MediaFile": mediFile,
        }
        var formData = new FormData();
        for (var key in file) {

            formData.append(key, file[key]);
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        await axios({
            method: 'post',
            url: basicUrl + '/Media/Upload',
            data: formData,
            headers: {
                'Content-Type': `multipart/form-data`,
                'Authorization': `Bearer ${token}`

            },
        }).then(async res => {
            try {
                if (res.status === 200) {
                    setshowSpiner && setshowSpiner(false)
                    if (res.data["err_code"] === 0) {
                        setFileData({ ...fileData, id_media: res.data.data?.id_media })
                        setShowDialogSave && setShowDialogSave(true)
                        setMessage && setMessage(t("theFileSuccessfullyAttached"))
                    }
                }
                else {
                    setShowDialogSave && setShowDialogSave(true)
                    setMessage && setMessage(t("FileAttachmentFailed"))
                }
            }
            catch (err) {
                console.log('error in attach Insider ToCustomer', res)
                setShowDialogSave && setShowDialogSave(true);
                setMessage && setMessage(t("FileAttachmentFailed"), err)
                setshowSpiner && setshowSpiner(false)

            }
        }).catch(err => {
            if (err?.response?.data.err_code === "50013") {
                setMessage && setMessage(t("fileSizeExceededMassege"))
                alert(t("fileSizeExceededMassege"))
            }
            else {
                if (err?.response?.data.err_code === "50014") {
                    setMessage && setMessage(t("fileTypeInvalidMassega"))
                    alert(t("fileTypeInvalidMassega"))
                }
            }
            setShowDialogSave && setShowDialogSave(true);
            setshowSpiner && setshowSpiner(false)
            console.log("error in upload file", err)
        })
    }



export const deteleFile = (idEntity: any, idMedia: any, userCredentials: any) => async (dispatch: Function) => {
    const fileDelete: any = {
        "id_entity": idEntity,
        "id_client": userCredentials ? userCredentials["idClient"] : "",
        "id_media": idMedia,
        "id_initiator": userCredentials ? userCredentials["idInitiator"] : "",
        "media_request_method": "delete"
    }
    let token = sessionStorage.getItem("token")
    console.log(token,"token-------");
    await axios({
        method: 'post',
        url: basicUrl + '/Media/Delete',
        data: fileDelete,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

        },
    }).then(async res => {
        try {
            if (res.status === 200) {
                if (res.data["err_code"] === 0) {
                }
            }
        }
        catch (err) {
            console.log('error in attach Insider ToCustomer',)
        }
    }).catch(err => {
        console.log("error in upload file", err)
    })
}


export const uploadCheckFile = (idEntity: any, userCredentials: IUserCredentials | null, mediFile: any,
    fileData?: any, setFileData?: any, fileName?: any, source?: any) => async (dispatch: Function) => {
        console.log("12345678");
        const file: any = {
            IdClient: userCredentials?.idClient,
            IdInitiator: userCredentials?.idInitiator,
            IdEntity: idEntity,
            MediaDetails: "שיק",
            MediaExpDate: moment(new Date()).format('YYYY-MM-DD'),
            MediaExternalFileName: "IMPAAAA",
            MediaFile: mediFile,
            MediaName: fileName,
            StatusId: "1",
            TypeId: "1",
        }
        console.log(file, "___file");

        var formData = new FormData();
        for (var key in file) {
            formData.append(key, file[key]);
        }
        debugger;
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        await axios({
            method: 'post',
            url: basicUrl + '/Media/Upload',
            data: formData,
            headers: {
                'Content-Type': `multipart/form-data`,
                'Authorization': `Bearer ${token}`

            },
        }).then(async res => {
            try {
                debugger;
                if (res.status === 200) {
                    console.log(res.status, "res.status");
                    console.log(res.data["err_code"], "res.status");

                    let source2 = source === "id_media_face" ? "media_face" :
                        "media_back"
                    if (res.data["err_code"] === 0) {
                        let media: any = { ...fileData }
                        media[source2] = {
                            id_media: res.data.data?.id_media,
                            media_extension_file_name: res.data.data?.media_extension_file_name
                        }
                        console.log(media, "media");
                        setFileData(media)
                    }
                    else {
                        console.log("err");

                    }
                } else {
                    console.log("err2");

                }
            }
            catch (err) {
                console.log('rror in upload filer', err)
            }
        }).catch(err => {
            console.log("error in upload file", err)
        })
    }

export const uploadMultiScanChecks = (idEntity: any, userCredentials: IUserCredentials | null, arrChecks: any, setArrChecks: any, checkType: string) => async (dispatch: Function) => {
    const copyArrChecks = [...arrChecks];
    console.log(copyArrChecks, "copyArrChecks");
    debugger
    const promisesFront = copyArrChecks.map(async (check: any, index: number) => {
        let mediaFile;
        let fileName;
        if (checkType === 'US') {
            mediaFile = dataURLtoFile(`data:image/jpeg;base64,${check.imageFront}`, "id_media_face.jpeg");
            fileName = `${check.micr?.accountNumber ? check.micr?.accountNumber : 0}-${check.micr?.routingNumber ? check.micr?.routingNumber : 0}-${check.micr?.routingNumber?.checkNumber ? check.micr?.routingNumber?.checkNumber : 0}`
        }
        if (checkType == "IL") {
            mediaFile = dataURLtoFile(`data:image/jpeg;base64,${check.media?.media_face}`, "id_media_face.jpeg");
            fileName = `${check.accountNumber ? check.micr?.accountNumber : 0}-${check.routingNumber}-${check.routingNumber}`
        }
        const file: any = {
            IdClient: userCredentials?.idClient,
            IdInitiator: userCredentials?.idInitiator,
            IdEntity: idEntity,
            MediaDetails: "שיק",
            MediaExpDate: moment(new Date()).format('YYYY-MM-DD'),
            MediaExternalFileName: "IMPAAAA",
            MediaFile: mediaFile,
            MediaName: fileName,
            StatusId: "1",
            TypeId: "1",
        }
        var formData = new FormData();
        for (var key in file) {
            formData.append(key, file[key]);
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const response = await axios({
            method: 'post',
            url: basicUrl + '/Media/Upload',
            data: formData,
            headers: {
                'Content-Type': `multipart/form-data`,
                'Authorization': `Bearer ${token}`

            }
        });
        if (response.status === 200) {
            console.log(response.status);
            console.log(response.data["err_code"], "response.data[");


            if (response.data["err_code"] === 0) {
                console.log(copyArrChecks, "copyArrCheckscopyArrCheckscopyArrChecks");

                copyArrChecks[index] = {
                    ...copyArrChecks[index], mediaFace: {
                        id_media: response.data.data?.id_media,
                        media_extension_file_name: response.data.data?.media_extension_file_name
                    }
                }
            }


            // const mediaFile = dataURLtoFile(`data:image/jpeg;base64,${check.imageFront}`, "id_media_face.jpeg");
            // const fileName = `${check.micr?.accountNumber ? check.micr?.accountNumber : 0}-${check.micr?.routingNumber ? check.micr?.routingNumber : 0}-${check.micr?.routingNumber?.checkNumber ? check.micr?.routingNumber?.checkNumber : 0}`
            // const file: any = {
            //     IdClient: userCredentials?.idClient,
            //     IdInitiator: userCredentials?.idInitiator,
            //     IdEntity: idEntity,
            //     MediaDetails: "שיק",
            //     MediaExpDate: moment(new Date()).format('YYYY-MM-DD'),
            //     MediaExternalFileName: "IMPAAAA",
            //     MediaFile: mediaFile,
            //     MediaName: fileName,
            //     StatusId: "1",
            //     TypeId: "1",
            // }
            // var formData = new FormData();
            // for (var key in file) {
            //     formData.append(key, file[key]);
            // }
            // const response = await axios({
            //     method: 'post',
            //     url: basicUrl + '/Media/Upload',
            //     data: formData,
            //     headers: {
            //         'Content-Type': `multipart/form-data`,
            //     }
            // });
            // if (response.status === 200) {
            //     console.log(response.status);
            //     console.log(response.data["err_code"],"response.data[");


            //     if (response.data["err_code"] === 0) {
            //         console.log(copyArrChecks,"copyArrCheckscopyArrCheckscopyArrChecks");

            //         copyArrChecks[index] = {
            //             ...copyArrChecks[index], mediaFace: {
            //                 id_media: response.data.data?.id_media,
            //                 media_extension_file_name: response.data.data?.media_extension_file_name
            //             }
            //         }
            //     }
        }
        else {
            console.log("errrrreee");

        }
    });

    const promisesBack = copyArrChecks.map(async (check: any, index: number) => {
        console.log(check, "check::;s");
        let mediaFile, fileName;
        if (checkType === 'US') {
            mediaFile = dataURLtoFile(`data:image/jpeg;base64,${check.imageBack}`, "id_media_back.jpeg");
            fileName = `${check.micr?.accountNumber ? check.micr?.accountNumber : 0}-${check.micr?.routingNumber ? check.micr?.routingNumber : 0}-${check.micr?.routingNumber?.checkNumber ? check.micr?.routingNumber?.checkNumber : 0}`
        }
        if (checkType == "IL") {
            mediaFile = dataURLtoFile(`data:image/jpeg;base64,${check.media.media_back}`, "id_media_back.jpeg");
            fileName = `${check.accountNumber ? check.micr?.accountNumber : 0}-${check.routingNumber}-${check.routingNumber}`
        }
        const file: any = {
            IdClient: userCredentials?.idClient,
            IdInitiator: userCredentials?.idInitiator,
            IdEntity: idEntity,
            MediaDetails: "שיק",
            MediaExpDate: moment(new Date()).format('YYYY-MM-DD'),
            MediaExternalFileName: "IMPAAAA",
            MediaFile: mediaFile,
            MediaName: fileName,
            StatusId: "1",
            TypeId: "1",
        }
        var formData = new FormData();
        for (var key in file) {
            formData.append(key, file[key]);
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const response = await axios({
            method: 'post',
            url: basicUrl + '/Media/Upload',
            data: formData,
            headers: {
                'Content-Type': `multipart/form-data`,
                'Authorization': `Bearer ${token}`

            }
        });
        console.log(response, "response---");
        console.log(response.status, "status-----");
        if (response.status === 200) {
            if (response.data["err_code"] === 0) {
                copyArrChecks[index] = {
                    ...copyArrChecks[index], mediaBack: {
                        id_media: response.data.data?.id_media,
                        media_extension_file_name: response.data.data?.media_extension_file_name
                    }
                }
            }
        }
        else {
            console.log(response, "response---");
            console.log(response.status, "status-----");
        }

        // const mediaFile = dataURLtoFile(`data:image/jpeg;base64,${check.imageBack}`, "id_media_back.jpeg");
        // const fileName = `${check.micr?.accountNumber ? check.micr?.accountNumber : 0}-${check.micr?.routingNumber ? check.micr?.routingNumber : 0}-${check.micr?.routingNumber?.checkNumber ? check.micr?.routingNumber?.checkNumber : 0}`
        // const file: any = {
        //     IdClient: userCredentials?.idClient,
        //     IdInitiator: userCredentials?.idInitiator,
        //     IdEntity: idEntity,
        //     MediaDetails: "שיק",
        //     MediaExpDate: moment(new Date()).format('YYYY-MM-DD'),
        //     MediaExternalFileName: "IMPAAAA",
        //     MediaFile: mediaFile,
        //     MediaName: fileName,
        //     StatusId: "1",
        //     TypeId: "1",
        // }
        // var formData = new FormData();
        // for (var key in file) {
        //     formData.append(key, file[key]);
        // }
        // const response = await axios({
        //     method: 'post',
        //     url: basicUrl + '/Media/Upload',
        //     data: formData,
        //     headers: {
        //         'Content-Type': `multipart/form-data`,
        //     }
        // });
        // console.log(response,"response---");
        // console.log(response.status,"status-----");
        // if (response.status === 200) {
        //     if (response.data["err_code"] === 0) {
        //         copyArrChecks[index] = {
        //             ...copyArrChecks[index], mediaBack: {
        //                 id_media: response.data.data?.id_media,
        //                 media_extension_file_name: response.data.data?.media_extension_file_name
        //             }
        //         }
        //     }
        // }
        // else{
        //     console.log(response,"response---");
        //     console.log(response.status,"status-----"); 
        // }
    });

    try {
        await Promise.all(promisesFront);
        await Promise.all(promisesBack);

    } catch (err) {
        console.log('err', err);

    }
    finally {
        setArrChecks(copyArrChecks);
    }
}


export const downloadFile = (idEntity: any, userCredentials: IUserCredentials | null, idMedia: any, mediaName: any,
    mediaType: any) => async (dispatch: Function) => {

        const download = {
            "id_media": idMedia,
            "id_entity": idEntity,
            "id_client": userCredentials && userCredentials["idClient"],
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "media_request_method": "read"
        }
        const body = JSON.stringify(download);
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        await axios({
            method: 'post',
            url: basicUrl + '/media/download',
            data: body,
            headers: {
                Accept: 'application/octet-stream',
                'Content-Type': 'application/octet-stream',
                'Authorization': `Bearer ${token}`

            },
            responseType: "blob"

        }).then(async (res: any) => {
            try {
                if (res.status === 200) {

                    const url = window.URL.createObjectURL(res.data);
                    let link: any
                    link = document.createElement('a')
                    link.href = url;
                    link.setAttribute('download', `${mediaName}.${mediaType}`);
                    document.body.appendChild(link);
                    link.click();
                }
            }
            catch (err) {
                alert("שגיאה בהורדת הקובץ נסה שנית")
                console.log('error in download', err)
            }
        }).catch(err => {
            alert("שגיאה הקובץ לא נימצא")
            console.log("error in download file", err)
        })
    }
export const previewFile = (idEntity: any, userCredentials: IUserCredentials | null, idMedia: any, mediaName: any,
    mediaType: any,) => async (dispatch: Function) => {

        const download = {
            "id_media": idMedia,
            "id_entity": idEntity,
            "id_client": userCredentials && userCredentials["idClient"],
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "media_request_method": "read"
        }
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        const body = JSON.stringify(download);
        await axios({
            method: 'post',
            url: basicUrl + '/media/download',
            data: body,
            headers: {
                Accept: 'application/octet-stream',
                'Content-Type': 'application/octet-stream',
                'Authorization': `Bearer ${token}`

            },
            responseType: "blob"

        }).then(async (res: any) => {
            try {
                if (res.status === 200) {
                    let type = mediaType === "pdf" ? `application/${mediaType}` : `image/${mediaType}`
                    var file = new Blob([res.data], { type: type });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL)
                }
            }
            catch (err) {
                alert("שגיאה בתצוגת הקובץ נסה שנית")
                console.log('error in download', err)
            }
        }).catch(err => {
            alert("שגיאה הקובץ לא נימצא")
            console.log("error in download file", err)
        })
    }

export const reportsDownloadFile = (idEntity: any, userCredentials: IUserCredentials | null,
    idMedia: any, mediaName: any, arrBlob: any[], fileData: any, zip: any, t?: any) => async (dispatch: Function) => {
        let userU = userCredentials && userCredentials["idClient"];
        debugger;
        const download = {
            "id_media": idMedia,
            "id_entity": idEntity,
            "id_client": userCredentials && userCredentials["idClient"],
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "media_request_method": "read"

        }
        const body = JSON.stringify(download);
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");

        const res = await axios({
            method: 'post',
            url: basicUrl + '/media/download',
            data: body,
            headers: {
                Accept: 'application/octet-stream',
                'Content-Type': 'application/octet-stream',
                'Authorization': `Bearer ${token}`

            },
            responseType: "blob"

        }).then(async (res) => {


            if (res.status === 200) {
                arrBlob.push(mediaName)
                zip.folder('attachments').file(mediaName, res.data, { binary: true });
                console.log(zip, "zip");

            }
            if (arrBlob?.length === fileData?.attachments?.length) {
                const content = await zip.generateAsync({ type: "blob" });
                const fileName = `${fileData?.zip_file_name}`;
                return saveAs(content, fileName)
            }
            if (res.status === 404) {
                console.log("404");

                arrBlob.push(mediaName)
                zip.folder('attachments').file(mediaName, res.data, { binary: true });
            }
        })
            .catch(async err => {
                arrBlob.push(mediaName)
                zip.folder('attachments').file(mediaName, null, { binary: true })
                // alert("לא נמצאו כל הקבצים")

            })
    }


export const reportUnRegularDownloadFile = (idEntity: any, userCredentials: IUserCredentials | null,
    idMedia: any) => async (dispatch: Function) => {
        debugger
        const download = {
            "id_media": idMedia,
            "id_entity": idEntity,
            "id_client": userCredentials && userCredentials["idClient"],
            "id_initiator": userCredentials && userCredentials["idInitiator"],
            "media_request_method": "read"
        }
        const body = JSON.stringify(download);
        let token = sessionStorage.getItem("token")
        console.log(token,"token-------");
        try {
            const data = await axios({
                method: 'post',
                url: basicUrl + '/media/download',
                data: body,
                headers: {
                    Accept: 'application/octet-stream',
                    'Content-Type': 'application/octet-stream',
                    'Authorization': `Bearer ${token}`

                },
                responseType: "blob"
            })
            return data?.data
        }
        catch (err: any) {
            console.log("err");
        }
    }

export const txtFilesByZip = () => async (dispatch: Function) => {
    const json = {
        "m1": {
            "file_name": "10_10_2022_1.txt",
            "data": [
                {
                    "f1": "10000       ",
                    "f2": "משה לוי          ",
                    "f3": "2022-10-10       "
                },
                {
                    "f1": "10          ",
                    "f2": "איתמר לוי         ",
                    "f3": "2023-10-10       "
                }
            ]
        },
        "m2": {
            "file_name": "10_10_2022_2.txt",
            "data": [
                {
                    "f1": "10000       ",
                    "f2": "משה לוי          ",
                    "f3": "2022-10-10       "
                },
                {
                    "f1": "10       ",
                    "f2": "איתמר לוי       ",
                    "f3": "2023-10-10       "
                }
            ]
        }
    }
    let arr: any = []
    let zip = new JSZip();
    Object.values(json).map((key: any) => {
        arr.push(key)
    })
    arr.map((item: any) => {
        let arrfiles: any = [], file: any
        item?.data.map((item: any) => {
            let names: any = []
            Object.values(item).map((key: any) => {
                names.push(key)
            })
            names.map((item: any) => {
                file = file ? file + item : item
            })
            arrfiles.push(file)
            file = ""
            arrfiles.push("\r\n")
        })
        const blob = new Blob(arrfiles, { type: "text/plain;" });
        zip.file(item?.file_name, blob, { binary: true });

    })
    const content = await zip.generateAsync({ type: "blob" });
    const currentDate = new Date().getTime();
    const fileName = `zipped-${currentDate}.zip`;

    return saveAs(content, fileName);
}