import React, { useEffect, useState } from 'react'
import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils'
import Subtitle from '../../shared/components/Subtitle'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CustomDropdown } from '../../shared/components/Option'
import { IDropdownOption, PrimaryButton } from '@fluentui/react'
import { CustomTextField } from '../../shared/components/TextField'
import { CustomerDate } from '../../shared/components/CustomHooks/concatTextFields'
import DialogSearch from '../global/DialogSearch'
import { IUserCredentials, convertArrToDropdropdown } from '../customerDetails/CustomerDetails'
import { useLocation } from 'react-router'
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions'
import { getUnRportsData } from '../../store/actions/reportsAction'
import { RESET_DATA_RESULTS_UN_REGULAR_REPORTS, SET_DATA_RESULTS_UN_REGULAR_REPORTS } from '../../store/actions/types'
import DialogProduceUnRegularReports from './DialogProduceUnRegularReports'
import UnRegularReportsTable from './UnRegularReportsTable'

import { ISpinnerStyles, Spinner } from '@fluentui/react/lib/Spinner';
import { DialogMessages } from '../../shared/components/dialog/DialogMessages'
import moment from 'moment'
import ExportUnRegularReports from './ExportUnRegularReports'

const spinnerStyles: Partial<ISpinnerStyles> = {
    root: {
        position: 'fixed',
        zIndex: '999999',
        fontWeight: 'bold',
    },
    circle: {
        color: '#FFB400 ',
        height: '100px',
        width: '100px',
    },
}

export default function UnRegularReports() {
    const dispatch = useDispatch()
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const dataTableUnRegularReports = useSelector(({ reportsReducer }: any) => reportsReducer?.dataRsultsUnRegularReports)
    const branchSettings = useSelector(({ authReducer }: any) => authReducer?.data?.branch?.setting)
    const [allChecked, setAllChecked] = useState<any>(false)
    const [showSpiner, setShowSpiner] = useState(false)
    const [messageError, setMessageError] = useState('')
    const [serverMessageError, setServerMessageError] = useState<any>(false)
    const [titleMessageError, setTitleMessageError] = useState('')
    const location = useLocation()
    const [readOnly, setReadOnly] = useState(false)
    const [unRegularReportsData, setUnRegularReportsData] = useState<any>({
        dateStart: "", dateEnd: "", reportType: "", rangeTimeAll: false, arrEntitiesToReports: [], arrEntitiesData: [],
        arrTransactionsToReports: [], duration_days: "", amount_threshold: "", amount_from: "",
        amount_to: "", impa_utr_characteristics: "", impa_utr_essence: "", month: moment().month() < 10 ? "0" + String(moment().month()) : String(moment().month()),
        year: String(moment().year())
    })
    const [produceReport, setProduceReport] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const enums = useSelector(({ dataReducer }: any) => dataReducer.enums)
    const [enumsDropdown, setenumsDropdown] = useState<any>()
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [active, setActive] = React.useState(2);
    const [entity, setEntity] = useState<any>()
    const [dataReport, setDataReport] = useState<any>();
    const arrFinancialAssetsCode = useSelector(({ authReducer }: any) => authReducer?.data?.enums[2]?.cheque_cashing[3]?.financial_asset_code)
    const arrTransactionType: IDropdownOption[] = [{ key: "cheque_cashing", text: t('cashing') }, { key: "exchange", text: t('exchange') },
    { key: "all", text: t('all') }]
    const arrFinancialAssetsType: IDropdownOption[] = [{ key: "financial_assets_in", text: t('in') }, { key: "financial_assets_out", text: t('out') },
    { key: "all", text: t('all') }]

    useEffect(() => {
        return () => {
            dispatch({
                type: RESET_DATA_RESULTS_UN_REGULAR_REPORTS,
                payload: null
            })
        }
    }, [])
    useEffect(() => {
        if (unRegularReportsData && branchSettings) {
            setUnRegularReportsData({
                ...unRegularReportsData,
                duration_days: branchSettings?.structuring?.duration_days,
                amount_threshold: branchSettings?.structuring?.amount_threshold,
                amount_from: branchSettings?.near_threshold?.amount_from,
                amount_to: branchSettings?.near_threshold?.amount_to,
            })
        }
    }, [branchSettings])

    useEffect(() => {
        if (authReducer || dataTableUnRegularReports) {

            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.user.id_client,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
        }
    }, [authReducer, dataTableUnRegularReports])


    useEffect(() => {
        if (enums) {
            let arr = {
                "impaUtrType": convertArrToDropdropdown([]),
                "impaUtrCharacteristics": convertArrToDropdropdown([]),
                "documentsTypes": convertArrToDropdropdown([]),
                "arrFinancialAssetsCode": convertArrToDropdropdown(arrFinancialAssetsCode)
            };
            for (let i = 0; i < enums?.length; i++) {
                if (enums[i].impa_utr !== undefined) {
                    let impa_utr = enums[i].impa_utr;
                    for (let i = 0; i < impa_utr?.length; i++) {
                        if (impa_utr[i].impa_utr_type_id !== undefined)
                            arr.impaUtrType = convertArrToDropdropdown(impa_utr[i].impa_utr_type_id)
                        if (impa_utr[i].impa_utr_characteristics_id !== undefined)
                            arr.impaUtrCharacteristics = convertArrToDropdropdown(impa_utr[i].impa_utr_characteristics_id)
                    }
                }
                if (enums[i].document !== undefined) {
                    let documents = enums[i].document;
                    for (let i = 0; i < documents?.length; i++) {
                        if (documents[i].type_id !== undefined)
                            arr.documentsTypes = convertArrToDropdropdown(documents[i].type_id)
                    }
                }
            }
            setenumsDropdown(arr);
        }
    }, [enums])


    const changeUnRegularReportsData = async (id: any, e: any) => {
        debugger
        setUnRegularReportsData((prevState: any) => ({
            ...prevState,
            [id]: e,
        }));

    }


    const addCheckReports = (item: any, i: number, value: any) => {
        let produceReportObj = false, transactionsToReports: any = [],
            entitiesToReports: any = [], entitiesData: any = []
        let copyDataTableUnRegularReports: any = { ...dataTableUnRegularReports }
        if (item === "all") {
            copyDataTableUnRegularReports?.results?.forEach((item: any) => {
                item.is_reporting = !allChecked
            });
            setAllChecked(!allChecked)
        }
        else {
            copyDataTableUnRegularReports.results[i].is_reporting = value
        }

        copyDataTableUnRegularReports?.results.forEach((data: any) => {
            if (data?.is_reporting === true) {
                let entity = entitiesToReports.find((item: any) => item?.id_entity === data?.id_entity)
                if (!entity) {
                    entitiesToReports.push({ id_entity: data?.id_entity })
                    entitiesData.push({ entity_name: data?.entity_name, ID_number: data?.ID_number })
                }
                transactionsToReports.push({ id_transaction: data?.id_transaction })
                produceReportObj = true
            }
        });

        dispatch({
            type: SET_DATA_RESULTS_UN_REGULAR_REPORTS,
            payload: copyDataTableUnRegularReports

        })
        setUnRegularReportsData({
            ...unRegularReportsData,
            arrEntitiesToReports: entitiesToReports,
            arrEntitiesData: entitiesData,
            arrTransactionsToReports: transactionsToReports
        })
        setProduceReport(produceReportObj)
    }



    const getUnRportsDataFunc = async () => {
        setShowSpiner(true)
        dispatch(getUnRportsData(unRegularReportsData, entity, userCredentials,
            setShowSpiner, setServerMessageError, setMessageError, setTitleMessageError))
    }
    console.log(unRegularReportsData?.reportType, "unRegularReportsData?.reportType");


    return (

        <div className={`${checkPermissionLevelOfPage(credentials, location) === "RW"
            ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={t("reports_link")} subTitle={"false"} disabledSave={true} createBtn={false}
                editBtn={false} deleteBtn={false} uploadBtn={false} action={false} />
            <br></br><br></br>
            <Subtitle title={t('unRegularReports')} />
            <div className={`content-div-${dir}`} >
                {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}

                {serverMessageError === true &&
                    <DialogMessages setansDelete={setServerMessageError} subText={t(messageError)}
                        title={t(titleMessageError)} setshowModalDelete={setServerMessageError} dialogType={"dialogMessage"} />}
                <p className="title-text" >{t("searchUnRegularReports")}</p>
                <hr className="hr" style={{ width: "1000px" }} ></hr>
                <div style={{ display: "flex" }}>
                    <div style={{ width: '100%' }}>
                        <p className="boldPP" style={{ width: "40%" }}> {t("selectReportType")}</p>
                        <div style={{ width: "15%" }}>
                            <CustomDropdown otherInputId={''} hasOtherValue={false} options={enumsDropdown?.impaUtrType} label={t('')}
                                onChange={(e: any, id: any) => { changeUnRegularReportsData(e, id) }} selectedKey={unRegularReportsData?.reportType} id={"reportType"} othertextInput={t('')} />
                        </div>
                        {unRegularReportsData?.reportType === "structuring" ?
                            <p className="boldPP" style={{ width: "40%", marginTop: "2%" }}> {"פיצולי פעולות"}</p>
                            : <p className="boldPP" style={{ width: "40%", marginTop: "2%" }}> {t(unRegularReportsData?.reportType)}</p>
                        }
                        <div >
                            {unRegularReportsData?.reportType === "structuring" &&
                                <div style={{ width: "100%" }} >
                                    <div style={{ width: "50%" }}>
                                        <div style={{ display: "flex", width: "100%" }}>
                                            <div className={`mr-4-un-reports-${dir}`} style={{ width: "30%" }}>
                                                <CustomTextField label={t("amount_threshold")} value={unRegularReportsData?.amount_threshold} readOnly={readOnly}
                                                    onChange={(id: any, e: any) => { changeUnRegularReportsData(id, e) }} id={"amount_threshold"} />
                                            </div>
                                            <div className={`padding-input-${dir}`} style={{ width: "30%" }}>
                                                <CustomTextField label={t("duration_days")} value={unRegularReportsData?.duration_days} readOnly={readOnly}
                                                    onChange={(id: any, e: any) => { changeUnRegularReportsData(id, e) }} id={"duration_days"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                            {unRegularReportsData?.reportType === "near_threshold" &&
                                <div style={{ width: "100%" }}>
                                    <div style={{ display: "flex", width: "50%" }}>
                                        <div className={`mr-4-un-reports-${dir}`} style={{ width: "30%" }}>
                                            <CustomTextField label={t("from_amount")} value={unRegularReportsData?.amount_from} readOnly={readOnly}
                                                onChange={(id: any, e: any) => { changeUnRegularReportsData(id, e) }} id={"amount_from"} />
                                        </div>
                                        <div className={`padding-input-${dir}`} style={{ width: "30%" }}>
                                            <CustomTextField label={t("to_amount")} value={unRegularReportsData?.amount_to} readOnly={readOnly}
                                                onChange={(id: any, e: any) => { changeUnRegularReportsData(id, e) }} id={"amount_to"} />
                                        </div>
                                    </div>
                                </div>}

                            {unRegularReportsData?.reportType === "trans_by_ID" &&
                                <div style={{ width: "100%" }}>
                                    <div style={{ display: "flex", width: "50%" }}>
                                        <div className={`mr-4-un-reports-${dir}`} style={{ width: "100%" }}>
                                            <p className="boldPP" style={{ width: "40%", marginTop: "2%" }}>{t("searchCustomer")}</p>

                                            <div style={{ display: "flex" }}>
                                                <DialogSearch isUnderLine={true} autoFocus={true} setReadOnly={setReadOnly}
                                                    userCredentials={userCredentials} isSearchInsiders={false} type="unRegularReport"
                                                    setRelatedFactors={setEntity} />
                                                <p>{entity?.entityName}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                            {
                                unRegularReportsData?.reportType === "reports_by_parameters" &&
                                <>
                                    <div style={{ display: "flex", width: "50%" }}>
                                        <div className={`mr-4-un-reports-${dir}`} style={{ width: "30%" }}>
                                            <CustomDropdown otherInputId={''} hasOtherValue={false} options={arrTransactionType} label={t('typeAction')}
                                                onChange={(e: any, id: any) => { changeUnRegularReportsData(e, id) }} selectedKey={unRegularReportsData?.transaction_type_id} id={"transaction_type_id"} othertextInput={t('')} />
                                        </div>
                                        <div className={`padding-input-${dir}`} style={{ width: "30%" }}>
                                            <CustomTextField label={t("overVolumn")} value={unRegularReportsData.over_volume} readOnly={readOnly}
                                                onChange={(id: any, e: any) => { changeUnRegularReportsData(id, e) }} id={"over_volume"} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", width: "50%", marginTop: '10px' }}>
                                        <div className={`mr-4-un-reports-${dir}`} style={{ width: "30%" }}>
                                            <CustomDropdown otherInputId={''} hasOtherValue={false} options={arrFinancialAssetsType} label={t('financialAsset')}
                                                onChange={(e: any, id: any) => { changeUnRegularReportsData(e, id) }} selectedKey={unRegularReportsData?.financial_assets_type} id={"financial_assets_type"} othertextInput={t('')} />
                                        </div>
                                        <div className={`padding-input-${dir}`} style={{ width: "30%" }}>
                                            <CustomDropdown otherInputId={''} hasOtherValue={false} options={enumsDropdown?.arrFinancialAssetsCode} label={t('inOrOut')}
                                                onChange={(e: any, id: any) => { changeUnRegularReportsData(e, id) }} selectedKey={unRegularReportsData?.financial_asset_code} id={"financial_asset_code"} othertextInput={t('')} />
                                        </div>
                                    </div>
                                </>
                            }


                            <div style={{ width: "100%" }}>
                                <div style={{ width: "50%" }}>
                                    <p className="boldPP" style={{ width: "100%", marginTop: '2%', }}>{t("RangeTime")}</p>

                                    <div style={{ display: "flex", marginTop: '3%', width: "100%" }}>
                                        <input type="radio" style={{ marginTop: "auto", marginBottom: 'auto', width: "2%" }} checked={active === 2} onChange={() => {
                                            setActive(2);
                                            changeUnRegularReportsData("rangeTimeAll", false)
                                        }} />
                                        <div className={`mr-2-un-reports-${dir}`} style={{ width: "30%" }}>
                                            <CustomerDate readOnly={readOnly} setIsValidDate={""} showRequiredFildes={false} isValidDate={""}
                                                entity={unRegularReportsData} setEntity={setUnRegularReportsData}
                                                isDefaultDate={true} idDate="dateStart" label={t("from_date")} source={"pastDate"} />
                                        </div>
                                        <div className={`padding-input-${dir}`} style={{ width: "30%" }}>
                                            <CustomerDate readOnly={readOnly} setIsValidDate={""} showRequiredFildes={false} isValidDate={""} entity={unRegularReportsData} setEntity={setUnRegularReportsData} isDefaultDate={true} idDate="dateEnd" label={t("to_date")} source={"pastDate"} />
                                        </div>
                                        <div className={`padding-input-${dir}`} style={{ alignItems: 'flex-end', display: "flex", width: "35%" }}>
                                            <PrimaryButton ariaDescription="create" allowDisabledFocus checked={false} name="create" text={t("searchRecords")} onClick={() => {
                                                getUnRportsDataFunc()
                                            }} />
                                            {produceReport && <div className={`padding-input-${dir}`}><PrimaryButton className={`padding-input-${dir}`} ariaDescription="create"
                                                allowDisabledFocus checked={false} name="create" text={t("produceReport")} onClick={() => {
                                                    setShowDialog(true)
                                                }} /></div>}
                                        </div>
                                        {/* setshowSpiner={setShowSpiner}  */}
                                        {showDialog &&
                                            <DialogProduceUnRegularReports
                                                setDataReport={setDataReport}
                                                enumsDropdown={enumsDropdown}
                                                setUnRegularReportsData={setUnRegularReportsData}
                                                unRegularReportsData={unRegularReportsData}
                                                setShowDialog={setShowDialog} />}
                                    </div>


                                    <div style={{ display: "flex", marginTop: '2%', width: "100%" }}>
                                        <input type="radio" style={{ marginTop: "auto", marginBottom: 'auto' }} checked={active === 0} onChange={() => {
                                            setActive(0);
                                            changeUnRegularReportsData("rangeTimeAll", true)
                                        }} />
                                        <p className={`mr-2-un-reports-${dir} boldPP`} style={{ width: "40%" }}> {t("anyTime")} </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style={{ width: '90%' }}>
                    <UnRegularReportsTable data={dataTableUnRegularReports}
                        selectTransactionsFunc={addCheckReports} allChecked={allChecked} />
                </div>
            </div>
            <div style={{ display: "none" }}>
                {/* setDataOfFile={""} */}
                <ExportUnRegularReports dataReport={dataReport} /></div>
        </div>
    )
}
