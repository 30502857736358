import { ISpinnerStyles, Spinner } from '@fluentui/react';
import { cloneDeep, isEqual } from 'lodash';
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import { DialogMessages } from '../../shared/components/dialog/DialogMessages';
import HeaderUtils from '../../shared/components/headerUtils/HeaderUtils'
import Subtitle from "../../shared/components/Subtitle";
import { readFairCredit, saveFairCredit } from '../../store/actions/settingsAction'
import { IUserCredentials } from '../customerDetails/CustomerDetails';
import RowThreeItems from './RowThreeItems'
import TableDetails from './TableDetails';
import { useLocation } from 'react-router';
import { checkPermissionLevelOfPage } from '../../shared/components/CustomHooks/GlobalFunctions';

const RoleFairCredit = () => {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const credentials = useSelector(({ authReducer }: any) => authReducer?.data?.credentials)
    const location = useLocation()
    const dispatch = useDispatch()
    const [readOnly, setreadOnly] = useState(true)
    const authReducer = useSelector(({ authReducer }: any) => authReducer)
    const [showDialogSave, setShowDialogSave] = useState(false)
    const [showDialogFailed, setShowDialogFailed] = useState(false)
    const [showSpiner, setshowSpiner] = useState(false)
    const [showTextRequiredFiles, setShowTextRequiredFiles] = useState(false)
    const [showRequiredFields, setShowRequiredFields] = useState(false)
    const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null)
    const [updateFairCredit, setUpdateFairCredit] = useState<any>()
    const [fairCreditBeforeChanges, setFairCreditBeforeChanges] = useState<any>({})

    const spinnerStyles: Partial<ISpinnerStyles> = {
        root: {
            position: 'fixed',
            zIndex: '999999',
            fontWeight: 'bold',
        },
        circle: {
            color: '#FFB400 ',
            height: '100px',
            width: '100px',
        },
    }
    useEffect(() => {
        if (!updateFairCredit && userCredentials) {
            dispatch(readFairCredit(userCredentials, setUpdateFairCredit, setFairCreditBeforeChanges))
        }
    }, [updateFairCredit, userCredentials,dispatch])



    useEffect(() => {
        if (authReducer) {
            setUserCredentials({
                "idInitiator": authReducer.data.user.id_entity,
                "idClient": authReducer.data.client.id_entity,
                "idBranch": authReducer.data.branch.id_entity,
                "idClientData": authReducer.data.client.id_client
            })
            // setFairCreditBeforeChanges(authReducer?.data?.branch?.fair_credit_limits)
            // setUpdateFairCredit(authReducer?.data?.branch?.fair_credit_limits)
        }
    }, [authReducer]);

    const changeFairCredit = (key: string, value: any) => {
        setUpdateFairCredit({ ...updateFairCredit, [key]: value })
    }

    const check = (currentValue: string) => updateFairCredit[currentValue]?.length > 0 || updateFairCredit[currentValue];

    const saveProgram = async (statusProgram: boolean) => {
        let requiredFields: any = ["boi_interest", "fx_interest", "addition_interest_to_90_ils", "addition_interest_to_90_fx", "addition_interest_over_90_ils", "addition_interest_over_90_fx", "addition_max_interest_ils", "addition_max_interest_fx",
            "exclusion_credit_over_days", "exclusion_credit_over_amount", "minimum_exclusion_amount", "exclusion_percentage_to_10k", "maximum_exclusion_amount_to_10k", "exclusion_percentage_over_10k_to_10d", "exclusion_percentage_over_10k_over_10d", "maximum_exclusion_amount_over_10k_over_10d"
        ]
        let arr = ["boi_interest", "fx_interest", "addition_interest_to_90_ils", "addition_interest_to_90_fx", "addition_max_interest_ils", "addition_interest_over_90_ils",
            "addition_interest_over_90_fx", "addition_max_interest_fx", "exclusion_percentage_to_10k", "exclusion_percentage_over_10k_to_10d", "exclusion_percentage_over_10k_over_10d"]
        let copyFairCredit = cloneDeep(updateFairCredit)
        arr.forEach((item: any, index: any) => {
            if (copyFairCredit[item])
                copyFairCredit[item] /= 100
        })
        if (requiredFields.every(check)) {
            if (statusProgram && userCredentials !== null && !isEqual(updateFairCredit, fairCreditBeforeChanges)) {
                setshowSpiner(true)
                // idEntity, setShowDialogSave, setShowDialogFailed, setshowSpiner
                await dispatch(saveFairCredit(copyFairCredit, userCredentials, setShowDialogSave, setShowDialogFailed, setshowSpiner))
                setFairCreditBeforeChanges(cloneDeep(updateFairCredit))
            }
            setreadOnly(true)
            setShowRequiredFields(false)
        }
        else {
            setShowTextRequiredFiles(true)
            setShowRequiredFields(true)
        }
    }
    const cancelEdit = async () => {
        setUpdateFairCredit(cloneDeep(fairCreditBeforeChanges))
        setreadOnly(true)
        setShowRequiredFields(false)
    }
    return (
        <div className={`${checkPermissionLevelOfPage(credentials,location) === "RW"
    ? 'page-read' : 'page-read-only'}`}>
            <HeaderUtils title={"settings"} subTitle={"false"} disabledSave={readOnly ? true : false} createBtn={false} saveFunc={() => saveProgram(true)} editFunc={() => setreadOnly(false)} cancelFunc={() => cancelEdit()} editBtn={readOnly ? true : false} deleteBtn={false} uploadBtn={readOnly ? false : true} />
            <br></br><br></br>
            {showTextRequiredFiles && <DialogMessages setansDelete={setShowTextRequiredFiles} setshowModalDelete={setShowTextRequiredFiles} dialogType={"requiredFields"} />}
            {showDialogSave && <DialogMessages setansDelete={setShowDialogSave} setshowModalDelete={setShowDialogSave} dialogType={"save"} />}
            {showDialogFailed && <DialogMessages setansDelete={setShowDialogFailed} setshowModalDelete={setShowDialogFailed} dialogType={"failed"} />}
            {showSpiner && <Spinner styles={spinnerStyles} className={`spinner-${dir}`} />}
            <Subtitle title={t('roleFairCredit')} />
            <div className={`content-div-${dir}`} >
                <p className="title-text" >{t("actualInterestLimit")}</p>
                <hr className="hr" style={{ width: "50.5%" }} ></hr>
                <div style={{ width: "50%" }}>
                    <div className="titleC">
                        <p className="title-text boldC" style={{ width: "40%" }}>{""}</p>
                        <p className="title-text boldC" style={{ width: "20%" }}>{t("shekels")}</p>
                        <p className="title-text boldC" style={{ width: "38%" }}>{t("foreignCurrency")}</p>
                    </div>
                </div>
                <hr className="hr" style={{ width: "50.5%", height: "3px", marginBottom: '0' }} ></hr>
                <TableDetails showRequiredFields={showRequiredFields} updateFairCredit={updateFairCredit} readOnly={readOnly} change={changeFairCredit} ilsId="boi_interest" fxId="fx_interest" value="ריבית בסיב (בנק ישראל/ליבור)" />
                <TableDetails showRequiredFields={showRequiredFields} updateFairCredit={updateFairCredit} readOnly={readOnly} change={changeFairCredit} ilsId="addition_interest_to_90_ils" fxId="addition_interest_to_90_fx" value="תוספת ריבית על אשראי עד 90 יום" />
                <TableDetails showRequiredFields={showRequiredFields} updateFairCredit={updateFairCredit} readOnly={readOnly} change={changeFairCredit} ilsId="addition_interest_over_90_ils" fxId="addition_interest_over_90_fx" value="תוספת ריבית על אשראי מעל 90 יום" />
                <TableDetails showRequiredFields={showRequiredFields} updateFairCredit={updateFairCredit} readOnly={readOnly} change={changeFairCredit} ilsId="addition_max_interest_ils" fxId="addition_max_interest_fx" value="תוספת ריבית רף פלילי" />
                <p className="title-text" style={{ marginTop: "16%" }} >החרגת הוצאות ממגבלת ריבית</p>
                <hr className="hr" style={{ width: "50.5%" }} ></hr>
                <p className="boldPP" style={{ width: "60%" }}>החרגת ההוצאות ממגבלת ריבית לא תחול על:</p>

                <RowThreeItems showRequiredFields={showRequiredFields} marginRight={`${dir==="rtl"?"13%":"0%"}`} marginLeft={`${dir==="ltr"?"13%":"0%"}`}  firstP="עסקאות שהן מעל:" scoundP='ימים' readOnly={readOnly} change={changeFairCredit} value="" updateFairCredit={updateFairCredit} id="exclusion_credit_over_days"
                 firstPMarginRight={`${dir==="rtl"?"0":"0"}`}firstPMarginLeft={`${dir==="ltr"?"0":"0"}`} />
                <RowThreeItems showRequiredFields={showRequiredFields}  marginRight={`${dir==="rtl"?"13%":"0%"}`} marginLeft={`${dir==="ltr"?"13%":"0%"}`}
                firstPMarginRight={`${dir==="rtl"?"5":"0"}`}firstPMarginLeft={`${dir==="ltr"?"5":"0"}`} firstP="ובסכום מעל" readOnly={readOnly} scoundP='ש"ח' isCurrency={true} change={changeFairCredit} value="" updateFairCredit={updateFairCredit} id="exclusion_credit_over_amount" />
                <RowThreeItems showRequiredFields={showRequiredFields} marginTop="2%" firstP="סכום מינמלי להחרגה" scoundP='ש"ח' isCurrency={true} readOnly={readOnly} change={changeFairCredit} value="" 
                firstPMarginRight={`${dir==="rtl"?"0":"0"}`}firstPMarginLeft={`${dir==="ltr"?"0":"0"}`} updateFairCredit={updateFairCredit} id="minimum_exclusion_amount" />
                <p className="boldPP" style={{ width: "60%", marginTop: '3%' }}>החרגת ההוצאות על הלוואות עד 10,000 ש"ח:</p>
                <hr className="hr" style={{ width: "50.5%", height: "1px", marginBottom: '0' }} ></hr>
                <RowThreeItems showRequiredFields={showRequiredFields} firstP="שיעור מגובה ההלואה:" value="%"  marginRight={`${dir==="rtl"?"4%":"0%"}`} marginLeft={`${dir==="ltr"?"4%":"0%"}`}  readOnly={readOnly} change={changeFairCredit}
                firstPMarginRight={`${dir==="rtl"?"0":"0"}`}firstPMarginLeft={`${dir==="ltr"?"0":"0"}`} updateFairCredit={updateFairCredit} id="exclusion_percentage_to_10k" isPrecent={true} />
                <RowThreeItems showRequiredFields={showRequiredFields}  marginRight={`${dir==="rtl"?"4%":"0%"}`} marginLeft={`${dir==="ltr"?"4%":"0%"}`}  firstP="ועד לסכום של" scoundP='ש"ח' isCurrency={true} readOnly={readOnly} change={changeFairCredit} value="" 
                firstPMarginRight={`${dir==="rtl"?"6":"0"}`}firstPMarginLeft={`${dir==="ltr"&&"6"}`} updateFairCredit={updateFairCredit} id="maximum_exclusion_amount_to_10k" />

                <p className="boldPP" style={{ width: "60%" }}>החרגת ההוצאות מעל הלוואות עד 10,000 ש"ח:</p>
                <hr className="hr" style={{ width: "50.5%", height: "1px", marginBottom: '0' }} ></hr>
                <p className="boldPP" style={{ width: "20%" }}>עד 10 ימים:</p>
                <RowThreeItems showRequiredFields={showRequiredFields} firstP="שיעור מגובה ההלואה:" value="%" 
                 marginRight={`${dir==="rtl"?"4%":"0%"}`} marginLeft={`${dir==="ltr"?"4%":"0%"}`} readOnly={readOnly} change={changeFairCredit}
                 firstPMarginRight={`${dir==="rtl"?"0":"0"}`}firstPMarginLeft={`${dir==="ltr"?"0":"0"}`} updateFairCredit={updateFairCredit} id="exclusion_percentage_over_10k_to_10d" isPrecent={true} />

                <p className="boldPP" style={{ width: "20%" }}>מעל 10 ימים:</p>
                <RowThreeItems showRequiredFields={showRequiredFields} firstP="שיעור מגובה ההלואה:" value="%"  marginRight={`${dir==="rtl"?"4%":"0%"}`} marginLeft={`${dir==="ltr"?"4%":"0%"}`} readOnly={readOnly} change={changeFairCredit} 
                firstPMarginRight={`${dir==="rtl"?"0":"0"}`}firstPMarginLeft={`${dir==="ltr"?"0":"0"}`} updateFairCredit={updateFairCredit} id="exclusion_percentage_over_10k_over_10d" isPrecent={true} />
                <RowThreeItems showRequiredFields={showRequiredFields}  marginRight={`${dir==="rtl"?"4%":"0"}`} marginLeft={`${dir==="ltr"?"4%":"0"}`}firstP="ועד לסכום של" scoundP='ש"ח' isCurrency={true} readOnly={readOnly} change={changeFairCredit} value="" 
                firstPMarginRight={`${dir==="rtl"?"6":"0"}`}firstPMarginLeft={`${dir==="ltr"?"6":"0"}`} updateFairCredit={updateFairCredit} id="maximum_exclusion_amount_over_10k_over_10d" />
            </div>
        </div>
    )
}
export default RoleFairCredit
