import { DefaultButton, Dialog,ContextualMenu } from '@fluentui/react';
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useBoolean } from '@fluentui/react-hooks';
import { useSelector } from 'react-redux';

export default function AccountsAddAcountFirstDialog(props: any) {
    const [t] = useTranslation();
    const dir = useSelector(({ dataReducer }: any) => dataReducer?.language)
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const { setShowDialog } = props
    useEffect(() => {
        if (setShowDialog) {
            toggleHideDialog()
        }
    }, [setShowDialog, toggleHideDialog])

    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
      };
    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        dragOptions: dragOptions 
    };

    return (
        <Dialog minWidth="sm" maxWidth="sm" modalProps={modelProps} hidden={hideDialog} onDismiss={() => { toggleHideDialog(); setShowDialog(false) }} >
            <div className="wrap-dialog" style={{ width: "100%" }}>
                <DefaultButton className={`button-account-${dir}`} onClick={() => { toggleHideDialog(); setShowDialog(false) }} text={t("cancel")} />
                <div style={{ display: "flex" }}>
                    <p className="title-text" style={{ fontSize: "30px", fontWeight: "bold" }}>{t('BookkeepingAccount')}</p>
                </div>
                <hr className="hr"></hr>
            </div>
        </Dialog>
    )
}
